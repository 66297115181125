import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateToISO'
})
export class DateToIsoPipe implements PipeTransform {

  transform(value: unknown): string {
    return moment(value).format();

  }

}
