import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { forkJoin } from 'rxjs';
import { BoosterSessionsService } from './../../booster-sessions/booster-sessions.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { AssessmentsService } from 'src/app/services/assessments.service';
import { TargetService } from 'src/app/services/target.service';

@Component({
  selector: 'app-learning-session-index',
  templateUrl: './learning-session-index.component.html',
  styleUrls: ['./learning-session-index.component.scss'],
})
export class LearningSessionIndexComponent implements OnInit {
  media;
  learningSession;
  learningSessionProgramme;
  learningSessionSteps;
  learningSessionId;
  sessionProgress;
  prevSession;
  nextSession;
  isFetching;
  currentUser;
  currentProgramme;
  environment = environment;
  stepTarget;

  constructor(
    private boosterSessionService: BoosterSessionsService,
    private route: ActivatedRoute,
    private boosterSessionsService: BoosterSessionsService,
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private router: Router,
    private titleService: Title,
    private assessmentService: AssessmentsService,
    private targetService: TargetService
  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
        if (this.currentUser) {
          if (this.currentUser.buddy) {
            console.log('buddy');
            this.router.navigate(['/dashboard']);
          }
        }
      });
    }

    //
    this.route.params.subscribe((params) => {
      this.prevSession = null;
      this.nextSession = null;
      this.learningSessionId = this.route.snapshot.params.learningSessionId;
      this.isFetching = true;

      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (this.currentProgramme) {
          forkJoin({
            learningSession: this.boosterSessionsService.fetch(
              this.learningSessionId
            ),
            learningSessionSteps: this.boosterSessionsService.fetchSteps(
              this.learningSessionId
            ),
            sessionProgress: this.boosterSessionsService.getSessionProgress(
              this.learningSessionId
            ),
            programmeContent: this.programmeService.getProgrammeContent(
              this.currentProgramme.programme_id
            ),
            stepTarget: this.targetService.getTarget('step')
          }).subscribe((responseData) => {
            this.isFetching = false;
            this.learningSession = responseData.learningSession;

            // do we need to take a pre test?
            this.checkAssessment(this.learningSession);

            // can they access?
            if (
              this.learningSession.status == 'unavailable' &&
              +this.currentUser.is_admin == 0
            ) {
              console.log('unavailable');
              this.router.navigate(['/dashboard']);
            }

            this.learningSessionSteps = responseData.learningSessionSteps;
            this.sessionProgress = responseData.sessionProgress;

            if (this.sessionProgress) {
              if (this.sessionProgress.pages_viewed) {
                const pagesViewed = JSON.parse(
                  this.sessionProgress.pages_viewed
                );

                if (Array.isArray(pagesViewed)) {
                  // set the status..
                  this.learningSessionSteps.forEach((item, index) => {
                    if (
                      pagesViewed.indexOf(item.booster_session_step_id) !== -1
                    ) {
                      this.learningSessionSteps[index].progress = 'viewed';
                    }
                  });
                }
              }
            }
            this.stepTarget = responseData.stepTarget;
            // do prev and next
            const learningSessions = responseData.programmeContent.booster_sessions.filter(
              (boosterSession) => boosterSession.release_date == 'O' || (boosterSession.release_date == 'EPL' && this.stepTarget)
            );
            let isLearningSession = false;
            learningSessions.forEach((session, index) => {
              if (session.booster_session_id == this.learningSessionId) {
                // console.log(session);

                this.learningSessionProgramme = session;
                // setHtmlTitle($scope.programmeBoosterSession.booster_session_title );
                isLearningSession = true;
                if (learningSessions[index - 1]) {
                  this.prevSession = learningSessions[index - 1];
                }
                if (learningSessions[index + 1]) {
                  this.nextSession = learningSessions[index + 1];
                }
              }
            });

            if (isLearningSession == false) {
              // is it an extra session?
              responseData.programmeContent.extra_sessions.forEach(
                (session, index) => {
                  if (session.booster_session_id == this.learningSessionId) {
                    isLearningSession = true;
                  }
                }
              );
            }

            if (isLearningSession == false) {
              this.router.navigate(['/dashboard']);
            }

            this.titleService.setTitle(
              environment.title +
                ' | Learning Sessions | ' +
                this.learningSession.title
            );
          });
        }
      });
    });
  }

  checkAssessment(session){
    if (session.assessment_id) {
      // ok, do we have any results?
      const assessmentIds = JSON.parse(session.assessment_id);
      let sentOff = false;
      if(Array.isArray(assessmentIds)){
      assessmentIds.forEach((assessmentId) => {
        this.assessmentService
          .fetchResults(assessmentId, 'pre', this.learningSessionId)
          .subscribe((resultsResponse) => {
            if (resultsResponse.length < 1 && sentOff == false) {
              sentOff = true;
              setTimeout(() => {
                // take it
                this.router.navigate([
                  '/assessments',
                  'take',
                  assessmentId,
                  'pre',
                  this.learningSessionId,
                ]);
                return;
              }, 500);
            }
          });
      });
    }
    }
  }
}
