import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AuthService } from 'src/app/auth/auth.service';
import { AppointmentService } from 'src/app/services/appointment.service';
import { ProgrammeService } from 'src/app/services/programme.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-m3-header',
  templateUrl: './m3-header.component.html',
  styleUrls: ['./m3-header.component.scss']
})
export class M3HeaderComponent implements OnInit {
  currentUser;
  currentProgramme;
  environment = environment;
  age;
  nextAppointment;
  constructor(
    private authService: AuthService,
    private programmeService: ProgrammeService,
    private router: Router,
    private userService: UserService,
    private appointmentService:AppointmentService
  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {});

      this.userService.userData.subscribe((userDataResponse) => {
        if(userDataResponse){
        this.currentUser = userDataResponse;
        this.age = moment().diff(this.currentUser.dob, 'years',false);
        }

        this.appointmentService.getNextAppointment().subscribe((responseData) => {
          if(responseData){
            this.nextAppointment = responseData;
          }
        });
      });
    }
  }
}

