import { LearningMaterialService } from './../../services/learning-material.service';
import { PageViewService } from './../../services/page-view.service';
import { DateHelperService } from './../../services/date-helper.service';
import { ConfigService } from './../../services/config.service';
import { SmokingService } from './../../services/smoking.service';
import { AlertService } from './../../services/alert.service';
import { NgForm } from '@angular/forms';
import { CholesterolService } from './../../services/cholesterol.service';
import { BloodPressureService } from './../../services/blood-pressure.service';
import { BloodGlucoseService } from './../../services/blood-glucose.service';
import { HealthyEatingService } from './../../services/healthy-eating.service';
import { environment } from './../../../environments/environment';
import { UserShapeService } from './../../services/user-shape.service';
import { BoosterSessionsService } from './../../booster-sessions/booster-sessions.service';
import { TargetService } from './../../services/target.service';
import { ActivityService } from './../../services/activity.service';
import { UserSettingsService } from './../../services/user-settings.service';
import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import Swal from 'sweetalert2/src/sweetalert2.js';
import * as moment from 'moment';

@Component({
  selector: 'app-dashboard-individual',
  templateUrl: './dashboard-individual.component.html',
  styleUrls: ['./dashboard-individual.component.scss'],
})
export class DashboardIndividualComponent implements OnInit {
  @ViewChild('stepsForm', { static: false }) stepsForm: NgForm;

  currentUser;
  currentProgramme;
  deviceSynced = null;
  hideTrackerDB = false;
  isFetching = false;
  errors = [];
  userSettings;

  currentActivity;
  currentTarget;
  currentTargetMins;
  environment = environment;
  currentSteps = 0;

  // ported vars
  learningSessionTotals;
  learningSessionProgressTotal;
  boosterSessionTotals;
  boosterSessionProgressTotal;
  programmeContent;
  startLSession = 0;
  endLSession = 2;
  startSession = 0;
  endSession = 2;
  lastViewed;
  lastViewedPage;

  newAlerts = [];

  // health trackers
  userShapes = [];
  userHealthyEatings = [];
  userSmokingType;
  smokingLabels = {
    cigarettes: 'Cigarettes',
    cigars: 'cigars',
    tobacco: 'Tobacco',
    pipe_tobacco: 'Pipe Tobacco',
  };
  cost;
  userSmokings = [];
  userHbA1cs = [];
  userBloodPressures = [];
  userCholesterols = [];
  bmi;

  // challenges
  challengePos;
  iLead = false;
  currentLeader = [];
  myActivity;
  format;
  monfriChallenge = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  weekendChallenge = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  currentChallenge = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  teamChallenge = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  globalChallenge = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };

  date = new Date();

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private userSettingsService: UserSettingsService,
    private activityService: ActivityService,
    private targetService: TargetService,
    private boosterSessionService: BoosterSessionsService,
    private userShapeService: UserShapeService,
    private healthyEatingService: HealthyEatingService,
    private bloodGlucoseService: BloodGlucoseService,
    private bloodPressureService: BloodPressureService,
    private cholesterolService: CholesterolService,
    private smokingService: SmokingService,
    private alertService: AlertService,
    private configService: ConfigService,
    private dateHelperService: DateHelperService,
    private pageViewService: PageViewService,
    private learningMaterialService: LearningMaterialService
  ) {}

  ngOnInit(): void {
    this.isFetching = true;
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
        if (this.currentUser) {
          this.getChallenge();
        }
      });

      forkJoin({
        userSettings: this.userSettingsService.fetchAll(),
        activity: this.activityService.getActivity(),
        target: this.targetService.getTarget('step'),
        targetMins: this.targetService.getTarget('mins'),
        sessionProgress: this.boosterSessionService.getSessionsProgressData(
          this.currentProgramme.programme_id
        ),
        userShapes: this.userShapeService.fetchAll(),
        userHealthyEatings: this.healthyEatingService.fetchAll(),
        userHbA1cs: this.bloodGlucoseService.fetchAll(),
        userBloodPressures: this.bloodPressureService.fetchAll(),
        userCholesterols: this.cholesterolService.fetchAll(),
        userSmokings: this.smokingService.fetchAll(),
        alerts: this.alertService.fetchAll(),
        configs: this.configService.fetchAll(),
        pageviews: this.pageViewService.fetchAll(),
      }).subscribe(
        (responseData) => {
          this.isFetching = false;
          this.userSettings = responseData.userSettings;
          this.hideTrackerDB = this.userSettings['hideDbTracker'];
          this.currentActivity = responseData.activity;
          if (this.currentActivity) {
            this.currentSteps = this.currentActivity.steps;
            this.deviceSynced = true;
          } else {
            this.deviceSynced = false;
          }

          if (responseData.target) {
            this.currentTarget = responseData.target.target;
          }
          if (responseData.targetMins) {
            this.currentTargetMins = responseData.targetMins.target;
          }

          const sessionProgress = this.boosterSessionService.doSessions(
            responseData.sessionProgress,
            user.created
          );
          // console.log(sessionProgress);
          this.learningSessionTotals = sessionProgress.learningSessionTotals;
          this.learningSessionProgressTotal =
            sessionProgress.learningSessionProgressTotal;
          this.boosterSessionTotals = sessionProgress.boosterSessionTotals;
          this.boosterSessionProgressTotal =
            sessionProgress.boosterSessionProgressTotal;
          this.programmeContent = sessionProgress.programmeContent;

          this.userShapes = responseData.userShapes;
          this.userHealthyEatings = responseData.userHealthyEatings;
          this.userHbA1cs = responseData.userHbA1cs;
          this.userBloodPressures = responseData.userBloodPressures;
          this.userCholesterols = responseData.userCholesterols;

          this.newAlerts = this.alertService.filterByProgramme(responseData.alerts, this.currentProgramme.programme_id);


          this.userSmokings = responseData.userSmokings;

          // cost
          if (this.userSmokings[0]) {
            this.userSmokingType = responseData.userSettings['smoking_type'];
            const configs = responseData.configs;
            switch (this.userSmokingType) {
              case 'cigarettes':
                this.cost =
                  this.userSmokings[0].cigarettes *
                  configs['cigarette_cost'] *
                  365;
                break;
              case 'tobacco':
                this.cost =
                  this.userSmokings[0].tobacco * configs['tobacco_cost'] * 365;

                break;
              case 'cigars':
                this.cost =
                  this.userSmokings[0].cigars * configs['cigar_cost'] * 365;

                break;
              case 'pipe_tobacco':
                this.cost =
                  this.userSmokings[0].pipe_tobacco *
                  configs['pipe_tobacco_cost'] *
                  365;

                break;
            }
          }

          this.lastViewed = responseData.pageviews[0];
          if (this.lastViewed) {
            if (this.lastViewed.booster_session_id) {
              this.boosterSessionService
                .fetchStep(this.lastViewed.page_id)
                .subscribe((lastViewedResponse) => {
                  if (lastViewedResponse) {
                    this.lastViewedPage = lastViewedResponse;
                    this.lastViewedPage.parent = {};
                    // get the parent..
                    this.programmeContent.booster_sessions.forEach((bs) => {
                      if (
                        bs.booster_session_id ==
                        this.lastViewedPage.booster_session_id
                      ) {
                        this.lastViewedPage.parent = bs;
                        if (bs.release_date == 'O') {
                          this.lastViewedPage.parent.type = 'learningSession';
                        } else {
                          this.lastViewedPage.parent.type = 'boosterSession';
                        }
                      }
                    });
                  }
                });
            }

            if (this.lastViewed.content_section_id) {
              this.learningMaterialService
                .fetchPage(this.lastViewed.page_id)
                .subscribe((lastViewedResponse) => {
                  if (lastViewedResponse) {
                    this.lastViewedPage = lastViewedResponse;
                    this.lastViewedPage.parent = {};

                    this.programmeContent.content_sections.forEach((bs) => {
                      if (
                        bs.content_section_id ==
                        this.lastViewedPage.content_section_id
                      ) {
                        this.lastViewedPage.parent = bs;
                      }
                    });
                  }
                });
            }
          }
        },
        (error) => {
          console.log(error);

          this.isFetching = false;
          this.errors.push(error.message);
        }
      );
    }
  }

  onSetLSession(index) {
    this.startLSession = index;
    this.endLSession = +this.startLSession + 2;
  }

  onSetSession(index) {
    this.startSession = index;
    this.endSession = +this.startSession + 2;
  }

  onSubmitStepsForm() {
    // save the steps

    this.activityService
      .saveSteps(this.stepsForm.value.steps, this.stepsForm.value.date)
      .subscribe((responseData) => {
        this.activityService.getActivity().subscribe((activityResponseData) => {
          this.currentActivity = activityResponseData;
        });

        Swal.fire({
          icon: 'success',
          title: 'Steps Updated',
          text: 'Your steps were successfully updated',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        /*
        swal({
        title: 'Steps Updated',
        text: 'Your steps were successfully updated',
        type: 'success',
        timer: 1000,
        showConfirmButton: false,
      });*/
      });
  }

  onCloseAlert(alert, followLink = true) {
    this.alertService.updateStatus(alert.alert_id).subscribe((responseData) => {
      this.alertService.fetchAll().subscribe((alertsResponse) => {
        this.newAlerts =  this.alertService.filterByProgramme(alertsResponse, this.currentProgramme.programme_id);
      });
      if (alert.button_link) {
        if(followLink){
        if (alert.button_link === 'refresh') {
          location.reload();
        } else {
          document.location = alert.button_link;
        }
      }
      }
    });
  }

  hideDbTracker() {
    this.hideTrackerDB = true;
    this.userSettingsService
      .saveSetting('hideDbTracker', '1')
      .subscribe((responseData) => {});
  }

  calcBmi(weight) {
    if (weight) {
      const BMI =
        Math.round(
          (+weight /
            ((this.currentUser.height / 100) *
              (this.currentUser.height / 100))) *
            10
        ) / 10;
      return BMI;
    } else {
      return '-';
    }
  }

  getChallenge() {
    // start challenge

    // get week
    const now = moment().format('YYYY-MM-DD');

    // what is the day today
    const today = moment(now).format('dddd');
    const currentWeek = this.dateHelperService.getWeekFor(now);

    this.globalChallenge.startDate = this.teamChallenge.startDate;
    this.globalChallenge.endDate = this.teamChallenge.endDate;
    this.globalChallenge.nextStartDate = moment(currentWeek[0])
      .add(1, 'week')
      .format('YYYY-MM-DD');

    if (today == 'Saturday' || today == 'Sunday') {
      this.weekendChallenge.title = 'Weekend Challenge';
      this.currentChallenge.title = 'Weekend Challenge';
      this.weekendChallenge.startDate = moment(currentWeek[5]).format(
        'YYYY-MM-DD'
      );
      this.weekendChallenge.endDate = moment(currentWeek[6]).format(
        'YYYY-MM-DD'
      );
      this.weekendChallenge.type = 'WEEKEND';
      this.weekendChallenge.id =
        this.weekendChallenge.type +
        ';' +
        this.weekendChallenge.startDate +
        ';' +
        this.weekendChallenge.endDate;

      this.currentChallenge = this.weekendChallenge;

      this.monfriChallenge.title = 'Monday - Friday Challenge';
      this.monfriChallenge.startDate = moment(currentWeek[0]).format(
        'YYYY-MM-DD'
      );
      this.monfriChallenge.endDate = moment(currentWeek[4]).format(
        'YYYY-MM-DD'
      );
      this.monfriChallenge.type = 'MON-FRI';
      this.monfriChallenge.id =
        this.monfriChallenge.type +
        ';' +
        this.monfriChallenge.startDate +
        ';' +
        this.monfriChallenge.endDate;
    } else {
      this.monfriChallenge.title = 'Monday - Friday Challenge';
      this.currentChallenge.title = 'Monday - Friday Challenge';
      this.monfriChallenge.startDate = moment(currentWeek[0]).format(
        'YYYY-MM-DD'
      );
      this.monfriChallenge.endDate = moment(currentWeek[4]).format(
        'YYYY-MM-DD'
      );
      this.monfriChallenge.type = 'MON-FRI';
      this.monfriChallenge.id =
        this.monfriChallenge.type +
        ';' +
        this.monfriChallenge.startDate +
        ';' +
        this.monfriChallenge.endDate;

      this.currentChallenge = this.monfriChallenge;

      this.weekendChallenge.title = 'Weekend Challenge';
      this.weekendChallenge.startDate = moment(currentWeek[5])
        .subtract(1, 'week')
        .format('YYYY-MM-DD');

      this.weekendChallenge.endDate = moment(currentWeek[6])
        .subtract(1, 'week')
        .format('YYYY-MM-DD');
      this.weekendChallenge.type = 'WEEKEND';
      this.weekendChallenge.id =
        this.weekendChallenge.type +
        ';' +
        this.weekendChallenge.startDate +
        ';' +
        this.weekendChallenge.endDate;
    }

    if (!this.currentUser.buddy) {
      this.activityService
        .getBandActivity(
          this.currentUser.step_band,
          this.currentProgramme.programme_id,
          this.currentChallenge.startDate,
          this.currentChallenge.endDate
        )
        .subscribe((responseData) => {
          let pos = 0;
          responseData.forEach((item, index) => {
            pos++;

            if (item.user_id == this.currentUser.user_id) {
              this.challengePos = pos;
            }
            if (item.user_id === this.currentUser.user_id) {
              this.myActivity = item;
            }

            if (this.currentLeader.length === 0) {
              this.currentLeader.push(responseData[index]);
              if (responseData[index].user_id === this.currentUser.user_id) {
                this.iLead = true;
              }
            } else {
              if (index > 0) {
                if (
                  responseData[index].step_count ===
                  this.currentLeader[0].step_count
                ) {
                  this.currentLeader.push(responseData[index]);
                  if (
                    responseData[index].user_id === this.currentUser.user_id
                  ) {
                    this.iLead = true;
                  }
                }
              }
            }
          });
        });
    }

    // end challenge
  }

}
