import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpParams,
  HttpErrorResponse,
  HttpHeaders,
  HttpEvent,
} from '@angular/common/http';
import {
  take,
  exhaustMap,
  tap,
  catchError,
  filter,
  switchMap,
} from 'rxjs/operators';

import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { CacheManagerService } from '../services/cache-manager.service';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthRefreshInterceptorService implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(
    private authService: AuthService,
    private router: Router,
    private cacheManagerService: CacheManagerService,
    private cookieService: CookieService,
    private userService: UserService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // We don't want to refresh token for some requests like login or refresh token itself
        // So we verify url and we throw an error if it's the case
        if (request.url.includes('refresh') || request.url.includes('login')) {
          // We do another check to see if refresh token failed
          // In this case we want to logout user and to redirect it to login page

          if (request.url.includes('refresh')) {
            this.authService.logout();
          }

          return throwError(error);
        }

        if (error.status !== 401) {
          return throwError(error);
        }

        if (error.status == 401) {
          if (this.refreshTokenInProgress) {
            // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
            // – which means the new token is ready and we can retry the request again
            return this.refreshTokenSubject.pipe(
              filter((token) => token != null),
              take(1),
              switchMap((token) => {
                return next.handle(this.addAuthenticationToken(request));
              })
            );
          } else {
            this.refreshTokenInProgress = true;

            // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.refreshTokenSubject.next(null);
            // refresh the token
            this.cookieService.delete('apiToken', '/');
            this.authService.apiToken = null;

            //TODO CHANGE FROM COOKIES
            // let bearer = user.getToken();

            this.refreshTokenInProgress = true;

            // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.refreshTokenSubject.next(null);

            // Call auth.refreshAccessToken(this is an Observable that will be returned)
            /* this.authService.refreshAccessToken().subscribe(
              (responseData) => {
                //When the call to refreshToken completes we reset the refreshTokenInProgress to false
                // for the next time the token needs to be refreshed
                this.authService.apiToken =
                  responseData?.api_token?.access_token;
                this.authService.refreshToken =
                  responseData?.api_token?.refresh_token;

                this.refreshTokenInProgress = false;
                this.refreshTokenSubject.next(this.authService.apiToken);
                this.cacheManagerService.clearAllCache();

                return next.handle(this.addAuthenticationToken(request));
              },
              (error) => {
                this.refreshTokenInProgress = false;

                this.cacheManagerService.clearAllCache();
                this.authService.logout();
                return throwError(error);
              }
            );*/

            // New test

            return this.authService.refreshAccessTokenAlt().pipe(
              switchMap((refreshResult) => {
                // assuming that tokenService.refreshToken() will return { accessToken: 'myNewAccessToken', refreshToken: 'myNewRefreshToken'}
                this.authService.apiToken =
                  refreshResult?.api_token?.access_token;
                this.authService.refreshToken =
                  refreshResult?.api_token?.refresh_token;

                this.refreshTokenInProgress = false;
                this.refreshTokenSubject.next(this.authService.apiToken);
                this.cacheManagerService.clearAllCache();

                this.authService.handleAuthentication(
                  refreshResult.user.email,
                  refreshResult.user.id,
                  refreshResult.user.app_token,
                  refreshResult.user,
                  refreshResult.user.api_token.access_token,
                  refreshResult.user.api_token.expires_in,
                  refreshResult.user.api_token.refresh_token
                );

                this.userService.get().subscribe((userdata) => {});

                return next.handle(this.addAuthenticationToken(request));
              }),
              catchError((error) => {
                if (error.status == '403' || error.status === '401') {
                  this.authService.logout();
                }
                return throwError(() => error);
              })
            );

            /// return throwError(error);
            /*
          this.authService.refreshAccessToken().subscribe(
            (responseData) => {
              this.authService.apiToken = responseData?.api_token?.access_token;
              this.authService.refreshToken =
                responseData?.api_token?.refresh_token;
              let modifiedReq = request.clone({
                setHeaders: {
                  Authorization:
                    'Bearer ' + responseData?.api_token?.access_token,
                },
              });

              this.refreshTokenInProgress = false;
              return next.handle(modifiedReq);
            },
            (error) => {
              let modifiedReq = request.clone({
                setHeaders: {
                  Authorization: '',
                },
              });
              return next.handle(modifiedReq);
            }
          );*/
          }
        } else {
          return throwError(error);
        }
        // this is the API's idosyncratic way of telling us a session has expired...
        /*if (error.url === environment.apiUrl + '/app/login') {
          // if (request.url.includes('login')) {
          this.authService.autoLogin().subscribe(
            (resData) => {
              if (this.router.url.split('?')[0] === '/login') {
                if (this.authService.redirect) {
                  this.router.navigateByUrl(this.authService.redirect);
                } else {
                  this.router.navigate(['/dashboard']);
                }
              }
            },
            (errorMessage) => {
              this.authService.logout();
              this.cacheManagerService.clearAllCache();
            }
          );
          return throwError(error);
        } else {
          return throwError(error);
        }*/

        //return throwError(error);
        // }
      })
    );
  }

  addAuthenticationToken(request) {
    // Get access token from Local Storage
    const accessToken = this.authService.apiToken;

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
      return request;
    }

    // We clone the request, because the original request is immutable
    return request.clone({
      setHeaders: {
        Authorization: this.authService.apiToken,
      },
    });
  }
}
