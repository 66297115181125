<nav class="subnav">
    <h2><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'comment']"></fa-icon
      ></span> FORUM</h2>
</nav>
<div class="container" *ngIf="currentProgramme">
  
<form
class="comment-form bs-form mt-1"
name="commentForm"
method="post"
(ngSubmit)="onSubmit()"
#commentForm="ngForm"
>
<h2 class="forum-cat-title mb-3">
  CREATE TOPIC
</h2>
<div class="form-group">
<label for="category">CATEGORY</label>

<select 
class="form-control"
#category="ngModel"
id="category"
name="category"
ngModel
autocomplete="off"
required
aria-label="Enter a category">
    <option [value]="'general'">General</option>
    <option [value]="'exercise'" *ngIf="currentProgramme.config.exerciseProgramme !=1">Exercise</option>
    <option [value]="'diet'" *ngIf="currentProgramme.config.exerciseProgramme !=1">Diet</option>
    <option [value]="'health'" *ngIf="currentProgramme.config.exerciseProgramme !=1">Health</option>
</select>

<div
  class="alert alert-danger"
  *ngIf="category.touched && category.invalid"
>
  Please enter a category in the text field above.
</div>
</div>

<hr class="divider" />


<div class="form-group">
    <label for="subject">SUBJECT</label>
    <input
      type="text"
      class="form-control"
      #subject="ngModel"
      placeholder="Write a subject"
      id="subject"
      name="subject"
      ngModel
      autocomplete="off"
      required
      aria-label="Enter a subject"
    >
  
    <div
      class="alert alert-danger"
      *ngIf="subject.touched && subject.invalid"
    >
      Please enter a subject in the text field above.
    </div>
  </div>

<hr class="divider" />
<div class="form-group">
  <label for="comment">MESSAGE</label>
  <textarea
    rows="2"
    autosize
    type="text"
    class="form-control"
    #comment="ngModel"
    placeholder="Write your message..."
    id="comment"
    name="comment"
    ngModel
    autocomplete="off"
    required
    aria-label="Enter a comment"
    maxlength="5000"
    style="min-height: 90px; resize: none"
  ></textarea>

  <div
    class="alert alert-danger"
    *ngIf="comment.touched && comment.invalid"
  >
    Please enter a comment in the text area above, maximum 5,000
    characters.
  </div>
</div>
<div class="text-center">
  <button
  class="btn btn-primary"
  [disabled]="!commentForm.valid || isDisabled || checkEmpty(comment.value)"
  aria-label="Post comment"
  value="Post comment"
>
<span class="icon-wrap"
><fa-icon [icon]="['fas', 'comment-dots']"></fa-icon></span
> POST TOPIC
</button>
</div>

</form>
</div>