import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ProgrammeService } from 'src/app/services/programme.service';
import { UserAlbuminService } from 'src/app/services/user-albumin.service';
import { UserEgfrService } from 'src/app/services/user-egfr.service';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-m3-kidney',
  templateUrl: './m3-kidney.component.html',
  styleUrls: ['./m3-kidney.component.scss'],
})
export class M3KidneyComponent implements OnInit {
  currentUser;
  albumins: any = [];
  egfrs: any = [];
  showAllEntries = false;
  entryDateAlbumin = new Date();
  entryDateEgfr = new Date();


  @ViewChild('entryFormAlbumin', { static: false }) entryFormAlbumin: NgForm;
  @ViewChild('entryFormEgfr', { static: false }) entryFormEgfr: NgForm;

  constructor(
    private authService: AuthService,
    private programmeService: ProgrammeService,
    private router: Router,
    private userService: UserService,
    private userSettingsService: UserSettingsService,
    private modalService: BsModalService,
    public userAlbuminService:UserAlbuminService,
    public userEgfrService:UserEgfrService
  )
  {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {});

      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });

      forkJoin({
        userSettings: this.userSettingsService.fetchAll(),
        albumins: this.userAlbuminService.fetchAll(),
        egfrs: this.userEgfrService.fetchAll()
      }).subscribe((responseData) => {

        this.albumins = responseData.albumins;
        this.egfrs = responseData.egfrs;
      });
    }
  }

  getRagForSex(albumin: any, sex:any) {
    return this.userAlbuminService.getRagForSex(albumin,sex);
  }


  getEgfrRag(egfr: any) {}

  onDeleteAlbumin(albumin) {
    
    this.userAlbuminService
    .delete(albumin.user_albumin_id)
      .subscribe((responseData) => {
        //this.activityService.clearCache();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/kidney']));
      });
  }

  onSubmitAlbuminForm() {
    // this.entryForm.value.date
    
    if (!this.entryFormAlbumin.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something below",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.userAlbuminService
        .create(
          this.entryFormAlbumin.value.albumin,
          moment(this.entryDateAlbumin).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
           
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/kidney']));
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  

  onDeleteEgfr(egfr) {
    
    this.userEgfrService
    .delete(egfr.user_egfr_id)
      .subscribe((responseData) => {
        //this.activityService.clearCache();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/kidney']));
      });
  }

  onSubmitEgfrForm() {
    console.log('submitted');
    
    if (!this.entryFormEgfr.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something below",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.userEgfrService
        .create(
          this.entryFormEgfr.value.egfr,
          moment(this.entryDateEgfr).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
           
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/kidney']));
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  
}
