import { DecisionMakerService } from './../services/decision-maker.service';
import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/src/sweetalert2.js';
import * as moment from 'moment';
import { ProgrammeService } from '../services/programme.service';

@Component({
  selector: 'app-decision-maker',
  templateUrl: './decision-maker.component.html',
  styleUrls: ['./decision-maker.component.scss'],
})
export class DecisionMakerComponent implements OnInit {
  // custom message based on
  cessationText = 'smoking cessation';
  programmeText = 'programme';

  talkMsg =
    'Talking about the things you find to be a burden about  about your health';
  talkGp =
    'Talking to your GP – they may recommend medications or other appropriate services';
  commonBarriers = [
    {
      barrier: 'Money',
      overcome: [
        'Seek further support (e.g. GP, family & friends, health care team, chat forum)',
        'Set a reminder (e.g. phone, calendar, diary)',
        'Set a SMARTER goal – review SMARTER goal setting information if necessary ',
        'Review appropriate program content',
        'Consider an alternative option (e.g. involve the family, alternate or shorten sessions of physical activity, different location)',
        'Include relaxing activities (e.g. walking, meditation or a hobby)',
      ],
    },
    {
      barrier: 'Skills or knowledge on how to do it',
      overcome: [
        'Seek further support (e.g. GP, family & friends, health care team, chat forum)',
        'Set a reminder (e.g. phone, calendar, diary)',
        'Set a SMARTER goal – review SMARTER goal setting information if necessary ',
        'Review appropriate program content',
        'Consider an alternative option (e.g. involve the family, alternate or shorten sessions of physical activity, different location)',
        'Include relaxing activities (e.g. walking, meditation or a hobby)',
      ],
    },
    {
      barrier: 'Time',
      overcome: [
        'Seek further support (e.g. GP, family & friends, health care team, chat forum)',
        'Set a reminder (e.g. phone, calendar, diary)',
        'Set a SMARTER goal – review SMARTER goal setting information if necessary ',
        'Review appropriate program content',
        'Consider an alternative option (e.g. involve the family, alternate or shorten sessions of physical activity, different location)',
        'Include relaxing activities (e.g. walking, meditation or a hobby)',
      ],
    },
    {
      barrier: 'Motivation',
      overcome: [
        'Seek further support (e.g. GP, family & friends, health care team, chat forum)',
        'Set a reminder (e.g. phone, calendar, diary)',
        'Set a SMARTER goal – review SMARTER goal setting information if necessary ',
        'Review appropriate program content',
        'Consider an alternative option (e.g. involve the family, alternate or shorten sessions of physical activity, different location)',
        'Include relaxing activities (e.g. walking, meditation or a hobby)',
      ],
    },
    {
      barrier: 'Other commitments (work, kids etc)',
      overcome: [
        'Seek further support (e.g. GP, family & friends, health care team, chat forum)',
        'Set a reminder (e.g. phone, calendar, diary)',
        'Set a SMARTER goal – review SMARTER goal setting information if necessary ',
        'Review appropriate program content',
        'Consider an alternative option (e.g. involve the family, alternate or shorten sessions of physical activity, different location)',
        'Include relaxing activities (e.g. walking, meditation or a hobby)',
      ],
    },
    {
      barrier: 'Stress, illness, injury or pain',
      overcome: [
        'Seek further support (e.g. GP, family & friends, health care team, chat forum)',
        'Set a reminder (e.g. phone, calendar, diary)',
        'Set a SMARTER goal – review SMARTER goal setting information if necessary ',
        'Review appropriate program content',
        'Consider an alternative option (e.g. involve the family, alternate or shorten sessions of physical activity, different location)',
        'Include relaxing activities (e.g. walking, meditation or a hobby)',
      ],
    },
    {
      barrier: 'Habit',
      overcome: [
        'Seek further support (e.g. GP, family & friends, health care team, chat forum)',
        'Set a reminder (e.g. phone, calendar, diary)',
        'Set a SMARTER goal – review SMARTER goal setting information if necessary ',
        'Review appropriate program content',
        'Consider an alternative option (e.g. involve the family, alternate or shorten sessions of physical activity, different location)',
        'Include relaxing activities (e.g. walking, meditation or a hobby)',
      ],
    },
    {
      barrier: 'Energy',
      overcome: [
        'Seek further support (e.g. GP, family & friends, health care team, chat forum)',
        'Set a reminder (e.g. phone, calendar, diary)',
        'Set a SMARTER goal – review SMARTER goal setting information if necessary ',
        'Review appropriate program content',
        'Consider an alternative option (e.g. involve the family, alternate or shorten sessions of physical activity, different location)',
        'Include relaxing activities (e.g. walking, meditation or a hobby)',
      ],
    },
  ];
  decisionData = [
    {
      title: 'Increase physical activity levels',
      icon: 'chart-bar',
      how: [
        { title: 'Increase step count' },
        { title: 'Be more active' },
        { title: 'Reduce sitting time' },
        { title: 'Increase strengthening exercises' },
      ],
      barriers: this
        .commonBarriers /*[
                {
                    'barrier': 'Tiredness',
                    'overcome' : ['Short bursts of exercise', 'Accept support']
                },
                {
                    'barrier': 'Low Mood',
                    'overcome' : ['Realistic goals', 'Share feelings with others', 'Seek and accept support']
                },
                {
                    'barrier': 'The Kids',
                    'overcome' : ['Include kids in activities', 'Ask for help']
                },
                {
                    'barrier': 'Poor Weather',
                    'overcome' : ['Wear appropriate clothing', 'Indoor activities']
                },
                {
                    'barrier': 'Work',
                    'overcome' : ['Go for a walk during your lunch break', 'Use the stairs']
                },
                {
                    'barrier': 'Money',
                    'overcome' : ['Consider free activities']
                },
                {
                    'barrier': 'Motivation',
                    'overcome' : ['Set short term goals', 'Make the goals realistic', 'Seek peer support', 'Use the website forum']
                },
                
                {
                    'barrier': 'Stress',
                    'overcome' : ['Seek further support (GP, family & friends, health care team, chat forum)', 'Set a reminder (phone, calendar, diary)', 'Set a SMARTER goal – review SMARTER goal setting video if necessary ', 'Review appropriate program content']
                },
            ],*/,
    },
    {
      title: 'Make healthier food choices',
      icon: 'utensils',
      how: [
        { title: 'Reduce saturated fats' },
        { title: 'Reduce total fats' },
        { title: 'Increase fibre' },
        {
          title:
            'Reduce overall ' + environment.translations['calorie'] + ' intake',
        },
      ],
      barriers: this
        .commonBarriers /*[
                {
                    'barrier': 'Money',
                    'overcome' : ['Plan ahead','Consider alternatives to fresh food (Tinned/frozen)', 'Cook in bulk']
                },
                
                {
                    'barrier': 'Time',
                    'overcome' : ['Plan ahead']
                },
                
                {
                    'barrier': 'Understanding the right food choice',
                    'overcome' : ['Read relevant information /material on website']
                },
                
                {
                    'barrier': 'Motivation',
                    'overcome' : ['Set short term goals', 'Make the goals realistic', 'Seek peer support', 'Use the website forum']
                },
                
                {
                    'barrier': 'Stress',
                    'overcome' : ['Seek further support (GP, family & friends, health care team, chat forum)', 'Set a reminder (phone, calendar, diary)', 'Set a SMARTER goal – review SMARTER goal setting video if necessary ', 'Review appropriate program content']
                },
            ],*/,
    },
    {
      title: 'Reduce weight & waist',
      icon: 'female',
      how: [
        { title: 'Reduce saturated fats' },
        { title: 'Reduce total fats' },
        { title: 'Increase fibre' },
        {
          title:
            'Reduce overall ' + environment.translations['calorie'] + ' intake',
        },
        { title: 'Increase step count' },
        { title: 'Be more active' },
        { title: 'Reduce alcohol intake' },
        { title: 'Reduce portion sizes' },
        { title: 'Eat more fruit and vegetables' },
      ],
      barriers: this
        .commonBarriers /*[
                {
                    'barrier': 'Tiredness',
                    'overcome' : ['Short bursts of exercise', 'Accept support']
                },
                {
                    'barrier': 'Low Mood',
                    'overcome' : ['Realistic goals', 'Share feelings with others', 'Seek and accept support']
                },
                {
                    'barrier': 'The Kids',
                    'overcome' : ['Include kids in activities', 'Ask for help']
                },
                {
                    'barrier': 'Poor Weather',
                    'overcome' : ['Wear appropriate clothing', 'Indoor activities']
                },
                {
                    'barrier': 'Work',
                    'overcome' : ['Go for a walk during your lunch break', 'Use the stairs']
                },
                {
                    'barrier': 'Money',
                    'overcome' : ['Consider free activities']
                },
                {
                    'barrier': 'Motivation',
                    'overcome' : ['Set short term goals', 'Make the goals realistic', 'Seek peer support', 'Use the website forum']
                },
                
                {
                    'barrier': 'Time',
                    'overcome' : ['Plan ahead']
                },
                
                {
                    'barrier': 'Understanding the right food choice',
                    'overcome' : ['Read relevant information /material on website']
                },
                {
                    'barrier': 'Stress',
                    'overcome' : ['Seek further support (GP, family & friends, health care team, chat forum)', 'Set a reminder (phone, calendar, diary)', 'Set a SMARTER goal – review SMARTER goal setting video if necessary ', 'Review appropriate program content']
                },
            ],*/,
    },
    {
      title: 'Cholesterol',
      icon: 'heart',
      how: [
        { title: 'Be more active' },
        { title: 'Take prescribed medication' },
        { title: 'Eat less total fat' },
        { title: 'Reduce saturated fat' },
        { title: 'Eat more fruit and vegetables' },
      ],
      barriers: this
        .commonBarriers /* [
                {
                    'barrier': 'Motivation',
                    'overcome' : ['Set short term goals', 'Make the goals realistic', 'Seek peer support', 'Use the website chat']
                },
                {
                    'barrier': 'Understanding the right food choices',
                    'overcome' : ['Review content with this website']
                },
                {
                    'barrier': 'Medications',
                    'overcome' : ['Seek advice from GP or Pharmacist', 'Set reminders so as to not forget to take your medication']
                },
                {
                    'barrier': 'Stress',
                    'overcome' : ['Seek further support (GP, family & friends, health care team, chat forum)', 'Set a reminder (phone, calendar, diary)', 'Set a SMARTER goal – review SMARTER goal setting video if necessary ', 'Review appropriate program content']
                },
            ],*/,
    },
    {
      title: 'Smoking',
      icon: 'ban',
      how: [
        {
          title: 'Take nicotine replacement therapies – patches, inhaler, gum',
        },
        { title: 'Attend a ' + this.cessationText + ' ' + this.programmeText },
        { title: 'Set a quit date' },
        { title: 'Tell people and enlist their support' },
        { title: 'Try e-cigarettes' },
        { title: 'Contact national smokefree service' },
      ],
      barriers: this
        .commonBarriers /* [
                {
                    'barrier': 'Stress',
                    'overcome' : ['Relaxation techniques', 'Increase exercises']
                },
                {
                    'barrier': 'Peer pressure',
                    'overcome' : ['Ask for Help']
                },
                {
                    'barrier': 'Habit',
                    'overcome' : ['Seek advice e.g. local ' + cessationText + ' service']
                },
                
                
            ],*/,
    },
    {
      title: 'Blood Pressure',
      icon: 'tint',
      how: [
        { title: 'Be more active' },
        { title: 'Take prescribed medication' },
        { title: 'Lose weight and/or reduce waist measurement' },
        { title: 'Having less salt' },
        { title: 'Eat more fruit and vegetables' },
        { title: 'Reduce alcohol intake' },
        { title: 'Reducing portion sizes to assist with weight loss' },
      ],
      barriers: this
        .commonBarriers /*[
                {
                    'barrier': 'Money',
                    'overcome' : ['Seek further support (GP, family & friends, health care team, chat forum)', 'Set a reminder (phone, calendar, diary)', 'Set a SMARTER goal – review SMARTER goal setting video if necessary ', 'Review appropriate program content']
                },
                {
                    'barrier': 'Lack of skills or knowledge on how to do it',
                    'overcome' : ['Seek further support (GP, family & friends, health care team, chat forum)', 'Set a reminder (phone, calendar, diary)', 'Set a SMARTER goal – review SMARTER goal setting video if necessary ', 'Review appropriate program content']
                },
                {
                    'barrier': 'Lack of time',
                    'overcome' : ['Seek further support (GP, family & friends, health care team, chat forum)', 'Set a reminder (phone, calendar, diary)', 'Set a SMARTER goal – review SMARTER goal setting video if necessary ', 'Review appropriate program content']
                },
                {
                    'barrier': 'Lack of motivation',
                    'overcome' : ['Seek further support (GP, family & friends, health care team, chat forum)', 'Set a reminder (phone, calendar, diary)', 'Set a SMARTER goal – review SMARTER goal setting video if necessary ', 'Review appropriate program content']
                },
                {
                    'barrier': 'Other commitments (work, kids etc)',
                    'overcome' : ['Seek further support (GP, family & friends, health care team, chat forum)', 'Set a reminder (phone, calendar, diary)', 'Set a SMARTER goal – review SMARTER goal setting video if necessary ', 'Review appropriate program content']
                },
                {
                    'barrier': 'Stress',
                    'overcome' : ['Seek further support (GP, family & friends, health care team, chat forum)', 'Set a reminder (phone, calendar, diary)', 'Set a SMARTER goal – review SMARTER goal setting video if necessary ', 'Review appropriate program content']
                },
                
                
            ],*/,
    },
    {
      title: 'Blood Glucose',
      icon: 'medkit',
      how: [
        { title: 'Take prescribed medication ' },
        { title: 'Be more active' },
        { title: 'Limit foods high in added sugar' },
        { title: 'Eat smaller portions of carbohydrates' },
        { title: 'Include a low GI food at every meal' },
        { title: 'Eat less fat, particularly saturated fat' },
        { title: 'Lose weight and/or reduce waist measurement' },
      ],
      barriers: this
        .commonBarriers /*[
                {
                    'barrier': 'Money',
                    'overcome' : ['Seek further support (GP, family & friends, health care team, chat forum)', 'Set a reminder (phone, calendar, diary)', 'Set a SMARTER goal – review SMARTER goal setting video if necessary ', 'Review appropriate program content']
                },
                {
                    'barrier': 'Lack of skills or knowledge on how to do it',
                    'overcome' : ['Seek further support (GP, family & friends, health care team, chat forum)', 'Set a reminder (phone, calendar, diary)', 'Set a SMARTER goal – review SMARTER goal setting video if necessary ', 'Review appropriate program content']
                },
                {
                    'barrier': 'Lack of time',
                    'overcome' : ['Seek further support (GP, family & friends, health care team, chat forum)', 'Set a reminder (phone, calendar, diary)', 'Set a SMARTER goal – review SMARTER goal setting video if necessary ', 'Review appropriate program content']
                },
                {
                    'barrier': 'Lack of motivation',
                    'overcome' : ['Seek further support (GP, family & friends, health care team, chat forum)', 'Set a reminder (phone, calendar, diary)', 'Set a SMARTER goal – review SMARTER goal setting video if necessary ', 'Review appropriate program content']
                },
                {
                    'barrier': 'Other commitments (work, kids etc)',
                    'overcome' : ['Seek further support (GP, family & friends, health care team, chat forum)', 'Set a reminder (phone, calendar, diary)', 'Set a SMARTER goal – review SMARTER goal setting video if necessary ', 'Review appropriate program content']
                },
                {
                    'barrier': 'Stress',
                    'overcome' : ['Seek further support (GP, family & friends, health care team, chat forum)', 'Set a reminder (phone, calendar, diary)', 'Set a SMARTER goal – review SMARTER goal setting video if necessary ', 'Review appropriate program content']
                },
                
                
            ],*/,
    },
    {
      title: 'Your Wellbeing',
      icon: 'meh',
      how: [
        { title: 'Be more active' },
        { title: 'Hobbies (gardening, singing, knitting, painting)' },
        {
          title:
            'Seeking support from, and socialising with, family or friends',
        },
        { title: this.talkMsg },
        { title: 'Seeing a professional counsellor' },
        { title: this.talkGp },
      ],
      barriers: this.commonBarriers,
    },
  ];

  step = 1;
  focus = null;
  focusIndex = 0;
  importance = 5;
  confidence = 5;
  task = null;
  barrier = null;
  barrierIndex = 0;
  overcome = null;
  weeks = 4;
  otherOptions = {
    area: null,
    how: null,
    barrier: null,
    overcome: null,
  };
  date = new Date();
  minDate = new Date();
  currentProgramme;

  constructor(private decisionMakerService: DecisionMakerService, private programmeService: ProgrammeService) {}

  ngOnInit(): void {
    this.programmeService.currentProgramme.subscribe((programmeData) => {
      this.currentProgramme = programmeData;
      if (this.currentProgramme.config.exerciseProgramme == 1) {
        this.decisionData.splice(6, 1);
        this.decisionData.splice(5, 1);
        this.decisionData.splice(4, 1);
        this.decisionData.splice(3, 1);
        this.decisionData.splice(2, 1);
        this.decisionData.splice(1, 1);
      }
    });

    if (environment.defaultProgramme === 'BabySteps') {
      this.talkMsg =
        'Talking about the things that you find to be a burden about your health';
      this.talkGp = 'Talk to your GP for support';
    }
    // specific to AUS
    if (environment.country == 'AUS') {
      this.decisionData[4].how.splice(4, 1);
      this.decisionData[4].how[4].title = 'Call the Quitline on 13 78 48';
      this.programmeText = 'program';
      this.cessationText = 'Quit Smoking';
    }
  }

  setStep(step) {
    this.step = step;
  }
  checkImportance() {
    if (this.importance < 7) {
      Swal.fire({
        icon: 'info',
        title: 'How Important?',
        text: 'Think about how you could make this goal more important',
      });
    } else {
      Swal.fire({
        icon: 'info',
        title: 'How Important?',
        text: 'Think about what makes this goal important',
      });
    }

    this.step++;
  }

  checkConfidence() {
    if (this.confidence < 7) {
      Swal.fire({
        icon: 'info',
        title: 'How confident are you?',
        text:
          'What might this tell you about your confidence to carry out your plan? A score of less than 7 often means that you do not feel confident. What could you do to increase your confidence? This could be just a small change to what you said you were going to do.',
      });
    }
    this.step++;
  }

  importanceUp() {
    if (this.importance < 10) {
      this.importance = +this.importance + +1;
    }
  }

  save() {
    this.decisionMakerService
      .create(
        this.focus,
        this.importance,
        this.task,
        this.barrier,
        this.overcome,
        this.weeks,
        moment(this.date).format('YYYY-MM-DD'),

        this.confidence
      )
      .subscribe(
        (responseData) => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Decision saved',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            toast: true,
          });
        },
        (error) => {
          console.log(error);
        }
      );
  }

  importanceDown() {
    if (this.importance > 1) {
      this.importance = this.importance - 1;
    }
  }

  confidenceUp() {
    if (this.confidence < 10) {
      this.confidence = +this.confidence + +1;
    }
  }
  confidenceDown() {
    if (this.confidence > 1) {
      this.confidence = this.confidence - 1;
    }
  }

  weeksUp() {
    if (this.weeks < 10) {
      this.weeks = +this.weeks + +1;
    }
  }
  weeksDown() {
    if (this.weeks > 1) {
      this.weeks = this.weeks - 1;
    }
  }

  prev() {
    this.step--;
  }
  next() {
    this.step++;
  }
  restart() {
    this.step = 1;
    this.focus = null;
    this.focusIndex = 0;
    this.importance = 5;
    this.task = null;
    this.barrier = null;
    this.barrierIndex = 0;
    this.overcome = null;
    this.date = new Date();
  }

  // date picker stuff
  // date picker

  open1() {
    // this.popup1.opened = true;
  }
  /* 
                  
                  this.format = 'dd/MM/yyyy';
                this.date = new Date();

                this.popup1 = {
                    opened: false
                  };
                  this.dateOptions = {
                    formatYear: 'yyyy',
                    minDate: new Date(),
                    startingDay: 1,
                    placement : 'right',
                    showWeeks : false
                  };
                  */
}
