<div>
    <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
    <div *ngIf="boosterSessionStep && boosterSession">
      <nav class="subnav">
          <a class="subnav-prev" *ngIf="prevSession" [routerLink]="['/booster-sessions', 'index', prevSession.booster_session_id]"><span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span></a>
          <h2 class-="uppercase-title">
            <span class="icon-wrap"><fa-icon [icon]="['fas', 'bolt']"></fa-icon></span>
            {{ boosterSessionProgramme?.booster_session_title }}
          </h2>
          <a *ngIf="nextSession" [routerLink]="['/booster-sessions', 'index', nextSession.booster_session_id]" class="subnav-next"
            ><span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span></a>
        </nav>
      
     
      <div class="reading-material-page-header booster-session-step-header">
        {{ boosterSessionStep.sort_order }} | {{ boosterSessionStep.title }}
      </div>
      <div class="booster-session-content">
        <div [ngSwitch]="boosterSessionStep.booster_session_step_type_id">
          <div class="container bg-white booster-session-step-content">
            <div *ngSwitchCase="1">
              <div [innerHTML]="boosterSessionStep.content | safeHtml"></div>
            </div>
            <div *ngSwitchCase="2">
              <app-step-video [content]="boosterSessionStep.content"></app-step-video>
             
            </div>
            <div *ngSwitchCase="3">
              <div *ngIf="dragAndDrop">
              <div [ngSwitch]="dragAndDrop.template">
                <div *ngSwitchCase="'selection'">
                  <app-step-drag-and-drop-selection [dragAndDrop]="dragAndDrop" [dragItems]="dragItems"></app-step-drag-and-drop-selection>
                  
                </div>
                <div *ngSwitchCase="'ranking'">
                  
                  <app-step-drag-and-drop-ranking [dragAndDrop]="dragAndDrop" [dragItems]="dragItems"></app-step-drag-and-drop-ranking>
                </div>
                <div *ngSwitchDefault>
                  <app-step-drag-and-drop-default [dragAndDrop]="dragAndDrop" [dragItems]="dragItems" [dropItems]="dropItems"></app-step-drag-and-drop-default>
  
                 
                </div>
              </div>
            </div>
            </div>
            <div *ngSwitchCase="4">
              <app-step-quiz [quizId]="boosterSessionStep.booster_session_step_type_ref_id"></app-step-quiz>
            </div>
            <div *ngSwitchCase="5">
              
              <app-step-questionnaire [questionnaireId]="boosterSessionStep.booster_session_step_type_ref_id"></app-step-questionnaire>
            </div>
            <div class="clear"></div>
            <hr class="divider" />
            <div>
              <div class="prev-next row">
                <div class="col-6">
                  <a *ngIf="prevStep" [routerLink]="['/booster-session-steps', 'view', prevStep.booster_session_step_id]"><span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span> PREV</a>
                </div>
                <div class="col-6 text-right">
                  <a *ngIf="nextStep" [routerLink]="['/booster-session-steps', 'view', nextStep.booster_session_step_id]">NEXT <span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  