import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-vo2max-table',
  templateUrl: './vo2max-table.component.html',
  styleUrls: ['./vo2max-table.component.scss']
})
export class Vo2maxTableComponent implements OnInit {

  @Input() gender: string;
  @Input() vo2max: number;
  @Input() age: number;

  constructor() { }

  ngOnInit(): void {
  }

}
