import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-programme',
  templateUrl: './no-programme.component.html',
  styleUrls: ['./no-programme.component.scss']
})
export class NoProgrammeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
