<nav class="subnav">
  <a [routerLink]="['/settings']" class="subnav-prev"
    ><span><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
  ></a>
  <h2>
    <span><fa-icon [icon]="['fas', 'cog']"></fa-icon></span> ACCOUNT SETTINGS
  </h2>
</nav>
<br />
<div class="container" *ngIf="currentUser">
  <form
    class="bs-form bs-form"
    name="profileForm"
    method="post"
    (ngSubmit)="onSubmit()"
    #profileForm="ngForm"
  >
    <div class="form-group">
      <label for="email">EMAIL ADDRESS</label>
      <input
        type="email"
        name="email"
        class="form-control input-tracker"
        placeholder=""
        id="email"
        #email="ngModel"
        [ngModel]="currentUser.email"
        autocomplete="off"
        required
        [attr.disbaled]="environment.country == 'AUS' ? '' : null"
        [attr.readonly]="environment.country == 'AUS' ? '' : null"
      />
      <p>
        <br /><em *ngIf="environment.country == 'AUS'"
          >In order to change an email address a request should be made through
          the Chat &gt; Technical Support function</em
        >
      </p>
    </div>
    <hr class="divider" />
    <div class="form-group">
      <div class="pull-right">
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="enableNotifications" name="paused" [value]="notification_settings.enabled" [(ngModel)]="notification_settings.enabled">
          <label class="custom-control-label" for="enableNotifications">Allow Notifications</label>
        </div>
      </div>
      <label for="notifications">EMAIL NOTIFICATIONS</label>

      <div class="form-group" [ngStyle]="notification_settings.enabled?{'opacity': '1'} : {'opacity': '0.2'}">
        <table class="table">
          <tr>
            <td>
              <input
                type="hidden"
                name="notification_settings.login"
                id="notification_settings.login"
                [ngModel]="notification_settings.login"
                required
              />
              Login Reminders
            </td>
            <td>
              <div class="row">
                <div class="col-6">
                  <button
                  class="btn btn-block"
                    [ngClass]="{'btn-primary': +notification_settings.login === 1,'btn-default': +notification_settings.login!==1}"
                    (click)="notification_settings.login =1"
                    type="button"
                  >
                    YES
                  </button>
                </div>
                <div class="col-6">
                  <button
                  class="btn btn-block"
                  [ngClass]="{'btn-primary': +notification_settings.login === 0,'btn-default': +notification_settings.login!==0}"
                    (click)="notification_settings.login =0"
                    type="button"
                  >
                    NO
                  </button>
                </div>
              </div>
              <p class="small mt-10">
                We send you reminders if you haven't logged in for a while.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <input
                type="hidden"
                name="notification_settings.challenges"
                id="notification_settings.challenges"
                ng-model="notification_settings.challenges"
                required
              />
              Challenge Notifications
            </td>
            <td>
              <div class="row">
                <div class="col-6">
                  <button
                  class="btn btn-block"
                  [ngClass]="{'btn-primary': +notification_settings.challenges === 1,'btn-default': +notification_settings.challenges!==1}"
                    (click)="notification_settings.challenges =1"
                    type="button"
                  >
                    YES
                  </button>
                </div>
                <div class="col-6">
                  <button
                  class="btn btn-block"
                  [ngClass]="{'btn-primary': +notification_settings.challenges === 0,'btn-default': +notification_settings.challenges!==0}"
                    (click)="notification_settings.challenges =0"
                    type="button"
                  >
                    NO
                  </button>
                </div>
              </div>
              <p class="small mt-10">
                We send you a reminder if a new challenge is about to start or
                end.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <input
                type="hidden"
                name="notification_settings.awards"
                id="notification_settings.awards"
                ng-model="notification_settings.awards"
                required
              />
              Awards Notifications
            </td>
            <td>
              <div class="row">
                <div class="col-6">
                  <button
                  class="btn btn-block"
                  [ngClass]="{'btn-primary': +notification_settings.awards === 1,'btn-default': +notification_settings.awards!==1}"
                    (click)="notification_settings.awards =1"
                    type="button"
                  >
                    YES
                  </button>
                </div>
                <div class="col-6">
                  <button
                  class="btn btn-block"
                  [ngClass]="{'btn-primary': +notification_settings.awards === 0,'btn-default': +notification_settings.awards!==0}"
                    (click)="notification_settings.awards =0"
                    type="button"
                  >
                    NO
                  </button>
                </div>
              </div>
              <p class="small mt-10">
                We send you an email if you win an award in the Weekend, Monday
                to Friday or Global leaderboard challenges.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <input
                type="hidden"
                name="notification_settings.comment"
                id="notification_settings.comment"
                ng-model="notification_settings.comments"
                required
              />
              New Comments
            </td>
            <td>
              <div class="row">
                <div class="col-6">
                  <button
                  class="btn btn-block"
                  [ngClass]="{'btn-primary': +notification_settings.comments === 1,'btn-default': +notification_settings.comments!==1}"
                    (click)="notification_settings.comments =1"
                    type="button"
                  >
                    YES
                  </button>
                </div>
                <div class="col-6">
                  <button
                  class="btn btn-block"
                  [ngClass]="{'btn-primary': +notification_settings.comments === 0,'btn-default': +notification_settings.comments!==0}"
                    (click)="notification_settings.comments =0"
                    type="button"
                  >
                    NO
                  </button>
                </div>
              </div>
              <p class="small mt-10">
                We send you an email if there are new posts in the forum
                (weekly), if you’ve had replies to your own comments or if
                someone has liked one of your comments.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <input
                type="hidden"
                name="notification_settings.comment"
                id="notification_settings.stats"
                ng-model="notification_settings.stats"
                required
              />
              Your Weekly Stats
            </td>
            <td>
              <div class="row">
                <div class="col-6">
                  <button
                  class="btn btn-block"
                  [ngClass]="{'btn-primary': +notification_settings.stats === 1,'btn-default': +notification_settings.stats!==1}"
                    (click)="notification_settings.stats =1"
                    type="button"
                  >
                    YES
                  </button>
                </div>
                <div class="col-6">
                  <button
                  class="btn btn-block"
                  [ngClass]="{'btn-primary': +notification_settings.stats === 0,'btn-default': +notification_settings.stats!==0}"
                    (click)="notification_settings.stats =0"
                    type="button"
                  >
                    NO
                  </button>
                </div>
              </div>
              <p class="small mt-10">
                We send you a weekly email with your steps summary. This is only
                sent if you are tracking steps.
              </p>
            </td>
          </tr>
        </table>

        <!-- Programme specific -->
        <div *ngIf="currentProgramme.config.exerciseProgramme">
          <div class="row">
            <div class="col">
              <p>
                The app will automatically send you activity notifications on
                stepping, workouts and inactivity (via email or phone [if
                android]). Please select how often you would like to receive these
                notifications You can select off if you do NOT want to receive
                notifications on any of the following topic areas.
              </p>
  
              <!-- Stepping notifications not to appear if user selects mobility status 1E, 1F 1D+2a+3N or 1D+2b+3N -->
              <div
                class="form-group"
                [hidden]="
                  currentUser.mobility1 == 'e' ||
                  currentUser.mobility1 == 'f' ||
                  (currentUser.mobility1 == 'd' &&
                    currentUser.mobility2 == '1' &&
                    currentUser.mobility3 == '0') ||
                  (currentUser.mobility1 == 'd' &&
                    currentUser.mobility2 == '0' &&
                    currentUser.mobility3 == '0')
                "
              >
                <label for="notifications1"  class="uppercase"
                  >Stepping</label
                >
                <app-notification-setting-control
                  [notificationData]="notification_settings.stepping"
                  [notificationName]="'Stepping'"
                ></app-notification-setting-control>
  
               
              </div>
  
              <div class="form-group">
                <label for="notifications1"  class="uppercase"
                  >Workouts</label
                >
                <app-notification-setting-control
                  [notificationData]="notification_settings.workouts"
                  [notificationName]="'Workouts'"
                ></app-notification-setting-control>
  
               
              </div>
  
              <div class="form-group">
                <label for="notifications3"  class="uppercase"
                  ><span [hidden]="
                  currentUser.mobility1 == 'e' ||
                  currentUser.mobility1 == 'f' ||
                  (currentUser.mobility1 == 'd' &&
                    currentUser.mobility2 == '1' &&
                    currentUser.mobility3 == '0') ||
                  (currentUser.mobility1 == 'd' &&
                    currentUser.mobility2 == '0' &&
                    currentUser.mobility3 == '0')
                ">Sitting</span><span [hidden]="!(
                currentUser.mobility1 == 'e' ||
                currentUser.mobility1 == 'f' ||
                (currentUser.mobility1 == 'd' &&
                  currentUser.mobility2 == '1' &&
                  currentUser.mobility3 == '0') ||
                (currentUser.mobility1 == 'd' &&
                  currentUser.mobility2 == '0' &&
                  currentUser.mobility3 == '0'))
              ">Sedentary behaviour</span></label
                >
                <app-notification-setting-control
                  [notificationData]="notification_settings.sitting"
                  [notificationName]="'Sitting'"
                ></app-notification-setting-control>
  
                
              </div>
            </div>
            </div>
        </div>

        <p class="small">
          <em
            >Please note there are emails which are fundamental to the
            functioning of the site including the welcome email and reminder to
            review your goals that cannot be managed from this page.
          </em>
        </p>

        <p><br />By selecting yes you agree to receive email notifications.</p>
      </div>
    </div>
    <hr class="divider" />
    <div class="form-group" *ngIf="environment.country == 'AUS'">
      <label for="marketing">DIABETES NEWS EMAILS</label>
      <div class="form-group">
        <input
          type="hidden"
          name="marketing"
          id="marketing"
          ng-model="regDetails.agree_marketing"
          required
        />
        <div class="row">
          <div class="col-6 col-md-2">
            <button
            class="btn-block btn"
              [ngClass]="{'btn-primary': +currentUser.agree_marketing===1, 'btn-default': +currentUser.agree_marketing!==1}"
              (click)="regDetails.agree_marketing =1"
              type="button"
            >
              YES
            </button>
          </div>
          <div class="col-6 col-md-2">
            <button
            class="btn-block btn"
              [ngClass]="{'btn-primary': +currentUser.agree_marketing===0, 'btn-default': +currentUser.agree_marketing!==0}"
              (click)="regDetails.agree_marketing =0"
              type="button"
            >
              NO
            </button>
          </div>
        </div>
        <p>
          <br />By selecting yes you agree to receive more information from NDSS
          Agents about other ways to manage diabetes or stay healthy.
        </p>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <button class="btn btn-primary btn-white"
            ><i class="fa fa-check"></i> SAVE DETAILS</button>
        </div>
      </div>
    </div>
  </form>
  <form
    class="bs-form bs-form"
    name="passwordForm"
    method="post"
    (ngSubmit)="onChangePassword()"
    #passwordForm="ngForm"
    *ngIf="currentUser.is_admin!=1"
  >
    <hr class="divider" />
    <div class="form-group">
      <label for="old_password">CURRENT PASSWORD</label>
      <input
        type="password"
        name="old_password"
        class="form-control input-tracker"
        placeholder=""
        id="old_password"
        #old_password="ngModel"
        [(ngModel)]="passwords.old_password"
        autocomplete="off"
        required
        ng-required
      />
      <div
        class="alert alert-danger"
        *ngIf="
          old_password.touched && old_password.invalid
        "
      >
        Please enter a valid password.
      </div>
    </div>

    <div class="form-group">
      <label for="password">NEW PASSWORD</label>
      <p>
        Minimum ten characters, at least one uppercase letter, one lowercase
        letter, one number and one special character ($@!%*?&amp;#)
      </p>
      <input
        type="password"
        name="password"
        class="form-control input-tracker"
        placeholder=""
        id="password"
        #password="ngModel"
        [(ngModel)]="passwords.password"
        autocomplete="off"
        required
        [pattern]="passwordPattern"
      />
      <div
        class="alert alert-danger"
        *ngIf="password.touched && password.invalid"
      >
        Please enter a valid password.
      </div>
    </div>
    <div class="form-group">
      <label for="password_confirm">CONFIRM NEW PASSWORD</label>
      <input
        type="password"
        name="password_confirm"
        class="form-control input-tracker"
        placeholder=""
        id="password_confirm"
        #password_confirm="ngModel"
        [(ngModel)]="passwords.password_confirm"
        autocomplete="off"
        required
        ng-required
        [equalTo]="password"
      />
      <div
        class="alert alert-danger"
        *ngIf="
          (password_confirm.touched &&
           password_confirm.invalid) ||
          (password_confirm.touched &&
            password_confirm.errors?.equalTo)
        "
      >
        Your passwords do not match.
      </div>
    </div>

    <hr class="divider" />
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <button
           
            class="btn btn-primary btn-white"
            ><i class="fa fa-check"></i> CHANGE PASSWORD</button
          >
        </div>
      </div>
    </div>
    <br /><br />
  </form>
  <p *ngIf="currentUser.is_admin == 1">
    <br /><br />Admin users can change their password in the admin area
  </p>
</div>
