<div
  class="bg-full"
  ng-style="{ 'background-image': 'url(/assets/img/bg-login.jpg)' }"
></div>
<div class="container">
  <div class="row">
    <div class="col-md-12 col-lg-8 col-lg-offset-2">
      <div class="well well-default text-center">
        <button
          class="btn btn-icon-circle btn-icon-circle-primary stuck-top-left"
          ng-show="loginPanel"
          ng-click="loginPanel=false"
        >
          <i class="fa fa-chevron-circle-left"></i>
        </button>
        <a class="logo-text">
          <span class="logo-text"
            ><img
              src="./assets/img/logo-my-desmond.png"
              height="60"
              *ngIf="environment.country != 'AUS'" /><img
              src="./assets/img/logo-my-desmond-aus-b.png"
              height="60"
              *ngIf="environment.country == 'AUS'" /></span></a
        ><br /><br />

        <div *ngIf="environment.defaultProgramme == 'BabySteps'">
          <span class="logo-text mt-20"
            ><img src="./assets/img/logo-baby-steps.png" height="24" /></span
          ><br /><br />
          <div class="row">
            <div class="col-md-6 col-md-offset-3">
              <p class="pt-20 pb-20" style="font-size: 16px">
                This web-based program has been designed to support women who
                have previously been diagnosed with gestational diabetes in
                making healthy lifestyle choices
              </p>
            </div>
          </div>
        </div>

        <div ng-show="!loginPanel">
          Please select an option…
          <div>
            <button
              [routerLink]="['/login']"
              class="btn btn-primary btn-lg"
              value="Sign In"
            >
              SIGN IN
            </button>
            <button
              [routerLink]="['/register']"
              class="btn btn-primary btn-lg"
              value="Register"
            >
              REGISTER
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
