import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { WorkoutsService } from 'src/app/services/workouts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import Swal from 'sweetalert2/src/sweetalert2.js';

@Component({
  selector: 'app-strength-view',
  templateUrl: './strength-view.component.html',
  styleUrls: ['./strength-view.component.scss'],
})
export class StrengthViewComponent implements OnInit {
  isFetching = false;
  workoutId;
  currentProgramme;
  currentUser;
  userWorkout;
  userExercises;
  areas = ['Upper Body', 'Lower Body', 'Core', 'Wrist'];

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private workoutsService: WorkoutsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.isFetching = true;
    this.route.params.subscribe((params) => {
      this.isFetching = true;

      this.workoutId = this.route.snapshot.params.id;

      // are we logged in?
      const user = this.authService.user.getValue();
      if (user) {
        this.programmeService.currentProgramme.subscribe((programmeData) => {
          this.currentProgramme = programmeData;
          if (this.currentProgramme) {
            forkJoin({
              workout: this.workoutsService.fetch(this.workoutId),
              exercises: this.workoutsService.fetchExercises(this.workoutId),
            }).subscribe(
              (responseData) => {
                this.isFetching = false;
                this.userWorkout = responseData.workout;
                this.userExercises = responseData.exercises;
              },
              (error) => {
                this.isFetching = false;
              }
            );
          }
        });
        this.userService.userData.subscribe((userDataResponse) => {
          this.currentUser = userDataResponse;
        });
      }
    });
  }

  onDeleteExercise(exerciseId) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to undo this",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it',
    }).then((result) => {
      if (result.value) {
        this.workoutsService.deleteExercise(exerciseId).subscribe(
          (delereResponseData) => {
            Swal.fire('Deleted', 'Your record has been deleted.', 'success');

            forkJoin({
              workout: this.workoutsService.fetch(this.workoutId),
              exercises: this.workoutsService.fetchExercises(this.workoutId),
            }).subscribe(
              (responseData) => {
                this.isFetching = false;
                this.userWorkout = responseData.workout;
                this.userExercises = responseData.exercises;
              },
              (error) => {
                this.isFetching = false;
              }
            );
          },
          (error) => {
            this.isFetching = false;
            Swal.fire('Error!', 'There was an error.', 'error');
          }
        );
      }
    });
  }
}
