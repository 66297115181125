import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordinal',
})
export class OrdinalPipe implements PipeTransform {
  transform(value: string, includeNumber?: boolean): string {
    if (!value) {
      return '';
    }

    const s = ['th', 'st', 'nd', 'rd'];
    const ordinal = s[(+value - 20) % 10] || s[+value] || s[0];
    if (includeNumber !== false) {
      includeNumber = true;
    }
    if (includeNumber) {
      return value + ordinal;
    } else {
      return ordinal;
    }
  }
}
