<nav class="subnav">
  <a [routerLink]="['/about']" class="subnav-prev">
    <span><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
  ></a>
  <h2>
    <span><fa-icon [icon]="['fas', 'gavel']"></fa-icon></span> LEGAL
  </h2>
</nav>
<div class="container container-pad">
  <!-- Nav tabs -->
  <ul class="nav nav-tabs nav-tabs-duo nav-tabs-skinny justify-content-center" role="tablist">
    <li role="presentation" [ngClass]="{ active: col1 }">
      <a
        aria-controls="percent"
        role="tab"
        data-toggle="tab"
        (click)="col1 = true; col2 = false; col3 = false"
        >TERMS OF USE</a
      >
    </li>
    <li role="presentation" [ngClass]="{ active: col2 }">
      <a
        aria-controls="step-count"
        role="tab"
        data-toggle="tab"
        (click)="col2 = true; col1 = false; col3 = false"
        >PRIVACY/COOKIE POLICY</a
      >
    </li>
    <li role="presentation" [ngClass]="{ active: col3 }">
      <a
        aria-controls="step-count"
        role="tab"
        data-toggle="tab"
        (click)="col3 = true; col2 = false; col1 = false"
        >ACCEPTABLE USE POLICY</a
      >
    </li>
  </ul>

  <!-- Tab panes -->
  <div class="tab-content">
    <div role="tabpanel"   id="terms" *ngIf="col1">
      <div [innerHtml]="cms.terms_of_use.content | safeHtml" *ngIf="cms"></div>
    </div>
    <div role="tabpanel"   id="privacy" *ngIf="col2">
      <div
        [innerHtml]="cms.privacy_policy.content | safeHtml"
        *ngIf="cms"
      ></div>
    </div>
    <div role="tabpanel" id="use" *ngIf="col3">
      <div
        [innerHtml]="cms.acceptable_use_policy.content | safeHtml"
        *ngIf="cms"
      ></div>
    </div>
  </div>
</div>
