import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {
  error = new Subject<string>();
  slug = '/user_appointments';
  resourceName = 'user_appointments';
  allAppointments?: any;
  nextAppointment?:any;
  appointmentsBetween?: any;
  cacheTime = new Date();
  cacheLifetime = 1 * 60 * 1000;
  
  constructor(private http: HttpClient) {}

  fetchAll(): any {
    const now = new Date();
    if (
      !this.allAppointments ||
      Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime
    ) {
      this.cacheTime = new Date();
      this.allAppointments = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: any[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allAppointments;
  }
  getNextAppointment(): any {
    const now = new Date();
    if (
      !this.nextAppointment ||
      Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime
    ) {
      this.cacheTime = new Date();
      this.nextAppointment = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            let _nextAppointment: any = null;
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                if(moment(item.appointment_date) > moment()){
                  _nextAppointment = item;
                }
              }
            );
            return _nextAppointment;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.nextAppointment;
  }
  clearCache() {
    this.allAppointments = null;
    this.appointmentsBetween = null;
  }

}
