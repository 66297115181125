import { take } from 'rxjs/operators';
import { UserService } from './../services/user.service';
import { AuthService } from './../auth/auth.service';
import { environment } from './../../environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  regDetails = {
    email: null,
    birth_country: null,
    language: null,
    smoker: null,
    exercise: null,
    attendedBefore: 0,
    gender: null,
    weight: null,
    weightStone: null,
    weightLbs: null,
    height: null,
    heightFeet: null,
    heightInches: null,
    glucosePercent: null,
    glucose: null,
    password: '',
    terms: 0,
    agree_marketing: 0,
    origin: null,
    first_name: null,
    last_name: null,
    // research_consent:1,
    mobility1: null,
    hospital_care: null,
  };
  stepSubmitted = {};
  step = 1;
  environment = environment;
  codeError;
  isFetching;
  unitMode = 'metric';
  regProgrammeConfig;
  regProgramme;
  hba1cUnitMode = 'percent';
  code;
  teamNames;
  team_id;
  bsValue = new Date('1960/01/01');
  minDob = moment().subtract('year', 110).toDate();
  maxDob = moment().subtract('year', 16).toDate();
  noSpacesPattern = /.*[^ ].*/;

  passwordPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#])[A-Za-z\d$=@$!%*?&#]{10,}$/;

  currentUser = null;

  @ViewChild('regForm', { static: false }) regForm: NgForm;

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // is there a code?
    this.route.queryParams.subscribe((params) => {
      this.code = params['code'];
    });
    // is there a user logged in?
    this.authService.user.subscribe((user) => {
      if (user && this.step === 1) {
        this.router.navigate(['/dashboard']);
      }
    });
    // check for NDSS data..
    this.route.data.subscribe((data) => {
      if (data.ndss) {
        if (data.ndss === true) {
          this.isFetching = true;
          // check ndss
          this.authService.checkNdss().subscribe((responseData) => {
            const status = responseData.status;
            if (status === 'SUCCESS') {
              this.regProgramme = responseData['programme'];
              this.step = 2;
              this.regProgrammeConfig = [];
              this.regDetails.first_name = responseData.first_name;
              this.regDetails.last_name = responseData.last_name;
              this.regDetails.email = responseData.email;
              this.isFetching = false;
            } else {
              this.codeError = true;
              this.isFetching = false;
              this.step = 1;
            }
            this.isFetching = false;
          });
        }
      }
    });
  }

  onSubmit() {}

  checkCode(code) {
    this.isFetching = true;

    this.authService.checkCode(code).subscribe(
      (responseData) => {
        const status = responseData['status'];
        this.code = code;
        this.regProgrammeConfig = responseData['programmeConfig'];
        this.regProgramme = responseData['programme'];
        // console.log(this.regProgrammeConfig);

        this.step = 2;
        this.regDetails.email = responseData['email'];
        // get the team names
        this.team_id = responseData['team_id'];
        if (responseData['name_options']) {
          this.teamNames = responseData['name_options'].split(',');
        }

        // is it an exercise programme?
        if (this.regProgrammeConfig.exerciseProgramme == 1) {
          this.router.navigate([
            '/register-ep'],
            { queryParams: { code: code } },
          );
        }

        this.isFetching = false;
        this.isFetching = false;
      },
      (error) => {
        this.codeError = error.error.detail;
        this.isFetching = false;
        this.step = 1;
      }
    );
  }

  checkReg(regStep) {
    this.isFetching = true;
    this.stepSubmitted[regStep] = true;
    switch (regStep) {
      case 2:
        // check the screen name
        this.authService
          .checkScreenName(this.regForm.value.screen_name)
          .subscribe(
            (responseData) => {
              this.isFetching = false;
              const status = responseData.status;
              if (status == 'SUCCESS') {
                let pass2 = false;
                if (this.environment.country == 'UK') {
                  if (
                    !this.regForm.controls.post_code.invalid &&
                    !this.regForm.controls.ethnicity_id.invalid &&
                    !this.regForm.controls.dob.invalid &&
                    this.regDetails.gender != null &&
                    !this.regForm.controls.last_name.invalid &&
                    !this.regForm.controls.first_name.invalid &&
                    !this.regForm.controls.screen_name.invalid
                  ) {
                    this.step = 3;
                    pass2 = true;
                  }
                }
                if (this.environment.country === 'AUS') {
                  if (
                    this.regForm.value.birth_country === 'other' &&
                    !this.regForm.value.birth_country_other
                  ) {
                    this.regForm.controls.birth_country.clearValidators();
                  }
                  if (
                    this.regForm.value.birth_country === 'other' &&
                    this.regForm.value.birth_country_other
                  ) {
                    this.regForm.controls.birth_country.setValidators([
                      Validators.required,
                    ]);
                  }

                  if (
                    this.regForm.value.language === 'other' &&
                    !this.regForm.value.language_other
                  ) {
                    this.regForm.controls.language.clearValidators();
                  }
                  if (
                    this.regForm.value.language == 'other' &&
                    this.regForm.value.language_other
                  ) {
                    this.regForm.controls.language.setValidators([
                      Validators.required,
                    ]);
                  }

                  if (
                    !this.regForm.controls.post_code.invalid &&
                    !this.regForm.controls.dob.invalid &&
                    this.regDetails.gender != null &&
                    !this.regForm.controls.last_name.invalid &&
                    !this.regForm.controls.first_name.invalid &&
                    !this.regForm.controls.screen_name.invalid &&
                    !this.regForm.controls.birth_country.invalid &&
                    !this.regForm.controls.language.invalid &&
                    !this.regForm.controls.language_other.invalid &&
                    this.regDetails.origin != null
                  ) {
                    this.step = 3;
                    pass2 = true;
                    window.scrollTo(0, 0);
                  }
                }
                if (pass2 == false) {
                  Swal.fire({
                    title: "We notice you've missed something",
                    text: 'Please check the form for information that has been missed',
                    icon: 'error',
                  });
                }
              } else {
                this.regForm.controls['screen_name'].setErrors({
                  nametaken: true,
                });
              }
            },
            (error) => {
              this.isFetching = false;
              this.regForm.controls['screen_name'].setErrors({
                nametaken: true,
              });
            }
          );

        break;

      case 3:
        // work out the weight

        if (this.regProgrammeConfig && this.regProgrammeConfig.gu1de != 1) {
          if (
            !this.regForm.controls.weight.invalid &&
            !this.regForm.controls.height.invalid &&
            !this.regForm.controls.smoker.invalid &&
            !this.regForm.controls.glucose.invalid &&
            !this.regForm.controls.cholesterol.invalid &&
            !this.regForm.controls.ldl.invalid &&
            !this.regForm.controls.hdl.invalid &&
            !this.regForm.controls.exercise.invalid &&
            !this.regForm.controls.mobility1.invalid
          ) {
            this.step = 4;
            this.isFetching = false;
            window.scrollTo(0, 0);
          } else {
            this.isFetching = false;
            Swal.fire({
              title: "We notice you've missed something",
              text: 'Please check the form for information that has been missed',
              icon: 'error',
            });
          }
        } else if (
          this.regProgrammeConfig &&
          this.regProgrammeConfig.gu1de == 1
        ) {
          if (
            !this.regForm.controls.gu1de_reason.invalid &&
            !this.regForm.controls.attendedBefore.invalid &&
            !this.regForm.controls.since_participated.invalid &&
            !this.regForm.controls.since_diagnosed.invalid &&
            !this.regForm.controls.hospital_care.invalid
          ) {
            this.step = 4;
            this.isFetching = false;
            window.scrollTo(0, 0);
          } else {
            this.isFetching = false;
            Swal.fire({
              title: "We notice you've missed something",
              text: 'Please check the form for information that has been missed',
              icon: 'error',
            });
          }
        }

        break;

      case 4:
        let attendedBeforeInvalid = false;
        if (this.regForm.controls.attendedBefore) {
          attendedBeforeInvalid = this.regForm.controls.attendedBefore.invalid;
        }

        if (
          (this.environment.defaultProgramme != 'BabySteps' &&
            !this.regForm.controls.email.invalid &&
            !this.regForm.controls.password.invalid &&
            !this.regForm.controls.confirmPassword.invalid &&
            !this.regForm.controls.terms.invalid &&
            !attendedBeforeInvalid) ||
          (this.environment.defaultProgramme == 'BabySteps' &&
            !this.regForm.controls.email.invalid &&
            !this.regForm.controls.password.invalid &&
            !this.regForm.controls.confirmPassword.invalid &&
            !this.regForm.controls.terms.invalid)
        ) {
          // console.log('data to submit');
          // console.log(this.regForm.value);
          // do the registration!
          this.authService.register(this.regForm.value).subscribe(
            (responseData) => {
              const status = responseData['status'];
              if (status == 'SUCCESS') {
                this.step = 5;
                // this.userService.reset();
                // this.userService.get().subscribe((userData) => {
                this.isFetching = false;
                window.scrollTo(0, 0);

                // });
                // set the programme?

                /* AUTH them

              // login
              let credentials = {};
              credentials.email = this.regDetails.email;
              credentials.password = this.regDetails.password;

              AuthService.login(credentials).then(function (user) {
                $rootScope.$broadcast(AUTH_EVENTS.loginSuccess);
                this.setCurrentUser(user);

                // let's go
                this.step = 5;

                // set current programmes too
                //this.getProgramme();
              });
              */
              } else {
                Swal.fire({
                  title: 'Error creating account',
                  text: 'There was an error setting up your account. Please try again.',
                  icon: 'error',
                });
                this.isFetching = false;
              }
              this.isFetching = false;
            },
            (error) => {
              Swal.fire({
                title: 'Error creating account',
                text: 'There was an error setting up your account. Please try again.',
                icon: 'error',
              });
              this.isFetching = false;
            }
          );
          /*
          $http({
            url: '/register',
            method: 'POST',
            data: $httpParamSerializer(this.regDetails),
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          }).then(function (response) {
            //console.log(response);
            var status = responseData.status;
            if (status == 'SUCCESS') {
              // login
              var credentials = {};
              credentials.email = this.regDetails.email;
              credentials.password = this.regDetails.password;
              AuthService.login(credentials).then(function (user) {
                $rootScope.$broadcast(AUTH_EVENTS.loginSuccess);
                this.setCurrentUser(user);

                // let's go
                this.step = 5;

                // set current programmes too
                this.getProgramme();
              });
            } else {
              swal({
                title: 'Error creating account',
                text:
                  'There was an error setting up your account. Please try again.',
                type: 'error',
              });
            }
            this.loading = false;
          });


        } else {
          swal({
            title: "We notice you've missed something",
            text: 'Please check the form for information that has been missed',
            type: 'error',
          });*/
        } else {
          this.isFetching = false;
        }

        break;
    }
  }

  addToPhone() {}
  complete() {
    // m3?
    if (this.regProgrammeConfig.m3 == 1) {
      this.router.navigate(['/m3/welcome']);
    } else if (+this.regProgrammeConfig.welcomePageOn === 1) {
      this.router.navigate(['/welcome']);
    } else {
      if (this.regProgrammeConfig.prepost) {
        if (this.regProgrammeConfig.prepostOrg) {
          const prepostOrg = JSON.parse(this.regProgrammeConfig.prepostOrg);
          this.userService.userData.subscribe((userDataResponse) => {
            this.currentUser = userDataResponse;
            // get the current user...
            const preposts = [];
            // check for org specific..
            if (prepostOrg[this.currentUser.client_id]) {
              for (const assessmentId of Object.keys(
                prepostOrg[this.currentUser.client_id]
              )) {
                for (const prepost of Object.keys(
                  prepostOrg[this.currentUser.client_id][assessmentId]
                )) {
                  if (
                    prepostOrg[this.currentUser.client_id][assessmentId].pre ==
                    true
                  ) {
                    preposts.push(assessmentId);
                  }
                }
              }
            }

            // go to the pre
            if (preposts.length > 0) {
              this.router.navigate([
                '/assessments',
                'take',
                preposts[0],
                'pre',
              ]);
            } else {
              this.router.navigate(['/dashboard']);
            }
          });
        } else {
          this.router.navigate([
            '/assessments',
            'take',
            this.regProgrammeConfig.prepost[0],
            'pre',
          ]);
        }
      } else {
        this.router.navigate(['/dashboard']);
      }
    }
  }

  getPostcodePattern() {
    if (environment.country === 'UK') {
      return '^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$';
    } else if (environment.country === 'AUS') {
      return '^[0-9]{4}$';
    }
  }

  getMinLength() {
    if (environment.country === 'UK') {
      return 5;
    } else if (environment.country === 'AUS') {
      return 4;
    }
  }
  getMaxLength() {
    if (environment.country === 'UK') {
      return 8;
    } else if (environment.country === 'AUS') {
      return 4;
    }
  }

  updateWeight(type?) {
    if (type != 'metric') {
      this.regDetails.weight =
        +parseInt(this.regDetails.weightStone) * 6.35029 +
        this.regDetails.weightLbs * 0.453592;
    } else {
      //convert metric to imperial
      var stones = Math.floor(this.regDetails.weight * 0.1574);
      var remainder = 0.1574 % this.regDetails.weight;
      var totalLbs = 2.20462 * this.regDetails.weight;
      var lbs = totalLbs - stones * 14;
      this.regDetails.weightStone = stones;
      this.regDetails.weightLbs = Math.round(lbs * 10) / 10;
    }
  }

  updateHeight(type?) {
    if (type != 'metric') {
      this.regDetails.height =
        +parseInt(this.regDetails.heightFeet) * 12 * 2.54 +
        +parseInt(this.regDetails.heightInches) * 2.54;
    } else {
      //convert metric to imperial
      var realFeet = (this.regDetails.height * 0.3937) / 12;
      var feet = Math.floor(this.regDetails.height / 30.48);
      var inches = Math.round((realFeet - feet) * 12);
      this.regDetails.heightFeet = feet;
      this.regDetails.heightInches = inches;
    }
  }

  updateVal() {
    // convert from percent..
    this.regDetails.glucose =
      Math.round((this.regDetails.glucosePercent - 2.15) * 10.929 * 10) / 10;
  }

  checkPassword(check, pswd) {
    var pass = false;

    if (pswd) {
      switch (check) {
        case 'uppercase':
          if (/^.*[A-Z].*$/.test(pswd)) {
            pass = true;
          }
          break;
        case 'lowercase':
          if (/^.*[a-z].*$/.test(pswd)) {
            pass = true;
          }
          break;
        case 'number':
          if (/^.*[0-9].*$/.test(pswd)) {
            pass = true;
          }
          break;
        case 'special':
          if (/^.*[$@!%*?&#].*$/.test(pswd)) {
            pass = true;
          }
          break;
      }
    }
    return pass;
  }

  getPasswordStrength(password) {
    let score = 0;

    if (/^.*[A-Z].*$/.test(password)) {
      score++;
    }
    if (/^.*[a-z].*$/.test(password)) {
      score++;
    }
    if (/^.*[0-9].*$/.test(password)) {
      score++;
    }
    if (/^.*[$@!%*?&#].*$/.test(password)) {
      score++;
    }

    return score;
  }
}
