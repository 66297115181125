<div [innerHTML]="dragAndDrop.instructions | safeHtml" *ngIf="dragAndDrop.instructions"></div>


<div class="quiz-pager">
    <div class="quiz-pager-content">
      <div
      *ngFor="let dragItem of dragItems; let index = index"
      [ngClass]="{ 'current' : qNum == (index + 1)}"
      (click)="setDDqNum(index+1)"
      >
        <div [ngClass]="{ correct: dragItem.state === 'answered' }">
          <span class="icon-wrap fa fa-circle"
            ><fa-icon [icon]="['fas', 'circle']"></fa-icon
          ></span>
          <span class="icon-wrap fa fa-check-circle"
            ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
          ></span>
          <span class="icon-wrap fa fa-times-circle"
            ><fa-icon [icon]="['fas', 'times-circle']"></fa-icon
          ></span>
        </div>
      </div>
    </div>
  </div>



<div class="row">
  <div
    class="col-md-4 col-md-offset-4 col-sm-8 col-sm-offset-2 col-8 col-offset-2"
  ></div>
  <div
    class="col-md-4 col-md-offset-4 col-sm-8 col-sm-offset-2 col-8 col-offset-2"
  >
    <div
      class="drag-item-container drag-item-container-full"
      *ngFor="let dragItem of dragItems; let index = index"
    >
    <div 
    *ngIf="qNum == (index + 1)">
      <div
        [ngClass]="
          { 'drag-item': dragItem.state, 'drag-item-dropped': dragItem.state === 'dropped' }"
        [dndDraggable]="dragItem"
        [dndEffectAllowed]="'copyMove'"
        (dndStart)="startCallback($event)"
        (dndEnd)="stopCallback($event)"
        (dndMoved)="dragCallback($event)"
        [ngStyle]="!dragItem.image && { padding: '0px', 'min-height': '26px' }"
        [dndDisableIf]="dragItem.state === 'dropped'"
      >
      <span class="drag-icon">
        <fa-icon [icon]="['fas', 'arrows']"></fa-icon>
    </span>
        <div class="drag-item-img" *ngIf="dragItem.image">
          <img [src]="environment.assetUrl + '/asset-thumb/' + dragItem.image + '/200x200'" width="100%" />
        </div>
        <div
          class="drag-item-text"
          [ngStyle]="
            !dragItem.image && { 'padding-right': '26px', position: 'relative' }
          "
        >
          {{ dragItem.text }}
        </div>
      </div>
    </div>
    </div>
  </div>
</div>

<div class="clearfix"></div>

<div class="drop-items">
  <div class="row">
    <div class="col-6" *ngFor="let dropItem of dropItems">
      <div
        class="drop-item-container"
        dndDropzone
        (dndDrop)="beforeDrop($event, dropItem)"
      >
        <div
          [ngClass]="
            {
                'drop-item': dropItem.state,
                'drop-item-correct' : dropItem.state === 'correct',
                'drop-item-incorrect' : dropItem.state === 'incorrect'
            }
          "
        >
        <span class="icon-wrap"  *ngIf="(dropItem.state === 'correct')">
          <fa-icon [icon]="['fas', 'check-circle']"></fa-icon>
        </span>
        <span class="icon-wrap"  *ngIf="dropItem.state === 'incorrect'">
          <fa-icon  [icon]="['fas', 'times-circle']"></fa-icon>
        </span>
          <div>{{ dropItem.text }}</div>
          <div
            *ngFor="let drop of dropItem.dropped"
            style="display: inline-block; margin-right: 5px"
          >
            <div class="drag-item">
            
              <div class="drag-item-img" *ngIf="drop.image">
                <img
                  [src]="environment.assetUrl + '/asset-thumb/' +  drop.image + '/160x120'"
                  width="80"
                  height="60"
                />
              </div>
              <div class="drag-item-text">
                {{ drop.text }}
                <a
                  class="remove-drag-item"
                  (click)="removeDropItem(dropItem.drop_item_id, drop.drag_item_id);"
                  ><span class="icon-wrap"
                  ><fa-icon [icon]="['fas', 'trash']"></fa-icon
                ></span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="clearfix"></div>
<br />
<div class="text-center">
  <button
    *ngIf="dragAndDrop.state != 'answered'"
    class="btn btn-primary"
    (click)="submitDnD()"
  >
  <span class="icon-wrap"
  ><fa-icon [icon]="['fas', 'check']"></fa-icon
></span> CONFIRM
  </button>
</div>

<div *ngIf="dragAndDrop.state == 'answered'">
  <div>
    You were <span *ngIf="!dragAndDrop.correct">Incorrect</span
    ><span *ngIf="dragAndDrop.correct">Correct</span>.
  </div>
  <div><div ng-bind-html="dragAndDrop.completed_text | rawHtml"></div></div>
</div>
