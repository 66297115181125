<nav class="subnav">
    <a [routerLink]="['/activity', 'minutes']" aria-label="Go to minutes" class="subnav-prev"
      ><span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span></a>
    <h2><span class="icon-wrap"><fa-icon [icon]="['fas', 'medal']"></fa-icon></span> ACHIEVEMENTS</h2>
    <a  [routerLink]="['/setup-device']" aria-label="Go to device setup" class="subnav-next"
      ><span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span></a>
  </nav>
<div *ngIf="isFetching">
    <app-loading-spinner [inline]="1"></app-loading-spinner>
  </div>
<div  *ngIf="awards">
<div class="masthead masthead-accent masthead-award" ng-model="target">
    <div *ngIf="latestAward">
    <h2>LATEST AWARD</h2>
    <div class="award"><img src="./assets/img/icon-rosette.png" width="59" alt="Rosette"><span class="place">{{latestAward.award_rank | ordinal}}</span></div>
    <h3><span *ngIf="latestAward.award_sub_type=='weekend'">WEEKEND CHALLENGE</span> <span *ngIf="latestAward.award_sub_type=='mon-fri'">MONDAY - FRIDAY CHALLENGE</span>
        <span *ngIf="latestAward.award_sub_type=='global'">GLOBAL LEADERBOARD</span> |  STEPS</h3>
    </div>
    <div *ngIf="awards.length==0">
        <h2>No awards yet</h2>
    </div>
</div>
<div class="container">
    <div class="well mb-3">
        <h3 class="text-center">MONDAY - FRIDAY CHALLENGE <span>| STEPS</span></h3>
        <div class="row">
            <div class="col-4"><div class="award"><img src="./assets/img/icon-rosette-gold.png" width="59" alt="Gold rosette"><span class="place">{{MfGold}}</span></div></div>
            <div class="col-4"><div class="award"><img src="./assets/img/icon-rosette-silver.png" width="59" alt="Silver rosette"><span class="place">{{MfSilver}}</span></div></div>
            <div class="col-4"><div class="award"><img src="./assets/img/icon-rosette-bronze.png" width="59" alt="Bronze rosette"><span class="place">{{MfBronze}}</span></div></div>
        </div>
    </div>
    <div class="well mb-3">
        <h3>WEEKEND CHALLENGE <span>| STEPS</span></h3>
        <div class="row">
            <div class="col-4"><div class="award award-medal"><img src="./assets/img/icon-aw-medal-gold.png" width="46" alt="Gold medal"><span class="place">{{wGold}}</span></div></div>
            <div class="col-4"><div class="award award-medal"><img src="./assets/img/icon-aw-medal-silver.png" width="46" alt="Silver medal"><span class="place">{{wSilver}}</span></div></div>
            <div class="col-4"><div class="award award-medal"><img src="./assets/img/icon-aw-medal-bronze.png" width="46" alt="Bronze medal"><span class="place">{{wBronze}}</span></div></div>
        </div>
    </div>
    <div class="well mb-3">
        <h3>GLOBAL LEADERBOARD <span>| STEPS</span></h3>
        <div class="row">
            <div class="col-4"><div class="award award-medal"><img src="./assets/img/icon-aw-medal-gold.png" width="46" alt="Gold medal"><span class="place">{{lbGold}}</span></div></div>
            <div class="col-4"><div class="award award-medal"><img src="./assets/img/icon-aw-medal-silver.png" width="46" alt="Silver medal"><span class="place">{{lbSilver}}</span></div></div>
            <div class="col-4"><div class="award award-medal"><img src="./assets/img/icon-aw-medal-bronze.png" width="46" alt="Bronze medal"><span class="place">{{lbBronze}}</span></div></div>
        </div>
    </div>
    <div class="well mb-3">
        <h3>STEP TOTALS {{currentUser.steps}}</h3>
        <div class="row">
            <div class="col-6">
                <div [ngClass]="{'hit': currentUser.steps>1000}" class="aw-step-total"> <span class="icon-wrap fa fa-star hit-goal"><fa-icon [icon]="['fas', 'star']"></fa-icon></span><span class="icon-wrap fa fa-star-o"><fa-icon [icon]="['far', 'star']"></fa-icon></span>1,000 steps</div>
                <div [ngClass]="{'hit': currentUser.steps>5000}" class="aw-step-total"><span class="icon-wrap fa fa-star hit-goal"><fa-icon [icon]="['fas', 'star']"></fa-icon></span><span class="icon-wrap fa fa-star-o"><fa-icon [icon]="['far', 'star']"></fa-icon></span> 5,000 steps</div>
                <div [ngClass]="{'hit': currentUser.steps>10000}" class="aw-step-total"><span class="icon-wrap fa fa-star hit-goal"><fa-icon [icon]="['fas', 'star']"></fa-icon></span><span class="icon-wrap fa fa-star-o"><fa-icon [icon]="['far', 'star']"></fa-icon></span> 10,000 steps</div>
                <div [ngClass]="{'hit': currentUser.steps>50000}" class="aw-step-total"><span class="icon-wrap fa fa-star hit-goal"><fa-icon [icon]="['fas', 'star']"></fa-icon></span><span class="icon-wrap fa fa-star-o"><fa-icon [icon]="['far', 'star']"></fa-icon></span> 50,000 steps</div>
                <div [ngClass]="{'hit': currentUser.steps>100000}" class="aw-step-total"><span class="icon-wrap fa fa-star hit-goal"><fa-icon [icon]="['fas', 'star']"></fa-icon></span><span class="icon-wrap fa fa-star-o"><fa-icon [icon]="['far', 'star']"></fa-icon></span> 100,000 steps</div>
            </div>
            <div class="col-6">
                <div [ngClass]="{'hit': currentUser.steps>500000}" class="aw-step-total"><span class="icon-wrap fa fa-star hit-goal"><fa-icon [icon]="['fas', 'star']"></fa-icon></span><span class="icon-wrap fa fa-star-o"><fa-icon [icon]="['far', 'star']"></fa-icon></span> 500,000 steps</div>
                <div [ngClass]="{'hit': currentUser.steps>1000000}" class="aw-step-total"><span class="icon-wrap fa fa-star hit-goal"><fa-icon [icon]="['fas', 'star']"></fa-icon></span><span class="icon-wrap fa fa-star-o"><fa-icon [icon]="['far', 'star']"></fa-icon></span> 1,000,000 steps</div>
                <div [ngClass]="{'hit': currentUser.steps>2000000}" class="aw-step-total"><span class="icon-wrap fa fa-star hit-goal"><fa-icon [icon]="['fas', 'star']"></fa-icon></span><span class="icon-wrap fa fa-star-o"><fa-icon [icon]="['far', 'star']"></fa-icon></span> 2,000,000 steps</div>
                <div [ngClass]="{'hit': currentUser.steps>5000000}" class="aw-step-total"><span class="icon-wrap fa fa-star hit-goal"><fa-icon [icon]="['fas', 'star']"></fa-icon></span><span class="icon-wrap fa fa-star-o"><fa-icon [icon]="['far', 'star']"></fa-icon></span> 5,000,000 steps</div>
                <div [ngClass]="{'hit': currentUser.steps>10000000}" class="aw-step-total"><span class="icon-wrap fa fa-star hit-goal"><fa-icon [icon]="['fas', 'star']"></fa-icon></span><span class="icon-wrap fa fa-star-o"><fa-icon [icon]="['far', 'star']"></fa-icon></span> 10,000,000 steps</div>
            </div>
        </div>
    </div>
</div>

<app-section-nav [section]="'activity'" [active]="'achievements'"></app-section-nav>
    
</div>