import { NgForm } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { SmokingService } from './../../../services/smoking.service';
import { ProgrammeService } from './../../../services/programme.service';
import { UserService } from './../../../services/user.service';
import { AuthService } from './../../../auth/auth.service';
import { UserSettingsService } from './../../../services/user-settings.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2/src/sweetalert2.js';
import * as moment from 'moment';

@Component({
  selector: 'app-smoking-settings',
  templateUrl: './smoking-settings.component.html',
  styleUrls: ['./smoking-settings.component.scss'],
})
export class SmokingSettingsComponent implements OnInit {
  isFetching = false;
  smokingSetting = {
    val: '',
    user_setting_id: null,
  };
  currentProgramme;
  currentUser;
  userSettings;
  errors = [];
  @ViewChild('trackerForm', { static: false }) trackerForm: NgForm;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private smokingService: SmokingService,
    private userSettingsService: UserSettingsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isFetching = true;
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (this.currentProgramme) {
          // this.barChartColors[0].backgroundColor = this.currentProgramme.config.primaryColor;

          forkJoin({
            Smokings: this.smokingService.fetchAll(),
            userSettings: this.userSettingsService.fetchByKey('smoking_type'),
          }).subscribe(
            (responseData) => {
              this.isFetching = false;
              this.userSettings = responseData.userSettings;
              if (responseData.userSettings) {
                this.smokingSetting = responseData.userSettings;
              }
            },
            (error) => {
              this.isFetching = false;
              this.errors.push(error.message);
            }
          );
        }
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });
    }
  }

  onSubmit() {
    // save the smoking
    let cigarettes = null;
    let tobacco = null;
    let cigars = null;
    let pipeTobacco = null;

    switch (this.smokingSetting.val) {
      case 'cigarettes':
        cigarettes = this.trackerForm.value.amount;
        break;
      case 'tobacco':
        tobacco = this.trackerForm.value.amount;
        break;
      case 'cigars':
        cigars = this.trackerForm.value.amount;
        break;
      case 'pipeTobacco':
        pipeTobacco = this.trackerForm.value.amount;
        break;
    }

    this.smokingService
      .create(cigarettes, tobacco, cigars, pipeTobacco, moment().format('YYYY-MM-DD'))
      .subscribe(
        (responseData) => {},
        (error) => {
          console.log(error);
        }
      );

    if (this.smokingSetting.user_setting_id) {
      this.userSettingsService
        .update(
          this.smokingSetting.user_setting_id,
          'smoking_type',
          this.smokingSetting.val
        )
        .subscribe(
          (responseData) => {
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your smoking type was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.router.navigate(['/health-trackers', 'smoking']);
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      this.userSettingsService
        .saveSetting('smoking_type', this.smokingSetting.val)
        .subscribe(
          (responseData) => {
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your smoking type was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.router.navigate(['/health-trackers', 'smoking']);
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }
}
