<nav class="subnav">
  <h2>REGISTER</h2>
</nav>

<app-loading-spinner *ngIf="isFetching"></app-loading-spinner>
<form
  class="bs-form reg-form"
  name="regForm"
  method="post"
  (ngSubmit)="onSubmit()"
  #regForm="ngForm"
>
  <!-- step 1 -->

  <div *ngIf="!regProgrammeConfig">


  
    <div
      class="reading-material-page-header booster-session-step-header register-header text-left"
    >
      1 | Code
    </div>
    <div class="container">
      <div class="form-group">
        <label for="location"
          >ENTER YOUR VERIFICATION CODE <span class="required">*</span></label
        >
        <input
          type="text"
          name="code"
          class="form-control"
          placeholder=""
          id="code"
          [(ngModel)]="code"
          autocomplete="off"
          required
        />
        <div class="alert alert-danger" *ngIf="codeError">
          {{codeError}}
        </div>
      </div>
      <div *ngIf="environment.country != 'AUS'">
        <div>
          <div>
              <div>
                <p><strong><u>What to do if you have not been sent a verification code:</u></strong></p>
                <p>If you want to access&nbsp;<b >Steps4Health</b> - this is currently only available across Leicestershire &amp; Rutland - if you are from this area, please visit&nbsp;<a href="https://forms.office.com/e/jqDwdWTtLh" target="_blank" >https://forms.office.com/e/jqDwdWTtLh</a> to register for access.&nbsp;</p>
                <p><strong><u>What to do if you have a verification code but are struggling to complete your registration:</u></strong></p>
                <p>Please see the video linked below for guidance. If you continue to have problems, please email&nbsp;<a href="mailto:mydesmond@uhl-tr.nhs.uk">mydesmond@uhl-tr.nhs.uk</a> (and please state your email address and the programme you are trying to access, as well as the problem you are having). If you are able to send screenshots/photographs of any errors or what you see on screen this will help us to resolve your issue more timely.</p>
                <p><u><strong>What to do if you have already registered:</strong></u></p>
                <p>If you are already registered on Steps4Health programmes, please&nbsp;<a href="https://www.mydesmond.com/app/login" >log into your account</a> via this link and enter your email address and the password you set up on registration.&nbsp;</p>
              </div>
          </div></div>
          <p class="required-msg mb-2">
            <span class="required">*</span> indicates required field
          </p>
  <div class="lead"><strong>All our digital programmes are part of the MyDESMOND Digital Health Platform</strong></div>
      </div>
      <hr class="divider" />
      <div class="container">
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6 text-right">
            <button
              (click)="checkCode(code)"
              class="btn btn-primary btn-white"
              [disabled]="!code"
              type="button"
            >
              NEXT <span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span>
            </button>
          </div>
        </div>
      </div>

      <!-- new video -->
      <div *ngIf="environment.country != 'AUS'" style="max-width:600px; margin:3rem auto;">
        <video  poster="./assets/img/reg-poster-steps4health.png" controls preload width="100%"  webkit-playsinline playsinline>
          <source  src="./assets/video/Steps4Health_Registration.mp4" type="video/mp4">
          <!--track *ngIf="captions" [src]="captions" kind="subtitles" srclang="en" 
                 label="English" default-->
         </video>
      </div>

    </div>
  </div>
  <div *ngIf="regProgrammeConfig">
    <div [hidden]="step !== 1">
      <div
        class="reading-material-page-header booster-session-step-header register-header text-left"
      >
        1.<span *ngIf="disclaimer == 'na'">1</span><span *ngIf="disclaimer != 'na'">2</span> | Disclaimer
      </div>
      <input
      [hidden]="true"
        type="text"
        name="code"
        placeholder=""
        id="code"
        [(ngModel)]="code"
        autocomplete="off"
        required
      />
      <div class="container">
        <div class="alert alert-white" *ngIf="disclaimer == 'na'">
          <p class="lead uppercase">
             Do you experience any of the following symptoms at rest or when
            exercising?
          </p>
          <ul>
            <li>Chest pain</li>
            <li>Unusually short of breath</li>
            <li>Unusually very tired</li>
            <li>Irregular heartbeats</li>
            <li>Balance problems / falls</li>
            <li>Dizziness</li>
          </ul>
          <div class="row">
            <div class="col-md-2 col-4">
              <button
                type="button"
                class="btn-block btn btn-default"
                (click)="disclaimer = 'y'"
              >
                YES
              </button>
            </div>
            <div class="col-md-2 col-4">
              <button
                type="button"
                class="btn-block btn btn-default"
                (click)="disclaimer = 'n'"
              >
                NO
              </button>
            </div>
            <div class="col-md-2 col-4">
              <button
                type="button"
                class="btn-block btn btn-default"
                (click)="disclaimer = 'n'"
              >
                YES, GP medical clearance received
              </button>
            </div>
          </div>
        </div>

        <div class="mt-4">
          <div id="gpMessage" *ngIf="disclaimer == 'y'">
            <p class="alert alert-danger">
              <strong> Please stop registration and consult your GP</strong>
            </p>
            <button
              type="button"
              class="btn-block btn btn-default"
              (click)="disclaimer = 'na'"
            >
              Back
            </button>
          </div>
          <div id="wavier" *ngIf="disclaimer == 'n'" class="alert alert-white">
            
            <p>
              This programme has several different exercise workouts. The
              exercises in this programme should not cause any harm, however
              they may not be suitable for all people. Make sure your activity
              and its intensity are appropriate for your level of fitness.
              Always consult a qualified health professional or your GP if you
              have not exercised regularly for some time and wish to begin a new
              fitness regime.
            </p>
            <p>
              You should always stop exercising immediately if you experience
              sudden pain or discomfort, chest pain, dizziness, excessive
              shortness of breath or tiredness, irregular heartbeats and seek
              the advice of your GP.
            </p>
            <p>
              You should not start exercising if you feel unwell to begin with,
              for example you have a temperature, or you have any new symptoms
              that may be linked to existing health conditions you have.
            </p>
            <p>
               Please confirm that you agree to abide by the statements above.
            </p>
            <div class="col-md-2">
              <button
                type="button"
                class="btn-block btn btn-default"
                (click)="step = step + 1"
              >
                I CONFIRM
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- step 2 -->
    <div [hidden]="step !== 2">
      <div
        class="reading-material-page-header booster-session-step-header register-header text-left"
      >
        2 | Personal Details
      </div>
      <div class="container">
        <div class="form-group">
          <label for="location"
            >FIRST NAME <span class="required">*</span></label
          >
          <input
            type="text"
            name="first_name"
            class="form-control"
            placeholder="First Name"
            id="first_name"
            [(ngModel)]="regDetails.first_name"
            #first_name="ngModel"
            autocomplete="off"
            required
            minlength="1"
            maxlength="100"
          />
          <div
            class="alert alert-danger"
            *ngIf="
              (first_name.touched && first_name.invalid) ||
              (stepSubmitted[2] && first_name.invalid)
            "
          >
            There is an error with your first name.
          </div>
        </div>
        <div class="form-group">
          <label for="location"
            >LAST NAME <span class="required">*</span></label
          >
          <input
            type="text"
            name="last_name"
            class="form-control"
            placeholder="Last Name"
            id="last_name"
            [(ngModel)]="regDetails.last_name"
            #last_name="ngModel"
            autocomplete="off"
            required
            minlength="1"
            maxlength="100"
          />
          <div
            class="alert alert-danger"
            *ngIf="
              (last_name.touched && last_name.invalid) ||
              (stepSubmitted[2] && last_name.invalid)
            "
          >
            There is an error with your last name.
          </div>
        </div>

        <div class="form-group">
          <label for="location"
            >SCREEN NAME <span class="required">*</span></label
          >
          <p>
            This is will be shown on challenge boards and comments. Please use
            only letters and numbers and remove any spaces or special
            characters.
          </p>
          <input
            type="text"
            name="screen_name"
            class="form-control"
            placeholder="Screen Name"
            id="screen_name"
            ngModel
            #screen_name="ngModel"
            autocomplete="off"
            required
            minlength="1"
            maxlength="100"
            pattern="^[a-zA-Z0-9]*$"
          />
          <div
            class="alert alert-danger"
            *ngIf="
              (screen_name.touched && screen_name.invalid) ||
              (stepSubmitted[2] && screen_name.invalid)
            "
          >
            There is an error with your screen name. Please use only letters and
            numbers and remove any spaces or special characters.
            <span *ngIf="screen_name.errors?.nametaken"
              >Sorry, that screen name is already taken</span
            >
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group form-group-dob">
              <label for="location"
                >DATE OF BIRTH <span class="required">*</span></label
              >

              <input
                class="form-control"
                name="dob"
                #dob="ngModel"
                bsDatepicker
                placeholder="DD/MM/YYYY"
                autocomplete="off"
                placement="top"
                [bsConfig]="{
                  dateInputFormat: 'DD/MM/YYYY',
                  containerClass: 'theme-cv',
                  showWeekNumbers: false
                }"
                [(ngModel)]="bsValue"
                required

              [minDate]="minDob"
              [maxDate]="maxDob"
              />

              <div
                class="alert alert-danger"
                *ngIf="
                  (dob.touched && dob.invalid) ||
                  (stepSubmitted[2] && dob.invalid)
                "
              >
                There is an error with your Date of Birth.
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="weight">SEX <span class="required">*</span></label>
              <input
                type="hidden"
                name="gender"
                id="gender"
                [(ngModel)]="regDetails.gender"
                #gender="ngModel"
                required
              />
              <div class="row">
                <div class="col-3">
                  <button
                    type="button"
                    class="btn btn-block btn-default"
                    [ngClass]="{
                      'btn-default': regDetails.gender !== 'female',
                      'btn-primary': regDetails.gender === 'female'
                    }"
                    (click)="regDetails.gender = 'female'"
                  >
                    FEMALE
                  </button>
                </div>
                <div class="col-3">
                  <button
                    type="button"
                    class="btn btn-block btn-default"
                    [ngClass]="{
                      'btn-default': regDetails.gender !== 'male',
                      'btn-primary': regDetails.gender === 'male'
                    }"
                    (click)="regDetails.gender = 'male'"
                  >
                    MALE
                  </button>
                </div>
                <div class="col-3">
                  <button
                    type="button"
                    class="btn btn-block btn-default"
                    [ngClass]="{
                      'btn-default': regDetails.gender !== 'x',
                      'btn-primary': regDetails.gender === 'x'
                    }"
                    (click)="regDetails.gender = 'x'"
                  >
                    X
                  </button>
                  <em>(Indeterminate / Intersex / Unspecified)</em>
                </div>

                <div class="col-3">
                  <button
                    type="button"
                    class="btn btn-block btn-default"
                    [ngClass]="{
                      'btn-default': regDetails.gender !== 'n',
                      'btn-primary': regDetails.gender === 'n'
                    }"
                    (click)="regDetails.gender = 'n'"
                  >
                    Prefer not to say
                  </button>
                </div>
              </div>
              <div
                class="alert alert-danger"
                *ngIf="stepSubmitted[2] && !regDetails.gender"
              >
                Please select your gender.
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6" *ngIf="environment.country == 'UK'">
            <div class="form-group">
              <label for="location"
                >ETHNICITY <span class="required">*</span></label
              >

              <select
                name="ethnicity_id"
                class="form-control"
                #ethnicity_id="ngModel"
                id="ethnicity_id"
                ngModel
                required
                (change)="onSelectEthnicity(ethnicity_id.value)"
              >
                <option value="">Please Select</option>
                <optgroup label="White">
                  <option value="1">British</option>
                  <option value="2">Irish</option>
                  <option value="3">
                    Any other White background (please specify)
                  </option>
                </optgroup>
                <optgroup label="Mixed">
                  <option value="4">White and Black Caribbean</option>
                  <option value="5">White and Black African</option>
                  <option value="6">White and Asian</option>
                  <option value="7">
                    Any other Mixed background (please specify)
                  </option>
                </optgroup>
                <optgroup label="Asian&#x20;or&#x20;Asian&#x20;British">
                  <option value="8">Indian</option>
                  <option value="9">Pakistani</option>
                  <option value="10">Bangladeshi</option>
                  <option value="11">
                    Any other Asian background (please specify)
                  </option>
                </optgroup>
                <optgroup label="Black&#x20;or&#x20;Black&#x20;British">
                  <option value="12">Caribbean</option>
                  <option value="13">African</option>
                  <option value="14">
                    Any other Black background (please specify)
                  </option>
                </optgroup>
                <optgroup
                  label="Chinese&#x20;or&#x20;other&#x20;ethnic&#x20;group"
                >
                  <option value="15">Chinese</option>
                  <option value="16">Any other (please specify)</option>
                </optgroup>
                <optgroup
                  label="Arab&#x20;or&#x20;Middle&#x20;Eastern&#x20;descent"
                >
                  <option value="17">Arab</option>
                  <option value="18">North African</option>
                  <option value="19">Iraqi</option>
                  <option value="20">Kurdish</option>
                  <option value="21">
                    Any other Middle Eastern background (please specify)
                  </option>
                </optgroup>
                <optgroup label="Other">
                  <option value="23">Prefer not to say</option>
                  <option value="24">Other</option>
                </optgroup>
              </select>

              <input
                type="text"
                [hidden]="!ethnicityOtherEnabled"
                class="form-control"
                placeholder="Please state.."
                name="ethnicity_other"
                #ethnicity_other="ngModel"
                id="ethnicity_other"
                ngModel
              />

              <div
                class="alert alert-danger"
                *ngIf="
                  (ethnicity_id.touched && ethnicity_id.invalid) ||
                  (stepSubmitted[2] && ethnicity_id.invalid)
                "
              >
                Ethnicity is required.
              </div>
            </div>
          </div>
          </div>

          <div class="row">
            <div class="col-sm-12" *ngIf="environment.country == 'UK'">
              <div class="form-group">
                <label for="location"
                  >Are you living with a long term health condition? <span class="required">*</span></label
                >
  
                
                <div class="row">
                  <div class="col-6">
                    <button
                      type="button"
                      class="btn-block btn btn-default"
                    (click)="showHealthConditions = true"
                    [ngClass]="{
                      'btn-primary': showHealthConditions === true,
                      'btn-default':
                      showHealthConditions !== true
                    }"
                    >
                      YES
                    </button>
                  </div>
                  <div class="col-6">
                    <button
                      type="button"
                      class="btn-block btn btn-default"
                      (click)="showHealthConditions = false"
                      [ngClass]="{
                        'btn-primary': showHealthConditions === false,
                        'btn-default':
                        showHealthConditions !== false
                      }"
                    
                    >
                      NO
                    </button>
                  </div>
                </div>

                <div [hidden]="!showHealthConditions" class="my-4">
                  
                  <label for="health_conditions">You have answered yes to living with a long term health condition. Please select the category of your health condition/s (multiple answers can be selected)?</label>
                  
                  <input
                    type="hidden"
                    name="health_conditions"
                    id="health_conditions"
                    [(ngModel)]="regDetails.user_health_conditions"
                    #health_conditions="ngModel"
                    required
                  />
                  <div class="form-check" *ngFor="let healthCondition of healthConditions">
                    <input class="form-check-input" (change)="pickHealthCondition(healthCondition)" type="checkbox" value="{{healthCondition.health_condition_id}}" id="healthCondition{{healthCondition.health_condition_id}}">
                    <label class="form-check-label" for="healthCondition{{healthCondition.health_condition_id}}">
                      {{healthCondition.health_condition}}
                    </label>
                  </div>

                  <div class="form-check">
                    <input class="form-check-input" name="health_condition_other_toggle" type="checkbox" value="" id="health_condition_other_toggle" (change)="showHealthConditionsOther=!showHealthConditionsOther">
                    <label class="form-check-label" for="health_condition_other_toggle">
                      Other
                    </label>
                  </div>
                  <input
                  type="text"
                  [hidden]="!showHealthConditionsOther"
                  class="form-control"
                  placeholder="Please state.."
                  name="health_condition_other"
                  #health_condition_other="ngModel"
                  id="health_condition_other"
                  ngModel
                />
                </div>

              </div>
            </div>

            <div class="col-sm-12">
              <div class="form-group">
                <label for="disability"
                  >Do you consider yourself to have a learning disability? <span class="required">*</span></label
                >

          <input
          type="hidden"
          name="disability"
          id="disability"
          [(ngModel)]="regDetails.disability"
          #disability="ngModel"
          required
        />
                <div class="row">
                  <div class="col-4">
                    <button
                      type="button"
                      class="btn-block btn btn-default"
                    (click)="regDetails.disability = 'y'"
                    [ngClass]="{
                      'btn-primary': regDetails.disability === 'y',
                      'btn-default':
                      regDetails.disability !== 'y'
                    }"
                    >
                      YES
                    </button>
                  </div>
                  <div class="col-4">
                    <button
                      type="button"
                      class="btn-block btn btn-default"
                      (click)="regDetails.disability = 'n'"
                      [ngClass]="{
                        'btn-primary': regDetails.disability === 'n',
                        'btn-default':
                        regDetails.disability !== 'n'
                      }"
                    
                    >
                      NO
                    </button>
                  </div>
                  <div class="col-4">
                    <button
                      type="button"
                      class="btn-block btn btn-default"
                      (click)="regDetails.disability = 'x'"
                      [ngClass]="{
                        'btn-primary': regDetails.disability === 'x',
                        'btn-default':
                        regDetails.disability !== 'x'
                      }"
                    
                    >
                      PREFER NOT TO SAY
                    </button>
                  </div>
                </div>
                </div>
                </div>


          <div class="col-sm-6">
            <label for="post_code">POST CODE</label>
           
            <input
              type="text"
              name="post_code"
              #postcode="ngModel"
              placeholder="Postcode"
              [pattern]="getPostcodePattern()"
              [required]="environment.country === 'AUS'"
              [attr.minlength]="getMinLength()"
              [attr.maxlength]="getMaxLength()"
              ngModel
              class="form-control"
            />
            <div
              class="alert alert-danger"
              *ngIf="
                (postcode.touched && postcode.invalid) ||
                (stepSubmitted[2] && postcode.invalid)
              "
            >
              There is an error with your Postcode.
            </div>
          </div>
        </div>

        <p class="required-msg">
          <span class="required">*</span> indicates required field
        </p>
        <hr class="divider" />
        <div class="container">
          <div class="row">
            <div class="col-6"></div>
            <div class="col-6 text-right">
              <button
                (click)="checkReg(2)"
                class="btn btn-primary btn-white"
                type="button"
              >
                NEXT
                <span class="icon-wrap"
                  ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- step 3 -->
    <div [hidden]="step !== 3">
      <div
        class="reading-material-page-header booster-session-step-header register-header text-left"
      >
        3 | Medical Details
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div [hidden]="unitMode !== 'metric'">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="weight"
                      >WEIGHT (KG) <span class="required">*</span></label
                    >
                    <input
                      type="number"
                      name="weight"
                      #weight="ngModel"
                      class="form-control input-tracker"
                      placeholder=""
                      id="weight"
                      [(ngModel)]="regDetails.weight"
                      [min]="40"
                      [max]="350"
                      autocomplete="off"
                      required
                      (change)="updateWeight('metric')"
                    />
                    <div
                      class="alert alert-danger"
                      *ngIf="
                        (weight.touched && weight.invalid) ||
                        (stepSubmitted[3] && weight.invalid)
                      "
                    >
                      Please enter a valid weight.
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="height"
                      >HEIGHT (CM) <span class="required">*</span></label
                    >
                    <input
                      type="number"
                      name="height"
                      class="form-control input-tracker"
                      placeholder=""
                      id="height"
                      [(ngModel)]="regDetails.height"
                      [min]="40"
                      [max]="250"
                      autocomplete="off"
                      required
                      #height="ngModel"
                      (change)="updateHeight('metric')"
                    />
                    <div
                      class="alert alert-danger"
                      *ngIf="
                        (height.touched && height.invalid) ||
                        (stepSubmitted[3] && height.invalid)
                      "
                    >
                      Please enter a valid height.
                    </div>
                  </div>
                </div>
              </div>
              <a (click)="unitMode = 'imperial'" class="btn btn-sm btn-default"
                >Switch to Imperial</a
              >
            </div>
            <div [hidden]="unitMode !== 'imperial'">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="weightStone"
                      >WEIGHT (Stone) <span class="required">*</span></label
                    >
                    <input
                      type="number"
                      name="weightStone"
                      class="form-control input-tracker"
                      placeholder=""
                      id="weightStone"
                      [(ngModel)]="regDetails.weightStone"
                      #weightStone="ngModel"
                      [min]="3"
                      [max]="40"
                      autocomplete="off"
                      (change)="updateWeight()"
                    />
                    <div
                      class="alert alert-danger"
                      *ngIf="
                        (weightStone.touched && weightStone.invalid) ||
                        (stepSubmitted[3] && weightStone.invalid)
                      "
                    >
                      Please enter a valid weight.
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="weightLbs"
                      >WEIGHT (lbs) <span class="required">*</span></label
                    >
                    <input
                      type="number"
                      name="weightLbs"
                      class="form-control input-tracker"
                      placeholder=""
                      id="weightLbs"
                      [(ngModel)]="regDetails.weightLbs"
                      #weightLbs="ngModel"
                      [min]="0"
                      [max]="15"
                      autocomplete="off"
                      (change)="updateWeight()"
                    />
                    <div
                      class="alert alert-danger"
                      *ngIf="
                        (weightLbs.touched && weightLbs.invalid) ||
                        (stepSubmitted[3] && weightLbs.invalid)
                      "
                    >
                      Please enter a valid weight.
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="heightFeet"
                      >HEIGHT (Feet) <span class="required">*</span></label
                    >
                    <input
                      type="number"
                      name="heightFeet"
                      class="form-control input-tracker"
                      placeholder=""
                      id="heightFeet"
                      [(ngModel)]="regDetails.heightFeet"
                      #heightFeet="ngModel"
                      [min]="3"
                      [max]="9"
                      autocomplete="off"
                      (change)="updateHeight()"
                    />
                    <div
                      class="alert alert-danger"
                      *ngIf="
                        (heightFeet.touched && heightFeet.invalid) ||
                        (stepSubmitted[3] && heightFeet.invalid)
                      "
                    >
                      Please enter a valid height.
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="heightInches"
                      >HEIGHT (Inches) <span class="required">*</span></label
                    >
                    <input
                      type="number"
                      name="heightInches"
                      class="form-control input-tracker"
                      placeholder=""
                      id="heightInches"
                      [(ngModel)]="regDetails.heightInches"
                      #heightInches="ngModel"
                      [min]="0"
                      [max]="11"
                      autocomplete="off"
                      (change)="updateHeight()"
                    />
                    <div
                      class="alert alert-danger"
                      *ngIf="
                        (heightInches.touched && heightInches.invalid) ||
                        (stepSubmitted[3] && heightInches.invalid)
                      "
                    >
                      Please enter a valid height.
                    </div>
                  </div>
                </div>
              </div>

              <a (click)="unitMode = 'metric'" class="btn btn-sm btn-default"
                >Switch to Metric</a
              >
            </div>
          </div>
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="weightStone">BMI (calculated)<span class="required">&nbsp;</span></label>
                  <div
                    *ngIf="!regDetails.weight || !regDetails.height"
                    class="bmi-text"
                  >
                    -
                  </div>
                  <div
                    class="bmi-text"
                    *ngIf="regDetails.weight && regDetails.height"
                  >
                    {{ calcBmi(regDetails.weight, regDetails.height / 100) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />

        <div class="form-group">
          <label for="smoker"
            >DO YOU SMOKE? <span class="required">*</span></label
          >
          <input
            type="hidden"
            name="smoker"
            id="smoker"
            [(ngModel)]="regDetails.smoker"
            #smoker="ngModel"
            required
          />
          <div class="row">
            <div class="col-6">
              <button
                type="button"
                class="btn-block btn btn-default"
                [ngClass]="{
                  'btn-primary': regDetails.smoker === 1,
                  'btn-default':
                    regDetails.smoker === 0 || regDetails.smoker === null
                }"
                (click)="regDetails.smoker = 1"
              >
                YES
              </button>
            </div>
            <div class="col-6">
              <button
                type="button"
                class="btn-block btn btn-default"
                [ngClass]="{
                  'btn-primary': regDetails.smoker === 0,
                  'btn-default':
                    regDetails.smoker === 1 || regDetails.smoker === null
                }"
                (click)="regDetails.smoker = 0"
              >
                NO
              </button>
            </div>
          </div>

          <div
            class="alert alert-danger"
            *ngIf="stepSubmitted[3] && regDetails.smoker == null"
          >
            Please select your smoking status.
          </div>
        </div>

        <p class="required-msg">
          <span class="required">*</span> indicates required field
        </p>
        <hr class="divider" />
        <div class="container">
          <div class="row">
            <div class="col-6"></div>
            <div class="col-6 text-right">
              <a (click)="checkReg(3)" class="btn btn-primary btn-white"
                >NEXT
                <span class="icon-wrap"
                  ><fa-icon
                    [icon]="['far', 'arrow-circle-right']"
                  ></fa-icon></span
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- step 4 -->
    <div [hidden]="step !== 4">
      
      <div
        class="reading-material-page-header booster-session-step-header register-header text-left"
      >
        4.{{subStep}} | Mobility Status
      </div>
      <div class="container">
        <div class="row">
          <div class="col">

            <div [hidden]="subStep!=1">
            <div class="form-group">
              <label for="mobility1" class="uppercase">
                Which one of the options below best describes your activity in
                the last week (Single answer)
                <span class="required">*</span></label
              >
              <div class="row">
                <div class="col-12">
                  <div class="radio-group-block">
                    <div class="custom-control custom-radio">
                      <input
                        #mobility1="ngModel"
required
                        [(ngModel)]="regDetails.mobility1"
                        type="radio"
                        id="mobility1a"
                        name="mobility1"
                        class="custom-control-input"
                        value="a"
                      />
                      <label class="custom-control-label" for="mobility1a"
                        >I have no problems in walking about or doing my usual
                        activities</label
                      >
                    </div>

                    <div class="custom-control custom-radio">
                      <input
                        #mobility1="ngModel"
required
                        [(ngModel)]="regDetails.mobility1"
                        type="radio"
                        id="mobility1b"
                        name="mobility1"
                        class="custom-control-input"
                        value="b"
                      />
                      <label class="custom-control-label" for="mobility1b"
                        >I have slight problems in walking about or doing my
                        usual activities</label
                      >
                    </div>

                    <div class="custom-control custom-radio">
                      <input
                        #mobility1="ngModel"
required
                        [(ngModel)]="regDetails.mobility1"
                        type="radio"
                        id="mobility1c"
                        name="mobility1"
                        class="custom-control-input"
                        value="c"
                      />
                      <label class="custom-control-label" for="mobility1c">
                        I have moderate problems in walking about or doing my
                        usual activities e.g., The distance and duration I can
                        walk for is limited</label
                      >
                    </div>

                    <div class="custom-control custom-radio">
                      <input
                        #mobility1="ngModel"
required
                        [(ngModel)]="regDetails.mobility1"
                        type="radio"
                        id="mobility1d"
                        name="mobility1"
                        class="custom-control-input"
                        value="d"
                      />
                      <label class="custom-control-label" for="mobility1d">
                        I have severe problems in walking about or doing my
                        usual activities e.g., I have physical
                        limitations</label
                      >
                    </div>

                    <div class="custom-control custom-radio">
                      <input
                        #mobility1="ngModel"
required
                        [(ngModel)]="regDetails.mobility1"
                        type="radio"
                        id="mobility1e"
                        name="mobility1"
                        class="custom-control-input"
                        value="e"
                      />
                      <label class="custom-control-label" for="mobility1e">
                        I am currently unable walk (temporarily) e.g., I am
                        unable to do most of my usual activities</label
                      >
                    </div>

                    <div class="custom-control custom-radio">
                      <input
                        #mobility1="ngModel"
required
                        [(ngModel)]="regDetails.mobility1"
                        type="radio"
                        id="mobility1f"
                        name="mobility1"
                        class="custom-control-input"
                        value="f"
                      />
                      <label class="custom-control-label" for="mobility1f">
                        I am unable walk (permanently) e.g., I am a wheelchair
                        user</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="alert alert-danger"
                *ngIf="stepSubmitted[4] &&subStepSubmitted[1] && regDetails.mobility1 == null"
              >
                Please select an option.
              </div>
            </div>
            <em
            >(Usual activities include getting washed and dressed, going to
            work, hobbies, and doing things with family and friends)
          </em>
            </div>


            <div [hidden]="subStep!=2">
           

            <div
              class="form-group mt-3"
              [hidden]="
                regDetails.mobility1 == null ||
                regDetails.mobility1 == 'e' ||
                regDetails.mobility1 == 'f'
              "
            >
              <label for="mobility2" class="uppercase"
                >Have you had 3 or more falls in the last 6 months?
                <span class="required">*</span></label
              >
              <input
                type="hidden"
                name="mobility2"
                id="mobility2"
                #mobility2="ngModel"
                [(ngModel)]="regDetails.mobility2"
                [required]="!(regDetails.mobility1 == null ||
                regDetails.mobility1 == 'e' ||
                regDetails.mobility1 == 'f')"
              />
              <div class="row">
                <div class="col-md-2 col-6">
                  <button
                    type="button"
                    class="btn-block btn btn-default"
                    [ngClass]="{
                      'btn-primary': regDetails.mobility2 === '1',
                      'btn-default':
                        regDetails.mobility2 === '0' ||
                        regDetails.mobility2 === null
                    }"
                    (click)="regDetails.mobility2 = '1'"
                  >
                    YES
                  </button>
                </div>
                <div class="col-md-2 col-6">
                  <button
                    type="button"
                    class="btn-block btn btn-default"
                    [ngClass]="{
                      'btn-primary': regDetails.mobility2 === '0',
                      'btn-default':
                        regDetails.mobility2 === '1' ||
                        regDetails.mobility2 === null
                    }"
                    (click)="regDetails.mobility2 = '0'"
                  >
                    NO
                  </button>
                </div>
              </div>

              <div
                class="alert alert-danger"
                *ngIf="stepSubmitted[4] && subStepSubmitted[2] && regDetails.mobility2 == null"
              >
                Please select an option.
              </div>
            </div>
            <div
              class="form-group"
              [hidden]="
                !(regDetails.mobility1 == 'd' && regDetails.mobility2 == '0')
              "
            >
              <label for="mobility3" class="uppercase">
                Would you like to include personalised walking as part of your
                programme? <span class="required">*</span></label
              >
              <input
                type="hidden"
                name="mobility3"
                id="mobility3"
                #mobility3="ngModel"
                [(ngModel)]="regDetails.mobility3"
                required
              />
              <div class="row">
                <div class="col-md-2 col-6">
                  <button
                    type="button"
                    class="btn-block btn btn-default"
                    [ngClass]="{
                      'btn-primary': regDetails.mobility3 === '1',
                      'btn-default':
                        regDetails.mobility3 === '0' ||
                        regDetails.mobility3 === null
                    }"
                    (click)="regDetails.mobility3 = '1'"
                  >
                    YES
                  </button>
                </div>
                <div class="col-md-2 col-6">
                  <button
                    type="button"
                    class="btn-block btn btn-default"
                    [ngClass]="{
                      'btn-primary': regDetails.mobility3 === '0',
                      'btn-default':
                        regDetails.mobility3 === '1' ||
                        regDetails.mobility3 === null
                    }"
                    (click)="regDetails.mobility3 = '0'"
                  >
                    NO
                  </button>
                </div>
              </div>

              <div
                class="alert alert-danger"
                *ngIf="stepSubmitted[4]&&subStepSubmitted[2] && regDetails.mobility3 == null"
              >
                Please select an option.
              </div>
            </div>
          </div>
            <p></p>
          </div>
        </div>
        <hr class="divider" />
        <div class="container">
          <div class="row">
            <div class="col-6"></div>
            <div class="col-6 text-right">
              <button
                (click)="checkReg(4)"
                class="btn btn-primary btn-white"
                [disabled]="isFetching"
                type="button"
              >
                NEXT
                <span class="icon-wrap"
                  ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div [hidden]="step !== 5">
      <div
        class="reading-material-page-header booster-session-step-header register-header text-left"
      >
        5.{{subStep}} | Physical activity status
      </div>
      <div class="container">
        <div class="row">
          <div class="col">

<!-- a -->

            <div [hidden]="subStep!=1">
            <div class="form-group">
              <label for="employed" class="uppercase"
                >Are you employed? <span class="required">*</span></label
              >
              <input
                type="hidden"
                name="employed"
                id="employed"
                #employed="ngModel"
                [(ngModel)]="regDetails.employed"
                required
              />
              <div class="row">
                <div class="col-md-2 col-6">
                  <button
                    type="button"
                    class="btn-block btn btn-default"
                    [ngClass]="{
                      'btn-primary': regDetails.employed === 1,
                      'btn-default':
                        regDetails.employed === 0 ||
                        regDetails.employed === null
                    }"
                    (click)="regDetails.employed = 1"
                  >
                    YES
                  </button>
                </div>
                <div class="col-md-2 col-6">
                  <button
                    type="button"
                    class="btn-block btn btn-default"
                    [ngClass]="{
                      'btn-primary': regDetails.employed === 0,
                      'btn-default':
                        regDetails.employed === 1 ||
                        regDetails.employed === null
                    }"
                    (click)="regDetails.employed = 0"
                  >
                    NO
                  </button>
                </div>
              </div>

              <div
                class="alert alert-danger"
                *ngIf="stepSubmitted[5] && regDetails.employed == null"
              >
                Please select an option.
              </div>
            </div>

            <div
              class="form-group"
              [hidden]="!regDetails.employed || regDetails.employed == 0"
            >
              <label for="employment_status" class="uppercase"
                >Please select an option below
                <span class="required">*</span></label
              >
              <input
                type="hidden"
                name="employment_status"
                id="employment_status"
                #employment_status="ngModel"
                [(ngModel)]="regDetails.employment_status"
                [required]="regDetails.employed != 0"
              />
              <div class="row">
                <div class="col-12">
                  <div class="radio-group-block">
                    <div class="custom-control custom-radio">
                      <input
                        type="radio"
                        name="employment_status"
                        id="employment_statusa"
                        #employment_status="ngModel"
                        [(ngModel)]="regDetails.employment_status"
                        class="custom-control-input"
                        value="a"
                      />
                      <label
                        class="custom-control-label"
                        for="employment_statusa"
                      >
                        I spend most of my time at work sitting</label
                      >
                    </div>

                    <div class="custom-control custom-radio">
                      <input
                        type="radio"
                        name="employment_status"
                        id="employment_statusb"
                        #employment_status="ngModel"
                        [(ngModel)]="regDetails.employment_status"
                        class="custom-control-input"
                        value="b"
                      />
                      <label
                        class="custom-control-label"
                        for="employment_statusb"
                      >
                        I spend most of my time at work standing or walking.
                        However, my work does not require much intense physical
                        effort (e.g., shop assistant, hairdresser, security
                        guard, childminder etc)</label
                      >
                    </div>

                    <div class="custom-control custom-radio">
                      <input
                        type="radio"
                        name="employment_status"
                        id="employment_statusc"
                        #employment_status="ngModel"
                        [(ngModel)]="regDetails.employment_status"
                        class="custom-control-input"
                        value="c"
                      />
                      <label
                        class="custom-control-label"
                        for="employment_statusc"
                      >
                        My work involves definite physical effort including the
                        handling of heavy objects and use of tools (e.g.,
                        plumber, electrician, carpenter, cleaner, hospital
                        nurse, gardener, postal delivery workers etc)</label
                      >
                    </div>

                    <div class="custom-control custom-radio">
                      <input
                        type="radio"
                        name="employment_status"
                        id="employment_statusd"
                        #employment_status="ngModel"
                        [(ngModel)]="regDetails.employment_status"
                        class="custom-control-input"
                        value="d"
                      />
                      <label
                        class="custom-control-label"
                        for="employment_statusd"
                      >
                        My work involves vigorous physical activity including
                        the handling of very heavy objects (e.g., scaffolder,
                        construction worker, refuse collector etc)</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="alert alert-danger"
                *ngIf="stepSubmitted[5] && regDetails.employment_status == null"
              >
                Please select an option.
              </div>
            </div>
          </div> 

          <div [hidden]="subStep!=2">
            <div class="form-group">
              <label class="uppercase">
                During the last week how many hours did you spend on each of the
                following activities? <span class="required">*</span></label
              >

              <div class="ep-activity-group">
                <label for="activity_time1"
                  >Physical exercise such as swimming, jogging, aerobics,
                  football, tennis, gym work out etc:
                  <span class="required">*</span></label
                >

                <div class="radio-group-ep">
                  <div class="custom-control custom-radio">
                    <input
                      [(ngModel)]="regDetails.activity_time1"
                      #activity_time1="ngModel"
                      type="radio"
                      id="activity_time1a"
                      name="activity_time1"
                      class="custom-control-input"
                      value="a"
                      required
                    />
                    <label class="custom-control-label" for="activity_time1a"
                      >None</label
                    >
                  </div>

                  <div class="custom-control custom-radio">
                    <input
                      [(ngModel)]="regDetails.activity_time1"
                      #activity_time1="ngModel"
                      type="radio"
                      id="activity_time1b"
                      name="activity_time1"
                      class="custom-control-input"
                      value="b"
                      required
                    />
                    <label class="custom-control-label" for="activity_time1b"
                      >Some but &lt; 1 h</label
                    >
                  </div>

                  <div class="custom-control custom-radio">
                    <input
                      [(ngModel)]="regDetails.activity_time1"
                      #activity_time1="ngModel"
                      type="radio"
                      id="activity_time1c"
                      name="activity_time1"
                      class="custom-control-input"
                      value="c"
                      required
                    />
                    <label class="custom-control-label" for="activity_time1c"
                      >1-3hrs</label
                    >
                  </div>

                  <div class="custom-control custom-radio">
                    <input
                      [(ngModel)]="regDetails.activity_time1"
                      #activity_time1="ngModel"
                      type="radio"
                      id="activity_time1d"
                      name="activity_time1"
                      class="custom-control-input"
                      value="d"
                      required
                    />
                    <label class="custom-control-label" for="activity_time1d"
                      >&gt;3 hrs</label
                    >
                  </div>
                </div>
                <div
                  class="alert alert-danger"
                  *ngIf="stepSubmitted[5] && subStepSubmitted[2] && !regDetails.activity_time1"
                >
                  Please select your activity time.
                </div>

                <label for="activity_time2"
                  >Cycling, including cycling to work and leisure time:
                  <span class="required">*</span></label
                >

                <div class="radio-group-ep">
                  <div class="custom-control custom-radio">
                    <input
                      [(ngModel)]="regDetails.activity_time2"
                      #activity_time2="ngModel"
                      type="radio"
                      id="activity_time2a"
                      name="activity_time2"
                      class="custom-control-input"
                      value="a"
                      required
                    />
                    <label class="custom-control-label" for="activity_time2a"
                      >None</label
                    >
                  </div>

                  <div class="custom-control custom-radio">
                    <input
                      [(ngModel)]="regDetails.activity_time2"
                      #activity_time2="ngModel"
                      type="radio"
                      id="activity_time2b"
                      name="activity_time2"
                      class="custom-control-input"
                      value="b"
                      required
                    />
                    <label class="custom-control-label" for="activity_time2b"
                      >Some but &lt; 1 h</label
                    >
                  </div>

                  <div class="custom-control custom-radio">
                    <input
                      [(ngModel)]="regDetails.activity_time2"
                      #activity_time2="ngModel"
                      type="radio"
                      id="activity_time2c"
                      name="activity_time2"
                      class="custom-control-input"
                      value="c"
                      required
                    />
                    <label class="custom-control-label" for="activity_time2c"
                      >1-3hrs</label
                    >
                  </div>

                  <div class="custom-control custom-radio">
                    <input
                      [(ngModel)]="regDetails.activity_time2"
                      #activity_time2="ngModel"
                      type="radio"
                      id="activity_time2d"
                      name="activity_time2"
                      class="custom-control-input"
                      value="d"
                      required
                    />
                    <label class="custom-control-label" for="activity_time2d"
                      >&gt;3 hrs</label
                    >
                  </div>
                </div>
                <div
                  class="alert alert-danger"
                  *ngIf="stepSubmitted[5] && subStepSubmitted[2] && !regDetails.activity_time2"
                >
                  Please select your activity time.
                </div>

                <label for="activity_time3"
                  >Walking, including walking to work, shopping, for pleasure
                  etc: <span class="required">*</span></label
                >

                <div class="radio-group-ep">
                  <div class="custom-control custom-radio">
                    <input
                      [(ngModel)]="regDetails.activity_time3"
                      #activity_time3="ngModel"
                      type="radio"
                      id="activity_time3a"
                      name="activity_time3"
                      class="custom-control-input"
                      value="a"
                      required
                    />
                    <label class="custom-control-label" for="activity_time3a"
                      >None</label
                    >
                  </div>

                  <div class="custom-control custom-radio">
                    <input
                      [(ngModel)]="regDetails.activity_time3"
                      #activity_time3="ngModel"
                      type="radio"
                      id="activity_time3b"
                      name="activity_time3"
                      class="custom-control-input"
                      value="b"
                      required
                    />
                    <label class="custom-control-label" for="activity_time3b"
                      >Some but &lt; 1 h</label
                    >
                  </div>

                  <div class="custom-control custom-radio">
                    <input
                      [(ngModel)]="regDetails.activity_time3"
                      #activity_time3="ngModel"
                      type="radio"
                      id="activity_time3c"
                      name="activity_time3"
                      class="custom-control-input"
                      value="c"
                      required
                    />
                    <label class="custom-control-label" for="activity_time3c"
                      >1-3hrs</label
                    >
                  </div>

                  <div class="custom-control custom-radio">
                    <input
                      [(ngModel)]="regDetails.activity_time3"
                      #activity_time3="ngModel"
                      type="radio"
                      id="activity_time3d"
                      name="activity_time3"
                      class="custom-control-input"
                      value="d"
                      required
                    />
                    <label class="custom-control-label" for="activity_time3d"
                      >&gt;3 hrs</label
                    >
                  </div>
                </div>
                <div
                  class="alert alert-danger"
                  *ngIf="stepSubmitted[5] && subStepSubmitted[2] && !regDetails.activity_time3"
                >
                  Please select your activity time.
                </div>

                <label for="activity_time4"
                  >Housework/childcare: <span class="required">*</span></label
                >

                <div class="radio-group-ep">
                  <div class="custom-control custom-radio">
                    <input
                      [(ngModel)]="regDetails.activity_time4"
                      #activity_time4="ngModel"
                      type="radio"
                      id="activity_time4a"
                      name="activity_time4"
                      class="custom-control-input"
                      value="a"
                      required
                    />
                    <label class="custom-control-label" for="activity_time4a"
                      >None</label
                    >
                  </div>

                  <div class="custom-control custom-radio">
                    <input
                      [(ngModel)]="regDetails.activity_time4"
                      #activity_time4="ngModel"
                      type="radio"
                      id="activity_time4b"
                      name="activity_time4"
                      class="custom-control-input"
                      value="b"
                      required
                    />
                    <label class="custom-control-label" for="activity_time4b"
                      >Some but &lt; 1 h</label
                    >
                  </div>

                  <div class="custom-control custom-radio">
                    <input
                      [(ngModel)]="regDetails.activity_time4"
                      #activity_time4="ngModel"
                      type="radio"
                      id="activity_time4c"
                      name="activity_time4"
                      class="custom-control-input"
                      value="c"
                      required
                    />
                    <label class="custom-control-label" for="activity_time4c"
                      >1-3hrs</label
                    >
                  </div>

                  <div class="custom-control custom-radio">
                    <input
                      [(ngModel)]="regDetails.activity_time4"
                      #activity_time4="ngModel"
                      type="radio"
                      id="activity_time4d"
                      name="activity_time4"
                      class="custom-control-input"
                      value="d"
                      required
                    />
                    <label class="custom-control-label" for="activity_time4d"
                      >&gt;3 hrs</label
                    >
                  </div>
                </div>
                <div
                  class="alert alert-danger"
                  *ngIf="stepSubmitted[5] && subStepSubmitted[2] && !regDetails.activity_time4"
                >
                  Please select your activity time.
                </div>

                <label for="activity_time5"
                  >Gardening/DIY: <span class="required">*</span></label
                >

                <div class="radio-group-ep">
                  <div class="custom-control custom-radio">
                    <input
                      [(ngModel)]="regDetails.activity_time5"
                      #activity_time5="ngModel"
                      type="radio"
                      id="activity_time5a"
                      name="activity_time5"
                      class="custom-control-input"
                      value="a"
                      required
                    />
                    <label class="custom-control-label" for="activity_time5a"
                      >None</label
                    >
                  </div>

                  <div class="custom-control custom-radio">
                    <input
                      [(ngModel)]="regDetails.activity_time5"
                      #activity_time5="ngModel"
                      type="radio"
                      id="activity_time5b"
                      name="activity_time5"
                      class="custom-control-input"
                      value="b"
                      required
                    />
                    <label class="custom-control-label" for="activity_time5b"
                      >Some but &lt; 1 h</label
                    >
                  </div>

                  <div class="custom-control custom-radio">
                    <input
                      [(ngModel)]="regDetails.activity_time5"
                      #activity_time5="ngModel"
                      type="radio"
                      id="activity_time5c"
                      name="activity_time5"
                      class="custom-control-input"
                      value="c"
                      required
                    />
                    <label class="custom-control-label" for="activity_time5c"
                      >1-3hrs</label
                    >
                  </div>

                  <div class="custom-control custom-radio">
                    <input
                      [(ngModel)]="regDetails.activity_time5"
                      #activity_time5="ngModel"
                      type="radio"
                      id="activity_time5d"
                      name="activity_time5"
                      class="custom-control-input"
                      value="d"
                      required
                    />
                    <label class="custom-control-label" for="activity_time5d"
                      >&gt;3 hrs</label
                    >
                  </div>
                </div>
                <div
                  class="alert alert-danger"
                  *ngIf="stepSubmitted[5] && subStepSubmitted[2] && !regDetails.activity_time5"
                >
                  Please select your activity time.
                </div>
              </div>
            </div>
          </div> 

          <div [hidden]="subStep!=3">
            <div class="form-group">
              <label for="walking_pace" class="uppercase"
                >How would you describe your usual walking pace?
                <span class="required">*</span></label
              >

              <div class="radio-group-ep">
                <div class="custom-control custom-radio">
                  <input
                    #walking_pace="ngModel"
                    [(ngModel)]="regDetails.walking_pace"
                    type="radio"
                    id="walking_pacea"
                    name="walking_pace"
                    class="custom-control-input"
                    value="a"
                  />
                  <label class="custom-control-label" for="walking_pacea"
                    >Slow (&lt;3mph)</label
                  >
                </div>

                <div class="custom-control custom-radio">
                  <input
                    #walking_pace="ngModel"
                    [(ngModel)]="regDetails.walking_pace"
                    type="radio"
                    id="walking_paceb"
                    name="walking_pace"
                    class="custom-control-input"
                    value="b"
                  />
                  <label class="custom-control-label" for="walking_paceb"
                    >Steady average pace</label
                  >
                </div>

                <div class="custom-control custom-radio">
                  <input
                    #walking_pace="ngModel"
                    [(ngModel)]="regDetails.walking_pace"
                    type="radio"
                    id="walking_pacec"
                    name="walking_pace"
                    class="custom-control-input"
                    value="c"
                  />
                  <label class="custom-control-label" for="walking_pacec"
                    >Brisk pace</label
                  >
                </div>

                <div class="custom-control custom-radio">
                  <input
                    #walking_pace="ngModel"
                    [(ngModel)]="regDetails.walking_pace"
                    type="radio"
                    id="walking_paced"
                    name="walking_pace"
                    class="custom-control-input"
                    value="d"
                  />
                  <label class="custom-control-label" for="walking_paced"
                    >Fast pace (&gt;4mph)</label
                  >
                </div>
              </div>
              <div
                class="alert alert-danger"
                *ngIf="stepSubmitted[5] && subStepSubmitted[3] && regDetails.walking_pace == null"
              >
                Please select an option.
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="location" class="uppercase"
                    >On average how many hours do you spend sitting on a
                    weekday?<span class="required">*</span></label
                  >
                  <input
                    type="text"
                    name="sitting_weekday"
                    class="form-control"
                    placeholder="Hours per day"
                    id="sitting_weekday"
                    [ngModel]="regDetails.sitting_weekday"
                    #sitting_weekday="ngModel"
                    autocomplete="off"
                    required
                    minlength="1"
                    maxlength="24"
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="
                      (sitting_weekday.touched && sitting_weekday.invalid) ||
                      (stepSubmitted[5] && subStepSubmitted[3] && sitting_weekday.invalid)
                    "
                  >
                    There is an error with your input.
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="location" class="uppercase"
                    >On average how many hours do you spend sitting on a weekend
                    day?<span class="required">*</span></label
                  >
                  <input
                    type="text"
                    name="sitting_weekend"
                    class="form-control"
                    placeholder="Hours per day"
                    id="sitting_weekend"
                    [ngModel]="regDetails.sitting_weekend"
                    #sitting_weekend="ngModel"
                    autocomplete="off"
                    required
                    minlength="1"
                    maxlength="24"
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="
                      (sitting_weekend.touched && sitting_weekend.invalid) ||
                      (stepSubmitted[5]&& subStepSubmitted[3] && sitting_weekend.invalid)
                    "
                  >
                    There is an error with your input.
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>

        <hr class="divider" />
        <div class="container">
          <div class="row">
            <div class="col-6"></div>
            <div class="col-6 text-right">
              <button
                (click)="checkReg(5)"
                class="btn btn-primary btn-white"
                [disabled]="isFetching"
                type="button"
              >
                NEXT
                <span class="icon-wrap"
                  ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div [hidden]="step !== 6">
      <div
        class="reading-material-page-header booster-session-step-header register-header text-left"
      >
        6 | Notification settings
      </div>
      <div class="container">
        <div class="row">
          <div class="col">
            <p>
              The app will automatically send you activity notifications on
              stepping, workouts and inactivity (via email or phone [if
              android]). Please select how often you would like to receive these
              notifications You can select off if you do NOT want to receive
              notifications on any of the following topic areas.
            </p>

            <!-- Stepping notifications not to appear if user selects mobility status 1E, 1F 1D+2a+3N or 1D+2b+3N -->
            <div
              class="form-group"
              [hidden]="
                regDetails.mobility1 == 'e' ||
                regDetails.mobility1 == 'f' ||
                (regDetails.mobility1 == 'd' &&
                  regDetails.mobility2 == '1' &&
                  regDetails.mobility3 == '0') ||
                (regDetails.mobility1 == 'd' &&
                  regDetails.mobility2 == '0' &&
                  regDetails.mobility3 == '0')
              "
            >
              <label for="notifications1" class="uppercase"
                >Stepping<span class="required">*</span></label
              >
              <input
                type="hidden"
                name="notifications1"
                [ngModel]="regDetails.notifications1 | json"
              />
              <app-notification-setting-control
                [notificationData]="regDetails.notifications1"
                [notificationName]="'Stepping'"
              ></app-notification-setting-control>

              <div
                class="alert alert-danger"
                *ngIf="stepSubmitted[6] && regDetails.notifications1 == null"
              >
                Please select your notification status.
              </div>
            </div>

            <div class="form-group">
              <label for="notifications1" class="uppercase"
                >Workouts<span class="required">*</span></label
              >
              <input
                type="hidden"
                name="notifications2"
                [ngModel]="regDetails.notifications2 | json"
              />
              <app-notification-setting-control
                [notificationData]="regDetails.notifications2"
                [notificationName]="'Workouts'"
              ></app-notification-setting-control>

              <div
                class="alert alert-danger"
                *ngIf="stepSubmitted[6] && regDetails.notifications2 == null"
              >
                Please select your notification status.
              </div>
            </div>

            <div class="form-group">
              <label for="notifications3" class="uppercase">
                <span
                  [hidden]="
                    regDetails.mobility1 == 'e' ||
                    regDetails.mobility1 == 'f' ||
                    (regDetails.mobility1 == 'd' &&
                      regDetails.mobility2 == '1' &&
                      regDetails.mobility3 == '0') ||
                    (regDetails.mobility1 == 'd' &&
                      regDetails.mobility2 == '0' &&
                      regDetails.mobility3 == '0')
                  "
                  >Sitting</span
                ><span
                  [hidden]="
                    !(
                      regDetails.mobility1 == 'e' ||
                      regDetails.mobility1 == 'f' ||
                      (regDetails.mobility1 == 'd' &&
                        regDetails.mobility2 == '1' &&
                        regDetails.mobility3 == '0') ||
                      (regDetails.mobility1 == 'd' &&
                        regDetails.mobility2 == '0' &&
                        regDetails.mobility3 == '0')
                    )
                  "
                  >Sedentary behaviour</span
                >
                <span class="required">*</span></label
              >
              
              <input
                type="hidden"
                name="notifications3"
                [ngModel]="regDetails.notifications3 | json"
              />
              <app-notification-setting-control
                [notificationData]="regDetails.notifications3"
                [notificationName]="'Sitting'"
              ></app-notification-setting-control>

              <div
                class="alert alert-danger"
                *ngIf="stepSubmitted[6] && regDetails.notifications3 == null"
              >
                Please select your notification status.
              </div>
            </div>
          </div>
        </div>

        <hr class="divider" />
        <div class="container">
          <div class="row">
            <div class="col-6"></div>
            <div class="col-6 text-right">
              <button
                (click)="checkReg(6)"
                class="btn btn-primary btn-white"
                [disabled]="isFetching"
                type="button"
              >
                NEXT
                <span class="icon-wrap"
                  ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div [hidden]="step !== 7">
      <div
        class="reading-material-page-header booster-session-step-header register-header text-left"
      >
        7 | Account Details
      </div>
      <div class="container">
        <div class="form-group">
          <label for="email"
            >EMAIL ADDRESS <span class="required">*</span></label
          >
          <input
            type="email"
            name="email"
            class="form-control input-tracker"
            placeholder=""
            id="email"
            #email="ngModel"
            [ngModel]="regDetails.email"
            autocomplete="off"
            required
            readonly
          />
          <div
            class="alert alert-danger"
            *ngIf="
              (email.touched && email.invalid) ||
              (stepSubmitted[7] && email.invalid)
            "
          >
            Please enter a valid email address.
          </div>
        </div>

        <div class="form-group">
          <label for="password">PASSWORD <span class="required">*</span></label>
          <p>
            Minimum ten characters, at least one uppercase letter, one lowercase
            letter, one number and one special character ($@!%*?&amp;#)
          </p>
          <input
            type="password"
            name="password"
            class="form-control input-tracker ok-password"
            [ngClass]="password.dirty && password.invalid ? 'error' : ''"
            placeholder="PASSWORD"
            id="password"
            ng-required="true"
            [(ngModel)]="regDetails.password"
            #password="ngModel"
            autocomplete="off"
            required
            [pattern]="passwordPattern"
          />
          <div
            class="alert alert-danger"
            *ngIf="stepSubmitted[4] && password.invalid && password.dirty"
          >
            Please enter a valid password.
          </div>
          <div
            class="label password-count"
            [ngClass]="
              regDetails.password.length > 9 ? 'label-success' : 'label-danger'
            "
          >
            {{ password.value | passwordCount: 10 }}
          </div>

          <div class="strength-meter">
            <div
              class="strength-meter-fill"
              [attr.data-strength]="getPasswordStrength(password.value)"
            ></div>
          </div>
          <div>
            <ul class="password-checklist">
              <li
                [ngClass]="{
                  'text-danger':
                    regDetails.password.length < 10 || !password.value,
                  'text-success': regDetails.password.length > 7
                }"
              >
                <span class="icon-wrap" *ngIf="regDetails.password.length > 9"
                  ><fa-icon [icon]="['fas', 'check']"></fa-icon
                ></span>
                <span
                  class="icon-wrap"
                  *ngIf="regDetails.password.length < 10 || !password.value"
                  ><fa-icon [icon]="['fas', 'times']"></fa-icon
                ></span>
                Minimum ten characters
              </li>
              <li
                [ngClass]="{
                  'text-danger': !checkPassword('uppercase', password.value),
                  'text-success': checkPassword('uppercase', password.value)
                }"
              >
                <span
                  class="icon-wrap"
                  *ngIf="checkPassword('uppercase', password.value)"
                  ><fa-icon [icon]="['fas', 'check']"></fa-icon
                ></span>
                <span
                  class="icon-wrap"
                  *ngIf="!checkPassword('uppercase', password.value)"
                  ><fa-icon [icon]="['fas', 'times']"></fa-icon
                ></span>

                At least one uppercase letter
              </li>
              <li
                [ngClass]="{
                  'text-danger': !checkPassword('lowercase', password.value),
                  'text-success': checkPassword('lowercase', password.value)
                }"
              >
                <span
                  class="icon-wrap"
                  *ngIf="checkPassword('lowercase', password.value)"
                  ><fa-icon [icon]="['fas', 'check']"></fa-icon
                ></span>
                <span
                  class="icon-wrap"
                  *ngIf="!checkPassword('lowercase', password.value)"
                  ><fa-icon [icon]="['fas', 'times']"></fa-icon
                ></span>

                One lowercase letter
              </li>
              <li
                [ngClass]="{
                  'text-danger': !checkPassword('number', password.value),
                  'text-success': checkPassword('number', password.value)
                }"
              >
                <span
                  class="icon-wrap"
                  *ngIf="checkPassword('number', password.value)"
                  ><fa-icon [icon]="['fas', 'check']"></fa-icon
                ></span>
                <span
                  class="icon-wrap"
                  *ngIf="!checkPassword('number', password.value)"
                  ><fa-icon [icon]="['fas', 'times']"></fa-icon
                ></span>

                One number
              </li>
              <li
                [ngClass]="{
                  'text-danger': !checkPassword('special', password.value),
                  'text-success': checkPassword('special', password.value)
                }"
              >
                <span
                  class="icon-wrap"
                  *ngIf="checkPassword('special', password.value)"
                  ><fa-icon [icon]="['fas', 'check']"></fa-icon
                ></span>
                <span
                  class="icon-wrap"
                  *ngIf="!checkPassword('special', password.value)"
                  ><fa-icon [icon]="['fas', 'times']"></fa-icon
                ></span>

                One special character ($@!%*?&amp;#)
              </li>
            </ul>
          </div>
        </div>

        <div class="form-group">
          <label for="confirmPassword"
            >CONFIRM PASSWORD <span class="required">*</span></label
          >
          <input
            type="password"
            name="confirmPassword"
            class="form-control input-tracker"
            placeholder=""
            id="confirmPassword"
            ngModel
            #confirmPassword="ngModel"
            autocomplete="off"
            required="true"
            [equalTo]="password"
          />
          <div
            class="alert alert-danger"
            *ngIf="
              (confirmPassword.touched && confirmPassword.invalid) ||
              (confirmPassword.touched && confirmPassword.errors?.equalTo)
            "
          >
            Your passwords do not match or there is an error with your password.
          </div>
        </div>

        <div class="form-group">
          <input
            type="checkbox"
            name="terms"
            id="terms"
            #terms="ngModel"
            [(ngModel)]="regDetails.terms"
            required
          />
          I agree to the
          <a href="/terms-of-use" target="_blank">Terms of Use</a>,
          <a href="/privacy-policy" target="_blank">Privacy/Cookie Policy</a>
          and
          <a href="/acceptable-use" target="_blank">Acceptable Use Policy</a>
          <span class="required">*</span>.
          <div
            class="alert alert-danger"
            *ngIf="
              (terms.touched && terms.invalid) ||
              (stepSubmitted[7] && terms.invalid)
            "
          >
            Please agree to the terms.
          </div>
        </div>

        <div class="form-group" *ngIf="environment.country == 'AUS'">
          <input
            type="checkbox"
            name="agree_marketing"
            id="agree_marketing"
            #agree_marketing="ngModel"
            required
            [(ngModel)]="regDetails.agree_marketing"
          />
          I would like to receive more information from
          <span
            *ngIf="
              environment.cookieDomain ==
                'https://www.mydesmondaustralia.com.au' ||
              environment.cookieDomain ==
                'https://test.mydesmondaustralia.com.au' ||
              environment.cookieDomain ==
                'https://play.mydesmondaustralia.com.au'
            "
            >Diabetes WA</span
          >
          <span
            *ngIf="
              environment.cookieDomain == 'https://trn-mydesmond.ndss.com.au' ||
              environment.cookieDomain == 'https://stg-mydesmond.ndss.com.au' ||
              environment.cookieDomain == 'https://mydesmond.ndss.com.au'
            "
            >NDSS Agents</span
          >
          about other ways to manage diabetes or stay healthy.
        </div>

        <p class="required-msg">
          <span class="required">*</span> indicates required field
        </p>
        <hr class="divider" />
        <div class="container">
          <div class="row">
            <div class="col-6"></div>
            <div class="col-6 text-right">
              <button
                (click)="checkReg(7)"
                class="btn btn-primary btn-white"
                [disabled]="isFetching"
                type="button"
              >
                NEXT
                <span class="icon-wrap"
                  ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- step 5 -->
    <!--div *ngIf="step==5">
          <div class="reading-material-page-header booster-session-step-header register-header text-left">5 | Team Name</div>
          <div class="container">
              <p>Please vote for your team name from the selsection of choices below...</p>
          </div>
              <div class="toggle-header toggle-header-left" ng-repeat="teamName in teamNames" (click)="teamVote(teamName)">
                  <a class="toggle-nav toggle-nav-expand"><i class="fa fa-arrow-circle-right"></i></a>
                  <div class="toggle-header-title">{{teamName | uppercase}}</div>
              </div>
              
              <hr class="divider" />
              <div class="container">
                  <div class=" row">
                      <div class="col-6">
                      </div>
                      <div class="col-6 text-right">
                          <a (click)="" class="btn btn-primary btn-white">NEXT <span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span></a>
                      </div>
                  </div>
              </div>
      </div-->

    <!-- step 8 -->
    <div [hidden]="step !== 8">
      <div
        class="reading-material-page-header booster-session-step-header register-header text-left"
      >
        Completed
      </div>
      <div class="container">
        <p>Congratulations!</p>
        <div *ngIf="environment.defaultProgramme != 'BabySteps'">
          <div *ngIf="environment.country == 'AUS'">
            <span *ngIf="regProgramme.programme_id == 2"
              >You are now registered to use MyDESMOND.</span
            >
            <span *ngIf="regProgramme.programme_id == 3"
              >You are now registered to use Baby Steps.</span
            >
            <span *ngIf="regProgramme.programme_id == 8"
              >You are now registered to use Let's Prevent.</span
            >
          </div>
          <div *ngIf="environment.country !== 'AUS'">
            <p>You are now registered to use Steps4Health.</p>
          </div>

          <!--p class="visible-xs">
            We recommend you add the MyDesmond shortcut icon to your home screen
            on your mobile phone using the button below.
          </p-->
        </div>
        <div *ngIf="environment.defaultProgramme == 'BabySteps'">
          <p>
            You are now registered to use Baby Steps - part of the MyDesmond
            family.
          </p>
          <!--p class="visible-xs">
            We recommend you add the MyDesmond shortcut icon to your home screen
            on your mobile phone using the button below. Please note this feature
            is not available for Chrome users on iOS
          </p-->
        </div>
      </div>
      <!--div class="text-center visible-xs">
        <img
          src="/app/assets/icons/apple-icon-180x180.png"
          width="115"
          height="115"
        /><br /><br />
        <button class="btn btn-primary" (click)="addToPhone()">
          <i class="fa fa-plus-circle"></i> ADD TO PHONE
        </button>
      </div-->
      <hr class="divider" />
      <div class="container">
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6 text-right">
            <a (click)="complete()" class="btn btn-primary btn-white"
              >NEXT
              <span class="icon-wrap"
                ><fa-icon
                  [icon]="['far', 'arrow-circle-right']"
                ></fa-icon></span
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
