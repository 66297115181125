import { Component, Input, OnInit } from '@angular/core';
import { Chart, ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as moment from 'moment';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { Sts60Service } from 'src/app/services/sts60.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-m3-physical-ability-chart',
  templateUrl: './m3-physical-ability-chart.component.html',
  styleUrls: ['./m3-physical-ability-chart.component.scss'],
})
export class M3PhysicalAbilityChartComponent implements OnInit {
  @Input() sts60s;
  @Input() nextAppointment;
  @Input() age;
  @Input() sex;

  // Line chart
  public lineChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            beginAtZero: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          scaleLabel:{
            labelString:"Sit-to-stands performed (reps/min)",
            display:true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          gridLines: {
            display: true,
            lineWidth: 1,
            color: '#dddddd',
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            lineWidth: 2,
            color: '#dddddddddddd',
          },
          ticks: {
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
            source:'data',
            minRotation:45,
            
          },
          scaleLabel: {
            display: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
      shaderArea: {
          good: 31,
      },
    },
  };
  public lineChartLabels: Label[] = [];
  public lineChartType: ChartType = 'line';
  public lineChartLegend = true;

  public pointBackgroundColors = [];
  public pointBorderColors = [];

  public lineChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'STS60',
      borderWidth: 3,
      borderColor: '#22B4FC',
      pointBackgroundColor: this.pointBackgroundColors,
      pointBorderColor: this.pointBorderColors,
      fill: false,
      pointRadius: 4,
    },
  ];
  public lineChartColors: Color[] = [
    { backgroundColor: environment.primaryColor },
  ];

  // end chart
  constructor(private sts60Service: Sts60Service) {}

  ngOnInit(): void {
    // try it??

    let goodSts = this.sts60Service.getGoodSts(this.age, this.sex);

    this.lineChartOptions.plugins.shaderArea.good = goodSts;

    BaseChartDirective.registerPlugin({
      id: 'shaderArea',
      beforeDraw(chart, easing, options ) {
        const ctx = chart.ctx;
        const chartArea = chart.chartArea;
        var scale = this.scale;

        ctx.save();
        ctx.fillStyle = 'rgba(147, 208, 147 ,0.4)';
        let yAxe = chart['scales'][chart.config.options.scales.yAxes[0].id];
        let rangesStart = yAxe.getPixelForValue(yAxe.max);
        let rangesEnd = yAxe.getPixelForValue(options.good);
        ctx.fillRect(
          chartArea.left,
          rangesStart,
          chartArea.right - chartArea.left,
          rangesEnd - rangesStart
        );
        ctx.restore();
      },
    });
    // end

    if (this.nextAppointment) {
      this.lineChartOptions.scales.xAxes[0].ticks.max =
        this.nextAppointment.appointment_date;
      this.lineChartOptions.scales.xAxes[0].ticks.suggestedMax =
        this.nextAppointment.appointment_date;
    }

    this.setChartData();
  }

  setChartData() {
    this.lineChartData[0].data = [];
    // setup chart data
    this.sts60s.slice().reverse().forEach((value, key) => {
      this.lineChartLabels.push(
        moment(value.date_recorded).format('DD-MM-YY')
      );
      this.lineChartData[0].data.push(value.sts);
      this.pointBackgroundColors.push(
        environment.ragColors[
          this.getRagForAgeAndSex(value.sts, this.age, this.sex)
        ]
      );
      this.pointBorderColors.push(
        environment.ragColors[
          this.getRagForAgeAndSex(value.sts, this.age, this.sex)
        ]
      );
    });
  }

  getRagForAgeAndSex(vo2max: any, age: number, sex: string) {
    return this.sts60Service.getRagForAgeAndSex(vo2max, age, sex);
  }
}
