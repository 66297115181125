<nav class="subnav">
  <h2 *ngIf="currentUser">
    <span class="icon-wrap">
      <fa-icon
        [icon]="['fas', 'female']"
        *ngIf="
          currentUser.gender === 'f' ||
          !currentUser.gender ||
          currentUser.gender === 'x'
        "
      ></fa-icon>
      <fa-icon
        [icon]="['fas', 'male']"
        *ngIf="currentUser.gender === 'm'"
      ></fa-icon>
    </span>
    SHAPE
  </h2>
  <a
    [routerLink]="['/health-trackers', 'healthy-eating']"
    class="subnav-next"
    aria-label="Go to Healthy Eating"
    ><span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
  ></a>
</nav>
<div>
    <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
  <div class="masthead masthead-accent" *ngIf="userShapes.length > 0">
    <div *ngIf="userShapes.length > 0">
      <div class="row">
        <div class="col-6">
          <h3 class="tracker-title">BMI &amp; WEIGHT</h3>
          <div class="row">
            <div class="col-12 pt-10">
              <img
                src="./assets/img/icon-scales.png"
                width="65"
                style="display: inline-block; vertical-align:top;"
              />
              <div style="display: inline-block">
                <span class="tracker-value tracker-value">{{
                  bmi | number: "1.1-1"
                }}</span
                ><br /><span class="tracker-value tracker-value-md"
                  >{{ userShapes[0].weight | number: "1.2-2" }}kg</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <h3 class="tracker-title">WAIST MEASUREMENT</h3>
          <div class="row">
            <div class="col-12 pt-10">
              <img
                src="./assets/img/icon-tape-measure.png"
                height="40"
                style="display: inline-block; position: relative; top: -10px"
              />&nbsp;
              <ng-container *ngIf="userShapes[0].waist">
                <span
                class="tracker-value tracker-value"
                *ngIf="environment.country != 'AUS'"
                style="display: inline-block"
                >{{ userShapes[0].waist | number: "1.0-0" }}”</span
              >
              <span
                class="tracker-value tracker-value"
                *ngIf="environment.country == 'AUS'"
                style="display: inline-block"
                >{{ userShapes[0].waist * 2.54 | number: "1.0-0"
                }}<span style="font-size: 16px">cm</span></span
              >
              </ng-container>
              <ng-container  *ngIf="!userShapes[0].waist">
                <span
                class="tracker-value tracker-value"
                style="display: inline-block"
                > - </span
              >
              </ng-container>
              


            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="masthead-subtext tracker-subtext">
          Last updated on
          {{ userShapes[0].created | dateToISO | dateFormatter: "D" | ordinal }}
          {{ userShapes[0].created | dateToISO | dateFormatter: "MMM yyyy" }}
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="userShapes.length === 0">
    <h4>You haven't added any readings yet!</h4>
  </div>
</div>

<div>
  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.update = !toggleRow.update"
    >
      <a href="" class="toggle-nav toggle-nav-expand" (click)="$event.preventDefault()"
        ><span class="icon-wrap" aria-label="Update Tracker Expand"
          ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span
      ></a>
      <div class="toggle-header-title">UPDATE TRACKER</div>
    </div>
    <div
      class="toggle-content"
      *ngIf="toggleRow.update || showAllRows"
      [attr.aria-expanded]="toggleRow.update || showAllRows"
    >
      <form
        class="bs-form tracker-form"
        name="trackerForm"
        method="post"
        (ngSubmit)="onSubmit()"
        #trackerForm="ngForm"
      >
        <div>
          <fieldset>
            <legend class="sr-only">Weight and Waist measurements</legend>
            <p class="instruction">
            </p>
            <div class="form-group">
              <label for="weight">WEIGHT</label>

              <div [hidden]="unitMode !== 'metric'">
                <p class="instruction">How much do you weight today in KG?</p>

                <input
                  type="number"
                  step="0.1"
                  name="weight"
                  class="form-control input-tracker"
                  placeholder=""
                  id="weight"
                  [min]="40"
                  [max]="350"
                  autocomplete="off"
                  #weight="ngModel"
                  [required]="!(waist.value !== '' || weight.value !== '')"
                  [ngModel]="currentWeight"
                  (change)="updateWeight('metric')"
                  [ngClass]="{ 'is-invalid': weight.touched && !weight.valid }"
                />
                <!--[attr.required]="!(waist != '' || weight != '')"-->
                <div
                  class="alert alert-danger"
                  *ngIf="weight.touched && !weight.valid"
                >
                  This must be a number between 40 and 350, inclusive.
                </div>

                <div class="text-center">
                  <br />
                  <a
                    href=""
                    (click)="onSwitchMode($event, 'weight', 'imperial');"
                    >Switch to imperial</a
                  >
                </div>
              </div>
              <div [hidden]="unitMode !== 'imperial'">
                <p class="instruction">
                  How much do you weight today in stones and pounds?
                </p>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="weightStone">Stone</label>
                      <input
                        type="number"
                        name="weightStone"
                        class="form-control input-tracker"
                        placeholder=""
                        id="weightStone"
                        #weightStone="ngModel"
                        ngModel
                        [min]="3"
                        [max]="40"
                        autocomplete="off"
                        (change)="updateWeight()"
                      />
                      <div
                        class="alert alert-danger"
                        *ngIf="weightStone.touched && !weightStone.valid"
                      >
                        This must be a number between 3 and 40, inclusive.
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="weightLbs">Pounds</label>
                      <input
                        type="number"
                        name="weightLbs"
                        class="form-control input-tracker"
                        placeholder=""
                        id="weightLbs"
                        #weightLbs="ngModel"
                        ngModel
                        [min]="0"
                        [max]="15"
                        autocomplete="off"
                        (change)="updateWeight()"
                      />
                      <div
                        class="alert alert-danger"
                        *ngIf="weightLbs.touched && !weightLbs.valid"
                      >
                        This must be a number between 0 and 15, inclusive.
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="text-center">
                  <br />
                  <a
                    href=""
                    (click)="onSwitchMode($event, 'weight',  'metric');$event.preventDefault()"
                    >Switch to metric</a
                  >
                </div>
              </div>
            </div>

            <div [hidden]="unitModeWaist !== 'imperial'">
              <div class="form-group">
                <label for="waist">WAIST MEASUREMENT</label>
                <p class="instruction">
                  What is your waist measurement in inches?
                </p>
                <input
                  type="number"
                  name="waist"
                  class="form-control input-tracker"
                  placeholder=""
                  id="waist"
                  #waist="ngModel"
                  [ngModel]="currentWaist"
                  [min]="20"
                  [max]="100"
                  autocomplete="off"
                  (change)="updateWaist()" 
                  [required]="false" 
                />
                <div
                  class="alert alert-danger"
                  *ngIf="waist.touched && !waist.valid"
                >
                  This must be a number between 20 and 100, inclusive.
                </div>
              </div>
              <div class="text-center">
                <br />
                <a href="" 
                (click)="onSwitchMode($event, 'waist',  'metric');"
                  >Switch to metric</a
                >
              </div>
            </div>
            <div [hidden]="unitModeWaist !== 'metric'">
              <div class="form-group">
                <label for="waistCm">WAIST MEASUREMENT</label>
                <p class="instruction">
                  What is your waist measurement in cms?
                </p>
                <input
                  type="number"
                  name="waistCm"
                  class="form-control input-tracker"
                  placeholder=""
                  id="waistCm"
                  #waistCm="ngModel"
                  [ngModel]="currentWaistCm"
                  [min]="51"
                  [max]="254"
                  autocomplete="off"
                  (change)="updateWaist('metric')"
                  [required]="false" 
                />
                <div
                  class="alert alert-danger"
                  *ngIf="waistCm.touched && !waistCm.valid"
                >
                  This must be a number between 51 and 254, inclusive.
                </div>
              </div>
              <div class="text-center">
                <br />
                <a href="" 
                (click)="onSwitchMode($event, 'waist', 'imperial');"
                  >Switch to imperial</a
                >
              </div>
            </div>
            <div class="form-group">
              <label for="entryDate">DATE</label>
              <p class="instructions">
                When did you do take this reading?
              </p>
              <input
                name="date"
                type="text"
                placeholder="DD/MM/YYYY"
                class="form-control input-tracker"
                bsDatepicker
                autocomplete="off"
                [bsConfig]="{
                  dateInputFormat: 'DD/MM/YYYY',
                  containerClass: 'theme-cv',
                  showWeekNumbers: false
                }"
                [(ngModel)]="entryDate"
                required
                [bsValue]="entryDate"
                [maxDate]="maxDate"
                #date="ngModel"
              />
            </div>
            <div class="tracker-submit-container">
              <button
                type="submit"
                class="btn btn-primary btn-white"
                value="Confirm"
                [disabled]="!(waist.value  || weight.value)"
              >
                <span class="icon-wrap"
                  ><fa-icon [icon]="['fas', 'check']"></fa-icon
                ></span>
                CONFIRM
              </button>
            </div>
          </fieldset>
        </div>
      </form>

      <!--span *ngIf="updatedToday()">You have already updated today</span-->
    </div>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.graph = !toggleRow.graph"
    >
      <a href="" (click)="$event.preventDefault()" class="toggle-nav toggle-nav-expand"
        ><span class="icon-wrap" aria-label="Tracker Graph Expand"
          ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span
      ></a>
      <div class="toggle-header-title">TRACKER GRAPH</div>
    </div>
    <div
      class="toggle-content"
      *ngIf="toggleRow.graph || showAllRows"
      [attr.aria-expanded]="toggleRow.graph || showAllRows"
    >
      <!-- Nav tabs -->
      <ul
        class="nav nav-tabs nav-tabs-duo justify-content-center"
        role="tablist"
      >
        <li role="presentation" [ngClass]="{ active: toggleChart.weight }">
          <a
            aria-controls="weightt"
            role="tab"
            data-toggle="tab"
            (click)="toggleChart.weight = true"
            >WEIGHT (KG)</a
          >
        </li>
        <li role="presentation" [ngClass]="{ active: !toggleChart.weight }">
          <a
            aria-controls="waistt"
            role="tab"
            data-toggle="tab"
            (click)="toggleChart.weight = false"
            >WAIST <span *ngIf="environment.country != 'AUS'">(INCHES)</span>
            <span *ngIf="environment.country == 'AUS'">(CMS)</span></a
          >
        </li>
      </ul>

      <!-- Tab panes -->
      <div class="tab-content">
        <div role="tabpanel" id="weightt" *ngIf="toggleChart.weight">
          <div
            class="chart tracker-chart"
            *ngIf="weightChartData[0].data.length > 1"
          >
            <canvas
              class="mt-3 mb-3 tracker-chart"
              baseChart
              [datasets]="weightChartData"
              [labels]="chartLabels"
              [options]="chartOptions"
              [legend]="chartLegend"
              [chartType]="chartType"
              [colors]="chartColors"
              height="200"
            >
            </canvas>
            <!--canvas id="line" class=" chart-line " chart-data="data" chart-labels="labels" chart-series="series" chart-options="options"
                              chart-dataset-override="datasetOverride" chart-click="onClick" height="200"  aria-label="Bar Chart Values {{data}}">
                              </canvas-->
          </div>
          <p class="text-center" *ngIf="weightChartData[0].data.length < 2">
            Please add more entries to see the graph
          </p>
        </div>
        <div role="tabpanel" id="waistt" *ngIf="!toggleChart.weight">
          <div
            class="chart tracker-chart"
            *ngIf="waistChartData[0].data.length > 1"
          >
            <canvas
              class="mt-3 mb-3 tracker-chart"
              baseChart
              [datasets]="waistChartData"
              [labels]="chartLabels"
              [options]="chartOptions"
              [legend]="chartLegend"
              [chartType]="chartType"
              [colors]="chartColors"
              height="200"
            >
            </canvas>
            <!--canvas id="line2" class=" chart-line " chart-data="data2" chart-labels="labels2" chart-series="series2" chart-options="options2"
                          chart-dataset-override="datasetOverride2" height="200"  aria-label="Bar Chart Values {{data}}">
                          </canvas-->
          </div>
          <p class="text-center" *ngIf="waistChartData[0].data.length < 2">
            Please add more entries to see the graph
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.entry = !toggleRow.entry"
    >
      <a href="" (click)="$event.preventDefault()" class="toggle-nav toggle-nav-expand"
        ><span class="icon-wrap" aria-label="Entry Archive Expand"
          ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span
      ></a>
      <div class="toggle-header-title">ENTRY ARCHIVE</div>
    </div>
    <div
      class="toggle-content"
      *ngIf="toggleRow.entry || showAllRows"
      [attr.aria-expanded]="toggleRow.entry || showAllRows"
    >
      <table *ngIf="userShapes.length > 0" class="table">
        <thead>
          <tr>
            <th>DATE</th>
            <th>WEIGHT</th>
            <th>WAIST</th>
            <th>BMI</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let userShape of userShapes">
            <td class="text-center">
              {{ userShape.created | dateToISO | dateFormatter: "D" | ordinal }}
              {{ userShape.created | dateToISO | dateFormatter: "MMM yyyy" }}
            </td>
            <td class="text-center">
              {{ userShape.weight }}<span *ngIf="userShape.weight">kg</span>
            </td>
            <td class="text-center" *ngIf="environment.country != 'AUS'">
              {{ userShape.waist | number: "1.2-2"
              }}<span *ngIf="userShape.waist">"</span>
            </td>
            <td class="text-center" *ngIf="environment.country == 'AUS'">
              {{ userShape.waist * 2.54 | number: "1.2-2"
              }}<span *ngIf="userShape.waist">cm</span>
            </td>
            <td class="text-center">{{ calcBmi(userShape.weight) }}</td>
            <td>
              <a
                [swal]="{
                  title: 'Are you sure you want to delete this?',
                  icon: 'warning',
                  showCancelButton: true
                }"
                (confirm)="onRemove(userShape)"
                class="pointer"
                ><span class="icon-wrap"
                  ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="userShapes.length ===0">There are no records</div>
    </div>
  </div>
  <!-- decision maker -->
  <hr class="divider-tracker" />
  <div class="text-center decision-maker-block">
    <p *ngIf="currentProgramme?.config.exerciseProgramme!=1">
      Want to set goals to reduce your BMI score?<br />
      Try our Decision Maker tool
    </p>
    <p *ngIf="currentProgramme?.config.exerciseProgramme==1">Want to set goals to increase your physical activity?<br />
      Try our Decision Maker tool</p>
    <a [routerLink]="['/decision-maker']" class="btn btn-primary"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'map-signs']"></fa-icon
      ></span>
      DECISION MAKER</a
    >
  </div>

  <app-section-nav
    [section]="'healthTrackers'"
    [active]="'shape'"
  ></app-section-nav>
</div>
