import { NotificationService } from './../../services/notification.service';
import { environment } from './../../../environments/environment';
import { CommentService } from './../../services/comment.service';
import { UserInviteService } from './../../services/user-invite.service';
import { DateHelperService } from './../../services/date-helper.service';
import { ActivityService } from './../../services/activity.service';
import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-friends-and-family-comments',
  templateUrl: './friends-and-family-comments.component.html',
  styleUrls: ['./friends-and-family-comments.component.scss'],
})
export class FriendsAndFamilyCommentsComponent implements OnInit {
  challengeType;
  startDate;
  endDate;
  comments;
  showPercent;
  isDisabled;
  commentLikes = {};
  disabledLikes = {};
  currentUser;
  currentProgramme;
  challengeActivity;
  showWeeksFull;
  isFetching;
  invites;
  buddyRef;
  environment = environment;


  @ViewChild('commentForm', { static: false }) commentForm: NgForm;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private activityService: ActivityService,
    private dateHelperService: DateHelperService,
    private userInviteService: UserInviteService,
    private commentService: CommentService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.startDate = this.route.snapshot.params.startDate;
      this.endDate = this.route.snapshot.params.endDate;
      this.challengeType = this.route.snapshot.params.challengeType;

      this.isFetching = true;
      const user = this.authService.user;
      if (user) {
        this.programmeService.currentProgramme.subscribe((programmeData) => {
          this.currentProgramme = programmeData;
          if (this.currentProgramme) {
            this.userService.userData
              .pipe(take(1))
              .subscribe((userDataResponse) => {
                if (userDataResponse) {
                  this.currentUser = userDataResponse;

                  forkJoin({
                    invites: this.userInviteService.fetchAll(),
                    asBuddy: this.userInviteService.fetchAsFriend(),
                    challengeActivity: this.activityService.getBuddyActivity(
                      this.startDate,
                      this.endDate
                    ),
                  }).subscribe(
                    (responseData) => {
                      this.challengeActivity = responseData.challengeActivity;
                      this.invites = responseData.invites;
                      if (this.currentUser.buddy == 1) {
                        this.buddyRef = responseData.asBuddy[0].user_id;
                      } else {
                        this.buddyRef = this.currentUser.user_id;
                      }
                      // get the comments now...

                      forkJoin({
                        comments: this.commentService.fetchBuddyChallenge(
                          this.buddyRef +
                            ';' +
                            this.challengeType +
                            ';' +
                            this.startDate +
                            ';' +
                            this.endDate
                        ),
                        commentLikes: this.commentService.fetchBuddyChallengeLikes(
                          this.buddyRef +
                            ';' +
                            this.challengeType +
                            ';' +
                            this.startDate +
                            ';' +
                            this.endDate
                        ),
                      }).subscribe(
                        (commentsResponse) => {
                          this.comments = commentsResponse.comments;
                          commentsResponse.commentLikes.forEach(
                            (commentLike) => {
                              this.commentLikes[
                                commentLike.comment_id
                              ] = true;
                            }
                          );
                          this.isFetching = false;
                        },
                        (error) => {
                          this.isFetching = false;
                          console.log(error);
                        }
                      );
                    },
                    (error) => {
                      this.isFetching = false;
                    }
                  );
                }
              });
          }
        });
      }
    });
  }

  onSubmit() {
    this.isDisabled = true;

    if (!this.commentForm.valid) {
      Swal.fire({
        title: "We notice you've missed something",
        text: 'Please check the form for information that has been missed',
        icon: 'error',
      });
      this.isDisabled = false;
    } else {
      this.commentService
        .createBuddyComment(this.commentForm.value.comment,  this.buddyRef +
          ';' +
          this.challengeType +
          ';' +
          this.startDate +
          ';' +
          this.endDate)
        .subscribe(
          (responseData) => {
            const comment = responseData;
            comment['first_name'] = this.currentUser.first_name;
            comment['last_name'] = this.currentUser.last_name;
            comment['avatar'] = this.currentUser.avatar;
            this.comments.push(comment);
            this.commentForm.reset();
            this.isDisabled = false;

            // add a notification
          },

          (error) => {
            console.log(error);
          }
        );
    }
  }

  likeComment(comment) {
    if (!this.disabledLikes[comment.comment_id]) {
      forkJoin({
        likeComment: this.commentService.likeComment(
          comment.comment_id,
          comment.comment_type,
          comment.comment_type_ref
        ),
        updateComment: this.commentService.updateComment(comment.comment_id, {
          likes: +comment.likes + 1,
        }),
      }).subscribe((responseData) => {
        comment.likes = +comment.likes + 1;
        this.commentLikes[comment.comment_id] =
          responseData.likeComment['user_comment_like_id'];
        this.disabledLikes[comment.comment_id] = false;

        this.notificationService
          .addNotification(
            comment.user_id,
            'like',
            this.currentUser.screen_name +
              ' liked your comment in the Buddies challenge.',
            '/app/friends-and-family-comments/' + this.challengeType + '/' + this.startDate + '/' + this.endDate ,
            0
          )
          .subscribe((notificaitonResponse) => {});
      });
    }
  }
  unlikeComment(comment) {
    if (!this.disabledLikes[comment.comment_id]) {
      this.disabledLikes[comment.comment_id] = true;
      forkJoin({
        unlikeComment: this.commentService.unlikeComment(
          this.commentLikes[comment.comment_id]
        ),
        updateComment: this.commentService.updateComment(comment.comment_id, {
          likes: +comment.likes--,
        }),
      }).subscribe((responseData) => {
        comment.likes = +comment.likes--;
        delete this.commentLikes[comment.comment_id];
        this.disabledLikes[comment.comment_id] = false;
      });
    }
  }

  sortActivity(activity, sort, reverse) {
    return this.activityService.sortActivity(activity, sort, reverse);
  }
}
