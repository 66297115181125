<nav class="subnav">
  <h2>
    <span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'search']"></fa-icon
    ></span>
    Search
  </h2>
</nav>
<div *ngIf="isFetching">
  <app-loading-spinner [inline]="1"></app-loading-spinner>
</div>
<div *ngIf="!isFetching">
  <div class="container container-pad">
    <form
      class="bs-form tracker-form"
      name="search"
      method="post"
      (ngSubmit)="onSubmitSearch()"
      #searchForm="ngForm"
    >
      <div class="input-group">
        <input
          name="query"
          type="text"
          class="form-control"
          placeholder="Search...."
          [ngModel]="query"
          #searchQuery="ngModel"
        />
        <div class="input-group-btn">
          <button type="submit" class="btn btn-primary">GO</button>
        </div>
      </div>
    </form>
    <h4 class="mt-3">
      {{ totalResults }} result<span *ngIf="totalResults != 1">s</span> for
      {{ query }}...
    </h4>
    
    <div
      *ngIf="(results[2] | filterObjectArray: { release_date: 'O' }).length > 0 && !currentUser.buddy"
    >
      <p>
        Learning Sessions ({{
          (results[2] | filterObjectArray: { release_date: "O" }).length
        }})
      </p>
      <ul class="search-list">
        <li
          *ngFor="
            let result of results[2] | filterObjectArray: { release_date: 'O' }
          "
        >
          <a
            [routerLink]="[
              '/learning-session-steps',
              'view',
              result.booster_session_step_id
            ]"
            >{{ result.title }} &gt; {{ result.step_title }}</a
          >
        </li>
      </ul>
      <hr />
    </div>
    <div
      *ngIf="
        (results[2] | filterObjectArray: { release_date: 'O' }:false:true)
          .length > 0 && !currentUser.buddy
      "
    >
      <p>
        Booster Sessions ({{
          (results[2] | filterObjectArray: { release_date: "O" }:false:true)
            .length
        }})
      </p>
      <ul class="search-list">
        <li
          *ngFor="
            let result of results[2]
              | filterObjectArray: { release_date: 'O' }:false:true
          "
        >
          <a
            [routerLink]="[
              '/booster-session-steps',
              'view',
              result.booster_session_step_id
            ]"
            >{{ result.title }} &gt; {{ result.step_title }}</a
          >
        </li>
      </ul>
      <hr />
    </div>
    <div *ngIf="results[1].length > 0">
      <p>Useful Information ({{ results[1].length }})</p>
      <ul class="search-list">
        <li *ngFor="let result of results[1]">
          <a
            [routerLink]="[
              '/educational-reading',
              'page',
              result.content_page_id
            ]"
            >{{ result.title }} &gt; {{ result.page_title }}</a
          >
        </li>
      </ul>
      <hr />
    </div>
    <div *ngIf="results[3].length > 0">
      <p>Help ({{ results[3].length }})</p>
      <ul class="search-list">
        <li *ngFor="let result of results[3]">
          <a [routerLink]="['/help', 'page', result.content_page_id]"
            >{{ result.title }} &gt; {{ result.page_title }}</a
          >
        </li>
      </ul>
      <hr />
    </div>
  </div>
</div>
