import { NgForm } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { CholesterolService } from './../../services/cholesterol.service';
import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { environment } from './../../../environments/environment';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-cholesterol',
  templateUrl: './cholesterol.component.html',
  styleUrls: ['./cholesterol.component.scss'],
})
export class CholesterolComponent implements OnInit {
  currentUser;
  currentProgramme;
  userCholesterols = [];
  userCholesterol;
  isFetching = false;
  errors = [];

  toggleRow = {
    update: false,
    graph: false,
    entry: false,
  };

  toggleChart = {
    total: true,
    ldl: false,
    hdl: false,
  };
  data = [];
  data2 = [];
  data3 = [];

  entryDate = new Date();
  maxDate = new Date();

  @ViewChild('trackerForm', { static: false }) trackerForm: NgForm;

  // chart1

  // Line chart
  public totalChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            beginAtZero: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          gridLines: {
            display: true,
            lineWidth: 1,
            color: '#95989A',
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            lineWidth: 2,
            color: '#95989A',
          },
          type: 'time',
          ticks: {
            autoSkip: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          time: {
            parser: 'YYYY-MM-DD HH:mm:ss',
            unit: 'day',
            displayFormats: {
              day: 'MMM-DD',
            },
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public totalChartLabels: Label[] = [];
  public totalChartType: ChartType = 'line';
  public totalChartLegend = true;

  public totalChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'Total',
      borderWidth: 1,
      borderColor: environment.chartDefaults.colors[0],
      fill: true,
      pointRadius: 5,
    },
  ];
  public totalChartColors: Color[] = [
    { backgroundColor: environment.primaryColor },
  ];

  // LDL
  public ldlChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'LDL',
      borderWidth: 1,
      borderColor: environment.chartDefaults.colors[0],
      fill: true,
      pointRadius: 5,
    },
  ];
  // HDL
  public hdlChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'HDL',
      borderWidth: 1,
      borderColor: environment.chartDefaults.colors[0],
      fill: true,
      pointRadius: 5,
    },
  ];
  //
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private cholesterolService: CholesterolService
  ) {}

  ngOnInit(): void {
    this.isFetching = true;
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (this.currentProgramme) {
          // this.barChartColors[0].backgroundColor = this.currentProgramme.config.primaryColor;

          forkJoin({
            userCholesterols: this.cholesterolService.fetchAll(),
          }).subscribe(
            (responseData) => {
              this.isFetching = false;
              this.userCholesterols = responseData.userCholesterols;
              if (this.userCholesterols.length > 0) {
                this.userCholesterol = this.userCholesterols[0];
              }
              this.setChartData(this.userCholesterols);
              // end
            },
            (error) => {
              this.isFetching = false;
              this.errors.push(error.message);
            }
          );
        }
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });
    }
  }

  onRemove(userCholesterol) {
    this.cholesterolService
      .delete(userCholesterol.user_cholesterol_id)
      .subscribe(
        (responseData) => {
          const index = this.userCholesterols.indexOf(userCholesterol);
          this.userCholesterols.splice(index, 1);
          this.setChartData(this.userCholesterols);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  onSubmit() {
    // this.trackerForm.value.date

    if (!this.trackerForm.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.cholesterolService
        .create(
          this.trackerForm.value.cholesterol,
          this.trackerForm.value.ldl,
          this.trackerForm.value.hdl,
          moment(this.entryDate).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
            this.userCholesterols.unshift(responseData);
            this.userCholesterols.sort(this.sortByCreated);
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });

            this.trackerForm.resetForm();
            this.entryDate = new Date();
            this.setChartData(this.userCholesterols);
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  // end chart
  updatedToday() {
    let hasUpdated = false;
    this.userCholesterols.forEach((userCholesterol) => {
      if (
        moment(userCholesterol.created).format('MM DD YY') ===
        moment().format('MM DD YY')
      ) {
        hasUpdated = true;
      }
    });
    return hasUpdated;
  }

  setChartData(trackerData) {
    this.totalChartData[0].data = [];
    this.ldlChartData[0].data = [];
    this.hdlChartData[0].data = [];
    this.totalChartLabels = [];
    // setup chart data
    trackerData.forEach((value, key) => {
      this.totalChartLabels.push(moment(value.created).format('YYYY-MM-DD'));
      this.totalChartData[0].data.push(value.cholesterol);
      this.ldlChartData[0].data.push(value.ldl);
      this.hdlChartData[0].data.push(value.hdl);
    });
  }
  sortByCreated(a, b) {
    if (a.created > b.created) {
      return -1;
    }
    if (a.created < b.created) {
      return 1;
    }
    return 0;
  }
}
