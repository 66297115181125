import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DateHelperService {
  constructor() {}

  getWeekFor(dateTime) {
    const days = [];
    const monday = moment(dateTime).startOf('isoWeek');
    days.push(monday.toDate());
    for (let i = 1; i < 7; i++) {
      days.push(moment(monday).add(i, 'days').toDate());
    }

    return days; // returns a list of moment objects
  }

  getWeekDayFor(dateTime) {
    const days = [];
    const monday = moment(dateTime).startOf('isoWeek');
    days.push(monday.toDate());

    for (let i = 1; i < 5; i++) {
      days.push(moment(monday).add(i, 'days').toDate());
    }

    return days; // returns a list of moment objects
  }

  shiftWeek(add, dateTime) {
    // this will just increment or decrement the week
    const sunday = moment(dateTime).startOf('isoWeek');
    sunday.add(1, 'd');

    if (add) {
      sunday.add(1, 'w');
    } else {
      sunday.subtract(1, 'w');
    }

    return sunday; // returns a moment object
  }

  getWeekDate(dateTime) {
    const sunday = moment(dateTime).startOf('isoWeek');

    const monday = sunday.add({ day: 1 }).clone();

    return 'Week Commencing ' + monday.format('Do'); // a nicely formatted string of the week commencing
  }

  getStartOfWeek(dateTime) {
    const sunday = moment(dateTime).startOf('isoWeek');

    const monday = sunday.add({ day: 1 }).clone();

    return monday; // the monday that started the week as a moment object
  }
}
