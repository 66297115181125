import { SearchService } from './../services/search.service';
import { AuthService } from './../auth/auth.service';
import { UserService } from './../services/user.service';
import { ProgrammeService } from './../services/programme.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  results;
  query;
  isFetching;
  currentProgramme;
  currentUser;
  totalResults = 0;

  @ViewChild('searchForm', { static: false }) searchForm: NgForm;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private searchService: SearchService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // are we logged in?
    this.isFetching = true;
    this.authService.user.subscribe((user) => {
      if (user) {
        this.programmeService.currentProgramme.subscribe((programmeData) => {
          if (programmeData) {
            this.currentProgramme = programmeData;
            this.route.queryParams.subscribe((params) => {
              this.query = params['q'];
              this.searchService
                .fetchResults(this.query, this.currentProgramme.programme_id)
                .subscribe(
                  (responseData) => {
                    this.results = responseData;
                    this.isFetching = false;

                    if (this.currentUser.buddy) {
                      this.totalResults = this.results[1].length;
                    } else {
                      this.totalResults = this.results[0];
                    }
                  },
                  (error) => {
                    this.isFetching = false;
                  }
                );
            });
          }
        });
        this.userService.userData.subscribe((userDataResponse) => {
          this.currentUser = userDataResponse;
        });
      }
    });
  }

  onSubmitSearch() {
    // this.router.navigate
    this.router.navigate(['/search'], {
      queryParams: { q: this.searchForm.value.query },
    });
  }
}
