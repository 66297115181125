import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as moment from 'moment';
import { Color, Label } from 'ng2-charts';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivityService } from 'src/app/services/activity.service';
import { AppointmentService } from 'src/app/services/appointment.service';
import { ProgrammeService } from 'src/app/services/programme.service';
import { TargetService } from 'src/app/services/target.service';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-m3-sitting',
  templateUrl: './m3-sitting.component.html',
  styleUrls: ['./m3-sitting.component.scss'],
})
export class M3SittingComponent implements OnInit {
  currentUser;
  currentProgramme;
  date = new Date();
  maxDate = new Date();
  allSittings;
  currentHours = 0;
  currentMins = 0;
  nextAppointment;
  sittingTarget = 6;
  latestSittings:any = [];
  sittingAverage:any;
  showAllEntries = false;
  sittingWeekStart;

  @ViewChild('entryForm', { static: false }) entryForm: NgForm;

  // end chart
  @ViewChild('addModal') public addModal: ModalDirective;

  constructor(
    private authService: AuthService,
    private programmeService: ProgrammeService,
    private router: Router,
    private userService: UserService,
    private userSettingsService: UserSettingsService,
    private activityService: ActivityService,
    private targetService: TargetService,
    private modalService: BsModalService,
    private appointmentService: AppointmentService
  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {});

      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
        if(this.currentUser.device){
          if(this.currentUser.device == 'fitbit'){
            this.sittingTarget = 9;
          }
        }
      });

      forkJoin({
        userSettings: this.userSettingsService.fetchAll(),
        allTimeSitting: this.activityService.getActivityBetween(
          moment().subtract(1, 'years').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ),
        nextAppointment: this.appointmentService.getNextAppointment(),
      }).subscribe((responseData) => {

        let allAllSittings:any = [];
        this.allSittings = [];
        responseData.allTimeSitting.forEach((sitting)=>{
          if(+sitting.sedentary_minutes>0 && +sitting.sedentary_minutes<960){
            this.allSittings.push(sitting);
          }
        });
       

        this.allSittings.forEach((activity)=>{
          if(activity.sedentary_minutes && this.latestSittings.length < 6){
          this.latestSittings.push(activity);
          }
        });

        if (this.allSittings.length > 0) {
          if (
            moment(this.allSittings[0].activity_date) >
            moment().subtract(1, 'week')
          ) {
            this.sittingWeekStart = moment()
              .subtract(6, 'day')
              .format('Do MMM');
          } else {
            this.sittingWeekStart = moment(this.allSittings[0].activity_date)
              .subtract(6, 'day')
              .format('Do MMM');
          }
        }

        this.sittingAverage = this.activityService.getSittingAverage(this.allSittings);
        
        if (this.allSittings[0]) {
          let hours = Math.floor(this.allSittings[0].sedentary_minutes / 60);
          let minutes = Math.floor(this.allSittings[0].sedentary_minutes % 60);
          this.currentMins = minutes;
          this.currentHours = hours;

          if (
            this.allSittings[0].sedentary_minutes == 0 ||
            !this.allSittings[0].sedentary_minutes
          ) {
            this.addModal.show();
          }
        } else {
          this.addModal.show();
        }

        this.nextAppointment = responseData.nextAppointment;

      
      });
    }
  }

  onSubmitEntryForm() {
    // save the steps
    const totalMins =
      +this.entryForm.value.hours * 60 + +this.entryForm.value.mins;
    this.activityService
      .saveSitting(totalMins, this.entryForm.value.date)
      .subscribe((responseData) => {
        // refresh the route..
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/sitting']));

        Swal.fire({
          icon: 'success',
          title: 'Entry Updated',
          text: 'Your sitting time has been added',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      });
  }

  getSittingRag(minutes, device){
    return this.activityService.getSittingRag(minutes, device);
  }



  onDelete(sitting) {
    
    this.activityService
      .deleteSitting(sitting.user_activity_id)
      .subscribe((responseData) => {
        //this.activityService.clearCache();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/sitting']));
      });
    
    /*
    this.activityService.delete(sleep.user_sleep_id).subscribe(
      (responseData) => {
        this.sleepService.clearCache();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/sleep']));
      },
      (error) => {
        console.log(error);
      }
    );*/
  }

  getUpdatedTarget(){
    if(this.sittingAverage  - (this.sittingTarget*60)  < 15){
      return this.sittingAverage  - (this.sittingTarget*60);
    }
    else{

      return 15;
    }
  }



  syncSteps(force) {
    force = typeof force === 'undefined' ? '0' : force;
    this.activityService.getActivity(force).subscribe((responseData) => {
      this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigate(['/m3', 'sitting']));
    });
  }
}
