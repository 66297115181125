<div class="container m3" *ngIf="currentUser">
  <app-m3-header class="d-none d-sm-block"></app-m3-header>
  <app-m3-mobile-navbar
    [pageTitle]="'Sleep'"
    class="d-block d-sm-none"
  ></app-m3-mobile-navbar>
  <nav class="subnav d-block d-sm-none">
    <h2>Overview</h2>
  </nav>
  <h1 class="m3-title mt-3 d-none d-sm-block">
    <a class="add-item-btn" (click)="addModal.show()"
      ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
    ></a>
    <app-m3-icon [icon]="'sleep'"></app-m3-icon> Sleep
  </h1>

  <button
    (click)="addModal.show()"
    class="btn btn-mobile-add d-block d-sm-none"
  >
    <app-m3-icon [icon]="'more'" [style]="'color'"></app-m3-icon> Add a Sleep
    Entry
  </button>

  <div class="card" *ngIf="allSleeps">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="info-stat info-stat-rag mb-2" *ngIf="sleepAverage">
            <div class="info-stat-rag-current">
              <app-m3-icon [icon]="'zzz'"></app-m3-icon>
              <span class="info-stat-label" *ngIf="sleepWeekStart"
                    >My average daily sleep time over the w/c {{sleepWeekStart}}</span
                  >
              <span
                class="info-stat-stat text-rag-{{
                  getSleepRagForAge(sleepAverage, age)
                }}"
                *ngIf="allSleeps[0]"
                >{{ sleepAverage | minsToHours }}</span
              >
              <span class="info-stat-label"></span>
            </div>

            <div class="rag-details">
              <div class="rag-details-target">
                Target:
                <span class="text-rag-green">{{ getAgeSleep(age) }}</span> hours/day
              </div>
              <div class="rag-details-history" *ngIf="allSleeps">
                Daily History:
                <div class="rag-history">
                  <ng-template #popTemplateSleep>
                    <div>
                      <div>Range:</div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-green mr-1"></span>
                        <span *ngIf="age < 26"> 7-9 hours/day </span>
                        <span *ngIf="age > 25 && age < 65">
                          7-9 hours/day
                        </span>
                        <span *ngIf="age > 64"> 7-8 hours/day </span>
                      </div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-amber mr-1"></span>

                        <span *ngIf="age < 26"> 6 or 10-11 hours/day </span>
                        <span *ngIf="age > 25 && age < 65">
                          6 or 10 hours/day
                        </span>
                        <span *ngIf="age > 64"> 5-6 or 9 hours/day </span>
                      </div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-red mr-1"></span>

                        <span *ngIf="age < 26">
                          &lt;6 or ≥11 hours/day
                        </span>
                        <span *ngIf="age > 25 && age < 65">
                          &lt;6 or ≥10 hours/day
                        </span>
                        <span *ngIf="age > 64">
                          &lt;5 or ≥9 hours/day
                        </span>
                      </div>
                    </div>
                    </ng-template
                  >
                  <ng-container
                    *ngFor="let sleep of allSleeps.slice();
                    let index = index
                  "
                >
                  <div
                    *ngIf="index > allSleeps.length - 6"
                      [tooltip]="
                        (sleep.sleep | minsToHours) +
                        ' on ' +
                        (sleep.date_recorded | dateFormatter : 'Do MMM YYYY')
                      "
                      class="rag-circle bg-rag-{{
                        getSleepRagForAge(sleep.sleep, age)
                      }}"
                    ></div>
                  </ng-container>
                  <div class="rag-circle pointer" [popover]="popTemplateSleep">
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['fas', 'info']"></fa-icon
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info-stat" *ngIf="sleepScore">
            <app-m3-icon [icon]="'clock-z'"></app-m3-icon>
            <span class="info-stat-label"
              >My Morningness-eveningness score</span
            >
            <span class="info-stat-stat">
              <ng-container *ngIf="!sleepScore.auto_meq">
                <a class="add-item-btn" (click)="sleepScoreModal.show()"
                  ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
                ></a>
              </ng-container>
              <ng-container *ngIf="sleepScore.auto_meq">
                <a  (click)="sleepScoreModal.show()" class="pointer"[ngClass]="{'text-rag-green': sleepScore.chronotype=='definite morning' || sleepScore.chronotype =='moderate morning' || sleepScore.chronotype =='intermediate', 'text-rag-red': sleepScore.chronotype=='definite evening' || sleepScore.chronotype=='moderate evening'}"
                >
                {{ sleepScore.auto_meq }}</a>
              </ng-container>
            </span>
          </div>

          <app-m3-sleep-chart
            *ngIf="allSleeps"
            [allSleeps]="allSleeps"
            [age]="age"
          ></app-m3-sleep-chart>

          <div class="text-center" *ngIf="currentUser.device != 'manual'">
            <button
              class="btn btn-white"
              (click)="syncSteps(1)"
              style="margin: 10px 0 0 0; font-size: 12px"
            >
              <span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'sync']"></fa-icon
              ></span>
              SYNC DATA
            </button>
          </div>

          <h3 class="chart-title text-center mt-4" *ngIf="allSleeps">Your Entries</h3>
          <div class="table-responsive">
            <table *ngIf="allSleeps" class="table">
              <thead>
                <tr>
                  <th>DATE</th>
                  <th>SLEEP TIME</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let sleep of allSleeps; let index = index;">

                <tr *ngIf="index<10 || showAllEntries">
                  <td class="text-center">
                    {{ sleep.date_recorded | dateFormatter : "MMM Do YYYY" }}
                  </td>
                  <td class="text-center">
                    {{ sleep.sleep | minsToHours}}
                  </td>

                  <td>
                    <a
                      [swal]="{
                        title: 'Are you sure you want to delete this?',
                        icon: 'warning',
                        showCancelButton: true
                      }"
                      (confirm)="onDelete(sleep)"
                      class="pointer"
                      ><span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                    ></a>
                  </td>
                </tr>

              </ng-container>
              </tbody>
            </table>
            <div class="text-center">
              <button
              *ngIf="!showAllEntries && allSleeps.length>10"
               
                class="btn btn-primary btn-sm btn-xs"
                (click)="showAllEntries = true"
              >
                Show all
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="info-stat  mb-2" *ngIf="age">
            <app-m3-icon [icon]="'target'"></app-m3-icon>
            <span class="info-stat-label"
              >
              <span *ngIf="sleepAverage && isHittingTarget()">
                You are meeting your sleep target of 
              </span>
              
              <span *ngIf="!isHittingTarget()">
                You are <span [ngClass]="{'text-rag-green':isHittingTarget(), 'text-rag-amber':!isHittingTarget() && +getTargetOffsetMins() <=60, 'text-rag-red':!isHittingTarget() && +getTargetOffsetMins() >60 }">{{getTargetOffset()}}</span> your sleep duration target</span>
              </span
            >
            <span class="info-stat-stat text-rag-green"  style="min-width: 50px;">{{ getAgeSleep(age) }}</span>
            <span class="info-stat-label">hours/day</span>
          </div>
          <div class="info-stat mb-2" *ngIf="sleepScore">
            <app-m3-icon [icon]="'clock-z'" class=""></app-m3-icon>
            <span class="info-stat-label">My chronotype is</span>
            <span class="info-stat-stat">&nbsp;</span
            ><span class="info-stat-label">
              <ng-container *ngIf="!sleepScore.chronotype">
                <a class="add-item-btn" (click)="sleepScoreModal.show()"
                  ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
                ></a>
              </ng-container>
              <ng-container *ngIf="sleepScore.chronotype">
                <a  (click)="sleepScoreModal.show()" class="pointer"
                [ngClass]="{'text-rag-green': sleepScore.chronotype=='definite morning' || sleepScore.chronotype =='moderate morning' || sleepScore.chronotype =='intermediate', 'text-rag-red': sleepScore.chronotype=='definite evening' || sleepScore.chronotype=='moderate evening'}"
                >
                {{ sleepScore.chronotype  | titlecase }}</a>
              </ng-container>
            </span>
          </div>

          <h3 class="chart-title text-center mt-3">Sleep Reference Values</h3>
          <table class="table m3-table " *ngIf="currentUser">
            <thead>
              <tr>
                <th>Age Range</th>
                <th>Recommended hours of sleep</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="this.age < 26">
                <td
                  [ngClass]="{
                    'text-rag-green': this.age < 26
                  }"
                >
                  18-25
                </td>
                <td
                  [ngClass]="{
                    'text-rag-green': this.age < 26
                  }"
                >
                  7-9 hours
                </td>
              </tr>
              <tr *ngIf="this.age > 25 && this.age < 65">
                <td
                  [ngClass]="{
                    'text-rag-green': this.age > 25 && this.age < 65
                  }"
                >
                  26-64
                </td>
                <td
                  [ngClass]="{
                    'text-rag-green': this.age > 25 && this.age < 65
                  }"
                >
                  7-9 hours
                </td>
              </tr>
              <tr *ngIf="this.age > 64">
                <td
                  [ngClass]="{
                    'text-rag-green': this.age > 64
                  }"
                >
                  &gt;=65
                </td>
                <td
                  [ngClass]="{
                    'text-rag-green': this.age > 64
                  }"
                >
                  7-8 hours
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    bsModal
    #addModal="bs-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-static-name"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left">Update Sleep</h4>
          <button
            type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="addModal.hide()"
          >
            <span aria-hidden="true" class="visually-hidden">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form
            name="entryForm"
            class="bs-form tracker-form"
            (ngSubmit)="onSubmitEntryForm()"
            #entryForm="ngForm"
          >
            <p class="instruction">
              You can update your hours and minutes for today or any previous
              days using the form below.
            </p>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="weight">HOURS</label>
                  <input
                    type="number"
                    style="font-size: 28px"
                    name="hours"
                    class="form-control input-tracker input-tracker-xsm"
                    placeholder=""
                    id="hours"
                    [ngModel]="currentHours"
                    min="0"
                    max="1000000000"
                    autocomplete="off"
                    ng-required=""
                    #hours="ngModel"
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="hours.touched && hours.invalid"
                  >
                    This must be a number below 1000000000.
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="weight">MINUTES</label>
                  <input
                    type="number"
                    style="font-size: 28px"
                    name="mins"
                    class="form-control input-tracker input-tracker-xsm"
                    placeholder=""
                    id="mins"
                    [ngModel]="currentMins"
                    min="0"
                    max="1000000000"
                    autocomplete="off"
                    ng-required=""
                    #mins="ngModel"
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="mins.touched && mins.invalid"
                  >
                    This must be a number below 1000000000.
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="number">DATE</label>
                  <input
                    name="date"
                    type="text"
                    placeholder="DD/MM/YYYY"
                    class="form-control input-tracker input-tracker-sm"
                    bsDatepicker
                    autocomplete="off"
                    [bsConfig]="{
                      dateInputFormat: 'DD/MM/YYYY',
                      containerClass: 'theme-cv',
                      showWeekNumbers: false
                    }"
                    [(ngModel)]="date"
                    required
                    [bsValue]="date"
                    [maxDate]="maxDate"
                    #dateInput="ngModel"
                  />
                </div>
              </div>
            </div>
            <div class="tracker-submit-container">
              <button
                type="submit"
                role="button"
                class="btn btn-primary btn-lg btn-white"
              >
                UPDATE
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    bsModal
    #sleepScoreModal="bs-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-static-name"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left">Update Scores</h4>
          <button
            type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="sleepScoreModal.hide()"
          >
            <span aria-hidden="true" class="visually-hidden">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form
            name="sleepScoreForm"
            class="bs-form tracker-form"
            (ngSubmit)="onSubmitSleepScoreForm()"
            #sleepScoreForm="ngForm"
          >
            <p class="instruction">
              Please take the questionnaire at
              <a
                href="https://qxmd.com/calculate/calculator_829/morningness-eveningness-questionnaire-meq"
                target="_blank"
                >https://qxmd.com/calculate/calculator_829/morningness-eveningness-questionnaire-meq</a
              >
              then report your scores here.
            </p>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="weight">AutoMEQ Score</label>
                  <input
                    type="number"
                    style="font-size: 28px"
                    name="auto_meq"
                    class="form-control input-tracker input-tracker-xsm"
                    placeholder=""
                    id="auto_meq"
                    [ngModel]
                    min="0"
                    max="1000000000"
                    autocomplete="off"
                    ng-required=""
                    #auto_meq="ngModel"
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="auto_meq.touched && auto_meq.invalid"
                  >
                    This must be a number below 1000000000.
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="weight">Chronotype</label>
                  <select
                    class="form-control input-tracker input-tracker-xsm"
                    name="chronotype"
                    id="chronotype"
                    [ngModel]
                    style="font-size: 18px"
                    #chronotype="ngModel"
                  >
                    <option value="definite evening">Definite evening</option>
                    <option value="moderate evening">Moderate evening</option>
                    <option value="intermediate">Intermediate</option>
                    <option value="moderate morning">Moderate morning</option>
                    <option value="definite morning">Definite morning</option>
                  </select>
                  <div
                    class="alert alert-danger"
                    *ngIf="chronotype.touched && chronotype.invalid"
                  >
                    enter a valid chronotype
                  </div>
                </div>
              </div>
            </div>
            <div class="tracker-submit-container">
              <button
                type="submit"
                role="button"
                class="btn btn-primary btn-lg btn-white"
              >
                UPDATE
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="clear"></div>
</div>
