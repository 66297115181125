<div
  class="container app-container top-container mobile-nav"
  *ngIf="currentProgramme && programmeContent && currentUser"
>
  <span>
    <!-- Static navbar -->
    <nav class="navbar navbar-default navbar-bs justify-content-center">
      <button
        class="btn-navbar btn-side-menu text-accent"
        id="app-menu-btn"
        (click)="onCloseMobileMenu($event)"
        aria-label="Hide Menu"
      >
        <span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'bars']"></fa-icon
        ></span>
      </button>
      <h1>MENU</h1>
      <button
        class="btn-user-menu"
        aria-label="User Menu"
        (click)="onShowUserMenu($event)"
      >
        <img
          [src]="
            (currentUser.avatar !== null &&
              environment.avatarUrl + '/assets/avatar/' + currentUser.avatar) ||
            'assets/img/default-avatar.png'
          "
          class="img-circle img-avatar"
          width="44"
          height="44"
          alt="User Avatar"
        />
      </button>
    </nav>
    <div class="container menu-container">
      <ng-container *ngIf="currentProgramme.config.m3 != 1">
        <nav class="side-menu" id="accordion" role="tablist">
          <div class="side-menu-item">
            <form
              class="bs-form tracker-form"
              name="search"
              method="post"
              (ngSubmit)="onSubmitSearch()"
              #searchForm="ngForm"
            >
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search...."
                  ngModel
                  #searchQuery="ngModel"
                  aria-label="Search Query"
                  name="query"
                />
                <div class="input-group-btn">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    aria-label="Submit search"
                  >
                    GO
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="side-menu-item">
            <a
              [routerLink]="['/dashboard']"
              class="no-sub collapsed"
              data-toggle="collapse"
              data-parent="#accordion"
              href="#"
              aria-expanded="false"
              role="tab"
              id="headingOne"
            >
              <span class="icon-wrap item-icon"
                ><fa-icon [icon]="['fas', 'home']"></fa-icon
              ></span>
              <span class="mobile-nav-label">HOME</span>
            </a>
          </div>

          <div class="side-menu-item" *ngIf="!currentUser.buddy">
            <a
              [ngClass]="{ collapsed: !menuToggle.learning }"
              (click)="menuToggle.learning = !menuToggle.learning"
              data-toggle="collapse"
              data-parent="#accordion"
              aria-expanded="false"
              aria-controls="collapseThree"
              role="tab"
              id="headingThree"
            >
              <span class="icon-wrap item-icon"
                ><fa-icon [icon]="['fas', 'graduation-cap']"></fa-icon
              ></span>
              <span class="mobile-nav-label">LEARNING SESSIONS</span>
              <span
                class="icon-wrap toggle-chevron"
                *ngIf="!menuToggle.learning"
                ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
              ></span>
            </a>
            <ul
              *ngIf="menuToggle.learning"
              id="collapseThree"
              class="side-menu-sub-menu booster-session-nav-menu"
              role="tabpanel"
              aria-labelledby="headingThree"
            >
              <ng-container
                *ngFor="let boosterSession of programmeContent.booster_sessions"
              >
                <li
                  *ngIf="
                    boosterSession.release_date == 'O' ||
                    boosterSession.release_date == 'EPL'
                  "
                >
                  <a
                    [routerLink]="[
                      '/learning-sessions',
                      'index',
                      boosterSession.booster_session_id
                    ]"
                    [ngClass]="{
                      disabled:
                        boosterSession.status == 'unavailable' ||
                        (boosterSession.release_date == 'EPL' && !stepTarget),
                      completed: boosterSession.status == 'completed'
                    }"
                    [attr.disabled]="
                      boosterSession.release_date == 'EPL' && !stepTarget
                    "
                  >
                    <span
                      class="icon-wrap"
                      *ngIf="boosterSession.status == 'completed'"
                      ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
                    ></span>
                    <span
                      class="icon-wrap"
                      *ngIf="
                        ((boosterSession.status == 'available' ||
                          boosterSession.status == 'started') &&
                          boosterSession.release_date != 'EPL') ||
                        (boosterSession.release_date == 'EPL' &&
                          stepTarget &&
                          boosterSession.status != 'completed')
                      "
                      ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
                    ></span>
                    <span
                      class="icon-wrap"
                      *ngIf="
                        boosterSession.status == 'unavailable' ||
                        (boosterSession.release_date == 'EPL' && !stepTarget)
                      "
                      ><fa-icon [icon]="['fas', 'circle']"></fa-icon
                    ></span>
                    <span class="subnav-label">{{
                      boosterSession.booster_session_title
                    }}</span></a
                  >
                </li>
              </ng-container>
              <ng-container
                *ngFor="let boosterSession of programmeContent.extra_sessions"
              >
                <li
                  *ngIf="
                    userSessions.indexOf(boosterSession.booster_session_id) !=
                    -1
                  "
                >
                  <a
                    [routerLink]="[
                      '/learning-sessions',
                      'index',
                      boosterSession.booster_session_id
                    ]"
                    ><span class="icon-wrap"
                      ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
                    ></span>
                    <span class="subnav-label">{{
                      boosterSession.title
                    }}</span></a
                  >
                </li>
              </ng-container>
              <li
                *ngIf="
                  programmeContent.extra_sessions.length > 0 &&
                  checkSessionsAvailable(
                    programmeContent.extra_sessions,
                    userSessions
                  )
                "
              >
                <a [routerLink]="['/extra-sessions']"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'plus-circle']"></fa-icon
                  ></span>
                  Add Sessions</a
                >
              </li>
            </ul>
          </div>

          <div class="side-menu-item" *ngIf="!currentUser.buddy">
            <a
              [ngClass]="{ collapsed: !menuToggle.booster }"
              (click)="menuToggle.booster = !menuToggle.booster"
              data-toggle="collapse"
              data-parent="#accordion"
              aria-expanded="false"
              aria-controls="collapseThreeB"
              role="tab"
              id="headingThreeB"
            >
              <span class="icon-wrap item-icon"
                ><fa-icon [icon]="['fas', 'bolt']"></fa-icon
              ></span>
              <span class="mobile-nav-label">BOOSTER SESSIONS</span>
              <span class="icon-wrap toggle-chevron" *ngIf="!menuToggle.booster"
                ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
              ></span>
            </a>
            <ul
              *ngIf="menuToggle.booster"
              id="collapseThreeB"
              class="side-menu-sub-menu booster-session-nav-menu"
              role="tabpanel"
              aria-labelledby="headingThreeB"
            >
              <ng-container
                *ngFor="let boosterSession of programmeContent.booster_sessions"
              >
                <li
                  *ngIf="
                    boosterSession.release_date != 'O' &&
                    boosterSession.release_date != 'EPL'
                  "
                >
                  <a
                    [routerLink]="[
                      '/booster-sessions',
                      'index',
                      boosterSession.booster_session_id
                    ]"
                    [ngClass]="
                      { unavailable: 'disabled', completed: 'completed' }[
                        boosterSession.status
                      ]
                    "
                    [ngClass]="{
                      disabled: boosterSession.status == 'unavailable'
                    }"
                    [attr.disabled]="boosterSession.status == 'unavailable'"
                    ><span
                      class="icon-wrap"
                      *ngIf="boosterSession.status == 'completed'"
                      ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
                    ></span>
                    <span
                      class="icon-wrap"
                      *ngIf="
                        boosterSession.status == 'available' ||
                        boosterSession.status == 'started'
                      "
                      ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
                    ></span>
                    <span
                      class="icon-wrap"
                      *ngIf="boosterSession.status == 'unavailable'"
                      ><fa-icon [icon]="['fas', 'circle']"></fa-icon
                    ></span>
                    <span class="subnav-label">{{
                      boosterSession.booster_session_title
                    }}</span></a
                  >
                </li>
              </ng-container>
              <ng-container
                *ngFor="
                  let boosterSession of programmeContent.adhoc_booster_sessions
                "
              >
                <li
                  *ngIf="
                    boosterSession.release_date <= sToday &&
                    boosterSession.close_date >= sToday
                  "
                >
                  <a
                    [routerLink]="[
                      '/booster-sessions',
                      'index',
                      boosterSession.booster_session_id
                    ]"
                    ><i
                      class="fa fa-check-circle-o text-accent"
                      *ngIf="boosterSession.status == 'completed'"
                    ></i>
                    <i
                      class="fa fa-arrow-circle-o-right"
                      *ngIf="boosterSession.status != 'completed'"
                    ></i>
                    <span class="subnav-label">{{
                      boosterSession.booster_session_title
                    }}</span></a
                  >
                </li>
              </ng-container>
            </ul>
          </div>
          <div class="side-menu-item">
            <a
              [ngClass]="{ collapsed: !menuToggle.steps }"
              (click)="menuToggle.steps = !menuToggle.steps"
              data-toggle="collapse"
              data-parent="#accordion"
              aria-expanded="false"
              aria-controls="collapseTwo"
              role="tab"
              id="headingTwo"
            >
              <span class="icon-wrap item-icon"
                ><fa-icon [icon]="['fas', 'walking']"></fa-icon
              ></span>
              <span class="mobile-nav-label">ACTIVITY</span>
              <span class="icon-wrap toggle-chevron" *ngIf="!menuToggle.steps"
                ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
              ></span>
            </a>
            <ul
              *ngIf="menuToggle.steps"
              id="collapseTwo"
              class="side-menu-sub-menu"
              role="tabpanel"
              aria-labelledby="headingTwo"
            >
              <li
                *ngIf="
                  currentUser.mobility3 != '0' && currentUser.mobility1 != 'f'
                "
              >
                <a [routerLink]="['/steps']">
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'shoe-prints']"></fa-icon
                  ></span>
                  <span class="subnav-label">My Steps</span>
                </a>
              </li>
              <li
                *ngIf="
                  !currentUser.buddy &&
                  currentProgramme.config.teamBased == 1 &&
                  currentUser.mobility3 != '0' &&
                  currentUser.mobility1 != 'f'
                "
              >
                <a [routerLink]="['/challenges']">
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'trophy']"></fa-icon
                  ></span>
                  <span class="subnav-label">Challenges</span>
                </a>
              </li>
              <li
                *ngIf="
                  !currentUser.buddy &&
                  currentProgramme.config.teamBased != 1 &&
                  currentUser.mobility3 != '0' &&
                  currentUser.mobility1 != 'f'
                "
              >
                <a [routerLink]="['/challenges-individual']"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'trophy']"></fa-icon></span
                  ><span class="subnav-label">Challenges</span></a
                >
              </li>
              <li *ngIf="currentUser.buddy">
                <a [routerLink]="['/friends-and-family']"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'trophy']"></fa-icon></span
                  ><span class="subnav-label">Challenges</span></a
                >
              </li>
              <li
                routerLinkActive="active"
                *ngIf="
                  currentProgramme.config.trackers.indexOf('strength') > -1
                "
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <a [routerLink]="['/activity', 'strength']"
                  ><span class="icon-wrap"
                    ><fa-icon
                      [icon]="['far', 'dumbbell']"
                      [fixedWidth]="true"
                    ></fa-icon></span
                  ><span class="nav-label">Strength</span></a
                >
              </li>

              <li
                *ngIf="
                  currentProgramme.config.buddies == 1 &&
                  !currentUser.buddy &&
                  currentUser.mobility3 != '0' &&
                  currentUser.mobility1 != 'f'
                "
              >
                <a [routerLink]="['/friends-and-family']"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'user-friends']"></fa-icon></span
                  ><span class="subnav-label">Buddies</span></a
                >
              </li>
              <li
                *ngIf="
                  currentUser.mobility3 != '0' && currentUser.mobility1 != 'f'
                "
              >
                <a [routerLink]="['/target']"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'bullseye']"></fa-icon></span
                  ><span class="subnav-label">Set Step Target</span></a
                >
              </li>

              <li
                *ngIf="currentProgramme.config.trackers.indexOf('sitting') > -1"
              >
                <a [routerLink]="['/activity', 'sitting']"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['far', 'couch']"></fa-icon></span
                  ><span class="subnav-label">
                    <span
                      *ngIf="
                        !(
                          currentUser.mobility1 == 'e' ||
                          currentUser.mobility1 == 'f'
                        )
                      "
                      >My Sitting</span
                    ><span
                      *ngIf="
                        currentUser.mobility1 == 'e' ||
                        currentUser.mobility1 == 'f'
                      "
                      >My Sedentary Behaviour</span
                    >
                  </span></a
                >
              </li>
              <li
                *ngIf="
                  currentProgramme.config.trackers.indexOf('workouts') > -1
                "
              >
                <a [routerLink]="['/activity', 'workouts']"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'running']"></fa-icon></span
                  ><span class="subnav-label">My Workouts</span></a
                >
              </li>
              <li
                *ngIf="currentProgramme.config.trackers.indexOf('minutes') > -1"
              >
                <a [routerLink]="['/activity', 'minutes']"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['far', 'stopwatch']"></fa-icon></span
                  ><span class="subnav-label">My Active Minutes</span></a
                >
              </li>
              <li>
                <a [routerLink]="['/achievements']"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'medal']"></fa-icon></span
                  ><span class="subnav-label">Achievements</span></a
                >
              </li>
              <li>
                <a [routerLink]="['/setup-device']"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'watch-fitness']"></fa-icon></span
                  ><span class="subnav-label">Setup Device</span></a
                >
              </li>
            </ul>
          </div>

          <div class="side-menu-item" *ngIf="!currentUser.buddy">
            <a
              [ngClass]="{ collapsed: !menuToggle.chat }"
              (click)="menuToggle.chat = !menuToggle.chat"
              data-toggle="collapse"
              data-parent="#accordion"
              aria-expanded="false"
              aria-controls="collapseFour"
              role="tab"
              id="headingFour"
            >
              <span class="icon-wrap item-icon"
                ><fa-icon [icon]="['fas', 'comments']"></fa-icon
              ></span>
              <span class="mobile-nav-label">CHAT</span>
              <span class="icon-wrap toggle-chevron" *ngIf="!menuToggle.chat"
                ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
              ></span>
            </a>
            <ul
              *ngIf="menuToggle.chat"
              id="collapseFour"
              class="side-menu-sub-menu"
              role="tabpanel"
              aria-labelledby="headingFour"
            >
              <li *ngIf="currentProgramme.config.askTheExpert == 1">
                <a [routerLink]="['/chat', 'ask-the-expert']"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'user-md-chat']"></fa-icon
                  ></span>
                  <span class="subnav-label">{{
                    environment.navLabels["askTheExpert"]
                  }}</span></a
                >
              </li>

              <li>
                <a [routerLink]="['/chat']"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'users']"></fa-icon
                  ></span>
                  <span class="subnav-label">{{
                    environment.navLabels["chat"]
                  }}</span></a
                >
              </li>
              <li>
                <a [routerLink]="['/chat', 'technical-support']"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'user-headset']"></fa-icon></span
                  ><span class="subnav-label">Technical Support</span></a
                >
              </li>
              <li *ngIf="currentProgramme.config.teamBased == 1">
                <a [routerLink]="['/chat', 'team']"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'comment']"></fa-icon></span
                  ><span class="subnav-label">Chat with my team only</span></a
                >
              </li>
            </ul>
          </div>

          <div
            class="side-menu-item"
            *ngIf="
              currentProgramme.config.trackers.length > 0 && !currentUser.buddy
            "
          >
            <a
              [ngClass]="{ collapsed: !menuToggle.trackers }"
              (click)="menuToggle.trackers = !menuToggle.trackers"
              class=""
              data-toggle="collapse"
              data-parent="#accordion"
              aria-expanded="false"
              aria-controls="collapseSix"
              role="tab"
              id="headingFive"
            >
              <span class="icon-wrap item-icon"
                ><fa-icon [icon]="['fas', 'chart-line']"></fa-icon
              ></span>
              <span class="mobile-nav-label">HEALTH TRACKERS</span>
              <span
                class="icon-wrap toggle-chevron"
                *ngIf="!menuToggle.trackers"
                ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
              ></span>
            </a>
            <ul
              *ngIf="menuToggle.trackers"
              id="collapseSix"
              class="side-menu-sub-menu"
              role="tabpanel"
              aria-labelledby="headingFive"
            >
              <li
                *ngIf="currentProgramme.config.trackers.indexOf('shape') > -1"
              >
                <a [routerLink]="['/health-trackers', 'shape']">
                  <span
                    *ngIf="
                      currentUser.gender == 'f' ||
                      !currentUser.gender ||
                      currentUser.gender == 'x'
                    "
                    class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'female']"></fa-icon
                  ></span>
                  <span *ngIf="currentUser.gender == 'm'" class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'male']"></fa-icon
                  ></span>
                  <span class="subnav-label">Shape</span></a
                >
              </li>
              <li
                *ngIf="
                  currentProgramme.config.trackers.indexOf('healthyEating') > -1
                "
              >
                <a [routerLink]="['/health-trackers', 'healthy-eating']"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'utensils']"></fa-icon></span
                  ><span class="subnav-label">Healthy Eating</span></a
                >
              </li>
              <li
                *ngIf="currentProgramme.config.trackers.indexOf('hba1c') > -1"
              >
                <a [routerLink]="['/health-trackers', 'hba1c']"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'tint']"></fa-icon></span
                  ><span class="subnav-label">HbA1c</span></a
                >
              </li>
              <li *ngIf="currentProgramme.config.trackers.indexOf('bp') > -1">
                <a [routerLink]="['/health-trackers', 'blood-pressure']"
                  ><span class="icon-wrap"
                    ><fa-icon
                      [icon]="['fas', 'tachometer-alt']"
                    ></fa-icon></span
                  ><span class="subnav-label">Blood Pressure</span></a
                >
              </li>
              <li
                *ngIf="
                  currentProgramme.config.trackers.indexOf('cholesterol') > -1
                "
              >
                <a [routerLink]="['/health-trackers', 'cholesterol']"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'heartbeat']"></fa-icon></span
                  ><span class="subnav-label">Cholesterol</span></a
                >
              </li>
              <li
                *ngIf="currentProgramme.config.trackers.indexOf('smoking') > -1"
              >
                <a [routerLink]="['/health-trackers', 'smoking']"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'smoking-ban']"></fa-icon></span
                  ><span class="subnav-label">Smoking</span></a
                >
              </li>
            </ul>
          </div>

          <!-- symptom tracker -->
          <div
            class="side-menu-item"
            *ngIf="currentProgramme.config.trackers.indexOf('symptoms') > -1"
          >
            <a
              [ngClass]="{ collapsed: !menuToggle.symptoms }"
              (click)="onToggleMenu($event, 'symptoms')"
              class=""
              data-toggle="collapse"
              data-parent="#accordion"
              aria-expanded="false"
              aria-controls="collapseTen"
              role="tab"
              id="headingTen"
              href="#"
            >
              <span class="icon-wrap item-icon nav-symptoms"
                ><fa-icon
                  [icon]="['fas', 'kidneys']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span>
              <span class="mobile-nav-label">SYMPTOM TRACKERS</span>
              <span
                class="icon-wrap toggle-chevron"
                *ngIf="!menuToggle.symptoms"
                ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
              ></span>
              <span class="icon-wrap toggle-chevron" *ngIf="menuToggle.symptoms"
                ><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon
              ></span>
            </a>
            <ul
              *ngIf="menuToggle.symptoms"
              id="collapseSix"
              class="side-menu-sub-menu"
              role="tabpanel"
              aria-labelledby="headingFive"
            >
              <li
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                *ngFor="let symptom of symptoms"
              >
                <a [routerLink]="['/symptom-trackers', symptom.slug]">
                  <span class="icon-wrap"
                    ><fa-icon
                      [icon]="[symptom.iconWeight, symptom.icon]"
                      [fixedWidth]="true"
                    ></fa-icon
                  ></span>
                  <span class="nav-label">{{
                    symptom.name | titlecase
                  }}</span></a
                >
              </li>
            </ul>
          </div>
          <!-- end symptom tracker -->

          <div class="side-menu-item" *ngIf="!currentUser.buddy">
            <a
              [routerLink]="['/decision-maker-saved']"
              class="no-sub collapsed"
              data-toggle="collapse"
              data-parent="#accordion"
              href="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
              role="tab"
              id="headingSix"
            >
              <span class="icon-wrap item-icon"
                ><fa-icon [icon]="['fas', 'map-signs']"></fa-icon
              ></span>
              <span class="mobile-nav-label">DECISION MAKER</span>
            </a>
          </div>

          <div class="side-menu-item">
            <a
              [ngClass]="{ collapsed: !menuToggle.education }"
              (click)="menuToggle.education = !menuToggle.education"
              data-toggle="collapse"
              data-parent="#accordion"
              aria-expanded="false"
              aria-controls="collapseSeven"
              role="tab"
              id="headingSeven"
            >
              <span class="icon-wrap item-icon"
                ><fa-icon [icon]="['fas', 'book']"></fa-icon
              ></span>
              <span class="mobile-nav-label">USEFUL INFORMATION</span>
              <span
                class="icon-wrap toggle-chevron"
                *ngIf="!menuToggle.education"
                ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
              ></span>
            </a>
            <ul
              *ngIf="menuToggle.education"
              id="collapseSeven"
              class="side-menu-sub-menu"
              role="tabpanel"
              aria-labelledby="headingSeven"
            >
              <ng-container
                *ngFor="
                  let contentSection of programmeContent.content_sections;
                  let index = index
                "
              >
                <li *ngIf="programmeContent.content_sections.length > 0">
                  <a
                    [routerLink]="[
                      '/educational-reading',
                      'section',
                      contentSection.content_section_id
                    ]"
                    ><span class="sub-menu-num">{{ index + 1 }}</span
                    ><span class="subnav-label">{{
                      contentSection.content_section_title
                    }}</span></a
                  >
                </li>
              </ng-container>
            </ul>
          </div>
        </nav>
      </ng-container>
      <ng-container *ngIf="currentProgramme.config.m3 == 1">
        <nav class="side-menu" id="accordion" role="tablist">
          <div class="side-menu-item">
            <a [routerLink]="['/m3/dashboard']" class="no-sub collapsed">
              <span class="icon-wrap item-icon" style="background-color: #fff!important;"
                ><app-m3-icon [icon]="'overview'" [style]="'light'"></app-m3-icon
              ></span>
              <span>Overview</span>
            </a>
          </div>

          <div class="side-menu-item">
            <a [routerLink]="['/m3/walking']" class="no-sub collapsed">
              <span class="icon-wrap item-icon" style="background-color: #fff!important;"
                ><app-m3-icon [icon]="'walking'"></app-m3-icon
              ></span>

              <span>Daily Stepping</span>
            </a>
          </div>
          <div class="side-menu-item">
            <a [routerLink]="['/m3/fitness']" class="no-sub collapsed">
              <span class="icon-wrap item-icon" style="background-color: #fff!important;"
                ><app-m3-icon [icon]="'fitness'"></app-m3-icon
              ></span>
              <span>Fitness</span>
            </a>
          </div>

          <div class="side-menu-item">
            <a [routerLink]="['/m3/non-stepping']" class="no-sub collapsed">
              <span class="icon-wrap item-icon" style="background-color: #fff!important;"
                ><app-m3-icon
                  [icon]="'non-stepping'"
                 
                ></app-m3-icon
              ></span>
              <span>Non Stepping Activity</span>
            </a>
          </div>

          <div class="side-menu-item">
            <a [routerLink]="['/m3/physical-ability']" class="no-sub collapsed">
              <span class="icon-wrap item-icon" style="background-color: #fff!important;"
                ><app-m3-icon
                  [icon]="'physical-ability'"
                 
                ></app-m3-icon
              ></span>
              <span>Physical function &amp; strength</span>
            </a>
          </div>

          <div class="side-menu-item">
            <a [routerLink]="['/m3/sitting']" class="no-sub collapsed">
              <span class="icon-wrap item-icon" style="background-color: #fff!important;"
                ><app-m3-icon [icon]="'sitting'"></app-m3-icon
              ></span>
              <span>Sitting</span>
            </a>
          </div>

          <div class="side-menu-item">
            <a [routerLink]="['/m3/sleep']" class="no-sub collapsed">
              <span class="icon-wrap item-icon" style="background-color: #fff!important;"
                ><app-m3-icon [icon]="'sleep'"></app-m3-icon
              ></span>
              <span>Sleep</span>
            </a>
          </div>

          <div class="side-menu-item">
            <a [routerLink]="['/m3/health-data']" class="no-sub collapsed">
              <span class="icon-wrap item-icon" style="background-color: #fff!important;"
                ><app-m3-icon
                  [icon]="'health-data'"
                 
                ></app-m3-icon
              ></span>
              <span>Health Data</span>
            </a>
          </div>

          <!--div class="side-menu-item">
            <a [routerLink]="['/setup-device']" class="no-sub collapsed">
              <span class="icon-wrap item-icon"
                ><fa-icon
                  [icon]="['fas', 'watch-fitness']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span>
              <span>Setup Device</span>
            </a>
          </div-->
        </nav>
      </ng-container>
    </div>
  </span>
</div>
