<nav class="subnav">
  <h2>
    <span class="icon-wrap"
      ><fa-icon [icon]="['far', 'dumbbell']"></fa-icon
    ></span>
    STRENGTH ENTRY
  </h2>
</nav>
<div class="dm-pager">
  <div [ngClass]="{ current: step === 1, complete: step > 1 }">
    <span class="icon-wrap fa fa-circle"
      ><fa-icon [icon]="['fas', 'circle']"></fa-icon
    ></span>
    <span class="icon-wrap fa fa-check-circle"
      ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
    ></span>
  </div>
  <div [ngClass]="{ current: step === 2, complete: step > 2 }">
    <span class="icon-wrap fa fa-circle"
      ><fa-icon [icon]="['fas', 'circle']"></fa-icon
    ></span>
    <span class="icon-wrap fa fa-check-circle"
      ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
    ></span>
  </div>
  <div [ngClass]="{ current: step === 3, complete: step > 3 }">
    <span class="icon-wrap fa fa-circle"
      ><fa-icon [icon]="['fas', 'circle']"></fa-icon
    ></span>
    <span class="icon-wrap fa fa-check-circle"
      ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
    ></span>
  </div>
  <div [ngClass]="{ current: step === 4, complete: step > 4 }">
    <span class="icon-wrap fa fa-circle"
      ><fa-icon [icon]="['fas', 'circle']"></fa-icon
    ></span>
    <span class="icon-wrap fa fa-check-circle"
      ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
    ></span>
  </div>
  <div [ngClass]="{ current: step === 5, complete: step > 5 }">
    <span class="icon-wrap fa fa-circle"
      ><fa-icon [icon]="['fas', 'circle']"></fa-icon
    ></span>
    <span class="icon-wrap fa fa-check-circle"
      ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
    ></span>
  </div>
</div>
<div class="decision-maker">
  <div *ngIf="step === 1">
    <p class="instruction text-center">
      Please select the area of the body for the exercise…
    </p>

    <div
      class="toggle-header toggle-header-left"
      *ngFor="let area of areas; let index = index"
      (click)="selected.area = area.label; setStep(2)"
    >
      <a class="toggle-nav toggle-nav-expand"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
      ></a>
      <div class="toggle-header-title">
        {{ area.label | uppercase }}
      </div>
    </div>
  </div>

  <div *ngIf="step === 2">
    <p class="instruction text-center">
      Please select the core exercise you did…
    </p>

    <ng-container *ngFor="let exercise of exercises; let index = index">
      <div
        *ngIf="exercise.area == selected.area"
        class="toggle-header toggle-header-left"
        (click)="selected.exercise = exercise.label; setStep(3)"
      >
        <a class="toggle-nav toggle-nav-expand"
          ><span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
        ></a>
        <div class="toggle-header-title">
          {{ exercise.label | uppercase }}
        </div>
      </div>
    </ng-container>
  </div>

  <div *ngIf="step === 3">
    <p class="instruction text-center">
      Please select the resistance band used…
    </p>

    <div
      class="toggle-header toggle-header-left"
      *ngFor="let band of bands; let index = index"
      (click)="selected.band = band; setStep(4)"
    >
      <a class="toggle-nav toggle-nav-expand"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
      ></a>
      <div class="toggle-header-title">
        {{ band | uppercase }}
      </div>
    </div>
  </div>

  <div *ngIf="step === 4">
    <p class="instruction text-center">
      Please enter how many reps you did in each set…
    </p>

    <div class="row">
      <div class="col text-center">
        SET 1
        <input
          type="number"
          class="form-control input-tracker"
          name="set1"
          [(ngModel)]="selected.set1"
          placeholder="reps"
        />
      </div>
      <div class="col text-center">
        SET 2
        <input
          type="number"
          class="form-control input-tracker"
          name="set2"
          [(ngModel)]="selected.set2"
          placeholder="reps"
        />
      </div>
      <div class="col text-center">
        SET 3
        <input
          type="number"
          class="form-control input-tracker"
          name="set3"
          [(ngModel)]="selected.set3"
          placeholder="reps"
        />
      </div>
    </div>
  </div>

  <div *ngIf="step == 5">
    <p class="instruction text-center">
      How much effort did this exercise take?
    </p>
    <p class="instruction-sm text-center">From 1 (Not much) to 10 (A Lot)</p>

    <div class="target-controls text-center">
      <span class="num">{{ selected.effort }}</span>
      <span class="target-control-btns">
        <button (click)="effortUp()">
          <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'chevron-up']"></fa-icon
          ></span>
        </button>
        <button (click)="effortDown()">
          <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'chevron-down']"></fa-icon
          ></span>
        </button>
      </span>
    </div>
  </div>

  <hr class="divider" />
  <div class="container">
    <div class="row">
      <div class="col-6">
        <a *ngIf="step > 1" (click)="prev()" class="btn btn-primary btn-white"
          ><span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'arrow-circle-left']"></fa-icon
          ></span>
          PREV</a
        >
      </div>
      <div class="col-6 text-right">
        <a *ngIf="step < 5" (click)="next()" class="btn btn-primary btn-white"
          >NEXT
          <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
        ></a>
        <a *ngIf="step === 5" (click)="onSaveWorkout()" class="btn btn-primary btn-white"
          >SAVE WORKOUT
          <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
        ></a>
      </div>
    </div>
  </div>
  <br /><br />
</div>
