import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { AuthService } from './../auth/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageViewService {
  userId;
  endPoint = '/page_views';

  updateProgress = new BehaviorSubject(null);

  cacheTime = new Date();
  cacheLifetime = 5 * 60 * 1000;
  cachedPageViews: any = null;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userId = user.id;
      }
    });
  }

  fetchAll(): Observable<any> {
    const now = new Date();

    if (
      !this.cachedPageViews ||
      Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime
    ) {
      this.cacheTime = new Date();
      let searchParams = new HttpParams();

      searchParams = searchParams.append('user_id', this.userId);
      searchParams = searchParams.append('sort', 'created');
      searchParams = searchParams.append('order', 'DESC');
      this.cachedPageViews = this.http
        .get<any>(environment.apiUrl + this.endPoint, {
          params: searchParams,
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            return responseData._embedded.page_views;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.cachedPageViews;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.endPoint + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(duration, booster_session_id, page_id, tracked, module_id) {
    this.clearCache();
    const postData = {
      user_id: this.userId,
      duration,
      booster_session_id,
      page_id,
      tracked,
      module_id,
    };
    return this.http.post<{ name: string }>(
      environment.apiUrl + this.endPoint,
      postData,
      {
        observe: 'response',
      }
    );
  }

  createContentSectionView(
    duration,
    content_section_id,
    page_id,
    tracked,
    module_id
  ) {
    this.clearCache();
    const postData = {
      user_id: this.userId,
      duration,
      content_section_id,
      page_id,
      tracked,
      module_id,
    };
    return this.http.post<{ name: string }>(
      environment.apiUrl + this.endPoint,
      postData,
      {
        observe: 'response',
      }
    );
  }

  update(id, duration, booster_session_id, page_id, tracked, module_id) {
    this.clearCache();
    const postData = {
      duration,
      booster_session_id,
      page_id,
      tracked,
      module_id,
    };
    return this.http.patch<{ name: string }>(
      environment.apiUrl + this.endPoint + '/' + id,
      postData,
      {
        observe: 'response',
      }
    );
  }

  delete(id) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.endPoint + '/' + id
    );
  }

  getLastViewed(viewData) {
    /*
    let lastViewedPage = {};
    if (viewData.booster_session_id) {

      return this.http
      .get<any>(environment.apiUrl + '/booster_session_steps/' + viewData.page_id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          lastViewedPage = responseData;
          if (
            bs.booster_session_id == $scope.lastViewedPage.booster_session_id
          ) {
            lastViewedPage.parent = bs;
            if (bs.release_date == 'O') {
              lastViewedPage.parent.type = 'learningSession';
            } else {
              lastViewedPage.parent.type = 'boosterSession';
            }
          }
          lastViewedPage

        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );

      // get page
      $http({
        method: 'GET',
        url: '/booster_session_steps/' + $scope.lastViewed.page_id,
      }).then(function successCallback(response) {
        $scope.lastViewedPage = response.data;
        // get the parent..
        $scope.programmeContent.booster_sessions.forEach(function (bs) {
          if (
            bs.booster_session_id == $scope.lastViewedPage.booster_session_id
          ) {
            $scope.lastViewedPage.parent = bs;
            if (bs.release_date == 'O') {
              $scope.lastViewedPage.parent.type = 'learningSession';
            } else {
              $scope.lastViewedPage.parent.type = 'boosterSession';
            }
          }
        });
      });
    }

    if (viewData.content_section_id) {
      // get page
      $http({
        method: 'GET',
        url: '/content_pages/' + $scope.lastViewed.page_id,
      }).then(function successCallback(response) {
        $scope.lastViewedPage = response.data;

        $scope.programmeContent.content_sections.forEach(function (bs) {
          if (
            bs.content_section_id == $scope.lastViewedPage.content_section_id
          ) {
            $scope.lastViewedPage.parent = bs;
          }
        });
      });
    }*/
  }

  refreshProgress() {
    this.updateProgress.next(true);
  }

  clearCache() {
    this.cachedPageViews = null;
  }
}
