<!--nav class="subnav subnav-tabbed subnav-tabbed-chat">
  <a
    ui-sref="chat"
    ng-click="setCategory('');"
    [ngClass]="{ active: category == '' }"
  >
    GENERAL
    <span *ngIf="unread['topic'] > 0" class="notification">{{
      unread["topic"]
    }}</span></a
  >
  <a
    ui-sref="chat"
    ng-click="setCategory('Exercise');"
    [ngClass]="{ active: category == 'Exercise' }"
  >
    EXERCISE
    <span *ngIf="unread['topicExercise'] > 0" class="notification">{{
      unread["topicExercise"]
    }}</span></a
  >
  <a
    ui-sref="chat"
    ng-click="setCategory('Diet');"
    [ngClass]="{ active: category == 'Diet' }"
  >
    DIET
    <span *ngIf="unread['topicDiet'] > 0" class="notification">{{
      unread["topicDiet"]
    }}</span></a
  >
  <a
    ui-sref="chat"
    ng-click="setCategory('Health');"
    [ngClass]="{ active: category == 'Health' }"
  >
    HEALTH
    <span *ngIf="unread['topicHealth'] > 0" class="notification">{{
      unread["topicHealth"]
    }}</span></a
  >
  <a ui-sref="chatTeam" *ngIf="currentProgramme.config.teamBased == 1">
    TEAM ONLY</a
  >
</nav-->
<nav class="subnav">
  <h2>
    <span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'comment']"></fa-icon
    ></span>
    FORUM
  </h2>
</nav>
<nav class="subnav subnav-tabbed subnav-tabbed-chat">
  <a
    [routerLink]="['/chat']"
    [queryParams]="{
      category: category,
      page: 1,
      yourTopics: 0,
      yourReplies: 0
    }"
    [ngClass]="{ active: category && !yourTopics && !yourReplies }"
  >
    ALL TOPICS
  </a>
  <a
    [routerLink]="['/chat']"
    [queryParams]="{
      yourTopics: 1,
      category: category,
      page: 1,
      yourReplies: 0
    }"
    [ngClass]="{ active: yourTopics }"
  >
    YOUR TOPICS
  </a>
  <a
    [routerLink]="['/chat']"
    [queryParams]="{
      yourReplies: 1,
      category: category,
      page: 1,
      yourTopics: 0
    }"
    [ngClass]="{ active: yourReplies }"
  >
    YOUR REPLIES
  </a>
  <a
    [routerLink]="['/chat', 'forum', 'search']"
  >
    SEARCH
  </a>
</nav>
<div *ngIf="isFetching">
  <app-loading-spinner [inline]="1"></app-loading-spinner>
</div>

<p class="mt-3 mb-2 pl-2 pr-2">Chat with others who are using the program to provide encouragement and share experiences related to your health journey.</p>
<div *ngIf="currentProgramme && currentUser">
  <div class="container container-pad">
    <!--p>
      Chat with others who are using the program to provide encouragement and
      share experiences related to your health journey.
    </p-->
    <!--form
      class="text-center comment-form"
      name="commentForm"
      ng-submit="submit(commentData)"
      novalidate
      method="post"
    >
      <div class="form-group">
        <label class="sr-only" for="comment">Comment</label>
        <div class="textarea-container">
          <img
            [src]="
              (currentUser.avatar !== null &&
                '/assets/user/' + currentUser.avatar) ||
              'assets/img/default-avatar.png'
            "
            class="img-circle img-avatar"
            width="30"
            height="30"
            *ngIf="!hideImageVal"
            alt="User avatar"
          /><textarea
            rows="4"
            msd-elastic
            aria-label="Enter a comment"
            type="text"
            class="form-control"
            placeholder="Write a new post…"
            id="comment"
            name="comment"
            ng-model="commentData.comment"
            autocomplete="off"
            required
            ng-click="hideImage()"
            style="min-height: 90px; resize: none"
            ng-maxlength="5000"
          ></textarea>
        </div>
        <div
          class="alert alert-danger mt-10"
          *ngIf="commentForm.comment.$touched && commentForm.comment.$invalid"
        >
          Please enter a comment in the field above, maximum 5,000 characters
        </div>
      </div>
      <button
        class="btn btn-primary"
        ng-disabled="!commentForm.$valid || isDisabled"
        aria-label="Send Post"
        value="Sent Post"
      >
        <i class="fa fa-comment-o"></i> SEND POST
      </button>
    </form>

    <hr class="divider" /-->

    <div class="row mb-3 row-nm">
      <div class="col-sm-6">
        <div class="btn-group d-flex" dropdown>
          <button
            id="button-basic"
            dropdownToggle
            type="button"
            class="btn btn-white btn-block"
            aria-controls="dropdown-basic"
          >
            <span *ngIf="category === 'all'">ALL CATEGORIES </span>
            <span *ngIf="category !== 'all'">{{ category | uppercase }}</span>

            <span class="icon-wrap float-right text-primary"
              ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
            ></span>
          </button>
          <ul
            id="dropdown-basic"
            *dropdownMenu
            class="dropdown-menu"
            role="menu"
            aria-labelledby="button-basic"
          >
            <li role="menuitem">
              <a
                class="dropdown-item"
                [routerLink]="['/chat']"
                [queryParams]="{
                  category: 'all',
                  page: 1,
                  yourTopics: yourTopics,
                  yourReplies: yourReplies
                }"
                >All categories</a
              >
            </li>
            <li role="menuitem">
              <a
                class="dropdown-item"
                [routerLink]="['/chat']"
                [queryParams]="{
                  category: 'general',
                  page: 1,
                  yourTopics: yourTopics,
                  yourReplies: yourReplies
                }"
                >General</a
              >
            </li>
            <li role="menuitem" *ngIf="currentProgramme.config.exerciseProgramme !=1">
              <a
                class="dropdown-item"
                [routerLink]="['/chat']"
                [queryParams]="{
                  category: 'exercise',
                  page: 1,
                  yourTopics: yourTopics,
                  yourReplies: yourReplies
                }"
                >Exercise</a
              >
            </li>
            <li role="menuitem" *ngIf="currentProgramme.config.exerciseProgramme !=1">
              <a
                class="dropdown-item"
                [routerLink]="['/chat']"
                [queryParams]="{
                  category: 'diet',
                  page: 1,
                  yourTopics: yourTopics,
                  yourReplies: yourReplies
                }"
                >Diet</a
              >
            </li>
            <li role="menuitem" *ngIf="currentProgramme.config.exerciseProgramme !=1">
              <a
                class="dropdown-item"
                [routerLink]="['/chat']"
                [queryParams]="{
                  category: 'health',
                  page: 1,
                  yourTopics: yourTopics,
                  yourReplies: yourReplies
                }"
                >Health</a
              >
            </li>
          </ul>
        </div>
      </div>
      <!--div class="col-sm-4 text-center">
        <form 
        (ngSubmit)="onSubmitForumSearch()"
        #forumSearchForm="ngForm">
          <div class="input-group mb-3">
            <input type="text"  name="query" class="form-control" placeholder="Search forum" aria-label="Search forum" aria-describedby="basic-addon2"
            ngModel
            #forumSearchQuery="ngModel">
            <div class="input-group-append">
              <button class="btn btn-primary" type="button">Go</button>
            </div>
          </div>
        </form>
      </div-->
      <div class="col-sm-6 text-center">
        <button
          class="btn btn-primary btn-block"
          [routerLink]="['/chat', 'forum', 'create-topic']"
        >
          <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'plus-circle']"></fa-icon
          ></span>
          NEW TOPIC
        </button>
      </div>
      
    </div>

    <div class="forum-paginator">
      <button
        [disabled]="page === 1 || (yourReplies || yourTopics)"
        [routerLink]="['/chat']"
        [queryParams]="{
          category: category,
          page: +page - 1,
          yourTopics: yourTopics,
          yourReplies: yourReplies
        }"
        class="btn btn-link prev"
      >
        <span class="icon-wrap" aria-label="previous page"
          ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon
        ></span>
      </button>
      <span *ngIf="!loadingTopics"
        >Displaying
        <ng-container *ngIf="!yourReplies && !yourTopics">
        <strong
          >{{ (page - 1) * perPage + 1 }} -
          {{ getTotal(page, perPage, topicsCount.length) }}</strong
        >
        of </ng-container>
        
        <strong>{{ topicsCount.length }}</strong></span
      >
      <span *ngIf="loadingTopics">
        <span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon></span
      ></span>
      <button
        [disabled]="page === topPage || topPage ===0 || (yourReplies || yourTopics)"
        class="btn btn-link next"
        (click)="onNextPage()"
        [routerLink]="['/chat']"
        [queryParams]="{
          category: category,
          page: +page + 1,
          yourTopics: yourTopics,
          yourReplies: yourReplies
        }"
      >
        <span class="icon-wrap" aria-label="next page"
          ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon
        ></span>
      </button>
    </div>
    

    <ul class="chat forum-list" *ngIf="!loadingTopics && !yourReplies">
      <!-- orderBy touched -->
      <ng-container
        *ngFor="
          let comment of sortBy(topics, 'last_reply', true);
          let index = index
        "
      >
        <li
          [routerLink]="['/chat', 'view', 'global_chat', comment.comment_id]"
          [ngClass]="{
            viewed:
              ((commentViews[comment.comment_id] == true ||
                commentViewsClicked[comment.comment_id] == true) &&
                checkReplies(comment)) ||
              comment.created < currentUser.created,
              pinned: comment.status == 3 || comment.status == 4
          }"
          ng-click="commentViewsClicked[comment.comment_id]=true;"
          id="comment-{{comment.comment_id}}"
        >
          <span class="closed-msg" *ngIf="comment.status == 2"
            >DISCUSSION CLOSED</span
          >
          <span class="pinned-msg" *ngIf="comment.status == 3"
          ><span class="icon-wrap icon-comment-count"
          ><fa-icon [icon]="['far', 'thumbtack']"></fa-icon
        ></span> PINNED</span
        >
        <span class="pinned-msg" *ngIf="comment.status == 4"
          ><span class="icon-wrap icon-comment-count"
          ><fa-icon [icon]="['far', 'thumbtack']"></fa-icon
        ></span> PINNED &amp; CLOSED</span
        >
          <span class="avatar"
            ><img
              [src]="
                (comment.avatar !== null &&
                  environment.avatarUrl + '/assets/avatar/' + comment.avatar) ||
                'assets/img/default-avatar.png'
              "
              class="img-circle img-avatar"
              width="30"
              height="30"
              alt="User avatar"
            />
            <span class="new-msg" *ngIf="!commentViews[comment.comment_id]"
              >New</span
            >
          </span>

          <div class="comment">
            <span class="sr-only">Comment #{{ comment.comment_id }}:</span>
            <span
              *ngIf="comment.comment"
              [innerHtml]="getSubject(comment) | linky"
            ></span>
            <span class="category">
              {{ getCategory(comment) }}
            </span>
          </div>

          <span class="comment-date">
            <span *ngIf="comment.status != 2"
              ><!--a
              [routerLink]="[
                '/chat',
                'view',
                'global_chat',
                comment.comment_id
              ]"
              [attr.aria-label]="
                'reply to ' +
                comment.screen_name +
                '. Comment #' +
                comment.comment_id
              "
              >Reply</a
            -->
              <strong> {{ comment.screen_name }}</strong>
              | </span
            >
            <span class="icon-wrap icon-comment-count"
              ><fa-icon [icon]="['fas', 'comments']"></fa-icon
            ></span> {{ comment.replies }} 
             <span *ngIf="!comment.last_reply"> | <span  timeago [date]="comment.created  | dateFormatter : ''" [live]="true"></span> 
              </span> 
              <span *ngIf="comment.last_reply"> |  <span  timeago [date]="comment.last_reply  | dateFormatter: ''" [live]="true"></span> 
                  </span> 
            <!--|
          <a
            *ngIf="commentLikes[comment.comment_id]"
            href="#"
            ng-click="unlikeComment(comment);$event.preventDefault(); $event.stopPropagation();"
            ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'thumbs-up']"></fa-icon
      ></span>{{ comment.likes }}</a
          ><a
            href="#"
            style="color: #333"
            ng-click="likeComment(comment);$event.preventDefault(); $event.stopPropagation();"
            *ngIf="
              !commentLikes[comment.comment_id] &&
              comment.user_id != currentUser.user_id
            "
            ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'thumbs-up']"></fa-icon
      ></span>{{ comment.likes }}</a
          >
          <span
            *ngIf="
              !commentLikes[comment.comment_id] &&
              comment.user_id == currentUser.user_id
            "
            ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'thumbs-up']"></fa-icon
      ></span>{{ comment.likes }}</span
          ></span
        >
        | 
        <a
          *ngIf="comment.status != 1"
          href="#"
          ng-click="reportComment(comment);$event.preventDefault();$event.stopPropagation();"
          >
          <span class="icon-wrap" [attr.aria-label]="'Report comment ' + comment.comment_id"
        ><fa-icon [icon]="['fas', 'flag']"></fa-icon
      ></span>
          
          
          </a
        ><span *ngIf="comment.status == 1"><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'exclamation-triangle']"></fa-icon
      ></span--></span
          >
        </li>
      </ng-container>
    </ul>

    <!-- your replies -->
    <ul class="chat forum-list" *ngIf="!loadingTopics && yourReplies">
      <!-- orderBy touched -->
      <ng-container
        *ngFor="
          let comment of topics;
          let index = index
        "
      >
        <li
          [routerLink]="['/chat', 'view', 'global_chat', comment.comment_type_ref]"
          
          id="comment-{{comment.comment_type_ref}}"
          class="viewed"
        >
          <span class="closed-msg" *ngIf="comment.status == 2"
            >DISCUSSION CLOSED</span
          >
          <span class="pinned-msg" *ngIf="comment.status == 3"
          ><span class="icon-wrap icon-comment-count"
          ><fa-icon [icon]="['far', 'thumbtack']"></fa-icon
        ></span> PINNED</span
        >
        <span class="pinned-msg" *ngIf="comment.status == 4"
          ><span class="icon-wrap icon-comment-count"
          ><fa-icon [icon]="['far', 'thumbtack']"></fa-icon
        ></span> PINNED &amp; CLOSED</span
        >
          <span class="avatar"
            ><img
              [src]="
                (comment.avatar !== null &&
                  environment.avatarUrl + '/assets/avatar/' + comment.avatar) ||
                'assets/img/default-avatar.png'
              "
              class="img-circle img-avatar"
              width="30"
              height="30"
              alt="User avatar"
            />
            <span class="new-msg" *ngIf="!commentViews[comment.comment_type_ref]"
              >New</span
            >
          </span>

          <div class="comment">
            <span class="sr-only">Comment:</span>
            <span
             
            >{{ comment.comment }} </span>
          </div>

          <span class="comment-date">
            <span *ngIf="comment.status != 2"
              ><!--a
              [routerLink]="[
                '/chat',
                'view',
                'global_chat',
                comment.comment_id
              ]"
              [attr.aria-label]="
                'reply to ' +
                comment.screen_name +
                '. Comment #' +
                comment.comment_id
              "
              >Reply</a
            -->
              <strong> {{ comment.screen_name }}</strong>
              | </span
            >
            <span class="icon-wrap icon-comment-count"
              ><fa-icon [icon]="['fas', 'comments']"></fa-icon
            ></span> 
             <span *ngIf="!comment.last_reply"> | <span  timeago [date]="comment.created  | dateFormatter : ''" [live]="true"></span> 
              </span> 
              <span *ngIf="comment.last_reply"> |  <span  timeago [date]="comment.last_reply  | dateFormatter: ''" [live]="true"></span> 
                  </span> 
            <!--|
          <a
            *ngIf="commentLikes[comment.comment_id]"
            href="#"
            ng-click="unlikeComment(comment);$event.preventDefault(); $event.stopPropagation();"
            ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'thumbs-up']"></fa-icon
      ></span>{{ comment.likes }}</a
          ><a
            href="#"
            style="color: #333"
            ng-click="likeComment(comment);$event.preventDefault(); $event.stopPropagation();"
            *ngIf="
              !commentLikes[comment.comment_id] &&
              comment.user_id != currentUser.user_id
            "
            ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'thumbs-up']"></fa-icon
      ></span>{{ comment.likes }}</a
          >
          <span
            *ngIf="
              !commentLikes[comment.comment_id] &&
              comment.user_id == currentUser.user_id
            "
            ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'thumbs-up']"></fa-icon
      ></span>{{ comment.likes }}</span
          ></span
        >
        | 
        <a
          *ngIf="comment.status != 1"
          href="#"
          ng-click="reportComment(comment);$event.preventDefault();$event.stopPropagation();"
          >
          <span class="icon-wrap" [attr.aria-label]="'Report comment ' + comment.comment_id"
        ><fa-icon [icon]="['fas', 'flag']"></fa-icon
      ></span>
          
          
          </a
        ><span *ngIf="comment.status == 1"><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'exclamation-triangle']"></fa-icon
      ></span--></span
          >
        </li>
      </ng-container>
    </ul>
    <!-- end your replies -->



    <p *ngIf="topicsCount.length == 0">
      There are no chat messages.
    </p>

    <div class="forum-paginator" *ngIf="topicsCount.length>5 && !loadingTopics">
      <button
        [disabled]="page === 1 || (yourReplies || yourTopics)"
        [routerLink]="['/chat']"
        [queryParams]="{
          category: category,
          page: +page - 1,
          yourTopics: yourTopics,
          yourReplies: yourReplies
        }"
        class="btn btn-link prev"
      >
        <span class="icon-wrap" aria-label="previous page"
          ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon
        ></span>
      </button>
      <span *ngIf="!loadingTopics"
        >Displaying

        <ng-container *ngIf="!yourReplies && !yourTopics">
        <strong
          >{{ (page - 1) * perPage + 1 }} -
          {{ getTotal(page, perPage, topicsCount.length) }}</strong
        >
        of 
        </ng-container>
        <strong>{{ topicsCount.length }}</strong></span
      >
      <span *ngIf="loadingTopics">
        <span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon></span
      ></span>
      <button
        [disabled]="page === topPage || topPage ===0 || (yourReplies || yourTopics)"
        class="btn btn-link next"
        (click)="onNextPage()"
        [routerLink]="['/chat']"
        [queryParams]="{
          category: category,
          page: +page + 1,
          yourTopics: yourTopics,
          yourReplies: yourReplies
        }"
      >
        <span class="icon-wrap" aria-label="next page"
          ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon
        ></span>
      </button>
    </div>
  </div>
</div>

