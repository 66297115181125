import { forkJoin } from 'rxjs';
import { NotificationService } from './../services/notification.service';
import { Component, OnInit, Output } from '@angular/core';
import * as EventEmitter from 'events';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  notifications = [];
  showNew = true;
  oldNotifications = [];

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    forkJoin({
      newNotifications: this.notificationService.getNotifications(0),
      oldNotifications: this.notificationService.getNotifications(1),
    }).subscribe(
      (responseData) => {
        this.notifications = responseData.newNotifications;
        this.oldNotifications = responseData.oldNotifications;
        if (this.notifications.length < 1) {
          this.showNew = false;
        }
        this.notifications.forEach((notification, index) => {
          this.notificationService
            .update(
              notification.notification_id,
              notification.notification_type,
              notification.content,
              notification.link,
              1
            )
            .subscribe((response) => {
              this.notificationService.refreshNotifications();
            });
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  hideOld() {
    this.oldNotifications.forEach((notification) => {
      this.notificationService
        .update(
          notification.notification_id,
          notification.notification_type,
          notification.content,
          notification.link,
          2
        )
        .subscribe((response) => {});
    });
    this.oldNotifications = [];
  }
}
