<table class="table table-bordered" *ngIf="gender != 'm'">
  <thead>
    <tr>
      <th>Age</th>
      <th>Very low</th>
      <th>Low</th>
      <th>Fair</th>
      <th>Good</th>
      <th>Excellent</th>
      <th>superior</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="age < 30">
      <td>20-29</td>
      <td>≤30.9</td>
      <td>35.2</td>
      <td [ngClass]="{'bg-blue': +vo2max<38.5,'bg-rag-green':+vo2max>=38.5 && +vo2max<42.4}">38.5</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=42.4 && +vo2max<46.8}">42.4</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=46.8 && +vo2max<54.4}">46.8</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=54.4}">≥54.4</td>
    </tr>
    <tr *ngIf="age > 29 && age < 40">
      <td>30-39</td>
      <td>≤29.5</td>
      <td>33.8</td>
      <td [ngClass]="{'bg-blue': +vo2max<37.1,'bg-rag-green':+vo2max>=37.1 && +vo2max<41 }">37.1</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=41 && +vo2max<45.3}">41.0</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=45.3 && +vo2max<52.5}">45.3</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=52.5}">≥52.5</td>
    </tr>
    <tr *ngIf="age > 39 && age < 50">
      <td>40-49</td>
      <td>≤28.2</td>
      <td>32.3</td>
      <td [ngClass]="{'bg-blue': +vo2max<35.2,'bg-rag-green':+vo2max>=35.2 && +vo2max<39.2 }">35.2</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=39.2 && +vo2max<43.9}">39.2</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=43.9 && +vo2max<51.1}">43.9</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=51.1}">≥51.1</td>
    </tr>
    <tr *ngIf="age > 49 && age < 60">
      <td>50-59</td>
      <td>≤25.9</td>
      <td>29.5</td>
      <td [ngClass]="{'bg-blue': +vo2max<32.3,'bg-rag-green':+vo2max>=32.3 && +vo2max<35.3 }">32.3</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=35.3 && +vo2max<39.5}">35.3</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=39.5 && +vo2max<46}">39.5</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=46}">≥46.0</td>
    </tr>
    <tr *ngIf="age > 59 && age < 70">
      <td>60-69</td>
      <td>≤24.0</td>
      <td>26.8</td>
      <td  [ngClass]="{'bg-blue': +vo2max<29.4,'bg-rag-green':+vo2max>=29.4 && +vo2max<32.3 }">29.4</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=32.3 && +vo2max<35.9}">32.3</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=35.9 && +vo2max<43.9}">35.9</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=43.9}">≥43.9</td>
    </tr>
    <tr *ngIf="age > 69 && age < 80">
      <td>70-79</td>
      <td>≤22.6</td>
      <td>25.1</td>
      <td  [ngClass]="{'bg-blue': +vo2max<28,'bg-rag-green':+vo2max>=28 && +vo2max<30.9 }">28.0</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=30.9 && +vo2max<33}">30.9</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=33 && +vo2max<43.9}">33.0</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=43.9}">≥43.9</td>
    </tr>
  </tbody>
</table>

<table class="table table-bordered" *ngIf="gender == 'm'">
  <thead>
    <tr>
      <th>Age</th>
      <th>Very low</th>
      <th>Low</th>
      <th>Fair</th>
      <th>Good</th>
      <th>Excellent</th>
      <th>Superior</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="age < 30">
      <td>20-29</td>
      <td>≤36.7</td>
      <td>41.0</td>
      <td [ngClass]="{'bg-blue': +vo2max<44.6,'bg-rag-green':+vo2max>=44.6 && +vo2max<48.4 }" >44.6</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=48.4 && +vo2max<53.5}">48.4</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=53.5 && +vo2max<59.8}">53.5</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=59.8}">≥59.8</td>
    </tr>
    <tr *ngIf="age > 29 && age < 40">
      <td>30-39</td>
      <td>≤35.2</td>
      <td>39.5</td>
      <td [ngClass]="{'bg-blue': +vo2max<43.9,'bg-rag-green':+vo2max>=43.9 && +vo2max<47 }" >43.9</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=47 && +vo2max<51.6}">47.0</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=51.6 && +vo2max<58.3}">51.6</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=58.3}">≥58.3</td>
    </tr>
    <tr *ngIf="age > 39 && age < 50">
      <td>40-49</td>
      <td>≤33.8</td>
      <td>37.7</td>
      <td [ngClass]="{'bg-blue': +vo2max<41,'bg-rag-green':+vo2max>=41 && +vo2max<44.9 }">41.0</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=44.9 && +vo2max<49.7}">44.9</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=49.7 && +vo2max<56.1}">49.7</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=56.1}">≥56.1</td>
    </tr>
    <tr *ngIf="age > 49 && age < 60">
      <td>50-59</td>
      <td>≤30.9</td>
      <td>35.0</td>
      <td [ngClass]="{'bg-blue': +vo2max<38.1,'bg-rag-green':+vo2max>=38.1 && +vo2max<41.9 }">38.1</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=38.1 && +vo2max<41.9}">41.9</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=41.9 && +vo2max<46.7}">46.7</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=54}">≥54.0</td>
    </tr>
    <tr *ngIf="age > 59 && age < 70">
      <td>60-69</td>
      <td>≤27.4</td>
      <td>31.6</td>
      <td [ngClass]="{'bg-blue': +vo2max<34.9,'bg-rag-green':+vo2max>=34.9 && +vo2max<38.3 }" >34.9</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=38.3 && +vo2max<42.6}">38.3</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=42.6 && +vo2max<51.1}">42.6</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=51.1}">≥51.1</td>
    </tr>
    <tr *ngIf="age > 69 && age < 80">
      <td>70-79</td>
      <td>≤24.6</td>
      <td>28.1</td>
      <td [ngClass]="{'bg-blue': +vo2max<31.5,'bg-rag-green':+vo2max>=31.5 && +vo2max<35.2 }" >31.5</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=35.2 && +vo2max<39.5}">35.2</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=39.5 && +vo2max<49.7}">39.5</td>
      <td [ngClass]="{'bg-rag-green': +vo2max>=49.7}">≥49.7</td>
    </tr>
  </tbody>
</table>
