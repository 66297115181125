import { UserService } from './../../services/user.service';
import { ProgrammeService } from './../../services/programme.service';
import { AuthService } from './../../auth/auth.service';
import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat-index',
  templateUrl: './chat-index.component.html',
  styleUrls: ['./chat-index.component.scss'],
})
export class ChatIndexComponent implements OnInit {
  currentProgramme;
  currentUser;
  environment = environment;
  constructor(
    private authService: AuthService,
    private programmeService: ProgrammeService,
    private userService: UserService
  ) {
    // are we logged in?
    this.authService.user.subscribe((user) => {
      if (user) {
        this.programmeService.currentProgramme.subscribe((programmeData) => {
          this.currentProgramme = programmeData;
        });
        this.userService.userData.subscribe((userDataResponse) => {
          this.currentUser = userDataResponse;
        });
      }
    });
  }

  ngOnInit(): void {}
}
