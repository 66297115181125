import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { AuthService } from './../auth/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CholesterolService {
  userId;
  endPoint = '/user_cholesterols';
  allEntries?: any;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userId = user.id;
      }
    });
  }

  fetchAll(): Observable<any> {
    if (!this.userId) {
      return;
    }
    if (!this.allEntries) {
      let searchParams = new HttpParams();

      searchParams = searchParams.append('user_id', this.userId);
      searchParams = searchParams.append('sort', 'created');
      searchParams = searchParams.append('order', 'DESC');
      this.allEntries = this.http
        .get<any>(environment.apiUrl + this.endPoint, {
          params: searchParams,
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            return responseData._embedded.user_cholesterols;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.allEntries;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.endPoint + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(cholesterol, ldl, hdl, entryDate, hdl_ratio = null) {
    this.clearCache();
    const postData = {
      user_id: this.userId,
      cholesterol,
      ldl,
      hdl,
      created: entryDate,
      hdl_ratio,
    };
    return this.http
      .post<{ name: string }>(environment.apiUrl + this.endPoint, postData, {
        observe: 'response',
      })
      .pipe(
        map((responseData) => {
          return responseData.body;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  update(id, cholesterol, ldl, hdl, hdl_ratio = null) {
    this.clearCache();
    const postData = {
      cholesterol,
      ldl,
      hdl,
      hdl_ratio,
    };
    return this.http.patch<{ name: string }>(
      environment.apiUrl + this.endPoint + '/' + id,
      postData,
      {
        observe: 'response',
      }
    );
  }

  delete(id) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.endPoint + '/' + id
    );
  }

  getRag(value, type) {
    let ragColour = '';

    switch (type) {
      case 'total':
        if (+value <= 4) {
          ragColour = 'green';
        } else if (+value > 4 && +value <= 7.5) {
          ragColour = 'amber';
        } else if (+value > 7.5) {
          ragColour = 'red';
        }
        break;
      case 'ldl':
        if (+value < 1.4) {
          ragColour = 'green';
        } else if (+value >= 1.4 && +value <= 2.5) {
          ragColour = 'amber';
        } else if (+value > 2.5) {
          ragColour = 'red';
        }
        break;
      case 'hdl_ratio':
        if (+value < 6) {
          ragColour = 'green';
        } else if (+value >= 6) {
          ragColour = 'red';
        }
        break;
    }
    /*
    if(+systolic <= 130 && diastolic<=80){
      ragColour = 'green';
    }
    else if(+systolic > 130 && systolic< 141 && diastolic>=80 && diastolic<91){
      ragColour = 'amber';

    }
    else if(+systolic > 140 && diastolic>90){
      ragColour = 'red';
    }*/
    return ragColour;
  }

  clearCache() {
    this.allEntries = null;
  }
}
