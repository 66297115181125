<div class="container m3" *ngIf="currentUser">
  <app-m3-header class="d-none d-sm-block"></app-m3-header>
  <app-m3-mobile-navbar
    [pageTitle]="'Health Data'"
    class="d-block d-sm-none"
  ></app-m3-mobile-navbar>
  <nav class="subnav d-block d-sm-none">
    <h2>Wellbeing</h2>
  </nav>
  <h1 class="m3-title mt-3 d-none d-sm-block">
    <button class="btn btn-white btn-sm float-right mt-1" [routerLink]="['/m3','health-data']"> &lt; Back</button>
    <app-m3-icon [icon]="'wellbeing'"></app-m3-icon> Wellbeing
  </h1>

  <!--button (click)="addModal.show()" class="btn btn-mobile-add d-block d-sm-none">
        <app-m3-icon [icon]="'more'" [style]="'color'"></app-m3-icon
        > Add a Sitting Entry</button-->

  <h2 class="mt-2" id="hba1c">
    PHQ9
    <a class="add-item-btn" (click)="addPhq9Modal.show()"
      ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
    ></a>
  </h2>
  <div class="card" *ngIf="phq9s">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          
            <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">PHQ-9 Score (depression)</span>
                  <span
                    *ngIf="phq9s[0]"
                    class="info-stat-stat text-rag-{{
                      userPhq9Service.getRag(phq9s[0].phq9)
                    }}"
                  >
                    <span *ngIf="phq9s[0]">{{ phq9s[0].phq9 }}</span
                    ><span *ngIf="!phq9s[0]">?</span></span
                  >
                  <span *ngIf="!phq9s[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label">&nbsp;</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-blue">
                      {{ userPhq9Service.getPhq9forColor("green") }}
                    </span>
                  </div>
                  <div class="rag-details-history" *ngIf="phq9s.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplatePhq9>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{ userPhq9Service.getPhq9forColor("green") }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            {{ userPhq9Service.getPhq9forColor("amber") }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{ userPhq9Service.getPhq9forColor("red") }}
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let phq9 of phq9s.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > phq9s.length - 6"
                          [tooltip]="
                            phq9.phq9 +
                            ' ' +
                            ' on ' +
                            (phq9.date_recorded | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            userPhq9Service.getRag(phq9.phq9)
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplatePhq9"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

          <h3 class="chart-title text-center mt-4" *ngIf="phq9s">
            Your Entries
          </h3>
          <div class="table-responsive" *ngIf="phq9s">
            <table *ngIf="phq9s" class="table">
              <thead>
                <tr>
                  <th>DATE</th>
                  <th>PHQ9</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let phq9 of phq9s; let index = index">
                  <tr *ngIf="index < 10 || showAllEntries">
                    <td class="text-center">
                      {{ phq9.date_recorded | dateFormatter : "MMM Do YYYY" }}
                    </td>
                    <td class="text-center">
                      {{ phq9.phq9 }}
                    </td>

                    <td>
                      <a
                        [swal]="{
                          title: 'Are you sure you want to delete this?',
                          icon: 'warning',
                          showCancelButton: true
                        }"
                        (confirm)="onDeletePhq9(phq9)"
                        class="pointer"
                        ><span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                      ></a>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <div class="text-center">
              <button
                *ngIf="!showAllEntries && phq9s.length > 10"
                class="btn btn-primary btn-sm btn-xs"
                (click)="showAllEntries = true"
              >
                Show all
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-6">
            <app-m3-phq9-chart
            *ngIf="phq9s && phq9s.length > 1"
            [phq9s]="phq9s.slice().reverse()"
          ></app-m3-phq9-chart>

        </div>
      </div>
    </div>
  </div>

  <hr />

  <h2 class="mt-2">
    PAID 20
    <a class="add-item-btn" (click)="addPaidModal.show()"
      ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
    ></a>
  </h2>
  <div class="card" *ngIf="paids">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          
            <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label"
                    >PAID-20 Score (emotional disresss)</span
                  >
                  <span
                    *ngIf="paids[0]"
                    class="info-stat-stat text-rag-{{
                      userPaidService.getRag(paids[0].paid)
                    }}"
                  >
                    <span *ngIf="paids[0]">{{ paids[0].paid }}</span
                    ><span *ngIf="!paids[0]">?</span></span
                  >
                  <span *ngIf="!paids[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label">&nbsp;</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-blue">
                      {{ userPaidService.getPaidforColor("green") }}
                    </span>
                  </div>
                  <div class="rag-details-history" *ngIf="paids.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplatePaid>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{ userPaidService.getPaidforColor("green") }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            {{ userPaidService.getPaidforColor("amber") }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{ userPaidService.getPaidforColor("red") }}
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let paid of paids.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > paids.length - 6"
                          [tooltip]="
                            paid.paid +
                            ' ' +
                            ' on ' +
                            (paid.date_recorded | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            userPaidService.getRag(paid.paid)
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplatePaid"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

          <h3 class="chart-title text-center mt-4" *ngIf="paids">
            Your Entries
          </h3>
          <div class="table-responsive" *ngIf="paids">
            <table *ngIf="paids" class="table">
              <thead>
                <tr>
                  <th>DATE</th>
                  <th>PAID 20</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let paid of paids; let index = index">
                  <tr *ngIf="index < 10 || showAllEntries">
                    <td class="text-center">
                      {{ paid.date_recorded | dateFormatter : "MMM Do YYYY" }}
                    </td>
                    <td class="text-center">
                      {{ paid.paid }}
                    </td>

                    <td>
                      <a
                        [swal]="{
                          title: 'Are you sure you want to delete this?',
                          icon: 'warning',
                          showCancelButton: true
                        }"
                        (confirm)="onDeletePaid(paid)"
                        class="pointer"
                        ><span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                      ></a>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <div class="text-center">
              <button
                *ngIf="!showAllEntries && paids.length > 10"
                class="btn btn-primary btn-sm btn-xs"
                (click)="showAllEntries = true"
              >
                Show all
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-6">
            <app-m3-paid-chart
            *ngIf="paids && paids.length > 1"
            [paids]="paids.slice().reverse()"
          ></app-m3-paid-chart>
        </div>
      </div>
    </div>
  </div>

  <hr />

  <h2 class="mt-2">
    MBTQ
    <a class="add-item-btn" (click)="addMbtqModal.show()"
      ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
    ></a>
  </h2>
  <div class="card" *ngIf="mbtqs">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
            <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label"
                    >MBTQ Score (treatment burden)</span
                  >

                  <span
                    *ngIf="mbtqs[0]"
                    class="info-stat-stat text-rag-{{
                      userMbtqService.getRag(mbtqs[0].mbtq)
                    }}"
                  >
                    <span *ngIf="mbtqs[0]">{{ mbtqs[0].mbtq }}</span
                    ><span *ngIf="!mbtqs[0]">?</span></span
                  >
                  <span *ngIf="!mbtqs[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label">&nbsp;</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-blue">
                      {{ userMbtqService.getMbtqforColor("green") }}
                    </span>
                  </div>
                  <div class="rag-details-history" *ngIf="mbtqs.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateMbtq>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{ userMbtqService.getMbtqforColor("green") }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            {{ userMbtqService.getMbtqforColor("amber") }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{ userMbtqService.getMbtqforColor("red") }}
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let mbtq of mbtqs.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > mbtqs.length - 6"
                          [tooltip]="
                            mbtq.mbtq +
                            ' ' +
                            ' on ' +
                            (mbtq.date_recorded | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            userMbtqService.getRag(mbtq.mbtq)
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateMbtq"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

          <h3 class="chart-title text-center mt-4" *ngIf="mbtqs">
            Your Entries
          </h3>
          <div class="table-responsive" *ngIf="mbtqs">
            <table *ngIf="mbtqs" class="table">
              <thead>
                <tr>
                  <th>DATE</th>
                  <th>MBTQ</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let mbtq of mbtqs; let index = index">
                  <tr *ngIf="index < 10 || showAllEntries">
                    <td class="text-center">
                      {{ mbtq.date_recorded | dateFormatter : "MMM Do YYYY" }}
                    </td>
                    <td class="text-center">
                      {{ mbtq.mbtq }}
                    </td>

                    <td>
                      <a
                        [swal]="{
                          title: 'Are you sure you want to delete this?',
                          icon: 'warning',
                          showCancelButton: true
                        }"
                        (confirm)="onDeleteMbtq(mbtq)"
                        class="pointer"
                        ><span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                      ></a>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <div class="text-center">
              <button
                *ngIf="!showAllEntries && mbtqs.length > 10"
                class="btn btn-primary btn-sm btn-xs"
                (click)="showAllEntries = true"
              >
                Show all
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          
            <app-m3-mbtq-chart
            *ngIf="mbtqs && mbtqs.length > 1"
            [mbtqs]="mbtqs.slice().reverse()"
          ></app-m3-mbtq-chart>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    bsModal
    #addPhq9Modal="bs-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-static-name"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left">Add PHQ9 Score</h4>
          <button
            type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="addPhq9Modal.hide()"
          >
            <span aria-hidden="true" class="visually-hidden">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form
            name="entryFormPhq9"
            class="bs-form tracker-form"
            (ngSubmit)="onSubmitPhq9Form()"
            #entryFormPhq9="ngForm"
          >

        <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="reps">PHQ9 Score</label>
                <input
                  type="number"
                  name="phq9"
                  class="form-control input-tracker input-tracker-xsm"
                  placeholder=""
                  id="phq9"
                  min="0"
                  max="100"
                  ngModel
                  autocomplete="off"
                  ng-required=""
                  #phq9="ngModel"
                />
                <div
                  class="alert alert-danger"
                  *ngIf="phq9.touched && phq9.invalid"
                >
                  This must be a number below 100.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="entryDatePhq9">DATE</label>
                <input
                  name="entryDatePhq9"
                  type="text"
                  placeholder="DD/MM/YYYY"
                  class="form-control input-tracker input-tracker-sm"
                  bsDatepicker
                  autocomplete="off"
                  [bsConfig]="{
                    dateInputFormat: 'DD/MM/YYYY',
                    containerClass: 'theme-cv',
                    showWeekNumbers: false
                  }"
                  [(ngModel)]="entryDatePhq9"
                  required
                  [bsValue]="entryDatePhq9"
                  [maxDate]="entryDatePhq9"
                  #dateInput="ngModel"
                />
              </div>
            </div>
          </div>
          <div class="tracker-submit-container">
            <button
              type="submit"
              role="button"
              class="btn btn-primary btn-lg btn-white"
            >
              UPDATE
            </button>
          </div>
          
          </form>
        </div>
      </div>
    </div>
  </div>
  



  <div
    class="modal fade"
    bsModal
    #addPaidModal="bs-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-static-name"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left">ADD PAID 20 Score</h4>
          <button
            type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="addPaidModal.hide()"
          >
            <span aria-hidden="true" class="visually-hidden">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form
            name="entryFormPaid"
            class="bs-form tracker-form"
            (ngSubmit)="onSubmitPaidForm()"
            #entryFormPaid="ngForm"
          >

        <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="reps">PAID 20 Score</label>
                <input
                  type="number"
                  name="paid"
                  class="form-control input-tracker input-tracker-xsm"
                  placeholder=""
                  id="paid"
                  min="0"
                  max="100"
                  ngModel
                  autocomplete="off"
                  ng-required=""
                  #paid="ngModel"
                />
                <div
                  class="alert alert-danger"
                  *ngIf="paid.touched && paid.invalid"
                >
                  This must be a number below 100.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="entryDatePaid">DATE</label>
                <input
                  name="entryDatePaid"
                  type="text"
                  placeholder="DD/MM/YYYY"
                  class="form-control input-tracker input-tracker-sm"
                  bsDatepicker
                  autocomplete="off"
                  [bsConfig]="{
                    dateInputFormat: 'DD/MM/YYYY',
                    containerClass: 'theme-cv',
                    showWeekNumbers: false
                  }"
                  [(ngModel)]="entryDatePaid"
                  required
                  [bsValue]="entryDatePaid"
                  [maxDate]="entryDatePaid"
                  #dateInput="ngModel"
                />
              </div>
            </div>
          </div>
          <div class="tracker-submit-container">
            <button
              type="submit"
              role="button"
              class="btn btn-primary btn-lg btn-white"
            >
              UPDATE
            </button>
          </div>
          
          </form>
        </div>
      </div>
    </div>
  </div>




  <div
    class="modal fade"
    bsModal
    #addMbtqModal="bs-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-static-name"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left">Add MBTQ Score</h4>
          <button
            type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="addMbtqModal.hide()"
          >
            <span aria-hidden="true" class="visually-hidden">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form
            name="entryFormMbtq"
            class="bs-form tracker-form"
            (ngSubmit)="onSubmitMbtqForm()"
            #entryFormMbtq="ngForm"
          >

        <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="reps">MBTQ Score</label>
                <input
                  type="number"
                  name="mbtq"
                  class="form-control input-tracker input-tracker-xsm"
                  placeholder=""
                  id="mbtq"
                  min="0"
                  max="100"
                  ngModel
                  autocomplete="off"
                  ng-required=""
                  #mbtq="ngModel"
                />
                <div
                  class="alert alert-danger"
                  *ngIf="mbtq.touched && mbtq.invalid"
                >
                  This must be a number below 100.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="entryDateMbtq">DATE</label>
                <input
                  name="entryDateMbtq"
                  type="text"
                  placeholder="DD/MM/YYYY"
                  class="form-control input-tracker input-tracker-sm"
                  bsDatepicker
                  autocomplete="off"
                  [bsConfig]="{
                    dateInputFormat: 'DD/MM/YYYY',
                    containerClass: 'theme-cv',
                    showWeekNumbers: false
                  }"
                  [(ngModel)]="entryDateMbtq"
                  required
                  [bsValue]="entryDateMbtq"
                  [maxDate]="entryDateMbtq"
                  #dateInput="ngModel"
                />
              </div>
            </div>
          </div>
          <div class="tracker-submit-container">
            <button
              type="submit"
              role="button"
              class="btn btn-primary btn-lg btn-white"
            >
              UPDATE
            </button>
          </div>
          
          </form>
        </div>
      </div>
    </div>
  </div>



  <div class="clear"></div>
</div>
