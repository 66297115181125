import { ProgrammeService } from './../services/programme.service';
import { UserService } from './../services/user.service';
import { AuthService } from './../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  userData;
  currentProgramme;
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        // console.log(this.currentProgramme.config);
        if(this.currentProgramme.config.exerciseProgramme == 1){
          this.router.navigate(['/dashboard-ep']);
        }
        else if(this.currentProgramme.config.m3 == 1){
          this.router.navigate(['/m3/dashboard']);
        }
      });
    }
  }
}
