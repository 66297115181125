<div>
  <canvas
    class="mt-3 mb-3 tracker-chart"
    baseChart
    [datasets]="barChartData"
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [legend]="barChartLegend"
    [chartType]="barChartType"
  >
  </canvas>
</div>
