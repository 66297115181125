import { Component, Input, OnInit } from '@angular/core';
import {
  Chart,
  ChartDataSets,
  ChartOptions,
  ChartPoint,
  ChartType,
} from 'chart.js';
import * as moment from 'moment';
import { Color, Label } from 'ng2-charts';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-m3-non-stepping-chart',
  templateUrl: './m3-non-stepping-chart.component.html',
  styleUrls: ['./m3-non-stepping-chart.component.scss'],
})
export class M3NonSteppingChartComponent implements OnInit {
  activities = [
    { id: 'swimming', label: 'Swimming' },
    { id: 'cycling', label: 'Cycling' },
    { id: 'stationaryBike', label: 'Stationary bike' },
    { id: 'yoga', label: 'Yoga' },
    { id: 'pilates', label: 'Pilates' },
    {
      id: 'waterSports',
      label: 'Water sports',
    },
    { id: 'rowing', label: 'Rowing / canoeing' },
    { id: 'armErgometry', label: 'Arm ergometry' },
    { id: 'seatedExercise', label: 'Seated aerobic exercise' },
    { id: 'aquaticExercise', label: 'Aquatic exercise' },
    { id: 'horseRiding', label: 'Horse riding' },
    { id: 'other', label: 'Other aerobic exercise' },
    { id: 'activeMinutes', label: 'Brisk Steps', labelShort: 'Brisk Steps' },
  ];

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: 'Week commencing',
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          gridLines: {
            color: '#dddddd',
            drawOnChartArea: false,
          },
          ticks: {
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
            autoSkip: true,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          gridLines: {
            display: true,
            lineWidth: 1,
            color: '#dddddd',
          },
          scaleLabel: {
            display: true,
            labelString: 'MVPA (min/wk)',
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          ticks: {
            min: 0,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
            autoSkip: true,
            maxTicksLimit: 6,
            suggestedMax: 150,
          },
        },
      ],
    },
    legend: {
      display: false,
      fullWidth: false,
      position: 'right',
      labels: {
        boxWidth: 5,
      },
    },
    layout: {
      padding: {},
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
    animation: {
      onComplete: function () {
        var ctx = this.chart.ctx;
        ctx.font = Chart.helpers.fontString(
          Chart.defaults.global.defaultFontFamily,
          'normal',
          Chart.defaults.global.defaultFontFamily
        );
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';

        this.data.datasets.forEach((dataset) => {
          for (var i = 0; i < dataset.data.length; i++) {
            var model =
                dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
              scale_max =
                dataset._meta[Object.keys(dataset._meta)[0]].data[i]._yScale
                  .maxHeight;

            ctx.fillStyle = '#444';
            var y_pos = model.y - 5;

            if (+dataset.data[i] >= this.options.lineAt) {
              // draw a star
              ctx.save();
              const path = new Path2D(
                'M -0.8095 -7.6293 L -2.8665 -3.4587 L -7.4687 -2.7877 c -0.8253 0.1197 -1.1561 1.1371 -0.5575 1.7199 l 3.3296 3.2445 l -0.7875 4.5833 c -0.1418 0.8284 0.7308 1.449 1.4616 1.0615 L 0.0945 5.6574 l 4.117 2.1641 c 0.7308 0.3843 1.6033 -0.2331 1.4616 -1.0615 l -0.7875 -4.5833 l 3.3296 -3.2445 c 0.5985 -0.5827 0.2677 -1.6002 -0.5575 -1.7199 L 3.0555 -3.4587 L 0.9985 -7.6293 c -0.3685 -0.7434 -1.4364 -0.7529 -1.8081 0 z'
              );
              ctx.translate(model.x, y_pos + 32);
              ctx.fillStyle = '#ffffff';
              ctx.fill(path);
              ctx.restore();
            }
          }
        });
      },
    },
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [];
  public barChartColors: Color[] = [
    { backgroundColor: environment.primaryColor },
  ];
  //

  @Input() activtitiesDone;
  @Input() nonSteppingsByDate;
  constructor() {}

  ngOnInit(): void {
    // console.log(this.nonSteppingsByDate);
    this.nonSteppingsByDate = Object.keys(this.nonSteppingsByDate)
      .sort()
      .reduce((obj, key) => {
        obj[key] = this.nonSteppingsByDate[key];
        return obj;
      }, {});

    this.activities.forEach((activity) => {
      if (this.activtitiesDone.indexOf(activity.id) !== -1) {
        this.barChartData.push({
          data: [],
          label: activity.label,
          maxBarThickness: 50,
        });
      }
    });

    this.setChartData();
  }

  setChartData() {
    // this.barChartData[0].data = [];
    // this.barChartData[1].data = [];
    // setup chart data

    let topIndex = 0;
    for (var key in this.nonSteppingsByDate) {
      if (this.nonSteppingsByDate.hasOwnProperty(key)) {
        this.barChartLabels.push(moment(key).format('DD-MM-YY'));

        // data
        this.nonSteppingsByDate[key].forEach((activity, acIndex) => {
          this.activities.forEach((acItem, index) => {
            if (activity.activity == acItem.id) {
              // find the dataset
              let activityIndex = 0;
              this.barChartData.forEach((bcd, bcdIndex) => {
                if (bcd.label == acItem.label) {
                  activityIndex = bcdIndex;
                }
              });

              if (!this.barChartData[activityIndex].data[topIndex]) {
                this.barChartData[activityIndex].data[topIndex] = 0;
              }
              this.barChartData[activityIndex].data[topIndex] =
                +this.barChartData[activityIndex].data[topIndex] +
                +activity.minutes;
            }
          });
        });
      }
      topIndex++;
    }

    // delete when all are zero...
    let  deleteLabels =[];
    for(let i = 0; i<this.barChartLabels.length; i++){
      var deleteDex = true;
      for(let n = 0; n< this.barChartData.length;n++){
        if(this.barChartData[n].data[i] != 0){
          deleteDex = false;
        }
      }

      if(deleteDex == true){
        deleteLabels.push(i);
      }
    }
  }
}
