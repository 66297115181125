import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivityService } from 'src/app/services/activity.service';
import { DateHelperService } from 'src/app/services/date-helper.service';
import { ExerciseWorkoutsService } from 'src/app/services/exercise-workouts.service';
import { ProgrammeService } from 'src/app/services/programme.service';
import { TargetService } from 'src/app/services/target.service';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-workouts-edit-workout',
  templateUrl: './workouts-edit-workout.component.html',
  styleUrls: ['./workouts-edit-workout.component.scss']
})
export class WorkoutsEditWorkoutComponent implements OnInit {
  isFetching = false;
  userSettings;
  exercises;
  workoutId;
  workout;
  date = new Date();

  @ViewChild('minsForm', { static: false }) minsForm: NgForm;
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private userSettingsService: UserSettingsService,
    private activityService: ActivityService,
    private targetService: TargetService,
    private dateHelperService: DateHelperService,
    private router: Router,
    private route: ActivatedRoute,
    private exerciseWorkoutsService: ExerciseWorkoutsService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.workoutId = this.route.snapshot.params.id;

      // get months data
      forkJoin({
        userSettings: this.userSettingsService.fetchAll(),
        exercises: this.exerciseWorkoutsService.fetchExercises(),
        workout: this.exerciseWorkoutsService.fetch(this.workoutId),
      }).subscribe(
        (responseData) => {
          this.isFetching = false;
          this.userSettings = responseData.userSettings;

          this.exercises = responseData.exercises;
          this.workout = responseData.workout;
          this.date = moment(this.workout.workout_date).toDate();
          // console.log(this.exercises);
          // end
        },
        (error) => {
          this.isFetching = false;
        }
      );
    });
  }

  onSubmitMinsForm() {
    // save the steps
    this.exerciseWorkoutsService
      .update(
        this.workoutId,
        this.minsForm.value.date,
        this.minsForm.value.exercise,
        this.minsForm.value.exercise_other,
        this.minsForm.value.mins,
        this.minsForm.value.effort,
        this.minsForm.value.comments
      )
      .subscribe((responseData) => {
        if (+this.minsForm.value.effort < 5) {
          Swal.fire({
            icon: 'success',
            title: 'Workout Updated!',
            html: `<div class="text-left"><p>Your entry was successfully added. You have entered a low effort rating for this
            workout. To get the most benefits, your effort level should be between 5-8.</p>
            <p>
            Next time you may wish to change 1 thing:</p>
            <ul>
            <li>Increase the length of time you work out for</li>
            <li>Increase the number of repetitions of exercises in the workout if that's an option</li>
            <li>Reduce the rest time between exercises</li>
            <li>Use heavier weight or thicker resistance bands if doing a strength workout</li>
            <li>Try a different workout if you are starting to find the ones you do easier.</li>
            </ul></div>`,
            showConfirmButton: true,
          });
        } else if (+this.minsForm.value.effort > 8) {
          Swal.fire({
            icon: 'success',
            title: 'Workout Updated!',
            html: `<div class="text-left"><p>Your entry was successfully added. You have entered a high effort rating for this
            workout. To get the most benefits, your effort level should be between 5-8.</p>
            <p>
            Next time you may wish to change 1 thing:</p>
            <ul>
            <li>Reduce the length of time you work out for</li>
            <li>Reduce the number of repetitions of exercises in the workout if that's an option</li>
            <li>Increase the rest time between exercises</li>
            <li>Use lighter weight or thinner resistance bands if doing a strength workout</li>
            <li>Try a different workout if you are finding this one too hard.</li>
            </ul></div>`,
            showConfirmButton: true,
          });
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Workout Updated',
            text: 'Your workout was successfully updated',
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
        }
        this.router.navigate(['/activity','workouts']);
      });
  }



  onDelete() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to undo this",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it',
    }).then((result) => {
      if (result.value) {
        this.exerciseWorkoutsService.delete(this.workoutId).subscribe(
          (responseData) => {
            Swal.fire('Deleted', 'Your record has been deleted.', 'success');
            this.router.navigate(['/activity', 'minutes']);
          },
          (error) => {
            this.isFetching = false;
            Swal.fire('Deleted!', 'There was an error.', 'error');
          }
        );
      }
    });
  }
}
