
import Swal from 'sweetalert2/src/sweetalert2.js';
import { Router } from '@angular/router';
import { UserService } from './../services/user.service';
import { AuthService } from './../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-continue-access',
  templateUrl: './continue-access.component.html',
  styleUrls: ['./continue-access.component.scss'],
})
export class ContinueAccessComponent implements OnInit {
  currentUser;
  isFetching;
  expiryDate;
  daysLeft;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
        if(this.currentUser){

        // when will it expire?
        this.expiryDate = this.currentUser.created;
        if (this.currentUser.continue_date){
          this.expiryDate = moment(this.currentUser.continue_date).subtract(2, 'years');
        }

        // how long till expiry?
        this.daysLeft = moment(this.expiryDate).diff(moment().subtract(2, 'years'), 'days');
        if (this.daysLeft < 0){
          this.daysLeft = 0;
        }
      }
      });
    }
  }

  continueAccess(){
    this.authService.continueAccess().subscribe((responseData) => {
      Swal.fire({
        icon: 'success',
        title: "Access Extended",
        text: 'Thank you. Your account has now been renewed and you will get another 2 years access to MyDESMOND.'
      });
      this.router.navigate(['/dashboard']);
    });
  }
}
