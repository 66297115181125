
<div *ngIf="isFetching">
  <app-loading-spinner [inline]="1"></app-loading-spinner>
</div>
<div *ngIf="sessionType == 'learning' || !sessionType">
  <nav class="subnav">
    <h2>
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'graduation-cap']"></fa-icon
      ></span>
      OVERVIEW
    </h2>
  </nav>
  <nav class="subnav ng-scope subnav-db ml-2 mr-2">
    <!-- last viewed -->
    <ng-container *ngIf="
    lastViewed &&
    lastViewedPage">
    <a [routerLink]="[
    '/learning-session-steps',
    'view',
    lastViewedPage.booster_session_step_id
    ]" *ngIf="lastViewedPage.parent.type == 'learningSession'" class="text-primary" style="top:5px;right:0px;">
    <span
    class="icon-wrap"
    ><fa-icon [icon]="['fas', 'bookmark']"></fa-icon
  ></span></a>
    </ng-container>
    
    <!-- end last viewed -->
    <h2>
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'graduation-cap']"></fa-icon
      ></span>
      LEARNING SESSIONS
    </h2>
  </nav>
  <div *ngIf="programmeContent">
    <div class="row row-nm">
      <div
        class="col-6 sessions-grid"
        *ngFor="
        let booster of getLearningSessions(programmeContent.booster_sessions);
        index as index
      "
      >
        <div class="reading-widget booster-widget sessions-widget">
          <div
            class="reading-widget-img"
            [ngStyle]="{
              'background-image':
                'url(' +
                environment.assetUrl +
                '/assets/' +
                booster.image_filename +
                ')'
            }"
          ></div>

          <h3>{{ booster.booster_session_title }}</h3>
          <button
            class="btn btn-primary"
            [attr.aria-label]="'Continue' + booster.booster_session_title"
            [routerLink]="[
              '/learning-sessions',
              'index',
              booster.booster_session_id
            ]"
            *ngIf="
              booster.status === 'started' || booster.status === 'completed'
            "

          [disabled]="booster.release_date==='EPL' && !stepTarget"
          >
          <span  *ngIf="booster.status === 'started'">CONTINUE</span>
          <span  *ngIf="booster.status === 'completed'">COMPLETED</span> &nbsp;
            <span class="icon-wrap" *ngIf="booster.status =='started'"
              ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
            ></span>

            <span class="icon-wrap" *ngIf="booster.status =='completed'"
              ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
            ></span>
          </button>


          <button
            class="btn btn-primary"
            *ngIf="booster.status === 'available' || booster.status === 'unavailable'"
            [attr.aria-label]="'Begin' + booster.booster_session_title"
            [routerLink]="[
              '/learning-sessions',
              'index',
              booster.booster_session_id
            ]"

          [disabled]="booster.release_date==='EPL' && !stepTarget"
          >
            BEGIN
            <span class="icon-wrap"
              ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
            ></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="sessionType == 'booster' || !sessionType">
  <nav class="subnav ng-scope subnav-db ml-2 mr-2">
    <!-- last viewed -->
    <ng-container *ngIf="
    lastViewed &&
    lastViewedPage">
    <a [routerLink]="[
    '/booster-session-steps',
    'view',
    lastViewedPage.booster_session_step_id
    ]" *ngIf="lastViewedPage.parent.type == 'boosterSession'" class="text-primary" style="top:5px;right:0px;">
    <span
    class="icon-wrap"
    ><fa-icon [icon]="['fas', 'bookmark']"></fa-icon
  ></span></a>
    </ng-container>
    
    <!-- end last viewed -->
    <h2>
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'bolt']"></fa-icon
      ></span>
      BOOSTER SESSIONS
    </h2>
  </nav>

  <div *ngIf="programmeContent">
    <div class="row row-nm">
      <div
        class="col-6 sessions-grid"
        *ngFor="
        let booster of getBoosterSessions(programmeContent.booster_sessions);
        index as index
      "
      >
        <div class="reading-widget booster-widget sessions-widget">
          <div
            class="reading-widget-img"
            [ngStyle]="{
              'background-image':
                'url(' +
                environment.assetUrl +
                '/assets/' +
                booster.image_filename +
                ')'
            }"
          ></div>
          <h3>{{ booster.booster_session_title }}</h3>
          <button
            class="btn btn-primary"
            *ngIf="booster.status == 'completed' || booster.status =='started'"
            [attr.aria-label]="'Continue' + booster.booster_session_title"
            [routerLink]="[
              '/booster-sessions',
              'index',
              booster.booster_session_id
            ]"
            
          >
          <span  *ngIf="booster.status === 'started'">CONTINUE</span>
          <span  *ngIf="booster.status === 'completed'">COMPLETED</span> &nbsp;

            <span class="icon-wrap" *ngIf="booster.status =='started'"
              ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
            ></span>

            <span class="icon-wrap" *ngIf="booster.status =='completed'"
              ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
            ></span>
          </button>
          <button
            class="btn btn-primary"
            *ngIf="booster.status == 'available'"
            [attr.aria-label]="'Begin' + booster.booster_session_title"
            [routerLink]="[
              '/booster-sessions',
              'index',
              booster.booster_session_id
            ]"
          >
            BEGIN
            <span class="icon-wrap"
              ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
            ></span>
          </button>
          <button
            *ngIf="booster.status == 'unavailable'"
           
            class="btn btn-primary disabled"
          >Not released yet
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
