import { NgForm } from '@angular/forms';
import { DateHelperService } from './../services/date-helper.service';
import { TargetService } from './../services/target.service';
import { ActivityService } from './../services/activity.service';
import { UserSettingsService } from './../services/user-settings.service';
import { ProgrammeService } from './../services/programme.service';
import { UserService } from './../services/user.service';
import { AuthService } from './../auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from './../../environments/environment';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { Color, Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-minutes',
  templateUrl: './minutes.component.html',
  styleUrls: ['./minutes.component.scss'],
})
export class MinutesComponent implements OnInit {
  @ViewChild('minsForm', { static: false }) minsForm: NgForm;
  currentUser;
  currentProgramme;
  deviceSynced = 'unknown';
  isFetching = false;
  errors = [];
  userSettings;

  currentActivity;
  currentTarget;
  currentTargetMins;
  currentTargetUnit;
  environment = environment;
  currentMins = 0;
  now;
  currentWeek;
  previousWeek = {
    startDate: null,
    endDate: null,
  };
  nextWeek = {
    startDate: null,
    endDate: null,
  };
  weekTotal = 0;
  weekData = [];

  toggleRow = {
    update: false,
    graph: false,
    target: false,
  };

  showMonth = true;
  date = new Date();

  targetMins = 0;
  targetUnit = 'day';
  startDate;
  endDate;

  // chart

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: '',
            fontColor: '#333',
          },
          gridLines: {
            color: '#555',
            drawOnChartArea: false,
          },
          ticks: {
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: '#555',
            drawOnChartArea: false,
          },
          scaleLabel: {
            display: true,
            labelString: '',
            fontColor: '#333',
          },
          ticks: {
            min: 0,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [
    { data: [], label: 'Minutes', maxBarThickness: 50 },
  ];
  public barChartColors: Color[] = [
    { backgroundColor: this.environment.primaryColor },
  ];
  //

  // Line chart
  public lineChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            beginAtZero: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          gridLines: {
            display: true,
            lineWidth: 1,
            color: '#95989A',
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            lineWidth: 2,
            color: '#95989A',
          },
          type: 'time',
          ticks: {
            autoSkip: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          time: {
            parser: 'YYYY-MM-DD HH:mm:ss',
            unit: 'day',
            displayFormats: {
              day: 'MMM-DD',
            },
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public lineChartLabels: Label[] = [];
  public lineChartType: ChartType = 'line';
  public lineChartLegend = true;

  public lineChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'Monthly',
      borderWidth: 1,
      borderColor: environment.chartDefaults.colors[0],
      fill: false,
      pointRadius: 5,
    },
  ];
  public lineChartColors: Color[] = [
    { backgroundColor: this.environment.primaryColor },
  ];

  // All time chart..
  public allTimeChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            beginAtZero: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          gridLines: {
            display: true,
            lineWidth: 1,
            color: '#95989A',
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            lineWidth: 2,
            color: '#95989A',
          },
          type: 'time',
          ticks: {
            autoSkip: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          time: {
            parser: 'YYYY-MM-DD HH:mm:ss',
            unit: 'day',
            displayFormats: {
              day: 'MMM-DD',
            },
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public allTimeChartLabels: Label[] = [];
  public allTimeChartType: ChartType = 'line';
  public allTimeChartLegend = true;

  public allTimeChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'All Time',
      borderWidth: 1,
      borderColor: environment.chartDefaults.colors[0],
      fill: false,
      pointRadius: 2,
    },
  ];
  public allTimeChartColors: Color[] = [
    { backgroundColor: this.environment.primaryColor },
  ];

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private userSettingsService: UserSettingsService,
    private activityService: ActivityService,
    private targetService: TargetService,
    private dateHelperService: DateHelperService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isFetching = true;
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (this.currentProgramme) {
          this.barChartColors[0].backgroundColor = this.currentProgramme.config.primaryColor;
        }
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });

      const now = moment();
      this.currentWeek = this.dateHelperService.getWeekFor(now);
      this.now = moment(now).format('YYYY-MM-DD');
      const startDate = moment(this.currentWeek[0]).format('YYYY-MM-DD');
      const endDate = moment(this.currentWeek[6]).format('YYYY-MM-DD');

      this.previousWeek.startDate = moment(this.currentWeek[0])
        .subtract(1, 'week')
        .format('YYYY-MM-DD');
      this.previousWeek.endDate = moment(this.currentWeek[6])
        .subtract(1, 'week')
        .format('YYYY-MM-DD');

      // get months data
      forkJoin({
        userSettings: this.userSettingsService.fetchAll(),
        activity: this.activityService.getActivity(0),
        target: this.targetService.getTarget('step'),
        targetMins: this.targetService.getTarget('mins'),
        activityBetween: this.activityService.getActivityBetween(
          startDate,
          endDate
        ),
        monthData: this.activityService.getActivityBetween(
          moment().subtract(1, 'months').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ),
        allTimeData: this.activityService.getActivityBetween(
          moment().subtract(10, 'years').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ),
      }).subscribe(
        (responseData) => {
          this.isFetching = false;
          this.userSettings = responseData.userSettings;
          this.currentActivity = responseData.activity;
          if (this.currentActivity) {
            this.currentMins = this.currentActivity.minutes;
          }

          if (responseData.target) {
            this.currentTarget = responseData.target.target;
          }
          if (responseData.targetMins) {
            this.currentTargetMins = responseData.targetMins.target;
            this.targetMins = this.currentTargetMins;
            this.currentTargetUnit = responseData.targetMins.target_unit;
          }

          const weeklyActivities = responseData.activityBetween;
          weeklyActivities.forEach((activity) => {
           // this.weekTotal = Number(this.weekTotal) + Number(activity.minutes);
          });

          // week data
          this.currentWeek.forEach((item, index) => {
            const thisActivity = weeklyActivities.filter(this.search, {
              activity_date: moment(item).format('YYYY-MM-DD'),
            })[0];

            if (thisActivity) {
              this.weekTotal += +thisActivity.minutes;
              if (
                thisActivity.hit_goal !== 1 &&
                +thisActivity.percent_hit >= 100
              ) {
                thisActivity.hit_goal = 1;
              }
              this.weekData.push(thisActivity);
            } else {
              const dData = {
                activity_date: moment(item).format('YYYY-MM-DD'),
              };
              this.weekData.push(dData);
            }
          });

          this.setChartData(this.weekData);
          this.setMonthChartData(responseData.monthData);
          this.setAllTimeChartData(responseData.allTimeData);

          // end
        },
        (error) => {
          this.isFetching = false;
          this.errors.push(error.message);
        }
      );
    }
  }

  search(obj) {
    return Object.keys(this).every((key) => obj[key] === this[key]);
  }

  setChartData(activity) {
    this.barChartData[0].data = [];

    if (moment(activity[0].activity_date).format('ddd') !== 'Monday') {
      let startDate = moment(this.currentWeek[0]).format('YYYY-MM-DD');

      while (
        moment(startDate).format('YYYY-MM-DD') !==
        moment(activity[0].activity_date).format('YYYY-MM-DD')
      ) {
        this.barChartData[0].data.push(0);
        startDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD');
      }
    }

    this.barChartLabels = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
    let maxMins = 0;
    activity.forEach((value, key) => {
      if (+value.minutes > +maxMins) {
        maxMins = +value.minutes;
      }
      this.barChartData[0].data.push(value.minutes);

      // have they hit target?
      //if(+(value.steps)>=+($scope.currentTarget)){
      //$scope.datasetOverride[0].backgroundColor[key] = '#FDCE06';

      // }
    });

    // this.barChartOptions.scales.yAxes[0].ticks.max = 10000;
    /*

    if(parseInt(maxSteps) < parseInt($scope.currentTarget)){
                        $scope.options.scales.yAxes[0].ticks.max =  parseInt($scope.currentTarget);
                    }
                    else if(maxSteps){
                        $scope.options.scales.yAxes[0].ticks.max = Math.ceil(parseInt(maxSteps) / 1000.0) * 1000;
                    }
                    else{
                        $scope.options.scales.yAxes[0].ticks.max = 10000;
                    }
                    //$scope.options.scales.yAxes[0].ticks.max = +maxSteps + 100;
                       
                       if($scope.currentTarget){
             $scope.options.lineAt = $scope.currentTarget; 
                       }
                       */

    // reverse them
    // this.barChartData[0].data.reverse();
    // this.barChartLabels.reverse();
  }

  setMonthChartData(activities) {
    this.lineChartData[0].data = [];
    // setup chart data
    activities.forEach((value, key) => {
      this.lineChartLabels.push(
        moment(value.activity_date).format('YYYY-MM-DD')
      );
      this.lineChartData[0].data.push(value.minutes);
    });
  }

  setAllTimeChartData(activities) {
    this.allTimeChartData[0].data = [];
    // setup chart data
    activities.forEach((value, key) => {
      this.allTimeChartLabels.push(
        moment(value.activity_date).format('YYYY-MM-DD')
      );
      this.allTimeChartData[0].data.push(value.minutes);
    });
  }

  onSubmitMinsForm() {
    // save the steps
    this.activityService
      .saveMinutes(this.minsForm.value.mins, this.minsForm.value.date)
      .subscribe((responseData) => {
        const startDate = moment(this.currentWeek[0]).format('YYYY-MM-DD');
        const endDate = moment(this.currentWeek[6]).format('YYYY-MM-DD');

        forkJoin({
          activity: this.activityService.getActivity(),
          activityBetween: this.activityService.getActivityBetween(
            startDate,
            endDate
          ),
        }).subscribe(
          (forkResponseData) => {
            this.currentActivity = forkResponseData.activity;
            if (this.currentActivity) {
              this.currentMins = this.currentActivity.minutes;
            }
            const weeklyActivities = forkResponseData.activityBetween;
            weeklyActivities.forEach((activity) => {
              this.weekTotal =
                Number(this.weekTotal) + Number(activity.minutes);
            });
            // week data
            this.weekData = [];
            this.currentWeek.forEach((item, index) => {
              const thisActivity = weeklyActivities.filter(this.search, {
                activity_date: moment(item).format('YYYY-MM-DD'),
              })[0];

              if (thisActivity) {
                if (
                  thisActivity.hit_goal !== 1 &&
                  +thisActivity.percent_hit >= 100
                ) {
                  thisActivity.hit_goal = 1;
                }
                this.weekData.push(thisActivity);
              } else {
                const dData = {
                  activity_date: moment(item).format('YYYY-MM-DD'),
                };
                this.weekData.push(dData);
              }
            });
            this.setChartData(this.weekData);
            // refresh the route..
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => this.router.navigate(['/activity/minutes']));
          },
          (error) => {
            console.log(error);
          }
        );

        Swal.fire({
          icon: 'success',
          title: 'Minutes Updated',
          text: 'Your minutes were successfully updated',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      });
  }

  syncSteps(force) {
    force = typeof force === 'undefined' ? '0' : force;
    this.activityService.getActivity(force).subscribe((responseData) => {
      this.currentActivity = responseData;
      if (this.currentActivity) {
        this.currentMins = this.currentActivity.minutes;
      }

      this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigate(['/activity', 'minutes']));
    });
  }

  next() {
    const startDate = moment(this.currentWeek[0])
      .add(1, 'week')
      .format('YYYY-MM-DD');
    const endDate = moment(this.currentWeek[6])
      .add(1, 'week')
      .format('YYYY-MM-DD');
    this.startDate = startDate;
    this.endDate = endDate;
    this.currentWeek = this.dateHelperService.getWeekFor(startDate);

    this.previousWeek.startDate = moment(startDate)
      .subtract(1, 'week')
      .format('YYYY-MM-DD');
    this.previousWeek.endDate = moment(endDate)
      .subtract(1, 'week')
      .format('YYYY-MM-DD');

    this.nextWeek.startDate = moment(startDate)
      .add(1, 'week')
      .format('YYYY-MM-DD');
    this.nextWeek.endDate = moment(endDate).add(1, 'week').format('YYYY-MM-DD');

    if (moment() < moment(this.nextWeek.startDate)) {
      this.nextWeek.startDate = null;
      this.nextWeek.endDate = null;
    }

    this.isFetching = true;
    this.weekTotal = 0;
    // get months data
    forkJoin({
      activityBetween: this.activityService.getActivityBetween(
        startDate,
        endDate
      ),
    }).subscribe(
      (responseData) => {
        this.isFetching = false;

        const weeklyActivities = responseData.activityBetween;
        weeklyActivities.forEach((activity) => {
          this.weekTotal = Number(this.weekTotal) + Number(activity.minutes);
        });
        this.weekData = [];

        // week data
        this.currentWeek.forEach((item, index) => {
          const thisActivity = weeklyActivities.filter(this.search, {
            activity_date: moment(item).format('YYYY-MM-DD'),
          })[0];

          if (thisActivity) {
            if (
              thisActivity.hit_goal !== 1 &&
              +thisActivity.percent_hit >= 100
            ) {
              thisActivity.hit_goal = 1;
            }
            this.weekData.push(thisActivity);
          } else {
            const dData = {
              activity_date: moment(item).format('YYYY-MM-DD'),
            };
            this.weekData.push(dData);
          }
        });

        this.setChartData(this.weekData);

        // end
      },
      (error) => {
        this.isFetching = false;
        this.errors.push(error.message);
      }
    );
  }
  previous() {
    const startDate = moment(this.currentWeek[0])
      .subtract(1, 'week')
      .format('YYYY-MM-DD');
    const endDate = moment(this.currentWeek[6])
      .subtract(1, 'week')
      .format('YYYY-MM-DD');

    this.startDate = startDate;
    this.endDate = endDate;
    this.currentWeek = this.dateHelperService.getWeekFor(startDate);

    this.previousWeek.startDate = moment(startDate)
      .subtract(1, 'week')
      .format('YYYY-MM-DD');
    this.previousWeek.endDate = moment(endDate)
      .subtract(1, 'week')
      .format('YYYY-MM-DD');

    this.nextWeek.startDate = moment(startDate)
      .add(1, 'week')
      .format('YYYY-MM-DD');
    this.nextWeek.endDate = moment(endDate).add(1, 'week').format('YYYY-MM-DD');

    this.isFetching = true;
    this.weekTotal = 0;
    // get months data
    forkJoin({
      activityBetween: this.activityService.getActivityBetween(
        startDate,
        endDate
      ),
    }).subscribe(
      (responseData) => {
        this.isFetching = false;

        const weeklyActivities = responseData.activityBetween;
        weeklyActivities.forEach((activity) => {
          this.weekTotal = Number(this.weekTotal) + Number(activity.minutes);
        });
        this.weekData = [];

        // week data
        this.currentWeek.forEach((item, index) => {
          const thisActivity = weeklyActivities.filter(this.search, {
            activity_date: moment(item).format('YYYY-MM-DD'),
          })[0];

          if (thisActivity) {
            if (
              thisActivity.hit_goal !== 1 &&
              +thisActivity.percent_hit >= 100
            ) {
              thisActivity.hit_goal = 1;
            }
            this.weekData.push(thisActivity);
          } else {
            const dData = {
              activity_date: moment(item).format('YYYY-MM-DD'),
            };
            this.weekData.push(dData);
          }
        });

        this.setChartData(this.weekData);

        // end
      },
      (error) => {
        this.isFetching = false;
        this.errors.push(error.message);
      }
    );
  }

  submitTarget(target) {
    this.targetService.create(target, 'mins', this.targetUnit).subscribe(
      (responseData) => {
        this.currentTargetMins = target;

        Swal.fire({
          icon: 'success',
          title: 'Target Updated',
          text: 'Your target were successfully updated',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigate(['/activity/minutes']));
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
