import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserPhq9Service {
  error = new Subject<string>();
  slug = '/user_phq9s';
  resourceName = 'user_phq9s';
  allPhq9s?: any;
  phq9sBetween?: any;

  constructor(private http: HttpClient) {}

  fetchAll(): any {
    if (!this.allPhq9s) {
      this.allPhq9s = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: any[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allPhq9s;
  }

  clearCache() {
    this.allPhq9s = null;
    this.phq9sBetween = null;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(phq9: string, date_recorded: string) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      phq9,
    };
    return this.http.post<any>(environment.apiUrl + this.slug, payload, {
      observe: 'response',
    });
  }

  update(id: number, phq9: string, date_recorded: string) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      phq9,
    };
    return this.http.patch<any>(
      environment.apiUrl + this.slug + '/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + '/' + id
    );
  }

  getRag(phq9) {
    let ragColour = '';
    if (+phq9 <=9) {
      ragColour = 'green';
    } else if (+phq9>14) {
      ragColour = 'red';
    } else {
      ragColour = 'amber';
    }

    return ragColour;
  }

  getPhq9forColor(colour: string) {
    let ragRange = '';
    if (colour == 'green') {
      ragRange = '≤9';
    } else if (colour == 'amber') {
      ragRange = '10-14';
    } else {
      ragRange = '>14';
    }

    return ragRange;
  }
}
