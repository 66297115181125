<nav class="subnav" *ngIf="currentUser">
  <a [routerLink]="['/target']" aria-label="Go to target" class="subnav-prev"
    ><span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
  ></a>
  <h2>
    <span class="icon-wrap"><fa-icon [icon]="['far', 'bed']"></fa-icon></span>

    <span>MY SLEEP</span>
  </h2>

  <a
    [routerLink]="['/achievements']"
    aria-label="Go to Achievements"
    class="subnav-next"
    ><span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
  ></a>
</nav>
<div *ngIf="isFetching">
  <app-loading-spinner [inline]="1"></app-loading-spinner>
</div>
<div *ngIf="currentProgramme && currentUser">
  <div
    class="masthead masthead-accent masthead-strength masthead-pad-top"
    *ngIf="currentWeek && weekData"
  >
    <div *ngIf="currentWeek">
      <div class="headline">
        <div class="headline-icon">
            <span class="icon-wrap"
            ><fa-icon [icon]="['far', 'bed']"></fa-icon
          ></span>
        </div>

        <div class="headline-text">
          <div class="headline-target">
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon
            ></span>
           {{getAgeSleep(age)}} hours a day
          </div>
          <strong>{{ daysHit }}</strong>
          <span class="headline-subtext"
            >day<span *ngIf="daysHit != 1">s</span> achived<br />
            this week</span
          >
        </div>
      </div>
      <div class="clear-headline"></div>

      <div class="days">
        <div *ngFor="let day of currentWeek; let index = index" class="day">
          <div *ngIf="weekData[index]">
            <span
              class="icon-wrap fa fa-check-circle"
              *ngIf="
                +weekData[index].sleep/60 <= 9 &&
                weekData[index].sleep/60 >= 7
              "
              ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
            ></span>
            <span
              class="icon-wrap fa fa-circle"
              [ngClass]="{ 'not-hit': 1 == 1 }"
              *ngIf="
              (+weekData[index].sleep/60 > 9 ||
              +weekData[index].sleep/60 < 7) || !weekData[index].sleep
              "
              ><fa-icon [icon]="['fas', 'circle']"></fa-icon
            ></span>
          </div>

          <br />
          <span>{{ day | dateFormatter : "dd" | slice : 0 : 1 }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.update = !toggleRow.update"
    >
      <a
        href=""
        class="toggle-nav toggle-nav-expand"
        (click)="$event.preventDefault()"
      >
        <span class="icon-wrap" aria-label="Down" *ngIf="!toggleRow.update"
          ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
        ></span>
        <span class="icon-wrap" aria-label="Up" *ngIf="toggleRow.update"
          ><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon
        ></span>
      </a>

      <div class="toggle-header-title">UPDATE MY SLEEP</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.update">
      <form
        name="entryForm"
        class="bs-form tracker-form"
        (ngSubmit)="onSubmitEntryForm()"
        #entryForm="ngForm"
      >
        <p class="instruction">
          You can update your hours and minutes for today or any previous days
          using the form below.
        </p>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="hours">HOURS</label>
              <input
                type="number"
                style="font-size: 28px"
                name="hours"
                class="form-control input-tracker input-tracker-xsm"
                placeholder=""
                id="hours"
                [ngModel]="currentHours"
                min="0"
                max="1000000000"
                autocomplete="off"
                ng-required=""
                #hours="ngModel"
              />
              <div
                class="alert alert-danger"
                *ngIf="hours.touched && hours.invalid"
              >
                This must be a number below 1000000000.
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="mins">MINUTES</label>
              <input
                type="number"
                style="font-size: 28px"
                name="mins"
                class="form-control input-tracker input-tracker-xsm"
                placeholder=""
                id="mins"
                [ngModel]="currentMins"
                min="0"
                max="1000000000"
                autocomplete="off"
                ng-required=""
                #mins="ngModel"
              />
              <div
                class="alert alert-danger"
                *ngIf="mins.touched && mins.invalid"
              >
                This must be a number below 1000000000.
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="number">DATE</label>
              <input
                name="date"
                type="text"
                placeholder="DD/MM/YYYY"
                class="form-control input-tracker input-tracker-sm"
                bsDatepicker
                autocomplete="off"
                [bsConfig]="{
                  dateInputFormat: 'DD/MM/YYYY',
                  containerClass: 'theme-cv',
                  showWeekNumbers: false
                }"
                [(ngModel)]="date"
                required
                [bsValue]="date"
                [maxDate]="date"
                #dateInput="ngModel"
              />
            </div>
          </div>
        </div>
        <div class="tracker-submit-container">
          <button
            type="submit"
            role="button"
            class="btn btn-primary btn-lg btn-white"
          >
            UPDATE
          </button>
        </div>
      </form>
    </div>
  </div>

  <!--div class="toggle-container">
      <div
        class="toggle-header toggle-header-left"
        (click)="toggleRow.target = !toggleRow.target"
      >
        <a
          href=""
          class="toggle-nav toggle-nav-expand"
          (click)="$event.preventDefault()"
          ><span class="icon-wrap" aria-label="Down" *ngIf="!toggleRow.target"
            ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
          ></span>
          <span class="icon-wrap" aria-label="Up" *ngIf="toggleRow.target"
            ><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon></span
        ></a>
  
        <div class="toggle-header-title">SET DAILY SITTING TARGET</div>
      </div>
      <div class="toggle-content" *ngIf="toggleRow.target">
        <form name="targetForm" class="bs-form tracker-form">
          <p class="instruction">
            Set a target for the number of hours of daily sitting you don't want
            to exceed.
          </p>
          <div class="row">
            <div class="col-md-4 offset-md-4">
              <div class="form-group">
                <label for="weight">HOURS</label>
                <input
                  type="number"
                  name="targetHours"
                  class="form-control input-tracker input-tracker-sm"
                  placeholder=""
                  id="targetHours"
                  [(ngModel)]="targetHours"
                  #targetHoursInput="ngModel"
                  min="1"
                  max="1000"
                  autocomplete="off"
                  ng-required=""
                />
                <div
                  class="alert alert-danger"
                  *ngIf="targetHoursInput.touched && targetHoursInput.invalid"
                >
                  This must be a number between 1 and 1000.
                </div>
              </div>
            </div>
          </div>
          <div class="tracker-submit-container">
            <button
              (click)="submitTarget(targetHours)"
              class="btn btn-primary btn-lg btn-white"
              [disabled]="targetHoursInput.invalid"
            >
              UPDATE TARGET
            </button>
          </div>
        </form>
      </div>
    </div-->

  <div class="toggle-container">
    <div class="toggle-header toggle-header-sub">
      <a
        href=""
        *ngIf="previousWeek"
        (click)="$event.preventDefault(); previous()"
        class="toggle-nav toggle-nav-prev"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
      ></a>
      <a
        href=""
        *ngIf="nextWeek.startDate < now"
        (click)="$event.preventDefault(); next()"
        class="toggle-nav toggle-nav-next"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
      ></a>
      <div class="toggle-header-title">WEEKLY SLEEP TIME</div>
      <div class="toggle-header-subtitle">
        {{ currentWeek[0] | dateFormatter : "DD/MM/yyyy" }} -
        {{ currentWeek[6] | dateFormatter : "DD/MM/yyyy" }}
      </div>
    </div>
    <div class="toggle-content">
      <div class="container">
        <table class="table">
          <thead>
            <tr>
              <th width="20"></th>
              <th style="text-align: left">DAY</th>
              <th style="text-align: right">DURATION</th>
            </tr>
          </thead>
          <tbody *ngIf="weekData.length > 0">
            <tr
              *ngFor="let dayData of currentWeek; let index = index"
              [ngClass]="{ current: weekData[index].activity_date == now }"
            >
              <td>
                <span
                  class="icon-wrap"
                  *ngIf="+weekData[index].sleep >= +currentTarget"
                  ><fa-icon [icon]="['far', 'star']"></fa-icon
                ></span>

                <span
                  class="icon-wrap hit-goal"
                  *ngIf="
                    +weekData[index].sleep < +currentTarget &&
                    +weekData[index].sleep > 0
                  "
                  ><fa-icon [icon]="['fas', 'star']"></fa-icon
                ></span>
              </td>
              <td>{{ dayData | dateFormatter : "dddd" }}</td>
              <td class="text-right">
                <span *ngIf="weekData[index].sleep">{{
                  weekData[index].sleep | minsToHours
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.graph = !toggleRow.graph"
    >
      <a
        href=""
        class="toggle-nav toggle-nav-expand"
        (click)="$event.preventDefault()"
        ><span class="icon-wrap" aria-label="Down" *ngIf="!toggleRow.graph"
          ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
        ></span>
        <span class="icon-wrap" aria-label="Up" *ngIf="toggleRow.graph"
          ><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon></span
      ></a>

      <div class="toggle-header-title">SLEEP GRAPH</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.graph">
      <!-- Nav tabs -->
      <ul
        class="nav nav-tabs nav-tabs-duo justify-content-center"
        role="tablist"
      >
        <li role="presentation" [ngClass]="{ active: showMonth }">
          <a
            href=""
            role="tab"
            aria-controls="showMonth"
            data-toggle="tab"
            (click)="$event.preventDefault(); showMonth = true"
            >PAST 30 DAYS</a
          >
        </li>
        <li role="presentation" [ngClass]="{ active: !showMonth }">
          <a
            href=""
            role="tab"
            aria-controls="allTime"
            data-toggle="tab"
            (click)="$event.preventDefault(); showMonth = false"
            >ALL TIME</a
          >
        </li>
      </ul>

      <!-- Tab panes -->
      <div class="tab-content">
        <div role="tabpanel" id="showMonth" *ngIf="showMonth">
          <canvas
              class="mt-3 mb-3 tracker-chart"
              baseChart
              [datasets]="lineChartData"
              [labels]="lineChartLabels"
              [options]="lineChartOptions"
              [legend]="lineChartLegend"
              [chartType]="lineChartType"
              [colors]="lineChartColors"
            >
            </canvas>
        </div>
        <div role="tabpanel" id="allTime" *ngIf="!showMonth">
          <canvas
              class="mt-3 mb-3 tracker-chart"
              baseChart
              [datasets]="allTimeChartData"
              [labels]="allTimeChartLabels"
              [options]="allTimeChartOptions"
              [legend]="allTimeChartLegend"
              [chartType]="allTimeChartType"
              [colors]="allTimeChartColors"
            >
            </canvas>
        </div>
      </div>
    </div>
  </div>
  <app-section-nav
    [section]="'activity'"
    [active]="'sleep'"
  ></app-section-nav>
</div>
