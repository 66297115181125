<div class="container m3" *ngIf="currentUser">
    <app-m3-header class="d-none d-sm-block"></app-m3-header>
    <app-m3-mobile-navbar
      [pageTitle]="'Health Data'"
      class="d-block d-sm-none"
    ></app-m3-mobile-navbar>
    <nav class="subnav d-block d-sm-none">
      <h2>Frailty</h2>
    </nav>
    <h1 class="m3-title mt-3 d-none d-sm-block">
      <button class="btn btn-white btn-sm float-right mt-1" [routerLink]="['/m3','health-data']"> &lt; Back</button>
      <app-m3-icon [icon]="'independence'"></app-m3-icon> Independence
    </h1>
  
  
    <!-- start falls -->
    <h2 class="mt-4" id="hba1c">
      Falls
      <a class="add-item-btn" (click)="addFallModal.show()"
        ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
      ></a>
    </h2>
    <div class="card" *ngIf="falls">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            
              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                  <div class="info-stat-rag-current">
                    <span class="info-stat-label">Falls in the last year</span>
                    <span
                      *ngIf="falls[0]"
                      class="info-stat-stat text-rag-{{
                        userFallService.getRag(falls[0].fall)
                      }}"
                    >
                      <span *ngIf="falls[0]">{{ falls[0].fall }}</span
                      ><span *ngIf="!falls[0]">?</span></span
                    >
                    <span *ngIf="!falls[0]" class="info-stat-stat">-</span>
                    <span class="info-stat-label">&nbsp;</span>
                  </div>
  
                  <div class="rag-details">
                    <div class="rag-details-target">
                      Target:
                      <span class="text-blue">
                        {{ userFallService.getRangeforColor("green") }}
                      </span>
                    </div>
                    <div class="rag-details-history" *ngIf="falls.length > 0">
                      History:
                      <div class="rag-history">
                        <ng-template #popTemplateFall>
                          <div>
                            <div>Range:</div>
                            <div class="d-flex">
                              <span class="rag-circle bg-rag-green mr-1"></span>
                              {{ userFallService.getRangeforColor("green") }}
                            </div>
                            <div class="d-flex">
                              <span class="rag-circle bg-rag-amber mr-1"></span>
                              {{ userFallService.getRangeforColor("amber") }}
                            </div>
                            <div class="d-flex">
                              <span class="rag-circle bg-rag-red mr-1"></span>
                              {{ userFallService.getRangeforColor("red") }}
                            </div>
                          </div></ng-template
                        >
                        <ng-container
                          *ngFor="
                            let fall of falls.slice().reverse();
                            let index = index
                          "
                        >
                          <div
                            *ngIf="index > falls.length - 6"
                            [tooltip]="
                              fall.falls +
                              ' ' +
                              ' on ' +
                              (fall.date_recorded | dateFormatter : 'Do MMM YYYY')
                            "
                            class="rag-circle bg-rag-{{
                              userFallService.getRag(fall.falls)
                            }}"
                          ></div>
                        </ng-container>
                        <div
                          class="rag-circle pointer"
                          [popover]="popTemplateFall"
                        >
                          <span class="icon-wrap"
                            ><fa-icon [icon]="['fas', 'info']"></fa-icon
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
            <h3 class="chart-title text-center mt-4" *ngIf="falls">
              Your Entries
            </h3>
            <div class="table-responsive" *ngIf="falls">
              <table *ngIf="falls" class="table">
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>FALLS</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let fall of falls; let index = index">
                    <tr *ngIf="index < 10 || showAllEntries">
                      <td class="text-center">
                        {{ fall.date_recorded | dateFormatter : "MMM Do YYYY" }}
                      </td>
                      <td class="text-center">
                        {{ fall.falls }}
                      </td>
  
                      <td>
                        <a
                          [swal]="{
                            title: 'Are you sure you want to delete this?',
                            icon: 'warning',
                            showCancelButton: true
                          }"
                          (confirm)="onDeleteFall(fall)"
                          class="pointer"
                          ><span class="icon-wrap"
                            ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                        ></a>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <div class="text-center">
                <button
                  *ngIf="!showAllEntries && falls.length > 10"
                  class="btn btn-primary btn-sm btn-xs"
                  (click)="showAllEntries = true"
                >
                  Show all
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
              <app-m3-falls-chart
              *ngIf="falls && falls.length > 1"
              [falls]="falls.slice().reverse()"
            ></app-m3-falls-chart>
  
          </div>
        </div>
      </div>
    </div>
  
    <hr />

    <div
      class="modal fade"
      bsModal
      #addFallModal="bs-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="dialog-static-name"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title pull-left">Add Falls in last year</h4>
            <button
              type="button"
              class="btn-close close pull-right"
              aria-label="Close"
              (click)="addFallModal.hide()"
            >
              <span aria-hidden="true" class="visually-hidden">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form
              name="entryFormFall"
              class="bs-form tracker-form"
              (ngSubmit)="onSubmitFallForm()"
              #entryFormFall="ngForm"
            >
  
          <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="fallsInput">Falls in the last year</label>
                  <input
                    type="number"
                    name="fallsInput"
                    class="form-control input-tracker input-tracker-xsm"
                    placeholder=""
                    id="fallsInput"
                    min="0"
                    max="100"
                    ngModel
                    autocomplete="off"
                    ng-required=""
                    #fallsInput="ngModel"
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="fallsInput.touched && fallsInput.invalid"
                  >
                    This must be a number below 100.
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="entryDateFall">DATE</label>
                  <input
                    name="entryDateFall"
                    type="text"
                    placeholder="DD/MM/YYYY"
                    class="form-control input-tracker input-tracker-sm"
                    bsDatepicker
                    autocomplete="off"
                    [bsConfig]="{
                      dateInputFormat: 'DD/MM/YYYY',
                      containerClass: 'theme-cv',
                      showWeekNumbers: false
                    }"
                    [(ngModel)]="entryDateFall"
                    required
                    [bsValue]="entryDateFall"
                    [maxDate]="entryDateFall"
                    #dateInput="ngModel"
                  />
                </div>
              </div>
            </div>
            <div class="tracker-submit-container">
              <button
                type="submit"
                role="button"
                class="btn btn-primary btn-lg btn-white"
              >
                UPDATE
              </button>
            </div>
            
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- end falls -->

    <!-- start 180 turns -->
    
    <h2 class="mt-2" id="hba1c">
      180&deg; Turn (balance)
      <a class="add-item-btn" (click)="adduser180TurnModal.show()"
        ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
      ></a>
    </h2>
    <div class="card" *ngIf="user180Turns">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            
              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                  <div class="info-stat-rag-current">
                    <span class="info-stat-label">180&deg; Turn (balance)</span>
                    <span
                      *ngIf="user180Turns[0]"
                      class="info-stat-stat text-rag-{{
                        user180TurnService.getRag(user180Turns[0].user180Turn)
                      }}"
                    >
                      <span *ngIf="user180Turns[0]">{{ user180Turns[0].steps }}</span
                      ><span *ngIf="!user180Turns[0]">?</span></span
                    >
                    <span *ngIf="!user180Turns[0]" class="info-stat-stat">-</span>
                    <span class="info-stat-label">steps</span>
                  </div>
  
                  <div class="rag-details">
                    <div class="rag-details-target">
                      Target:
                      <span class="text-blue">
                        {{ user180TurnService.getRangeforColor("green") }} steps
                      </span>
                    </div>
                    <div class="rag-details-history" *ngIf="user180Turns.length > 0">
                      History:
                      <div class="rag-history">
                        <ng-template #popTemplateuser180Turn>
                          <div>
                            <div>Range:</div>
                            <div class="d-flex">
                              <span class="rag-circle bg-rag-green mr-1"></span>
                              {{ user180TurnService.getRangeforColor("green") }} steps
                            </div>
                            <div class="d-flex">
                              <span class="rag-circle bg-rag-red mr-1"></span>
                              {{ user180TurnService.getRangeforColor("red") }} steps
                            </div>
                          </div></ng-template
                        >
                        <ng-container
                          *ngFor="
                            let user180Turn of user180Turns.slice().reverse();
                            let index = index
                          "
                        >
                          <div
                            *ngIf="index > user180Turns.length - 6"
                            [tooltip]="
                              user180Turn.steps +
                              '  steps ' +
                              ' on ' +
                              (user180Turn.date_recorded | dateFormatter : 'Do MMM YYYY')
                            "
                            class="rag-circle bg-rag-{{
                              user180TurnService.getRag(user180Turn.steps)
                            }}"
                          ></div>
                        </ng-container>
                        <div
                          class="rag-circle pointer"
                          [popover]="popTemplateuser180Turn"
                        >
                          <span class="icon-wrap"
                            ><fa-icon [icon]="['fas', 'info']"></fa-icon
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
            <h3 class="chart-title text-center mt-4" *ngIf="user180Turns">
              Your Entries
            </h3>
            <div class="table-responsive" *ngIf="user180Turns">
              <table *ngIf="user180Turns" class="table">
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>Steps</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let user180Turn of user180Turns; let index = index">
                    <tr *ngIf="index < 10 || showAllEntries">
                      <td class="text-center">
                        {{ user180Turn.date_recorded | dateFormatter : "MMM Do YYYY" }}
                      </td>
                      <td class="text-center">
                        {{ user180Turn.steps }}
                      </td>
  
                      <td>
                        <a
                          [swal]="{
                            title: 'Are you sure you want to delete this?',
                            icon: 'warning',
                            showCancelButton: true
                          }"
                          (confirm)="onDeleteuser180Turn(user180Turn)"
                          class="pointer"
                          ><span class="icon-wrap"
                            ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                        ></a>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <div class="text-center">
                <button
                  *ngIf="!showAllEntries && user180Turns.length > 10"
                  class="btn btn-primary btn-sm btn-xs"
                  (click)="showAllEntries = true"
                >
                  Show all
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
              <app-m3-user-turns-chart
              *ngIf="user180Turns && user180Turns.length > 1"
              [user180Turns]="user180Turns.slice().reverse()"
            ></app-m3-user-turns-chart>
  
          </div>
        </div>
      </div>
    </div>
  
    <hr />

    <div
      class="modal fade"
      bsModal
      #adduser180TurnModal="bs-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="dialog-static-name"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title pull-left">Add 180&deg; turn record</h4>
            <button
              type="button"
              class="btn-close close pull-right"
              aria-label="Close"
              (click)="adduser180TurnModal.hide()"
            >
              <span aria-hidden="true" class="visually-hidden">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form
              name="entryFormuser180Turn"
              class="bs-form tracker-form"
              (ngSubmit)="onSubmituser180TurnForm()"
              #entryForm180Turn="ngForm"
            >
  
          <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="user180TurnsInput">Steps</label>
                  <input
                    type="number"
                    name="user180TurnsInput"
                    class="form-control input-tracker input-tracker-xsm"
                    placeholder=""
                    id="user180TurnsInput"
                    min="0"
                    max="100"
                    ngModel
                    autocomplete="off"
                    ng-required=""
                    #user180TurnsInput="ngModel"
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="user180TurnsInput.touched && user180TurnsInput.invalid"
                  >
                    This must be a number below 100.
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="entryDateuser180Turn">DATE</label>
                  <input
                    name="entryDateuser180Turn"
                    type="text"
                    placeholder="DD/MM/YYYY"
                    class="form-control input-tracker input-tracker-sm"
                    bsDatepicker
                    autocomplete="off"
                    [bsConfig]="{
                      dateInputFormat: 'DD/MM/YYYY',
                      containerClass: 'theme-cv',
                      showWeekNumbers: false
                    }"
                    [(ngModel)]="entryDate180Turn"
                    required
                    [bsValue]="entryDate180Turn"
                    [maxDate]="entryDate180Turn"
                    #dateInput="ngModel"
                  />
                </div>
              </div>
            </div>
            <div class="tracker-submit-container">
              <button
                type="submit"
                role="button"
                class="btn btn-primary btn-lg btn-white"
              >
                UPDATE
              </button>
            </div>
            
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- end 180 turns -->




    <!-- start shortFes -->
    <h2 class="mt-2" id="hba1c">
      Short FES-I (confidence with balance)
      <a class="add-item-btn" (click)="addShortFesModal.show()"
        ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
      ></a>
    </h2>
    <div class="card" *ngIf="shortFess">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            
              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                  <div class="info-stat-rag-current">
                    <span class="info-stat-label">Short FES-I (confidence with balance)</span>
                    <span
                      *ngIf="shortFess[0]"
                      class="info-stat-stat text-rag-{{
                        userShortFesService.getRag(shortFess[0].short_fes)
                      }}"
                    >
                      <span *ngIf="shortFess[0]">{{ shortFess[0].short_fes }}</span
                      ><span *ngIf="!shortFess[0]">?</span></span
                    >
                    <span *ngIf="!shortFess[0]" class="info-stat-stat">-</span>
                    <span class="info-stat-label">&nbsp;</span>
                  </div>
  
                  <div class="rag-details">
                    <div class="rag-details-target">
                      Target:
                      <span class="text-blue">
                        {{ userShortFesService.getRangeforColor("green") }}
                      </span>
                    </div>
                    <div class="rag-details-history" *ngIf="shortFess.length > 0">
                      History:
                      <div class="rag-history">
                        <ng-template #popTemplateShortFes>
                          <div>
                            <div>Range:</div>
                            <div class="d-flex">
                              <span class="rag-circle bg-rag-green mr-1"></span>
                              {{ userShortFesService.getRangeforColor("green") }}
                            </div>
                            <div class="d-flex">
                              <span class="rag-circle bg-rag-amber mr-1"></span>
                              {{ userShortFesService.getRangeforColor("amber") }}
                            </div>
                            <div class="d-flex">
                              <span class="rag-circle bg-rag-red mr-1"></span>
                              {{ userShortFesService.getRangeforColor("red") }}
                            </div>
                          </div></ng-template
                        >
                        <ng-container
                          *ngFor="
                            let short_fes of shortFess.slice().reverse();
                            let index = index
                          "
                        >
                          <div
                            *ngIf="index > shortFess.length - 6"
                            [tooltip]="
                              short_fes.short_fes +
                              ' ' +
                              ' on ' +
                              (short_fes.date_recorded | dateFormatter : 'Do MMM YYYY')
                            "
                            class="rag-circle bg-rag-{{
                              userShortFesService.getRag(short_fes.short_fes)
                            }}"
                          ></div>
                        </ng-container>
                        <div
                          class="rag-circle pointer"
                          [popover]="popTemplateShortFes"
                        >
                          <span class="icon-wrap"
                            ><fa-icon [icon]="['fas', 'info']"></fa-icon
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
            <h3 class="chart-title text-center mt-4" *ngIf="shortFess">
              Your Entries
            </h3>
            <div class="table-responsive" *ngIf="shortFess">
              <table *ngIf="shortFess" class="table">
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>SCORE</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let short_fes of shortFess; let index = index">
                    <tr *ngIf="index < 10 || showAllEntries">
                      <td class="text-center">
                        {{ short_fes.date_recorded | dateFormatter : "MMM Do YYYY" }}
                      </td>
                      <td class="text-center">
                        {{ short_fes.short_fes }}
                      </td>
  
                      <td>
                        <a
                          [swal]="{
                            title: 'Are you sure you want to delete this?',
                            icon: 'warning',
                            showCancelButton: true
                          }"
                          (confirm)="onDeleteShortFes(short_fes)"
                          class="pointer"
                          ><span class="icon-wrap"
                            ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                        ></a>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <div class="text-center">
                <button
                  *ngIf="!showAllEntries && shortFess.length > 10"
                  class="btn btn-primary btn-sm btn-xs"
                  (click)="showAllEntries = true"
                >
                  Show all
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
              <app-m3-user-short-fes-chart
              *ngIf="shortFess && shortFess.length > 1"
              [shortFess]="shortFess.slice().reverse()"
            ></app-m3-user-short-fes-chart>
  
          </div>
        </div>
      </div>
    </div>
  
    <hr />

    <div
      class="modal fade"
      bsModal
      #addShortFesModal="bs-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="dialog-static-name"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title pull-left">Add Short FES-I Score </h4>
            <button
              type="button"
              class="btn-close close pull-right"
              aria-label="Close"
              (click)="addShortFesModal.hide()"
            >
              <span aria-hidden="true" class="visually-hidden">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form
              name="entryFormShortFes"
              class="bs-form tracker-form"
              (ngSubmit)="onSubmitShortFesForm()"
              #entryFormShortFes="ngForm"
            >
  
          <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="shortFesInput">Score </label>
                  <input
                    type="number"
                    name="shortFesInput"
                    class="form-control input-tracker input-tracker-xsm"
                    placeholder=""
                    id="shortFesInput"
                    min="0"
                    max="100"
                    ngModel
                    autocomplete="off"
                    ng-required=""
                    #shortFesInput="ngModel"
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="shortFesInput.touched && shortFesInput.invalid"
                  >
                    This must be a number below 100.
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="entryDateShortFes">DATE</label>
                  <input
                    name="entryDateShortFes"
                    type="text"
                    placeholder="DD/MM/YYYY"
                    class="form-control input-tracker input-tracker-sm"
                    bsDatepicker
                    autocomplete="off"
                    [bsConfig]="{
                      dateInputFormat: 'DD/MM/YYYY',
                      containerClass: 'theme-cv',
                      showWeekNumbers: false
                    }"
                    [(ngModel)]="entryDateShortFes"
                    required
                    [bsValue]="entryDateShortFes"
                    [maxDate]="entryDateShortFes"
                    #dateInput="ngModel"
                  />
                </div>
              </div>
            </div>
            <div class="tracker-submit-container">
              <button
                type="submit"
                role="button"
                class="btn btn-primary btn-lg btn-white"
              >
                UPDATE
              </button>
            </div>
            
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- end shortFes -->




    <!-- start sss8 -->
    <h2 class="mt-2" id="hba1c">
      SSS8 (symptoms)
      <a class="add-item-btn" (click)="addSss8Modal.show()"
        ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
      ></a>
    </h2>
    <div class="card" *ngIf="sss8s">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            
              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                  <div class="info-stat-rag-current">
                    <span class="info-stat-label">SSS8 (symptoms)</span>
                    <span
                      *ngIf="sss8s[0]"
                      class="info-stat-stat text-rag-{{
                        userSss8Service.getRag(sss8s[0].sss8)
                      }}"
                    >
                      <span *ngIf="sss8s[0]">{{ sss8s[0].sss8 }}</span
                      ><span *ngIf="!sss8s[0]">?</span></span
                    >
                    <span *ngIf="!sss8s[0]" class="info-stat-stat">-</span>
                    <span class="info-stat-label">&nbsp;</span>
                  </div>
  
                  <div class="rag-details">
                    <div class="rag-details-target">
                      Target:
                      <span class="text-blue">
                        {{ userSss8Service.getRangeforColor("green") }}
                      </span>
                    </div>
                    <div class="rag-details-history" *ngIf="sss8s.length > 0">
                      History:
                      <div class="rag-history">
                        <ng-template #popTemplateSss8>
                          <div>
                            <div>Range:</div>
                            <div class="d-flex">
                              <span class="rag-circle bg-rag-green mr-1"></span>
                              {{ userSss8Service.getRangeforColor("green") }}
                            </div>
                            <div class="d-flex">
                              <span class="rag-circle bg-rag-amber mr-1"></span>
                              {{ userSss8Service.getRangeforColor("amber") }}
                            </div>
                            <div class="d-flex">
                              <span class="rag-circle bg-rag-red mr-1"></span>
                              {{ userSss8Service.getRangeforColor("red") }}
                            </div>
                          </div></ng-template
                        >
                        <ng-container
                          *ngFor="
                            let sss8 of sss8s.slice().reverse();
                            let index = index
                          "
                        >
                          <div
                            *ngIf="index > sss8s.length - 6"
                            [tooltip]="
                              sss8.sss8 +
                              ' ' +
                              ' on ' +
                              (sss8.date_recorded | dateFormatter : 'Do MMM YYYY')
                            "
                            class="rag-circle bg-rag-{{
                              userSss8Service.getRag(sss8.sss8)
                            }}"
                          ></div>
                        </ng-container>
                        <div
                          class="rag-circle pointer"
                          [popover]="popTemplateSss8"
                        >
                          <span class="icon-wrap"
                            ><fa-icon [icon]="['fas', 'info']"></fa-icon
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
            <h3 class="chart-title text-center mt-4" *ngIf="sss8s">
              Your Entries
            </h3>
            <div class="table-responsive" *ngIf="sss8s">
              <table *ngIf="sss8s" class="table">
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>SCORE</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let sss8 of sss8s; let index = index">
                    <tr *ngIf="index < 10 || showAllEntries">
                      <td class="text-center">
                        {{ sss8.date_recorded | dateFormatter : "MMM Do YYYY" }}
                      </td>
                      <td class="text-center">
                        {{ sss8.sss8 }}
                      </td>
  
                      <td>
                        <a
                          [swal]="{
                            title: 'Are you sure you want to delete this?',
                            icon: 'warning',
                            showCancelButton: true
                          }"
                          (confirm)="onDeleteSss8(sss8)"
                          class="pointer"
                          ><span class="icon-wrap"
                            ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                        ></a>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <div class="text-center">
                <button
                  *ngIf="!showAllEntries && sss8s.length > 10"
                  class="btn btn-primary btn-sm btn-xs"
                  (click)="showAllEntries = true"
                >
                  Show all
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
              <app-m3-sss8-chart
              *ngIf="sss8s && sss8s.length > 1"
              [sss8s]="sss8s.slice().reverse()"
            ></app-m3-sss8-chart>
  
          </div>
        </div>
      </div>
    </div>
  
    <hr />

    <div
      class="modal fade"
      bsModal
      #addSss8Modal="bs-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="dialog-static-name"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title pull-left">Add SSS8 </h4>
            <button
              type="button"
              class="btn-close close pull-right"
              aria-label="Close"
              (click)="addSss8Modal.hide()"
            >
              <span aria-hidden="true" class="visually-hidden">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form
              name="entryFormSss8"
              class="bs-form tracker-form"
              (ngSubmit)="onSubmitSss8Form()"
              #entryFormSss8="ngForm"
            >
  
          <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="sss8Input">SSS8</label>
                  <input
                    type="number"
                    name="sss8Input"
                    class="form-control input-tracker input-tracker-xsm"
                    placeholder=""
                    id="sss8Input"
                    min="0"
                    max="100"
                    ngModel
                    autocomplete="off"
                    ng-required=""
                    #sss8Input="ngModel"
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="sss8Input.touched && sss8Input.invalid"
                  >
                    This must be a number below 100.
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="entryDateSss8">DATE</label>
                  <input
                    name="entryDateSss8"
                    type="text"
                    placeholder="DD/MM/YYYY"
                    class="form-control input-tracker input-tracker-sm"
                    bsDatepicker
                    autocomplete="off"
                    [bsConfig]="{
                      dateInputFormat: 'DD/MM/YYYY',
                      containerClass: 'theme-cv',
                      showWeekNumbers: false
                    }"
                    [(ngModel)]="entryDateSss8"
                    required
                    [bsValue]="entryDateSss8"
                    [maxDate]="entryDateSss8"
                    #dateInput="ngModel"
                  />
                </div>
              </div>
            </div>
            <div class="tracker-submit-container">
              <button
                type="submit"
                role="button"
                class="btn btn-primary btn-lg btn-white"
              >
                UPDATE
              </button>
            </div>
            
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- end sss8 -->




    <!-- start distress -->
    <h2 class="mt-2" id="hba1c">
      Distress Thermometer (emotional distress)
      <a class="add-item-btn" (click)="addDistressModal.show()"
        ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
      ></a>
    </h2>
    <div class="card" *ngIf="distressScores">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            
              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                  <div class="info-stat-rag-current">
                    <span class="info-stat-label">Distress Thermometer (emotional distress)</span>
                    <span
                      *ngIf="distressScores[0]"
                      class="info-stat-stat text-rag-{{
                        userDistressScoreService.getRag(distressScores[0].distress_score)
                      }}"
                    >
                      <span *ngIf="distressScores[0]">{{ distressScores[0].distress_score }}</span
                      ><span *ngIf="!distressScores[0]">?</span></span
                    >
                    <span *ngIf="!distressScores[0]" class="info-stat-stat">-</span>
                    <span class="info-stat-label">&nbsp;</span>
                  </div>
  
                  <div class="rag-details">
                    <div class="rag-details-target">
                      Target:
                      <span class="text-blue">
                        {{ userDistressScoreService.getRangeforColor("green") }}
                      </span>
                    </div>
                    <div class="rag-details-history" *ngIf="distressScores.length > 0">
                      History:
                      <div class="rag-history">
                        <ng-template #popTemplateDistress>
                          <div>
                            <div>Range:</div>
                            <div class="d-flex">
                              <span class="rag-circle bg-rag-green mr-1"></span>
                              {{ userDistressScoreService.getRangeforColor("green") }}
                            </div>
                            <div class="d-flex">
                              <span class="rag-circle bg-rag-amber mr-1"></span>
                              {{ userDistressScoreService.getRangeforColor("amber") }}
                            </div>
                            <div class="d-flex">
                              <span class="rag-circle bg-rag-red mr-1"></span>
                              {{ userDistressScoreService.getRangeforColor("red") }}
                            </div>
                          </div></ng-template
                        >
                        <ng-container
                          *ngFor="
                            let distress of distressScores.slice().reverse();
                            let index = index
                          "
                        >
                          <div
                            *ngIf="index > distressScores.length - 6"
                            [tooltip]="
                              distress.distress_score +
                              ' ' +
                              ' on ' +
                              (distress.date_recorded | dateFormatter : 'Do MMM YYYY')
                            "
                            class="rag-circle bg-rag-{{
                              userDistressScoreService.getRag(distress.distress_score)
                            }}"
                          ></div>
                        </ng-container>
                        <div
                          class="rag-circle pointer"
                          [popover]="popTemplateDistress"
                        >
                          <span class="icon-wrap"
                            ><fa-icon [icon]="['fas', 'info']"></fa-icon
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
            <h3 class="chart-title text-center mt-4" *ngIf="distressScores">
              Your Entries
            </h3>
            <div class="table-responsive" *ngIf="distressScores">
              <table *ngIf="distressScores" class="table">
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>DISTRESS SCORE</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let distress of distressScores; let index = index">
                    <tr *ngIf="index < 10 || showAllEntries">
                      <td class="text-center">
                        {{ distress.date_recorded | dateFormatter : "MMM Do YYYY" }}
                      </td>
                      <td class="text-center">
                        {{ distress.distress_score }}
                      </td>
  
                      <td>
                        <a
                          [swal]="{
                            title: 'Are you sure you want to delete this?',
                            icon: 'warning',
                            showCancelButton: true
                          }"
                          (confirm)="onDeleteDistress(distress)"
                          class="pointer"
                          ><span class="icon-wrap"
                            ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                        ></a>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <div class="text-center">
                <button
                  *ngIf="!showAllEntries && distressScores.length > 10"
                  class="btn btn-primary btn-sm btn-xs"
                  (click)="showAllEntries = true"
                >
                  Show all
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
              <app-m3-distress-chart
              *ngIf="distressScores && distressScores.length > 1"
              [distressScores]="distressScores.slice().reverse()"
            ></app-m3-distress-chart>
  
          </div>
        </div>
      </div>
    </div>
  
    <hr />

    <div
      class="modal fade"
      bsModal
      #addDistressModal="bs-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="dialog-static-name"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title pull-left">Add Distress Thermometer Score</h4>
            <button
              type="button"
              class="btn-close close pull-right"
              aria-label="Close"
              (click)="addDistressModal.hide()"
            >
              <span aria-hidden="true" class="visually-hidden">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form
              name="entryFormDistress"
              class="bs-form tracker-form"
              (ngSubmit)="onSubmitDistressForm()"
              #entryFormDistressScore="ngForm"
            >
  
          <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="distressScoreInput">Distress Thermometer Score</label>
                  <input
                    type="number"
                    name="distressScoreInput"
                    class="form-control input-tracker input-tracker-xsm"
                    placeholder=""
                    id="distressScoreInput"
                    min="0"
                    max="100"
                    ngModel
                    autocomplete="off"
                    ng-required=""
                    #distressScoreInput="ngModel"
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="distressScoreInput.touched && distressScoreInput.invalid"
                  >
                    This must be a number below 100.
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="entryDateDistress">DATE</label>
                  <input
                    name="entryDateDistress"
                    type="text"
                    placeholder="DD/MM/YYYY"
                    class="form-control input-tracker input-tracker-sm"
                    bsDatepicker
                    autocomplete="off"
                    [bsConfig]="{
                      dateInputFormat: 'DD/MM/YYYY',
                      containerClass: 'theme-cv',
                      showWeekNumbers: false
                    }"
                    [(ngModel)]="entryDateDistressScore"
                    required
                    [bsValue]="entryDateDistressScore"
                    [maxDate]="entryDateDistressScore"
                    #dateInput="ngModel"
                  />
                </div>
              </div>
            </div>
            <div class="tracker-submit-container">
              <button
                type="submit"
                role="button"
                class="btn btn-primary btn-lg btn-white"
              >
                UPDATE
              </button>
            </div>
            
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- end distress -->
    
  
    <div class="clear"></div>
  </div>
  