import { UserService } from './../services/user.service';
import { AuthService } from './../auth/auth.service';
import { ProgrammeService } from './../services/programme.service';
import { environment } from './../../environments/environment';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CacheManagerService } from '../services/cache-manager.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  isFetching = false;
  currentProgramme;
  currentUser;
  newNotifications;
  environment = environment;
  programme;
  contentSection;
  showProgrammesMenu;
  showEducationMenu;
  currentTeam;
  programmeContent;
  currentProgrammes;

  @Output() closeMenu: EventEmitter<any> = new EventEmitter();
  @Output() showMobileMenu: EventEmitter<any> = new EventEmitter();

  constructor(
    private authService: AuthService,
    private programmeService: ProgrammeService,
    private userService: UserService,
    private router: Router,
    private cacheManagerService: CacheManagerService
  ) {}

  ngOnInit(): void {
    this.isFetching = true;
    // are we logged in?
    this.authService.user.subscribe((user) => {
      if (user) {
        this.programmeService.currentUserProgrammes.subscribe(
          (currentProgrammeData) => {
            this.currentProgrammes = currentProgrammeData;
          }
        );

        this.programmeService.currentProgramme.subscribe((programmeData) => {
          if (programmeData) {
            this.currentProgramme = programmeData;
            this.programme = programmeData;

            this.programmeService
              .getProgrammeContent(this.currentProgramme.programme_id)
              .subscribe((responseData) => {
                this.programmeContent = responseData;
              });
          }
        });
        this.userService.userData.subscribe((userDataResponse) => {
          this.currentUser = userDataResponse;
        });
      }
    });
  }

  setCurrentProgramme(programmeId) {}

  onCloseMenu(event) {
    this.closeMenu.emit(true);
  }

  onShowMobileMenu(event) {
    this.showMobileMenu.emit(true);
  }
  onLogout(): void {
    this.cacheManagerService.clearAllCache();
    this.authService.logout();
  }

  onSetCurrentProgramme(programme) {
    this.programmeService.setCurrentProgramme(programme);

    if (programme.config.exerciseProgramme == '1') {
      this.router.navigate(['/setup-ep']);
    } else {
      this.router
        .navigateByUrl('/page-not-found', { skipLocationChange: true })
        .then(() => this.router.navigate(['/dashboard']));
    }
  }
}
