<nav class="subnav">
  <a
    [routerLink]="['/activity', 'strength']"
    aria-label="Go to strength training"
    class="subnav-prev"
    ><span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
  ></a>
  <h2 *ngIf="currentUser">
    <span class="icon-wrap">
      <fa-icon [icon]="['far', 'dumbbell']"></fa-icon>
    </span>
    STRENGTH ENTRY
  </h2>
</nav>
<div>
  <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
  <div class="toggle-content" *ngIf="userWorkout">
    <div class="row mx-0">
      <div class="col-md-12">
        <div class="label-header">Workout Overview</div>
        <div class="workout-overview">
          <div class="row">
            <div class="col my-auto">
              <div class="workout-overview-value">
                {{ userWorkout.workout_date | dateFormatter: "D" }}
              </div>
              <div class="workout-overview-label">
                {{ userWorkout.workout_date | dateFormatter: "MMM" }}
              </div>
            </div>
            <div class="col my-auto">
              <div class="workout-overview-value">
                {{ userWorkout.minutes }}
              </div>
              <div class="workout-overview-label">Mins</div>
            </div>
            <div class="col my-auto">
              <div
                class="workout-overview-value workout-overview-value-circle bg-effort-{{
                  userWorkout.effort
                }}"
              >
                {{ userWorkout.effort }}
              </div>
              <div class="workout-overview-label">Effort</div>
            </div>
            <div class="col my-auto">
              <div class="workout-overview-value">
                {{ userWorkout.reps }} <span *ngIf="!userWorkout.reps">-</span>
              </div>
              <div class="workout-overview-label">Reps</div>
            </div>
            <div class="col my-auto">
              <a
                [routerLink]="[
                  '/activity',
                  'strength',
                  'edit',
                  userWorkout.user_strength_workout_id
                ]"
                ><span class="icon-wrap" aria-label="Edit Workout">
                  <fa-icon
                    [icon]="['far', 'arrow-circle-right']"
                  ></fa-icon> </span
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-0 mt-4">
      <div class="col-md-12">
        <div class="label-header">Workout Details</div>

        <div *ngIf="!userExercises">
          <p>
            Keep track of your workouts by adding each exercise you completed
            during your session.
          </p>
        </div>

        <div *ngFor="let area of areas">
          <div *ngIf="(userExercises | filterObjectArray: { area: area }).length > 0" class="strength-workout-exercise-area">
            <h4>{{ area }}</h4>
            <div
              *ngFor="
                let exercise of userExercises
                  | filterObjectArray: { area: area }
              "
              class="strength-workout-exercise"
            >
              <div class="strength-workout-exercise-box">
                <div class="strength-workout-exercise-title">
                  <a
                    (click)="
                      onDeleteExercise(
                        exercise.user_strength_workout_exercise_id
                      )
                    "
                    class="float-right"
                    ><span class="icon-wrap" aria-label="Delete Exercise">
                      <fa-icon [icon]="['fas', 'trash']"></fa-icon> </span
                  ></a>
                  {{ exercise.name }}
                </div>
                <div class="strength-workout-exercise-details">
                  <span
                    >Set 1: <strong>{{ exercise.set1 }}</strong></span
                  >
                  <span
                    >Set 2: <strong>{{ exercise.set2 }}</strong></span
                  >
                  <span
                    >Set 3: <strong>{{ exercise.set3 }}</strong></span
                  >
                  <span
                    >Effort:
                    <div
                      class="strength-entry-circle bg-effort-{{
                        exercise.effort
                      }}"
                    >
                      {{ exercise.effort }}
                    </div></span
                  >
                  <span
                    >Band:
                    <span
                      class="band-style"
                      [ngStyle]="{ 'background-color': exercise.band }"
                    ></span
                    ><strong>{{ exercise.band | titlecase }}</strong></span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center">
          <button
            class="btn btn-primary"
            [routerLink]="[
              '/activity',
              'strength',
              'add-exercise',
              userWorkout.user_strength_workout_id
            ]"
          >
            <span class="icon-wrap">
              <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>
            </span>
            Add Exercise
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
