<div
class="mt-3 mb-3 tracker-chart">
  <h3 class="text-center chart-title mb-3">Daily walking and non stepping activity </h3>
  <canvas
    baseChart
    [datasets]="barChartData"
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [legend]="barChartLegend"
    [chartType]="barChartType"
    [colors]="barChartColors"
  >
  </canvas></div>