<nav class="subnav">
  <h2>
    <span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'question']"></fa-icon
    ></span>
    CONTINUE ACCESS
  </h2>
</nav>
<div *ngIf="isFetching">
  <app-loading-spinner [inline]="1"></app-loading-spinner>
</div>
<div *ngIf="currentUser" class="p-3">
  <p *ngIf="daysLeft!=0">Your account is due to expire in {{daysLeft}} day<span *ngIf="daysLeft!=1">s</span>.</p>
  <p *ngIf="daysLeft==0">Your account is due to expire.</p>


  <p>
    Please let us know if you'd like to continue using your account by pressing
    the confirm button below
  </p>

  <div>
    <a (click)="continueAccess()" role="button" class="btn btn-success mr-3"
    >CONFIRM
    <span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'check']"></fa-icon></span
  ></a>

  <a [routerLink]="['/dashboard']" role="button" class="btn btn-primary mr-1"
    >DISMISS
    <span class="icon-wrap"
      ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span
  ></a>
  </div>
 
</div>
