import { environment } from 'src/environments/environment';
import { CmsService } from './../services/cms.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  cms;
  environment = environment;

  constructor(private cmsService: CmsService) {}

  ngOnInit(): void {
    this.cmsService.fetchByKey(['about']).subscribe((responseData) => {
      this.cms = responseData;
    });
  }
}
