<div class="container m3" *ngIf="currentUser">
    <app-m3-header class="d-none d-sm-block"></app-m3-header>
    <app-m3-mobile-navbar
      [pageTitle]="'Health Data'"
      class="d-block d-sm-none"
    ></app-m3-mobile-navbar>
    <nav class="subnav d-block d-sm-none">
      <h2>Heart Health</h2>
    </nav>
    <h1 class="m3-title mt-3 d-none d-sm-block"> <button class="btn btn-white btn-sm float-right mt-1" [routerLink]="['/m3','health-data']"> &lt; Back</button>
      <app-m3-icon [icon]="'heart'"></app-m3-icon> Heart Health
    </h1>
  
    <!--button (click)="addModal.show()" class="btn btn-mobile-add d-block d-sm-none">
        <app-m3-icon [icon]="'more'" [style]="'color'"></app-m3-icon
        > Add a Sitting Entry</button-->
  
    <h2 class="mt-2" id="hba1c">
     
      Blood Pressure
      <a class="add-item-btn" (click)="addModal.show()"
        ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
      ></a>
    </h2>
    <div class="card" *ngIf="bloodPressures[0]">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            
            
            <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
              <div class="info-stat-rag-current">
                <span class="info-stat-label">BP Systolic</span>
                <span
                  *ngIf="bloodPressures[0]"
                  class="info-stat-stat text-rag-{{
                    bloodPressureService.getSystolicRag(bloodPressures[0].systolic)
                  }}"
                >
                  <span *ngIf="bloodPressures[0]">{{
                    bloodPressures[0].systolic
                  }}</span
                  ><span *ngIf="!bloodPressures[0]">?</span></span
                >
                <span *ngIf="!bloodPressures[0]" class="info-stat-stat"
                  >-</span
                >
                <span class="info-stat-label">mmHg</span>
              </div>

              <div class="rag-details">
                <div class="rag-details-target">
                  Target:
                  <span class="text-blue">≤139 mmHg</span>
                </div>
                <div
                  class="rag-details-history"
                  *ngIf="bloodPressures.length > 0"
                >
                  History:
                  <div class="rag-history">
                    <ng-template #popTemplateglucose>
                      <div>
                        <div>Range:</div>
                        <div class="d-flex">
                          <span class="rag-circle bg-rag-green mr-1"></span>

                          ≤139 mmHg
                        </div>
                        <div class="d-flex">
                          <span class="rag-circle bg-rag-amber mr-1"></span>

                          140-149 mmHg
                        </div>
                        <div class="d-flex">
                          <span class="rag-circle bg-rag-red mr-1"></span>

                          >149 mmHg
                        </div>
                      </div></ng-template
                    >
                    <ng-container
                      *ngFor="
                        let bloodPressure of bloodPressures.slice().reverse();
                        let index = index
                      "
                    >
                      <div
                        *ngIf="index > bloodPressures.length - 6"
                        [tooltip]="
                          bloodPressure.systolic +
                          ' mmHg' +
                          ' on ' +
                          (bloodPressure.created
                            | dateFormatter : 'Do MMM YYYY')
                        "
                        class="rag-circle bg-rag-{{
                          bloodPressureService.getSystolicRag(bloodPressure.systolic)
                        }}"
                      ></div>
                    </ng-container>
                    <div
                      class="rag-circle pointer"
                      [popover]="popTemplateglucose"
                    >
                      <span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'info']"></fa-icon
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
              <div class="info-stat-rag-current">
                <span class="info-stat-label">BP Diastolic</span>
                <span
                  *ngIf="bloodPressures[0]"
                  class="info-stat-stat text-rag-{{
                    bloodPressureService.getDiastolicRag(bloodPressures[0].diastolic)
                  }}"
                >
                  <span *ngIf="bloodPressures[0]">{{
                    bloodPressures[0].diastolic
                  }}</span
                  ><span *ngIf="!bloodPressures[0]">?</span></span
                >
                <span *ngIf="!bloodPressures[0]" class="info-stat-stat"
                  >-</span
                >
                <span class="info-stat-label">mmHg</span>
              </div>

              <div class="rag-details">
                <div class="rag-details-target">
                  Target:
                  <span class="text-blue">≤89 mmHg</span>
                </div>
                <div
                  class="rag-details-history"
                  *ngIf="bloodPressures.length > 0"
                >
                  History:
                  <div class="rag-history">
                    <ng-template #popTemplateglucose>
                      <div>
                        <div>Range:</div>
                        <div class="d-flex">
                          <span class="rag-circle bg-rag-green mr-1"></span>

                          ≤89 mmHg
                        </div>
                        <div class="d-flex">
                          <span class="rag-circle bg-rag-amber mr-1"></span>

                          90-99 mmHg
                        </div>
                        <div class="d-flex">
                          <span class="rag-circle bg-rag-red mr-1"></span>

                          >99 mmHg
                        </div>
                      </div></ng-template
                    >
                    <ng-container
                      *ngFor="
                        let bloodPressure of bloodPressures.slice().reverse();
                        let index = index
                      "
                    >
                      <div
                        *ngIf="index > bloodPressures.length - 6"
                        [tooltip]="
                          bloodPressure.diastolic +
                          ' mmHg' +
                          ' on ' +
                          (bloodPressure.created
                            | dateFormatter : 'Do MMM YYYY')
                        "
                        class="rag-circle bg-rag-{{
                          bloodPressureService.getDiastolicRag(
                            bloodPressure.diastolic
                          )
                        }}"
                      ></div>
                    </ng-container>
                    <div
                      class="rag-circle pointer"
                      [popover]="popTemplateglucose"
                    >
                      <span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'info']"></fa-icon
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

    
            
    
            <h3 class="chart-title text-center mt-4" *ngIf="bloodPressures">Your Entries</h3>
            <div class="table-responsive" *ngIf="bloodPressures">
              <table *ngIf="bloodPressures" class="table">
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>SYSTOLIC</th>
                    <th>DIASTOLIC</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let bloodPressure of bloodPressures; let index = index">
                    <tr *ngIf="index < 10 || showAllEntries">
                      <td class="text-center">
                        {{ bloodPressure.created | dateFormatter : "MMM Do YYYY" }}
                      </td>
                      <td class="text-center">
                        {{ bloodPressure.systolic }}
                      </td>
                      <td class="text-center">
                        {{ bloodPressure.diastolic }}
                      </td>
                      <td>
                        <a
                          [swal]="{
                            title: 'Are you sure you want to delete this?',
                            icon: 'warning',
                            showCancelButton: true
                          }"
                          (confirm)="onDelete(bloodPressure)"
                          class="pointer"
                          ><span class="icon-wrap"
                            ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                        ></a>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <div class="text-center">
                <button
                  *ngIf="!showAllEntries && bloodPressures.length > 10"
                  class="btn btn-primary btn-sm btn-xs"
                  (click)="showAllEntries = true"
                >
                  Show all
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <app-m3-bp-chart
            *ngIf="bloodPressures && bloodPressures.length > 1"
            [bloodPressures]="bloodPressures.slice().reverse()"
          ></app-m3-bp-chart>
          </div>
        </div>
      </div>
    </div>
  
  <hr>
    
  <h2 class="mt-2">
  Cholesterol
    <a class="add-item-btn" (click)="addCholesterolModal.show()"
      ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
    ></a>
  </h2>
  <div class="card" *ngIf="cholesterols">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          
          
          <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
            <div class="info-stat-rag-current">
              <span class="info-stat-label">LDL cholesterol </span>
              <span
                *ngIf="cholesterols[0]"
                class="info-stat-stat text-rag-{{
                  cholesterolService.getRag(cholesterols[0].ldl, 'ldl')
                }}"
              >
                <span *ngIf="cholesterols[0]">{{
                  cholesterols[0].ldl
                }}</span
                ><span *ngIf="!cholesterols[0]">?</span></span
              >
              <span *ngIf="!cholesterols[0]" class="info-stat-stat">-</span>
              <span class="info-stat-label">mmol/L</span>
            </div>

            <div class="rag-details">
              <div class="rag-details-target">
                Target:
                <span class="text-blue">&lt;1.4 mmol/L</span>
              </div>
              <div
                class="rag-details-history"
                *ngIf="cholesterols.length > 0"
              >
                History:
                <div class="rag-history">
                  <ng-template #popTemplateglucose>
                    <div>
                      <div>Range:</div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-green mr-1"></span>
                        &lt;1.4 mmol/L
                      </div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-amber mr-1"></span>
                        1.4-2.5 mmol/L
                      </div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-red mr-1"></span>
                        >2.5 mmol/L
                      </div>
                    </div></ng-template
                  >
                  <ng-container
                    *ngFor="
                      let cholesterol of cholesterols.slice().reverse();
                      let index = index
                    "
                  >
                    <div
                      *ngIf="index > cholesterols.length - 6"
                      [tooltip]="
                        cholesterol.ldl +
                        ' mmol/L' +
                        ' on ' +
                        (cholesterol.created
                          | dateFormatter : 'Do MMM YYYY')
                      "
                      class="rag-circle bg-rag-{{
                        cholesterolService.getRag(cholesterol.ldl, 'ldl')
                      }}"
                    ></div>
                  </ng-container>
                  <div
                    class="rag-circle pointer"
                    [popover]="popTemplateglucose"
                  >
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['fas', 'info']"></fa-icon
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
            <div class="info-stat-rag-current">
              <span class="info-stat-label">Cholesterol / HDL ratio </span>
              <span
                *ngIf="hdlRatios[0]"
                class="info-stat-stat text-rag-{{
                  cholesterolService.getRag(hdlRatios[0].hdl_ratio, 'hdl_ratio')
                }}"
              >
                <span *ngIf="hdlRatios[0]">{{
                  hdlRatios[0].hdl_ratio
                }}</span
                ><span *ngIf="!hdlRatios[0]">?</span></span
              >
              <span *ngIf="!hdlRatios[0]" class="info-stat-stat">-</span>
              <span class="info-stat-label"></span>
            </div>

            <div class="rag-details">
              <div class="rag-details-target">
                Target:
                <span class="text-blue">&lt;6.0</span>
              </div>
              <div
                class="rag-details-history"
                *ngIf="cholesterols.length > 0"
              >
                History:
                <div class="rag-history">
                  <ng-template #popTemplateglucose>
                    <div>
                      <div>Range:</div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-green mr-1"></span>
                        &lt;6.0
                      </div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-red mr-1"></span>
                        ≥6.0
                      </div>
                    </div></ng-template
                  >
                  <ng-container
                    *ngFor="
                      let hdlRatio of hdlRatios.slice().reverse();
                      let index = index
                    "
                  >
                    <div
                      *ngIf="hdlRatio.hdl_ratio && index > hdlRatios.length - 6"
                      [tooltip]="
                      hdlRatio.hdl_ratio +
                        ' on ' +
                        (hdlRatio.created
                          | dateFormatter : 'Do MMM YYYY')
                      "
                      class="rag-circle bg-rag-{{
                        cholesterolService.getRag(hdlRatio.hdl_ratio, 'hdl_ratio')
                      }}"
                    ></div>
                  </ng-container>
                  <div
                    class="rag-circle pointer"
                    [popover]="popTemplateglucose"
                  >
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['fas', 'info']"></fa-icon
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          
          
  
          <h3 class="chart-title text-center mt-4" *ngIf="cholesterols">Your Entries</h3>
          <div class="table-responsive" *ngIf="cholesterols">
            <table *ngIf="cholesterols" class="table">
              <thead>
                <tr>
                  <th>DATE</th>
                  <th>LDL</th>
                  <th>Cholesterol/HDL ratio</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let cholesterol of cholesterols; let index = index">
                  <tr *ngIf="index < 10 || showAllEntries">
                    <td class="text-center">
                      {{ cholesterol.created | dateFormatter : "MMM Do YYYY" }}
                    </td>
                    <td class="text-center">
                      {{ cholesterol.ldl }}
                    </td>
                    <td class="text-center">
                      {{ cholesterol.hdl_ratio }}
                    </td>
  
  
                    <td>
                      <a
                        [swal]="{
                          title: 'Are you sure you want to delete this?',
                          icon: 'warning',
                          showCancelButton: true
                        }"
                        (confirm)="onDeleteCholesterol(cholesterol)"
                        class="pointer"
                        ><span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                      ></a>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <div class="text-center">
              <button
                *ngIf="!showAllEntries && cholesterols.length > 10"
                class="btn btn-primary btn-sm btn-xs"
                (click)="showAllEntries = true"
              >
                Show all
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <app-m3-cholesterol-chart
          *ngIf="cholesterols && cholesterols.length > 1"
          [cholesterols]="cholesterols.slice().reverse()"
        ></app-m3-cholesterol-chart>
        </div>
      </div>
    </div>
  </div>

  <!--
  <hr>
  <h2 class="mt-2">
    Smoking
    <a class="add-item-btn" (click)="addLevelModal.show()"
      ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
    ></a>
  </h2>
  <div class="card" *ngIf="cholesterols">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
            <div class="info-stat-rag-current">
              <span class="info-stat-label">Smoking</span>
              <span
                *ngIf="cholesterols[0]"
                class="info-stat-stat text-rag-{{
                  getDiastolicRag(cholesterols[0].user_glucose_average)
                }}"
              >
                <span *ngIf="cholesterols[0]">{{ cholesterols[0].user_glucose_average }}</span
                ><span *ngIf="!cholesterols[0]">?</span></span
              >
              <span *ngIf="!cholesterols[0]" class="info-stat-stat">-</span>
            </div>
  
            <div class="rag-details">
              <div class="rag-details-target"></div>
              <div class="rag-details-history" *ngIf="cholesterols.length > 0">
                History:
                <div class="rag-history">
                  <ng-container *ngFor="let diastolic of cholesterols; let index = index">
                    <div
                      *ngIf="index < 5"
                      [tooltip]="
                        diastolic.user_glucose_average +
                        '' +
                        ' on ' +
                        (diastolic.date_recorded | dateFormatter : 'Do MMM YYYY')
                      "
                      class="rag-circle bg-rag-{{
                        getDiastolicRag(diastolic.user_glucose_average)
                      }}"
                    ></div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
  
          <div
            class="info-stat info-stat-rag info-stat-no-icon mb-3"
            *ngIf="cholesterols[0]"
          >
            <div class="info-stat-rag-current">
              <span class="info-stat-label">Smoking
            </span>
              <span
                *ngIf="cholesterols[0]"
                class="info-stat-stat text-rag-{{ getDiastolicRag(cholesterols[0].change) }}"
              >
                <span *ngIf="cholesterols[0]">{{ cholesterols[0].change }}</span
                ><span *ngIf="!cholesterols[0]">?</span></span
              >
              <span *ngIf="!cholesterols[0]" class="info-stat-stat">-</span>
              <span class="info-stat-label"></span>
            </div>
  
            <div class="rag-details">
              <div class="rag-details-target"></div>
              <div class="rag-details-history" *ngIf="cholesterols.length > 0">
                History:
                <div class="rag-history">
                  <ng-container *ngFor="let diastolic of cholesterols; let index = index">
                    <div
                      *ngIf="index < 5"
                      [tooltip]="
                        diastolic.change +
                        ' ' +
                        ' on ' +
                        (diastolic.date_recorded | dateFormatter : 'Do MMM YYYY')
                      "
                      class="rag-circle bg-rag-{{
                        getDiastolicRag(diastolic.user_glucose_average)
                      }}"
                    ></div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
  
          <h3 class="chart-title text-center mt-4" *ngIf="cholesterols">Your Entries</h3>
          <div class="table-responsive" *ngIf="cholesterols">
            <table *ngIf="cholesterols" class="table">
              <thead>
                <tr>
                  <th>DATE</th>
                  <th>Smoking
                </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let diastolic of cholesterols; let index = index">
                  <tr *ngIf="index < 10 || showAllEntries">
                    <td class="text-center">
                      {{ diastolic.date_recorded | dateFormatter : "MMM Do YYYY" }}
                    </td>
                    <td class="text-center">
                      {{ diastolic.user_glucose_average }}
                    </td>
  
                    <td>
                      <a
                        [swal]="{
                          title: 'Are you sure you want to delete this?',
                          icon: 'warning',
                          showCancelButton: true
                        }"
                        (confirm)="onDeleteDiastolic(diastolic)"
                        class="pointer"
                        ><span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                      ></a>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <div class="text-center">
              <button
                *ngIf="!showAllEntries && cholesterols.length > 10"
                class="btn btn-primary btn-sm btn-xs"
                (click)="showAllEntries = true"
              >
                Show all
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="lead p-4 text-center">Chart will go here</div>
        </div>
      </div>
    </div>
  </div>
-->  
  <div
    class="modal fade"
    bsModal
    #addModal="bs-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-static-name"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left">Update Blood Pressure</h4>
          <button
            type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="addModal.hide()"
          >
            <span aria-hidden="true" class="visually-hidden">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form
        class="bs-form tracker-form"
        name="trackerForm"
        method="post"
        (ngSubmit)="onSubmit()"
        #trackerForm="ngForm"
      >
        <div >
          <div class="form-group">
            <label for="systolic">SYSTOLIC (mmHg)</label>
            <input
              type="number"
              name="systolic"
              class="form-control input-tracker"
              placeholder=""
              id="systolic"
             ngModel
             #systolic="ngModel"
              [min]="diastolic.value  || 70"
              [max]="239"
              autocomplete="off"
              required
            />
            <div
              class="alert alert-danger"
              *ngIf="
                systolic.touched && !systolic.valid
              "
            >
              This must be a number between
              {{ diastolic.value || 70 }} and 240, inclusive.
            </div>
          </div>
          <div class="form-group">
            <label for="diastolic">DIASTOLIC (mmHg)</label>
            <input
              type="number"
              name="diastolic"
              class="form-control input-tracker"
              placeholder=""
              id="diastolic"
              ngModel
              #diastolic="ngModel"
              [min]="30"
              [max]="150"
              autocomplete="off"
              required
            />
            <div
              class="alert alert-danger"
              *ngIf="
                diastolic.touched && !diastolic.valid 
              "
            >
              This must be a number between 30 and 150, inclusive.
            </div>
          </div>
          <div class="form-group">
            <label for="entryDate">DATE</label>
            <p class="instructions">
              When did you do take this reading?
            </p>
            <input
              name="date"
              type="text"
              placeholder="DD/MM/YYYY"
              class="form-control input-tracker"
              bsDatepicker
              autocomplete="off"
              [bsConfig]="{
                dateInputFormat: 'DD/MM/YYYY',
                containerClass: 'theme-cv',
                showWeekNumbers: false
              }"
              [(ngModel)]="entryDate"
              required
              [bsValue]="entryDate"
              [maxDate]="maxDate"
              #date="ngModel"
            />
          </div>
          <div class="tracker-submit-container">
            <button
              type="submit"
              class="btn btn-primary btn-white"
              [disabled]="!trackerForm.valid"
            >
            <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'check']"></fa-icon
          ></span> CONFIRM
            </button>
          </div>
        </div>
      </form>
        </div>
      </div>
    </div>
  </div>
  
  <!--  addCholesterolModal  -->
  
  <div
    class="modal fade"
    bsModal
    #addCholesterolModal="bs-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-static-name"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left">Update Cholesterol Level</h4>
          <button
            type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="addCholesterolModal.hide()"
          >
            <span aria-hidden="true" class="visually-hidden">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form
        class="bs-form tracker-form"
        name="cholesterolForm"
        method="post"
        (ngSubmit)="onSubmitCholesterol()"
        #cholesterolForm="ngForm"
      >
        <div>
          
          
          <div class="form-group">
            <label for="ldl">LDL (mmol/L)</label>

            <p class="instruction">
              Please enter your latest LDL Cholesterol measure below in mmol/L.
            </p>
            <input
              type="number"
              name="ldl"
              class="form-control input-tracker"
              placeholder=""
              [ngModel]
              id="ldl"
              [min]="0"
              [max]="10"
              autocomplete="off"
              #ldl="ngModel"
            />
            <div
              class="alert alert-danger"
              *ngIf="ldl.errors?.min || ldl.errors?.max"
            >
              This must be a number between 0 and 10.
            </div>
          </div>

          <div class="form-group">
            <label for="hdl_ratio">Cholesterol/HDL ratio</label>
            <p class="instruction">
              Please enter your latest Cholesterol/HDL ratio measure below.
            </p>
            <input
              type="number"
              name="hdl_ratio"
              class="form-control input-tracker"
              placeholder=""
              id="hdl_ratio"
              [ngModel]
              [min]="0"
              [max]="10"
              autocomplete="off"
              #hdl_ratio="ngModel"
            />
            
            <div
              class="alert alert-danger"
              *ngIf="hdl_ratio.errors?.min || hdl_ratio.errors?.max"
            >
              This must be a number between 0 and 10.
            </div>
          </div>

          <div class="form-group">
            <label for="entryDate">DATE</label>
            <p class="instructions">
              When did you do take this reading?
            </p>
            <input
              name="date"
              type="text"
              placeholder="DD/MM/YYYY"
              class="form-control input-tracker"
              bsDatepicker
              autocomplete="off"
              [bsConfig]="{
                dateInputFormat: 'DD/MM/YYYY',
                containerClass: 'theme-cv',
                showWeekNumbers: false
              }"
              [(ngModel)]="entryDate"
              required
              [bsValue]="entryDate"
              [maxDate]="maxDate"
              #date="ngModel"
            />
          </div>
          <div class="tracker-submit-container">
            <button

            [disabled]="!cholesterolForm.valid || cholesterolForm.pristine"
              type="submit"
              class="btn btn-primary btn-white"
            >
            <span class="icon-wrap"><fa-icon [icon]="['fas', 'check']"></fa-icon></span> CONFIRM
            </button>
          </div>
        </div>
      </form>
        </div>
      </div>
    </div>
  </div>
  
  <div class="clear"></div>
  </div>