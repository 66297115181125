import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { forkJoin } from 'rxjs';
import { BoosterSessionsService } from './../../booster-sessions/booster-sessions.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { AssessmentsService } from 'src/app/services/assessments.service';

@Component({
  selector: 'app-booster-session-index',
  templateUrl: './booster-session-index.component.html',
  styleUrls: ['./booster-session-index.component.scss'],
})
export class BoosterSessionIndexComponent implements OnInit {
  media;
  boosterSession;
  boosterSessionProgramme;
  boosterSessionSteps;
  boosterSessionId;
  sessionProgress;
  prevSession;
  nextSession;
  isFetching;
  currentUser;
  currentProgramme;
  environment = environment;

  constructor(
    private boosterSessionService: BoosterSessionsService,
    private route: ActivatedRoute,
    private boosterSessionsService: BoosterSessionsService,
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private router: Router,
    private titleService: Title,
    private assessmentService: AssessmentsService
  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
        if (this.currentUser) {
          if (this.currentUser.buddy) {
            console.log('isbuddy');
            this.router.navigate(['/dashboard']);
          }
        }
      });
    }

    //
    this.route.params.subscribe((params) => {
      this.prevSession = null;
      this.nextSession = null;
      this.boosterSessionId = this.route.snapshot.params.boosterSessionId;
      this.isFetching = true;

      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (this.currentProgramme) {
          forkJoin({
            boosterSession: this.boosterSessionsService.fetch(
              this.boosterSessionId
            ),
            boosterSessionSteps: this.boosterSessionsService.fetchSteps(
              this.boosterSessionId
            ),
            sessionProgress: this.boosterSessionsService.getSessionProgress(
              this.boosterSessionId
            ),
            programmeContent: this.programmeService.getProgrammeContent(
              this.currentProgramme.programme_id
            ),
          }).subscribe((responseData) => {
            this.isFetching = false;
            this.boosterSession = responseData.boosterSession;
            // do we need to take a pre test?
            this.checkAssessment(this.boosterSession);

            const programmeStartDate = user.created;
            let lastDate = '';
            let hasSession = false;
            responseData.programmeContent.booster_sessions.forEach(
              (item, index) => {
                if (item.booster_session_id == this.boosterSessionId) {
                  this.boosterSessionProgramme = item;
                  hasSession = true;
                }

                let status = 'unavailable';
                // is it open?
                let dPeriod;
                let dLength;

                switch (item.release_date) {
                  case 'M':
                    dPeriod = 'month';
                    dLength = 1;
                    break;
                  case 'W':
                    dPeriod = 'week';
                    dLength = 1;
                    break;
                  case 'W2':
                    dPeriod = 'week';
                    dLength = 2;
                    break;
                  case 'W3':
                    dPeriod = 'week';
                    dLength = 3;
                    break;
                  case 'W5':
                    dPeriod = 'week';
                    dLength = 5;
                    break;
                  case 'W6':
                    dPeriod = 'week';
                    dLength = 6;
                    break;
                  case 'W7':
                    dPeriod = 'week';
                    dLength = 7;
                    break;
                  case 'W8':
                    dPeriod = 'week';
                    dLength = 8;
                    break;
                }

                if (lastDate === '') {
                  lastDate = moment(programmeStartDate).format('YYYY-MM-DD');
                }

                if (
                  moment(lastDate).add(dLength, dPeriod).format('YYYY-MM-DD') <=
                  moment().format('YYYY-MM-DD')
                ) {
                  status = 'available';
                }
                lastDate = moment(lastDate)
                  .add(dLength, dPeriod)
                  .format('YYYY-MM-DD');

                if (item.booster_session_id == this.boosterSessionId) {
                  // can they access?
                  if (
                    status == 'unavailable' &&
                    +this.currentUser.is_admin == 0
                  ) {
                    console.log('unavailable');
                    this.router.navigate(['/dashboard']);
                  }
                }
              }
            );

            // can they access?
            if (
              this.boosterSession.status === 'unavailable' &&
              +this.currentUser.is_admin == 0
            ) {
              console.log('unavailable b');
              this.router.navigate(['/dashboard']);
            }

            this.boosterSessionSteps = responseData.boosterSessionSteps;
            this.sessionProgress = responseData.sessionProgress;

            if (this.sessionProgress) {
              if (this.sessionProgress.pages_viewed) {
                const pagesViewed = JSON.parse(
                  this.sessionProgress.pages_viewed
                );

                if (Array.isArray(pagesViewed)) {
                  // set the status..
                  this.boosterSessionSteps.forEach((item, index) => {
                    if (
                      pagesViewed.indexOf(item.booster_session_step_id) !== -1
                    ) {
                      this.boosterSessionSteps[index].progress = 'viewed';
                    }
                  });
                }
              }
            }
            // do prev and next
            const boosterSessions =
              responseData.programmeContent.booster_sessions.filter(
                (boosterSession) => boosterSession.release_date !== 'O'
              );
            boosterSessions.forEach((session, index) => {
              if (session.booster_session_id == this.boosterSessionId) {
                // setHtmlTitle($scope.programmeBoosterSession.booster_session_title );

                if (boosterSessions[index - 1]) {
                  this.prevSession = boosterSessions[index - 1];
                }
                if (boosterSessions[index + 1]) {
                  this.nextSession = boosterSessions[index + 1];
                }
              }
            });

            this.titleService.setTitle(
              environment.title +
                ' | Booster Sessions | ' +
                this.boosterSession.title
            );
          });
        }
      });
    });
  }

  checkAssessment(session) {
    if (session.assessment_id) {
      // ok, do we have any results?
      let assessmentIds = JSON.parse(session.assessment_id);
      if(!Array.isArray(assessmentIds)){
        assessmentIds = [assessmentIds];
      }
      let sentOff = false;
      assessmentIds.forEach((assessmentId) => {
        this.assessmentService
          .fetchResults(assessmentId, 'pre', this.boosterSessionId)
          .subscribe((resultsResponse) => {
            if (resultsResponse.length < 1 && sentOff == false) {
              sentOff = true;
              setTimeout(() => {
                // take it
                this.router.navigate([
                  '/assessments',
                  'take',
                  assessmentId,
                  'pre',
                  this.boosterSessionId,
                ]);
                return;
              }, 500);
            }
          });
      });
    }
  }
}
