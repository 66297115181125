import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ExerciseWorkoutsService {
  error = new Subject<string>();
  slug = '/user_workouts';
  resourceName = 'user_workouts';
  weeklyTarget = {
    threshold: 10,
    days: 5,
  };
  allWorkouts?: any;
  workoutsBetween?: any;
  allExercises?: any;
  constructor(private http: HttpClient) {}

  fetchAll(): any {
    if (!this.allWorkouts) {
      this.allWorkouts = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: any[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                // parse the exercise data
                if (item.exercises) {
                  item.exercises = JSON.parse(item.exercises);
                }
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allWorkouts;
  }

  fetchExercises(): any {
    if (!this.allExercises) {
      this.allExercises = this.http
        .get<any>(environment.apiUrl + '/exercises', {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            return responseData['_embedded']['exercises'];
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allExercises;
  }

  clearCache() {
    this.allWorkouts = null;
    this.workoutsBetween = null;
    this.allExercises = null;
  }

  
  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
        
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(
    workout_date: string,
    exercise_id: number,
    exercise_other: string,
    minutes: number,
    effort: number,
    comments: string
  ) {
    this.clearCache();
    const payload = {
      workout_date: moment(workout_date).format('YYYY-MM-DD'),
      exercise_id,
      exercise_other,
      minutes,
      effort,
      comments,
    };
    return this.http.post<any>(environment.apiUrl + this.slug, payload, {
      observe: 'response',
    });
  }

  update(
    id: number,
    workout_date: string,
    exercise_id: number,
    exercise_other: string,
    minutes: number,
    effort: number,
    comments: string
  ) {
    this.clearCache();
    const payload = {
      workout_date: moment(workout_date).format('YYYY-MM-DD'),
      exercise_id,
      exercise_other,
      minutes,
      effort,
      comments,
    };
    return this.http.patch<any>(
      environment.apiUrl + this.slug + '/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + '/' + id
    );
  }
}
