import { UserService } from './user.service';
import { environment } from './../../environments/environment';
import { AuthService } from './../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  userId;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userId = user.id;
      }
    });
  }



  checkConnection(){
    if (!this.userId) {
      return;
    }
    return this.http
      .get<any>(environment.applicationUrl + '/devices/check-connection', {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );

  }

  disconnect() {
    if (!this.userId) {
      return;
    }
    return this.http
      .delete<any>(environment.apiUrl + '/device_connection/' + this.userId, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
}

  setManual(){
    if (!this.userId) {
      return;
    }
    const postData =
    {
      manual:1
    }
    return this.http
      .post<any>(environment.apiUrl + '/device_connection', postData, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );


  }
}
