import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ProgrammeService } from 'src/app/services/programme.service';
import { UserMbtqService } from 'src/app/services/user-mbtq.service';
import { UserPaidService } from 'src/app/services/user-paid.service';
import { UserPhq9Service } from 'src/app/services/user-phq9.service';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-m3-wellbeing',
  templateUrl: './m3-wellbeing.component.html',
  styleUrls: ['./m3-wellbeing.component.scss']
})
export class M3WellbeingComponent implements OnInit {
  currentUser;
  phq9s: any = [];
  paids: any = [];
  mbtqs:any = [];
  showAllEntries = false;

  entryDatePhq9 = new Date();
  entryDatePaid = new Date();
  entryDateMbtq = new Date();


  @ViewChild('entryFormPhq9', { static: false }) entryFormPhq9: NgForm;
  @ViewChild('entryFormPaid', { static: false }) entryFormPaid: NgForm;
  @ViewChild('entryFormMbtq', { static: false }) entryFormMbtq: NgForm;

  constructor(
    private authService: AuthService,
    private programmeService: ProgrammeService,
    private router: Router,
    private userService: UserService,
    private userSettingsService: UserSettingsService,
    private modalService: BsModalService,
    public userPhq9Service:UserPhq9Service,
    public userPaidService:UserPaidService,
    public userMbtqService:UserMbtqService 

  ) // private bloodGlucoseService:BloodGlucoseService
  {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {});

      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });

      forkJoin({
        userSettings: this.userSettingsService.fetchAll(),
        phq9s: this.userPhq9Service.fetchAll(),
        paids: this.userPaidService.fetchAll(),
        mbtqs: this.userMbtqService.fetchAll()
      }).subscribe((responseData) => {

        this.phq9s = responseData.phq9s;
        this.paids = responseData.paids;
        this.mbtqs = responseData.mbtqs;

      });
    }
  }


  onDeletePhq9(phq9) {
    
    this.userPhq9Service
    .delete(phq9.user_phq9_id)
      .subscribe((responseData) => {
        //this.activityService.clearCache();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/wellbeing']));
      });
  }

  onSubmitPhq9Form() {
    // this.entryForm.value.date
    
    if (!this.entryFormPhq9.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something below",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.userPhq9Service
        .create(
          this.entryFormPhq9.value.phq9,
          moment(this.entryDatePhq9).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
           
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/wellbeing']));
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }



  onDeletePaid(paid) {
    
    this.userPaidService
    .delete(paid.user_paid_id)
      .subscribe((responseData) => {
        //this.activityService.clearCache();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/wellbeing']));
      });
  }

  onSubmitPaidForm() {
    // this.entryForm.value.date
    
    if (!this.entryFormPaid.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something below",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.userPaidService
        .create(
          this.entryFormPaid.value.paid,
          moment(this.entryDatePaid).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
           
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/wellbeing']));
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }



  onDeleteMbtq(mbtq) {
    
    this.userMbtqService
    .delete(mbtq.user_mbtq_id)
      .subscribe((responseData) => {
        //this.activityService.clearCache();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/wellbeing']));
      });
  }

  onSubmitMbtqForm() {
    // this.entryForm.value.date
    
    if (!this.entryFormMbtq.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something below",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.userMbtqService
        .create(
          this.entryFormMbtq.value.mbtq,
          moment(this.entryDateMbtq).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
           
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/wellbeing']));
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }
  
  
}
