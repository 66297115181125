<div class="row">
  <div class="col-6 col-md-2">
    <button
      type="button"
      class="btn-block btn btn-default"
      [ngClass]="{
        'btn-primary': notificationData.setting !== '0',
        'btn-default': notificationData.setting === '0' || notificationData.setting === null
      }"
      (click)="notificationData.setting = 'a'"
    >
      YES
    </button>
  </div>
  <div class="col-6 col-md-2">
    <button
      type="button"
      class="btn-block btn btn-default"
      [ngClass]="{
        'btn-primary': notificationData.setting == '0',
        'btn-default': notificationData.setting != '0' || notificationData.setting === null
      }"
      (click)="notificationData.setting = '0'"
    >
      NO
    </button>
  </div>
</div>
<div class="row" [hidden]="notificationData.setting == '0'">
  <div class="col mt-2">

    <p><strong>How often would you like to receive notifications?</strong></p>
    <div class="custom-control custom-radio">
      <input
        #notifications1="ngModel"
        [(ngModel)]="notificationData.setting"
        type="radio"
        id="{{notificationName}}notifications1a"
        name="{{notificationName}}notifications1"
        class="custom-control-input"
        value="a"
      />
      <label class="custom-control-label" for="{{notificationName}}notifications1a"
        >One notification a day</label
      >
    </div>
    <div class="custom-control custom-radio">
      <input
        #notifications1="ngModel"
        [(ngModel)]="notificationData.setting"
        type="radio"
        id="{{notificationName}}notifications1b"
        name="{{notificationName}}notifications1"
        class="custom-control-input"
        value="b"
      />
      <label class="custom-control-label" for="{{notificationName}}notifications1b">
        One notification per week</label
      >
    </div>
    <div class="custom-control custom-radio">
      <input
        #notifications1="ngModel"
        [(ngModel)]="notificationData.setting"
        type="radio"
        id="{{notificationName}}notifications1c"
        name="{{notificationName}}notifications1"
        class="custom-control-input"
        value="c"
      />
      <label class="custom-control-label" for="{{notificationName}}notifications1c">
        Customise</label
      >
    </div>
    <div
      class="alert alert-dark"
      [hidden]="notificationData.setting !== 'c'"
      *ngIf="notificationData"
    >
      <div class="row">
        <div class="col-6">
          <div>Days</div>
          <div class="custom-control custom-checkbox" *ngFor="let item of days">
            <input
              type="checkbox"
              [(ngModel)]="item.isChecked"
              (change)="changeSelection()"
              class="custom-control-input"
              id="{{notificationName}}day{{item.id}}"
            />
            <label class="custom-control-label" for="{{notificationName}}day{{item.id}}">
              {{ item.label }}</label
            >
          </div>
        </div>
        <div class="col-6">
          <div>Time of Day</div>

          <div class="custom-control custom-radio">
            <input
              type="radio"
              id="{{notificationName}}timeMorning"
              name="{{notificationName}}time"
              class="custom-control-input"
              value="morning"
              [(ngModel)]="notificationData.time"
            />
            <label class="custom-control-label" for="{{notificationName}}timeMorning">
              Morning</label
            >
          </div>

          <div class="custom-control custom-radio">
            <input
              type="radio"
              id="{{notificationName}}timeAfternoon"
              name="{{notificationName}}time"
              class="custom-control-input"
              value="afternoon"
              [(ngModel)]="notificationData.time"
            />
            <label class="custom-control-label" for="{{notificationName}}timeAfternoon">
              Afternoon</label
            >
          </div>

          <div class="custom-control custom-radio">
            <input
              type="radio"
              id="{{notificationName}}timeEvening"
              name="{{notificationName}}time"
              class="custom-control-input"
              value="evening"
              [(ngModel)]="notificationData.time"
            />
            <label class="custom-control-label" for="{{notificationName}}timeEvening">
              Evening</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
