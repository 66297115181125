import { NgForm } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { HealthyEatingService } from './../../services/healthy-eating.service';
import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { environment } from './../../../environments/environment';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-healthy-eating',
  templateUrl: './healthy-eating.component.html',
  styleUrls: ['./healthy-eating.component.scss']
})
export class HealthyEatingComponent implements OnInit {
  currentUser;
  currentProgramme;
  healthyEatings = [];
  healthyEating;
  isFetching = false;
  errors = [];
  showAllRows = false;
  environment = environment;

  toggleRow = {
    update: false,
    graph: false,
    entry: false,
  };

  toggleChart = {
    healthyEating: true,
    veg: false,
    fruit: false
  };
  entryDate = new Date();
  maxDate = new Date();
  @ViewChild('trackerForm', { static: false }) trackerForm: NgForm;

  // Line chart
  public chartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            beginAtZero: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          gridLines: {
            display: true,
            lineWidth: 1,
            color: '#95989A',
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            lineWidth: 2,
            color: '#95989A',
          },
          type: 'time',
          ticks: {
            autoSkip: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          time: {
            parser: 'YYYY-MM-DD HH:mm:ss',
            unit: 'day',
            displayFormats: {
              day: 'MMM-DD',
            },
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public chartLabels: Label[] = [];
  public chartType: ChartType = 'line';
  public chartLegend = true;

  public chartColors: Color[] = [{ backgroundColor: environment.primaryColor }];


  public healthyEatingChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'Healthy Eating',
      borderWidth: 1,
      borderColor: environment.chartDefaults.colors[0],
      fill: false,
      pointRadius: 2,
    },
  ];
  public vegChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'Veg',
      borderWidth: 1,
      borderColor: environment.chartDefaults.colors[0],
      fill: false,
      pointRadius: 2,
    },
  ];
  public fruitChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'Fruit',
      borderWidth: 1,
      borderColor: environment.chartDefaults.colors[0],
      fill: false,
      pointRadius: 2,
    },
  ];
  //
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private healthyEatingService: HealthyEatingService
  ) {}

  ngOnInit(): void {

    this.isFetching = true;
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (this.currentProgramme) {
          // this.barChartColors[0].backgroundColor = this.currentProgramme.config.primaryColor;

          forkJoin({
            healthyEatings: this.healthyEatingService.fetchAll(),
          }).subscribe(
            (responseData) => {
              this.isFetching = false;
              this.healthyEatings = responseData.healthyEatings;
              if (this.healthyEatings.length > 0) {
                this.healthyEating = this.healthyEatings[0];
              }
              this.setChartData(this.healthyEatings);
              // end
            },
            (error) => {
              this.isFetching = false;
              this.errors.push(error.message);
            }
          );
        }
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });
    }
  }

  onRemove(healthyEating) {
    this.healthyEatingService.delete(healthyEating.user_healthy_eating_id).subscribe(
      (responseData) => {
        const index = this.healthyEatings.indexOf(healthyEating);
        this.healthyEatings.splice(index, 1);
        this.setChartData(this.healthyEatings);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onSubmit() {
    // this.trackerForm.value.date

    if (!this.trackerForm.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.healthyEatingService
        .create(this.trackerForm.value.healthyEating, this.trackerForm.value.veg, this.trackerForm.value.fruit,
          moment(this.entryDate).format('YYYY-MM-DD'))
        .subscribe(
          (responseData) => {
            this.healthyEatings.unshift(responseData);
            this.healthyEatings.sort(this.sortByCreated);
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.trackerForm.resetForm();
            this.entryDate = new Date();
            this.setChartData(this.healthyEatings);
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  // end chart
  updatedToday() {
    let hasUpdated = false;
    this.healthyEatings.forEach((healthyEating) => {
      if (
        moment(healthyEating.created).format('MM DD YY') ===
        moment().format('MM DD YY')
      ) {
        hasUpdated = true;
      }
    });
    return hasUpdated;
  }

  setChartData(trackerData) {
    this.healthyEatingChartData[0].data = [];
    this.fruitChartData[0].data = [];
    this.vegChartData[0].data = [];
    this.chartLabels = [];
    // setup chart data
    trackerData.forEach((value, key) => {
      this.chartLabels.push(moment(value.created).format('YYYY-MM-DD'));
      this.healthyEatingChartData[0].data.push(value.healthy_eating);
      this.fruitChartData[0].data.push(value.five_a_day_fruit);
      this.vegChartData[0].data.push(value.five_a_day);
    });
  }


  sortByCreated(a, b) {
    if (a.created > b.created) {
      return -1;
    }
    if (a.created < b.created) {
      return 1;
    }
    return 0;
  }
}
