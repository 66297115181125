<div *ngIf="isFetching">
  <app-loading-spinner [inline]="1"></app-loading-spinner>
</div>
<div class="container m3 m3-dashboard" *ngIf="currentUser">
  <app-m3-mobile-navbar
    [pageTitle]="'home'"
    class="d-block d-sm-none"
  ></app-m3-mobile-navbar>

  <app-m3-header></app-m3-header>

  <div *ngIf="currentUser" class="m3-inner-container">
   

    <div>
      <div class="row dashboard-row" *ngIf="deviceSynced== false && !isFetching">
        <div class="col-md-12">
          <div class="mt-2 card pt-3">
            <h3 class="text-center">
              <span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'exclamation-circle']"></fa-icon
              ></span>
              PLEASE SETUP YOUR FITNESS TRACKER
            </h3>
            <div class="p-3">
              <p class="text-left">
                Setting up a device will ensure that the programme tailors'
                physical activity specifically suited to you and your ability.
              </p>
              <p class="text-left">
                We are aiming to progress you to achieve the minimum recommended
                steps for health. As little as 10 minutes of brisk
                walking every day
                can increase your life expectancy by ~4 years and as little as
                500 steps per day can decreased your risk of cardiovascular
                disease and even death
              </p>
              <p class="text-left">
                If you do not have a device, there is also an option to enter
                your steps manually
              </p>
            </div>

            <div class="row mb-3">
              <div class="col text-right">
                <a
                  role="button"
                  [routerLink]="['/setup-device']"
                  class="btn btn-white mr-1"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'watch-fitness']"></fa-icon
                  ></span>
                  SETUP DEVICE</a
                >
              </div>
              <div class="col text-left">
                <a
                  role="button"
                  [routerLink]="['/setup-device']"
                  class="btn btn-white ml-1"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'hand-pointer']"></fa-icon
                  ></span>
                  MANUAL</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!algorithmComplete && deviceSynced === true">
      <!---->
      <div class="container container-pad">
        <div class="row dashboard-row">
          <div class="col-md-12">
            <div class="card">
              <div class="p-3" *ngIf="activitySetupStep == 1">
                <div *ngIf="currentUser.manual_steps != 1">
                  <p class="text-left">Get Ready, Get Set, GO!</p>
                  <p class="text-left">
                    Once you have worn your device for at least 3 full days and
                    provided us with the data from the questions below we will
                    be able to generate your report.
                  </p>
                  <p class="text-left">
                    Three days will ensure your baseline activity level is
                    accurate
                  </p>
                </div>

                <div *ngIf="currentUser.manual_steps == 1">
                  <p class="text-left">
                    Once you have entered the total number of steps you perform
                    every day for the next 3 days and provided us with the data
                    from the questions below we will be able to generate your
                    report.
                  </p>
                  <p class="text-left">
                    Three days will ensure your starting activity data is
                    accurate
                  </p>
                </div>

                <div class="text-center">
                  <button
                    class="btn btn-white"
                    (click)="onConfirmReadActivitySetup()"
                  >
                    Continue
                  </button>
                </div>
              </div>

              <div *ngIf="activitySetupStep == 2" class="text-center">
                <div class="headline-flex">
                  <div class="headline-icon">
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['far', 'hourglass-half']"></fa-icon
                    ></span>
                  </div>
                  <div class="headline-text">
                    <strong>{{ algorithmDaysRemaining }}</strong>
                    <span class="headline-subtext"
                      >day<span *ngIf="algorithmDaysRemaining != 1">s</span
                      ><br />
                      Remaining</span
                    >
                  </div>
                </div>

                <div class="days days-activity">
                  <div *ngFor="let day of algorithmDays" class="day">
                    <span
                      class="icon-wrap fa fa-check-circle"
                      *ngIf="day.status"
                      ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
                    ></span>
                    <span
                      class="icon-wrap fa fa-circle"
                      *ngIf="!day.status"
                      [ngClass]="{ 'not-hit': day.status === 0 }"
                      ><fa-icon
                        [icon]="['fas', 'circle']"
                        style="color: #333"
                      ></fa-icon></span
                    ><br />
                    {{ day.day | dateFormatter : "ddd" | uppercase }}
                  </div>
                </div>

                <div *ngIf="algorithmDaysRemaining > 0">
                  <p>
                    3 days of activity is required before the dashboard is
                    optimised
                  </p>

                  
                </div>
              </div>
              <div *ngIf="algorithmDaysRemaining == 0" class="p-3 text-center">
                <a (click)="onGenerateReport()" class="btn btn-white">
                  Generate Targets
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="row mt-3 mb-3">
        <div class="col-md-6">
          <div class="head-block">
            <h3>
              <a class="more-link" [routerLink]="['/m3', 'walking']"
                ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
              ></a>
              <app-m3-icon [icon]="'walking'"></app-m3-icon> Daily Stepping <button class="btn btn-link m3-video-link" aria-label="Show stepping video" (click)="this.activeVideo='stepping';videoModal.show();"><fa-icon [icon]="['far', 'video']"></fa-icon
                ></button>
            </h3>
          </div>

          <div class="card">
            <div class="card-body">
              <div
                class="info-stat info-stat-rag mb-2"
                *ngIf="stepAverage != null"
              >
                <div class="info-stat-rag-current">
                  <app-m3-icon [icon]="'walking-alt'"></app-m3-icon>
                  <span class="info-stat-label">My average daily steps in the last 7 days</span>
                  <span
                    class="info-stat-stat text-rag-{{
                      getStepsRag(stepAverage)
                    }}"
                    >{{ stepAverage | number }}</span
                  >
                </div>
                <div class="rag-details">
                  <div class="rag-details-target d-flex justify-content-start" *ngIf="stepTarget">
                    Optimal population level:
                    <ng-template #popTemplateStepsTarget>
                      <div>
                        The optimal level of &gt;7,500 steps may differ to the current weekly target. Feedback should be based on meeting and progressing the current target where this is available.
                      </div></ng-template
                    >
                    &nbsp;<span class="text-rag-green" style="min-width:106px;">&gt;7,500-10,000&nbsp;<span
                      class="rag-circle pointer text-dark"
                      style="display:inline-block;"
                      [popover]="popTemplateStepsTarget"
                    ><span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'info']"></fa-icon
                      ></span>
                    </span></span> &nbsp;
                  </div>
                  <!--div class="rag-details-target" *ngIf="+stepAverage>=7500">
                    &nbsp;
                  </div-->
                  <div class="rag-details-history">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateSteps>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            >7,500
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            5,000 - 7,499
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            &lt; 5000
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                    *ngFor="let step of weeklyStepAverages.slice().reverse(); let index = index"
                  >
                    <div
                      *ngIf="index > weeklyStepAverages.length-6"
                      [tooltip]="(step.steps | number) + ' steps, W/C ' + step.activity_date "
                      class="rag-circle bg-rag-{{ getStepsRag(step.steps) }}"
                    ></div>
                  </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateSteps"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="rag-details" *ngIf="stepTarget">
                  <div class="rag-details-target d-flex" >
                    My daily steps target:
                    
                    &nbsp;<span class="text-rag-green">{{stepTarget.target | number}}</span>
                  </div>
                  <div class="rag-details-history">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateStepTarget>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            Target achieved
                          </div>
                          
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            Target not achieved
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                    *ngFor="let step of weeklyStepAverages.slice().reverse(); let index = index"
                  >
                    <div
                      *ngIf="index > weeklyStepAverages.length-6"
                      [tooltip]="(step.steps | number) + ' steps, W/C ' + step.activity_date "
                      class="rag-circle"
                      [ngClass]="{'bg-rag-green': +step.steps >= +stepTarget.target, 'bg-rag-red': +step.steps < +stepTarget.target }"
                    ></div>
                  </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateStepTarget"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--div class="info-stat info-stat-target" *ngIf="stepTarget">
                <app-m3-icon [icon]="'target'" class="text-rag-green"></app-m3-icon>
                <span class="info-stat-label">My daily step target </span>
                <span class="info-stat-stat text-rag-green">{{
                  stepTarget.target | number
                }}</span>
              </div-->

              <app-m3-walking-chart
                *ngIf="allTimeSteps"
                [allTimeSteps]="allTimeSteps"
              ></app-m3-walking-chart>

              <div class="info-stat" *ngIf="currentUser.cadence">
                <app-m3-icon
                  [icon]="'walking-alt'"
                  
                ></app-m3-icon>
                <span class="info-stat-label"
                  >During 1 minute of brisk walking your step count was
                </span>
                <span class="info-stat-stat" [ngClass]="{'text-rag-red':currentUser.cadence<100, 'text-rag-green':currentUser.cadence>=100 }">{{
                  currentUser.cadence
                }}</span>
              </div>
              
            </div>
          </div>
        </div>
        
        <div class="col-md-6">
          <div class="head-block">
            <h3>
              <a class="more-link" [routerLink]="['/', 'm3', 'fitness']"
                ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
              ></a>
              <app-m3-icon [icon]="'fitness'"></app-m3-icon>
              Fitness <button class="btn btn-link m3-video-link" aria-label="Show fitness video" (click)="this.activeVideo='fitness';videoModal.show();"><fa-icon [icon]="['far', 'video']"></fa-icon
                ></button>
            </h3>
          </div>
          <div class="card">
            <div class="card-body">
              <!--div class="info-stat mb-2">
                  <span class="text-pink"
                    ><app-m3-icon [icon]="'heart-rate'"></app-m3-icon
                  ></span>
                  <span class="info-stat-label">My resting heart rate</span>
                  <span class="info-stat-stat text-pink" *ngIf="heartRates">
                    <span *ngIf="heartRates[0]">{{
                      heartRates[0].heart_rate
                    }}</span
                    ><span *ngIf="!heartRates[0]">-</span></span
                  >
                </div-->
              <div class="info-stat info-stat-rag mb-2">
                <div class="info-stat-rag-current">
                  <app-m3-icon
                    [icon]="'vo2max'"
                    class="text-dark"
                  ></app-m3-icon>
                  <span class="info-stat-label"
                    >My estimated VO<sub>2</sub>max</span
                  >
                  <span
                    *ngIf="vo2maxs && vo2maxs[0]"
                    class="info-stat-stat text-rag-{{
                      getRagForAgeAndSex(vo2maxs[0].vo2max, age, sex)
                    }}"
                  >
                    <span *ngIf="vo2maxs && vo2maxs[0]">{{ vo2maxs[0].vo2max }}</span
                    ><span *ngIf="!vo2maxs">?</span></span
                  >
                  <span *ngIf="!vo2maxs" class="info-stat-stat">-</span>
                  <span class="info-stat-label">ml/kg/min</span>
                </div>

                <div class="rag-details" *ngIf="vo2maxs && vo2maxs[0]">
                  <div class="rag-details-target" *ngIf="vo2maxs[0]">
                    <span *ngIf="getGoodforAgeAndSex(age, sex) > vo2maxs[0].vo2max ">
                    Target:
                    <span class="text-rag-green">{{
                      getGoodforAgeAndSex(age, sex)
                    }}</span>
                    <span class="ml-1">ml/kg/min</span></span>
                  </div>
                  <div class="rag-details-history" *ngIf="vo2maxs.length>0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateVo2Max>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{
                              getVo2maxforColorAgeAndSex("green", age, sex)
                            }}
                            ml/kg/min
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            {{
                              getVo2maxforColorAgeAndSex("amber", age, sex)
                            }}
                            ml/kg/min
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{
                              getVo2maxforColorAgeAndSex("red", age, sex)
                            }}
                            ml/kg/min
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="let vo2max of vo2maxs.slice().reverse(); let index = index"
                      >
                        <div
                          *ngIf="index > vo2maxs.length -6"
                          [tooltip]="
                            vo2max.vo2max +
                            ' ml/kg/min' +
                            ' on ' +
                            (vo2max.date_recorded
                              | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            getRagForAgeAndSex(vo2max.vo2max, age, sex)
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateVo2Max"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--div class="info-stat info-stat-target">
                  <app-m3-icon
                    [icon]="'target'"
                    class="text-dark"
                  ></app-m3-icon>
                  <span class="info-stat-label"
                    >My VO<sub>2</sub>max Target</span
                  >
                  <span class="info-stat-stat text-dark" *ngIf="vo2maxs">
                    <span *ngIf="vo2maxs[0]">{{getGoodforAgeAndSex(age, sex)}}</span
                    ><span *ngIf="!vo2maxs[0]">-</span></span
                  >
                </div-->

              <app-m3-fitness-chart
                *ngIf="vo2maxs && vo2maxs.length > 1"
                [heartRates]="heartRates"
                [vo2maxs]="vo2maxs"
                [age]="age"
                [sex]="sex"
              ></app-m3-fitness-chart>
            </div>
          </div>
        </div>
        
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="head-block">
            <h3>
              <a class="more-link" [routerLink]="['/m3', 'non-stepping']"
                ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
              ></a>
              <app-m3-icon [icon]="'non-stepping'"></app-m3-icon>
              Non-stepping activity <button class="btn btn-link m3-video-link" aria-label="Show non stepping video" (click)="this.activeVideo='non-stepping';videoModal.show();"><fa-icon [icon]="['far', 'video']"></fa-icon
                ></button>
            </h3>
          </div>

          <div class="card">
            <div class="card-body">
              <div
                class="info-stat info-stat-rag mb-2"
              >
                <div class="info-stat-rag-current">
                  <app-m3-icon [icon]="'non-stepping'"></app-m3-icon>
                  <span class="info-stat-label"
                    >My total moderate to vigorous physical activity (MVPA), including brisk steps</span
                  >
                  <span
                    class="info-stat-stat"
                    [ngClass]="{
                      'text-rag-green': +nonSteppingWeeklyTotal+briskMinsThisWeek >= 150,
                      'text-rag-amber': +nonSteppingWeeklyTotal+briskMinsThisWeek >= 100 &&  +nonSteppingWeeklyTotal+briskMinsThisWeek<150,
                      'text-rag-red': +nonSteppingWeeklyTotal+briskMinsThisWeek < 100
                    }"
                    >{{ nonSteppingWeeklyTotal+briskMinsThisWeek | number : "1.0-0" }}</span
                  >
                  <span class="info-stat-label">min/week</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green">150 min/week</span>
                  </div>
                  <div class="rag-details-history" *ngIf="activitiesByWeekTotals.length>0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateNonStepping>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            >= 150 mins/week
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            &lt; 150 mins/week
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let week of activitiesByWeekTotals.slice();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > activitiesByWeekTotals.length - 6"
                          [tooltip]="
                            week.minutes + ' mins/week, ' + week.date_recorded
                          "
                          class="rag-circle"
                          [ngClass]="{ 'bg-rag-green': +week.minutes >= 150, 'bg-rag-red': +week.minutes<150 }"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateNonStepping"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--app-m3-non-stepping-week-chart
                *ngIf="nonSteppingsWeek && nonSteppings.length > 0"
                [nonSteppings]="nonSteppingsWeek"
              ></app-m3-non-stepping-week-chart-->
              <app-m3-non-stepping-chart
                *ngIf="activitiesByWeek && activitiesDone && activitiesDone.length>0"
                [nonSteppingsByDate]="activitiesByWeek"
                [activtitiesDone]="activitiesDone"
              ></app-m3-non-stepping-chart>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="head-block">
            <h3>
              <a class="more-link" [routerLink]="['/m3', 'physical-ability']"
                ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
              ></a>
              <app-m3-icon [icon]="'physical-ability'"></app-m3-icon>
              Physical function &amp; strength  <button class="btn btn-link m3-video-link" aria-label="Show strength video" (click)="this.activeVideo='strength';videoModal.show();"><fa-icon [icon]="['far', 'video']"></fa-icon
                ></button>
            </h3>
          </div>

          <div class="card">
            <div class="card-body">
              <div class="info-stat info-stat-rag mb-2">
                <div class="info-stat-rag-current">
                  <app-m3-icon [icon]="'sts'" class="text-dark"></app-m3-icon>
                  <span class="info-stat-label">My sit-to-stand 60 score</span>
                  <span
                    *ngIf="sts60s && sts60s[0]"
                    class="info-stat-stat text-rag-{{
                      getSts60RagForAgeAndSex(sts60s[0].sts, age, sex)
                    }}"
                    ><span *ngIf="sts60s && sts60s"
                      ><span *ngIf="!sts60s">-</span
                      ><span *ngIf="sts60s[0]">{{ sts60s[0].sts }}</span></span
                    ></span
                  >
                  <span class="info-stat-stat" *ngIf="!sts60s">-</span>

                  <span class="info-stat-label">reps</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green">{{
                      sts60Service.getGoodSts(this.age, this.currentUser.gender)
                    }}</span>
                  </div>
                  <div class="rag-details-history" *ngIf="sts60s && sts60s.length>0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateSts60>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{
                              getSts60forColorAgeAndSex("green", age, sex)
                            }}
                            reps
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            {{
                              getSts60forColorAgeAndSex("amber", age, sex)
                            }}
                            reps
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{
                              getSts60forColorAgeAndSex("red", age, sex)
                            }}
                            reps
                          </div>
                        </div>
                      </ng-template>
                      <ng-container
                        *ngFor="let sts60 of sts60s.slice().reverse(); let index = index"
                      >
                        <div
                          *ngIf="index > sts60s.length - 6"
                          [tooltip]="
                            sts60.sts +
                            ' on ' +
                            (sts60.date_recorded
                              | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            getSts60RagForAgeAndSex(sts60.sts, age, sex)
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateSts60"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--div class="info-stat info-stat-target">
                <app-m3-icon [icon]="'target'" class="text-rag-green"></app-m3-icon>
                <span class="info-stat-label">Average STS60 for my age </span>
                <span class="info-stat-stat text-rag-green">{{
                  sts60Service.getAverageSts(this.age, this.currentUser.gender)
                }}</span>
              </div-->

              <app-m3-physical-ability-chart
                *ngIf="sts60s"
                [sts60s]="sts60s"
                [age]="age"
                [sex]="currentUser.gender"
              ></app-m3-physical-ability-chart>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="head-block">
            <h3>
              <a class="more-link" [routerLink]="['/m3', 'sitting']"
                ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
              ></a>
              <app-m3-icon [icon]="'sitting'"></app-m3-icon>
              Sitting <button class="btn btn-link m3-video-link" aria-label="Show sitting video" (click)="this.activeVideo='sitting';videoModal.show();"><fa-icon [icon]="['far', 'video']"></fa-icon
                ></button>
            </h3>
          </div>

          <div class="card">
            <div class="card-body">
              <div class="info-stat info-stat-rag mb-2">
                <div class="info-stat-rag-current">
                  <app-m3-icon [icon]="'sitting-alt'"></app-m3-icon>
                  <span class="info-stat-label" *ngIf="allSittings && sittingWeekStart"
                    >My average daily sitting time over the w/c {{sittingWeekStart}}</span
                  >
                  <span
                    *ngIf="sittingAverage"
                    class="info-stat-stat text-rag-{{
                      getSittingRag(sittingAverage, currentUser.device)
                    }}"
                    >{{ sittingAverage | minsToHours }}</span
                  >
                  <span
                  *ngIf="!sittingAverage"
                  class="info-stat-stat">-</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target" *ngIf="sittingTarget">
                    Target:
                    <span class="text-rag-green">{{ sittingTarget | number }}</span>
                    hours
                  </div>
                  <div class="rag-details-history" *ngIf="latestSittings.length>0">
                    Daily History:
                    <div class="rag-history">
                      <ng-template #popTemplateSitting>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            <span *ngIf="currentUser.device == 'fitbit'"
                              >&lt;9 hours/day</span
                            >
                            <span *ngIf="currentUser.device != 'fitbit'"
                              >&lt;6 hours/day</span
                            >
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>

                            <span *ngIf="currentUser.device == 'fitbit'"
                              >9-10 hours/day</span
                            >
                            <span *ngIf="currentUser.device != 'fitbit'"
                              >6-8 hours/day</span
                            >
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>

                            <span *ngIf="currentUser.device == 'fitbit'"
                              >≥10 hours/day</span
                            >
                            <span *ngIf="currentUser.device != 'fitbit'"
                              >≥8 hours/day</span
                            >
                          </div>
                        </div>
                      </ng-template>
                      <ng-container
                        *ngFor="
                          let activity of latestSittings.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > latestSittings.length - 6"
                          [tooltip]="
                            (activity.sedentary_minutes | minsToHours) +
                            ' on ' +
                            (activity.activity_date
                              | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            getSittingRag(
                              activity.sedentary_minutes,
                              currentUser.device
                            )
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateSitting"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--div class="info-stat info-stat-target">
                <app-m3-icon [icon]="'target'" class="text-rag-green"></app-m3-icon>
                <span class="info-stat-label"
                  >My daily sitting time target</span
                >
                <span class="info-stat-stat text-rag-green">&lt;10</span>
                <span class="info-stat-label">hours</span>
              </div-->

              <app-m3-sitting-chart
                *ngIf="allSittings && allSittings.length>0"
                [allSittings]="allSittings"
              ></app-m3-sitting-chart>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="head-block">
            <h3>
              <a class="more-link" [routerLink]="['/m3', 'sleep']"
                ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
              ></a>
              <app-m3-icon [icon]="'sleep'"></app-m3-icon> Sleep <button class="btn btn-link m3-video-link" aria-label="Show sleep video" (click)="this.activeVideo='sleep';videoModal.show();"><fa-icon [icon]="['far', 'video']"></fa-icon
                ></button>
            </h3>
          </div>

          <div class="card">
            <div class="card-body">
              <!--div class="info-stat mb-2">
                <app-m3-icon [icon]="'zzz'" class="text-pink"></app-m3-icon>
                <span class="info-stat-label">My average daily sleep time</span>
                <span class="info-stat-stat text-pink">{{ sleepHours }}</span>
                <span class="info-stat-label">hours</span>
                <span class="info-stat-stat text-pink">{{ sleepMins }}</span>
                <span class="info-stat-label">mins</span>
              </div-->

              <div class="info-stat info-stat-rag mb-2">
                <div class="info-stat-rag-current">
                  <app-m3-icon [icon]="'zzz'"></app-m3-icon>
                  <span class="info-stat-label" *ngIf="sleepWeekStart"
                    >My average daily sleep time over the w/c {{sleepWeekStart}}</span
                  >
                  <span
                  *ngIf="sleepAverage"
                    class="info-stat-stat text-rag-{{
                      getSleepRagForAge(sleepAverage, age)
                    }}"
                    >{{ sleepAverage | minsToHours }}</span
                  >
                  <span class="info-stat-stat" *ngIf="!sleepAverage">-</span>
                  <span class="info-stat-label"></span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green">{{ getAgeSleep(age) }}</span>
                    hours/day
                  </div>
                  <div class="rag-details-history" *ngIf="allSleeps && allSleeps.length>0">
                    Daily History:
                    <div class="rag-history">
                      <ng-template #popTemplateSleep>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            <span *ngIf="age < 26"> 7-9 hours/day </span>
                            <span *ngIf="age > 25 && age < 65">
                              7-9 hours/day
                            </span>
                            <span *ngIf="age > 64"> 7-8 hours/day </span>
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
    
                            <span *ngIf="age < 26"> 6 or 10-11 hours/day </span>
                            <span *ngIf="age > 25 && age < 65">
                              6 or 10 hours/day
                            </span>
                            <span *ngIf="age > 64">  5-6 or 9 hours/day </span>
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
    
                            <span *ngIf="age < 26">
                              &lt;6 or ≥11 hours/day
                            </span>
                            <span *ngIf="age > 25 && age < 65">
                              &lt;6 or ≥10 hours/day
                            </span>
                            <span *ngIf="age > 64">
                              &lt;5 or ≥9 hours/day
                            </span>
                          </div>
                        </div>
                        </ng-template
                      >
                      <ng-container
                        *ngFor="let sleep of allSleeps.slice().reverse();
                        let index = index
                      "
                    >
                      <div
                        *ngIf="index > allSleeps.length - 6"
                          [tooltip]="
                            (sleep.sleep | minsToHours) +
                            ' on ' +
                            (sleep.date_recorded
                              | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            getSleepRagForAge(sleep.sleep, age)
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateSleep"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <app-m3-sleep-chart
                *ngIf="allSleeps && allSleeps.length>0"
                [allSleeps]="allSleeps"
                [age]="age"
              ></app-m3-sleep-chart>

              <div
                class="info-stat mb-2"
                *ngIf="sleepScore && sleepScore.chronotype"
              >
                <app-m3-icon [icon]="'clock-z'" class=""></app-m3-icon>
                <span class="info-stat-label">My chronotype is</span>
                <span class="info-stat-stat">&nbsp;</span
                ><span class="info-stat-label"[ngClass]="{'text-rag-green': sleepScore.chronotype=='definite morning' || sleepScore.chronotype =='moderate morning' || sleepScore.chronotype =='intermediate', 'text-rag-red': sleepScore.chronotype=='definite evening' || sleepScore.chronotype=='moderate evening'}"
                >
                  <ng-container *ngIf="!sleepScore.chronotype">
                    <a class="add-item-btn" [routerLink]="['/m3', 'sleep']"
                      ><app-m3-icon
                        [icon]="'more'"
                        [style]="'thin'"
                      ></app-m3-icon
                    ></a>
                  </ng-container>
                  <ng-container *ngIf="sleepScore.chronotype">
                    {{ sleepScore.chronotype | titlecase }}
                  </ng-container>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- video modal -->

<div
class="modal fade"
bsModal
#videoModal="bs-modal"
tabindex="-1"
role="dialog"
aria-labelledby="dialog-static-name"
(onHidden)="activeVideo=null"
>
<div class="modal-dialog" style="max-width:560px !important;">
  <div class="modal-content" style="padding:0;border:none;border-radius:0;background:none;">
    <button
        type="button"
        class="btn-close close-m3-video"
        aria-label="Close"
        (click)="videoModal.hide();activeVideo = null;"
      >
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    <div class="modal-body" style="padding:15px 15px 10px 15px;background-color:#fff;">
      <div class="m3-video" id="m3-video-stepping" *ngIf="activeVideo == 'stepping'">
        <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/WmduT8qcIaM?si=iuAYekjNJ1UNMEcB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <div class="m3-video" id="m3-video-fitness" *ngIf="activeVideo == 'fitness'">
      <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/9NQsqxNnzGM?si=F6rVcJU5KjBEN6wt" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <div class="m3-video" id="m3-video-sitting" *ngIf="activeVideo == 'sitting'">
      <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/d6VcMMxyjmc?si=HZWI5AX_1km_SQP-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <div class="m3-video" id="m3-video-non-stepping" *ngIf="activeVideo == 'non-stepping'">
      <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/dmEBfYZOk3c?si=rhex79PnyBru-vpG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <div class="m3-video" id="m3-video-strength" *ngIf="activeVideo == 'strength'">
      <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/k8MhNjEiOeM?si=bscI9DwHXMcCNaDO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <div class="m3-video" id="m3-video-sleep" *ngIf="activeVideo == 'sleep'">
      <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/a7cP49bNyaA?si=1DqJongGOubN9W4u" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>
</div>
<!-- end video modal -->