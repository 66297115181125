<nav class="subnav">
    <h2>Setup Programme</h2>
  </nav>
  
  <app-loading-spinner *ngIf="isFetching"></app-loading-spinner>
  <form
    class="bs-form reg-form"
    name="regForm"
    method="post"
    (ngSubmit)="onSubmit()"
    #regForm="ngForm"
  >
    <!-- step 1 -->
  
    <div *ngIf="!regProgrammeConfig">
  
  
      <!-- step 4 -->
      <div [hidden]="step !== 4">
        
        <div
          class="reading-material-page-header booster-session-step-header register-header text-left"
        >
          1.{{subStep}} | Mobility Status
        </div>
        <div class="container">
          <div class="row">
            <div class="col">
  
              <div [hidden]="subStep!=1">
              <div class="form-group">
                <label for="mobility1" class="uppercase">
                  Which one of the options below best describes your activity in
                  the last week (Single answer)
                  <span class="required">*</span></label
                >
                <div class="row">
                  <div class="col-12">
                    <div class="radio-group-block">
                      <div class="custom-control custom-radio">
                        <input
                          #mobility1="ngModel"
  required
                          [(ngModel)]="regDetails.mobility1"
                          type="radio"
                          id="mobility1a"
                          name="mobility1"
                          class="custom-control-input"
                          value="a"
                        />
                        <label class="custom-control-label" for="mobility1a"
                          >I have no problems in walking about or doing my usual
                          activities</label
                        >
                      </div>
  
                      <div class="custom-control custom-radio">
                        <input
                          #mobility1="ngModel"
  required
                          [(ngModel)]="regDetails.mobility1"
                          type="radio"
                          id="mobility1b"
                          name="mobility1"
                          class="custom-control-input"
                          value="b"
                        />
                        <label class="custom-control-label" for="mobility1b"
                          >I have slight problems in walking about or doing my
                          usual activities</label
                        >
                      </div>
  
                      <div class="custom-control custom-radio">
                        <input
                          #mobility1="ngModel"
  required
                          [(ngModel)]="regDetails.mobility1"
                          type="radio"
                          id="mobility1c"
                          name="mobility1"
                          class="custom-control-input"
                          value="c"
                        />
                        <label class="custom-control-label" for="mobility1c">
                          I have moderate problems in walking about or doing my
                          usual activities e.g., The distance and duration I can
                          walk for is limited</label
                        >
                      </div>
  
                      <div class="custom-control custom-radio">
                        <input
                          #mobility1="ngModel"
  required
                          [(ngModel)]="regDetails.mobility1"
                          type="radio"
                          id="mobility1d"
                          name="mobility1"
                          class="custom-control-input"
                          value="d"
                        />
                        <label class="custom-control-label" for="mobility1d">
                          I have severe problems in walking about or doing my
                          usual activities e.g., I have physical
                          limitations</label
                        >
                      </div>
  
                      <div class="custom-control custom-radio">
                        <input
                          #mobility1="ngModel"
  required
                          [(ngModel)]="regDetails.mobility1"
                          type="radio"
                          id="mobility1e"
                          name="mobility1"
                          class="custom-control-input"
                          value="e"
                        />
                        <label class="custom-control-label" for="mobility1e">
                          I am currently unable walk (temporarily) e.g., I am
                          unable to do most of my usual activities</label
                        >
                      </div>
  
                      <div class="custom-control custom-radio">
                        <input
                          #mobility1="ngModel"
  required
                          [(ngModel)]="regDetails.mobility1"
                          type="radio"
                          id="mobility1f"
                          name="mobility1"
                          class="custom-control-input"
                          value="f"
                        />
                        <label class="custom-control-label" for="mobility1f">
                          I am unable walk (permanently) e.g., I am a wheelchair
                          user</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
  
                <div
                  class="alert alert-danger"
                  *ngIf="stepSubmitted[4] &&subStepSubmitted[1] && regDetails.mobility1 == null"
                >
                  Please select an option.
                </div>
              </div>
              <em
              >(Usual activities include getting washed and dressed, going to
              work, hobbies, and doing things with family and friends)
            </em>
              </div>
  
  
              <div [hidden]="subStep!=2">
             
  
              <div
                class="form-group mt-3"
                [hidden]="
                  regDetails.mobility1 == null
                "
              >
                <label for="mobility2" class="uppercase"
                  >Have you had 3 or more falls in the last 6 months?
                  <span class="required">*</span></label
                >
                <input
                  type="hidden"
                  name="mobility2"
                  id="mobility2"
                  #mobility2="ngModel"
                  [(ngModel)]="regDetails.mobility2"
                  [required]="!(regDetails.mobility1 == null ||
                  regDetails.mobility1 == 'e' ||
                  regDetails.mobility1 == 'f')"
                />
                <div class="row">
                  <div class="col-md-2 col-6">
                    <button
                      type="button"
                      class="btn-block btn btn-default"
                      [ngClass]="{
                        'btn-primary': regDetails.mobility2 === '1',
                        'btn-default':
                          regDetails.mobility2 === '0' ||
                          regDetails.mobility2 === null
                      }"
                      (click)="regDetails.mobility2 = '1'"
                    >
                      YES
                    </button>
                  </div>
                  <div class="col-md-2 col-6">
                    <button
                      type="button"
                      class="btn-block btn btn-default"
                      [ngClass]="{
                        'btn-primary': regDetails.mobility2 === '0',
                        'btn-default':
                          regDetails.mobility2 === '1' ||
                          regDetails.mobility2 === null
                      }"
                      (click)="regDetails.mobility2 = '0'"
                    >
                      NO
                    </button>
                  </div>
                </div>
  
                <div
                  class="alert alert-danger"
                  *ngIf="stepSubmitted[4] && subStepSubmitted[2] && regDetails.mobility2 == null"
                >
                  Please select an option.
                </div>
              </div>
              <div
                class="form-group"
                [hidden]="
                  !(regDetails.mobility1 == 'd' && regDetails.mobility2 == '0')
                "
              >
                <label for="mobility3" class="uppercase">
                  Would you like to include personalised walking as part of your
                  programme? <span class="required">*</span></label
                >
                <input
                  type="hidden"
                  name="mobility3"
                  id="mobility3"
                  #mobility3="ngModel"
                  [(ngModel)]="regDetails.mobility3"
                  required
                />
                <div class="row">
                  <div class="col-md-2 col-6">
                    <button
                      type="button"
                      class="btn-block btn btn-default"
                      [ngClass]="{
                        'btn-primary': regDetails.mobility3 === '1',
                        'btn-default':
                          regDetails.mobility3 === '0' ||
                          regDetails.mobility3 === null
                      }"
                      (click)="regDetails.mobility3 = '1'"
                    >
                      YES
                    </button>
                  </div>
                  <div class="col-md-2 col-6">
                    <button
                      type="button"
                      class="btn-block btn btn-default"
                      [ngClass]="{
                        'btn-primary': regDetails.mobility3 === '0',
                        'btn-default':
                          regDetails.mobility3 === '1' ||
                          regDetails.mobility3 === null
                      }"
                      (click)="regDetails.mobility3 = '0'"
                    >
                      NO
                    </button>
                  </div>
                </div>
  
                <div
                  class="alert alert-danger"
                  *ngIf="stepSubmitted[4]&&subStepSubmitted[2] && regDetails.mobility3 == null"
                >
                  Please select an option.
                </div>
              </div>
            </div>
              <p></p>
            </div>
          </div>
          <hr class="divider" />
          <div class="container">
            <div class="row">
              <div class="col-6"></div>
              <div class="col-6 text-right">
                <button
                  (click)="checkReg(4)"
                  class="btn btn-primary btn-white"
                  [disabled]="isFetching"
                  type="button"
                >
                  NEXT
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div [hidden]="step !== 5">
        <div
          class="reading-material-page-header booster-session-step-header register-header text-left"
        >
          2.{{subStep}} | Physical activity status
        </div>
        <div class="container">
          <div class="row">
            <div class="col">
  
  <!-- a -->
  
              <div [hidden]="subStep!=1">
              <div class="form-group">
                <label for="employed" class="uppercase"
                  >Are you employed? <span class="required">*</span></label
                >
                <input
                  type="hidden"
                  name="employed"
                  id="employed"
                  #employed="ngModel"
                  [(ngModel)]="regDetails.employed"
                  required
                />
                <div class="row">
                  <div class="col-md-2 col-6">
                    <button
                      type="button"
                      class="btn-block btn btn-default"
                      [ngClass]="{
                        'btn-primary': regDetails.employed === 1,
                        'btn-default':
                          regDetails.employed === 0 ||
                          regDetails.employed === null
                      }"
                      (click)="regDetails.employed = 1"
                    >
                      YES
                    </button>
                  </div>
                  <div class="col-md-2 col-6">
                    <button
                      type="button"
                      class="btn-block btn btn-default"
                      [ngClass]="{
                        'btn-primary': regDetails.employed === 0,
                        'btn-default':
                          regDetails.employed === 1 ||
                          regDetails.employed === null
                      }"
                      (click)="regDetails.employed = 0"
                    >
                      NO
                    </button>
                  </div>
                </div>
  
                <div
                  class="alert alert-danger"
                  *ngIf="stepSubmitted[5] && regDetails.employed == null"
                >
                  Please select an option.
                </div>
              </div>
  
              <div
                class="form-group"
                [hidden]="!regDetails.employed || regDetails.employed == 0"
              >
                <label for="employment_status" class="uppercase"
                  >Please select an option below
                  <span class="required">*</span></label
                >
                <input
                  type="hidden"
                  name="employment_status"
                  id="employment_status"
                  #employment_status="ngModel"
                  [(ngModel)]="regDetails.employment_status"
                  [required]="regDetails.employed != 0"
                />
                <div class="row">
                  <div class="col-12">
                    <div class="radio-group-block">
                      <div class="custom-control custom-radio">
                        <input
                          type="radio"
                          name="employment_status"
                          id="employment_statusa"
                          #employment_status="ngModel"
                          [(ngModel)]="regDetails.employment_status"
                          class="custom-control-input"
                          value="a"
                        />
                        <label
                          class="custom-control-label"
                          for="employment_statusa"
                        >
                          I spend most of my time at work sitting</label
                        >
                      </div>
  
                      <div class="custom-control custom-radio">
                        <input
                          type="radio"
                          name="employment_status"
                          id="employment_statusb"
                          #employment_status="ngModel"
                          [(ngModel)]="regDetails.employment_status"
                          class="custom-control-input"
                          value="b"
                        />
                        <label
                          class="custom-control-label"
                          for="employment_statusb"
                        >
                          I spend most of my time at work standing or walking.
                          However, my work does not require much intense physical
                          effort (e.g., shop assistant, hairdresser, security
                          guard, childminder etc)</label
                        >
                      </div>
  
                      <div class="custom-control custom-radio">
                        <input
                          type="radio"
                          name="employment_status"
                          id="employment_statusc"
                          #employment_status="ngModel"
                          [(ngModel)]="regDetails.employment_status"
                          class="custom-control-input"
                          value="c"
                        />
                        <label
                          class="custom-control-label"
                          for="employment_statusc"
                        >
                          My work involves definite physical effort including the
                          handling of heavy objects and use of tools (e.g.,
                          plumber, electrician, carpenter, cleaner, hospital
                          nurse, gardener, postal delivery workers etc)</label
                        >
                      </div>
  
                      <div class="custom-control custom-radio">
                        <input
                          type="radio"
                          name="employment_status"
                          id="employment_statusd"
                          #employment_status="ngModel"
                          [(ngModel)]="regDetails.employment_status"
                          class="custom-control-input"
                          value="d"
                        />
                        <label
                          class="custom-control-label"
                          for="employment_statusd"
                        >
                          My work involves vigorous physical activity including
                          the handling of very heavy objects (e.g., scaffolder,
                          construction worker, refuse collector etc)</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="alert alert-danger"
                  *ngIf="stepSubmitted[5] && regDetails.employment_status == null"
                >
                  Please select an option.
                </div>
              </div>
            </div> 
  
            <div [hidden]="subStep!=2">
              <div class="form-group">
                <label class="uppercase">
                  During the last week how many hours did you spend on each of the
                  following activities? <span class="required">*</span></label
                >
  
                <div class="ep-activity-group">
                  <label for="activity_time1"
                    >Physical exercise such as swimming, jogging, aerobics,
                    football, tennis, gym work out etc:
                    <span class="required">*</span></label
                  >
  
                  <div class="radio-group-ep">
                    <div class="custom-control custom-radio">
                      <input
                        [(ngModel)]="regDetails.activity_time1"
                        #activity_time1="ngModel"
                        type="radio"
                        id="activity_time1a"
                        name="activity_time1"
                        class="custom-control-input"
                        value="a"
                        required
                      />
                      <label class="custom-control-label" for="activity_time1a"
                        >None</label
                      >
                    </div>
  
                    <div class="custom-control custom-radio">
                      <input
                        [(ngModel)]="regDetails.activity_time1"
                        #activity_time1="ngModel"
                        type="radio"
                        id="activity_time1b"
                        name="activity_time1"
                        class="custom-control-input"
                        value="b"
                        required
                      />
                      <label class="custom-control-label" for="activity_time1b"
                        >Some but &lt; 1 h</label
                      >
                    </div>
  
                    <div class="custom-control custom-radio">
                      <input
                        [(ngModel)]="regDetails.activity_time1"
                        #activity_time1="ngModel"
                        type="radio"
                        id="activity_time1c"
                        name="activity_time1"
                        class="custom-control-input"
                        value="c"
                        required
                      />
                      <label class="custom-control-label" for="activity_time1c"
                        >1-3hrs</label
                      >
                    </div>
  
                    <div class="custom-control custom-radio">
                      <input
                        [(ngModel)]="regDetails.activity_time1"
                        #activity_time1="ngModel"
                        type="radio"
                        id="activity_time1d"
                        name="activity_time1"
                        class="custom-control-input"
                        value="d"
                        required
                      />
                      <label class="custom-control-label" for="activity_time1d"
                        >&gt;3 hrs</label
                      >
                    </div>
                  </div>
                  <div
                    class="alert alert-danger"
                    *ngIf="stepSubmitted[5] && subStepSubmitted[2] && !regDetails.activity_time1"
                  >
                    Please select your activity time.
                  </div>
  
                  <label for="activity_time2"
                    >Cycling, including cycling to work and leisure time:
                    <span class="required">*</span></label
                  >
  
                  <div class="radio-group-ep">
                    <div class="custom-control custom-radio">
                      <input
                        [(ngModel)]="regDetails.activity_time2"
                        #activity_time2="ngModel"
                        type="radio"
                        id="activity_time2a"
                        name="activity_time2"
                        class="custom-control-input"
                        value="a"
                        required
                      />
                      <label class="custom-control-label" for="activity_time2a"
                        >None</label
                      >
                    </div>
  
                    <div class="custom-control custom-radio">
                      <input
                        [(ngModel)]="regDetails.activity_time2"
                        #activity_time2="ngModel"
                        type="radio"
                        id="activity_time2b"
                        name="activity_time2"
                        class="custom-control-input"
                        value="b"
                        required
                      />
                      <label class="custom-control-label" for="activity_time2b"
                        >Some but &lt; 1 h</label
                      >
                    </div>
  
                    <div class="custom-control custom-radio">
                      <input
                        [(ngModel)]="regDetails.activity_time2"
                        #activity_time2="ngModel"
                        type="radio"
                        id="activity_time2c"
                        name="activity_time2"
                        class="custom-control-input"
                        value="c"
                        required
                      />
                      <label class="custom-control-label" for="activity_time2c"
                        >1-3hrs</label
                      >
                    </div>
  
                    <div class="custom-control custom-radio">
                      <input
                        [(ngModel)]="regDetails.activity_time2"
                        #activity_time2="ngModel"
                        type="radio"
                        id="activity_time2d"
                        name="activity_time2"
                        class="custom-control-input"
                        value="d"
                        required
                      />
                      <label class="custom-control-label" for="activity_time2d"
                        >&gt;3 hrs</label
                      >
                    </div>
                  </div>
                  <div
                    class="alert alert-danger"
                    *ngIf="stepSubmitted[5] && subStepSubmitted[2] && !regDetails.activity_time2"
                  >
                    Please select your activity time.
                  </div>
  
                  <label for="activity_time3"
                    >Walking, including walking to work, shopping, for pleasure
                    etc: <span class="required">*</span></label
                  >
  
                  <div class="radio-group-ep">
                    <div class="custom-control custom-radio">
                      <input
                        [(ngModel)]="regDetails.activity_time3"
                        #activity_time3="ngModel"
                        type="radio"
                        id="activity_time3a"
                        name="activity_time3"
                        class="custom-control-input"
                        value="a"
                        required
                      />
                      <label class="custom-control-label" for="activity_time3a"
                        >None</label
                      >
                    </div>
  
                    <div class="custom-control custom-radio">
                      <input
                        [(ngModel)]="regDetails.activity_time3"
                        #activity_time3="ngModel"
                        type="radio"
                        id="activity_time3b"
                        name="activity_time3"
                        class="custom-control-input"
                        value="b"
                        required
                      />
                      <label class="custom-control-label" for="activity_time3b"
                        >Some but &lt; 1 h</label
                      >
                    </div>
  
                    <div class="custom-control custom-radio">
                      <input
                        [(ngModel)]="regDetails.activity_time3"
                        #activity_time3="ngModel"
                        type="radio"
                        id="activity_time3c"
                        name="activity_time3"
                        class="custom-control-input"
                        value="c"
                        required
                      />
                      <label class="custom-control-label" for="activity_time3c"
                        >1-3hrs</label
                      >
                    </div>
  
                    <div class="custom-control custom-radio">
                      <input
                        [(ngModel)]="regDetails.activity_time3"
                        #activity_time3="ngModel"
                        type="radio"
                        id="activity_time3d"
                        name="activity_time3"
                        class="custom-control-input"
                        value="d"
                        required
                      />
                      <label class="custom-control-label" for="activity_time3d"
                        >&gt;3 hrs</label
                      >
                    </div>
                  </div>
                  <div
                    class="alert alert-danger"
                    *ngIf="stepSubmitted[5] && subStepSubmitted[2] && !regDetails.activity_time3"
                  >
                    Please select your activity time.
                  </div>
  
                  <label for="activity_time4"
                    >Housework/childcare: <span class="required">*</span></label
                  >
  
                  <div class="radio-group-ep">
                    <div class="custom-control custom-radio">
                      <input
                        [(ngModel)]="regDetails.activity_time4"
                        #activity_time4="ngModel"
                        type="radio"
                        id="activity_time4a"
                        name="activity_time4"
                        class="custom-control-input"
                        value="a"
                        required
                      />
                      <label class="custom-control-label" for="activity_time4a"
                        >None</label
                      >
                    </div>
  
                    <div class="custom-control custom-radio">
                      <input
                        [(ngModel)]="regDetails.activity_time4"
                        #activity_time4="ngModel"
                        type="radio"
                        id="activity_time4b"
                        name="activity_time4"
                        class="custom-control-input"
                        value="b"
                        required
                      />
                      <label class="custom-control-label" for="activity_time4b"
                        >Some but &lt; 1 h</label
                      >
                    </div>
  
                    <div class="custom-control custom-radio">
                      <input
                        [(ngModel)]="regDetails.activity_time4"
                        #activity_time4="ngModel"
                        type="radio"
                        id="activity_time4c"
                        name="activity_time4"
                        class="custom-control-input"
                        value="c"
                        required
                      />
                      <label class="custom-control-label" for="activity_time4c"
                        >1-3hrs</label
                      >
                    </div>
  
                    <div class="custom-control custom-radio">
                      <input
                        [(ngModel)]="regDetails.activity_time4"
                        #activity_time4="ngModel"
                        type="radio"
                        id="activity_time4d"
                        name="activity_time4"
                        class="custom-control-input"
                        value="d"
                        required
                      />
                      <label class="custom-control-label" for="activity_time4d"
                        >&gt;3 hrs</label
                      >
                    </div>
                  </div>
                  <div
                    class="alert alert-danger"
                    *ngIf="stepSubmitted[5] && subStepSubmitted[2] && !regDetails.activity_time4"
                  >
                    Please select your activity time.
                  </div>
  
                  <label for="activity_time5"
                    >Gardening/DIY: <span class="required">*</span></label
                  >
  
                  <div class="radio-group-ep">
                    <div class="custom-control custom-radio">
                      <input
                        [(ngModel)]="regDetails.activity_time5"
                        #activity_time5="ngModel"
                        type="radio"
                        id="activity_time5a"
                        name="activity_time5"
                        class="custom-control-input"
                        value="a"
                        required
                      />
                      <label class="custom-control-label" for="activity_time5a"
                        >None</label
                      >
                    </div>
  
                    <div class="custom-control custom-radio">
                      <input
                        [(ngModel)]="regDetails.activity_time5"
                        #activity_time5="ngModel"
                        type="radio"
                        id="activity_time5b"
                        name="activity_time5"
                        class="custom-control-input"
                        value="b"
                        required
                      />
                      <label class="custom-control-label" for="activity_time5b"
                        >Some but &lt; 1 h</label
                      >
                    </div>
  
                    <div class="custom-control custom-radio">
                      <input
                        [(ngModel)]="regDetails.activity_time5"
                        #activity_time5="ngModel"
                        type="radio"
                        id="activity_time5c"
                        name="activity_time5"
                        class="custom-control-input"
                        value="c"
                        required
                      />
                      <label class="custom-control-label" for="activity_time5c"
                        >1-3hrs</label
                      >
                    </div>
  
                    <div class="custom-control custom-radio">
                      <input
                        [(ngModel)]="regDetails.activity_time5"
                        #activity_time5="ngModel"
                        type="radio"
                        id="activity_time5d"
                        name="activity_time5"
                        class="custom-control-input"
                        value="d"
                        required
                      />
                      <label class="custom-control-label" for="activity_time5d"
                        >&gt;3 hrs</label
                      >
                    </div>
                  </div>
                  <div
                    class="alert alert-danger"
                    *ngIf="stepSubmitted[5] && subStepSubmitted[2] && !regDetails.activity_time5"
                  >
                    Please select your activity time.
                  </div>
                </div>
              </div>
            </div> 
  
            <div [hidden]="subStep!=3">
              <div class="form-group">
                <label for="walking_pace" class="uppercase"
                  >How would you describe your usual walking pace?
                  <span class="required">*</span></label
                >
  
                <div class="radio-group-ep">
                  <div class="custom-control custom-radio">
                    <input
                      #walking_pace="ngModel"
                      [(ngModel)]="regDetails.walking_pace"
                      type="radio"
                      id="walking_pacea"
                      name="walking_pace"
                      class="custom-control-input"
                      value="a"
                    />
                    <label class="custom-control-label" for="walking_pacea"
                      >Slow (&lt;3mph)</label
                    >
                  </div>
  
                  <div class="custom-control custom-radio">
                    <input
                      #walking_pace="ngModel"
                      [(ngModel)]="regDetails.walking_pace"
                      type="radio"
                      id="walking_paceb"
                      name="walking_pace"
                      class="custom-control-input"
                      value="b"
                    />
                    <label class="custom-control-label" for="walking_paceb"
                      >Steady average pace</label
                    >
                  </div>
  
                  <div class="custom-control custom-radio">
                    <input
                      #walking_pace="ngModel"
                      [(ngModel)]="regDetails.walking_pace"
                      type="radio"
                      id="walking_pacec"
                      name="walking_pace"
                      class="custom-control-input"
                      value="c"
                    />
                    <label class="custom-control-label" for="walking_pacec"
                      >Brisk pace</label
                    >
                  </div>
  
                  <div class="custom-control custom-radio">
                    <input
                      #walking_pace="ngModel"
                      [(ngModel)]="regDetails.walking_pace"
                      type="radio"
                      id="walking_paced"
                      name="walking_pace"
                      class="custom-control-input"
                      value="d"
                    />
                    <label class="custom-control-label" for="walking_paced"
                      >Fast pace (&gt;4mph)</label
                    >
                  </div>
                </div>
                <div
                  class="alert alert-danger"
                  *ngIf="stepSubmitted[5] && subStepSubmitted[3] && regDetails.walking_pace == null"
                >
                  Please select an option.
                </div>
              </div>
  
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="location" class="uppercase"
                      >On average how many hours do you spend sitting on a
                      weekday?<span class="required">*</span></label
                    >
                    <input
                      type="text"
                      name="sitting_weekday"
                      class="form-control"
                      placeholder="Hours per day"
                      id="sitting_weekday"
                      [ngModel]="regDetails.sitting_weekday"
                      #sitting_weekday="ngModel"
                      autocomplete="off"
                      required
                      minlength="1"
                      maxlength="24"
                    />
                    <div
                      class="alert alert-danger"
                      *ngIf="
                        (sitting_weekday.touched && sitting_weekday.invalid) ||
                        (stepSubmitted[5] && subStepSubmitted[3] && sitting_weekday.invalid)
                      "
                    >
                      There is an error with your input.
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="location" class="uppercase"
                      >On average how many hours do you spend sitting on a weekend
                      day?<span class="required">*</span></label
                    >
                    <input
                      type="text"
                      name="sitting_weekend"
                      class="form-control"
                      placeholder="Hours per day"
                      id="sitting_weekend"
                      [ngModel]="regDetails.sitting_weekend"
                      #sitting_weekend="ngModel"
                      autocomplete="off"
                      required
                      minlength="1"
                      maxlength="24"
                    />
                    <div
                      class="alert alert-danger"
                      *ngIf="
                        (sitting_weekend.touched && sitting_weekend.invalid) ||
                        (stepSubmitted[5]&& subStepSubmitted[3] && sitting_weekend.invalid)
                      "
                    >
                      There is an error with your input.
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
  
          <hr class="divider" />
          <div class="container">
            <div class="row">
              <div class="col-6"></div>
              <div class="col-6 text-right">
                <button
                  (click)="checkReg(5)"
                  class="btn btn-primary btn-white"
                  [disabled]="isFetching"
                  type="button"
                >
                  NEXT
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div [hidden]="step !== 6">
        <div
          class="reading-material-page-header booster-session-step-header register-header text-left"
        >
          3 | Notification settings
        </div>
        <div class="container">
          <div class="row">
            <div class="col">
              <p>
                The app will automatically send you activity notifications on
                stepping, workouts and inactivity (via email or phone [if
                android]). Please select how often you would like to receive these
                notifications You can select off if you do NOT want to receive
                notifications on any of the following topic areas.
              </p>
  
              <!-- Stepping notifications not to appear if user selects mobility status 1E, 1F 1D+2a+3N or 1D+2b+3N -->
              <div
                class="form-group"
                [hidden]="
                  regDetails.mobility1 == 'e' ||
                  regDetails.mobility1 == 'f' ||
                  (regDetails.mobility1 == 'd' &&
                    regDetails.mobility2 == '1' &&
                    regDetails.mobility3 == '0') ||
                  (regDetails.mobility1 == 'd' &&
                    regDetails.mobility2 == '0' &&
                    regDetails.mobility3 == '0')
                "
              >
                <label for="notifications1" class="uppercase"
                  >Stepping<span class="required">*</span></label
                >
                <input
                  type="hidden"
                  name="notifications1"
                  [ngModel]="regDetails.notifications1 | json"
                />
                <app-notification-setting-control
                  [notificationData]="regDetails.notifications1"
                  [notificationName]="'Stepping'"
                ></app-notification-setting-control>
  
                <div
                  class="alert alert-danger"
                  *ngIf="stepSubmitted[6] && regDetails.notifications1 == null"
                >
                  Please select your notification status.
                </div>
              </div>
  
              <div class="form-group">
                <label for="notifications1" class="uppercase"
                  >Workouts<span class="required">*</span></label
                >
                <input
                  type="hidden"
                  name="notifications2"
                  [ngModel]="regDetails.notifications2 | json"
                />
                <app-notification-setting-control
                  [notificationData]="regDetails.notifications2"
                  [notificationName]="'Workouts'"
                ></app-notification-setting-control>
  
                <div
                  class="alert alert-danger"
                  *ngIf="stepSubmitted[6] && regDetails.notifications2 == null"
                >
                  Please select your notification status.
                </div>
              </div>
  
              <div class="form-group">
                <label for="notifications3" class="uppercase">
                  <span
                    [hidden]="
                      regDetails.mobility1 == 'e' ||
                      regDetails.mobility1 == 'f' ||
                      (regDetails.mobility1 == 'd' &&
                        regDetails.mobility2 == '1' &&
                        regDetails.mobility3 == '0') ||
                      (regDetails.mobility1 == 'd' &&
                        regDetails.mobility2 == '0' &&
                        regDetails.mobility3 == '0')
                    "
                    >Sitting</span
                  ><span
                    [hidden]="
                      !(
                        regDetails.mobility1 == 'e' ||
                        regDetails.mobility1 == 'f' ||
                        (regDetails.mobility1 == 'd' &&
                          regDetails.mobility2 == '1' &&
                          regDetails.mobility3 == '0') ||
                        (regDetails.mobility1 == 'd' &&
                          regDetails.mobility2 == '0' &&
                          regDetails.mobility3 == '0')
                      )
                    "
                    >Sedentary behaviour</span
                  >
                  <span class="required">*</span></label
                >
                
                <input
                  type="hidden"
                  name="notifications3"
                  [ngModel]="regDetails.notifications3 | json"
                />
                <app-notification-setting-control
                  [notificationData]="regDetails.notifications3"
                  [notificationName]="'Sitting'"
                ></app-notification-setting-control>
  
                <div
                  class="alert alert-danger"
                  *ngIf="stepSubmitted[6] && regDetails.notifications3 == null"
                >
                  Please select your notification status.
                </div>
              </div>
            </div>
          </div>
  
          <hr class="divider" />
          <div class="container">
            <div class="row">
              <div class="col-6"></div>
              <div class="col-6 text-right">
                <button
                  (click)="checkReg(6)"
                  class="btn btn-primary btn-white"
                  [disabled]="isFetching"
                  type="button"
                >
                  NEXT
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
  
      <!-- step 8 -->
      <div [hidden]="step !== 7">
        <div
          class="reading-material-page-header booster-session-step-header register-header text-left"
        >
          Completed
        </div>
        <div class="container">
          <p>Congratulations!</p>
          <div *ngIf="environment.defaultProgramme != 'BabySteps'">
            <div *ngIf="environment.country == 'AUS'">
              <span *ngIf="regProgramme.programme_id == 2"
                >You are now setup to use MyDESMOND.</span
              >
              <span *ngIf="regProgramme.programme_id == 3"
                >You are now setup to use Baby Steps.</span
              >
              <span *ngIf="regProgramme.programme_id == 8"
                >You are now setup to use Let's Prevent.</span
              >
            </div>
            <div *ngIf="environment.country !== 'AUS'">
              <p>You are now setup to use Steps4Health.</p>
            </div>
  
            <!--p class="visible-xs">
              We recommend you add the MyDesmond shortcut icon to your home screen
              on your mobile phone using the button below.
            </p-->
          </div>
          <div *ngIf="environment.defaultProgramme == 'BabySteps'">
            <p>
              You are now setup to use Baby Steps - part of the MyDesmond
              family.
            </p>
            <!--p class="visible-xs">
              We recommend you add the MyDesmond shortcut icon to your home screen
              on your mobile phone using the button below. Please note this feature
              is not available for Chrome users on iOS
            </p-->
          </div>
        </div>
        <!--div class="text-center visible-xs">
          <img
            src="/app/assets/icons/apple-icon-180x180.png"
            width="115"
            height="115"
          /><br /><br />
          <button class="btn btn-primary" (click)="addToPhone()">
            <i class="fa fa-plus-circle"></i> ADD TO PHONE
          </button>
        </div-->
        <hr class="divider" />
        <div class="container">
          <div class="row">
            <div class="col-6"></div>
            <div class="col-6 text-right">
              <a (click)="complete()" class="btn btn-primary btn-white"
                >NEXT
                <span class="icon-wrap"
                  ><fa-icon
                    [icon]="['far', 'arrow-circle-right']"
                  ></fa-icon></span
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  