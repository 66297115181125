<nav class="subnav">
    <a [routerLink]="['/health-trackers','healthy-eating']" class="subnav-prev" aria-label="Go to Healthy Eating">
        <span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span>
        </a>
    <h2>
      <span class="icon-wrap">
        <fa-icon
          [icon]="['fas', 'tint']"
        ></fa-icon>
      </span>
      HbA1c
    </h2>
    <a
      [routerLink]="['/health-trackers', 'blood-pressure']"
      class="subnav-next"
      aria-label="Go to Blood Pressure"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
    ></a>
  </nav>
  <div>
      <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
      
    
  <div class="masthead masthead-accent">
    <div *ngIf="userHbA1cs.length>0">
      <div class="row">
        <div class="col-12">
          <h3 class="tracker-title">LONG TERM<br>BLOOD GLUCOSE MEASURE</h3>
          <div class="row">
            <div class="col-12">
              <img
                src="./assets/img/icon-drop.png"
                width="60"
                alt="Drop of blood"
              />
              <span class="tracker-value tracker-value-lg tracker-value-hba1c"
                >{{ userHbA1cs[0].glucose | number: "1.2-2" }}<span class="hba1c-mmol"> mmol/mol</span></span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="masthead-subtext tracker-subtext">
            Last updated on
            {{ userHbA1cs[0].created | dateToISO | dateFormatter: "D" | ordinal }}
            {{ userHbA1cs[0].created | dateToISO | dateFormatter: "MMM yyyy" }}
          </p>
        </div>
      </div>
    </div>

    <div *ngIf="userHbA1cs.length ===0">
      <h4>You haven't added any readings yet!</h4>
    </div>
  </div>

  <div class="toggle-container">
    <div>
      <div
        class="toggle-header toggle-header-left"
        (click)="toggleRow.update = !toggleRow.update"
      >
        <a href="" class="toggle-nav toggle-nav-expand" (click)="$event.preventDefault()"
          ><span class="icon-wrap" aria-label="Expand"
          ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span
      ></a>
        <div class="toggle-header-title">UPDATE TRACKER</div>
      </div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.update">
      <form
        class="bs-form tracker-form"
        name="trackerForm"
        method="post"
        (ngSubmit)="onSubmit()"
        #trackerForm="ngForm"
      >
        <div>
          <div class="form-group">
            <label for="glucosePercent">GLUCOSE MEASURE (mmol/mol)</label>
            <p class="instruction">
              Please enter your latest HbA1c measure below. Please note your
              HbA1c is normally only measured every 3 months.
            </p>

            <div [hidden]="unitMode !== 'percent'">
              <p><strong>Enter a value as a %</strong></p>
              <input
                type="number"
                name="glucosePercent"
                class="form-control input-tracker"
                placeholder=""
                aria-label="glucose Percent"
                id="glucosePercent"
                #glucosePercent="ngModel"
                ngModel
                [min]="4"
                [max]="15"
                autocomplete="off"
                (change)="updateVal()"
              />
              <div
                class="alert alert-danger"
                *ngIf="
                 glucosePercent.touched &&
                 !glucosePercent.valid
                "
              >
                This must be a number 4-15.
              </div>
              <div class="text-center">
                <br />
                <a href="#" (click)="$event.preventDefault();unitMode='mmol/mol'">Switch to mmol/mol</a>
              </div>
            </div>

            <div [hidden]="unitMode !== 'mmol/mol'">
              <p><strong>Enter a value in mmol/mol</strong></p>
              <input
                type="number"
                name="glucose"
                class="form-control input-tracker"
                placeholder=""
                id="glucose"
                #glucose="ngModel"
                ngModel
                [min]="20"
                [max]="140"
                autocomplete="off"
                required
                aria-label="glucose"
                (change)="updateValP()"
              />
              <div
                class="alert alert-danger"
                *ngIf="
                  glucose.touched && !glucose.valid
                "
              >
                This must be a number 20-140.
              </div>
              <div class="text-center">
                <br />
                <a href="#" (click)="$event.preventDefault();unitMode='percent'">Switch to %</a>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="entryDate">DATE</label>
            <p class="instructions">
              When did you do take this reading?
            </p>
            <input
              name="date"
              type="text"
              placeholder="DD/MM/YYYY"
              class="form-control input-tracker"
              bsDatepicker
              autocomplete="off"
              [bsConfig]="{
                dateInputFormat: 'DD/MM/YYYY',
                containerClass: 'theme-cv',
                showWeekNumbers: false
              }"
              [(ngModel)]="entryDate"
              required
              [bsValue]="entryDate"
              [maxDate]="maxDate"
              #date="ngModel"
            />
          </div>
          <div class="tracker-submit-container">
            <button
              type="submit"
              class="btn btn-primary btn-white"
              ng-disabled="!trackerForm.$valid"
            >
            <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'check']"></fa-icon
          ></span> CONFIRM
            </button>
          </div>
        </div>
      </form>

      <!--span *ngIf="updatedToday()">You have already updated today</span-->
    </div>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.graph = !toggleRow.graph"
    >
      <a href="" class="toggle-nav toggle-nav-expand" (click)="$event.preventDefault()"
        ><span class="icon-wrap" aria-label="Expand"
        ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span
    ></a>
      <div class="toggle-header-title">TRACKER GRAPH</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.graph">
      <div *ngIf="userHbA1cs.length > 0">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs nav-tabs-duo justify-content-center" role="tablist">
          <li role="presentation" class="active">
            <a aria-controls="grapht" role="tab" data-toggle="tab"
              >HbA1c (mmol/mol)</a
            >
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <div
            role="tabpanel"
            id="grapht"
            aria-labelledby="grapht"
          >
            <div *ngIf="bloodGlucoseChartData[0].data.length > 1">
              <canvas
                class="mt-3 mb-3 tracker-chart"
                baseChart
                [datasets]="bloodGlucoseChartData"
                [labels]="chartLabels"
                [options]="chartOptions"
                [legend]="chartLegend"
                [chartType]="chartType"
                [colors]="chartColors"
              ></canvas>
              <!--canvas id="line" class="chart chart-line tracker-chart" chart-data="data" chart-labels="labels" chart-series="series" chart-options="options"
                chart-dataset-override="datasetOverride" chart-click="onClick" height="200"  aria-label="Bar Chart Values {{data}}">
                </canvas-->
            </div>
            <p class="text-center" *ngIf="bloodGlucoseChartData[0].data.length < 2">
              Please add more entries to see the graph
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="userHbA1cs.length === 0">
        <p>There are no readings to display in a graph</p>
      </div>
    </div>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.entry = !toggleRow.entry"
    >
      <a href="" class="toggle-nav toggle-nav-expand" (click)="$event.preventDefault()"
        ><span class="icon-wrap" aria-label="Expand"
        ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span
    ></a>
      <div class="toggle-header-title">ENTRY ARCHIVE</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.entry">
      <table *ngIf="userHbA1cs.length > 0" class="table">
        <thead>
          <tr>
            <th>DATE</th>
            <th>GLUCOSE MEASURE</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let userHbA1c of userHbA1cs">
            <td class="text-center">
              {{ userHbA1c.created | dateToISO | dateFormatter: "D" | ordinal }}
              {{ userHbA1c.created | dateToISO | dateFormatter: "MMM yyyy" }}
            </td>
            <td class="text-center">{{ userHbA1c.glucose }}</td>
            <td>
              <a
              [swal]="{
                title: 'Are you sure you want to delete this?',
                icon: 'warning',
                showCancelButton: true
              }"
              (confirm)="onRemove(userHbA1c)"
              class="pointer"
              ><span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
            ></a>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="userHbA1cs.length === 0">There are no records</div>
    </div>
  </div>
  <!-- decision maker -->
  <hr class="divider-tracker" />
  <div class="text-center decision-maker-block">
    <p>
      Want to set goals to stabilise your blood glucose levels?<br />
      Try our Decision Maker tool
    </p>
    <a [routerLink]="['/decision-maker']" class="btn btn-primary"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'map-signs']"></fa-icon
      ></span>
      DECISION MAKER</a
    >
  </div>

  <app-section-nav
    [section]="'healthTrackers'"
    [active]="'hba1c'"
  ></app-section-nav>
</div>
