<nav class="subnav">
    <a [routerLink]="['/settings']" class="subnav-prev"
    > <span
    ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon
  ></span></a>
    <h2><span
      ><fa-icon [icon]="['fas', 'info']"></fa-icon
    ></span> ABOUT <span *ngIf="environment.defaultProgramme != 'BabySteps'"> MyDesmond</span> <span *ngIf="environment.defaultProgramme == 'BabySteps'"> Baby Steps</span></h2>
    <a [routerLink]="['/legal']" class="subnav-next"
      > <span
      ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon
    ></span></a>
  </nav>
<div class="container container-pad">
    <div [innerHtml]="cms.about.content | safeHtml" *ngIf="cms"></div>
    <div ng-if="environment.country == 'UK'">
        <div class="well text-center">
            <img src="/app/assets/img/logo-clahrc.png" width="313" class="img-responsive">
        </div>
        <div class="well text-center">
            <img src="/app/assets/img/logo-ldc.png" width="226">
        </div>
        <div class="well text-center">
            <img src="/app/assets/img/logo-lu.png" width="152">
        </div>
        <div class="well text-center">
            <img src="/app/assets/img/logo-uhl.png" width="259">
        </div>
    </div>
    <div ng-if="environment.country == 'AUS'">       
        <div ng-if="environment.defaultProgramme != 'BabySteps'" class="well text-center" style="padding:20px;">
            <img src="/app/assets/img/logo-ndss.jpg" width="300" >
        </div>
        <div ng-if="environment.defaultProgramme == 'BabySteps'" class="well text-center" style="padding:20px;">
            <img src="/app/assets/img/logo-adh.jpg" width="300" >
        </div>
        <div class="well text-center">
            Original Programme by <br><br><br>
            <img src="/app/assets/img/logo-clahrc.png" width="313" ><br><br><br>
            <img src="/app/assets/img/logo-ldc.png" width="226"><br><br><br>
            <img src="/app/assets/img/logo-lu.png" width="152"><br><br><br>
            <img src="/app/assets/img/logo-uhl.png" width="259"><br>
        </div>       
    </div>
</div>