import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification-setting-control',
  templateUrl: './notification-setting-control.component.html',
  styleUrls: ['./notification-setting-control.component.scss'],
})
export class NotificationSettingControlComponent implements OnInit {
  @Input() notificationData;
  @Input() notificationName;

  public days = [
    {
      id: 'mon',
      label:'Monday',
      isChecked: false,
    },
    {
      id:'tue',
      label: 'Tuesday',
      isChecked: false,
    },
    {
      id:'wed',
      label: 'Wednesday',
      isChecked: false,
    },
    {
      id:'thu',
      label: 'Thursday',
      isChecked: false,
    },
    {
      id:'fri',
      label: 'Friday',
      isChecked: false,
    },
    {
      id:'sat',
      label: 'Saturday',
      isChecked: false,
    },
    {
      id:'sun',
      label: 'Sunday',
      isChecked: false,
    },
  ];
  selectedItemsList = [];
  checkedIDs = [];
  constructor() {}

  ngOnInit(): void {
    if (!this.notificationData || this.notificationData === null) {
      this.notificationData = {};
      this.notificationData.setting = 'a';
      this.notificationData.days = [];
      this.notificationData.time = 'morning';
    } else {
      if (this.days) {
        this.notificationData.days.forEach((day) => {
          this.days.forEach((value, index) => {
            if (day === value.id) {
              this.days[index].isChecked = true;
            }
          });
        });
      }
    }
  }

  changeSelection() {
    this.fetchCheckedIDs();
  }

  fetchSelectedItems() {
    this.selectedItemsList = this.days.filter(
      (value, index) => {
        return value.isChecked;
      }
    );
  }

  fetchCheckedIDs() {
    this.checkedIDs = [];
    this.days.forEach((value, index) => {
      if (value.isChecked) {
        this.checkedIDs.push(value.id);
      }
    });
    this.notificationData.days = this.checkedIDs;
  }
}
