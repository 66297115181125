<!--div class="section-nav-mobile-sub"><app-section-nav
[section]="'healthTrackers'"
></app-section-nav>
</div-->
<nav class="subnav">
  <h2>
    <span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'chart-line']"></fa-icon
    ></span>
    OVERVIEW
  </h2>
</nav>
<div class="decision-maker" *ngIf="currentProgramme">
  <div
    class="toggle-header toggle-header-left"
    [routerLink]="['/health-trackers', 'shape']"
    *ngIf="currentProgramme.config.trackers.indexOf('shape') > -1"
  >
    <a
      class="toggle-nav toggle-nav-expand"
      [routerLink]="['/health-trackers', 'shape']"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
    ></a>
    <div class="toggle-header-title">
      <ng-container *ngIf="currentUser">
        <span
          class="icon-wrap section-nav-icon"
          *ngIf="currentUser.gender === 'f' || !currentUser.gender"
          ><fa-icon [icon]="['fas', 'female']" [fixedWidth]="true"></fa-icon
        ></span>
        <span
          class="icon-wrap section-nav-icon"
          *ngIf="currentUser.gender === 'm' || currentUser.gender == 'x'"
          ><fa-icon [icon]="['fas', 'male']" [fixedWidth]="true"></fa-icon
        ></span>
      </ng-container>
      SHAPE
    </div>
    <div class="values" *ngIf="userShapes[0]">
      <span
        >BMI:
        <strong>{{
          calcBmi(userShapes[0].weight) | number: "1.1-1"
        }}</strong></span
      ><br />
      <span
        >Weight: <strong>{{ userShapes[0].weight | number }}</strong
        >kg</span
      ><br />
      <span
        >Waist:

        <span *ngIf="environment.country != 'AUS'"
          ><strong>{{ userShapes[0].waist | number: "1.0-0" }}”</strong></span
        >
        <span *ngIf="environment.country == 'AUS'"
          ><strong>{{ userShapes[0].waist * 2.54 | number: "1.0-0" }}</strong
          ><span>cm</span></span
        >
      </span>
    </div>
  </div>

  <div
    class="toggle-header toggle-header-left"
    [routerLink]="['/health-trackers', 'healthy-eating']"
    *ngIf="currentProgramme.config.trackers.indexOf('healthyEating') > -1"
  >
    <a
      class="toggle-nav toggle-nav-expand"
      [routerLink]="['/health-trackers', 'healthy-eating']"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
    ></a>
    <div class="toggle-header-title">
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'utensils']"></fa-icon
      ></span>
      HEALTHY EATING
    </div>
    <div class="values" *ngIf="userHealthyEatings[0]">
      <span>
        <strong>{{ userHealthyEatings[0].healthy_eating | number }}/10</strong>
        for healthy eating</span
      ><br />
      <span
        ><strong>{{
          +userHealthyEatings[0].five_a_day +
            +userHealthyEatings[0].five_a_day_fruit | number
        }}</strong>
        of your 5-a-day</span
      >
    </div>
  </div>
  <div
    class="toggle-header toggle-header-left"
    [routerLink]="['/health-trackers', 'hba1c']"
    *ngIf="currentProgramme.config.trackers.indexOf('hba1c') > -1"
  >
    <a
      class="toggle-nav toggle-nav-expand"
      [routerLink]="['/health-trackers', 'hba1c']"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
    ></a>
    <div class="toggle-header-title">
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'tint']"></fa-icon
      ></span>
      HBA1C
    </div>
    <div class="values" *ngIf="userHbA1cs[0]">
      <span>
        <strong>{{ userHbA1cs[0].glucose | number }}</strong> mmol/mol</span
      ><br />
    </div>
  </div>

  <div
    class="toggle-header toggle-header-left"
    [routerLink]="['/health-trackers', 'blood-pressure']"
    *ngIf="currentProgramme.config.trackers.indexOf('bp') > -1"
  >
    <a
      class="toggle-nav toggle-nav-expand"
      [routerLink]="['/health-trackers', 'blood-pressure']"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
    ></a>
    <div class="toggle-header-title">
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'tachometer-alt']"></fa-icon
      ></span>
      BLOOD PRESSURE
    </div>
    <div class="values" *ngIf="userBloodPressures[0]">
      <span>
        Sys:
        <strong>{{ userBloodPressures[0].systolic | number }}</strong>
        mmHg</span
      ><br />
      <span
        >Dia:
        <strong>{{ +userBloodPressures[0].diastolic | number }}</strong>
        mmHg</span
      >
    </div>
  </div>

  <div
    class="toggle-header toggle-header-left"
    [routerLink]="['/health-trackers', 'cholesterol']"
    *ngIf="currentProgramme.config.trackers.indexOf('cholesterol') > -1"
  >
    <a
      class="toggle-nav toggle-nav-expand"
      [routerLink]="['/health-trackers', 'cholesterol']"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
    ></a>
    <div class="toggle-header-title">
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'heartbeat']"></fa-icon
      ></span>
      CHOLESTEROL
    </div>
    <div class="values" *ngIf="userCholesterols[0]">
      <span>
        Total:
        <strong>{{ userCholesterols[0].cholesterol | number }}</strong>
        mmol/l</span
      ><br />
      <span
        >LDL:
        <strong>{{ +userCholesterols[0].ldl | number }}</strong> mmol/l</span
      ><br />
      <span
        >HDL:
        <strong>{{ +userCholesterols[0].hdl | number }}</strong> mmol/l</span
      >
    </div>
  </div>

  <div
    class="toggle-header toggle-header-left"
    [routerLink]="['/health-trackers', 'smoking']"
    *ngIf="currentProgramme.config.trackers.indexOf('smoking') > -1"
  >
    <a
      class="toggle-nav toggle-nav-expand"
      [routerLink]="['/health-trackers', 'smoking']"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
    ></a>
    <div class="toggle-header-title">
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'smoking-ban']"></fa-icon
      ></span>
      SMOKING
    </div>
    <div class="values" *ngIf="userSmokings[0]">
      <span>
        <strong>{{ userSmokings[0][userSmokingType] | number }}</strong>
        {{ smokingLabels[userSmokingType] }} per day</span
      ><br />
    </div>
  </div>
  <div
    class="toggle-header toggle-header-left"
    [routerLink]="['/symptom-trackers']"
    *ngIf="currentProgramme.config.trackers.indexOf('symptoms') > -1"
  >
    <a
      class="toggle-nav toggle-nav-expand"
      [routerLink]="['/symptom-trackers']"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
    ></a>
    <div class="toggle-header-title">
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'kidneys']"></fa-icon
      ></span>
      SYMPTOM TRACKERS
    </div>
   
  </div>
</div>
