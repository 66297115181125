<nav class="subnav">
  <a [routerLink]="['/notifications']" class="subnav-prev">
    <span><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
  ></a>
  <h2>
    <span><fa-icon [icon]="['fas', 'watch-fitness']"></fa-icon></span> MY PROFILE
  </h2>
  <a [routerLink]="['/settings']" class="subnav-next">
    <span><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
  ></a>
</nav>

<div class="row">
  <div class="col-md-12">
    <br /><label
      >Upload a photo or
      <a href="#" (click)="$event.preventDefault();resetAvatar()">reset your avatar</a></label
    ><br />

    <form action="" class="goal-form">
      <div class="form-group">
        <div class="custom-file">
          <input
            type="file"
            name="image"
            (change)="fileProgress($event)"
            id="customFile"
            class="custom-file-input"
            #image="ngModel"
            ngModel
          />
          <label class="custom-file-label" for="customFile">Choose file</label>
        </div>
        <div *ngIf="fileUploadProgress">
          Upload progress: {{ fileUploadProgress }}
        </div>
        <div class="image-preview mb-3" *ngIf="previewUrl">
          <img [src]="previewUrl" height="300" />
        </div>
      </div>
      <div class="mb-3" *ngIf="uploadedFilePath">
        {{ uploadedFilePath }}
      </div>

      <hr>
      <div class="form-group text-center">
        <button class="btn btn-primary" (click)="onSubmit()" [disabled]="!image.value">Submit</button>
      </div>
    </form>
  </div>
</div>

