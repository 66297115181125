<div [innerHTML]="dragAndDrop.instructions | safeHtml"></div>

<div class="select-item-container" *ngFor="let dragItem of dragItems">
  <div
    [ngClass]="{'select-item' : dragItem.state,
        'select-item-correct' : (dragItem.state === 'selectedCorrect' || dragItem.state === 'unselectedCorrect'),
        'select-item-incorrect': dragItem.state === 'selectedIncorrect' ,
        'select-item-unselected':  dragItem.state === 'unselectedIncorrect',
        'select-item-selected':  dragItem.state === 'selected'}"
    (click)="selectItem(dragItem)"
  >
  <span class="icon-wrap"  *ngIf="(dragItem.state === 'selectedCorrect' || dragItem.state === 'unselectedCorrect')">
    <fa-icon [icon]="['fas', 'check-circle']"></fa-icon>
  </span>
  <span class="icon-wrap"  *ngIf="(dragItem.state === 'selectedIncorrect')">
    <fa-icon  [icon]="['fas', 'times-circle']"></fa-icon>
  </span>
    <div class="select-item-img" *ngIf="dragItem.image">
      <img
        [src]="
          environment.assetUrl + '/asset-thumb/' + dragItem.image + '/310x310'
        "
        width="100%"
      />
    </div>

    <div class="select-item-text">{{ dragItem.text }}</div>
  </div>
</div>
<div class="clearfix"></div>

<br />
<div class="text-center">
  <button
    *ngIf="dragAndDrop.state === 'default' || !dragAndDrop.state" 
    class="btn btn-primary"
    (click)="submitSelect()"
  >
    <i class="fa fa-check"></i> CONFIRM
  </button>
</div>
<div *ngIf="dragAndDrop.state == 'answered'">
  <div class="feedback-title">
    You got {{ correct }} selections correct<span *ngIf="incorrect > 0">
      and {{ incorrect }} selections incorrect</span
    >.
  </div>
  <div><div [innerHTML]="dragAndDrop.completed_text | safeHtml"></div></div>
</div>
