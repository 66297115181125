import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as moment from 'moment';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { BloodPressureService } from 'src/app/services/blood-pressure.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-m3-bp-chart',
  templateUrl: './m3-bp-chart.component.html',
  styleUrls: ['./m3-bp-chart.component.scss']
})
export class M3BpChartComponent implements OnInit {
  @Input() bloodPressures;

  // Line chart
  public lineChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            beginAtZero: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
            suggestedMax: 4,
          },
          gridLines: {
            display: true,
            lineWidth: 1,
            color: '#dddddd',
          },
          scaleLabel:{
            display:true,
            labelString: 'mmHg',
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          }
          
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            lineWidth: 2,
            color: '#dddddd',
          },
          ticks: {
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
            source: 'data',
            maxRotation: 45,
            minRotation: 45,
          },
          scaleLabel: {
            display: true, fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
        },
      ],
    },
    legend: {
      display: true,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
      shaderArea:{

      }
    },
  };
  public lineChartLabels: Label[] = [];
  public lineChartType: ChartType = 'line';
  public lineChartLegend = true;

  public pointBackgroundColorsSystolic = [];
  public pointBorderColorsSystolic = [];
  public pointBackgroundColorsDiastolic = [];
  public pointBorderColorsDiastolic = [];
  public lineChartData: ChartDataSets[] = [
    /*{
      data: [],
      label: 'Resting Heart Rate (bpm)',
      borderWidth: 3,
      borderColor: '#999999',
      fill: false,
      pointRadius: 4,
    },
    */ {
      data: [],
      pointBackgroundColor: this.pointBackgroundColorsSystolic,
      pointBorderColor: this.pointBorderColorsSystolic,
      label: 'Systolic',
      borderColor: '#22B4FC',
      borderWidth: 3,
      fill: false,
      pointRadius: 4,
    }, {
      data: [],
      pointBackgroundColor: this.pointBackgroundColorsDiastolic,
      pointBorderColor: this.pointBorderColorsDiastolic,
      label: 'Diastolic',
      borderColor: '#333',
      borderWidth: 3,
      fill: false,
      pointRadius: 4,
    },
  ];
  public lineChartColors: Color[] = [
    { backgroundColor: '#999999' },
    { backgroundColor: '#22B4FC' },
  ];
  constructor(private userbloodPressueService: BloodPressureService) {}

  ngOnInit(): void {

    /*let range = +this.userbloodPressueService.get('green').replace(/^\D+/g, '');

   // this.lineChartOptions.plugins.shaderArea.rangeStart = +ranges[1];
    this.lineChartOptions.plugins.shaderArea.rangesStart = range;

    BaseChartDirective.registerPlugin({
      id: 'shaderArea',
      beforeDraw(chart, easing, options) {
        const ctx = chart.ctx;
        const chartArea = chart.chartArea;
        var scale = this.scale;

        ctx.save();
        ctx.fillStyle = 'rgba(147, 208, 147 ,0.4)';
        let yAxe = chart['scales'][chart.config.options.scales.yAxes[0].id];
        let rangesStart = yAxe.getPixelForValue(options.rangesStart);
        let rangesEnd = yAxe.getPixelForValue(yAxe.min);
        ctx.fillRect(
          chartArea.left,
          rangesStart,
          chartArea.right - chartArea.left,
          rangesEnd - rangesStart
        );
        ctx.restore();
      },
    });
    // end
*/
    this.setChartData();
  }

  setChartData() {
    this.lineChartData[0].data = [];
    this.lineChartData[1].data = [];
    //this.lineChartData[1].data = [];
    // setup chart data
    this.bloodPressures.forEach((bloodPressure, vo2key) => {
      this.lineChartLabels.push(
        moment(bloodPressure.created).format('DD-MM-YY')
      );

      this.lineChartData[0].data.push(bloodPressure.systolic);
      this.pointBackgroundColorsSystolic.push(
        environment.ragColors[
          this.userbloodPressueService.getSystolicRag(bloodPressure.systolic)
        ]
      );
      this.pointBorderColorsSystolic.push(
        environment.ragColors[
          this.userbloodPressueService.getSystolicRag(bloodPressure.systolic)
        ]
      );

      this.lineChartData[1].data.push(bloodPressure.diastolic);
      this.pointBackgroundColorsDiastolic.push(
        environment.ragColors[
          this.userbloodPressueService.getDiastolicRag(bloodPressure.diastolic)
        ]
      );
      this.pointBorderColorsDiastolic.push(
        environment.ragColors[
          this.userbloodPressueService.getDiastolicRag(bloodPressure.diastolic)
        ]
      );
    });
  }

}
