import { forkJoin } from 'rxjs';
import { ProgrammeService } from './../services/programme.service';
import { UserService } from './../services/user.service';
import { AuthService } from './../auth/auth.service';
import { LearningMaterialService } from './../services/learning-material.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  currentUser;
  currentProgramme;
  prevSection;
  nextSection;
  sectionId;
  isFetching;
  section;
  programmeContentSection;
  pages;
  media;

  constructor(
    private learningMaterialService: LearningMaterialService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private router: Router,
    private titleService: Title
  ) { }

  ngOnInit(): void {


    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });
    }

    //
    this.route.params.subscribe((params) => {
      this.prevSection = null;
      this.nextSection = null;
      this.sectionId = this.route.snapshot.params.sectionId;
      this.isFetching = true;
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (this.currentProgramme) {

          forkJoin({
            section: this.learningMaterialService.fetch(
              this.sectionId
            ),
            pages: this.learningMaterialService.fetchPages(
              this.sectionId
            ),
            programmeContent: this.programmeService.getProgrammeContent(
              this.currentProgramme.programme_id
            ),
          }).subscribe((responseData) => {
            this.isFetching = false;
            this.section = responseData.section;

            // can they access?
            if (
              this.section.status === 'unavailable' &&
              this.currentUser.is_admin == 0
            ) {
              this.router.navigate(['/dashboard']);
            }

            this.pages = responseData.pages;

            // do prev and next
            const sections = responseData.programmeContent.help_sections;
            sections.forEach((section, index) => {
              if (section.content_section_id == this.sectionId) {
                this.programmeContentSection = section;
                // setHtmlTitle($scope.programmeBoosterSession.booster_session_title );

                if (sections[index - 1]) {
                  this.prevSection =
                  sections[index - 1];
                }
                if (sections[index + 1]) {
                  this.nextSection =
                  sections[index + 1];
                }
              }
            });

            this.titleService.setTitle(
              environment.title +
                ' | Help | ' +
                this.section.title
            );
          });


        }
      });
    });
  }

}
