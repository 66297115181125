import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class DragAndDropService {
  constructor(private http: HttpClient) {}

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + '/drag_and_drops/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchDragItems(dragAndDropId) {
    let searchParams = new HttpParams();

    searchParams = searchParams.append('drag_and_drop_id', '' + dragAndDropId);
    return this.http
      .get<any>(environment.apiUrl + '/drag_items', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          let dragItems = responseData._embedded.drag_items;
          const originalOrder = [];
          dragItems.forEach((di, index) => {
            dragItems[index].state = 'default';
            originalOrder.push(di);
          });
          // shuffle up
          dragItems = this.shuffle(dragItems);

          return dragItems;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
  fetchDropItems(dragAndDropId) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('drag_and_drop_id', '' + dragAndDropId);
    return this.http
      .get<any>(environment.apiUrl + '/drop_items', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          let dropItems = responseData._embedded.drop_items;
          const originalOrder = [];
          dropItems.forEach((di, index) => {
            dropItems[index].state = 'default';
            originalOrder.push(di);
          });
          // shuffle up
          dropItems = this.shuffle(dropItems);
          return dropItems;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  shuffle(a) {
    let j;
    let x;
    let i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(this.cryptoRandom() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  }

  cryptoRandom() {
    const typedArray = new Uint8Array(1);
    const randomValue = crypto.getRandomValues(typedArray)[0];
    const randomFloat = randomValue / Math.pow(2, 8);
    return randomFloat;
  }
}
