import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './../auth/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AwardService {
  userId;
  endPoint = '/awards';
  cacheTime = new Date();
  cacheLifetime = 3 * 60 * 1000;

  allAwards: any = null;
  awardsByStatus: any = {};

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userId = user.id;
      }
    });
  }

  fetchAll(): Observable<any> {
    if (!this.userId) {
      return;
    }

    const now = new Date();

    if (
      !this.allAwards ||
      Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime
    ) {
      this.cacheTime = new Date();
      let searchParams = new HttpParams();

      searchParams = searchParams.append('user_id', this.userId);
      searchParams = searchParams.append('sort', 'created');
      searchParams = searchParams.append('order', 'DESC');
      this.allAwards = this.http
        .get<any>(environment.apiUrl + this.endPoint, {
          params: searchParams,
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            return responseData._embedded.awards;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.allAwards;
  }

  getAwards(status): Observable<any> {
    if (!this.userId) {
      return;
    }

    const now = new Date();

    if (
      !this.awardsByStatus[status] ||
      Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime
    ) {
      this.cacheTime = new Date();
      let searchParams = new HttpParams();

      searchParams = searchParams.append('user_id', this.userId);
      searchParams = searchParams.append('notified', '' + status);
      searchParams = searchParams.append('sort', 'created');
      searchParams = searchParams.append('order', 'DESC');
      this.awardsByStatus[status] = this.http
        .get<any>(environment.apiUrl + this.endPoint, {
          params: searchParams,
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            return responseData._embedded.awards;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.awardsByStatus[status];
  }

  setStatus(awardId, status) {
    this.clearCache();
    const postData = {
      notified: status,
    };
    return this.http
      .patch<{ name: string }>(
        environment.apiUrl + this.endPoint + '/' + awardId,
        postData,
        {
          observe: 'response',
        }
      )
      .pipe(
        map((responseData) => {
          return responseData.body;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  clearCache() {
    this.allAwards = null;
    this.awardsByStatus = {};
  }
}
