import { ProgrammeService } from './../services/programme.service';
import { UserService } from './../services/user.service';
import { AuthService } from './../auth/auth.service';
import { forkJoin } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { WorkoutsService } from '../services/workouts.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-strength',
  templateUrl: './strength.component.html',
  styleUrls: ['./strength.component.scss'],
})
export class StrengthComponent implements OnInit {
  currentUser;
  currentProgramme;
  userWorkouts = [];
  userWorkout;
  isFetching = false;
  errors = [];
  showAllRows = false;
  environment = environment;

  toggleRow = {
    entry: false,
  };
  days = [];
  daysActive = 0;
  todayFormatted;
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private workoutsService: WorkoutsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.isFetching = true;

    // get monday this week
    const monday = moment().startOf('isoWeek');
    this.todayFormatted = moment().format('YYYY-MM-DD');
    this.days.push(monday.format('YYYY-MM-DD'));
    for (let x = 0; x < 6; x++) {
      this.days.push(monday.add(1, 'days').format('YYYY-MM-DD'));
    }

    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (this.currentProgramme) {
          // this.barChartColors[0].backgroundColor = this.currentProgramme.config.primaryColor;

          forkJoin({
            userWorkouts: this.workoutsService.fetchAll(),
          }).subscribe(
            (responseData) => {
              this.isFetching = false;
              this.userWorkouts = responseData.userWorkouts;
              if (this.userWorkouts.length > 0) {
                this.userWorkout = this.userWorkouts[0];
                const workoutDays = [];
                this.userWorkouts.forEach((workout) => {
                  if (workout.workout_date >= this.days[0] && workout.workout_date <= this.days[6] ) {
                    if (workoutDays.indexOf(workout.workout_date) === -1) {
                      workoutDays.push(workout.workout_date);
                    }
                  }
                });

                this.daysActive = workoutDays.length;
              }
              // this.setChartData(this.userWorkouts);
              // end
            },
            (error) => {
              this.isFetching = false;
              this.errors.push(error.message);
            }
          );
        }
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });
    }
  }

  onRemove(userWorkout) {
    this.workoutsService.delete(userWorkout.user_symptom_id).subscribe(
      (responseData) => {
        const index = this.userWorkouts.indexOf(userWorkout);
        this.userWorkouts.splice(index, 1);
        // this.setChartData(this.userWorkouts);
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
