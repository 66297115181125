import { ActivatedRoute, Router } from '@angular/router';
import { CommentService } from './../../services/comment.service';
import { forkJoin, Subject } from 'rxjs';
import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { environment } from './../../../environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { faThList } from '@fortawesome/pro-solid-svg-icons';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.scss'],
})
export class ForumComponent implements OnInit {
  @ViewChild('forumSearchForm', { static: false }) forumSearchForm: NgForm;
  environment = environment;
  topics = [];
  topicsCount = [];
  currentUser;
  currentProgramme;
  isFetching;
  page = 1;
  perPage = 20;
  category = 'all';
  unread;
  commentViews = {};
  commentViewsClicked = {};
  commentLikes = {};
  topPage = 1;
  commentIds = [];
  commentIdReplies = [];
  yourTopics:any = false;
  yourReplies:any = false;
  userTopics = [];
  userReplyTopics = [];
  loadingTopics = false;
  commentReplies:any;
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private commentService: CommentService,
    private route: ActivatedRoute,
    private titleService: Title,
    private router: Router
  ) {}

  ngOnInit(): void {
    // are we logged in?
    this.isFetching = true;
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (programmeData) {
          this.route.queryParams.subscribe((params) => {
            this.yourTopics = null;
            this.yourReplies = null;
            this.category = 'all';
            if (params['page']) {
              this.page = +params['page'];
            }
            if (params['category']) {
              this.category = params['category'];
            }
            if (params['yourTopics'] == '1') {
              this.yourTopics = params['yourTopics'];
            }
            if (params['yourReplies'] == '1') {
              this.yourReplies = params['yourReplies'];
            }
            // get the stuff again

            this.fetchData();
          });
        }
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });
    }
  }

  fetchData() {
    this.loadingTopics = true;
    forkJoin({
      comments: this.commentService.fetchForumTopics(
        this.currentProgramme.programme_id,
        this.category,
        this.page,
        this.perPage
      ),
      countComments: this.commentService.fetchForumTopics(
        this.currentProgramme.programme_id,
        this.category,
        null,
        null,
        true
      ),
      // userTopics: this.commentService.fetchUserTopicsForum(),
      commentViews: this.commentService.fetchCommentViews(),
      commentLikes: this.commentService.fetchCommentLikes(),
      commentReplies: this.commentService.fetchGlobalReplies(),
      userTopics: this.commentService.fetchUserTopicsForum(),
    }).subscribe(
      (responseData) => {

        this.commentReplies = responseData.commentReplies;
        let theTopics = responseData.comments;

        

 
        this.topics = [];
        this.topicsCount = [];

        const topicReplyIds = [];

        responseData.commentReplies.forEach((com) => {
          topicReplyIds.push(com.comment_type_ref);
        });

        theTopics.forEach((topic, index) => {
          this.commentIds.push(topic.comment_id);
          this.commentIdReplies[topic.comment_id] = [];
          if (topic.modified) {
            theTopics[index].touched = topic.modified;
          } else {
            theTopics[index].touched = topic.created;
          }

          let addToComments = true;
          /*if (this.yourTopics) {
            if (topic.user_id != this.currentUser.user_id) {
              addToComments = false;
            }
          }

          if (this.yourReplies) {
            if (topicReplyIds.indexOf(''+topic.comment_id) === -1) {
             addToComments = false;
            }
          }*/

          if (addToComments) {
            this.topics.push(topic);
          }
        });

        if(this.yourReplies==1){
          theTopics = responseData.commentReplies;
        }
        if(this.yourTopics ==1){
          theTopics = responseData.userTopics;
          }

        // test
        this.topicsCount = responseData.countComments;

        if(this.yourTopics==1){
          this.topicsCount = responseData.userTopics;
          this.topics = responseData.userTopics;
        }

        if(this.yourReplies==1){
          this.topicsCount = responseData.commentReplies;
          this.topics = responseData.commentReplies;
        }

        this.topPage = Math.ceil(this.topicsCount.length / this.perPage);

        this.isFetching = false;

        responseData.commentViews.forEach((commentView) => {
          this.commentViews[commentView.comment_id] = true;
        });

        responseData.commentLikes.forEach((commentLike) => {
          this.commentLikes[commentLike.comment_id] =
            commentLike.user_comment_like_id;
        });

        this.loadingTopics = false;

        if (this.category) {
          this.titleService.setTitle(
            environment.title +
              ' | Forum | ' +
              this.category[0].toUpperCase() +
              this.category.substring(1)
          );
        }
      },
      (error) => {
        this.isFetching = false;

        this.loadingTopics = false;
        console.log(error);
      }
    );
  }
  checkReplies(comment) {
    let read = true;
    comment.reply_ids.forEach((cr, i) => {
      if (!this.commentViews[cr]) {
        read = false;
      }
    });

    return read;
  }

  getCategory(comment) {
    let category = comment.comment_type_ref;
    category = category.replace('topic', '');

    if (!category) {
      return 'General';
    } else {
      return category;
    }
  }

  onPrevPage() {
    this.page--;
    window.scrollTo(0, 0);
  }
  onNextPage() {
    this.page++;
    window.scrollTo(0, 0);
  }

  sortBy(topics, prop: string, reverse = false) {
    let sorted = topics.sort((a, b) =>
      (a[prop] > b[prop]) ? 1 : a[prop] === b[prop] ? 0 : -1
    );
    if (reverse) {
      sorted = sorted.reverse();
    }


    // check for pinned
    const returnComments = [];
    sorted.forEach((comment) => {
      if(comment.status == 3 || comment.status == 4){
        returnComments.push(comment);
      }
    });
    sorted.forEach((comment) => {
      if(comment.status != 3 && comment.status != 4){
        returnComments.push(comment);
      }
    });

    return returnComments;
  }

  getSubject(comment) {
    if (comment.subject) {
      return comment.subject;
    } else {
      if (comment.comment.length < 129) {
        return comment.comment;
      } else {
        return comment.comment.substr(0, 128) + '\u2026';
      }
    }
  }

  getTotal(page, perPage, total) {
    if (page * perPage > total) {
      return total;
    } else {
      return page * perPage;
    }
  }

  onSubmitForumSearch() {
    // this.router.navigate
    this.router.navigate(['/chat/forum/search'], {
      queryParams: { q: this.forumSearchForm.value.query },
    });
  }
}
