<nav class="subnav">
    <a [routerLink]="['/activity', 'minutes']" aria-label="Go to target" class="subnav-prev"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
    ></a>
    <h2>
      <span class="icon-wrap"
        ><fa-icon [icon]="['far', 'stopwatch']"></fa-icon
      ></span>
      UPDATE WORKOUT
    </h2>
  
   
  </nav>
  <div *ngIf="isFetching">
    <app-loading-spinner [inline]="1"></app-loading-spinner>
  </div>
  
  <div class="toggle-container">
  
    <div class="toggle-content">
      <form
        name="minsForm"
        class="bs-form tracker-form"
        (ngSubmit)="onSubmitMinsForm()"
        #minsForm="ngForm"
      >
        <p class="instruction">
          
        </p>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="weight">MINUTES</label>
              <input
                type="number"
                style="font-size: 28px"
                name="mins"
                class="form-control input-tracker input-tracker-xsm"
                placeholder="e.g. 15"
                id="mins"
                [ngModel]="workout?.minutes"
                min="0"
                max="1000000000"
                autocomplete="off"
                ng-required=""
                #mins="ngModel"
              />
              <div
                class="alert alert-danger"
                *ngIf="mins.touched && mins.invalid"
              >
                This must be a number below 1000000000.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="number">DATE</label>
              <input
                name="date"
                type="text"
                placeholder="DD/MM/YYYY"
                class="form-control input-tracker input-tracker-sm"
                bsDatepicker
                autocomplete="off"
                [bsConfig]="{
                  dateInputFormat: 'DD/MM/YYYY',
                  containerClass: 'theme-cv',
                  showWeekNumbers: false
                }"
                [(ngModel)]="date"
                required
                [bsValue]="date"
                [maxDate]="date"
                #dateInput="ngModel"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="weight">EFFORT</label>
              <input
                type="number"
                style="font-size: 28px"
                name="effort"
                class="form-control input-tracker input-tracker-xsm"
                placeholder="e.g. 5"
                id="effort"
                [ngModel]="workout?.effort"
                min="0"
                max="1000000000"
                autocomplete="off"
                ng-required=""
                #effort="ngModel"
              />
              <p class="instructions">On a scale of 0 - 10</p>
              <div
                class="alert alert-danger"
                *ngIf="effort.touched && effort.invalid"
              >
                This must be a number below 1000000000.
              </div>
            </div>
          </div>
  
          <div class="col-md-6">
            <div class="form-group">
              <label for="exercise">EXERCISE</label>
              <select
                type="number"
                name="exercise"
                class="custom-select custom-select-lg"
                id="exercise"
                [ngModel]="workout?.exercise_id"
                ng-required=""
                #exercise="ngModel"
              >
                <option [ngValue]="null">Please choose an exercise</option>
                <option
                  *ngFor="let exercise of exercises"
                  [ngValue]="exercise.exercise_id"
                >
                  {{ exercise.name }}
                </option>
              </select>
              <div
                class="alert alert-danger"
                *ngIf="exercise.touched && exercise.invalid"
              >
                This must be a number below 1000000000.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="comments">Comments</label>
              <textarea
                name="comments"
                class="form-control"
                id="comments"
                [ngModel]="workout?.comments"
                #comments="ngModel"
                placeholder="You can enter a comment here"
              >
              </textarea>
              <div
                class="alert alert-danger"
                *ngIf="comments.touched && comments.invalid"
              >
                Please enter a comment.
              </div>
            </div>
          </div>
        </div>
        <div class="tracker-submit-container">
          <button
            type="submit"
            role="button"
            class="btn btn-primary btn-lg btn-white"
          >
            UPDATE WORKOUT
          </button>
        </div>
      </form>
  
      <div class="text-center mt-5">
          <button class="btn btn-danger btn-sm" (click)="onDelete()">
            Delete &nbsp;
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'trash']"></fa-icon
            ></span>
          </button>
        </div>
  
    </div>
    <app-section-nav
      [section]="'activity'"
      [active]="'minutes'"
    ></app-section-nav>
  </div>
  