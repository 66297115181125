import { FooterNavComponent } from './footer-nav/footer-nav.component';
import { environment } from 'src/environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import { interceptorProviders } from './interceptors';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SidebarComponent } from './sidebar/sidebar.component';

import { CookieService } from 'ngx-cookie-service';
import { AuthComponent } from './auth/auth.component';
import { HomeComponent } from './home/home.component';
import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardIndividualComponent } from './dashboard/dashboard-individual/dashboard-individual.component';
import { FilterObjectArray } from './pipes/filter-object-array.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false;
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';

import { DndModule } from 'ngx-drag-drop';
import { TimeagoModule } from 'ngx-timeago';
import { AutosizeModule } from 'ngx-autosize';
import { LinkyModule } from 'ngx-linky';

import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';

import { NgxSliderModule } from '@angular-slider/ngx-slider';

import {
  faCheck as fasCheck,
  faTimes as fasTimes,
  faBolt as fasBolt,
  faCheckCircle as fasCheckCircle,
  faArrowCircleRight as fasArrowCircleRight,
  faArrowCircleLeft as fasArrowCircleLeft,
  faGraduationCap as fasGraduationCap,
  faFemale as fasFemale,
  faMale as fasMale,
  faUtensils as fasUtensils,
  faMobile as fasMobile,
  faUser as fasUser,
  faUsers as fasUsers,
  faBullseye as fasBullseye,
  faStar as fasStar,
  faEdit as fasEdit,
  faCircle as fasCircle,
  faChartBar as fasChartBar,
  faChartLine as fasChartLine,
  faExclamationCircle as fasExclamationCircle,
  faHandPointer as fasHandPointer,
  faCalendar as fasCalendar,
  faClock as fasClock,
  faDownload as fasDownload,
  faTint as fasTint,
  faMedkit as fasMedkit,
  faHeart as fasHeart,
  faBan as fasBan,
  faTrophy as fasTrophy,
  faBook as fasBook,
  faSearch as fasSearch,
  faHome as fasHome,
  faComments as fasComments,
  faMapSigns as fasMapSigns,
  faInfo as fasInfo,
  faPlus as fasPlus,
  faSync as fasSync,
  faHandshake as fasHandshake,
  faChevronCircleRight as fasChevronCircleRight,
  faChevronCircleLeft as fasChevronCircleLeft,
  faChevronCircleUp as fasChevronCircleUp,
  faChevronCircleDown as fasChevronCircleDown,
  faBars as fasBars,
  faTrash as fasTrash,
  faTimesCircle as fasTimesCircle,
  faQuestionCircle as fasQuestionCircle,
  faMeh as fasMeh,
  faChevronUp as fasChevronUp,
  faChevronDown as fasChevronDown,
  faBell as fasBell,
  faGavel as fasGavel,
  faComment as fasComment,
  faPencil as fasPencil,
  faCog as fasCog,
  faUserMd as fasUserMd,
  faCommentDots as fasCommentDots,
  faFileDownload as fasFileDownload,
  faThumbsUp as fasThumbsUp,
  faExclamationTriangle as fasExclamationTriangle,
  faFlag as fasFlag,
  faIdBadge as fasIdBadge,
  faSignOut as fasSignOut,
  faPlusCircle as fasPlusCircle,
  faArrowRight as fasArrowRight,
  faTicket as fasTicket,
  faSadTear as fasSadTear,
  faCheckSquare as fasCheckSquare,
  faWalking as fasWalking,
  faShoePrints as fasShoePrints,
  faUserFriends as fasUserFriends,
  faMedal as fasMedal,
  faWatchFitness as fasWatchFitness,
  faUserMdChat as fasUserMdChat,
  faUserHeadset as fasUserHeadset,
  faTachometerAlt as fasTachometerAlt,
  faHeartbeat as fasHeartbeat,
  faSmokingBan as fasSmokingBan,
  faQuestion as fasQuestion,
  faEllipsisH as fasEllipsisH,
  faSpinner as fasSpinner,
  faArrows as fasArrows,
  faKidneys as fasKidneys,
  faBullseyeArrow as fasBullseyeArrow,
  faRunning as fasRunning,
  faBookmark as fasBookmark,
  faArrowUp as fasArrowUp,
  faArrowDown as fasArrowDown
} from '@fortawesome/pro-solid-svg-icons';

import {
  faStar as farStar,
  faSquare as farSquare,
  faStopwatch as farStopwatch,
  faCircle as farCircle,
  faCalendarEdit as farCalendarEdit,
  faArrowCircleRight as farArrowCircleRight,
  faArrowCircleLeft as farArrowCircleLeft,
  faAllergies as farAllergies,
  faAlarmClock as farAlarmClock,
  faStomach as farStomach,
  faSnooze as farSnooze,
  faBone as farBone,
  faHeadSideBrain as farHeadSideBrain,
  faBedAlt as farBedAlt,
  faFistRaised as farFistRaised,
  faLungs as farLungs,
  faTired as farTired,
  faSocks as farSocks,
  faToilet as farToilet,
  faTemperatureFrigid as farTemperatureFrigid,
  faDumbbell as farDumbbell,
  faThumbtack as farThumbtack,
  faHourglassHalf as farHourglassHalf,
  faCouch as farCouch,
  faRunning as farRunning,
  faBed as farBed,
  faVideo as farVideo
} from '@fortawesome/pro-regular-svg-icons';

import {
  faGraduationCap as falGraduationCap,
  faQuestion as falQuestion,
  faChevronCircleUp as falChevronCircleUp,
  faChevronCircleDown as falChevronCircleDown,
  faChevronCircleLeft as falChevronCircleLeft,
  faSquare as falSquare

} from '@fortawesome/pro-light-svg-icons';
import {
  faFacebookSquare,
  faTwitterSquare,
} from '@fortawesome/free-brands-svg-icons';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { StepsComponent } from './steps/steps.component';
import { ChartsModule } from 'ng2-charts';
import { SectionNavComponent } from './section-nav/section-nav.component';
import { ChallengesComponent } from './challenges/challenges.component';
import { CholesterolComponent } from './health-trackers/cholesterol/cholesterol.component';
import { OrdinalPipe } from './pipes/ordinal.pipe';
import { DateToIsoPipe } from './pipes/date-to-iso.pipe';

import { NavbarComponent } from './navbar/navbar.component';

export function provideSwal() {
  return import('sweetalert2/src/sweetalert2.js'); // instead of import('sweetalert2')
}
import { CustomFormsModule } from 'ngx-custom-validators';
import { ShapeComponent } from './health-trackers/shape/shape.component';
import { HealthyEatingComponent } from './health-trackers/healthy-eating/healthy-eating.component';
import { Hba1cComponent } from './health-trackers/hba1c/hba1c.component';
import { BloodPressureComponent } from './health-trackers/blood-pressure/blood-pressure.component';
import { SmokingComponent } from './health-trackers/smoking/smoking.component';
import { SmokingSettingsComponent } from './health-trackers/smoking/smoking-settings/smoking-settings.component';
import { LearningSessionIndexComponent } from './learning-sessions/learning-session-index/learning-session-index.component';
import { LearningSessionStepComponent } from './learning-sessions/learning-session-step/learning-session-step.component';
import { StepVideoComponent } from './booster-sessions/step-templates/step-video/step-video.component';
import { Nl2brPipe } from './pipes/nl2br.pipe';
import { StepQuizComponent } from './booster-sessions/step-templates/step-quiz/step-quiz.component';
import { StepDragAndDropSelectionComponent } from './booster-sessions/step-templates/step-drag-and-drop-selection/step-drag-and-drop-selection.component';
import { StepQuestionnaireComponent } from './booster-sessions/step-templates/step-questionnaire/step-questionnaire.component';
import { StepDragAndDropDefaultComponent } from './booster-sessions/step-templates/step-drag-and-drop-default/step-drag-and-drop-default.component';
import { StepDragAndDropRankingComponent } from './booster-sessions/step-templates/step-drag-and-drop-ranking/step-drag-and-drop-ranking.component';
import { StepDragAndDropMatchedComponent } from './booster-sessions/step-templates/step-drag-and-drop-matched/step-drag-and-drop-matched.component';
import { BoosterSessionStepComponent } from './booster-sessions/booster-session-step/booster-session-step.component';
import { BoosterSessionIndexComponent } from './booster-sessions/booster-session-index/booster-session-index.component';
import { DecisionMakerComponent } from './decision-maker/decision-maker.component';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';

import { DecisionMakerSavedComponent } from './decision-maker/decision-maker-saved/decision-maker-saved.component';
import { DecisionMakerEditComponent } from './decision-maker/decision-maker-edit/decision-maker-edit.component';
import { EducationalReadingComponent } from './educational-reading/educational-reading.component';
import { EducationalReadingPageComponent } from './educational-reading/educational-reading-page/educational-reading-page.component';
import { HelpComponent } from './help/help.component';
import { HelpPageComponent } from './help/help-page/help-page.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { AboutComponent } from './about/about.component';
import { LegalComponent } from './legal/legal.component';
import { ProfileComponent } from './profile/profile.component';
import { SettingsComponent } from './settings/settings.component';
import { PersonalDetailsComponent } from './settings/personal-details/personal-details.component';
import { AccountDetailsComponent } from './settings/account-details/account-details.component';
import { TargetComponent } from './target/target.component';
import { AchievementsComponent } from './achievements/achievements.component';
import { DeviceComponent } from './device/device.component';
import { AskTheExpertComponent } from './chat/ask-the-expert/ask-the-expert.component';
import { TechnicalSupportComponent } from './chat/technical-support/technical-support.component';
import { ChallengesIndividualComponent } from './challenges/challenges-individual/challenges-individual.component';
import { FriendsAndFamilyComponent } from './friends-and-family/friends-and-family.component';
import { FriendsAndFamilyCommentsComponent } from './friends-and-family/friends-and-family-comments/friends-and-family-comments.component';
import { MinutesComponent } from './minutes/minutes.component';
import { ForumComponent } from './chat/forum/forum.component';
import { ForumViewComponent } from './chat/forum/forum-view/forum-view.component';
import { SearchComponent } from './search/search.component';
import { ForumCreateTopicComponent } from './chat/forum/forum-create-topic/forum-create-topic.component';
import { UploadAvatarComponent } from './profile/upload-avatar/upload-avatar.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { MobileNavComponent } from './mobile-nav/mobile-nav.component';
import { FriendsAndFamilyInvitesComponent } from './friends-and-family/friends-and-family-invites/friends-and-family-invites.component';
import { RegisterComponent } from './register/register.component';
import { PasswordCountPipe } from './pipes/password-count.pipe';
import { AssessmentsComponent } from './assessments/assessments.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { AddToHomescreenComponent } from './add-to-homescreen/add-to-homescreen.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AgreeTermsComponent } from './agree-terms/agree-terms.component';
import { SessionsComponent } from './sessions/sessions.component';
import { OfflineComponent } from './offline/offline.component';
import { NoProgrammeComponent } from './no-programme/no-programme.component';
import { ProgrammeOfflineComponent } from './programme-offline/programme-offline.component';
import { AwardComponent } from './award/award.component';
import { ExtraSessionsComponent } from './learning-sessions/extra-sessions/extra-sessions.component';
import { RegisterBuddyComponent } from './register/register-buddy/register-buddy.component';
import { DashboardTeamComponent } from './dashboard/dashboard-team/dashboard-team.component';
import { DashboardLearningComponent } from './dashboard/dashboard-learning/dashboard-learning.component';
import { ChallengeArchiveComponent } from './challenges/challenge-archive/challenge-archive.component';
import { ChallengeBandArchiveComponent } from './challenges/challenge-band-archive/challenge-band-archive.component';
import { ChallengeGlobalArchiveComponent } from './challenges/challenge-global-archive/challenge-global-archive.component';
import { DateFormatterPipe } from './pipes/date-formatter.pipe';
import { HealthTrackerIndexComponent } from './health-trackers/health-tracker-index/health-tracker-index.component';
import { ChatIndexComponent } from './chat/chat-index/chat-index.component';
import { ActivityIndexComponent } from './activity-index/activity-index.component';

import {
  NgcCookieConsentModule,
  NgcCookieConsentConfig,
} from 'ngx-cookieconsent';
import { ContinueAccessComponent } from './continue-access/continue-access.component';
import { SymptomTrackerComponent } from './symptom-tracker/symptom-tracker.component';
import { StrengthComponent } from './strength/strength.component';
import { StrengthAddComponent } from './strength/strength-add/strength-add.component';
import { StrengthViewComponent } from './strength/strength-view/strength-view.component';
import { StrengthAddExerciseComponent } from './strength/strength-add-exercise/strength-add-exercise.component';
import { StrengthEditComponent } from './strength/strength-edit/strength-edit.component';
import { SymptomTrackerIndexComponent } from './symptom-tracker/symptom-tracker-index/symptom-tracker-index.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { ForumSearchComponent } from './chat/forum/forum-search/forum-search.component';
import { AssessmentsBoosterComponent } from './assessments/assessments-booster/assessments-booster.component';
import { RegisterExerciseProgrammeComponent } from './register-exercise-programme/register-exercise-programme.component';
import { NotificationSettingControlComponent } from './notification-setting-control/notification-setting-control.component';
import { WelcomeEpComponent } from './welcome-ep/welcome-ep.component';
import { DashboardEpComponent } from './dashboard/dashboard-ep/dashboard-ep.component';
import { TargetEpComponent } from './target-ep/target-ep.component';
import { SittingComponent } from './sitting/sitting.component';
import { MinsToHoursPipe } from './pipes/mins-to-hours.pipe';
import { M3DashboardComponent } from './m3/m3-dashboard/m3-dashboard.component';
import { M3IconComponent } from './m3/m3-icon/m3-icon.component';
import { M3HeaderComponent } from './m3/m3-header/m3-header.component';
import { WorkoutsComponent } from './workouts/workouts.component';
import { WorkoutsEditWorkoutComponent } from './workouts/workouts-edit-workout/workouts-edit-workout.component';
import { M3FitnessComponent } from './m3/m3-fitness/m3-fitness.component';
import { Vo2maxTableComponent } from './m3/tables/vo2max-table/vo2max-table.component';
import { M3WalkingComponent } from './m3/m3-walking/m3-walking.component';
import { M3NonSteppingComponent } from './m3/m3-non-stepping/m3-non-stepping.component';
import { M3PhysicalAbilityComponent } from './m3/m3-physical-ability/m3-physical-ability.component';
import { M3SittingComponent } from './m3/m3-sitting/m3-sitting.component';
import { M3SleepComponent } from './m3/m3-sleep/m3-sleep.component';
import { M3WelcomeComponent } from './m3/m3-welcome/m3-welcome.component';
import { M3MobileNavbarComponent } from './m3/m3-mobile-navbar/m3-mobile-navbar.component';
import { M3FitnessChartComponent } from './m3/m3-fitness-chart/m3-fitness-chart.component';
import { M3WalkingChartComponent } from './m3/m3-walking-chart/m3-walking-chart.component';
import { M3PhysicalAbilityChartComponent } from './m3/m3-physical-ability-chart/m3-physical-ability-chart.component';
import { M3SittingChartComponent } from './m3/m3-sitting-chart/m3-sitting-chart.component';
import { M3SleepChartComponent } from './m3/m3-sleep-chart/m3-sleep-chart.component';
import { M3NonSteppingWeekChartComponent } from './m3/m3-non-stepping-week-chart/m3-non-stepping-week-chart.component';
import { M3NonSteppingChartComponent } from './m3/m3-non-stepping-chart/m3-non-stepping-chart.component';
import { M3WalkingNonSteppingChartComponent } from './m3/m3-walking-non-stepping-chart/m3-walking-non-stepping-chart.component';
import { M3HealthDataComponent } from './m3/m3-health-data/m3-health-data.component';
import { M3GlucoseComponent } from './m3/m3-glucose/m3-glucose.component';
import { M3KidneyComponent } from './m3/m3-kidney/m3-kidney.component';
import { M3WeightComponent } from './m3/m3-weight/m3-weight.component';
import { M3LiverComponent } from './m3/m3-liver/m3-liver.component';
import { M3HeartComponent } from './m3/m3-heart/m3-heart.component';
import { M3WellbeingComponent } from './m3/m3-wellbeing/m3-wellbeing.component';
import { M3AlbuminChartComponent } from './m3/m3-albumin-chart/m3-albumin-chart.component';
import { M3EgfrChartComponent } from './m3/m3-egfr-chart/m3-egfr-chart.component';
import { M3AltChartComponent } from './m3/m3-alt-chart/m3-alt-chart.component';
import { M3Fib4ChartComponent } from './m3/m3-fib4-chart/m3-fib4-chart.component';
import { M3Phq9ChartComponent } from './m3/m3-phq9-chart/m3-phq9-chart.component';
import { M3PaidChartComponent } from './m3/m3-paid-chart/m3-paid-chart.component';
import { M3MbtqChartComponent } from './m3/m3-mbtq-chart/m3-mbtq-chart.component';
import { M3Hba1cChartComponent } from './m3/m3-hba1c-chart/m3-hba1c-chart.component';
import { M3GlucoseAverageChartComponent } from './m3/m3-glucose-average-chart/m3-glucose-average-chart.component';
import { M3GlucoseInRangeChartComponent } from './m3/m3-glucose-in-range-chart/m3-glucose-in-range-chart.component';
import { M3GlucoseBelowRangeChartComponent } from './m3/m3-glucose-below-range-chart/m3-glucose-below-range-chart.component';
import { M3BmiChartComponent } from './m3/m3-bmi-chart/m3-bmi-chart.component';
import { M3WaistChartComponent } from './m3/m3-waist-chart/m3-waist-chart.component';
import { M3WeightChartComponent } from './m3/m3-weight-chart/m3-weight-chart.component';
import { M3BpChartComponent } from './m3/m3-bp-chart/m3-bp-chart.component';
import { M3CholesterolChartComponent } from './m3/m3-cholesterol-chart/m3-cholesterol-chart.component';
import { ProgrammeSetupEpComponent } from './programme-setup/programme-setup-ep/programme-setup-ep.component';
import { SleepComponent } from './sleep/sleep.component';
import { ProgrammeSetupGu1deComponent } from './programme-setup/programme-setup-gu1de/programme-setup-gu1de.component';
import { Eq5dComponent } from './assessments/eq5d/eq5d.component';
import { M3FrailtyComponent } from './m3/m3-frailty/m3-frailty.component';
import { M3FallsChartComponent } from './m3/m3-frailty/m3-falls-chart/m3-falls-chart.component';
import { M3UserTurnsChartComponent } from './m3/m3-frailty/m3-user-turns-chart/m3-user-turns-chart.component';
import { M3UserShortFesChartComponent } from './m3/m3-frailty/m3-user-short-fes-chart/m3-user-short-fes-chart.component';
import { M3Sss8ChartComponent } from './m3/m3-frailty/m3-sss8-chart/m3-sss8-chart.component';
import { M3DistressChartComponent } from './m3/m3-frailty/m3-distress-chart/m3-distress-chart.component';
import { DashboardApiTestComponent } from './dashboard/dashboard-api-test/dashboard-api-test.component';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieDomain,
  },
  palette: {
    popup: {
      background: '#206ba4',
    },
    button: {
      background: '#fff',
    },
    highlight: { background: '#fff' },
  },
  theme: 'block',
  type: 'opt-in',
  revokable: false,
  law: {
    countryCode: null,
    regionalLaw: false,
  },
};

// disable cookie consent for AUS
if (environment.country === 'AUS') {
  cookieConfig.enabled = false;
}

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    AuthComponent,
    HomeComponent,
    LoadingSpinnerComponent,
    DashboardComponent,
    DashboardIndividualComponent,
    FilterObjectArray,
    StepsComponent,
    SectionNavComponent,
    ChallengesComponent,
    CholesterolComponent,
    OrdinalPipe,
    DateToIsoPipe,
    NavbarComponent,
    ShapeComponent,
    HealthyEatingComponent,
    Hba1cComponent,
    BloodPressureComponent,
    SmokingComponent,
    SmokingSettingsComponent,
    LearningSessionIndexComponent,
    LearningSessionStepComponent,
    SafeHtmlPipe,
    StepVideoComponent,
    Nl2brPipe,
    StepQuizComponent,
    StepDragAndDropSelectionComponent,
    StepQuestionnaireComponent,
    StepDragAndDropDefaultComponent,
    StepDragAndDropRankingComponent,
    StepDragAndDropMatchedComponent,
    BoosterSessionStepComponent,
    BoosterSessionIndexComponent,
    DecisionMakerComponent,
    DecisionMakerSavedComponent,
    DecisionMakerEditComponent,
    EducationalReadingComponent,
    EducationalReadingPageComponent,
    HelpComponent,
    HelpPageComponent,
    NotificationsComponent,
    AboutComponent,
    LegalComponent,
    ProfileComponent,
    SettingsComponent,
    PersonalDetailsComponent,
    AccountDetailsComponent,
    TargetComponent,
    AchievementsComponent,
    DeviceComponent,
    AskTheExpertComponent,
    TechnicalSupportComponent,
    ChallengesIndividualComponent,
    FriendsAndFamilyComponent,
    FriendsAndFamilyCommentsComponent,
    MinutesComponent,
    ForumComponent,
    ForumViewComponent,
    SearchComponent,
    ForumCreateTopicComponent,
    UploadAvatarComponent,
    UserMenuComponent,
    MobileNavComponent,
    FriendsAndFamilyInvitesComponent,
    RegisterComponent,
    PasswordCountPipe,
    AssessmentsComponent,
    WelcomeComponent,
    AddToHomescreenComponent,
    PageNotFoundComponent,
    AgreeTermsComponent,
    FooterNavComponent,
    SessionsComponent,
    OfflineComponent,
    NoProgrammeComponent,
    ProgrammeOfflineComponent,
    AwardComponent,
    ExtraSessionsComponent,
    RegisterBuddyComponent,
    DashboardTeamComponent,
    DashboardLearningComponent,
    ChallengeArchiveComponent,
    ChallengeBandArchiveComponent,
    ChallengeGlobalArchiveComponent,
    DateFormatterPipe,
    HealthTrackerIndexComponent,
    ChatIndexComponent,
    ActivityIndexComponent,
    ContinueAccessComponent,
    SymptomTrackerComponent,
    StrengthComponent,
    StrengthAddComponent,
    StrengthViewComponent,
    StrengthAddExerciseComponent,
    StrengthEditComponent,
    SymptomTrackerIndexComponent,
    ForumSearchComponent,
    AssessmentsBoosterComponent,
    RegisterExerciseProgrammeComponent,
    NotificationSettingControlComponent,
    WelcomeEpComponent,
    DashboardEpComponent,
    TargetEpComponent,
    SittingComponent,
    MinsToHoursPipe,
    M3DashboardComponent,
    M3IconComponent,
    M3HeaderComponent,
    WorkoutsComponent,
    WorkoutsEditWorkoutComponent,
    M3FitnessComponent,
    Vo2maxTableComponent,
    M3WalkingComponent,
    M3NonSteppingComponent,
    M3PhysicalAbilityComponent,
    M3SittingComponent,
    M3SleepComponent,
    M3WelcomeComponent,
    M3MobileNavbarComponent,
    M3FitnessChartComponent,
    M3WalkingChartComponent,
    M3PhysicalAbilityChartComponent,
    M3SittingChartComponent,
    M3SleepChartComponent,
    M3NonSteppingWeekChartComponent,
    M3NonSteppingChartComponent,
    M3WalkingNonSteppingChartComponent,
    M3HealthDataComponent,
    M3GlucoseComponent,
    M3KidneyComponent,
    M3WeightComponent,
    M3LiverComponent,
    M3HeartComponent,
    M3WellbeingComponent,
    M3AlbuminChartComponent,
    M3EgfrChartComponent,
    M3AltChartComponent,
    M3Fib4ChartComponent,
    M3Phq9ChartComponent,
    M3PaidChartComponent,
    M3MbtqChartComponent,
    M3Hba1cChartComponent,
    M3GlucoseAverageChartComponent,
    M3GlucoseInRangeChartComponent,
    M3GlucoseBelowRangeChartComponent,
    M3BmiChartComponent,
    M3WaistChartComponent,
    M3WeightChartComponent,
    M3BpChartComponent,
    M3CholesterolChartComponent,
    ProgrammeSetupEpComponent,
    SleepComponent,
    ProgrammeSetupGu1deComponent,
    Eq5dComponent,
    M3FrailtyComponent,
    M3FallsChartComponent,
    M3UserTurnsChartComponent,
    M3UserShortFesChartComponent,
    M3Sss8ChartComponent,
    M3DistressChartComponent,
    DashboardApiTestComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production, registrationStrategy: 'registerImmediately'
    }),
    FontAwesomeModule,
    SweetAlert2Module.forRoot({
      provideSwal,
    }),
    ChartsModule,
    CustomFormsModule,
    DndModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    TimeagoModule.forRoot(),
    AutosizeModule,
    LinkyModule,
    PopoverModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsAPIKey,
    }),
    AgmDirectionModule,
    TooltipModule.forRoot(),
    NgxSliderModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    RecaptchaModule,
    RecaptchaFormsModule,
    ModalModule.forRoot()
  ],
  providers: [CookieService, interceptorProviders, HttpClientXsrfModule],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(
      fasCheck,
      fasTimes,
      fasBolt,
      fasBolt,
      fasCircle,
      fasCheckCircle,
      fasArrowCircleRight,
      fasGraduationCap,
      falGraduationCap,
      fasFemale,
      fasMale,
      fasUtensils,
      fasMobile,
      fasUser,
      fasUsers,
      fasBullseye,
      fasStar,
      fasEdit,
      fasChartBar,
      fasExclamationCircle,
      fasHandPointer,
      fasCalendar,
      fasClock,
      fasDownload,
      fasChartLine,
      fasTint,
      fasMedkit,
      fasHeart,
      fasBan,
      fasTrophy,
      fasBook,
      fasSearch,
      fasHome,
      fasComments,
      fasMapSigns,
      fasInfo,
      fasPlus,
      fasSync,
      fasHandshake,
      farStar,
      fasChevronCircleRight,
      fasChevronCircleLeft,
      fasBars,
      fasChevronCircleUp,
      fasChevronCircleDown,
      fasTrash,
      fasTimesCircle,
      fasQuestionCircle,
      fasMeh,
      fasChevronUp,
      fasChevronDown,
      fasArrowCircleLeft,
      fasBell,
      fasGavel,
      fasComment,
      fasPencil,
      fasCog,
      fasUserMd,
      fasCommentDots,
      fasFileDownload,
      fasThumbsUp,
      fasExclamationTriangle,
      fasFlag,
      fasIdBadge,
      fasSignOut,
      fasPlusCircle,
      fasArrowRight,
      fasTicket,
      fasSadTear,
      faFacebookSquare,
      faTwitterSquare,
      fasCheckSquare,
      farSquare,
      fasWalking,
      fasShoePrints,
      fasUserFriends,
      fasMedal,
      fasWatchFitness,
      fasUserMdChat,
      fasUserHeadset,
      fasTachometerAlt,
      fasHeartbeat,
      fasSmokingBan,
      fasQuestion,
      fasEllipsisH,
      farStopwatch,
      farCircle,
      farCalendarEdit,
      farArrowCircleRight,
      farArrowCircleLeft,
      fasSpinner,
      fasArrows,
      fasKidneys,
      farAllergies,
      farAlarmClock,
      farStomach,
      farSnooze,
      farBone,
      farHeadSideBrain,
      farBedAlt,
      farFistRaised,
      farLungs,
      farTired,
      farSocks,
      farToilet,
      farTemperatureFrigid,
      farDumbbell,
      farThumbtack,
      farHourglassHalf,
      farCouch,
      fasBullseyeArrow,
      fasRunning,
      farRunning,
      falQuestion,
      falChevronCircleUp,
      falChevronCircleDown,
      fasBookmark,
      falChevronCircleLeft,
      fasArrowUp,
      fasArrowDown,
      farBed,
      farVideo,
      falSquare
    );
  }
}

