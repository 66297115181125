<nav class="subnav">
  <a
  *ngIf="prevSymptomDetails"
    [routerLink]="['/symptom-trackers', prevSymptomDetails.slug]"
    class="subnav-prev"
    [attr.aria-label]="'Go to ' + prevSymptomDetails.name"
    ><span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
  ></a>
    <h2 *ngIf="currentUser" style="text-transform: uppercase;">
      <span class="icon-wrap">
        <fa-icon
          [icon]="[symptomDetails?.iconWeight, symptomDetails?.icon]"
        ></fa-icon>
        
      </span>
      {{symptomDetails?.name | titlecase}}
    </h2>
    <a
    *ngIf="nextSymptomDetails"
      [routerLink]="['/symptom-trackers', nextSymptomDetails.slug]"
      class="subnav-next"
      [attr.aria-label]="'Go to ' + nextSymptomDetails.name"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
    ></a>
  </nav>
  <div>
      <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
    <div class="masthead masthead-accent masthead-symptom-tracker" *ngIf="userSymptoms.length > 0">
      <div *ngIf="userSymptoms.length > 0">
        <div class="row">
          <div class="col-4 text-center  my-auto">
              <span class="icon-wrap icon-symptom-masthead">
                <fa-icon
                    [icon]="[symptomDetails?.iconWeight, symptomDetails?.icon]"
                ></fa-icon>
              </span>
          </div>
          <div class="col-4">
            <div class="symptom-reading">
              <div class="symptom-reading-circle bg-symptom-{{userSymptoms[0].frequency}}"></div>
              <div class="symptom-reading-label">
                {{frequencyLabels[userSymptoms[0].frequency]}}
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="symptom-reading">
              <div class="symptom-reading-circle bg-symptom-{{userSymptoms[0].importance}}"></div>
              <div class="symptom-reading-label">
                {{importanceLabels[userSymptoms[0].importance]}}
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="masthead-subtext tracker-subtext">
            Last updated on
            {{ userSymptoms[0].created | dateToISO | dateFormatter: "D" | ordinal }}
            {{ userSymptoms[0].created | dateToISO | dateFormatter: "MMM yyyy" }}
          </p>
        </div>
      </div>
    </div>
  
    <div *ngIf="userSymptoms.length === 0">
      <p class="lead text-center m-4">You haven't added any readings yet.</p>
    </div>
  </div>
  
  <div>
    <div class="toggle-container">
      <div
        class="toggle-header toggle-header-left"
        (click)="toggleRow.update = !toggleRow.update"
      >
        <a href="" class="toggle-nav toggle-nav-expand" (click)="$event.preventDefault()"
          ><span class="icon-wrap" aria-label="Update Tracker Expand"
            ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span
        ></a>
        <div class="toggle-header-title">UPDATE TRACKER</div>
      </div>
      <div
        class="toggle-content"
        *ngIf="toggleRow.update || showAllRows"
        [attr.aria-expanded]="toggleRow.update || showAllRows"
      >
        <form
          class="bs-form tracker-form"
          name="trackerForm"
          method="post"
          (ngSubmit)="onSubmit()"
          #trackerForm="ngForm"
        >
            <fieldset>
              <legend class="sr-only">Symptoms input data</legend>
              <p class="instruction">
              </p>
              <div class="form-group">
  
                
                  <p class="instruction">
                    You can update your symptom for today or any previous days using the form below.
                  </p>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="weightStone">FREQUENCY</label>
                        <select name="frequency"
                        class="custom-select"
                        placeholder="select a frequency"
                        id="frequency"
                        #frequency="ngModel"
                        ngModel
                        autocomplete="off">
                            <option value="1">Never</option>
                            <option value="2">Less than once a week</option>
                            <option value="3">1 – 2 times a week </option>
                            <option value="4">Several times a week </option>
                            <option value="5">Every day </option>
                        </select>
                        
                        <div
                          class="alert alert-danger"
                          *ngIf="frequency.touched && !frequency.valid"
                        >
                          Please select a valid frequency
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="weightLbs">IMPORTANCE</label>
                        <select name="importance"
                        class="custom-select"
                        placeholder="select an importance"
                        id="importance"
                        #importance="ngModel"
                        ngModel
                        autocomplete="off">
                            <option value="1">Not important</option>
                            <option value="2">Of little importance</option>
                            <option value="3">Moderately important</option>
                            <option value="4">Important</option>
                            <option value="5">Very important</option>
                        </select>
                        <div
                          class="alert alert-danger"
                          *ngIf="importance.touched && !importance.valid"
                        >
                        Please select a valid importance
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                          <label for="date">DATE</label>
                          <input
                            name="symptomDate"
                            type="text"
                            placeholder="DD/MM/YYYY"
                            class="form-control"
                            bsDatepicker
                            autocomplete="off"
                            [bsConfig]="{
                                dateInputFormat: 'DD/MM/YYYY',
                                containerClass: 'theme-cv',
                                showWeekNumbers: false
                            }"
                            [(ngModel)]="date"
                            required
                            [bsValue]="date"
                            #symptomDate="ngModel"
                            />
                         
                        </div>
                      </div>
                  </div>
                  
              </div>
  
              
  
              <div class="tracker-submit-container">
                <button
                  type="submit"
                  class="btn btn-primary btn-white"
                  value="Confirm"
                  [disabled]="!trackerForm.valid"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'check']"></fa-icon
                  ></span>
                  CONFIRM
                </button>
              </div>
            </fieldset>
        </form>
      </div>
    </div>
  
    <div class="toggle-container">
      <div
        class="toggle-header toggle-header-left"
        (click)="toggleRow.graph = !toggleRow.graph"
      >
        <a href="" (click)="$event.preventDefault()" class="toggle-nav toggle-nav-expand"
          ><span class="icon-wrap" aria-label="Tracker Graph Expand"
            ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span
        ></a>
        <div class="toggle-header-title">TRACKER GRAPH</div>
      </div>
      <div
        class="toggle-content"
        *ngIf="toggleRow.graph || showAllRows"
        [attr.aria-expanded]="toggleRow.graph || showAllRows"
      >
        <!-- Nav tabs -->
        <ul
          class="nav nav-tabs nav-tabs-duo justify-content-center"
          role="tablist"
        >
          <li role="presentation" [ngClass]="{ active: toggleChart.frequency }">
            <a
              aria-controls="frequency"
              role="tab"
              data-toggle="tab"
              (click)="toggleChart.frequency = true"
              >FREQUENCY</a
            >
          </li>
          <li role="presentation" [ngClass]="{ active: !toggleChart.frequency }">
            <a
              aria-controls="importance"
              role="tab"
              data-toggle="tab"
              (click)="toggleChart.frequency = false"
              >IMPORTANCE</a
            >
          </li>
        </ul>
  
        <!-- Tab panes -->
        <div class="tab-content">
          <div role="tabpanel" id="frequency" *ngIf="toggleChart.frequency">
            <div
              class="chart tracker-chart"
              *ngIf="symptomChartDataFrequency[0].data.length > 1"
            >
              <canvas
                class="mt-3 mb-3 tracker-chart"
                baseChart
                [datasets]="symptomChartDataFrequency"
                [labels]="chartLabels"
                [options]="chartOptionsFrequency"
                [legend]="chartLegend"
                [chartType]="chartType"
                [colors]="chartColors"
                height="200"
              >
              </canvas>
              <!--canvas id="line" class=" chart-line " chart-data="data" chart-labels="labels" chart-series="series" chart-options="options"
                                chart-dataset-override="datasetOverride" chart-click="onClick" height="200"  aria-label="Bar Chart Values {{data}}">
                                </canvas-->
            </div>
            <p class="text-center" *ngIf="symptomChartDataFrequency[0].data.length < 2">
              Please add more entries to see the graph
            </p>
          </div>
          <div role="tabpanel" id="importance" *ngIf="!toggleChart.frequency">
            <div
              class="chart tracker-chart"
              *ngIf="symptomChartDataImportance[0].data.length > 1"
            >
              <canvas
                class="mt-3 mb-3 tracker-chart"
                baseChart
                [datasets]="symptomChartDataImportance"
                [labels]="chartLabels"
                [options]="chartOptionsImportance"
                [legend]="chartLegend"
                [chartType]="chartType"
                [colors]="chartColors"
                height="200"
              >
              </canvas>
              <!--canvas id="line" class=" chart-line " chart-data="data" chart-labels="labels" chart-series="series" chart-options="options"
                                chart-dataset-override="datasetOverride" chart-click="onClick" height="200"  aria-label="Bar Chart Values {{data}}">
                                </canvas-->
            </div>
            <p class="text-center" *ngIf="symptomChartDataImportance[0].data.length < 2">
              Please add more entries to see the graph
            </p>
          </div>
          
        </div>
      </div>
    </div>
  
    <div class="toggle-container">
      <div
        class="toggle-header toggle-header-left"
        (click)="toggleRow.entry = !toggleRow.entry"
      >
        <a href="" (click)="$event.preventDefault()" class="toggle-nav toggle-nav-expand"
          ><span class="icon-wrap" aria-label="Entry Archive Expand"
            ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span
        ></a>
        <div class="toggle-header-title">ENTRY ARCHIVE</div>
      </div>
      <div
        class="toggle-content"
        *ngIf="toggleRow.entry || showAllRows"
        [attr.aria-expanded]="toggleRow.entry || showAllRows"
      >
        <table *ngIf="userSymptoms.length > 0" class="table">
          <thead>
            <tr>
              <th class="text-left">DATE</th>
              <th class="text-left">FREQUENCY</th>
              <th class="text-left">IMPORTANCE</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let userSymptom of userSymptoms">
              <td class="text-left">
                {{ userSymptom.symptom_date | dateToISO | dateFormatter: "D" | ordinal }}
                {{ userSymptom.symptom_date | dateToISO | dateFormatter: "MMM yyyy" }}
              </td>
              <td class="text-left">
                <div class="symptom-entry-circle bg-symptom-{{ userSymptom.frequency }}"></div>
                {{ frequencyLabels[userSymptom.frequency] }}
              </td>
              <td class="text-left">
                <div class="symptom-entry-circle bg-symptom-{{ userSymptom.importance }}"></div>
                {{ importanceLabels[userSymptom.importance]}}
              </td>
              <td>
                <a
                  [swal]="{
                    title: 'Are you sure you want to delete this?',
                    icon: 'warning',
                    showCancelButton: true
                  }"
                  (confirm)="onRemove(userSymptom)"
                  class="pointer"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                ></a>
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="userSymptoms.length ===0">There are no records</div>
      </div>
    </div>
  
  </div>
  