import { ForumSearchComponent } from './chat/forum/forum-search/forum-search.component';
import { SymptomTrackerIndexComponent } from './symptom-tracker/symptom-tracker-index/symptom-tracker-index.component';
import { StrengthEditComponent } from './strength/strength-edit/strength-edit.component';
import { StrengthAddExerciseComponent } from './strength/strength-add-exercise/strength-add-exercise.component';
import { StrengthViewComponent } from './strength/strength-view/strength-view.component';
import { StrengthAddComponent } from './strength/strength-add/strength-add.component';
import { StrengthComponent } from './strength/strength.component';
import { SymptomTrackerComponent } from './symptom-tracker/symptom-tracker.component';
import { ContinueAccessComponent } from './continue-access/continue-access.component';
import { HealthTrackerIndexComponent } from './health-trackers/health-tracker-index/health-tracker-index.component';
import { ChatIndexComponent } from './chat/chat-index/chat-index.component';
import { ChallengeGlobalArchiveComponent } from './challenges/challenge-global-archive/challenge-global-archive.component';
import { ChallengeBandArchiveComponent } from './challenges/challenge-band-archive/challenge-band-archive.component';
import { RegisterBuddyComponent } from './register/register-buddy/register-buddy.component';
import { ExtraSessionsComponent } from './learning-sessions/extra-sessions/extra-sessions.component';
import { NoProgrammeComponent } from './no-programme/no-programme.component';
import { OfflineComponent } from './offline/offline.component';
import { SessionsComponent } from './sessions/sessions.component';
import { AgreeTermsComponent } from './agree-terms/agree-terms.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { AssessmentsComponent } from './assessments/assessments.component';
import { RegisterComponent } from './register/register.component';
import { FriendsAndFamilyInvitesComponent } from './friends-and-family/friends-and-family-invites/friends-and-family-invites.component';
import { UploadAvatarComponent } from './profile/upload-avatar/upload-avatar.component';
import { ForumCreateTopicComponent } from './chat/forum/forum-create-topic/forum-create-topic.component';
import { SearchComponent } from './search/search.component';
import { ForumViewComponent } from './chat/forum/forum-view/forum-view.component';
import { ForumComponent } from './chat/forum/forum.component';
import { MinutesComponent } from './minutes/minutes.component';
import { FriendsAndFamilyComponent } from './friends-and-family/friends-and-family.component';
import { ChallengesIndividualComponent } from './challenges/challenges-individual/challenges-individual.component';
import { TechnicalSupportComponent } from './chat/technical-support/technical-support.component';
import { AskTheExpertComponent } from './chat/ask-the-expert/ask-the-expert.component';
import { DeviceComponent } from './device/device.component';
import { AchievementsComponent } from './achievements/achievements.component';
import { TargetComponent } from './target/target.component';
import { AccountDetailsComponent } from './settings/account-details/account-details.component';
import { PersonalDetailsComponent } from './settings/personal-details/personal-details.component';
import { SettingsComponent } from './settings/settings.component';
import { LegalComponent } from './legal/legal.component';
import { AboutComponent } from './about/about.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { HelpPageComponent } from './help/help-page/help-page.component';
import { HelpComponent } from './help/help.component';
import { EducationalReadingPageComponent } from './educational-reading/educational-reading-page/educational-reading-page.component';
import { EducationalReadingComponent } from './educational-reading/educational-reading.component';
import { DecisionMakerEditComponent } from './decision-maker/decision-maker-edit/decision-maker-edit.component';
import { DecisionMakerComponent } from './decision-maker/decision-maker.component';
import { BoosterSessionStepComponent } from './booster-sessions/booster-session-step/booster-session-step.component';
import { BoosterSessionIndexComponent } from './booster-sessions/booster-session-index/booster-session-index.component';
import { LearningSessionIndexComponent } from './learning-sessions/learning-session-index/learning-session-index.component';
import { SmokingSettingsComponent } from './health-trackers/smoking/smoking-settings/smoking-settings.component';
import { SmokingComponent } from './health-trackers/smoking/smoking.component';
import { BloodPressureComponent } from './health-trackers/blood-pressure/blood-pressure.component';
import { Hba1cComponent } from './health-trackers/hba1c/hba1c.component';
import { ShapeComponent } from './health-trackers/shape/shape.component';
import { StepsComponent } from './steps/steps.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthComponent } from './auth/auth.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { CholesterolComponent } from './health-trackers/cholesterol/cholesterol.component';
import { HealthyEatingComponent } from './health-trackers/healthy-eating/healthy-eating.component';
import { LearningSessionStepComponent } from './learning-sessions/learning-session-step/learning-session-step.component';
import { DecisionMakerSavedComponent } from './decision-maker/decision-maker-saved/decision-maker-saved.component';
import { ProfileComponent } from './profile/profile.component';
import { FriendsAndFamilyCommentsComponent } from './friends-and-family/friends-and-family-comments/friends-and-family-comments.component';
import { ProgrammeOfflineComponent } from './programme-offline/programme-offline.component';
import { ActivityIndexComponent } from './activity-index/activity-index.component';
import { AssessmentsBoosterComponent } from './assessments/assessments-booster/assessments-booster.component';
import { RegisterExerciseProgrammeComponent } from './register-exercise-programme/register-exercise-programme.component';
import { WelcomeEpComponent } from './welcome-ep/welcome-ep.component';
import { DashboardEpComponent } from './dashboard/dashboard-ep/dashboard-ep.component';
import { TargetEpComponent } from './target-ep/target-ep.component';
import { SittingComponent } from './sitting/sitting.component';
import { M3DashboardComponent } from './m3/m3-dashboard/m3-dashboard.component';
import { WorkoutsComponent } from './workouts/workouts.component';
import { WorkoutsEditWorkoutComponent } from './workouts/workouts-edit-workout/workouts-edit-workout.component';
import { M3FitnessComponent } from './m3/m3-fitness/m3-fitness.component';
import { M3WalkingComponent } from './m3/m3-walking/m3-walking.component';
import { M3NonSteppingComponent } from './m3/m3-non-stepping/m3-non-stepping.component';
import { M3PhysicalAbilityComponent } from './m3/m3-physical-ability/m3-physical-ability.component';
import { M3SittingComponent } from './m3/m3-sitting/m3-sitting.component';
import { M3SleepComponent } from './m3/m3-sleep/m3-sleep.component';
import { M3WelcomeComponent } from './m3/m3-welcome/m3-welcome.component';
import { M3HealthDataComponent } from './m3/m3-health-data/m3-health-data.component';
import { M3GlucoseComponent } from './m3/m3-glucose/m3-glucose.component';
import { M3KidneyComponent } from './m3/m3-kidney/m3-kidney.component';
import { M3LiverComponent } from './m3/m3-liver/m3-liver.component';
import { M3WeightComponent } from './m3/m3-weight/m3-weight.component';
import { M3WellbeingComponent } from './m3/m3-wellbeing/m3-wellbeing.component';
import { M3HeartComponent } from './m3/m3-heart/m3-heart.component';
import { ProgrammeSetupEpComponent } from './programme-setup/programme-setup-ep/programme-setup-ep.component';
import { SleepComponent } from './sleep/sleep.component';
import { ProgrammeSetupGu1deComponent } from './programme-setup/programme-setup-gu1de/programme-setup-gu1de.component';
import { Eq5dComponent } from './assessments/eq5d/eq5d.component';
import { M3FrailtyComponent } from './m3/m3-frailty/m3-frailty.component';
import { DashboardApiTestComponent } from './dashboard/dashboard-api-test/dashboard-api-test.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    data: {
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: true,
      //  fluidContainer: true,
      pageTitle: 'Login',
    },
  },
  {
    path: 'login',
    component: AuthComponent,
    data: {
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: true,
      // fluidContainer: true,
      pageTitle: 'Login',
    },
  },
  {
    path: 'login/:status',
    component: AuthComponent,
    data: {
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: true,
      // fluidContainer: true,
      pageTitle: 'Login',
    },
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: true,
      // fluidContainer: true,
      pageTitle: 'Register',
    },
  },
  {
    path: 'register-ndss',
    component: RegisterComponent,
    data: {
      hideFooterNav: true,
      hideTopNav: false,
      hideSiebar: true,
      fluidContainer: false,
      pageTitle: 'Register NDSS',
      ndss: true,
    },
  },
  {
    path: 'register-babysteps',
    component: RegisterComponent,
    data: {
      hideFooterNav: true,
      hideTopNav: false,
      hideSiebar: true,
      fluidContainer: false,
      pageTitle: 'Register Baby Steps',
      ndss: true,
    },
  },
  {
    path: 'register-lets-prevent',
    component: RegisterComponent,
    data: {
      hideFooterNav: true,
      hideTopNav: false,
      hideSiebar: true,
      fluidContainer: false,
      pageTitle: "Register Let's Prevent",
      ndss: true,
    },
  },
  {
    path: 'register-buddy',
    component: RegisterBuddyComponent,
    data: {
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: true,
      // fluidContainer: true,
      pageTitle: 'Register Buddy',
    },
  },
  {
    path: 'register-ep',
    component: RegisterExerciseProgrammeComponent,
    data: {
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: true,
      // fluidContainer: true,
      pageTitle: 'Register',
    },
  },
  {
    path: 'setup-ep',
    component: ProgrammeSetupEpComponent,
    data: {
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: true,
      // fluidContainer: true,
      pageTitle: 'Setup Programme',
    },
  },
  {
    path: 'setup-gu1de',
    component: ProgrammeSetupGu1deComponent,
    data: {
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: true,
      // fluidContainer: true,
      pageTitle: 'Setup Programme',
    },
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
    data: { hideSiebar: false },
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Dashboard',
      hideSiebar: false,
      pageTitle: 'Home',
      home: true,
    },
  },
  {
    path: 'dashboard-ep',
    component: DashboardEpComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Dashboard',
      hideSiebar: false,
      pageTitle: 'Home',
      home: true,
    },
  },
  {
    path: 'dashboard-api-test',
    component: DashboardApiTestComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Dashboard',
      hideSiebar: false,
      pageTitle: 'Home',
      home: true,
    },
  },
  {
    path: 'steps',
    component: StepsComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Steps',
      hideSiebar: false,
      pageTitle: 'Activity',
      subTitle: 'Steps',
    },
  },

  {
    path: 'steps/walking-speed',
    component: StepsComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Steps',
      hideSiebar: false,
      pageTitle: 'Activity',
      subTitle: 'Steps',
      walkingSpeed: true,
    },
  },

  {
    path: 'activity/index',
    component: ActivityIndexComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'ActivityIndex',
      hideSiebar: false,
      pageTitle: 'Activity',
      subTitle: 'Index',
    },
  },
  // Health Trackers
  {
    path: 'health-trackers/index',
    component: HealthTrackerIndexComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'tracker index',
      hideSiebar: false,
      pageTitle: 'Trackers',
      subTitle: 'Index',
    },
  },
  {
    path: 'health-trackers/cholesterol',
    component: CholesterolComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Cholesterol',
      hideSiebar: false,
      pageTitle: 'Health Trackers',
      subTitle: 'Cholesterol',
    },
  },
  {
    path: 'health-trackers/shape',
    component: ShapeComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Shape',
      hideSiebar: false,
      pageTitle: 'Health Trackers',
      subTitle: 'Shape',
    },
  },
  {
    path: 'health-trackers/healthy-eating',
    component: HealthyEatingComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'HealthyEating',
      hideSiebar: false,
      pageTitle: 'Health Trackers',
      subTitle: 'Healthy Eating',
    },
  },
  {
    path: 'health-trackers/hba1c',
    component: Hba1cComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'hba1c',
      hideSiebar: false,
      pageTitle: 'Health Trackers',
      subTitle: 'HbA1c',
    },
  },
  {
    path: 'health-trackers/blood-pressure',
    component: BloodPressureComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'bloodPressure',
      hideSiebar: false,
      pageTitle: 'Health Trackers',
      subTitle: 'Blood Pressure',
    },
  },
  {
    path: 'health-trackers/smoking',
    component: SmokingComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'smoking',
      hideSiebar: false,
      pageTitle: 'Health Trackers',
      subTitle: 'Smoking',
    },
  },
  {
    path: 'health-trackers/smoking-settings',
    component: SmokingSettingsComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'smokingSettings',
      hideSiebar: false,
      pageTitle: 'Health Trackers',
      subTitle: 'Smoking Settings',
    },
  },
  {
    path: 'learning-sessions/index/:learningSessionId',
    component: LearningSessionIndexComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'learningSessionIndex',
      hideSiebar: false,
      pageTitle: 'Learning Sessions',
    },
  },
  {
    path: 'learning-session-steps/view/:learningSessionStepId',
    component: LearningSessionStepComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'learningSessionStep',
      hideSiebar: false,
      pageTitle: 'Learning Sessions',
    },
  },
  {
    path: 'extra-sessions',
    component: ExtraSessionsComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'ExtraSessions',
      hideSiebar: false,
      pageTitle: 'Learning Sessions',
      subTitle: 'Extra Sessions',
    },
  },
  {
    path: 'booster-sessions/index/:boosterSessionId',
    component: BoosterSessionIndexComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'boosterSessionIndex',
      hideSiebar: false,
      pageTitle: 'Booster Sessions',
    },
  },
  {
    path: 'booster-session-steps/view/:boosterSessionStepId',
    component: BoosterSessionStepComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'boosterSessionStep',
      hideSiebar: false,
      pageTitle: 'Booster Sessions',
    },
  },
  {
    path: 'decision-maker',
    component: DecisionMakerComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'decisionMaker',
      hideSiebar: false,
      pageTitle: 'Decision Maker',
    },
  },
  {
    path: 'decision-maker-saved',
    component: DecisionMakerSavedComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'decisionMakerSaved',
      hideSiebar: false,
      pageTitle: 'Decision Maker',
      subTitle: 'Saved',
    },
  },
  {
    path: 'decision-maker-edit/:decisionMakerId',
    component: DecisionMakerEditComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'decisionMakerEdit',
      hideSiebar: false,
      pageTitle: 'Decision Maker',
      subTitle: 'Edit',
    },
  },
  {
    path: 'educational-reading/section/:sectionId',
    component: EducationalReadingComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'EducationalReading',
      hideSiebar: false,
      pageTitle: 'Useful Information',
    },
  },
  {
    path: 'educational-reading/page/:pageId',
    component: EducationalReadingPageComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'EducationalReadingPage',
      hideSiebar: false,
      pageTitle: 'Useful Information',
    },
  },

  {
    path: 'help/section/:sectionId',
    component: HelpComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Help',
      hideSiebar: false,
      pageTitle: 'Useful Information',
    },
  },
  {
    path: 'help/page/:pageId',
    component: HelpPageComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'HelpPage',
      hideSiebar: false,
      pageTitle: 'Useful Information',
    },
  },
  {
    path: 'notifications',
    component: NotificationsComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'notifications',
      hideSiebar: false,
      pageTitle: 'Notifications',
    },
  },
  {
    path: 'about',
    component: AboutComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'about',
      hideSiebar: false,
      pageTitle: 'About',
    },
  },
  {
    path: 'legal',
    component: LegalComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'legal',
      hideSiebar: false,
      pageTitle: 'Legal',
    },
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'profile',
      hideSiebar: false,
      pageTitle: 'Profile',
    },
  },
  {
    path: 'update-profile',
    component: UploadAvatarComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'updateProfile',
      hideSiebar: false,
      pageTitle: 'Profile',
      subTitle: 'Update',
    },
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'settings',
      hideSiebar: false,
      pageTitle: 'Settings',
    },
  },
  {
    path: 'settings/personal-details',
    component: PersonalDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'settingsPersonal',
      hideSiebar: false,
      pageTitle: 'settings',
      subTitle: 'Personal',
    },
  },
  {
    path: 'settings/account-details',
    component: AccountDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'settingsAccount',
      hideSiebar: false,
      pageTitle: 'Settings',
      subTitle: 'Account',
    },
  },
  {
    path: 'target',
    component: TargetComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'target',
      hideSiebar: false,
      pageTitle: 'Activity',
      subTitle: 'Step Target',
    },
  },
  {
    path: 'target-ep',
    component: TargetEpComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'target',
      hideSiebar: false,
      pageTitle: 'Activity',
      subTitle: 'Step Target',
    },
  },
  {
    path: 'achievements',
    component: AchievementsComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'achievements',
      hideSiebar: false,
      pageTitle: 'Activity',
      subTitle: 'Achievements',
    },
  },
  {
    path: 'setup-device',
    component: DeviceComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'device',
      hideSiebar: false,
      pageTitle: 'Activity',
      subTitle: 'Setup Device',
    },
  },
  {
    path: 'setup-device/:errorCode',
    component: DeviceComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'device',
      hideSiebar: false,
      pageTitle: 'Activity',
      subTitle: 'Setup Device',
    },
  },
  {
    path: 'chat/index',
    component: ChatIndexComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'chatIndex',
      hideSiebar: false,
      pageTitle: 'Chat',
      subTitle: 'Index',
    },
  },
  {
    path: 'chat/ask-the-expert',
    component: AskTheExpertComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'ATE',
      hideSiebar: false,
      pageTitle: 'CHAT',
    },
  },
  {
    path: 'chat/technical-support',
    component: TechnicalSupportComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'TechSupport',
      hideSiebar: false,
      pageTitle: 'CHAT',
    },
  },

  {
    path: 'challenges',
    component: ChallengesIndividualComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'ChallengesIndividual',
      hideSiebar: false,
      pageTitle: 'Activity',
      subTitle: 'Challenges',
    },
  },
  {
    path: 'challenges-individual',
    component: ChallengesIndividualComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'ChallengesIndividual',
      hideSiebar: false,
      pageTitle: 'Activity',
      subTitle: 'Challenges',
    },
  },

  {
    path: 'challenge-band-archive/:challengeType/:challengeStartDate/:challengeEndDate/:challengeBand',
    component: ChallengeBandArchiveComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'ChallengesBandArchive',
      hideSiebar: false,
      pageTitle: 'Activity',
      subTitle: 'Challenge Archive',
    },
  },
  {
    path: 'challenge-global-archive/:challengeType/:challengeStartDate/:challengeEndDate',
    component: ChallengeGlobalArchiveComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'ChallengesGlobalArchive',
      hideSiebar: false,
      pageTitle: 'Activity',
      subTitle: 'Challenge Archive',
    },
  },
  {
    path: 'friends-and-family',
    component: FriendsAndFamilyComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'FriendsAndFamily',
      hideSiebar: false,
      pageTitle: 'Activity',
      subTitle: 'Buddies',
    },
  },
  {
    path: 'friends-and-family-comments/:challengeType/:startDate/:endDate',
    component: FriendsAndFamilyCommentsComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'FriendsAndFamilyComments',
      hideSiebar: false,
      pageTitle: 'Activity',
      subTitle: 'Buddies | Comments',
    },
  },
  {
    path: 'friends-and-family/invites',
    component: FriendsAndFamilyInvitesComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'FriendsAndFamilyInvites',
      hideSiebar: false,
      pageTitle: 'Activity',
      subTitle: 'Buddies | Invites',
    },
  },
  {
    path: 'activity/minutes',
    component: MinutesComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'MinutesComponent',
      hideSiebar: false,
      pageTitle: 'Activity',
      subTitle: 'Minutes',
    },
  },

  {
    path: 'activity/workouts',
    component: WorkoutsComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'WorkoutsComponent',
      hideSiebar: false,
      pageTitle: 'Activity',
      subTitle: 'wokrouts',
    },
  },
  {
    path: 'activity/workouts/edit-workout/:id',
    component: WorkoutsEditWorkoutComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'WorkoutsEditWorkoutComponent',
      hideSiebar: false,
      pageTitle: 'Activity',
      subTitle: 'Workouts',
    },
  },

  {
    path: 'activity/sitting',
    component: SittingComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'SittingComponent',
      hideSiebar: false,
      pageTitle: 'Activity',
      subTitle: 'Sitting',
    },
  },

  {
    path: 'activity/sleep',
    component: SleepComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'SleepComponent',
      hideSiebar: false,
      pageTitle: 'Activity',
      subTitle: 'Sleep',
    },
  },
  {
    path: 'activity/strength',
    component: StrengthComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'StrengthComponent',
      hideSiebar: false,
      pageTitle: 'Activity',
      subTitle: 'Strength',
    },
  },
  {
    path: 'activity/strength/add',
    component: StrengthAddComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'StrengthAddComponent',
      hideSiebar: false,
      pageTitle: 'Activity',
      subTitle: 'Add Strength Workout',
    },
  },
  {
    path: 'activity/strength/edit/:id',
    component: StrengthEditComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'StrengthEditComponent',
      hideSiebar: false,
      pageTitle: 'Activity',
      subTitle: 'Edit Strength Workout',
    },
  },
  {
    path: 'activity/strength/view/:id',
    component: StrengthViewComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'StrengthViewComponent',
      hideSiebar: false,
      pageTitle: 'Activity',
      subTitle: 'View Strength Workout',
    },
  },
  {
    path: 'activity/strength/add-exercise/:id',
    component: StrengthAddExerciseComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'StrengthAddExerciseComponent',
      hideSiebar: false,
      pageTitle: 'Activity',
      subTitle: 'Add Strength Exercise',
    },
  },
  {
    path: 'chat',
    component: ForumComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'ForumComponent',
      hideSiebar: false,
      pageTitle: 'CHAT',
    },
  },
  {
    path: 'chat/:page',
    component: ForumComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'ForumComponent',
      hideSiebar: false,
      pageTitle: 'CHAT',
    },
  },
  {
    path: 'chat/view/:type/:commentId',
    component: ForumViewComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'ForumViewComponent',
      hideSiebar: false,
      pageTitle: 'CHAT',
    },
  },
  {
    path: 'search',
    component: SearchComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Search',
      hideSiebar: false,
      pageTitle: 'Search',
    },
  },

  {
    path: 'chat/forum/create-topic',
    component: ForumCreateTopicComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'ForumCreateComponent',
      hideSiebar: false,
      pageTitle: 'CHAT',
    },
  },

  {
    path: 'chat/forum/search',
    component: ForumSearchComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'ForumSearch',
      hideSiebar: false,
      pageTitle: 'CHAT',
    },
  },
  {
    path: 'assessments/take/:assessmentId/:prePost',
    component: AssessmentsComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'AssessmentsComponent',
      hideSiebar: false,
      pageTitle: 'Assessments',
    },
  },
  {
    path: 'assessments/take/:assessmentId/:prePost/:boosterSessionId',
    component: AssessmentsBoosterComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'AssessmentsBoosterComponent',
      hideSiebar: false,
      pageTitle: 'Assessments',
    },
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Welcome',
      hideSiebar: false,
      pageTitle: 'Welcome',
    },
  },
  {
    path: 'welcome-ep',
    component: WelcomeEpComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Welcome',
      hideSiebar: false,
      pageTitle: 'Welcome',
    },
  },
  {
    path: 'agree-terms',
    component: AgreeTermsComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'AgreeTerms',
      hideSiebar: false,
      pageTitle: 'Agree Terms',
    },
  },
  {
    path: 'continue-access',
    component: ContinueAccessComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'ContinueAccess',
      hideSiebar: false,
      pageTitle: 'Account',
      subTitle: 'Continue Access',
    },
  },
  {
    path: 'sessions',
    component: SessionsComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Sessions',
      hideSiebar: false,
      pageTitle: 'Sessions',
    },
  },
  {
    path: 'learning-sessions',
    component: SessionsComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Sessions',
      hideSiebar: false,
      pageTitle: 'Sessions',
      subTitle: 'Learning',
      sessionType: 'learning',
    },
  },
  {
    path: 'booster-sessions',
    component: SessionsComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Sessions',
      hideSiebar: false,
      pageTitle: 'Sessions',
      subTitle: 'Booster',
      sessionType: 'booster',
    },
  },
  {
    path: 'offline',
    component: OfflineComponent,
    canActivate: [],
    data: {
      animation: 'Maintenance',
      hideSiebar: true,
      pageTitle: 'Maintenance',
    },
  },
  {
    path: 'no-programme',
    component: NoProgrammeComponent,
    canActivate: [],
    data: {
      animation: 'NoProgramme',
      hideSiebar: true,
      pageTitle: 'No Programme',
    },
  },
  {
    path: 'programme-offline',
    component: ProgrammeOfflineComponent,
    canActivate: [],
    data: {
      animation: 'ProgrammeOffline',
      hideSiebar: true,
      pageTitle: 'Offline',
    },
  },
  // Symptoms Tracker
  {
    path: 'symptom-trackers/:symptom',
    component: SymptomTrackerComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Symptom tracker',
      hideSiebar: false,
      pageTitle: 'Symptom Trackers',
      subTitle: 'Index',
    },
  },
  {
    path: 'symptom-trackers',
    component: SymptomTrackerIndexComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Symptom tracker index',
      hideSiebar: false,
      pageTitle: 'Symptom Trackers',
      subTitle: 'Index',
    },
  },
  // M3
  {
    path: 'm3/welcome',
    component: M3WelcomeComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'M3Welcome',
      hideSiebar: false,
      hideNavbar: true,
      pageTitle: 'Home',
      subTitle: '',
      m3skin: true,
    },
  },
  {
    path: 'm3/dashboard',
    component: M3DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'M3 Dashboard',
      hideSiebar: false,
      hideNavbar: true,
      pageTitle: 'Home',
      subTitle: '',
      m3skin: true,
    },
  },

  {
    path: 'm3/fitness',
    component: M3FitnessComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'M3Fitness',
      hideSiebar: false,
      hideNavbar: true,
      pageTitle: 'Fitness',
      subTitle: '',
      m3skin: true,
    },
  },

  {
    path: 'm3/walking',
    component: M3WalkingComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'M3Walking',
      hideSiebar: false,
      hideNavbar: true,
      pageTitle: 'Walking',
      subTitle: '',
      m3skin: true,
    },
  },

  {
    path: 'm3/non-stepping',
    component: M3NonSteppingComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'M3NonStepping',
      hideSiebar: false,
      hideNavbar: true,
      pageTitle: 'Non Stepping Activity',
      subTitle: '',
      m3skin: true,
    },
  },

  {
    path: 'm3/physical-ability',
    component: M3PhysicalAbilityComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'M3PhysicalAbility',
      hideSiebar: false,
      hideNavbar: true,
      pageTitle: 'Physical Ability',
      subTitle: '',
      m3skin: true,
    },
  },

  {
    path: 'm3/sitting',
    component: M3SittingComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'M3Sitting',
      hideSiebar: false,
      hideNavbar: true,
      pageTitle: 'Sitting',
      subTitle: '',
      m3skin: true,
    },
  },

  {
    path: 'm3/sleep',
    component: M3SleepComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'M3Sleep',
      hideSiebar: false,
      hideNavbar: true,
      pageTitle: 'Sleep',
      subTitle: '',
      m3skin: true,
    },
  },

  {
    path: 'm3/health-data',
    component: M3HealthDataComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'M3HealthData',
      hideSiebar: false,
      hideNavbar: true,
      pageTitle: 'HealthData',
      subTitle: '',
      m3skin: true,
    },
  },
  {
    path: 'm3/health-data/glucose',
    component: M3GlucoseComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'M3Glucose',
      hideSiebar: false,
      hideNavbar: true,
      pageTitle: 'HealthData',
      subTitle: '',
      m3skin: true,
    },
  },
  {
    path: 'm3/health-data/kidney',
    component: M3KidneyComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'M3Kidney',
      hideSiebar: false,
      hideNavbar: true,
      pageTitle: 'HealthData',
      subTitle: '',
      m3skin: true,
    },
  },
  {
    path: 'm3/health-data/liver',
    component: M3LiverComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'M3Liver',
      hideSiebar: false,
      hideNavbar: true,
      pageTitle: 'HealthData',
      subTitle: '',
      m3skin: true,
    },
  },
  {
    path: 'm3/health-data/weight',
    component: M3WeightComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'M3Weight',
      hideSiebar: false,
      hideNavbar: true,
      pageTitle: 'HealthData',
      subTitle: '',
      m3skin: true,
    },
  },
  {
    path: 'm3/health-data/wellbeing',
    component: M3WellbeingComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'M3Wellbeing',
      hideSiebar: false,
      hideNavbar: true,
      pageTitle: 'HealthData',
      subTitle: '',
      m3skin: true,
    },
  },
  {
    path: 'm3/health-data/frailty',
    component: M3FrailtyComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'M3Frailty',
      hideSiebar: false,
      hideNavbar: true,
      pageTitle: 'HealthData',
      subTitle: '',
      m3skin: true,
    },
  },
  {
    path: 'm3/health-data/independence',
    component: M3FrailtyComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'M3Frailty',
      hideSiebar: false,
      hideNavbar: true,
      pageTitle: 'HealthData',
      subTitle: '',
      m3skin: true,
    },
  },
  {
    path: 'm3/health-data/heart',
    component: M3HeartComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'M3Heart',
      hideSiebar: false,
      hideNavbar: true,
      pageTitle: 'HealthData',
      subTitle: '',
      m3skin: true,
    },
  },

  {
    path: 'assessments/eq5d',
    component: Eq5dComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Eq5dQuestionnaire',
      hideSiebar: true,
      pageTitle: 'Assessments',
      navbarOptions: { style: 'light' },
    },
  },

  {
    path: 'assessments/eq5d/:stage',
    component: Eq5dComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Eq5dQuestionnaire',
      hideSiebar: true,
      pageTitle: 'Assessments',
      navbarOptions: { style: 'light' },
    },
  },

  {
    path: '**',
    component: PageNotFoundComponent,
    data: {
      hideFooterNav: true,
      hideTopNav: false,
      hideSiebar: true,
      fluidContainer: false,
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    onSameUrlNavigation: 'reload',
    anchorScrolling: 'enabled',
    enableTracing: false
}),
    // NgxGoogleAnalyticsRouterModule
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
