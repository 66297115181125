import { AnalyticsService } from './services/analytics-service.service';
import { UpdateService } from './services/update.service';
import { NotificationService } from './services/notification.service';
import { TargetService } from './services/target.service';
import { ActivityService } from './services/activity.service';
import { environment } from './../environments/environment';
import { AwardService } from './services/award.service';
import { forkJoin, interval, Subscription } from 'rxjs';
import { ConfigService } from './services/config.service';
import { AssessmentsService } from './services/assessments.service';
import { UserService } from './services/user.service';
import { ProgrammeService } from './services/programme.service';
import { AuthService } from './auth/auth.service';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { slideInAnimation, fadeInAnimation } from './route-animations';
import { ChallengeService } from './services/challenge.service';
import { Title } from '@angular/platform-browser';
import { ViewportScroller } from '@angular/common';
import Swal from 'sweetalert2/src/sweetalert2.js';
import {
  NgcCookieConsentService,
  NgcInitializeEvent,
  NgcNoCookieLawEvent,
  NgcStatusChangeEvent,
} from 'ngx-cookieconsent';
import { SwPush } from '@angular/service-worker';
import { CacheManagerService } from './services/cache-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation, fadeInAnimation],
})
@HostListener('window:resize', ['$event'])
export class AppComponent implements OnInit, OnDestroy {
  title = 'MyDESMOND';
  currentUser;
  currentProgramme;
  isFetching = false;
  checkSiteStatusInterval;
  checkActivityInterval;
  checkSessionInterval;
  newAwards;
  activeAward = {
    position: null,
    type: null,
    typeRef: null,
    title: null,
    startDate: null,
    endDate: null,
  };
  showAward = false;
  public innerWidth: number;
  environment = environment;

  timer;
  m3 = 0;

  // keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  constructor(
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private programmeService: ProgrammeService,
    private userService: UserService,
    private assessmentService: AssessmentsService,
    private configService: ConfigService,
    private awardService: AwardService,
    private challengeService: ChallengeService,
    private titleService: Title,
    private viewportScroller: ViewportScroller,
    private activityService: ActivityService,
    private targetService: TargetService,
    private notificationService: NotificationService,
    private sw: UpdateService,
    private ccService: NgcCookieConsentService,
    private analyticsService: AnalyticsService,
    readonly swPush: SwPush,
    private cacheManagerService:CacheManagerService
  ) {
    // check the service worker for updates
    this.sw.checkForUpdates();
  }

  ngOnInit() {
    this.subscribeToPush();
    this.swPush.notificationClicks.subscribe(({ action, notification }) => {
      // console.log(notification);
      // check if there is somehting we need to do?
      if (notification.data?.url) {
        this.router.navigateByUrl(notification.data?.url);
      }
      parent.focus();
      window.focus();
    });

    if (environment.country === 'AUS') {
      moment.tz.setDefault('Australia/Perth');
    }
    this.checkSiteStatus();
    this.checkSiteStatusInterval = interval(60000).subscribe((count) => {
      this.checkSiteStatus();
    });

    this.innerWidth = window.innerWidth;
    this.authService.user.subscribe((user) => {
      if (user) {
        this.programmeService.currentProgramme.subscribe((programmeData) => {
          this.currentProgramme = programmeData;

          this.checkAwards();
          this.m3 = this.currentProgramme.config.m3;
          if (programmeData) {
            this.addBrandStyles(programmeData['config']);
            this.userService.userData.subscribe((userDataResponse) => {
              this.currentUser = userDataResponse;
              if (this.currentUser != null) {
                if (environment.country === 'UK') {
                  this.checkExpiredAccess();
                }

                if (
                  this.currentUser.agree_terms == null &&
                  this.router.url !== '/agree-terms'
                ) {
                  // go to terms agree
                  this.router.navigate(['/agree-terms']);
                } else {
                  this.checkPost();
                }

                this.checkActivityInterval = interval(60000).subscribe(
                  (count) => {
                    this.activityPoll();
                  }
                );
              }
            });
          }
        });
      }
      this.isFetching = false;
    });
    const autoLoginObs = this.authService.autoLogin();

    if (autoLoginObs) {
      autoLoginObs.subscribe(
        (resData) => {
          if (this.router.url.split('?')[0] === '/login') {
            if (this.authService.redirect) {
              this.router.navigateByUrl(this.authService.redirect);
            } else {
              this.router.navigate(['/dashboard']);
            }
          }
        },
        (errorMessage) => {
          console.log(errorMessage);
        }
      );
    }

    // scroll to top on route change
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);

      // set page title
      const title = this.getTitle(
        this.router.routerState,
        this.router.routerState.root
      ).join(' | ');
      this.titleService.setTitle(environment.title + ' | ' + title);

      // analytics
      this.analyticsService.trackVirtualPageview(
        environment.title + ' | ' + title
      );

      this.programmeService.currentProgramme.subscribe((programmeData) => {
        if (programmeData['status'] == '0') {
          if (this.router.url != '/programme-offline') {
            this.router.navigate(['/programme-offline']);
          }
        }
      });
    });

    // cookie consent
    if (environment.country === 'UK') {
      if (this.ccService.hasConsented()) {
        // start tracking
        this.analyticsService.startTracking();
      }
      // subscribe to cookieconsent observables to react to main events
      this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
        // you can use this.ccService.getConfig() to do stuff...
      });

      this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
        // you can use this.ccService.getConfig() to do stuff...
      });

      this.initializeSubscription = this.ccService.initialize$.subscribe(
        (event: NgcInitializeEvent) => {
          // you can use this.ccService.getConfig() to do stuff...
        }
      );

      this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
        (event: NgcStatusChangeEvent) => {
          // you can use this.ccService.getConfig() to do stuff...
          if (event.status === 'deny') {
            this.analyticsService.stopTracking();
          } else {
            this.analyticsService.startTracking();
          }
        }
      );

      this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
        () => {
          // you can use this.ccService.getConfig() to do stuff...
        }
      );

      this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
        (event: NgcNoCookieLawEvent) => {
          // you can use this.ccService.getConfig() to do stuff...
        }
      );
      // end cookie consent
    } else {
      this.analyticsService.startTracking();
    }
  }

  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.pageTitle) {
      data.push(parent.snapshot.data.pageTitle);
    }

    if (parent && parent.snapshot.data && parent.snapshot.data.subTitle) {
      data.push(parent.snapshot.data.subTitle);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  checkPost() {
    // do they need to take the post test for anything?
    if (this.currentProgramme.config.prepost) {
      if (
        this.router.url != '/register' &&
        this.router.url != '/register-ep' &&
        this.router.url != '/register-buddy' &&
        this.router.url != '/welcome' &&
        this.router.url != '/welcome-ep' &&
        !this.currentUser.buddy
      ) {
        // did they take the pre?
        let sentOff = false;
        let orgSpecific = false;
        let orgPres = [];
        let orgPosts = [];
        let orgIntervals: any = {};

        // do we have a client specific prepost?
        let preposts = [];
        if (this.currentProgramme.config.prepostOrg) {
          orgSpecific = true;
          const prepostOrg = JSON.parse(
            this.currentProgramme.config.prepostOrg
          );
          if (prepostOrg[this.currentUser.client_id]) {
            for (const assessmentId of Object.keys(
              prepostOrg[this.currentUser.client_id]
            )) {
              for (const prepost of Object.keys(
                prepostOrg[this.currentUser.client_id][assessmentId]
              )) {
                if (
                  prepostOrg[this.currentUser.client_id][assessmentId].pre ==
                    true ||
                  prepostOrg[this.currentUser.client_id][assessmentId].post ==
                    true
                ) {
                  preposts.push(assessmentId);
                  // is there an interval?
                  if (
                    prepostOrg[this.currentUser.client_id][assessmentId].int
                  ) {
                    orgIntervals[assessmentId] =
                      prepostOrg[this.currentUser.client_id][assessmentId].int;
                  }
                }
                if (
                  prepostOrg[this.currentUser.client_id][assessmentId].pre ==
                  true
                ) {
                  orgPres.push(assessmentId);
                }
                if (
                  prepostOrg[this.currentUser.client_id][assessmentId].post ==
                  true
                ) {
                  orgPosts.push(assessmentId);
                }
              }
            }
          } else {
            preposts = this.currentProgramme.config.prepost;
          }
        } else {
          preposts = this.currentProgramme.config.prepost;
        }

        preposts.forEach((prepostObj) => {
          if (sentOff == false) {
            this.assessmentService
              .fetchResults(prepostObj, 'pre')
              .subscribe((resultsResponse) => {
                if (
                  resultsResponse.length > 0 ||
                  // check for client set post onlys
                  (orgPres.indexOf(prepostObj) === -1 &&
                    orgPosts.indexOf(prepostObj) !== -1)
                ) {
                  // check for pre only
                  if (
                    orgPres.indexOf(prepostObj) !== -1 &&
                    orgPosts.indexOf(prepostObj) === -1
                  ) {
                    // don't need post
                  } else {
                    let _preDate = null;
                    if (resultsResponse.length > 0) {
                      _preDate = resultsResponse[0].created;
                    }

                    this.assessmentService
                      .fetchResults(prepostObj, 'post')
                      .subscribe((postResultsResponse) => {
                        if (postResultsResponse.length < 1) {
                          if (_preDate) {
                            // check if 8 weeks has passed
                            const PreDate = moment(_preDate);
                            const PostDate = moment(PreDate);
                            let days = 56;

                            if (orgIntervals[prepostObj]) {
                              days = +orgIntervals[prepostObj] * 7;
                            } else if (
                              this.currentProgramme.config.prepostInterval
                            ) {
                              days =
                                +this.currentProgramme.config.prepostInterval *
                                7;
                            }
                            PostDate.add(days, 'days');
                            const now = moment();
                            if (now > PostDate && sentOff === false) {
                              sentOff = true;

                              setTimeout(() => {
                                this.router.navigate([
                                  '/assessments',
                                  'take',
                                  prepostObj,
                                  'post',
                                ]);

                                return;
                              }, 500);
                            }
                          } else {
                            if (
                              orgPres.indexOf(prepostObj) === -1 &&
                              orgPosts.indexOf(prepostObj) !== -1
                            ) {

                              let days = 8;
                              if (orgIntervals[prepostObj]) {
                                days = +orgIntervals[prepostObj] * 7;
                              } else if (
                                this.currentProgramme.config.prepostInterval
                              ) {
                                days =
                                  +this.currentProgramme.config.prepostInterval *
                                  7;
                              }
                              let created = moment(this.currentUser.created);
                              created.add(days, 'days');
                              const now = moment();
                              if (now > created && sentOff === false) {
                              
                              sentOff = true;

                              setTimeout(() => {
                                this.router.navigate([
                                  '/assessments',
                                  'take',
                                  prepostObj,
                                  'post',
                                ]);

                                return;
                              }, 500);

                            }







                            }
                          }
                        }
                      });
                  }
                } else {
                  // they don't have a pre result
                  // have they started it?

                  if (
                    !orgSpecific ||
                    (orgSpecific && orgPres.indexOf(prepostObj) !== -1)
                  ) {
                    this.assessmentService
                      .fetchResponses(prepostObj, 'pre')
                      .subscribe((responseResponse) => {
                        // they need finish it..
                        const now = moment();
                        let createdCheck = moment(this.currentUser.created);
                        createdCheck = createdCheck.add(7, 'days');
                        if (createdCheck > now && sentOff === false) {
                          sentOff = true;

                          setTimeout(() => {
                            this.router.navigate([
                              '/assessments',
                              'take',
                              prepostObj,
                              'pre',
                            ]);
                            return;
                          }, 500);
                        }
                      });
                  }
                }
              });
          }
        });
      }
    }
  }

  checkSiteStatus() {
    this.configService.checkStatus().subscribe(
      (responseData) => {
        if (responseData != 1) {
          if (
            window.location.href !== '/offline' &&
            this.router.url !== '/offline'
          ) {
            window.location.href = '/offline';
          }
        }

      },
      (error) => {}
    );
  }

  checkAwards() {
    this.newAwards = null;
    this.showAward = false;
    this.activeAward = {
      position: null,
      type: null,
      typeRef: null,
      title: null,
      startDate: null,
      endDate: null,
    };
    this.awardService.getAwards(0).subscribe((awards) => {
      this.newAwards = awards;
      // set notified to true!

      let awardText = '';
      let awardsWon = 0;
      const awardTitles = { 'mon-fri': 'Weekday', weekend: 'Weekend' };
      let idSplit = '';
      if (awards) {
        awards.forEach((award, index) => {
          if(!award.programme_id  || award.programme_id == this.currentProgramme.programme_id){
          if (award.award_type == 'challenge') {
            idSplit = award.award_type_ref.split(';');
            awardText +=
              'You were ' +
              this.intToOrdinalNumberString(award.award_rank) +
              ' in the ' +
              awardTitles[award.award_sub_type] +
              ' challenge <br><em>' +
              moment(idSplit[1]).format('MMM Do') +
              ' - ' +
              moment(idSplit[2]).format('MMM Do') +
              '</em><br><br>';
            awardsWon++;
          }

          // show this first award..
          if (index == 0) {
            this.activeAward.position = award.award_rank;
            if (award.award_type == 'challenge') {
              this.activeAward.type = 'c';
              if (award.award_sub_type == 'weekend') {
                this.activeAward.typeRef = 'w';
              } else if (award.award_sub_type == 'mon-fri') {
                this.activeAward.typeRef = 'mf';
              } else if (award.award_sub_type == 'global') {
                this.activeAward.typeRef = 'g';
                this.activeAward.title = 'Global Leaderboard';
              } else if (award.award_sub_type == 'band') {
                this.activeAward.typeRef = 'b';
              } else {
                this.activeAward.typeRef = 'c';
                // get the challenge
                const challengeId = idSplit[0].split('-')[1];

                this.challengeService
                  .fetch(challengeId)
                  .subscribe((responseData) => {
                    const challenge = responseData;
                    this.activeAward.title = challenge.title;
                  });
              }
              this.activeAward.startDate = moment(idSplit[1]).format(
                'DD/MM/YYYY'
              );
              this.activeAward.endDate = moment(idSplit[2]).format(
                'DD/MM/YYYY'
              );
            }

            if (award.award_type == 'milestone') {
              this.activeAward.type = 'm';
            }
            if (award.award_type == 'record') {
              this.activeAward = award;
              this.activeAward.position = award.award_rank;
              this.activeAward.typeRef = award.award_sub_type;
              this.activeAward.type = 'r';
            }
            this.showAward = true;
            // update the status
            this.awardService
              .setStatus(award.award_id, 1)
              .subscribe((response) => {});
          }
        }
        });
      }

      if (awardsWon > 0) {
        var awardTitle = '';
        if (awardsWon == 1) {
          awardTitle = 'You won an award!';
        } else {
          awardTitle = 'You won ' + awardsWon + ' awards!';
        }
        /*swal({
                  'title': awardTitle,
                  'html' : awardText + 'Well done!',
                  'type' : 'success'
             })*/
      }
    });
  }

  public onClick(event, elementId: string): void {
    event.preventDefault();
    this.viewportScroller.scrollToAnchor(elementId);
  }

  onCloseAward(event) {
    this.checkAwards();
  }

  addBrandStyles(config) {
    const css = document.createElement('style');
    css.type = 'text/css';
    css.id = 'brandStyles';

    css.innerHTML =
      `.btn-primary {
        background-color: ` +
      config.primaryColor +
      ` !important;
        border-color: ` +
      config.primaryColor +
      ` !important;
    }
     .btn-white {
        color: ` +
      config.primaryColor +
      `!important;
        background-color: #fff !important;
        border-color: #fff !important;
    }
     .section-nav ul > li > a > .icon-wrap, 
         .side-menu-item > a .item-icon,
             .section-nav ul > li > a > .icon-wrap, .toggle-header-title > .icon-wrap{
        background-color: ` +
      config.primaryColor +
      ` !important;
    }
    
     .masthead-accent{
        background-color: ` +
      config.accentColor +
      `;
    
    }
      .section-nav ul > li > a:after, 
      .btn-navbar,
           .toggle-header  a,
              .prev-next a ,
             .nav-tabs a,
           .reading-material-overview  a ,
              .text-primary
      {
         
        color:` +
      config.primaryColor +
      `;
            }
            
     .text-accent{
        color:` +
      config.accentColor +
      ` !important;}
     
      .profile-achievements{
        background-color: ;
         }
         .masthead .profile .profile-details > ul > li{
            border-color: ;
         }
     .pace .pace-progress {
      background: ` +
      config.primaryColor +
      `;
          }
          
     .side-menu-user .side-menu-item > a .item-icon{
        color:#fff;
    }
    
    .user-menu-wrap{
    }
          
     .quiz-answer-alph{
        background-color: ` +
      config.primaryColor +
      `;
    
    }
     .quiz-answers > li:after, .questionnaire-answers > li:after{
        color: ` +
      config.primaryColor +
      `;
    }
    
     .loading-app{
        color: ` +
      config.primaryColor +
      `;
    }
    
     .masthead-accent .progress-bar-accent{
        background-color: ` +
      config.primaryColor +
      `;
    }
    
     .reading-content h4{
        color: ` +
      config.primaryColor +
      `;
    }
    
     .db-tracker h2{
        background-color: ` +
      config.accentColor +
      `;
    }
     #db-sidenav .side-menu-item > a{
        color: ` +
      config.primaryColor +
      `;
    }
     #db-sidenav .side-menu-item > a.collapsed{
        color: #333;
    }
     #db-sidenav .side-menu-item > a.collapsed:after{
        color: ` +
      config.primaryColor +
      `;
    }
     #db-sidenav .side-menu-sub-menu li.active a{
    color: ` +
      config.primaryColor +
      `;
    }
     #db-sidenav .side-menu-sub-menu li.active a i{
    color: ` +
      config.primaryColor +
      `;
    }
     a.circle-active{
        color: ` +
      config.primaryColor +
      `;
    }`;
    document.body.appendChild(css);
  }
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  showMobile(routerOutlet) {
    if (this.innerWidth < 400) {
      return (
        routerOutlet &&
        routerOutlet.activatedRouteData &&
        routerOutlet.activatedRouteData['animation']
      );
    }
  }
  showDesktop(routerOutlet) {
    if (this.innerWidth > 399) {
      return (
        routerOutlet &&
        routerOutlet.activatedRouteData &&
        routerOutlet.activatedRouteData['animation']
      );
    }
  }
  ngOnDestroy(): void {
    this.checkSiteStatusInterval.unsubscribe();
    this.checkActivityInterval.unsubscribe();
    clearInterval(this.checkActivityInterval);
    clearInterval(this.checkSiteStatusInterval);
  }
  intToOrdinalNumberString = (num: number): string => {
    num = Math.round(num);
    let numString = num.toString();

    // If the ten's place is 1, the suffix is always "th"
    // (10th, 11th, 12th, 13th, 14th, 111th, 112th, etc.)
    if (Math.floor(num / 10) % 10 === 1) {
      return numString + 'th';
    }

    // Otherwise, the suffix depends on the one's place as follows
    // (1st, 2nd, 3rd, 4th, 21st, 22nd, etc.)
    switch (num % 10) {
      case 1:
        return numString + 'st';
      case 2:
        return numString + 'nd';
      case 3:
        return numString + 'rd';
      default:
        return numString + 'th';
    }
  };

  activityPoll() {
    forkJoin({
      activity: this.activityService.getActivityBetween(
        moment().format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ),
      target: this.targetService.getTarget('step'),
    }).subscribe((responseData) => {
      const activity = responseData.activity[0];
      if (responseData.target) {
        const target = responseData.target.target;
        //console.log(response);

        if (activity) {
          // did they hit their target?
          if (
            +activity.steps >= +target &&
            activity.hit_goal != 1 &&
            activity.steps > 0
          ) {
            // do the notification!
            Swal.fire({
              title: 'Target Hit!',
              text: 'You have hit your target of ' + target + '. Well done!',
              icon: 'success',
            });

            this.activityService
              .update(activity.user_activity_id, activity.steps, 1)
              .subscribe((updateResponse) => {
                this.notificationService
                  .addNotification(
                    this.currentUser.user_id,
                    'Hit Goal',
                    'You hit your daily step target of ' +
                      target +
                      '. Well done!',
                    null,
                    0
                  )
                  .subscribe((notificationAddResponse) => {});
              });
          }
        }
      }
    });
  }

  checkExpiredAccess() {
    let forwardUser = false;
    if (moment(this.currentUser.created) < moment().subtract(21, 'months')) {
      // have they continued access yet?
      if (this.currentUser.continue_date) {
        if (
          moment(this.currentUser.continue_date) <
          moment().add(3, 'months')
        ) {
          forwardUser = true;
        }
      } else {
        // go to the continue access page
        forwardUser = true;
      }
    }

    if (forwardUser === true && this.currentUser.is_admin == '0') {
      this.router.navigate(['/continue-access']);
    }
  }

  // check for idle user
  @HostListener('document:mousemove')
  @HostListener('document:keypress')
  @HostListener('document:click')
  @HostListener('document:keydown')
  @HostListener('document:keyup')
  @HostListener('document:DOMMouseScroll')
  @HostListener('document:mousewheel')
  @HostListener('document:mousedown')
  @HostListener('document:touchstart')
  @HostListener('document:touchmove')
  @HostListener('document:scroll')
  @HostListener('document:focus')
  @HostListener('document:wheel')
  resetTimer() {
    if (environment.country === 'AUS' && this.currentUser != null) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.authService.logout();

        this.cacheManagerService.clearAllCache();
      }, 884000);
    }
  }

  // push notifications
  private async subscribeToPush() {
    let subSaved = false;
    try {
      const sub = await this.swPush
        .requestSubscription({
          serverPublicKey: environment.vapidKeys.publicKey,
        })
        .then((sub) => {
          if (this.currentUser) {
            this.authService
              .saveWebPush(JSON.stringify(sub.toJSON()))
              .subscribe((responseData) => {
                //console.log(responseData);
                this.userService.reset();
                this.userService.get().subscribe((userData) => {
                  this.currentUser = userData;
                });
                subSaved = true;
              });
          } else {
            this.authService.user.subscribe((user) => {
              if (user && !subSaved) {
                this.authService
                  .saveWebPush(JSON.stringify(sub.toJSON()))
                  .subscribe((responseData) => {
                    //console.log(responseData);
                    this.userService.reset();
                    this.userService.get().subscribe((userData) => {
                      this.currentUser = userData;
                    });
                    subSaved = true;
                  });
              }
            });
          }
        });
      // TODO: Send to server.
    } catch (err) {
      console.error('Could not subscribe due to:', err);
    }
  }
}
