<nav class="section-nav" *ngIf="currentProgramme && currentUser">
    <ul>
        <ng-container *ngIf="section === 'activity'">
            <li *ngIf="active !== 'steps' && currentUser.mobility3!='0' && currentUser.mobility1!='f'">
              <a [routerLink]="['/steps']">
                <span class="icon-wrap section-nav-icon"><fa-icon [icon]="['fas', 'shoe-prints']" [fixedWidth]="true"></fa-icon></span>
                <span class="section-nav-label">My Steps</span>
                <span class="section-nav-arrow"><fa-icon [icon]="['far', 'arrow-circle-right']" [fixedWidth]="true"></fa-icon></span>
              </a>
            </li>
            <li *ngIf="active !== 'challenges' && currentUser.mobility3!='0' && currentUser.mobility1!='f'">
              <a [routerLink]="['/challenges-individual']"><span class="icon-wrap section-nav-icon"><fa-icon [icon]="['fas', 'trophy']" [fixedWidth]="true"></fa-icon></span>
                <span class="section-nav-label">Challenges</span>
                <span class="section-nav-arrow"><fa-icon [icon]="['far', 'arrow-circle-right']" [fixedWidth]="true"></fa-icon></span>
              </a>
            </li>
            <li *ngIf="active !== 'strength' && currentProgramme.config.trackers.indexOf('strength') > -1">
              <a [routerLink]="['/activity', 'strength']"><span class="icon-wrap section-nav-icon"><fa-icon [icon]="['far', 'dumbbell']" [fixedWidth]="true"></fa-icon></span>
                <span class="section-nav-label">Strength Training</span>
                <span class="section-nav-arrow"><fa-icon [icon]="['far', 'arrow-circle-right']" [fixedWidth]="true"></fa-icon></span>
              </a>
            </li>
            <li *ngIf="currentProgramme.config.buddies==1 && !currentUser.buddy  && active!== 'buddies'">
              <a  [routerLink]="['/friends-and-family']"><span class="icon-wrap section-nav-icon"><fa-icon [icon]="['fas', 'user-friends']" [fixedWidth]="true"></fa-icon></span>
                <span class="section-nav-label">Buddies</span>
                <span class="section-nav-arrow"><fa-icon [icon]="['far', 'arrow-circle-right']" [fixedWidth]="true"></fa-icon></span>
              </a>
            </li>
            <li *ngIf="active !== 'target' && currentUser.mobility3!='0' && currentUser.mobility1!='f'">
              <a  [routerLink]="['/target']"><span class="icon-wrap section-nav-icon"><fa-icon [icon]="['fas', 'bullseye']" [fixedWidth]="true"></fa-icon></span>
                <span class="section-nav-label">Set Daily Steps Target</span>
                <span class="section-nav-arrow"><fa-icon [icon]="['far', 'arrow-circle-right']" [fixedWidth]="true"></fa-icon></span>
              </a>
            </li>
            <li *ngIf="currentProgramme.config.trackers.indexOf('workouts') > -1 && active!== 'workouts'">
              <a [routerLink]="['/activity', 'workouts']"><span class="icon-wrap section-nav-icon"><fa-icon [icon]="['fas', 'running']" [fixedWidth]="true"></fa-icon></span>
                <span class="section-nav-label">Workouts</span>
                <span class="section-nav-arrow"><fa-icon [icon]="['far', 'arrow-circle-right']" [fixedWidth]="true"></fa-icon></span>
              </a>
            </li>
            <li *ngIf="currentProgramme.config.trackers.indexOf('minutes') > -1 && active!== 'minutes'">
              <a [routerLink]="['/activity', 'minutes']"><span class="icon-wrap section-nav-icon"><fa-icon [icon]="['far', 'stopwatch']" [fixedWidth]="true"></fa-icon></span>
                <span class="section-nav-label">Minutes</span>
                <span class="section-nav-arrow"><fa-icon [icon]="['far', 'arrow-circle-right']" [fixedWidth]="true"></fa-icon></span>
              </a>
            </li>
            <li *ngIf="currentProgramme.config.trackers.indexOf('sitting') > -1 && active!== 'sitting'">
              <a [routerLink]="['/activity', 'sitting']"><span class="icon-wrap section-nav-icon"><fa-icon [icon]="['far', 'couch']" [fixedWidth]="true"></fa-icon></span>
                <span class="section-nav-label"><span *ngIf="!(currentUser.mobility1 == 'e' || currentUser.mobility1 == 'f')">My Sitting</span><span *ngIf="currentUser.mobility1 == 'e' || currentUser.mobility1 == 'f'">My Sedentary Behaviour</span></span>
                <span class="section-nav-arrow"><fa-icon [icon]="['far', 'arrow-circle-right']" [fixedWidth]="true"></fa-icon></span>
              </a>
            </li>
            <li *ngIf="currentProgramme.config.trackers.indexOf('sleep') > -1 && active!== 'sleep'">
              <a [routerLink]="['/activity', 'sleep']"><span class="icon-wrap section-nav-icon"><fa-icon [icon]="['far', 'bed']" [fixedWidth]="true"></fa-icon></span>
                <span class="section-nav-label">My Sleep</span>
                <span class="section-nav-arrow"><fa-icon [icon]="['far', 'arrow-circle-right']" [fixedWidth]="true"></fa-icon></span>
              </a>
            </li>
            <li *ngIf="active !== 'achievements'">
              <a  [routerLink]="['/achievements']"><span class="icon-wrap section-nav-icon"><fa-icon [icon]="['fas', 'medal']" [fixedWidth]="true"></fa-icon></span>
                <span class="section-nav-label">Achievements</span>
                <span class="section-nav-arrow"><fa-icon [icon]="['far', 'arrow-circle-right']" [fixedWidth]="true"></fa-icon></span>
              </a>
            </li>
            <li *ngIf="active !== 'setupDevice'">
              <a [routerLink]="['/setup-device']"><span class="icon-wrap section-nav-icon"><fa-icon [icon]="['fas', 'watch-fitness']" [fixedWidth]="true"></fa-icon></span>
                <span class="section-nav-label">Setup Device</span>
                <span class="section-nav-arrow"><fa-icon [icon]="['far', 'arrow-circle-right']" [fixedWidth]="true"></fa-icon></span>
              </a>
            </li>
        </ng-container>
        <ng-container *ngIf="section === 'healthTrackers'">
            <li *ngIf="currentProgramme.config.trackers.indexOf('shape') > -1 && active !== 'shape'">
                <a [routerLink]="['/health-trackers', 'shape']">
                  <ng-container *ngIf="currentUser">
                    <span class="icon-wrap section-nav-icon" *ngIf="currentUser.gender === 'f' || !currentUser.gender"><fa-icon [icon]="['fas', 'female']" [fixedWidth]="true"></fa-icon></span>
                    <span class="icon-wrap section-nav-icon" *ngIf="currentUser.gender === 'm' || currentUser.gender == 'x'"><fa-icon [icon]="['fas', 'male']" [fixedWidth]="true"></fa-icon></span>
                </ng-container>
                  <span class="section-nav-label">Shape</span>
                  <span class="section-nav-arrow"><fa-icon [icon]="['far', 'arrow-circle-right']" [fixedWidth]="true"></fa-icon></span>
                </a>
              </li>
              <li *ngIf="currentProgramme.config.trackers.indexOf('healthyEating') > -1 && active !== 'healthyEating'">
                <a [routerLink]="['/health-trackers', 'healthy-eating']">
                  <span class="icon-wrap section-nav-icon"><fa-icon [icon]="['fas', 'utensils']" [fixedWidth]="true"></fa-icon></span>
                  <span class="section-nav-label">Healthy Eating</span>
                  <span class="section-nav-arrow"><fa-icon [icon]="['far', 'arrow-circle-right']" [fixedWidth]="true"></fa-icon></span>
                </a>
              </li>
              <li *ngIf="currentProgramme.config.trackers.indexOf('hba1c') > -1 && active !== 'hba1c'">
                <a [routerLink]="['/health-trackers', 'hba1c']">
                  <span class="icon-wrap section-nav-icon"><fa-icon [icon]="['fas', 'tint']" [fixedWidth]="true"></fa-icon></span>
                  <span class="section-nav-label">HbA1c</span>
                  <span class="section-nav-arrow"><fa-icon [icon]="['far', 'arrow-circle-right']" [fixedWidth]="true"></fa-icon></span>
                </a>
              </li>
              <li *ngIf="currentProgramme.config.trackers.indexOf('bp') > -1 && active !== 'bloodPressure'">
                <a [routerLink]="['/health-trackers', 'blood-pressure']">
                  <span class="icon-wrap section-nav-icon"><fa-icon [icon]="['fas', 'tachometer-alt']" [fixedWidth]="true"></fa-icon></span>
                  <span class="section-nav-label">Blood Pressure</span>
                  <span class="section-nav-arrow"><fa-icon [icon]="['far', 'arrow-circle-right']" [fixedWidth]="true"></fa-icon></span>
                </a>
              </li>
              <li *ngIf="currentProgramme.config.trackers.indexOf('bp') > -1 && active !== 'cholesterol'">
                <a [routerLink]="['/health-trackers', 'cholesterol']">
                  <span class="icon-wrap section-nav-icon"><fa-icon [icon]="['fas', 'heartbeat']" [fixedWidth]="true"></fa-icon></span>
                  <span class="section-nav-label">Cholesterol</span>
                  <span class="section-nav-arrow"><fa-icon [icon]="['far', 'arrow-circle-right']" [fixedWidth]="true"></fa-icon></span>
                </a>
              </li>
              <li *ngIf="currentProgramme.config.trackers.indexOf('smoking') > -1 && active !== 'smoking'">
                <a [routerLink]="['/health-trackers', 'smoking']">
                  <span class="icon-wrap section-nav-icon"><fa-icon [icon]="['fas', 'smoking-ban']" [fixedWidth]="true"></fa-icon></span>
                  <span class="section-nav-label">Smoking</span>
                  <span class="section-nav-arrow"><fa-icon [icon]="['far', 'arrow-circle-right']" [fixedWidth]="true"></fa-icon></span>
                </a>
              </li>
        </ng-container>
    </ul>
</nav>