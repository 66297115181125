<nav class="subnav">
    <a [routerLink]="['/steps']" aria-label="Go to p" class="subnav-prev"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
    ></a>
    <h2>
      <span class="icon-wrap"><fa-icon [icon]="['fas', 'trophy']"></fa-icon></span>
      CHALLENGES
    </h2>
    <div *ngIf="currentProgramme && currentUser">
      <a
        *ngIf="currentProgramme.config.buddies == 1"
        [routerLink]="['/friends-and-family']"
        aria-label="Go to buddies"
        class="subnav-next"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
      ></a>
  
      <a
        *ngIf="currentProgramme.config.buddies != 1"
        [routerLink]="['/target']"
        aria-label="Go to target"
        class="subnav-next"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
      ></a>
    </div>
  </nav>
<div *ngIf="isFetching">
  <app-loading-spinner [inline]="1"></app-loading-spinner>
</div>
<div *ngIf="currentUser">
  <div class="masthead masthead-accent">
    <div class="masthead-challenge">
      <img
        src="./assets/img/icon-flag.png"
        width="60"
        class="masthead-icon"
      />
      <div class="masthead-figure">WINNER</div>
      <div class="masthead-strap">{{ challenge.title | uppercase }}</div>
      <div class="masthead-leader" *ngIf="currentLeader">
        <img
          [src]="
            (currentLeader.avatar !== null &&
              environment.avatarUrl + '/assets/avatar/' + currentLeader.avatar) ||
              'assets/img/default-avatar.png'
          "
          class="img-circle img-avatar"
          width="50"
          height="50"
        />
        <span class="leader-name">{{ currentLeader.screen_name }}</span>
      </div>
    </div>
  </div>

  <div class="text-center">
    <button class="btn btn-primary" [routerLink]="['/challenges-individual']">
      GO TO CURRENT CHALLENGE
    </button>
  </div>
  <br />

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
    (click)="toggleRow.results= !toggleRow.results"
    >
      <a href="" class="toggle-nav toggle-nav-expand" (click)="$event.preventDefault()"
        > <span *ngIf="!toggleRow.results" class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span>
        <span *ngIf="toggleRow.results" class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon></span></a>
      <div class="toggle-header-title">
        {{ challenge.title | uppercase }} ({{
          challenge.startDate | dateFormatter: "DD/MM/yyyy"
        }}
        - {{ challenge.endDate | dateFormatter: "DD/MM/yyyy" }})
      </div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.results">
      <!-- Tab panes -->
      <div class="tab-content">
        <table class="table table-leaderboard" *ngIf="challenge_results">
          <thead>
            <tr>
              <th colspan="4">LEADERBOARD</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let cResults of orderResult(challenge_results, 'award_rank')
              "
              [ngClass]="{ me: cResults.user_id == currentUser.user_id }"
            >
              <td width="32">
                <span *ngIf="cResults.award_rank == 1" class="medal"
                  ><img
                    src="./assets/img/icon-aw-medal-gold.png"
                    width="16"
                    alt="gold medal"
                /></span>
                <span *ngIf="cResults.award_rank == 2" class="medal"
                  ><img
                    src="./assets/img/icon-aw-medal-silver.png"
                    width="16"
                    alt="silver-medal"
                /></span>
                <span *ngIf="cResults.award_rank == 3" class="medal"
                  ><img
                    src="./assets/img/icon-aw-medal-bronze.png"
                    width="16"
                    alt="bronze medal"
                /></span>
              </td>
              <td class="avi" width="32">
                <img
                  [src]="
                    (cResults.avatar !== null &&
                      environment.avatarUrl + '/assets/avatar/' + cResults.avatar) ||
                      'assets/img/default-avatar.png'
                  "
                  class="img-circle img-avatar"
                  width="30"
                  height="30"
                />
              </td>
              <td>{{ cResults.screen_name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <app-section-nav
    [section]="'activity'"
    [active]="'challenges'"
  ></app-section-nav>
</div>
