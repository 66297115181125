<nav class="subnav">
  <h2>
    <span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'user-md']"></fa-icon
    ></span>
    {{ environment.navLabels["askTheExpert"] | uppercase }}
  </h2>
</nav>
<div class="container container-pad">
  <p *ngIf="environment.country == 'AUS'">
    Our health professionals with experience in nutrition, exercise and diabetes
    education, are available to answer your questions and support you in your
    health journey. This is a private feature and only you and our health
    professionals will see these messages. Please allow up to 2 business days
    for a response to your query.
  </p>
  <div *ngIf="environment.country == 'UK'">
    <div *ngIf="currentProgramme?.config?.exerciseProgramme != 1">
    <p>
      Our experts with experience in nutrition, exercise and diabetes
        prevention, are available to answer your questions and support you in
        your health journey. This is a private feature and only you and our
        experts will see these messages. Please allow up to 2 business days for
        a response to your query.
    </p>
    <p style="font-weight:bold;color:#333;"><strong>
      We are unable to provide individual medical advice and would recommend
        that you instead speak with your local healthcare professional. This is
        NOT to be used for any emergency or urgent queries.</strong>
    </p>
    </div>
    <div *ngIf="currentProgramme?.config?.exerciseProgramme == 1">
      <p>Our experts with experience in physical activity and exercise are available to answer your questions and support you. This is a private feature and only you and our experts will see these messages. Please allow up to 2 business days for a response to your query.</p>
      <p style="font-weight:bold;color:#333;"><strong>
We are unable to provide individual medical advice and would recommend that you instead speak with your local healthcare professional. This is NOT to be used for any emergency or urgent queries.</strong>
</p>
    </div>
    <!--p style="font-size:1rem;font-weight:bold;color:#333;">PLEASE NOTE: The Ask the Expert feature is coordinated and answered by NHS staff. It is not an emergency or urgent care service and therefore over the Easter period there will be a pause in replies to questions from Friday 7th April to Monday 10th April inclusive.</p-->
   
  
  </div>

  <span *ngIf="isFetching">
    <span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon></span
  ></span>
  <ul class="chat expert-chat">
    <li
      *ngFor="let comment of comments"
      [ngClass]="{ expert: comment.admin === '1' }"
    >
      <span class="avatar"
        ><img
          [src]="
            (comment.avatar !== null &&
              environment.avatarUrl + '/assets/avatar/' + comment.avatar) ||
            './assets/img/default-avatar.png'
          "
          class="img-circle img-avatar"
          width="30"
          height="30"
          alt="user avatar"
      /></span>
      <div class="comment">
        <strong>
          <span *ngIf="!comment.admin">{{
            comment.first_name + " " + comment.last_name
          }}</span>
          <span *ngIf="comment.admin"
            ><span *ngIf="environment.country != 'AUS'">Expert</span
            ><span *ngIf="environment.country == 'AUS'">MyDESMOND</span></span
          ></strong
        >&nbsp;
        <span
          *ngIf="comment.comment"
          [innerHTML]="comment.comment | linky"
        ></span>
      </div>
      <div
        *ngFor="let attachment of commentAttachments[comment.comment_id]"
        class="mt-10"
      >
        <a
          [href]="
            '/download-attachment/' +
            attachment.comment_attachment_id +
            '/' +
            attachment.filename
          "
          target="_blank"
          ><span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'file-download']"></fa-icon
          ></span>
          Attachment</a
        >
      </div>
      <span
        class="comment-date"
        timeago
        [date]="comment.created  | dateFormatter : ''"
        [live]="true"
      ></span>
    </li>
  </ul>
  <p *ngIf="!comments">You have not sent a message yet.</p>
  <hr class="divider" />
  <form
    class="text-center comment-form"
    name="commentForm"
    method="post"
    (ngSubmit)="onSubmit()"
    #commentForm="ngForm"
  >
    <div class="form-group">
      <label class="sr-only" for="comment">Comment</label>
      <textarea
        rows="2"
        autosize
        type="text"
        class="form-control"
        #comment="ngModel"
        placeholder="Write a new message…"
        id="comment"
        name="comment"
        ngModel
        autocomplete="off"
        required
        aria-label="Enter a comment"
        maxlength="5000"
        style="min-height: 90px; resize: none"
      ></textarea>
      <div
        class="alert alert-danger"
        *ngIf="comment.touched && comment.invalid"
      >
        Please enter a comment in the text area above, maximum 5,000 characters.
      </div>
    </div>
    <button
      class="btn btn-primary"
      [disabled]="!commentForm.valid || isDisabled || checkEmpty(comment.value)"
      aria-label="Post comment"
      value="Post Comment"
    >
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'comment-dots']"></fa-icon
      ></span>
      POST COMMENT
    </button>
  </form>

  <div *ngIf="faqs.length > 0">
    <hr />
    <h4 class="mb-4">FAQs</h4>
    <div *ngFor="let faq of faqs">
      <div class="toggle-container">
        <div
        class="toggle-header toggle-header-left toggle-header-faq"
          (click)="
            $event.preventDefault();
            this.showFaq[faq.faq_id] = !this.showFaq[faq.faq_id]
          "
        >
          <div [innerHtml]="faq.question | safeHtml" class="faq-question"></div>
          <span class="icon-wrap" *ngIf="!this.showFaq[faq.faq_id]" aria-label="Down"
          ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
        ></span>
        <span class="icon-wrap" *ngIf="this.showFaq[faq.faq_id]" aria-label="Up"
          ><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon
        ></span>
        </div>
        <div
          class="toggle-content"
          [innerHtml]="faq.answer | safeHtml"
          *ngIf="showFaq[faq.faq_id]"
        ></div>
      </div>
    </div>
  </div>
</div>
