import { AuthService } from './../../auth/auth.service';
import { environment } from './../../../environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-buddy',
  templateUrl: './register-buddy.component.html',
  styleUrls: ['./register-buddy.component.scss'],
})
export class RegisterBuddyComponent implements OnInit {
  regDetails = {
    email: null,
    birth_country: null,
    language: null,
    smoker: null,
    exercise: null,
    attendedBefore: 0,
    gender: null,
    weight: null,
    weightStone: null,
    weightLbs: null,
    height: null,
    heightFeet: null,
    heightInches: null,
    glucosePercent: null,
    glucose: null,
    password: '',
    terms: 0,
    agree_marketing: 0,
  };
  stepSubmitted = {};
  step = 1;
  environment = environment;
  codeError;
  isFetching;
  unitMode = 'metric';
  regProgrammeConfig;
  hba1cUnitMode = 'percent';
  code;
  bsValue = new Date('1960/01/01');

  passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#])[A-Za-z\d$@$!%*?&#]{10,}$/;

  @ViewChild('regForm', { static: false }) regForm: NgForm;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    // is there a user logged in?
    this.authService.user.subscribe((user) => {
      if (user && this.step === 1) {
        this.router.navigate(['/dashboard']);
      }
    });
  }

  checkCode(code) {
    this.isFetching = true;

    this.authService.checkCodeBuddy(code).subscribe(
      (responseData) => {
        console.log(responseData);
        const status = responseData['status'];
        if (status === 'SUCCESS') {
          this.code = code;
          this.regProgrammeConfig = responseData['programmeConfig'];

          this.step = 2;
          this.regDetails.email = responseData['email'];
          // get the team names
          /*this.team_id = responseData['team_id'];
          if (responseData['name_options']) {
            this.teamNames = responseData['name_options'].split(',');
          }*/

          this.isFetching = false;
        } else {
          this.codeError = true;
          this.isFetching = false;
        }
        this.isFetching = false;
      },
      (error) => {
        this.codeError = error.error.detail;
        this.isFetching = false;
        this.step = 1;

      }
    );
  }

  checkPassword(check, pswd) {
    var pass = false;

    if (pswd) {
      switch (check) {
        case 'uppercase':
          if (/^.*[A-Z].*$/.test(pswd)) {
            pass = true;
          }
          break;
        case 'lowercase':
          if (/^.*[a-z].*$/.test(pswd)) {
            pass = true;
          }
          break;
        case 'number':
          if (/^.*[0-9].*$/.test(pswd)) {
            pass = true;
          }
          break;
        case 'special':
          if (/^.*[$@!%*?&#].*$/.test(pswd)) {
            pass = true;
          }
          break;
      }
    }
    return pass;
  }

  checkReg(regStep) {
    this.isFetching = true;
    this.stepSubmitted[regStep] = true;
    switch (regStep) {
      case 2:
        // check the screen name
        this.authService
          .checkScreenName(this.regForm.value.screen_name)
          .subscribe(
            (responseData) => {
              this.isFetching = false;
              const status = responseData.status;
              if (status == 'SUCCESS') {
                let pass2 = false;
                if (this.environment.country == 'UK') {
                  if (
                    !this.regForm.controls.dob.invalid &&
                    this.regDetails.gender != null &&
                    !this.regForm.controls.screen_name.invalid
                  ) {
                    this.step = 3;
                    pass2 = true;
                  }
                }
                if (this.environment.country === 'AUS') {
                  if (
                    !this.regForm.controls.dob.invalid &&
                    this.regDetails.gender != null &&
                    !this.regForm.controls.screen_name.invalid
                  ) {
                    this.step = 3;
                    pass2 = true;
                  }
                }
                if (pass2 == false) {
                  Swal.fire({
                    title: "We notice you've missed something",
                    text:
                      'Please check the form for information that has been missed',
                    icon: 'error',
                  });
                }
              } else {
                this.regForm.controls['screen_name'].setErrors({
                  nametaken: true,
                });
              }
            },
            (error) => {}
          );

        break;

      case 3:
        // work out the weight

        if (
          !this.regForm.controls.email.invalid &&
          !this.regForm.controls.password.invalid &&
          !this.regForm.controls.confirmPassword.invalid &&
          !this.regForm.controls.terms.invalid
        ) {
          this.authService.registerBuddy(this.regForm.value).subscribe(
            (responseData) => {
              this.isFetching = false;
              const status = responseData['status'];
              if (status == 'SUCCESS') {
                this.step = 4;
                // set the programme?

                /* AUTH them

              // login
              let credentials = {};
              credentials.email = this.regDetails.email;
              credentials.password = this.regDetails.password;

              AuthService.login(credentials).then(function (user) {
                $rootScope.$broadcast(AUTH_EVENTS.loginSuccess);
                this.setCurrentUser(user);

                // let's go
                this.step = 5;

                // set current programmes too
                //this.getProgramme();
              });
              */
              } else {
                Swal.fire({
                  title: 'Error creating account',
                  text:
                    'There was an error setting up your account. Please try again.',
                  icon: 'error',
                });
              }
              this.isFetching = false;
            },
            (error) => {
              Swal.fire({
                title: 'Error creating account',
                text:
                  'There was an error setting up your account. Please try again.',
                icon: 'error',
              });
              this.isFetching = false;
            }
          );
        } else {
          this.isFetching = false;
          Swal.fire({
            title: "We notice you've missed something",
            text: 'Please check the form for information that has been missed',
            icon: 'error',
          });
        }
        break;
    }
  }

  complete() {
    this.router.navigate(['/dashboard']);
    // set the programme?
  }

  getPasswordStrength(password) {
    let score = 0;

    if (/^.*[A-Z].*$/.test(password)) {
      score++;
    }
    if (/^.*[a-z].*$/.test(password)) {
      score++;
    }
    if (/^.*[0-9].*$/.test(password)) {
      score++;
    }
    if (/^.*[$@!%*?&#].*$/.test(password)) {
      score++;
    }

    return score;
  }
}
