import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ProgrammeService } from 'src/app/services/programme.service';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { UserShapeService } from 'src/app/services/user-shape.service';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-m3-weight',
  templateUrl: './m3-weight.component.html',
  styleUrls: ['./m3-weight.component.scss'],
})
export class M3WeightComponent implements OnInit {
  currentUser;
  bmis: any = [];
  weightChanges: any = [];
  userShapes: any = [];
  waists: any = [];
  weights:any = [];
  showAllEntries = false;
  entryDate = new Date();
  maxDate = new Date();

  @ViewChild('trackerForm', { static: false }) trackerForm: NgForm;

  unitMode = 'metric';
  unitModeWaist = 'imperial';
  unitModeWeight = 'metric';

  constructor(
    private authService: AuthService,
    private programmeService: ProgrammeService,
    private router: Router,
    private userService: UserService,
    private userSettingsService: UserSettingsService,
    private modalService: BsModalService,
    private userShapeService: UserShapeService
  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {});

      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });

      forkJoin({
        userSettings: this.userSettingsService.fetchAll(),
        userShapes: this.userShapeService.fetchAll(),
      }).subscribe((responseData) => {
        let bmi = 0;
        this.userShapes = responseData.userShapes;
        /*
        this.userShapes.forEach((user_shape, index) => {
          if (user_shape.weight) {
            bmi = this.userShapeService.getBmi(
              this.currentUser.height,
              user_shape.weight
            );
            this.bmis.push({
              bmi: bmi,
              date_recorded: user_shape.created,
            });

            if (index > 0 && this.userShapes[index - 1].weight) {
              this.weightChanges.push({
                change: +this.userShapes[index - 1].weight - +user_shape.weight,
                date_recorded: user_shape.created,
              });
            }
          }

          if (user_shape.waist) {
            this.waists.push({
              waist: user_shape.waist * 2.54,
              date_recorded: user_shape.created,
              weight: user_shape.weight,
              user_shape_id: user_shape.user_shape_id,
            });
          }
        });*/

        this.userShapes.forEach((user_shape, index) => {
          if (user_shape.weight) {
            this.weights.push(user_shape);
            bmi = this.userShapeService.getBmi(
              this.currentUser.height,
              user_shape.weight
            );
            this.bmis.push({
              bmi: bmi,
              date_recorded: user_shape.created,
            });
          }
          if (user_shape.waist) {
            this.waists.push({
              waist: user_shape.waist * 2.54,
              date_recorded: user_shape.created,
            });
          }
        });
        this.weights.forEach((user_shape, index) => {
          if (index > 0 && this.weights[index - 1].weight) {
            this.weightChanges.push({
              change: +this.weights[index - 1].weight - +user_shape.weight,
              date_recorded: user_shape.created,
            });
          }
        });


      });
    }
  }

  getBmiRag(bmi, ethnicityId) {
    return this.userShapeService.getBmiRag(bmi, ethnicityId);
  }

  getBmiRagRange(color: string, ethnicityId) {
    return this.userShapeService.getBmiRagRange(color, ethnicityId);
  }

  getWaistRag(waist: number, sex, ethnicityId) {
    return this.userShapeService.getWaistRag(waist, sex, ethnicityId);
  }
  getWaistRagRange(color: string, sex, ethnicityId) {
    return this.userShapeService.getWaistRagRange(color, sex, ethnicityId);
  }

  onDeleteBmi(bmi) {
    /*
    this.bloodGlucoseService
    .delete(hba1c.user_blood_glucose_id)
      .subscribe((responseData) => {
        //this.activityService.clearCache();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/hebmih-data/glucose']));
      });*/
  }

  onSubmitBmiForm() {
    // this.entryForm.value.date
    /*
    if (!this.entryFormBelowRange.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed somethbelowg",
        text: 'Please check the form for belowformation that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.bloodGlucoseService
        .createTimeBelowRange(
          this.entryFormBelowRange.value.glucoseBelowRange,
          moment(this.entryDateLevel).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
           
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/hebmih-data/glucose']));
          },
          (error) => {
            console.log(error);
          }
        );
    }*/
  }

  onDeleteWaist(waist) {
    if (waist.weight == null) {
      this.userShapeService
        .delete(waist.user_shape_id)
        .subscribe((responseData) => {
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => this.router.navigate(['/m3/health-data/weight']));
        });
    } else {
      this.userShapeService
        .update(waist.user_shape_id, waist.weight, null)
        .subscribe((responseData) => {
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => this.router.navigate(['/m3/health-data/weight']));
        });
    }
    /*
    this.bloodGlucoseService
    .delete(hba1c.user_blood_glucose_id)
      .subscribe((responseData) => {
        //this.activityService.clearCache();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/hebmih-data/glucose']));
      });*/
  }

  onDeleteWeight(weight) {
    if (weight.waist == null) {
      this.userShapeService
        .delete(weight.user_shape_id)
        .subscribe((responseData) => {
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => this.router.navigate(['/m3/health-data/weight']));
        });
    } else {
      this.userShapeService
        .update(weight.user_shape_id, null, weight.waist)
        .subscribe((responseData) => {
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => this.router.navigate(['/m3/health-data/weight']));
        });
    }
  }

  onSubmit() {
    // this.trackerForm.value.date

    if (!(this.trackerForm.value.weight || this.trackerForm.value.waist)) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      let weightInput = null;
      let waistInput = null;
      if (this.trackerForm.value.weight) {
        weightInput = this.trackerForm.value.weight;
      }
      if (this.trackerForm.value.waist) {
        waistInput = this.trackerForm.value.waist;
      }

      this.userShapeService
        .create(
          weightInput,
          waistInput,
          moment(this.entryDate).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => this.router.navigate(['/m3/health-data/weight']));
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  updateWeight(type?) {
    if (type !== 'metric') {
      let ilbs = this.trackerForm.controls['weightLbs'].value;
      if (!ilbs) {
        ilbs = 0;
      }
      this.trackerForm.controls['weight'].setValue(
        +this.trackerForm.controls['weightStone'].value * 6.35029 +
          ilbs * 0.453592
      );
      this.trackerForm.controls['weight'].setValue(
        (Math.round(+this.trackerForm.controls['weight'].value) * 10) / 10
      );
    } else {
      // convert metric to imperial
      const stones = Math.floor(
        +this.trackerForm.controls['weight'].value * 0.1574
      );
      const remainder = 0.1574 % +this.trackerForm.controls['weight'].value;
      const totalLbs = 2.20462 * +this.trackerForm.controls['weight'].value;
      const lbs = totalLbs - stones * 14;
      this.trackerForm.controls['weightStone'].setValue(stones);
      this.trackerForm.controls['weightLbs'].setValue(
        Math.round(lbs * 10) / 10
      );
    }
  }

  updateWaist(type?) {
    if (type !== 'metric') {
      if (+this.trackerForm.controls['waist'].value) {
        this.trackerForm.controls['waistCm'].setValue(
          Math.round(+this.trackerForm.controls['waist'].value * 2.54 * 10) / 10
        );
      } else {
        this.trackerForm.controls['waistCm'].setValue('');
      }
    } else {
      if (+this.trackerForm.controls['waistCm'].value) {
        this.trackerForm.controls['waist'].setValue(
          Math.round(
            (+this.trackerForm.controls['waistCm'].value / 2.54) * 10
          ) / 10
        );
      } else {
        this.trackerForm.controls['waist'].setValue('');
      }
    }
  }

  onSwitchMode(event, area, mode) {
    event.preventDefault();
    if (area === 'weight') {
      this.unitMode = mode;
      if (mode === 'imperial') {
        this.updateWeight('metric');
      }
      if (mode === 'metric') {
        this.updateWeight('imperial');
      }
    }
    if (area === 'waist') {
      this.unitModeWaist = mode;
      if (mode === 'imperial') {
        this.updateWaist('metric');
      }
      if (mode === 'metric') {
        this.updateWaist('imperial');
      }
    }
  }
}
