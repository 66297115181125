import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-m3-icon',
  templateUrl: './m3-icon.component.html',
  styleUrls: ['./m3-icon.component.scss']
})
export class M3IconComponent implements OnInit {
  @Input() icon:any;
  @Input() style:any;
  constructor() { }

  ngOnInit(): void {
  }

}
