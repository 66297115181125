import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { WorkoutsService } from 'src/app/services/workouts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import Swal from 'sweetalert2/src/sweetalert2.js';

@Component({
  selector: 'app-strength-add-exercise',
  templateUrl: './strength-add-exercise.component.html',
  styleUrls: ['./strength-add-exercise.component.scss'],
})
export class StrengthAddExerciseComponent implements OnInit {
  isFetching = false;
  workoutId;
  currentProgramme;
  currentUser;
  userWorkout;
  step = 1;

  selected = {
    area: null,
    exercise: null,
    band: null,
    set1: null,
    set2: null,
    set3: null,
    effort: 5,
  };

  areas = [
    { label: 'Upper Body' },
    { label: 'Lower Body' },
    { label: 'Core' },
    { label: 'Wrist' },
  ];

  exercises = [
    { label: 'Shoulder raise (front)', area: 'Upper Body' },
    { label: 'Chest pull', area: 'Upper Body' },
    { label: 'Back stretch', area: 'Upper Body' },
    { label: 'Arm curl', area: 'Upper Body' },
    { label: 'Pull apart I', area: 'Upper Body' },
    { label: 'Pull apart II', area: 'Upper Body' },
    { label: 'Seated shoulder raise (double)', area: 'Upper Body' },
    { label: 'Single arm shoulder raise (side)', area: 'Upper Body' },
    { label: 'Double arm shoulder raise (side)', area: 'Upper Body' },
    { label: 'Pull back', area: 'Upper Body' },

    { label: 'Standing knee curl', area: 'Lower Body' },
    { label: 'Sitting knee curl', area: 'Lower Body' },
    { label: 'Side step', area: 'Lower Body' },
    { label: 'Leg push I', area: 'Lower Body' },
    { label: 'Leg push II', area: 'Lower Body' },
    { label: 'Lunges', area: 'Lower Body' },
    { label: 'Sit to stand', area: 'Lower Body' },
    { label: 'Squat', area: 'Lower Body' },
    { label: 'Wall squat ', area: 'Lower Body' },
    { label: 'Standing leg press', area: 'Lower Body' },

    { label: 'Seated row', area: 'Core' },
    { label: 'Standing row', area: 'Core' },
    { label: 'Wall press', area: 'Core' },

    { label: 'Wrist curl I', area: 'Wrist' },
    { label: 'Wrist curl II', area: 'Wrist' },
    { label: 'Wrist curl III', area: 'Wrist' },
  ];

  bands = ['red', 'green', 'yellow', 'blue', 'black'];

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private workoutsService: WorkoutsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.isFetching = true;
    this.route.params.subscribe((params) => {
      this.isFetching = true;

      this.workoutId = this.route.snapshot.params.id;

      // are we logged in?
      const user = this.authService.user.getValue();
      if (user) {
        this.programmeService.currentProgramme.subscribe((programmeData) => {
          this.currentProgramme = programmeData;
          if (this.currentProgramme) {
            forkJoin({
              workout: this.workoutsService.fetch(this.workoutId),
            }).subscribe(
              (responseData) => {
                this.isFetching = false;
                this.userWorkout = responseData.workout;
              },
              (error) => {
                this.isFetching = false;
              }
            );
          }
        });
        this.userService.userData.subscribe((userDataResponse) => {
          this.currentUser = userDataResponse;
        });
      }
    });
  }

  setStep(step) {
    this.step = step;
  }
  restart() {
    this.step = 1;
  }

  prev() {
    this.step--;
  }
  next() {
    switch (this.step) {
      case 1:
        if (this.selected.area) {
          this.step++;
        }
        break;
      case 2:
        if (this.selected.exercise) {
          this.step++;
        }
        break;
      case 3:
        this.step++;
        break;
      case 4:
        this.step++;
        break;
    }
  }

  effortUp() {
    if (this.selected.effort < 10) {
      this.selected.effort = +this.selected.effort + +1;
    }
  }
  effortDown() {
    if (this.selected.effort > 1) {
      this.selected.effort = this.selected.effort - 1;
    }
  }
  onSaveWorkout() {
    this.workoutsService
      .addExercise(
        this.workoutId,
        this.selected.area,
        this.selected.exercise,
        this.selected.band,
        this.selected.set1,
        this.selected.set2,
        this.selected.set3,
        this.selected.effort
      )
      .subscribe(
        (responseData) => {
          let msg = '';
          if (this.selected.effort < 4) {
            msg =
              'You have entered a low effort rating for this exercise. To get the most benefits, your effort level should be between 6-8. Try increasing the resistance/weight by changing the band colour, try to do some more repetitions, or reduce the rest time to make this exercise harder. Remember to try different exercises if you find this one easy';
          }
          if (this.selected.effort > 8) {
            msg =
              'You have entered a high effort rating for this exercise. To get the most benefits, your effort level should be between 6-8. Try reducing the resistance/weight by changing the band colour, try to do some less repetitions, or increase the rest time to make this exercise easier. Remember to try different exercises if you find this one hard';
          }
          // set chart data
          Swal.fire({
            icon: 'success',
            title: 'Exercise added!',
            text: 'Your entry was successfully added. ' + msg,
            showConfirmButton: true,
          });
          this.router.navigateByUrl(
            '/activity/strength/view/' + this.workoutId
          );
        },
        (error) => {
          console.log(error);
        }
      );
  }
}
