<!--div class="section-nav-mobile-sub"><app-section-nav
[section]="'healthTrackers'"
></app-section-nav>
</div-->
<nav class="subnav">
  <h2>
    <span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'comments']"></fa-icon
    ></span>
    OVERVIEW
  </h2>
</nav>
<div class="decision-maker">
  <div
    class="toggle-header toggle-header-left" [routerLink]="['/chat', 'ask-the-expert']"
  >
    <a
      class="toggle-nav toggle-nav-expand" [routerLink]="['/chat', 'ask-the-expert']"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
    ></a>
    <div class="toggle-header-title">
        <span
          class="icon-wrap section-nav-icon"
          ><fa-icon [icon]="['fas', 'user-md-chat']" [fixedWidth]="true"></fa-icon
        ></span>
        {{ environment.navLabels["askTheExpert"] | uppercase}}
    </div>
    
  </div>

  <div
    class="toggle-header toggle-header-left" [routerLink]="['/chat']"
  >
    <a
      class="toggle-nav toggle-nav-expand"
      [routerLink]="['/chat']"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
    ></a>
    <div class="toggle-header-title">
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'comment']"></fa-icon
      ></span>
      FORUM
    </div>
    <div class="values">
      
    </div>
  </div>
  <div
    class="toggle-header toggle-header-left" [routerLink]="['/chat', 'technical-support']"
  >
    <a
      class="toggle-nav toggle-nav-expand" [routerLink]="['/chat', 'technical-support']"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
    ></a>
    <div class="toggle-header-title">
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'user-headset']"></fa-icon
      ></span>
      TECHNICAL SUPPORT
    </div>
    
  </div>
</div>

<!--div class="section-nav-mobile-sub">
  <nav class="section-nav" *ngIf="currentProgramme && currentUser">
    <ul>
      <li
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="currentProgramme.config.askTheExpert == 1"
      >
        <a [routerLink]="['/chat', 'ask-the-expert']"
          ><span class="icon-wrap section-nav-icon"
            ><fa-icon
              [icon]="['fas', 'user-md-chat']"
              [fixedWidth]="true"
            ></fa-icon></span
          ><span class="section-nav-label">{{
            environment.navLabels["askTheExpert"]
          }}</span>

          <span class="section-nav-arrow"
            ><fa-icon
              [icon]="['far', 'arrow-circle-right']"
              [fixedWidth]="true"
            ></fa-icon
          ></span>
        </a>
      </li>

      <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a [routerLink]="['/chat']"
          ><span class="icon-wrap section-nav-icon"
            ><fa-icon
              [icon]="['fas', 'users']"
              [fixedWidth]="true"
            ></fa-icon></span
          ><span class="section-nav-label">{{
            environment.navLabels["chat"]
          }}</span>
          <span class="section-nav-arrow"
            ><fa-icon
              [icon]="['far', 'arrow-circle-right']"
              [fixedWidth]="true"
            ></fa-icon></span
        ></a>
      </li>
      <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a [routerLink]="['/chat', 'technical-support']"
          ><span class="icon-wrap section-nav-icon"
            ><fa-icon
              [icon]="['fas', 'user-headset']"
              [fixedWidth]="true"
            ></fa-icon></span
          ><span class="section-nav-label">Technical Support</span>
          <span class="section-nav-arrow"
            ><fa-icon
              [icon]="['far', 'arrow-circle-right']"
              [fixedWidth]="true"
            ></fa-icon></span
        ></a>
      </li>
    </ul>
  </nav>
</div-->
