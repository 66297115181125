import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { AuthService } from './../auth/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SmokingService {
  userId;
  endPoint = '/user_smokings';
  allEntries?: any;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userId = user.id;
      }
    });
  }

  fetchAll(): Observable<any> {
    if (!this.userId) {
      return;
    }
    if (!this.allEntries) {
      let searchParams = new HttpParams();

      searchParams = searchParams.append('user_id', this.userId);
      searchParams = searchParams.append('sort', 'created');
      searchParams = searchParams.append('order', 'DESC');
      this.allEntries = this.http
        .get<any>(environment.apiUrl + this.endPoint, {
          params: searchParams,
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            return responseData._embedded.user_smokings;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.allEntries;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.endPoint + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(cigarettes, tobacco, cigars, pipe_tobacco, entryDate) {
    this.clearCache();
    const postData = {
      user_id: this.userId,
      cigarettes,
      tobacco,
      cigars,
      pipe_tobacco,
      created: entryDate,
    };
    return this.http
      .post<{ name: string }>(environment.apiUrl + this.endPoint, postData, {
        observe: 'response',
      })
      .pipe(
        map((responseData) => {
          return responseData.body;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  update(id, cigarettes, tobacco, cigars, pipe_tobacco) {
    this.clearCache();
    const postData = {
      cigarettes,
      tobacco,
      cigars,
      pipe_tobacco,
    };
    return this.http.patch<{ name: string }>(
      environment.apiUrl + this.endPoint + '/' + id,
      postData,
      {
        observe: 'response',
      }
    );
  }

  delete(id) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.endPoint + '/' + id
    );
  }

  clearCache() {
    this.allEntries = null;
  }
}
