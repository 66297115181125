<!--div class="section-nav-mobile-sub"><app-section-nav
[section]="'activity'"
></app-section-nav>
</div-->

<nav class="subnav">
    <h2>
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'walking']"></fa-icon
      ></span>
      OVERVIEW
    </h2> 
  </nav>
  <div class="decision-maker" *ngIf="currentUser">

    <div
      class="toggle-header toggle-header-left"
      [routerLink]="['/steps']"
      *ngIf="currentUser.mobility3!='0'&& currentUser.mobility1!='f'"
    >
      <a
        class="toggle-nav toggle-nav-expand"
        [routerLink]="['/steps']"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
      ></a>
      <div class="toggle-header-title">
          <span
            class="icon-wrap section-nav-icon"
            ><fa-icon [icon]="['fas', 'shoe-prints']" [fixedWidth]="true"></fa-icon
          ></span>
         
        MY STEPS
      </div>
      <div class="values" *ngIf="weekTotal">
        <strong>{{weekTotal | number}}</strong> steps<br> completed this week
      </div>
    </div>

    <div
      class="toggle-header toggle-header-left"
      [routerLink]="['/challenges']"
      *ngIf="currentUser.mobility && currentUser.mobility1!='f'"
    >
      <a
        class="toggle-nav toggle-nav-expand"
        [routerLink]="['/challenges']"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
      ></a>
      <div class="toggle-header-title">
          <span
            class="icon-wrap section-nav-icon"
            ><fa-icon [icon]="['fas', 'trophy']" [fixedWidth]="true"></fa-icon
          ></span>
         
        CHALLENGES
      </div>
      <div class="values" *ngIf="challengePos">
          <strong>{{challengePos | ordinal}}</strong> position<br>{{currentChallenge.title}}
      </div>
    </div>

    <div
      *ngIf="currentProgramme.config.trackers.indexOf('strength') > -1"
      class="toggle-header toggle-header-left"
      [routerLink]="['/activity', 'strength']"
    >
      <a
        class="toggle-nav toggle-nav-expand"
        [routerLink]="['/activity', 'strength']"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
      ></a>
      <div class="toggle-header-title">
          <span
            class="icon-wrap section-nav-icon"
            ><fa-icon [icon]="['far', 'dumbbell']" [fixedWidth]="true"></fa-icon
          ></span>
         
        STRENGTH
      </div>
      <div class="values" >
          
      </div>
    </div>

    <div
      class="toggle-header toggle-header-left"
      [routerLink]="['/friends-and-family']"
      *ngIf="currentProgramme.config.buddies==1 && !currentUser.buddy"
    >
      <a
        class="toggle-nav toggle-nav-expand"
        [routerLink]="['/friends-and-family']"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
      ></a>
      <div class="toggle-header-title">
          <span
            class="icon-wrap section-nav-icon"
            ><fa-icon [icon]="['fas', 'users']" [fixedWidth]="true"></fa-icon
          ></span>
         
        BUDDIES
      </div>
      <div class="values" *ngIf="invites">
        <strong>{{invites.length}}</strong> buddies<br>
        <strong>{{totalInvites - invites.length}}</strong> invites remaining
      </div>
    </div>



    <div
      class="toggle-header toggle-header-left"
      [routerLink]="['/target']"
      *ngIf="currentUser.mobility3!='0' && currentUser.mobility1!='f'"
    >
      <a
        class="toggle-nav toggle-nav-expand"
        [routerLink]="['/target']"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
      ></a>
      <div class="toggle-header-title">
          <span
            class="icon-wrap section-nav-icon"
            ><fa-icon [icon]="['fas', 'bullseye']" [fixedWidth]="true"></fa-icon
          ></span>
         
        UPDATE DAILY STEPS TARGET
      </div>
      <div class="values" *ngIf="currentTarget">
        Target: <strong>{{currentTarget | number}}</strong>
      </div>
    </div>


    <div
      class="toggle-header toggle-header-left"
      [routerLink]="['/activity', 'workouts']"
      *ngIf="currentProgramme.config.trackers.indexOf('workouts') > -1"
    >
      <a
        class="toggle-nav toggle-nav-expand"
        [routerLink]="['/activity', 'workouts']"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
      ></a>
      <div class="toggle-header-title">
          <span
            class="icon-wrap section-nav-icon"
            ><fa-icon [icon]="['fas', 'running']" [fixedWidth]="true"></fa-icon
          ></span>
         
        MY WORKOUTS
      </div>
      <div class="values"  *ngIf="!isFetching">
        <strong>{{weekWorkouts.length | number}}</strong> workouts<br> completed this week
      </div>
    </div>

    <div
    class="toggle-header toggle-header-left"
    [routerLink]="['/activity', 'minutes']"
    *ngIf="currentProgramme.config.trackers.indexOf('minutes') > -1"
  >
    <a
      class="toggle-nav toggle-nav-expand"
      [routerLink]="['/activity', 'minutes']"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
    ></a>
    <div class="toggle-header-title">
        <span
          class="icon-wrap section-nav-icon"
          ><fa-icon [icon]="['far', 'stopwatch']" [fixedWidth]="true"></fa-icon
        ></span>
       
      MY MINUTES
    </div>
    <div class="values"  *ngIf="(weekTotalMins || weekTotalMins===0) && !isFetching">
      <strong>{{weekTotalMins | number}}</strong> mins<br> completed this week
    </div>
  </div>

    <div
      *ngIf="currentProgramme.config.trackers.indexOf('sitting') > -1"
      class="toggle-header toggle-header-left"
      [routerLink]="['/activity', 'sitting']"
    >
      <a
        class="toggle-nav toggle-nav-expand"
        [routerLink]="['/activity', 'sitting']"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
      ></a>
      <div class="toggle-header-title">
          <span
            class="icon-wrap section-nav-icon"
            ><fa-icon [icon]="['far', 'couch']" [fixedWidth]="true"></fa-icon
          ></span>
         
          <span *ngIf="!(currentUser.mobility1 == 'e' || currentUser.mobility1 == 'f')">MY SITTING</span><span *ngIf="currentUser.mobility1 == 'e' || currentUser.mobility1 == 'f'">MY SEDENTARY BEHAVIOUR</span>
      </div>
      <div class="values"  *ngIf="(weekTotalSitting || weekTotalSitting===0) && !isFetching">
        <strong>{{weekTotalSitting | minsToHours: "hoursOnly"}}</strong>h <strong>{{weekTotalSitting | minsToHours: "minutesOnly"}}</strong>m <br> sitting this week
      </div>
    </div>

    <div
      *ngIf="currentProgramme.config.trackers.indexOf('sleep') > -1"
      class="toggle-header toggle-header-left"
      [routerLink]="['/activity', 'sleep']"
    >
      <a
        class="toggle-nav toggle-nav-expand"
        [routerLink]="['/activity', 'sleep']"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
      ></a>
      <div class="toggle-header-title">
          <span
            class="icon-wrap section-nav-icon"
            ><fa-icon [icon]="['far', 'bed']" [fixedWidth]="true"></fa-icon
          ></span>
         
          MY SLEEP
      </div>
      <div class="values"  *ngIf="(weekTotalSitting || weekTotalSitting===0) && !isFetching">
        <strong>{{sleepDaysHit}}</strong>  night<span *ngIf="sleepDaysHit!=1">s</span><br> hit this week
      </div>
    </div>

    <div
      class="toggle-header toggle-header-left"
      [routerLink]="['/achievements']"
    >
      <a
        class="toggle-nav toggle-nav-expand"
        [routerLink]="['/achievements']"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
      ></a>
      <div class="toggle-header-title">
          <span
            class="icon-wrap section-nav-icon"
            ><fa-icon [icon]="['fas', 'medal']" [fixedWidth]="true"></fa-icon
          ></span>
         
        ACHIEVEMENTS
      </div>
      <div class="values" *ngIf="awards">
          <strong>{{awards.length}}</strong> medal<span *ngIf="awards.length!=1">s</span>
      </div>
    </div>

    <div
      class="toggle-header toggle-header-left"
      [routerLink]="['/setup-device']"
    >
      <a
        class="toggle-nav toggle-nav-expand"
        [routerLink]="['/setup-device']"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
      ></a>
      <div class="toggle-header-title">
          <span
            class="icon-wrap section-nav-icon"
            ><fa-icon [icon]="['fas', 'watch-fitness']" [fixedWidth]="true"></fa-icon
          ></span>
         
        SETUP DEVICE
      </div>
      <div class="values" *ngIf="deviceStatus && currentUser">
          Current: 
          <span *ngIf="deviceStatus.deviceType"><strong>{{deviceStatus.deviceType | titlecase}}</strong></span>
          <span *ngIf="!deviceStatus.deviceType && currentUser.manual_steps">Manual</span>
          <span *ngIf="!deviceStatus.deviceType && !currentUser.manual_steps">N/A</span>
      </div>
    </div>
  
    
  </div>
  