import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LearningMaterialService {
  cacheTime = new Date();
  cacheLifetime = 3 * 60 * 1000;
  chachedPages: any = {};

  constructor(private http: HttpClient) {}

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + '/content_sections/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchPage(id: number) {
    return this.http
      .get<any>(environment.apiUrl + '/content_pages/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
  fetchPages(id: number): Observable<any> {
    const now = new Date();

    if (
      !this.chachedPages[id] ||
      Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime
    ) {
      this.cacheTime = new Date();

      let httpParams = new HttpParams();
      httpParams = httpParams.append('content_section_id', '' + id);
      httpParams = httpParams.append('sort', 'sort_order');
      httpParams = httpParams.append('order', 'ASC');
      this.chachedPages[id] = this.http
        .get<any>(environment.apiUrl + '/content_pages', {
          params: httpParams,
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            return responseData._embedded.content_pages;
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.chachedPages[id];
  }

  clearCache() {
    this.chachedPages = {};
  }
}
