import { ConfigService } from './../../services/config.service';
import { Router } from '@angular/router';
import { UserSettingsService } from './../../services/user-settings.service';
import { NgForm } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { SmokingService } from './../../services/smoking.service';
import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { environment } from './../../../environments/environment';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-smoking',
  templateUrl: './smoking.component.html',
  styleUrls: ['./smoking.component.scss'],
})
export class SmokingComponent implements OnInit {
  currentUser;
  currentProgramme;
  userSmokings;
  userSmoking;
  isFetching = false;
  errors = [];
  showAllRows = false;
  environment = environment;
  userSettings;
  userSmokingType;

  toggleRow = {
    update: false,
    graph: false,
    entry: false,
  };

  toggleChart = {
    systolic: true,
  };

  smokingLabels = {
    cigarettes: 'Cigarettes',
    cigars: 'cigars',
    tobacco: 'Tobacco',
    pipe_tobacco: 'Pipe Tobacco',
  };
  cost = 0;
  configs;

  entryDate = new Date();
  maxDate = new Date();

  @ViewChild('trackerForm', { static: false }) trackerForm: NgForm;

  // Line chart
  public chartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            beginAtZero: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          gridLines: {
            display: true,
            lineWidth: 1,
            color: '#95989A',
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            lineWidth: 2,
            color: '#95989A',
          },
          type: 'time',
          ticks: {
            autoSkip: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          time: {
            parser: 'YYYY-MM-DD HH:mm:ss',
            unit: 'day',
            displayFormats: {
              day: 'MMM-DD',
            },
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public chartLabels: Label[] = [];
  public chartType: ChartType = 'line';
  public chartLegend = true;

  public chartColors: Color[] = [{ backgroundColor: environment.primaryColor }];

  public smokingChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'Smoking',
      borderWidth: 1,
      borderColor: environment.chartDefaults.colors[0],
      fill: false,
      pointRadius: 2,
    },
  ];
  //
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private smokingService: SmokingService,
    private userSettingsService: UserSettingsService,
    private router: Router,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.isFetching = true;
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (this.currentProgramme) {
          // this.barChartColors[0].backgroundColor = this.currentProgramme.config.primaryColor;

          forkJoin({
            smokings: this.smokingService.fetchAll(),
            userSettings: this.userSettingsService.fetchAll(),
            configs: this.configService.fetchAll(),
          }).subscribe(
            (responseData) => {
              this.isFetching = false;
              this.userSettings = responseData.userSettings;
              this.userSmokingType = this.userSettings.smoking_type;
              this.configs = responseData.configs;

              if (!this.userSettings.smoking_type) {
                this.router.navigate(['/health-trackers', 'smoking-settings']);
              }
              this.userSmokings = responseData.smokings;
              if (this.userSmokings.length > 0) {
                this.userSmoking = this.userSmokings[0];

                switch (this.userSmokingType) {
                  case 'cigarettes':
                    this.cost =
                      this.userSmokings[0].cigarettes *
                      this.configs['cigarette_cost'] *
                      365;
                    break;
                  case 'tobacco':
                    this.cost =
                      this.userSmokings[0].tobacco *
                      this.configs['tobacco_cost'] *
                      365;

                    break;
                  case 'cigars':
                    this.cost =
                      this.userSmokings[0].cigars *
                      this.configs['cigar_cost'] *
                      365;

                    break;
                  case 'pipe_tobacco':
                    this.cost =
                      this.userSmokings[0].pipe_tobacco *
                      this.configs['pipe_tobacco_cost'] *
                      365;

                    break;
                }
              }
              this.setChartData(this.userSmokings);
              // end
            },
            (error) => {
              this.isFetching = false;
              this.errors.push(error.message);
            }
          );
        }
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });
    }
  }

  onRemove(smoking) {
    this.smokingService.delete(smoking.user_smoking_id).subscribe(
      (responseData) => {
        const index = this.userSmokings.indexOf(smoking);
        this.userSmokings.splice(index, 1);
        this.setChartData(this.userSmokings);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onSubmit() {
    // this.trackerForm.value.date

    if (!this.trackerForm.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {

      let cigarettes = null;
      let tobacco = null;
      let cigars = null;
      let pipeTobacco = null;
  
      switch (this.userSmokingType) {
        case 'cigarettes':
          cigarettes = this.trackerForm.value.cigarettes;
          break;
        case 'tobacco':
          tobacco = this.trackerForm.value.tobacco;
          break;
        case 'cigars':
          cigars = this.trackerForm.value.cigars;
          break;
        case 'pipeTobacco':
          pipeTobacco = this.trackerForm.value.pipeTobacco;
          break;
      }

      this.smokingService
        .create(
          cigarettes,
          tobacco,
          cigars,
          pipeTobacco,
          moment(this.entryDate).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
            this.userSmokings.unshift(responseData);
            this.userSmokings.sort(this.sortByCreated);
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.trackerForm.resetForm();
            this.entryDate = new Date();
            this.setChartData(this.userSmokings);
            switch (this.userSmokingType) {
              case 'cigarettes':
                this.cost =
                  this.userSmokings[0].cigarettes *
                  this.configs['cigarette_cost'] *
                  365;
                break;
              case 'tobacco':
                this.cost =
                  this.userSmokings[0].tobacco *
                  this.configs['tobacco_cost'] *
                  365;

                break;
              case 'cigars':
                this.cost =
                  this.userSmokings[0].cigars *
                  this.configs['cigar_cost'] *
                  365;

                break;
              case 'pipe_tobacco':
                this.cost =
                  this.userSmokings[0].pipe_tobacco *
                  this.configs['pipe_tobacco_cost'] *
                  365;

                break;
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  // end chart
  updatedToday() {
    let hasUpdated = false;
    this.userSmokings.forEach((Smoking) => {
      if (
        moment(Smoking.created).format('MM DD YY') ===
        moment().format('MM DD YY')
      ) {
        hasUpdated = true;
      }
    });
    return hasUpdated;
  }

  setChartData(trackerData) {
    this.smokingChartData[0].data = [];
    this.chartLabels = [];
    // setup chart data
    trackerData.forEach((value, key) => {
      if(value[this.userSmokingType]){
        this.chartLabels.push(moment(value.created).format('YYYY-MM-DD'));
        this.smokingChartData[0].data.push(value[this.userSmokingType]);
      }
    });
  }

  sortByCreated(a, b) {
    if (a.created > b.created) {
      return -1;
    }
    if (a.created < b.created) {
      return 1;
    }
    return 0;
  }
}
