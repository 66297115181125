import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserEgfrService {
  error = new Subject<string>();
  slug = '/user_egfrs';
  resourceName = 'user_egfrs';
  allEgfrs?: any;
  egfrsBetween?: any;

  constructor(private http: HttpClient) {}

  fetchAll(): any {
    if (!this.allEgfrs) {
      this.allEgfrs = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: any[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allEgfrs;
  }

  clearCache() {
    this.allEgfrs = null;
    this.egfrsBetween = null;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(egfr: string, date_recorded: string) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      egfr,
    };
    return this.http.post<any>(environment.apiUrl + this.slug, payload, {
      observe: 'response',
    });
  }

  update(id: number, egfr: string, date_recorded: string) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      egfr,
    };
    return this.http.patch<any>(
      environment.apiUrl + this.slug + '/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + '/' + id
    );
  }

  

  getTypical(egfr, gender) {
    if (gender == 'm') {
      switch (+egfr) {
        case 27:
          return '20 - 59';
          break;
        case 26:
          return '60 - 69';
          break;
        case 23:
          return '70 - 80';
          break;
      }
    } else if (gender == 'f') {
      switch (+egfr) {
        case 27:
          return '20 - 49';
          break;
        case 24:
          return '50 - 69';
          break;
        case 23:
          return '70 - 80';
          break;
      }
    }
  }

  getRag(egfr) {
    let ragColour = '';
    if (+egfr >= 60) {
      ragColour = 'green';
    } else if (+egfr < 45) {
      ragColour = 'red';
    } else {
      ragColour = 'amber';
    }
    return ragColour;
  }

  getEgfrforColor(colour: string) {
    let ragRange = '';

    if (colour == 'green') {
      ragRange = '≥60';
    } else if (colour == 'amber') {
      ragRange = '45-59';
    } else {
      ragRange = '<45';
    }

    return ragRange;
  }
}
