import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CmsService {
  cacheTime = new Date();
  cacheLifetime = 3 * 60 * 1000;
  cmsKeyEntries: any = {};

  constructor(private http: HttpClient) {}

  fetchByKey(keys): Observable<any> {
    const now = new Date();

    if (
      !this.cmsKeyEntries[keys] ||
      Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime
    ) {
      this.cacheTime = new Date();

      let searchParams = new HttpParams();
      searchParams = searchParams.append('sort', 'created');
      searchParams = searchParams.append('order', 'DESC');
      searchParams = searchParams.append('cms_key', keys);

      this.cmsKeyEntries[keys] = this.http
        .get<any>(environment.apiUrl + '/cms', {
          responseType: 'json',
          // params: searchParams,
        })
        .pipe(
          map((responseData) => {
            const cms = {};
            responseData._embedded.cms.forEach((item) => {
              cms[item.cms_key] = item;
            });
            return cms;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.cmsKeyEntries[keys];
  }

  clearCache() {
    this.cmsKeyEntries = {};
  }
}
