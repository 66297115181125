<nav class="navbar navbar-default navbar-bs navbar-fixed-top navbar-m3-mobile">
  <button
    *ngIf="pageTitle!='home'"
    class="btn-navbar btn-side-menu visible-sm visible-xs"
    ui-sref="notifications"
    [routerLink]="['/m3','dashboard']"
    aria-label="User Menu"
  >
  <span class="icon-wrap"
  ><fa-icon [icon]="['fal', 'chevron-circle-left']"></fa-icon></span
>
  </button>

  <h1 class="mx-auto" *ngIf="pageTitle!='home'">{{pageTitle}}</h1>
  <span class="logo-text mx-auto"  *ngIf="pageTitle=='home'">
    <span 
      ><img
      src="./assets/img/m3/logo-mha.svg"
        height="50"
        alt="Programme Logo"
    ></span>
   
  </span>

  <button
    class="btn-navbar btn-user-menu visible-sm visible-xs"
    aria-label="Application Navigation"
    (click)="onToggleUserMenu()"
    data-animation="am-slide-left"
  >
    <span class="icon-wrap"><app-m3-icon [icon]="'menu'"></app-m3-icon></span>
  </button>
</nav>

<app-user-menu [hidden]="!showUserMenu" (closeMenu)="onCloseMenu($event)" (showMobileMenu)="onShowMobileMenu($event)"></app-user-menu>
<app-mobile-nav [hidden]="!showMobileMenu" (closeMobileMenu)="onCloseMobileMenu($event)" (showUserMenu)="onShowUserMenu($event)"></app-mobile-nav>