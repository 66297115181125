import { environment } from 'src/environments/environment';
import { forkJoin } from 'rxjs';
import { AssessmentsService } from './../../../services/assessments.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-step-quiz',
  templateUrl: './step-quiz.component.html',
  styleUrls: ['./step-quiz.component.scss'],
})
export class StepQuizComponent implements OnInit {
  @Input() quizId;
  quiz;
  questions;
  answers;
  qNum = 1;
  currQuestion = 1;
  selectedCorrect;
  showAnswerBtn;
  score = 0;
  environment = environment;
  answersSelected = [];

  constructor(private assessmentService: AssessmentsService) {}

  ngOnInit(): void {
    forkJoin({
      quiz: this.assessmentService.fetch(this.quizId),
      questions: this.assessmentService.fetchQuestions(this.quizId),
      answers: this.assessmentService.fetchAnswers(this.quizId),
    }).subscribe(
      (responseData) => {
        this.quiz = responseData.quiz;
        this.questions = responseData.questions;
        this.answers = responseData.answers;

        this.answers.forEach((answer, index) => {

          this.questions.forEach((question, qindex) => {
            this.questions[qindex].state = 'unanswered';
            if (
              answer.assessment_question_id == question.assessment_question_id
            ) {
              this.questions[qindex].answers.push(answer);
            }
          });

        });
      },
      (error) => {
      }
    );
  }

  getLetter(index) {
    return String.fromCharCode(65 + index);
  }

  answerQuiz(question, answers, manualAnswer) {
    const qKey = this.qNum - 1;
    this.currQuestion = question;
    // are there multiple corrects?
    let totCorrect = 0;

    this.questions[qKey].answers.forEach( (ans, index) => {
      if (ans.correct == 1) {
        totCorrect++;
      }
    });

    // multiple answers
    if (totCorrect > 1 && manualAnswer == false) {
      if (answers[0].selectedState == 'selected') {
        answers[0].selectedState = null;
        // unselect it
        this.answersSelected.forEach(function (_a, _i) {
          if (_a.assessment_answer_id == answers[0].assessment_answer_id) {
            this.answersSelected.splice(_i, 1);
          }
        });

        if (this.answersSelected.length == 0) {
          this.showAnswerBtn = false;
        }
      } else {
        this.showAnswerBtn = true;
        //
        this.answersSelected.push(answers[0]);

        // what have they selected?
        answers[0].selectedState = 'selected';
      }
    } else {
      if (this.questions[qKey].state != 'answered') {
        question.correct = true;
        question.answers.forEach( (a, index) => {
          // loop through answrs and assign value

          let selected = false;
          answers.forEach( (sa, saIndex) => {
            if (sa.assessment_answer_id == a.assessment_answer_id) {
              selected = true;
              if (sa.correct == 1) {
                this.questions[qKey].answers[index].selectedState =
                  'selectedCorrect';
              } else {
                this.questions[qKey].answers[index].selectedState =
                  'selectedIncorrect';
                question.correct = false;
              }
            }
          });

          if (selected === false) {
            if (a.correct == 1) {
              this.questions[qKey].answers[index].selectedState =
                'unselectedCorrect';
              question.correct = false;
            } else {
              this.questions[qKey].answers[index].selectedState =
                'unselectedIncorrect';
            }
          }
        });

        this.questions[qKey].state = 'answered';
        this.questions[qKey].correct = question.correct;
        if (question.correct == true) {
          this.score++;
        }
      }
      this.showAnswerBtn = false;
      this.answersSelected = [];
    }
  }

  nextQuestion() {
    this.qNum++;
    this.showAnswerBtn = false;
    this.answersSelected = [];
  }
}
