import { NotificationService } from './../../../services/notification.service';
import { ActivatedRoute } from '@angular/router';
import { CommentService } from './../../../services/comment.service';
import { forkJoin } from 'rxjs';
import { ProgrammeService } from './../../../services/programme.service';
import { UserService } from './../../../services/user.service';
import { AuthService } from './../../../auth/auth.service';
import { environment } from './../../../../environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-forum-view',
  templateUrl: './forum-view.component.html',
  styleUrls: ['./forum-view.component.scss'],
})
export class ForumViewComponent implements OnInit {
  environment = environment;

  currentUser;
  currentProgramme;
  isFetching;
  page = 1;
  perPage = 7;
  category;
  unread;
  commentViews = {};
  commentViewsClicked = {};
  commentLikes = {};
  topPage = 1;
  topic;
  comments;

  topicId;
  isDisabled;

  disabledLikes = {};
  likeLoading = [];
  fragment;

  @ViewChild('commentForm', { static: false }) commentForm: NgForm;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private commentService: CommentService,
    private route: ActivatedRoute,
    private location: Location,
    private notificationService: NotificationService,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.route.fragment.subscribe((fragment) => {
      this.fragment = fragment;
    });
    // are we logged in?
    this.isFetching = true;
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;

        this.route.params.subscribe((params) => {
          this.topicId = this.route.snapshot.params.commentId;
          this.isFetching = true;
          forkJoin({
            /* comments: this.commentService.fetchForumTopics(
              this.currentProgramme.programme_id,
              this.category,
              this.page,
              this.perPage
            ),*/
            topic: this.commentService.getTopic(this.topicId),
            topicReplies: this.commentService.getTopicReplies(this.topicId),
            commentViews: this.commentService.fetchCommentViews(),
            commentLikes: this.commentService.fetchCommentLikes(),
          }).subscribe(
            (responseData) => {
              this.topic = responseData.topic;

              this.isFetching = false;

              this.comments = responseData.topicReplies;

              responseData.commentViews.forEach((commentView) => {
                this.commentViews[commentView.comment_id] = true;
              });

              responseData.commentLikes.forEach((commentLike) => {
                this.commentLikes[commentLike.comment_id] =
                  commentLike.user_comment_like_id;
              });

              const commentIds = [];
              commentIds.push(this.topicId);
              this.comments.forEach((com, dex) => {
                commentIds.push(com.comment_id);
              });
              for (let i = 0; i < commentIds.length; i++) {
                // this has been viewed?
                if (!this.commentViews[commentIds[i]]) {
                  this.commentService
                    .createCommentView(commentIds[i])
                    .subscribe((ViewresponseData) => {
                      this.commentViews[ViewresponseData['comment_id']] = true;
                    });
                }
              }

              let titleSubject = this.topic.comment_id;
              if (this.topic.subject) {
                titleSubject = this.topic.subject;
              } else {
                if(this.topic.comment){
                  titleSubject = this.topic.comment.substring(0, 100);
                }
              }
              this.titleService.setTitle(
                environment.title + ' | Forum | Topic | ' + titleSubject
              );

              // do we need to scroll?
              setTimeout(() => {
                try {
                  if (this.fragment) {
                    const element = document.querySelector('#' + this.fragment);
                    if (element) {
                      setTimeout(() => {
                        element.scrollIntoView({
                          behavior: 'smooth',
                          block: 'start',
                          inline: 'nearest',
                        });
                      }, 500);
                    }
                  }
                } catch (e) {}
              }, 500);
            },
            (error) => {
              this.isFetching = false;
              console.log(error);
            }
          );
        });
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });
    }
  }

  checkReplies(comment) {
    let read = true;
    comment.reply_ids.forEach((cr, i) => {
      if (!this.commentViews[cr]) {
        read = false;
      }
    });

    return read;
  }

  getCategory(comment) {
    let category = comment.comment_type_ref;
    category = category.replace('topic', '');

    if (!category) {
      return 'General';
    } else {
      return category;
    }
  }

  onSubmit() {
    this.isDisabled = true;

    if (!this.commentForm.valid) {
      Swal.fire({
        title: "We notice you've missed something",
        text: 'Please check the form for information that has been missed',
        icon: 'error',
      });
      this.isDisabled = false;
    } else {
      this.commentService
        .createTopicReply(this.topicId, this.commentForm.value.comment)
        .subscribe(
          (responseData) => {
            const comment = responseData;
            comment['first_name'] = this.currentUser.first_name;
            comment['last_name'] = this.currentUser.last_name;
            comment['avatar'] = this.currentUser.avatar;
            this.comments.push(comment);
            this.commentForm.reset();
            this.isDisabled = false;
          },

          (error) => {
            console.log(error);
          }
        );
    }
  }

  onBack() {
    this.location.back();
  }

  // like function
  likeComment(comment) {
    this.likeLoading.push(comment.comment_id);
    // if (!this.disabledLikes[comment.comment_id]) {
    // this.commentLikes[comment.comment_id] = -1;
    forkJoin({
      likeComment: this.commentService.likeComment(
        comment.comment_id,
        comment.comment_type,
        comment.comment_type_ref
      ),
      updateComment: this.commentService.updateComment(comment.comment_id, {
        likes: +comment.likes + 1,
      }),
    }).subscribe(
      (responseData) => {
        comment.likes = +comment.likes + 1;
        this.commentLikes[comment.comment_id] =
          responseData.likeComment['user_comment_like_id'];
        // this.disabledLikes[comment.comment_id] = false;

        const likeIndex: number = this.likeLoading.indexOf(comment.comment_id);
        if (likeIndex !== -1) {
          this.likeLoading.splice(likeIndex, 1);
        }

        this.notificationService
          .addNotification(
            comment.user_id,
            'like',
            this.currentUser.screen_name +
              ' liked your comment in ' +
              this.topic.comment_type.replace('_', ' ') +
              '.',
            '/app/chat/view/' +
              this.topic.comment_type +
              '/' +
              this.topic.comment_id,
            0
          )
          .subscribe((notificaitonResponse) => {});
      },
      (error) => {
        const likeIndex: number = this.likeLoading.indexOf(comment.comment_id);
        if (likeIndex !== -1) {
          this.likeLoading.splice(likeIndex, 1);
        }
      }
    );
    // }
  }
  unlikeComment(comment) {
    this.likeLoading.push(comment.comment_id);
    const commentIdToUnlike = this.commentLikes[comment.comment_id];
    if (commentIdToUnlike != -1) {
      delete this.commentLikes[comment.comment_id];
      //  if (!this.disabledLikes[comment.comment_id]) {
      //  this.disabledLikes[comment.comment_id] = true;
      forkJoin({
        unlikeComment: this.commentService.unlikeComment(commentIdToUnlike),
        updateComment: this.commentService.updateComment(comment.comment_id, {
          likes: +comment.likes - 1,
        }),
      }).subscribe(
        (responseData) => {
          comment.likes = +comment.likes - 1;
          const likeIndex: number = this.likeLoading.indexOf(
            comment.comment_id
          );
          if (likeIndex !== -1) {
            this.likeLoading.splice(likeIndex, 1);
          }
          //  delete this.commentLikes[comment.comment_id];
          // this.disabledLikes[comment.comment_id] = false;
        },
        (error) => {
          const likeIndex: number = this.likeLoading.indexOf(
            comment.comment_id
          );
          if (likeIndex !== -1) {
            this.likeLoading.splice(likeIndex, 1);
          }
        }
      );
    }
    // }
  }

  reportComment(comment) {
    Swal.fire({
      title: 'Report Comment',
      text: 'Are you sure you want to report this comment?',
      icon: 'info',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((value) => {
      if (value.value === true) {
        this.commentService
          .updateComment(comment.comment_id, { status: 1 })
          .subscribe((responseData) => {
            comment.status = 1;
            this.isDisabled = false;
          });
      }
    });
  }

  checkEmpty(comment) {
    if (comment) {
      return comment.trim() === '';
    } else {
      return true;
    }
  }
}
