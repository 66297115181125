import { AuthService } from './../auth/auth.service';
import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Observable, Subject, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TargetService {
  userId;

  cacheTime = new Date();
  cacheLifetime = 3 * 60 * 1000;
  userTargets: any = {};

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userId = user.id;
      }
    });
  }

  getTarget(type = 'step'): Observable<any> {
    const now = new Date();
    if (
      !this.userTargets[type] ||
      Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime
    ) {
      this.cacheTime = new Date();
      let searchParams = new HttpParams();
      if (type) {
        searchParams = searchParams.append('target_type', type);
      }
      searchParams = searchParams.append('user_id', this.userId);
      searchParams = searchParams.append('sort', 'created');
      searchParams = searchParams.append('order', 'DESC');
      this.userTargets[type] = this.http
        .get<any>(environment.apiUrl + '/user_targets', {
          params: searchParams,
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            if (responseData._embedded.user_targets[0]) {
              return responseData._embedded.user_targets[0];
            } else {
              return;
            }
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.userTargets[type];
  }
  clearCache() {
    this.userTargets = {};
  }

  create(target, target_type, target_unit) {
    this.clearCache();
    const postData = {
      user_id: this.userId,
      target,
      target_type,
      target_unit,
    };
    return this.http
      .post<{ name: string }>(environment.apiUrl + '/user_targets', postData, {
        observe: 'response',
      })
      .pipe(
        map((responseData) => {
          return responseData.body;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
}
