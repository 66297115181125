import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserSss8Service {
  error = new Subject<string>();
  slug = '/user_sss8s';
  resourceName = 'user_sss8s';
  allSss8s?: any;
  sss8sBetween?: any;

  constructor(private http: HttpClient) {}

  fetchAll(): any {
    if (!this.allSss8s) {
      this.allSss8s = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: any[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allSss8s;
  }

  clearCache() {
    this.allSss8s = null;
    this.sss8sBetween = null;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(sss8: string, date_recorded: string) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      sss8,
    };
    return this.http.post<any>(environment.apiUrl + this.slug, payload, {
      observe: 'response',
    });
  }

  update(id: number, sss8: string, date_recorded: string) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      sss8,
    };
    return this.http.patch<any>(
      environment.apiUrl + this.slug + '/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + '/' + id
    );
  }

  getRag(sss8) {
    let ragColour = '';

    if (+sss8 <8) {
      ragColour = 'green';
    } else if (+sss8 > 7 && +sss8 < 12) {
      ragColour = 'amber';
    } else {
      ragColour = 'red';
    }

    return ragColour;
  }

  getRangeforColor(colour: string) {
    let ragRange = '';
    if (colour == 'green') {
      ragRange = '0-7';
    } else if (colour == 'amber') {
      ragRange = '8-11';
    } else {
      ragRange = '>12';
    }

    return ragRange;
  }
}
