import { map, catchError } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { AuthService } from './../auth/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SymptomsService {
  userId;
  endPoint = '/user_symptoms';

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userId = user.id;
      }
    });
  }

  fetchAll() {
    if (!this.userId) {
      return;
    }
    let searchParams = new HttpParams();

    searchParams = searchParams.append('user_id', this.userId);
    searchParams = searchParams.append('sort', 'created');
    searchParams = searchParams.append('order', 'DESC');
    return this.http
      .get<any>(environment.apiUrl + this.endPoint, {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.user_symptoms;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchSypmtom(symptom){
    if (!this.userId) {
      return;
    }
    let searchParams = new HttpParams();

    searchParams = searchParams.append('user_id', this.userId);
    searchParams = searchParams.append('symptom', symptom);
    searchParams = searchParams.append('sort', 'symptom_date');
    searchParams = searchParams.append('order', 'DESC');
    return this.http
      .get<any>(environment.apiUrl + this.endPoint, {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.user_symptoms;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }


  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.endPoint + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(
    symptom,
    frequency,
    importance,
    date
  ) {
    const postData = {
      user_id: this.userId,
      symptom,
      frequency,
      importance,
      symptom_date: date
    };
    return this.http.post<{ name: string }>(
      environment.apiUrl + this.endPoint,
      postData,
      {
        observe: 'response',
      }
    ).pipe(
      map((responseData) => {
        return responseData.body;
      }),
      catchError((errorRes) => {
        return throwError(errorRes);
      }));
  }

  update(
    id,
    symptom,
    frequency,
    importance,
    symptom_date
  ) {
    const postData = {
      symptom,
      frequency,
      importance,
      symptom_date
    };
    return this.http.patch<{ name: string }>(
      environment.apiUrl + this.endPoint + '/' + id,
      postData,
      {
        observe: 'response',
      }
    );
  }

  delete(id) {
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.endPoint + '/' + id
    );
  }
}
