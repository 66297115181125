<div class="assessment-container">
  <div class="container-lg">
    <nav class="subnav" *ngIf="cms">
      <h2>
        <span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'info']"></fa-icon
        ></span>
        {{ cms.item.title | uppercase }}
      </h2>
    </nav>
    <div *ngIf="isFetching">
      <app-loading-spinner [inline]="1"></app-loading-spinner>
    </div>
    <div class="container container-pad" *ngIf="cms">
      <div [innerHtml]="cms.item.content | safeHtml"></div>
      <hr class="divider" />
      <div class="text-center">
      <a (click)="goNext()" class="btn btn-primary"
        >CONTINUE
        <span class="icon-wrap"
          ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span
      ></a>
    </div>
    </div>
  </div>
</div>
