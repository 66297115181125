<div class="container m3" *ngIf="currentUser">
  <app-m3-header class="d-none d-sm-block"></app-m3-header>
  <app-m3-mobile-navbar
    [pageTitle]="'Walking'"
    class="d-block d-sm-none"
  ></app-m3-mobile-navbar>
  <nav class="subnav d-block d-sm-none">
    <h2>Overview</h2>
  </nav>
  <h1 class="m3-title mt-3 d-none d-sm-block">
    <a class="add-item-btn" (click)="addModal.show()"
      ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
    ></a>
    <app-m3-icon [icon]="'walking'"></app-m3-icon> Daily Stepping
  </h1>

  <button
    (click)="addModal.show()"
    class="btn btn-mobile-add d-block d-sm-none"
  >
    <app-m3-icon [icon]="'more'" [style]="'color'"></app-m3-icon> Add a Walking
    Entry
  </button>

  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="info-stat info-stat-rag mb-2" *ngIf="stepAverage">
            <div class="info-stat-rag-current">
              <app-m3-icon [icon]="'walking-alt'"></app-m3-icon>
              <span class="info-stat-label"
                >My average daily steps in the last 7 days</span
              >
              <span
                class="info-stat-stat text-rag-{{ getStepsRag(stepAverage) }}"
                >{{ stepAverage | number : "1.0-0" }}</span
              >
            </div>
            <div class="rag-details">
              <div class="rag-details-target d-flex">
                Optimal population level:
                <ng-template #popTemplateStepsTarget>
                  <div>
                    The optimal level of &gt;7,500 steps may differ to the
                    current weekly target. Feedback should be based on meeting
                    and progressing the current target where this is available.
                  </div></ng-template
                >
                &nbsp;<span class="text-rag-green" style="min-width: 106px"
                  >&gt;7,500-10,000&nbsp;<span
                    class="rag-circle pointer text-dark"
                    style="display: inline-block"
                    [popover]="popTemplateStepsTarget"
                    ><span class="icon-wrap"
                      ><fa-icon [icon]="['fas', 'info']"></fa-icon
                    ></span> </span
                ></span>
                &nbsp;
              </div>
              <div class="rag-details-history">
                History:
                <div class="rag-history">
                  <ng-template #popTemplateSteps>
                    <div>
                      <div>Range:</div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-green mr-1"></span>
                        >7,500
                      </div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-amber mr-1"></span>
                        5,000 - 7,499
                      </div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-red mr-1"></span>
                        &lt; 5000
                      </div>
                    </div></ng-template
                  >
                  <ng-container
                    *ngFor="
                      let step of weeklyAverages.slice().reverse();
                      let index = index
                    "
                  >
                    <div
                      *ngIf="index > weeklyAverages.length - 6"
                      [tooltip]="
                        (step.steps | number) +
                        ' steps, W/C ' +
                        step.activity_date
                      "
                      class="rag-circle bg-rag-{{ getStepsRag(step.steps) }}"
                    ></div>
                  </ng-container>
                  <div class="rag-circle pointer" [popover]="popTemplateSteps">
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['fas', 'info']"></fa-icon
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info-stat info-stat-target" *ngIf="changeInSteps">
            <app-m3-icon [icon]="'target'" class="text-rag-green"></app-m3-icon>
            <span class="info-stat-label">average daily step change </span>
            <span
              class="info-stat-stat"
              [ngClass]="{
                'text-rag-green': +changeInSteps >= 500,
                'text-rag-amber': +changeInSteps < 500 && changeInSteps > -500,
                'text-rag-red': +changeInSteps <= -500
              }"
              *ngIf="changeInSteps >= 0"
              ><fa-icon [icon]="['fas', 'arrow-up']"></fa-icon
              >{{ changeInSteps | number }}</span
            >
            <span
              class="info-stat-stat"
              *ngIf="changeInSteps < 0"
              [ngClass]="{
                'text-rag-green': +changeInSteps >= 500,
                'text-rag-amber': +changeInSteps < 500 && changeInSteps > -500,
                'text-rag-red': +changeInSteps <= -500
              }"
              ><fa-icon [icon]="['fas', 'arrow-down']"></fa-icon
              >{{ changeInSteps * -1 | number }}</span
            >
          </div>

          <div class="info-stat info-stat-target" *ngIf="personalisedTarget">
            <app-m3-icon [icon]="'target'" class="text-rag-green"></app-m3-icon>
            <span class="info-stat-label"
              >Your updated personalised step target is
            </span>
            <span class="info-stat-stat text-rag-green">{{
              personalisedTarget | number
            }}</span>
          </div>

          <app-m3-walking-chart
            [allTimeSteps]="allTimeSteps"
            *ngIf="allTimeSteps"
          ></app-m3-walking-chart>

          <h3 class="chart-title text-center mt-4" *ngIf="allTimeSteps">
            Your Entries
          </h3>
          <div class="table-responsive" *ngIf="allTimeSteps">
            <table *ngIf="allTimeSteps" class="table">
              <thead>
                <tr>
                  <th>DATE</th>
                  <th>Steps</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="
                    let step of allTimeSteps.slice().reverse();
                    let index = index
                  "
                >
                  <tr *ngIf="step.steps > 0 && (index < 10 || showAllEntries)">
                    <td class="text-center">
                      {{ step.activity_date | dateFormatter : "MMM Do YYYY" }}
                    </td>
                    <td class="text-center">
                      {{ step.steps | number }}
                    </td>

                    <td>
                      <a
                        [swal]="{
                          title: 'Are you sure you want to delete this?',
                          icon: 'warning',
                          showCancelButton: true
                        }"
                        (confirm)="onDelete(step)"
                        class="pointer"
                        ><span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                      ></a>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <div class="text-center">
              <button
                *ngIf="!showAllEntries && allTimeSteps.length > 10"
                class="btn btn-primary btn-sm btn-xs"
                (click)="showAllEntries = true"
              >
                Show all
              </button>
            </div>
          </div>

          <div class="text-center" *ngIf="currentUser.device != 'manual'">
            <button
              class="btn btn-white"
              (click)="syncSteps(1)"
              style="margin: 10px 0 0 0; font-size: 12px"
            >
              <span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'sync']"></fa-icon
              ></span>
              SYNC STEPS
            </button>
          </div>
        </div>
        <div class="col-md-6">
          <div class="info-stat info-stat-rag mb-2">
            <div class="info-stat-rag-current">
              <span class="text-rag-green"
                ><app-m3-icon [icon]="'walking-alt'"></app-m3-icon
              ></span>
              <span class="info-stat-label">My daily steps target</span>
              <span class="info-stat-stat text-blue" *ngIf="currentTarget">{{
                currentTarget.target | number
              }}</span>
            </div>
            <div class="rag-details" *ngIf="currentTarget">
              <div class="rag-details-target d-flex">
                <button
                class="btn btn-white btn-sm"
                style="margin:0 0 0; font-size: 12px"
                (click)="checkStepTarget()"
              >
                <span class="icon-wrap"
                  ><fa-icon [icon]="['fas', 'sync']"></fa-icon
                ></span>
                Refresh Target
              </button>
              </div>
              <div class="rag-details-history">
                History:
                <div class="rag-history">
                  <ng-template #popTemplateStepTarget>
                    <div>
                      <div>Range:</div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-green mr-1"></span>
                        Target achieved
                      </div>

                      <div class="d-flex">
                        <span class="rag-circle bg-rag-red mr-1"></span>
                        Target not achieved
                      </div>
                    </div></ng-template
                  >
                  <ng-container
                    *ngFor="
                      let step of weeklyAverages.slice().reverse();
                      let index = index
                    "
                  >
                    <div
                      *ngIf="index > weeklyAverages.length - 6"
                      [tooltip]="
                        (step.steps | number) +
                        ' steps, W/C ' +
                        step.activity_date
                      "
                      class="rag-circle"
                      [ngClass]="{
                        'bg-rag-green': +step.steps >= +currentTarget.target,
                        'bg-rag-red': +step.steps < +currentTarget.target
                      }"
                    ></div>
                  </ng-container>
                  <div
                    class="rag-circle pointer"
                    [popover]="popTemplateStepTarget"
                  >
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['fas', 'info']"></fa-icon
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info-stat mb-2">
            <span class="text-rag-green"
              ><app-m3-icon [icon]="'walking-alt'"></app-m3-icon
            ></span>
            <span class="info-stat-label"
              >A good stepping value for someone your age is</span
            >
            <span class="info-stat-stat text-rag-green">&gt;7,500</span>
          </div>

          <div class="info-stat" *ngIf="currentUser.cadence">
            <app-m3-icon [icon]="'walking-alt'"></app-m3-icon>
            <span class="info-stat-label"
              >During 1 minute of brisk walking your step count was
            </span>
            <span
              class="info-stat-stat"
              [ngClass]="{
                'text-rag-red': currentUser.cadence < 100,
                'text-rag-green': currentUser.cadence >= 100
              }"
              >{{ currentUser.cadence }}</span
            >
          </div>
          <div class="mt-4" *ngIf="stepAverage">
            <h3 class="chart-title text-center">Steps/day reference values</h3>
            <table class="table">
              <thead>
                <tr>
                  <th></th>
                  <th class="text-left">Steps/day</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-rag-red">
                  <th>Low</th>
                  <td>&lt;5,000</td>
                </tr>
                <tr class="text-rag-amber">
                  <th>Fair</th>
                  <td>5,000-7,500</td>
                </tr>
                <tr class="text-rag-green">
                  <th>Good</th>
                  <td>7,500-10,000</td>
                </tr>
                <tr class="text-rag-green">
                  <th>Excellent</th>
                  <td>&gt;10,000</td>
                </tr>
              </tbody>
            </table>

            <div *ngIf="currentUser.cadence">
              <h3 class="chart-title text-center">
                Steps/min reference values
              </h3>
              <table class="table">
                <thead>
                  <tr>
                    <th></th>
                    <th class="text-left">Steps/min</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="text-rag-red">
                    <th>Low</th>
                    <td>&lt;100</td>
                  </tr>
                  <tr class="text-rag-green">
                    <th>Good brisk pace</th>
                    <td>≥100</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    bsModal
    #addModal="bs-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-static-name"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left">Update Steps</h4>
          <button
            type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="addModal.hide()"
          >
            <span aria-hidden="true" class="visually-hidden">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form
            *ngIf="currentUser.manual_steps"
            name="entryForm"
            class="bs-form tracker-form"
            (ngSubmit)="onSubmitEntryForm()"
            #entryForm="ngForm"
          >
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="steps">Steps</label>
                  <input
                    type="number"
                    name="steps"
                    class="form-control input-tracker input-tracker-xsm"
                    placeholder=""
                    id="steps"
                    min="1"
                    max="10000000"
                    autocomplete="off"
                    ng-required=""
                    #steps="ngModel"
                    ngModel
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="steps.touched && steps.invalid"
                  >
                    This must be number.
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="number">DATE</label>
                  <input
                    name="date"
                    type="text"
                    placeholder="DD/MM/YYYY"
                    class="form-control input-tracker input-tracker-sm"
                    bsDatepicker
                    autocomplete="off"
                    [bsConfig]="{
                      dateInputFormat: 'DD/MM/YYYY',
                      containerClass: 'theme-cv',
                      showWeekNumbers: false
                    }"
                    [(ngModel)]="date"
                    required
                    [bsValue]="date"
                    [maxDate]="date"
                    #dateInput="ngModel"
                  />
                </div>
              </div>
            </div>
            <div class="tracker-submit-container">
              <button
                type="submit"
                role="button"
                class="btn btn-primary btn-lg btn-white"
              >
                UPDATE STEPS
              </button>
            </div>
          </form>
          <div class="modal-header">
            <h4 class="modal-title pull-left">Update Walking Speed</h4>
          </div>
          <div class="modal-body">
            <form
              name="cadenceForm"
              class="bs-form tracker-form"
              (ngSubmit)="onSubmitCadenceForm()"
              #cadenceForm="ngForm"
            >
              <div class="row">
                <div class="col-md-6 offset-md-3">
                  <div class="form-group">
                    <label for="steps">Steps per Minute</label>
                    <input
                      type="number"
                      name="cadence"
                      class="form-control input-tracker input-tracker-xsm"
                      placeholder=""
                      id="cadence"
                      min="1"
                      max="10000000"
                      autocomplete="off"
                      ng-required=""
                      #cadence="ngModel"
                      ngModel
                    />
                    <div
                      class="alert alert-danger"
                      *ngIf="cadence.touched && cadence.invalid"
                    >
                      This must be number.
                    </div>
                  </div>
                </div>
              </div>
              <div class="tracker-submit-container">
                <button
                  type="submit"
                  role="button"
                  class="btn btn-primary btn-lg btn-white"
                >
                  UPDATE Walking Speed
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="clear"></div>
</div>
