<div
*ngIf="bg"
  class="bg-full"
  style="background-size: cover !important;"
  [ngStyle]="{ 'background-image': 'url(' + bg + ')' }"
></div>

<div *ngIf="isLoading" style="text-align: center;">
  <app-loading-spinner></app-loading-spinner>
</div>
<div class="container">
  <div class="row">
    <div class="col-md-12 col-lg-8 offset-lg-2">
      <div class="well well-default text-center">
        <button
          class="btn btn-icon-circle btn-icon-circle-primary stuck-top-left"
          ng-show="loginPanel"
          ng-click="loginPanel=false"
        >
          <i class="fa fa-chevron-circle-left"></i>
        </button>
        <a class="logo-text">
          <span class="logo-text"
            ><img
              src="./assets/img/logo-my-desmond.png"
              height="60"
              *ngIf="environment.country != 'AUS'" /><img
              src="./assets/img/logo-my-desmond-aus-b.png"
              height="60"
              *ngIf="environment.country == 'AUS'" /></span></a
        ><br /><br />

        <div *ngIf="environment.defaultProgramme == 'BabySteps'">
          <span class="logo-text mt-20"
            ><img src="./assets/img/logo-baby-steps.png" height="24" /></span
          ><br /><br />
          <div class="row">
            <div class="col-md-6 col-md-offset-3">
              <p class="pt-20 pb-20" style="font-size: 16px">
                This web-based program has been designed to support women who
                have previously been diagnosed with gestational diabetes in
                making healthy lifestyle choices
              </p>
            </div>
          </div>
        </div>

        <div *ngIf="status">
            <div [ngSwitch]="status">
                <div *ngSwitchCase="1">
                  <div class="alert alert-success" role="alert">
                    Your password has been reset
                  </div>
                </div>
                <div *ngSwitchCase="2">
                  <div class="alert alert-danger" role="alert">
                    There was an error resetting your password
                  </div>
                </div>
              </div>
        </div>
        <div class="alert alert-danger" *ngIf="error">
            <p>{{ error }}</p>
          </div>
          
        <form
          class="bs-form"
          name="loginForm"
          #authForm="ngForm"
          (ngSubmit)="onSubmit(authForm)"
          *ngIf="!isLoading"
        >
          <fieldset>
            <legend class="sr-only">Login credentials</legend>
            <div class="form-group">
              <label for="email" class="sr-only">Email</label>
              <input
              type="email"
              id="email"
              class="form-control"
              ngModel
              name="email"
              placeholder="Email"
              required
              email
              #email="ngModel"
              autocomplete="off"
              [ngClass]="{'is-invalid':authForm.submitted && email.invalid}"
              />
              <div *ngIf="authForm.submitted && email.invalid" class="form-text text-danger">Invalid email</div>
            </div>
            <div class="form-group">
              <label for="password" class="sr-only">Password</label>
              <input
              type="password"
              id="password"
              class="form-control"
              ngModel
              #password="ngModel"
              name="password"
              required
              minlength="6"
              placeholder="Password"
              autocomplete="off"
              [ngClass]="{'is-invalid':authForm.submitted && password.invalid}"
              />
              <div *ngIf="authForm.submitted && password.invalid" class="form-text text-danger">Invalid password</div>
            </div>
            <div class="form-group" *ngIf="attempts > 4">
              <re-captcha
                [(ngModel)]="credentials['g-recaptcha-response']"
                name="captcha"
                required
                siteKey="6Ld6bnwUAAAAAGLcbl8PJLXRzMmYm1j-8Tin0DP2"

                (resolved)="captchaResolved($event)"
                (error)="captchaError($event)"
              ></re-captcha>
                        
              <div *ngIf="captcha_status" class="text-danger">
                {{ captcha_status }}
              </div>
            </div>

            <div class="form-row">
              <div class="col-8">
                <input
                  type="checkbox"
                  id="rememberMe"
                  name="rememberMe"
                  ngModel
                  value="1"
                />
                <label for="rememberMe"> &nbsp; Keep me signed in</label><br />
                <a href="{{environment.applicationUrl}}/forgotten-password">Forgotten Password</a>
              </div>
              <div class="col-4">
                <button
                  type="submit"
                  class="btn btn-primary btn-lg pull-right"
                  value="Sign In"
                >
                  SIGN IN <i class="fa fa-arrow-circle-o-right"></i>
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</div>
