import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as moment from 'moment';
import { Color, Label } from 'ng2-charts';
import { ActivityService } from 'src/app/services/activity.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-m3-walking-non-stepping-chart',
  templateUrl: './m3-walking-non-stepping-chart.component.html',
  styleUrls: ['./m3-walking-non-stepping-chart.component.scss'],
})
export class M3WalkingNonSteppingChartComponent implements OnInit {
  @Input() allTimeSteps;
  @Input() allNonSteppings;
  pointBackgroundColors = [];
  pointBorderColors = [];

  // Line chart
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: 'Date', 
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          gridLines: {
            color: '#555',
            drawOnChartArea: false,
          },
          ticks: {
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
            autoSkip: true
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          gridLines: {
            color: '#555',
            drawOnChartArea: false,
          },
          scaleLabel: {
            display: true,
            labelString: 'Average Steps per day', 
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          ticks: {
            min: 0,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
            autoSkip: true,
            maxTicksLimit:6
          },
        },
      ],
    },
    legend: {
      display: true,
    },
    plugins: {
      datalabels: {
      },
    },
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'Steps',
      borderWidth: 0,
      borderColor: '#999999',
      fill: true,
      // pointBackgroundColor: this.pointBackgroundColors,
      // pointBorderColor: this.pointBorderColors,
      pointRadius: 0,
    },
    {
      data: [],
      label: 'Non Stepping activity',
      borderWidth: 0,
      borderColor: '#22B4FC',
      fill: true,
      // pointBackgroundColor: this.pointBackgroundColors,
      // pointBorderColor: this.pointBorderColors,
      pointRadius: 0,
    },
  ];
  public barChartColors: Color[] = [
    { backgroundColor: '#fe690c' },
    { backgroundColor: '#22B4FC' },
  ];

  // end chart

  constructor(private activityService: ActivityService) {}

  ngOnInit(): void {
    this.setChartData();
  }

  setChartData() {
    this.barChartData[0].data = [];

    // do labels
    let dates = [];
    this.allTimeSteps.forEach((value, key) => {
      if (
        value.steps>0 &&
        moment(value.activity_date).format('YYYY-MM-DD') >
          moment().subtract(6, 'months').format('YYYY-MM-DD')
      ) {
        dates.push(moment(value.activity_date).format('YYYY-MM-DD'));
      }
    });

    this.allNonSteppings.forEach((_value, _key) => {
        dates.push(moment(_value.date_recorded).format('YYYY-MM-DD'));
    });
    // unique

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }
    

    // usage example:
    dates = dates.filter(onlyUnique);
    dates.sort();

    dates.forEach((date) => {
      this.barChartLabels.push(moment(date).format('DD-MM-YY'));
      this.allTimeSteps.forEach((value, key) => {
        if (
          moment(value.activity_date).format('YYYY-MM-DD') ==
          moment(date).format('YYYY-MM-DD')
        ) {
          if (value.steps) {
            this.barChartData[0].data.push(value.steps);
          }
          else{

            this.barChartData[0].data.push(0);
          }
        }
      });

      let nonSteppingSteps = null;
      this.allNonSteppings.forEach((_value, _key) => {
        if (
          moment(_value.date_recorded).format('YYYY-MM-DD') ==
          moment(date).format('YYYY-MM-DD')
        ) {
          if (nonSteppingSteps == null) {
            nonSteppingSteps = 0;
          }
          nonSteppingSteps += +_value.steps;
        }
      });

      if (nonSteppingSteps) {
        this.barChartData[1].data.push(nonSteppingSteps);
      }
      else{
        this.barChartData[1].data.push(0);
      }
    });
    // setup chart data
    /*
    this.allTimeSteps.forEach((value, key) => {

      let nonSteppingSteps = null;
      this.allNonSteppings.forEach((_value, _key) => {
        if(moment(_value.date_recorded ).format('YYYY-MM-DD') ==  moment(value.activity_date).format('YYYY-MM-DD')){
          if(nonSteppingSteps == null){nonSteppingSteps = 0;}
          nonSteppingSteps+= +_value.steps;
        }
      });


      if (value.steps || nonSteppingSteps>0) {
        if (
          moment(value.activity_date).format('YYYY-MM-DD') >
          moment().subtract(1, 'y').format('YYYY-MM-DD')
        ) {
          this.barChartLabels.push(
            moment(value.activity_date).format('YYYY-MM-DD')
          );
          this.barChartData[0].data.push(value.steps);
          this.barChartData[1].data.push(nonSteppingSteps);
          
        }
      }
    });*/
  }

  getStepsRag(averageSteps: number) {
    return this.activityService.getStepsRag(averageSteps);
  }
}
