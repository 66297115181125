import { NgForm } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { SymptomsService } from './../services/symptoms.service';
import { ProgrammeService } from './../services/programme.service';
import { UserService } from './../services/user.service';
import { AuthService } from './../auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { environment } from './../../environments/environment';
import { Color, Label } from 'ng2-charts';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-symptom-tracker',
  templateUrl: './symptom-tracker.component.html',
  styleUrls: ['./symptom-tracker.component.scss'],
})
export class SymptomTrackerComponent implements OnInit {
  currentUser;
  currentProgramme;
  userSymptoms = [];
  userSymptom;
  isFetching = false;
  errors = [];
  showAllRows = false;
  environment = environment;
  symptom;
  symptomDetails;
  prevSymptomDetails;
  nextSymptomDetails;

  toggleRow = {
    update: false,
    graph: false,
    entry: false,
  };

  toggleChart = {
    frequency: true,
    importance: false,
  };
  data = [];
  data2 = [];
  data3 = [];

  date = new Date();

  symptoms = [
    { name: 'itching', slug: 'itching', iconWeight: 'far', icon: 'allergies' },
    {
      name: 'Sleep disturbance',
      slug: 'sleep-disturbance',
      iconWeight: 'far',
      icon: 'alarm-clock',
    },
    {
      name: 'Loss of appetite',
      slug: 'loss-of-appetite',
      iconWeight: 'far',
      icon: 'stomach',
    },
    {
      name: 'Feeling tired',
      slug: 'feeling-tired',
      iconWeight: 'far',
      icon: 'snooze',
    },
    {
      name: 'Pain in bones/joints',
      slug: 'pain-in-bones-joints',
      iconWeight: 'far',
      icon: 'bone',
    },
    {
      name: 'Poor concentration',
      slug: 'poor-concentration',
      iconWeight: 'far',
      icon: 'head-side-brain',
    },
    {
      name: 'Loss of libido',
      slug: 'loss-of-libido',
      iconWeight: 'far',
      icon: 'bed-alt',
    },
    {
      name: 'Loss of muscle strength',
      slug: 'loss-of-muscle-strength',
      iconWeight: 'far',
      icon: 'fist-raised',
    },
    {
      name: 'Shortness of breath',
      slug: 'shortness-of-breath',
      iconWeight: 'far',
      icon: 'lungs',
    },
    {
      name: 'Cramp/Muscle stiffness',
      slug: 'cramp-muscle-stiffness',
      iconWeight: 'far',
      icon: 'tired',
    },
    {
      name: 'Restless legs',
      slug: 'restless-legs',
      iconWeight: 'far',
      icon: 'socks',
    },
    {
      name: 'Need to urinate more often',
      slug: 'need-to-urinate-more-often',
      iconWeight: 'far',
      icon: 'toilet',
    },
    {
      name: 'Feeling cold',
      slug: 'feeling-cold',
      iconWeight: 'far',
      icon: 'temperature-frigid',
    },
  ];

  frequencyLabels = {
    1: 'Never',
    2: 'Less than once a week',
    3: '1 – 2 times a week',
    4: 'Several times a week',
    5: 'Every day',
  };

  importanceLabels = {
    1: 'Not important',
    2: 'Of little importance',
    3: 'Moderately important',
    4: 'Important',
    5: 'Very important',
  };

  @ViewChild('trackerForm', { static: false }) trackerForm: NgForm;

  // Line chart
  public chartOptionsFrequency: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            beginAtZero: false,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
            min: 1,
            max: 5,
            callback: function (label, index, labels) {
              switch (label) {
                case 1:
                  return 'Never';
                  break;
                case 2:
                  return 'Less than once a week';
                  break;
                case 3:
                  return '1 – 2 times a week';
                  break;
                case 4:
                  return 'Several times a week';
                  break;
                case 5:
                  return 'Every day';
                  break;
              }
            },
          },
          gridLines: {
            display: true,
            lineWidth: 1,
            color: '#95989A',
          },
        }
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            lineWidth: 1,
            color: '#95989A',
          },
          type: 'time',
          ticks: {
            autoSkip: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          time: {
            parser: 'YYYY-MM-DD HH:mm:ss',
            unit: 'day',
            displayFormats: {
              day: 'MMM-DD',
            },
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };

  public chartOptionsImportance: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          id: 'y-axis-2',
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            beginAtZero: false,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
            min: 1,
            max: 5,
            callback: function (label, index, labels) {
              switch (label) {
                case 1:
                  return 'Not important';
                  break;
                case 2:
                  return 'Of little importance';
                  break;
                case 3:
                  return 'Moderately important';
                  break;
                case 4:
                  return 'Important';
                  break;
                case 5:
                  return 'Very important';
                  break;
              }
            },
          },
          gridLines: {
            display: true,
            lineWidth: 1,
            color: '#95989A',
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            lineWidth: 1,
            color: '#95989A',
          },
          type: 'time',
          ticks: {
            autoSkip: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          time: {
            parser: 'YYYY-MM-DD HH:mm:ss',
            unit: 'day',
            displayFormats: {
              day: 'MMM-DD',
            },
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public chartLabels: Label[] = [];
  public chartType: ChartType = 'line';
  public chartLegend = true;

  public chartColors: Color[] = [{ backgroundColor: environment.primaryColor }];
  public symptomChartDataFrequency: ChartDataSets[] = [
    {
      data: [],
      label: 'Frequency',
      borderWidth: 2,
      borderColor: environment.chartDefaults.colors[0],
      pointBorderColor: environment.chartDefaults.colors[0],
      fill: false,
      pointRadius: 4,
      yAxisID: 'y-axis-1',
    }
  ];
  public symptomChartDataImportance: ChartDataSets[] = [
    {
      data: [],
      label: 'Importance',
      borderWidth: 2,
      borderColor: environment.chartDefaults.colors[1],
      pointBorderColor: environment.chartDefaults.colors[1],
      pointBackgroundColor: environment.chartDefaults.colors[1],
      fill: false,
      pointRadius: 4,
      yAxisID: 'y-axis-2',
    },
  ];
  //
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private symptomsService: SymptomsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.isFetching = true;
    this.route.params.subscribe((params) => {
      this.isFetching = true;

      this.prevSymptomDetails = null;
      this.nextSymptomDetails = null;

      this.symptom = this.route.snapshot.params.symptom;
      if (!this.symptom) {
        this.symptom = 'itching';
      }
      this.symptoms.forEach((sy, syIndex) => {
        if (sy.slug === this.symptom) {
          this.symptomDetails = sy;
          if (this.symptoms[syIndex - 1]) {
            this.prevSymptomDetails = this.symptoms[syIndex - 1];
          }
          if (this.symptoms[syIndex + 1]) {
            this.nextSymptomDetails = this.symptoms[syIndex + 1];
          }
        }
      });

      // are we logged in?
      const user = this.authService.user.getValue();
      if (user) {
        this.programmeService.currentProgramme.subscribe((programmeData) => {
          this.currentProgramme = programmeData;
          if (this.currentProgramme) {
            // this.barChartColors[0].backgroundColor = this.currentProgramme.config.primaryColor;

            forkJoin({
              userSymptoms: this.symptomsService.fetchSypmtom(this.symptom),
            }).subscribe(
              (responseData) => {
                this.isFetching = false;
                this.userSymptoms = responseData.userSymptoms;
                if (this.userSymptoms.length > 0) {
                  this.userSymptom = this.userSymptoms[0];
                }
                this.setChartData(this.userSymptoms);
                // end
              },
              (error) => {
                this.isFetching = false;
                this.errors.push(error.message);
              }
            );
          }
        });
        this.userService.userData.subscribe((userDataResponse) => {
          this.currentUser = userDataResponse;
        });
      }
    });
  }

  onRemove(userSymptom) {
    this.symptomsService.delete(userSymptom.user_symptom_id).subscribe(
      (responseData) => {
        const index = this.userSymptoms.indexOf(userSymptom);
        this.userSymptoms.splice(index, 1);
        this.setChartData(this.userSymptoms);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onSubmit() {
    // this.trackerForm.value.date

    if (!this.trackerForm.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.symptomsService
        .create(
          this.symptom,
          this.trackerForm.value.frequency,
          this.trackerForm.value.importance,
          moment(this.date).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
            this.userSymptoms.unshift(responseData);
            this.userSymptoms.sort((a, b) =>
              a.symptom_date > b.symptom_date
                ? 1
                : b.symptom_date > a.symptom_date
                ? -1
                : 0
            );
            // set chart data
            let msg =
              'Well done for logging your symptoms. Try and record any other symptoms that you experience this week.';

            if (
              this.trackerForm.value.frequency > 3 &&
              this.trackerForm.value.importance > 3
            ) {
              msg =
                'You have entered a high score for this symptom. Try reducing your symptom by using strategies to help manage and deal with the symptom. Remember some may work better for you than others so you may need to try different ones until you find the one that works best for you';
            }
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: msg,
              showConfirmButton: true,
            });
            this.trackerForm.resetForm();
            this.setChartData(this.userSymptoms);
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  // end chart
  updatedToday() {
    let hasUpdated = false;
    this.userSymptoms.forEach((userSymptom) => {
      if (
        moment(userSymptom.created).format('MM DD YY') ===
        moment().format('MM DD YY')
      ) {
        hasUpdated = true;
      }
    });
    return hasUpdated;
  }

  setChartData(trackerData) {
    this.symptomChartDataFrequency[0].data = [];
    this.symptomChartDataImportance[0].data = [];
    this.chartLabels = [];
    // setup chart data
    trackerData.forEach((value, key) => {
      this.chartLabels.push(moment(value.symptom_date).format('YYYY-MM-DD'));
      this.symptomChartDataFrequency[0].data.push(value.frequency);
      this.symptomChartDataImportance[0].data.push(value.importance);
    });
  }
}
