<div class="container app-container user-menu-wrap top-container" *ngIf="currentProgramme && currentUser">
  <!-- Static navbar -->
  <nav class="navbar navbar-default navbar-bs justify-content-center">
    <button class="btn-navbar btn-side-menu" aria-label="App Menu" *ngIf="currentProgramme.config.m3!=1"
    (click)="onShowMobileMenu($event)"
    >
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'bars']"></fa-icon
      ></span>
    </button>
    <h1>
      {{ currentUser.first_name + " " + currentUser.last_name | uppercase }}
    </h1>
    <button
      class="btn-user-menu btn-user-menu-close"
      (click)="onCloseMenu($event)"
      aria-label="Close User Menu"
    >
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'times']"></fa-icon
      ></span>
    </button>
  </nav>
  <div class="container menu-container">
    <nav class="side-menu side-menu-user" id="accordion" role="tablist">
      <div class="side-menu-item" *ngIf="currentProgrammes">
        <ng-container *ngIf="currentProgrammes.length>1">
        <a
          [ngClass]="{ collapsed: !showProgrammesMenu }"
          (click)="showProgrammesMenu = !showProgrammesMenu"
          data-toggle="collapse"
          data-parent="#accordion"
          aria-expanded="true"
          aria-controls="collapseTwo"
          role="tab"
          id="headingOne"
        >
          <span class="icon-wrap item-icon"
            ><fa-icon [icon]="['fas', 'graduation-cap']"></fa-icon
          ></span>
          <span>MY PROGRAMMES</span>
        </a>
        <ul
          *ngIf="showProgrammesMenu"
          id="collapseTwo"
          class="side-menu-sub-menu"
          role="tabpanel"
          aria-labelledby="headingOne"
        >
          <li *ngFor="let programme of currentProgrammes">
            <a (click)="onSetCurrentProgramme(programme)"
              ><span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'arrow-right']"></fa-icon
              ></span>
              {{ programme.title }}</a
            >
          </li>
        </ul>
        </ng-container>
      </div>

      <div class="side-menu-item">
        <a
          [routerLink]="['/notifications']"
          class="no-sub collapsed"
          role="tab"
        >
          <span class="icon-wrap item-icon"
            ><fa-icon [icon]="['fas', 'bell']"></fa-icon
          ></span>
          <span>NOTIFICATIONS</span>
        </a>
      </div>

      <div class="side-menu-item">
        <a [routerLink]="['/profile']" class="no-sub collapsed" role="tab">
          <span class="icon-wrap item-icon"
            ><fa-icon [icon]="['fas', 'id-badge']"></fa-icon
          ></span>
          <span>MY PROFILE</span>
        </a>
      </div>

      <div class="side-menu-item" *ngIf="currentTeam">
        <a [routerLink]="['/team']" class="no-sub collapsed" role="tab">
          <span class="icon-wrap item-icon"
            ><fa-icon [icon]="['fas', 'user-friends']"></fa-icon
          ></span>
          <span>MY TEAM</span>
        </a>
      </div>

      <div class="side-menu-item">
        <a [routerLink]="['/settings']" class="no-sub collapsed" role="tab">
          <span class="icon-wrap item-icon"
            ><fa-icon [icon]="['fas', 'cog']"></fa-icon></span
          ><span>SETTINGS</span>
        </a>
      </div>

      <div class="side-menu-item">
        <a [routerLink]="['/about']" class="no-sub collapsed" role="tab">
          <span class="icon-wrap item-icon"
            ><fa-icon [icon]="['fas', 'info']"></fa-icon
          ></span>
          <span *ngIf="environment.defaultProgramme != 'BabySteps'"
            >ABOUT MYDESMOND</span
          >
          <span *ngIf="environment.defaultProgramme == 'BabySteps'"
            >ABOUT BABY STEPS</span
          >
        </a>
      </div>

      <div class="side-menu-item">
        <a [routerLink]="['/legal']" class="no-sub collapsed" role="tab">
          <span class="icon-wrap item-icon"
            ><fa-icon [icon]="['fas', 'gavel']"></fa-icon
          ></span>
          <span>LEGAL</span>
        </a>
      </div>

      <div class="side-menu-item">
        <a [routerLink]="['/setup-device']" class="no-sub collapsed" role="tab">
          <span class="icon-wrap item-icon"
            ><fa-icon 
            [icon]="['fas', 'watch-fitness']"></fa-icon
          ></span>
          <span>SETUP DEVICE</span>
        </a>
      </div>
      <div class="side-menu-item" *ngIf="currentProgramme.config.tour == 1">
        <a
          [ngClass]="{ collapsed: !showEducationMenu }"
          (click)="showEducationMenu = !showEducationMenu"
          data-toggle="collapse"
          data-parent="#accordion"
          aria-expanded="false"
          aria-controls="collapseSeven"
          role="tab"
          id="headingSeven"
        >
          <span class="icon-wrap item-icon"
            ><fa-icon [icon]="['fas', 'book']"></fa-icon
          ></span>
          <span>HELP</span>
        </a>
        <ul
          *ngIf="showEducationMenu"
          id="collapseSeven"
          class="side-menu-sub-menu"
          role="tabpanel"
          aria-labelledby="headingSeven"
        >
          <ng-container
            *ngFor="
              let contentSection of programmeContent.help_sections;
              let index = index
            "
          >
            <li *ngIf="programmeContent.help_sections.length > 0">
              <a
                [routerLink]="[
                  '/help',
                  'section',
                  contentSection.content_section_id
                ]"
                ><span class="sub-menu-num">{{ index + 1 }}</span
                >{{ contentSection.content_section_title }}</a
              >
            </li>
          </ng-container>
        </ul>
      </div>

      <div class="side-menu-item">
        <a (click)="onLogout()" class="no-sub collapsed" role="tab">
          <span class="icon-wrap item-icon"
            ><fa-icon [icon]="['fas', 'sign-out']"></fa-icon
          ></span>
          <span>LOGOUT</span>
        </a>
      </div>
    </nav>
  </div>
</div>
