import { Component, OnInit } from '@angular/core';
import { ActivityService } from 'src/app/services/activity.service';

@Component({
  selector: 'app-dashboard-api-test',
  templateUrl: './dashboard-api-test.component.html',
  styleUrls: ['./dashboard-api-test.component.scss']
})
export class DashboardApiTestComponent implements OnInit {

  constructor(private userActivityService:ActivityService) { }

  ngOnInit(): void {
    this.userActivityService.apiTest().subscribe((responseData)=>{
      console.log(responseData);
    })

  }

}
