<nav class="subnav">
  <a [routerLink]="['/settings']" class="subnav-prev"
    ><span><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
  ></a>
  <h2>
    <span><fa-icon [icon]="['fas', 'cog']"></fa-icon></span> PERSONAL SETTINGS
  </h2>
</nav>
<br />
<div class="container" *ngIf="currentUser">
  <form
    class="bs-form bs-form"
    name="profileForm"
    method="post"
    (ngSubmit)="onSubmit()"
    #profileForm="ngForm"
  >
    <div class="form-group" *ngIf="!currentUser.buddy">
      <label for="first_name">FIRST NAME<span class="required">*</span></label>
      <input
        type="text"
        name="first_name"
        class="form-control"
        placeholder="First Name"
        id="first_name"
        [ngModel]="currentUser.first_name"
        autocomplete="off"
        required
        minlength="1"
        maxlength="100"
        #first_name="ngModel"
      />
      <div
        class="alert alert-danger"
        *ngIf="first_name.touched && first_name.invalid"
      >
        There is an error with your first name.
      </div>
    </div>
    <div class="form-group" *ngIf="!currentUser.buddy">
      <label for="last_name">LAST NAME<span class="required">*</span></label>
      <input
        type="text"
        name="last_name"
        class="form-control"
        placeholder="Last Name"
        id="last_name"
        [ngModel]="currentUser.last_name"
        #last_name="ngModel"
        autocomplete="off"
        required
        minlength="1"
        maxlength="100"
      />
      <div
        class="alert alert-danger"
        *ngIf="last_name.touched && last_name.invalid"
      >
        There is an error with your last name.
      </div>
    </div>

    <div class="form-group" *ngIf="!currentUser.buddy">
      <label for="mobile">Mobile number</label>
      <input
        type="text"
        name="mobile"
        class="form-control"
        placeholder="Enter your mobile number"
        id="mobile"
        [ngModel]="currentUser.mobile"
        #mobile="ngModel"
        autocomplete="off"
        minlength="1"
        maxlength="16"
      />
      <div
        class="alert alert-danger"
        *ngIf="mobile.touched && mobile.invalid"
      >
        There is an error with your mobile.
      </div>
    </div>

    <div class="form-group">
      <label for="screen_name"
        >SCREEN NAME<span class="required">*</span></label
      >
      <p>This is will be shown on challenge boards and comments. Please use only letters and numbers and remove any spaces or special characters.</p>
      <input
        type="text"
        name="screen_name"
        class="form-control"
        placeholder="Screen Name"
        id="screen_name"
        #screen_name="ngModel"
        [ngModel]="currentUser.screen_name"
        autocomplete="off"
        required
        minlength="1"
        maxlength="100"
        pattern="^[a-zA-Z0-9]*$"
      />
      <div
        class="alert alert-danger"
        *ngIf="screen_name.touched && screen_name.invalid"
      >
        There is an error with your screen name. Please use only letters and numbers and remove any spaces or special characters.
        <span *ngIf="screen_name.errors.nametaken"
          >Sorry, that screen name is already taken</span
        >
      </div>
    </div>
    <div class="form-group form-group-dob">
      <label for="location">DATE OF BIRTH<span class="required">*</span></label>
      
      <input
      class="form-control"
      name="dob"
      #dob="ngModel"
      bsDatepicker
      placeholder="DD/MM/YYYY"
      autocomplete="off"
      placement="top"
      [bsConfig]="{
        dateInputFormat: 'DD/MM/YYYY',
        containerClass: 'theme-cv',
        showWeekNumbers: false
      }"
      [(ngModel)]="currentUser.dob"
      required
      [minDate]="minDate"
      [maxDate]="maxDate"
    />

      <div class="alert alert-danger" *ngIf="dob.touched && dob.invalid">
        There is an error with your Date of Birth.
      </div>
      <div class="clear"></div>
    </div>

    <div class="form-group">
      <label for="weight">GENDER <span class="required">*</span></label>
      <input
        type="hidden"
        name="gender"
        id="gender"
        #gender="ngModel"
        [ngModel]="currentUser.gender"
        required
      />
      <div class="row">
        <div class="col-3">
          <button
            type="button"
            class="btn btn-block"
            [ngClass]="{'btn-default': currentUser.gender!=='f','btn-primary': currentUser.gender==='f'}"
           
            (click)="currentUser.gender = 'f'"
          >
            FEMALE
          </button>
        </div>
        <div class="col-3">
          <button
            type="button"
            class="btn btn-block"
            [ngClass]="{'btn-default': currentUser.gender!=='m','btn-primary': currentUser.gender==='m'}"
            (click)="currentUser.gender = 'm'"
          >
            MALE
          </button>
        </div>
        <div class="col-3">
          <button
            type="button"
            class="btn btn-block"

            [ngClass]="{'btn-default': currentUser.gender!=='x','btn-primary': currentUser.gender==='x'}"
            (click)="currentUser.gender = 'x'"
          >
            X
          </button>
          <em>(Indeterminate / Intersex / Unspecified)</em>
        </div>
        <div class="col-3">
          <button
            type="button"
            class="btn btn-block"

            [ngClass]="{'btn-default': currentUser.gender!=='n','btn-primary': currentUser.gender==='n'}"
            (click)="currentUser.gender = 'n'"
          >
            Prefer not to say
          </button>
        </div>
      </div>
      <div class="alert alert-danger" *ngIf="!gender.valid">
        Please select your gender.
      </div>
    </div>

    <div class="row" *ngIf="!currentUser.buddy">
      <div class="col-sm-6" *ngIf="environment.country == 'UK'">
        <div class="form-group">
          <label for="location"
            >ETHNICITY <span class="required">*</span></label
          >

          <select
            name="ethnicity_id"
            #ethnicity_id="ngModel"
            class="form-control"
            id="ethnicity_id"
            [ngModel]="currentUser.ethnicity_id"
            required
          >
            <option [value]="">Please Select</option>
            <optgroup label="White">
              <option [value]="1">British</option>
              <option [value]="2">Irish</option>
              <option [value]="3">
                Any other White background (please specify)
              </option>
            </optgroup>
            <optgroup label="Mixed">
              <option [value]="4">White and Black Caribbean</option>
              <option [value]="5">White and Black African</option>
              <option [value]="6">White and Asian</option>
              <option [value]="7">
                Any other Mixed background (please specify)
              </option>
            </optgroup>
            <optgroup label="Asian&#x20;or&#x20;Asian&#x20;British">
              <option [value]="8">Indian</option>
              <option [value]="9">Pakistani</option>
              <option [value]="10">Bangladeshi</option>
              <option [value]="11">
                Any other Asian background (please specify)
              </option>
            </optgroup>
            <optgroup label="Black&#x20;or&#x20;Black&#x20;British">
              <option [value]="12">Caribbean</option>
              <option [value]="13">African</option>
              <option [value]="14">
                Any other Black background (please specify)
              </option>
            </optgroup>
            <optgroup label="Chinese&#x20;or&#x20;other&#x20;ethnic&#x20;group">
              <option [value]="15">Chinese</option>
              <option [value]="16">Any other (please specify)</option>
            </optgroup>
            <optgroup
              label="Arab&#x20;or&#x20;Middle&#x20;Eastern&#x20;descent"
            >
              <option [value]="17">Arab</option>
              <option [value]="18">North African</option>
              <option [value]="19">Iraqi</option>
              <option [value]="20">Kurdish</option>
              <option [value]="21">
                Any other Middle Eastern background (please specify)
              </option>
            </optgroup>
            <optgroup label="Other">
              <option [value]="23">Prefer not to say</option>
              <option [value]="24">Other</option>
            </optgroup>
          </select>

          <div
            class="alert alert-danger"
            *ngIf="
              (ethnicity_id.touched &&
                ethnicity_id.invalid)
            "
          >
            Ethnicity is required.
          </div>
        </div>
      </div>
      <div class="col-sm-6" *ngIf="environment.country == 'AUS'">
        <div class="form-group">
          <label for="origin"
            >ARE YOU OF ABORIGINAL OR TORRES STRAIT ISLANDER ORIGIN?
            <span class="required">*</span></label
          >
          <br />
          <label
            ><input
              type="radio"
              name="origin"
              [(ngModel)]="currentUser.origin"
              value="no"
              class="custom-control custom-radio custom-control-inline"
              [required]="!currentUser.origin"
            />
            No</label
          ><br />
          <label
            ><input
              type="radio"
              name="origin"
              [(ngModel)]="currentUser.origin"
              value="Aboriginal"
             [required]="!currentUser.origin"
             class="custom-control custom-radio custom-control-inline"
            />
            Yes, Aboriginal</label
          ><br />
          <label
            ><input
              type="radio"
              name="origin"
              [(ngModel)]="currentUser.origin"
              value="Torres Strait Islander"
              [required]="!currentUser.origin"
              class="custom-control custom-radio custom-control-inline"
            />
            Yes, Torres Strait Islander</label
          ><br />
          <label
            ><input
              type="radio"
              name="origin"
              [(ngModel)]="currentUser.origin"
              value="Aboriginal and Torres Strait Islander"
              [required]="!currentUser.origin"
              class="custom-control custom-radio custom-control-inline"
            />
            Yes, Aboriginal and Torres Strait Islander</label
          ><br />
          <label
            ><input
              type="radio"
              name="origin"
              [(ngModel)]="currentUser.origin"
              value="not say"
              [required]="!currentUser.origin"
              class="custom-control custom-radio custom-control-inline"
            />
            Do not wish to disclose</label
          >
          <div
            class="alert alert-danger"
            *ngIf="
              (!currentUser.origin)
            "
          >
            Please select an option above.
          </div>

          <br /><br />

          <label for="location"
            >IN WHICH COUNTRY WERE YOU BORN?
            <span class="required">*</span></label
          >
          <select
            name="birth_country"
            class="form-control"
            id="birth_country"
            [(ngModel)]="currentUser.birth_country"
            #birth_country="ngModel"
            required
          >
            <option value="Australia">Australia</option>
            <option value="Afghanistan">Afghanistan</option>
            <option value="Åland Islands">Åland Islands</option>
            <option value="Albania">Albania</option>
            <option value="Algeria">Algeria</option>
            <option value="American Samoa">American Samoa</option>
            <option value="Andorra">Andorra</option>
            <option value="Angola">Angola</option>
            <option value="Anguilla">Anguilla</option>
            <option value="Antarctica">Antarctica</option>
            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
            <option value="Argentina">Argentina</option>
            <option value="Armenia">Armenia</option>
            <option value="Aruba">Aruba</option>
            <option value="Austria">Austria</option>
            <option value="Azerbaijan">Azerbaijan</option>
            <option value="Bahamas">Bahamas</option>
            <option value="Bahrain">Bahrain</option>
            <option value="Bangladesh">Bangladesh</option>
            <option value="Barbados">Barbados</option>
            <option value="Belarus">Belarus</option>
            <option value="Belgium">Belgium</option>
            <option value="Belize">Belize</option>
            <option value="Benin">Benin</option>
            <option value="Bermuda">Bermuda</option>
            <option value="Bhutan">Bhutan</option>
            <option value="Bolivia">Bolivia</option>
            <option value="Bosnia and Herzegovina">
              Bosnia and Herzegovina
            </option>
            <option value="Botswana">Botswana</option>
            <option value="Bouvet Island">Bouvet Island</option>
            <option value="Brazil">Brazil</option>
            <option value="British Indian Ocean Territory">
              British Indian Ocean Territory
            </option>
            <option value="Brunei Darussalam">Brunei Darussalam</option>
            <option value="Bulgaria">Bulgaria</option>
            <option value="Burkina Faso">Burkina Faso</option>
            <option value="Burundi">Burundi</option>
            <option value="Cambodia">Cambodia</option>
            <option value="Cameroon">Cameroon</option>
            <option value="Canada">Canada</option>
            <option value="Cape Verde">Cape Verde</option>
            <option value="Cayman Islands">Cayman Islands</option>
            <option value="Central African Republic">
              Central African Republic
            </option>
            <option value="Chad">Chad</option>
            <option value="Chile">Chile</option>
            <option value="China">China</option>
            <option value="Christmas Island">Christmas Island</option>
            <option value="Cocos (Keeling) Islands">
              Cocos (Keeling) Islands
            </option>
            <option value="Colombia">Colombia</option>
            <option value="Comoros">Comoros</option>
            <option value="Congo">Congo</option>
            <option value="Congo, The Democratic Republic of The">
              Congo, The Democratic Republic of The
            </option>
            <option value="Cook Islands">Cook Islands</option>
            <option value="Costa Rica">Costa Rica</option>
            <option value="Cote D'ivoire">Cote D'ivoire</option>
            <option value="Croatia">Croatia</option>
            <option value="Cuba">Cuba</option>
            <option value="Cyprus">Cyprus</option>
            <option value="Czech Republic">Czech Republic</option>
            <option value="Denmark">Denmark</option>
            <option value="Djibouti">Djibouti</option>
            <option value="Dominica">Dominica</option>
            <option value="Dominican Republic">Dominican Republic</option>
            <option value="Ecuador">Ecuador</option>
            <option value="Egypt">Egypt</option>
            <option value="El Salvador">El Salvador</option>
            <option value="Equatorial Guinea">Equatorial Guinea</option>
            <option value="Eritrea">Eritrea</option>
            <option value="Estonia">Estonia</option>
            <option value="Ethiopia">Ethiopia</option>
            <option value="Falkland Islands (Malvinas)">
              Falkland Islands (Malvinas)
            </option>
            <option value="Faroe Islands">Faroe Islands</option>
            <option value="Fiji">Fiji</option>
            <option value="Finland">Finland</option>
            <option value="France">France</option>
            <option value="French Guiana">French Guiana</option>
            <option value="French Polynesia">French Polynesia</option>
            <option value="French Southern Territories">
              French Southern Territories
            </option>
            <option value="Gabon">Gabon</option>
            <option value="Gambia">Gambia</option>
            <option value="Georgia">Georgia</option>
            <option value="Germany">Germany</option>
            <option value="Ghana">Ghana</option>
            <option value="Gibraltar">Gibraltar</option>
            <option value="Greece">Greece</option>
            <option value="Greenland">Greenland</option>
            <option value="Grenada">Grenada</option>
            <option value="Guadeloupe">Guadeloupe</option>
            <option value="Guam">Guam</option>
            <option value="Guatemala">Guatemala</option>
            <option value="Guernsey">Guernsey</option>
            <option value="Guinea">Guinea</option>
            <option value="Guinea-bissau">Guinea-bissau</option>
            <option value="Guyana">Guyana</option>
            <option value="Haiti">Haiti</option>
            <option value="Heard Island and Mcdonald Islands">
              Heard Island and Mcdonald Islands
            </option>
            <option value="Holy See (Vatican City State)">
              Holy See (Vatican City State)
            </option>
            <option value="Honduras">Honduras</option>
            <option value="Hong Kong">Hong Kong</option>
            <option value="Hungary">Hungary</option>
            <option value="Iceland">Iceland</option>
            <option value="India">India</option>
            <option value="Indonesia">Indonesia</option>
            <option value="Iran, Islamic Republic of">
              Iran, Islamic Republic of
            </option>
            <option value="Iraq">Iraq</option>
            <option value="Ireland">Ireland</option>
            <option value="Isle of Man">Isle of Man</option>
            <option value="Israel">Israel</option>
            <option value="Italy">Italy</option>
            <option value="Jamaica">Jamaica</option>
            <option value="Japan">Japan</option>
            <option value="Jersey">Jersey</option>
            <option value="Jordan">Jordan</option>
            <option value="Kazakhstan">Kazakhstan</option>
            <option value="Kenya">Kenya</option>
            <option value="Kiribati">Kiribati</option>
            <option value="Korea, Democratic People's Republic of">
              Korea, Democratic People's Republic of
            </option>
            <option value="Korea, Republic of">Korea, Republic of</option>
            <option value="Kuwait">Kuwait</option>
            <option value="Kyrgyzstan">Kyrgyzstan</option>
            <option value="Lao People's Democratic Republic">
              Lao People's Democratic Republic
            </option>
            <option value="Latvia">Latvia</option>
            <option value="Lebanon">Lebanon</option>
            <option value="Lesotho">Lesotho</option>
            <option value="Liberia">Liberia</option>
            <option value="Libyan Arab Jamahiriya">
              Libyan Arab Jamahiriya
            </option>
            <option value="Liechtenstein">Liechtenstein</option>
            <option value="Lithuania">Lithuania</option>
            <option value="Luxembourg">Luxembourg</option>
            <option value="Macao">Macao</option>
            <option value="Macedonia, The Former Yugoslav Republic of">
              Macedonia, The Former Yugoslav Republic of
            </option>
            <option value="Madagascar">Madagascar</option>
            <option value="Malawi">Malawi</option>
            <option value="Malaysia">Malaysia</option>
            <option value="Maldives">Maldives</option>
            <option value="Mali">Mali</option>
            <option value="Malta">Malta</option>
            <option value="Marshall Islands">Marshall Islands</option>
            <option value="Martinique">Martinique</option>
            <option value="Mauritania">Mauritania</option>
            <option value="Mauritius">Mauritius</option>
            <option value="Mayotte">Mayotte</option>
            <option value="Mexico">Mexico</option>
            <option value="Micronesia, Federated States of">
              Micronesia, Federated States of
            </option>
            <option value="Moldova, Republic of">Moldova, Republic of</option>
            <option value="Monaco">Monaco</option>
            <option value="Mongolia">Mongolia</option>
            <option value="Montenegro">Montenegro</option>
            <option value="Montserrat">Montserrat</option>
            <option value="Morocco">Morocco</option>
            <option value="Mozambique">Mozambique</option>
            <option value="Myanmar">Myanmar</option>
            <option value="Namibia">Namibia</option>
            <option value="Nauru">Nauru</option>
            <option value="Nepal">Nepal</option>
            <option value="Netherlands">Netherlands</option>
            <option value="Netherlands Antilles">Netherlands Antilles</option>
            <option value="New Caledonia">New Caledonia</option>
            <option value="New Zealand">New Zealand</option>
            <option value="Nicaragua">Nicaragua</option>
            <option value="Niger">Niger</option>
            <option value="Nigeria">Nigeria</option>
            <option value="Niue">Niue</option>
            <option value="Norfolk Island">Norfolk Island</option>
            <option value="Northern Mariana Islands">
              Northern Mariana Islands
            </option>
            <option value="Norway">Norway</option>
            <option value="Oman">Oman</option>
            <option value="Pakistan">Pakistan</option>
            <option value="Palau">Palau</option>
            <option value="Palestinian Territory, Occupied">
              Palestinian Territory, Occupied
            </option>
            <option value="Panama">Panama</option>
            <option value="Papua New Guinea">Papua New Guinea</option>
            <option value="Paraguay">Paraguay</option>
            <option value="Peru">Peru</option>
            <option value="Philippines">Philippines</option>
            <option value="Pitcairn">Pitcairn</option>
            <option value="Poland">Poland</option>
            <option value="Portugal">Portugal</option>
            <option value="Puerto Rico">Puerto Rico</option>
            <option value="Qatar">Qatar</option>
            <option value="Reunion">Reunion</option>
            <option value="Romania">Romania</option>
            <option value="Russian Federation">Russian Federation</option>
            <option value="Rwanda">Rwanda</option>
            <option value="Saint Helena">Saint Helena</option>
            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
            <option value="Saint Lucia">Saint Lucia</option>
            <option value="Saint Pierre and Miquelon">
              Saint Pierre and Miquelon
            </option>
            <option value="Saint Vincent and The Grenadines">
              Saint Vincent and The Grenadines
            </option>
            <option value="Samoa">Samoa</option>
            <option value="San Marino">San Marino</option>
            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
            <option value="Saudi Arabia">Saudi Arabia</option>
            <option value="Senegal">Senegal</option>
            <option value="Serbia">Serbia</option>
            <option value="Seychelles">Seychelles</option>
            <option value="Sierra Leone">Sierra Leone</option>
            <option value="Singapore">Singapore</option>
            <option value="Slovakia">Slovakia</option>
            <option value="Slovenia">Slovenia</option>
            <option value="Solomon Islands">Solomon Islands</option>
            <option value="Somalia">Somalia</option>
            <option value="South Africa">South Africa</option>
            <option value="South Georgia and The South Sandwich Islands">
              South Georgia and The South Sandwich Islands
            </option>
            <option value="Spain">Spain</option>
            <option value="Sri Lanka">Sri Lanka</option>
            <option value="Sudan">Sudan</option>
            <option value="Suriname">Suriname</option>
            <option value="Svalbard and Jan Mayen">
              Svalbard and Jan Mayen
            </option>
            <option value="Swaziland">Swaziland</option>
            <option value="Sweden">Sweden</option>
            <option value="Switzerland">Switzerland</option>
            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
            <option value="Taiwan, Province of China">
              Taiwan, Province of China
            </option>
            <option value="Tajikistan">Tajikistan</option>
            <option value="Tanzania, United Republic of">
              Tanzania, United Republic of
            </option>
            <option value="Thailand">Thailand</option>
            <option value="Timor-leste">Timor-leste</option>
            <option value="Togo">Togo</option>
            <option value="Tokelau">Tokelau</option>
            <option value="Tonga">Tonga</option>
            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
            <option value="Tunisia">Tunisia</option>
            <option value="Turkey">Turkey</option>
            <option value="Turkmenistan">Turkmenistan</option>
            <option value="Turks and Caicos Islands">
              Turks and Caicos Islands
            </option>
            <option value="Tuvalu">Tuvalu</option>
            <option value="Uganda">Uganda</option>
            <option value="Ukraine">Ukraine</option>
            <option value="United Arab Emirates">United Arab Emirates</option>
            <option value="United Kingdom">United Kingdom</option>
            <option value="United States">United States</option>
            <option value="United States Minor Outlying Islands">
              United States Minor Outlying Islands
            </option>
            <option value="Uruguay">Uruguay</option>
            <option value="Uzbekistan">Uzbekistan</option>
            <option value="Vanuatu">Vanuatu</option>
            <option value="Venezuela">Venezuela</option>
            <option value="Viet Nam">Viet Nam</option>
            <option value="Virgin Islands, British">
              Virgin Islands, British
            </option>
            <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
            <option value="Wallis and Futuna">Wallis and Futuna</option>
            <option value="Western Sahara">Western Sahara</option>
            <option value="Yemen">Yemen</option>
            <option value="Zambia">Zambia</option>
            <option value="Zimbabwe">Zimbabwe</option>
          </select>
          <input
            type="text"
            class="form-control"
            placeholder="Please specify"
            name="birth_country_other"
            #birth_country_other="ngModel"
            [ngModel]="currentUser.birth_country_other"
            *ngIf="currentUser.birth_country == 'other'"
          />
          <div
            class="alert alert-danger"
            *ngIf="
              (birth_country.touched &&
                birth_country.invalid)
            "
          >
            Please select your birth country.
          </div>
          <br /><br />
        </div>
      </div>
      <div class="col-sm-6">
        <div *ngIf="environment.country == 'AUS'">
          <label for="language">
            WHAT LANGUAGE DO YOU SPEAK MOST OFTEN AT HOME?<span class="required"
              >*</span
            ></label
          >
          <select
          #language="ngModel"
            name="language"
            class="form-control"
            id="language"
            [ngModel]="languageInput"
            required
            (change)="currentUser.language_other = null"
          >
            <option value="">Please Select</option>
            <option value="English">English</option>
            <option value="other">Other (please specify)</option>
          </select>
          <input
          type="text"
          class="form-control"
          placeholder="Please specify"
          name="language_other"
          #language_other="ngModel"
          [ngModel]="currentUser.language_other"
          [hidden]="language.value !== 'other'"
          [required]="language.value === 'other'"
          [pattern]="noSpacesPattern"
        />

        <div
          class="alert alert-danger"
          *ngIf="
            (language.touched && language.invalid) ||
            (language_other.invalid)
          "
        >
          Please select a preferred language.
        </div>
          <br /><br />
        </div>

        <label for="post_code">POST CODE <span class="required">*</span></label>

        <div>

        </div>
        <input
          
          type="text"
          name="post_code"
          #postcode="ngModel"
          placeholder="Postcode"
          [pattern]="getPostcodePattern()"
          required
          [attr.minlength]="getMinLength()"
          [attr.maxlength]="getMaxLength()"
          [ngModel]="currentUser.post_code"
          class="form-control"
        />



          <div
          class="alert alert-danger"
          *ngIf="
            postcode.touched && postcode.invalid
          "
        >
          There is an error with your Postcode.
        </div>



      </div>
    </div>

    <div *ngIf="!currentUser.buddy">
      <div *ngIf="unitMode == 'metric'">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="height"
                >HEIGHT (CM)<span class="required">*</span></label
              >
              <input
                type="number"
                name="height"
                class="form-control input-tracker"
                placeholder=""
                id="height"
                #height="ngModel"
                [ngModel]="currentUser.height"
                [min]="40"
                [max]="250"
                autocomplete="off"
                required
              />
              <div
                class="alert alert-danger"
                *ngIf="height.touched && height.invalid"
              >
                Please enter a valid height between 40 and 250 cm.
              </div>
            </div>
          </div>
        </div>
        <a (click)="unitMode='imperial'">Switch to Imperial</a>
      </div>
      <div *ngIf="unitMode == 'imperial'">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="heightFeetInput"
                >HEIGHT (Feet)<span class="required">*</span></label
              >
              <input
                type="text"
                name="heightFeetInput"
                #heightFeetInput="ngModel"
                class="form-control input-tracker"
                placeholder=""
                id="heightFeet"
                [(ngModel)]="heightFeet"
                min="3"
                max="9"
                autocomplete="off"
                (change)="updateHeight()"
              />
              <div
                class="alert alert-danger"
                *ngIf="heightFeetInput.touched && heightFeetInput.invalid"
              >
                Please enter a valid height.
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="heightInchesInput"
                >HEIGHT (Inches)<span class="required">*</span></label
              >
              <input
                type="text"
                name="heightInchesInput"
                class="form-control input-tracker"
                placeholder=""
                #heightInchesInput="ngModel"
                id="heightInches"
                [(ngModel)]="heightInches"
                min="0"
                max="11"
                autocomplete="off"
                (change)="updateHeight()"
              />
              <div
                class="alert alert-danger"
                *ngIf="heightInchesInput.touched && heightInchesInput.invalid"
              >
                Please enter a valid height.
              </div>
            </div>
          </div>
        </div>

        <a (click)="unitMode='metric'">Switch to Metric</a>
      </div>
      <p class="required-msg">
        <span class="required">*</span> indicates required field
      </p>
      <hr class="divider" />
      <p>
        Your other details such as weight, HbA1C, Cholesterol etc. can be
        updated in the health trackers.
      </p>
    </div>
    <hr class="divider" />
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <button class="btn btn-primary btn-white"
         
            ><span><fa-icon [icon]="['fas', 'check']"></fa-icon></span
              > SAVE</button>
        </div>
      </div>
    </div>
  </form>
</div>
