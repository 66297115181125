import { BoosterSessionsService } from './../../booster-sessions/booster-sessions.service';
import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import Swal from 'sweetalert2/src/sweetalert2.js';

@Component({
  selector: 'app-extra-sessions',
  templateUrl: './extra-sessions.component.html',
  styleUrls: ['./extra-sessions.component.scss'],
})
export class ExtraSessionsComponent implements OnInit {
  userSessionsFull = [];
  userSessions = [];
  isFetching;
  currentProgramme;
  currentUser;
  programmeContent;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private boosterSessionService: BoosterSessionsService
  ) {}

  ngOnInit(): void {
    this.isFetching = true;
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (this.currentProgramme) {
          forkJoin({
            extraSessions: this.boosterSessionService.fetchExtraSessions(
              this.currentProgramme.programme_id
            ),
            programmeContent: this.programmeService.getProgrammeContent(
              this.currentProgramme.programme_id
            ),
          }).subscribe((responseData) => {
            this.programmeContent = responseData.programmeContent;
            responseData.extraSessions.forEach((session) => {
              if (this.userSessions.indexOf(session.session_id) == -1) {
                this.userSessions.push(session.session_id);
              }
              this.userSessionsFull.push(session);
            });
          });
        }
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });
    }
  }

  addSession(session) {
    if (this.userSessions.indexOf(session.booster_session_id) != -1) {
      let index = this.userSessions.indexOf(session.booster_session_id);

      this.boosterSessionService
        .deleteUserSession(this.userSessionsFull[index].user_extra_session_id)
        .subscribe((deleteResponse) => {
          index = this.userSessions.indexOf(session.booster_session_id);
          this.userSessions.splice(index, 1);
          this.userSessionsFull.splice(index, 1);
          Swal.fire({
            title: 'Session Removed',
            text: 'The session was removed to your learning sessions',
            icon: 'success',
            timer: 1000,
            showConfirmButton: false,
          });
          this.boosterSessionService.refreshExtraSessions();
        });
    } else {
      this.boosterSessionService
        .addUserSession(
          this.currentProgramme.programme_id,
          session.booster_session_id
        )
        .subscribe((addResponse) => {
          this.userSessions.push(session.booster_session_id);
          this.userSessionsFull.push(addResponse);

          Swal.fire({
            title: 'Session Added',
            text: 'The session was added to your learning sessions',
            icon: 'success',
            timer: 1000,
            showConfirmButton: false,
          });
          this.boosterSessionService.refreshExtraSessions();
        });
    }
  }
}


