import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AuthService } from 'src/app/auth/auth.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-programme-setup-gu1de',
  templateUrl: './programme-setup-gu1de.component.html',
  styleUrls: ['./programme-setup-gu1de.component.scss']
})
export class ProgrammeSetupGu1deComponent implements OnInit {
  regDetails = {
    email: null,
    birth_country: null,
    language: null,
    smoker: null,
    exercise: null,
    attendedBefore: 0,
    gender: null,
    weight: null,
    weightStone: null,
    weightLbs: null,
    height: null,
    heightFeet: null,
    heightInches: null,
    glucosePercent: null,
    glucose: null,
    password: '',
    terms: 0,
    agree_marketing: 0,
    origin: null,
    first_name: null,
    last_name: null,
    // research_consent:1,
    mobility1:null,
    hospital_care:null
  };
  stepSubmitted = {};
  step = 1;
  environment = environment;
  codeError;
  isFetching;
  unitMode = 'metric';
  regProgrammeConfig;
  regProgramme;
  hba1cUnitMode = 'percent';
  code;
  teamNames;
  team_id;
  bsValue = new Date('1960/01/01');
  minDob = moment().subtract('year', 110).toDate();
  maxDob = moment().subtract('year', 16).toDate();
  noSpacesPattern = /.*[^ ].*/;

  passwordPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#])[A-Za-z\d$=@$!%*?&#]{10,}$/;

  currentUser = null;

  @ViewChild('regForm', { static: false }) regForm: NgForm;

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute
  ) {}



  ngOnInit(): void {
    this.isFetching = true;
    this.step = 1;
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userService.userData.subscribe((userDataResponse) => {
        if(userDataResponse){
          this.currentUser = userDataResponse;

          if (this.currentUser.since_participated || this.currentUser.hospital_care || this.currentUser.since_diagnosed || this.currentUser.gu1de_reason) {
            this.router.navigate(['/dashboard']);
          }
          this.isFetching = false;
        }
        else{

          this.isFetching = false;
        }
        });
      } else {
        this.isFetching = false;
      }
    });

  }


  
  checkReg(regStep) {
    this.isFetching = true;
    this.stepSubmitted[regStep] = true;
    switch (regStep) {

      case 1:
        // work out the weight

        if (
          !this.regForm.controls.gu1de_reason.invalid &&
          !this.regForm.controls.attendedBefore.invalid &&
          !this.regForm.controls.since_participated.invalid &&
          !this.regForm.controls.since_diagnosed.invalid &&
          !this.regForm.controls.hospital_care.invalid
        ) {
          this.step = 4;
          this.isFetching = false;
          window.scrollTo(0, 0);

          // complete it

          this.isFetching = false;

          // submit it
          this.authService.setupProgrammeGu1de(this.regForm.value).subscribe(
            (responseData) => {
              // console.log(responseData);
              this.userService.reset();
                  this.userService.get().subscribe((userData) => {
                    this.currentUser = userData;
                  });
              this.step = 2;
  
              this.isFetching = false;
  
            },
            (error) => {
              console.log(error);
              Swal.fire({
                title: 'Error creating account',
                text: 'There was an error setting up your account. Please try again.',
                icon: 'error',
              });
              this.isFetching = false;
            }
          );



        } else {
          this.isFetching = false;
          Swal.fire({
            title: "We notice you've missed something",
            text: 'Please check the form for information that has been missed',
            icon: 'error',
          });
        }
      

        break;

      
        
    }
  }

  complete() {
    this.router.navigate(['/dashboard']);
  }

  onSubmit() {
    // console.log(this.regForm.value);
  }
}
