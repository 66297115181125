import { TargetService } from './../services/target.service';
import { NotificationService } from './../services/notification.service';
import { environment } from './../../environments/environment';
import { BoosterSessionsService } from './../booster-sessions/booster-sessions.service';
import { UserService } from './../services/user.service';
import { ProgrammeService } from './../services/programme.service';
import { AuthService } from './../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { forkJoin, interval } from 'rxjs';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { PageViewService } from '../services/page-view.service';
import { LearningMaterialService } from '../services/learning-material.service';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss'],
})
export class SessionsComponent implements OnInit {
  currentUser;
  currentProgramme;
  isFetching = false;
  programmeContent;
  errors = [];
  learningSessionTotals;
  learningSessionProgressTotal;
  boosterSessionTotals;
  boosterSessionProgressTotal;
  sToday;
  currentProgrammes;
  currentTeam;
  environment = environment;
  notifications = [];
  searchQuery = '';
  notificationsInterval;
  userSessions = [];
  lastViewed;
  lastViewedPage;
  stepTarget;

  menuToggle = {
    booster: false,
    learning: false,
    steps: false,
    chat: false,
    trackers: false,
    help: false,
    education: false,
    programmes: false,
    user: false,
  };

  sessionType;

  constructor(
    private authService: AuthService,
    private programmeService: ProgrammeService,
    private userService: UserService,
    private boosterSessionService: BoosterSessionsService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private pageViewService: PageViewService,
    private learningMaterialService: LearningMaterialService,
    private targetService: TargetService
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe((routeData) => {
      this.sessionType = routeData.sessionType;
      // console.log(this.sessionType);
    });
    // console.log(this.environment);
    this.isFetching = true;
    this.sToday = moment().format('YYYY-MM-DD');
    // are we logged in?
    this.authService.user.subscribe((user) => {
      if (user) {
        this.programmeService.currentProgramme.subscribe((programmeData) => {
          this.currentProgramme = programmeData;
          if (this.currentProgramme) {
            forkJoin({
              sessionProgress:
                this.boosterSessionService.getSessionsProgressData(
                  this.currentProgramme.programme_id
                ),
              notifications: this.notificationService.getNotifications(0),
              pageviews: this.pageViewService.fetchAll(),
              stepTarget: this.targetService.getTarget('step')
            }).subscribe(
              (responseData) => {
                this.isFetching = false;
                this.notifications = responseData.notifications;

                this.stepTarget = responseData.stepTarget;

                const sessionProgress = this.boosterSessionService.doSessions(
                  responseData.sessionProgress,
                  user.created
                );
                this.learningSessionTotals =
                  sessionProgress.learningSessionTotals;
                this.learningSessionProgressTotal =
                  sessionProgress.learningSessionProgressTotal;
                this.boosterSessionTotals =
                  sessionProgress.boosterSessionTotals;
                this.boosterSessionProgressTotal =
                  sessionProgress.boosterSessionProgressTotal;
                this.programmeContent = sessionProgress.programmeContent;

                // last viewed
                this.lastViewed = responseData.pageviews[0];
                if (this.lastViewed) {
                  if (this.lastViewed.booster_session_id) {
                    this.boosterSessionService
                      .fetchStep(this.lastViewed.page_id)
                      .subscribe((lastViewedResponse) => {
                        if (lastViewedResponse) {
                          this.lastViewedPage = lastViewedResponse;
                          this.lastViewedPage.parent = {};
                          // get the parent..
                          this.programmeContent.booster_sessions.forEach(
                            (bs) => {
                              if (
                                bs.booster_session_id ==
                                this.lastViewedPage.booster_session_id
                              ) {
                                this.lastViewedPage.parent = bs;
                                if (bs.release_date == 'O') {
                                  this.lastViewedPage.parent.type =
                                    'learningSession';
                                } else {
                                  this.lastViewedPage.parent.type =
                                    'boosterSession';
                                }
                              }
                            }
                          );
                        }
                      });
                  }

                  if (this.lastViewed.content_section_id) {
                    this.learningMaterialService
                      .fetchPage(this.lastViewed.page_id)
                      .subscribe((lastViewedResponse) => {
                        if (lastViewedResponse) {
                          this.lastViewedPage = lastViewedResponse;
                          this.lastViewedPage.parent = {};

                          this.programmeContent.content_sections.forEach(
                            (bs) => {
                              if (
                                bs.content_section_id ==
                                this.lastViewedPage.content_section_id
                              ) {
                                this.lastViewedPage.parent = bs;
                              }
                            }
                          );
                        }
                      });
                  }
                }
              },
              (error) => {
                this.isFetching = false;
                this.errors.push(error.message);
              }
            );
          }
        });
        this.userService.userData.subscribe((userDataResponse) => {
          this.currentUser = userDataResponse;
        });
      }
    });
  }



  getBoosterSessions(sessions){
    let returnSessions = [];
    sessions.forEach((session) => {
      if(session.release_date !== 'O' && session.release_date !== 'EPL' ){
        returnSessions.push(session);
      }
    });
    return returnSessions;
  }

  getLearningSessions(sessions){
    let returnSessions = [];
    sessions.forEach((session) => {
      if(session.release_date == 'O' || session.release_date == 'EPL' ){
        returnSessions.push(session);
      }
    });
    return returnSessions;
  }
}
