<nav class="subnav">
  <a [routerLink]="['/activity', 'strength']" aria-label="Go to strength training" class="subnav-prev"
  ><span class="icon-wrap"
    ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
></a>
  <h2 *ngIf="currentUser">
    <span class="icon-wrap">
      <fa-icon [icon]="['far', 'dumbbell']"></fa-icon>
    </span>
    ADD STRENGTH ENTRY
  </h2>
</nav>
<div>
  <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
  <div class="toggle-content">
    <form
      class="bs-form tracker-form"
      name="trackerForm"
      method="post"
      (ngSubmit)="onSubmit()"
      #trackerForm="ngForm"
    >
      <fieldset>
        <p class="instruction"></p>
        <div class="form-group">
          <div class="row mx-0">
            <div class="col-md-4">
              <div class="form-group">
                <label for="weightStone">DATE</label>
                <p class="instructions">
                  When did you do this strength workout?
                </p>
                <input
                  name="symptomDate"
                  type="text"
                  placeholder="DD/MM/YYYY"
                  class="form-control input-tracker"
                  bsDatepicker
                  autocomplete="off"
                  [bsConfig]="{
                    dateInputFormat: 'DD/MM/YYYY',
                    containerClass: 'theme-cv',
                    showWeekNumbers: false
                  }"
                  [(ngModel)]="workoutDate"
                  required
                  [bsValue]="workoutDate"
                  #symptomDate="ngModel"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="minutes">MINUTES</label>
                <p class="instructions">
                  How many minutes did your workout last?
                </p>
                <input
                  type="number"
                  step="1"
                  name="minutes"
                  class="form-control input-tracker"
                  placeholder=""
                  id="minutes"
                  [min]="1"
                  [max]="900"
                  autocomplete="off"
                  #minutes="ngModel"
                  required
                  [ngModel]="minutes"
                  [ngClass]="{
                    'is-invalid': minutes.touched && !minutes.valid
                  }"
                />

                <div
                  class="alert alert-danger"
                  *ngIf="minutes.touched && !minutes.valid"
                >
                  Please select a valid number of minutes
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="effort">EFFORT</label>
                <p class="instructions">
                  How much effort did this workout take?
                </p>
                <input
                  type="number"
                  step="1"
                  name="effort"
                  class="form-control input-tracker"
                  placeholder=""
                  id="effort"
                  [min]="1"
                  [max]="10"
                  max="10"
                  min="1"
                  autocomplete="off"
                  #effort="ngModel"
                  required
                  [ngModel]="effort"
                  [ngClass]="{ 'is-invalid': effort.touched && !effort.valid }"
                />
                <p><span class="small">From 1 (Not much) to 10 (A Lot)</span></p>
                <div
                class="alert alert-danger"
                *ngIf="effort.touched && !effort.valid"
              >
                Please select a valid effort (1-10)
              </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tracker-submit-container">
          <button
            type="submit"
            class="btn btn-primary btn-white"
            value="Confirm"
            [disabled]="!trackerForm.valid"
          >
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'check']"></fa-icon
            ></span>
            SAVE WORKOUT
          </button>
        </div>
      </fieldset>
    </form>
  </div>
</div>