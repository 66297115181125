<div>
    <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
    <div *ngIf="boosterSession">
      <nav class="subnav">
        <a class="subnav-prev" *ngIf="prevSession" [routerLink]="['/booster-sessions', 'index', prevSession.booster_session_id]"><span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span></a>
        <h2 class="uppercase-title">
          <span class="icon-wrap"><fa-icon [icon]="['fas', 'bolt']"></fa-icon></span>
          {{ boosterSessionProgramme?.booster_session_title }}
        </h2>
        <a *ngIf="nextSession" [routerLink]="['/booster-sessions', 'index', nextSession.booster_session_id]" class="subnav-next"
          ><span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span></a>
      </nav>
      <div *ngIf="boosterSession.status">
        <div
          *ngIf="boosterSession.status != 1 && currentUser.is_admin != 1"
        >
          <br />
          <p class="text-center">
            This booster session is currently unavailable.
          </p>
        </div>
        <div ng-show="boosterSession.status === 1 || currentUser.is_admin === 1">
          <div
            class="section-img-masthead"
            *ngIf="boosterSession.image_url"
            [ngStyle]="{'background-image' :  'url(' + environment.assetUrl + '/assets/' + boosterSession.image_url + ')'}"
          ></div>
          <nav class="section-nav section-nav-booster-session">
            <ul>
              <li
                *ngFor="
                  let boosterSessionStep of boosterSessionSteps;
                  let index = index
                "
              >
                <a [routerLink]="['/booster-session-steps', 'view', boosterSessionStep.booster_session_step_id]">
                  <span class="num">{{ index + 1 }}</span>
                  <span class="section-nav-label">{{ boosterSessionStep.title }}</span>
                  
                  <span class="section-nav-arrow">
                    <fa-icon *ngIf="boosterSessionStep.progress!='viewed'" [icon]="['far', 'arrow-circle-right']" [fixedWidth]="true"></fa-icon>
                    <fa-icon *ngIf="boosterSessionStep.progress==='viewed'" class="text-primary"  [icon]="['fas', 'check-circle']" [fixedWidth]="true"></fa-icon>
                  </span>

                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
  