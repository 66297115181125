import { environment } from './../../../../environments/environment';
import { CommentService } from './../../../services/comment.service';
import { ProgrammeService } from './../../../services/programme.service';
import { UserService } from './../../../services/user.service';
import { AuthService } from './../../../auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-forum-search',
  templateUrl: './forum-search.component.html',
  styleUrls: ['./forum-search.component.scss'],
})
export class ForumSearchComponent implements OnInit {
  isFetching = false;
  results;
  query;
  currentProgramme;
  currentUser;
  totalResults = 0;
  environment = environment;
  @ViewChild('forumSearchForm', { static: false }) forumSearchForm: NgForm;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private router: Router,
    private commentService: CommentService
  ) {}

  ngOnInit(): void {
    // are we logged in?
    this.isFetching = true;
    this.authService.user.subscribe((user) => {
      if (user) {
        this.programmeService.currentProgramme.subscribe((programmeData) => {
          if (programmeData) {
            this.currentProgramme = programmeData;
            this.route.queryParams.subscribe((params) => {
              this.query = params['q'];

              this.isFetching = false;

              this.commentService
                .searchForum(this.query, this.currentProgramme.programme_id)
                .subscribe(
                  (responseData) => {
                    this.results = responseData;
                    this.isFetching = false;
                  },
                  (error) => {
                    this.isFetching = false;
                  }
                );
            });
          }
        });
        this.userService.userData.subscribe((userDataResponse) => {
          this.currentUser = userDataResponse;
        });
      }
    });
  }

  onSubmitForumSearch() {
    // this.router.navigate
    this.router.navigate(['/chat/forum/search'], {
      queryParams: { q: this.forumSearchForm.value.query },
    });
  }

  visitTopic(comment){
    if(comment.comment_type == 'global_chat'){
      this.router.navigateByUrl('/chat/view/global_chat/' + comment.comment_id);
    }
    else{
      this.router.navigateByUrl('/chat/view/global_chat/' + comment.comment_type_ref + '#comment-' + comment.comment_id);
    }
  }
}
