import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-symptom-tracker-index',
  templateUrl: './symptom-tracker-index.component.html',
  styleUrls: ['./symptom-tracker-index.component.scss']
})
export class SymptomTrackerIndexComponent implements OnInit {


  symptoms = [
    { name: 'itching', slug: 'itching', iconWeight: 'far', icon: 'allergies' },
    {
      name: 'Sleep disturbance',
      slug: 'sleep-disturbance',
      iconWeight: 'far',
      icon: 'alarm-clock',
    },
    {
      name: 'Loss of appetite',
      slug: 'loss-of-appetite',
      iconWeight: 'far',
      icon: 'stomach',
    },
    {
      name: 'Feeling tired',
      slug: 'feeling-tired',
      iconWeight: 'far',
      icon: 'snooze',
    },
    {
      name: 'Pain in bones/joints',
      slug: 'pain-in-bones-joints',
      iconWeight: 'far',
      icon: 'bone',
    },
    {
      name: 'Poor concentration',
      slug: 'poor-concentration',
      iconWeight: 'far',
      icon: 'head-side-brain',
    },
    {
      name: 'Loss of libido',
      slug: 'loss-of-libido',
      iconWeight: 'far',
      icon: 'bed-alt',
    },
    {
      name: 'Loss of muscle strength',
      slug: 'loss-of-muscle-strength',
      iconWeight: 'far',
      icon: 'fist-raised',
    },
    {
      name: 'Shortness of breath',
      slug: 'shortness-of-breath',
      iconWeight: 'far',
      icon: 'lungs',
    },
    {
      name: 'Cramp/Muscle stiffness',
      slug: 'cramp-muscle-stiffness',
      iconWeight: 'far',
      icon: 'tired',
    },
    {
      name: 'Restless legs',
      slug: 'restless-legs',
      iconWeight: 'far',
      icon: 'socks',
    },
    {
      name: 'Need to urinate more often',
      slug: 'need-to-urinate-more-often',
      iconWeight: 'far',
      icon: 'toilet',
    },
    {
      name: 'Feeling cold',
      slug: 'feeling-cold',
      iconWeight: 'far',
      icon: 'temperature-frigid',
    },
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
