<nav class="subnav">
  <h2><span
    ><fa-icon [icon]="['fas', 'bell']"></fa-icon
  ></span> NOTIFICATIONS</h2>
  <a [routerLink]="['/profile']" class="subnav-next"
    > <span
    ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon
  ></span></a>
</nav>

<div class="container">
  <!-- Nav tabs -->
  <ul class="nav nav-tabs nav-tabs-duo justify-content-center" role="tablist">
    <li role="presentation" [ngClass]="{ active: showNew }">
      <a
        aria-controls="percent"
        role="tab"
        data-toggle="tab"
        (click)="showNew = true;"
        >NEW NOTIFICATIONS
        <span *ngIf="notifications.length>0" class="notification">{{
          notifications.length
        }}</span></a
      >
    </li>
    <li role="presentation" [ngClass]="{ active: !showNew }">
      <a
        aria-controls="step-count"
        role="tab"
        data-toggle="tab"
        (click)="showNew = false"
        >OLD NOTIFICATIONS</a
      >
    </li>
  </ul>

  <!-- Tab panes -->
  <div class="tab-content">
    <div role="tabpanel"  id="percent" *ngIf="showNew">
      <ul class="notifications">
        <li *ngFor="let notification of  notifications">

          <span class="icon"><fa-icon [icon]="['fas', 'comment']"></fa-icon
  ></span>

          <div>
            <span
              class="comment"
              [innerHtml]="notification.content | safeHtml"
            ></span> <a *ngIf="notification.link" [href]="notification.link"
              >View here.</a
            >
          </div>
          <span class="comment-date" timeago [date]="notification.created | dateFormatter : ''" [live]="true" ></span>
        </li>
      </ul>
      <p *ngIf="!notifications">No new notifications</p>
    </div>
    <div
      role="tabpanel"
      id="step-count"
      *ngIf="!showNew"
    >
      <ul class="notifications">
        <li *ngFor="let notification of oldNotifications">
          <span class="icon"><fa-icon [icon]="['fas', 'comment']"></fa-icon
            ></span>
          <div>
            <span
              class="comment"
              [innerHtml]="notification.content | safeHtml"
            ></span> <a *ngIf="notification.link" [href]="notification.link"
              >View here.</a
            >
          </div>
          <span class="comment-date" timeago [date]="notification.created | dateFormatter : ''" [live]="true" ></span>
        </li>
      </ul>
      <p *ngIf="oldNotifications.length == 0">No old notifications</p>
      <p *ngIf="oldNotifications.length > 0" class="text-center">
        <button class="btn btn-primary btn-sm" (click)="hideOld();">
          HIDE ALL
        </button>
      </p>
    </div>
  </div>
</div>
