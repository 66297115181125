import { AuthService } from './../auth/auth.service';
import { CmsService } from './../services/cms.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-agree-terms',
  templateUrl: './agree-terms.component.html',
  styleUrls: ['./agree-terms.component.scss'],
})
export class AgreeTermsComponent implements OnInit {
  col1 = true;
  col2 = false;
  col3 = false;
  cms;

  constructor(private cmsService: CmsService, private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.cmsService.fetchByKey(['terms_of_use', 'privacy_policy', 'acceptable_use_policy']).subscribe((responseData) => {
      this.cms = responseData;
    });
  }

  onAgree(){
    this.authService.agreeTerms().subscribe((responseData) => {
      this.router.navigate(['/dashboard']);

    });
  }
}
