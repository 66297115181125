import { UserService } from './../services/user.service';
import { ProgrammeService } from './../services/programme.service';
import { AuthService } from './../auth/auth.service';
import { environment } from './../../environments/environment';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import {
  ActivatedRoute,
  ActivationStart,
  NavigationEnd,
  Router,
} from '@angular/router';

@Component({
  selector: 'app-footer-nav',
  templateUrl: './footer-nav.component.html',
  styleUrls: ['./footer-nav.component.scss'],
})
export class FooterNavComponent implements OnInit, OnDestroy {
  hasFooterNav = true;
  currentProgramme;
  currentUser;
  environment = environment;
  showSub = '';
  currentUrl;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.showSub = '';
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private authService: AuthService,
    private programmeService: ProgrammeService,
    private userService: UserService,
    private router: Router,
    private eRef: ElementRef,
    private route: ActivatedRoute
  ) {
    // are we logged in?
    this.authService.user.subscribe((user) => {
      if (user) {
        this.programmeService.currentProgramme.subscribe((programmeData) => {
          this.currentProgramme = programmeData;
        });
        this.userService.userData.subscribe((userDataResponse) => {
          this.currentUser = userDataResponse;
        });
      }
    });
  }

  ngOnInit(): void {
    this.currentUrl = this.router.url.split('/')[1];
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url.split('/')[1];
      }
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.showSub = '';
    });
    this.renderer.addClass(this.document.body, 'has-footer-nav');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'has-footer-nav');
  }
  onToggleSub(event, area) {
    event.preventDefault();
    event.stopPropagation();
    this.showSub = area;
  }
}
