import { NgForm } from '@angular/forms';
import { DateHelperService } from './../services/date-helper.service';
import { TargetService } from './../services/target.service';
import { ActivityService } from './../services/activity.service';
import { UserSettingsService } from './../services/user-settings.service';
import { ProgrammeService } from './../services/programme.service';
import { UserService } from './../services/user.service';
import { AuthService } from './../auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from './../../environments/environment';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { Color, Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';

declare var Chart;
import { BaseChartDirective } from 'ng2-charts';

import Swal from 'sweetalert2/src/sweetalert2.js';
import { ConditionalExpr } from '@angular/compiler';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
})
export class StepsComponent implements OnInit {
  @ViewChild('stepsForm', { static: false }) stepsForm: NgForm;
  @ViewChild('speedForm', { static: false }) speedForm: NgForm;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  showWalkingSpeed = false;
  currentUser;
  currentProgramme;
  deviceSynced = 'unknown';
  isFetching = false;
  errors = [];
  userSettings;

  currentActivity;
  currentTarget;
  currentTargetMins;
  environment = environment;
  currentSteps = 0;
  now;
  currentWeek;
  previousWeek = {
    startDate: null,
    endDate: null,
  };
  nextWeek = {
    startDate: null,
    endDate: null,
  };
  weekTotal = 0;
  weekData = [];

  toggleRow = {
    update: false,
    graph: false,
    cadence: false,
  };
  cadence;

  showMonth = true;
  date = new Date();
  minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 5));
  startDate;
  endDate;

  // chart

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: '',
            fontColor: '#333',
          },
          gridLines: {
            color: '#555',
            drawOnChartArea: false,
          },
          ticks: {
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          gridLines: {
            color: '#555',
            drawOnChartArea: false,
          },
          scaleLabel: {
            display: true,
            labelString: '',
            fontColor: '#333',
          },
          ticks: {
            min: 0,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    layout: {
      padding: {
        top: -10,
        left: -10,
        right: -10,
        bottom: -10,
      },
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
    animation: {
      onComplete: function () {
        var ctx = this.chart.ctx;
        ctx.font = Chart.helpers.fontString(
          Chart.defaults.global.defaultFontFamily,
          'normal',
          Chart.defaults.global.defaultFontFamily
        );
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';

        let dataTotals: any;
        dataTotals = {};
        this.data.datasets.forEach((dataset, index) => {
          for (var i = 0; i < dataset.data.length; i++) {
            if (!dataTotals[i]) {
              dataTotals[i] = 0;
            }
            var model =
                dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
              scale_max =
                dataset._meta[Object.keys(dataset._meta)[0]].data[i]._yScale
                  .maxHeight;

            ctx.fillStyle = '#444';
            var y_pos = model.y - 5;

            dataTotals[i] += +dataset.data[i];
            if (
              +dataTotals[i] >= this.options.lineAt &&
              (index + 1 == this.data.datasets.length ||
                this.data.datasets[1].data.length == 0)
            ) {
              // draw a star
              ctx.save();
              const path = new Path2D(
                'M -0.8095 -7.6293 L -2.8665 -3.4587 L -7.4687 -2.7877 c -0.8253 0.1197 -1.1561 1.1371 -0.5575 1.7199 l 3.3296 3.2445 l -0.7875 4.5833 c -0.1418 0.8284 0.7308 1.449 1.4616 1.0615 L 0.0945 5.6574 l 4.117 2.1641 c 0.7308 0.3843 1.6033 -0.2331 1.4616 -1.0615 l -0.7875 -4.5833 l 3.3296 -3.2445 c 0.5985 -0.5827 0.2677 -1.6002 -0.5575 -1.7199 L 3.0555 -3.4587 L 0.9985 -7.6293 c -0.3685 -0.7434 -1.4364 -0.7529 -1.8081 0 z'
              );
              ctx.translate(model.x, y_pos + 32);
              ctx.fillStyle = '#ffffff';
              ctx.fill(path);
              ctx.restore();
            }
          }
        });
      },
    },
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [
    { data: [], label: 'steps', maxBarThickness: 50 },
  ];
  public barChartColors: Color[] = [
    { backgroundColor: this.environment.primaryColor },
  ];
  //

  // Line chart
  public lineChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            beginAtZero: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          gridLines: {
            display: true,
            lineWidth: 1,
            color: '#95989A',
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            lineWidth: 2,
            color: '#95989A',
          },
          type: 'time',
          ticks: {
            autoSkip: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          time: {
            parser: 'YYYY-MM-DD HH:mm:ss',
            unit: 'day',
            displayFormats: {
              day: 'MMM-DD',
            },
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public lineChartLabels: Label[] = [];
  public lineChartType: ChartType = 'line';
  public lineChartLegend = true;

  public lineChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'Monthly',
      borderWidth: 1,
      borderColor: environment.chartDefaults.colors[0],
      fill: false,
      pointRadius: 5,
    },
  ];
  public lineChartColors: Color[] = [
    { backgroundColor: this.environment.primaryColor },
  ];

  // All time chart..
  public allTimeChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            beginAtZero: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          gridLines: {
            display: true,
            lineWidth: 1,
            color: '#95989A',
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            lineWidth: 2,
            color: '#95989A',
          },
          type: 'time',
          ticks: {
            autoSkip: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          time: {
            parser: 'YYYY-MM-DD HH:mm:ss',
            unit: 'day',
            displayFormats: {
              day: 'MMM-DD',
            },
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public allTimeChartLabels: Label[] = [];
  public allTimeChartType: ChartType = 'line';
  public allTimeChartLegend = true;

  public allTimeChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'All Time',
      borderWidth: 1,
      borderColor: environment.chartDefaults.colors[0],
      fill: false,
      pointRadius: 2,
    },
  ];
  public allTimeChartColors: Color[] = [
    { backgroundColor: this.environment.primaryColor },
  ];

  // algorithm for EP

  algorithmComplete = false;
  algorithmDays = [
    { day: '', status: 0 },
    { day: '', status: 0 },
    { day: '', status: 0 },
  ];
  algorithmDaysRemaining = 3;
  activitySetupStep = 1;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private userSettingsService: UserSettingsService,
    private activityService: ActivityService,
    private targetService: TargetService,
    private dateHelperService: DateHelperService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.isFetching = true;
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (this.currentProgramme) {
          this.barChartColors[0].backgroundColor =
            this.currentProgramme.config.primaryColor;
        }
        this.userService.userData.subscribe((userDataResponse) => {
          this.currentUser = userDataResponse;
          this.cadence = this.currentUser.cadence;
          // is it an EP programme?
          if (this.currentProgramme.config.exerciseProgramme == 1) {
            this.minDate = moment(this.currentUser.created).toDate();
          }
        });
      });

      const now = moment();
      this.currentWeek = this.dateHelperService.getWeekFor(now);
      this.now = moment(now).format('YYYY-MM-DD');
      const startDate = moment(this.currentWeek[0]).format('YYYY-MM-DD');
      const endDate = moment(this.currentWeek[6]).format('YYYY-MM-DD');

      this.previousWeek.startDate = moment(this.currentWeek[0])
        .subtract(1, 'week')
        .format('YYYY-MM-DD');
      this.previousWeek.endDate = moment(this.currentWeek[6])
        .subtract(1, 'week')
        .format('YYYY-MM-DD');

      // get months data
      forkJoin({
        userSettings: this.userSettingsService.fetchAll(),
        activity: this.activityService.getActivity(0),
        target: this.targetService.getTarget('step'),
        activityBetween: this.activityService.getActivityBetween(
          startDate,
          endDate
        ),
        monthData: this.activityService.getActivityBetween(
          moment().subtract(1, 'months').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ),
        allTimeData: this.activityService.getActivityBetween(
          moment().subtract(10, 'years').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ),
      }).subscribe(
        (responseData) => {
          if (
            moment(this.previousWeek.endDate).toDate() <
            moment(this.currentUser.created).toDate()
          ) {
            this.previousWeek = null;
          }
          this.isFetching = false;
          this.userSettings = responseData.userSettings;
          this.currentActivity = responseData.activity;
          if (this.currentActivity) {
            this.currentSteps = this.currentActivity.steps;
          }

          if (responseData.target) {
            this.currentTarget = responseData.target.target;
          } else {
            this.generateAlgorithmDays();
          }
          const weeklyActivities = responseData.activityBetween;
          this.weekTotal = 0;
          weeklyActivities.forEach((activity) => {
            this.weekTotal = Number(this.weekTotal) + Number(activity.steps);
          });

          // week data
          this.weekData = [];

          this.currentWeek.forEach((item, index) => {
            const thisActivity = weeklyActivities.filter(this.search, {
              activity_date: moment(item).format('YYYY-MM-DD'),
            })[0];

            if (thisActivity) {
              if (
                thisActivity.hit_goal !== 1 &&
                +thisActivity.percent_hit >= 100
              ) {
                thisActivity.hit_goal = 1;
              }
              this.weekData.push(thisActivity);
            } else {
              const dData = {
                activity_date: moment(item).format('YYYY-MM-DD'),
              };
              this.weekData.push(dData);
            }
          });

          this.setChartData(this.weekData);
          this.setMonthChartData(responseData.monthData);
          this.setAllTimeChartData(responseData.allTimeData);

          if (this.route.snapshot.data['walkingSpeed']) {
            this.showWalkingSpeed = true;
            this.toggleRow.cadence = true;
            setTimeout(() => {
              let el = document.getElementById('setWalkingSpeed');
              el.scrollIntoView();
            }, 500);
          }
          // end
        },
        (error) => {
          this.isFetching = false;
          this.errors.push(error.message);
        }
      );
    }
  }

  search(obj) {
    return Object.keys(this).every((key) => obj[key] === this[key]);
  }

  setChartData(activity) {
    // do they have step intensity?
    let hasIntensity = true;
    if (this.weekTotal == 0 || this.currentUser.device == 'manual' || this.currentUser.device == 'garmin') {
      hasIntensity = false;
    }
    activity.forEach((activity) => {
      if (
        +activity.steps > 0 &&
        !activity.light_steps &&
        !activity.moderate_steps &&
        !activity.vigorous_steps
      ) {
        hasIntensity = false;
      }
    });

    // has step intensity
    if (hasIntensity) {
      this.barChartData[1] = {};
      this.barChartOptions.legend.display = true;
      this.barChartOptions.legend.position = 'bottom';
      this.barChartColors[0].backgroundColor = '#b8cde8';
      this.barChartColors[1] = { backgroundColor: '#5591c7' };
      //this.barChartColors[2] = { backgroundColor: '#5591c7' }
      this.barChartData[0].label = 'Light';
      this.barChartData[1] = { data: [], label: 'Brisk', maxBarThickness: 50 };
      //this.barChartData[2] = { data: [], label: 'Vigorous', maxBarThickness: 50 };
      this.barChartData[0].data = [];
      this.barChartData[1].data = [];
      // this.barChartData[2].data = [];
    } else {
      this.barChartData[0].label = 'Steps';
      this.barChartOptions.legend.display = false;
      this.barChartData[0].data = [];
    }

    if (moment(activity[0].activity_date).format('ddd') !== 'Monday') {
      let startDate = moment(this.currentWeek[0]).format('YYYY-MM-DD');

      while (
        moment(startDate).format('YYYY-MM-DD') !==
        moment(activity[0].activity_date).format('YYYY-MM-DD')
      ) {
        this.barChartData[0].data.push(0);
        startDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD');
      }
    }

    this.barChartLabels = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
    let maxSteps = 0;
    activity.forEach((value, key) => {
      if (+value.steps > +maxSteps) {
        maxSteps = +value.steps;
      }
      if (hasIntensity) {
        this.barChartData[0].data.push(value.light_steps);
        this.barChartData[1].data.push(
          +value.moderate_steps + +value.vigorous_steps
        );
        //this.barChartData[2].data.push(value.vigorous_steps);
      } else {
        this.barChartData[0].data.push(value.steps);
      }
      // have they hit target?
      //if(+(value.steps)>=+(this.currentTarget)){
      //this.datasetOverride[0].backgroundColor[key] = '#FDCE06';

      // }

      // add the line

      Chart.pluginService.register({
        afterDraw(chart) {
          if (typeof chart.config.options.lineAt != 'undefined') {
            var lineAt = chart.config.options.lineAt;
            var ctxPlugin = chart.chart.ctx;
            var xAxe = chart.scales[chart.config.options.scales.xAxes[0].id];
            var yAxe = chart.scales[chart.config.options.scales.yAxes[0].id];

            if (yAxe.min != 0) return;

            ctxPlugin.strokeStyle = '#cccccc';
            ctxPlugin.lineWidth = 1;
            ctxPlugin.beginPath();

            lineAt = (lineAt - yAxe.min) * (100 / yAxe.max);
            lineAt = ((100 - lineAt) / 100) * yAxe.height + yAxe.paddingTop;

            ctxPlugin.moveTo(xAxe.left, lineAt);
            ctxPlugin.lineTo(xAxe.right, lineAt);
            ctxPlugin.stroke();
          }
        },
      });
    });

    // this.barChartOptions.scales.yAxes[0].ticks.max = 10000;
    if (+maxSteps < +this.currentTarget) {
      // this.barChartOptions.scales.yAxes[0].ticks.max = +this.currentTarget;
      this.barChartOptions.scales.yAxes[0].ticks.max =
        Math.ceil(+this.currentTarget / 1000.0) * 1000;
    } else if (maxSteps) {
      this.barChartOptions.scales.yAxes[0].ticks.max =
        Math.ceil(+maxSteps / 1000.0) * 1000;
    } else {
      this.barChartOptions.scales.yAxes[0].ticks.max = 10000;
    }
    if (this.currentTarget) {
      this.barChartOptions['lineAt'] = this.currentTarget;
    }

    // reverse them
    // this.barChartData[0].data.reverse();
    // this.barChartLabels.reverse();
  }

  setMonthChartData(activities) {
    this.lineChartData[0].data = [];
    // setup chart data
    activities.forEach((value, key) => {
      this.lineChartLabels.push(
        moment(value.activity_date).format('YYYY-MM-DD')
      );
      this.lineChartData[0].data.push(value.steps);
    });
  }

  setAllTimeChartData(activities) {
    this.allTimeChartData[0].data = [];
    // setup chart data
    activities.forEach((value, key) => {
      this.allTimeChartLabels.push(
        moment(value.activity_date).format('YYYY-MM-DD')
      );
      this.allTimeChartData[0].data.push(value.steps);
    });
  }

  onSubmitStepsForm() {
    // save the steps

    this.activityService
      .saveSteps(this.stepsForm.value.steps, this.stepsForm.value.date)
      .subscribe(
        (responseData) => {
          const startDate = moment(this.currentWeek[0]).format('YYYY-MM-DD');
          const endDate = moment(this.currentWeek[6]).format('YYYY-MM-DD');

          forkJoin({
            activity: this.activityService.getActivity(),
            activityBetween: this.activityService.getActivityBetween(
              startDate,
              endDate
            ),
          }).subscribe((forkResponseData) => {
            this.currentActivity = forkResponseData.activity;
            if (this.currentActivity) {
              this.currentSteps = this.currentActivity.steps;
            }
            const weeklyActivities = forkResponseData.activityBetween;
            this.weekTotal = 0;
            weeklyActivities.forEach((activity) => {
              this.weekTotal = Number(this.weekTotal) + Number(activity.steps);
            });
            // week data
            this.weekData = [];
            this.currentWeek.forEach((item, index) => {
              const thisActivity = weeklyActivities.filter(this.search, {
                activity_date: moment(item).format('YYYY-MM-DD'),
              })[0];

              if (thisActivity) {
                if (
                  thisActivity.hit_goal !== 1 &&
                  +thisActivity.percent_hit >= 100
                ) {
                  thisActivity.hit_goal = 1;
                }
                this.weekData.push(thisActivity);
              } else {
                const dData = {
                  activity_date: moment(item).format('YYYY-MM-DD'),
                };
                this.weekData.push(dData);
              }
            });
            // console.log(this.weekData);
            this.setChartData(this.weekData);
            // refresh the route..
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => this.router.navigate(['/steps']));
          });

          Swal.fire({
            icon: 'success',
            title: 'Steps Updated',
            text: 'Your steps were successfully updated',
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
          /*
        swal({
        title: 'Steps Updated',
        text: 'Your steps were successfully updated',
        type: 'success',
        timer: 1000,
        showConfirmButton: false,
      });*/
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'There was an error saving your steps.',
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
        }
      );
  }

  syncSteps(force) {
    force = typeof force === 'undefined' ? '0' : force;
    this.activityService.getActivity(force).subscribe((responseData) => {
      this.currentActivity = responseData;
      if (this.currentActivity) {
        this.currentSteps = this.currentActivity.steps;
      }

      this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigate(['/steps']));
    });
  }

  next() {
    const startDate = moment(this.currentWeek[0])
      .add(1, 'week')
      .format('YYYY-MM-DD');
    const endDate = moment(this.currentWeek[6])
      .add(1, 'week')
      .format('YYYY-MM-DD');
    this.startDate = startDate;
    this.endDate = endDate;
    this.currentWeek = this.dateHelperService.getWeekFor(startDate);

    this.previousWeek.startDate = moment(startDate)
      .subtract(1, 'week')
      .format('YYYY-MM-DD');
    this.previousWeek.endDate = moment(endDate)
      .subtract(1, 'week')
      .format('YYYY-MM-DD');

    this.nextWeek.startDate = moment(startDate)
      .add(1, 'week')
      .format('YYYY-MM-DD');
    this.nextWeek.endDate = moment(endDate).add(1, 'week').format('YYYY-MM-DD');

    if (moment() < moment(this.nextWeek.startDate)) {
      this.nextWeek.startDate = null;
      this.nextWeek.endDate = null;
    }

    this.isFetching = true;
    this.weekTotal = 0;
    // get months data
    forkJoin({
      activityBetween: this.activityService.getActivityBetween(
        startDate,
        endDate
      ),
    }).subscribe(
      (responseData) => {
        this.isFetching = false;

        const weeklyActivities = responseData.activityBetween;
        weeklyActivities.forEach((activity) => {
          this.weekTotal = Number(this.weekTotal) + Number(activity.steps);
        });
        this.weekData = [];

        // week data
        this.currentWeek.forEach((item, index) => {
          const thisActivity = weeklyActivities.filter(this.search, {
            activity_date: moment(item).format('YYYY-MM-DD'),
          })[0];

          if (thisActivity) {
            if (
              thisActivity.hit_goal !== 1 &&
              +thisActivity.percent_hit >= 100
            ) {
              thisActivity.hit_goal = 1;
            }
            this.weekData.push(thisActivity);
          } else {
            const dData = {
              activity_date: moment(item).format('YYYY-MM-DD'),
            };
            this.weekData.push(dData);
          }
        });

        this.setChartData(this.weekData);

        // end
      },
      (error) => {
        this.isFetching = false;
        this.errors.push(error.message);
      }
    );
  }
  previous() {
    const startDate = moment(this.currentWeek[0])
      .subtract(1, 'week')
      .format('YYYY-MM-DD');
    const endDate = moment(this.currentWeek[6])
      .subtract(1, 'week')
      .format('YYYY-MM-DD');

    this.startDate = startDate;
    this.endDate = endDate;
    this.currentWeek = this.dateHelperService.getWeekFor(startDate);

    this.previousWeek.startDate = moment(startDate)
      .subtract(1, 'week')
      .format('YYYY-MM-DD');
    this.previousWeek.endDate = moment(endDate)
      .subtract(1, 'week')
      .format('YYYY-MM-DD');

    this.nextWeek.startDate = moment(startDate)
      .add(1, 'week')
      .format('YYYY-MM-DD');
    this.nextWeek.endDate = moment(endDate).add(1, 'week').format('YYYY-MM-DD');

    this.isFetching = true;
    this.weekTotal = 0;
    // get months data
    forkJoin({
      activityBetween: this.activityService.getActivityBetween(
        startDate,
        endDate
      ),
    }).subscribe(
      (responseData) => {
        this.isFetching = false;

        const weeklyActivities = responseData.activityBetween;
        weeklyActivities.forEach((activity) => {
          this.weekTotal = Number(this.weekTotal) + Number(activity.steps);
        });
        this.weekData = [];

        // week data
        this.currentWeek.forEach((item, index) => {
          const thisActivity = weeklyActivities.filter(this.search, {
            activity_date: moment(item).format('YYYY-MM-DD'),
          })[0];

          if (thisActivity) {
            if (
              thisActivity.hit_goal !== 1 &&
              +thisActivity.percent_hit >= 100
            ) {
              thisActivity.hit_goal = 1;
            }
            this.weekData.push(thisActivity);
          } else {
            const dData = {
              activity_date: moment(item).format('YYYY-MM-DD'),
            };
            this.weekData.push(dData);
          }
        });

        this.setChartData(this.weekData);

        // end
      },
      (error) => {
        this.isFetching = false;
        this.errors.push(error.message);
      }
    );
  }

  generateAlgorithmDays() {
    // get 4 days ago
    const threeDaysAgo = moment().subtract('3', 'days');
    const created = moment(this.currentUser.created);
    let startDay = created.add('1', 'day');
    if (threeDaysAgo > created) {
      startDay = threeDaysAgo;
    }

    this.algorithmDaysRemaining = 3;
    this.activityService
      .getActivityBetween(
        startDay.format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      )
      .subscribe((responseData) => {
        const userActivity = responseData;
        // do the loop
        let returnDays = [];
        for (let i = 0; i < 3; i++) {
          let dayStatus = 0;

          userActivity.forEach((activity) => {
            if (
              activity.activity_date ==
                moment(startDay).add(i, 'days').format('YYYY-MM-DD') &&
              activity.steps > 0 &&
              moment(startDay).add(i, 'days').format('YYYY-MM-DD') <
                moment().format('YYYY-MM-DD')
            ) {
              dayStatus = 1;
              this.algorithmDaysRemaining--;
            }
          });
          returnDays.push({
            day: moment(startDay).add(i, 'days'),
            status: dayStatus,
          });
        }

        this.algorithmDays = returnDays;
      });
  }

  onSubmitCadenceForm() {
    // save the steps

    this.activityService
      .saveWalkingSpeed(this.speedForm.value.cadence)
      .subscribe((responseData) => {
        this.userService.reset();
        this.userService.get().subscribe((userData) => {
          this.currentUser = userData;
        });

        Swal.fire({
          icon: 'success',
          title: 'Walking Speed set',
          text: 'Your walking speed has been saved.',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });

        // refresh the route..
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/steps']));
      });
  }
}
