import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { AuthService } from './../auth/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  userId;
  endPoint = '/alerts';

  cacheTime = new Date();
  cacheLifetime = 3 * 60 * 1000;

  allAlerts: any = null;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userId = user.id;
      }
    });
  }

  fetchAll(): Observable<any> {
    const now = new Date();

    if (
      !this.allAlerts ||
      Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime
    ) {
      this.cacheTime = new Date();
      let searchParams = new HttpParams();

      searchParams = searchParams.append('user_id', this.userId);
      searchParams = searchParams.append('sort', 'created');
      searchParams = searchParams.append('order', 'DESC');

      this.allAlerts = this.http
        .get<any>(environment.apiUrl + this.endPoint, {
          params: searchParams,
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            return responseData._embedded.alerts;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allAlerts;
  }

  updateStatus(alert_id) {
    const postData = {
      alert_id,
      user_id: this.userId,
    };
    return this.http.post<{ name: string }>(
      environment.apiUrl + '/user_alert_status',
      postData,
      {
        observe: 'response',
      }
    );
  }

  filterByProgramme(alerts, programmeId) {
    let newAlerts = [];
    alerts.forEach((alert) => {
      // check if it's for this programme
      var alertProgs = [];
      if (alert.programme_id) {
        alertProgs = alert.programme_id.split(',').map(Number);
      }

      if (alertProgs.indexOf(+programmeId) !== -1) {
        // check release date too
        var available = false;
        if (!alert.release_date) {
          available = true;
        } else {
          if (moment() > moment(alert.release_date)) {
            available = true;
          }
        }

        if (available) {
          newAlerts.push(alert);
        }
      }
    });
    return newAlerts;
  }

  clearCache() {
    this.allAlerts = null;
  }
}
