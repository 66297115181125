import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class Vo2maxService {
  error = new Subject<string>();
  slug = '/user_vo2maxs';
  resourceName = 'user_vo2maxs';
  allVo2maxs?: any;
  vo2maxsBetween?: any;
  cacheTime = new Date();
  cacheLifetime = 1 * 60 * 1000;

  constructor(private http: HttpClient) {}

  fetchAll(): any {
    const now = new Date();
    if (
      !this.allVo2maxs ||
      Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime
    ) {
      this.cacheTime = new Date();
      this.allVo2maxs = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: any[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allVo2maxs;
  }


  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + '/' + id
    );
  }
  
  clearCache() {
    this.allVo2maxs = null;
    this.vo2maxsBetween = null;
  }

  getGoodforAgeAndSex(age: number, sex: string) {
    let goodValue = '';
    if (age > 19 && age < 30) {
      if (sex == 'm') {
        goodValue = '44.6';
      }
      if (sex == 'f') {
        goodValue = '38.5';
      }
    } else if (age > 29 && age < 40) {
      if (sex == 'm') {
        goodValue = '43.9';
      }
      if (sex == 'f') {
        goodValue = '37.1';
      }
    } else if (age > 39 && age < 50) {
      if (sex == 'm') {
        goodValue = '41.0';
      }
      if (sex == 'f') {
        goodValue = '35.2';
      }
    } else if (age > 49 && age < 60) {
      if (sex == 'm') {
        goodValue = '38.1';
      }
      if (sex == 'f') {
        goodValue = '32.3';
      }
    }else if (age > 59 && age < 70) {
      if (sex == 'm') {
        goodValue = '34.9';
      }
      if (sex == 'f') {
        goodValue = '29.4';
      }
    } else if (age > 69 && age < 80) {
      if (sex == 'm') {
        goodValue = '31.5';
      }
      if (sex == 'f') {
        goodValue = '28.0';
      }
    } 
    
    return goodValue;
  }

  getGoodAgeforVo2maxAndSex(vo2max, sex) {
      let goodAge = '';
    if(sex == 'm'){
      if(+vo2max>=44.6){
        goodAge = '20-29';
      }
      else if(+vo2max>=43.9){
        goodAge = '30-39';
      }
      else if(+vo2max>=41.0){
        goodAge = '40-49';

      }
      else if(+vo2max>=38.1){
        goodAge = '50-59';

      }
      else if(+vo2max>=34.9){
        goodAge = '60-69';

      }
      else if(+vo2max>=31.5){
        goodAge = '70-79';

      }
      else{
        goodAge = '80+';
      }
    }

    if(sex == 'f'){
      if(vo2max>=38.5){
        goodAge = '20-29';
      }
      else if(vo2max>=37.1){
        goodAge = '30-39';
      }
      else if(vo2max>=35.2){
        goodAge = '40-49';

      }
      else if(vo2max>=32.3){
        goodAge = '50-59';

      }
      else if(vo2max>=29.4){
        goodAge = '60-69';

      }
      else if(vo2max>=28.0){
        goodAge = '70-79';

      }
      else{
        goodAge = '80+';
      }
    }
    return goodAge;
  }

  getRagForAgeAndSex(vo2max, age, sex) {
    let ragColour = '';

    if (age > 19 && age < 30) {
      if (sex == 'm') {
        if (+vo2max >= 44.6) {
          ragColour = 'green';
        } else if (+vo2max <= 36.7) {
          ragColour = 'red';
        } else {
          ragColour = 'amber';
        }


      }
      if (sex == 'f') {
        if (+vo2max >= 38.5) {
          ragColour = 'green';
        } else if (+vo2max <= 30.9) {
          ragColour = 'red';
        } else {
          ragColour = 'amber';
        }
      }
    } else if (age > 29 && age < 40) {
      if (sex == 'm') {
        if (+vo2max >= 43.9) {
          ragColour = 'green';
        } else if (+vo2max <= 35.2) {
          ragColour = 'red';
        } else {
          ragColour = 'amber';
        }
      }
      if (sex == 'f') {
        if (+vo2max >= 37.1) {
          ragColour = 'green';
        } else if (+vo2max <= 29.5) {
          ragColour = 'red';
        } else {
          ragColour = 'amber';
        }
      }
    } else if (age > 39 && age < 50) {
      if (sex == 'm') {
        if (+vo2max >= 41.0) {
          ragColour = 'green';
        } else if (+vo2max <= 33.8) {
          ragColour = 'red';
        } else {
          ragColour = 'amber';
        }
      }
      if (sex == 'f') {
        if (+vo2max >= 35.2) {
          ragColour = 'green';
        } else if (+vo2max <= 28.2) {
          ragColour = 'red';
        } else {
          ragColour = 'amber';
        }
      }
    } else if (age > 49 && age < 60) {
      if (sex == 'm') {
        if (+vo2max >= 38.1) {
          ragColour = 'green';
        } else if (+vo2max <= 30.9) {
          ragColour = 'red';
        } else {
          ragColour = 'amber';
        }
      }
      if (sex == 'f') {
        if (+vo2max >= 32.3) {
          ragColour = 'green';
        } else if (+vo2max <= 25.9) {
          ragColour = 'red';
        } else {
          ragColour = 'amber';
        }
      }
    } else if (age > 59 && age < 70) {
      if (sex == 'm') {
        if (+vo2max >= 34.9) {
          ragColour = 'green';
        } else if (+vo2max <= 27.4) {
          ragColour = 'red';
        } else {
          ragColour = 'amber';
        }
      }
      if (sex == 'f') {
        if (+vo2max >= 29.4) {
          ragColour = 'green';
        } else if (+vo2max <= 24.0) {
          ragColour = 'red';
        } else {
          ragColour = 'amber';
        }
      }
    } else if (age > 69 && age < 80) {
      if (sex == 'm') {
        if (+vo2max >= 34.9) {
          ragColour = 'green';
        } else if (+vo2max <= 27.4) {
          ragColour = 'red';
        } else {
          ragColour = 'amber';
        }
      }
      if (sex == 'f') {
        if (+vo2max >= 31.5) {
          ragColour = 'green';
        } else if (+vo2max <= 24.6) {
          ragColour = 'red';
        } else {
          ragColour = 'amber';
        }
      }
    }

    return ragColour;
  }

  getVo2maxAgeEquivalent(vo2max, age, sex){
    let returnRange:any = {
      min:0,
      max:0,
    }
    let goodAge = this.getGoodAgeforVo2maxAndSex(vo2max, sex);
    let range = goodAge.split('-');
    if(range[0] && range[1]){
      returnRange.min = +range[0] - age;
      returnRange.max = +range[1] - age;
    }
    return returnRange;
    
  }

  getVo2maxforColorAgeAndSex(colour:string, age:number, sex:string){
    let ragRange = '';

    if (age > 19 && age < 30) {
      if (sex == 'm') {
        if (colour == 'green') {
          ragRange = '≥44.6';
        } else if (colour == 'amber') {
          ragRange = '36.8-44.5';
        } else {
          ragRange = '≤36.7';
        }
      }
      if (sex == 'f') {
        if (colour == 'green') {
          ragRange = '≥38.5';
        } else if (colour == 'amber') {
          ragRange = '31.0-38.4';
        } else {
          ragRange = '≤30.9';
        }
      }
    } else if (age > 29 && age < 40) {
      if (sex == 'm') {
        if (colour == 'green') {
          ragRange = '≥43.9';
        } else if (colour == 'amber') {
          ragRange = '35.3-43.8';
        } else {
          ragRange = '≤35.2';
        }
      }
      if (sex == 'f') {
        if (colour == 'green') {
          ragRange = '≥37.1';
        } else if (colour == 'amber') {
          ragRange = '29.6-37.0';
        } else {
          ragRange = '≤29.5';
        }
      }
    } else if (age > 39 && age < 50) {
      if (sex == 'm') {
        if (colour == 'green') {
          ragRange = '≥41.0';
        } else if (colour == 'amber') {
          ragRange = '33.9-40.9';
        } else {
          ragRange = '≤33.8';
        }
      }
      if (sex == 'f') {
        if (colour == 'green') {
          ragRange = '≥35.2';
        } else if (colour == 'amber') {
          ragRange = '28.3-35.1';
        } else {
          ragRange = '≤28.2';
        }
      }
    } else if (age > 49 && age < 60) {
      if (sex == 'm') {
        if (colour == 'green') {
          ragRange = '≥38.1';
        } else if (colour == 'amber') {
          ragRange = '31.0-38.0';
        } else {
          ragRange = '≤30.9';
        }
      }
      if (sex == 'f') {
        if (colour == 'green') {
          ragRange = '≥32.3';
        } else if (colour == 'amber') {
          ragRange = '26.0-32.2';
        } else {
          ragRange = '≤25.9';
        }
      }
    } else if (age > 59 && age < 70) {
      if (sex == 'm') {
        if (colour == 'green') {
          ragRange = '≥34.9';
        } else if (colour == 'amber') {
          ragRange = '27.5-34.8';
        } else {
          ragRange = '≤27.4';
        }
      }
      if (sex == 'f') {
        if (colour == 'green') {
          ragRange = '≥29.4';
        } else if (colour == 'amber') {
          ragRange = '24.1-29.3';
        } else {
          ragRange = '≤24.0';
        }
      }
    } else if (age > 69 && age < 80) {
      if (sex == 'm') {
        if (colour == 'green') {
          ragRange = '≥31.5';
        } else if (colour == 'amber') {
          ragRange = '24.7-31.4';
        } else {
          ragRange = '≤24.6';
        }
      }
      if (sex == 'f') {
        if (colour == 'green') {
          ragRange = '≥28.0';
        } else if (colour == 'amber') {
          ragRange = '22.7-27.9';
        } else {
          ragRange = '≤22.6';
        }
      }
    }

    return ragRange;

  }
}
