<!--div class="footer-subnav" *ngIf="showSub">
  <nav class="section-nav" *ngIf="currentProgramme && currentUser">
    <ul>
      <ng-container *ngIf="showSub === 'chat'">
        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="currentProgramme.config.askTheExpert == 1"
        >
          <a [routerLink]="['/chat', 'ask-the-expert']"
            ><span class="icon-wrap section-nav-icon"
              ><fa-icon
                [icon]="['fas', 'user-md-chat']"
                [fixedWidth]="true"
              ></fa-icon></span
            ><span class="section-nav-label">{{
              environment.navLabels["askTheExpert"]
            }}</span>

            <span class="section-nav-arrow"
              ><fa-icon
                [icon]="['far', 'arrow-circle-right']"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
          </a>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a [routerLink]="['/chat']"
            ><span class="icon-wrap section-nav-icon"
              ><fa-icon
                [icon]="['fas', 'users']"
                [fixedWidth]="true"
              ></fa-icon></span
            ><span class="section-nav-label">{{
              environment.navLabels["chat"]
            }}</span>
            <span class="section-nav-arrow"
              ><fa-icon
                [icon]="['far', 'arrow-circle-right']"
                [fixedWidth]="true"
              ></fa-icon></span
          ></a>
        </li>
        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a [routerLink]="['/chat', 'technical-support']"
            ><span class="icon-wrap section-nav-icon"
              ><fa-icon
                [icon]="['fas', 'user-headset']"
                [fixedWidth]="true"
              ></fa-icon></span
            ><span class="section-nav-label">Technical Support</span>
            <span class="section-nav-arrow"
              ><fa-icon
                [icon]="['far', 'arrow-circle-right']"
                [fixedWidth]="true"
              ></fa-icon></span
          ></a>
        </li>
      </ng-container>
      <ng-container *ngIf="showSub === 'activity'">
        <li routerLinkActive="active">
          <a [routerLink]="['/steps']">
            <span class="icon-wrap section-nav-icon"
              ><fa-icon
                [icon]="['fas', 'shoe-prints']"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
            <span class="section-nav-label">My Steps</span>
            <span class="section-nav-arrow"
              ><fa-icon
                [icon]="['far', 'arrow-circle-right']"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
          </a>
        </li>
        <li  routerLinkActive="active">
          <a [routerLink]="['/challenges-individual']"
            ><span class="icon-wrap section-nav-icon"
              ><fa-icon [icon]="['fas', 'trophy']" [fixedWidth]="true"></fa-icon
            ></span>
            <span class="section-nav-label">Challenges</span>
            <span class="section-nav-arrow"
              ><fa-icon
                [icon]="['far', 'arrow-circle-right']"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
          </a>
        </li>
        <li *ngIf="currentProgramme.config.buddies == 1 && !currentUser.buddy">
          <a [routerLink]="['/friends-and-family']"
            ><span class="icon-wrap section-nav-icon"
              ><fa-icon
                [icon]="['fas', 'user-friends']"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
            <span class="section-nav-label">Buddies</span>
            <span class="section-nav-arrow"
              ><fa-icon
                [icon]="['far', 'arrow-circle-right']"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
          </a>
        </li>
        <li  routerLinkActive="active">
          <a [routerLink]="['/target']"
            ><span class="icon-wrap section-nav-icon"
              ><fa-icon
                [icon]="['fas', 'bullseye']"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
            <span class="section-nav-label">Set Daily Steps Target</span>
            <span class="section-nav-arrow"
              ><fa-icon
                [icon]="['far', 'arrow-circle-right']"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
          </a>
        </li>
        <li *ngIf="currentProgramme.config.trackers.indexOf('minutes') > -1">
          <a [routerLink]="['/activity', 'minutes']"
            ><span class="icon-wrap section-nav-icon"
              ><fa-icon
                [icon]="['far', 'stopwatch']"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
            <span class="section-nav-label">Minutes</span>
            <span class="section-nav-arrow"
              ><fa-icon
                [icon]="['far', 'arrow-circle-right']"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
          </a>
        </li>
        <li  routerLinkActive="active">
          <a [routerLink]="['/achievements']"
            ><span class="icon-wrap section-nav-icon"
              ><fa-icon [icon]="['fas', 'medal']" [fixedWidth]="true"></fa-icon
            ></span>
            <span class="section-nav-label">Achievements</span>
            <span class="section-nav-arrow"
              ><fa-icon
                [icon]="['far', 'arrow-circle-right']"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
          </a>
        </li>
        <li  routerLinkActive="active">
          <a [routerLink]="['/setup-device']"
            ><span class="icon-wrap section-nav-icon"
              ><fa-icon
                [icon]="['fas', 'watch-fitness']"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
            <span class="section-nav-label">Setup Device</span>
            <span class="section-nav-arrow"
              ><fa-icon
                [icon]="['far', 'arrow-circle-right']"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
          </a>
        </li>
      </ng-container>
      <ng-container *ngIf="showSub === 'healthTrackers'">
        <li *ngIf="currentProgramme.config.trackers.indexOf('shape') > -1">
          <a [routerLink]="['/health-trackers', 'shape']">
            <ng-container *ngIf="currentUser">
              <span
                class="icon-wrap section-nav-icon"
                *ngIf="currentUser.gender === 'f' || !currentUser.gender"
                ><fa-icon
                  [icon]="['fas', 'female']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span>
              <span
                class="icon-wrap section-nav-icon"
                *ngIf="currentUser.gender === 'm' || currentUser.gender == 'x'"
                ><fa-icon [icon]="['fas', 'male']" [fixedWidth]="true"></fa-icon
              ></span>
            </ng-container>
            <span class="section-nav-label">Shape</span>
            <span class="section-nav-arrow"
              ><fa-icon
                [icon]="['far', 'arrow-circle-right']"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
          </a>
        </li>
        <li
          *ngIf="currentProgramme.config.trackers.indexOf('healthyEating') > -1"
        >
          <a [routerLink]="['/health-trackers', 'healthy-eating']">
            <span class="icon-wrap section-nav-icon"
              ><fa-icon
                [icon]="['fas', 'utensils']"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
            <span class="section-nav-label">Healthy Eating</span>
            <span class="section-nav-arrow"
              ><fa-icon
                [icon]="['far', 'arrow-circle-right']"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
          </a>
        </li>
        <li *ngIf="currentProgramme.config.trackers.indexOf('hba1c') > -1">
          <a [routerLink]="['/health-trackers', 'hba1c']">
            <span class="icon-wrap section-nav-icon"
              ><fa-icon [icon]="['fas', 'tint']" [fixedWidth]="true"></fa-icon
            ></span>
            <span class="section-nav-label">HbA1c</span>
            <span class="section-nav-arrow"
              ><fa-icon
                [icon]="['far', 'arrow-circle-right']"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
          </a>
        </li>
        <li *ngIf="currentProgramme.config.trackers.indexOf('bp') > -1">
          <a [routerLink]="['/health-trackers', 'blood-pressure']">
            <span class="icon-wrap section-nav-icon"
              ><fa-icon
                [icon]="['fas', 'tachometer-alt']"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
            <span class="section-nav-label">Blood Pressure</span>
            <span class="section-nav-arrow"
              ><fa-icon
                [icon]="['far', 'arrow-circle-right']"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
          </a>
        </li>
        <li *ngIf="currentProgramme.config.trackers.indexOf('bp') > -1">
          <a [routerLink]="['/health-trackers', 'cholesterol']">
            <span class="icon-wrap section-nav-icon"
              ><fa-icon
                [icon]="['fas', 'heartbeat']"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
            <span class="section-nav-label">Cholesterol</span>
            <span class="section-nav-arrow"
              ><fa-icon
                [icon]="['far', 'arrow-circle-right']"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
          </a>
        </li>
        <li *ngIf="currentProgramme.config.trackers.indexOf('smoking') > -1">
          <a [routerLink]="['/health-trackers', 'smoking']">
            <span class="icon-wrap section-nav-icon"
              ><fa-icon
                [icon]="['fas', 'smoking-ban']"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
            <span class="section-nav-label">Smoking</span>
            <span class="section-nav-arrow"
              ><fa-icon
                [icon]="['far', 'arrow-circle-right']"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
          </a>
        </li>
      </ng-container>
      <ng-container *ngIf="showSub === 'sessions'">
        <li  routerLinkActive="active">
          <a [routerLink]="['/learning-sessions']">
            <ng-container *ngIf="currentUser">
              <span class="icon-wrap section-nav-icon"
                ><fa-icon
                  [icon]="['fas', 'graduation-cap']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span>
            </ng-container>
            <span class="section-nav-label">Learning Sessions</span>
            <span class="section-nav-arrow"
              ><fa-icon
                [icon]="['far', 'arrow-circle-right']"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
          </a>
        </li>
        <li  routerLinkActive="active">
          <a [routerLink]="['/booster-sessions']">
            <span class="icon-wrap section-nav-icon"
              ><fa-icon [icon]="['fas', 'bolt']" [fixedWidth]="true"></fa-icon
            ></span>
            <span class="section-nav-label">Booster Sessions</span>
            <span class="section-nav-arrow"
              ><fa-icon
                [icon]="['far', 'arrow-circle-right']"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
          </a>
        </li>
      </ng-container>
    </ul>
  </nav>
</div-->

<nav
  class="navbar fixed-bottom navbar-expand navbar-light navbar-footer"
  aria-label="Main footer navigation"
  *ngIf="currentUser"
>
  <div
    class="d-flex justify-content-center"
    style="margin: 0 auto; border-top: 1px solid #fff"
  >
    <ul
      class="navbar-nav justify-content-center"
      *ngIf="currentProgramme && currentProgramme.config.m3 != 1"
    >
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/dashboard']">
          <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'home']"></fa-icon
          ></span>
          <span class="nav-label">Home</span>
        </a>
      </li>
      <li
        class="nav-item"
        [ngClass]="{
          active:
            currentUrl == 'steps' ||
            currentUrl == 'challenges' ||
            currentUrl == 'challenges-individual' ||
            currentUrl == 'friends-and-family' ||
            currentUrl == 'target' ||
            currentUrl == 'activity' ||
            currentUrl == 'achievements' ||
            currentUrl == 'setup-device'
        }"
      >
        <a
          class="nav-link"
          (click)="onToggleSub($event, 'activity')"
          [routerLink]="['/activity', 'index']"
        >
          <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'walking']"></fa-icon
          ></span>
          <span class="nav-label">Activity</span>
        </a>
      </li>
      <li
        *ngIf="!currentUser.buddy"
        class="nav-item"
        [ngClass]="{
          active:
            currentUrl == 'learning-sessions' ||
            currentUrl == 'booster-sessions' ||
            currentUrl == 'sessions' ||
            currentUrl == 'learning-session-steps' ||
            currentUrl == 'booster-session-steps'
        }"
      >
        <a
          class="nav-link"
          (click)="onToggleSub($event, 'sessions')"
          [routerLink]="['/sessions']"
        >
          <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'graduation-cap']"></fa-icon
          ></span>
          <span class="nav-label">Sessions</span>
        </a>
      </li>
      <li
        *ngIf="!currentUser.buddy"
        class="nav-item"
        [ngClass]="{
          active:
            currentUrl == 'chat' ||
            currentUrl == 'ask-the-expert' ||
            currentUrl == 'technical-support'
        }"
      >
        <a
          class="nav-link"
          (click)="onToggleSub($event, 'chat')"
          [routerLink]="['/chat', 'index']"
        >
          <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'comments']"></fa-icon
          ></span>
          <span class="nav-label">Chat</span>
        </a>
      </li>
      <li
        *ngIf="!currentUser.buddy"
        class="nav-item"
        [ngClass]="{
          active:
            currentUrl == 'health-trackers' ||
            currentUrl == 'shape' ||
            currentUrl == 'healthy-eating' ||
            currentUrl == 'hba1c' ||
            currentUrl == 'blood-pressure' ||
            currentUrl == 'cholesterol' ||
            currentUrl == 'smoking'
        }"
      >
        <a
          class="nav-link"
          (click)="onToggleSub($event, 'healthTrackers')"
          [routerLink]="['/health-trackers', 'index']"
        >
          <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'chart-line']"></fa-icon
          ></span>
          <span class="nav-label">Trackers</span>
        </a>
      </li>
    </ul>

    <ul
      class="navbar-nav justify-content-center navbar-footer-m3"
      *ngIf="currentProgramme && currentProgramme.config.m3 == 1"
    >
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/m3', 'dashboard']">
          <span class="icon-wrap"
            ><app-m3-icon [icon]="'overview'"></app-m3-icon
          ></span>
          <span class="nav-label">Physical Activity</span>
        </a>
      </li>
      <!--li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/m3', 'walking']">
          <span class="icon-wrap"
            ><app-m3-icon [icon]="'walking'"></app-m3-icon
          ></span>
          <span class="nav-label">Walking</span>
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/m3', 'non-stepping']">
          <span class="icon-wrap"
            ><app-m3-icon [icon]="'non-stepping'"></app-m3-icon
          ></span>
          <span class="nav-label">Activity</span>
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/m3', 'physical-ability']">
          <span class="icon-wrap"
            ><app-m3-icon [icon]="'physical-ability'"></app-m3-icon
          ></span>
          <span class="nav-label">Physical</span>
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/m3', 'sitting']">
          <span class="icon-wrap"
            ><app-m3-icon [icon]="'sitting'"></app-m3-icon
          ></span>
          <span class="nav-label">Sitting</span>
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/m3', 'sleep']">
          <span class="icon-wrap"
            ><app-m3-icon [icon]="'sleep'"></app-m3-icon
          ></span>
          <span class="nav-label">Sleep</span>
        </a>
      </li-->
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/m3', 'health-data']">
          <span class="icon-wrap"
            ><app-m3-icon [icon]="'health-data'"></app-m3-icon
          ></span>
          <span class="nav-label">Health Data</span>
        </a>
      </li>
    </ul>
  </div>
</nav>
