import { NgForm } from '@angular/forms';
import { DateHelperService } from './../services/date-helper.service';
import { TargetService } from './../services/target.service';
import { ActivityService } from './../services/activity.service';
import { UserSettingsService } from './../services/user-settings.service';
import { ProgrammeService } from './../services/programme.service';
import { UserService } from './../services/user.service';
import { AuthService } from './../auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from './../../environments/environment';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { Color, Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { Router } from '@angular/router';
import { SleepService } from '../services/sleep.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AppointmentService } from '../services/appointment.service';

@Component({
  selector: 'app-sleep',
  templateUrl: './sleep.component.html',
  styleUrls: ['./sleep.component.scss'],
})
export class SleepComponent implements OnInit {
  currentUser;
  currentProgramme;
  date = new Date();
  maxDate = new Date();
  allSleeps;
  currentHours = 0;
  currentMins = 0;
  age;
  nextAppointment;
  suggestedTarget;
  reviewTarget;
  isFetching = false;
  now;

  sleepAverage;
  sleepScore;
  sleepWeekStart;
  showAllEntries = false;

  //
  currentWeek;
  currentTarget;
  weekData: any = [];
  daysHit = 0;

  toggleRow = {
    update: false,
    graph: false,
    target: false,
  };

  previousWeek = {
    startDate: null,
    endDate: null,
  };
  nextWeek = {
    startDate: null,
    endDate: null,
  };

  startDate;
  endDate;

  showMonth;

  environment = environment;

  @ViewChild('entryForm', { static: false }) entryForm: NgForm;
  @ViewChild('sleepScoreForm', { static: false }) sleepScoreForm: NgForm;

  // start charts

  // chart

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: '',
            fontColor: '#333',
          },
          gridLines: {
            color: '#555',
            drawOnChartArea: false,
          },
          ticks: {
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: '#555',
            drawOnChartArea: false,
          },
          scaleLabel: {
            display: true,
            labelString: '',
            fontColor: '#333',
          },
          ticks: {
            min: 0,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [
    { data: [], label: 'Time', maxBarThickness: 50 },
  ];
  public barChartColors: Color[] = [
    { backgroundColor: this.environment.primaryColor },
  ];
  //

  // Line chart
  public lineChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            beginAtZero: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          gridLines: {
            display: true,
            lineWidth: 1,
            color: '#95989A',
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            lineWidth: 2,
            color: '#95989A',
          },
          type: 'time',
          ticks: {
            autoSkip: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          time: {
            parser: 'YYYY-MM-DD HH:mm:ss',
            unit: 'day',
            displayFormats: {
              day: 'MMM-DD',
            },
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public lineChartLabels: Label[] = [];
  public lineChartType: ChartType = 'line';
  public lineChartLegend = true;

  public lineChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'Monthly',
      borderWidth: 1,
      borderColor: environment.chartDefaults.colors[0],
      fill: false,
      pointRadius: 5,
    },
  ];
  public lineChartColors: Color[] = [
    { backgroundColor: this.environment.primaryColor },
  ];

  // All time chart..
  public allTimeChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            beginAtZero: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          gridLines: {
            display: true,
            lineWidth: 1,
            color: '#95989A',
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            lineWidth: 2,
            color: '#95989A',
          },
          type: 'time',
          ticks: {
            autoSkip: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          time: {
            parser: 'YYYY-MM-DD HH:mm:ss',
            unit: 'day',
            displayFormats: {
              day: 'MMM-DD',
            },
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public allTimeChartLabels: Label[] = [];
  public allTimeChartType: ChartType = 'line';
  public allTimeChartLegend = true;

  public allTimeChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'All Time',
      borderWidth: 1,
      borderColor: environment.chartDefaults.colors[0],
      fill: false,
      pointRadius: 2,
    },
  ];
  public allTimeChartColors: Color[] = [
    { backgroundColor: this.environment.primaryColor },
  ];

  // end charts

  constructor(
    private authService: AuthService,
    private programmeService: ProgrammeService,
    private router: Router,
    private userService: UserService,
    private userSettingsService: UserSettingsService,
    private sleepService: SleepService,
    private targetService: TargetService,
    private modalService: BsModalService,
    private activityService: ActivityService,
    private dateHelperService: DateHelperService
  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (this.currentProgramme) {
          // this.barChartColors[0].backgroundColor = this.currentProgramme.config.primaryColor;
        }
      });

      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;

        if (this.currentUser.dob) {
          this.age = moment().diff(this.currentUser.dob, 'years', false);
        }
      });

      const now = moment();
      this.currentWeek = this.dateHelperService.getWeekFor(now);
      this.now = moment(now).format('YYYY-MM-DD');
      const startDate = moment(this.currentWeek[0]).format('YYYY-MM-DD');
      const endDate = moment(this.currentWeek[6]).format('YYYY-MM-DD');

      this.previousWeek.startDate = moment(this.currentWeek[0])
        .subtract(1, 'week')
        .format('YYYY-MM-DD');
      this.previousWeek.endDate = moment(this.currentWeek[6])
        .subtract(1, 'week')
        .format('YYYY-MM-DD');

      forkJoin({
        userSettings: this.userSettingsService.fetchAll(),
        allTimeSleep: this.sleepService.fetchAll(),
        sleepScore: this.sleepService.getSleepScore(),
      }).subscribe((responseData) => {
        this.allSleeps = responseData.allTimeSleep;

        this.setMonthChartData(this.allSleeps);
        this.setAllTimeChartData(this.allSleeps);

        if (this.allSleeps[0]) {
          let hours = Math.floor(this.allSleeps[0].sleep / 60);
          let minutes = Math.floor(this.allSleeps[0].sleep % 60);

          if (
            moment(this.allSleeps[0].date_recorded).dayOfYear() ==
            moment().dayOfYear()
          ) {
            this.currentMins = minutes;
            this.currentHours = hours;
          }
        }

        if (this.allSleeps.length > 0) {
          if (
            moment(this.allSleeps[0].date_recorded) >
            moment().subtract(1, 'week')
          ) {
            this.sleepWeekStart = moment().subtract(6, 'day').format('Do MMM');
          } else {
            this.sleepWeekStart = moment(this.allSleeps[0].date_recorded)
              .subtract(6, 'day')
              .format('Do MMM');
          }
        }

        // get this week's data
        this.currentWeek.forEach((item, index) => {
          const thisActivity = this.allSleeps.filter(this.search, {
            date_recorded: moment(item).format('YYYY-MM-DD'),
          })[0];

          if (thisActivity) {
            //this.weekTotal += +thisActivity.sedentary_minutes;
            this.weekData.push(thisActivity);
            if (
              +thisActivity.sleep / 60 >= 7 &&
              +thisActivity.sleep / 60 <= 9
            ) {
              this.daysHit++;
            }
          } else {
            const dData = {
              date_recorded: moment(item).format('YYYY-MM-DD'),
            };
            this.weekData.push(dData);
          }
        });

        this.sleepAverage = this.sleepService.getSleepAverage(this.allSleeps);
        // this.nextAppointment = responseData.nextAppointment;

        this.sleepScore = responseData.sleepScore;
      });
    }
  }

  onDelete(sleep) {
    this.sleepService.delete(sleep.user_sleep_id).subscribe(
      (responseData) => {
        this.sleepService.clearCache();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/sleep']));
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onSubmitEntryForm() {
    // save the steps
    const totalMins =
      +this.entryForm.value.hours * 60 + +this.entryForm.value.mins;
    this.sleepService
      .create('' + totalMins, this.entryForm.value.date)
      .subscribe((responseData) => {
        // refresh the route..
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/activity/sleep']));

        Swal.fire({
          icon: 'success',
          title: 'Entry Updated',
          text: 'Your sleep time has been added',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      });
  }

  getAgeSleep(age) {
    if (age < 65) {
      return '7-9';
    } else {
      return '7-8';
    }
  }

  getSleepRagForAge(sleep: any, age: number) {
    return this.sleepService.getRagForAge(sleep, age);
  }

  onSubmitSleepScoreForm() {
    this.sleepService
      .setSleepScore(
        this.sleepScoreForm.value.auto_meq,
        this.sleepScoreForm.value.chronotype
      )
      .subscribe((responseData) => {
        // refresh the route..
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/sleep']));

        Swal.fire({
          icon: 'success',
          title: 'Score Updated',
          text: 'Your sleep score has been updated',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      });
  }

  isHittingTarget() {
    let isHitting = false;
    if (this.sleepAverage >= 7 * 60 && this.sleepAverage <= 9 * 60) {
      isHitting = true;
    }
    return isHitting;
  }

  getTargetOffset() {
    if (this.sleepAverage < 7 * 60) {
      return Math.round(7 * 60 - this.sleepAverage) + ' minutes under';
    } else {
      return Math.round(9 * 60 - this.sleepAverage) * -1 + ' minutes over';
    }
  }

  getTargetOffsetMins() {
    if (this.sleepAverage < 7 * 60) {
      return Math.round(7 * 60 - this.sleepAverage);
    } else {
      return Math.round(9 * 60 - this.sleepAverage) * -1;
    }
  }

  syncSteps(force) {
    force = typeof force === 'undefined' ? '0' : force;
    this.activityService.getActivity(force).subscribe((responseData) => {
      this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigate(['/m3', 'sleep']));
    });
  }

  generateTarget() {}

  submitTarget(target: any) {}

  onSubmitForm() {}

  previous() {
    const startDate = moment(this.currentWeek[0])
      .subtract(1, 'week')
      .format('YYYY-MM-DD');
    const endDate = moment(this.currentWeek[6])
      .subtract(1, 'week')
      .format('YYYY-MM-DD');

    this.startDate = startDate;
    this.endDate = endDate;
    this.currentWeek = this.dateHelperService.getWeekFor(startDate);

    this.previousWeek.startDate = moment(startDate)
      .subtract(1, 'week')
      .format('YYYY-MM-DD');
    this.previousWeek.endDate = moment(endDate)
      .subtract(1, 'week')
      .format('YYYY-MM-DD');

    this.nextWeek.startDate = moment(startDate)
      .add(1, 'week')
      .format('YYYY-MM-DD');
    this.nextWeek.endDate = moment(endDate).add(1, 'week').format('YYYY-MM-DD');

    // week data
    this.weekData = [];
    this.currentWeek.forEach((item, index) => {
      const thisActivity = this.allSleeps.filter(this.search, {
        date_recorded: moment(item).format('YYYY-MM-DD'),
      })[0];

      if (thisActivity) {
        this.weekData.push(thisActivity);
      } else {
        const dData = {
          date_recorded: moment(item).format('YYYY-MM-DD'),
        };
        this.weekData.push(dData);
      }
    });
  }
  next() {
    const startDate = moment(this.currentWeek[0])
      .add(1, 'week')
      .format('YYYY-MM-DD');
    const endDate = moment(this.currentWeek[6])
      .add(1, 'week')
      .format('YYYY-MM-DD');
    this.startDate = startDate;
    this.endDate = endDate;
    this.currentWeek = this.dateHelperService.getWeekFor(startDate);

    this.previousWeek.startDate = moment(startDate)
      .subtract(1, 'week')
      .format('YYYY-MM-DD');
    this.previousWeek.endDate = moment(endDate)
      .subtract(1, 'week')
      .format('YYYY-MM-DD');

    this.nextWeek.startDate = moment(startDate)
      .add(1, 'week')
      .format('YYYY-MM-DD');
    this.nextWeek.endDate = moment(endDate).add(1, 'week').format('YYYY-MM-DD');

    if (moment() < moment(this.nextWeek.startDate)) {
      this.nextWeek.startDate = null;
      this.nextWeek.endDate = null;
    }

    this.weekData = [];

    // week data
    this.currentWeek.forEach((item, index) => {
      const thisActivity = this.allSleeps.filter(this.search, {
        date_recorded: moment(item).format('YYYY-MM-DD'),
      })[0];

      if (thisActivity) {
        this.weekData.push(thisActivity);
      } else {
        const dData = {
          date_recorded: moment(item).format('YYYY-MM-DD'),
        };
        this.weekData.push(dData);
      }
    });

    //this.setChartData(this.weekData);

    // end
  }

  search(obj) {
    return Object.keys(this).every((key) => obj[key] === this[key]);
  }

  setMonthChartData(activities) {
    this.lineChartData[0].data = [];
    // setup chart data
    activities.forEach((value, key) => {
      if (
        moment(value.date_recorded).format('YYYY-MM-DD') >=
        moment().subtract(1, 'M').format('YYYY-MM-DD')
      ) {
        this.lineChartLabels.push(
          moment(value.date_recorded).format('YYYY-MM-DD')
        );
        this.lineChartData[0].data.push(value.sleep);
      }
    });
  }

  setAllTimeChartData(activities) {
    this.allTimeChartData[0].data = [];
    // setup chart data
    activities.forEach((value, key) => {
      this.allTimeChartLabels.push(
        moment(value.date_recorded).format('YYYY-MM-DD')
      );
      this.allTimeChartData[0].data.push(value.sleep);
    });
  }
}
