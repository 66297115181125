import { ProgrammeService } from './../services/programme.service';
import { UserService } from './../services/user.service';
import { AuthService } from './../auth/auth.service';
import { AssessmentsService } from './../services/assessments.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Options } from '@angular-slider/ngx-slider';
import { forkJoin } from 'rxjs';
import Swal from 'sweetalert2/src/sweetalert2.js';

@Component({
  selector: 'app-assessments',
  templateUrl: './assessments.component.html',
  styleUrls: ['./assessments.component.scss'],
})
export class AssessmentsComponent implements OnInit {
  qNum = 0;
  started;
  complete;
  quiz;
  questions = [];
  answers = [];
  score = 0;
  prepost;
  currentUser;
  assessmentId;
  value: 5;
  options: Options = {
    showTicksValues: true,
    stepsArray: [
      { value: 0, legend: 'Cannot do at all' },
      { value: 1 },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5, legend: 'Maybe yes Maybe no' },
      { value: 6 },
      { value: 7 },
      { value: 8 },
      { value: 9 },
      { value: 10, legend: 'Certain can do' },
    ],
  };
  responses = {};
  showAnswerBtn;
  answersSelected = [];
  qKey;
  currQuestion;
  isFetching;
  currentProgramme;
  sentOff = false;

  constructor(
    private assessmentsService: AssessmentsService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // are we logged in?

    this.complete = false;
    this.responses = {};
    this.options = {
      showTicksValues: true,
      stepsArray: [
        { value: 0, legend: 'Cannot do at all' },
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5, legend: 'Maybe yes Maybe no' },
        { value: 6 },
        { value: 7 },
        { value: 8 },
        { value: 9 },
        { value: 10, legend: 'Certain can do' },
      ],
    };

    const user = this.authService.user.getValue();
    if (user) {
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
      });
    }

    //
    this.route.params.subscribe((params) => {
      this.score = 0;
      this.questions = [];
      this.answers = [];
      this.responses = {};
      this.qNum = 1;
      this.started = null;
      this.showAnswerBtn = false;
      this.prepost = null;
      this.assessmentId = null;
      this.complete = false;
      this.currQuestion = null;
      this.assessmentId = this.route.snapshot.params.assessmentId;
      this.prepost = this.route.snapshot.params.prePost;

      this.isFetching = true;
      forkJoin({
        assessment: this.assessmentsService.fetch(this.assessmentId),
        questions: this.assessmentsService.fetchQuestions(this.assessmentId),
        answers: this.assessmentsService.fetchAnswers(this.assessmentId),
        results: this.assessmentsService.fetchResults(
          this.assessmentId,
          this.prepost
        ),
        responses: this.assessmentsService.fetchResponses(
          this.assessmentId,
          'pre'
        ),
      }).subscribe(
        (responseData) => {
          this.isFetching = false;
          if (responseData.results.length > 0) {
            Swal.fire({
              title: 'Assessment Completed',
              text: 'You have already taken this assessment',
              icon: 'info',
            });
            this.router.navigate(['/dashboard']);
          }

          this.quiz = responseData.assessment;
          this.questions = responseData.questions;
          this.questions.forEach((q) => {
            this.responses[q.assessment_question_id] = null;
          });

          //get questions stuff

          if (this.quiz.quiz_type == 'prepost:custom') {
            const stepsArr = [];
            const items = this.quiz.options.split('\n');
            items.forEach((item, index) => {
              var qu = item.split('=');
              if (!qu[1]) {
                qu[1] = qu[0];
                // get last index..
                if (stepsArr.length - 1 > -1) {
                  qu[0] = stepsArr[stepsArr.length - 1].value * 1 + 1;
                } else {
                  qu[0] = 0;
                }
              }
              stepsArr.push({ value: qu[0], legend: qu[1] });
            });
            this.options.stepsArray = stepsArr;
            //console.log(stepsArr);
          }

          this.questions = [];
          responseData.questions.forEach((item, index) => {
            if (!this.responses[item.assessment_question_id]) {
              if (this.quiz.quiz_type === 'prepost:self-efficacy') {
                this.responses[item.assessment_question_id] = 5;
              } else {
                this.responses[item.assessment_question_id] = undefined;
              }
            }

            item.answers = [];
            if (item.question_image) {
              /*
              $http({
                method: 'GET',
                url: '/multimedia/' + item.question_image,
              }).then(function successCallback(response) {
                item.imageData = response.data;
              });
              */
            }
            this.questions[index] = item;
          });

          // answers

          this.answers = responseData.answers;

          this.answers.forEach((answer, index) => {
            // get the images....
            if (answer.answer_image) {
              /*
              $http({
                method: 'GET',
                url: '/multimedia/' + answer.answer_image,
                headers: {
                  'X-Parse-Application-Id': 'X',
                  'X-Parse-REST-API-Key': 'Y',
                },
              }).then(function successCallback(response) {
                answer.imageData = response.data;
              });
*/
            }

            this.questions.forEach((question, qindex) => {
              this.questions[qindex].state = 'unanswered';
              if (
                answer.assessment_question_id == question.assessment_question_id
              ) {
                this.questions[qindex].answers.push(answer);
              }
            });
          });

          //console.log(this.questions);

          // end get questions stuff

          responseData.responses.forEach((response) => {
            if (isNaN(+response.assessment_set_answer)) {
              this.responses[response.assessment_question_id] =
                response.assessment_set_answer;
            } else {
              this.responses[response.assessment_question_id] =
                +response.assessment_set_answer;
            }
          });

          this.isFetching = false;
        },
        (error) => {}
      );
    });
  }

  onStartQuiz() {
    this.started = true;
  }

  answerQuiz(question, answers, manualAnswer) {
    var qKey = this.qNum - 1;
    this.currQuestion = question;
    // are there multiple corrects?
    var totCorrect = 0;
    this.responses[question.assessment_question_id] = answers[0];
    this.questions[qKey].answers.forEach((ans, index) => {
      if (ans.correct == 1) {
        totCorrect++;
      }
    });

    // multiple answers
    if (totCorrect > 1 && manualAnswer == false) {
      if (answers[0].selectedState == 'selected') {
        answers[0].selectedState = null;
        // unselect it
        this.answersSelected.forEach((_a, _i) => {
          if (_a.assessment_answer_id == answers[0].assessment_answer_id) {
            this.answersSelected.splice(_i, 1);
          }
        });

        if (this.answersSelected.length == 0) {
          this.showAnswerBtn = false;
        }
      } else {
        this.showAnswerBtn = true;
        //
        this.answersSelected.push(answers[0]);

        // what have they selected?
        answers[0].selectedState = 'selected';
      }
    } else {
      if (this.questions[qKey].state != 'answered') {
        question.correct = true;
        question.answers.forEach((a, index) => {
          // loop through answrs and assign value

          var selected = false;
          answers.forEach((sa, saIndex) => {
            if (sa.assessment_answer_id == a.assessment_answer_id) {
              selected = true;
              if (sa.correct == 1) {
                this.questions[qKey].answers[index].selectedState =
                  'selectedCorrect';
              } else {
                this.questions[qKey].answers[index].selectedState =
                  'selectedIncorrect';
                question.correct = false;
              }
            }
          });

          if (selected === false) {
            if (a.correct == 1) {
              this.questions[qKey].answers[index].selectedState =
                'unselectedCorrect';
              question.correct = false;
            } else {
              this.questions[qKey].answers[index].selectedState =
                'unselectedIncorrect';
            }
          }
        });

        this.questions[qKey].state = 'answered';
        this.questions[qKey].correct = question.correct;
        if (question.correct == true) {
          this.score++;
        }

        // what about self efficacy
      }
      this.showAnswerBtn = false;
      this.answersSelected = [];
      this.nextQuestion();
    }
  }

  nextQuestion() {
    this.qNum++;
    this.showAnswerBtn = false;
    this.answersSelected = [];
    if (this.qNum == this.questions.length + 1) {
      this.submit();
    }
  }

  submit() {
    //submit the responses
    //console.log(this.responses);
    this.isFetching = true;
    this.complete = true;

    for (var qId in this.responses) {
      this.score = +this.score + +this.responses[qId];
      this.assessmentsService
        .saveResponse(this.assessmentId, qId, this.responses[qId], this.prepost)
        .subscribe(
          (saveResponse) => {},
          (error) => {}
        );
    }

    this.assessmentsService
      .saveResult(
        this.assessmentId,
        this.score,
        this.questions.length,
        this.prepost
      )
      .subscribe((saveResult) => {
        // is there another prepost
        //console.log(this.currentProgramme.config.prepost);
        if (this.currentProgramme.config.prepost) {
          //

          let preposts = this.currentProgramme.config.prepost;
          // is there an org specific one?
          if (this.currentProgramme.config.prepostOrg) {
            const prepostOrg = JSON.parse(
              this.currentProgramme.config.prepostOrg
            );
            // get the current user...
            preposts = [];
            // check for org specific..
            if (prepostOrg[this.currentUser.client_id]) {
              for (const assessmentId of Object.keys(
                prepostOrg[this.currentUser.client_id]
              )) {
                for (const prepost of Object.keys(
                  prepostOrg[this.currentUser.client_id][assessmentId]
                )) {
                  if (
                    prepost == 'pre' &&
                    prepostOrg[this.currentUser.client_id][assessmentId].pre ==
                      true
                  ) {
                    preposts.push(assessmentId);
                  }
                }
              }
            }
            else{
              preposts = this.currentProgramme.config.prepost;
            }
          }
          this.sentOff = false;

          if(this.prepost == 'pre'){
          preposts.forEach((prepost) => {
            // console.log('prepost is...');
            // console.log(prepost);
            //(prepost);
            this.assessmentsService
              .fetchResults(prepost, this.prepost)
              .subscribe(
                (resultsReponse) => {
                  // console.log('result for ' + prepost);
                  // console.log(resultsReponse);

                  if (this.sentOff === false) {
                    this.complete = true;
                    if (resultsReponse.length < 1) {
                      // console.log('yes go to ...' + prepost);
                      this.complete = false;
                      this.isFetching = false;
                      this.sentOff = true;
                      this.responses = {};
                      this.options = {
                        showTicksValues: true,
                        stepsArray: [
                          { value: 0, legend: 'Cannot do at all' },
                          { value: 1 },
                          { value: 2 },
                          { value: 3 },
                          { value: 4 },
                          { value: 5, legend: 'Maybe yes Maybe no' },
                          { value: 6 },
                          { value: 7 },
                          { value: 8 },
                          { value: 9 },
                          { value: 10, legend: 'Certain can do' },
                        ],
                      };
                      // console.log('route to ....' + prepost);
                      this.router.navigate([
                        '/assessments',
                        'take',
                        prepost,
                        this.prepost,
                      ]);
                    } else {
                      // console.log('no do not go to...' + prepost);
                    }
                  }
                  this.isFetching = false;
                },
                (error) => {
                  this.complete = true;
                  this.isFetching = false;
                }
              );
            //
          });
        }
        else{
          this.complete = true;
          this.isFetching = false;
        }
        }
      });
  }
}
