import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as moment from 'moment';
import { Color, Label } from 'ng2-charts';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { Sts60Service } from 'src/app/services/sts60.service';
import { ProgrammeService } from 'src/app/services/programme.service';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { AppointmentService } from 'src/app/services/appointment.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-m3-physical-ability',
  templateUrl: './m3-physical-ability.component.html',
  styleUrls: ['./m3-physical-ability.component.scss']
})
export class M3PhysicalAbilityComponent implements OnInit {
  currentUser;
  currentProgramme;
  currentSts60 = 0;
  date = new Date();
  sts60s;
  age;
  sex;
  genderLabel;
  nextAppointment;
  @ViewChild('entryForm', { static: false }) entryForm: NgForm;



  @ViewChild('addModal') public addModal: ModalDirective;

  constructor(
    private authService: AuthService,
    private programmeService: ProgrammeService,
    private router: Router,
    private userService: UserService,
    public sts60Service: Sts60Service,
    private userSettingsService: UserSettingsService,
    private appointmentService:AppointmentService
  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {});

      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
        if(this.currentUser.gender == 'm'){
          this.genderLabel = 'male';
        }
        else if(this.currentUser.gender == 'f'){
          this.genderLabel = 'female';
        }
        this.sex = this.currentUser.gender;
        if(this.currentUser.dob){
          this.age = moment().diff(this.currentUser.dob, 'years',false);
        }
      });

      forkJoin({
        userSettings: this.userSettingsService.fetchAll(),
        sts60s: this.sts60Service.fetchAll(),
        nextAppointment: this.appointmentService.getNextAppointment()
        
      }).subscribe(
        (responseData) => {
          this.sts60s = responseData.sts60s;
          if(this.sts60s[0]){
            this.currentSts60 = this.sts60s[0].sts;
          }
          else{
            this.addModal.show();
          }
          
          this.nextAppointment = responseData.nextAppointment;

         
        }
      );
    }
  }

  onSubmitEntryForm() {
    // save the steps
    this.sts60Service
      .create(this.entryForm.value.reps, this.entryForm.value.date)
      .subscribe((responseData) => {
        // refresh the route..
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/physical-ability']));

        Swal.fire({
          icon: 'success',
          title: 'Entry Added',
          text: 'Your Heart Rate entry was added',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      });
  }



  onDelete(sts60) {
    this.sts60Service.delete(sts60.user_sts60_id).subscribe(
      (responseData) => {
        const index = this.sts60s.indexOf(sts60);
        this.sts60s.splice(index, 1);
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/physical-ability']));
        
      },
      (error) => {
        console.log(error);
      }
    );
  }


  getSts60RagForAgeAndSex( sts60:any, age:number, sex:string){
    return this.sts60Service.getRagForAgeAndSex(sts60, age, sex);
  }


  getSts60forColorAgeAndSex(color:string, age:number, sex:string){
    return this.sts60Service.getSts60forColorAgeAndSex(color, age, sex);
  }
}


