import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { AuthService } from './../auth/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserShapeService {
  userId;
  endPoint = '/user_shapes';
  allEntries?: any;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userId = user.id;
      }
    });
  }

  fetchAll(): Observable<any> {
    if (!this.userId) {
      return;
    }
    if (!this.allEntries) {
      let searchParams = new HttpParams();

      searchParams = searchParams.append('user_id', this.userId);
      searchParams = searchParams.append('sort', 'created');
      searchParams = searchParams.append('order', 'DESC');
      this.allEntries = this.http
        .get<any>(environment.apiUrl + this.endPoint, {
          params: searchParams,
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            return responseData._embedded.user_shapes;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.allEntries;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.endPoint + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(weight, waist, entryDate?) {
    this.clearCache();
    const postData = {
      user_id: this.userId,
      weight,
      waist,
      created: entryDate,
    };
    return this.http
      .post<{ name: string }>(environment.apiUrl + this.endPoint, postData, {
        observe: 'response',
      })
      .pipe(
        map((responseData) => {
          return responseData.body;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  update(id, weight, waist) {
    this.clearCache();
    const postData = {
      weight,
      waist,
    };
    return this.http.patch<{ name: string }>(
      environment.apiUrl + this.endPoint + '/' + id,
      postData,
      {
        observe: 'response',
      }
    );
  }

  delete(id) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.endPoint + '/' + id
    );
  }

  getBmi(height, weight) {
    return +weight / ((height / 100) * (height / 100));
  }

  getBmiRag(bmi, ethnicityId) {
    let ragColour = '';
    let nonMinority = [1, 2, 3];
    if (
      (+bmi >= 18.5 && +bmi < 25 && nonMinority.indexOf(+ethnicityId) !== -1) ||
      (+bmi >= 18.5 && +bmi < 23 && nonMinority.indexOf(+ethnicityId) == -1)
    ) {
      ragColour = 'green';
    } else if (
      (+bmi > 23 && +bmi < 30 && nonMinority.indexOf(+ethnicityId) !== -1) ||
      (+bmi > 23 && +bmi < 27 && nonMinority.indexOf(+ethnicityId) == -1)
    ) {
      ragColour = 'amber';
    } else {
      ragColour = 'red';
    }
    return ragColour;
  }

  getBmiRagRange(color, ethnicityId) {
    let range = '';
    let nonMinority = [1, 2, 3];
    switch (color) {
      case 'green':
        if (nonMinority.indexOf(+ethnicityId) !== -1) {
          range = '18.5-<25';
        } else {
          range = '18.5-<23';
        }
        break;
      case 'amber':
        if (nonMinority.indexOf(+ethnicityId) !== -1) {
          range = '25-30';
        } else {
          range = '23-27';
        }
        break;
      case 'red':
        if (nonMinority.indexOf(+ethnicityId) !== -1) {
          range = '<18.5 or >30';
        } else {
          range = '<18.5 or >27';
        }
        break;
    }

    return range;
  }

  getWaistRagRange(color, sex, ethnicityId) {
    let range = '';
    let nonMinority = [1, 2, 3];
    switch (color) {
      case 'green':
        if (nonMinority.indexOf(+ethnicityId) !== -1 && sex == 'm') {
          range = '<94';
        } else if (nonMinority.indexOf(+ethnicityId) !== -1 && sex == 'f') {
          range = '<80';
        } else if (nonMinority.indexOf(+ethnicityId) == -1 && sex == 'm') {
          range = '<90';
        } else if (nonMinority.indexOf(+ethnicityId) == -1 && sex == 'f') {
          range = '<80';
        }
        break;
      case 'amber':
        if (nonMinority.indexOf(+ethnicityId) !== -1 && sex == 'm') {
          range = '94-102';
        } else if (nonMinority.indexOf(+ethnicityId) !== -1 && sex == 'f') {
          range = '80-88';
        } else if (nonMinority.indexOf(+ethnicityId) == -1 && sex == 'm') {
          range = '>90';
        } else if (nonMinority.indexOf(+ethnicityId) == -1 && sex == 'f') {
          range = '>80';
        }
        break;
      case 'red':
        if (nonMinority.indexOf(+ethnicityId) !== -1 && sex == 'm') {
          range = '>102';
        } else if (nonMinority.indexOf(+ethnicityId) !== -1 && sex == 'f') {
          range = '>88';
        } else if (nonMinority.indexOf(+ethnicityId) == -1 && sex == 'm') {
          range = '>90';
        } else if (nonMinority.indexOf(+ethnicityId) == -1 && sex == 'f') {
          range = '>80';
        }
        break;
    }

    return range;
  }

  getWaistRag(rag, sex, ethnicityId) {
    let ragColor = '';
    let nonMinority = [1, 2, 3];

    if (sex == 'm' && nonMinority.indexOf(+ethnicityId) !== -1) {
      if (+rag < 94) {
        ragColor = 'green';
      } else if (+rag >= 94 && +rag <= 102) {
        ragColor = 'amber';
      } else {
        ragColor = 'red';
      }
    }
    if (sex == 'f' && nonMinority.indexOf(+ethnicityId) !== -1) {
      if (+rag < 80) {
        ragColor = 'green';
      } else if (+rag >= 80 && +rag <= 88) {
        ragColor = 'amber';
      } else {
        ragColor = 'red';
      }
    }
    if (sex == 'm' && nonMinority.indexOf(+ethnicityId) == -1) {
      if (+rag < 90) {
        ragColor = 'green';
      } else {
      /*else if(+rag>=94 && +rag<=102){
        ragColor = 'amber';
      }*/
        ragColor = 'red';
      }
    }
    if (sex == 'f' && nonMinority.indexOf(+ethnicityId) == -1) {
      if (+rag < 80) {
        ragColor = 'green';
      } else {
      /* else if(+rag>=94 && +rag<=102){
        ragColor = 'amber';
      }*/
        ragColor = 'red';
      }
    }

    return ragColor;
  }
  clearCache() {
    this.allEntries = null;
  }
}
