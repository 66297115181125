import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'passwordCount',
})
export class PasswordCountPipe implements PipeTransform {
  transform(value: string, peak: number, ...args: unknown[]): unknown {
    value = typeof value === 'string' ? value : '';
    peak = isFinite(peak) ? peak : 7;

    return value && (value.length > peak ? peak + '+' : value.length);
  }
}
