import { NgForm } from '@angular/forms';
import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { WorkoutsService } from 'src/app/services/workouts.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-strength-edit',
  templateUrl: './strength-edit.component.html',
  styleUrls: ['./strength-edit.component.scss'],
})
export class StrengthEditComponent implements OnInit {
  currentUser;
  currentProgramme;
  workoutDate;
  isFetching = false;
  workoutId;
  userWorkout;

  @ViewChild('trackerForm', { static: false }) trackerForm: NgForm;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private workoutsService: WorkoutsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.isFetching = true;

    this.route.params.subscribe((params) => {
      this.isFetching = true;

      this.workoutId = this.route.snapshot.params.id;
      // are we logged in?
      const user = this.authService.user.getValue();
      if (user) {
        this.programmeService.currentProgramme.subscribe((programmeData) => {
          this.currentProgramme = programmeData;
          if (this.currentProgramme) {
            this.isFetching = false;

            forkJoin({
              workout: this.workoutsService.fetch(this.workoutId),
            }).subscribe(
              (responseData) => {
                this.isFetching = false;
                this.userWorkout = responseData.workout;
                this.workoutDate = moment(
                  this.userWorkout.workout_date
                ).toDate();
              },
              (error) => {
                this.isFetching = false;
              }
            );
          }
        });

        this.userService.userData.subscribe((userDataResponse) => {
          this.currentUser = userDataResponse;
        });
      }
    });
  }

  onSubmit() {
    // this.trackerForm.value.date

    if (!this.trackerForm.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.workoutsService
        .update(
          this.workoutId,
          this.trackerForm.value.minutes,
          this.trackerForm.value.effort,
          moment(this.workoutDate).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry updated',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.trackerForm.resetForm();
            this.router.navigateByUrl(
              '/activity/strength/view/' + this.workoutId
            );
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  onDelete() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to undo this",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it',
    }).then((result) => {
      if (result.value) {
        this.workoutsService.delete(this.workoutId).subscribe(
          (responseData) => {
            Swal.fire('Deleted', 'Your record has been deleted.', 'success');
            this.router.navigate(['/activity', 'strength']);
          },
          (error) => {
            this.isFetching = false;
            Swal.fire('Error!', 'There was an error.', 'error');
          }
        );
      }
    });
  }
}
