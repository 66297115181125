import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-to-homescreen',
  templateUrl: './add-to-homescreen.component.html',
  styleUrls: ['./add-to-homescreen.component.scss'],
})
export class AddToHomescreenComponent implements OnInit {
  showInstallMessage = false;
  skipped = false;
  device = '';
  deferredPrompt;
  canInstallAndroid = false;
  constructor(public router: Router) {}

  ngOnInit(): void {

    if(sessionStorage.getItem('pwaInstallSkipped') === '1' || +localStorage.getItem('pwaInstallSkipped') >2){
      this.skipped = true;
    }
    else{
      this.skipped = false;
    }
    this.router.events.subscribe((val) => {
      if (this.router.isActive('/dashboard', true) && this.skipped === false) {
        // Checks if should display install popup notification:
        if ((this.isIos() || this.isAndroid()) && !this.isInStandaloneMode()) {
          this.showInstallMessage = true;
        }
      }
    });

    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      // showInstallPromotion();
      this.canInstallAndroid = true;
    });
  }

  onSkip(event) {
    this.showInstallMessage = false;
    this.skipped = true;
    sessionStorage.setItem('pwaInstallSkipped', '1');

    let localSkips = +localStorage.getItem('pwaInstallSkipped');
    localSkips++;
    localStorage.setItem('pwaInstallSkipped', ''+localSkips);

    // local storage for skipping too
  }

  // PWA install for IOS
  // Detects if device is on iOS
  isIos() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (/iphone/.test(userAgent)) {
      this.device = 'iphone';
    }
    if (/ipad/.test(userAgent)) {
      this.device = 'ipad';
    }
    if (/ipod/.test(userAgent)) {
      this.device = 'ipod';
    }

    let flag = /iphone|ipad|ipod/.test(userAgent);

    if (flag === false) {
      if (
        navigator.platform === 'MacIntel' &&
        typeof navigator['standalone'] !== 'undefined'
      ) {
        flag = true;
        this.device = 'ipad';
      }
    }
    return flag;
  }

  isAndroid() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isAndroid = userAgent.indexOf('android') > -1; //&& ua.indexOf("mobile");
    if (isAndroid) {
      this.device = 'android';
    }
    return isAndroid;
  }
  // Detects if device is in standalone mode
  isInStandaloneMode() {
    if (this.isIos()) {
      return 'standalone' in window.navigator && window.navigator['standalone'];
    }
    if (this.isAndroid()) {
      return window.matchMedia('(display-mode: standalone)').matches;
    }
  }

  onInstallAndroid(event) {
    // Show the install prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }

      this.showInstallMessage = false;
      this.skipped = true;
    });
  }
}
