import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpParams,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { take, exhaustMap, tap } from 'rxjs/operators';

import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  user: any;
  constructor(
    private authService: AuthService,
    private router: Router,
    private cookieService: CookieService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let modifiedReq = req.clone();

    //TODO CHANGE FROM COOKIES
    let bearer;
    if (this.authService.apiToken) {
      bearer = this.authService.apiToken;
    }
    if (!bearer) {
     bearer = this.cookieService.get('apiToken');
    }
    if (bearer) {
      modifiedReq = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + bearer,
        },
        //headers: new HttpHeaders().set('Authorization', 'Bearer ' + user.token),
      });
    }

    return next.handle(modifiedReq).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            /* if (err.status !== 401) {
           return;
          }
          this.router.navigate(['login']);*/
          }
        }
      )
    );
  }
}
