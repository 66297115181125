<app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
<div  *ngIf="section && page">
    <nav class="subnav">
        <a *ngIf="prevSection" [routerLink]="['/help', 'section', prevSection.content_section_id]"  class="subnav-prev"><span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span></a>
        <h2><span class="num">{{programmeContentSection.sort_order}}</span> {{programmeContentSection.title | uppercase}}</h2>
        <a *ngIf="nextSection"  [routerLink]="['/help', 'section', nextSection.content_section_id]" class="subnav-next"><span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span></a>
    </nav>
    <div class="reading-material-page-header">
        <span class="alph">{{page.sort_order}}</span>  | {{page.title}}
    </div>
    <div class="container bg-white">
        <div class="row">
            <div class="col-md-12">
                <div class="reading-content">
                    <div [innerHTML]="page.content | safeHtml"></div>
                    <hr class="divider">
                    <div>
                        <div class="prev-next row">
                            <div class="col-6">
                                <a *ngIf="prevPage" [routerLink]="['/help', 'page', prevPage.content_page_id]"><span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-left']"></fa-icon></span> PREV</a>
                            </div>
                            <div class="col-6 text-right">
                                <a *ngIf="nextPage" [routerLink]="['/help', 'page', nextPage.content_page_id]">NEXT <span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span></a>
                            </div>
                        </div>
                    </div>
    
                </div>
            </div>
        </div>
        <br><br>
        <div class="text-center">
            <button type="button" class="btn btn-primary" (click)="showSubnav()">
                JUMP TO <span class="caret"></span>
            </button>
        </div><br>
        <nav class="section-nav" *ngIf="subnav">
            <ul>
                <ng-container *ngFor="let nPage of pages">
                    <li  *ngIf="page.content_page_id !== nPage.content_page_id" ng-class="{active : page.content_page_id === nPage.content_page_id}" role="menuitem"><a  [routerLink]="['/help', 'page', nPage.content_page_id]">{{ nPage.title}}</a></li>
          
                </ng-container>
             </ul>
        </nav></div>