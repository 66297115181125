import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  cacheTime = new Date();
  cacheLifetime = 3 * 60 * 1000;
  allConfigs: any;

  constructor(private http: HttpClient) {}

  fetchAll(): Observable<any> {
    const now = new Date();

    if (
      !this.allConfigs ||
      Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime
    ) {
      this.cacheTime = new Date();
      this.allConfigs = this.http
        .get<any>(environment.apiUrl + '/configs', {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const configs = {};
            responseData._embedded.configs.forEach((item) => {
              configs[item.var] = item.value;
            });
            return configs;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.allConfigs;
  }

  clearCache() {
    this.allConfigs = null;
  }

  checkStatus() {
    return this.http
      .get<any>(environment.apiUrl + '/check_status', {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.check_status[0]?.online;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
}
