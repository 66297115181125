import { Router } from '@angular/router';
import { NotificationService } from './../../services/notification.service';
import { NgForm } from '@angular/forms';
import { DateHelperService } from './../../services/date-helper.service';
import { TargetService } from './../../services/target.service';
import { ActivityService } from './../../services/activity.service';
import { UserSettingsService } from './../../services/user-settings.service';
import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from './../../../environments/environment';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { ChallengeService } from 'src/app/services/challenge.service';

import './../../extensions/google-maps.extensions';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-challenges-individual',
  templateUrl: './challenges-individual.component.html',
  styleUrls: ['./challenges-individual.component.scss'],
})
export class ChallengesIndividualComponent implements OnInit {
  @ViewChild('stepsForm', { static: false }) stepsForm: NgForm;
  currentUser;
  currentProgramme;
  isFetching = false;
  errors = [];
  userSettings;
  row = {
    rowToggle: {},
  };
  showPercent = false;
  showPercentWe = false;
  showPercentG = false;
  showPercentCu = false;
  globalShowall;
  monFriShowall = false;
  weShowall = false;

  tableModeG;
  tableMode;
  tableModeWe;

  currentActivity;
  currentTarget;
  currentTargetMins;
  environment = environment;
  currentSteps = 0;
  now;
  currentWeek;
  previousWeek = {
    startDate: null,
    endDate: null,
  };
  nextWeek = {
    startDate: null,
    endDate: null,
  };
  weekTotal = 0;
  weekData = [];

  toggleRow = {
    monFri: false,
    weekend: false,
    custom: false,
  };

  filters = {
    gFilterOn: false,
    gender: 'all',
    ageRange: 'all',
  };

  showMonth = true;
  date;

  monfriChallenge = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  monfriChallengePrev = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  monfriChallengeNext = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  weekendChallenge = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  weekendChallengePrev = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  weekendChallengeNext = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  currentChallenge = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  teamChallenge = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  globalChallenge = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  globalChallengePrev = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  globalChallengeNext = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };

  monfriActivity;
  weekendActivity;
  globalActivity;
  filterBand;
  myActivity = {
    step_count: 0,
  };

  currentLeader = [];
  iLead;

  allTimeTeamDistance;
  geojson;
  mapChallenge;

  today;
  nowTime;

  //maps
  map;
  origin;
  destination;
  wayPoints = [];
  challengeDistance;
  challengeDistanceOutput = {
    distance: 0,
    text: null,
  };
  totaldistance = 0;
  currentMapProgress;
  mapCompleted;
  mapChallengeDistance = 0;

  mapNotified = false;

  markerOptions = {
    origin: {
      label: { text: 'Start Here', className: 'custom-marker' },
      draggable: false,
      id: 'start',
    },
    destination: {
      label: { text: 'End Here', className: 'custom-marker' },
      draggable: false,
      id: 'end',
    },
  };
  youHere;
  icon = {
    url: '',
    scaledSize: {
      width: 50,
      height: 50,
    },
  };

  gFilterOn;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private userSettingsService: UserSettingsService,
    private activityService: ActivityService,
    private targetService: TargetService,
    private dateHelperService: DateHelperService,
    private challengeService: ChallengeService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isFetching = true;

    // setup the challenge

    this.mapChallenge = null;
    this.geojson = null;
    this.globalShowall = false;
    this.allTimeTeamDistance = 1;

    // get week
    const now = moment();

    // what is the day today
    this.today = moment(now).format('dddd');
    this.currentWeek = this.dateHelperService.getWeekFor(now);
    this.now = now.format('YYYY-MM-DD');

    this.nowTime = now.format('YYYY-MM-DD  HH:mm:ss');

    this.teamChallenge.startDate = moment(this.currentWeek[0]).format(
      'YYYY-MM-DD'
    );
    this.teamChallenge.endDate = moment(this.currentWeek[6]).format(
      'YYYY-MM-DD'
    );

    this.globalChallenge.startDate = this.teamChallenge.startDate;
    this.globalChallenge.endDate = this.teamChallenge.endDate;
    this.globalChallenge.nextStartDate = moment(this.currentWeek[0])
      .add(1, 'week')
      .format('YYYY-MM-DD');

    if (this.today === 'Saturday' || this.today === 'Sunday') {
      this.weekendChallenge.title = 'Weekend Challenge';
      this.currentChallenge.title = 'Weekend Challenge';
      this.weekendChallenge.startDate = moment(this.currentWeek[5]).format(
        'YYYY-MM-DD'
      );
      this.weekendChallenge.nextStartDate = moment(this.currentWeek[5])
        .add(1, 'week')
        .format('YYYY-MM-DD');
      this.weekendChallenge.endDate = moment(this.currentWeek[6]).format(
        'YYYY-MM-DD'
      );
      this.weekendChallenge.type = 'WEEKEND';

      this.currentChallenge = this.weekendChallenge;

      this.monfriChallenge.title = 'Monday - Friday Challenge';
      this.monfriChallenge.startDate = moment(this.currentWeek[0]).format(
        'YYYY-MM-DD'
      );
      this.monfriChallenge.endDate = moment(this.currentWeek[4]).format(
        'YYYY-MM-DD'
      );
      this.monfriChallenge.type = 'MON-FRI';
      this.monfriChallenge.id =
        this.monfriChallenge.type +
        ';' +
        this.monfriChallenge.startDate +
        ';' +
        this.monfriChallenge.endDate;
      this.monfriChallenge = this.monfriChallenge;
    } else {
      this.monfriChallenge.title = 'Monday - Friday Challenge';
      this.currentChallenge.title = 'Monday - Friday Challenge';
      this.monfriChallenge.startDate = moment(this.currentWeek[0]).format(
        'YYYY-MM-DD'
      );
      this.monfriChallenge.endDate = moment(this.currentWeek[4]).format(
        'YYYY-MM-DD'
      );
      this.monfriChallenge.type = 'MON-FRI';
      this.monfriChallenge.id =
        this.monfriChallenge.type +
        ';' +
        this.monfriChallenge.startDate +
        ';' +
        this.monfriChallenge.endDate;

      this.currentChallenge = this.monfriChallenge;

      this.weekendChallenge.title = 'Weekend Challenge';
      this.weekendChallenge.startDate = moment(this.currentWeek[5])
        .subtract(1, 'week')
        .format('YYYY-MM-DD');
      this.weekendChallenge.nextStartDate = moment(this.currentWeek[5]).format(
        'YYYY-MM-DD'
      );

      this.weekendChallenge.endDate = moment(this.currentWeek[6])
        .subtract(1, 'week')
        .format('YYYY-MM-DD');
      this.weekendChallenge.type = 'WEEKEND';
      this.weekendChallenge.id =
        this.weekendChallenge.type +
        ';' +
        this.weekendChallenge.startDate +
        ';' +
        this.weekendChallenge.endDate;
    }

    // are we logged in?
    const user = this.authService.user;
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (this.currentProgramme) {
          this.userService.userData
            .pipe(take(1))
            .subscribe((userDataResponse) => {
              if (userDataResponse) {
                this.currentUser = userDataResponse;
                if (this.currentUser.buddy) {
                  this.router.navigate(['/friends-and-family']);
                }

                forkJoin({
                  monFriActivity: this.activityService.getBandActivity(
                    this.currentUser.step_band,
                    this.currentProgramme.programme_id,
                    this.monfriChallenge.startDate,
                    this.monfriChallenge.endDate
                  ),
                  weActivity: this.activityService.getBandActivity(
                    this.currentUser.step_band,
                    this.currentProgramme.programme_id,
                    this.weekendChallenge.startDate,
                    this.weekendChallenge.endDate
                  ),
                  globalActivity: this.activityService.getGlobalActivity(
                    this.currentProgramme.programme_id,
                    moment(this.currentWeek[0]).format('YYYY-MM-DD'),
                    moment(this.currentWeek[6]).format('YYYY-MM-DD'),
                    this.filters.gender,
                    this.filters.ageRange
                  ),
                  mapChallenges: this.challengeService.fetchMaps(),
                  mapChallengeProgress:
                    this.challengeService.fetchMapChallengeProgress(),
                }).subscribe(
                  (responseData) => {
                    this.isFetching = false;
                    this.monfriActivity = responseData.monFriActivity;
                    this.weekendActivity = responseData.weActivity;

                    // work out the leader
                    if (this.currentChallenge.type === 'MON-FRI') {
                      this.monfriActivity.forEach((item, index) => {
                        if (item.user_id == this.currentUser.user_id) {
                          this.myActivity = item;
                        }

                        if (this.currentLeader.length === 0) {
                          this.currentLeader.push(this.monfriActivity[index]);
                          if (
                            this.monfriActivity[index].user_id ==
                            this.currentUser.user_id
                          ) {
                            this.iLead = true;
                          }
                        } else {
                          if (index > 0) {
                            if (
                              this.monfriActivity[index].step_count ==
                              this.currentLeader[0].step_count
                            ) {
                              this.currentLeader.push(
                                this.monfriActivity[index]
                              );
                              if (
                                this.monfriActivity[index].user_id ==
                                this.currentUser.user_id
                              ) {
                                this.iLead = true;
                              }
                            }
                          }
                        }
                      });

                      //
                      this.tableModeWe = 'awards';
                      // get the results data
                      /*
                      this.activityService
                        .getChallengeBandResults(
                          this.currentUser.step_band,
                          this.weekendChallenge.type,
                          this.weekendChallenge.startDate,
                          this.weekendChallenge.endDate
                        )
                        .subscribe(
                          (resultsResponseData) => {
                            this.weekendActivity = resultsResponseData;
                          },
                          (error) => {}
                        );*/
                        this.activityService
                        .getBandActivity(
                          this.currentUser.step_band,
                          this.currentProgramme.programme_id,
                          this.weekendChallenge.startDate,
                          this.weekendChallenge.endDate
                        )
                        .subscribe(
                          (responseData) => {
                            this.weekendActivity = responseData;
                          },
                          (error) => {}
                        );
                    }

                    // weekend now

                    if (this.currentChallenge.type === 'WEEKEND') {
                      this.weekendActivity.forEach((item, index) => {
                        if (item.user_id == this.currentUser.user_id) {
                          this.myActivity = item;
                        }

                        if (this.currentLeader.length === 0) {
                          this.currentLeader.push(this.weekendActivity[index]);
                          if (
                            this.weekendActivity[index].user_id ==
                            this.currentUser.user_id
                          ) {
                            this.iLead = true;
                          }
                        } else {
                          if (index > 0) {
                            if (
                              this.weekendActivity[index].step_count ==
                              this.currentLeader[0].step_count
                            ) {
                              this.currentLeader.push(
                                this.weekendActivity[index]
                              );
                              if (
                                this.weekendActivity[index].user_id ==
                                this.currentUser.user_id
                              ) {
                                this.iLead = true;
                              }
                            }
                          }
                        }
                      });

                      //
                      this.tableMode = 'awards';
                      // get the results data
                     /* this.activityService
                        .getChallengeBandResults(
                          this.currentUser.step_band,
                          this.monfriChallenge.type,
                          this.monfriChallenge.startDate,
                          this.monfriChallenge.endDate
                        )
                        .subscribe(
                          (resultsResponseData) => {
                            this.monfriActivity = resultsResponseData;
                          },
                          (error) => {}
                        );*/
                        this.activityService
                        .getBandActivity(
                          this.currentUser.step_band,
                          this.currentProgramme.programme_id,
                          this.monfriChallenge.startDate,
                          this.monfriChallenge.endDate
                        )
                        .subscribe(
                          (responseData) => {
                            this.monfriActivity = responseData;
                          },
                          (error) => {}
                        );
                    }

                    this.globalActivity = responseData.globalActivity;

                    // start map
                    const mapChallenges = responseData.mapChallenges;
                    const mapChallengeProgress =
                      responseData.mapChallengeProgress;

                    var completeMaps = [];
                    var progressTrackMaps = [];
                    mapChallengeProgress.forEach((mcp, mcpDex) => {
                      if (mcp.completed == 1) {
                        completeMaps.push(mcp.challenge_id);
                      } else {
                        progressTrackMaps.push(mcp.challenge_id);
                      }
                    });

                    var mapSet = 0;

                    // find ProgressTrackMaps [0]
                    mapChallenges.forEach((chlng, index) => {
                      if (chlng.challenge_id == progressTrackMaps[0]) {
                        // we got it!
                        this.mapChallenge = chlng;
                        this.geojson = JSON.parse(this.mapChallenge.geojson);
                        mapSet = 1;
                        mapChallengeProgress.forEach((mcp, mcpDex) => {
                          if (mcp.challenge_id == chlng.challenge_id) {
                            this.currentMapProgress = mcp;
                          }
                        });
                      }
                    });

                    mapChallenges.forEach((chlng, index) => {
                      // set the map challenge
                      if (
                        completeMaps.indexOf(chlng.challenge_id) < 0 &&
                        mapSet == 0
                      ) {
                        this.mapChallenge = chlng;
                        this.geojson = JSON.parse(this.mapChallenge.geojson);
                        mapSet = 1;

                        // is there a progress set?
                        let map_date_started = null;
                        if (progressTrackMaps.indexOf(chlng.challenge_id) < 0) {
                          if (completeMaps.length > 0) {
                            // start it tomorrow!
                            map_date_started = moment()
                              .add(1, 'days')
                              .startOf('day')
                              .format('YYYY-MM-DD');
                          } else {
                            map_date_started = moment().format('YYYY-MM-DD');
                          }

                          this.challengeService
                            .createMapProgress(
                              chlng.challenge_id,
                              0,
                              map_date_started
                            )
                            .subscribe(
                              (mapchlngRepsonse) => {
                                this.currentMapProgress = mapchlngRepsonse;
                              },
                              (error) => {}
                            );
                        } else {
                          mapChallengeProgress.forEach((mcp, mcpDex) => {
                            if (mcp.challenge_id == chlng.challenge_id) {
                              this.currentMapProgress = mcp;
                            }
                          });
                        }
                      }
                    });

                    // current progress
                    // set the step progress for this date..
                    let mapStartDate = moment().format('YYYY-MM-DD');
                    if (this.currentMapProgress) {
                      mapStartDate = moment(
                        this.currentMapProgress.date_started
                      ).format('YYYY-MM-DD');
                      this.activityService
                        .getActivitySummary(mapStartDate, null)
                        .subscribe(
                          (activitySummaryResponse) => {
                            activitySummaryResponse.forEach((activity) => {
                              this.mapChallengeDistance +=
                                activity.distance_count;
                            });
                          },
                          (error) => {}
                        );
                    }

                    if (this.geojson) {
                      this.origin = {
                        lng: this.geojson.features[0].geometry.coordinates[0],
                        lat: this.geojson.features[0].geometry.coordinates[1],
                      };
                      this.destination = {
                        lng: this.geojson.features[
                          this.geojson.features.length - 1
                        ].geometry.coordinates[0],
                        lat: this.geojson.features[
                          this.geojson.features.length - 1
                        ].geometry.coordinates[1],
                      };

                      this.geojson.features.forEach((item, index) => {
                        if (
                          index > 0 &&
                          index < this.geojson.features.length - 1
                        ) {
                          const waypoint = {
                            location: {
                              lng: item.geometry.coordinates[0],
                              lat: item.geometry.coordinates[1],
                            },
                            stopover: false,
                          };
                          this.wayPoints.push(waypoint);
                        }
                      });
                    }
                    // end map
                    // end
                  },
                  (error) => {
                    this.isFetching = false;
                    this.errors.push(error.message);
                  }
                );
              }
            });
        }
      });
    }
  }

  updateGlobal() {
    this.globalActivity = [];
    this.activityService
      .getGlobalActivity(
        this.currentProgramme.programme_id,
        moment(this.currentWeek[0]).format('YYYY-MM-DD'),
        moment(this.currentWeek[6]).format('YYYY-MM-DD'),
        this.filters.gender,
        this.filters.ageRange
      )
      .subscribe(
        (responseData) => {
          this.globalActivity = responseData;
          this.filters.gFilterOn = false;
        },
        (error) => {
          console.log(error);
          this.filters.gFilterOn = false;
        }
      );
  }

  setFilterBand(band, type) {
    this.filterBand = band;
  }

  prevData(challenge) {
    this.tableMode = 'activity';
    if (challenge === 'mon-fri') {
      // work out prev
      this.monfriChallengePrev.startDate = moment(
        this.monfriChallenge.startDate
      )
        .subtract(1, 'week')
        .format('YYYY-MM-DD');
      this.monfriChallengePrev.endDate = moment(this.monfriChallenge.endDate)
        .subtract(1, 'week')
        .format('YYYY-MM-DD');

      this.monfriChallenge.title = 'Monday - Friday Challenge';

      this.monfriChallenge.startDate = this.monfriChallengePrev.startDate;
      this.monfriChallenge.endDate = this.monfriChallengePrev.endDate;
      this.monfriChallenge.type = 'MON-FRI';
      this.monfriChallenge.id =
        'band-' +
        this.currentUser.step_band +
        ';' +
        this.monfriChallenge.type +
        ';' +
        this.monfriChallengePrev.startDate +
        ';' +
        this.monfriChallengePrev.endDate;

      // get data OR awards
      /*if (
        moment(this.monfriChallenge.endDate).format('YYYY-MM-DD') >=
        moment().format('YYYY-MM-DD')
      ) {*/
        this.activityService
          .getBandActivity(
            this.currentUser.step_band,
            this.currentProgramme.programme_id,
            this.monfriChallenge.startDate,
            this.monfriChallenge.endDate
          )
          .subscribe(
            (responseData) => {
              this.monfriActivity = responseData;
            },
            (error) => {}
          );
     /* } else {
        this.tableMode = 'awards';

        this.activityService
          .getChallengeBandResults(
            this.currentUser.step_band,
            this.monfriChallenge.type,
            this.monfriChallenge.startDate,
            this.monfriChallenge.endDate
          )
          .subscribe(
            (responseData) => {
              this.monfriActivity = responseData;
            },
            (error) => {}
          );
      }*/
    } else {
      // work out prev
      this.weekendChallengePrev.startDate = moment(
        this.weekendChallenge.startDate
      )
        .subtract(1, 'week')
        .format('YYYY-MM-DD');
      this.weekendChallengePrev.endDate = moment(this.weekendChallenge.endDate)
        .subtract(1, 'week')
        .format('YYYY-MM-DD');

      this.weekendChallenge.title = 'Weekend Challenge';

      this.weekendChallenge.startDate = this.weekendChallengePrev.startDate;
      this.weekendChallenge.nextStartDate = moment(
        this.weekendChallenge.startDate
      )
        .add(1, 'week')
        .format('YYYY-MM-DD');
      this.weekendChallenge.endDate = this.weekendChallengePrev.endDate;
      this.weekendChallenge.type = 'WEEKEND';
      this.weekendChallenge.id =
        'band-' +
        this.currentUser.step_band +
        ';' +
        this.weekendChallenge.type +
        ';' +
        this.weekendChallengePrev.startDate +
        ';' +
        this.weekendChallengePrev.endDate;

      /*if (
        moment(this.weekendChallenge.endDate).format('YYYY-MM-DD') >=
        moment().format('YYYY-MM-DD')
      ) {*/
        // get data

        this.activityService
          .getBandActivity(
            this.currentUser.step_band,
            this.currentProgramme.programme_id,
            this.weekendChallenge.startDate,
            this.weekendChallenge.endDate
          )
          .subscribe(
            (responseData) => {
              this.weekendActivity = responseData;
            },
            (error) => {}
          );
      /*} else {
        this.tableModeWe = 'awards';

        this.activityService
          .getChallengeBandResults(
            this.currentUser.step_band,
            this.weekendChallenge.type,
            this.weekendChallenge.startDate,
            this.weekendChallenge.endDate
          )
          .subscribe(
            (responseData) => {
              this.weekendActivity = responseData;
            },
            (error) => {}
          );
      }*/
    }
  }
  nextData(challenge) {
    this.tableMode = 'activity';
    if (challenge === 'mon-fri') {
      // work out next
      this.monfriChallengeNext.startDate = moment(
        this.monfriChallenge.startDate
      )
        .add(1, 'week')
        .format('YYYY-MM-DD');
      this.monfriChallengeNext.endDate = moment(this.monfriChallenge.endDate)
        .add(1, 'week')
        .format('YYYY-MM-DD');

      this.monfriChallenge.title = 'Monday - Friday Challenge';

      this.monfriChallenge.startDate = this.monfriChallengeNext.startDate;
      this.monfriChallenge.endDate = this.monfriChallengeNext.endDate;
      this.monfriChallenge.type = 'MON-FRI';
      this.monfriChallenge.id =
        'band-' +
        this.currentUser.step_band +
        ';' +
        this.monfriChallenge.type +
        ';' +
        this.monfriChallengeNext.startDate +
        ';' +
        this.monfriChallengeNext.endDate;

      // get data OR awards
     /* if (
        moment(this.monfriChallenge.endDate).format('YYYY-MM-DD') >=
        moment().format('YYYY-MM-DD')
      ) {*/
        this.activityService
          .getBandActivity(
            this.currentUser.step_band,
            this.currentProgramme.programme_id,
            this.monfriChallenge.startDate,
            this.monfriChallenge.endDate
          )
          .subscribe(
            (responseData) => {
              this.monfriActivity = responseData;
            },
            (error) => {}
          );
     /* } else {
        this.tableMode = 'awards';

        this.activityService
          .getChallengeBandResults(
            this.currentUser.step_band,
            this.monfriChallenge.type,
            this.monfriChallenge.startDate,
            this.monfriChallenge.endDate
          )
          .subscribe(
            (responseData) => {
              this.monfriActivity = responseData;
            },
            (error) => {}
          );
      }*/
    } else {
      // work out next
      this.weekendChallengeNext.startDate = moment(
        this.weekendChallenge.startDate
      )
        .add(1, 'week')
        .format('YYYY-MM-DD');
      this.weekendChallengeNext.endDate = moment(this.weekendChallenge.endDate)
        .add(1, 'week')
        .format('YYYY-MM-DD');

      this.weekendChallenge.title = 'Weekend Challenge';

      this.weekendChallenge.startDate = this.weekendChallengeNext.startDate;
      this.weekendChallenge.nextStartDate = moment(
        this.weekendChallenge.startDate
      )
        .add(1, 'week')
        .format('YYYY-MM-DD');
      this.weekendChallenge.endDate = this.weekendChallengeNext.endDate;
      this.weekendChallenge.type = 'WEEKEND';
      this.weekendChallenge.id =
        'band-' +
        this.currentUser.step_band +
        ';' +
        this.weekendChallenge.type +
        ';' +
        this.weekendChallengeNext.startDate +
        ';' +
        this.weekendChallengeNext.endDate;

     /* if (
        moment(this.weekendChallenge.endDate).format('YYYY-MM-DD') >=
        moment().format('YYYY-MM-DD')
      ) {*/
        // get data

        this.activityService
          .getBandActivity(
            this.currentUser.step_band,
            this.currentProgramme.programme_id,
            this.weekendChallenge.startDate,
            this.weekendChallenge.endDate
          )
          .subscribe(
            (responseData) => {
              this.weekendActivity = responseData;
            },
            (error) => {}
          );
      /*} else {
        this.tableModeWe = 'awards';

        this.activityService
          .getChallengeBandResults(
            this.currentUser.step_band,
            this.weekendChallenge.type,
            this.weekendChallenge.startDate,
            this.weekendChallenge.endDate
          )
          .subscribe(
            (responseData) => {
              this.weekendActivity = responseData;
            },
            (error) => {}
          );
      }*/
    }
  }

  prevDataGlobal() {
    this.tableModeG = 'activity';
    // work out prev
    this.globalChallengePrev.startDate = moment(this.globalChallenge.startDate)
      .subtract(1, 'week')
      .format('YYYY-MM-DD');
    this.globalChallengePrev.endDate = moment(this.globalChallenge.endDate)
      .subtract(1, 'week')
      .format('YYYY-MM-DD');

    this.globalChallenge.title = 'Global';
    this.globalChallenge.nextStartDate = moment(this.globalChallenge.startDate)
      .subtract(1, 'week')
      .format('YYYY-MM-DD');
    this.globalChallenge.startDate = this.globalChallengePrev.startDate;
    this.globalChallenge.endDate = this.globalChallengePrev.endDate;
    this.globalChallenge.type = 'GLOBAL';
    this.globalChallenge.id =
      'band-' +
      this.currentUser.step_band +
      ';' +
      this.globalChallenge.type +
      ';' +
      this.globalChallenge.startDate +
      ';' +
      this.globalChallenge.endDate;

    // get data
   /* if (
      moment(this.globalChallenge.endDate).format('YYYY-MM-DD') >=
      moment().format('YYYY-MM-DD')
    ) {*/
      this.activityService
        .getGlobalActivity(
          this.currentProgramme.programme_id,
          this.globalChallenge.startDate,
          this.globalChallenge.endDate,
          this.filters.gender,
          this.filters.ageRange
        )
        .subscribe(
          (responseData) => {
            this.globalActivity = responseData;
          },
          (error) => {}
        );
      this.gFilterOn = false;
    /*} else {
      this.tableModeG = 'awards';

      this.activityService
        .getChallengeGlobalResults(
          this.globalChallenge.type,
          this.globalChallenge.startDate,
          this.globalChallenge.endDate
        )
        .subscribe(
          (responseData) => {
            this.globalActivity = responseData;
          },
          (error) => {}
        );
    }*/
  }
  nextDataGlobal() {
    this.tableModeG = 'activity';
    // work out next
    this.globalChallengeNext.startDate = moment(this.globalChallenge.startDate)
      .add(1, 'week')
      .format('YYYY-MM-DD');
    this.globalChallengeNext.endDate = moment(this.globalChallenge.endDate)
      .add(1, 'week')
      .format('YYYY-MM-DD');

    this.globalChallenge.title = 'Global';
    this.globalChallenge.nextStartDate = moment(
      this.globalChallengeNext.startDate
    )
      .add(1, 'week')
      .format('YYYY-MM-DD');
    this.globalChallenge.startDate = this.globalChallengeNext.startDate;
    this.globalChallenge.endDate = this.globalChallengeNext.endDate;
    this.globalChallenge.type = 'GLOBAL';
    this.globalChallenge.id =
      'band-' +
      this.currentUser.step_band +
      ';' +
      this.globalChallenge.type +
      ';' +
      this.globalChallenge.startDate +
      ';' +
      this.globalChallenge.endDate;

    // get data
    /*if (
      moment(this.globalChallenge.endDate).format('YYYY-MM-DD') >=
      moment().format('YYYY-MM-DD')
    ) {*/
      this.activityService
        .getGlobalActivity(
          this.currentProgramme.programme_id,
          this.globalChallenge.startDate,
          this.globalChallenge.endDate,
          this.filters.gender,
          this.filters.ageRange
        )
        .subscribe(
          (responseData) => {
            this.globalActivity = responseData;
          },
          (error) => {}
        );
      this.gFilterOn = false;
   /* } else {
      this.tableModeG = 'awards';

      this.activityService
        .getChallengeGlobalResults(
          this.globalChallenge.type,
          this.globalChallenge.startDate,
          this.globalChallenge.endDate
        )
        .subscribe(
          (responseData) => {
            this.globalActivity = responseData;
          },
          (error) => {}
        );
    }*/
  }

  reload() {
    document.location.reload();
  }

  sortActivity(items, sort, reverse) {
    let sorted = true;
    items = items.sort((a, b) => {
      if (!a[sort]) {
        // Change this values if you want to put `null` values at the end of the array
        // return -1;
      }
      if (!b[sort]) {
        // Change this values if you want to put `null` values at the end of the array
        // return +1;
      }

      let result = a[sort] - b[sort];

      if (isNaN(result)) {
        return !reverse
          ? a[sort].toString().localeCompare(b[sort])
          : b[sort].toString().localeCompare(a[sort]);
      } else {
        return !reverse ? result : -result;
      }

      /*
      if (sorted) return a[sort].localeCompare(b[sort]);
      else return b[sort].localeCompare(a[sort]);*/

      // a[sort].localeCompare(b[sort])
    });

    return items;
  }

  getPos(activities, thisActivity, metric) {
    let pos = 0;
    activities.forEach((ac, index) => {
      if (ac === thisActivity) {
        pos = index + 1;
        // is the stepcount the same?
        for (let i = index; i >= 0; i--) {
          if (activities[i][metric] === thisActivity[metric]) {
            pos = i;
          }
        }
      }
    });
    return pos + 1;
  }

  checkMapDate(startDate) {
    const date = moment(startDate);
    const now = moment();

    if (now > date) {
      return false;
    } else {
      return true;
    }
  }

  mapReady(map) {
    this.map = map;
    this.addMapExtensions();
  }
  onResponse(directions) {
    this.challengeDistance = [];
    this.totaldistance = 0;
    directions.routes[0].legs.forEach((leg, index) => {
      this.challengeDistance.push(leg);
      this.totaldistance += leg.distance.value;
    });

    this.challengeDistanceOutput = {
      distance: this.totaldistance,
      text: this.totaldistance,
    };

    // have they completed it?
    // console.log(this.mapChallengeDistance);
    // console.log(this.totaldistance);
    if (+this.mapChallengeDistance >= +this.totaldistance) {
      // map challenge complete..

      // have we already sent this?
      if (!this.mapNotified) {
        this.mapNotified = true;
        this.challengeService
          .updateMapProgress(
            this.currentMapProgress.map_challenge_progress_id,
            '1'
          )
          .subscribe((responseData) => {
            Swal.fire({
              title: 'Map Challenge Complete!',
              text:
                'You have completed the map challenge "' +
                this.mapChallenge.title +
                '"',
              icon: 'success',
            });
            // notification!
            this.mapCompleted = 1;

            this.notificationService
              .addNotification(
                this.currentUser.user_id,
                'challenge',
                'You have completed the map challenge "' +
                  this.mapChallenge.title +
                  '". Well done!',
                '/app/challenges',
                0
              )
              .subscribe((notificationResponse) => {});
          });
      }
    }

    // let's try this

    const bounds = new google.maps.LatLngBounds();
    const route = directions.routes[0];

    const startLocation = this.origin;
    const endLocation = this.destination;
    const polyline = new google.maps.Polyline({
      path: [],
      strokeColor: '#FF0000',
      strokeWeight: 3,
    });
    const legs = directions.routes[0].legs;
    //var waypts = [];
    for (var i = 0; i < legs.length; i++) {
      if (i == 0) {
        startLocation.latlng = legs[i].start_location;
        startLocation.address = legs[i].start_address;
        //            startLocation.marker = createMarker(legs[i].start_location,"start",legs[i].start_address,"green");
      } else {
        // waypts[i] = new Object();
        // waypts[i].latlng = legs[i].start_location;
        // waypts[i].address = legs[i].start_address;
        //            waypts[i].marker = createMarker(legs[i].start_location,"waypoint"+i,legs[i].start_address,"yellow");
      }
      endLocation.latlng = legs[i].end_location;
      endLocation.address = legs[i].end_address;
      const steps = legs[i].steps;

      for (var j = 0; j < steps.length; j++) {
        var nextSegment = steps[j].path;
        for (var k = 0; k < nextSegment.length; k++) {
          polyline.getPath().push(nextSegment[k]);
          bounds.extend(nextSegment[k]);
        }
      }
    }

    this.youHere = polyline.GetPointAtDistance(this.mapChallengeDistance);
    this.createMarker(
      polyline.GetPointAtDistance(this.mapChallengeDistance),
      'YOU ARE HERE',
      '',
      '',
      this.map
    );
  }

  createMarker(latlng, label, html, color, map) {
    var contentString = '<b>' + label + '</b><br>' + html;
    var marker = new google.maps.Marker({
      position: latlng,
      // draggable: true,
      map: map,
      //shadow: iconShadow,
      //  icon: {url:'http://activ8yourhealth.localhost/assets/user/5d64ee29ed265.jpg', scaledSize: new google.maps.Size(50, 50)},
      //shape: iconShape,
      title: label,
      zIndex: Math.round(latlng.lat() * -100000) << 5,
    });
    // marker.myname = label;
    // gmarkers.push(marker);

    google.maps.event.addListener(marker, 'click', () => {
      var infoWindow = new google.maps.InfoWindow();
      infoWindow.setContent(contentString);
      infoWindow.open(map, marker);
    });
    return marker;
  }

  addMapExtensions() {
    // === first support methods that don't (yet) exist in v3
    google.maps.LatLng.prototype.distanceFrom = function (newLatLng) {
      var EarthRadiusMeters = 6378137.0; // meters
      var lat1 = this.lat();
      var lon1 = this.lng();
      var lat2 = newLatLng.lat();
      var lon2 = newLatLng.lng();
      var dLat = ((lat2 - lat1) * Math.PI) / 180;
      var dLon = ((lon2 - lon1) * Math.PI) / 180;
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((lat1 * Math.PI) / 180) *
          Math.cos((lat2 * Math.PI) / 180) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = EarthRadiusMeters * c;
      return d;
    };

    google.maps.LatLng.prototype.latRadians = function () {
      return (this.lat() * Math.PI) / 180;
    };

    google.maps.LatLng.prototype.lngRadians = function () {
      return (this.lng() * Math.PI) / 180;
    };

    // === A method for testing if a point is inside a polygon
    // === Returns true if poly contains point
    // === Algorithm shamelessly stolen from http://alienryderflex.com/polygon/
    google.maps.Polygon.prototype.Contains = function (point) {
      var j = 0;
      var oddNodes = false;
      var x = point.lng();
      var y = point.lat();
      for (var i = 0; i < this.getPath().getLength(); i++) {
        j++;
        if (j == this.getPath().getLength()) {
          j = 0;
        }
        if (
          (this.getPath().getAt(i).lat() < y &&
            this.getPath().getAt(j).lat() >= y) ||
          (this.getPath().getAt(j).lat() < y &&
            this.getPath().getAt(i).lat() >= y)
        ) {
          if (
            this.getPath().getAt(i).lng() +
              ((y - this.getPath().getAt(i).lat()) /
                (this.getPath().getAt(j).lat() -
                  this.getPath().getAt(i).lat())) *
                (this.getPath().getAt(j).lng() -
                  this.getPath().getAt(i).lng()) <
            x
          ) {
            oddNodes = !oddNodes;
          }
        }
      }
      return oddNodes;
    };

    // === A method which returns the approximate area of a non-intersecting polygon in square metres ===
    // === It doesn't fully account for spherical geometry, so will be inaccurate for large polygons ===
    // === The polygon must not intersect itself ===
    google.maps.Polygon.prototype.Area = function () {
      var a = 0;
      var j = 0;
      var b = this.Bounds();
      var x0 = b.getSouthWest().lng();
      var y0 = b.getSouthWest().lat();
      for (var i = 0; i < this.getPath().getLength(); i++) {
        j++;
        if (j == this.getPath().getLength()) {
          j = 0;
        }
        var x1 = this.getPath()
          .getAt(i)
          .distanceFrom(
            new google.maps.LatLng(this.getPath().getAt(i).lat(), x0)
          );
        var x2 = this.getPath()
          .getAt(j)
          .distanceFrom(
            new google.maps.LatLng(this.getPath().getAt(j).lat(), x0)
          );
        var y1 = this.getPath()
          .getAt(i)
          .distanceFrom(
            new google.maps.LatLng(y0, this.getPath().getAt(i).lng())
          );
        var y2 = this.getPath()
          .getAt(j)
          .distanceFrom(
            new google.maps.LatLng(y0, this.getPath().getAt(j).lng())
          );
        a += x1 * y2 - x2 * y1;
      }
      return Math.abs(a * 0.5);
    };

    // === A method which returns the length of a path in metres ===
    google.maps.Polygon.prototype.Distance = function () {
      var dist = 0;
      for (var i = 1; i < this.getPath().getLength(); i++) {
        dist += this.getPath()
          .getAt(i)
          .distanceFrom(this.getPath().getAt(i - 1));
      }
      return dist;
    };

    // === A method which returns the bounds as a GLatLngBounds ===
    google.maps.Polygon.prototype.Bounds = function () {
      var bounds = new google.maps.LatLngBounds();
      for (var i = 0; i < this.getPath().getLength(); i++) {
        bounds.extend(this.getPath().getAt(i));
      }
      return bounds;
    };

    // === A method which returns a GLatLng of a point a given distance along the path ===
    // === Returns null if the path is shorter than the specified distance ===
    google.maps.Polygon.prototype.GetPointAtDistance = function (metres) {
      // some awkward special cases
      if (metres == 0) return this.getPath().getAt(0);
      if (metres < 0) return null;
      if (this.getPath().getLength() < 2) return null;
      var dist = 0;
      var olddist = 0;
      for (var i = 1; i < this.getPath().getLength() && dist < metres; i++) {
        olddist = dist;
        dist += this.getPath()
          .getAt(i)
          .distanceFrom(this.getPath().getAt(i - 1));
      }
      if (dist < metres) {
        return null;
      }
      var p1 = this.getPath().getAt(i - 2);
      var p2 = this.getPath().getAt(i - 1);
      var m = (metres - olddist) / (dist - olddist);
      return new google.maps.LatLng(
        p1.lat() + (p2.lat() - p1.lat()) * m,
        p1.lng() + (p2.lng() - p1.lng()) * m
      );
    };

    // === A method which returns an array of GLatLngs of points a given interval along the path ===
    google.maps.Polygon.prototype.GetPointsAtDistance = function (metres) {
      var next = metres;
      var points = [];
      // some awkward special cases
      if (metres <= 0) return points;
      var dist = 0;
      var olddist = 0;
      for (var i = 1; i < this.getPath().getLength(); i++) {
        olddist = dist;
        dist += this.getPath()
          .getAt(i)
          .distanceFrom(this.getPath().getAt(i - 1));
        while (dist > next) {
          var p1 = this.getPath().getAt(i - 1);
          var p2 = this.getPath().getAt(i);
          var m = (next - olddist) / (dist - olddist);
          points.push(
            new google.maps.LatLng(
              p1.lat() + (p2.lat() - p1.lat()) * m,
              p1.lng() + (p2.lng() - p1.lng()) * m
            )
          );
          next += metres;
        }
      }
      return points;
    };

    // === A method which returns the Vertex number at a given distance along the path ===
    // === Returns null if the path is shorter than the specified distance ===
    google.maps.Polygon.prototype.GetIndexAtDistance = function (metres) {
      // some awkward special cases
      if (metres == 0) return this.getPath().getAt(0);
      if (metres < 0) return null;
      var dist = 0;
      var olddist = 0;
      for (var i = 1; i < this.getPath().getLength() && dist < metres; i++) {
        olddist = dist;
        dist += this.getPath()
          .getAt(i)
          .distanceFrom(this.getPath().getAt(i - 1));
      }
      if (dist < metres) {
        return null;
      }
      return i;
    };

    // === A function which returns the bearing between two vertices in decgrees from 0 to 360===
    // === If v1 is null, it returns the bearing between the first and last vertex ===
    // === If v1 is present but v2 is null, returns the bearing from v1 to the next vertex ===
    // === If either vertex is out of range, returns void ===
    google.maps.Polygon.prototype.Bearing = function (v1, v2) {
      if (v1 == null) {
        v1 = 0;
        v2 = this.getPath().getLength() - 1;
      } else if (v2 == null) {
        v2 = v1 + 1;
      }
      if (
        v1 < 0 ||
        v1 >= this.getPath().getLength() ||
        v2 < 0 ||
        v2 >= this.getPath().getLength()
      ) {
        return;
      }
      var from = this.getPath().getAt(v1);
      var to = this.getPath().getAt(v2);
      if (from.equals(to)) {
        return 0;
      }
      var lat1 = from.latRadians();
      var lon1 = from.lngRadians();
      var lat2 = to.latRadians();
      var lon2 = to.lngRadians();
      var angle = -Math.atan2(
        Math.sin(lon1 - lon2) * Math.cos(lat2),
        Math.cos(lat1) * Math.sin(lat2) -
          Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon1 - lon2)
      );
      if (angle < 0.0) angle += Math.PI * 2.0;
      angle = (angle * 180.0) / Math.PI;
      return parseFloat(angle.toFixed(1));
    };

    // === Copy all the above functions to GPolyline ===
    google.maps.Polyline.prototype.Contains =
      google.maps.Polygon.prototype.Contains;
    google.maps.Polyline.prototype.Area = google.maps.Polygon.prototype.Area;
    google.maps.Polyline.prototype.Distance =
      google.maps.Polygon.prototype.Distance;
    google.maps.Polyline.prototype.Bounds =
      google.maps.Polygon.prototype.Bounds;
    google.maps.Polyline.prototype.GetPointAtDistance =
      google.maps.Polygon.prototype.GetPointAtDistance;
    google.maps.Polyline.prototype.GetPointsAtDistance =
      google.maps.Polygon.prototype.GetPointsAtDistance;
    google.maps.Polyline.prototype.GetIndexAtDistance =
      google.maps.Polygon.prototype.GetIndexAtDistance;
    google.maps.Polyline.prototype.Bearing =
      google.maps.Polygon.prototype.Bearing;
  }
}
