<nav class="subnav">
    <a [routerLink]="['/profile']" class="subnav-prev"
      > <span
      ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon
    ></span></a>
    <h2><span
      ><fa-icon [icon]="['fas', 'cog']"></fa-icon
    ></span> SETTINGS</h2>
    <a [routerLink]="['/about']" class="subnav-next"
      > <span
      ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon
    ></span></a>
  </nav>
<br>
<div  [routerLink]="['/settings', 'personal-details']" class="toggle-header toggle-header-left">
    <a class="toggle-nav toggle-nav-expand"><span
        ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
      ></span> </a>
    <div class="toggle-header-title">EDIT MY PERSONAL DETAILS</div>
</div>
<br>
<div [routerLink]="['/settings', 'account-details']" class="toggle-header toggle-header-left">
    <a class="toggle-nav toggle-nav-expand"><span
        ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
      ></span></a>
    <div class="toggle-header-title">EDIT MY ACCOUNT DETAILS</div>
</div>