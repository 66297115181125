<form
    class="bs-form reg-form"
    name="regForm"
    method="post"
    (ngSubmit)="onSubmit()"
    #regForm="ngForm"
  >
<div [hidden]="step != 1">

  <div
    class="reading-material-page-header booster-session-step-header register-header text-left"
  >
    Details
  </div>
  <div class="container">
    <div class="form-group">
      <label for="gu1de_reason"
        >WHAT IS YOUR REASON FOR ENROLLING ON THE GU1DE PROGRAMME?
        <span class="required">*</span></label
      >
      <select
        name="gu1de_reason"
        class="form-control"
        #gu1de_reason="ngModel"
        id="gu1de_reason"
        ngModel
        required
      >
        <option value="">Please Select</option>
        <option value="live">I live with type 1 diabetes</option>
        <option value="learn">
          I don't have type 1 but I want to learn more
        </option>
        <option value="hcp">I am a health professional</option>
        <option value="parent">
          I am a parent of someone with type 1 diabetes
        </option>
        <option value="carer">I care for someone with type 1 diabetes</option>
      </select>
      <div
        class="alert alert-danger"
        *ngIf="
          (gu1de_reason.touched && gu1de_reason.invalid) ||
          (stepSubmitted[3] && gu1de_reason.invalid)
        "
      >
        There is an error with this.
      </div>
    </div>

    <div class="form-group">
      <label for="attendedBefore">
        <span
          >Have you ever participated in a structured education programme e.g
          DAFNE, BERTI?</span
        >
        <span class="required">*</span></label
      >
      <input
        type="hidden"
        name="attendedBefore"
        id="attendedBefore"
        [(ngModel)]="regDetails.attendedBefore"
        #attendedBefore="ngModel"
        required
      />
      <div class="row">
        <div class="col-6">
          <button
            type="button"
            class="btn-block btn btn-default"
            [ngClass]="{
              'btn-primary': regDetails.attendedBefore === 1,
              'btn-default': regDetails.attendedBefore === 0
            }"
            (click)="regDetails.attendedBefore = 1"
          >
            YES
          </button>
        </div>
        <div class="col-6">
          <button
            type="button"
            class="btn-block btn btn-default"
            [ngClass]="{
              'btn-primary': regDetails.attendedBefore === 0,
              'btn-default': regDetails.attendedBefore === 1
            }"
            (click)="regDetails.attendedBefore = 0"
          >
            NO
          </button>
        </div>
      </div>

      <div
        class="alert alert-danger"
        *ngIf="stepSubmitted[3] && regDetails.attendedBefore == null"
      >
        Please select either Yes or No.
      </div>
    </div>

    <div class="form-group">
      <label for="since_participated"
        >If Yes: How long ago did you participate?</label
      >
      <select
        name="since_participated"
        class="form-control"
        #since_participated="ngModel"
        id="since_participated"
        ngModel
      >
        <option value="">Please Select</option>
        <option value="<1">Less than 1 year</option>
        <option value="<5">less than 5 years</option>
        <option value="<10">less than 10 Years</option>
        <option value=">10">More than 10 years</option>
      </select>
      <div
        class="alert alert-danger"
        *ngIf="
          (since_participated.touched && since_participated.invalid) ||
          (stepSubmitted[3] && since_participated.invalid)
        "
      >
        There is an error with this.
      </div>
    </div>

    <div class="form-group">
      <label for="since_diagnosed"
        >How long has it been since your diagnosis of diabetes?</label
      >
      <select
        name="since_diagnosed"
        class="form-control"
        #since_diagnosed="ngModel"
        id="since_diagnosed"
        ngModel
        required
      >
        <option value="">Please Select</option>
        <option value="N/A">N/A</option>
        <option value="<1">Less than 12 months</option>
        <option value="1">1 year</option>
        <option value="2">2 years</option>
        <option value="3">3 years</option>
        <option value="4">4 years</option>
        <option value="5">5 years</option>
        <option value="6">6 years</option>
        <option value="7">7 years</option>
        <option value="8">8 years</option>
        <option value="9">9 years</option>
        <option value="10">10 years</option>
        <option value="11">11 years</option>
        <option value="12">12 years</option>
        <option value="13">13 years</option>
        <option value="14">14 years</option>
        <option value="15">15 years</option>
        <option value="16">16 years</option>
        <option value="17">17 years</option>
        <option value="18">18 years</option>
        <option value="19">19 years</option>
        <option value="20">20 years</option>
        <option value="21+">21 years or more</option>
      </select>
      <div
        class="alert alert-danger"
        *ngIf="
          (since_diagnosed.touched && since_diagnosed.invalid) ||
          (stepSubmitted[3] && since_diagnosed.invalid)
        "
      >
        There is an error with this.
      </div>
    </div>

    <div class="form-group">
      <label for="hospital_care"
        >Do you currently go to your local hospital for your diabetes
        care?</label
      >
      <input
        type="hidden"
        name="hospital_care"
        id="hospital_care"
        [(ngModel)]="regDetails.hospital_care"
        #hospital_care="ngModel"
      />
      <div class="row">
        <div class="col-6">
          <button
            type="button"
            class="btn-block btn btn-default"
            [ngClass]="{
              'btn-primary': regDetails.hospital_care === 1,
              'btn-default':
                regDetails.hospital_care === 0 ||
                regDetails.hospital_care === null
            }"
            (click)="regDetails.hospital_care = 1"
          >
            YES
          </button>
        </div>
        <div class="col-6">
          <button
            type="button"
            class="btn-block btn btn-default"
            [ngClass]="{
              'btn-primary': regDetails.hospital_care === 0,
              'btn-default':
                regDetails.hospital_care === 1 ||
                regDetails.hospital_care === null
            }"
            (click)="regDetails.hospital_care = 0"
          >
            NO
          </button>
        </div>
      </div>

      <div
        class="alert alert-danger"
        *ngIf="stepSubmitted[3] && regDetails.hospital_care == null"
      >
        Please select your smoking status.
      </div>
    </div>

    <p class="required-msg">
      <span class="required">*</span> indicates required field
    </p>
    <hr class="divider" />
    <div class="container">
      <div class="row">
        <div class="col-6"></div>
        <div class="col-6 text-right">
          <a (click)="checkReg(1)" class="btn btn-primary btn-white"
            >NEXT
            <span class="icon-wrap"
              ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span
          ></a>
        </div>
      </div>
    </div>
  </div>
</div>
<div [hidden]="step !== 2">
    <div
      class="reading-material-page-header booster-session-step-header register-header text-left"
    >
      Completed
    </div>
    <div class="container">
      <p>Congratulations!</p>
      
          <span
            >You are now setup to use GU1DE.</span
          >
        
    <hr class="divider" />
    <div class="container">
      <div class="row">
        <div class="col-6"></div>
        <div class="col-6 text-right">
          <a (click)="complete()" class="btn btn-primary btn-white"
            >NEXT
            <span class="icon-wrap"
              ><fa-icon
                [icon]="['far', 'arrow-circle-right']"
              ></fa-icon></span
          ></a>
        </div>
      </div>
    </div>
  </div>
</div>
</form>