import { NotificationService } from './../services/notification.service';
import { environment } from './../../environments/environment';
import { config } from '@fortawesome/fontawesome-svg-core';
import { BoosterSessionsService } from './../booster-sessions/booster-sessions.service';
import { UserService } from './../services/user.service';
import { UserSettingsService } from './../services/user-settings.service';
import { ProgrammeService } from './../services/programme.service';
import { AuthService } from './../auth/auth.service';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { forkJoin, interval } from 'rxjs';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TargetService } from '../services/target.service';
import { CacheManagerService } from '../services/cache-manager.service';

@Component({
  selector: 'app-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss']
})
export class MobileNavComponent implements OnInit, OnDestroy {

  @ViewChild('searchForm', { static: false }) searchForm: NgForm;
  currentUser;
  currentProgramme;
  isFetching = false;
  programmeContent;
  errors = [];
  learningSessionTotals;
  learningSessionProgressTotal;
  boosterSessionTotals;
  boosterSessionProgressTotal;
  sToday;
  currentProgrammes;
  currentTeam;
  environment = environment;
  notifications = [];
  searchQuery  = '';
  userSessions = [];
  notificationsInterval;
  stepTarget;

  menuToggle = {
    booster: false,
    learning: false,
    steps: false,
    chat: false,
    trackers: false,
    help: false,
    education: false,
    programmes: false,
    user: false,
    symptoms: false
  };


  symptoms = [
    { name: 'itching', slug: 'itching', iconWeight: 'far', icon: 'allergies' },
    {
      name: 'Sleep disturbance',
      slug: 'sleep-disturbance',
      iconWeight: 'far',
      icon: 'alarm-clock',
    },
    {
      name: 'Loss of appetite',
      slug: 'loss-of-appetite',
      iconWeight: 'far',
      icon: 'stomach',
    },
    {
      name: 'Feeling tired',
      slug: 'feeling-tired',
      iconWeight: 'far',
      icon: 'snooze',
    },
    {
      name: 'Pain in bones/joints',
      slug: 'pain-in-bones-joints',
      iconWeight: 'far',
      icon: 'bone',
    },
    {
      name: 'Poor concentration',
      slug: 'poor-concentration',
      iconWeight: 'far',
      icon: 'head-side-brain',
    },
    {
      name: 'Loss of libido',
      slug: 'loss-of-libido',
      iconWeight: 'far',
      icon: 'bed-alt',
    },
    {
      name: 'Loss of muscle strength',
      slug: 'loss-of-muscle-strength',
      iconWeight: 'far',
      icon: 'fist-raised',
    },
    {
      name: 'Shortness of breath',
      slug: 'shortness-of-breath',
      iconWeight: 'far',
      icon: 'lungs',
    },
    {
      name: 'Cramp/Muscle stiffness',
      slug: 'cramp-muscle-stiffness',
      iconWeight: 'far',
      icon: 'tired',
    },
    {
      name: 'Restless legs',
      slug: 'restless-legs',
      iconWeight: 'far',
      icon: 'socks',
    },
    {
      name: 'Need to urinate more often',
      slug: 'need-to-urinate-more-often',
      iconWeight: 'far',
      icon: 'toilet',
    },
    {
      name: 'Feeling cold',
      slug: 'feeling-cold',
      iconWeight: 'far',
      icon: 'temperature-frigid',
    },
  ];


  @Output() closeMobileMenu: EventEmitter<any> = new EventEmitter();
  @Output() showUserMenu: EventEmitter<any> = new EventEmitter();

  constructor(
    private authService: AuthService,
    private programmeService: ProgrammeService,
    private userService: UserService,
    private boosterSessionService: BoosterSessionsService,
    private notificationService: NotificationService,
    private router: Router,
    private targetService:TargetService,
    private cacheManagerService:CacheManagerService
  ) {}

  ngOnInit(): void {
    // console.log(this.environment);
    this.isFetching = true;
    this.sToday = moment().format('YYYY-MM-DD');
    // are we logged in?
    this.authService.user.subscribe((user) => {
      if (user) {
        this.programmeService.currentProgramme.subscribe((programmeData) => {
          this.currentProgramme = programmeData;
          if (this.currentProgramme) {
            forkJoin({
              sessionProgress: this.boosterSessionService.getSessionsProgressData(
                this.currentProgramme.programme_id
              ),
              notifications: this.notificationService.getNotifications(0),
              userSessions: this.boosterSessionService.fetchExtraSessions(
                this.currentProgramme.programme_id
              ),
              stepTarget: this.targetService.getTarget('step')
            }).subscribe(
              (responseData) => {
                this.isFetching = false;
                this.notifications = responseData.notifications;

                const sessionProgress = this.boosterSessionService.doSessions(
                  responseData.sessionProgress,
                  user.created
                );

                this.userSessions = [];
                responseData.userSessions.forEach((us) => {
                  this.userSessions.push(us.session_id);
                });
                
                // console.log(sessionProgress);
                this.learningSessionTotals =
                  sessionProgress.learningSessionTotals;
                this.learningSessionProgressTotal =
                  sessionProgress.learningSessionProgressTotal;
                this.boosterSessionTotals =
                  sessionProgress.boosterSessionTotals;
                this.boosterSessionProgressTotal =
                  sessionProgress.boosterSessionProgressTotal;
                this.programmeContent = sessionProgress.programmeContent;

                this.stepTarget = responseData.stepTarget;
              },
              (error) => {
                this.isFetching = false;
                console.log(error);
                this.errors.push(error.message);
              }
            );

            // check for notifications
            this.notificationsInterval = interval(60000).subscribe((count) => {
              this.notificationService
                .getNotifications(0)
                .subscribe((responseData) => {
                  this.notifications = responseData;
                });
            });
            this.notificationService.updateNotifications.subscribe((data) => {
              if (data) {
                this.notificationService
                  .getNotifications(0)
                  .subscribe((responseData) => {
                    this.notifications = responseData;
                  });
              }
            });

            this.boosterSessionService.updateExtraSessions.subscribe((data) => {
              if (data) {
                this.boosterSessionService
                  .fetchExtraSessions(this.currentProgramme.programme_id)
                  .subscribe((responseData) => {
                    this.userSessions = [];
                    responseData.forEach((us) => {
                      this.userSessions.push(us.session_id);
                    });
                  });
              }
            });
          }
        });
        this.userService.userData.subscribe((userDataResponse) => {
          this.currentUser = userDataResponse;
        });
      }
    });
  }

  onToggleMenu(event, area) {
    event.preventDefault();
    this.menuToggle[area] = !this.menuToggle[area];
  }
  onLogout(): void {
    this.cacheManagerService.clearAllCache();
    this.authService.logout();
  }

  onSubmitSearch(){
    // this.router.navigate
    this.router.navigate(['/search'], { queryParams: {q: this.searchForm.value.query}});
  }


  onCloseMobileMenu(event) {
    this.closeMobileMenu.emit(true);
  }
  onShowUserMenu(event) {
    this.showUserMenu.emit(true);
  }
  ngOnDestroy(): void {
    this.notificationsInterval.unsubscribe();
  }


  checkSessionsAvailable(programmeSession, userSessions){
    let sessionAvailable = false;

    programmeSession.forEach((session) => {
      if(userSessions.indexOf(session.booster_session_id) === -1){
        sessionAvailable = true;
      }
    });

    return sessionAvailable;
  }
}

