import { NgForm } from '@angular/forms';
import { DateHelperService } from './../services/date-helper.service';
import { TargetService } from './../services/target.service';
import { ActivityService } from './../services/activity.service';
import { UserSettingsService } from './../services/user-settings.service';
import { ProgrammeService } from './../services/programme.service';
import { UserService } from './../services/user.service';
import { AuthService } from './../auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from './../../environments/environment';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { Color, Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sitting',
  templateUrl: './sitting.component.html',
  styleUrls: ['./sitting.component.scss']
})
export class SittingComponent implements OnInit {
  @ViewChild('minsForm', { static: false }) minsForm: NgForm;
  currentUser;
  currentProgramme;
  deviceSynced = 'unknown';
  isFetching = false;
  errors = [];
  userSettings;

  currentActivity;
  currentTarget;
  currentTargetMins;
  environment = environment;
  currentMins = 0;
  currentHours = 0;
  daysHit = 0;
  now;
  currentWeek;
  previousWeek = {
    startDate: null,
    endDate: null,
  };
  nextWeek = {
    startDate: null,
    endDate: null,
  };
  weekTotal = 0;
  weekData = [];

  toggleRow = {
    update: false,
    graph: false,
    target: false,
  };

  showMonth = true;
  date = new Date();

  targetHours = 0;
  targetMins = 0;
  startDate;
  endDate;

  suggestedTarget;
  algorithmReady = 0;
  algorithmDaysRemaining = 3;

  algorithmComplete = false;
  algorithmDays = [
    { day: '', status: 0 },
    { day: '', status: 0 },
    { day: '', status: 0 },
  ];


  // chart

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: '',
            fontColor: '#333',
          },
          gridLines: {
            color: '#555',
            drawOnChartArea: false,
          },
          ticks: {
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: '#555',
            drawOnChartArea: false,
          },
          scaleLabel: {
            display: true,
            labelString: '',
            fontColor: '#333',
          },
          ticks: {
            min: 0,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [
    { data: [], label: 'Time', maxBarThickness: 50 },
  ];
  public barChartColors: Color[] = [
    { backgroundColor: this.environment.primaryColor },
  ];
  //

  // Line chart
  public lineChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            beginAtZero: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          gridLines: {
            display: true,
            lineWidth: 1,
            color: '#95989A',
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            lineWidth: 2,
            color: '#95989A',
          },
          type: 'time',
          ticks: {
            autoSkip: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          time: {
            parser: 'YYYY-MM-DD HH:mm:ss',
            unit: 'day',
            displayFormats: {
              day: 'MMM-DD',
            },
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public lineChartLabels: Label[] = [];
  public lineChartType: ChartType = 'line';
  public lineChartLegend = true;

  public lineChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'Monthly',
      borderWidth: 1,
      borderColor: environment.chartDefaults.colors[0],
      fill: false,
      pointRadius: 5,
    },
  ];
  public lineChartColors: Color[] = [
    { backgroundColor: this.environment.primaryColor },
  ];

  // All time chart..
  public allTimeChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            beginAtZero: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          gridLines: {
            display: true,
            lineWidth: 1,
            color: '#95989A',
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            lineWidth: 2,
            color: '#95989A',
          },
          type: 'time',
          ticks: {
            autoSkip: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          time: {
            parser: 'YYYY-MM-DD HH:mm:ss',
            unit: 'day',
            displayFormats: {
              day: 'MMM-DD',
            },
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public allTimeChartLabels: Label[] = [];
  public allTimeChartType: ChartType = 'line';
  public allTimeChartLegend = true;

  public allTimeChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'All Time',
      borderWidth: 1,
      borderColor: environment.chartDefaults.colors[0],
      fill: false,
      pointRadius: 2,
    },
  ];
  public allTimeChartColors: Color[] = [
    { backgroundColor: this.environment.primaryColor },
  ];

  twoWeekData;
  currentTargetObj;
  reviewTarget = false;
  stepTarget = false;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private userSettingsService: UserSettingsService,
    private activityService: ActivityService,
    private targetService: TargetService,
    private dateHelperService: DateHelperService,
    private router: Router
  ) {}

  ngOnInit(): void {

    this.isFetching = true;
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (this.currentProgramme) {
          this.barChartColors[0].backgroundColor = this.currentProgramme.config.primaryColor;
        }
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });

      const now = moment();
      this.currentWeek = this.dateHelperService.getWeekFor(now);
      this.now = moment(now).format('YYYY-MM-DD');
      const startDate = moment(this.currentWeek[0]).format('YYYY-MM-DD');
      const endDate = moment(this.currentWeek[6]).format('YYYY-MM-DD');

      this.previousWeek.startDate = moment(this.currentWeek[0])
        .subtract(1, 'week')
        .format('YYYY-MM-DD');
      this.previousWeek.endDate = moment(this.currentWeek[6])
        .subtract(1, 'week')
        .format('YYYY-MM-DD');

      // get months data
      forkJoin({
        userSettings: this.userSettingsService.fetchAll(),
        activity: this.activityService.getActivity(0),
        target: this.targetService.getTarget('sitting'),
        stepTarget: this.targetService.getTarget('step'),
        activityBetween: this.activityService.getActivityBetween(
          startDate,
          endDate
        ),
        monthData: this.activityService.getActivityBetween(
          moment().subtract(1, 'months').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ),
        allTimeData: this.activityService.getActivityBetween(
          moment().subtract(10, 'years').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ),
        twoWeekData:  this.activityService.getActivityBetween(
          moment().subtract(15, 'day').format('YYYY-MM-DD'),
          moment().subtract(1, 'day').format('YYYY-MM-DD'),
        ),
      }).subscribe(
        (responseData) => {
          this.isFetching = false;
          this.userSettings = responseData.userSettings;
          this.currentActivity = responseData.activity;
          if (this.currentActivity) {

            let hours = Math.floor(this.currentActivity.sedentary_minutes / 60);
            let minutes = Math.floor(this.currentActivity.sedentary_minutes % 60);
            this.currentMins = minutes;
            this.currentHours = hours;
          }

          if (responseData.target) {
            this.currentTargetObj =  responseData.target;
            this.currentTarget = responseData.target.target;
            this.targetHours = this.currentTarget;
          }
          else{

            // no target set so do we have a suggested one?

            this.stepTarget = responseData.stepTarget;
            this.generateAlgorithmDays();

            /*
            if(this.currentUser.manual_steps){
              if(this.currentUser.sitting_weekday){
                this.suggestedTarget = (+this.currentUser.sitting_weekday * 60) - 30;
              }
              else{
                this.suggestedTarget = 7*60;
              }
            }*/
          }

          const weeklyActivities = responseData.activityBetween;
          weeklyActivities.forEach((activity) => {
           // this.weekTotal = Number(this.weekTotal) + Number(activity.minutes);
          });

          // week data
          this.currentWeek.forEach((item, index) => {
            const thisActivity = weeklyActivities.filter(this.search, {
              activity_date: moment(item).format('YYYY-MM-DD'),
            })[0];

            if (thisActivity) {
              this.weekTotal += +thisActivity.sedentary_minutes;
              this.weekData.push(thisActivity);
              if(+thisActivity.sedentary_minutes < +this.currentTarget && +thisActivity.sedentary_minutes>0){
                this.daysHit++
              }
            } else {
              const dData = {
                activity_date: moment(item).format('YYYY-MM-DD'),
              };
              this.weekData.push(dData);
            }
          });

          this.setChartData(this.weekData);
          this.setMonthChartData(responseData.monthData);
          this.setAllTimeChartData(responseData.allTimeData);


          // review target time?
          if( moment(this.currentTargetObj?.created).format('YYYY-MM-DD') <  moment().subtract(14, 'days').format('YYYY-MM-DD')){
          this.twoWeekData = responseData.twoWeekData;
          let twoWeekHits = 0;
          let weekCount = 0;
          let weekTotal = 0;
          this.twoWeekData.forEach((activityData) => {
            weekCount++;
            if(+activityData.sedentary_minutes < +this.currentTarget){
              twoWeekHits++;
            }
            if(weekCount <8){
              weekTotal +=+activityData.sedentary_minutes;
            }

          });

            if(twoWeekHits > 13){
              this.reviewTarget = true;
              let newTarget = (weekTotal/7) - 15;

              let minTarget = 540;
              if(this.currentUser.device == 'manual'){
                minTarget = 420;
              }

              if(newTarget < minTarget){
                newTarget = minTarget;
              }
              this.suggestedTarget = newTarget;
            }
          }
          // end
        },
        (error) => {
          this.isFetching = false;
          this.errors.push(error.message);
        }
      );
    }
  }

  search(obj) {
    return Object.keys(this).every((key) => obj[key] === this[key]);
  }

  setChartData(activity) {
    this.barChartData[0].data = [];

    if (moment(activity[0].activity_date).format('ddd') !== 'Monday') {
      let startDate = moment(this.currentWeek[0]).format('YYYY-MM-DD');

      while (
        moment(startDate).format('YYYY-MM-DD') !==
        moment(activity[0].activity_date).format('YYYY-MM-DD')
      ) {
        this.barChartData[0].data.push(0);
        startDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD');
      }
    }

    this.barChartLabels = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
    let maxMins = 0;
    activity.forEach((value, key) => {
      if (+value.sedentary_minutes > +maxMins) {
        maxMins = +value.sedentary_minutes;
      }
      this.barChartData[0].data.push(value.sedentary_minutes);

      // have they hit target?
      //if(+(value.steps)>=+($scope.currentTarget)){
      //$scope.datasetOverride[0].backgroundColor[key] = '#FDCE06';

      // }
    });

    // this.barChartOptions.scales.yAxes[0].ticks.max = 10000;
    /*

    if(parseInt(maxSteps) < parseInt($scope.currentTarget)){
                        $scope.options.scales.yAxes[0].ticks.max =  parseInt($scope.currentTarget);
                    }
                    else if(maxSteps){
                        $scope.options.scales.yAxes[0].ticks.max = Math.ceil(parseInt(maxSteps) / 1000.0) * 1000;
                    }
                    else{
                        $scope.options.scales.yAxes[0].ticks.max = 10000;
                    }
                    //$scope.options.scales.yAxes[0].ticks.max = +maxSteps + 100;
                       
                       if($scope.currentTarget){
             $scope.options.lineAt = $scope.currentTarget; 
                       }
                       */

    // reverse them
    // this.barChartData[0].data.reverse();
    // this.barChartLabels.reverse();
  }

  setMonthChartData(activities) {
    this.lineChartData[0].data = [];
    // setup chart data
    activities.forEach((value, key) => {
      this.lineChartLabels.push(
        moment(value.activity_date).format('YYYY-MM-DD')
      );
      this.lineChartData[0].data.push(value.sedentary_minutes);
    });
  }

  setAllTimeChartData(activities) {
    this.allTimeChartData[0].data = [];
    // setup chart data
    activities.forEach((value, key) => {
      this.allTimeChartLabels.push(
        moment(value.activity_date).format('YYYY-MM-DD')
      );
      this.allTimeChartData[0].data.push(value.sedentary_minutes);
    });
  }

  onSubmitForm() {
    // save the steps

    const totalMins = +this.minsForm.value.hours*60 + +this.minsForm.value.mins;
    this.activityService
      .saveSitting(totalMins, this.minsForm.value.date)
      .subscribe((responseData) => {
        const startDate = moment(this.currentWeek[0]).format('YYYY-MM-DD');
        const endDate = moment(this.currentWeek[6]).format('YYYY-MM-DD');

        forkJoin({
          activity: this.activityService.getActivity(),
          activityBetween: this.activityService.getActivityBetween(
            startDate,
            endDate
          ),
        }).subscribe(
          (forkResponseData) => {
            this.currentActivity = forkResponseData.activity;
            if (this.currentActivity) {
              this.currentMins = this.currentActivity.minutes;
            }
            const weeklyActivities = forkResponseData.activityBetween;
            weeklyActivities.forEach((activity) => {
              this.weekTotal =
                Number(this.weekTotal) + Number(activity.minutes);
            });
            // week data
            this.weekData = [];
            this.currentWeek.forEach((item, index) => {
              const thisActivity = weeklyActivities.filter(this.search, {
                activity_date: moment(item).format('YYYY-MM-DD'),
              })[0];

              if (thisActivity) {
                if (
                  thisActivity.hit_goal !== 1 &&
                  +thisActivity.percent_hit >= 100
                ) {
                  thisActivity.hit_goal = 1;
                }
                this.weekData.push(thisActivity);
              } else {
                const dData = {
                  activity_date: moment(item).format('YYYY-MM-DD'),
                };
                this.weekData.push(dData);
              }
            });
            this.setChartData(this.weekData);
            // refresh the route..
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => this.router.navigate(['/activity/sitting']));
          },
          (error) => {
            console.log(error);
          }
        );

        Swal.fire({
          icon: 'success',
          title: 'Sitting Tume Updated',
          text: 'Your sitting time was successfully updated',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      });
  }

  syncSteps(force) {
    force = typeof force === 'undefined' ? '0' : force;
    this.activityService.getActivity(force).subscribe((responseData) => {
      this.currentActivity = responseData;
      if (this.currentActivity) {
        this.currentMins = this.currentActivity.minutes;
      }

      this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigate(['/activity', 'minutes']));
    });
  }

  next() {
    const startDate = moment(this.currentWeek[0])
      .add(1, 'week')
      .format('YYYY-MM-DD');
    const endDate = moment(this.currentWeek[6])
      .add(1, 'week')
      .format('YYYY-MM-DD');
    this.startDate = startDate;
    this.endDate = endDate;
    this.currentWeek = this.dateHelperService.getWeekFor(startDate);

    this.previousWeek.startDate = moment(startDate)
      .subtract(1, 'week')
      .format('YYYY-MM-DD');
    this.previousWeek.endDate = moment(endDate)
      .subtract(1, 'week')
      .format('YYYY-MM-DD');

    this.nextWeek.startDate = moment(startDate)
      .add(1, 'week')
      .format('YYYY-MM-DD');
    this.nextWeek.endDate = moment(endDate).add(1, 'week').format('YYYY-MM-DD');

    if (moment() < moment(this.nextWeek.startDate)) {
      this.nextWeek.startDate = null;
      this.nextWeek.endDate = null;
    }

    this.isFetching = true;
    this.weekTotal = 0;
    // get months data
    forkJoin({
      activityBetween: this.activityService.getActivityBetween(
        startDate,
        endDate
      ),
    }).subscribe(
      (responseData) => {
        this.isFetching = false;

        const weeklyActivities = responseData.activityBetween;
        weeklyActivities.forEach((activity) => {
          this.weekTotal = Number(this.weekTotal) + Number(activity.minutes);
        });
        this.weekData = [];

        // week data
        this.currentWeek.forEach((item, index) => {
          const thisActivity = weeklyActivities.filter(this.search, {
            activity_date: moment(item).format('YYYY-MM-DD'),
          })[0];

          if (thisActivity) {
            if (
              thisActivity.hit_goal !== 1 &&
              +thisActivity.percent_hit >= 100
            ) {
              thisActivity.hit_goal = 1;
            }
            this.weekData.push(thisActivity);
          } else {
            const dData = {
              activity_date: moment(item).format('YYYY-MM-DD'),
            };
            this.weekData.push(dData);
          }
        });

        this.setChartData(this.weekData);

        // end
      },
      (error) => {
        this.isFetching = false;
        this.errors.push(error.message);
      }
    );
  }
  previous() {
    const startDate = moment(this.currentWeek[0])
      .subtract(1, 'week')
      .format('YYYY-MM-DD');
    const endDate = moment(this.currentWeek[6])
      .subtract(1, 'week')
      .format('YYYY-MM-DD');

    this.startDate = startDate;
    this.endDate = endDate;
    this.currentWeek = this.dateHelperService.getWeekFor(startDate);

    this.previousWeek.startDate = moment(startDate)
      .subtract(1, 'week')
      .format('YYYY-MM-DD');
    this.previousWeek.endDate = moment(endDate)
      .subtract(1, 'week')
      .format('YYYY-MM-DD');

    this.nextWeek.startDate = moment(startDate)
      .add(1, 'week')
      .format('YYYY-MM-DD');
    this.nextWeek.endDate = moment(endDate).add(1, 'week').format('YYYY-MM-DD');

    this.isFetching = true;
    this.weekTotal = 0;
    // get months data
    forkJoin({
      activityBetween: this.activityService.getActivityBetween(
        startDate,
        endDate
      ),
    }).subscribe(
      (responseData) => {
        this.isFetching = false;

        const weeklyActivities = responseData.activityBetween;
        weeklyActivities.forEach((activity) => {
          this.weekTotal = Number(this.weekTotal) + Number(activity.minutes);
        });
        this.weekData = [];

        // week data
        this.currentWeek.forEach((item, index) => {
          const thisActivity = weeklyActivities.filter(this.search, {
            activity_date: moment(item).format('YYYY-MM-DD'),
          })[0];

          if (thisActivity) {
            if (
              thisActivity.hit_goal !== 1 &&
              +thisActivity.percent_hit >= 100
            ) {
              thisActivity.hit_goal = 1;
            }
            this.weekData.push(thisActivity);
          } else {
            const dData = {
              activity_date: moment(item).format('YYYY-MM-DD'),
            };
            this.weekData.push(dData);
          }
        });

        this.setChartData(this.weekData);

        // end
      },
      (error) => {
        this.isFetching = false;
        this.errors.push(error.message);
      }
    );
  }

  submitTarget(mins) {
    const target = Math.ceil(+mins);
    this.targetService.create(target , 'sitting', 'minutes').subscribe(
      (responseData) => {
        this.currentTarget = target;

        Swal.fire({
          icon: 'success',
          title: 'Target Updated',
          text: 'Your target were successfully updated',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        // reload..
        this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigate(['/activity', 'sitting']));
      },
      (error) => {
        console.log(error);
      }
    );
  }

  checkAlgorithm(user, target){
   
    const now = moment();
        // do it
        const threeDaysAgo = moment().subtract('3', 'days');
        const weekAgo = moment().subtract('7', 'days');
        const created = moment(user.created);
        let startDay = created;
        if (threeDaysAgo > created) {
          startDay = threeDaysAgo;
        }

        this.startDate = moment(startDay).format('YYYY-MM-DD');
        this.endDate = moment(now).subtract(1, 'days').format('YYYY-MM-DD');

        this.isFetching = true;
        forkJoin({
          userSettings: this.userSettingsService.fetchAll(),
          activityBetween: this.activityService.getActivityBetween(
            this.startDate,
            this.endDate
          ),
        }).subscribe(
          (responseData) => {
            let totalMins = 0;
            this.isFetching = false;
            if (this.algorithmReady == 0 && !target) {
              // count the days
              let returnDays = [];
              this.algorithmDaysRemaining = 3;
              for (let i = 0; i < 4; i++) {
                let dayStatus = 0;

                responseData.activityBetween.forEach((activity) => {
                  if (
                    activity.activity_date ==
                      moment(startDay).add(i, 'days').format('YYYY-MM-DD') &&
                    activity.sedentary_minutes > 0
                    && moment(startDay).add(i, 'days').format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
                  ) {
                    
                    this.algorithmDaysRemaining--;
                    totalMins+= activity.sedentary_minutes;
                  }
                });
                
              }
              if (this.algorithmDaysRemaining < 1) {
                this.algorithmReady = 1;
              }
            }

            /*if (
              moment(target.created) < weekAgo ||
              this.algorithmReady == 1
            )
              this.suggestedTarget = Math.ceil(+totalMins/3 - 15 );
            */
          },
          (error) => {
            this.isFetching = false;
            this.errors.push(error.message);
          }
        );
  }

  generateAlgorithmDays() {
    // get 4 days ago
    const threeDaysAgo = moment().subtract('3', 'days');
    const created = moment(this.currentUser.created);
    let startDay = created.add('1', 'day');
    if (threeDaysAgo > created) {
      startDay = threeDaysAgo;
    }

    this.algorithmDaysRemaining = 3;
    this.activityService
      .getActivityBetween(
        startDay.format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      )
      .subscribe((responseData) => {
        const userActivity = responseData;
        // do the loop
        let returnDays = [];
        for (let i = 0; i < 3; i++) {
          let dayStatus = 0;

          userActivity.forEach((activity) => {
            if (
              activity.activity_date ==
                moment(startDay).add(i, 'days').format('YYYY-MM-DD') &&
              +activity.sedentary_minutes > 0
              && moment(startDay).add(i, 'days').format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
            ) {
              dayStatus = 1;
              this.algorithmDaysRemaining--;
            }
          });
          returnDays.push({
            day: moment(startDay).add(i, 'days'),
            status: dayStatus,
          });
        }
        this.algorithmDays = returnDays;
      },
      (error)=>{
        console.log(error);
      });
  }
  generateTarget(){
    this.algorithmDaysRemaining = 3;
    // get 4 days ago
    const threeDaysAgo = moment().subtract('3', 'days');
    const created = moment(this.currentUser.created);
    let startDay = created;
    if (threeDaysAgo > created) {
      startDay = threeDaysAgo;
    }

    this.activityService
      .getActivityBetween(
        startDay.format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      )
      .subscribe((responseData) => {
        const userActivity = responseData;
        // do the loop
        let returnDays = [];
        let totalSitting = 0;
        for (let i = 0; i < 3; i++) {
          let dayStatus = 0;

          userActivity.forEach((activity) => {
            if (
              activity.activity_date ==
                moment(startDay).add(i, 'days').format('YYYY-MM-DD') &&
              activity.sedentary_minutes > 0
              && moment(startDay).add(i, 'days').format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
            ) {
              dayStatus = 1;
              this.algorithmDaysRemaining--;
              totalSitting+= +activity.sedentary_minutes;
            }
          });
          returnDays.push({
            day: moment(startDay).add(i, 'days'),
            status: dayStatus,
          });
        }


        this.algorithmDays = returnDays;
        
        if(!totalSitting){
          // fall back to reg data?
          totalSitting = +this.currentUser.sitting_weekday*60 * (3-this.algorithmDaysRemaining);
        }
        this.suggestedTarget = Math.ceil((+totalSitting/(3-this.algorithmDaysRemaining)) - 15);

        let minTarget = 540;
        if(this.currentUser.device == 'manual'){
          minTarget = 420;
        }
        if(this.suggestedTarget < minTarget){
          this.suggestedTarget = minTarget;
        }
      });
  }
}
