import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SleepService {
  error = new Subject<string>();
  slug = '/user_sleeps';
  resourceName = 'user_sleeps';
  allSleeps?: any;
  sleepsBetween?: any;
  sleepScore?: any;

  constructor(private http: HttpClient) {}

  fetchAll(): any {
    if (!this.allSleeps) {
      this.allSleeps = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: any[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allSleeps;
  }

  clearCache() {
    this.allSleeps = null;
    this.sleepsBetween = null;
    this.sleepScore = null;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(sleep: string, date_recorded: string) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      sleep,
    };
    return this.http.post<any>(environment.apiUrl + this.slug, payload, {
      observe: 'response',
    });
  }

  update(id: number, sleep: string, date_recorded: string) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      sleep,
    };
    return this.http.patch<any>(
      environment.apiUrl + this.slug + '/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + '/' + id
    );
  }

  getRagForAge(sleep, age) {
    let ragColour = '';

    if (age > 19 && age < 26) {
      if (+sleep >= 11 * 60 || +sleep < 6 * 60) {
        ragColour = 'red';
      } else if (+sleep >= 7 * 60 && +sleep <= 9 * 60) {
        ragColour = 'green';
      } else {
        ragColour = 'amber';
      }
    } else if (age > 25 && age < 65) {
      if (+sleep >= 10 * 60 || +sleep < 6 * 60) {
        ragColour = 'red';
      } else if (+sleep >= 7 * 60 && +sleep <= 9 * 60) {
        ragColour = 'green';
      } else {
        ragColour = 'amber';
      }
    } else {
      if (+sleep >= 9 * 60 || +sleep < 5 * 60) {
        ragColour = 'red';
      } else if (+sleep >= 7 * 60 && +sleep <= 8 * 60) {
        ragColour = 'green';
      } else {
        ragColour = 'amber';
      }
    }

    return ragColour;
  }

  getSleepAverage(allSleeps) {
    if (!allSleeps) {
      return;
    }
    let sleeps = 0;
    let sleepTime = 0;

    allSleeps.forEach((sleep, index) => {
      if (
        moment(sleep.date_recorded).format('YYYY-MM-DD') >=
        moment().subtract(6, 'days').format('YYYY-MM-DD')
      ) {
        sleeps++;
        sleepTime += +sleep.sleep;
      }
    });

    if (sleeps > 0) {
      return sleepTime / sleeps;
    } else {
      // get the latest
      sleeps = 0;
      sleepTime = 0;
      if (allSleeps[0]) {
        let startDate = allSleeps[0].date_recorded;

        allSleeps.forEach((sleep) => {
          if (
            moment(sleep.date_recorded).format('YYYY-MM-DD') >=
            moment(startDate).subtract(6, 'days').format('YYYY-MM-DD')
          ) {
            sleeps++;
            sleepTime += +sleep.sleep;
          }
        });
      }
      if (sleeps > 0) {
        return sleepTime / sleeps;
      }

      return 0;
    }
  }

  setSleepScore(auto_meq, chronotype) {
    this.clearCache();
    const payload = {
      auto_meq,
      chronotype,
    };
    return this.http.post<any>(
      environment.apiUrl + '/user_sleep_score',
      payload,
      {
        observe: 'response',
      }
    );
  }

  getSleepScore() {
    if (!this.sleepScore) {
      this.sleepScore = this.http
        .get<any>(environment.apiUrl + '/user_sleep_score', {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            return responseData['_embedded']['user_sleep_score'][0];
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.sleepScore;
  }

  getWeeklyAverages(sleeps) {
    let weeklyData: any = {};

    sleeps.forEach((sleep, index) => {
      if (
        !weeklyData[
          moment(sleep.date_recorded).startOf('isoWeek').format('YYYY-MM-DD')
        ]
      ) {
        weeklyData[
          moment(sleep.date_recorded).startOf('isoWeek').format('YYYY-MM-DD')
        ] = [];
      }
      weeklyData[
        moment(sleep.date_recorded).startOf('isoWeek').format('YYYY-MM-DD')
      ].push(sleep);
    });

    let weeklyAverages = [];

    for (var key in weeklyData) {
      if (weeklyData.hasOwnProperty(key)) {
        // data
        weeklyAverages[key] = 0;
        let weekSleep = 0;
        let weekSleeps = 0;
        weeklyData[key].forEach((sleep, acIndex) => {
          weekSleep += +sleep.sleep;
          weekSleeps++;
        });
        weeklyAverages[key] = weekSleep / weekSleeps;
      }
    }

    return weeklyAverages;
  }
}
