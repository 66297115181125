<nav class="subnav">
  <a
    [routerLink]="['/health-trackers', 'cholesterol']"
    class="subnav-prev"
    aria-label="Go to Cholesterol"
  >
    <span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon
    ></span>
  </a>
  <h2>
    <span class="icon-wrap">
      <fa-icon [icon]="['fas', 'smoking-ban']"></fa-icon>
    </span>
    SMOKING
  </h2>
</nav>
<div>
  <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>

  <div class="toggle-content">
    <form
      class="bs-form tracker-form"
      name="trackerForm"
      method="post"
      (ngSubmit)="onSubmit()"
      #trackerForm="ngForm"
    >
      <div class="form-group">
        <label for="weight">WHAT DO YOU SMOKE?</label>
        <p class="instruction">
          Please choose what you smoke and enter how much
        </p>

        <select
          class="form-control"
          name="smokingSetting.val"
          id="type"
          [(ngModel)]="smokingSetting.val"
          #smokingSetting.valInput="ngModel"
          
        >
          <option value="">I don't smoke</option>
          <option value="cigarettes">Cigarettes</option>
          <option value="cigars">Cigars</option>
          <option value="tobacco">Tobacco</option>
          <option value="pipe_tobacco">Pipe Tobacco</option>
        </select>
      </div>

      <div class="form-group" [hidden]="!smokingSetting.val">
        <label for="weight">Amount</label>
        <p [hidden]="smokingSetting.val !== 'cigarettes'" class="instruction">
          Please enter the number of cigarettes you smoke per day.
        </p>
        <p [hidden]="smokingSetting.val !== 'cigars'" class="instruction">
          Please enter the number of cigars you smoke per day.
        </p>
        <p [hidden]="smokingSetting.val !== 'tobacco'" class="instruction">
          Please enter the grams of tobacco you smoke per day.
        </p>
        <p [hidden]="smokingSetting.val !== 'pipe_tobacco'" class="instruction">
          Please enter the grams of pipe tobacco you smoke per day.
        </p>

        <input
          type="number"
          name="amount"
          class="form-control input-tracker"
          placeholder=""
          id="amount"
          ngModel
          #amount="ngModel"
          [min]="1"
          [max]="1000"
          autocomplete="off"
         [required]="smokingSetting.val!=''"
        />
        <div class="alert alert-danger" *ngIf="amount.touched && !amount.valid">
          This must be a number between 1 and 1000.
        </div>
      </div>

      <div class="tracker-submit-container">
        <button
          type="submit"
          class="btn btn-primary btn-white"
          [disabled]="!trackerForm.valid"
        >
          <i class="fa fa-check"></i> CONFIRM
        </button>
      </div>
    </form>
  </div>
</div>
