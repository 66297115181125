import { DeviceService } from './../services/device.service';
import { AwardService } from './../services/award.service';
import { UserInviteService } from './../services/user-invite.service';
import { DateHelperService } from './../services/date-helper.service';
import { BoosterSessionsService } from './../booster-sessions/booster-sessions.service';
import { TargetService } from './../services/target.service';
import { ActivityService } from './../services/activity.service';
import { UserSettingsService } from './../services/user-settings.service';
import { ProgrammeService } from './../services/programme.service';
import { UserService } from './../services/user.service';
import { AuthService } from './../auth/auth.service';
import { forkJoin } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { WorkoutsService } from '../services/workouts.service';
import { ExerciseWorkoutsService } from '../services/exercise-workouts.service';
import { SleepService } from '../services/sleep.service';

@Component({
  selector: 'app-activity-index',
  templateUrl: './activity-index.component.html',
  styleUrls: ['./activity-index.component.scss'],
})
export class ActivityIndexComponent implements OnInit {
  isFetching;
  currentUser;
  currentProgramme;
  currentActivity;
  deviceSynced;
  currentSteps;
  currentTarget;
  errors = [];
  // challenges
  challengePos;
  iLead = false;
  currentLeader = [];
  myActivity;
  format;

  monfriChallenge = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  weekendChallenge = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  currentChallenge = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  teamChallenge = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  globalChallenge = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };

  currentWeek;
  now;
  weekData;
  weekTotal = 0;
  weekTotalMins = 0;
  weekTotalSitting = 0;

  invites;
  totalInvites = 5;

  awards;

  deviceStatus;
  allWorkouts;
  weekWorkouts = [];

  sleepDaysHit = 0;

  allSleeps:any;
  sleepWeekData:any = [];

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private userSettingsService: UserSettingsService,
    private activityService: ActivityService,
    private targetService: TargetService,
    private boosterSessionService: BoosterSessionsService,
    private dateHelperService: DateHelperService,
    private userInviteService: UserInviteService,
    private awardService: AwardService,
    private deviceService: DeviceService,
    private exerciseWorkoutsService:ExerciseWorkoutsService,
    private sleepService:SleepService
  ) {}

  ngOnInit(): void {
    this.isFetching = true;

    const now = moment();
    this.currentWeek = this.dateHelperService.getWeekFor(now);
    this.now = moment(now).format('YYYY-MM-DD');
    const startDate = moment(this.currentWeek[0]).format('YYYY-MM-DD');
    const endDate = moment(this.currentWeek[6]).format('YYYY-MM-DD');

    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        this.userService.userData.subscribe((userDataResponse) => {
          this.currentUser = userDataResponse;
          if (this.currentUser) {
            this.getChallenge();
          }
        });
      });

      forkJoin({
        userSettings: this.userSettingsService.fetchAll(),
        activity: this.activityService.getActivity(),
        target: this.targetService.getTarget('step'),
        // targetMins: this.targetService.getTarget('mins'),
        activityBetween: this.activityService.getActivityBetween(
          startDate,
          endDate
        ),
        invites: this.userInviteService.fetchAll(),
        awards: this.awardService.fetchAll(),
        device: this.deviceService.checkConnection(),
        workouts: this.exerciseWorkoutsService.fetchAll(),
        sleeps:this.sleepService.fetchAll()
      }).subscribe(
        (responseData) => {
          this.isFetching = false;

          this.weekData = [];
          const weeklyActivities = responseData.activityBetween;
          weeklyActivities.forEach((activity) => {
            this.weekTotal = Number(this.weekTotal) + Number(activity.steps);
            this.weekTotalMins = +this.weekTotalMins + +activity.minutes;
            this.weekTotalSitting = +this.weekTotalSitting + +activity.sedentary_minutes;
          });

          this.currentWeek.forEach((item, index) => {
            const thisActivity = weeklyActivities.filter(this.search, {
              activity_date: moment(item).format('YYYY-MM-DD'),
            })[0];

            if (thisActivity) {
              if (
                thisActivity.hit_goal !== 1 &&
                +thisActivity.percent_hit >= 100
              ) {
                thisActivity.hit_goal = 1;
              }
              this.weekData.push(thisActivity);
            } else {
              const dData = {
                activity_date: moment(item).format('YYYY-MM-DD'),
              };
              this.weekData.push(dData);
            }

            // do the workouts
            this.allWorkouts = responseData.workouts;
            this.allWorkouts.forEach((workout) => {
              if(workout.workout_date == moment(item).format('YYYY-MM-DD') ){
                this.weekWorkouts.push(workout);
              }
            });

          });

          this.currentActivity = responseData.activity;
          if (this.currentActivity) {
            this.currentSteps = this.currentActivity.steps;
            this.deviceSynced = true;
          } else {
            this.deviceSynced = false;
          }

          if (responseData.target) {
            this.currentTarget = responseData.target.target;
          }
          // if (responseData.targetMins) {
          //this.currentTargetMins = responseData.targetMins.target;
          // }

          this.invites = responseData.invites;

          this.awards = responseData.awards;
          this.deviceStatus = responseData.device;


          this.allSleeps = responseData.sleeps;

       
        // get this week's data
        this.currentWeek.forEach((item, index) => {
          const thisActivity = this.allSleeps.filter(this.search, {
            date_recorded: moment(item).format('YYYY-MM-DD'),
          })[0];

          if (thisActivity) {
            //this.weekTotal += +thisActivity.sedentary_minutes;
            this.sleepWeekData.push(thisActivity);
            if (
              +thisActivity.sleep / 60 >= 7 &&
              +thisActivity.sleep / 60 <= 9
            ) {
              this.sleepDaysHit++;
            }
          } else {
            const dData = {
              date_recorded: moment(item).format('YYYY-MM-DD'),
            };
            this.sleepWeekData.push(dData);
          }
        });


        },
        (error) => {
          console.log(error);

          this.isFetching = false;
          this.errors.push(error.message);
        }
      );
    }
  }

  getChallenge() {
    // start challenge

    // get week
    const now = moment().format('YYYY-MM-DD');

    // what is the day today
    const today = moment(now).format('dddd');
    const currentWeek = this.dateHelperService.getWeekFor(now);

    this.globalChallenge.startDate = this.teamChallenge.startDate;
    this.globalChallenge.endDate = this.teamChallenge.endDate;
    this.globalChallenge.nextStartDate = moment(currentWeek[0])
      .add(1, 'week')
      .format('YYYY-MM-DD');

    if (today == 'Saturday' || today == 'Sunday') {
      this.weekendChallenge.title = 'Weekend Challenge';
      this.currentChallenge.title = 'Weekend Challenge';
      this.weekendChallenge.startDate = moment(currentWeek[5]).format(
        'YYYY-MM-DD'
      );
      this.weekendChallenge.endDate = moment(currentWeek[6]).format(
        'YYYY-MM-DD'
      );
      this.weekendChallenge.type = 'WEEKEND';
      this.weekendChallenge.id =
        this.weekendChallenge.type +
        ';' +
        this.weekendChallenge.startDate +
        ';' +
        this.weekendChallenge.endDate;

      this.currentChallenge = this.weekendChallenge;

      this.monfriChallenge.title = 'Monday - Friday Challenge';
      this.monfriChallenge.startDate = moment(currentWeek[0]).format(
        'YYYY-MM-DD'
      );
      this.monfriChallenge.endDate = moment(currentWeek[4]).format(
        'YYYY-MM-DD'
      );
      this.monfriChallenge.type = 'MON-FRI';
      this.monfriChallenge.id =
        this.monfriChallenge.type +
        ';' +
        this.monfriChallenge.startDate +
        ';' +
        this.monfriChallenge.endDate;
    } else {
      this.monfriChallenge.title = 'Monday - Friday Challenge';
      this.currentChallenge.title = 'Monday - Friday Challenge';
      this.monfriChallenge.startDate = moment(currentWeek[0]).format(
        'YYYY-MM-DD'
      );
      this.monfriChallenge.endDate = moment(currentWeek[4]).format(
        'YYYY-MM-DD'
      );
      this.monfriChallenge.type = 'MON-FRI';
      this.monfriChallenge.id =
        this.monfriChallenge.type +
        ';' +
        this.monfriChallenge.startDate +
        ';' +
        this.monfriChallenge.endDate;

      this.currentChallenge = this.monfriChallenge;

      this.weekendChallenge.title = 'Weekend Challenge';
      this.weekendChallenge.startDate = moment(currentWeek[5])
        .subtract(1, 'week')
        .format('YYYY-MM-DD');

      this.weekendChallenge.endDate = moment(currentWeek[6])
        .subtract(1, 'week')
        .format('YYYY-MM-DD');
      this.weekendChallenge.type = 'WEEKEND';
      this.weekendChallenge.id =
        this.weekendChallenge.type +
        ';' +
        this.weekendChallenge.startDate +
        ';' +
        this.weekendChallenge.endDate;
    }

    if (!this.currentUser.buddy) {
      this.activityService
        .getBandActivity(
          this.currentUser.step_band,
          this.currentProgramme.programme_id,
          this.currentChallenge.startDate,
          this.currentChallenge.endDate
        )
        .subscribe((responseData) => {
          let pos = 0;
          responseData.forEach((item, index) => {
            pos++;

            if (item.user_id == this.currentUser.user_id) {
              this.challengePos = pos;
            }
            if (item.user_id == this.currentUser.user_id) {
              this.myActivity = item;
            }

            if (this.currentLeader.length === 0) {
              this.currentLeader.push(responseData[index]);
              if (responseData[index].user_id == this.currentUser.user_id) {
                this.iLead = true;
              }
            } else {
              if (index > 0) {
                if (
                  responseData[index].step_count ==
                  this.currentLeader[0].step_count
                ) {
                  this.currentLeader.push(responseData[index]);
                  if (
                    responseData[index].user_id == this.currentUser.user_id
                  ) {
                    this.iLead = true;
                  }
                }
              }
            }
          });
        });
    }

    // end challenge
  }

  search(obj) {
    return Object.keys(this).every((key) => obj[key] === this[key]);
  }
  
}
