import { UserService } from './../services/user.service';
import { ProgrammeService } from './../services/programme.service';
import { AuthService } from './../auth/auth.service';
import { Component, OnInit, Input } from '@angular/core';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-section-nav',
  templateUrl: './section-nav.component.html',
  styleUrls: ['./section-nav.component.scss']
})
export class SectionNavComponent implements OnInit {
  @Input() section: string;
  @Input() active: string;
  currentProgramme;
  currentUser;

  constructor(
    private authService: AuthService,
    private programmeService: ProgrammeService,
    private userService: UserService
    ) { 
    // are we logged in?
    this.authService.user.subscribe((user) => {
      if (user) {
        this.programmeService.currentProgramme.subscribe((programmeData) => {
          this.currentProgramme = programmeData;
        });
        this.userService.userData.subscribe((userDataResponse) => {
          this.currentUser = userDataResponse;
        });
      }
    });
  }

  ngOnInit(): void {
  }

}
