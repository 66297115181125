import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivityService } from 'src/app/services/activity.service';
import { DateHelperService } from 'src/app/services/date-helper.service';
import { HeartRateService } from 'src/app/services/heart-rate.service';
import { NonSteppingService } from 'src/app/services/non-stepping.service';
import { ProgrammeService } from 'src/app/services/programme.service';
import { SleepService } from 'src/app/services/sleep.service';
import { Sts60Service } from 'src/app/services/sts60.service';
import { TargetService } from 'src/app/services/target.service';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { UserService } from 'src/app/services/user.service';
import { Vo2maxService } from 'src/app/services/vo2max.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-m3-dashboard',
  templateUrl: './m3-dashboard.component.html',
  styleUrls: ['./m3-dashboard.component.scss'],
})
export class M3DashboardComponent implements OnInit {
  currentUser;
  currentProgramme;
  heartRates;
  vo2maxs;
  sts60s;
  age;
  deviceSynced = null;
  currentActivity;
  currentSteps;
  currentTargetObj;
  currentTarget;
  userSettings;
  isFetching = false;
  perosnalisedTarget;
  myActivity;
  stepTarget;
  stepAverage;
  allTimeSteps;
  allSleeps;
  weeklyStepAverages: any = [];
  sleepHours;
  sleepMins;
  nonSteppingsByDate = [];
  nonSteppings;
  nonSteppingWeeklyTotal = 0;
  nonSteppingsWeek = [];
  activitiesDone;
  activitiesByWeek: any = {};
  activitiesByWeekTotals = [];
  sex: any;
  sittingTarget = 6;
  latestSittings: any = [];
  sittingAverage = 0;
  sleepAverage;
  sleepScore;
  sleepWeekStart;

  algorithmComplete = false;
  algorithmDays = [
    { day: '', status: 0 },
    { day: '', status: 0 },
    { day: '', status: 0 },
  ];
  algorithmDaysRemaining = 3;
  activitySetupStep = 1;
  allSittings;
  sittingWeekStart;
  briskMinsThisWeek = 0;
  activeMinsByWeekTotals: any;

  startDate;
  lastWeek;
  endDate;
  activeVideo = null;

  constructor(
    private authService: AuthService,
    private programmeService: ProgrammeService,
    private router: Router,
    private userService: UserService,
    private heartRateService: HeartRateService,
    private vo2maxService: Vo2maxService,
    private userSettingsService: UserSettingsService,
    public sts60Service: Sts60Service,
    private activityService: ActivityService,
    private targetService: TargetService,
    private sleepService: SleepService,
    private nonSteppingService: NonSteppingService,
    private dateHelperService: DateHelperService
  ) {}

  ngOnInit(): void {
    // are we logged in?
    this.isFetching = true;
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {

        this.currentProgramme = programmeData;
        // only if it's M3
        if(this.currentProgramme.config?.m3 !=1){
          this.router.navigateByUrl('/dashboard');
        }
      });

      this.userService.userData.subscribe((userDataResponse) => {
        if (userDataResponse) {
          this.currentUser = userDataResponse;
          this.sex = this.currentUser.gender;
          if (this.currentUser.dob) {
            this.age = moment().diff(this.currentUser.dob, 'years', false);
          }
          if (this.currentUser.device) {
            if (this.currentUser.device == 'fitbit') {
              this.sittingTarget = 9;
            }
          }
        }
      });

      // get start date and three day summary?
      // do it
      const threeDaysAgo = moment().subtract('4', 'days');
      const created = moment(user.created);
      let startDay = created;

      if (threeDaysAgo > created) {
        startDay = threeDaysAgo;
      }

      const now = moment();

      let startDate = moment(startDay).format('YYYY-MM-DD');
      let endDate = moment(now).subtract(1, 'days').format('YYYY-MM-DD');


      forkJoin({
        userSettings: this.userSettingsService.fetchAll(),
        heartRates: this.heartRateService.fetchAll(),
        vo2maxs: this.vo2maxService.fetchAll(),
        sts60s: this.sts60Service.fetchAll(),
        activity: this.activityService.getActivity(),
        target: this.targetService.getTarget('step'),
        activitySummary: this.activityService.getActivitySummary(
          startDate,
          endDate
        ),
        allTimeSteps: this.activityService.getActivityBetween(
          moment().subtract(1, 'years').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ),

        allTimeSleep: this.sleepService.fetchAll(),

        nonSteppings: this.nonSteppingService.fetchAll(),
        sleepScore: this.sleepService.getSleepScore(),
      }).subscribe(
        (responseData) => {
          this.isFetching = false;
          this.heartRates = responseData.heartRates;
          this.vo2maxs = responseData.vo2maxs;
          this.sts60s = responseData.sts60s;
          this.stepTarget = responseData.target;

          this.allTimeSteps = responseData.allTimeSteps;
          this.allTimeSteps.forEach((activity) => {
            if (activity.sedentary_minutes && this.latestSittings.length < 6) {
              this.latestSittings.push(activity);
            }
          });

          this.allTimeSteps.forEach((activity: any) => {
            if (
              moment(activity.activity_date).format('YYYY-MM-DD') >=
              moment().startOf('isoWeek').format('YYYY-MM-DD')
            ) {
              this.briskMinsThisWeek += +activity.brisk_step_minutes;
            }
          });

          // do we need a new target??

          let allWeeklyAverages = this.activityService.getWeeklyStepAverages(
            this.allTimeSteps
          );
          let index = 0;
          for (var key in allWeeklyAverages) {
            if (allWeeklyAverages.hasOwnProperty(key)) {
              index++;
              if (index <= 5) {
                this.weeklyStepAverages.push({
                  activity_date: key,
                  steps: allWeeklyAverages[key],
                });
              }
            }
          }

          this.sittingAverage = this.activityService.getSittingAverage(
            this.allTimeSteps
          );

          this.allSittings = [];
          this.allTimeSteps.forEach((sitting) => {
            if (sitting.sedentary_minutes > 0) {
              this.allSittings.push(sitting);
            }
          });

          if (this.allSittings.length > 0) {
            if (
              moment(this.allSittings[0].activity_date) >
              moment().subtract(1, 'week')
            ) {
              this.sittingWeekStart = moment()
                .subtract(6, 'day')
                .format('Do MMM');
            } else {
              this.sittingWeekStart = moment(this.allSittings[0].activity_date)
                .subtract(6, 'day')
                .format('Do MMM');
            }
          }

          this.sleepScore = responseData.sleepScore;

          this.userSettings = responseData.userSettings;
          if (this.userSettings['readActivitySetup']) {
            this.activitySetupStep = 2;
          }

          this.allSleeps = responseData.allTimeSleep;
          this.sleepAverage = this.sleepService.getSleepAverage(this.allSleeps);
          if (this.allSleeps[0]) {
            let hours = Math.floor(this.allSleeps[0].sleep / 60);
            let minutes = Math.floor(this.allSleeps[0].sleep % 60);

            if (
              moment(this.allSleeps[0].date_recorded).dayOfYear() ==
              moment().dayOfYear()
            ) {
              this.sleepMins = minutes;
              this.sleepHours = hours;
            }
          }

          if (this.allSleeps.length > 0) {
            if (
              moment(this.allSleeps[0].date_recorded) >
              moment().subtract(1, 'week')
            ) {
              this.sleepWeekStart = moment()
                .subtract(6, 'day')
                .format('Do MMM');
            } else {
              this.sleepWeekStart = moment(this.allSleeps[0].date_recorded)
                .subtract(6, 'day')
                .format('Do MMM');
            }
          }

          // non stepping..

          this.nonSteppings = responseData.nonSteppings;

          this.activitiesByWeek = this.nonSteppingService.getActivitiesByWeek(
            this.nonSteppings,
            this.allTimeSteps
          );

          this.activitiesByWeekTotals =
            this.nonSteppingService.getActivitiesByWeekTotals(
              this.activitiesByWeek
            );

          let activtitiesDone = [];
          this.nonSteppings.forEach((nonStepping) => {
            activtitiesDone.push(nonStepping.activity);
            // is it in the last 7 days?
            if (
              moment(nonStepping.date_recorded).format('YYYY-MM-DD') >=
              moment().startOf('isoWeek').format('YYYY-MM-DD')
            ) {
              this.nonSteppingWeeklyTotal += +nonStepping.minutes;
              this.nonSteppingsWeek.push(nonStepping);
            }

            if (!this.nonSteppingsByDate[nonStepping.date_recorded]) {
              this.nonSteppingsByDate[nonStepping.date_recorded] = [];
            }
            this.nonSteppingsByDate[nonStepping.date_recorded].push(
              nonStepping
            );
          });
          this.activitiesDone = activtitiesDone;
          this.activitiesDone.push('activeMinutes');

          // do week total
          /*
        for (var key in this.nonSteppingsByDate) {
          this.weeklyTotal = 0;
          if (this.nonSteppingsByDate.hasOwnProperty(key)) {
            this.nonSteppingsByDate[key].forEach((activity, acIndex) => {
              this.weeklyTotal += +activity.minutes;
            });
          }
        }*/

          this.currentActivity = responseData.activity;
          this.stepAverage = this.activityService.getStepAverage(
            this.allTimeSteps
          );
          if(!this.stepAverage || this.stepAverage == 0){
            if(this.weeklyStepAverages){
              let weekStep = false;
              this.weeklyStepAverages.slice().reverse().forEach((week)=>{
                if(!weekStep && +week.steps>0){
                  weekStep = true;
                  this.stepAverage = week.steps;
                }
              });
            }
          }
          // console.log(this.currentActivity);
          if (this.currentActivity) {
            this.currentSteps = this.currentActivity.steps;
            this.deviceSynced = true;
          } else {
            this.deviceSynced = false;
          }

          if (responseData.target) {
            this.currentTargetObj = responseData.target;
            this.currentTarget = responseData.target.target;
            this.algorithmComplete = true;
          }
          // can they update their target?
          this.checkStepTarget();
          this.generateAlgorithmDays();
        },
        (error) => {
          this.isFetching = false;
        }
      );
    }
  }

  onConfirmReadActivitySetup() {
    this.activitySetupStep = 2;
    this.userSettingsService
      .saveSetting('readActivitySetup', '1')
      .subscribe((responseData) => {});
  }
  generateAlgorithmDays() {
    // get 4 days ago
    const threeDaysAgo = moment().subtract('3', 'days');
    const created = moment(this.currentUser.created);
    let startDay = created.add('1', 'day');
    let oldestDay = moment().subtract('4', 'day');
    if (threeDaysAgo > created) {
      startDay = threeDaysAgo;
    }

    this.algorithmDaysRemaining = 3;
    this.activityService
      .getActivityBetween(
        oldestDay.format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      )
      .subscribe((responseData) => {
        const userActivity = responseData;
        // do the loop
        let returnDays = [];
        let totalSteps = 0;
        for (let i = 0; i < 3; i++) {
          let dayStatus = 0;

          userActivity.forEach((activity) => {
            //console.log(activity);
            if (+activity.steps > 0) {
              if (
                moment(activity.activity_date).format('YYYY-MM-DD') <
                moment(startDay).format('YYYY-MM-DD')
              ) {
                startDay = moment(activity.activity_date);
              }
            }
          });

          userActivity.forEach((activity) => {
            if (
              activity.activity_date ==
                moment(startDay).add(i, 'days').format('YYYY-MM-DD') &&
              activity.steps > 0 &&
              moment(startDay).add(i, 'days').format('YYYY-MM-DD') <
                moment().format('YYYY-MM-DD')
            ) {
              dayStatus = 1;
              this.algorithmDaysRemaining--;
              totalSteps += +activity.steps;
            }
          });
          returnDays.push({
            day: moment(startDay).add(i, 'days'),
            status: dayStatus,
          });
        }

        if (this.algorithmDaysRemaining == 0) {
          // work out the target?
          this.perosnalisedTarget = Math.round(totalSteps / 3 + 500);

          if (this.perosnalisedTarget >= 10000) {
            this.perosnalisedTarget = 10000;
          }
        }
        this.algorithmDays = returnDays;
      });
  }

  onGenerateReport() {
    // get suggested target
    let target = this.perosnalisedTarget;

    this.isFetching = true;
    // submit
    this.targetService.create(target, 'step', 'steps').subscribe(
      (responseData) => {
        this.isFetching = false;
        this.algorithmComplete = true;
      },
      (error) => {}
    );
  }

  // fitness

  getGoodforAgeAndSex(age: number, sex: string) {
    return this.vo2maxService.getGoodforAgeAndSex(age, sex);
  }

  getRagForAgeAndSex(vo2max: any, age: number, sex: string) {
    return this.vo2maxService.getRagForAgeAndSex(vo2max, age, sex);
  }

  getVo2maxforColorAgeAndSex(color: string, age: number, sex: string) {
    return this.vo2maxService.getVo2maxforColorAgeAndSex(color, age, sex);
  }
  getSts60RagForAgeAndSex(sts60: any, age: number, sex: string) {
    return this.sts60Service.getRagForAgeAndSex(sts60, age, sex);
  }
  getSts60forColorAgeAndSex(color: string, age: number, sex: string) {
    return this.sts60Service.getSts60forColorAgeAndSex(color, age, sex);
  }

  getStepsRag(averageSteps: number) {
    return this.activityService.getStepsRag(averageSteps);
  }
  getSittingRag(minutes, device) {
    return this.activityService.getSittingRag(minutes, device);
  }
  getSleepRagForAge(sleep: any, age: number) {
    return this.sleepService.getRagForAge(sleep, age);
  }

  getAgeSleep(age) {
    if (age < 65) {
      return '7-9';
    } else {
      return '7-8';
    }
  }

  checkStepTarget() {
    const now = moment();
    // what is the day today
    this.startDate = moment(now).subtract(1, 'weeks');
    this.lastWeek = this.dateHelperService.getWeekDayFor(now);
    this.startDate = moment(this.startDate).format('YYYY-MM-DD');
    this.endDate = moment(now).subtract(1, 'days').format('YYYY-MM-DD');

    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;

        // do it
        const threeDaysAgo = moment().subtract('4', 'days');
        const weekAgo = moment().subtract('7', 'days');
        const created = moment(user.created);
        let startDay = created;
        if (threeDaysAgo > created) {
          startDay = threeDaysAgo;
        }

        this.startDate = moment(startDay).format('YYYY-MM-DD');
        this.endDate = moment(now).subtract(1, 'days').format('YYYY-MM-DD');

        // get monday last week
        const lastMonday = moment().isoWeekday(-6);
        const lastSunday = moment(lastMonday).add(6, 'days');
        const lastLastMonday = moment().isoWeekday(-13);

        this.isFetching = true;
        forkJoin({
          userSettings: this.userSettingsService.fetchAll(),
          activity: this.activityService.getActivity(0),
          target: this.targetService.getTarget('step'),
          activitySummary: this.activityService.getActivitySummary(
            this.startDate,
            this.endDate
          ),
          activityBetween: this.activityService.getActivityBetween(
            this.startDate,
            this.endDate
          ),
          activityLastWeek: this.activityService.getActivitySummary(
            lastMonday.format('YYYY-MM-DD'),
            lastSunday.format('YYYY-MM-DD')
          ),
          activityLastLastWeek: this.activityService.getActivitySummary(
            lastLastMonday.format('YYYY-MM-DD'),
            lastSunday.format('YYYY-MM-DD')
          ),
        }).subscribe(
          (responseData) => {
            this.isFetching = false;
            this.currentTargetObj = responseData.target;
            this.myActivity = responseData.activitySummary[0];

            if (this.currentTargetObj) {
              // can they update based on last week?
              if (
                this.currentTargetObj.created < lastSunday.format('YYYY-MM-DD')
              ) {
                if (
                  +responseData.activityLastLastWeek[0].step_avg >=
                    +this.currentTargetObj.target &&
                  +responseData.activityLastWeek[0].step_avg >=
                    +this.currentTargetObj.target
                ) {
                  if (+responseData.activityLastWeek[0].step_avg < 2000) {
                    this.perosnalisedTarget = Math.round(
                      +responseData.activityLastWeek[0].step_avg + 100
                    );
                  } else {
                    this.perosnalisedTarget = Math.round(
                      +responseData.activityLastWeek[0].step_avg + 500
                    );
                  }
                  if (this.perosnalisedTarget >= 10000) {
                    this.perosnalisedTarget = 10000;
                  }
                } else if (
                  +responseData.activityLastWeek[0].step_avg >=
                  +this.currentTargetObj.target
                ) {

                } else if (
                  responseData.activityLastWeek[0].step_avg <
                    +this.currentTargetObj.target &&
                  this.currentUser.created < lastMonday.format('ddd Do MMM')
                ) {
                  if (responseData.activityLastWeek[0].step_avg < 500) {
                    this.perosnalisedTarget = Math.round(
                      +this.currentTargetObj.target - 500
                    );
                  } else {
                    if (+responseData.activityLastWeek[0].step_avg < 2000) {
                      this.perosnalisedTarget = Math.round(
                        +responseData.activityLastWeek[0].step_avg + 100
                      );
                    } else {
                      this.perosnalisedTarget = Math.round(
                        +responseData.activityLastWeek[0].step_avg + 500
                      );
                    }
                  }
                }

                if (this.perosnalisedTarget >= 10000) {
                  this.perosnalisedTarget = 10000;
                }
                if (this.perosnalisedTarget > 0) {
                  this.targetService
                    .create(this.perosnalisedTarget, 'step', 'steps')
                    .subscribe((responseData) => {
                      this.stepTarget = responseData;
                      Swal.fire({
                        position:'top-end',
                        text: 'Your step target was updated',
                        icon: 'success',
                        toast:true,
                        showConfirmButton:false,
                        timer:1000
                      });
                    });
                }
              }
            }
          },
          (error) => {
            this.isFetching = false;
          }
        );
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });
    }
  }
  
}
