import { PageViewService } from './../../services/page-view.service';
import { environment } from './../../../environments/environment';
import { DragAndDropService } from './../../services/drag-and-drop.service';
import { forkJoin } from 'rxjs';
import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { BoosterSessionsService } from './../../booster-sessions/booster-sessions.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { AssessmentsService } from 'src/app/services/assessments.service';
import { TargetService } from 'src/app/services/target.service';

@Component({
  selector: 'app-learning-session-step',
  templateUrl: './learning-session-step.component.html',
  styleUrls: ['./learning-session-step.component.scss'],
})
export class LearningSessionStepComponent implements OnInit, OnDestroy {
  isFetching;
  learningSessionStep;
  learningSession;
  learningSessionProgramme;
  currentUser;
  currentProgramme;
  learningSessionStepId;
  prevStep;
  nextStep;
  learningSessionId;
  learningSessionSteps;
  sessionProgress;
  dragAndDrop;
  dragItems;
  dropItems;
  prevSession;
  nextSession;
  environment = environment;
  stepTarget;
  start;
  firstView = true;
  setWalkingSpeedLink = ['/dashboard'];
  setWalkingSpeedText = 'Set Walking Speed';

  constructor(
    private boosterSessionService: BoosterSessionsService,
    private route: ActivatedRoute,
    private boosterSessionsService: BoosterSessionsService,
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private router: Router,
    private dragAndDropService: DragAndDropService,
    private titleService: Title,
    private pageViewService: PageViewService,
    private assessmentService: AssessmentsService,
    private targetService: TargetService
  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
        if (this.currentUser) {
          if (this.currentUser) {
            if (this.currentUser.buddy) {
              this.router.navigate(['/dashboard']);
            }
          }
        }
      });
    }

    //
    this.route.params.subscribe((params) => {
      if (this.firstView) {
        this.start = moment();
        this.firstView = false;
      } else {
        this.savePageView();
        this.start = moment();
      }
      this.prevStep = null;
      this.nextStep = null;
      this.learningSessionStepId =
        this.route.snapshot.params.learningSessionStepId;
      this.isFetching = true;
      this.learningSessionStep = null;

      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (this.currentProgramme) {
          // get the step first
          this.boosterSessionsService
            .fetchStep(this.learningSessionStepId)
            .subscribe((stepResponse) => {
              //console.log(stepResponse);
              this.learningSessionId = stepResponse.booster_session_id;
              this.learningSessionStep = stepResponse;

              // what other data do we need?
              if (
                +this.learningSessionStep.booster_session_step_type_id == 3
              ) {
                const dragAndDropId =
                  this.learningSessionStep.booster_session_step_type_ref_id;
                forkJoin({
                  dragAndDrop: this.dragAndDropService.fetch(dragAndDropId),
                  dragItems:
                    this.dragAndDropService.fetchDragItems(dragAndDropId),
                  dropItems:
                    this.dragAndDropService.fetchDropItems(dragAndDropId)
                }).subscribe((dragDropResponse) => {
                  this.dragAndDrop = dragDropResponse.dragAndDrop;
                  this.dragItems = dragDropResponse.dragItems;
                  this.dropItems = dragDropResponse.dropItems;
                });
              }

              forkJoin({
                learningSession: this.boosterSessionsService.fetch(
                  this.learningSessionId
                ),
                learningSessionSteps: this.boosterSessionsService.fetchSteps(
                  this.learningSessionId
                ),
                sessionProgress: this.boosterSessionsService.getSessionProgress(
                  this.learningSessionId
                ),
                programmeContent: this.programmeService.getProgrammeContent(
                  this.currentProgramme.programme_id
                ),
                stepTarget: this.targetService.getTarget('step')
              }).subscribe((responseData) => {
                this.isFetching = false;
                this.learningSession = responseData.learningSession;

                this.stepTarget = responseData.stepTarget;
                // can they access?
                if (
                  this.learningSession.status === 'unavailable' &&
                  this.currentUser.is_admin == 0
                ) {
                  this.router.navigate(['/dashboard']);
                }

                this.learningSessionSteps = responseData.learningSessionSteps;
                this.sessionProgress = responseData.sessionProgress;

                if (this.sessionProgress) {
                  if (this.sessionProgress.pages_viewed) {
                    const pagesViewed = JSON.parse(
                      this.sessionProgress.pages_viewed
                    );

                    if (Array.isArray(pagesViewed)) {
                      // set the status..
                      this.learningSessionSteps.forEach((item, index) => {
                        if (
                          pagesViewed.indexOf(item.booster_session_step_id) !==
                          -1
                        ) {
                          this.learningSessionSteps[index].progress = 'viewed';
                        }
                      });
                    }
                  }
                }
                // do prev and next
                const learningSessions =
                  responseData.programmeContent.booster_sessions.filter(
                    (boosterSession) => boosterSession.release_date == 'O' || (boosterSession.release_date == 'EPL' && this.stepTarget)
                  );
                this.learningSessionSteps.forEach((step, index) => {
                  if (
                    step.booster_session_step_id == this.learningSessionStepId
                  ) {
                    if (this.learningSessionSteps[index - 1]) {
                      this.prevStep = this.learningSessionSteps[index - 1];
                    }
                    if (this.learningSessionSteps[index + 1]) {
                      this.nextStep = this.learningSessionSteps[index + 1];
                    }
                  }
                });

                // do prev and next session
                // do prev and next
                learningSessions.forEach((session, index) => {
                  if (session.booster_session_id == this.learningSessionId) {
                    this.learningSessionProgramme = session;
                    // setHtmlTitle($scope.programmeBoosterSession.booster_session_title );

                    if (learningSessions[index - 1]) {
                      this.prevSession = learningSessions[index - 1];
                    }
                    if (learningSessions[index + 1]) {
                      this.nextSession = learningSessions[index + 1];
                    }
                  }
                });

                this.titleService.setTitle(
                  environment.title +
                    ' | Learning Sessions | ' +
                    this.learningSession.title +
                    ' | ' +
                    this.learningSessionStep.title
                );

                // walking speed link?
                if (
                  environment.walkingSpeedStepId ==
                  this.learningSessionStep.booster_session_step_id
                ) {
                  this.targetService
                    .getTarget('step')
                    .subscribe((responseData) => {
                      if (responseData.target) {
                        this.setWalkingSpeedText = 'Update Walking Speed';
                        this.setWalkingSpeedLink = ['/steps', 'walking-speed'];
                      }
                    });
                }
              });
            });
        }
      });
    });
  }

  savePageView() {
    const end = moment();
    const timeSpent = Math.round((+end - this.start) / 1000);
    // console.log(timeSpent);

    // post
    this.pageViewService
      .create(
        timeSpent,
        this.learningSessionStep.booster_session_id,
        this.learningSessionStep.booster_session_step_id,
        1,
        1
      )
      .subscribe(
        (responseData) => {
          this.pageViewService.refreshProgress();
        },
        (error) => {
          // console.log(error);
        }
      );
  }

  checkAssessment(session) {
    if (session.assessment_id) {
      // ok, do we have any results?
      const assessmentIds = JSON.parse(session.assessment_id);
      let sentOff = false;
      assessmentIds.forEach((assessmentId) => {
        this.assessmentService
          .fetchResults(assessmentId, 'post', this.learningSessionId)
          .subscribe((resultsResponse) => {
            if (resultsResponse.length < 1 && sentOff == false) {
              sentOff = true;
              setTimeout(() => {
                // take it
                this.router.navigate([
                  '/assessments',
                  'take',
                  assessmentId,
                  'post',
                  this.learningSessionId,
                ]);
                return;
              }, 500);
            }
          });
      });
    }
  }

  ngOnDestroy() {
    this.savePageView();

    // are we on the last step?
    if (!this.nextStep) {
      this.checkAssessment(this.learningSession);
    }
  }
}
