import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NonSteppingService {
  error = new Subject<string>();
  slug = '/user_non_steppings';
  resourceName = 'user_non_steppings';
  allNonSteppings?: any;
  non_steppingsBetween?: any;

  constructor(private http: HttpClient) {}

  fetchAll(): any {
    if (!this.allNonSteppings) {
      this.allNonSteppings = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: any[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allNonSteppings;
  }

  clearCache() {
    this.allNonSteppings = null;
    this.non_steppingsBetween = null;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(
    activity: string,
    minutes: number,
    intensity: number,
    date_recorded: string
  ) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      activity,
      minutes,
      intensity,
    };
    return this.http.post<any>(environment.apiUrl + this.slug, payload, {
      observe: 'response',
    });
  }

  update(
    id: number,
    activity: string,
    minutes: number,
    intensity: number,
    date_recorded: string
  ) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      activity,
      minutes,
      intensity,
    };
    return this.http.patch<any>(
      environment.apiUrl + this.slug + '/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + '/' + id
    );
  }

  getActivitiesByWeek(activities: any, steppings: any) {
    let monthlyActivities: any = {};
    // get first month recorded..

    activities.forEach((activity, index) => {
      if (
        !monthlyActivities[
          moment(activity.date_recorded).startOf('isoWeek').format('YYYY-MM-DD')
        ]
      ) {
        monthlyActivities[
          moment(activity.date_recorded).startOf('isoWeek').format('YYYY-MM-DD')
        ] = [];
      }
      monthlyActivities[
        moment(activity.date_recorded).startOf('isoWeek').format('YYYY-MM-DD')
      ].push(activity);
    });

      steppings.forEach((activity, index) => {

        if(moment(activity.activity_date).startOf('isoWeek').format('YYYY-MM-DD') > moment().subtract(6, 'months').format('YYYY-MM-DD') && activity.brisk_step_minutes){
        if (
          !monthlyActivities[
            moment(activity.activity_date).startOf('isoWeek').format('YYYY-MM-DD')
          ]
        ) {
          monthlyActivities[
            moment(activity.activity_date).startOf('isoWeek').format('YYYY-MM-DD')
          ] = [];
        } }
      });
   

    let compareDate = moment().startOf('isoWeek').format('YYYY-MM-DD');
    if (activities[0]) {
      compareDate = moment(activities[0].date_recorded)
        .startOf('isoWeek')
        .format('YYYY-MM-DD');
    }

    steppings.forEach((activity, index) => {
      if (activity.brisk_step_minutes && +activity.brisk_step_minutes > 0) {
        if (
          monthlyActivities[
            moment(activity.activity_date)
              .startOf('isoWeek')
              .format('YYYY-MM-DD')
          ] ||
          moment(activity.activity_date)
            .startOf('isoWeek')
            .format('YYYY-MM-DD') > compareDate
        ) {
          if (
            !monthlyActivities[
              moment(activity.activity_date)
                .startOf('isoWeek')
                .format('YYYY-MM-DD')
            ]
          ) {
            monthlyActivities[
              moment(activity.activity_date)
                .startOf('isoWeek')
                .format('YYYY-MM-DD')
            ] = [];
          }
          monthlyActivities[
            moment(activity.activity_date)
              .startOf('isoWeek')
              .format('YYYY-MM-DD')
          ].push({
            minutes: +activity.brisk_step_minutes,
            activity: 'activeMinutes',
            intensity: 'm',
            steps: 0,
            date_recorded: activity.activity_date,
          });
        }
      }
    });

    return monthlyActivities;
  }

  getActivitiesByWeekTotals(activities: any) {
    let weekTotals = [];
    let topIndex = 0;

    
    Object.keys(activities)
    .sort()
    .forEach( (key)  =>{
      if (activities.hasOwnProperty(key)) {
        let weekTotal: any = {
          date_recorded: '',
          minutes: 0,
        };

        weekTotal.date_recorded = moment(key).format('DD-MM-yy');
        // data
        activities[key].forEach((activity, acIndex) => {
          
          weekTotal.minutes += +activity.minutes;
        });

        weekTotals.push(weekTotal);
      }
      topIndex++;
    })

      

    return weekTotals;
  }

  getStepAverage(allActivities) {
    let totalStepsForAvg = 0;
    let totalStepsForAvgCount = 0;
    let averageSteps = 0;
    for (let day = 6; day > -1; day--) {
      allActivities.forEach((activity) => {
        if (
          moment(activity.date_recorded).format('YYYY-MM-DD') ==
          moment().subtract(day, 'days').format('YYYY-MM-DD')
        ) {
          totalStepsForAvg += +activity.steps;
        }
      });
    }

    averageSteps = Math.round(totalStepsForAvg / 7);

    return averageSteps;
  }
}
