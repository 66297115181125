import { AuthService } from './../auth/auth.service';
import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import {
  Subject,
  throwError,
  BehaviorSubject,
  ReplaySubject,
  forkJoin,
  Observable,
} from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ProgrammeService {
  currentProgramme = new ReplaySubject(1);
  currentUserProgrammes = new ReplaySubject(1);
  userId;
  currentUser: any;
  programmeContent: any = {};
  programme: any;

  cacheTime = new Date();
  cacheLifetime = 3 * 60 * 1000;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private router: Router,
    private userService: UserService
  ) {
    // Is a programme set?

    this.authService.user.subscribe((user) => {
      if (user) {
        this.userId = user.id;

        forkJoin({
          userProgrammes: this.getUserProgrammes(),
        }).subscribe(
          (responseData) => {
            const currentUserProgrammes = [];
            let cupSet = false;

            if (responseData.userProgrammes.length === 0) {
              this.router.navigate(['/no-programme']);
            }
            let lsProgramme = localStorage.getItem('currentProgramme');

            if (lsProgramme) {
              try {
                lsProgramme = JSON.parse(lsProgramme);
              } catch (e) {
                lsProgramme = null;
                localStorage.setItem('currentProgramme', null);
              }
            }
            let programmeExists = false;
            if (lsProgramme) {
              responseData.userProgrammes.forEach((userProgramme, index) => {
                if (
                  +userProgramme.programme_id === +lsProgramme['programme_id']
                ) {
                  programmeExists = true;
                }
              });
            }
            if (!programmeExists) {
              localStorage.setItem('currentProgramme', null);
              lsProgramme = null;
            }

            responseData.userProgrammes.forEach((programme, index) => {
              if (programme.config) {
                programme.config = JSON.parse(programme.config);
              }
              // we have it
              currentUserProgrammes.push(programme);
              if (cupSet === false) {
                if (!lsProgramme) {
                  this.setCurrentProgramme(programme);
                  cupSet = true;
                } else {
                  if (
                    +lsProgramme['programme_id'] === +programme.programme_id
                  ) {
                    this.setCurrentProgramme(programme);
                    cupSet = true;
                  }
                }
              }
            });

            this.currentUserProgrammes.next(currentUserProgrammes);
          },
          (error) => {
            // console.log(error);
          }
        );
      }
    });
  }

  getCurrentProgramme() {
    return this.currentProgramme;
  }

  getUserProgrammes() {
    if (!this.userId) {
      return;
    }
    let searchParams = new HttpParams();
    searchParams = searchParams.append('user_id', this.userId);
    return this.http
      .get<any>(environment.apiUrl + '/user_programmes', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const userProgrammeIds = [];
          responseData['_embedded'].user_programmes.forEach((userProgramme) => {
            userProgrammeIds.push(userProgramme);
          });
          return userProgrammeIds;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  /*

  USER PROGRAMME

// which programme?
                 $http({method : 'GET',url : '/user_programmes?user_id=' + $scope.currentUser.user_id})
                .then(function successCallback(response) {
                    if(angular.isDefined(response.data._embedded)){
                    var userProgrammes = response.data._embedded.user_programmes;
                    var userProgrammeIds = []
                    var allProgrammes;
                    var currentUserProgrammes = [];
                    var currentUserProgramme = null;
                    userProgrammes.forEach(function(userProgramme, index){
                        userProgrammeIds.push(userProgramme.programme_id);
                    })
            
            
                    // get programmes...
                    $http({method : 'GET',url : '/programmes'})
                    .then(function successCallback(response) {
                        allProgrammes = response.data._embedded.programmes;
                
                    // now set it...
                    var cupSet = false;
                    allProgrammes.forEach(function(programme, index){
                        if(userProgrammeIds.indexOf(programme.programme_id) != -1){
                            // we have it
                            currentUserProgrammes.push(programme);
                            if(cupSet==false){
                                currentUserProgramme = programme;
                                cupSet = true;
                            }
                        }
                    })
                    
                    if(currentUserProgramme==null)
                    {
                        // no programmes for this user...
                        $state.go('noProgramme');
                    }
                    
                    $scope.setCurrentProgrammes(currentUserProgrammes);
                    var lsProgramme = localStorageService.get('currentProgramme');
                    
                    
                    if(!lsProgramme){
                        $scope.setCurrentProgramme(currentUserProgramme);
                    }
                    else{
                        
                        // load it first...
                         $http({method : 'GET',url : '/programmes/' + lsProgramme.programme_id})
                        .then(function successCallback(response) {
                            //console.log('loading programme')
                            $scope.setCurrentProgramme(response.data);
                        })
                        
                    }

  */

  getProgramme(id: number): Observable<any> {
    const now = new Date();
    if (
      !this.programme ||
      Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime
    ) {
      this.cacheTime = new Date();

      this.programme = this.http
        .get<any>(environment.apiUrl + '/programmes/' + id, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            return responseData;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.programme;
  }

  setCurrentProgramme(programme: any) {
    localStorage.setItem('currentProgramme', JSON.stringify(programme));
    this.currentProgramme.next(programme);

    // do they need to setup the programme?
    this.userService.userData.subscribe((userDataResponse) => {
      if (userDataResponse) {
        this.currentUser = userDataResponse;

        if (!this.currentUser.mobility1 && !this.currentUser.sitting_weekday) {
          if (programme.config.exerciseProgramme == '1') {
            this.router.navigate(['/setup-ep']);
          }
        }
        // gu1de setup
        if (!this.currentUser.gu1de_reason) {
          if (programme.config.gu1de == '1') {
            this.router.navigate(['/setup-gu1de']);
          }
        }
      }
    });

    if (programme.status == '0') {
      this.router.navigate(['/programme-offline']);
    }
  }

  getProgrammes() {
    return this.http
      .get<any>(environment.apiUrl + '/programmes', {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const programmeArray = [];
          responseData['_embedded'].programmes.forEach((programme) => {
            programmeArray.push(programme);
          });
          return programmeArray;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  getProgrammeContent(programmeId): Observable<any> {
    const now = new Date();
    if (
      !this.programmeContent[programmeId] ||
      Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime
    ) {
      this.cacheTime = new Date();
      this.programmeContent[programmeId] = this.http
        .get<any>(
          environment.apiUrl + '/user_programme_details/' + programmeId,
          {
            responseType: 'json',
          }
        )
        .pipe(
          map((responseData: any) => {
            return responseData;
          }),
          catchError((errorRes: any) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.programmeContent[programmeId];
  }

  clearCache() {
    this.programmeContent = {};
    this.programme = null;
  }
}
