import { Component, OnInit } from '@angular/core';
import { environment } from './../../environments/environment';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../services/user.service';
import { ProgrammeService } from '../services/programme.service';
import { UserSettingsService } from '../services/user-settings.service';
import { ActivityService } from '../services/activity.service';
import { TargetService } from '../services/target.service';
import { DateHelperService } from '../services/date-helper.service';

@Component({
  selector: 'app-target-ep',
  templateUrl: './target-ep.component.html',
  styleUrls: ['./target-ep.component.scss'],
})
export class TargetEpComponent implements OnInit {
  target = 10000;
  originalTarget;
  currentTargetObj;
  myActivity;
  startDate;
  endDate;
  lastWeek;
  currentProgramme;
  currentUser;
  isFetching;
  errors;
  perosnalisedTarget;
  algorithmReady = 0;
  algorithmDaysRemaining = 3;
  message = '';

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private userSettingsService: UserSettingsService,
    private activityService: ActivityService,
    private targetService: TargetService,
    private dateHelperService: DateHelperService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const now = moment();

    // what is the day today
    this.startDate = moment(now).subtract(1, 'weeks');
    this.lastWeek = this.dateHelperService.getWeekDayFor(now);
    this.startDate = moment(this.startDate).format('YYYY-MM-DD');
    this.endDate = moment(now).subtract(1, 'days').format('YYYY-MM-DD');

    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (this.currentProgramme.config.exerciseProgramme) {
         // this.router.navigate(['/target-ep']);
        }

        // do it
        const threeDaysAgo = moment().subtract('3', 'days');
        const weekAgo = moment().subtract('7', 'days');
        const created = moment(user.created);
        let startDay = created;
        if (threeDaysAgo > created) {
          startDay = threeDaysAgo;
        }
        this.startDate = moment(startDay).format('YYYY-MM-DD');
        this.endDate = moment(now).subtract(1, 'days').format('YYYY-MM-DD');

        // get monday last week
        const lastMonday = moment().isoWeekday(-6);
        const lastSunday = moment(lastMonday).add(6, 'days');
        const lastLastMonday = moment().isoWeekday(-13);

        this.isFetching = true;
        forkJoin({
          userSettings: this.userSettingsService.fetchAll(),
          activity: this.activityService.getActivity(0),
          target: this.targetService.getTarget('step'),
          activitySummary: this.activityService.getActivitySummary(
            this.startDate,
            this.endDate
          ),
          activityBetween: this.activityService.getActivityBetween(
            this.startDate,
            this.endDate
          ),
          activityLastWeek: this.activityService.getActivitySummary(
            lastMonday.format('YYYY-MM-DD'),
            lastSunday.format('YYYY-MM-DD')
          ),
          activityLastLastWeek: this.activityService.getActivitySummary(
            lastLastMonday.format('YYYY-MM-DD'),
            lastSunday.format('YYYY-MM-DD')
          ),
        }).subscribe(
          (responseData) => {
            this.isFetching = false;
            this.currentTargetObj = responseData.target;
            this.myActivity = responseData.activitySummary[0];
            if (this.algorithmReady == 0 && !this.currentTargetObj) {
              // count the days
              let returnDays = [];
              this.algorithmDaysRemaining = 3;
              for (let i = 0; i < 4; i++) {
                let dayStatus = 0;

                responseData.activityBetween.forEach((activity) => {
                  if (
                    activity.activity_date ==
                      moment(startDay).add(i, 'days').format('YYYY-MM-DD') &&
                    activity.steps > 0 &&
                    moment(startDay).add(i, 'days').format('YYYY-MM-DD') <
                      moment().format('YYYY-MM-DD')
                  ) {
                    dayStatus = 1;
                    this.algorithmDaysRemaining--;
                  }
                });
                returnDays.push({
                  day: moment(startDay).add(i, 'days'),
                  status: dayStatus,
                });
              }
              if (this.algorithmDaysRemaining < 1) {
                this.algorithmReady = 1;
              }
            }

            if (this.algorithmReady == 1) {
              // console.log(this.myActivity.step_avg);
              if(+this.myActivity.step_avg<2000){
                this.perosnalisedTarget = Math.round(
                  +this.myActivity.step_avg + 100
                );
              }
              else{
              this.perosnalisedTarget = Math.round(
                +this.myActivity.step_avg + 500
              );
              }

              if (this.perosnalisedTarget >= 10000) {
                Swal.fire({
                  title: 'Congratulations',
                  text: 'Congratulations you are consistently achieving 10,000 or more steps a day, keep this up!  Now it is time to look at your workouts and your sitting time to try and improve your overall physical activity levels throughout the day.',
                  icon: 'success',
                });

                this.perosnalisedTarget = 10000;
              }
            }

            if (this.currentTargetObj) {
              
              // can they update based on last week?
              if (
                this.currentTargetObj.created < lastSunday.format('YYYY-MM-DD')
              ) {
                if (
                  +responseData.activityLastLastWeek[0].step_avg >=
                    +this.currentTargetObj.target &&
                  +responseData.activityLastWeek[0].step_avg >=
                    +this.currentTargetObj.target
                ) {


                  if(+responseData.activityLastWeek[0].step_avg<2000){
                    this.perosnalisedTarget = Math.round(
                      +responseData.activityLastWeek[0].step_avg + 100
                    );
                  }
                  else{
                    this.perosnalisedTarget = Math.round(
                      +responseData.activityLastWeek[0].step_avg + 500
                    );
                  }

                  


                  if (this.perosnalisedTarget >= 10000) {this.perosnalisedTarget = 10000;}
                  this.message =
                    'Congratulations! You have achieved your step count goal for 2 weeks (' +
                    moment(lastLastMonday).format('ddd Do MMM') +
                    ' - ' +
                    moment(lastSunday).format('ddd Do MMM') +
                    '), you are now ready to progress.  Your revised target is ' +
                    this.perosnalisedTarget +
                    ' steps';
                } else if (
                  +responseData.activityLastWeek[0].step_avg >=
                  +this.currentTargetObj.target
                ) {

                  this.message =
                    'Well done you have been achieving your step count goal for 1 week (' +
                    moment(lastMonday).format('ddd Do MMM') +
                    ' - ' +
                    moment(lastSunday).format('ddd Do MMM') +
                    '). If you stay on track you will be ready to progress in one week. Keep up the good work!';
                } else if (
                  responseData.activityLastWeek[0].step_avg <
                    +this.currentTargetObj.target &&
                  this.currentUser.created < lastMonday.format('ddd Do MMM')
                ) {
                  if(responseData.activityLastWeek[0].step_avg < 500){
                    this.perosnalisedTarget = Math.round(
                      +this.currentTargetObj.target - 500
                    );
                  }
                  else{


                    if(+responseData.activityLastWeek[0].step_avg<2000){
                      this.perosnalisedTarget = Math.round(
                        +responseData.activityLastWeek[0].step_avg + 100
                      );
                    }
                    else{
                      this.perosnalisedTarget = Math.round(
                        +responseData.activityLastWeek[0].step_avg + 500
                      );
                    }

                  }
                  this.message =
                    'You have not yet consistently reached your step count goal over the past week (' +
                    moment(lastMonday).format('ddd Do MMM') +
                    ' - ' +
                    moment(lastSunday).format('ddd Do MMM') +
                    ').  Your revised target is ' +
                    this.perosnalisedTarget +
                    ' steps';
                }
              }
              if (this.perosnalisedTarget >= 10000) {
                Swal.fire({
                  title: 'Congratulations',
                  text: 'Congratulations you are consistently achieving 10,000 or more steps a day, keep this up!  Now it is time to look at your workouts and your sitting time to try and improve your overall physical activity levels throughout the day.',
                  icon: 'success',
                });

                this.perosnalisedTarget = 10000;
              }
            }
          },
          (error) => {
            this.isFetching = false;
            this.errors.push(error.message);
          }
        );
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });
    }
  }

  getTargetFormatted(num) {
    const size = 5;
    return ('00000' + num).substr(-size);
  }

  targetUp() {
    this.target = this.target * 1 + 100;
  }

  targetDown() {
    if (this.target > 100) {
      this.target = this.target - 100;
    }
  }

  submitTarget(target) {
    this.targetService.create(target, 'step', 'steps').subscribe(
      (responseData) => {
        Swal.fire({
          title: 'Target Set',
          text: 'Your new target has been set, now go and get moving!',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false,
        });

        this.router.navigate(['dashboard-ep']);

        // new target set, do we need to reset hit goal?

        this.activityService
          .getActivityBetween(
            moment().format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD')
          )
          .subscribe((activityResponse) => {
            const activity = activityResponse[0];
            if (activity) {
              if (activity.hit_goal == 1 && +this.target > activity.steps) {
                this.activityService
                  .update(activity.user_activity_id, activity.steps, 0)
                  .subscribe((hitGoalResponse) => {});
              }
            }
          });
      },
      (error) => {}
    );
  }
}
