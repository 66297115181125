import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserShortFesService {
  error = new Subject<string>();
  slug = '/user_short_fess';
  resourceName = 'user_short_fess';
  allShortFess?: any;
  short_fessBetween?: any;

  constructor(private http: HttpClient) {}

  fetchAll(): any {
    if (!this.allShortFess) {
      this.allShortFess = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: any[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allShortFess;
  }

  clearCache() {
    this.allShortFess = null;
    this.short_fessBetween = null;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(short_fes: string, date_recorded: string) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      short_fes,
    };
    return this.http.post<any>(environment.apiUrl + this.slug, payload, {
      observe: 'response',
    });
  }

  update(id: number, short_fes: string, date_recorded: string) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      short_fes,
    };
    return this.http.patch<any>(
      environment.apiUrl + this.slug + '/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + '/' + id
    );
  }

  getRag(short_fes) {
    let ragColour = '';

    if (+short_fes <9) {
      ragColour = 'green';
    } else if (+short_fes > 8 && +short_fes < 14) {
      ragColour = 'amber';
    } else {
      ragColour = 'red';
    }

    return ragColour;
  }

  getRangeforColor(colour: string) {
    let ragRange = '';
    if (colour == 'green') {
      ragRange = '<9';
    } else if (colour == 'amber') {
      ragRange = '9-13';
    } else {
      ragRange = '>13';
    }

    return ragRange;
  }
}
