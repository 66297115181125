export class User {
    constructor(
      public email: string,
      public id: string,
      public first_name: string,
      public last_name: string,
      public created: string,
      private app_token: string,
      public api_token: string,
      private _tokenExpirationDate: Date,
      public avatar: string
    ) {}

    
    getToken() {
      if (!this._tokenExpirationDate || new Date() > this._tokenExpirationDate) {
        return null;
      }
      return this.api_token;
    }

    setToken(token){
      this.api_token = token;
    }
    
  }
