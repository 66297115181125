<div
*ngIf="currentProgramme"
class="bg-full d-block d-sm-none"
[ngStyle]="{
  'background-image': 'url(' + environment.assetUrl + '/assets/' + currentProgramme.dashboard_bg + ')'
}"
></div>
<div *ngIf="isFetching">
  <app-loading-spinner [inline]="1"></app-loading-spinner>
</div>
<div *ngIf="currentUser">

  <div class="container container-app">
    <div>
      <div
        class="well well-default text-center d-block d-sm-none"
        *ngIf="!hideTrackerDB || currentUser.buddy || deviceSynced"
      >
        <div ng-hide="deviceSynced === null">
          <div class="masthead home-masthead">
            <div *ngIf="!deviceSynced">
              <div *ngIf="!hideTrackerDB">
                <div class="masthead-strap">CONNECT YOUR DEVICE</div>
                <br />
                <p class="masthead-subtext">
                  To get the most out of the program<span
                    *ngIf="environment.country != 'AUS'"
                    >me</span
                  >
                  we recommend connecting an activity tracker to your account.
                </p>
                <p class="masthead-subtext">
                  We currently support FitBit, Garmin and Google Fit.
                </p>
                <br />
                <div class="text-center">
                  <a
                    role="button"
                    [routerLink]="['/setup-device']"
                    class="btn btn-primary btn-lg"
                  >
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['fas', 'watch-fitness']"></fa-icon
                    ></span>
                    SETUP DEVICE</a
                  >
                  <a
                    role="button"
                    class="btn btn-primary btn-lg"
                    (click)="hideDbTracker()"
                  >
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['fas', 'times']"></fa-icon
                    ></span>
                    HIDE</a
                  >
                </div>
                <br /><br /><br />
              </div>

              <p class="masthead-subtext">
                You can still view your challenges without connecting your
                device but you won't appear on the leaderboards.
              </p>

              <div class="text-center">
                <a role="button" [routerLink]="['/steps']" class="btn btn-primary btn-lg">
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'shoe-prints']"></fa-icon
                  ></span>
                  MY STEPS</a
                >
                <a
                  *ngIf="!currentUser.buddy && currentUser.manual_steps != 1"
                  role="button"
                  [routerLink]="['/challenges-individual']"
                  class="btn btn-primary btn-lg"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'trophy']"></fa-icon
                  ></span>
                  CHALLENGES</a
                >
                <a
                  *ngIf="currentUser.buddy && currentUser.manual_steps != 1"
                  role="button"
                  [routerLink]="['/friends-and-family']"
                  class="btn btn-primary btn-lg"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'trophy']"></fa-icon
                  ></span>
                  CHALLENGES</a
                >
                <a
                  *ngIf="currentUser.manual_steps == 1"
                  role="button"
                  [routerLink]="['/steps']"
                  ng-click="rowUDS.booleanVal=true"
                  class="btn btn-primary btn-lg"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'shoe-prints']"></fa-icon
                  ></span>
                  UPDATE STEPS</a
                >
              </div>
            </div>

            <div *ngIf="deviceSynced && !currentTarget">
              <div class="masthead-strap">PLEASE SET A STEP TARGET</div>
              <a
                role="button"
                [routerLink]="['/target']"
                class="btn btn-primary btn-lg btn-block"
              >
                <span class="icon-wrap"
                  ><fa-icon [icon]="['fas', 'bullseye']"></fa-icon
                ></span>
                SET TARGET</a
              >
            </div>

            <div
              *ngIf="
                deviceSynced &&
                currentTarget &&
                +currentTarget > +currentActivity.steps
              "
            >
              <div class="masthead-figure masthead-figure-xb">
                <div class="masthead-icon">
                  <img
                    src="assets/img/icon-shoe.png"
                    width="81"
                    height="55"
                    class="icon-shoe"
                  />
                </div>
                {{ currentTarget - currentActivity.steps | number }}
              </div>
              <div class="masthead-strap">STEPS REMAINING TODAY</div>

              <div class="progress">
                <div
                  class="progress-bar progress-bar-accent"
                  role="progressbar"
                  [attr.aria-valuenow]="
                    (currentActivity.steps / currentTarget) * 100 | number
                  "
                  [attr.aria-valuemin]="0"
                  [attr.aria-valuemax]="100"
                  [ngStyle]="{
                    width: (currentActivity.steps / currentTarget) * 100 + '%'
                  }"
                >
                  <span class="sr-only"
                    >{{
                      (currentActivity.steps / currentTarget) * 100 | number
                    }}% Complete</span
                  >
                </div>
              </div>
              <p class="masthead-subtext">
                <strong>{{ currentActivity.steps | number }}</strong> of
                <strong>{{ currentTarget | number }}</strong> daily steps target
                completed
              </p>
            </div>

            <div
              *ngIf="
                deviceSynced &&
                currentTarget &&
                +currentTarget <= +currentActivity.steps
              "
            >
              <div class="masthead-figure masthead-figure-xb">
                <span class="icon-wrap hit-goal"
                  ><fa-icon [icon]="['fas', 'star']"></fa-icon
                ></span>
                {{ currentActivity.steps | number
                }}<span class="masthead-strap"> STEPS</span>
              </div>
              <div class="masthead-strap">CONGRATULATIONS!</div>
              <p class="masthead-subtext">
                You have hit your
                <strong>{{ currentTarget | number }}</strong> daily steps target
              </p>
            </div>
          </div>

          <div class="row row-btns-duo" *ngIf="deviceSynced && currentTarget">

            <ng-container *ngIf="currentProgramme.config.stepBands">
              <div class="col-6">
                <a
                  role="button"
                  [routerLink]="['/steps']"
                  class="btn btn-primary btn-lg btn-block"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'shoe-prints']"></fa-icon
                  ></span>
                  MY STEPS</a
                >
              </div>
              <div class="col-6">
                <a
                  *ngIf="!currentUser.buddy && currentUser.manual_steps != 1"
                  role="button"
                  [routerLink]="['/challenges-individual']"
                  class="btn btn-primary btn-lg btn-block"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'trophy']"></fa-icon
                  ></span>
                  CHALLENGES</a
                >
                <a
                  *ngIf="currentUser.buddy && currentUser.manual_steps != 1"
                  role="button"
                  [routerLink]="['/friends-and-family']"
                  class="btn btn-primary btn-lg btn-block"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'trophy']"></fa-icon
                  ></span>
                  CHALLENGES</a
                >
                <a
                  *ngIf="currentUser.manual_steps == 1"
                  role="button"
                  [routerLink]="['/steps']"
                  ng-click="rowUDS.booleanVal=true"
                  class="btn btn-primary btn-lg"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'edit']"></fa-icon
                  ></span>
                  UPDATE STEPS</a
                >
              </div>
            </ng-container>

            <ng-container *ngIf="!currentProgramme.config.stepBands">
              <div class="col-8 col-offset-2">
                <a
                  role="button"
                  [routerLink]="['/steps']"
                  class="btn btn-primary btn-lg btn-block"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'shoe-prints']"></fa-icon
                  ></span>
                  MY STEPS</a
                >
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <!--  learning content tile -->
      <div
        class="well well-default text-center d-block d-sm-none"
        *ngIf="
          !currentUser.buddy &&
          hideTrackerDB &&
          (!deviceSynced || deviceSynced === null)
        "
      >
        <div class="masthead">
          <div class="masthead-figure masthead-figure-xb">
            <div class="masthead-icon">
              <span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'graduation-cap']"></fa-icon
              ></span>
            </div>
            {{ learningSessionProgressTotal | number }}%
          </div>
          <div class="masthead-strap">PAGES VIEWED</div>

          <div class="progress">
            <div
              class="progress-bar progress-bar-accent"
              role="progressbar"
              [attr.aria-valuenow]="learningSessionProgressTotal | number"
              aria-valuemin="0"
              aria-valuemax="100"
              style="width: {{ learningSessionProgressTotal | number }}%;"
            >
              <span class="sr-only"
                >{{ learningSessionProgressTotal | number }}% Complete</span
              >
            </div>
          </div>
          <p
            class="masthead-subtext"
            *ngIf="
              lastViewed && lastViewedPage.parent.type == 'learningSession'
            "
          >
            <strong>Last Read:</strong>
            {{ lastViewedPage.parent.booster_session_title }}
          </p>
        </div>

        <div class="row" *ngIf="lastViewed">
          <div>
            <div class="col-8 col-offset-2">
              <a
                class="btn btn-primary btn-lg btn-block"
                [routerLink]="['/learning-session-steps', 'view', lastViewedPage.booster_session_step_id]"
                *ngIf="
                  lastViewed.booster_session_id &&
                  lastViewedPage.parent.type == 'learningSession'
                "
                >CONTINUE</a
              >

              <!--span
              ng-repeat=" booster in programmeContent.booster_sessions | filter: { release_date: 'O' }"
              *ngIf="
                $index == learningSessionShowMob &&
                lastViewed.booster_session_id &&
                lastViewedPage.parent.type != 'learningSession'
              "
            >
              <a
                class="btn btn-primary btn-lg btn-block"
                ui-sref="learningSessionsIndex({boosterSessionId : {{
                  booster.booster_session_id
                }}})"
                >CONTINUE</a
              >
            </span-->
            </div>
          </div>
        </div>
      </div>
      <!-- end lct -->

      <!-- end DB-->
    </div>

    <!-- last viewed test -->

    <div *ngIf="lastViewed && !newAlerts" class="d-block d-sm-none">
      <!-- Have they completed?-->
      <div class="fixed-bottom fb-sidenav">
        <div
          *ngIf="
            currentProgramme.config.certificate &&
            learningSessionTotals.completed > 0 &&
            learningSessionTotals.completed ==
              learningSessionTotals.available +
                learningSessionTotals.upcoming &&
            boosterSessionTotals.completed ==
              boosterSessionTotals.available + boosterSessionTotals.upcoming
          "
        >
          <div class="well well-notification">
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'trophy']"></fa-icon
            ></span>
            <div class="notification-content">
              <div class="notification-title">CERTIFICATE OF COMPLETION</div>
              <a
                class="btn btn-primary"
                style="margin-left: 10px"
                href="/download-certificate/{{ currentProgramme.programme_id }}"
                >DOWNLOAD</a
              >
              <div class="notification-text" style="min-height: 38px">
                You have completed all the Learning Sessions and Booster
                Sessions
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="
            !(
              currentProgramme.config.certificate &&
              learningSessionTotals.completed > 0 &&
              learningSessionTotals.completed ==
                learningSessionTotals.available +
                  learningSessionTotals.upcoming &&
              boosterSessionTotals.completed ==
                boosterSessionTotals.available + boosterSessionTotals.upcoming
            )
          "
        >
          <div class="well well-notification">
            <span
              class="icon-wrap"
              *ngIf="lastViewedPage.parent.type == 'boosterSession'"
              ><fa-icon [icon]="['fas', 'graduation-cap']"></fa-icon
            ></span>
            <span
              class="icon-wrap"
              *ngIf="lastViewedPage.parent.type == 'learningSession'"
              ><fa-icon [icon]="['fas', 'bolt']"></fa-icon
            ></span>
            <span
              class="icon-wrap"
              *ngIf="
                lastViewedPage.parent.type != 'learningSession' &&
                lastViewedPage.parent.type != 'boosterSession'
              "
              ><fa-icon [icon]="['fas', 'book']"></fa-icon
            ></span>

            <div class="notification-content">
              <div class="notification-title">LAST VIEWED</div>
              <a
                class="btn btn-primary"
                style="margin-left: 10px"
                [routerLink]="['/booster-session-steps', 'view', lastViewedPage.booster_session_step_id]"
                *ngIf="
                  lastViewed.booster_session_id &&
                  lastViewedPage.parent.type == 'boosterSession'
                "
                >VIEW</a
              >
              <a
                class="btn btn-primary"
                style="margin-left: 10px"
                [routerLink]="['/learning-session-steps', 'view', lastViewedPage.booster_session_step_id]"
                *ngIf="
                  lastViewed.booster_session_id &&
                  lastViewedPage.parent.type == 'learningSession'
                "
                >VIEW</a
              >
              <a
                class="btn btn-primary"
                style="margin-left: 10px"
                [routerLink]="['/educational-reading', 'page', lastViewedPage.content_page_id]"
                *ngIf="lastViewed.content_section_id"
                >VIEW</a
              >

              <div
                class="notification-text"
                style="min-height: 38px"
                *ngIf="
                  lastViewed.booster_session_id &&
                  lastViewedPage.parent.type == 'boosterSession'
                "
              >
                {{ lastViewedPage.parent.booster_session_title }} &gt;
                {{ lastViewedPage.title }}
              </div>
              <div
                class="notification-text"
                style="min-height: 38px"
                *ngIf="
                  lastViewed.booster_session_id &&
                  lastViewedPage.parent.type == 'learningSession'
                "
              >
                {{ lastViewedPage.parent.booster_session_title }} &gt;
                {{ lastViewedPage.title }}
              </div>
              <div
                class="notification-text"
                style="min-height: 38px"
                *ngIf="lastViewed.content_section_id"
              >
                {{ lastViewedPage.parent.content_section_title }} &gt;
                {{ lastViewedPage.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-none d-sm-block">
      <nav
        class="subnav ng-scope subnav-db"
        ng-hide="!deviceSynced && hideTrackerDB"
      >
        <h2>
          <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'walking']"></fa-icon
          ></span>
          ACTIVITY
        </h2>
      </nav>

      <div *ngIf="!hideTrackerDB">
        <div class="row dashboard-row" *ngIf="!deviceSynced && currentUser.manual_steps != 1">
          <div class="col-lg-4 col-md-6">
            <div class="no-tracker bg-accent masthead-db" style="height: 280px">
              <span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'exclamation-circle']"></fa-icon
              ></span>
              <h3>PLEASE SETUP YOUR<br />FITNESS TRACKER</h3>
              <p>
                To get the full benefit of the program<span
                  *ngIf="environment.country != 'AUS'"
                  >me</span
                >, please set up your fitness tracker and you can take advantage
                of our Activity section.
              </p>
              <a role="button" [routerLink]="['/setup-device']" class="btn btn-primary">
                <span class="icon-wrap"
                  ><fa-icon [icon]="['fas', 'watch-fitness']"></fa-icon
                ></span>
                SETUP DEVICE</a
              >
              <a role="button" [routerLink]="['/setup-device']" class="btn btn-primary">
                <span class="icon-wrap"
                  ><fa-icon [icon]="['fas', 'hand-pointer']"></fa-icon
                ></span>
                MANUAL</a
              >
            </div>
          </div>
          <div class="col-lg-8 col-md-6">
            <div
              style="
                height: 280px;
                background-image: url('/assets/img/bg-tracker.jpg');
                background-size: cover;
                position: relative;
              "
            >
              <a href="#" (click)="$event.preventDefault();hideDbTracker()" class="hide-db-tracker"
                ><span class="icon-wrap"
                  ><fa-icon [icon]="['fas', 'times']"></fa-icon
                ></span>
                Don’t show me this section again</a
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row dashboard-row" *ngIf="deviceSynced || currentUser.manual_steps == 1">
        <div class="col-lg-4 col-6">
          <div class="masthead masthead-accent masthead-db masthead-steps">
            <h3>STEPS REMAINING TODAY</h3>
            <div *ngIf="currentActivity && currentTarget">
              <div *ngIf="currentTarget > currentActivity.steps">
                <div class="masthead-figure masthead-figure-xb">
                  <div class="masthead-icon">
                    <img
                      src="assets/img/icon-shoe.png"
                      width="71"
                      class="icon-shoe"
                    />
                  </div>
                  {{ currentTarget - currentActivity.steps | number }}
                </div>

                <div class="progress">
                  <div
                    class="progress-bar progress-bar-accent"
                    role="progressbar"
                    aria-valuenow="
                    (currentActivity.steps / currentTarget) * 100 | number
                  "
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style="width: {{
                      (currentActivity.steps / currentTarget) * 100 | number
                    }}%;"
                  >
                    <span class="sr-only"
                      >{{
                        (currentActivity.steps / currentTarget) * 100 | number
                      }}% Complete</span
                    >
                  </div>
                </div>
                <p class="masthead-subtext">
                  <strong>{{ currentActivity.steps | number }}</strong> of
                  <strong>{{ currentTarget | number }}</strong> daily steps
                  target completed
                </p>
              </div>
              <div
                *ngIf="currentTarget <= currentActivity.steps && currentTarget"
              >
                <div class="masthead-figure masthead-figure-xb">
                  <span class="icon-wrap hit-goal"
                    ><fa-icon [icon]="['fas', 'star']"></fa-icon
                  ></span>
                  {{ currentActivity.steps | number
                  }}<span class="masthead-strap"> STEPS</span>
                </div>
                <div class="masthead-strap">CONGRATULATIONS!</div>
                <p class="masthead-subtext">
                  You have hit your
                  <strong>{{ currentTarget | number }}</strong> daily steps
                  target
                </p>
              </div>
            </div>

            <div *ngIf="!currentActivity && currentTarget">
              <p class="masthead-subtext">No step data yet</p>
            </div>

            <div *ngIf="!currentTarget">
              <p class="masthead-subtext">No step target set yet</p>
              <a role="button" [routerLink]="['/target']" class="btn btn-primary">
                <span class="icon-wrap"
                  ><fa-icon [icon]="['fas', 'bullseye']"></fa-icon
                ></span>
                SET TARGET</a
              >
            </div>
            <a role="button" [routerLink]="['/steps']" class="btn btn-primary">
              <span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'shoe-prints']"></fa-icon
              ></span>
              MY STEPS</a
            >
          </div>
        </div>
        <div class="col-lg-4 col-6 d-none d-lg-block">
          <!--div class="masthead masthead-accent masthead-db">
      <h3>STEPS PERFORMANCE</h3>
      <div *ngIf="currentActivity">
          <canvas id="line" class="chart chart-bar" chart-data="data" chart-labels="labels" chart-series="series" chart-options="options" chart-dataset-override="datasetOverride" chart-click="onClick" height="180"></canvas>
      
      </div>
      <div *ngIf="!currentActivity">
              <p class="masthead-subtext">No step data yet</p>
      </div>
      
              <a role="button" ui-sref="target" class="btn btn-primary"> <i class="fa fa-bullseye" aria-hidden="true"></i> UPDATE TARGET</a>
  </div-->
          <div class="masthead masthead-accent masthead-db masthead-steps">
            <h3>MINUTES REMAINING TODAY</h3>
            <div *ngIf="currentActivity && currentTargetMins">
              <div *ngIf="currentTargetMins > currentActivity.minutes">
                <div class="masthead-figure masthead-figure-xb">
                  <div class="masthead-icon">
                    <img
                      src="assets/img/icon-stopwatch.png"
                      width="60"
                      class="icon-shoe"
                    />
                  </div>
                  {{ currentTargetMins - currentActivity.minutes | number }}
                </div>

                <div class="progress">
                  <div
                    class="progress-bar progress-bar-accent"
                    role="progressbar"
                    aria-valuenow="
                    (currentActivity.minutes / currentTargetMins) * 100 | number
                  "
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style="width: {{
                      (currentActivity.minutes / currentTargetMins) * 100
                        | number
                    }}%;"
                  >
                    <span class="sr-only"
                      >{{
                        (currentActivity.minutes / currentTargetMins) * 100
                          | number
                      }}% Complete</span
                    >
                  </div>
                </div>
                <p class="masthead-subtext">
                  <strong>{{ currentActivity.minutes | number }}</strong> of
                  <strong>{{ currentTargetMins | number }}</strong> daily
                  minutes target completed
                </p>
              </div>
              <div
                *ngIf="
                  currentTargetMins <= currentActivity.minutes &&
                  currentTargetMins
                "
              >
                <div class="masthead-figure masthead-figure-xb">
                  <span class="icon-wrap hit-goal"
                    ><fa-icon [icon]="['fas', 'star']"></fa-icon></span
                  >{{ currentActivity.minutes | number
                  }}<span class="masthead-strap"> MINUTES</span>
                </div>
                <div class="masthead-strap">CONGRATULATIONS!</div>
                <p class="masthead-subtext">
                  You have hit your
                  <strong>{{ currentTargetMins | number }}</strong> daily
                  minutes target
                </p>
              </div>
            </div>
            <div *ngIf="!currentActivity && currentTargetMins">
              <p class="masthead-subtext">No minute data yet</p>
            </div>
            <div *ngIf="!currentTargetMins">
              <p class="masthead-subtext">No minute target set yet</p>
              <a
                role="button"
                [routerLink]="['/activity','minutes']"
                class="btn btn-primary"
              >
                <span class="icon-wrap"
                  ><fa-icon [icon]="['far', 'stopwatch']"></fa-icon
                ></span>
                SET TARGET</a
              >
            </div>
            <a
              role="button"
              [routerLink]="['/activity', 'minutes']"
              class="btn btn-primary"
            >
              <span class="icon-wrap"
                ><fa-icon [icon]="['far', 'stopwatch']"></fa-icon
              ></span>
              MY MINUTES</a
            >
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 visible-lg">
          <div *ngIf="currentUser.manual_steps != 1 && currentChallenge">
            <div class="masthead masthead-accent masthead-db">
              <h3>{{ currentChallenge.title | uppercase }}</h3>
              <div class="masthead-challenge">
                <img
                  src="./assets/img/icon-flag.png"
                  width="65"
                  class="masthead-icon"
                  style="left: 35px; top: 0px"
                />
                <span class="masthead-figure" style="font-size: 66px"
                  ><span *ngIf="!challengePos">&nbsp;</span>{{ challengePos
                  }}<span
                    class="ordinal"
                    style="font-size: 31px; position: absolute; top: 6px"
                    *ngIf="challengePos"
                    >{{ challengePos | uppercase }}</span
                  ></span
                >
              </div>

              <hr />
              <p class="masthead-subtext" *ngIf="!iLead">
                You are
                <strong>{{
                  currentLeader[0].step_count - myActivity.step_count | number
                }}</strong>
                steps <br />from 1st position
              </p>
              <p *ngIf="iLead">&nbsp;<br />&nbsp;</p>

              <a
                *ngIf="!currentUser.buddy"
                role="button"
                [routerLink]="['/challenges-individual']"
                class="btn btn-primary btn-lg"
              >
                <span class="icon-wrap"
                  ><fa-icon [icon]="['fas', 'trophy']"></fa-icon
                ></span>
                CHALLENGES</a
              >
              <a
                *ngIf="currentUser.buddy"
                role="button"
                [routerLink]="['/friends-and-family']"
                class="btn btn-primary btn-lg"
              >
                <span class="icon-wrap"
                  ><fa-icon [icon]="['fas', 'trophy']"></fa-icon
                ></span>
                CHALLENGES</a
              >
            </div>
          </div>

          <div *ngIf="currentUser.manual_steps == 1">
            <div
              class="masthead masthead-accent masthead-db masthead-manual-steps"
            >
              <h3>UPDATE MY STEP COUNT</h3>
              <div *ngIf="currentTarget">
                <form
                  name="stepsForm"
                  class="bs-form tracker-form"
                  (ngSubmit)="onSubmitStepsForm()"
                  #stepsForm="ngForm"
                >
                  <div class="input-group input-group-fa">
                    <span class="icon-wrap fa"
                      ><fa-icon [icon]="['fas', 'shoe-prints']"></fa-icon
                    ></span>
                    <input
                      type="number"
                      name="steps"
                      class="form-control input-tracker input-tracker-sm"
                      placeholder=""
                      id="steps"
                      [(ngModel)]="currentSteps"
                      #steps
                      min="0"
                      max="1000000000"
                      autocomplete="off"
                      ng-required=""
                      style="text-align: left"
                    />
                    <!--div
                    class="alert alert-danger"
                    *ngIf="
                      stepsForm.steps.$touched && stepsForm.steps.$invalid
                    "
                  >
                    This must be a number below 1000000000.
                  </div-->
                  </div>
                  <hr />
                  <div class="form-group input-group input-group-fa">
                    <span class="icon-wrap fa"
                      ><fa-icon [icon]="['far', 'calendar-edit']"></fa-icon
                    ></span>
                    <!--input
                        ng-click="open1()"
                        type="text"
                        class="form-control input-tracker input-tracker-sm"
                        ng-model="date"
                        is-open="popup1.opened"
                        datepicker-options="dateOptions"
                        ng-required="true"
                        close-text="Close"
                        alt-input-formats="altInputFormats"
                        #date
                      /-->
                    <input
                    name="date"
                      type="text"
                      placeholder="DD/MM/YYYY"
                      class="form-control input-tracker input-tracker-sm"
                      bsDatepicker
                      autocomplete="off"
                      [bsConfig]="{
                        dateInputFormat: 'DD/MM/YYYY',
                        containerClass: 'theme-cv',
                        showWeekNumbers: false
                      }"
                      [(ngModel)]="date"
                      required
                      [bsValue]="date"
                      [maxDate]="date"
                      #dateInput="ngModel"
                    />
                  </div>

                  <button
                    role="button"
                    href="#"
                    class="btn btn-primary btn-lg btn-white"
                  >
                    UPDATE STEPS
                  </button>
                </form>
              </div>
              <div *ngIf="!currentTarget">
                <p class="masthead-subtext">No step target set yet</p>
                <a role="button" [routerLink]="['/target']" class="btn btn-primary">
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'bullseye']"></fa-icon
                  ></span>
                  SET TARGET</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!currentUser.buddy">
        <nav class="subnav ng-scope subnav-db">
          <h2>
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'graduation-cap']"></fa-icon
            ></span>
            LEARNING SESSIONS
          </h2>
        </nav>
        <div class="row dashboard-row" *ngIf="programmeContent">
          <div class="col col-lg-8 col-6">
            <div class="row">
              <div
                class="col col-md-12 col-lg-6"
                *ngFor="
                  let booster of programmeContent.booster_sessions
                    | filterObjectArray: { release_date: 'O' };
                  index as index
                "
              >
                <div
                  class="reading-widget booster-widget"
                  style="min-height: 215px"
                  [ngClass]="{ 'd-none d-lg-block': index > startLSession }"
                  *ngIf="index >= startLSession && index < endLSession"
                >
                  <div
                    class="reading-widget-img"
                    [ngStyle]="{
                      'background-image':
                      'url(' + environment.assetUrl + '/assets/' +
                        booster.image_filename +
                        ')'
                    }"
                  ></div>

                  <h3>{{ booster.booster_session_title }}</h3>
                  <button
                    class="btn btn-primary"
                    *ngIf="booster.status === 'completed'"
                    [attr.aria-label]="
                      'Continue' + booster.booster_session_title
                    "
                    [routerLink]="['/learning-sessions', 'index', booster.booster_session_id]"
                  >
                    CONTINUE
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
                    ></span>
                  </button>
                  <button
                    class="btn btn-primary"
                    *ngIf="booster.status === 'available'"
                    [attr.aria-label]="'Begin' + booster.booster_session_title"
                    [routerLink]="['/learning-sessions', 'index', booster.booster_session_id]"
                  >
                    BEGIN
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
                    ></span>
                  </button>
                  <p
                    *ngIf="booster.status === 'unavailable'"
                    class="masthead-subtext tracker-subtext"
                  >
                    Not released yet
                  </p>
                </div>
              </div>
            </div>

            <div class="d-none d-lg-block text-center session-toggles">
              <ng-container
                *ngFor="
                  let booster of programmeContent.booster_sessions
                    | filterObjectArray: { release_date: 'O' };
                  index as index
                "
              >
                <a
                  *ngIf="index % 2 == 0"
                  (click)="onSetLSession(index)"
                  [ngClass]="{ 'circle-active': index == startLSession }"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'circle']"></fa-icon
                  ></span>
                </a>
              </ng-container>
            </div>
            <div
              class="d-none d-sm-block d-md-block d-lg-none text-center session-toggles"
            >
              <ng-container
                *ngFor="
                  let booster of programmeContent.booster_sessions
                    | filterObjectArray: { release_date: 'O' };
                  index as index
                "
              >
                <a
                  (click)="onSetLSession(index)"
                  [ngClass]="{ 'circle-active': index == startLSession }"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'circle']"></fa-icon></span
                ></a>
              </ng-container>
            </div>
          </div>
          <div class="col-6 col-lg-4">
            <div
              class="masthead masthead-accent db-tracker"
              style="margin-top: 0; min-height: 215px"
            >
              <h2>PROGRESS</h2>
              <div class="masthead-content" *ngIf="learningSessionTotals">
                <div class="progress-stat">
                  <span class="icon-wrap text-primary">
                    <fa-icon [icon]="['fas', 'check-circle']"></fa-icon>
                  </span>
                  <span class="progress-label">
                    <span>{{ learningSessionTotals.completed }}</span> completed learning sessions
                  </span>
                </div>
                <div class="progress-stat">
                  <span class="icon-wrap text-accent">
                    <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                  </span>
                  <span class="progress-label">
                    <span>{{ learningSessionTotals.available }}</span> available learning sessions
                  </span>
                </div>
              </div>
            </div>
            <div
              class="text-center"
              *ngIf="
                currentProgramme.config.certificate &&
                learningSessionTotals?.completed > 0 &&
                learningSessionTotals?.completed ==
                  learningSessionTotals?.available +
                    learningSessionTotals?.upcoming &&
                boosterSessionTotals?.completed ==
                  boosterSessionTotals?.available +
                    boosterSessionTotals?.upcoming
              "
            >
              <a
                class="btn btn-primary"
                href="/download-certificate/{{ currentProgramme.programme_id }}"
                >DOWNLOAD CERTIFICATE
                <span class="icon-wrap"
                  ><fa-icon [icon]="['fas', 'download']"></fa-icon></span
              ></a>
            </div>
          </div>
        </div>

        <nav class="subnav ng-scope subnav-db">
          <h2>
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'bolt']"></fa-icon
            ></span>
            BOOSTER SESSIONS
          </h2>
        </nav>

        <div class="row dashboard-row" *ngIf="programmeContent">
          <div class="col-lg-8 col-6">
            <div class="row">
              <div
                class="col-md-12 col-lg-6"
                *ngFor="
                  let booster of programmeContent.booster_sessions
                    | filterObjectArray: { release_date: 'O' }:false:true;
                  index as index
                "
              >
                <div
                  *ngIf="index >= startSession && index < endSession"
                  class="reading-widget booster-widget"
                  style="min-height: 215px"
                  [ngClass]="{ 'd-none d-lg-block': index > startSession }"
                >
                  <div
                    class="reading-widget-img"
                    [ngStyle]="{
                      'background-image':
                        'url(' + environment.assetUrl + '/assets/' +
                        booster.image_filename +
                        ')'
                    }"
                  ></div>
                  <h3>{{ booster.booster_session_title }}</h3>
                  <button
                    class="btn btn-primary"
                    *ngIf="booster.status == 'completed'"
                    [attr.aria-label]="
                      'Continue' + booster.booster_session_title
                    "
                    [routerLink]="['/booster-sessions', 'index', booster.booster_session_id]"
                  >
                    CONTINUE
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
                    ></span>
                  </button>
                  <button
                    class="btn btn-primary"
                    *ngIf="booster.status == 'available'"
                    [attr.aria-label]="'Begin' + booster.booster_session_title"
                    [routerLink]="['/booster-sessions', 'index', booster.booster_session_id]"
                  >
                    BEGIN
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
                    ></span>
                  </button>
                  <p
                    *ngIf="booster.status == 'unavailable'"
                    class="masthead-subtext tracker-subtext"
                  >
                    Not released yet
                  </p>
                </div>
              </div>
            </div>

            <div class="d-none d-lg-block text-center session-toggles">
              <ng-container
                *ngFor="
                  let booster of programmeContent.booster_sessions
                    | filterObjectArray: { release_date: 'O' }:false:true;
                  index as index
                "
              >
                <a
                  (click)="onSetSession(index)"
                  *ngIf="index % 2 == 0"
                  [ngClass]="{ 'circle-active': index == startSession }"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'circle']"></fa-icon></span
                ></a>
              </ng-container>
            </div>
            <div
              class="d-none d-sm-block d-md-block d-lg-none text-center session-toggles"
            >
              <ng-container
                *ngFor="
                  let booster of programmeContent.booster_sessions
                    | filterObjectArray: { release_date: 'O' }:false:true;
                  index as index
                "
              >
                <a
                  (click)="onSetSession(index)"
                  [ngClass]="{ 'circle-active': index == startSession }"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'circle']"></fa-icon></span
                ></a>
              </ng-container>
            </div>
          </div>
          <div class="col-6 col-lg-4">
            <div
              class="masthead masthead-accent db-tracker"
              style="margin-top: 0; min-height: 215px"
            >
              <h2>PROGRESS</h2>
              <div class="masthead-content" *ngIf="boosterSessionTotals">
                <div class="progress-stat">
                  <span class="icon-wrap text-primary"
                    ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon>
                  </span>
                  <span class="progress-label">
                    <span>{{ boosterSessionTotals.completed }}</span> completed booster sessions
                  </span>
                </div>
                <div class="progress-stat">
                  <span class="icon-wrap text-accent">
                    <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                  </span>
                  <span class="progress-label">
                    <span>{{ boosterSessionTotals.available }}</span> available booster sessions
                  </span>
                </div>
                <div class="progress-stat">
                  <span class="icon-wrap">
                    <fa-icon [icon]="['far', 'circle']"></fa-icon>
                  </span>
                  <span class="progress-label">
                    <span>{{ boosterSessionTotals.upcoming }}</span> upcoming booster sessions
                </span>
                </div>
              </div>
            </div>

            <div
              class="text-center"
              *ngIf="
                currentProgramme.config.certificate &&
                learningSessionTotals?.completed > 0 &&
                learningSessionTotals?.completed ==
                  learningSessionTotals?.available +
                    learningSessionTotals?.upcoming &&
                boosterSessionTotals?.completed ==
                  boosterSessionTotals?.available +
                    boosterSessionTotals?.upcoming
              "
            >
              <a
                class="btn btn-primary"
                href="/download-certificate/{{ currentProgramme.programme_id }}"
                >DOWNLOAD CERTIFICATE
                <span class="icon-wrap"
                  ><fa-icon [icon]="['fas', 'download']"></fa-icon></span
              ></a>
            </div>
          </div>
        </div>

        <nav class="subnav ng-scope subnav-db">
          <h2>
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'chart-line']"></fa-icon
            ></span>
            HEALTH TRACKERS
          </h2>
        </nav>
        <div class="row dashboard-row">
          <div
            class="col-lg-4 col-6"
            *ngIf="currentProgramme.config.trackers.indexOf('shape') > -1"
          >
            <div class="masthead masthead-accent db-tracker">
              <a class="tracker-link" [routerLink]="['/health-trackers', 'shape']">
                <h2>
                  <span class="icon-wrap" *ngIf="currentUser.gender == 'f' || !currentUser.gender">
                    <fa-icon [icon]="['fas', 'female']"></fa-icon>
                  </span>
                  <span class="icon-wrap" *ngIf="currentUser.gender == 'x' || currentUser.gender == 'm'">
                    <fa-icon [icon]="['fas', 'male']"></fa-icon>
                  </span>
                   <span class="tracker-label">SHAPE</span>
                </h2>
              </a>
              <div class="masthead-content">
                <div *ngIf="userShapes[0]">
                  <div class="row">
                    <div class="col-6">
                      <h3 class="tracker-title">BMI &amp; WEIGHT</h3>
                      <div class="row">
                        <div class="col-4">
                          <img src="./assets/img/icon-scales.png" width="40" />
                        </div>
                        <div class="col-8">
                          <div>
                            <span class="tracker-value tracker-value">{{
                              calcBmi(userShapes[0].weight) | number:'1.1-1'
                            }}</span
                            ><br /><span class="tracker-value tracker-value-sm"
                              >{{ userShapes[0].weight | number }}kg</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <h3 class="tracker-title">WAIST MEASUREMENT</h3>
                      <div class="row">
                        <div class="col-6">
                          <img
                            src="./assets/img/icon-tape-measure.png"
                            height="30"
                          />
                        </div>
                        <div class="col-6">
                          <div>
                            <span
                              class="tracker-value tracker-value"
                              *ngIf="environment.country != 'AUS'"
                              style="display: inline-block"
                              >{{ userShapes[0].waist | number:'1.0-0' }}”</span
                            >
                            <span
                              class="tracker-value tracker-value"
                              *ngIf="environment.country == 'AUS'"
                              style="display: inline-block"
                              >{{ userShapes[0].waist * 2.54 | number:'1.0-0'
                              }}<span style="font-size: 16px">cm</span></span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <p class="masthead-subtext tracker-subtext">
                        Last updated on
                        {{ userShapes[0].created | dateFormatter: "D" }}
                        {{ userShapes[0].created | dateFormatter: "MMM yyyy" }}
                      </p>
                    </div>
                  </div>
                </div>

                <div *ngIf="!userShapes[0]">
                  <p class="masthead-subtext tracker-subtext">
                    You haven't added any readings yet
                  </p>
                  <br />
                  <a
                    class="btn btn-primary btn-sm"
                    [routerLink]="['/health-trackers', 'shape']"
                    >ADD ENTRY</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-4 col-6"
            *ngIf="
              currentProgramme.config.trackers.indexOf('healthyEating') > -1
            "
          >
            <div class="masthead masthead-accent db-tracker">
              <a class="tracker-link" [routerLink]="['/health-trackers', 'healthy-eating']">
                <h2>
                  <span class="icon-wrap"><fa-icon [icon]="['fas', 'utensils']"></fa-icon></span>
                  <span class="tracker-label">HEALTHY EATING</span>
                </h2>
            </a>
              <div class="masthead-content">
                <div *ngIf="userHealthyEatings[0]">
                  <div class="row">
                    <div class="col-6">
                      <h3 class="tracker-title">HEALTHY EATING RATING</h3>
                      <div class="row">
                        <div class="col-4">
                          <img src="./assets/img/icon-food.png" width="35" />
                        </div>
                        <div class="col-8">
                          <div>
                            <span class="tracker-value tracker-value-lg">{{
                              userHealthyEatings[0].healthy_eating | number
                            }}</span
                            ><span
                              class="tracker-value tracker-value-md"
                              style="font-size: 12px"
                              >/10</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <h3 class="tracker-title">DAILY <br />FRUIT &amp; VEG</h3>
                      <div class="row">
                        <div class="col-4">
                          <img src="./assets/img/icon-veg.png" height="40" />
                        </div>
                        <div class="col-8">
                          <div>
                            <span class="tracker-value tracker-value-lg"
                              >{{ userHealthyEatings[0].five_a_day | number
                              }}<span class="tracker-value tracker-value-xs"
                                >OF YOUR<br />5-A-DAY</span
                              ></span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <p class="masthead-subtext tracker-subtext">
                        Last updated on
                        {{ userHealthyEatings[0].created | dateFormatter: "D" }}
                        {{ userHealthyEatings[0].created | dateFormatter: "MMM yyyy" }}
                      </p>
                    </div>
                  </div>
                </div>

                <div *ngIf="!userHealthyEatings[0]">
                  <p class="masthead-subtext tracker-subtext">
                    You haven't added any readings yet
                  </p>
                  <br />
                  <a
                    class="btn btn-primary btn-sm"
                    [routerLink]="['/health-trackers', 'healthy-eating']"
                    >ADD ENTRY</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-4 col-6"
            *ngIf="currentProgramme.config.trackers.indexOf('hba1c') > -1"
          >
            <div class="masthead masthead-accent db-tracker">
              <a class="tracker-link" [routerLink]="['/health-trackers', 'hba1c']">
                <h2>
                  <span class="icon-wrap"><fa-icon [icon]="['fas', 'tint']"></fa-icon></span>
                  <span class="tracker-label">HbA1c</span>
                </h2>
              </a>
              <div class="masthead-content">
                <div *ngIf="userHbA1cs[0]">
                  <div class="row">
                    <div class="col-12">
                      <h3 class="tracker-title">
                        LONG TERM <br />BLOOD GLUCOSE MEASURE
                      </h3>
                      <div class="row">
                        <div class="col-12">
                          <img
                            src="./assets/img/icon-finger-prick.png"
                            width="50"
                          />
                          <span
                            class="tracker-value tracker-value-lg tracker-value-hba1c"
                            >{{ userHbA1cs[0].glucose | number }}
                            <span style="font-size: 14px"> mmol/mol</span></span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <p class="masthead-subtext tracker-subtext">
                        Last updated on
                        {{ userHbA1cs[0].created | dateFormatter: "D" }}
                        {{ userHbA1cs[0].created | dateFormatter: "MMM yyyy" }}
                      </p>
                    </div>
                  </div>
                </div>

                <div *ngIf="!userHbA1cs[0]">
                  <p class="masthead-subtext tracker-subtext">
                    You haven't added any readings yet
                  </p>
                  <br />
                  <a
                    class="btn btn-primary btn-sm"
                    [routerLink]="['/health-trackers', 'hba1c']"
                    >ADD ENTRY</a
                  >
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-lg-4 col-6"
            *ngIf="currentProgramme.config.trackers.indexOf('bp') > -1"
          >
            <div class="masthead masthead-accent db-tracker">
              <a class="tracker-link" [routerLink]="['/health-trackers', 'blood-pressure']">
                <h2>
                  <span class="icon-wrap"><fa-icon [icon]="['fas', 'tachometer-alt']"></fa-icon></span>
                  <span class="tracker-label">BLOOD PRESSURE</span>
                </h2>
            </a>
              <div class="masthead-content">
                <div *ngIf="userBloodPressures[0]">
                  <div class="row">
                    <div class="col-4 nudge-right-10">
                      <h3 class="tracker-title-sm tracker-title-sm-nm">
                        SYSTOLIC
                      </h3>
                      <div class="row">
                        <div class="col-12 text-center">
                          <div>
                            <span class="tracker-value tracker-value-lg">{{
                              userBloodPressures[0].systolic | number
                            }}</span
                            ><br /><span class="tracker-value-sm-nf">mmHg</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <br />
                      <br />
                      <img src="./assets/img/icon-bp.png" width="70" />
                    </div>
                    <div class="col-4 nudge-left-10">
                      <h3 class="tracker-title-sm tracker-title-sm-nm">
                        DIASTOLIC
                      </h3>
                      <div class="row">
                        <div class="col-12 text-center">
                          <div>
                            <span class="tracker-value tracker-value-lg">{{
                              userBloodPressures[0].diastolic | number
                            }}</span
                            ><br /><span class="tracker-value-sm-nf">mmHg</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <p class="masthead-subtext tracker-subtext">
                        Last updated on
                        {{ userBloodPressures[0].created | dateFormatter: "D" }}
                        {{ userBloodPressures[0].created | dateFormatter: "MMM yyyy" }}
                      </p>
                    </div>
                  </div>
                </div>

                <div *ngIf="!userBloodPressures[0]">
                  <p class="masthead-subtext tracker-subtext">
                    You haven't added any readings yet
                  </p>
                  <br />
                  <a
                    class="btn btn-primary btn-sm"
                    [routerLink]="['/health-trackers', 'blood-pressure']"
                    >ADD ENTRY</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-4 col-6"
            *ngIf="currentProgramme.config.trackers.indexOf('cholesterol') > -1"
          >
            <div
              class="masthead masthead-accent masthead-cholesterol db-tracker"
            >
            <a class="tracker-link" [routerLink]="['/health-trackers', 'cholesterol']">
              <h2>
                <span class="icon-wrap"><fa-icon [icon]="['fas', 'heartbeat']"></fa-icon></span>
                <span class="tracker-label">CHOLESTEROL</span>
              </h2>
            </a>
              <div class="masthead-content">
                <br />
                <div *ngIf="userCholesterols[0]">
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-3">
                          <span
                            class="tracker-value tracker-value-md tracker-value-title"
                            >TOTAL</span
                          ><br />
                          <span class="tracker-value tracker-value-lg">{{
                            userCholesterols[0].cholesterol | number
                          }}</span
                          ><br />
                          <span class="tracker-value tracker-value-sm"
                            >mmol/L</span
                          >
                        </div>
                        <div class="col-3 nudge-left-10">
                          <img src="./assets/img/icon-artery.png" width="50" />
                        </div>
                        <div class="col-3 nudge-left-20">
                          <span
                            class="tracker-value tracker-value-md tracker-value-title"
                            >LDL</span
                          ><br />
                          <span class="tracker-value tracker-value-lg">{{
                            userCholesterols[0].ldl | number
                          }}</span
                          ><br />
                          <span class="tracker-value tracker-value-sm"
                            >mmol/L</span
                          >
                        </div>
                        <div class="col-3 nudge-left-20">
                          <span
                            class="tracker-value tracker-value-md tracker-value-title"
                            >HDL</span
                          ><br />
                          <span class="tracker-value tracker-value-lg">{{
                            userCholesterols[0].hdl | number
                          }}</span
                          ><br />
                          <span class="tracker-value tracker-value-sm"
                            >mmol/L</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <p class="masthead-subtext tracker-subtext">
                        Last updated on
                        {{ userCholesterols[0].created | dateFormatter: "D" }}
                        {{ userCholesterols[0].created | dateFormatter: "MMM yyyy" }}
                      </p>
                    </div>
                  </div>
                </div>

                <div *ngIf="!userCholesterols[0]">
                  <p class="masthead-subtext tracker-subtext">
                    You haven't added any readings yet
                  </p>
                  <br />
                  <a
                    class="btn btn-primary btn-sm"
                    [routerLink]="['/health-trackers', 'cholesterol']"
                    >ADD ENTRY</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-4 col-6"
            *ngIf="currentProgramme.config.trackers.indexOf('smoking') > -1"
          >
            <div
              class="masthead masthead-accent db-tracker"
            >
            <a class="tracker-link" [routerLink]="['/health-trackers', 'smoking']">
              <h2>
                <span class="icon-wrap" ><fa-icon [icon]="['fas', 'smoking-ban']"></fa-icon></span>
                <span class="tracker-label">SMOKING</span>
              </h2>
            </a>
              <div class="masthead-content">
                <div *ngIf="userSmokings[0]">
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-6">
                          <h3
                            class="tracker-title tracker-title-sm uppercase-title"
                            *ngIf="userSmokingType"
                            style="margin-bottom: 0; padding-bottom: 0"
                          >
                            {{ smokingLabels[userSmokingType] }} <br /><span
                              style="font-size: 16px"
                              >PER DAY</span
                            >
                          </h3>
                          <h3
                            class="tracker-title tracker-title-sm"
                            *ngIf="!userSmokingType"
                          >
                            YOU DON'T SMOKE
                          </h3>

                          <img
                            src="./assets/img/icon-no-smoking.png"
                            width="36"
                            class="smoking-icon"
                          /><span class="tracker-value tracker-value-lg">{{
                            userSmokings[0][userSmokingType] | number
                          }}</span>
                        </div>
                        <div class="col-6">
                          <div class="text-center">
                            <img
                              class="inline-block icon-piggy"
                              src="./assets/img/icon-piggy.png"
                              height="50"
                            />
                            <h3 class="inline-block tracker-title">
                              COST<br /><span style="font-size: 14px"
                                >PER YEAR</span
                              >
                            </h3>
                          </div>

                          <span class="tracker-value tracker-value-lg"
                            >{{ environment.currencySymbol
                            }}{{ cost | number:'1.0-0' }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <p
                    class="masthead-subtext tracker-subtext"
                    style="margin-top: 10px"
                  >
                    Last updated on
                    {{ userSmokings[0].created | dateFormatter: "D" }}
                    {{ userSmokings[0].created | dateFormatter: "MMM yyyy" }}
                  </p>
                  <br />
                </div>

                <div *ngIf="!userSmokings[0]">
                  <p class="masthead-subtext tracker-subtext">
                    You haven't added any readings yet
                  </p>
                  <br />
                  <a
                    class="btn btn-primary btn-sm"
                    [routerLink]="['/health-trackers', 'smoking']"
                    >ADD ENTRY</a
                  >
                </div>
                <div *ngIf="!userSmokings">
                  <p class="masthead-subtext tracker-subtext">
                    You need to update your smoking settings.
                  </p>
                  <br /><br />
                  <a
                    class="btn btn-primary btn-sm"
                    [routerLink]="['/health-trackers', 'smoking']"
                    >UPDATE SMOKING SETTINGS</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="fixed-bottom fb-sidenav">
  <div class="container">
    <div class="well well-notification" *ngFor="let alert of newAlerts">
      <span class="icon-wrap" *ngIf="alert.alert_type == 'update'"
        ><fa-icon [icon]="['fas', 'sync']"></fa-icon
      ></span>
      <span class="icon-wrap" *ngIf="alert.alert_type == 'info'"
        ><fa-icon [icon]="['fas', 'info']"></fa-icon
      ></span>
      <span class="icon-wrap" *ngIf="alert.alert_type == 'booster'"
        ><fa-icon [icon]="['fas', 'graduation-cap']"></fa-icon
      ></span>
      <div class="notification-content">
        <a role="button" (click)="onCloseAlert(alert)" class="btn btn-primary"
          ><span *ngIf="alert.button_title">{{
            alert.button_title | uppercase
          }}</span>
          <span *ngIf="!alert.button_title">CLOSE</span>
          <span class="icon-wrap" *ngIf="alert.alert_type == 'update'"
            ><fa-icon [icon]="['fas', 'sync']"></fa-icon
          ></span>
        </a>

        <div class="notification-title">{{ alert.title | uppercase }}</div>
        <div class="notification-text">{{ alert.content }}</div>
      </div>
    </div>
  </div>
</div>
