<div class="container m3" *ngIf="currentUser">
    <app-m3-header class="d-none d-sm-block"></app-m3-header>
    <app-m3-mobile-navbar
      [pageTitle]="'Health Data'"
      class="d-block d-sm-none"
    ></app-m3-mobile-navbar>
    <nav class="subnav d-block d-sm-none">
      <h2>Body Weight</h2>
    </nav><button class="btn btn-white btn-sm float-right mt-1" [routerLink]="['/m3','health-data']"> &lt; Back</button>
    <h1 class="m3-title mt-3 d-none d-sm-block">
      
      <app-m3-icon [icon]="'scales'"></app-m3-icon> Body Weight
    </h1>
  
    <!--button (click)="addModal.show()" class="btn btn-mobile-add d-block d-sm-none">
          <app-m3-icon [icon]="'more'" [style]="'color'"></app-m3-icon
          > Add a Sitting Entry</button-->
  
    <h2 class="mt-2" id="hba1c">
      Body Mass Index
      
    </h2>
    <div class="card" *ngIf="bmis">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            
            <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">Body Mass Index</span>
                  <span
                    *ngIf="bmis[0]"
                    class="info-stat-stat text-rag-{{
                      getBmiRag(bmis[0].bmi, currentUser.ethnicity_id)
                    }}"
                  >
                    <span *ngIf="bmis[0]">{{
                      bmis[0].bmi | number : "1.1-1"
                    }}</span
                    ><span *ngIf="!bmis[0]">?</span></span
                  >
                  <span *ngIf="!bmis[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label"></span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-blue">
                      {{
                        getBmiRagRange("green", currentUser.ethnicity_id)
                      }}</span
                    >
                  </div>
                  <div class="rag-details-history" *ngIf="bmis.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplatebmi>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>

                            {{
                              getBmiRagRange("green", currentUser.ethnicity_id)
                            }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            {{
                              getBmiRagRange("amber", currentUser.ethnicity_id)
                            }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{
                              getBmiRagRange("red", currentUser.ethnicity_id)
                            }}
                          </div>
                        </div></ng-template
                      >
                      <ng-container *ngFor="let bmi of bmis.slice().reverse();
                      let index = index
                    "
                  >
                    <div
                      *ngIf="index > bmis.length - 6"
                          [tooltip]="
                            (bmi.bmi | number : '1.1-1') +
                            '' +
                            ' on ' +
                            (bmi.date_recorded | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            getBmiRag(bmi.bmi, currentUser.ethnicity_id)
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplatebmi"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
            <h3 class="chart-title text-center mt-4" *ngIf="bmis">
              Your Entries
            </h3>
            <div class="table-responsive" *ngIf="bmis">
              <table *ngIf="bmis" class="table">
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>BMI</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let bmi of bmis; let index = index">
                    <tr *ngIf="index < 10 || showAllEntries">
                      <td class="text-center">
                        {{ bmi.date_recorded | dateFormatter : "MMM Do YYYY" }}
                      </td>
                      <td class="text-center">
                        {{ bmi.bmi | number }}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <div class="text-center">
                <button
                  *ngIf="!showAllEntries && bmis.length > 10"
                  class="btn btn-primary btn-sm btn-xs"
                  (click)="showAllEntries = true"
                >
                  Show all
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <app-m3-bmi-chart
        *ngIf="bmis && bmis.length > 1"
        [bmis]="bmis.slice().reverse()"
        [ethnicity]="currentUser.ethnicity_id"
      ></app-m3-bmi-chart>
          </div>
        </div>
      </div>
    </div>
  
    <hr />

  
    <h2 class="mt-2">
      Waist Circumference
      <a class="add-item-btn" (click)="addModal.show()"
        ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
      ></a>
    </h2>
    <div class="card" *ngIf="waists">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            
            <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">Waist</span>
                  <span
                    *ngIf="waists[0]"
                    class="info-stat-stat text-rag-{{
                      getWaistRag(
                        waists[0].waist,
                        currentUser.gender,
                        currentUser.ethnicity_id
                      )
                    }}"
                  >
                    <span *ngIf="waists[0]">{{ waists[0].waist | number : '1.2-2' }}</span
                    ><span *ngIf="!waists[0]">?</span></span
                  >
                  <span *ngIf="!waists[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label">cm</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-blue">
                      {{
                        getWaistRagRange(
                          "green",
                          currentUser.gender,
                          currentUser.ethnicity_id
                        )
                      }}cm</span
                    >
                  </div>
                  <div class="rag-details-history" *ngIf="waists.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplatewaist>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>

                            {{
                              getWaistRagRange(
                                "green",
                                currentUser.gender,
                                currentUser.ethnicity_id
                              )
                            }}cm
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>

                            {{
                              getWaistRagRange(
                                "amber",
                                currentUser.gender,
                                currentUser.ethnicity_id
                              )
                            }}cm
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>

                            {{
                              getWaistRagRange(
                                "red",
                                currentUser.gender,
                                currentUser.ethnicity_id
                              )
                            }}cm
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let waist of waists.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > waists.length - 6"
                          [tooltip]="
                            waist.waist +
                            ' cm' +
                            ' on ' +
                            (waist.date_recorded
                              | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{getWaistRag(waist.waist,
                            currentUser.gender,
                            currentUser.ethnicity_id)}}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplatewaist"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
  
            <h3 class="chart-title text-center mt-4" *ngIf="waists">
              Your Entries
            </h3>
            <div class="table-responsive" *ngIf="waists">
              <table *ngIf="waists" class="table">
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>Waist</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let waist of waists; let index = index">
                    <tr *ngIf="index < 10 || showAllEntries">
                      <td class="text-center">
                        {{ waist.date_recorded | dateFormatter : "MMM Do YYYY" }}
                      </td>
                      <td class="text-center">
                        {{ waist.waist | number }}cm
                      </td>
  
                      <td>
                        <a
                          [swal]="{
                            title: 'Are you sure you want to delete this?',
                            icon: 'warning',
                            showCancelButton: true
                          }"
                          (confirm)="onDeleteWaist(waist)"
                          class="pointer"
                          ><span class="icon-wrap"
                            ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                        ></a>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <div class="text-center">
                <button
                  *ngIf="!showAllEntries && waists.length > 10"
                  class="btn btn-primary btn-sm btn-xs"
                  (click)="showAllEntries = true"
                >
                  Show all
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            
            <app-m3-waist-chart
        *ngIf="waists && waists.length > 1"
        [waists]="waists.slice().reverse()"
        [ethnicity]="currentUser.ethnicity_id"
        [sex]="currentUser.gender"
      ></app-m3-waist-chart>
          </div>
        </div>
      </div>
    </div>
  
    <hr />
  
    <h2 class="mt-2">
      Weight
      <a class="add-item-btn" (click)="addModal.show()"
        ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
      ></a>
    </h2>
    <div class="card" *ngIf="userShapes">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
              <div class="info-stat-rag-current">
                <span class="info-stat-label">Weight</span>
                <span *ngIf="weights[0]" class="info-stat-stat text-rag-{{
                  getBmiRag(bmis[0].bmi, currentUser.ethnicity_id)
                }}">
                  <span *ngIf="weights[0]">{{
                    weights[0].weight | number : "1.1-1"
                  }}</span
                  ><span *ngIf="!weights[0]">?</span></span
                >
                <span *ngIf="!weights[0]" class="info-stat-stat">-</span>
                <span class="info-stat-label">Kg</span>
                <!--span *ngIf="weights[1]" style="margin-left:5px">
                  <fa-icon *ngIf="+weights[1].weight<+weights[0].weight" [icon]="['fas', 'arrow-up']"></fa-icon>
                  <fa-icon *ngIf="+weights[1].weight>+weights[0].weight" [icon]="['fas', 'arrow-down']"></fa-icon>
                  <span *ngIf="+weights[1].weight == +weights[0].weight">-</span>
                </span-->
              </div>

              <div class="rag-details">
                <div class="rag-details-target"></div>
                <div
                  class="rag-details-history"
                  *ngIf="weights.length > 0"
                >
                  History:
                  <div class="rag-history">
                    <ng-container
                      *ngFor="
                        let weight of weights.slice().reverse();
                        let index = index
                      "
                    >
                      <div
                        *ngIf="index > weights.length - 6"
                        [tooltip]="
                          (weight.weight | number : '1.1-1') +
                          '' +
                          ' on ' +
                          (weight.created | dateFormatter : 'Do MMM YYYY')
                        "
                        class="rag-circle"
                      >
                     
                        <fa-icon
                          *ngIf="
                          weights.slice().reverse()[index  - 1] &&
                            +weight.weight > +weights.slice().reverse()[index - 1].weight
                          "
                          [icon]="['fas', 'arrow-up']"
                        ></fa-icon>
                        <fa-icon
                          *ngIf="
                          weights.slice().reverse()[index - 1] &&
                            +weight.weight < +weights.slice().reverse()[index - 1].weight
                          "
                          [icon]="['fas', 'arrow-down']"
                        ></fa-icon>
                        <span
                          *ngIf="
                          weights.slice().reverse()[index - 1] &&
                            +weights.slice().reverse()[index - 1].weight == +weight.weight
                          "
                          >-</span
                        >
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">Change in weight</span>
                  <span *ngIf="weightChanges[0]" class="info-stat-stat"  [ngClass]="{'text-rag-green' : +weightChanges[0].change <=-0.5, 'text-rag-red' : +weightChanges[0].change >=0.5, 'text-rag-amber' : +weightChanges[0].change <0.5 &&  +weightChanges[0].change>=-0.5}">
                    <fa-icon
                      *ngIf="+weightChanges[0].change > 0"
                      [icon]="['fas', 'arrow-up']"
                    ></fa-icon>
                    <fa-icon
                      *ngIf="+weightChanges[0].change < 0"
                      [icon]="['fas', 'arrow-down']"
                    ></fa-icon>
                    <span *ngIf="weightChanges[0]">{{
                      weightChanges[0].change | number : "1.1-1"
                    }}</span
                    ><span *ngIf="!weightChanges[0]">?</span></span
                  >
                  <span *ngIf="!weightChanges[0]" class="info-stat-stat"
                    >-</span
                  >
                  <span class="info-stat-label">Kg</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target"></div>
                  <div
                    class="rag-details-history"
                    *ngIf="weightChanges.length > 0"
                  >
                    History:
                    <div class="rag-history">
                      <ng-container
                        *ngFor="let weight of weightChanges.slice().reverse();
                        let index = index
                      "
                    >
                      <div
                        *ngIf="index > weightChanges.length - 6"
                          [tooltip]="
                            (weight.change | number : '1.1-1') +
                            '' +
                            ' on ' +
                            (weight.date_recorded
                              | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle"
                        >
                          <fa-icon
                            *ngIf="+weight.change > 0"
                            [icon]="['fas', 'arrow-up']"
                          ></fa-icon>
                          <fa-icon
                            *ngIf="+weight.change < 0"
                            [icon]="['fas', 'arrow-down']"
                          ></fa-icon>
                          <span *ngIf="weight.change == 0">-</span>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
  
            <h3 class="chart-title text-center mt-4" *ngIf="userShapes">
              Your Entries
            </h3>
            <div class="table-responsive" *ngIf="userShapes">
              <table *ngIf="userShapes" class="table">
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>WEIGHT</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let weight of userShapes; let index = index">
                    <tr *ngIf="weight.weight && index < 10 || showAllEntries">
                      <td class="text-center">
                        {{ weight.created | dateFormatter : "MMM Do YYYY" }}
                      </td>
                      <td class="text-center">
                        {{ weight.weight }}
                      </td>
  
                      <td>
                        <a
                          [swal]="{
                            title: 'Are you sure you want to delete this?',
                            icon: 'warning',
                            showCancelButton: true
                          }"
                          (confirm)="onDeleteWeight(weight)"
                          class="pointer"
                          ><span class="icon-wrap"
                            ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                        ></a>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <div class="text-center">
                <button
                  *ngIf="!showAllEntries && userShapes.length > 10"
                  class="btn btn-primary btn-sm btn-xs"
                  (click)="showAllEntries = true"
                >
                  Show all
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            
            <app-m3-weight-chart
        *ngIf="weights && weights.length > 1"
        [weights]="weights.slice().reverse()"
      ></app-m3-weight-chart>
          </div>
        </div>
      </div>
    </div>

   
    
  
    <div
      class="modal fade"
      bsModal
      #addModal="bs-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="dialog-static-name"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title pull-left">Update Weight and Waist Circumference</h4>
            <button
              type="button"
              class="btn-close close pull-right"
              aria-label="Close"
              (click)="addModal.hide()"
            >
              <span aria-hidden="true" class="visually-hidden">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form
        class="bs-form tracker-form"
        name="trackerForm"
        method="post"
        (ngSubmit)="onSubmit()"
        #trackerForm="ngForm"
      >
        <div>
          <fieldset>
            <legend class="sr-only">Weight and Waist measurements</legend>
            <p class="instruction">
            </p>
            <div class="form-group">
              <label for="weight">WEIGHT</label>

              <div [hidden]="unitMode !== 'metric'">
                <p class="instruction">How much do you weight today in KG?</p>

                <input
                  type="number"
                  step="0.1"
                  name="weight"
                  class="form-control input-tracker"
                  placeholder=""
                  id="weight"
                  [min]="40"
                  [max]="350"
                  autocomplete="off"
                  #weight="ngModel"
                  [required]="!(waist.value !== '' || weight.value !== '')"
                  ngModel
                  (change)="updateWeight('metric')"
                  [ngClass]="{ 'is-invalid': weight.touched && !weight.valid }"
                />
                <!--[attr.required]="!(waist != '' || weight != '')"-->
                <div
                  class="alert alert-danger"
                  *ngIf="weight.touched && !weight.valid"
                >
                  This must be a number between 40 and 350, inclusive.
                </div>

                <div class="text-center">
                  <br />
                  <a
                    href=""
                    (click)="onSwitchMode($event, 'weight', 'imperial');"
                    >Switch to imperial</a
                  >
                </div>
              </div>
              <div [hidden]="unitMode !== 'imperial'">
                <p class="instruction">
                  How much do you weight today in stones and pounds?
                </p>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="weightStone">Stone</label>
                      <input
                        type="number"
                        name="weightStone"
                        class="form-control input-tracker"
                        placeholder=""
                        id="weightStone"
                        #weightStone="ngModel"
                        ngModel
                        [min]="3"
                        [max]="40"
                        autocomplete="off"
                        (change)="updateWeight()"
                      />
                      <div
                        class="alert alert-danger"
                        *ngIf="weightStone.touched && !weightStone.valid"
                      >
                        This must be a number between 3 and 40, inclusive.
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="weightLbs">Pounds</label>
                      <input
                        type="number"
                        name="weightLbs"
                        class="form-control input-tracker"
                        placeholder=""
                        id="weightLbs"
                        #weightLbs="ngModel"
                        ngModel
                        [min]="0"
                        [max]="15"
                        autocomplete="off"
                        (change)="updateWeight()"
                      />
                      <div
                        class="alert alert-danger"
                        *ngIf="weightLbs.touched && !weightLbs.valid"
                      >
                        This must be a number between 0 and 15, inclusive.
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="text-center">
                  <br />
                  <a
                    href=""
                    (click)="onSwitchMode($event, 'weight',  'metric');$event.preventDefault()"
                    >Switch to metric</a
                  >
                </div>
              </div>
            </div>

            <div [hidden]="unitModeWaist !== 'imperial'">
              <div class="form-group">
                <label for="waist">WAIST MEASUREMENT</label>
                <p class="instruction">
                  What is your waist measurement in inches?
                </p>
                <input
                  type="number"
                  name="waist"
                  class="form-control input-tracker"
                  placeholder=""
                  id="waist"
                  #waist="ngModel"
                  ngModel
                  [min]="20"
                  [max]="100"
                  autocomplete="off"
                  (change)="updateWaist()" 
                  [required]="false" 
                />
                <div
                  class="alert alert-danger"
                  *ngIf="waist.touched && !waist.valid"
                >
                  This must be a number between 20 and 100, inclusive.
                </div>
              </div>
              <div class="text-center">
                <br />
                <a href="" 
                (click)="onSwitchMode($event, 'waist',  'metric');"
                  >Switch to metric</a
                >
              </div>
            </div>
            <div [hidden]="unitModeWaist !== 'metric'">
              <div class="form-group">
                <label for="waistCm">WAIST MEASUREMENT</label>
                <p class="instruction">
                  What is your waist measurement in cms?
                </p>
                <input
                  type="number"
                  name="waistCm"
                  class="form-control input-tracker"
                  placeholder=""
                  id="waistCm"
                  #waistCm="ngModel"
                  ngModel
                  [min]="51"
                  [max]="254"
                  autocomplete="off"
                  (change)="updateWaist('metric')"
                  [required]="false" 
                />
                <div
                  class="alert alert-danger"
                  *ngIf="waistCm.touched && !waistCm.valid"
                >
                  This must be a number between 51 and 254, inclusive.
                </div>
              </div>
              <div class="text-center">
                <br />
                <a href="" 
                (click)="onSwitchMode($event, 'waist', 'imperial');"
                  >Switch to imperial</a
                >
              </div>
            </div>
            <div class="form-group">
              <label for="entryDate">DATE</label>
              <p class="instructions">
                When did you do take this reading?
              </p>
              <input
                name="date"
                type="text"
                placeholder="DD/MM/YYYY"
                class="form-control input-tracker"
                bsDatepicker
                autocomplete="off"
                [bsConfig]="{
                  dateInputFormat: 'DD/MM/YYYY',
                  containerClass: 'theme-cv',
                  showWeekNumbers: false
                }"
                [(ngModel)]="entryDate"
                required
                [bsValue]="entryDate"
                [maxDate]="maxDate"
                #date="ngModel"
              />
            </div>
            <div class="tracker-submit-container">
              <button
                type="submit"
                class="btn btn-primary btn-white"
                value="Confirm"
                [disabled]="!(waist.value  || weight.value)"
              >
                <span class="icon-wrap"
                  ><fa-icon [icon]="['fas', 'check']"></fa-icon
                ></span>
                CONFIRM
              </button>
            </div>
          </fieldset>
        </div>
      </form>
            
          </div>
        </div>
      </div>
    </div>
  
    
  
    <div class="clear"></div>
  </div>
  