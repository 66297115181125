<ng-container [ngSwitch]="icon">
  <ng-container *ngSwitchCase="'physical-ability'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          id="Fitness_-_Thin"
          data-name="Fitness - Thin"
          xmlns="http://www.w3.org/2000/svg"
          width="19.87"
          height="23.062"
          viewBox="0 0 19.87 23.062"
        >
          <path
            id="Path_151"
            data-name="Path 151"
            d="M22.747,41.685a.535.535,0,0,1-.458-.257,5.18,5.18,0,0,0-2.026-1.716,5.534,5.534,0,0,0-2.61-.525,9.331,9.331,0,0,0-3.9,1.132.535.535,0,0,1-.489-.953,10.343,10.343,0,0,1,4.392-1.251,6.574,6.574,0,0,1,3.124.659,6.157,6.157,0,0,1,2.421,2.1.536.536,0,0,1-.458.812Z"
            transform="translate(-8.708 -25.244)"
          />
          <path
            id="Path_152"
            data-name="Path 152"
            d="M7.879,34.967a.536.536,0,0,1-.487-.312,4.072,4.072,0,0,0-1.353-1.477A5.381,5.381,0,0,0,1.2,32.883a.535.535,0,0,1-.338-1.016,7.872,7.872,0,0,1,3.471-.422,5.325,5.325,0,0,1,2.345.875A5.07,5.07,0,0,1,8.366,34.21a.536.536,0,0,1-.487.757Z"
            transform="translate(-0.5 -20.825)"
          />
          <path
            id="Path_153"
            data-name="Path 153"
            d="M3.836,59.605a.536.536,0,0,1-.486-.759,12.863,12.863,0,0,1,2.225-3.4.535.535,0,0,1,.765.75,12.033,12.033,0,0,0-2.016,3.094A.536.536,0,0,1,3.836,59.605Z"
            transform="translate(-2.343 -36.544)"
          />
          <path
            id="Path_154"
            data-name="Path 154"
            d="M14.685,23.1q-.558,0-1.1-.04a12.089,12.089,0,0,1-5.778-1.9.535.535,0,0,1,.585-.9,11.031,11.031,0,0,0,5.273,1.726,16.475,16.475,0,0,0,5.11-.485A22.865,22.865,0,0,0,23.9,19.562a29.064,29.064,0,0,0-.358-6.529A29.989,29.989,0,0,0,22.124,7.7a23.968,23.968,0,0,0-2.239-4.659l-.052-.066a.285.285,0,0,1-.033-.046,2.6,2.6,0,0,0-.967-.685,9.139,9.139,0,0,0-3.914-.677,3.068,3.068,0,0,0-1.434,3.008l.358.489L16.5,4.758a10.458,10.458,0,0,1,.74-1.649.535.535,0,1,1,.884.6,12.6,12.6,0,0,0-.731,1.719.535.535,0,0,1-.442.351l-3.3.383a.535.535,0,0,1-.494-.216L12.541,5.1a.535.535,0,0,1-.1-.234,4.04,4.04,0,0,1,.863-3.275A4.31,4.31,0,0,1,14.528.56.535.535,0,0,1,14.767.5a10.2,10.2,0,0,1,4.652.84,3.378,3.378,0,0,1,1.263.984l.054.068.025.033a24.358,24.358,0,0,1,2.368,4.908,31.063,31.063,0,0,1,1.465,5.521,30.111,30.111,0,0,1,.352,7.071.535.535,0,0,1-.264.425,23.034,23.034,0,0,1-5.654,2.194A18.358,18.358,0,0,1,14.685,23.1Z"
            transform="translate(-5.147 -0.499)"
          />
          <path
            id="Path_155"
            data-name="Path 155"
            d="M36.389,19.647l-1.047-.227a10.681,10.681,0,0,1,.982-2.562,6.09,6.09,0,0,0,.358-.793,7.379,7.379,0,0,0-.165-2.4l-.019-.094q0-.016-.006-.033a4.583,4.583,0,0,0-1.639-2.455l.656-.847a5.729,5.729,0,0,1,2.041,3.135l.017.085,0,.013a7.941,7.941,0,0,1,.138,2.9,6.593,6.593,0,0,1-.426.965A9.636,9.636,0,0,0,36.389,19.647Z"
            transform="translate(-22.993 -6.847)"
          />
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'thin'">
        <svg
          id="Fitness_-_Thin"
          data-name="Fitness - Thin"
          xmlns="http://www.w3.org/2000/svg"
          width="19.513"
          height="22.705"
          viewBox="0 0 19.513 22.705"
        >
          <path
            id="Path_13"
            data-name="Path 13"
            d="M22.819,41.578a.357.357,0,0,1-.305-.172h0a5.345,5.345,0,0,0-2.084-1.776,5.7,5.7,0,0,0-2.7-.551,9.493,9.493,0,0,0-3.984,1.153.357.357,0,0,1-.326-.635,10.168,10.168,0,0,1,4.316-1.232,6.4,6.4,0,0,1,3.04.641,5.961,5.961,0,0,1,2.347,2.03.357.357,0,0,1-.305.542Z"
            transform="translate(-8.958 -25.494)"
          />
          <path
            id="Path_14"
            data-name="Path 14"
            d="M7.95,34.86a.357.357,0,0,1-.325-.208h0a4.238,4.238,0,0,0-1.4-1.541A4.451,4.451,0,0,0,4.272,32.4a7.018,7.018,0,0,0-3.053.386.357.357,0,0,1-.225-.677,7.7,7.7,0,0,1,3.392-.414,5.149,5.149,0,0,1,2.267.846,4.874,4.874,0,0,1,1.621,1.816.357.357,0,0,1-.325.5Z"
            transform="translate(-0.75 -21.075)"
          />
          <path
            id="Path_15"
            data-name="Path 15"
            d="M3.908,59.5a.357.357,0,0,1-.324-.506,12.666,12.666,0,0,1,2.19-3.348.357.357,0,0,1,.51.5h0A12.189,12.189,0,0,0,4.232,59.29.357.357,0,0,1,3.908,59.5Z"
            transform="translate(-2.593 -36.794)"
          />
          <path
            id="Path_16"
            data-name="Path 16"
            d="M14.756,22.991q-.551,0-1.086-.04a11.913,11.913,0,0,1-5.694-1.868.357.357,0,0,1,.39-.6,11.208,11.208,0,0,0,5.357,1.754,16.652,16.652,0,0,0,5.166-.489,22.958,22.958,0,0,0,5.252-2.006,29.235,29.235,0,0,0-.354-6.668,30.168,30.168,0,0,0-1.423-5.362A24.1,24.1,0,0,0,20.1,3.01l-.057-.072a.23.23,0,0,1-.023-.032,2.644,2.644,0,0,0-1-.731,9.277,9.277,0,0,0-4.077-.712,3.575,3.575,0,0,0-.89.787,3.123,3.123,0,0,0-.666,2.467l.446.61L16.7,4.994a11.273,11.273,0,0,1,.758-1.713.357.357,0,1,1,.589.4,12.6,12.6,0,0,0-.751,1.76A.357.357,0,0,1,17,5.678l-3.3.383a.357.357,0,0,1-.329-.144l-.622-.849a.357.357,0,0,1-.065-.156,3.866,3.866,0,0,1,.824-3.135A4.112,4.112,0,0,1,14.681.79a.357.357,0,0,1,.159-.04C19.2.7,20.457,2.282,20.61,2.5l.058.072.016.022a24.162,24.162,0,0,1,2.349,4.869,30.884,30.884,0,0,1,1.457,5.489,29.933,29.933,0,0,1,.35,7.029.357.357,0,0,1-.176.283,22.835,22.835,0,0,1-5.606,2.175A18.18,18.18,0,0,1,14.756,22.991Z"
            transform="translate(-5.397 -0.749)"
          />
          <path
            id="Path_17"
            data-name="Path 17"
            d="M36.259,19.665l-.7-.151a10.53,10.53,0,0,1,.967-2.52,6.15,6.15,0,0,0,.37-.822,7.225,7.225,0,0,0-.16-2.485l-.02-.1q0-.011,0-.022A4.864,4.864,0,0,0,35.008,11l.437-.564A5.534,5.534,0,0,1,37.42,13.46l.018.09,0,.009a7.823,7.823,0,0,1,.142,2.82,6.492,6.492,0,0,1-.415.936A9.784,9.784,0,0,0,36.259,19.665Z"
            transform="translate(-23.215 -7.082)"
          />
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'colour'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="20.607"
          height="23.905"
          viewBox="0 0 20.607 23.905"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#f7931e" />
              <stop offset="0.1" stop-color="#f78e24" />
              <stop offset="0.256" stop-color="#f78335" />
              <stop offset="0.393" stop-color="#f87052" />
              <stop offset="0.565" stop-color="#fa567a" />
              <stop offset="0.751" stop-color="#fc34ad" />
              <stop offset="1" stop-color="#fe0ceb" />
              <stop offset="1" stop-color="#f0f" />
            </linearGradient>
          </defs>
          <g
            id="Fitness_-_Colour"
            data-name="Fitness - Colour"
            transform="translate(-10.748 -8.346)"
          >
            <path
              id="Path_13"
              data-name="Path 13"
              d="M22.86,41.628a.59.59,0,0,1-.5-.283h0a5.315,5.315,0,0,0-2.082-1.761,5.684,5.684,0,0,0-2.68-.537,9.609,9.609,0,0,0-4.008,1.166.59.59,0,1,1-.539-1.05,10.725,10.725,0,0,1,4.555-1.3,6.83,6.83,0,0,1,3.245.685,6.4,6.4,0,0,1,2.517,2.182.59.59,0,0,1-.5.9Z"
              transform="translate(2.433 -16.218)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_14"
              data-name="Path 14"
              d="M7.376,34.567a4.172,4.172,0,0,0-1.389-1.514,4.387,4.387,0,0,0-1.918-.69,7.049,7.049,0,0,0-3.043.391.59.59,0,1,1-.372-1.12,8.172,8.172,0,0,1,3.6-.437,5.54,5.54,0,0,1,2.439.911,5.28,5.28,0,0,1,1.752,1.968l-1.075.488Z"
              transform="translate(10.499 -11.876)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_15"
              data-name="Path 15"
              d="M3.641,59.573a.59.59,0,0,1-.536-.836,13.333,13.333,0,0,1,2.306-3.522.59.59,0,0,1,.843.826,12.4,12.4,0,0,0-2.076,3.187A.59.59,0,0,1,3.641,59.573Z"
              transform="translate(8.688 -27.322)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_16"
              data-name="Path 16"
              d="M14.709,23.675q-.578,0-1.139-.042a12.529,12.529,0,0,1-5.989-1.965.59.59,0,1,1,.644-.989,11.363,11.363,0,0,0,5.432,1.777,16.988,16.988,0,0,0,5.269-.5,23.606,23.606,0,0,0,5.266-1.994,29.993,29.993,0,0,0-.366-6.692,30.953,30.953,0,0,0-1.451-5.49,24.934,24.934,0,0,0-2.323-4.844L20,2.871a.3.3,0,0,1-.036-.05,2.679,2.679,0,0,0-.992-.7,9.418,9.418,0,0,0-4.01-.692A3.134,3.134,0,0,0,13.5,4.487l.351.48,2.7-.314a10.692,10.692,0,0,1,.76-1.691.59.59,0,0,1,.974.667A13.016,13.016,0,0,0,17.544,5.4a.59.59,0,0,1-.487.386l-3.406.4a.59.59,0,0,1-.544-.238l-.642-.877a.59.59,0,0,1-.107-.258,4.211,4.211,0,0,1,.9-3.414A4.415,4.415,0,0,1,14.53.316a.59.59,0,0,1,.264-.066,10.578,10.578,0,0,1,4.824.872,3.467,3.467,0,0,1,1.321,1.03l.053.067q.014.018.027.037A25.21,25.21,0,0,1,23.47,7.336a32.136,32.136,0,0,1,1.516,5.712,31.153,31.153,0,0,1,.364,7.315.59.59,0,0,1-.291.469A23.845,23.845,0,0,1,19.207,23.1,19.009,19.009,0,0,1,14.709,23.675Z"
              transform="translate(5.932 8.097)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_17"
              data-name="Path 17"
              d="M36.346,19.8l-1.154-.25a11.07,11.07,0,0,1,1.018-2.656,6.281,6.281,0,0,0,.368-.814,7.216,7.216,0,0,0-.171-2.462l-.02-.1q0-.018-.006-.036a4.7,4.7,0,0,0-1.68-2.513l.723-.933A5.96,5.96,0,0,1,37.548,13.3l.018.086,0,.014a8.232,8.232,0,0,1,.141,3.016,6.834,6.834,0,0,1-.443,1A9.927,9.927,0,0,0,36.346,19.8Z"
              transform="translate(-11.681 1.818)"
              fill="url(#linear-gradient)"
            />
          </g>
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'walking'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          id="Walking_-_Thick"
          data-name="Walking - Thick"
          xmlns="http://www.w3.org/2000/svg"
          width="23.955"
          height="13.815"
          viewBox="0 0 23.955 13.815"
        >
          <path
            id="Path_149"
            data-name="Path 149"
            d="M23.984,11.824l-1.09-.2.545.1-.545-.1a2.845,2.845,0,0,0-.175-1.375,3.367,3.367,0,0,0-2.556-1.916A10.438,10.438,0,0,1,16.84,7.143a60.32,60.32,0,0,1-6.317-4.23.553.553,0,0,1-.056-.062,6.289,6.289,0,0,0-.739-.767c-.52-.447-.824-.5-.912-.468s-.191.182-.281.525a7.983,7.983,0,0,0-.112,2.308.554.554,0,0,1-.207.465A4.665,4.665,0,0,1,3.8,5.53a.554.554,0,0,1-.3-.269s-.466-.931-.823-1.646a1.134,1.134,0,0,0-.265-.29,1.454,1.454,0,0,0-.237.482,16.694,16.694,0,0,0-.4,6.369L.7,10.394A17.6,17.6,0,0,1,1.115,3.5q0-.015.009-.03c.262-.786.654-1.213,1.165-1.269.75-.082,1.259.7,1.353.856q.011.019.021.038L4.4,4.565A3.553,3.553,0,0,0,7.3,4.2C7.211,2.055,7.57.9,8.423.58c.581-.22,1.268.007,2.041.676a7.409,7.409,0,0,1,.846.876,63.684,63.684,0,0,0,6.122,4.074.554.554,0,0,1,.048.032A9.439,9.439,0,0,0,20.388,7.25l.026.006a4.521,4.521,0,0,1,3.36,2.65A3.923,3.923,0,0,1,23.984,11.824Z"
            transform="translate(-0.498 -0.498)"
          />
          <path
            id="Path_150"
            data-name="Path 150"
            d="M1.774,34.246h22.3a.554.554,0,0,1,.524.373,4.067,4.067,0,0,1,.083,2.292.554.554,0,0,1-.531.4H1.7a.554.554,0,0,1-.48-.278,2.424,2.424,0,0,1-.154-1.546,6.7,6.7,0,0,1,.178-.839A.554.554,0,0,1,1.774,34.246Zm21.874,1.108H2.209a3.974,3.974,0,0,0-.086.844H23.683A3.05,3.05,0,0,0,23.647,35.354Z"
            transform="translate(-0.85 -23.491)"
          />
          <path
            id="Line_75"
            data-name="Line 75"
            d="M-.2,1.136A.554.554,0,0,1-.7.818.554.554,0,0,1-.433.08L1.212-.7a.554.554,0,0,1,.738.264.554.554,0,0,1-.264.738L.041,1.082A.552.552,0,0,1-.2,1.136Z"
            transform="translate(7.188 3.65)"
          />
          <path
            id="Line_76"
            data-name="Line 76"
            d="M.285,5.308-.6,4.635,3.3-.456l.88.673Z"
            transform="translate(6.328 6.297)"
          />
          <path
            id="Line_77"
            data-name="Line 77"
            d="M.284,4.149l-.878-.677L2.435-.458l.878.677Z"
            transform="translate(9.059 7.419)"
          />
          <path
            id="Line_78"
            data-name="Line 78"
            d="M.282,2.993-.591,2.31,1.574-.462l.873.682Z"
            transform="translate(11.767 8.559)"
          />
          <path
            id="Line_79"
            data-name="Line 79"
            d="M-.2,1.346a.552.552,0,0,1-.344-.12A.554.554,0,0,1-.63.448L.152-.54A.554.554,0,0,1,.93-.63a.554.554,0,0,1,.09.778l-.782.988A.553.553,0,0,1-.2,1.346Z"
            transform="translate(11.527 3.837)"
          />
          <path
            id="Line_80"
            data-name="Line 80"
            d="M-.2,1.346a.552.552,0,0,1-.343-.119A.554.554,0,0,1-.631.448L.15-.539A.554.554,0,0,1,.928-.631.554.554,0,0,1,1.02.148l-.781.988A.553.553,0,0,1-.2,1.346Z"
            transform="translate(13.265 4.969)"
          />
          <path
            id="Line_81"
            data-name="Line 81"
            d="M-.2,1.346a.552.552,0,0,1-.343-.12A.554.554,0,0,1-.63.448L.151-.54A.554.554,0,0,1,.929-.631.554.554,0,0,1,1.02.148l-.781.988A.553.553,0,0,1-.2,1.346Z"
            transform="translate(15.12 5.975)"
          />
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'thin'">
        <svg
          id="Walking_-_Thin"
          data-name="Walking - Thin"
          xmlns="http://www.w3.org/2000/svg"
          width="23.587"
          height="13.445"
          viewBox="0 0 23.587 13.445"
        >
          <path
            id="Path_11"
            data-name="Path 11"
            d="M23.868,11.856l-.726-.135.363.067-.364-.066c.019-.109.436-2.688-2.873-3.5a10.487,10.487,0,0,1-3.26-1.165,60.494,60.494,0,0,1-6.293-4.211.369.369,0,0,1-.038-.041c-.488-.621-1.418-1.463-1.862-1.294-.158.06-.663.455-.512,3.017a.369.369,0,0,1-.138.31,4.471,4.471,0,0,1-4.232.587.369.369,0,0,1-.2-.179s-.47-.939-.827-1.655c-.121-.194-.348-.415-.454-.4-.063.007-.223.149-.386.632a16.905,16.905,0,0,0-.4,6.459l-.724.146a17.4,17.4,0,0,1,.414-6.807l.006-.02c.237-.711.577-1.1,1.01-1.144.651-.072,1.123.681,1.175.767l.014.025c.277.556.625,1.252.768,1.537a3.755,3.755,0,0,0,3.224-.419c-.1-2.12.217-3.249,1-3.545.513-.194,1.137.022,1.855.643a7.19,7.19,0,0,1,.832.863A63.321,63.321,0,0,0,17.4,6.43l.032.022A9.6,9.6,0,0,0,20.418,7.5l.018,0a4.345,4.345,0,0,1,3.231,2.535A3.719,3.719,0,0,1,23.868,11.856Z"
            transform="translate(-0.748 -0.749)"
          />
          <path
            id="Path_12"
            data-name="Path 12"
            d="M1.839,34.5h22.3a.369.369,0,0,1,.349.249,3.863,3.863,0,0,1,.08,2.179.369.369,0,0,1-.354.264H1.763A.369.369,0,0,1,1.443,37a3.581,3.581,0,0,1,.042-2.24A.369.369,0,0,1,1.839,34.5Zm22.023.739H2.125a3.566,3.566,0,0,0-.1,1.213H23.912A3.228,3.228,0,0,0,23.862,35.235Z"
            transform="translate(-1.099 -23.742)"
          />
          <path
            id="Line_13"
            data-name="Line 13"
            d="M-.13,1.016A.369.369,0,0,1-.465.8.369.369,0,0,1-.288.313L1.357-.465a.369.369,0,0,1,.492.176A.369.369,0,0,1,1.672.2L.027.981A.368.368,0,0,1-.13,1.016Z"
            transform="translate(6.938 3.4)"
          />
          <path
            id="Line_14"
            data-name="Line 14"
            d="M.19,5.235-.4,4.786,3.5-.3l.587.449Z"
            transform="translate(6.092 6.072)"
          />
          <path
            id="Line_15"
            data-name="Line 15"
            d="M.189,4.076-.4,3.625,2.633-.305l.585.451Z"
            transform="translate(8.824 7.195)"
          />
          <path
            id="Line_16"
            data-name="Line 16"
            d="M.188,2.919l-.582-.455L1.771-.308l.582.455Z"
            transform="translate(11.532 8.334)"
          />
          <path
            id="Line_17"
            data-name="Line 17"
            d="M-.131,1.226a.368.368,0,0,1-.229-.08A.369.369,0,0,1-.42.628L.362-.36A.369.369,0,0,1,.881-.42.369.369,0,0,1,.941.1l-.782.988A.369.369,0,0,1-.131,1.226Z"
            transform="translate(11.278 3.587)"
          />
          <path
            id="Line_18"
            data-name="Line 18"
            d="M-.131,1.227a.368.368,0,0,1-.229-.08A.369.369,0,0,1-.42.628L.36-.36A.369.369,0,0,1,.879-.42.369.369,0,0,1,.94.1l-.781.988A.369.369,0,0,1-.131,1.227Z"
            transform="translate(13.015 4.719)"
          />
          <path
            id="Line_19"
            data-name="Line 19"
            d="M-.131,1.226a.368.368,0,0,1-.229-.08A.369.369,0,0,1-.42.628L.361-.36A.369.369,0,0,1,.88-.42.369.369,0,0,1,.94.1l-.781.988A.369.369,0,0,1-.131,1.226Z"
            transform="translate(14.87 5.725)"
          />
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'colour'">
        <svg
          id="Walking_-_Colour"
          data-name="Walking - Colour"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="23.955"
          height="13.815"
          viewBox="0 0 23.955 13.815"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#f7931e" />
              <stop offset="0.1" stop-color="#f78e24" />
              <stop offset="0.256" stop-color="#f78335" />
              <stop offset="0.393" stop-color="#f87052" />
              <stop offset="0.565" stop-color="#fa567a" />
              <stop offset="0.751" stop-color="#fc34ad" />
              <stop offset="1" stop-color="#fe0ceb" />
              <stop offset="1" stop-color="#f0f" />
            </linearGradient>
          </defs>
          <path
            id="Path_149"
            data-name="Path 149"
            d="M23.984,11.824l-1.09-.2.545.1-.545-.1a2.845,2.845,0,0,0-.175-1.375,3.367,3.367,0,0,0-2.556-1.916A10.438,10.438,0,0,1,16.84,7.143a60.32,60.32,0,0,1-6.317-4.23.553.553,0,0,1-.056-.062,6.289,6.289,0,0,0-.739-.767c-.52-.447-.824-.5-.912-.468s-.191.182-.281.525a7.983,7.983,0,0,0-.112,2.308.554.554,0,0,1-.207.465A4.665,4.665,0,0,1,3.8,5.53a.554.554,0,0,1-.3-.269s-.466-.931-.823-1.646a1.134,1.134,0,0,0-.265-.29,1.454,1.454,0,0,0-.237.482,16.694,16.694,0,0,0-.4,6.369L.7,10.394A17.6,17.6,0,0,1,1.115,3.5q0-.015.009-.03c.262-.786.654-1.213,1.165-1.269.75-.082,1.259.7,1.353.856q.011.019.021.038L4.4,4.565A3.553,3.553,0,0,0,7.3,4.2C7.211,2.055,7.57.9,8.423.58c.581-.22,1.268.007,2.041.676a7.409,7.409,0,0,1,.846.876,63.684,63.684,0,0,0,6.122,4.074.554.554,0,0,1,.048.032A9.439,9.439,0,0,0,20.388,7.25l.026.006a4.521,4.521,0,0,1,3.36,2.65A3.923,3.923,0,0,1,23.984,11.824Z"
            transform="translate(-0.498 -0.498)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Path_150"
            data-name="Path 150"
            d="M1.774,34.246h22.3a.554.554,0,0,1,.524.373,4.067,4.067,0,0,1,.083,2.292.554.554,0,0,1-.531.4H1.7a.554.554,0,0,1-.48-.278,2.424,2.424,0,0,1-.154-1.546,6.7,6.7,0,0,1,.178-.839A.554.554,0,0,1,1.774,34.246Zm21.874,1.108H2.209a3.974,3.974,0,0,0-.086.844H23.683A3.05,3.05,0,0,0,23.647,35.354Z"
            transform="translate(-0.85 -23.491)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Line_75"
            data-name="Line 75"
            d="M-.2,1.136A.554.554,0,0,1-.7.818.554.554,0,0,1-.433.08L1.212-.7a.554.554,0,0,1,.738.264.554.554,0,0,1-.264.738L.041,1.082A.552.552,0,0,1-.2,1.136Z"
            transform="translate(7.188 3.65)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Line_76"
            data-name="Line 76"
            d="M.285,5.308-.6,4.635,3.3-.456l.88.673Z"
            transform="translate(6.328 6.297)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Line_77"
            data-name="Line 77"
            d="M.284,4.149l-.878-.677L2.435-.458l.878.677Z"
            transform="translate(9.059 7.419)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Line_78"
            data-name="Line 78"
            d="M.282,2.993-.591,2.31,1.574-.462l.873.682Z"
            transform="translate(11.767 8.559)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Line_79"
            data-name="Line 79"
            d="M-.2,1.346a.552.552,0,0,1-.344-.12A.554.554,0,0,1-.63.448L.152-.54A.554.554,0,0,1,.93-.63a.554.554,0,0,1,.09.778l-.782.988A.553.553,0,0,1-.2,1.346Z"
            transform="translate(11.527 3.837)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Line_80"
            data-name="Line 80"
            d="M-.2,1.346a.552.552,0,0,1-.343-.119A.554.554,0,0,1-.631.448L.15-.539A.554.554,0,0,1,.928-.631.554.554,0,0,1,1.02.148l-.781.988A.553.553,0,0,1-.2,1.346Z"
            transform="translate(13.265 4.969)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Line_81"
            data-name="Line 81"
            d="M-.2,1.346a.552.552,0,0,1-.343-.12A.554.554,0,0,1-.63.448L.151-.54A.554.554,0,0,1,.929-.631.554.554,0,0,1,1.02.148l-.781.988A.553.553,0,0,1-.2,1.346Z"
            transform="translate(15.12 5.975)"
            fill="url(#linear-gradient)"
          />
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'non-stepping'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          id="Activity_-_Thick"
          data-name="Activity - Thick"
          xmlns="http://www.w3.org/2000/svg"
          width="24.929"
          height="21.144"
          viewBox="0 0 24.929 21.144"
        >
          <path
            id="Path_144"
            data-name="Path 144"
            d="M5.425,39.81a4.925,4.925,0,1,1,4.924-4.924A4.93,4.93,0,0,1,5.425,39.81Zm0-8.69A3.765,3.765,0,1,0,9.19,34.886,3.77,3.77,0,0,0,5.425,31.12Z"
            transform="translate(-0.5 -18.667)"
          />
          <path
            id="Path_145"
            data-name="Path 145"
            d="M44.758,39.81a4.925,4.925,0,1,1,4.925-4.924A4.93,4.93,0,0,1,44.758,39.81Zm0-8.69a3.765,3.765,0,1,0,3.766,3.766A3.77,3.77,0,0,0,44.758,31.12Z"
            transform="translate(-24.754 -18.667)"
          />
          <path
            id="Path_146"
            data-name="Path 146"
            d="M21.589,16.9a.577.577,0,0,1-.369-.133,11.076,11.076,0,0,0-2.8-1.873l-.008,0a2.6,2.6,0,0,1-1.936-1.725,3.2,3.2,0,0,1,.935-2.636.58.58,0,0,1,.043-.04l3.826-3.243.021-.017a4.577,4.577,0,0,1,2.709-1.117L24.1,6.1a2.106,2.106,0,0,1,1.8.465,4,4,0,0,1,1.165,2.039,8.939,8.939,0,0,1,.139.935,6.329,6.329,0,0,0,.441,2c.4.121,1.594.475,3.878,1.149A.58.58,0,1,1,31.2,13.8c-2.891-.853-4.033-1.2-4.044-1.2a.58.58,0,0,1-.23-.133,4.817,4.817,0,0,1-.868-2.793c-.038-.322-.073-.626-.115-.8a2.778,2.778,0,0,0-.8-1.439.481.481,0,0,1-.076-.072,1.342,1.342,0,0,0-.852-.107l-.1.008a3.42,3.42,0,0,0-2.09.872l-3.79,3.213c-.223.243-.764.983-.626,1.539.114.456.7.74,1.172.9a11.24,11.24,0,0,1,3.181,2.084.58.58,0,0,1-.37,1.026Z"
            transform="translate(-10.319 -3.945)"
          />
          <path
            id="Path_147"
            data-name="Path 147"
            d="M28.813,28.688a.58.58,0,0,1-.561-.728c1.2-4.526,1.575-6.253,1.69-6.86l-3.054-2.408a.58.58,0,0,1,.035-.936l3.514-2.364a.58.58,0,1,1,.647.962l-2.854,1.921,2.7,2.127a.58.58,0,0,1,.219.5,71.531,71.531,0,0,1-1.775,7.358A.58.58,0,0,1,28.813,28.688Z"
            transform="translate(-16.636 -9.621)"
          />
          <path
            id="Path_148"
            data-name="Path 148"
            d="M47.488.5a2.528,2.528,0,1,1-2.529,2.528A2.531,2.531,0,0,1,47.488.5Zm0,3.9a1.369,1.369,0,1,0-1.37-1.37A1.371,1.371,0,0,0,47.488,4.4Z"
            transform="translate(-27.914 -0.501)"
          />
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'thin'">
        <svg
          id="Activity_-_Thin"
          data-name="Activity - Thin"
          xmlns="http://www.w3.org/2000/svg"
          width="24.542"
          height="20.757"
          viewBox="0 0 24.542 20.757"
        >
          <path
            id="Path_18"
            data-name="Path 18"
            d="M5.482,39.674a4.731,4.731,0,1,1,4.731-4.731A4.737,4.737,0,0,1,5.482,39.674Zm0-8.69A3.959,3.959,0,1,0,9.44,34.943,3.963,3.963,0,0,0,5.482,30.984Z"
            transform="translate(-0.75 -18.917)"
          />
          <path
            id="Path_19"
            data-name="Path 19"
            d="M44.815,39.674a4.731,4.731,0,1,1,4.732-4.731A4.736,4.736,0,0,1,44.815,39.674Zm0-8.69a3.959,3.959,0,1,0,3.959,3.959A3.963,3.963,0,0,0,44.815,30.984Z"
            transform="translate(-25.004 -18.917)"
          />
          <path
            id="Path_20"
            data-name="Path 20"
            d="M21.646,16.761a.385.385,0,0,1-.246-.089,10.913,10.913,0,0,0-2.862-1.909l0,0a2.429,2.429,0,0,1-1.809-1.588,3.069,3.069,0,0,1,.886-2.456l.028-.027,3.826-3.243.014-.011a4.354,4.354,0,0,1,2.608-1.077l.092-.008a1.891,1.891,0,0,1,1.651.414,3.78,3.78,0,0,1,1.109,1.943,8.841,8.841,0,0,1,.135.913,5.5,5.5,0,0,0,.516,2.143c.334.1,1.526.456,3.94,1.168a.386.386,0,1,1-.219.741c-2.891-.853-4.032-1.2-4.043-1.2a.387.387,0,0,1-.153-.088A4.8,4.8,0,0,1,26.3,9.711c-.038-.327-.075-.637-.119-.824a3,3,0,0,0-.872-1.546c-.038-.03-.045-.029-.065-.064a1.4,1.4,0,0,0-1-.156l-.1.008a3.628,3.628,0,0,0-2.195.914l-3.8,3.223a2.265,2.265,0,0,0-.68,1.724c.108.436.546.785,1.3,1.037a11.242,11.242,0,0,1,3.116,2.049.386.386,0,0,1-.246.684Z"
            transform="translate(-10.569 -4.195)"
          />
          <path
            id="Path_21"
            data-name="Path 21"
            d="M28.87,28.551a.387.387,0,0,1-.374-.485c1.283-4.839,1.625-6.486,1.715-6.989L27.065,18.6a.386.386,0,0,1,.024-.624L30.6,15.608a.386.386,0,1,1,.431.641L27.96,18.317l2.906,2.291a.386.386,0,0,1,.146.332,72.382,72.382,0,0,1-1.769,7.323A.387.387,0,0,1,28.87,28.551Z"
            transform="translate(-16.886 -9.871)"
          />
          <path
            id="Path_22"
            data-name="Path 22"
            d="M47.544.751a2.335,2.335,0,1,1-2.335,2.335A2.338,2.338,0,0,1,47.544.751Zm0,3.9a1.563,1.563,0,1,0-1.563-1.563A1.565,1.565,0,0,0,47.544,4.649Z"
            transform="translate(-28.164 -0.751)"
          />
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'colour'">
        <svg
          id="Activity_-_Colour"
          data-name="Activity - Colour"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="24.929"
          height="21.144"
          viewBox="0 0 24.929 21.144"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#f7931e" />
              <stop offset="0.1" stop-color="#f78e24" />
              <stop offset="0.256" stop-color="#f78335" />
              <stop offset="0.393" stop-color="#f87052" />
              <stop offset="0.565" stop-color="#fa567a" />
              <stop offset="0.751" stop-color="#fc34ad" />
              <stop offset="1" stop-color="#fe0ceb" />
              <stop offset="1" stop-color="#f0f" />
            </linearGradient>
          </defs>
          <path
            id="Path_144"
            data-name="Path 144"
            d="M5.425,39.81a4.925,4.925,0,1,1,4.924-4.924A4.93,4.93,0,0,1,5.425,39.81Zm0-8.69A3.765,3.765,0,1,0,9.19,34.886,3.77,3.77,0,0,0,5.425,31.12Z"
            transform="translate(-0.5 -18.667)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Path_145"
            data-name="Path 145"
            d="M44.758,39.81a4.925,4.925,0,1,1,4.925-4.924A4.93,4.93,0,0,1,44.758,39.81Zm0-8.69a3.765,3.765,0,1,0,3.766,3.766A3.77,3.77,0,0,0,44.758,31.12Z"
            transform="translate(-24.754 -18.667)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Path_146"
            data-name="Path 146"
            d="M21.589,16.9a.577.577,0,0,1-.369-.133,11.076,11.076,0,0,0-2.8-1.873l-.008,0a2.6,2.6,0,0,1-1.936-1.725,3.2,3.2,0,0,1,.935-2.636.58.58,0,0,1,.043-.04l3.826-3.243.021-.017a4.577,4.577,0,0,1,2.709-1.117L24.1,6.1a2.106,2.106,0,0,1,1.8.465,4,4,0,0,1,1.165,2.039,8.939,8.939,0,0,1,.139.935,6.329,6.329,0,0,0,.441,2c.4.121,1.594.475,3.878,1.149A.58.58,0,1,1,31.2,13.8c-2.891-.853-4.033-1.2-4.044-1.2a.58.58,0,0,1-.23-.133,4.817,4.817,0,0,1-.868-2.793c-.038-.322-.073-.626-.115-.8a2.778,2.778,0,0,0-.8-1.439.481.481,0,0,1-.076-.072,1.342,1.342,0,0,0-.852-.107l-.1.008a3.42,3.42,0,0,0-2.09.872l-3.79,3.213c-.223.243-.764.983-.626,1.539.114.456.7.74,1.172.9a11.24,11.24,0,0,1,3.181,2.084.58.58,0,0,1-.37,1.026Z"
            transform="translate(-10.319 -3.945)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Path_147"
            data-name="Path 147"
            d="M28.813,28.688a.58.58,0,0,1-.561-.728c1.2-4.526,1.575-6.253,1.69-6.86l-3.054-2.408a.58.58,0,0,1,.035-.936l3.514-2.364a.58.58,0,1,1,.647.962l-2.854,1.921,2.7,2.127a.58.58,0,0,1,.219.5,71.531,71.531,0,0,1-1.775,7.358A.58.58,0,0,1,28.813,28.688Z"
            transform="translate(-16.636 -9.621)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Path_148"
            data-name="Path 148"
            d="M47.488.5a2.528,2.528,0,1,1-2.529,2.528A2.531,2.531,0,0,1,47.488.5Zm0,3.9a1.369,1.369,0,1,0-1.37-1.37A1.371,1.371,0,0,0,47.488,4.4Z"
            transform="translate(-27.914 -0.501)"
            fill="url(#linear-gradient)"
          />
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'fitness'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          id="Physical_-_Thick"
          data-name="Physical - Thick"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="26.202"
          viewBox="0 0 20 26.202"
        >
          <path
            id="Line_23"
            data-name="Line 23"
            d="M-.144,1.77A.606.606,0,0,1-.75,1.164V-.144A.606.606,0,0,1-.144-.75a.606.606,0,0,1,.606.606V1.164A.606.606,0,0,1-.144,1.77Z"
            transform="translate(5.642 10.393)"
            fill="#2b3642"
          />
          <g id="Group_19" data-name="Group 19" transform="translate(0 0)">
            <g id="Group_265" data-name="Group 265" transform="translate(0 0)">
              <path
                id="Path_28"
                data-name="Path 28"
                d="M10.831,9.645a.606.606,0,0,1-.606-.606V6.514h-1.3a1.059,1.059,0,0,1-1.01-.659,1.208,1.208,0,0,1,.228-1.29L11.828.872a1.267,1.267,0,0,1,1.791,0l3.7,3.7a1.217,1.217,0,0,1,.206,1.293l0,0a1,1,0,0,1-.933.652H15.365V8.631a.606.606,0,1,1-1.211,0V5.3h2.188L12.763,1.727a.057.057,0,0,0-.077,0L9.123,5.3h2.314V9.039A.606.606,0,0,1,10.831,9.645Z"
                transform="translate(-5.334 -0.5)"
                fill="#2b3642"
              />
              <path
                id="Line_24"
                data-name="Line 24"
                d="M-.144,1.436A.606.606,0,0,1-.75.83V-.144A.606.606,0,0,1-.144-.75a.606.606,0,0,1,.606.606V.83A.606.606,0,0,1-.144,1.436Z"
                transform="translate(16.51 18.755)"
                fill="#2b3642"
              />
              <path
                id="Line_25"
                data-name="Line 25"
                d="M-.144,1.351A.606.606,0,0,1-.75.745v-.89A.606.606,0,0,1-.144-.75a.606.606,0,0,1,.606.606v.89A.606.606,0,0,1-.144,1.351Z"
                transform="translate(11.233 20.735)"
                fill="#2b3642"
              />
              <path
                id="Line_26"
                data-name="Line 26"
                d="M-.144,2.056A.606.606,0,0,1-.75,1.45V-.144A.606.606,0,0,1-.144-.75a.606.606,0,0,1,.606.606V1.45A.606.606,0,0,1-.144,2.056Z"
                transform="translate(11.233 17.45)"
                fill="#2b3642"
              />
              <path
                id="Path_29"
                data-name="Path 29"
                d="M30.936,29.828a.606.606,0,0,1-.606-.606v-4.47h3.006v0a.36.36,0,0,0,.014-.213L28.4,19.595a.283.283,0,0,0-.2-.082.275.275,0,0,0-.2.081l-4.941,4.959a.265.265,0,0,0-.008.19l0,.007a.184.184,0,0,0,.044,0h3.16v3.228a.606.606,0,1,1-1.211,0V25.965H23.1a1.221,1.221,0,0,1-1.164-.757A1.429,1.429,0,0,1,22.2,23.7l4.945-4.963a1.494,1.494,0,0,1,2.111,0L34.226,23.7a1.44,1.44,0,0,1,.231,1.512l0,0a1.142,1.142,0,0,1-1.062.748H31.542v3.258A.606.606,0,0,1,30.936,29.828Z"
                transform="translate(-14.571 -12.242)"
                fill="#2b3642"
              />
              <path
                id="Line_27"
                data-name="Line 27"
                d="M-.145,8.326a.6.6,0,0,1-.343-.107.606.606,0,0,1-.155-.842L4.776-.488a.606.606,0,0,1,.842-.155A.606.606,0,0,1,5.773.2L.354,8.064A.605.605,0,0,1-.145,8.326Z"
                transform="translate(2.201 14.78)"
                fill="#2b3642"
              />
              <path
                id="Path_30"
                data-name="Path 30"
                d="M18.148,61.425A2.125,2.125,0,1,1,20.272,59.3,2.127,2.127,0,0,1,18.148,61.425Zm0-3.039a.914.914,0,1,0,.913.914A.914.914,0,0,0,18.148,58.386Z"
                transform="translate(-10.74 -37.884)"
                fill="#2b3642"
              />
              <path
                id="Path_31"
                data-name="Path 31"
                d="M2.625,44.687a2.124,2.124,0,1,1,2.124-2.124A2.127,2.127,0,0,1,2.625,44.687Zm0-3.037a.913.913,0,1,0,.913.913A.914.914,0,0,0,2.625,41.65Z"
                transform="translate(-0.5 -26.845)"
                fill="#2b3642"
              />
              <path
                id="Line_28"
                data-name="Line 28"
                d="M-.144,4.852A.606.606,0,0,1-.75,4.247V-.144A.606.606,0,0,1-.144-.75a.606.606,0,0,1,.606.606V4.247A.606.606,0,0,1-.144,4.852Z"
                transform="translate(13.775 15.082)"
                fill="#2b3642"
              />
              <path
                id="Line_29"
                data-name="Line 29"
                d="M-.144,2.649A.606.606,0,0,1-.75,2.044V-.144A.606.606,0,0,1-.144-.75a.606.606,0,0,1,.606.606V2.044A.606.606,0,0,1-.144,2.649Z"
                transform="translate(13.775 21.165)"
                fill="#2b3642"
              />
              <path
                id="Line_30"
                data-name="Line 30"
                d="M-.144,1.157A.606.606,0,0,1-.75.551v-.7A.606.606,0,0,1-.144-.75a.606.606,0,0,1,.606.606v.7A.606.606,0,0,1-.144,1.157Z"
                transform="translate(13.775 25.045)"
                fill="#2b3642"
              />
            </g>
          </g>
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'thin'">
        <svg
          id="Physical_-_Thin"
          data-name="Physical - Thin"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="19.641"
          height="25.841"
          viewBox="0 0 19.641 25.841"
        >
          <defs>
            <clipPath id="clip-path">
              <rect
                id="Rectangle_9"
                data-name="Rectangle 9"
                width="19.641"
                height="25.841"
                fill="none"
                stroke="#707070"
                stroke-width="1"
              />
            </clipPath>
          </defs>
          <path
            id="Line_23"
            data-name="Line 23"
            d="M-.1,1.616a.4.4,0,0,1-.4-.4V-.1A.4.4,0,0,1-.1-.5a.4.4,0,0,1,.4.4V1.212A.4.4,0,0,1-.1,1.616Z"
            transform="translate(5.414 10.164)"
            fill="#2b3642"
          />
          <g id="Group_19" data-name="Group 19" transform="translate(0)">
            <g id="Group_18" data-name="Group 18">
              <path
                id="Path_28"
                data-name="Path 28"
                d="M10.88,9.491a.4.4,0,0,1-.4-.4V6.36h-1.5a.862.862,0,0,1-.823-.535,1.015,1.015,0,0,1,.185-1.07l3.68-3.694a1.065,1.065,0,0,1,1.505,0l3.7,3.695a1.025,1.025,0,0,1,.162,1.074.806.806,0,0,1-.748.531H15.211V8.679a.4.4,0,1,1-.808,0V5.552h2.218a.175.175,0,0,0,.017-.032.3.3,0,0,0,.006-.2l-3.69-3.687a.258.258,0,0,0-.183-.075.251.251,0,0,0-.18.074L8.912,5.326a.234.234,0,0,0-.012.19c.009.022.016.038.078.038h2.305V9.087A.4.4,0,0,1,10.88,9.491Z"
                transform="translate(-5.562 -0.728)"
                fill="#2b3642"
              />
              <path
                id="Line_24"
                data-name="Line 24"
                d="M-.1,1.282a.4.4,0,0,1-.4-.4V-.1A.4.4,0,0,1-.1-.5a.4.4,0,0,1,.4.4V.878A.4.4,0,0,1-.1,1.282Z"
                transform="translate(16.281 18.526)"
                fill="#2b3642"
              />
              <path
                id="Line_25"
                data-name="Line 25"
                d="M-.1,1.2a.4.4,0,0,1-.4-.4V-.1A.4.4,0,0,1-.1-.5a.4.4,0,0,1,.4.4v.89A.4.4,0,0,1-.1,1.2Z"
                transform="translate(11.004 20.507)"
                fill="#2b3642"
              />
              <path
                id="Line_26"
                data-name="Line 26"
                d="M-.1,1.9a.4.4,0,0,1-.4-.4V-.1A.4.4,0,0,1-.1-.5a.4.4,0,0,1,.4.4V1.5A.4.4,0,0,1-.1,1.9Z"
                transform="translate(11.004 17.221)"
                fill="#2b3642"
              />
              <path
                id="Path_29"
                data-name="Path 29"
                d="M30.984,29.675a.4.4,0,0,1-.4-.4V25h2.861c.023,0,.077,0,.131-.128a.48.48,0,0,0-.012-.413L28.592,19.5a.483.483,0,0,0-.345-.141.474.474,0,0,0-.338.14l-4.945,4.964a.434.434,0,0,0-.046.406.215.215,0,0,0,.233.136h2.959V28.03a.4.4,0,0,1-.808,0V25.811H23.151a1.024,1.024,0,0,1-.978-.633,1.219,1.219,0,0,1,.219-1.286l4.945-4.963a1.292,1.292,0,0,1,1.826,0l4.968,4.964a1.231,1.231,0,0,1,.187,1.293.948.948,0,0,1-.877.627H31.388v3.46A.4.4,0,0,1,30.984,29.675Z"
                transform="translate(-14.799 -12.47)"
                fill="#2b3642"
              />
              <path
                id="Line_27"
                data-name="Line 27"
                d="M-.1,8.172A.4.4,0,0,1-.325,8.1a.4.4,0,0,1-.1-.562L4.99-.325a.4.4,0,0,1,.562-.1.4.4,0,0,1,.1.562L.236,8A.4.4,0,0,1-.1,8.172Z"
                transform="translate(1.972 14.551)"
                fill="#2b3642"
              />
              <path
                id="Path_30"
                data-name="Path 30"
                d="M18.2,61.271a1.923,1.923,0,1,1,1.922-1.923A1.925,1.925,0,0,1,18.2,61.271Zm0-3.039a1.116,1.116,0,1,0,1.115,1.116A1.116,1.116,0,0,0,18.2,58.233Z"
                transform="translate(-10.968 -38.113)"
                fill="#2b3642"
              />
              <path
                id="Path_31"
                data-name="Path 31"
                d="M2.673,44.534A1.922,1.922,0,1,1,4.6,42.612,1.925,1.925,0,0,1,2.673,44.534Zm0-3.037a1.115,1.115,0,1,0,1.115,1.115A1.117,1.117,0,0,0,2.673,41.5Z"
                transform="translate(-0.728 -27.073)"
                fill="#2b3642"
              />
              <path
                id="Line_28"
                data-name="Line 28"
                d="M-.1,4.7a.4.4,0,0,1-.4-.4V-.1A.4.4,0,0,1-.1-.5a.4.4,0,0,1,.4.4V4.295A.4.4,0,0,1-.1,4.7Z"
                transform="translate(13.546 14.854)"
                fill="#2b3642"
              />
              <path
                id="Line_29"
                data-name="Line 29"
                d="M-.1,2.5a.4.4,0,0,1-.4-.4V-.1A.4.4,0,0,1-.1-.5a.4.4,0,0,1,.4.4V2.092A.4.4,0,0,1-.1,2.5Z"
                transform="translate(13.546 20.937)"
                fill="#2b3642"
              />
              <path
                id="Line_30"
                data-name="Line 30"
                d="M-.1,1A.4.4,0,0,1-.5.6V-.1A.4.4,0,0,1-.1-.5a.4.4,0,0,1,.4.4V.6A.4.4,0,0,1-.1,1Z"
                transform="translate(13.546 24.816)"
                fill="#2b3642"
              />
            </g>
          </g>
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'colour'">
        <svg
          id="Physical_-_Colour"
          data-name="Physical - Colour"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="20"
          height="26.202"
          viewBox="0 0 20 26.202"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#f7931e" />
              <stop offset="0.1" stop-color="#f78e24" />
              <stop offset="0.256" stop-color="#f78335" />
              <stop offset="0.393" stop-color="#f87052" />
              <stop offset="0.565" stop-color="#fa567a" />
              <stop offset="0.751" stop-color="#fc34ad" />
              <stop offset="1" stop-color="#fe0ceb" />
              <stop offset="1" stop-color="#f0f" />
            </linearGradient>
          </defs>
          <path
            id="Line_23"
            data-name="Line 23"
            d="M-.144,1.77A.606.606,0,0,1-.75,1.164V-.144A.606.606,0,0,1-.144-.75a.606.606,0,0,1,.606.606V1.164A.606.606,0,0,1-.144,1.77Z"
            transform="translate(5.642 10.393)"
            fill="url(#linear-gradient)"
          />
          <g id="Group_19" data-name="Group 19" transform="translate(0 0)">
            <g id="Group_265" data-name="Group 265" transform="translate(0 0)">
              <path
                id="Path_28"
                data-name="Path 28"
                d="M10.831,9.645a.606.606,0,0,1-.606-.606V6.514h-1.3a1.059,1.059,0,0,1-1.01-.659,1.208,1.208,0,0,1,.228-1.29L11.828.872a1.267,1.267,0,0,1,1.791,0l3.7,3.7a1.217,1.217,0,0,1,.206,1.293l0,0a1,1,0,0,1-.933.652H15.365V8.631a.606.606,0,1,1-1.211,0V5.3h2.188L12.763,1.727a.057.057,0,0,0-.077,0L9.123,5.3h2.314V9.039A.606.606,0,0,1,10.831,9.645Z"
                transform="translate(-5.334 -0.5)"
                fill="url(#linear-gradient)"
              />
              <path
                id="Line_24"
                data-name="Line 24"
                d="M-.144,1.436A.606.606,0,0,1-.75.83V-.144A.606.606,0,0,1-.144-.75a.606.606,0,0,1,.606.606V.83A.606.606,0,0,1-.144,1.436Z"
                transform="translate(16.51 18.755)"
                fill="url(#linear-gradient)"
              />
              <path
                id="Line_25"
                data-name="Line 25"
                d="M-.144,1.351A.606.606,0,0,1-.75.745v-.89A.606.606,0,0,1-.144-.75a.606.606,0,0,1,.606.606v.89A.606.606,0,0,1-.144,1.351Z"
                transform="translate(11.233 20.735)"
                fill="url(#linear-gradient)"
              />
              <path
                id="Line_26"
                data-name="Line 26"
                d="M-.144,2.056A.606.606,0,0,1-.75,1.45V-.144A.606.606,0,0,1-.144-.75a.606.606,0,0,1,.606.606V1.45A.606.606,0,0,1-.144,2.056Z"
                transform="translate(11.233 17.45)"
                fill="url(#linear-gradient)"
              />
              <path
                id="Path_29"
                data-name="Path 29"
                d="M30.936,29.828a.606.606,0,0,1-.606-.606v-4.47h3.006v0a.36.36,0,0,0,.014-.213L28.4,19.595a.283.283,0,0,0-.2-.082.275.275,0,0,0-.2.081l-4.941,4.959a.265.265,0,0,0-.008.19l0,.007a.184.184,0,0,0,.044,0h3.16v3.228a.606.606,0,1,1-1.211,0V25.965H23.1a1.221,1.221,0,0,1-1.164-.757A1.429,1.429,0,0,1,22.2,23.7l4.945-4.963a1.494,1.494,0,0,1,2.111,0L34.226,23.7a1.44,1.44,0,0,1,.231,1.512l0,0a1.142,1.142,0,0,1-1.062.748H31.542v3.258A.606.606,0,0,1,30.936,29.828Z"
                transform="translate(-14.571 -12.242)"
                fill="url(#linear-gradient)"
              />
              <path
                id="Line_27"
                data-name="Line 27"
                d="M-.145,8.326a.6.6,0,0,1-.343-.107.606.606,0,0,1-.155-.842L4.776-.488a.606.606,0,0,1,.842-.155A.606.606,0,0,1,5.773.2L.354,8.064A.605.605,0,0,1-.145,8.326Z"
                transform="translate(2.201 14.78)"
                fill="url(#linear-gradient)"
              />
              <path
                id="Path_30"
                data-name="Path 30"
                d="M18.148,61.425A2.125,2.125,0,1,1,20.272,59.3,2.127,2.127,0,0,1,18.148,61.425Zm0-3.039a.914.914,0,1,0,.913.914A.914.914,0,0,0,18.148,58.386Z"
                transform="translate(-10.74 -37.884)"
                fill="url(#linear-gradient)"
              />
              <path
                id="Path_31"
                data-name="Path 31"
                d="M2.625,44.687a2.124,2.124,0,1,1,2.124-2.124A2.127,2.127,0,0,1,2.625,44.687Zm0-3.037a.913.913,0,1,0,.913.913A.914.914,0,0,0,2.625,41.65Z"
                transform="translate(-0.5 -26.845)"
                fill="url(#linear-gradient)"
              />
              <path
                id="Line_28"
                data-name="Line 28"
                d="M-.144,4.852A.606.606,0,0,1-.75,4.247V-.144A.606.606,0,0,1-.144-.75a.606.606,0,0,1,.606.606V4.247A.606.606,0,0,1-.144,4.852Z"
                transform="translate(13.775 15.082)"
                fill="url(#linear-gradient)"
              />
              <path
                id="Line_29"
                data-name="Line 29"
                d="M-.144,2.649A.606.606,0,0,1-.75,2.044V-.144A.606.606,0,0,1-.144-.75a.606.606,0,0,1,.606.606V2.044A.606.606,0,0,1-.144,2.649Z"
                transform="translate(13.775 21.165)"
                fill="url(#linear-gradient)"
              />
              <path
                id="Line_30"
                data-name="Line 30"
                d="M-.144,1.157A.606.606,0,0,1-.75.551v-.7A.606.606,0,0,1-.144-.75a.606.606,0,0,1,.606.606v.7A.606.606,0,0,1-.144,1.157Z"
                transform="translate(13.775 25.045)"
                fill="url(#linear-gradient)"
              />
            </g>
          </g>
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'sitting'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          id="Sitting_-_Thick"
          data-name="Sitting - Thick"
          xmlns="http://www.w3.org/2000/svg"
          width="23.192"
          height="25.781"
          viewBox="0 0 23.192 25.781"
        >
          <path
            id="Path_139"
            data-name="Path 139"
            d="M4.24,31.9A3.914,3.914,0,0,1,.315,28.009V20.1h1.2v7.911A2.713,2.713,0,0,0,4.24,30.7Z"
            transform="translate(-0.315 -11.918)"
          />
          <path
            id="Line_72"
            data-name="Line 72"
            d="M14.608.449H0V-.75H14.608Z"
            transform="translate(8.584 14.856)"
          />
          <path
            id="Line_73"
            data-name="Line 73"
            d="M.449,9.275H-.75V0h1.2Z"
            transform="translate(21.523 16.207)"
          />
          <path
            id="Path_140"
            data-name="Path 140"
            d="M39.362,27.011,38.236,26.6l2.021-5.488H34.116l-.846,2.342-1.128-.408.989-2.738a.6.6,0,0,1,.564-.4h7.422a.6.6,0,0,1,.563.807Z"
            transform="translate(-19.323 -12.103)"
          />
          <path
            id="Path_141"
            data-name="Path 141"
            d="M14.392,6.255h0A2.877,2.877,0,0,1,14.39.5a2.877,2.877,0,0,1,0,5.755Zm0-4.555a1.678,1.678,0,0,0,0,3.356h0a1.678,1.678,0,0,0,0-3.356Z"
            transform="translate(-7.009 -0.501)"
          />
          <path
            id="Path_142"
            data-name="Path 142"
            d="M15.156,35.454c-.8,0-1.657-.361-2.472-1.758-.9-1.54-2.071-3.362-2.511-4.039H6.024a2.89,2.89,0,0,1-2.349-1,4.906,4.906,0,0,1-.7-3.6L4.169,17.9a2.316,2.316,0,0,1,1.122-1.654A3,3,0,0,1,8.019,16.1a.6.6,0,0,1,.178.11l6.673,5.946a.6.6,0,0,1,.194.538,1.859,1.859,0,0,1-1,1.432,2.925,2.925,0,0,1-2.579-.157.6.6,0,0,1-.071-.044L6.848,20.636l.7-.973,4.533,3.263a1.747,1.747,0,0,0,1.444.13.579.579,0,0,0,.264-.256L7.483,17.18a1.8,1.8,0,0,0-1.565.087,1.121,1.121,0,0,0-.557.768q0,.021-.005.042L4.163,25.261l0,.013A3.687,3.687,0,0,0,4.6,27.9a1.731,1.731,0,0,0,1.423.559H10.5a.6.6,0,0,1,.5.271c.016.024,1.581,2.412,2.72,4.362.628,1.074,1.11,1.241,1.764,1.134l-2.865-6.532c-.005-.012-.008-.017-.012-.029a1.5,1.5,0,0,0-.353-.507l-.02-.019a1.509,1.509,0,0,0-.469-.323l-4.2-1.024a.6.6,0,0,1-.438-.734l.619-2.382,1.161.3-.466,1.792,3.639.886.028.007a2.485,2.485,0,0,1,.956.606,2.7,2.7,0,0,1,.662.953L16.87,34.4a.6.6,0,0,1-.391.819A5.043,5.043,0,0,1,15.156,35.454Z"
            transform="translate(-1.859 -9.673)"
          />
          <path
            id="Line_74"
            data-name="Line 74"
            d="M.449,4.127H-.75V0h1.2Z"
            transform="translate(4.972 19.45)"
          />
          <path
            id="Path_143"
            data-name="Path 143"
            d="M10.636,60.224h-1.2v-.982a.264.264,0,0,0-.261-.261H5.235a.265.265,0,0,0-.261.261v.982h-1.2v-.982a1.462,1.462,0,0,1,1.461-1.46H9.176a1.462,1.462,0,0,1,1.46,1.46Z"
            transform="translate(-2.383 -34.742)"
          />
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'thin'">
        <svg
          id="Sitting_-_Thin"
          data-name="Sitting - Thin"
          xmlns="http://www.w3.org/2000/svg"
          width="22.992"
          height="25.381"
          viewBox="0 0 22.992 25.381"
        >
          <path
            id="Path_23"
            data-name="Path 23"
            d="M4.29,31.7A3.714,3.714,0,0,1,.565,28.009V20.1h.8v7.911A2.913,2.913,0,0,0,4.29,30.9Z"
            transform="translate(-0.565 -12.117)"
          />
          <path
            id="Line_20"
            data-name="Line 20"
            d="M14.608.3H0V-.5H14.608Z"
            transform="translate(8.384 14.606)"
          />
          <path
            id="Line_21"
            data-name="Line 21"
            d="M.3,9.275H-.5V0H.3Z"
            transform="translate(21.273 16.007)"
          />
          <path
            id="Path_24"
            data-name="Path 24"
            d="M39.221,26.992l-.75-.276,2.12-5.757H34.023l-.894,2.474-.752-.272.989-2.738a.4.4,0,0,1,.376-.264h7.422a.4.4,0,0,1,.375.538Z"
            transform="translate(-19.57 -12.353)"
          />
          <path
            id="Path_25"
            data-name="Path 25"
            d="M14.442,6.105h0a2.678,2.678,0,1,1,.973-.184A2.662,2.662,0,0,1,14.441,6.105Zm0-4.555a1.878,1.878,0,1,0,1.749,1.2A1.868,1.868,0,0,0,14.44,1.55Z"
            transform="translate(-7.259 -0.751)"
          />
          <path
            id="Path_26"
            data-name="Path 26"
            d="M15.209,35.3c-.752,0-1.535-.345-2.3-1.659-.945-1.619-2.19-3.547-2.575-4.138H6.074a2.7,2.7,0,0,1-2.195-.923,4.7,4.7,0,0,1-.652-3.441l1.19-7.168a2.112,2.112,0,0,1,1.028-1.511,2.808,2.808,0,0,1,2.55-.129.4.4,0,0,1,.119.073l6.673,5.946a.4.4,0,0,1,.129.358A1.644,1.644,0,0,1,14.026,24a2.722,2.722,0,0,1-2.4-.155.4.4,0,0,1-.047-.029L7.015,20.524l.467-.649,4.545,3.272a1.959,1.959,0,0,0,1.639.137.8.8,0,0,0,.413-.488l-6.442-5.74a2.007,2.007,0,0,0-1.773.092,1.317,1.317,0,0,0-.652.919q0,.014,0,.028L4.015,25.279l0,.009A3.982,3.982,0,0,0,4.5,28.077a1.917,1.917,0,0,0,1.578.632h4.474a.4.4,0,0,1,.334.18c.016.024,1.578,2.408,2.714,4.354.769,1.317,1.394,1.372,2.22,1.18l-2.965-6.759-.008-.021a1.709,1.709,0,0,0-.407-.584l-.014-.013a1.663,1.663,0,0,0-.549-.371L7.659,25.648a.4.4,0,0,1-.292-.489l.619-2.382.774.2-.517,1.989,3.836.934.018,0a2.311,2.311,0,0,1,.877.56,2.476,2.476,0,0,1,.613.884l3.15,7.18a.4.4,0,0,1-.261.546A4.856,4.856,0,0,1,15.209,35.3Z"
            transform="translate(-2.109 -9.923)"
          />
          <path
            id="Line_22"
            data-name="Line 22"
            d="M.3,4.127H-.5V0H.3Z"
            transform="translate(4.722 19.25)"
          />
          <path
            id="Path_27"
            data-name="Path 27"
            d="M10.486,60.274h-.8v-.982a.461.461,0,0,0-.461-.461H5.285a.462.462,0,0,0-.461.461v.982h-.8v-.982a1.262,1.262,0,0,1,1.261-1.261H9.226a1.262,1.262,0,0,1,1.26,1.261Z"
            transform="translate(-2.633 -34.992)"
          />
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'colour'">
        <svg
          id="Sitting_-_Colour"
          data-name="Sitting - Colour"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="23.192"
          height="25.781"
          viewBox="0 0 23.192 25.781"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#f7931e" />
              <stop offset="0.1" stop-color="#f78e24" />
              <stop offset="0.256" stop-color="#f78335" />
              <stop offset="0.393" stop-color="#f87052" />
              <stop offset="0.565" stop-color="#fa567a" />
              <stop offset="0.751" stop-color="#fc34ad" />
              <stop offset="1" stop-color="#fe0ceb" />
              <stop offset="1" stop-color="#f0f" />
            </linearGradient>
          </defs>
          <path
            id="Path_139"
            data-name="Path 139"
            d="M4.24,31.9A3.914,3.914,0,0,1,.315,28.009V20.1h1.2v7.911A2.713,2.713,0,0,0,4.24,30.7Z"
            transform="translate(-0.315 -11.918)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Line_72"
            data-name="Line 72"
            d="M14.608.449H0V-.75H14.608Z"
            transform="translate(8.584 14.856)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Line_73"
            data-name="Line 73"
            d="M.449,9.275H-.75V0h1.2Z"
            transform="translate(21.523 16.207)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Path_140"
            data-name="Path 140"
            d="M39.362,27.011,38.236,26.6l2.021-5.488H34.116l-.846,2.342-1.128-.408.989-2.738a.6.6,0,0,1,.564-.4h7.422a.6.6,0,0,1,.563.807Z"
            transform="translate(-19.323 -12.103)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Path_141"
            data-name="Path 141"
            d="M14.392,6.255h0A2.877,2.877,0,0,1,14.39.5a2.877,2.877,0,0,1,0,5.755Zm0-4.555a1.678,1.678,0,0,0,0,3.356h0a1.678,1.678,0,0,0,0-3.356Z"
            transform="translate(-7.009 -0.501)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Path_142"
            data-name="Path 142"
            d="M15.156,35.454c-.8,0-1.657-.361-2.472-1.758-.9-1.54-2.071-3.362-2.511-4.039H6.024a2.89,2.89,0,0,1-2.349-1,4.906,4.906,0,0,1-.7-3.6L4.169,17.9a2.316,2.316,0,0,1,1.122-1.654A3,3,0,0,1,8.019,16.1a.6.6,0,0,1,.178.11l6.673,5.946a.6.6,0,0,1,.194.538,1.859,1.859,0,0,1-1,1.432,2.925,2.925,0,0,1-2.579-.157.6.6,0,0,1-.071-.044L6.848,20.636l.7-.973,4.533,3.263a1.747,1.747,0,0,0,1.444.13.579.579,0,0,0,.264-.256L7.483,17.18a1.8,1.8,0,0,0-1.565.087,1.121,1.121,0,0,0-.557.768q0,.021-.005.042L4.163,25.261l0,.013A3.687,3.687,0,0,0,4.6,27.9a1.731,1.731,0,0,0,1.423.559H10.5a.6.6,0,0,1,.5.271c.016.024,1.581,2.412,2.72,4.362.628,1.074,1.11,1.241,1.764,1.134l-2.865-6.532c-.005-.012-.008-.017-.012-.029a1.5,1.5,0,0,0-.353-.507l-.02-.019a1.509,1.509,0,0,0-.469-.323l-4.2-1.024a.6.6,0,0,1-.438-.734l.619-2.382,1.161.3-.466,1.792,3.639.886.028.007a2.485,2.485,0,0,1,.956.606,2.7,2.7,0,0,1,.662.953L16.87,34.4a.6.6,0,0,1-.391.819A5.043,5.043,0,0,1,15.156,35.454Z"
            transform="translate(-1.859 -9.673)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Line_74"
            data-name="Line 74"
            d="M.449,4.127H-.75V0h1.2Z"
            transform="translate(4.972 19.45)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Path_143"
            data-name="Path 143"
            d="M10.636,60.224h-1.2v-.982a.264.264,0,0,0-.261-.261H5.235a.265.265,0,0,0-.261.261v.982h-1.2v-.982a1.462,1.462,0,0,1,1.461-1.46H9.176a1.462,1.462,0,0,1,1.46,1.46Z"
            transform="translate(-2.383 -34.742)"
            fill="url(#linear-gradient)"
          />
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'sleep'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          id="Sleep_-_Thick"
          data-name="Sleep - Thick"
          xmlns="http://www.w3.org/2000/svg"
          width="24.042"
          height="24.318"
          viewBox="0 0 24.042 24.318"
        >
          <path
            id="Path_134"
            data-name="Path 134"
            d="M12.725,26.606A12.229,12.229,0,0,1,7.093,3.526a.567.567,0,0,1,.522,1.006,11.094,11.094,0,0,0,5.109,20.941h0a11.109,11.109,0,0,0,9.849-5.982.567.567,0,0,1,1.005.523,12.242,12.242,0,0,1-10.854,6.593Z"
            transform="translate(-0.5 -2.288)"
          />
          <path
            id="Path_135"
            data-name="Path 135"
            d="M25.282,21.629a12.287,12.287,0,0,1-1.78-.13A12.226,12.226,0,0,1,13.189,11.185a12.251,12.251,0,0,1,1.244-7.418.567.567,0,0,1,1.006.523,11.1,11.1,0,0,0,2,12.958,11,11,0,0,0,6.226,3.13,11.141,11.141,0,0,0,3.437-.032,11.043,11.043,0,0,0,3.294-1.1.567.567,0,1,1,.523,1.006,12.174,12.174,0,0,1-3.631,1.209A12.284,12.284,0,0,1,25.282,21.629Z"
            transform="translate(-8.082 -2.288)"
          />
          <path
            id="Path_136"
            data-name="Path 136"
            d="M34.546,7.877H29.367a.567.567,0,0,1-.435-.929l4.429-5.314H29.343a.567.567,0,1,1,0-1.133h5.228a.567.567,0,0,1,.435.929L30.577,6.744h3.969a.567.567,0,1,1,0,1.133Z"
            transform="translate(-17.57 -0.5)"
          />
          <path
            id="Path_137"
            data-name="Path 137"
            d="M53.164,21.918h-3.7a.567.567,0,0,1-.435-.929l2.943-3.531H49.447a.567.567,0,0,1,0-1.133h3.735a.567.567,0,0,1,.435.929l-2.943,3.531h2.489a.567.567,0,1,1,0,1.133Z"
            transform="translate(-29.706 -10.053)"
          />
          <path
            id="Path_138"
            data-name="Path 138"
            d="M38.168,29.563h-2.6a.567.567,0,0,1-.435-.929l1.837-2.2H35.557a.567.567,0,1,1,0-1.133h2.624a.567.567,0,0,1,.435.929l-1.837,2.2h1.389a.567.567,0,1,1,0,1.133Z"
            transform="translate(-21.321 -15.469)"
          />
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'thin'">
        <svg
          id="Sleep_-_Thin"
          data-name="Sleep - Thin"
          xmlns="http://www.w3.org/2000/svg"
          width="23.664"
          height="23.94"
          viewBox="0 0 23.664 23.94"
        >
          <path
            id="Path_32"
            data-name="Path 32"
            d="M12.786,26.478A12.04,12.04,0,0,1,7.241,3.754a.378.378,0,1,1,.348.67,11.283,11.283,0,0,0,5.2,21.3h0A11.3,11.3,0,0,0,22.8,19.639a.378.378,0,0,1,.67.349,12.054,12.054,0,0,1-10.686,6.491Z"
            transform="translate(-0.75 -2.538)"
          />
          <path
            id="Path_33"
            data-name="Path 33"
            d="M25.343,21.5a12.1,12.1,0,0,1-1.752-.128A12.037,12.037,0,0,1,13.437,11.219a12.062,12.062,0,0,1,1.225-7.3.378.378,0,0,1,.67.348,11.286,11.286,0,0,0,2.036,13.178A11.182,11.182,0,0,0,23.7,20.626a11.33,11.33,0,0,0,3.5-.033,11.232,11.232,0,0,0,3.35-1.115.378.378,0,1,1,.348.67,11.986,11.986,0,0,1-3.575,1.19A12.1,12.1,0,0,1,25.343,21.5Z"
            transform="translate(-8.332 -2.538)"
          />
          <path
            id="Path_34"
            data-name="Path 34"
            d="M34.607,7.749H29.428a.378.378,0,0,1-.29-.62l4.687-5.624H29.4a.378.378,0,0,1,0-.755h5.228a.378.378,0,0,1,.29.62L30.235,6.994h4.372a.378.378,0,0,1,0,.755Z"
            transform="translate(-17.82 -0.75)"
          />
          <path
            id="Path_35"
            data-name="Path 35"
            d="M53.225,21.79h-3.7a.378.378,0,0,1-.29-.62l3.2-3.841H49.508a.378.378,0,0,1,0-.755h3.735a.378.378,0,0,1,.29.62l-3.2,3.841h2.893a.378.378,0,0,1,0,.755Z"
            transform="translate(-29.956 -10.303)"
          />
          <path
            id="Path_36"
            data-name="Path 36"
            d="M38.229,29.435h-2.6a.378.378,0,0,1-.29-.62l2.1-2.514H35.618a.378.378,0,0,1,0-.755h2.624a.378.378,0,0,1,.29.62l-2.1,2.514h1.792a.378.378,0,1,1,0,.755Z"
            transform="translate(-21.571 -15.719)"
          />
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'colour'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="273.042"
          height="24.318"
          viewBox="0 0 273.042 24.318"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#f7931e" />
              <stop offset="0.1" stop-color="#f78e24" />
              <stop offset="0.256" stop-color="#f78335" />
              <stop offset="0.393" stop-color="#f87052" />
              <stop offset="0.565" stop-color="#fa567a" />
              <stop offset="0.751" stop-color="#fc34ad" />
              <stop offset="1" stop-color="#fe0ceb" />
              <stop offset="1" stop-color="#f0f" />
            </linearGradient>
          </defs>
          <g id="Sleep" transform="translate(-51 -315)">
            <g
              id="Sleep_-_Thin"
              data-name="Sleep - Thin"
              transform="translate(51 315)"
            >
              <path
                id="Path_32"
                data-name="Path 32"
                d="M12.786,26.478A12.04,12.04,0,0,1,7.241,3.754a.378.378,0,1,1,.348.67,11.283,11.283,0,0,0,5.2,21.3h0A11.3,11.3,0,0,0,22.8,19.639a.378.378,0,0,1,.67.349,12.054,12.054,0,0,1-10.686,6.491Z"
                transform="translate(-0.75 -2.538)"
              />
              <path
                id="Path_33"
                data-name="Path 33"
                d="M25.343,21.5a12.1,12.1,0,0,1-1.752-.128A12.037,12.037,0,0,1,13.437,11.219a12.062,12.062,0,0,1,1.225-7.3.378.378,0,0,1,.67.348,11.286,11.286,0,0,0,2.036,13.178A11.182,11.182,0,0,0,23.7,20.626a11.33,11.33,0,0,0,3.5-.033,11.232,11.232,0,0,0,3.35-1.115.378.378,0,1,1,.348.67,11.986,11.986,0,0,1-3.575,1.19A12.1,12.1,0,0,1,25.343,21.5Z"
                transform="translate(-8.332 -2.538)"
              />
              <path
                id="Path_34"
                data-name="Path 34"
                d="M34.607,7.749H29.428a.378.378,0,0,1-.29-.62l4.687-5.624H29.4a.378.378,0,0,1,0-.755h5.228a.378.378,0,0,1,.29.62L30.235,6.994h4.372a.378.378,0,0,1,0,.755Z"
                transform="translate(-17.82 -0.75)"
              />
              <path
                id="Path_35"
                data-name="Path 35"
                d="M53.225,21.79h-3.7a.378.378,0,0,1-.29-.62l3.2-3.841H49.508a.378.378,0,0,1,0-.755h3.735a.378.378,0,0,1,.29.62l-3.2,3.841h2.893a.378.378,0,0,1,0,.755Z"
                transform="translate(-29.956 -10.303)"
              />
              <path
                id="Path_36"
                data-name="Path 36"
                d="M38.229,29.435h-2.6a.378.378,0,0,1-.29-.62l2.1-2.514H35.618a.378.378,0,0,1,0-.755h2.624a.378.378,0,0,1,.29.62l-2.1,2.514h1.792a.378.378,0,1,1,0,.755Z"
                transform="translate(-21.571 -15.719)"
              />
            </g>
            <g
              id="Sleep_-_Thick"
              data-name="Sleep - Thick"
              transform="translate(175 315)"
            >
              <path
                id="Path_134"
                data-name="Path 134"
                d="M12.725,26.606A12.229,12.229,0,0,1,7.093,3.526a.567.567,0,0,1,.522,1.006,11.094,11.094,0,0,0,5.109,20.941h0a11.109,11.109,0,0,0,9.849-5.982.567.567,0,0,1,1.005.523,12.242,12.242,0,0,1-10.854,6.593Z"
                transform="translate(-0.5 -2.288)"
              />
              <path
                id="Path_135"
                data-name="Path 135"
                d="M25.282,21.629a12.287,12.287,0,0,1-1.78-.13A12.226,12.226,0,0,1,13.189,11.185a12.251,12.251,0,0,1,1.244-7.418.567.567,0,0,1,1.006.523,11.1,11.1,0,0,0,2,12.958,11,11,0,0,0,6.226,3.13,11.141,11.141,0,0,0,3.437-.032,11.043,11.043,0,0,0,3.294-1.1.567.567,0,1,1,.523,1.006,12.174,12.174,0,0,1-3.631,1.209A12.284,12.284,0,0,1,25.282,21.629Z"
                transform="translate(-8.082 -2.288)"
              />
              <path
                id="Path_136"
                data-name="Path 136"
                d="M34.546,7.877H29.367a.567.567,0,0,1-.435-.929l4.429-5.314H29.343a.567.567,0,1,1,0-1.133h5.228a.567.567,0,0,1,.435.929L30.577,6.744h3.969a.567.567,0,1,1,0,1.133Z"
                transform="translate(-17.57 -0.5)"
              />
              <path
                id="Path_137"
                data-name="Path 137"
                d="M53.164,21.918h-3.7a.567.567,0,0,1-.435-.929l2.943-3.531H49.447a.567.567,0,0,1,0-1.133h3.735a.567.567,0,0,1,.435.929l-2.943,3.531h2.489a.567.567,0,1,1,0,1.133Z"
                transform="translate(-29.706 -10.053)"
              />
              <path
                id="Path_138"
                data-name="Path 138"
                d="M38.168,29.563h-2.6a.567.567,0,0,1-.435-.929l1.837-2.2H35.557a.567.567,0,1,1,0-1.133h2.624a.567.567,0,0,1,.435.929l-1.837,2.2h1.389a.567.567,0,1,1,0,1.133Z"
                transform="translate(-21.321 -15.469)"
              />
            </g>
            <g
              id="Sleep_-_Colour"
              data-name="Sleep - Colour"
              transform="translate(300 315)"
            >
              <path
                id="Path_134-2"
                data-name="Path 134"
                d="M12.725,26.606A12.229,12.229,0,0,1,7.093,3.526a.567.567,0,0,1,.522,1.006,11.094,11.094,0,0,0,5.109,20.941h0a11.109,11.109,0,0,0,9.849-5.982.567.567,0,0,1,1.005.523,12.242,12.242,0,0,1-10.854,6.593Z"
                transform="translate(-0.5 -2.288)"
                fill="url(#linear-gradient)"
              />
              <path
                id="Path_135-2"
                data-name="Path 135"
                d="M25.282,21.629a12.287,12.287,0,0,1-1.78-.13A12.226,12.226,0,0,1,13.189,11.185a12.251,12.251,0,0,1,1.244-7.418.567.567,0,0,1,1.006.523,11.1,11.1,0,0,0,2,12.958,11,11,0,0,0,6.226,3.13,11.141,11.141,0,0,0,3.437-.032,11.043,11.043,0,0,0,3.294-1.1.567.567,0,1,1,.523,1.006,12.174,12.174,0,0,1-3.631,1.209A12.284,12.284,0,0,1,25.282,21.629Z"
                transform="translate(-8.082 -2.288)"
                fill="url(#linear-gradient)"
              />
              <path
                id="Path_136-2"
                data-name="Path 136"
                d="M34.546,7.877H29.367a.567.567,0,0,1-.435-.929l4.429-5.314H29.343a.567.567,0,1,1,0-1.133h5.228a.567.567,0,0,1,.435.929L30.577,6.744h3.969a.567.567,0,1,1,0,1.133Z"
                transform="translate(-17.57 -0.5)"
                fill="url(#linear-gradient)"
              />
              <path
                id="Path_137-2"
                data-name="Path 137"
                d="M53.164,21.918h-3.7a.567.567,0,0,1-.435-.929l2.943-3.531H49.447a.567.567,0,0,1,0-1.133h3.735a.567.567,0,0,1,.435.929l-2.943,3.531h2.489a.567.567,0,1,1,0,1.133Z"
                transform="translate(-29.706 -10.053)"
                fill="url(#linear-gradient)"
              />
              <path
                id="Path_138-2"
                data-name="Path 138"
                d="M38.168,29.563h-2.6a.567.567,0,0,1-.435-.929l1.837-2.2H35.557a.567.567,0,1,1,0-1.133h2.624a.567.567,0,0,1,.435.929l-1.837,2.2h1.389a.567.567,0,1,1,0,1.133Z"
                transform="translate(-21.321 -15.469)"
                fill="url(#linear-gradient)"
              />
            </g>
          </g>
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'overview'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23.46"
          height="26.44"
          viewBox="0 0 23.46 26.44"
        >
          <g
            id="Home_-_Thick"
            data-name="Home - Thick"
            transform="translate(0 0)"
          >
            <path
              id="Path_2"
              data-name="Path 2"
              d="M29.693,40.269h-1.1V39.146a1.3,1.3,0,0,0-1.239-1.336l-1.1,1.3a.55.55,0,0,1-.828.015L24.236,37.81a1.229,1.229,0,0,0-1.155,1.336v1.123h-1.1V39.146a2.4,2.4,0,0,1,.723-1.8,2.546,2.546,0,0,1,1.764-.65.55.55,0,0,1,.407.18l.947,1.043.866-1.027a.55.55,0,0,1,.421-.2,2.706,2.706,0,0,1,1.8.637,2.368,2.368,0,0,1,.786,1.811Z"
              transform="translate(-14.107 -23.429)"
              fill="#2b3642"
            />
            <path
              id="Path_3"
              data-name="Path 3"
              d="M28.728,27.934a2.2,2.2,0,0,1-2.184-2.209v-.39a2.184,2.184,0,1,1,4.367,0v.39A2.2,2.2,0,0,1,28.728,27.934Zm0-3.709a1.1,1.1,0,0,0-1.084,1.109v.39a1.084,1.084,0,1,0,2.166,0v-.39A1.1,1.1,0,0,0,28.728,24.225Z"
              transform="translate(-16.997 -14.832)"
              fill="#2b3642"
            />
            <path
              id="Line_1"
              data-name="Line 1"
              d="M3.291,2.424-.375.3.175-.649,3.842,1.472Z"
              transform="translate(14.76 4.063)"
              fill="#2b3642"
            />
            <path
              id="Line_2"
              data-name="Line 2"
              d="M.175,2.424l-.551-.952L3.291-.649,3.842.3Z"
              transform="translate(5.232 4.063)"
              fill="#2b3642"
            />
            <path
              id="Line_3"
              data-name="Line 3"
              d="M.175,2.424l-.551-.952L3.291-.649,3.842.3Z"
              transform="translate(14.76 20.602)"
              fill="#2b3642"
            />
            <path
              id="Line_4"
              data-name="Line 4"
              d="M3.291,2.424-.375.3.175-.649,3.842,1.472Z"
              transform="translate(5.232 20.602)"
              fill="#2b3642"
            />
            <path
              id="Path_4"
              data-name="Path 4"
              d="M28.743.5a2.174,2.174,0,1,1-2.174,2.174A2.177,2.177,0,0,1,28.743.5Zm0,3.248a1.074,1.074,0,1,0-1.074-1.074A1.075,1.075,0,0,0,28.743,3.748Z"
              transform="translate(-17.013 -0.5)"
              fill="#2b3642"
            />
            <path
              id="Line_5"
              data-name="Line 5"
              d="M.35,2.48H-.75V0H.35Z"
              transform="translate(11.93 3.979)"
              fill="#2b3642"
            />
            <path
              id="Path_5"
              data-name="Path 5"
              d="M28.743,60.773a2.174,2.174,0,1,1-2.174,2.174A2.176,2.176,0,0,1,28.743,60.773Zm0,3.248a1.074,1.074,0,1,0-1.074-1.074A1.075,1.075,0,0,0,28.743,64.021Z"
              transform="translate(-17.013 -38.68)"
              fill="#2b3642"
            />
            <path
              id="Line_6"
              data-name="Line 6"
              d="M.35,2.479H-.75V0H.35Z"
              transform="translate(11.93 19.982)"
              fill="#2b3642"
            />
            <path
              id="Path_6"
              data-name="Path 6"
              d="M54.814,19.864A2.175,2.175,0,1,1,55.9,19.57,2.184,2.184,0,0,1,54.814,19.864Zm0-3.248a1.074,1.074,0,1,0,.93.535A1.072,1.072,0,0,0,54.811,16.616Z"
              transform="translate(-33.527 -10.012)"
              fill="#2b3642"
            />
            <path
              id="Line_7"
              data-name="Line 7"
              d="M.176,1.547-.376.6,1.769-.649,2.321.3Z"
              transform="translate(17.679 8.756)"
              fill="#2b3642"
            />
            <path
              id="Path_7"
              data-name="Path 7"
              d="M2.674,19.864A2.174,2.174,0,1,1,4.556,18.78,2.165,2.165,0,0,1,2.674,19.864Zm0-3.248a1.075,1.075,0,1,0,.537.145A1.079,1.079,0,0,0,2.676,16.616Z"
              transform="translate(-0.5 -10.012)"
              fill="#2b3642"
            />
            <path
              id="Line_8"
              data-name="Line 8"
              d="M1.769,1.547-.376.3.176-.649,2.321.6Z"
              transform="translate(3.836 8.756)"
              fill="#2b3642"
            />
            <path
              id="Path_8"
              data-name="Path 8"
              d="M2.673,45.754A2.174,2.174,0,1,1,.794,49.019a2.174,2.174,0,0,1,1.878-3.265Zm0,3.249a1.074,1.074,0,1,0-.93-.536A1.071,1.071,0,0,0,2.676,49Z"
              transform="translate(-0.5 -29.166)"
              fill="#2b3642"
            />
            <path
              id="Line_9"
              data-name="Line 9"
              d="M.176,1.547-.376.6,1.769-.649,2.321.3Z"
              transform="translate(3.836 16.786)"
              fill="#2b3642"
            />
            <path
              id="Path_9"
              data-name="Path 9"
              d="M54.814,45.754h0a2.174,2.174,0,1,1-1.883,1.084A2.174,2.174,0,0,1,54.814,45.754Zm0,3.249a1.078,1.078,0,0,0,.93-.535,1.074,1.074,0,0,0-.927-1.613h0a1.074,1.074,0,0,0,0,2.148Z"
              transform="translate(-33.527 -29.166)"
              fill="#2b3642"
            />
            <path
              id="Line_10"
              data-name="Line 10"
              d="M1.769,1.547-.376.3.176-.649,2.321.6Z"
              transform="translate(17.679 16.786)"
              fill="#2b3642"
            />
            <path
              id="Path_10"
              data-name="Path 10"
              d="M19.954,31.281a7.258,7.258,0,1,1,7.26-7.258A7.266,7.266,0,0,1,19.954,31.281Zm0-13.416a6.158,6.158,0,1,0,6.159,6.158A6.165,6.165,0,0,0,19.954,17.865Z"
              transform="translate(-8.226 -10.803)"
              fill="#2b3642"
            />
            <path
              id="Line_11"
              data-name="Line 11"
              d="M.35,4.923H-.75V0H.35Z"
              transform="translate(2.327 10.759)"
              fill="#2b3642"
            />
            <path
              id="Line_12"
              data-name="Line 12"
              d="M.35,4.242H-.75V0H.35Z"
              transform="translate(21.467 11.099)"
              fill="#2b3642"
            />
          </g>
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'thin'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23.093"
          height="26.074"
          viewBox="0 0 23.093 26.074"
        >
          <g
            id="Home_-_Thin"
            data-name="Home - Thin"
            transform="translate(0 0)"
          >
            <path
              id="Path_2"
              data-name="Path 2"
              d="M29.576,40.336h-.733V39.212a1.5,1.5,0,0,0-1.5-1.526l-1.156,1.372a.367.367,0,0,1-.552.01l-1.255-1.383a1.42,1.42,0,0,0-1.413,1.527v1.123h-.733V39.212a2.218,2.218,0,0,1,.666-1.665,2.333,2.333,0,0,1,1.638-.6.367.367,0,0,1,.272.12l1.088,1.2,1-1.188a.367.367,0,0,1,.28-.13,2.524,2.524,0,0,1,1.677.592,2.159,2.159,0,0,1,.723,1.674Z"
              transform="translate(-14.357 -23.679)"
              fill="#2b3642"
            />
            <path
              id="Path_3"
              data-name="Path 3"
              d="M28.794,27.817a2.016,2.016,0,0,1-2-2.026V25.4a2,2,0,1,1,4,0v.39A2.015,2.015,0,0,1,28.794,27.817Zm0-3.709A1.281,1.281,0,0,0,27.527,25.4v.39a1.267,1.267,0,1,0,2.533,0V25.4A1.281,1.281,0,0,0,28.794,24.108Z"
              transform="translate(-17.248 -15.082)"
              fill="#2b3642"
            />
            <path
              id="Line_1"
              data-name="Line 1"
              d="M3.417,2.323-.25.2.117-.433,3.784,1.688Z"
              transform="translate(14.544 3.823)"
              fill="#2b3642"
            />
            <path
              id="Line_2"
              data-name="Line 2"
              d="M.117,2.323-.25,1.688,3.417-.433,3.784.2Z"
              transform="translate(5.016 3.823)"
              fill="#2b3642"
            />
            <path
              id="Line_3"
              data-name="Line 3"
              d="M.117,2.323-.25,1.688,3.417-.433,3.784.2Z"
              transform="translate(14.544 20.361)"
              fill="#2b3642"
            />
            <path
              id="Line_4"
              data-name="Line 4"
              d="M3.417,2.323-.25.2.117-.433,3.784,1.688Z"
              transform="translate(5.016 20.361)"
              fill="#2b3642"
            />
            <path
              id="Path_4"
              data-name="Path 4"
              d="M28.81.75a1.991,1.991,0,1,1-1.991,1.991A1.993,1.993,0,0,1,28.81.75ZM28.81,4a1.257,1.257,0,1,0-1.257-1.257A1.259,1.259,0,0,0,28.81,4Z"
              transform="translate(-17.264 -0.75)"
              fill="#2b3642"
            />
            <path
              id="Line_5"
              data-name="Line 5"
              d="M.233,2.48H-.5V0H.233Z"
              transform="translate(11.68 3.795)"
              fill="#2b3642"
            />
            <path
              id="Path_5"
              data-name="Path 5"
              d="M28.81,61.023a1.991,1.991,0,1,1-1.991,1.991A1.993,1.993,0,0,1,28.81,61.023Zm0,3.248a1.257,1.257,0,1,0-1.257-1.257A1.258,1.258,0,0,0,28.81,64.271Z"
              transform="translate(-17.264 -38.93)"
              fill="#2b3642"
            />
            <path
              id="Line_6"
              data-name="Line 6"
              d="M.233,2.479H-.5V0H.233Z"
              transform="translate(11.68 19.799)"
              fill="#2b3642"
            />
            <path
              id="Path_6"
              data-name="Path 6"
              d="M54.88,19.747a1.991,1.991,0,1,1,1-.269A2,2,0,0,1,54.88,19.747Zm0-3.248a1.257,1.257,0,1,0,1.089.627A1.256,1.256,0,0,0,54.878,16.5Z"
              transform="translate(-33.777 -10.262)"
              fill="#2b3642"
            />
            <path
              id="Line_7"
              data-name="Line 7"
              d="M.117,1.446-.251.812,1.895-.433,2.262.2Z"
              transform="translate(17.462 8.515)"
              fill="#2b3642"
            />
            <path
              id="Path_7"
              data-name="Path 7"
              d="M2.74,19.747a1.973,1.973,0,0,1-1-.269,1.99,1.99,0,1,1,1,.269Zm0-3.248a1.258,1.258,0,1,0,.629.17A1.263,1.263,0,0,0,2.743,16.5Z"
              transform="translate(-0.75 -10.262)"
              fill="#2b3642"
            />
            <path
              id="Line_8"
              data-name="Line 8"
              d="M1.895,1.446-.251.2.117-.433,2.262.812Z"
              transform="translate(3.619 8.515)"
              fill="#2b3642"
            />
            <path
              id="Path_8"
              data-name="Path 8"
              d="M2.74,46a1.991,1.991,0,1,1-1.72,2.99A1.991,1.991,0,0,1,2.74,46Zm0,3.249a1.258,1.258,0,1,0-1.088-.627A1.255,1.255,0,0,0,2.742,49.252Z"
              transform="translate(-0.75 -29.416)"
              fill="#2b3642"
            />
            <path
              id="Line_9"
              data-name="Line 9"
              d="M.117,1.446-.251.812,1.895-.433,2.262.2Z"
              transform="translate(3.619 16.545)"
              fill="#2b3642"
            />
            <path
              id="Path_9"
              data-name="Path 9"
              d="M54.88,46h0A1.991,1.991,0,1,1,53.156,47,1.99,1.99,0,0,1,54.881,46Zm0,3.249a1.262,1.262,0,0,0,1.089-.627,1.258,1.258,0,0,0-1.086-1.888h0a1.258,1.258,0,0,0,0,2.515Z"
              transform="translate(-33.777 -29.416)"
              fill="#2b3642"
            />
            <path
              id="Line_10"
              data-name="Line 10"
              d="M1.895,1.446-.251.2.117-.433,2.262.812Z"
              transform="translate(17.462 16.545)"
              fill="#2b3642"
            />
            <path
              id="Path_10"
              data-name="Path 10"
              d="M20.021,31.165A7.075,7.075,0,1,1,27.1,24.09,7.082,7.082,0,0,1,20.021,31.165Zm0-13.416a6.341,6.341,0,1,0,6.343,6.341A6.348,6.348,0,0,0,20.021,17.748Z"
              transform="translate(-8.476 -11.053)"
              fill="#2b3642"
            />
            <path
              id="Line_11"
              data-name="Line 11"
              d="M.233,4.923H-.5V0H.233Z"
              transform="translate(2.077 10.575)"
              fill="#2b3642"
            />
            <path
              id="Line_12"
              data-name="Line 12"
              d="M.233,4.242H-.5V0H.233Z"
              transform="translate(21.216 10.916)"
              fill="#2b3642"
            />
          </g>
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'colour'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="23.46"
          height="26.44"
          viewBox="0 0 23.46 26.44"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#f7931e" />
              <stop offset="0.1" stop-color="#f78e24" />
              <stop offset="0.256" stop-color="#f78335" />
              <stop offset="0.393" stop-color="#f87052" />
              <stop offset="0.565" stop-color="#fa567a" />
              <stop offset="0.751" stop-color="#fc34ad" />
              <stop offset="1" stop-color="#fe0ceb" />
              <stop offset="1" stop-color="#f0f" />
            </linearGradient>
          </defs>
          <g
            id="Home_-_Thick"
            data-name="Home - Thick"
            transform="translate(0 0)"
          >
            <path
              id="Path_2"
              data-name="Path 2"
              d="M29.693,40.269h-1.1V39.146a1.3,1.3,0,0,0-1.239-1.336l-1.1,1.3a.55.55,0,0,1-.828.015L24.236,37.81a1.229,1.229,0,0,0-1.155,1.336v1.123h-1.1V39.146a2.4,2.4,0,0,1,.723-1.8,2.546,2.546,0,0,1,1.764-.65.55.55,0,0,1,.407.18l.947,1.043.866-1.027a.55.55,0,0,1,.421-.2,2.706,2.706,0,0,1,1.8.637,2.368,2.368,0,0,1,.786,1.811Z"
              transform="translate(-14.107 -23.429)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_3"
              data-name="Path 3"
              d="M28.728,27.934a2.2,2.2,0,0,1-2.184-2.209v-.39a2.184,2.184,0,1,1,4.367,0v.39A2.2,2.2,0,0,1,28.728,27.934Zm0-3.709a1.1,1.1,0,0,0-1.084,1.109v.39a1.084,1.084,0,1,0,2.166,0v-.39A1.1,1.1,0,0,0,28.728,24.225Z"
              transform="translate(-16.997 -14.832)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_1"
              data-name="Line 1"
              d="M3.291,2.424-.375.3.175-.649,3.842,1.472Z"
              transform="translate(14.76 4.063)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_2"
              data-name="Line 2"
              d="M.175,2.424l-.551-.952L3.291-.649,3.842.3Z"
              transform="translate(5.232 4.063)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_3"
              data-name="Line 3"
              d="M.175,2.424l-.551-.952L3.291-.649,3.842.3Z"
              transform="translate(14.76 20.602)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_4"
              data-name="Line 4"
              d="M3.291,2.424-.375.3.175-.649,3.842,1.472Z"
              transform="translate(5.232 20.602)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_4"
              data-name="Path 4"
              d="M28.743.5a2.174,2.174,0,1,1-2.174,2.174A2.177,2.177,0,0,1,28.743.5Zm0,3.248a1.074,1.074,0,1,0-1.074-1.074A1.075,1.075,0,0,0,28.743,3.748Z"
              transform="translate(-17.013 -0.5)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_5"
              data-name="Line 5"
              d="M.35,2.48H-.75V0H.35Z"
              transform="translate(11.93 3.979)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_5"
              data-name="Path 5"
              d="M28.743,60.773a2.174,2.174,0,1,1-2.174,2.174A2.176,2.176,0,0,1,28.743,60.773Zm0,3.248a1.074,1.074,0,1,0-1.074-1.074A1.075,1.075,0,0,0,28.743,64.021Z"
              transform="translate(-17.013 -38.68)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_6"
              data-name="Line 6"
              d="M.35,2.479H-.75V0H.35Z"
              transform="translate(11.93 19.982)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_6"
              data-name="Path 6"
              d="M54.814,19.864A2.175,2.175,0,1,1,55.9,19.57,2.184,2.184,0,0,1,54.814,19.864Zm0-3.248a1.074,1.074,0,1,0,.93.535A1.072,1.072,0,0,0,54.811,16.616Z"
              transform="translate(-33.527 -10.012)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_7"
              data-name="Line 7"
              d="M.176,1.547-.376.6,1.769-.649,2.321.3Z"
              transform="translate(17.679 8.756)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_7"
              data-name="Path 7"
              d="M2.674,19.864A2.174,2.174,0,1,1,4.556,18.78,2.165,2.165,0,0,1,2.674,19.864Zm0-3.248a1.075,1.075,0,1,0,.537.145A1.079,1.079,0,0,0,2.676,16.616Z"
              transform="translate(-0.5 -10.012)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_8"
              data-name="Line 8"
              d="M1.769,1.547-.376.3.176-.649,2.321.6Z"
              transform="translate(3.836 8.756)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_8"
              data-name="Path 8"
              d="M2.673,45.754A2.174,2.174,0,1,1,.794,49.019a2.174,2.174,0,0,1,1.878-3.265Zm0,3.249a1.074,1.074,0,1,0-.93-.536A1.071,1.071,0,0,0,2.676,49Z"
              transform="translate(-0.5 -29.166)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_9"
              data-name="Line 9"
              d="M.176,1.547-.376.6,1.769-.649,2.321.3Z"
              transform="translate(3.836 16.786)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_9"
              data-name="Path 9"
              d="M54.814,45.754h0a2.174,2.174,0,1,1-1.883,1.084A2.174,2.174,0,0,1,54.814,45.754Zm0,3.249a1.078,1.078,0,0,0,.93-.535,1.074,1.074,0,0,0-.927-1.613h0a1.074,1.074,0,0,0,0,2.148Z"
              transform="translate(-33.527 -29.166)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_10"
              data-name="Line 10"
              d="M1.769,1.547-.376.3.176-.649,2.321.6Z"
              transform="translate(17.679 16.786)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_10"
              data-name="Path 10"
              d="M19.954,31.281a7.258,7.258,0,1,1,7.26-7.258A7.266,7.266,0,0,1,19.954,31.281Zm0-13.416a6.158,6.158,0,1,0,6.159,6.158A6.165,6.165,0,0,0,19.954,17.865Z"
              transform="translate(-8.226 -10.803)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_11"
              data-name="Line 11"
              d="M.35,4.923H-.75V0H.35Z"
              transform="translate(2.327 10.759)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_12"
              data-name="Line 12"
              d="M.35,4.242H-.75V0H.35Z"
              transform="translate(21.467 11.099)"
              fill="url(#linear-gradient)"
            />
          </g>
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'more'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="32"
          height="32"
          viewBox="0 0 32 32"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#f7931e" />
              <stop offset="0.1" stop-color="#f78e24" />
              <stop offset="0.256" stop-color="#f78335" />
              <stop offset="0.393" stop-color="#f87052" />
              <stop offset="0.565" stop-color="#fa567a" />
              <stop offset="0.751" stop-color="#fc34ad" />
              <stop offset="1" stop-color="#fe0ceb" />
              <stop offset="1" stop-color="#f0f" />
            </linearGradient>
          </defs>
          <g
            id="Add_-_Hover"
            data-name="Add - Hover"
            transform="translate(-202 -549)"
          >
            <rect
              id="Rectangle_43"
              data-name="Rectangle 43"
              width="32"
              height="32"
              rx="16"
              transform="translate(202 549)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_69"
              data-name="Line 69"
              d="M0,17.5A1.5,1.5,0,0,1-1.5,16V0A1.5,1.5,0,0,1,0-1.5,1.5,1.5,0,0,1,1.5,0V16A1.5,1.5,0,0,1,0,17.5Z"
              transform="translate(218 557)"
              fill="#fff"
            />
            <path
              id="Line_70"
              data-name="Line 70"
              d="M16,1.5H0A1.5,1.5,0,0,1-1.5,0,1.5,1.5,0,0,1,0-1.5H16A1.5,1.5,0,0,1,17.5,0,1.5,1.5,0,0,1,16,1.5Z"
              transform="translate(210 565)"
              fill="#fff"
            />
          </g>
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'thin'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="32"
          height="32"
          viewBox="0 0 32 32"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#f7931e" />
              <stop offset="0.1" stop-color="#f78e24" />
              <stop offset="0.256" stop-color="#f78335" />
              <stop offset="0.393" stop-color="#f87052" />
              <stop offset="0.565" stop-color="#fa567a" />
              <stop offset="0.751" stop-color="#fc34ad" />
              <stop offset="1" stop-color="#fe0ceb" />
              <stop offset="1" stop-color="#f0f" />
            </linearGradient>
          </defs>
          <g
            id="Add_-_Default"
            data-name="Add - Default"
            transform="translate(-202 -549)"
          >
            <rect
              id="Rectangle_43"
              data-name="Rectangle 43"
              width="32"
              height="32"
              rx="16"
              transform="translate(202 549)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_69"
              data-name="Line 69"
              d="M0,16.75A.75.75,0,0,1-.75,16V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V16A.75.75,0,0,1,0,16.75Z"
              transform="translate(218 557)"
              fill="#fff"
            />
            <path
              id="Line_70"
              data-name="Line 70"
              d="M16,.75H0A.75.75,0,0,1-.75,0,.75.75,0,0,1,0-.75H16a.75.75,0,0,1,.75.75A.75.75,0,0,1,16,.75Z"
              transform="translate(210 565)"
              fill="#fff"
            />
          </g>
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'heart-rate'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35.5"
          height="30.423"
          viewBox="0 0 35.5 30.423"
        >
          <g
            id="Heart_Rate_-_Black"
            data-name="Heart Rate - Black"
            transform="translate(0.75 0.754)"
          >
            <g
              id="Group_118"
              data-name="Group 118"
              transform="translate(-0.75 -0.754)"
            >
              <g id="Group_119" data-name="Group 119">
                <path
                  id="Path_89"
                  data-name="Path 89"
                  d="M18.253,30.919h-.014a1.047,1.047,0,0,1-.685-.255c-.164-.142-6.313-6.234-9.675-9.583A1.047,1.047,0,0,1,9.355,19.6c3.323,3.308,7.3,7.242,8.889,8.814,2.271-2.251,9.43-9.376,13.472-13.4a7.426,7.426,0,0,0,2.19-5.205A7.084,7.084,0,0,0,26.693,2.6a7.429,7.429,0,0,0-5.207,2.191L19.012,7.262a1.047,1.047,0,0,1-.815.3l-.048,0a1.047,1.047,0,0,1-.665-.3L15.011,4.786A7.324,7.324,0,0,0,4.666,4.669a7.3,7.3,0,0,0-1.134,8.69A1.047,1.047,0,1,1,1.7,14.377,9.406,9.406,0,0,1,3.186,3.189a9.42,9.42,0,0,1,13.3.117l1.758,1.758,1.757-1.758a9.42,9.42,0,0,1,13.305-.117A9.177,9.177,0,0,1,36,9.828a9.508,9.508,0,0,1-2.8,6.666c-6.421,6.393-14.105,14.04-14.256,14.17A1.047,1.047,0,0,1,18.253,30.919Z"
                  transform="translate(-0.499 -0.496)"
                />
                <path
                  id="Path_90"
                  data-name="Path 90"
                  d="M15.056,28.1a1.046,1.046,0,0,1-.939-.584l-2.488-5.056L10.341,24.2a1.047,1.047,0,0,1-.842.424H1.547a1.047,1.047,0,1,1,0-2.093H8.971l2-2.7a1.047,1.047,0,0,1,1.781.16l2.082,4.23,2.348-7.647a1.047,1.047,0,0,1,1.985-.048l2.185,6.056h3.136a1.047,1.047,0,1,1,0,2.093H20.613a1.047,1.047,0,0,1-.984-.691l-1.37-3.8-2.2,7.172a1.047,1.047,0,0,1-.917.736C15.112,28.1,15.084,28.1,15.056,28.1Z"
                  transform="translate(-0.5 -6.976)"
                />
              </g>
            </g>
          </g>
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'colour'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="35.5"
          height="30.423"
          viewBox="0 0 35.5 30.423"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#f7931e" />
              <stop offset="0.1" stop-color="#f78e24" />
              <stop offset="0.256" stop-color="#f78335" />
              <stop offset="0.393" stop-color="#f87052" />
              <stop offset="0.565" stop-color="#fa567a" />
              <stop offset="0.751" stop-color="#fc34ad" />
              <stop offset="1" stop-color="#fe0ceb" />
              <stop offset="1" stop-color="#f0f" />
            </linearGradient>
          </defs>
          <g
            id="Heart_Rate_-_Colour"
            data-name="Heart Rate - Colour"
            transform="translate(0.75 0.754)"
          >
            <g
              id="Group_118"
              data-name="Group 118"
              transform="translate(-0.75 -0.754)"
            >
              <g id="Group_119" data-name="Group 119">
                <path
                  id="Path_89"
                  data-name="Path 89"
                  d="M18.253,30.919h-.014a1.047,1.047,0,0,1-.685-.255c-.164-.142-6.313-6.234-9.675-9.583A1.047,1.047,0,0,1,9.355,19.6c3.323,3.308,7.3,7.242,8.889,8.814,2.271-2.251,9.43-9.376,13.472-13.4a7.426,7.426,0,0,0,2.19-5.205A7.084,7.084,0,0,0,26.693,2.6a7.429,7.429,0,0,0-5.207,2.191L19.012,7.262a1.047,1.047,0,0,1-.815.3l-.048,0a1.047,1.047,0,0,1-.665-.3L15.011,4.786A7.324,7.324,0,0,0,4.666,4.669a7.3,7.3,0,0,0-1.134,8.69A1.047,1.047,0,1,1,1.7,14.377,9.406,9.406,0,0,1,3.186,3.189a9.42,9.42,0,0,1,13.3.117l1.758,1.758,1.757-1.758a9.42,9.42,0,0,1,13.305-.117A9.177,9.177,0,0,1,36,9.828a9.508,9.508,0,0,1-2.8,6.666c-6.421,6.393-14.105,14.04-14.256,14.17A1.047,1.047,0,0,1,18.253,30.919Z"
                  transform="translate(-0.499 -0.496)"
                  fill="url(#linear-gradient)"
                />
                <path
                  id="Path_90"
                  data-name="Path 90"
                  d="M15.056,28.1a1.046,1.046,0,0,1-.939-.584l-2.488-5.056L10.341,24.2a1.047,1.047,0,0,1-.842.424H1.547a1.047,1.047,0,1,1,0-2.093H8.971l2-2.7a1.047,1.047,0,0,1,1.781.16l2.082,4.23,2.348-7.647a1.047,1.047,0,0,1,1.985-.048l2.185,6.056h3.136a1.047,1.047,0,1,1,0,2.093H20.613a1.047,1.047,0,0,1-.984-.691l-1.37-3.8-2.2,7.172a1.047,1.047,0,0,1-.917.736C15.112,28.1,15.084,28.1,15.056,28.1Z"
                  transform="translate(-0.5 -6.976)"
                  fill="url(#linear-gradient)"
                />
              </g>
            </g>
          </g>
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'vo2max'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30.691"
          height="30.398"
          viewBox="0 0 30.691 30.398"
        >
          <g
            id="VO2_-_Black"
            data-name="VO2 - Black"
            transform="translate(-1.903 312.263)"
          >
            <path
              id="Rectangle_15"
              data-name="Rectangle 15"
              d="M-.309-.875H11.339a.566.566,0,0,1,.566.566V7.456a.566.566,0,0,1-.566.566H-.309a.566.566,0,0,1-.566-.566V-.309A.566.566,0,0,1-.309-.875ZM10.773.257H.257V6.89H10.773Z"
              transform="translate(3.07 -311.388)"
            />
            <path
              id="Path_59"
              data-name="Path 59"
              d="M46.361,6.125a2.513,2.513,0,0,1,1.769.621,1.956,1.956,0,0,1,.634,1.449,3.3,3.3,0,0,1-.811,1.989,2.7,2.7,0,0,1-2.009,1.082c-1.256,0-1.819-1.291-1.819-2.57a2.732,2.732,0,0,1,.665-1.824A2.128,2.128,0,0,1,46.361,6.125Zm-.417,4.008a1.6,1.6,0,0,0,1.137-.672,2.253,2.253,0,0,0,.551-1.267c0-.432-.333-.937-1.271-.937a1.316,1.316,0,0,0-1.1,1.438C45.257,9.1,45.381,10.133,45.944,10.133Z"
              transform="translate(-20.68 -316.176)"
            />
            <path
              id="Path_60"
              data-name="Path 60"
              d="M41.2,35.295a.566.566,0,0,1-.491-.284c-.006-.011-.641-1.115-1.267-2.347-1.178-2.318-1.314-3.077-1.314-3.446,0-.4.17-1.131,1.643-2.812.76-.867,1.508-1.577,1.539-1.607a.566.566,0,0,1,.779.822h0c-.007.007-.746.709-1.472,1.538-1.319,1.506-1.357,2.007-1.357,2.059a9.19,9.19,0,0,0,1.189,2.93c.357.7.716,1.362.956,1.793a.836.836,0,0,0,.229-.232c-.025-.377-.515-2.489-.984-4.352a.566.566,0,0,1,.118-.505,15.4,15.4,0,0,1,3.675-3.14c.63-.33.742-1.46.742-4.477,0-3.8-1.664-4.917-1.991-4.976-.1.006-.719.117-2.351,1.842-.925.978-1.694,1.968-1.7,1.978a.566.566,0,0,1-.2.161c-.009,0-.868.428-1.774.923a11.443,11.443,0,0,0-1.911,1.192.421.421,0,0,0,.067.225,1.141,1.141,0,0,0,.5.062h.029a26.733,26.733,0,0,0,3.51-1.623l2.451-2.236a.566.566,0,0,1,.763.836l-2.5,2.286a.566.566,0,0,1-.119.083c-.008,0-.84.44-1.726.869a6.736,6.736,0,0,1-2.356.918h-.045a1.714,1.714,0,0,1-1.305-.393,1.6,1.6,0,0,1-.406-1.176c0-.438.343-.837,2.3-1.92.815-.452,1.618-.857,1.912-1,.227-.286.9-1.117,1.693-1.958,1.862-1.969,2.708-2.2,3.182-2.2a2.789,2.789,0,0,1,1.958,1.44,8.711,8.711,0,0,1,1.156,4.669,21.721,21.721,0,0,1-.185,3.645,2.339,2.339,0,0,1-1.163,1.835,13.631,13.631,0,0,0-3.148,2.657c.295,1.179.946,3.846.946,4.357a1.487,1.487,0,0,1-.732,1.157,3.894,3.894,0,0,1-.6.358A.564.564,0,0,1,41.2,35.295Z"
              transform="translate(-15.69 -320.667)"
            />
            <path
              id="Path_61"
              data-name="Path 61"
              d="M40.195,40.641a.566.566,0,0,1-.5-.292,13.523,13.523,0,0,1-1.574-4.783c0-.389.191-.972,2.477-2.992.848-.75,1.705-1.446,2.094-1.758v-.4a.566.566,0,1,1,1.132,0v.668a.566.566,0,0,1-.216.445c-.011.008-1.063.839-2.139,1.782-1.868,1.638-2.17,2.179-2.216,2.289a12.833,12.833,0,0,0,1.431,4.2.566.566,0,0,1-.495.841Z"
              transform="translate(-17.686 -328.017)"
            />
            <path
              id="Path_62"
              data-name="Path 62"
              d="M43.725,46.786H27.192a.566.566,0,0,1-.566-.547l-.5-14.528a.566.566,0,0,1,.566-.586h1.5a.566.566,0,0,1,.559.473l1.922,11.531a7.96,7.96,0,0,0,3.693-1.37.566.566,0,1,1,.679.906A8.886,8.886,0,0,1,30.2,44.281a.566.566,0,0,1-.559-.473l-1.925-11.55h-.437l.462,13.4h15.4a26.557,26.557,0,0,0-.844-4.378H39.717a.566.566,0,1,1,0-1.132h3.006a.566.566,0,0,1,.543.407,26,26,0,0,1,1.025,5.67A.566.566,0,0,1,43.725,46.786Z"
              transform="translate(-11.697 -328.651)"
            />
            <path
              id="Path_63"
              data-name="Path 63"
              d="M29.69,30.99a.566.566,0,0,1-.1-1.124l1.246-.222.988-.91a.566.566,0,0,1,.767.833l-1.11,1.022a.566.566,0,0,1-.284.141l-1.409.25A.57.57,0,0,1,29.69,30.99Z"
              transform="translate(-13.195 -327.384)"
            />
            <path
              id="Path_64"
              data-name="Path 64"
              d="M54.163,38.261H50.621a.566.566,0,1,1,0-1.132h3.542a.436.436,0,1,0,0-.871H53.2a.566.566,0,1,1,0-1.132h.962a1.568,1.568,0,1,1,0,3.136Z"
              transform="translate(-23.64 -330.647)"
            />
            <path
              id="Line_37"
              data-name="Line 37"
              d="M-.309,4.787a.566.566,0,0,1-.566-.566V-.309A.566.566,0,0,1-.309-.875a.566.566,0,0,1,.566.566v4.53A.566.566,0,0,1-.309,4.787Z"
              transform="translate(30.249 -291.975)"
            />
            <path
              id="Line_38"
              data-name="Line 38"
              d="M-.309,4.787a.566.566,0,0,1-.566-.566V-.309A.566.566,0,0,1-.309-.875a.566.566,0,0,1,.566.566v4.53A.566.566,0,0,1-.309,4.787Z"
              transform="translate(28.307 -291.975)"
            />
            <path
              id="Path_65"
              data-name="Path 65"
              d="M48.822,53.468a1.071,1.071,0,1,1-.837.407l.05-.063.275-.2a.567.567,0,0,1,.207-.1A1.093,1.093,0,0,1,48.822,53.468Z"
              transform="translate(-22.492 -339.801)"
            />
            <path
              id="Line_39"
              data-name="Line 39"
              d="M-.309.916A.566.566,0,0,1-.848.521.566.566,0,0,1-.481-.19L1.586-.848A.566.566,0,0,1,2.3-.481.566.566,0,0,1,1.93.231L-.137.889A.566.566,0,0,1-.309.916Z"
              transform="translate(23.819 -284.785)"
            />
            <path
              id="Path_66"
              data-name="Path 66"
              d="M12.709,41.77a.566.566,0,0,1-.566-.566V35.693a.436.436,0,0,0-.436-.436H4.693a.436.436,0,0,0-.436.436V41.2a.566.566,0,0,1-1.132,0V35.693a1.57,1.57,0,0,1,1.568-1.568h7.014a1.57,1.57,0,0,1,1.568,1.568V41.2A.566.566,0,0,1,12.709,41.77Z"
              transform="translate(-0.22 -330.148)"
            />
            <path
              id="Line_40"
              data-name="Line 40"
              d="M4.868.257H-.309A.566.566,0,0,1-.875-.309.566.566,0,0,1-.309-.875H4.868a.566.566,0,0,1,.566.566A.566.566,0,0,1,4.868.257Z"
              transform="translate(5.658 -293.27)"
            />
            <path
              id="Path_67"
              data-name="Path 67"
              d="M11.711,50.261a1.57,1.57,0,0,1-1.568-1.568v-.436H4.261v.436a1.568,1.568,0,1,1-3.136,0v-1a.566.566,0,0,1,.566-.566H12.713a.566.566,0,0,1,.566.566v1A1.57,1.57,0,0,1,11.711,50.261Zm-.436-2v.436a.436.436,0,1,0,.871,0v-.436Zm-9.018,0v.436a.436.436,0,1,0,.871,0v-.436Z"
              transform="translate(0.778 -336.635)"
            />
            <path
              id="Rectangle_16"
              data-name="Rectangle 16"
              d="M-.309-.875H10.045a.566.566,0,0,1,.566.566V1.633a.566.566,0,0,1-.566.566H-.309a.566.566,0,0,1-.566-.566V-.309A.566.566,0,0,1-.309-.875ZM9.479.257H.257v.809H9.479Z"
              transform="translate(3.07 -301.035)"
            />
            <path
              id="Line_41"
              data-name="Line 41"
              d="M-.309,2.846A.566.566,0,0,1-.875,2.28V-.309A.566.566,0,0,1-.309-.875a.566.566,0,0,1,.566.566V2.28A.566.566,0,0,1-.309,2.846Z"
              transform="translate(9.541 -303.623)"
            />
            <path
              id="Line_42"
              data-name="Line 42"
              d="M-.309,2.846A.566.566,0,0,1-.875,2.28V-.309A.566.566,0,0,1-.309-.875a.566.566,0,0,1,.566.566V2.28A.566.566,0,0,1-.309,2.846Z"
              transform="translate(7.6 -303.623)"
            />
            <path
              id="Line_43"
              data-name="Line 43"
              d="M-.309,4.14a.566.566,0,0,1-.566-.566V-.309A.566.566,0,0,1-.309-.875a.566.566,0,0,1,.566.566V3.574A.566.566,0,0,1-.309,4.14Z"
              transform="translate(10.835 -299.093)"
            />
            <path
              id="Line_44"
              data-name="Line 44"
              d="M-.309,4.14a.566.566,0,0,1-.566-.566V-.309A.566.566,0,0,1-.309-.875a.566.566,0,0,1,.566.566V3.574A.566.566,0,0,1-.309,4.14Z"
              transform="translate(6.953 -299.093)"
            />
            <path
              id="Path_68"
              data-name="Path 68"
              d="M10.705,9.265a.566.566,0,0,1-.549-.429L9.578,6.527l-.352.879a.566.566,0,0,1-.526.356H8.5L7.669,9.013a.566.566,0,0,1-1.02-.177L6.071,6.527l-.352.879a.566.566,0,0,1-.526.356h-1.5a.566.566,0,1,1,0-1.132h1.12l.86-2.149a.566.566,0,0,1,1.075.073L7.437,7.32l.292-.438A.566.566,0,0,1,8.2,6.63h.118l.86-2.149a.566.566,0,0,1,1.075.073l.692,2.766.292-.438a.566.566,0,0,1,.471-.252h1a.566.566,0,1,1,0,1.132h-.7l-.834,1.251A.566.566,0,0,1,10.705,9.265Z"
              transform="translate(-0.22 -315.177)"
            />
            <path
              id="Path_69"
              data-name="Path 69"
              d="M35.728,31.766a1.567,1.567,0,0,1-1.492-1.141.566.566,0,0,1,1.05-.424c.174.432.349.432.442.432,1.215,0,1.757-.6,2.578-1.5a9.665,9.665,0,0,1,4.244-2.978.566.566,0,1,1,.383,1.066,8.574,8.574,0,0,0-3.79,2.675A4.277,4.277,0,0,1,35.728,31.766Z"
              transform="translate(-15.724 -326.156)"
            />
            <path
              id="Path_70"
              data-name="Path 70"
              d="M29.3,17.023a.566.566,0,0,1-.566-.553,19.177,19.177,0,0,0-.7-5.7,3.285,3.285,0,0,0-3.348-2.514.566.566,0,1,1,0-1.132,4.4,4.4,0,0,1,4.414,3.266,20.019,20.019,0,0,1,.763,6.053.566.566,0,0,1-.553.579Z"
              transform="translate(-10.699 -316.675)"
            />
          </g>
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'colour'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="30.691"
          height="30.398"
          viewBox="0 0 30.691 30.398"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#f7931e" />
              <stop offset="0.1" stop-color="#f78e24" />
              <stop offset="0.256" stop-color="#f78335" />
              <stop offset="0.393" stop-color="#f87052" />
              <stop offset="0.565" stop-color="#fa567a" />
              <stop offset="0.751" stop-color="#fc34ad" />
              <stop offset="1" stop-color="#fe0ceb" />
              <stop offset="1" stop-color="#f0f" />
            </linearGradient>
          </defs>
          <g
            id="VO2_-_Colour"
            data-name="VO2 - Colour"
            transform="translate(-1.903 312.263)"
          >
            <path
              id="Rectangle_15"
              data-name="Rectangle 15"
              d="M-.309-.875H11.339a.566.566,0,0,1,.566.566V7.456a.566.566,0,0,1-.566.566H-.309a.566.566,0,0,1-.566-.566V-.309A.566.566,0,0,1-.309-.875ZM10.773.257H.257V6.89H10.773Z"
              transform="translate(3.07 -311.388)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_59"
              data-name="Path 59"
              d="M46.361,6.125a2.513,2.513,0,0,1,1.769.621,1.956,1.956,0,0,1,.634,1.449,3.3,3.3,0,0,1-.811,1.989,2.7,2.7,0,0,1-2.009,1.082c-1.256,0-1.819-1.291-1.819-2.57a2.732,2.732,0,0,1,.665-1.824A2.128,2.128,0,0,1,46.361,6.125Zm-.417,4.008a1.6,1.6,0,0,0,1.137-.672,2.253,2.253,0,0,0,.551-1.267c0-.432-.333-.937-1.271-.937a1.316,1.316,0,0,0-1.1,1.438C45.257,9.1,45.381,10.133,45.944,10.133Z"
              transform="translate(-20.68 -316.176)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_60"
              data-name="Path 60"
              d="M41.2,35.295a.566.566,0,0,1-.491-.284c-.006-.011-.641-1.115-1.267-2.347-1.178-2.318-1.314-3.077-1.314-3.446,0-.4.17-1.131,1.643-2.812.76-.867,1.508-1.577,1.539-1.607a.566.566,0,0,1,.779.822h0c-.007.007-.746.709-1.472,1.538-1.319,1.506-1.357,2.007-1.357,2.059a9.19,9.19,0,0,0,1.189,2.93c.357.7.716,1.362.956,1.793a.836.836,0,0,0,.229-.232c-.025-.377-.515-2.489-.984-4.352a.566.566,0,0,1,.118-.505,15.4,15.4,0,0,1,3.675-3.14c.63-.33.742-1.46.742-4.477,0-3.8-1.664-4.917-1.991-4.976-.1.006-.719.117-2.351,1.842-.925.978-1.694,1.968-1.7,1.978a.566.566,0,0,1-.2.161c-.009,0-.868.428-1.774.923a11.443,11.443,0,0,0-1.911,1.192.421.421,0,0,0,.067.225,1.141,1.141,0,0,0,.5.062h.029a26.733,26.733,0,0,0,3.51-1.623l2.451-2.236a.566.566,0,0,1,.763.836l-2.5,2.286a.566.566,0,0,1-.119.083c-.008,0-.84.44-1.726.869a6.736,6.736,0,0,1-2.356.918h-.045a1.714,1.714,0,0,1-1.305-.393,1.6,1.6,0,0,1-.406-1.176c0-.438.343-.837,2.3-1.92.815-.452,1.618-.857,1.912-1,.227-.286.9-1.117,1.693-1.958,1.862-1.969,2.708-2.2,3.182-2.2a2.789,2.789,0,0,1,1.958,1.44,8.711,8.711,0,0,1,1.156,4.669,21.721,21.721,0,0,1-.185,3.645,2.339,2.339,0,0,1-1.163,1.835,13.631,13.631,0,0,0-3.148,2.657c.295,1.179.946,3.846.946,4.357a1.487,1.487,0,0,1-.732,1.157,3.894,3.894,0,0,1-.6.358A.564.564,0,0,1,41.2,35.295Z"
              transform="translate(-15.69 -320.667)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_61"
              data-name="Path 61"
              d="M40.195,40.641a.566.566,0,0,1-.5-.292,13.523,13.523,0,0,1-1.574-4.783c0-.389.191-.972,2.477-2.992.848-.75,1.705-1.446,2.094-1.758v-.4a.566.566,0,1,1,1.132,0v.668a.566.566,0,0,1-.216.445c-.011.008-1.063.839-2.139,1.782-1.868,1.638-2.17,2.179-2.216,2.289a12.833,12.833,0,0,0,1.431,4.2.566.566,0,0,1-.495.841Z"
              transform="translate(-17.686 -328.017)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_62"
              data-name="Path 62"
              d="M43.725,46.786H27.192a.566.566,0,0,1-.566-.547l-.5-14.528a.566.566,0,0,1,.566-.586h1.5a.566.566,0,0,1,.559.473l1.922,11.531a7.96,7.96,0,0,0,3.693-1.37.566.566,0,1,1,.679.906A8.886,8.886,0,0,1,30.2,44.281a.566.566,0,0,1-.559-.473l-1.925-11.55h-.437l.462,13.4h15.4a26.557,26.557,0,0,0-.844-4.378H39.717a.566.566,0,1,1,0-1.132h3.006a.566.566,0,0,1,.543.407,26,26,0,0,1,1.025,5.67A.566.566,0,0,1,43.725,46.786Z"
              transform="translate(-11.697 -328.651)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_63"
              data-name="Path 63"
              d="M29.69,30.99a.566.566,0,0,1-.1-1.124l1.246-.222.988-.91a.566.566,0,0,1,.767.833l-1.11,1.022a.566.566,0,0,1-.284.141l-1.409.25A.57.57,0,0,1,29.69,30.99Z"
              transform="translate(-13.195 -327.384)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_64"
              data-name="Path 64"
              d="M54.163,38.261H50.621a.566.566,0,1,1,0-1.132h3.542a.436.436,0,1,0,0-.871H53.2a.566.566,0,1,1,0-1.132h.962a1.568,1.568,0,1,1,0,3.136Z"
              transform="translate(-23.64 -330.647)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_37"
              data-name="Line 37"
              d="M-.309,4.787a.566.566,0,0,1-.566-.566V-.309A.566.566,0,0,1-.309-.875a.566.566,0,0,1,.566.566v4.53A.566.566,0,0,1-.309,4.787Z"
              transform="translate(30.249 -291.975)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_38"
              data-name="Line 38"
              d="M-.309,4.787a.566.566,0,0,1-.566-.566V-.309A.566.566,0,0,1-.309-.875a.566.566,0,0,1,.566.566v4.53A.566.566,0,0,1-.309,4.787Z"
              transform="translate(28.307 -291.975)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_65"
              data-name="Path 65"
              d="M48.822,53.468a1.071,1.071,0,1,1-.837.407l.05-.063.275-.2a.567.567,0,0,1,.207-.1A1.093,1.093,0,0,1,48.822,53.468Z"
              transform="translate(-22.492 -339.801)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_39"
              data-name="Line 39"
              d="M-.309.916A.566.566,0,0,1-.848.521.566.566,0,0,1-.481-.19L1.586-.848A.566.566,0,0,1,2.3-.481.566.566,0,0,1,1.93.231L-.137.889A.566.566,0,0,1-.309.916Z"
              transform="translate(23.819 -284.785)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_66"
              data-name="Path 66"
              d="M12.709,41.77a.566.566,0,0,1-.566-.566V35.693a.436.436,0,0,0-.436-.436H4.693a.436.436,0,0,0-.436.436V41.2a.566.566,0,0,1-1.132,0V35.693a1.57,1.57,0,0,1,1.568-1.568h7.014a1.57,1.57,0,0,1,1.568,1.568V41.2A.566.566,0,0,1,12.709,41.77Z"
              transform="translate(-0.22 -330.148)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_40"
              data-name="Line 40"
              d="M4.868.257H-.309A.566.566,0,0,1-.875-.309.566.566,0,0,1-.309-.875H4.868a.566.566,0,0,1,.566.566A.566.566,0,0,1,4.868.257Z"
              transform="translate(5.658 -293.27)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_67"
              data-name="Path 67"
              d="M11.711,50.261a1.57,1.57,0,0,1-1.568-1.568v-.436H4.261v.436a1.568,1.568,0,1,1-3.136,0v-1a.566.566,0,0,1,.566-.566H12.713a.566.566,0,0,1,.566.566v1A1.57,1.57,0,0,1,11.711,50.261Zm-.436-2v.436a.436.436,0,1,0,.871,0v-.436Zm-9.018,0v.436a.436.436,0,1,0,.871,0v-.436Z"
              transform="translate(0.778 -336.635)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Rectangle_16"
              data-name="Rectangle 16"
              d="M-.309-.875H10.045a.566.566,0,0,1,.566.566V1.633a.566.566,0,0,1-.566.566H-.309a.566.566,0,0,1-.566-.566V-.309A.566.566,0,0,1-.309-.875ZM9.479.257H.257v.809H9.479Z"
              transform="translate(3.07 -301.035)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_41"
              data-name="Line 41"
              d="M-.309,2.846A.566.566,0,0,1-.875,2.28V-.309A.566.566,0,0,1-.309-.875a.566.566,0,0,1,.566.566V2.28A.566.566,0,0,1-.309,2.846Z"
              transform="translate(9.541 -303.623)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_42"
              data-name="Line 42"
              d="M-.309,2.846A.566.566,0,0,1-.875,2.28V-.309A.566.566,0,0,1-.309-.875a.566.566,0,0,1,.566.566V2.28A.566.566,0,0,1-.309,2.846Z"
              transform="translate(7.6 -303.623)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_43"
              data-name="Line 43"
              d="M-.309,4.14a.566.566,0,0,1-.566-.566V-.309A.566.566,0,0,1-.309-.875a.566.566,0,0,1,.566.566V3.574A.566.566,0,0,1-.309,4.14Z"
              transform="translate(10.835 -299.093)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_44"
              data-name="Line 44"
              d="M-.309,4.14a.566.566,0,0,1-.566-.566V-.309A.566.566,0,0,1-.309-.875a.566.566,0,0,1,.566.566V3.574A.566.566,0,0,1-.309,4.14Z"
              transform="translate(6.953 -299.093)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_68"
              data-name="Path 68"
              d="M10.705,9.265a.566.566,0,0,1-.549-.429L9.578,6.527l-.352.879a.566.566,0,0,1-.526.356H8.5L7.669,9.013a.566.566,0,0,1-1.02-.177L6.071,6.527l-.352.879a.566.566,0,0,1-.526.356h-1.5a.566.566,0,1,1,0-1.132h1.12l.86-2.149a.566.566,0,0,1,1.075.073L7.437,7.32l.292-.438A.566.566,0,0,1,8.2,6.63h.118l.86-2.149a.566.566,0,0,1,1.075.073l.692,2.766.292-.438a.566.566,0,0,1,.471-.252h1a.566.566,0,1,1,0,1.132h-.7l-.834,1.251A.566.566,0,0,1,10.705,9.265Z"
              transform="translate(-0.22 -315.177)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_69"
              data-name="Path 69"
              d="M35.728,31.766a1.567,1.567,0,0,1-1.492-1.141.566.566,0,0,1,1.05-.424c.174.432.349.432.442.432,1.215,0,1.757-.6,2.578-1.5a9.665,9.665,0,0,1,4.244-2.978.566.566,0,1,1,.383,1.066,8.574,8.574,0,0,0-3.79,2.675A4.277,4.277,0,0,1,35.728,31.766Z"
              transform="translate(-15.724 -326.156)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_70"
              data-name="Path 70"
              d="M29.3,17.023a.566.566,0,0,1-.566-.553,19.177,19.177,0,0,0-.7-5.7,3.285,3.285,0,0,0-3.348-2.514.566.566,0,1,1,0-1.132,4.4,4.4,0,0,1,4.414,3.266,20.019,20.019,0,0,1,.763,6.053.566.566,0,0,1-.553.579Z"
              transform="translate(-10.699 -316.675)"
              fill="url(#linear-gradient)"
            />
          </g>
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'walking-alt'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="42.559"
          height="30.903"
          viewBox="0 0 42.559 30.903"
        >
          <g
            id="Daily_Walking_-_Black"
            data-name="Daily Walking - Black"
            transform="translate(0.111 0.112)"
          >
            <path
              id="Path_134"
              data-name="Path 134"
              d="M34.942,32.335H26.428c-2.444,0-6.042-2.361-7.41-3.959l-1.249-1.135c-1.722-1.312-2.729-1.489-5.142-1.914-.3-.053-.616-.108-.956-.17-4.989-.907-7.139-2.48-9.574-5.251A6.247,6.247,0,0,1,.262,15.475a3.749,3.749,0,0,1,1.416-2.626c1.768-1.457,3.085-2.442,3.14-2.483a.992.992,0,0,1,1.187,1.591c-.013.01-1.336,1-3.065,2.423a1.764,1.764,0,0,0-.7,1.254A4.427,4.427,0,0,0,3.588,18.6c2.224,2.53,3.97,3.8,8.438,4.609.336.061.65.116.945.168,2.522.444,3.911.689,6.038,2.318q.033.025.064.053L20.4,26.952a.993.993,0,0,1,.095.1c1.06,1.273,4.205,3.3,5.932,3.3h8.514c1.641,0,2.975-.9,4.2-2.824a13.706,13.706,0,0,0,1.23-2.557.992.992,0,1,1,1.875.652,15.636,15.636,0,0,1-1.43,2.969,9.4,9.4,0,0,1-2.129,2.437A5.981,5.981,0,0,1,34.942,32.335Z"
              transform="translate(-0.361 -1.544)"
            />
            <path
              id="Path_135"
              data-name="Path 135"
              d="M13.761.25h0a10.672,10.672,0,0,1,1.281.11l.144.018a1.678,1.678,0,0,1,1.578,1.969,2.179,2.179,0,0,0,1.019,2.029,3.888,3.888,0,0,0,2.548.963,7.217,7.217,0,0,0,2.562-.605l.281-.106a4.632,4.632,0,0,1,1.6-.344,2.91,2.91,0,0,1,2.639,2.147c.8,1.9,5.872,9.464,7.589,11.774.413.1.875.206,1.357.319a51.463,51.463,0,0,1,5.78,1.552,1.9,1.9,0,0,1,1.147,1.46,5.809,5.809,0,0,1-1.558,4.5.985.985,0,0,1-.075.067c-1.908,1.533-5.305,1.533-9.239,1.533h-.69A14.156,14.156,0,0,1,20.232,22.52c-1.757-2.134-3.507-2.492-5.533-2.906a18.716,18.716,0,0,1-2.685-.693,14.824,14.824,0,0,1-4.571-3c-1.954-1.851-2.87-3.739-2.649-5.46A7.573,7.573,0,0,1,5.845,7.59,74.4,74.4,0,0,1,11,1.527,3.848,3.848,0,0,1,13.761.25ZM14.78,2.328a9.3,9.3,0,0,0-1.019-.093h0c-.367,0-.646.051-1.429.762A74.487,74.487,0,0,0,7.5,8.693a5.6,5.6,0,0,0-.733,2.02c-.135,1.051.592,2.389,2.046,3.766a13.111,13.111,0,0,0,3.881,2.575,16.931,16.931,0,0,0,2.408.615,15.1,15.1,0,0,1,3.3.948,9.224,9.224,0,0,1,3.372,2.64,11.581,11.581,0,0,0,4.454,3.36,14.1,14.1,0,0,0,5.509,1.028h.69c3.229,0,6.566,0,7.955-1.064a4.174,4.174,0,0,0,.825-1.52,2.353,2.353,0,0,0,.14-1.171,54.762,54.762,0,0,0-5.425-1.435c-.626-.146-1.217-.284-1.72-.406a.992.992,0,0,1-.55-.358C32.257,17.9,26.552,9.5,25.585,7.2c-.388-.929-.641-.929-.808-.929a2.907,2.907,0,0,0-.915.221l-.265.1a9.112,9.112,0,0,1-3.267.734,5.873,5.873,0,0,1-3.81-1.416A4.1,4.1,0,0,1,14.78,2.328Z"
              transform="translate(-0.899 -0.362)"
            />
            <path
              id="Line_72"
              data-name="Line 72"
              d="M.243,3.877A.992.992,0,0,1-.2,2L5.083-.645A.992.992,0,0,1,5.971,1.13L.686,3.772A.988.988,0,0,1,.243,3.877Z"
              transform="translate(22.658 6.656)"
            />
            <path
              id="Line_73"
              data-name="Line 73"
              d="M.243,3.877A.992.992,0,0,1-.2,2L5.083-.645A.992.992,0,0,1,5.971,1.13L.686,3.772A.988.988,0,0,1,.243,3.877Z"
              transform="translate(24.42 9.298)"
            />
            <path
              id="Line_74"
              data-name="Line 74"
              d="M.243,3.877A.992.992,0,0,1-.2,2L5.083-.645A.992.992,0,0,1,5.971,1.13L.686,3.772A.988.988,0,0,1,.243,3.877Z"
              transform="translate(26.182 11.941)"
            />
            <path
              id="Line_75"
              data-name="Line 75"
              d="M.243,3.877A.992.992,0,0,1-.2,2L5.083-.645A.992.992,0,0,1,5.971,1.13L.686,3.772A.988.988,0,0,1,.243,3.877Z"
              transform="translate(27.943 14.583)"
            />
          </g>
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'colour'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="42.559"
          height="30.903"
          viewBox="0 0 42.559 30.903"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#f7931e" />
              <stop offset="0.1" stop-color="#f78e24" />
              <stop offset="0.256" stop-color="#f78335" />
              <stop offset="0.393" stop-color="#f87052" />
              <stop offset="0.565" stop-color="#fa567a" />
              <stop offset="0.751" stop-color="#fc34ad" />
              <stop offset="1" stop-color="#fe0ceb" />
              <stop offset="1" stop-color="#f0f" />
            </linearGradient>
          </defs>
          <g
            id="Daily_Walking_-_Colour"
            data-name="Daily Walking - Colour"
            transform="translate(0.111 0.112)"
          >
            <path
              id="Path_134"
              data-name="Path 134"
              d="M34.942,32.335H26.428c-2.444,0-6.042-2.361-7.41-3.959l-1.249-1.135c-1.722-1.312-2.729-1.489-5.142-1.914-.3-.053-.616-.108-.956-.17-4.989-.907-7.139-2.48-9.574-5.251A6.247,6.247,0,0,1,.262,15.475a3.749,3.749,0,0,1,1.416-2.626c1.768-1.457,3.085-2.442,3.14-2.483a.992.992,0,0,1,1.187,1.591c-.013.01-1.336,1-3.065,2.423a1.764,1.764,0,0,0-.7,1.254A4.427,4.427,0,0,0,3.588,18.6c2.224,2.53,3.97,3.8,8.438,4.609.336.061.65.116.945.168,2.522.444,3.911.689,6.038,2.318q.033.025.064.053L20.4,26.952a.993.993,0,0,1,.095.1c1.06,1.273,4.205,3.3,5.932,3.3h8.514c1.641,0,2.975-.9,4.2-2.824a13.706,13.706,0,0,0,1.23-2.557.992.992,0,1,1,1.875.652,15.636,15.636,0,0,1-1.43,2.969,9.4,9.4,0,0,1-2.129,2.437A5.981,5.981,0,0,1,34.942,32.335Z"
              transform="translate(-0.361 -1.544)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_135"
              data-name="Path 135"
              d="M13.761.25h0a10.672,10.672,0,0,1,1.281.11l.144.018a1.678,1.678,0,0,1,1.578,1.969,2.179,2.179,0,0,0,1.019,2.029,3.888,3.888,0,0,0,2.548.963,7.217,7.217,0,0,0,2.562-.605l.281-.106a4.632,4.632,0,0,1,1.6-.344,2.91,2.91,0,0,1,2.639,2.147c.8,1.9,5.872,9.464,7.589,11.774.413.1.875.206,1.357.319a51.463,51.463,0,0,1,5.78,1.552,1.9,1.9,0,0,1,1.147,1.46,5.809,5.809,0,0,1-1.558,4.5.985.985,0,0,1-.075.067c-1.908,1.533-5.305,1.533-9.239,1.533h-.69A14.156,14.156,0,0,1,20.232,22.52c-1.757-2.134-3.507-2.492-5.533-2.906a18.716,18.716,0,0,1-2.685-.693,14.824,14.824,0,0,1-4.571-3c-1.954-1.851-2.87-3.739-2.649-5.46A7.573,7.573,0,0,1,5.845,7.59,74.4,74.4,0,0,1,11,1.527,3.848,3.848,0,0,1,13.761.25ZM14.78,2.328a9.3,9.3,0,0,0-1.019-.093h0c-.367,0-.646.051-1.429.762A74.487,74.487,0,0,0,7.5,8.693a5.6,5.6,0,0,0-.733,2.02c-.135,1.051.592,2.389,2.046,3.766a13.111,13.111,0,0,0,3.881,2.575,16.931,16.931,0,0,0,2.408.615,15.1,15.1,0,0,1,3.3.948,9.224,9.224,0,0,1,3.372,2.64,11.581,11.581,0,0,0,4.454,3.36,14.1,14.1,0,0,0,5.509,1.028h.69c3.229,0,6.566,0,7.955-1.064a4.174,4.174,0,0,0,.825-1.52,2.353,2.353,0,0,0,.14-1.171,54.762,54.762,0,0,0-5.425-1.435c-.626-.146-1.217-.284-1.72-.406a.992.992,0,0,1-.55-.358C32.257,17.9,26.552,9.5,25.585,7.2c-.388-.929-.641-.929-.808-.929a2.907,2.907,0,0,0-.915.221l-.265.1a9.112,9.112,0,0,1-3.267.734,5.873,5.873,0,0,1-3.81-1.416A4.1,4.1,0,0,1,14.78,2.328Z"
              transform="translate(-0.899 -0.362)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_72"
              data-name="Line 72"
              d="M.243,3.877A.992.992,0,0,1-.2,2L5.083-.645A.992.992,0,0,1,5.971,1.13L.686,3.772A.988.988,0,0,1,.243,3.877Z"
              transform="translate(22.658 6.656)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_73"
              data-name="Line 73"
              d="M.243,3.877A.992.992,0,0,1-.2,2L5.083-.645A.992.992,0,0,1,5.971,1.13L.686,3.772A.988.988,0,0,1,.243,3.877Z"
              transform="translate(24.42 9.298)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_74"
              data-name="Line 74"
              d="M.243,3.877A.992.992,0,0,1-.2,2L5.083-.645A.992.992,0,0,1,5.971,1.13L.686,3.772A.988.988,0,0,1,.243,3.877Z"
              transform="translate(26.182 11.941)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_75"
              data-name="Line 75"
              d="M.243,3.877A.992.992,0,0,1-.2,2L5.083-.645A.992.992,0,0,1,5.971,1.13L.686,3.772A.988.988,0,0,1,.243,3.877Z"
              transform="translate(27.943 14.583)"
              fill="url(#linear-gradient)"
            />
          </g>
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'target'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35.681"
          height="34.197"
          viewBox="0 0 35.681 34.197"
        >
          <g
            id="Target_-_Black"
            data-name="Target - Black"
            transform="translate(0.085 0.28)"
          >
            <path
              id="Path_71"
              data-name="Path 71"
              d="M39.317,16.635a.832.832,0,0,1-.455-.135l-2.646-1.727a.832.832,0,0,1-.341-.938l.917-3.024a.832.832,0,0,1,.512-.54l5.053-1.839a.832.832,0,0,1,1.079,1.027l-.748,2.428,2.133,1.38a.832.832,0,0,1-.168,1.48L39.6,16.585A.832.832,0,0,1,39.317,16.635Zm-1.669-2.914,1.775,1.158,3.089-1.124-1.258-.814A.832.832,0,0,1,40.912,12l.441-1.43-3.089,1.124Z"
              transform="translate(-9.606 -2.652)"
            />
            <path
              id="Path_72"
              data-name="Path 72"
              d="M19.015,26.153c-1.867,0-3.834-1.713-4.79-4.338-1.13-3.105-.354-6.142,1.765-6.913a3.7,3.7,0,0,1,3.713.987.832.832,0,1,1-1.1,1.245,2.128,2.128,0,0,0-2.04-.669,2.054,2.054,0,0,0-1.085,1.559,6.04,6.04,0,0,0,.314,3.222,6.039,6.039,0,0,0,1.83,2.67,2.054,2.054,0,0,0,1.833.5,2,2,0,0,0,1.06-1.454.832.832,0,0,1,1.615.4,3.5,3.5,0,0,1-2.106,2.619A2.927,2.927,0,0,1,19.015,26.153Z"
              transform="translate(-3.841 -4.306)"
            />
            <path
              id="Path_73"
              data-name="Path 73"
              d="M17.617,30.261a7.67,7.67,0,0,1-4.637-1.793A15.55,15.55,0,0,1,8.153,21.5a15.547,15.547,0,0,1-.78-8.44c.581-2.682,2.01-4.563,4.024-5.3,3.234-1.177,7.179.953,9.815,5.3a.832.832,0,0,1-1.423.863,11.508,11.508,0,0,0-3.9-4.033,4.724,4.724,0,0,0-3.921-.566C10.508,9.86,9.455,11.311,9,13.414a13.873,13.873,0,0,0,.718,7.518A13.877,13.877,0,0,0,14,27.153c1.7,1.318,3.441,1.752,4.9,1.222,2.365-.861,3.607-4.147,3.09-8.177a.832.832,0,1,1,1.65-.212,12.672,12.672,0,0,1-.6,6.173,6.185,6.185,0,0,1-3.571,3.78A5.379,5.379,0,0,1,17.617,30.261Z"
              transform="translate(-2.117 -2.409)"
            />
            <path
              id="Path_74"
              data-name="Path 74"
              d="M16.006,34.451a8.986,8.986,0,0,1-2.155-.271,12.769,12.769,0,0,1-4.723-2.391A23.47,23.47,0,0,1,1.848,21.272,23.47,23.47,0,0,1,.664,8.536,12.769,12.769,0,0,1,2.746,3.668,8.168,8.168,0,0,1,6.57.715c2.577-.938,5.576-.437,8.443,1.41A21.242,21.242,0,0,1,22.039,10.3a.832.832,0,0,1-1.481.759,19.567,19.567,0,0,0-6.446-7.537,9.994,9.994,0,0,0-3.6-1.494,6.4,6.4,0,0,0-3.371.249C4.747,3.149,3.025,5.5,2.29,8.889A21.8,21.8,0,0,0,3.411,20.7a21.8,21.8,0,0,0,6.735,9.77c2.743,2.126,5.571,2.817,7.962,1.947,2.069-.753,3.624-2.585,4.5-5.3a19.267,19.267,0,0,0,.219-9.683.832.832,0,1,1,1.627-.346,20.942,20.942,0,0,1-.263,10.539c-1.022,3.174-2.98,5.43-5.512,6.351A7.762,7.762,0,0,1,16.006,34.451Z"
              transform="translate(-0.333 -0.533)"
            />
            <path
              id="Line_45"
              data-name="Line 45"
              d="M.082,5.624A.832.832,0,0,1-.2,4.011L12.739-.7A.832.832,0,0,1,13.308.864L.366,5.574A.83.83,0,0,1,.082,5.624Z"
              transform="translate(14.042 11.342)"
            />
          </g>
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'colour'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="35.681"
          height="34.197"
          viewBox="0 0 35.681 34.197"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#f7931e" />
              <stop offset="0.1" stop-color="#f78e24" />
              <stop offset="0.256" stop-color="#f78335" />
              <stop offset="0.393" stop-color="#f87052" />
              <stop offset="0.565" stop-color="#fa567a" />
              <stop offset="0.751" stop-color="#fc34ad" />
              <stop offset="1" stop-color="#fe0ceb" />
              <stop offset="1" stop-color="#f0f" />
            </linearGradient>
          </defs>
          <g
            id="Target_-_Colour"
            data-name="Target - Colour"
            transform="translate(0.085 0.28)"
          >
            <path
              id="Path_71"
              data-name="Path 71"
              d="M39.317,16.635a.832.832,0,0,1-.455-.135l-2.646-1.727a.832.832,0,0,1-.341-.938l.917-3.024a.832.832,0,0,1,.512-.54l5.053-1.839a.832.832,0,0,1,1.079,1.027l-.748,2.428,2.133,1.38a.832.832,0,0,1-.168,1.48L39.6,16.585A.832.832,0,0,1,39.317,16.635Zm-1.669-2.914,1.775,1.158,3.089-1.124-1.258-.814A.832.832,0,0,1,40.912,12l.441-1.43-3.089,1.124Z"
              transform="translate(-9.606 -2.652)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_72"
              data-name="Path 72"
              d="M19.015,26.153c-1.867,0-3.834-1.713-4.79-4.338-1.13-3.105-.354-6.142,1.765-6.913a3.7,3.7,0,0,1,3.713.987.832.832,0,1,1-1.1,1.245,2.128,2.128,0,0,0-2.04-.669,2.054,2.054,0,0,0-1.085,1.559,6.04,6.04,0,0,0,.314,3.222,6.039,6.039,0,0,0,1.83,2.67,2.054,2.054,0,0,0,1.833.5,2,2,0,0,0,1.06-1.454.832.832,0,0,1,1.615.4,3.5,3.5,0,0,1-2.106,2.619A2.927,2.927,0,0,1,19.015,26.153Z"
              transform="translate(-3.841 -4.306)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_73"
              data-name="Path 73"
              d="M17.617,30.261a7.67,7.67,0,0,1-4.637-1.793A15.55,15.55,0,0,1,8.153,21.5a15.547,15.547,0,0,1-.78-8.44c.581-2.682,2.01-4.563,4.024-5.3,3.234-1.177,7.179.953,9.815,5.3a.832.832,0,0,1-1.423.863,11.508,11.508,0,0,0-3.9-4.033,4.724,4.724,0,0,0-3.921-.566C10.508,9.86,9.455,11.311,9,13.414a13.873,13.873,0,0,0,.718,7.518A13.877,13.877,0,0,0,14,27.153c1.7,1.318,3.441,1.752,4.9,1.222,2.365-.861,3.607-4.147,3.09-8.177a.832.832,0,1,1,1.65-.212,12.672,12.672,0,0,1-.6,6.173,6.185,6.185,0,0,1-3.571,3.78A5.379,5.379,0,0,1,17.617,30.261Z"
              transform="translate(-2.117 -2.409)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_74"
              data-name="Path 74"
              d="M16.006,34.451a8.986,8.986,0,0,1-2.155-.271,12.769,12.769,0,0,1-4.723-2.391A23.47,23.47,0,0,1,1.848,21.272,23.47,23.47,0,0,1,.664,8.536,12.769,12.769,0,0,1,2.746,3.668,8.168,8.168,0,0,1,6.57.715c2.577-.938,5.576-.437,8.443,1.41A21.242,21.242,0,0,1,22.039,10.3a.832.832,0,0,1-1.481.759,19.567,19.567,0,0,0-6.446-7.537,9.994,9.994,0,0,0-3.6-1.494,6.4,6.4,0,0,0-3.371.249C4.747,3.149,3.025,5.5,2.29,8.889A21.8,21.8,0,0,0,3.411,20.7a21.8,21.8,0,0,0,6.735,9.77c2.743,2.126,5.571,2.817,7.962,1.947,2.069-.753,3.624-2.585,4.5-5.3a19.267,19.267,0,0,0,.219-9.683.832.832,0,1,1,1.627-.346,20.942,20.942,0,0,1-.263,10.539c-1.022,3.174-2.98,5.43-5.512,6.351A7.762,7.762,0,0,1,16.006,34.451Z"
              transform="translate(-0.333 -0.533)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_45"
              data-name="Line 45"
              d="M.082,5.624A.832.832,0,0,1-.2,4.011L12.739-.7A.832.832,0,0,1,13.308.864L.366,5.574A.83.83,0,0,1,.082,5.624Z"
              transform="translate(14.042 11.342)"
              fill="url(#linear-gradient)"
            />
          </g>
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'target-alt'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          id="Structured_Activity_-_Black"
          data-name="Structured Activity - Black"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="33.849"
          height="33.817"
          viewBox="0 0 33.849 33.817"
        >
          <g id="Group_57" data-name="Group 57">
            <path
              id="Path_75"
              data-name="Path 75"
              d="M20.865,19.033a.659.659,0,0,1-.469-.2,4.393,4.393,0,0,0-.452-.4.661.661,0,1,1,.8-1.051,5.719,5.719,0,0,1,.589.519.661.661,0,0,1-.469,1.127Z"
              transform="translate(-5.864 -5.166)"
            />
            <path
              id="Path_76"
              data-name="Path 76"
              d="M18.807,27.043a3.7,3.7,0,0,1-2.241-.86,7.213,7.213,0,0,1-2.243-3.231,7.209,7.209,0,0,1-.358-3.917,3.286,3.286,0,0,1,1.975-2.569c.085-.03.156-.052.224-.07a.661.661,0,0,1,.332,1.28c-.03.008-.066.019-.109.034a2.112,2.112,0,0,0-1.131,1.6,5.879,5.879,0,0,0,.308,3.184c.782,2.149,2.483,3.587,3.713,3.139a2.134,2.134,0,0,0,1.167-1.769.661.661,0,0,1,1.3.226,3.393,3.393,0,0,1-2.018,2.786A2.688,2.688,0,0,1,18.807,27.043Z"
              transform="translate(-4.186 -4.914)"
            />
            <path
              id="Path_77"
              data-name="Path 77"
              d="M24.185,16.346a.66.66,0,0,1-.556-.3,13.644,13.644,0,0,0-.9-1.238.661.661,0,1,1,1.022-.839,14.966,14.966,0,0,1,.99,1.359.661.661,0,0,1-.554,1.021Z"
              transform="translate(-6.694 -4.154)"
            />
            <path
              id="Path_78"
              data-name="Path 78"
              d="M17.227,30.714a7.26,7.26,0,0,1-4.386-1.7A14.849,14.849,0,0,1,8.232,22.36a14.851,14.851,0,0,1-.747-8.06c.55-2.537,1.9-4.314,3.79-5a6.03,6.03,0,0,1,5.226.779.661.661,0,0,1-.689,1.128,4.779,4.779,0,0,0-4.085-.665c-1.452.529-2.5,1.964-2.95,4.041a13.521,13.521,0,0,0,.7,7.327,13.519,13.519,0,0,0,4.176,6.062c1.68,1.3,3.405,1.728,4.858,1.2,2.14-.779,3.379-3.579,3.157-7.135a.661.661,0,1,1,1.32-.082,11.268,11.268,0,0,1-.822,5.273,5.706,5.706,0,0,1-3.2,3.186A5.044,5.044,0,0,1,17.227,30.714Z"
              transform="translate(-2.288 -2.797)"
            />
            <path
              id="Path_79"
              data-name="Path 79"
              d="M27.165,14.013a.661.661,0,0,1-.576-.336,22.3,22.3,0,0,0-1.307-2.048.661.661,0,1,1,1.073-.772,23.625,23.625,0,0,1,1.384,2.169.661.661,0,0,1-.575.986Z"
              transform="translate(-7.435 -3.252)"
            />
            <path
              id="Path_80"
              data-name="Path 80"
              d="M15.412,34.5a10.823,10.823,0,0,1-6.531-2.54A22.49,22.49,0,0,1,1.907,21.883,22.488,22.488,0,0,1,.771,9.68c.819-3.779,2.81-6.421,5.6-7.439,3.093-1.126,6.753-.067,10.041,2.906a.661.661,0,0,1-.887.981c-2.912-2.633-6.084-3.6-8.7-2.644C4.475,4.341,2.783,6.641,2.064,9.96A21.158,21.158,0,0,0,3.15,21.43a21.16,21.16,0,0,0,6.541,9.485c2.685,2.081,5.46,2.755,7.813,1.9a7.294,7.294,0,0,0,4.129-4.387,16.608,16.608,0,0,0,.87-8,.661.661,0,0,1,1.314-.147,17.922,17.922,0,0,1-.958,8.642,8.572,8.572,0,0,1-4.9,5.134A7.4,7.4,0,0,1,15.412,34.5Z"
              transform="translate(-0.323 -0.732)"
            />
            <path
              id="Line_46"
              data-name="Line 46"
              d="M11.814,2.489a.666.666,0,0,1-.1-.008L-.063.69a.661.661,0,0,1,.2-1.307L11.912,1.174a.661.661,0,0,1-.1,1.315Z"
              transform="translate(14.684 18.351)"
            />
            <path
              id="Path_81"
              data-name="Path 81"
              d="M39.173,24.952a.661.661,0,0,1,.1.008l4.663.709A.661.661,0,0,1,44.3,26.8l-1.634,1.59,1.086,2.005a.661.661,0,0,1-.681.969l-4.66-.71a.661.661,0,0,1-.483-.341l-1.311-2.441a.661.661,0,0,1,.119-.784l1.977-1.942A.661.661,0,0,1,39.173,24.952Zm3.248,1.824-3.021-.46-1.387,1.362.92,1.713,3.02.46-.692-1.276a.661.661,0,0,1,.12-.789Z"
              transform="translate(-10.699 -7.375)"
            />
            <path
              id="Line_47"
              data-name="Line 47"
              d="M.036,7.907a.658.658,0,0,1-.419-.15.661.661,0,0,1-.092-.93L5.443-.383A.661.661,0,0,1,6.465.456L.547,7.665A.66.66,0,0,1,.036,7.907Z"
              transform="translate(13.199 7.02)"
            />
            <path
              id="Path_82"
              data-name="Path 82"
              d="M29.4,8.154h-.027L26.6,8.039a.661.661,0,0,1-.615-.5l-.665-2.69a.661.661,0,0,1,.13-.577L28.433.618A.661.661,0,0,1,29.587.88L30.125,3.1l2.279.085a.661.661,0,0,1,.487,1.079L29.908,7.911A.661.661,0,0,1,29.4,8.154ZM27.151,6.738l1.942.08,1.933-2.365L29.576,4.4a.661.661,0,0,1-.618-.5l-.342-1.41L26.684,4.851Z"
              transform="translate(-7.476 -0.323)"
            />
            <path
              id="Line_48"
              data-name="Line 48"
              d="M.037,6.045A.661.661,0,0,1-.247,4.787L11.038-.561A.661.661,0,0,1,11.6.634L.319,5.981A.659.659,0,0,1,.037,6.045Z"
              transform="translate(13.832 10.968)"
            />
            <path
              id="Path_83"
              data-name="Path 83"
              d="M37.766,15.5a.661.661,0,0,1-.306-.075L35,14.144a.661.661,0,0,1-.342-.716l.545-2.717a.661.661,0,0,1,.363-.467l4.255-2.032a.661.661,0,0,1,.932.73L40.3,11.175l2.025,1.048a.661.661,0,0,1-.019,1.184l-4.255,2.031A.661.661,0,0,1,37.766,15.5Zm-1.71-2.3,1.723.9,2.757-1.316-1.289-.667a.661.661,0,0,1-.344-.72L39.2,9.978,36.439,11.3Z"
              transform="translate(-10.158 -2.553)"
            />
            <path
              id="Path_84"
              data-name="Path 84"
              d="M30.617,21.681a.661.661,0,0,1-.636-.484,23.308,23.308,0,0,0-.951-2.773.661.661,0,0,1,1.211-.531,24.142,24.142,0,0,1,1.013,2.949.662.662,0,0,1-.637.839Z"
              transform="translate(-8.53 -5.236)"
            />
            <path
              id="Path_85"
              data-name="Path 85"
              d="M27.1,22.316a.661.661,0,0,1-.636-.481c-.3-1.051-.618-1.938-.621-1.947a.661.661,0,1,1,1.244-.449c.014.038.339.94.65,2.036a.662.662,0,0,1-.636.842Z"
              transform="translate(-7.619 -5.668)"
            />
            <path
              id="Path_86"
              data-name="Path 86"
              d="M23.476,23.143a.661.661,0,0,1-.644-.517,5.023,5.023,0,0,0-.352-1.056.661.661,0,0,1,1.177-.6,6.22,6.22,0,0,1,.465,1.369.662.662,0,0,1-.646.806Z"
              transform="translate(-6.645 -6.129)"
            />
          </g>
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'colour'">
        <svg
          id="Structured_Activity_-_Colour"
          data-name="Structured Activity - Colour"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="33.849"
          height="33.817"
          viewBox="0 0 33.849 33.817"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#f7931e" />
              <stop offset="0.1" stop-color="#f78e24" />
              <stop offset="0.256" stop-color="#f78335" />
              <stop offset="0.393" stop-color="#f87052" />
              <stop offset="0.565" stop-color="#fa567a" />
              <stop offset="0.751" stop-color="#fc34ad" />
              <stop offset="1" stop-color="#fe0ceb" />
              <stop offset="1" stop-color="#f0f" />
            </linearGradient>
            <clipPath id="clip-path">
              <rect
                id="Rectangle_19"
                data-name="Rectangle 19"
                width="33.849"
                height="33.817"
                stroke="#343434"
                stroke-width="1.6"
                fill="url(#linear-gradient)"
              />
            </clipPath>
          </defs>
          <g id="Group_57" data-name="Group 57">
            <path
              id="Path_75"
              data-name="Path 75"
              d="M20.865,19.033a.659.659,0,0,1-.469-.2,4.393,4.393,0,0,0-.452-.4.661.661,0,1,1,.8-1.051,5.719,5.719,0,0,1,.589.519.661.661,0,0,1-.469,1.127Z"
              transform="translate(-5.864 -5.166)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_76"
              data-name="Path 76"
              d="M18.807,27.043a3.7,3.7,0,0,1-2.241-.86,7.213,7.213,0,0,1-2.243-3.231,7.209,7.209,0,0,1-.358-3.917,3.286,3.286,0,0,1,1.975-2.569c.085-.03.156-.052.224-.07a.661.661,0,0,1,.332,1.28c-.03.008-.066.019-.109.034a2.112,2.112,0,0,0-1.131,1.6,5.879,5.879,0,0,0,.308,3.184c.782,2.149,2.483,3.587,3.713,3.139a2.134,2.134,0,0,0,1.167-1.769.661.661,0,0,1,1.3.226,3.393,3.393,0,0,1-2.018,2.786A2.688,2.688,0,0,1,18.807,27.043Z"
              transform="translate(-4.186 -4.914)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_77"
              data-name="Path 77"
              d="M24.185,16.346a.66.66,0,0,1-.556-.3,13.644,13.644,0,0,0-.9-1.238.661.661,0,1,1,1.022-.839,14.966,14.966,0,0,1,.99,1.359.661.661,0,0,1-.554,1.021Z"
              transform="translate(-6.694 -4.154)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_78"
              data-name="Path 78"
              d="M17.227,30.714a7.26,7.26,0,0,1-4.386-1.7A14.849,14.849,0,0,1,8.232,22.36a14.851,14.851,0,0,1-.747-8.06c.55-2.537,1.9-4.314,3.79-5a6.03,6.03,0,0,1,5.226.779.661.661,0,0,1-.689,1.128,4.779,4.779,0,0,0-4.085-.665c-1.452.529-2.5,1.964-2.95,4.041a13.521,13.521,0,0,0,.7,7.327,13.519,13.519,0,0,0,4.176,6.062c1.68,1.3,3.405,1.728,4.858,1.2,2.14-.779,3.379-3.579,3.157-7.135a.661.661,0,1,1,1.32-.082,11.268,11.268,0,0,1-.822,5.273,5.706,5.706,0,0,1-3.2,3.186A5.044,5.044,0,0,1,17.227,30.714Z"
              transform="translate(-2.288 -2.797)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_79"
              data-name="Path 79"
              d="M27.165,14.013a.661.661,0,0,1-.576-.336,22.3,22.3,0,0,0-1.307-2.048.661.661,0,1,1,1.073-.772,23.625,23.625,0,0,1,1.384,2.169.661.661,0,0,1-.575.986Z"
              transform="translate(-7.435 -3.252)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_80"
              data-name="Path 80"
              d="M15.412,34.5a10.823,10.823,0,0,1-6.531-2.54A22.49,22.49,0,0,1,1.907,21.883,22.488,22.488,0,0,1,.771,9.68c.819-3.779,2.81-6.421,5.6-7.439,3.093-1.126,6.753-.067,10.041,2.906a.661.661,0,0,1-.887.981c-2.912-2.633-6.084-3.6-8.7-2.644C4.475,4.341,2.783,6.641,2.064,9.96A21.158,21.158,0,0,0,3.15,21.43a21.16,21.16,0,0,0,6.541,9.485c2.685,2.081,5.46,2.755,7.813,1.9a7.294,7.294,0,0,0,4.129-4.387,16.608,16.608,0,0,0,.87-8,.661.661,0,0,1,1.314-.147,17.922,17.922,0,0,1-.958,8.642,8.572,8.572,0,0,1-4.9,5.134A7.4,7.4,0,0,1,15.412,34.5Z"
              transform="translate(-0.323 -0.732)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_46"
              data-name="Line 46"
              d="M11.814,2.489a.666.666,0,0,1-.1-.008L-.063.69a.661.661,0,0,1,.2-1.307L11.912,1.174a.661.661,0,0,1-.1,1.315Z"
              transform="translate(14.684 18.351)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_81"
              data-name="Path 81"
              d="M39.173,24.952a.661.661,0,0,1,.1.008l4.663.709A.661.661,0,0,1,44.3,26.8l-1.634,1.59,1.086,2.005a.661.661,0,0,1-.681.969l-4.66-.71a.661.661,0,0,1-.483-.341l-1.311-2.441a.661.661,0,0,1,.119-.784l1.977-1.942A.661.661,0,0,1,39.173,24.952Zm3.248,1.824-3.021-.46-1.387,1.362.92,1.713,3.02.46-.692-1.276a.661.661,0,0,1,.12-.789Z"
              transform="translate(-10.699 -7.375)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_47"
              data-name="Line 47"
              d="M.036,7.907a.658.658,0,0,1-.419-.15.661.661,0,0,1-.092-.93L5.443-.383A.661.661,0,0,1,6.465.456L.547,7.665A.66.66,0,0,1,.036,7.907Z"
              transform="translate(13.199 7.02)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_82"
              data-name="Path 82"
              d="M29.4,8.154h-.027L26.6,8.039a.661.661,0,0,1-.615-.5l-.665-2.69a.661.661,0,0,1,.13-.577L28.433.618A.661.661,0,0,1,29.587.88L30.125,3.1l2.279.085a.661.661,0,0,1,.487,1.079L29.908,7.911A.661.661,0,0,1,29.4,8.154ZM27.151,6.738l1.942.08,1.933-2.365L29.576,4.4a.661.661,0,0,1-.618-.5l-.342-1.41L26.684,4.851Z"
              transform="translate(-7.476 -0.323)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_48"
              data-name="Line 48"
              d="M.037,6.045A.661.661,0,0,1-.247,4.787L11.038-.561A.661.661,0,0,1,11.6.634L.319,5.981A.659.659,0,0,1,.037,6.045Z"
              transform="translate(13.832 10.968)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_83"
              data-name="Path 83"
              d="M37.766,15.5a.661.661,0,0,1-.306-.075L35,14.144a.661.661,0,0,1-.342-.716l.545-2.717a.661.661,0,0,1,.363-.467l4.255-2.032a.661.661,0,0,1,.932.73L40.3,11.175l2.025,1.048a.661.661,0,0,1-.019,1.184l-4.255,2.031A.661.661,0,0,1,37.766,15.5Zm-1.71-2.3,1.723.9,2.757-1.316-1.289-.667a.661.661,0,0,1-.344-.72L39.2,9.978,36.439,11.3Z"
              transform="translate(-10.158 -2.553)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_84"
              data-name="Path 84"
              d="M30.617,21.681a.661.661,0,0,1-.636-.484,23.308,23.308,0,0,0-.951-2.773.661.661,0,0,1,1.211-.531,24.142,24.142,0,0,1,1.013,2.949.662.662,0,0,1-.637.839Z"
              transform="translate(-8.53 -5.236)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_85"
              data-name="Path 85"
              d="M27.1,22.316a.661.661,0,0,1-.636-.481c-.3-1.051-.618-1.938-.621-1.947a.661.661,0,1,1,1.244-.449c.014.038.339.94.65,2.036a.662.662,0,0,1-.636.842Z"
              transform="translate(-7.619 -5.668)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_86"
              data-name="Path 86"
              d="M23.476,23.143a.661.661,0,0,1-.644-.517,5.023,5.023,0,0,0-.352-1.056.661.661,0,0,1,1.177-.6,6.22,6.22,0,0,1,.465,1.369.662.662,0,0,1-.646.806Z"
              transform="translate(-6.645 -6.129)"
              fill="url(#linear-gradient)"
            />
          </g>
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'sts'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="45.674"
          height="36.843"
          viewBox="0 0 45.674 36.843"
        >
          <g
            id="STS60_-_Black"
            data-name="STS60 - Black"
            transform="translate(0.215 0.214)"
          >
            <path
              id="Path_109"
              data-name="Path 109"
              d="M6.3,43.52l-.755-.013A5.026,5.026,0,0,1,.5,38.515v-9.34H2.2v9.34a3.328,3.328,0,0,0,3.352,3.3h.015l.761.013Z"
              transform="translate(-0.715 -14.384)"
            />
            <path
              id="Path_110"
              data-name="Path 110"
              d="M15.966,49.563a.849.849,0,0,1-.816-.616l-1.874-6.589H7.231l-.057-.008a3.125,3.125,0,0,1-2.807-2.435,7.764,7.764,0,0,1-.038-2.849l.014-.118c.049-.4.1-.859.156-1.346.417-3.663.788-6.547,1.438-7.574a4.532,4.532,0,0,1,3.118-2.076,2.829,2.829,0,0,1,2.071.319c1.067.859,1.346,2.049,2.02,4.924.118.5.248,1.058.4,1.676l6.03,1.523a.848.848,0,1,1-.415,1.645l-7.021-1.773-.121-.5c-.2-.83-.373-1.552-.522-2.188-.608-2.592-.837-3.476-1.39-3.955A2.767,2.767,0,0,0,7.37,28.937c-.493.779-.943,4.734-1.185,6.858-.056.489-.108.952-.158,1.358l-.015.121a6.4,6.4,0,0,0,0,2.22,1.439,1.439,0,0,0,1.34,1.167h7.207l2.225,7.821a.849.849,0,0,1-.816,1.081Z"
              transform="translate(-2.545 -13.235)"
            />
            <path
              id="Path_111"
              data-name="Path 111"
              d="M17.224,12.747a3.283,3.283,0,1,1-2.12.777A3.277,3.277,0,0,1,17.224,12.747Zm0,4.869a1.586,1.586,0,1,0-1.212-.561A1.586,1.586,0,0,0,17.226,17.616Z"
              transform="translate(-7.342 -6.753)"
            />
            <path
              id="Path_112"
              data-name="Path 112"
              d="M22.931,56.289a.849.849,0,0,1-.831-.68l-1.675-8.269a1.085,1.085,0,0,0-.725-.8l-5.82-1a.848.848,0,0,1-.7-.967l.554-3.55a.848.848,0,1,1,1.676.261l-.425,2.724,5.028.86.043.009a2.779,2.779,0,0,1,2.021,2.1l0,.018,1.678,8.279a.849.849,0,0,1-.832,1.017Z"
              transform="translate(-6.963 -20.341)"
            />
            <path
              id="Line_66"
              data-name="Line 66"
              d="M.947,7.256H-.75V0h1.7Z"
              transform="translate(5.526 28.524)"
            />
            <path
              id="Path_113"
              data-name="Path 113"
              d="M22.5,43.139a.851.851,0,0,1-.191-.022l-7.993-1.836-1.594-5.008a.848.848,0,0,1,1.617-.515l1.3,4.086,7.05,1.619a.848.848,0,0,1-.189,1.675Z"
              transform="translate(-6.722 -17.807)"
            />
            <path
              id="Ellipse_14"
              data-name="Ellipse 14"
              d="M2.609-.75A3.359,3.359,0,1,1-.75,2.609,3.363,3.363,0,0,1,2.609-.75Zm0,5.022A1.663,1.663,0,1,0,.947,2.609,1.665,1.665,0,0,0,2.609,4.272Z"
              transform="translate(31.218 1.223)"
            />
            <path
              id="Line_67"
              data-name="Line 67"
              d="M.1,17.187a.848.848,0,0,1-.848-.848V.1a.848.848,0,1,1,1.7,0v16.24A.848.848,0,0,1,.1,17.187Z"
              transform="translate(33.729 19.442)"
            />
            <path
              id="Path_114"
              data-name="Path 114"
              d="M59.71,37.343a.848.848,0,0,1-.848-.848v-22.6a.848.848,0,0,1,.173-.513l6.149-8.092,0-.006c1.025-1.323,1.207-2.061,1.02-2.6L59.577,10a.848.848,0,0,1-.629.279H53.727A.848.848,0,0,1,53.1,10L46.466,2.683c-.187.535-.006,1.272,1.02,2.6l0,.006,6.15,8.092a.848.848,0,0,1,.173.513v22.6a.848.848,0,1,1-1.7,0V14.176L46.143,6.315c-1.216-1.571-2.351-3.623-.33-5.577a.848.848,0,0,1,1.218.04L54.1,8.586h4.469L65.645.779a.848.848,0,0,1,1.219-.04c2.019,1.953.885,4-.33,5.575l-5.975,7.863V36.495A.848.848,0,0,1,59.71,37.343Z"
              transform="translate(-22.51 -0.714)"
            />
            <path
              id="Line_68"
              data-name="Line 68"
              d="M12.5.948H.1a.848.848,0,0,1,0-1.7H12.5a.848.848,0,0,1,0,1.7Z"
              transform="translate(14.684 17.982)"
            />
            <path
              id="Path_115"
              data-name="Path 115"
              d="M46.537,36.709a.848.848,0,0,1-.6-1.448L48.994,32.2l-3.058-3.082a.848.848,0,0,1,1.2-1.2l3.654,3.682a.848.848,0,0,1,0,1.2L47.137,36.46A.846.846,0,0,1,46.537,36.709Z"
              transform="translate(-22.995 -14.113)"
            />
          </g>
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'colour'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="45.674"
          height="36.843"
          viewBox="0 0 45.674 36.843"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#f7931e" />
              <stop offset="0.1" stop-color="#f78e24" />
              <stop offset="0.256" stop-color="#f78335" />
              <stop offset="0.393" stop-color="#f87052" />
              <stop offset="0.565" stop-color="#fa567a" />
              <stop offset="0.751" stop-color="#fc34ad" />
              <stop offset="1" stop-color="#fe0ceb" />
              <stop offset="1" stop-color="#f0f" />
            </linearGradient>
          </defs>
          <g
            id="STS60_-_Colour"
            data-name="STS60 - Colour"
            transform="translate(0.215 0.214)"
          >
            <path
              id="Path_109"
              data-name="Path 109"
              d="M6.3,43.52l-.755-.013A5.026,5.026,0,0,1,.5,38.515v-9.34H2.2v9.34a3.328,3.328,0,0,0,3.352,3.3h.015l.761.013Z"
              transform="translate(-0.715 -14.384)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_110"
              data-name="Path 110"
              d="M15.966,49.563a.849.849,0,0,1-.816-.616l-1.874-6.589H7.231l-.057-.008a3.125,3.125,0,0,1-2.807-2.435,7.764,7.764,0,0,1-.038-2.849l.014-.118c.049-.4.1-.859.156-1.346.417-3.663.788-6.547,1.438-7.574a4.532,4.532,0,0,1,3.118-2.076,2.829,2.829,0,0,1,2.071.319c1.067.859,1.346,2.049,2.02,4.924.118.5.248,1.058.4,1.676l6.03,1.523a.848.848,0,1,1-.415,1.645l-7.021-1.773-.121-.5c-.2-.83-.373-1.552-.522-2.188-.608-2.592-.837-3.476-1.39-3.955A2.767,2.767,0,0,0,7.37,28.937c-.493.779-.943,4.734-1.185,6.858-.056.489-.108.952-.158,1.358l-.015.121a6.4,6.4,0,0,0,0,2.22,1.439,1.439,0,0,0,1.34,1.167h7.207l2.225,7.821a.849.849,0,0,1-.816,1.081Z"
              transform="translate(-2.545 -13.235)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_111"
              data-name="Path 111"
              d="M17.224,12.747a3.283,3.283,0,1,1-2.12.777A3.277,3.277,0,0,1,17.224,12.747Zm0,4.869a1.586,1.586,0,1,0-1.212-.561A1.586,1.586,0,0,0,17.226,17.616Z"
              transform="translate(-7.342 -6.753)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_112"
              data-name="Path 112"
              d="M22.931,56.289a.849.849,0,0,1-.831-.68l-1.675-8.269a1.085,1.085,0,0,0-.725-.8l-5.82-1a.848.848,0,0,1-.7-.967l.554-3.55a.848.848,0,1,1,1.676.261l-.425,2.724,5.028.86.043.009a2.779,2.779,0,0,1,2.021,2.1l0,.018,1.678,8.279a.849.849,0,0,1-.832,1.017Z"
              transform="translate(-6.963 -20.341)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_66"
              data-name="Line 66"
              d="M.947,7.256H-.75V0h1.7Z"
              transform="translate(5.526 28.524)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_113"
              data-name="Path 113"
              d="M22.5,43.139a.851.851,0,0,1-.191-.022l-7.993-1.836-1.594-5.008a.848.848,0,0,1,1.617-.515l1.3,4.086,7.05,1.619a.848.848,0,0,1-.189,1.675Z"
              transform="translate(-6.722 -17.807)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Ellipse_14"
              data-name="Ellipse 14"
              d="M2.609-.75A3.359,3.359,0,1,1-.75,2.609,3.363,3.363,0,0,1,2.609-.75Zm0,5.022A1.663,1.663,0,1,0,.947,2.609,1.665,1.665,0,0,0,2.609,4.272Z"
              transform="translate(31.218 1.223)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_67"
              data-name="Line 67"
              d="M.1,17.187a.848.848,0,0,1-.848-.848V.1a.848.848,0,1,1,1.7,0v16.24A.848.848,0,0,1,.1,17.187Z"
              transform="translate(33.729 19.442)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_114"
              data-name="Path 114"
              d="M59.71,37.343a.848.848,0,0,1-.848-.848v-22.6a.848.848,0,0,1,.173-.513l6.149-8.092,0-.006c1.025-1.323,1.207-2.061,1.02-2.6L59.577,10a.848.848,0,0,1-.629.279H53.727A.848.848,0,0,1,53.1,10L46.466,2.683c-.187.535-.006,1.272,1.02,2.6l0,.006,6.15,8.092a.848.848,0,0,1,.173.513v22.6a.848.848,0,1,1-1.7,0V14.176L46.143,6.315c-1.216-1.571-2.351-3.623-.33-5.577a.848.848,0,0,1,1.218.04L54.1,8.586h4.469L65.645.779a.848.848,0,0,1,1.219-.04c2.019,1.953.885,4-.33,5.575l-5.975,7.863V36.495A.848.848,0,0,1,59.71,37.343Z"
              transform="translate(-22.51 -0.714)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Line_68"
              data-name="Line 68"
              d="M12.5.948H.1a.848.848,0,0,1,0-1.7H12.5a.848.848,0,0,1,0,1.7Z"
              transform="translate(14.684 17.982)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_115"
              data-name="Path 115"
              d="M46.537,36.709a.848.848,0,0,1-.6-1.448L48.994,32.2l-3.058-3.082a.848.848,0,0,1,1.2-1.2l3.654,3.682a.848.848,0,0,1,0,1.2L47.137,36.46A.846.846,0,0,1,46.537,36.709Z"
              transform="translate(-22.995 -14.113)"
              fill="url(#linear-gradient)"
            />
          </g>
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'sitting-alt'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="31.93"
          height="35.511"
          viewBox="0 0 31.93 35.511"
        >
          <g
            id="Daily_Sitting_-_Black"
            data-name="Daily Sitting - Black"
            transform="translate(0.134 0.134)"
          >
            <path
              id="Path_134"
              data-name="Path 134"
              d="M7.119,24.723H5.467V21.647a3.065,3.065,0,0,1,3.062-3.062h1.825v1.652H8.529a1.411,1.411,0,0,0-1.41,1.41Z"
              transform="translate(-2.853 -8.713)"
            />
            <path
              id="Path_135"
              data-name="Path 135"
              d="M9.6,44.478H2.444A1.946,1.946,0,0,1,.5,42.534V31.412A3.065,3.065,0,0,1,3.562,28.35h.746a3.065,3.065,0,0,1,3.061,3.062V36.7H9.6v1.652H6.542a.826.826,0,0,1-.826-.826V31.412A1.411,1.411,0,0,0,4.308,30H3.562a1.411,1.411,0,0,0-1.41,1.41V42.534a.3.3,0,0,0,.292.292H9.6Z"
              transform="translate(-0.634 -13.075)"
            />
            <path
              id="Path_136"
              data-name="Path 136"
              d="M9.651,58.85H6.018a.826.826,0,1,1,0-1.652H8.825V55.509a.826.826,0,1,1,1.652,0v2.515A.826.826,0,0,1,9.651,58.85Z"
              transform="translate(-2.73 -24.839)"
            />
            <path
              id="Path_137"
              data-name="Path 137"
              d="M48.555,24.723H46.9V21.647a1.411,1.411,0,0,0-1.41-1.41H43.669V18.585h1.824a3.065,3.065,0,0,1,3.062,3.062Z"
              transform="translate(-19.508 -8.713)"
            />
            <path
              id="Path_138"
              data-name="Path 138"
              d="M48.172,44.478H41.011V42.826h7.161a.3.3,0,0,0,.292-.292V31.412A1.411,1.411,0,0,0,47.054,30h-.746a1.411,1.411,0,0,0-1.409,1.41v6.111a.826.826,0,0,1-.826.826H41.011V36.7h2.236V31.412a3.065,3.065,0,0,1,3.061-3.062h.746a3.065,3.065,0,0,1,3.062,3.062V42.534A1.946,1.946,0,0,1,48.172,44.478Z"
              transform="translate(-18.32 -13.075)"
            />
            <path
              id="Path_139"
              data-name="Path 139"
              d="M48.425,58.85H44.792a.826.826,0,1,1,0-1.652H47.6V55.509a.826.826,0,1,1,1.652,0v2.515A.826.826,0,0,1,48.425,58.85Z"
              transform="translate(-20.051 -24.839)"
            />
            <path
              id="Path_140"
              data-name="Path 140"
              d="M29.16,33H15.188a.826.826,0,0,1-.819-.718l-1.083-8.217a.826.826,0,0,1,.819-.934H30.241a.826.826,0,0,1,.819.934l-1.082,8.217A.826.826,0,0,1,29.16,33ZM15.912,31.345H28.435L29.3,24.78H15.046Z"
              transform="translate(-6.343 -10.743)"
            />
            <path
              id="Path_141"
              data-name="Path 141"
              d="M17.207,21.639a.826.826,0,0,1-.826-.826c0-1.709,1.4-4.181,3.94-4.181h.614a.826.826,0,0,1,.628.29,2.991,2.991,0,0,0,2,1.141.826.826,0,1,1,0,1.652,4.42,4.42,0,0,1-2.986-1.431h-.251a2.04,2.04,0,0,0-1.679.889,2.978,2.978,0,0,0-.609,1.64A.826.826,0,0,1,17.207,21.639Z"
              transform="translate(-7.729 -7.841)"
            />
            <path
              id="Path_142"
              data-name="Path 142"
              d="M35.038,21.639a.826.826,0,0,1-.826-.826,2.977,2.977,0,0,0-.609-1.64,2.042,2.042,0,0,0-1.68-.889h-.251a4.42,4.42,0,0,1-2.987,1.431.826.826,0,1,1,0-1.652,2.989,2.989,0,0,0,2-1.141.826.826,0,0,1,.628-.29h.614c2.538,0,3.942,2.472,3.942,4.181A.826.826,0,0,1,35.038,21.639Z"
              transform="translate(-12.856 -7.841)"
            />
            <path
              id="Path_143"
              data-name="Path 143"
              d="M25.97,49.737H24.318V41.088a1.056,1.056,0,0,0-1.055-1.055h-.627a1.056,1.056,0,0,0-1.055,1.055v5.406H19.929V41.088a2.71,2.71,0,0,1,2.707-2.707h.627a2.71,2.71,0,0,1,2.707,2.707Z"
              transform="translate(-9.314 -17.556)"
            />
            <path
              id="Path_144"
              data-name="Path 144"
              d="M33.9,49.282H32.25V41.089a1.056,1.056,0,0,0-1.055-1.055h-.627a1.056,1.056,0,0,0-1.055,1.055H27.86a2.71,2.71,0,0,1,2.707-2.707h.627A2.71,2.71,0,0,1,33.9,41.089Z"
              transform="translate(-12.857 -17.557)"
            />
            <path
              id="Path_145"
              data-name="Path 145"
              d="M22.416,54.776h.236a3.065,3.065,0,0,1,3.061,3.061v1.593a.826.826,0,0,1-.826.826H20.18a.826.826,0,0,1-.826-.826V57.837A3.065,3.065,0,0,1,22.416,54.776ZM24.061,58.6v-.767a1.411,1.411,0,0,0-1.409-1.409h-.236a1.411,1.411,0,0,0-1.41,1.409V58.6Z"
              transform="translate(-9.057 -24.88)"
            />
            <path
              id="Path_146"
              data-name="Path 146"
              d="M30.922,54.776h.236a3.065,3.065,0,0,1,3.062,3.061v1.593a.826.826,0,0,1-.826.826H28.687a.826.826,0,0,1-.826-.826V57.837A3.065,3.065,0,0,1,30.922,54.776ZM32.568,58.6v-.767a1.411,1.411,0,0,0-1.41-1.409h-.236a1.411,1.411,0,0,0-1.409,1.409V58.6Z"
              transform="translate(-12.857 -24.88)"
            />
            <path
              id="Path_147"
              data-name="Path 147"
              d="M26.184,9.606a3.951,3.951,0,0,1-3.922-3.97V4.47a3.923,3.923,0,1,1,7.846,0V5.636A3.952,3.952,0,0,1,26.184,9.606Zm0-7.454a2.3,2.3,0,0,0-2.27,2.318V5.636a2.272,2.272,0,1,0,4.542,0V4.47A2.3,2.3,0,0,0,26.184,2.152Z"
              transform="translate(-10.356 -0.634)"
            />
          </g>
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'colour'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="31.93"
          height="35.511"
          viewBox="0 0 31.93 35.511"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#f7931e" />
              <stop offset="0.1" stop-color="#f78e24" />
              <stop offset="0.256" stop-color="#f78335" />
              <stop offset="0.393" stop-color="#f87052" />
              <stop offset="0.565" stop-color="#fa567a" />
              <stop offset="0.751" stop-color="#fc34ad" />
              <stop offset="1" stop-color="#fe0ceb" />
              <stop offset="1" stop-color="#f0f" />
            </linearGradient>
          </defs>
          <g
            id="Daily_Sitting_-_Colour"
            data-name="Daily Sitting - Colour"
            transform="translate(0.134 0.134)"
          >
            <path
              id="Path_134"
              data-name="Path 134"
              d="M7.119,24.723H5.467V21.647a3.065,3.065,0,0,1,3.062-3.062h1.825v1.652H8.529a1.411,1.411,0,0,0-1.41,1.41Z"
              transform="translate(-2.853 -8.713)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_135"
              data-name="Path 135"
              d="M9.6,44.478H2.444A1.946,1.946,0,0,1,.5,42.534V31.412A3.065,3.065,0,0,1,3.562,28.35h.746a3.065,3.065,0,0,1,3.061,3.062V36.7H9.6v1.652H6.542a.826.826,0,0,1-.826-.826V31.412A1.411,1.411,0,0,0,4.308,30H3.562a1.411,1.411,0,0,0-1.41,1.41V42.534a.3.3,0,0,0,.292.292H9.6Z"
              transform="translate(-0.634 -13.075)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_136"
              data-name="Path 136"
              d="M9.651,58.85H6.018a.826.826,0,1,1,0-1.652H8.825V55.509a.826.826,0,1,1,1.652,0v2.515A.826.826,0,0,1,9.651,58.85Z"
              transform="translate(-2.73 -24.839)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_137"
              data-name="Path 137"
              d="M48.555,24.723H46.9V21.647a1.411,1.411,0,0,0-1.41-1.41H43.669V18.585h1.824a3.065,3.065,0,0,1,3.062,3.062Z"
              transform="translate(-19.508 -8.713)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_138"
              data-name="Path 138"
              d="M48.172,44.478H41.011V42.826h7.161a.3.3,0,0,0,.292-.292V31.412A1.411,1.411,0,0,0,47.054,30h-.746a1.411,1.411,0,0,0-1.409,1.41v6.111a.826.826,0,0,1-.826.826H41.011V36.7h2.236V31.412a3.065,3.065,0,0,1,3.061-3.062h.746a3.065,3.065,0,0,1,3.062,3.062V42.534A1.946,1.946,0,0,1,48.172,44.478Z"
              transform="translate(-18.32 -13.075)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_139"
              data-name="Path 139"
              d="M48.425,58.85H44.792a.826.826,0,1,1,0-1.652H47.6V55.509a.826.826,0,1,1,1.652,0v2.515A.826.826,0,0,1,48.425,58.85Z"
              transform="translate(-20.051 -24.839)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_140"
              data-name="Path 140"
              d="M29.16,33H15.188a.826.826,0,0,1-.819-.718l-1.083-8.217a.826.826,0,0,1,.819-.934H30.241a.826.826,0,0,1,.819.934l-1.082,8.217A.826.826,0,0,1,29.16,33ZM15.912,31.345H28.435L29.3,24.78H15.046Z"
              transform="translate(-6.343 -10.743)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_141"
              data-name="Path 141"
              d="M17.207,21.639a.826.826,0,0,1-.826-.826c0-1.709,1.4-4.181,3.94-4.181h.614a.826.826,0,0,1,.628.29,2.991,2.991,0,0,0,2,1.141.826.826,0,1,1,0,1.652,4.42,4.42,0,0,1-2.986-1.431h-.251a2.04,2.04,0,0,0-1.679.889,2.978,2.978,0,0,0-.609,1.64A.826.826,0,0,1,17.207,21.639Z"
              transform="translate(-7.729 -7.841)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_142"
              data-name="Path 142"
              d="M35.038,21.639a.826.826,0,0,1-.826-.826,2.977,2.977,0,0,0-.609-1.64,2.042,2.042,0,0,0-1.68-.889h-.251a4.42,4.42,0,0,1-2.987,1.431.826.826,0,1,1,0-1.652,2.989,2.989,0,0,0,2-1.141.826.826,0,0,1,.628-.29h.614c2.538,0,3.942,2.472,3.942,4.181A.826.826,0,0,1,35.038,21.639Z"
              transform="translate(-12.856 -7.841)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_143"
              data-name="Path 143"
              d="M25.97,49.737H24.318V41.088a1.056,1.056,0,0,0-1.055-1.055h-.627a1.056,1.056,0,0,0-1.055,1.055v5.406H19.929V41.088a2.71,2.71,0,0,1,2.707-2.707h.627a2.71,2.71,0,0,1,2.707,2.707Z"
              transform="translate(-9.314 -17.556)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_144"
              data-name="Path 144"
              d="M33.9,49.282H32.25V41.089a1.056,1.056,0,0,0-1.055-1.055h-.627a1.056,1.056,0,0,0-1.055,1.055H27.86a2.71,2.71,0,0,1,2.707-2.707h.627A2.71,2.71,0,0,1,33.9,41.089Z"
              transform="translate(-12.857 -17.557)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_145"
              data-name="Path 145"
              d="M22.416,54.776h.236a3.065,3.065,0,0,1,3.061,3.061v1.593a.826.826,0,0,1-.826.826H20.18a.826.826,0,0,1-.826-.826V57.837A3.065,3.065,0,0,1,22.416,54.776ZM24.061,58.6v-.767a1.411,1.411,0,0,0-1.409-1.409h-.236a1.411,1.411,0,0,0-1.41,1.409V58.6Z"
              transform="translate(-9.057 -24.88)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_146"
              data-name="Path 146"
              d="M30.922,54.776h.236a3.065,3.065,0,0,1,3.062,3.061v1.593a.826.826,0,0,1-.826.826H28.687a.826.826,0,0,1-.826-.826V57.837A3.065,3.065,0,0,1,30.922,54.776ZM32.568,58.6v-.767a1.411,1.411,0,0,0-1.41-1.409h-.236a1.411,1.411,0,0,0-1.409,1.409V58.6Z"
              transform="translate(-12.857 -24.88)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_147"
              data-name="Path 147"
              d="M26.184,9.606a3.951,3.951,0,0,1-3.922-3.97V4.47a3.923,3.923,0,1,1,7.846,0V5.636A3.952,3.952,0,0,1,26.184,9.606Zm0-7.454a2.3,2.3,0,0,0-2.27,2.318V5.636a2.272,2.272,0,1,0,4.542,0V4.47A2.3,2.3,0,0,0,26.184,2.152Z"
              transform="translate(-10.356 -0.634)"
              fill="url(#linear-gradient)"
            />
          </g>
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'zzz'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="38"
          height="33"
          viewBox="0 0 38 33"
        >
          <g
            id="Daily_Sleep_-_Black"
            data-name="Daily Sleep - Black"
            transform="translate(0 -0.201)"
          >
            <g
              id="Group_89"
              data-name="Group 89"
              transform="translate(0 -0.049)"
            >
              <path
                id="Path_96"
                data-name="Path 96"
                d="M14.694,22.588a3.658,3.658,0,0,1-.523-.038L3.352,20.98A3.622,3.622,0,0,1,1.4,14.751L8.676,7.944,5.314,7.457A3.622,3.622,0,1,1,6.354.289L17.173,1.858a3.622,3.622,0,0,1,1.954,6.229l-7.278,6.807,3.362.488a3.621,3.621,0,0,1,3.064,4.1A3.639,3.639,0,0,1,14.694,22.588ZM5.83,2.028A1.851,1.851,0,0,0,4.009,3.607,1.845,1.845,0,0,0,5.569,5.7l5.192.753a.889.889,0,0,1,.479,1.528l-8.629,8.07a1.845,1.845,0,0,0,1,3.173l10.819,1.57a1.876,1.876,0,0,0,.268.019,1.852,1.852,0,0,0,1.822-1.58,1.844,1.844,0,0,0-1.56-2.09l-5.192-.754a.889.889,0,0,1-.479-1.528l8.628-8.07a1.845,1.845,0,0,0-1-3.173L6.1,2.047A1.874,1.874,0,0,0,5.83,2.028Z"
                transform="translate(-0.348 -0.272)"
              />
              <path
                id="Path_97"
                data-name="Path 97"
                d="M42.37,38.755H42.3l-6.926-.172a2.62,2.62,0,0,1-1.94-4.307l3.63-4.314-1.475-.036a2.621,2.621,0,1,1,.13-5.24l6.926.172a2.621,2.621,0,0,1,1.94,4.308l-3.631,4.313,1.476.037a2.62,2.62,0,0,1-.065,5.24ZM35.658,26.461a.849.849,0,0,0-.843.823.844.844,0,0,0,.822.864l3.323.082a.889.889,0,0,1,.658,1.46L34.8,35.42a.843.843,0,0,0,.624,1.386l6.948.172a.849.849,0,0,0,.843-.823.843.843,0,0,0-.822-.864l-3.324-.083a.889.889,0,0,1-.658-1.46l4.821-5.728a.844.844,0,0,0-.624-1.387Z"
                transform="translate(-7.194 -5.407)"
              />
              <path
                id="Path_98"
                data-name="Path 98"
                d="M17.936,32.233a2.87,2.87,0,0,1-2.446-4.377l.421-.682a.889.889,0,0,1,1.513.932L17,28.789a1.093,1.093,0,0,0,1.062,1.659l7.868-.964a1.093,1.093,0,1,0-.266-2.169l-3.776.463a.889.889,0,0,1-.864-1.348l4.5-7.29a1.093,1.093,0,0,0-1.063-1.658l-4.181.513a.889.889,0,0,1-.216-1.764l4.181-.513a2.87,2.87,0,0,1,2.792,4.355L23.51,25.788l1.94-.238a2.87,2.87,0,1,1,.7,5.7l-7.868.964A2.824,2.824,0,0,1,17.936,32.233Z"
                transform="translate(-3.462 -3.517)"
              />
            </g>
          </g>
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'colour'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="38"
          height="33"
          viewBox="0 0 38 33"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#f7931e" />
              <stop offset="0.1" stop-color="#f78e24" />
              <stop offset="0.256" stop-color="#f78335" />
              <stop offset="0.393" stop-color="#f87052" />
              <stop offset="0.565" stop-color="#fa567a" />
              <stop offset="0.751" stop-color="#fc34ad" />
              <stop offset="1" stop-color="#fe0ceb" />
              <stop offset="1" stop-color="#f0f" />
            </linearGradient>
            <clipPath id="clip-path">
              <rect
                id="Rectangle_34"
                data-name="Rectangle 34"
                width="38"
                height="33"
                transform="translate(0 0.25)"
                stroke="#343434"
                stroke-width="1.5"
                fill="url(#linear-gradient)"
              />
            </clipPath>
          </defs>
          <g
            id="Daily_Sleep_-_Colour"
            data-name="Daily Sleep - Colour"
            transform="translate(0 -0.201)"
          >
            <g
              id="Group_89"
              data-name="Group 89"
              transform="translate(0 -0.049)"
            >
              <path
                id="Path_96"
                data-name="Path 96"
                d="M14.694,22.588a3.658,3.658,0,0,1-.523-.038L3.352,20.98A3.622,3.622,0,0,1,1.4,14.751L8.676,7.944,5.314,7.457A3.622,3.622,0,1,1,6.354.289L17.173,1.858a3.622,3.622,0,0,1,1.954,6.229l-7.278,6.807,3.362.488a3.621,3.621,0,0,1,3.064,4.1A3.639,3.639,0,0,1,14.694,22.588ZM5.83,2.028A1.851,1.851,0,0,0,4.009,3.607,1.845,1.845,0,0,0,5.569,5.7l5.192.753a.889.889,0,0,1,.479,1.528l-8.629,8.07a1.845,1.845,0,0,0,1,3.173l10.819,1.57a1.876,1.876,0,0,0,.268.019,1.852,1.852,0,0,0,1.822-1.58,1.844,1.844,0,0,0-1.56-2.09l-5.192-.754a.889.889,0,0,1-.479-1.528l8.628-8.07a1.845,1.845,0,0,0-1-3.173L6.1,2.047A1.874,1.874,0,0,0,5.83,2.028Z"
                transform="translate(-0.348 -0.272)"
                fill="url(#linear-gradient)"
              />
              <path
                id="Path_97"
                data-name="Path 97"
                d="M42.37,38.755H42.3l-6.926-.172a2.62,2.62,0,0,1-1.94-4.307l3.63-4.314-1.475-.036a2.621,2.621,0,1,1,.13-5.24l6.926.172a2.621,2.621,0,0,1,1.94,4.308l-3.631,4.313,1.476.037a2.62,2.62,0,0,1-.065,5.24ZM35.658,26.461a.849.849,0,0,0-.843.823.844.844,0,0,0,.822.864l3.323.082a.889.889,0,0,1,.658,1.46L34.8,35.42a.843.843,0,0,0,.624,1.386l6.948.172a.849.849,0,0,0,.843-.823.843.843,0,0,0-.822-.864l-3.324-.083a.889.889,0,0,1-.658-1.46l4.821-5.728a.844.844,0,0,0-.624-1.387Z"
                transform="translate(-7.194 -5.407)"
                fill="url(#linear-gradient)"
              />
              <path
                id="Path_98"
                data-name="Path 98"
                d="M17.936,32.233a2.87,2.87,0,0,1-2.446-4.377l.421-.682a.889.889,0,0,1,1.513.932L17,28.789a1.093,1.093,0,0,0,1.062,1.659l7.868-.964a1.093,1.093,0,1,0-.266-2.169l-3.776.463a.889.889,0,0,1-.864-1.348l4.5-7.29a1.093,1.093,0,0,0-1.063-1.658l-4.181.513a.889.889,0,0,1-.216-1.764l4.181-.513a2.87,2.87,0,0,1,2.792,4.355L23.51,25.788l1.94-.238a2.87,2.87,0,1,1,.7,5.7l-7.868.964A2.824,2.824,0,0,1,17.936,32.233Z"
                transform="translate(-3.462 -3.517)"
                fill="url(#linear-gradient)"
              />
            </g>
          </g>
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'clock-z'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          id="Morning_Evening_score_-_Black"
          data-name="Morning/Evening score - Black"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="34.479"
          height="34.479"
          viewBox="0 0 34.479 34.479"
        >
          <g id="Group_92" data-name="Group 92">
            <g id="Group_91" data-name="Group 91">
              <path
                id="Path_99"
                data-name="Path 99"
                d="M32.9,8.466a.892.892,0,0,1-.777-1.33L33.562,4.58l-1.056.153a.892.892,0,0,1-.256-1.765L35.1,2.554a.892.892,0,0,1,.9,1.321L34.566,6.431l1.077-.156A.892.892,0,0,1,35.9,8.04l-2.874.417A.893.893,0,0,1,32.9,8.466Z"
                transform="translate(-9.441 -0.766)"
              />
            </g>
          </g>
          <path
            id="Path_100"
            data-name="Path 100"
            d="M41.151,17.486A.9.9,0,0,1,41,17.473l-2.862-.493a.892.892,0,0,1-.452-1.536l2.162-1.985L38.8,13.279a.892.892,0,1,1,.3-1.758l2.84.489a.892.892,0,0,1,.452,1.536l-2.163,1.985,1.074.185a.892.892,0,0,1-.15,1.771Z"
            transform="translate(-11.146 -3.352)"
          />
          <g id="Group_94" data-name="Group 94">
            <g id="Group_93" data-name="Group 93">
              <path
                id="Path_101"
                data-name="Path 101"
                d="M45.256,6.663q-.039,0-.078,0l-3.354-.29a.892.892,0,0,1-.579-1.492l2.5-2.716-1.52-.131A.892.892,0,1,1,42.378.253l3.329.287a.892.892,0,0,1,.579,1.492l-2.5,2.716,1.545.133a.892.892,0,0,1-.076,1.78Z"
                transform="translate(-12.188 -0.104)"
              />
            </g>
          </g>
          <path
            id="Path_102"
            data-name="Path 102"
            d="M22.411,28.5H18.141a.892.892,0,0,1-.891-.892V21.349a.892.892,0,0,1,1.783,0v5.364h3.378a.892.892,0,0,1,0,1.783Z"
            transform="translate(-5.334 -5.933)"
          />
          <path
            id="Line_53"
            data-name="Line 53"
            d="M.142,2.085A.892.892,0,0,1-.75,1.193V.142a.892.892,0,0,1,1.783,0V1.193A.892.892,0,0,1,.142,2.085Z"
            transform="translate(12.666 11.538)"
          />
          <path
            id="Line_54"
            data-name="Line 54"
            d="M.142,1.777A.892.892,0,0,1-.489.255L.255-.489A.892.892,0,0,1,1.516.772l-.744.744A.889.889,0,0,1,.142,1.777Z"
            transform="translate(18.988 14.465)"
          />
          <path
            id="Line_55"
            data-name="Line 55"
            d="M1.193,1.033H.142a.892.892,0,0,1,0-1.783H1.193a.892.892,0,1,1,0,1.783Z"
            transform="translate(21.607 21.53)"
          />
          <path
            id="Line_56"
            data-name="Line 56"
            d="M.885,1.777a.889.889,0,0,1-.631-.261L-.489.772A.892.892,0,0,1,.772-.489l.744.744A.892.892,0,0,1,.885,1.777Z"
            transform="translate(18.988 27.852)"
          />
          <g id="Group_96" data-name="Group 96">
            <g id="Group_95" data-name="Group 95">
              <path
                id="Line_57"
                data-name="Line 57"
                d="M.142,2.085A.892.892,0,0,1-.75,1.193V.142a.892.892,0,0,1,1.783,0V1.193A.892.892,0,0,1,.142,2.085Z"
                transform="translate(12.666 30.471)"
              />
            </g>
          </g>
          <path
            id="Line_58"
            data-name="Line 58"
            d="M.142,1.777A.892.892,0,0,1-.489.255L.255-.489A.892.892,0,1,1,1.516.772l-.744.744A.889.889,0,0,1,.142,1.777Z"
            transform="translate(5.6 27.852)"
          />
          <g id="Group_98" data-name="Group 98">
            <g id="Group_97" data-name="Group 97">
              <path
                id="Line_59"
                data-name="Line 59"
                d="M1.193,1.033H.142a.892.892,0,0,1,0-1.783H1.193a.892.892,0,1,1,0,1.783Z"
                transform="translate(2.673 21.53)"
              />
            </g>
          </g>
          <path
            id="Line_60"
            data-name="Line 60"
            d="M.885,1.777a.889.889,0,0,1-.631-.261L-.489.772A.892.892,0,0,1,.772-.489l.744.744A.892.892,0,0,1,.885,1.777Z"
            transform="translate(5.6 14.465)"
          />
          <g id="Group_100" data-name="Group 100">
            <g id="Group_99" data-name="Group 99">
              <path
                id="Ellipse_13"
                data-name="Ellipse 13"
                d="M12.031-.75a12.781,12.781,0,0,1,9.038,21.819A12.781,12.781,0,0,1,2.994,2.994,12.7,12.7,0,0,1,12.031-.75Zm0,23.779a11,11,0,1,0-11-11A11.01,11.01,0,0,0,12.031,23.029Z"
                transform="translate(1.047 9.37)"
              />
            </g>
          </g>
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'colour'">
        <svg
          id="Morning_Evening_score_-_Colour"
          data-name="Morning/Evening score - Colour"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="34.479"
          height="34.479"
          viewBox="0 0 34.479 34.479"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#f7931e" />
              <stop offset="0.1" stop-color="#f78e24" />
              <stop offset="0.256" stop-color="#f78335" />
              <stop offset="0.393" stop-color="#f87052" />
              <stop offset="0.565" stop-color="#fa567a" />
              <stop offset="0.751" stop-color="#fc34ad" />
              <stop offset="1" stop-color="#fe0ceb" />
              <stop offset="1" stop-color="#f0f" />
            </linearGradient>
          </defs>
          <g id="Group_92" data-name="Group 92">
            <g id="Group_91" data-name="Group 91">
              <path
                id="Path_99"
                data-name="Path 99"
                d="M32.9,8.466a.892.892,0,0,1-.777-1.33L33.562,4.58l-1.056.153a.892.892,0,0,1-.256-1.765L35.1,2.554a.892.892,0,0,1,.9,1.321L34.566,6.431l1.077-.156A.892.892,0,0,1,35.9,8.04l-2.874.417A.893.893,0,0,1,32.9,8.466Z"
                transform="translate(-9.441 -0.766)"
                fill="url(#linear-gradient)"
              />
            </g>
          </g>
          <path
            id="Path_100"
            data-name="Path 100"
            d="M41.151,17.486A.9.9,0,0,1,41,17.473l-2.862-.493a.892.892,0,0,1-.452-1.536l2.162-1.985L38.8,13.279a.892.892,0,1,1,.3-1.758l2.84.489a.892.892,0,0,1,.452,1.536l-2.163,1.985,1.074.185a.892.892,0,0,1-.15,1.771Z"
            transform="translate(-11.146 -3.352)"
            fill="url(#linear-gradient)"
          />
          <g id="Group_94" data-name="Group 94">
            <g id="Group_93" data-name="Group 93">
              <path
                id="Path_101"
                data-name="Path 101"
                d="M45.256,6.663q-.039,0-.078,0l-3.354-.29a.892.892,0,0,1-.579-1.492l2.5-2.716-1.52-.131A.892.892,0,1,1,42.378.253l3.329.287a.892.892,0,0,1,.579,1.492l-2.5,2.716,1.545.133a.892.892,0,0,1-.076,1.78Z"
                transform="translate(-12.188 -0.104)"
                fill="url(#linear-gradient)"
              />
            </g>
          </g>
          <path
            id="Path_102"
            data-name="Path 102"
            d="M22.411,28.5H18.141a.892.892,0,0,1-.891-.892V21.349a.892.892,0,0,1,1.783,0v5.364h3.378a.892.892,0,0,1,0,1.783Z"
            transform="translate(-5.334 -5.933)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Line_53"
            data-name="Line 53"
            d="M.142,2.085A.892.892,0,0,1-.75,1.193V.142a.892.892,0,0,1,1.783,0V1.193A.892.892,0,0,1,.142,2.085Z"
            transform="translate(12.666 11.538)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Line_54"
            data-name="Line 54"
            d="M.142,1.777A.892.892,0,0,1-.489.255L.255-.489A.892.892,0,0,1,1.516.772l-.744.744A.889.889,0,0,1,.142,1.777Z"
            transform="translate(18.988 14.465)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Line_55"
            data-name="Line 55"
            d="M1.193,1.033H.142a.892.892,0,0,1,0-1.783H1.193a.892.892,0,1,1,0,1.783Z"
            transform="translate(21.607 21.53)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Line_56"
            data-name="Line 56"
            d="M.885,1.777a.889.889,0,0,1-.631-.261L-.489.772A.892.892,0,0,1,.772-.489l.744.744A.892.892,0,0,1,.885,1.777Z"
            transform="translate(18.988 27.852)"
            fill="url(#linear-gradient)"
          />
          <g id="Group_96" data-name="Group 96">
            <g id="Group_95" data-name="Group 95">
              <path
                id="Line_57"
                data-name="Line 57"
                d="M.142,2.085A.892.892,0,0,1-.75,1.193V.142a.892.892,0,0,1,1.783,0V1.193A.892.892,0,0,1,.142,2.085Z"
                transform="translate(12.666 30.471)"
                fill="url(#linear-gradient)"
              />
            </g>
          </g>
          <path
            id="Line_58"
            data-name="Line 58"
            d="M.142,1.777A.892.892,0,0,1-.489.255L.255-.489A.892.892,0,1,1,1.516.772l-.744.744A.889.889,0,0,1,.142,1.777Z"
            transform="translate(5.6 27.852)"
            fill="url(#linear-gradient)"
          />
          <g id="Group_98" data-name="Group 98">
            <g id="Group_97" data-name="Group 97">
              <path
                id="Line_59"
                data-name="Line 59"
                d="M1.193,1.033H.142a.892.892,0,0,1,0-1.783H1.193a.892.892,0,1,1,0,1.783Z"
                transform="translate(2.673 21.53)"
                fill="url(#linear-gradient)"
              />
            </g>
          </g>
          <path
            id="Line_60"
            data-name="Line 60"
            d="M.885,1.777a.889.889,0,0,1-.631-.261L-.489.772A.892.892,0,0,1,.772-.489l.744.744A.892.892,0,0,1,.885,1.777Z"
            transform="translate(5.6 14.465)"
            fill="url(#linear-gradient)"
          />
          <g id="Group_100" data-name="Group 100">
            <g id="Group_99" data-name="Group 99">
              <path
                id="Ellipse_13"
                data-name="Ellipse 13"
                d="M12.031-.75a12.781,12.781,0,0,1,9.038,21.819A12.781,12.781,0,0,1,2.994,2.994,12.7,12.7,0,0,1,12.031-.75Zm0,23.779a11,11,0,1,0-11-11A11.01,11.01,0,0,0,12.031,23.029Z"
                transform="translate(1.047 9.37)"
                fill="url(#linear-gradient)"
              />
            </g>
          </g>
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'settings'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          id="Settings_-_Thick"
          data-name="Settings - Thick"
          xmlns="http://www.w3.org/2000/svg"
          width="24.317"
          height="24.318"
          viewBox="0 0 24.317 24.318"
        >
          <path
            id="Path_48"
            data-name="Path 48"
            d="M14.312,24.818H11a.566.566,0,0,1-.558-.471l-.468-2.758a9.243,9.243,0,0,1-1.753-.728L5.96,22.486a.566.566,0,0,1-.73-.06L2.891,20.088a.566.566,0,0,1-.06-.73l1.625-2.264a9.227,9.227,0,0,1-.729-1.754L.971,14.871A.566.566,0,0,1,.5,14.313V11a.566.566,0,0,1,.471-.558l2.757-.468a9.238,9.238,0,0,1,.9-2.063.566.566,0,1,1,.973.577,8.122,8.122,0,0,0-.879,2.124.566.566,0,0,1-.453.417l-2.64.448v2.353l2.64.449a.566.566,0,0,1,.453.417,8.1,8.1,0,0,0,.889,2.137.566.566,0,0,1-.026.619L4.03,19.627l1.66,1.66,2.169-1.556a.566.566,0,0,1,.619-.027,8.115,8.115,0,0,0,2.138.888.566.566,0,0,1,.417.453l.449,2.642h2.353l.45-2.641a.566.566,0,0,1,.417-.453A8.161,8.161,0,0,0,16.84,19.7a.566.566,0,0,1,.619.027l2.168,1.556,1.66-1.66-1.556-2.167a.566.566,0,0,1-.027-.619,8.209,8.209,0,0,0,.894-2.158.566.566,0,0,1,.456-.418l2.632-.433V11.485l-2.632-.432a.566.566,0,0,1-.457-.419,8.145,8.145,0,0,0-.883-2.142.566.566,0,0,1,.025-.615l1.549-2.184L19.624,4.03,17.44,5.58a.566.566,0,0,1-.615.025,8.133,8.133,0,0,0-2.14-.883.566.566,0,0,1-.418-.456l-.433-2.634h-2.34l-.134.9a.566.566,0,0,1-1.119-.166L10.445.983A.566.566,0,0,1,11,.5h3.308a.566.566,0,0,1,.558.474l.453,2.751a9.262,9.262,0,0,1,1.756.725L19.36,2.831a.566.566,0,0,1,.727.061l2.339,2.339a.566.566,0,0,1,.061.727L20.87,8.239A9.273,9.273,0,0,1,21.594,10l2.748.451a.566.566,0,0,1,.474.558v3.308a.566.566,0,0,1-.474.558l-2.749.452a9.339,9.339,0,0,1-.733,1.772l1.625,2.263a.566.566,0,0,1-.06.73l-2.339,2.339a.566.566,0,0,1-.73.06l-2.264-1.625a9.291,9.291,0,0,1-1.754.728l-.47,2.758A.566.566,0,0,1,14.312,24.818Z"
            transform="translate(-0.5 -0.5)"
          />
          <path
            id="Path_49"
            data-name="Path 49"
            d="M7.751,10.972a.565.565,0,0,1-.46-.237L6.5,9.635a.566.566,0,0,1,.06-.729L8.9,6.568a.566.566,0,0,1,.727-.061l2.282,1.618A9.244,9.244,0,0,1,14.026,7.3.566.566,0,0,1,14.3,8.4a8.1,8.1,0,0,0-2.139.882.566.566,0,0,1-.616-.025L9.365,7.7,7.7,9.367l.508.71a.566.566,0,0,1-.459.895Z"
            transform="translate(-4.173 -4.175)"
          />
          <path
            id="Path_50"
            data-name="Path 50"
            d="M22.214,28.584a6.374,6.374,0,0,1-6.367-6.367,6.306,6.306,0,0,1,1.318-3.881.566.566,0,1,1,.9.69,5.185,5.185,0,0,0-1.084,3.191,5.233,5.233,0,1,0,2.748-4.609.566.566,0,0,1-.538-.995,6.368,6.368,0,1,1,3.027,11.971Z"
            transform="translate(-10.056 -10.058)"
          />
          <path
            id="Path_51"
            data-name="Path 51"
            d="M26.828,30.4A3.574,3.574,0,0,1,23.7,25.109a.566.566,0,1,1,.992.544,2.438,2.438,0,1,0,1.327-1.125.566.566,0,1,1-.375-1.067A3.572,3.572,0,1,1,26.828,30.4Z"
            transform="translate(-14.67 -14.671)"
          />
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'thin'">
        <svg
          id="Settings_-_Thin"
          data-name="Settings - Thin"
          xmlns="http://www.w3.org/2000/svg"
          width="23.94"
          height="23.941"
          viewBox="0 0 23.94 23.941"
        >
          <path
            id="Path_48"
            data-name="Path 48"
            d="M14.374,24.691H11.066a.377.377,0,0,1-.372-.314L10.206,21.5a9.056,9.056,0,0,1-1.933-.8L5.911,22.394a.377.377,0,0,1-.486-.04L3.086,20.016a.377.377,0,0,1-.04-.486l1.695-2.361a9.039,9.039,0,0,1-.8-1.933l-2.873-.489a.377.377,0,0,1-.314-.372V11.066a.377.377,0,0,1,.314-.372l2.873-.488a9.055,9.055,0,0,1,.917-2.133.377.377,0,0,1,.649.384,8.31,8.31,0,0,0-.9,2.173.377.377,0,0,1-.3.278l-2.8.475v2.672l2.8.476a.377.377,0,0,1,.3.278A8.287,8.287,0,0,0,5.513,17a.377.377,0,0,1-.018.413l-1.65,2.3L5.732,21.6l2.3-1.649a.377.377,0,0,1,.412-.018,8.3,8.3,0,0,0,2.187.908.377.377,0,0,1,.278.3l.475,2.8h2.671l.476-2.8a.377.377,0,0,1,.278-.3A8.35,8.35,0,0,0,17,19.928a.377.377,0,0,1,.412.018l2.3,1.649,1.886-1.886-1.649-2.3A.377.377,0,0,1,19.927,17a8.4,8.4,0,0,0,.914-2.208.377.377,0,0,1,.3-.279l2.79-.459V11.387l-2.79-.458a.377.377,0,0,1-.3-.279,8.333,8.333,0,0,0-.9-2.191.377.377,0,0,1,.017-.41L21.6,5.735,19.707,3.846,17.392,5.487a.377.377,0,0,1-.41.017,8.322,8.322,0,0,0-2.19-.9.377.377,0,0,1-.279-.3L14.054,1.5H11.391l-.158,1.065a.377.377,0,1,1-.746-.11l.205-1.387A.377.377,0,0,1,11.066.75h3.308a.377.377,0,0,1,.372.316l.472,2.868a9.074,9.074,0,0,1,1.935.8L19.53,3.046a.377.377,0,0,1,.485.041l2.339,2.339a.377.377,0,0,1,.041.485L20.709,8.288a9.084,9.084,0,0,1,.8,1.936l2.866.47a.377.377,0,0,1,.316.372v3.308a.377.377,0,0,1-.316.372l-2.866.471A9.151,9.151,0,0,1,20.7,17.17l1.694,2.36a.377.377,0,0,1-.04.486l-2.339,2.339a.377.377,0,0,1-.486.04L17.168,20.7a9.1,9.1,0,0,1-1.933.8l-.489,2.874A.377.377,0,0,1,14.374,24.691Z"
            transform="translate(-0.75 -0.75)"
          />
          <path
            id="Path_49"
            data-name="Path 49"
            d="M7.812,10.845a.377.377,0,0,1-.307-.158l-.787-1.1a.377.377,0,0,1,.04-.486L9.1,6.762a.377.377,0,0,1,.485-.041L11.96,8.408a9.05,9.05,0,0,1,2.174-.862.377.377,0,0,1,.186.731,8.285,8.285,0,0,0-2.189.9.377.377,0,0,1-.41-.017L9.406,7.521,7.518,9.408l.6.84a.377.377,0,0,1-.306.6Z"
            transform="translate(-4.423 -4.425)"
          />
          <path
            id="Path_50"
            data-name="Path 50"
            d="M22.276,28.457A6.186,6.186,0,0,1,16.1,22.279a6.119,6.119,0,0,1,1.279-3.766.377.377,0,0,1,.6.46,5.371,5.371,0,0,0-1.123,3.306A5.421,5.421,0,1,0,19.7,17.5a.377.377,0,1,1-.359-.663,6.179,6.179,0,1,1,2.937,11.616Z"
            transform="translate(-10.306 -10.308)"
          />
          <path
            id="Path_51"
            data-name="Path 51"
            d="M26.89,30.275a3.386,3.386,0,0,1-2.965-5.014.377.377,0,1,1,.661.363,2.627,2.627,0,1,0,1.429-1.213.377.377,0,1,1-.25-.711,3.384,3.384,0,1,1,1.125,6.576Z"
            transform="translate(-14.92 -14.921)"
          />
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'colour'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="24.317"
          height="24.318"
          viewBox="0 0 24.317 24.318"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#f7931e" />
              <stop offset="0.1" stop-color="#f78e24" />
              <stop offset="0.256" stop-color="#f78335" />
              <stop offset="0.393" stop-color="#f87052" />
              <stop offset="0.565" stop-color="#fa567a" />
              <stop offset="0.751" stop-color="#fc34ad" />
              <stop offset="1" stop-color="#fe0ceb" />
              <stop offset="1" stop-color="#f0f" />
            </linearGradient>
          </defs>
          <path
            id="Path_48"
            data-name="Path 48"
            d="M14.312,24.818H11a.566.566,0,0,1-.558-.471l-.468-2.758a9.243,9.243,0,0,1-1.753-.728L5.96,22.486a.566.566,0,0,1-.73-.06L2.891,20.088a.566.566,0,0,1-.06-.73l1.625-2.264a9.227,9.227,0,0,1-.729-1.754L.971,14.871A.566.566,0,0,1,.5,14.313V11a.566.566,0,0,1,.471-.558l2.757-.468a9.238,9.238,0,0,1,.9-2.063.566.566,0,1,1,.973.577,8.122,8.122,0,0,0-.879,2.124.566.566,0,0,1-.453.417l-2.64.448v2.353l2.64.449a.566.566,0,0,1,.453.417,8.1,8.1,0,0,0,.889,2.137.566.566,0,0,1-.026.619L4.03,19.627l1.66,1.66,2.169-1.556a.566.566,0,0,1,.619-.027,8.115,8.115,0,0,0,2.138.888.566.566,0,0,1,.417.453l.449,2.642h2.353l.45-2.641a.566.566,0,0,1,.417-.453A8.161,8.161,0,0,0,16.84,19.7a.566.566,0,0,1,.619.027l2.168,1.556,1.66-1.66-1.556-2.167a.566.566,0,0,1-.027-.619,8.209,8.209,0,0,0,.894-2.158.566.566,0,0,1,.456-.418l2.632-.433V11.485l-2.632-.432a.566.566,0,0,1-.457-.419,8.145,8.145,0,0,0-.883-2.142.566.566,0,0,1,.025-.615l1.549-2.184L19.624,4.03,17.44,5.58a.566.566,0,0,1-.615.025,8.133,8.133,0,0,0-2.14-.883.566.566,0,0,1-.418-.456l-.433-2.634h-2.34l-.134.9a.566.566,0,0,1-1.119-.166L10.445.983A.566.566,0,0,1,11,.5h3.308a.566.566,0,0,1,.558.474l.453,2.751a9.262,9.262,0,0,1,1.756.725L19.36,2.831a.566.566,0,0,1,.727.061l2.339,2.339a.566.566,0,0,1,.061.727L20.87,8.239A9.273,9.273,0,0,1,21.594,10l2.748.451a.566.566,0,0,1,.474.558v3.308a.566.566,0,0,1-.474.558l-2.749.452a9.339,9.339,0,0,1-.733,1.772l1.625,2.263a.566.566,0,0,1-.06.73l-2.339,2.339a.566.566,0,0,1-.73.06l-2.264-1.625a9.291,9.291,0,0,1-1.754.728l-.47,2.758A.566.566,0,0,1,14.312,24.818Z"
            transform="translate(-0.5 -0.5)"
            fill="url(#linear-gradient)"
          />
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'power'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          id="Logout_-_Thick"
          data-name="Logout - Thick"
          xmlns="http://www.w3.org/2000/svg"
          width="23.41"
          height="23.409"
          viewBox="0 0 23.41 23.409"
        >
          <path
            id="Path_52"
            data-name="Path 52"
            d="M19.633,27.177a6.634,6.634,0,0,1-2.894-12.6l.478.984a5.557,5.557,0,1,0,4.817,0l.475-.985a6.629,6.629,0,0,1-2.876,12.6Z"
            transform="translate(-7.928 -8.839)"
            fill="#343434"
          />
          <path
            id="Line_31"
            data-name="Line 31"
            d="M-.2,7.3A.547.547,0,0,1-.75,6.749V-.2A.547.547,0,0,1-.2-.75.547.547,0,0,1,.344-.2V6.749A.547.547,0,0,1-.2,7.3Z"
            transform="translate(11.908 4.652)"
            fill="#343434"
          />
          <path
            id="Path_53"
            data-name="Path 53"
            d="M12.205,23.909A11.7,11.7,0,0,1,3.928,3.928,11.7,11.7,0,1,1,20.481,20.481,11.628,11.628,0,0,1,12.205,23.909Zm0-22.316A10.611,10.611,0,0,0,4.7,19.708,10.611,10.611,0,1,0,19.708,4.7,10.541,10.541,0,0,0,12.205,1.594Z"
            transform="translate(-0.5 -0.5)"
            fill="#343434"
          />
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'thin'">
        <svg
          id="Logout_-_Thin"
          data-name="Logout - Thin"
          xmlns="http://www.w3.org/2000/svg"
          width="23.045"
          height="23.045"
          viewBox="0 0 23.045 23.045"
        >
          <path
            id="Path_52"
            data-name="Path 52"
            d="M19.7,27.055A6.451,6.451,0,0,1,16.886,14.8l.318.656a5.739,5.739,0,1,0,4.976,0L22.5,14.8a6.446,6.446,0,0,1-2.8,12.256Z"
            transform="translate(-8.178 -9.083)"
            fill="#343434"
          />
          <path
            id="Line_31"
            data-name="Line 31"
            d="M-.135,7.182A.365.365,0,0,1-.5,6.817V-.135A.365.365,0,0,1-.135-.5a.365.365,0,0,1,.365.365V6.817A.365.365,0,0,1-.135,7.182Z"
            transform="translate(11.658 4.402)"
            fill="#343434"
          />
          <path
            id="Path_53"
            data-name="Path 53"
            d="M12.273,23.795A11.522,11.522,0,0,1,4.125,4.125a11.523,11.523,0,1,1,16.3,16.3A11.447,11.447,0,0,1,12.273,23.795Zm0-22.316A10.793,10.793,0,0,0,4.64,19.9,10.793,10.793,0,1,0,19.9,4.641,10.722,10.722,0,0,0,12.273,1.479Z"
            transform="translate(-0.75 -0.75)"
            fill="#343434"
          />
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'colour'">
        <svg
          id="Logout_-_Thick"
          data-name="Logout - Thick"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="23.41"
          height="23.409"
          viewBox="0 0 23.41 23.409"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#f7931e" />
              <stop offset="0.1" stop-color="#f78e24" />
              <stop offset="0.256" stop-color="#f78335" />
              <stop offset="0.393" stop-color="#f87052" />
              <stop offset="0.565" stop-color="#fa567a" />
              <stop offset="0.751" stop-color="#fc34ad" />
              <stop offset="1" stop-color="#fe0ceb" />
              <stop offset="1" stop-color="#f0f" />
            </linearGradient>
          </defs>
          <path
            id="Path_52"
            data-name="Path 52"
            d="M19.633,27.177a6.634,6.634,0,0,1-2.894-12.6l.478.984a5.557,5.557,0,1,0,4.817,0l.475-.985a6.629,6.629,0,0,1-2.876,12.6Z"
            transform="translate(-7.928 -8.839)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Line_31"
            data-name="Line 31"
            d="M-.2,7.3A.547.547,0,0,1-.75,6.749V-.2A.547.547,0,0,1-.2-.75.547.547,0,0,1,.344-.2V6.749A.547.547,0,0,1-.2,7.3Z"
            transform="translate(11.908 4.652)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Path_53"
            data-name="Path 53"
            d="M12.205,23.909A11.7,11.7,0,0,1,3.928,3.928,11.7,11.7,0,1,1,20.481,20.481,11.628,11.628,0,0,1,12.205,23.909Zm0-22.316A10.611,10.611,0,0,0,4.7,19.708,10.611,10.611,0,1,0,19.708,4.7,10.541,10.541,0,0,0,12.205,1.594Z"
            transform="translate(-0.5 -0.5)"
            fill="url(#linear-gradient)"
          />
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'menu'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          <g
            id="Menu_-_Default"
            data-name="Menu - Default"
            transform="translate(-6333 -1359)"
          >
            <circle
              id="Ellipse_23"
              data-name="Ellipse 23"
              cx="10"
              cy="10"
              r="10"
              transform="translate(6333 1359)"
              fill="#fff"
            />
            <path
              id="Ellipse_23_-_Outline"
              data-name="Ellipse 23 - Outline"
              d="M10,1.24A8.76,8.76,0,1,0,18.76,10,8.77,8.77,0,0,0,10,1.24M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z"
              transform="translate(6333 1359)"
              fill="#343434"
            />
            <g
              id="Group_283"
              data-name="Group 283"
              transform="translate(0 -0.5)"
            >
              <path
                id="Line_82"
                data-name="Line 82"
                d="M7,.625H0A.625.625,0,0,1-.625,0,.625.625,0,0,1,0-.625H7A.625.625,0,0,1,7.625,0,.625.625,0,0,1,7,.625Z"
                transform="translate(6339.5 1366.5)"
                fill="#343434"
              />
              <path
                id="Line_83"
                data-name="Line 83"
                d="M7,.625H0A.625.625,0,0,1-.625,0,.625.625,0,0,1,0-.625H7A.625.625,0,0,1,7.625,0,.625.625,0,0,1,7,.625Z"
                transform="translate(6339.5 1369.5)"
                fill="#343434"
              />
              <path
                id="Line_84"
                data-name="Line 84"
                d="M7,.625H0A.625.625,0,0,1-.625,0,.625.625,0,0,1,0-.625H7A.625.625,0,0,1,7.625,0,.625.625,0,0,1,7,.625Z"
                transform="translate(6339.5 1372.5)"
                fill="#343434"
              />
            </g>
          </g>
        </svg>
      </ng-container>
      <ng-container *ngSwitchCase="'colour'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#f7931e" />
              <stop offset="0.1" stop-color="#f78e24" />
              <stop offset="0.256" stop-color="#f78335" />
              <stop offset="0.393" stop-color="#f87052" />
              <stop offset="0.565" stop-color="#fa567a" />
              <stop offset="0.751" stop-color="#fc34ad" />
              <stop offset="1" stop-color="#fe0ceb" />
              <stop offset="1" stop-color="#f0f" />
            </linearGradient>
          </defs>
          <g
            id="Menu_-_Active"
            data-name="Menu - Active"
            transform="translate(-6333 -1359)"
          >
            <circle
              id="Ellipse_23"
              data-name="Ellipse 23"
              cx="10"
              cy="10"
              r="10"
              transform="translate(6333 1359)"
              fill="url(#linear-gradient)"
            />
            <g
              id="Group_283"
              data-name="Group 283"
              transform="translate(0 -0.5)"
            >
              <path
                id="Line_82"
                data-name="Line 82"
                d="M7,.625H0A.625.625,0,0,1-.625,0,.625.625,0,0,1,0-.625H7A.625.625,0,0,1,7.625,0,.625.625,0,0,1,7,.625Z"
                transform="translate(6339.5 1366.5)"
                fill="#fff"
              />
              <path
                id="Line_83"
                data-name="Line 83"
                d="M7,.625H0A.625.625,0,0,1-.625,0,.625.625,0,0,1,0-.625H7A.625.625,0,0,1,7.625,0,.625.625,0,0,1,7,.625Z"
                transform="translate(6339.5 1369.5)"
                fill="#fff"
              />
              <path
                id="Line_84"
                data-name="Line 84"
                d="M7,.625H0A.625.625,0,0,1-.625,0,.625.625,0,0,1,0-.625H7A.625.625,0,0,1,7.625,0,.625.625,0,0,1,7,.625Z"
                transform="translate(6339.5 1372.5)"
                fill="#fff"
              />
            </g>
          </g>
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'glucose'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 75 75"
        >
          <defs>
            <style>
              .gl-cls-1,
              .gl-cls-2,
              .gl-cls-3,
              .gl-cls-4 {
                fill: none;
                stroke: #333;
                stroke-width: 2.5px;
              }

              .gl-cls-1,
              .gl-cls-3 {
                stroke-linejoin: round;
              }

              .gl-cls-1,
              .gl-cls-4 {
                stroke-linecap: round;
              }

              .gl-cls-2,
              .gl-cls-4 {
                stroke-miterlimit: 10;
              }
            </style>
          </defs>
          <g>
            <path
              class="gl-cls-2"
              d="m47.81,51.84c2.08,0,3.78,1.73,3.78,3.84v.89c0,2.11-1.7,3.84-3.78,3.84h-6.3c-2.08,0-3.78-1.73-3.78-3.84v-.89c0-2.11,1.7-3.84,3.78-3.84h6.3Z"
            />
            <path
              class="gl-cls-2"
              d="m50.15,43.27c2.08,0,3.78,1.73,3.78,3.84v.89c0,2.11-1.7,3.84-3.78,3.84h-8.64c-2.08,0-3.78-1.73-3.78-3.84v-.89c0-2.11,1.7-3.84,3.78-3.84h8.64Z"
            />
            <path
              class="gl-cls-2"
              d="m40.75,26.14h25.79c2.08,0,3.78,1.73,3.78,3.84v.89c0,2.11-1.7,3.84-3.78,3.84h-25.56"
            />
            <path
              class="gl-cls-2"
              d="m41.52,34.71h10.79c2.08,0,3.78,1.73,3.78,3.84v.89c0,2.11-1.7,3.84-3.78,3.84h-10.79"
            />
            <path class="gl-cls-4" d="m24.51,42.16s7.82-2.7,7.82-10.88" />
            <path
              class="gl-cls-1"
              d="m4.67,53.91h6.13s1.52,2.75,5.45,4.25c4.57,1.74,13.75,2.26,24.52,2.26"
            />
            <path
              class="gl-cls-1"
              d="m32.35,27.96v8.29c0,6.29,8.01,6.3,8.01,6.3v-17.84c0-3.38-1.98-4.5-1.98-4.5-7.2-1.98-12.97.46-12.97.46-2.34.72-11.14,7.72-11.14,7.72-1.8,1.53-3.6,1.8-3.6,1.8h-6"
            />
          </g>
          <path
            class="gl-cls-1"
            d="m64.85,42.57c0,2.23-4.39,6.25-4.39,8.67s1.96,4.39,4.39,4.39,4.39-1.96,4.39-4.39-4.39-6.44-4.39-8.67Z"
          />
          <line class="gl-cls-3" x1="64.55" y1="34.74" x2="62.3" y2="29.99" />
          <g>
            <line
              class="gl-cls-1"
              x1="62.35"
              y1="18.59"
              x2="62.35"
              y2="14.59"
            />
            <g>
              <line
                class="gl-cls-1"
                x1="66.71"
                y1="20.58"
                x2="69.54"
                y2="17.75"
              />
              <line
                class="gl-cls-1"
                x1="57.99"
                y1="20.58"
                x2="55.16"
                y2="17.75"
              />
            </g>
          </g>
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'kidney'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 75 75"
        >
          <defs>
            <style>
              .k-cls-1,
              .k-cls-2 {
                fill: none;
                stroke: #394553;
                stroke-miterlimit: 10;
                stroke-width: 2.5px;
              }

              .k-cls-2 {
                stroke-linecap: round;
              }
            </style>
          </defs>
          <g>
            <path
              class="k-cls-1"
              d="m34.88,64.25v-19.48c0-2.18.25-6.73-9.03-11.44"
            />
            <path class="k-cls-2" d="m18.06,40.08s2.43-2.2,6.74-2.04" />
            <path
              class="k-cls-2"
              d="m26.64,24.95l.48-.65s6.11-7.68-2.24-12.48c-4.32-2.48-19.72-2.55-21.66,20.88-.76,9.09,3.42,24.12,17.95,24.12,6.26,0,12.01-7.35,6.19-14.85-1.08-1.39-2.47-3.71-2.47-3.71,2.3-6.36-.83-9.18-.83-9.18-3.15-2.86-7.11-2.47-7.11-2.47"
            />
            <path
              class="k-cls-2"
              d="m16.92,16.64c-.38.14-.76.31-1.14.52-1.73.95-3.18,2.5-4.33,4.6"
            />
          </g>
          <g>
            <path
              class="k-cls-1"
              d="m40.12,64.25v-19.48c0-2.18-.25-6.73,9.03-11.44"
            />
            <path class="k-cls-2" d="m56.94,40.08s-2.43-2.2-6.74-2.04" />
            <path
              class="k-cls-2"
              d="m48.36,24.95l-.48-.65s-6.11-7.68,2.24-12.48c4.32-2.48,19.72-2.55,21.66,20.88.76,9.09-3.42,24.12-17.95,24.12-6.26,0-12.01-7.35-6.19-14.85,1.08-1.39,2.47-3.71,2.47-3.71-2.3-6.36.83-9.18.83-9.18,3.15-2.86,7.11-2.47,7.11-2.47"
            />
            <path
              class="k-cls-2"
              d="m63.55,21.77c-1.14-2.1-2.59-3.65-4.33-4.6-.38-.21-.77-.38-1.14-.52"
            />
          </g>
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'heart'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          id="Layer_2"
          data-name="Layer 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 69.89 63.94"
        >
          <defs>
            <style>
              .cls-1 {
                stroke-linecap: round;
              }

              .cls-1,
              .cls-2,
              .cls-3 {
                fill: none;
                stroke: #394553;
                stroke-width: 2.5px;
              }

              .cls-1,
              .cls-3 {
                stroke-linejoin: round;
              }

              .cls-2 {
                stroke-miterlimit: 10;
              }
            </style>
          </defs>
          <g id="Layer_1-2" data-name="Layer 1">
            <g>
              <g>
                <path
                  class="cls-3"
                  d="m49.77,42.37l12.16-6.21c2.35-1.26,5.1-.75,6.24,1.36,1.13,2.12.14,4.88-2.21,6.14l-25.23,13.51-1.33.33-23.52.63s-3.03.85-6.67,4.97"
                />
                <path
                  class="cls-2"
                  d="m.85,50.75l10.67-9.82c5.22-3.52,11.4-1.57,11.4-1.57,2.99.79,8.56.97,8.56.97h9.23c2.75,0,5,1.97,5,4.37,0,2.4-2.25,4.37-5,4.37h-12.77"
                />
              </g>
              <g>
                <path
                  class="cls-1"
                  d="m24.43,7.15c-.49.17-.95.45-1.33.83-.69.69-1.07,1.64-1.06,2.66,0,1.07.43,2.07,1.19,2.83"
                />
                <path
                  class="cls-1"
                  d="m50.62,17.49c3.75-3.75,3.82-9.84.14-13.51-3.68-3.68-9.76-3.62-13.52.13l-2.33,2.31-2.31-2.31c-3.75-3.75-9.84-3.82-13.52-.14-3.68,3.68-3.62,9.76.13,13.51l15.69,15.68,15.71-15.67Z"
                />
              </g>
            </g>
          </g>
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'liver'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 75 75"
        >
          <defs>
            <style>
              .l-cls-1 {
                fill: none;
                stroke: #394553;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 2.5px;
              }
            </style>
          </defs>
          <path class="l-cls-1" d="m17.46,23.13s-7.74,1.08-6.3,8.64" />
          <path class="l-cls-1" d="m23.22,48.33s6.3-1.98,11.88-6.48" />
          <path
            class="l-cls-1"
            d="m6.53,40.51c-2.87-9.44-5.31-19.01,6.79-22.81,9.51-2.99,20.49-2.7,27.01-1.4,2.64.53,4.54,2.84,4.54,5.53v16.43c0,1.44-.55,2.84-1.54,3.88-5.43,5.7-13.65,7.72-19.56,12.1-3.73,2.76-5.43,7.44-10.87,6.42-2.1-.39-3.84-2.22-3.45-7.27.17-2.19-.37-4.41-1.33-7.62"
          />
          <path
            class="l-cls-1"
            d="m61.76,14.21c-3.64.05-6.89.91-11.59,3.19-.92.45-1.51,1.39-1.51,2.41v15.06c0,1.96,2.05,3.27,3.82,2.42,1.9-.91,4.24-2.25,6.35-4.06,4.55-3.9,4.27-6.3,6.58-9.3,1.07-1.39,2.08-2.05,2.93-2.32,3.23-1.04,3.28-5.5.21-6.67-.53-.2-1.15-.37-1.84-.5"
          />
          <path
            class="l-cls-1"
            d="m42,43.41c1.89,1.36,4.36,1.77,6.78.53,2.73-1.4,4.11-4.12,4.43-7.01"
          />
          <path
            class="l-cls-1"
            d="m51.34,16.85c-.83-1.3-2.88-2.21-5.27-2.21-2.17,0-4.06.76-5.01,1.86"
          />
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'scales'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 75 75"
        >
          <defs>
            <style>
              .sc-cls-1 {
                fill: #394553;
                stroke-miterlimit: 10;
              }

              .sc-cls-1,
              .sc-cls-2,
              .sc-cls-3 {
                stroke: #394553;
                stroke-width: 2.5px;
              }

              .sc-cls-2 {
                stroke-linecap: round;
              }

              .sc-cls-2,
              .sc-cls-3 {
                fill: none;
                stroke-linejoin: round;
              }
            </style>
          </defs>
          <path
            class="sc-cls-3"
            d="m55.3,20.6h3.91c3.9,0,6.67,3.16,6.15,7.03l-4.31,32.54c-.51,3.86-4.12,7.03-8.02,7.03h-31.06c-3.9,0-7.51-3.16-8.02-7.03l-4.31-32.54c-.51-3.86,2.26-7.03,6.16-7.03h3.75"
          />
          <line class="sc-cls-2" x1="37.5" y1="66.94" x2="37.5" y2="46.13" />
          <path
            class="sc-cls-3"
            d="m50.54,20.85c0,7.2-5.84,13.04-13.04,13.04s-13.04-5.84-13.04-13.04,5.84-13.04,13.04-13.04,13.04,5.84,13.04,13.04Z"
          />
          <line class="sc-cls-2" x1="36.63" y1="21.62" x2="42.8" y2="16.15" />
          <g>
            <g>
              <g>
                <path
                  class="sc-cls-1"
                  d="m29.57,41.16c0,.43-.35.78-.78.78s-.78-.35-.78-.78.35-.78.78-.78.78.35.78.78Z"
                />
                <path
                  class="sc-cls-1"
                  d="m22.93,41.16c0,.43-.35.78-.78.78s-.78-.35-.78-.78.35-.78.78-.78.78.35.78.78Z"
                />
              </g>
              <g>
                <path
                  class="sc-cls-1"
                  d="m29.57,49.2c0,.43-.35.78-.78.78s-.78-.35-.78-.78.35-.78.78-.78.78.35.78.78Z"
                />
                <path
                  class="sc-cls-1"
                  d="m22.93,49.2c0,.43-.35.78-.78.78s-.78-.35-.78-.78.35-.78.78-.78.78.35.78.78Z"
                />
              </g>
              <g>
                <path
                  class="sc-cls-1"
                  d="m29.57,57.24c0,.43-.35.78-.78.78s-.78-.35-.78-.78.35-.78.78-.78.78.35.78.78Z"
                />
                <path
                  class="sc-cls-1"
                  d="m22.93,57.24c0,.43-.35.78-.78.78s-.78-.35-.78-.78.35-.78.78-.78.78.35.78.78Z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  class="sc-cls-1"
                  d="m53.63,41.16c0,.43-.35.78-.78.78s-.78-.35-.78-.78.35-.78.78-.78.78.35.78.78Z"
                />
                <path
                  class="sc-cls-1"
                  d="m46.99,41.16c0,.43-.35.78-.78.78s-.78-.35-.78-.78.35-.78.78-.78.78.35.78.78Z"
                />
              </g>
              <g>
                <path
                  class="sc-cls-1"
                  d="m53.63,49.2c0,.43-.35.78-.78.78s-.78-.35-.78-.78.35-.78.78-.78.78.35.78.78Z"
                />
                <path
                  class="sc-cls-1"
                  d="m46.99,49.2c0,.43-.35.78-.78.78s-.78-.35-.78-.78.35-.78.78-.78.78.35.78.78Z"
                />
              </g>
              <g>
                <path
                  class="sc-cls-1"
                  d="m53.63,57.24c0,.43-.35.78-.78.78s-.78-.35-.78-.78.35-.78.78-.78.78.35.78.78Z"
                />
                <path
                  class="sc-cls-1"
                  d="m46.99,57.24c0,.43-.35.78-.78.78s-.78-.35-.78-.78.35-.78.78-.78.78.35.78.78Z"
                />
              </g>
            </g>
          </g>
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'wellbeing'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 75 75"
        >
          <defs>
            <style>
              .w-cls-1 {
                fill: #394553;
                stroke-miterlimit: 10;
              }

              .w-cls-1,
              .w-cls-2,
              .w-cls-3 {
                stroke: #394553;
                stroke-width: 2.5px;
              }

              .w-cls-2 {
                stroke-linecap: round;
              }

              .w-cls-2,
              .w-cls-3 {
                fill: none;
                stroke-linejoin: round;
              }
            </style>
          </defs>
          <g>
            <path
              class="w-cls-1"
              d="m42.39,19.9c0,.42.33.75.75.75s.75-.33.75-.75-.34-.75-.75-.75-.75.33-.75.75Z"
            />
            <path
              class="w-cls-1"
              d="m31.05,19.9c0,.42.33.75.75.75s.75-.33.75-.75-.34-.75-.75-.75-.75.33-.75.75Z"
            />
          </g>
          <circle
            class="w-cls-2"
            cx="37.47"
            cy="24.51"
            r="17.71"
            transform="translate(-6.36 33.68) rotate(-45)"
          />
          <path
            class="w-cls-2"
            d="m45.04,28.92c-1.52,2.57-4.37,4.3-7.57,4.3s-6.05-1.73-7.57-4.3"
          />
          <g>
            <path
              class="w-cls-1"
              d="m57.58,45.87c0,.42.33.75.75.75s.75-.33.75-.75-.34-.75-.75-.75-.75.33-.75.75Z"
            />
            <path
              class="w-cls-1"
              d="m46.24,45.87c0,.42.33.75.75.75s.75-.33.75-.75-.34-.75-.75-.75-.75.33-.75.75Z"
            />
          </g>
          <path
            class="w-cls-3"
            d="m40.68,63.49c3.1,2.85,7.21,4.61,11.75,4.67,9.78.13,17.81-7.7,17.94-17.48.11-8.46-5.74-15.62-13.65-17.47-1.01-.24-2.06-.39-3.13-.44"
          />
          <path
            class="w-cls-2"
            d="m45.09,58.74c1.52-2.57,4.37-4.3,7.57-4.3s6.05,1.73,7.57,4.3"
          />
          <g>
            <path
              class="w-cls-1"
              d="m27.26,45.88c0,.42.33.75.75.75s.75-.33.75-.75-.34-.75-.75-.75-.75.33-.75.75Z"
            />
            <path
              class="w-cls-1"
              d="m15.92,45.88c0,.42.33.75.75.75s.75-.33.75-.75-.34-.75-.75-.75-.75.33-.75.75Z"
            />
          </g>
          <path
            class="w-cls-3"
            d="m16.64,33.72c-6.98,2.37-12.01,8.99-12.01,16.77,0,9.78,7.93,17.71,17.71,17.71s17.71-7.93,17.71-17.71c0-2.88-.69-5.6-1.91-8"
          />
          <line class="w-cls-2" x1="29.59" y1="55.88" x2="15.09" y2="55.88" />
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'health-data'">
    <ng-container [ngSwitch]="style">
      <ng-container *ngSwitchDefault>
        <svg
          id="Layer_2"
          data-name="Layer 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 60.92 51.98"
          height="20"
        >
          <defs>
            <style>
              .cls-1,
              .cls-2 {
                fill: none;
                stroke: #394553;
                stroke-miterlimit: 10;
                stroke-width: 2.5px;
              }

              .cls-2 {
                stroke-linecap: round;
              }
            </style>
          </defs>
          <g id="Layer_1-2" data-name="Layer 1">
            <g>
              <line class="cls-2" x1="8.08" y1="44.97" x2="8.08" y2="35.12" />
              <line class="cls-2" x1="14.88" y1="44.97" x2="14.88" y2="28.74" />
              <line class="cls-2" x1="21.69" y1="44.97" x2="21.69" y2="22.48" />
              <line class="cls-2" x1="28.49" y1="44.97" x2="28.49" y2="21.55" />
              <line class="cls-2" x1="35.3" y1="44.97" x2="35.3" y2="25.43" />
              <line class="cls-2" x1="42.1" y1="44.97" x2="42.1" y2="24.97" />
              <line class="cls-2" x1="48.9" y1="44.97" x2="48.9" y2="18.83" />
              <line class="cls-2" x1="55.71" y1="44.97" x2="55.71" y2="17.76" />
              <polyline
                class="cls-1"
                points="6.08 30.66 24.16 12.48 38.41 21.35 55.85 4.54"
              />
              <polyline
                class="cls-1"
                points="55.85 13.2 55.85 4.54 47.18 4.54"
              />
              <polyline class="cls-1" points="60.92 50.73 1.25 50.73 1.25 0" />
            </g>
          </g>
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'independence'">
    <svg
      id="Layer_2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60.93 65.76"
    >
      <defs>
        <style>
          .in-cls-1,
          .in-cls-2,
          .in-cls-3,
          .in-cls-4 {
            stroke: #344452;
            stroke-width: 2.5px;
          }

          .in-cls-1,
          .in-cls-2,
          .in-cls-4 {
            fill: none;
          }

          .in-cls-1,
          .in-cls-3 {
            stroke-miterlimit: 10;
          }

          .in-cls-2,
          .in-cls-4 {
            stroke-linejoin: round;
          }

          .in-cls-3 {
            fill: #344452;
          }

          .in-cls-4 {
            stroke-linecap: round;
          }
        </style>
      </defs>
      <g id="Layer_1-2" data-name="Layer_1">
        <g>
          <g>
            <g>
              <path
                class="in-cls-2"
                d="M52.81,31.59v-12.98c-.01-2.15,1.39-3.93,3.33-3.94,1.94,0,3.53,1.74,3.54,3.9v25.19l-11.55,15.08s-1.25,1.46-.88,6.79"
              />
              <path
                class="in-cls-1"
                d="M33.43,65.76v-11.7c.01-4.55,5.32-7.63,5.32-7.63,2.4-1.61,5.13-4.41,5.13-4.41l4.53-4.91c1.87-2.02,4.56-2.6,5.98-1.29,1.43,1.31,1.07,4.04-.8,6.06l-6.36,6.89"
              />
            </g>
            <g>
              <path
                class="in-cls-2"
                d="M8.12,31.59v-12.98c.01-2.15-1.39-3.93-3.33-3.94-1.94,0-3.53,1.74-3.54,3.9v25.19s11.55,15.08,11.55,15.08c0,0,1.25,1.46.88,6.79"
              />
              <path
                class="in-cls-1"
                d="M27.5,65.76v-11.7c-.01-4.55-5.32-7.63-5.32-7.63-2.4-1.61-5.13-4.41-5.13-4.41l-4.53-4.91c-1.86-2.02-4.56-2.6-5.98-1.29-1.43,1.31-1.07,4.04.8,6.06l6.36,6.89"
              />
            </g>
          </g>
          <path
            class="in-cls-4"
            d="M20,4.94c1.69-1.36,3.67-2.42,5.89-3.05,8.85-2.53,18.08,2.6,20.61,11.45,2.53,8.86-2.6,18.08-11.45,20.61-8.85,2.53-18.08-2.6-20.61-11.45-1.23-4.32-.64-8.72,1.31-12.4"
          />
          <g>
            <g>
              <path
                class="in-cls-3"
                d="M35.39,13.32c0,.42.33.75.75.75s.75-.33.75-.75-.34-.75-.75-.75-.75.34-.75.75Z"
              />
              <path
                class="in-cls-3"
                d="M24.05,13.32c0,.42.33.75.75.75s.75-.33.75-.75-.34-.75-.75-.75-.75.34-.75.75Z"
              />
            </g>
            <path
              class="in-cls-4"
              d="M38.04,22.34c-1.52,2.57-4.37,4.3-7.57,4.3s-6.05-1.73-7.57-4.3"
            />
          </g>
        </g>
      </g>
    </svg>
  </ng-container>
</ng-container>
