import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'filterObjectArray',
})
export class FilterObjectArray implements PipeTransform {
  transform(array: any, query: {}, single?: boolean, notEqual?: boolean): any {
    if (!array) {
      return;
    }
    let filtered;
    if (notEqual === true) {
      filtered = array.filter(this.searchNot, query);
    } else {
      filtered = array.filter(this.search, query);
    }
    if (single === true && filtered[0]) {
      return filtered[0];
    } else {
      return filtered;
    }
  }
  search(obj) {
    return Object.keys(this).every((key) => obj[key] === this[key]);
  }

  searchNot(obj) {
    return Object.keys(this).every((key) => obj[key] !== this[key]);
  }
}
