import { AwardService } from './../services/award.service';
import { forkJoin } from 'rxjs';
import { ProgrammeService } from './../services/programme.service';
import { UserService } from './../services/user.service';
import { AuthService } from './../auth/auth.service';
import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  isFetching;
  currentUser;
  currentProgramme;
  environment = environment;
  gold = 0;
  silver = 0;
  bronze = 0;
  awards;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private awardService: AwardService
  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });
    }

    this.isFetching = true;

    this.programmeService.currentProgramme.subscribe((programmeData) => {
      this.currentProgramme = programmeData;
      if (this.currentProgramme) {
        this.awardService.fetchAll().subscribe(
          (responseData) => {
            this.awards = responseData;
            this.awards.forEach((award) => {
              if (award.award_rank == '1') {
                this.gold++;
              }
              if (award.award_rank == '2') {
                this.silver++;
              }
              if (award.award_rank == '3') {
                this.bronze++;
              }
            });
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }
}
