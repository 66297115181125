<nav class="subnav">
    <h2>REGISTER AS A BUDDY</h2>
  </nav>
  <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
  <form
    class="bs-form bs-form"
    name="regForm"
    method="post"
    #regForm="ngForm"
  >
  <!-- step 1 -->
  <div [hidden]="step !== 1">
    <div
      class="reading-material-page-header booster-session-step-header register-header text-left"
    >
      1 | Code
    </div>
    <div class="container">
      <div class="form-group">
        <label for="location"
          >ENTER YOUR VERIFICATION CODE <span class="required">*</span></label
        >
        <input
          type="text"
          name="code"
          class="form-control"
          placeholder=""
          id="code"
          [(ngModel)]="code"
          autocomplete="off"
          required
        />
        <div class="alert alert-danger" *ngIf="codeError">
          The code was incorrect.
        </div>
      </div>

      <p class="required-msg">
        <span class="required">*</span> indicates required field
      </p>
      <hr class="divider" />
      <div class="container">
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6 text-right">
            <a
              (click)="checkCode(code)"
              class="btn btn-primary btn-white"
              >NEXT <span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- step 2 -->
  <div [hidden]="step !== 2">
    <div
      class="reading-material-page-header booster-session-step-header register-header text-left"
    >
      2 | Personal Details
    </div>
    <div class="container">
      <div class="form-group">
        <label for="location"
          >SCREEN NAME <span class="required">*</span></label
        >
        <p>This is will be shown on challenge boards and comments.</p>
        <input
          type="text"
          name="screen_name"
          class="form-control"
          placeholder="Screen Name"
          id="screen_name"
          ngModel
          #screen_name="ngModel"
          autocomplete="off"
          required
          minlength="1"
          maxlength="100"
          ng-pattern="/^[\w -]*$/"
        />
        <div
          class="alert alert-danger"
          *ngIf="
            (screen_name.touched && screen_name.invalid) ||
            (stepSubmitted[2] && screen_name.invalid)
          "
        >
          There is an error with your screen name.
          <span *ngIf="screen_name.errors?.nametaken"
            >Sorry, that screen name is already taken</span
          >
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group form-group-dob">
            <label for="location"
              >DATE OF BIRTH <span class="required">*</span></label
            >

            <input
              class="form-control"
              name="dob"
              #dob="ngModel"
              bsDatepicker
              placeholder="DD/MM/YYYY"
              autocomplete="off"
              placement="bottom"
              [bsConfig]="{
                dateInputFormat: 'DD/MM/YYYY',
                containerClass: 'theme-cv',
                showWeekNumbers: false
              }"
              [(ngModel)]="bsValue"
              required
            />

            <div
              class="alert alert-danger"
              *ngIf="
                (dob.touched && dob.invalid) ||
                (stepSubmitted[2] && dob.invalid)
              "
            >
              There is an error with your Date of Birth.
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="weight">GENDER <span class="required">*</span></label>
            <input
              type="hidden"
              name="gender"
              id="gender"
              [(ngModel)]="regDetails.gender"
              #gender="ngModel"
              required
            />
            <div class="row">
              <div class="col-4">
                <button
                  type="button"
                  class="btn btn-block btn-default"
                  [ngClass]="{
                    'btn-default': regDetails.gender !== 'female',
                    'btn-primary': regDetails.gender === 'female'
                  }"
                  (click)="regDetails.gender = 'female'"
                >
                  FEMALE
                </button>
              </div>
              <div class="col-4">
                <button
                  type="button"
                  class="btn btn-block btn-default"
                  [ngClass]="{
                    'btn-default': regDetails.gender !== 'male',
                    'btn-primary': regDetails.gender === 'male'
                  }"
                  (click)="regDetails.gender = 'male'"
                >
                  MALE
                </button>
              </div>
              <div class="col-4">
                <button
                  type="button"
                  class="btn btn-block btn-default"
                  [ngClass]="{
                    'btn-default': regDetails.gender !== 'x',
                    'btn-primary': regDetails.gender === 'x'
                  }"
                  (click)="regDetails.gender = 'x'"
                >
                  X
                </button>
                <em>(Indeterminate / Intersex / Unspecified)</em>
              </div>
            </div>
            <div
              class="alert alert-danger"
              *ngIf="stepSubmitted[2] && !regDetails.gender"
            >
              Please select your gender.
            </div>
          </div>
        </div>
      </div>

      <p class="required-msg">
        <span class="required">*</span> indicates required field
      </p>
      <hr class="divider" />
      <div class="container">
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6 text-right">
            <a (click)="checkReg(2)" class="btn btn-primary btn-white"
              >NEXT <span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span></a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- step 3 -->
  <div [hidden]="step !== 3">
    <div
      class="reading-material-page-header booster-session-step-header register-header text-left"
    >
      3 | Account Details
    </div>
    <div class="container">
      <div class="form-group">
        <label for="email">EMAIL ADDRESS <span class="required">*</span></label>
        <input
          type="email"
          name="email"
          class="form-control input-tracker"
          placeholder=""
          id="email"
          #email="ngModel"
          [ngModel]="regDetails.email"
          autocomplete="off"
          required
          readonly
        />
        <div
          class="alert alert-danger"
          *ngIf="
            (email.touched && email.invalid) ||
            (stepSubmitted[4] && email.invalid)
          "
        >
          Please enter a valid email address.
        </div>
      </div>

      <div class="form-group">
        <label for="password">PASSWORD <span class="required">*</span></label>
        <p>
          Minimum ten characters, at least one uppercase letter, one lowercase
          letter, one number and one special character ($@!%*?&amp;#)
        </p>
        <input
          type="password"
          name="password"
          class="form-control input-tracker ok-password"
          [ngClass]="password.dirty && password.invalid ? 'error' : ''"
          placeholder="PASSWORD"
          id="password"
          ng-required="true"
          [(ngModel)]="regDetails.password"
          #password="ngModel"
          autocomplete="off"
          required
          [pattern]="passwordPattern"
        />
        <div
          class="alert alert-danger"
          *ngIf="stepSubmitted[4] && password.invalid && password.dirty"
        >
          Please enter a valid password.
        </div>
        <div
          class="label password-count"
          [ngClass]="
            regDetails.password.length > 9 ? 'label-success' : 'label-danger'
          "
        >
          {{ password.value | passwordCount: 10 }}
        </div>

        <div class="strength-meter">
          <div class="strength-meter-fill"
          [attr.data-strength]="getPasswordStrength(password.value)"></div>
        </div>
        <div>
          <ul class="password-checklist">
            <li
              [ngClass]="{
                'text-danger':
                  regDetails.password.length < 10 || !password.value,
                'text-success': regDetails.password.length > 7
              }"
            >

            <span
            class="icon-wrap"
            *ngIf="regDetails.password.length > 9"
            ><fa-icon [icon]="['fas', 'check']"></fa-icon
          ></span>
            <span
            class="icon-wrap"
            *ngIf="regDetails.password.length < 10 || !password.value"
            ><fa-icon [icon]="['fas', 'times']"></fa-icon
          ></span>
              Minimum ten characters
            </li>
            <li
              [ngClass]="{
                'text-danger': !checkPassword('uppercase', password.value),
                'text-success': checkPassword('uppercase', password.value)
              }"
            >
              <span
                class="icon-wrap"
                *ngIf="checkPassword('uppercase', password.value)"
                ><fa-icon [icon]="['fas', 'check']"></fa-icon
              ></span>
              <span
                class="icon-wrap"
                *ngIf="!checkPassword('uppercase', password.value)"
                ><fa-icon [icon]="['fas', 'times']"></fa-icon
              ></span>

              At least one uppercase letter
            </li>
            <li
              [ngClass]="{
                'text-danger': !checkPassword('lowercase', password.value),
                'text-success': checkPassword('lowercase', password.value)
              }"
            >
              <span
                class="icon-wrap"
                *ngIf="checkPassword('lowercase', password.value)"
                ><fa-icon [icon]="['fas', 'check']"></fa-icon
              ></span>
              <span
                class="icon-wrap"
                *ngIf="!checkPassword('lowercase', password.value)"
                ><fa-icon [icon]="['fas', 'times']"></fa-icon
              ></span>

              One lowercase letter
            </li>
            <li
              [ngClass]="{
                'text-danger': !checkPassword('number', password.value),
                'text-success': checkPassword('number', password.value)
              }"
            >
              <span
                class="icon-wrap"
                *ngIf="checkPassword('number', password.value)"
                ><fa-icon [icon]="['fas', 'check']"></fa-icon
              ></span>
              <span
                class="icon-wrap"
                *ngIf="!checkPassword('number', password.value)"
                ><fa-icon [icon]="['fas', 'times']"></fa-icon
              ></span>

              One number
            </li>
            <li
              [ngClass]="{
                'text-danger': !checkPassword('special', password.value),
                'text-success': checkPassword('special', password.value)
              }"
            >
              <span
                class="icon-wrap"
                *ngIf="checkPassword('special', password.value)"
                ><fa-icon [icon]="['fas', 'check']"></fa-icon
              ></span>
              <span
                class="icon-wrap"
                *ngIf="!checkPassword('special', password.value)"
                ><fa-icon [icon]="['fas', 'times']"></fa-icon
              ></span>

              One special character ($@!%*?&amp;#)
            </li>
          </ul>
        </div>
      </div>

      
      <div class="form-group">
        <label for="confirmPassword"
          >CONFIRM PASSWORD <span class="required">*</span></label
        >
        <input
          type="password"
          name="confirmPassword"
          class="form-control input-tracker"
          placeholder=""
          id="confirmPassword"
          ngModel
          #confirmPassword="ngModel"
          autocomplete="off"
          required="true"
          [equalTo]="password"
        />
        <div
          class="alert alert-danger"
          *ngIf="
            (confirmPassword.touched && confirmPassword.invalid) ||
            (confirmPassword.touched && confirmPassword.errors?.equalTo)
          "
        >
          Your passwords do not match or there is an error with your password.
        </div>
      </div>

      <div class="form-group">
        <input
          type="checkbox"
          name="terms"
          id="terms"
          #terms="ngModel"
          [(ngModel)]="regDetails.terms"
          required
        />
        I agree to the <a href="/terms-of-use" target="_blank">Terms of Use</a>,
        <a href="/privacy-policy" target="_blank">Privacy/Cookie Policy</a> and
        <a href="/acceptable-use" target="_blank">Acceptable Use Policy</a>
        <span class="required">*</span>.
        <div
          class="alert alert-danger"
          *ngIf="
            (terms.touched && terms.invalid) ||
            (stepSubmitted[4] && terms.invalid)
          "
        >
          Please agree to the terms {{ terms.value }}.
        </div>
      </div>

      <p class="required-msg">
        <span class="required">*</span> indicates required field
      </p>
      <hr class="divider" />
      <div class="container">
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6 text-right">
            <button type="button" (click)="checkReg(3)" class="btn btn-primary btn-white" [disabled]="isFetching"
              >NEXT <span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span></button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- step 5 -->
  <!--div ng-show="step==5">
        <div class="reading-material-page-header booster-session-step-header register-header text-left">5 | Team Name</div>
        <div class="container">
            <p>Please vote for your team name from the selsection of choices below...</p>
        </div>
            <div class="toggle-header toggle-header-left" ng-repeat="teamName in teamNames" (click)="teamVote(teamName)">
                <a class="toggle-nav toggle-nav-expand"><i class="fa fa-arrow-circle-right"></i></a>
                <div class="toggle-header-title">{{teamName | uppercase}}</div>
            </div>
            
            <hr class="divider" />
            <div class="container">
                <div class=" row">
                    <div class="col-6">
                    </div>
                    <div class="col-6 text-right">
                        <a (click)="" class="btn btn-primary btn-white">NEXT <i class="fa fa-arrow-circle-o-right"></i></a>
                    </div>
                </div>
            </div>
    </div-->

  <!-- step 4 -->
  <div [hidden]="step !== 4">
    <div
      class="reading-material-page-header booster-session-step-header register-header text-left"
    >
      Completed
    </div>
    <div class="container">
      <p>Congratulations!</p>
      <p>You are now registered to use MyDesmond as a buddy.</p>
    </div>
    

    <hr class="divider" />
    <div class="container">
      <div class="row">
        <div class="col-6"></div>
        <div class="col-6 text-right">
          <a (click)="complete()" class="btn btn-primary btn-white"
            >NEXT <span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span></a>
        </div>
      </div>
    </div>
  </div>
</form>
