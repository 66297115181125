<nav class="subnav">
    <h2><span class="icon-wrap"><fa-icon [icon]="['fas', 'graduation-cap']" [fixedWidth]="true"></fa-icon></span>  ADD SESSIONS</h2>
</nav>
<div *ngIf="programmeContent">
<p class="instruction">Below are some optional extra sessions you can add to your programme if you wish. These additional sessions cover a variety of topics, which may be useful to different people. If any of these look useful to you, you can choose them by selecting the tick box and then they will be automatically added to your Learning Sessions.</p>
<div class="toggle-header toggle-header-left" *ngFor="let extraSession of programmeContent.extra_sessions" (click)="addSession(extraSession)">
  <a class="toggle-nav toggle-nav-expand">
    <span class="icon-wrap" *ngIf="userSessions.indexOf(extraSession.booster_session_id)==-1"><fa-icon [icon]="['far', 'square']" [fixedWidth]="true"></fa-icon></span> 
    <span class="icon-wrap" *ngIf="userSessions.indexOf(extraSession.booster_session_id)!=-1"><fa-icon [icon]="['fas', 'check-square']" [fixedWidth]="true"></fa-icon></span>
  </a>
  <div class="toggle-header-title">{{extraSession.title | uppercase}}</div>
</div>

</div>