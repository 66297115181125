<div class="container m3" *ngIf="currentUser">
  <app-m3-header class="d-none d-sm-block"></app-m3-header>
  <app-m3-mobile-navbar
    [pageTitle]="'Physical Ability'"
    class="d-block d-sm-none"
  ></app-m3-mobile-navbar>
  <nav class="subnav d-block d-sm-none">
    <h2>Overview</h2>
  </nav>
  <h1 class="m3-title mt-3 d-none d-sm-block">
    <a class="add-item-btn" (click)="addModal.show()"
      ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
    ></a>
    <app-m3-icon [icon]="'physical-ability'"></app-m3-icon>Physical function
    &amp; strength
  </h1>

  <button
    (click)="addModal.show()"
    class="btn btn-mobile-add d-block d-sm-none"
  >
    <app-m3-icon [icon]="'more'" [style]="'color'"></app-m3-icon> Add a sit-to-stand 60 score
  </button>

  <div class="card">
    <div class="card-body" *ngIf="sts60s">
      <div class="row">
        <div class="col-md-6">
          <div class="info-stat info-stat-rag mb-2">
            <div class="info-stat-rag-current">
              <app-m3-icon [icon]="'sts'" class="text-dark"></app-m3-icon>
              <span class="info-stat-label">My sit-to-stand 60 score</span>
              <span
                *ngIf="sts60s[0]"
                class="info-stat-stat text-rag-{{
                  getSts60RagForAgeAndSex(sts60s[0].sts, age, sex)
                }}"
                ><span *ngIf="sts60s"
                  ><span *ngIf="!sts60s[0]">-</span
                  ><span *ngIf="sts60s[0]">{{ sts60s[0].sts }}</span></span
                ></span
              >
            </div>

            <div class="rag-details">
              <div class="rag-details-target">
                <span
                  *ngIf="
                    sts60s[0] &&
                    +sts60s[0].sts <
                      sts60Service.getGoodSts(this.age, this.currentUser.gender)
                  "
                >
                  Target:
                  <span class="text-blue">{{
                    sts60Service.getGoodSts(this.age, this.currentUser.gender)
                  }}</span></span
                >
              </div>
              <div class="rag-details-history">
                History:
                <div class="rag-history">
                  <ng-template #popTemplateSts60>
                    <div>
                      <div>Range:</div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-green mr-1"></span>
                        {{ getSts60forColorAgeAndSex("green", age, sex) }} reps
                      </div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-amber mr-1"></span>
                        {{ getSts60forColorAgeAndSex("amber", age, sex) }} reps
                      </div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-red mr-1"></span>
                        {{ getSts60forColorAgeAndSex("red", age, sex) }} reps
                      </div>
                    </div>
                  </ng-template>
                  <ng-container
                    *ngFor="
                      let sts60 of sts60s.slice().reverse();
                      let index = index
                    "
                  >
                    <div
                      *ngIf="index > sts60s.length - 6"
                      [tooltip]="
                        sts60.sts +
                        ' on ' +
                        (sts60.date_recorded | dateFormatter : 'Do MMM YYYY')
                      "
                      class="rag-circle bg-rag-{{
                        getSts60RagForAgeAndSex(sts60.sts, age, sex)
                      }}"
                    ></div>
                  </ng-container>
                  <div class="rag-circle pointer" [popover]="popTemplateSts60">
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['fas', 'info']"></fa-icon
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <app-m3-physical-ability-chart
            *ngIf="sts60s[0] && currentUser"
            [sts60s]="sts60s"
            [age]="age"
            [sex]="currentUser.gender"
          ></app-m3-physical-ability-chart>

          <h3 class="chart-title text-center mt-4">Your Entries</h3>
          <table *ngIf="sts60s[0]" class="table">
            <thead>
              <tr>
                <th>DATE</th>
                <th>REPETITIONS</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let hr of sts60s">
                <td class="text-center">
                  {{ hr.date_recorded | dateFormatter : "MMM Do YYYY" }}
                </td>
                <td class="text-center">
                  {{ hr.sts }}
                </td>

                <td>
                  <a
                    [swal]="{
                      title: 'Are you sure you want to delete this?',
                      icon: 'warning',
                      showCancelButton: true
                    }"
                    (confirm)="onDelete(hr)"
                    class="pointer"
                    ><span class="icon-wrap"
                      ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-6">
          <div
            class="info-stat mb-2"
            *ngIf="this.currentUser && this.age"
          >
            <app-m3-icon [icon]="'vo2max'" class=""></app-m3-icon>
            <span class="info-stat-label"
              >A good STS60 score for someone aged {{ this.age }}</span
            >
            <span class="info-stat-stat text-rag-green">{{
              sts60Service.getGoodSts(this.age, this.currentUser.gender)
            }}</span>
          </div>

          <div class="info-stat mb-2">
            <span class="text-orange"
              ><app-m3-icon [icon]="'heart-rate'"></app-m3-icon
            ></span>
            <span
              class="info-stat-label"
              *ngIf="
                +sts60s[0].sts >
                +sts60Service.getGoodSts(this.age, this.currentUser.gender)
              "
              >Your STS60 score is what we would expect for a
              {{ genderLabel }} younger than {{ age }} years</span
            >
            <span
              class="info-stat-label"
              *ngIf="
                +sts60s[0].sts ==
                +sts60Service.getGoodSts(this.age, this.currentUser.gender)
              "
              >Your STS60 score is a typical value for a {{ genderLabel }} aged
              {{ age }}</span
            >

            <span
              class="info-stat-label"
              *ngIf="
                +sts60s[0].sts <
                +sts60Service.getGoodSts(this.age, this.currentUser.gender)
              "
              >Your STS60 score is what we would expect for a
              {{ genderLabel }} in the
              {{
                sts60Service.getTypical(currentSts60, currentUser.gender)
              }}
              age category</span
            >

            <span class="info-stat-stat text-orange" style="min-width: 100px"
              >&nbsp;</span
            >
            <!--span class="info-stat-stat text-orange" style="min-width: 100px">{{
              sts60Service.getTypical(currentSts60, currentUser.gender)
            }}</span-->
          </div>

          <div class="mt-4">
            <h3 class="chart-title text-center">STS60 Reference Values</h3>
            <table class="table m3-table" *ngIf="currentUser">
              <thead>
                <tr>
                  <th>Age Category</th>
                  <th>Reps</th>
                </tr>
              </thead>
              <tbody *ngIf="this.currentUser.gender == 'm'">
                <tr>
                  <td>20-59</td>
                  <td
                    [ngClass]="{
                      'bg-blue': this.age > 19 && this.age < 60,
                      'bg-rag-green':
                        +this.currentSts60 >= 27 &&
                        getSts60RagForAgeAndSex(+this.currentSts60, age, sex) ==
                          'green',
                      'bg-rag-amber':
                        +this.currentSts60 >= 27 &&
                        getSts60RagForAgeAndSex(+this.currentSts60, age, sex) ==
                          'amber',
                      'bg-rag-red':
                        +this.currentSts60 >= 27 &&
                        getSts60RagForAgeAndSex(+this.currentSts60, age, sex) ==
                          'red'
                    }"
                  >
                    ≥27
                  </td>
                </tr>
                <tr>
                  <td>60-69</td>
                  <td
                    [ngClass]="{
                      'bg-blue': this.age > 59 && this.age < 70,
                      'bg-rag-green':
                        +this.currentSts60 ==26 &&
                        getSts60RagForAgeAndSex(+this.currentSts60, age, sex) ==
                          'green',
                      'bg-rag-amber':
                        +this.currentSts60 == 26 &&
                        getSts60RagForAgeAndSex(+this.currentSts60, age, sex) ==
                          'amber',
                      'bg-rag-red':
                        +this.currentSts60 == 26 &&
                        getSts60RagForAgeAndSex(+this.currentSts60, age, sex) ==
                          'red'
                    }"
                  >
                    ≥26
                  </td>
                </tr>
                <tr>
                  <td>70-80</td>
                  <td
                    [ngClass]="{
                      'bg-blue': this.age > 69 && this.age < 81,
                      'bg-rag-green':
                        +this.currentSts60 >=23 && +this.currentSts60 <26 &&
                        getSts60RagForAgeAndSex(+this.currentSts60, age, sex) ==
                          'green',
                      'bg-rag-amber':
                        +this.currentSts60  >=23 &&+this.currentSts60 <26  &&
                        getSts60RagForAgeAndSex(+this.currentSts60, age, sex) ==
                          'amber',
                      'bg-rag-red':
                        +this.currentSts60  >=23 &&+this.currentSts60 <26  &&
                        getSts60RagForAgeAndSex(+this.currentSts60, age, sex) ==
                          'red'
                    }"
                  >
                    ≥23
                  </td>
                </tr>
              </tbody>

              <tbody *ngIf="this.currentUser.gender == 'f'">
                <tr>
                  <td>20-49</td>
                  <td
                    [ngClass]="{
                      'bg-blue': this.age > 19 && this.age < 50,
                      'bg-rag-green':
                        +this.currentSts60 >= 27 &&
                        getSts60RagForAgeAndSex(+this.currentSts60, age, sex) ==
                          'green',
                      'bg-rag-amber':
                        +this.currentSts60 >= 27 &&
                        getSts60RagForAgeAndSex(+this.currentSts60, age, sex) ==
                          'amber',
                      'bg-rag-red':
                        +this.currentSts60 >= 27 &&
                        getSts60RagForAgeAndSex(+this.currentSts60, age, sex) ==
                          'red'
                    }"
                  >
                    ≥27
                  </td>
                </tr>
                <tr>
                  <td>50-69</td>
                  <td
                    [ngClass]="{
                      'bg-blue': this.age > 49 && this.age < 70,
                      'bg-rag-green':
                        +this.currentSts60 >=24 && +this.currentSts60 <27 &&
                        getSts60RagForAgeAndSex(+this.currentSts60, age, sex) ==
                          'green',
                      'bg-rag-amber':
                        +this.currentSts60 >=24 && +this.currentSts60 <27 &&
                        getSts60RagForAgeAndSex(+this.currentSts60, age, sex) ==
                          'amber',
                      'bg-rag-red':
                        +this.currentSts60 >=24 && +this.currentSts60 <27 &&
                        getSts60RagForAgeAndSex(+this.currentSts60, age, sex) ==
                          'red'
                    }"
                  >
                    ≥24
                  </td>
                </tr>
                <tr>
                  <td>70-80</td>
                  <td
                    [ngClass]="{
                      'bg-blue': this.age > 69 && this.age < 81,
                      'bg-rag-green':
                        +this.currentSts60 >=23 && +this.currentSts60 <26 &&
                        getSts60RagForAgeAndSex(+this.currentSts60, age, sex) ==
                          'green',
                      'bg-rag-amber':
                        +this.currentSts60  >=23 &&+this.currentSts60 <26  &&
                        getSts60RagForAgeAndSex(+this.currentSts60, age, sex) ==
                          'amber',
                      'bg-rag-red':
                        +this.currentSts60  >=23 &&+this.currentSts60 <26  &&
                        getSts60RagForAgeAndSex(+this.currentSts60, age, sex) ==
                          'red'
                    }"
                  >
                    ≥23
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    bsModal
    #addModal="bs-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-static-name"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left">Add sit-to-stand in 60 seconds data</h4>
          <button
            type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="addModal.hide()"
          >
            <span aria-hidden="true" class="visually-hidden">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form
            name="entryForm"
            class="bs-form tracker-form"
            (ngSubmit)="onSubmitEntryForm()"
            #entryForm="ngForm"
          >
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="reps">Repetitions</label>
                  <input
                    type="number"
                    name="reps"
                    class="form-control input-tracker input-tracker-xsm"
                    placeholder=""
                    id="reps"
                    [ngModel]="currentSts60"
                    min="1"
                    max="300"
                    autocomplete="off"
                    ng-required=""
                    #reps="ngModel"
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="reps.touched && reps.invalid"
                  >
                    This must be a number below 300.
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="number">DATE</label>
                  <input
                    name="date"
                    type="text"
                    placeholder="DD/MM/YYYY"
                    class="form-control input-tracker input-tracker-sm"
                    bsDatepicker
                    autocomplete="off"
                    [bsConfig]="{
                      dateInputFormat: 'DD/MM/YYYY',
                      containerClass: 'theme-cv',
                      showWeekNumbers: false
                    }"
                    [(ngModel)]="date"
                    required
                    [bsValue]="date"
                    [maxDate]="date"
                    #dateInput="ngModel"
                  />
                </div>
              </div>
            </div>
            <div class="tracker-submit-container">
              <button
                type="submit"
                role="button"
                class="btn btn-primary btn-lg btn-white"
              >
                UPDATE STS60
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
