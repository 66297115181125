import { ProgrammeService } from './../services/programme.service';
import { catchError, map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { AuthService } from './../auth/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, forkJoin, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BoosterSessionsService {
  userId;
  learningSessionProgressTotal = 0;
  boosterSessionProgressTotal = 0;

  updateExtraSessions = new BehaviorSubject(null);
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private programmeService: ProgrammeService
  ) {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userId = user.id;
      }
    });
  }

  getSessionProgress(sessionId?) {
    // get the progress
    let searchParams = new HttpParams();
    searchParams = searchParams.append('user_id', this.userId);

    if (sessionId) {
      searchParams = searchParams.append('booster_session_id', sessionId);
    }

    return this.http
      .get<any>(environment.apiUrl + '/user_booster_session_progress', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const progress = responseData._embedded.user_booster_session_progress;

          if (sessionId) {
            if (progress[0]) {
              return progress[0];
            } else {
              return;
            }
          }
          const sessionProgress = {};

          progress.forEach((Item, Index) => {
            sessionProgress[Item.booster_session_id] = Item.progress;
          });

          return sessionProgress;
        })
      );
  }

  getSessionsProgressData(programmeId) {
    if (!this.userId) {
      return;
    }
    return forkJoin({
      programmeContent: this.programmeService.getProgrammeContent(programmeId),
      sessionProgress: this.getSessionProgress(),
      currentProgramme: this.programmeService.getProgramme(programmeId),
    });
  }
  doSessions(responseData, programmeStartDate) {
    this.learningSessionProgressTotal = 0;
    let sToday = moment().format('YYYY-MM-DD');
    let lastDate;
    const rlsDates = { M: 'month', W: 'week' };

    let boosterSessionTotals = { completed: 0, available: 0, upcoming: 0 };
    let learningSessionTotals = { completed: 0, available: 0, upcoming: 0 };
    let learningSessionShowMob;

    const sessionProgress = responseData.sessionProgress;
    const programmeContent = responseData.programmeContent;
    const currentProgramme = responseData.currentProgramme;

    // booster session status

    let lsTotal = 0;
    let lsProg = 0;

    programmeContent.booster_sessions.forEach((item, index) => {
      if (item.release_date === 'O' || item.release_date === 'EPL') {
        if (sessionProgress[item.booster_session_id]) {
          lsProg += +sessionProgress[item.booster_session_id];
        }
        lsTotal++;
      }

      let status = 'unavailable';
      if (!lastDate) {
        lastDate = moment(programmeStartDate).format('YYYY-MM-DD');
      }
      // is it open?
      if (item.release_date === 'O' || item.release_date === 'EPL') {
        learningSessionTotals['available']++;
      }

      // is it open?
      let dPeriod;
      let dLength;

      switch (item.release_date) {
        case 'M':
          dPeriod = 'month';
          dLength = 1;
          break;
        case 'W':
          dPeriod = 'week';
          dLength = 1;
          break;
        case 'W2':
          dPeriod = 'week';
          dLength = 2;
          break;
        case 'W3':
          dPeriod = 'week';
          dLength = 3;
          break;
        case 'W5':
          dPeriod = 'week';
          dLength = 5;
          break;
        case 'W6':
          dPeriod = 'week';
          dLength = 6;
          break;
        case 'W7':
          dPeriod = 'week';
          dLength = 7;
          break;
        case 'W8':
          dPeriod = 'week';
          dLength = 8;
          break;
      }

      if (
        moment(lastDate).add(dLength, dPeriod).format('YYYY-MM-DD') <=
        moment().format('YYYY-MM-DD')
      ) {
        status = 'available';
        if (item.release_date !== 'O' && item.release_date !== 'EPL') {
          boosterSessionTotals['available']++;
        }
      } else {
        if (item.release_date !== 'O' && item.release_date !== 'EPL') {
          boosterSessionTotals['upcoming']++;
        }
      }

      lastDate = moment(lastDate).add(dLength, dPeriod).format('YYYY-MM-DD');

      programmeContent.booster_sessions[index].status = status;
      // is it complete?
      if (sessionProgress[item.booster_session_id]) {
        programmeContent.booster_sessions[index].progress =
          sessionProgress[item.booster_session_id];
        if (sessionProgress[item.booster_session_id] >= 100) {
          programmeContent.booster_sessions[index].status = 'completed';

          if (item.release_date !== 'O' && item.release_date !== 'EPL') {
            boosterSessionTotals['completed']++;
          }
          if (item.release_date === 'O' || item.release_date == 'EPL') {
            learningSessionTotals['completed']++;
            learningSessionShowMob++;
          }
        }
        else{
          programmeContent.booster_sessions[index].status = 'started';
        }
      }
    });

    // do the extra sessions
    programmeContent.extra_sessions.forEach((item, index) => {
      
      programmeContent.extra_sessions[index].status = status;
      // is it complete?
      if (sessionProgress[item.booster_session_id]) {
        programmeContent.extra_sessions[index].progress =
          sessionProgress[item.booster_session_id];
        if (sessionProgress[item.booster_session_id] >= 100) {
          programmeContent.extra_sessions[index].status = 'completed';

          if (item.release_date !== 'O' && item.release_date !== 'EPL' ) {
            boosterSessionTotals['completed']++;
          }
          if (item.release_date === 'O' || item.release_date === 'EPL' ) {
            learningSessionTotals['completed']++;
            learningSessionShowMob++;
          }
        }
        else{
          programmeContent.extra_sessions[index].status = 'started';
        }
      }
    });

    this.learningSessionProgressTotal = lsProg / lsTotal;

    boosterSessionTotals = boosterSessionTotals;
    learningSessionTotals = learningSessionTotals;
    return {
      sessionProgress,
      programmeContent,
      learningSessionTotals,
      learningSessionProgressTotal: this.learningSessionProgressTotal,
      boosterSessionTotals,
      boosterSessionProgressTotal: this.boosterSessionProgressTotal,
    };
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + '/booster_sessions/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchStep(id: number) {
    return this.http
      .get<any>(environment.apiUrl + '/booster_session_steps/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
  fetchSteps(id: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('booster_session_id', '' + id);
    httpParams = httpParams.append('sort', 'sort_order');
    httpParams = httpParams.append('order', 'ASC');
    return this.http
      .get<any>(environment.apiUrl + '/booster_session_steps', {
        params: httpParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.booster_session_steps;
        })
      );
  }


  fetchExtraSessions(id: number) {
    // /user_extra_sessions?user_id=' + $scope.currentUser.user_id + '&programme_id=' + $scope.currentProgramme.programme_id
    let httpParams = new HttpParams();
    httpParams = httpParams.append('user_id', this.userId);
    httpParams = httpParams.append('programme_id', '' + id);
    return this.http
      .get<any>(environment.apiUrl + '/user_extra_sessions', {
        params: httpParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.user_extra_sessions;
        })
      );
  }

  deleteUserSession(id: number){
    return this.http.delete<{ name: string }>(
      environment.apiUrl + '/user_extra_sessions/' + id
    );
  }



  addUserSession(programmeId, sessionId) {
    const postData = {
      user_id: this.userId,
      programme_id: programmeId,
      session_id: sessionId,
    };
    return this.http.post<{ name: string }>(
      environment.apiUrl + '/user_extra_sessions',
      postData,
      {
        observe: 'response',
      }
    ).pipe(
      map((responseData) => {
        return responseData.body;
      }),
      catchError((errorRes) => {
        return throwError(errorRes);
      }));
  }

  refreshExtraSessions(){
    this.updateExtraSessions.next(true);
  }

  /*.subscribe((responseData) => {
      this.learningSessionProgressTotal = 0;
      let sToday = moment().format('YYYY-MM-DD');
      let lastDate;
      const rlsDates = { M: 'month', W: 'week' };

      let boosterSessionTotals = { completed: 0, available: 0, upcoming: 0 };
      let learningSessionTotals = { completed: 0, available: 0, upcoming: 0 };
      let learningSessionShowMob;

      const sessionProgress = responseData.sessionProgress;
      const programmeContent = responseData.programmeContent;
      const currentProgramme = responseData.currentProgramme;

      // booster session status

      let lsTotal = 0;
      let lsProg = 0;

      programmeContent.booster_sessions.forEach((item, index) => {
        if (item.release_date === 'O') {
          if (sessionProgress[item.booster_session_id]) {
            lsProg += +sessionProgress[item.booster_session_id];
          }
          lsTotal++;
        }

        let status = 'unavailable';
        if (!lastDate) {
          lastDate = moment(programmeStartDate).format('YYYY-MM-DD');
        }
        // is it open?
        if (item.release_date === 'O') {
          learningSessionTotals['available']++;
        }

        // is it open?
        let dPeriod;
        let dLength;

        switch (item.release_date) {
          case 'M':
            dPeriod = 'month';
            dLength = 1;
            break;
          case 'W':
            dPeriod = 'week';
            dLength = 1;
            break;
          case 'W2':
            dPeriod = 'week';
            dLength = 2;
            break;
          case 'W3':
            dPeriod = 'week';
            dLength = 3;
            break;
          case 'W5':
            dPeriod = 'week';
            dLength = 5;
            break;
          case 'W6':
            dPeriod = 'week';
            dLength = 6;
            break;
          case 'W7':
            dPeriod = 'week';
            dLength = 7;
            break;
          case 'W8':
            dPeriod = 'week';
            dLength = 8;
            break;
        }

        if (
          moment(lastDate).add(dLength, dPeriod).format('YYYY-MM-DD') <=
          moment().format('YYYY-MM-DD')
        ) {
          status = 'available';
          if (item.release_date !== 'O') {
            boosterSessionTotals['available']++;
          }
        } else {
          if (item.release_date !== 'O') {
            boosterSessionTotals['upcoming']++;
          }
        }

        lastDate = moment(lastDate).add(dLength, dPeriod).format('YYYY-MM-DD');

        programmeContent.booster_sessions[index].status = status;
        // is it complete?
        if (sessionProgress[item.booster_session_id]) {
          programmeContent.booster_sessions[index].progress =
            sessionProgress[item.booster_session_id];
          if (sessionProgress[item.booster_session_id] >= 100) {
            programmeContent.booster_sessions[index].status = 'completed';

            if (item.release_date !== 'O') {
              boosterSessionTotals['completed']++;
            }
            if (item.release_date === 'O') {
              learningSessionTotals['completed']++;
              learningSessionShowMob++;
            }
          }
        }
      });

      this.learningSessionProgressTotal = lsProg / lsTotal;

      boosterSessionTotals = boosterSessionTotals;
      learningSessionTotals = learningSessionTotals;

      /*
      // have they completed everything?
      if (
        currentProgramme['config'].certificate &&
        learningSessionTotals.completed > 0 &&
        learningSessionTotals.completed ==
         learningSessionTotals.available +
            learningSessionTotals.upcoming &&
        boosterSessionTotals.completed ==
          boosterSessionTotals.available +
           boosterSessionTotals.upcoming
      ) {
        // do they have a notification?
        $http({
          method: 'GET',
          url:
            '/notifications?user_id=' +
            $scope.currentUser.user_id +
            '&notification_type=completionCert',
        }).then(
          function successCallback(response) {
            // is there one
            if (response.data.total_items == 0) {
              var notification = {};
              notification.notification_type = 'completionCert';
              notification.content =
                'You have completed all the Learning and Booster Sessions!';
              notification.status = 0;
              notification.user_id = $scope.currentUser.user_id;
              notification.link =
                '/download-certificate/' + $scope.currentProgramme.programme_id;
              NotificationService.addNotification(notification).then(
                function () {
                  NotificationService.getNotifications(
                    $scope.currentUser.user_id,
                    0
                  ).then(function (notifications) {
                    $scope.setNewNotifications(notifications);
                  });
                }
              );

              // read all alerts for boosters here
              if ($scope.newAlerts) {
                $scope.newAlerts.forEach(function (alert, alertIndex) {
                  // read it
                  if (alert.alert_type == 'booster') {
                    var alertStatus = {};
                    alertStatus.user_id = $scope.currentUser.user_id;
                    alertStatus.alert_id = alert.alert_id;
                    $http({
                      method: 'POST',
                      url: '/user_alert_status',
                      data: alertStatus,
                    }).then(
                      function successCallback(response) {
                        AlertService.getAlerts(
                          $scope.currentUser.user_id,
                          0
                        ).then(function (alerts) {
                          $scope.setNewAlerts(alerts);
                        });
                      },
                      function errorCallback(response) {
                        console.log('there was an error');
                      }
                    );
                  }
                });
              }
            }
          },
          function errorCallback(response) {}
        );
      }*/

  // save it
  /*
      localStorageService.set(
        'boosterSessionTotals',
        $scope.boosterSessionTotals
      );
      // console.log(boosterSessionTotals);

      console.log(sessionProgress);
      return ['foo', 'baa'];
    });*/
}

/*

    $http({
      method: 'GET',
      url:
        '/user_booster_session_progress?user_id=' +
        $scope.currentUser.user_id,
    }).then(
      function successCallback(response) {
        var progress = response.data._embedded.user_booster_session_progress;
        $scope.sessionProgress = {};

        progress.forEach(function (Item, Index) {
          $scope.sessionProgress[Item.booster_session_id] = Item.progress;
        });

        // booster session status

        var lsTotal = 0;
        var lsProg = 0;

        $scope.programmeContent.booster_sessions.forEach(function (
          item,
          index
        ) {
          if (item.release_date == 'O') {
            if ($scope.sessionProgress[item.booster_session_id]) {
              lsProg += +$scope.sessionProgress[item.booster_session_id];
            }
            lsTotal++;
          }

          var status = 'unavailable';
          if (lastDate == false) {
            lastDate = moment(programmeStartDate).format('YYYY-MM-DD');
          }
          // is it open?
          if (item.release_date == 'O') {
            learningSessionTotals['available']++;
          }

          // is it open?
          var dPeriod;
          var dLength;

          switch (item.release_date) {
            case 'M':
              dPeriod = 'month';
              dLength = 1;
              break;
            case 'W':
              dPeriod = 'week';
              dLength = 1;
              break;
            case 'W2':
              dPeriod = 'week';
              dLength = 2;
              break;
            case 'W3':
              dPeriod = 'week';
              dLength = 3;
              break;
            case 'W5':
              dPeriod = 'week';
              dLength = 5;
              break;
            case 'W6':
              dPeriod = 'week';
              dLength = 6;
              break;
            case 'W7':
              dPeriod = 'week';
              dLength = 7;
              break;
            case 'W8':
              dPeriod = 'week';
              dLength = 8;
              break;
          }

          //console.log(dPeriod);console.log(dLength);console.log(lastDate);
          if (
            moment(lastDate).add(dLength, dPeriod).format('YYYY-MM-DD') <=
            moment().format('YYYY-MM-DD')
          ) {
            status = 'available';
            if (item.release_date != 'O') {
              boosterSessionTotals['available']++;
            }
          } else {
            if (item.release_date != 'O') {
              boosterSessionTotals['upcoming']++;
            }
          }
          //lastDate = moment(lastDate).add(1, rlsDates[item.release_date]).format('YYYY-MM-DD');
          lastDate = moment(lastDate)
            .add(dLength, dPeriod)
            .format('YYYY-MM-DD');

          $scope.programmeContent.booster_sessions[index].status = status;
          // is it complete?
          if (
            angular.isDefined($scope.sessionProgress[item.booster_session_id])
          ) {
            $scope.programmeContent.booster_sessions[index].progress =
              $scope.sessionProgress[item.booster_session_id];
            if ($scope.sessionProgress[item.booster_session_id] >= 100) {
              $scope.programmeContent.booster_sessions[index].status =
                'completed';

              if (item.release_date != 'O') {
                boosterSessionTotals['completed']++;
              }
              if (item.release_date == 'O') {
                learningSessionTotals['completed']++;
                $scope.learningSessionShowMob++;
              }
            }
          }
        });

        $scope.learningSessionProgressTotal = lsProg / lsTotal;

        $scope.boosterSessionTotals = boosterSessionTotals;
        $scope.learningSessionTotals = learningSessionTotals;

        // have they completed everything?
        if (
          $scope.currentProgramme.config.certificate &&
          $scope.learningSessionTotals.completed > 0 &&
          $scope.learningSessionTotals.completed ==
            $scope.learningSessionTotals.available +
              $scope.learningSessionTotals.upcoming &&
          $scope.boosterSessionTotals.completed ==
            $scope.boosterSessionTotals.available +
              $scope.boosterSessionTotals.upcoming
        ) {
          // do they have a notification?
          $http({
            method: 'GET',
            url:
              '/notifications?user_id=' +
              $scope.currentUser.user_id +
              '&notification_type=completionCert',
          }).then(
            function successCallback(response) {
              // is there one
              if (response.data.total_items == 0) {
                var notification = {};
                notification.notification_type = 'completionCert';
                notification.content =
                  'You have completed all the Learning and Booster Sessions!';
                notification.status = 0;
                notification.user_id = $scope.currentUser.user_id;
                notification.link =
                  '/download-certificate/' +
                  $scope.currentProgramme.programme_id;
                NotificationService.addNotification(notification).then(
                  function () {
                    NotificationService.getNotifications(
                      $scope.currentUser.user_id,
                      0
                    ).then(function (notifications) {
                      $scope.setNewNotifications(notifications);
                    });
                  }
                );

                // read all alerts for boosters here
                if ($scope.newAlerts) {
                  $scope.newAlerts.forEach(function (alert, alertIndex) {
                    // read it
                    if (alert.alert_type == 'booster') {
                      var alertStatus = {};
                      alertStatus.user_id = $scope.currentUser.user_id;
                      alertStatus.alert_id = alert.alert_id;
                      $http({
                        method: 'POST',
                        url: '/user_alert_status',
                        data: alertStatus,
                      }).then(
                        function successCallback(response) {
                          AlertService.getAlerts(
                            $scope.currentUser.user_id,
                            0
                          ).then(function (alerts) {
                            $scope.setNewAlerts(alerts);
                          });
                        },
                        function errorCallback(response) {
                          console.log('there was an error');
                        }
                      );
                    }
                  });
                }
              }
            },
            function errorCallback(response) {}
          );
        }

        // save it
        localStorageService.set(
          'boosterSessionTotals',
          $scope.boosterSessionTotals
        );
        //console.log(boosterSessionTotals);
      },
      function errorCallback(response) {}
    );

    /*
    $scope.boosterSessions = {};
    $http({ method: 'GET', url: '/booster_sessions' }).then(
      function successCallback(response) {
        response.data._embedded.booster_sessions.forEach(function (
          booster_session
        ) {
          if (booster_session.image) {
            $http({
              method: 'GET',
              url: '/multimedia/' + booster_session.image,
            }).then(function successCallback(response) {
              $scope.media = response.data;
              booster_session.media = response.data;
              $scope.boosterSessions[
                booster_session.booster_session_id
              ] = booster_session;
            });
          } else {
            booster_session.media = null;
            $scope.boosterSessions[
              booster_session.booster_session_id
            ] = booster_session;
          }
        });
      }
    );*/
