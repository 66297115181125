import { NgForm } from '@angular/forms';
import { DateHelperService } from './../services/date-helper.service';
import { TargetService } from './../services/target.service';
import { ActivityService } from './../services/activity.service';
import { UserSettingsService } from './../services/user-settings.service';
import { ProgrammeService } from './../services/programme.service';
import { UserService } from './../services/user.service';
import { AuthService } from './../auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from './../../environments/environment';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { Color, Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { Router } from '@angular/router';
import { ExerciseWorkoutsService } from '../services/exercise-workouts.service';

@Component({
  selector: 'app-workouts',
  templateUrl: './workouts.component.html',
  styleUrls: ['./workouts.component.scss'],
})
export class WorkoutsComponent implements OnInit {
  @ViewChild('minsForm', { static: false }) minsForm: NgForm;
  currentUser;
  currentProgramme;
  deviceSynced = 'unknown';
  isFetching = false;
  errors = [];
  userSettings;

  currentTarget;
  environment = environment;
  currentMins = 0;
  now;
  currentWeek;
  previousWeek = {
    startDate: null,
    endDate: null,
  };
  nextWeek = {
    startDate: null,
    endDate: null,
  };
  weekTotal = 0;
  weekData = [];

  toggleRow = {
    update: false,
    graph: false,
    target: false,
  };

  showMonth = true;
  date = new Date();

  targetMins = 0;
  startDate;
  endDate;
  daysHit = 0;
  exercises;

  allWorkouts;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private userSettingsService: UserSettingsService,
    private activityService: ActivityService,
    private targetService: TargetService,
    private dateHelperService: DateHelperService,
    private router: Router,
    private exerciseWorkoutsService: ExerciseWorkoutsService
  ) {}

  ngOnInit(): void {
    this.isFetching = true;
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });

      const now = moment();
      this.currentWeek = this.dateHelperService.getWeekFor(now);
      this.now = moment(now).format('YYYY-MM-DD');
      const startDate = moment(this.currentWeek[0]).format('YYYY-MM-DD');
      const endDate = moment(this.currentWeek[6]).format('YYYY-MM-DD');

      this.previousWeek.startDate = moment(this.currentWeek[0])
        .subtract(1, 'week')
        .format('YYYY-MM-DD');
      this.previousWeek.endDate = moment(this.currentWeek[6])
        .subtract(1, 'week')
        .format('YYYY-MM-DD');

      // get months data
      forkJoin({
        userSettings: this.userSettingsService.fetchAll(),
        target: this.targetService.getTarget('workout'),
        exercises: this.exerciseWorkoutsService.fetchExercises(),
        workouts: this.exerciseWorkoutsService.fetchAll(),
      }).subscribe(
        (responseData) => {
          this.isFetching = false;
          this.userSettings = responseData.userSettings;

          if (responseData.target) {
            this.currentTarget = responseData.target;
          }

          this.exercises = responseData.exercises;
          this.allWorkouts = responseData.workouts;
          // week data
          this.daysHit = 0;
          this.currentWeek.forEach((day) => {

            if(this.workoutsByDate(day).length>0){
              this.daysHit+= this.workoutsByDate(day).length;
              this.weekData.push({date:moment(day).format('YYYY-MM-DD'), hit:1});
            }
            else{
              this.weekData.push({date:moment(day).format('YYYY-MM-DD'), hit:0});
            }
          });
          // console.log(this.exercises);
          // end
        },
        (error) => {
          this.isFetching = false;
          this.errors.push(error.message);
        }
      );
    }
  }

  search(obj) {
    return Object.keys(this).every((key) => obj[key] === this[key]);
  }

  onSubmitForm() {
    // save the steps
    // console.log(this.minsForm.value);
    this.exerciseWorkoutsService
      .create(
        this.minsForm.value.date,
        this.minsForm.value.exercise,
        this.minsForm.value.exercise_other,
        this.minsForm.value.mins,
        this.minsForm.value.effort,
        this.minsForm.value.comments
      )
      .subscribe((responseData) => {
        const startDate = moment(this.currentWeek[0]).format('YYYY-MM-DD');
        const endDate = moment(this.currentWeek[6]).format('YYYY-MM-DD');

        forkJoin({
          activity: this.activityService.getActivity(),
          activityBetween: this.activityService.getActivityBetween(
            startDate,
            endDate
          ),
        }).subscribe(
          (forkResponseData) => {
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => this.router.navigate(['/activity/workouts']));
          },
          (error) => {
            console.log(error);
          }
        );

        if (+this.minsForm.value.effort < 5) {
          Swal.fire({
            icon: 'success',
            title: 'Workout Added!',
            html: `<div class="text-left"><p>Your entry was successfully added. You have entered a low effort rating for this
            workout. To get the most benefits, your effort level should be between 5-8.</p>
            <p>
            Next time you may wish to change 1 thing:</p>
            <ul>
            <li>Increase the length of time you work out for</li>
            <li>Increase the number of repetitions of exercises in the workout if that's an option</li>
            <li>Reduce the rest time between exercises</li>
            <li>Use heavier weight or thicker resistance bands if doing a strength workout</li>
            <li>Try a different workout if you are starting to find the ones you do easier.</li>
            </ul></div>`,
            showConfirmButton: true,
          });
        } else if (+this.minsForm.value.effort > 8) {
          Swal.fire({
            icon: 'success',
            title: 'Workout Added!',
            html: `<div class="text-left"><p>Your entry was successfully added. You have entered a high effort rating for this
            workout. To get the most benefits, your effort level should be between 5-8.</p>
            <p>
            Next time you may wish to change 1 thing:</p>
            <ul>
            <li>Reduce the length of time you work out for</li>
            <li>Reduce the number of repetitions of exercises in the workout if that's an option</li>
            <li>Increase the rest time between exercises</li>
            <li>Use lighter weight or thinner resistance bands if doing a strength workout</li>
            <li>Try a different workout if you are finding this one too hard.</li>
            </ul></div>`,
            showConfirmButton: true,
          });
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Workout Added',
            text: 'Your workout was successfully added',
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
        }
      });
  }

  next() {
    const startDate = moment(this.currentWeek[0])
      .add(1, 'week')
      .format('YYYY-MM-DD');
    const endDate = moment(this.currentWeek[6])
      .add(1, 'week')
      .format('YYYY-MM-DD');
    this.startDate = startDate;
    this.endDate = endDate;
    this.currentWeek = this.dateHelperService.getWeekFor(startDate);

    this.previousWeek.startDate = moment(startDate)
      .subtract(1, 'week')
      .format('YYYY-MM-DD');
    this.previousWeek.endDate = moment(endDate)
      .subtract(1, 'week')
      .format('YYYY-MM-DD');

    this.nextWeek.startDate = moment(startDate)
      .add(1, 'week')
      .format('YYYY-MM-DD');
    this.nextWeek.endDate = moment(endDate).add(1, 'week').format('YYYY-MM-DD');

    if (moment() < moment(this.nextWeek.startDate)) {
      this.nextWeek.startDate = null;
      this.nextWeek.endDate = null;
    }

    this.isFetching = true;
    this.weekTotal = 0;
    // get months data
    forkJoin({
      activityBetween: this.activityService.getActivityBetween(
        startDate,
        endDate
      ),
    }).subscribe(
      (responseData) => {
        this.isFetching = false;

        const weeklyActivities = responseData.activityBetween;
        weeklyActivities.forEach((activity) => {
          this.weekTotal = Number(this.weekTotal) + Number(activity.minutes);
        });
        this.weekData = [];

        // week data
        this.currentWeek.forEach((item, index) => {
          const thisActivity = weeklyActivities.filter(this.search, {
            activity_date: moment(item).format('YYYY-MM-DD'),
          })[0];

          if (thisActivity) {
            if (
              thisActivity.hit_goal !== 1 &&
              +thisActivity.percent_hit >= 100
            ) {
              thisActivity.hit_goal = 1;
            }
            this.weekData.push(thisActivity);
          } else {
            const dData = {
              activity_date: moment(item).format('YYYY-MM-DD'),
            };
            this.weekData.push(dData);
          }
        });

        // end
      },
      (error) => {
        this.isFetching = false;
        this.errors.push(error.message);
      }
    );
  }
  previous() {
    const startDate = moment(this.currentWeek[0])
      .subtract(1, 'week')
      .format('YYYY-MM-DD');
    const endDate = moment(this.currentWeek[6])
      .subtract(1, 'week')
      .format('YYYY-MM-DD');

    this.startDate = startDate;
    this.endDate = endDate;
    this.currentWeek = this.dateHelperService.getWeekFor(startDate);

    this.previousWeek.startDate = moment(startDate)
      .subtract(1, 'week')
      .format('YYYY-MM-DD');
    this.previousWeek.endDate = moment(endDate)
      .subtract(1, 'week')
      .format('YYYY-MM-DD');

    this.nextWeek.startDate = moment(startDate)
      .add(1, 'week')
      .format('YYYY-MM-DD');
    this.nextWeek.endDate = moment(endDate).add(1, 'week').format('YYYY-MM-DD');

    this.isFetching = true;
    this.weekTotal = 0;
    // get months data
    forkJoin({
      activityBetween: this.activityService.getActivityBetween(
        startDate,
        endDate
      ),
    }).subscribe(
      (responseData) => {
        this.isFetching = false;

        const weeklyActivities = responseData.activityBetween;
        weeklyActivities.forEach((activity) => {
          this.weekTotal = Number(this.weekTotal) + Number(activity.minutes);
        });
        this.weekData = [];

        // week data
        this.currentWeek.forEach((item, index) => {
          const thisActivity = weeklyActivities.filter(this.search, {
            activity_date: moment(item).format('YYYY-MM-DD'),
          })[0];

          if (thisActivity) {
            if (
              thisActivity.hit_goal !== 1 &&
              +thisActivity.percent_hit >= 100
            ) {
              thisActivity.hit_goal = 1;
            }
            this.weekData.push(thisActivity);
          } else {
            const dData = {
              activity_date: moment(item).format('YYYY-MM-DD'),
            };
            this.weekData.push(dData);
          }
        });

        // end
      },
      (error) => {
        this.isFetching = false;
        this.errors.push(error.message);
      }
    );
  }

  submitTarget(target) {
    this.targetService.create(target, 'workout', 'days').subscribe(
      (responseData) => {
        if(this.currentTarget){
        this.currentTarget.target = +target;
        }
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/activity/workouts']));

        Swal.fire({
          icon: 'success',
          title: 'Target Updated',
          text: 'Your target were successfully updated',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  workoutsByDate(date) {
    let workouts = [];
    if (this.allWorkouts) {
      this.allWorkouts.forEach((workout) => {
        if (moment(workout.workout_date).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD')) {
          workouts.push(workout);
        }
      });
    }
    return workouts;
  }

  workoutMinutesForDate(date){
    let minutes = 0;
    if (this.allWorkouts) {
      this.allWorkouts.forEach((workout) => {
        if (moment(workout.workout_date).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD')) {
          minutes+= +workout.minutes;
        }
      });
    }
    return minutes;
  }
}
