<!--div class="section-nav-mobile-sub"><app-section-nav
[section]="'healthTrackers'"
></app-section-nav>
</div-->
<nav class="subnav">
  <h2>
    <span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'kidneys']"></fa-icon
    ></span>
    OVERVIEW
  </h2>
</nav>
<div class="decision-maker">
  <ng-container *ngFor="let symptom of symptoms">
    <div
      class="toggle-header toggle-header-left"
      [routerLink]="['/symptom-trackers', symptom.slug]"
    >
      <a
        class="toggle-nav toggle-nav-expand"
        [routerLink]="['/symptom-trackers', symptom.slug]"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
      ></a>
      <div class="toggle-header-title">
          <span
            class="icon-wrap section-nav-icon"
            ><fa-icon [icon]="[symptom.iconWeight, symptom.icon]" [fixedWidth]="true"></fa-icon
          ></span>
        {{symptom.name | uppercase}}
      </div>
    </div>
  </ng-container>
</div>
