import { AuthService } from './../auth/auth.service';
import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssessmentsService {
  userId;

  cacheTime = new Date();
  cacheLifetime = 3 * 60 * 1000;

  assessmentResults: any = {};

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userId = user.id;
      }
    });
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + '/assessments/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchQuestions(assessmentId) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('assessment_id', '' + assessmentId);
    searchParams = searchParams.append('sort', 'sort_order');
    searchParams = searchParams.append('order', 'ASC');
    return this.http
      .get<any>(environment.apiUrl + '/assessment_questions', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const questions = responseData._embedded.assessment_questions;
          questions.forEach((item, index) => {
            item.answers = [];
            questions[index] = item;
          });
          return questions;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
  fetchAnswers(assessmentId) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('assessment_id', '' + assessmentId);
    return this.http
      .get<any>(environment.apiUrl + '/assessment_answers', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.assessment_answers;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchResults(assessmentId, prePost, boosterSessionId?): Observable<any> {
    const now = new Date();

    if (
      !this.assessmentResults[
        assessmentId + '_' + prePost + '_' + boosterSessionId
      ] ||
      Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime
    ) {
      let searchParams = new HttpParams();
      searchParams = searchParams.append('assessment_id', '' + assessmentId);
      searchParams = searchParams.append('prepost', '' + prePost);
      searchParams = searchParams.append('user_id', '' + this.userId);
      if (boosterSessionId) {
        searchParams = searchParams.append(
          'booster_session_id',
          '' + boosterSessionId
        );
      }
      this.assessmentResults[
        assessmentId + '_' + prePost + '_' + boosterSessionId
      ] = this.http
        .get<any>(environment.apiUrl + '/assessment_results', {
          params: searchParams,
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            return responseData._embedded.assessment_results;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.assessmentResults[
      assessmentId + '_' + prePost + '_' + boosterSessionId
    ];
  }

  fetchResponses(assessmentId, prePost, boosterSessionId?) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('assessment_id', '' + assessmentId);
    searchParams = searchParams.append('prepost', '' + prePost);
    searchParams = searchParams.append('user_id', '' + this.userId);
    if (boosterSessionId) {
      searchParams = searchParams.append(
        'booster_session_id',
        '' + boosterSessionId
      );
    }
    return this.http
      .get<any>(environment.apiUrl + '/assessment_responses', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.assessment_responses;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  saveResponse(
    assessment_id,
    assessment_question_id,
    assessment_set_answer,
    prepost,
    boosterSessionId?
  ) {
    this.clearCache();
    const postData = {
      user_id: this.userId,
      assessment_id,
      assessment_question_id,
      assessment_set_answer,
      prepost,
      booster_session_id: null,
    };
    if (boosterSessionId) {
      postData.booster_session_id = boosterSessionId;
    }
    return this.http
      .post<{ name: string }>(
        environment.apiUrl + '/assessment_responses',
        postData,
        {
          observe: 'response',
        }
      )
      .pipe(
        map((responseData) => {
          return responseData.body;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  saveResult(assessment_id, score, num_questions, prepost, boosterSessionId?) {
    this.clearCache();
    const postData = {
      user_id: this.userId,
      assessment_id,
      score,
      num_questions,
      prepost,
      booster_session_id: null,
    };
    if (boosterSessionId) {
      postData.booster_session_id = boosterSessionId;
    }
    return this.http
      .post<{ name: string }>(
        environment.apiUrl + '/assessment_results',
        postData,
        {
          observe: 'response',
        }
      )
      .pipe(
        map((responseData) => {
          return responseData.body;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  clearCache() {
    this.assessmentResults = {};
  }
}
