import { QuestionnaireService } from './../../../services/questionnaire.service';
import { forkJoin } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-step-questionnaire',
  templateUrl: './step-questionnaire.component.html',
  styleUrls: ['./step-questionnaire.component.scss'],
})
export class StepQuestionnaireComponent implements OnInit {
  @Input() questionnaireId;
  questionnaire;
  questions;
  qNum = 1;
  answers;

  constructor(
    private questionnaireService: QuestionnaireService
  ) {}

  ngOnInit(): void {
    forkJoin({
      questionnaire: this.questionnaireService.fetch(this.questionnaireId),
      questions: this.questionnaireService.fetchQuestions(this.questionnaireId),
      answers: this.questionnaireService.fetchAnswers(this.questionnaireId),
    }).subscribe(
      (responseData) => {
        this.questionnaire = responseData.questionnaire;

        this.questionnaire.feedback = JSON.parse((this.questionnaire.feedback));
        this.questions = responseData.questions;
        this.answers = responseData.answers;

        this.answers.forEach((answer, index) => {

          this.questions.forEach((question, qindex) => {
            this.questions[qindex].state = 'unanswered';
            if (
              answer.questionnaire_question_id === question.questionnaire_question_id
            ) {
              this.questions[qindex].answers.push(answer);
            }
          });

        });
      },
      (error) => {
      }
    );}

  pickQuestionnaireAnswer(question, answer) {
    if (this.questionnaire.state != 'answered') {
      this.answers.forEach( (scoAnswer, index)=> {
        if (
          this.answers[index].questionnaire_question_id ==
          question.questionnaire_question_id
        ) {
          this.answers[index].state = 'default';
        }
        if (
          scoAnswer.questionnaire_answer_id == answer.questionnaire_answer_id
        ) {
          if (this.answers[index].state != 'selected') {
            this.answers[index].state = 'selected';
          } else {
            this.answers[index].state = 'default';
          }
          // get other answers to this question
        }
      });
    }

    // update the q
    this.questions.forEach( (scoQ, index) => {
      if (
        scoQ.questionnaire_question_id == question.questionnaire_question_id
      ) {
        this.questions[index].state = 'answered';
      }
    });

    this.qNum++;
    if (this.qNum == this.questions.length + 1) {
      this.submitQuestionnaire();
    }
  }

  submitQuestionnaire() {
    this.questionnaire.score = 0;
    this.answers.forEach( (scoAnswer, index) => {
      if (scoAnswer.state == 'selected') {
        this.questionnaire.score = +this.questionnaire.score + +scoAnswer.score;
      }
    });
    this.questionnaire.state = 'answered';
  }

  checkQuestionnaireFeedback(score, min, max) {
    if (+score >= +min && +score <= +max) {
      return true;
    } else {
      return false;
    }
  }

  questionnaireOffset() {
    let percentScore;
    if (this.questionnaire) {
      const range = Math.abs(
        +this.questionnaire.feedback.feedback3Max -
          +this.questionnaire.feedback.feedback1Min
      );
      if (+this.questionnaire.feedback.feedback1Min < 0) {
        percentScore =
          +this.questionnaire.score +
          +this.questionnaire.feedback.feedback1Min * -1;
      } else {
        percentScore =
          +this.questionnaire.score + +this.questionnaire.feedback.feedback1Min;
      }
      return (percentScore / range) * 100 + '%';
    }
  }
}
