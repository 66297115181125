import { FaqsService } from './../../services/faqs.service';
import { CommentService } from './../../services/comment.service';
import { forkJoin } from 'rxjs';
import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { environment } from './../../../environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2/src/sweetalert2.js';

@Component({
  selector: 'app-ask-the-expert',
  templateUrl: './ask-the-expert.component.html',
  styleUrls: ['./ask-the-expert.component.scss'],
})
export class AskTheExpertComponent implements OnInit {
  environment = environment;
  comments;
  commentAttachments = {};
  currentUser;
  currentProgramme;
  isFetching;
  isDisabled;
  faqs = [];
  showFaq = {};
  @ViewChild('commentForm', { static: false }) commentForm: NgForm;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private commentService: CommentService,
    private faqsService: FaqsService
  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });
      this.isFetching = true;
      forkJoin({
        comments: this.commentService.fetchAskTheExpert(),
        attatchments: this.commentService.fetchAttachments(),
        faqs: this.faqsService.fetchAll(),
      }).subscribe(
        (responseData) => {
          this.isFetching = false;
          this.comments = responseData.comments;
          this.comments.forEach((item, index) => {
            if (item.modified) {
              this.comments[index].touched = item.modified;
            } else {
              this.comments[index].touched = item.created;
            }
          });

          responseData.attatchments.forEach((item, index) => {
            if (!this.commentAttachments[item.comment_id]) {
              this.commentAttachments[item.comment_id] = [];
            }
            this.commentAttachments[item.comment_id].push(item);
          });

          responseData.faqs.forEach((item, index) => {
            var itemProgs = []
            if(item.programme_id){
            itemProgs = item.programme_id.split(',').map( Number );
            }
            if (item.section === 'ate' && (!item.programme_id || item.programme_id == '0' || itemProgs.indexOf(+this.currentProgramme.programme_id) !==-1)) {
              this.faqs.push(item);
              this.showFaq[item.faq_id] = false;
            }
          });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  onSubmit() {
    this.isDisabled = true;

    if (!this.commentForm.valid) {
      Swal.fire({
        title: "We notice you've missed something",
        text: 'Please check the form for information that has been missed',
        icon: 'error',
      });
      this.isDisabled = false;
    } else {
      this.commentService
        .createAskTheExpert(this.commentForm.value.comment)
        .subscribe(
          (responseData) => {
            const comment = responseData;
            comment['first_name'] = this.currentUser.first_name;
            comment['last_name'] = this.currentUser.last_name;
            comment['avatar'] = this.currentUser.avatar;
            this.comments.unshift(comment);
            this.commentForm.reset();
            this.isDisabled = false;
            window.scroll(0, 0);
          },

          (error) => {
            console.log(error);
          }
        );
    }
  }


  checkEmpty(comment) {
    if (comment) {
      return comment.trim() === '';
    } else {
      return true;
    }
  }
}
