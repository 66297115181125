import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as moment from 'moment';
import { Color, Label } from 'ng2-charts';
import { ActivityService } from 'src/app/services/activity.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-m3-walking-chart',
  templateUrl: './m3-walking-chart.component.html',
  styleUrls: ['./m3-walking-chart.component.scss'],
})
export class M3WalkingChartComponent implements OnInit {
  @Input() allTimeSteps;
  pointBackgroundColors = [];
  pointBorderColors = [];
  backgroundColors = [];
  backgroundColorsLight = [];
  backgroundColorsBrisk = [];

  // Line chart
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          stacked: true,
          ticks: {
            beginAtZero: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          gridLines: {
            display: true,
            lineWidth: 1,
            color: '#dddddd',
          },
          scaleLabel: {
            display: true,
            labelString: 'Average Steps per day', 
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          }
        },
      ],
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
            lineWidth: 2,
            color: '#dddddd',
          },
        
          ticks: {
            autoSkip: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
        
          scaleLabel: {
            display: true,
            labelString: 'Week commencing',
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
        },
      ],
    },
    legend: {
      display: false,
      labels:{
        boxWidth:10
      }
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'Steps',
      borderColor: '#999999',
      fill: true,
      backgroundColor: this.backgroundColors,
      hoverBackgroundColor:this.backgroundColors,
      pointBorderColor:this.backgroundColors
    },
    {
      data: [],
      label: 'Light',
      borderColor: '#999999',
      fill: true,
      backgroundColor: this.backgroundColorsLight,
      hoverBackgroundColor:this.backgroundColorsLight,
      pointBorderColor:this.backgroundColorsLight
    },
    {
      data: [],
      label: 'Brisk',
      borderColor: '#999999',
      fill: true,
      backgroundColor: this.backgroundColorsBrisk,
      hoverBackgroundColor:this.backgroundColorsBrisk,
      pointBorderColor:this.backgroundColorsBrisk
    },
  ];
  public barChartColors: Color[] = [
    { backgroundColor: '#999999' },
  ];

  // end chart

  constructor(private activityService: ActivityService) {}

  ngOnInit(): void {
    this.setChartData();
  }

  setChartData() {


    /*


     // do they have step intensity?
     let hasIntensity = true;
    
     this.allTimeSteps.forEach((activity) => {
       if (
         +activity.steps > 0 &&
         !activity.light_steps &&
         !activity.moderate_steps &&
         !activity.vigorous_steps
       ) {
         hasIntensity = false;
       }
     });
 
     this.barChartData[0].label = 'Steps';
     this.barChartOptions.legend.display = false;
     this.barChartData[0].data = [];
     // has step intensity
     if (hasIntensity) {
       this.barChartData[2] = {};

       this.barChartData[1] = {};
       this.barChartData[1] = { data: [], label: 'Light'};
       this.barChartOptions.legend.display = true;
       this.barChartOptions.legend.position = 'bottom';
       this.barChartColors[1].backgroundColor = '#b8cde8';
       this.barChartColors[2] = { backgroundColor: '#5591c7' };
       //this.barChartColors[2] = { backgroundColor: '#5591c7' }
       this.barChartData[2] = { data: [], label: 'Brisk' };
       //this.barChartData[2] = { data: [], label: 'Vigorous', maxBarThickness: 50 };
       this.barChartData[1].data = [];
       this.barChartData[2].data = [];
       // this.barChartData[2].data = [];
     }


    this.barChartData[0].data = [];
    // setup chart data
    this.allTimeSteps.forEach((value, key) => {


      if(value.steps){
      if(  moment(value.activity_date).format('YYYY-MM-DD') >   moment().subtract(1, 'y').format('YYYY-MM-DD')){

      this.barChartLabels.push(
        moment(value.activity_date).format('YYYY-MM-DD')
      );


      if (hasIntensity) {
        this.barChartData[1].data.push(value.light_steps);
        this.barChartData[2].data.push(
          +value.moderate_steps + +value.vigorous_steps
        );
        //this.barChartData[2].data.push(value.vigorous_steps);
      } 

      this.barChartData[0].data.push(value.steps);
      this.pointBackgroundColors.push(
        environment.ragColors[this.getStepsRag(value.steps)]
      );
      this.pointBorderColors.push(
        environment.ragColors[this.getStepsRag(value.steps)]
      );
      }

    }
    });
  */

    // weekly..



    this.barChartData[0].data = [];

    //do weekly sleeps!

    let weeklySteps = this.activityService.getWeeklyStepAverages(this.allTimeSteps.reverse(), 'total');

    let weeklyStepsLight = this.activityService.getWeeklyStepAverages(this.allTimeSteps, 'light');
    let weeklyStepsBrisk = this.activityService.getWeeklyStepAverages(this.allTimeSteps, 'brisk');

    // setup chart data

    for (var key in weeklySteps) {
      if (weeklySteps.hasOwnProperty(key)) {

      this.barChartLabels.push(
        moment(key).format('DD-MM-YY')
      );
      if(!weeklyStepsLight[key] && !weeklyStepsBrisk[key]){
      this.barChartData[0].data.push(weeklySteps[key]);
      }
      else{
      this.barChartData[0].data.push(0);
      }


      this.backgroundColors.push(environment.ragColors[this.getStepsRag(weeklySteps[key])]);
      this.backgroundColorsLight.push(environment.ragColors[this.getStepsRag(weeklySteps[key])]);
      this.backgroundColorsBrisk.push(environment.ragColorsDark[this.getStepsRag(weeklySteps[key])]);
      }
    };



    for (var key in weeklyStepsLight) {
      if (weeklyStepsLight.hasOwnProperty(key)) {
      this.barChartData[1].data.push(weeklyStepsLight[key]);
      //this.backgroundColors.push(environment.ragColors[this.getStepsRag(weeklyStepsLight[key])]);
      }
    };


    for (var key in weeklyStepsBrisk) {
      if (weeklyStepsBrisk.hasOwnProperty(key)) {

      this.barChartData[2].data.push(weeklyStepsBrisk[key]);
      //this.backgroundColors.push(environment.ragColors[this.getStepsRag(weeklyStepsBrisk[key])]);
      }
    };

  }

  getStepsRag(averageSteps: number) {
    return this.activityService.getStepsRag(averageSteps);
  }
}
