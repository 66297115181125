import { AuthService } from './../auth/auth.service';
import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';

import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { Observable, Subject, throwError } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  // error = new Subject<string>();
  userId;
  cachedSettings?: any;


  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userId = user.id;
      }
    });
  }

  fetchAll(): Observable<any> {
    if (!this.userId) {
      return;
    }
    if (!this.cachedSettings) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('user_id', this.userId);

    this.cachedSettings =  this.http
      .get<any>(environment.apiUrl + '/user_settings', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const userSettings = {};
          responseData._embedded.user_settings.forEach((userSetting) => {
            userSettings[userSetting.key] = userSetting.val;
          });
          return userSettings;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        }),
        publishReplay(1),
        refCount()
      );
    }
    return this.cachedSettings;
  }

  fetchByKey(key) {
    if (!this.userId) {
      return;
    }
    let searchParams = new HttpParams();
    searchParams = searchParams.append('user_id', this.userId);
    searchParams = searchParams.append('key', key);

    return this.http
      .get<any>(environment.apiUrl + '/user_settings', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const userSettings = {};
          if (responseData._embedded.user_settings[0]) {
            return responseData._embedded.user_settings[0];
          } else {
            return;
          }
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  saveSetting(settingVar, settingVal) {
    this.clearCache();
    if (!this.userId) {
      return;
    }
    const settingData = {
      key: settingVar,
      val: settingVal,
      user_id: this.userId,
    };
    return this.http.post<any>(
      environment.apiUrl + '/user_settings',
      settingData,
      {
        observe: 'response',
      }
    );
  }
  update(id, key, val) {
    this.clearCache();
    const postData = {
      key,
      val,
    };
    return this.http.patch<{ name: string }>(
      environment.apiUrl + '/user_settings' + '/' + id,
      postData,
      {
        observe: 'response',
      }
    );
  }


  clearCache() {
    this.cachedSettings = null;
  }
}
