import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {
  constructor(private http: HttpClient) {}

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + '/questionnaires/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchQuestions(questionnaireId) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('questionnaire_id', '' + questionnaireId);
    searchParams = searchParams.append('sort', 'sort_order');
    searchParams = searchParams.append('order', 'ASC');
    return this.http
      .get<any>(environment.apiUrl + '/questionnaire_questions', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const questions = responseData._embedded.questionnaire_questions;
          questions.forEach((item, index) => {
            item.answers = [];
            questions[index] = item;
          });
          return questions;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
  fetchAnswers(questionnaireId) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('questionnaire_id', '' + questionnaireId);
    return this.http
      .get<any>(environment.apiUrl + '/questionnaire_answers', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.questionnaire_answers;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
}
