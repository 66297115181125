import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Sts60Service {
  error = new Subject<string>();
  slug = '/user_sts60s';
  resourceName = 'user_sts60s';
  allSts60s?: any;
  sts60sBetween?: any;
  
  
  constructor(private http: HttpClient) {}

  fetchAll(): any {
    if (!this.allSts60s) {
      this.allSts60s = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: any[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allSts60s;
  }

  clearCache() {
    this.allSts60s = null;
    this.sts60sBetween = null;
  }

  
  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
        
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(
    sts: string,
    date_recorded: string,
  ) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      sts,
    };
    return this.http.post<any>(environment.apiUrl + this.slug, payload, {
      observe: 'response',
    });
  }

  update(
    id: number,
    sts: string,
    date_recorded: string,
  ) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      sts,
    };
    return this.http.patch<any>(
      environment.apiUrl + this.slug + '/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + '/' + id
    );
  }


  getGoodSts(age, gender){
    if(age <50){
      if(gender == 'm') {return 27;}
      if(gender == 'f') {return 27;}
    }
    else if(age >49 && age < 60){
      if(gender == 'm') {return 27;}
      if(gender == 'f') {return 24;}
    }
    else if(age >59 && age < 70){
      if(gender == 'm') {return 26;}
      if(gender == 'f') {return 24;}
    }
    else if(age >69 && age < 80){
      if(gender == 'm') {return 23;}
      if(gender == 'f') {return 23;}
    }
  }

  getTypical(sts, gender){
    if(gender == 'm'){

    if(+sts >=27){
      return '20 - 59';
    }
    else if(+sts<27 && +sts>=26){
      return '60 - 69';
    }
    else{
      return '70 - 80';
    }
    }
    else if (gender == 'f'){

      if(+sts >=27){
        return '20 - 49';
      }
      else if(+sts<27 && +sts>=24){
        return '50 - 69';
      }
      else{
        return '70 - 80';
      }
    } 
  }


  getRagForAgeAndSex(sts60, age, sex) {
    let ragColour = '';

    if (age > 19 && age < 50) {
      if (sex == 'm') {
        if (+sts60 >= 27) {
          ragColour = 'green';
        } else if (+sts60 <= 21) {
          ragColour = 'red';
        } else {
          ragColour = 'amber';
        }


      }
      if (sex == 'f') {
        if (+sts60 >=27) {
          ragColour = 'green';
        } else if (+sts60 <= 22) {
          ragColour = 'red';
        } else {
          ragColour = 'amber';
        }
      }
    } /*else if (age > 29 && age < 40) {
      if (sex == 'm') {
        if (+sts60 >= 36) {
          ragColour = 'green';
        } else if (+sts60 <= 33) {
          ragColour = 'red';
        } else {
          ragColour = 'amber';
        }
      }
      if (sex == 'f') {
        if (+sts60 >= 35) {
          ragColour = 'green';
        } else if (+sts60 <= 31) {
          ragColour = 'red';
        } else {
          ragColour = 'amber';
        }
      }
    } else if (age > 39 && age < 50) {
      if (sex == 'm') {
        if (+sts60 >= 31) {
          ragColour = 'green';
        } else if (+sts60 <= 28) {
          ragColour = 'red';
        } else {
          ragColour = 'amber';
        }
      }
      if (sex == 'f') {
        if (+sts60 >= 34) {
          ragColour = 'green';
        } else if (+sts60 <= 30) {
          ragColour = 'red';
        } else {
          ragColour = 'amber';
        }
      }
    } */else if (age > 49 && age < 60) {
      if (sex == 'm') {
        if (+sts60 >= 27) {
          ragColour = 'green';
        } else if (+sts60 <= 22) {
          ragColour = 'red';
        } else {
          ragColour = 'amber';
        }
      }
      if (sex == 'f') {
        if (+sts60 >= 24) {
          ragColour = 'green';
        } else if (+sts60 <= 18) {
          ragColour = 'red';
        } else {
          ragColour = 'amber';
        }
      }
    } else if (age > 59 && age < 70) {
      if (sex == 'm') {
        if (+sts60 >= 26) {
          ragColour = 'green';
        } else if (+sts60 <= 20) {
          ragColour = 'red';
        } else {
          ragColour = 'amber';
        }
      }
      if (sex == 'f') {
        if (+sts60 >= 24) {
          ragColour = 'green';
        } else if (+sts60 <= 18) {
          ragColour = 'red';
        } else {
          ragColour = 'amber';
        }
      }
    } else if (age > 69 && age < 80) {
      if (sex == 'm') {
        if (+sts60 >= 23) {
          ragColour = 'green';
        } else if (+sts60 <= 18) {
          ragColour = 'red';
        } else {
          ragColour = 'amber';
        }
      }
      if (sex == 'f') {
        if (+sts60 >= 23) {
          ragColour = 'green';
        } else if (+sts60 <= 17) {
          ragColour = 'red';
        } else {
          ragColour = 'amber';
        }
      }
    }

    return ragColour;
  }



  getSts60forColorAgeAndSex(colour:string, age:number, sex:string){
    let ragRange = '';

    if (age > 19 && age < 50) {
      if (sex == 'm') {
        if (colour == 'green') {
          ragRange = '≥27';
        } else if (colour == 'amber') {
          ragRange = '22-26';
        } else {
          ragRange = '≤21';
        }
      }
      if (sex == 'f') {
        if (colour == 'green') {
          ragRange = '≥27';
        } else if (colour == 'amber') {
          ragRange = '23-26';
        } else {
          ragRange = '≤22';
        }
      }
    } /*else if (age > 29 && age < 40) {
      if (sex == 'm') {
        if (colour == 'green') {
          ragRange = '≥36';
        } else if (colour == 'amber') {
          ragRange = '34-35';
        } else {
          ragRange = '≤33';
        }
      }
      if (sex == 'f') {
        if (colour == 'green') {
          ragRange = '≥35';
        } else if (colour == 'amber') {
          ragRange = '32-34';
        } else {
          ragRange = '≤31';
        }
      }
    } else if (age > 39 && age < 50) {
      if (sex == 'm') {
        if (colour == 'green') {
          ragRange = '≥31';
        } else if (colour == 'amber') {
          ragRange = '29-30';
        } else {
          ragRange = '≤28';
        }
      }
      if (sex == 'f') {
        if (colour == 'green') {
          ragRange = '≥34';
        } else if (colour == 'amber') {
          ragRange = '33-31';
        } else {
          ragRange = '≤30';
        }
      }
    } */else if (age > 49 && age < 60) {
      if (sex == 'm') {
        if (colour == 'green') {
          ragRange = '≥27';
        } else if (colour == 'amber') {
          ragRange = '12-26';
        } else {
          ragRange = '≤21';
        }
      }
      if (sex == 'f') {
        if (colour == 'green') {
          ragRange = '≥24';
        } else if (colour == 'amber') {
          ragRange = '19-23';
        } else {
          ragRange = '≤18';
        }
      }
    } else if (age > 59 && age < 70) {
      if (sex == 'm') {
        if (colour == 'green') {
          ragRange = '≥26';
        } else if (colour == 'amber') {
          ragRange = '21-25';
        } else {
          ragRange = '≤20';
        }
      }
      if (sex == 'f') {
        if (colour == 'green') {
          ragRange = '≥24';
        } else if (colour == 'amber') {
          ragRange = '19-23';
        } else {
          ragRange = '≤18';
        }
      }
    } else if (age > 69 && age < 80) {
      if (sex == 'm') {
        if (colour == 'green') {
          ragRange = '≥23';
        } else if (colour == 'amber') {
          ragRange = '19-22';
        } else {
          ragRange = '≤18';
        }
      }
      if (sex == 'f') {
        if (colour == 'green') {
          ragRange = '≥23';
        } else if (colour == 'amber') {
          ragRange = '18-22';
        } else {
          ragRange = '≤17';
        }
      }
    }

    return ragRange;

  }

}
