<nav class="subnav">
  <div *ngIf="currentProgramme && currentUser">
    <a
      *ngIf="currentProgramme.config.buddies == 1"
      [routerLink]="['/friends-and-family']"
      aria-label="Go to buddies"
      class="subnav-prev"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
    ></a>

    <a
      *ngIf="currentProgramme.config.buddies != 1"
      [routerLink]="['/challenges']"
      aria-label="Challenges"
      class="subnav-prev"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
    ></a>
  </div>

  <div *ngIf="isFetching">
    <app-loading-spinner [inline]="1"></app-loading-spinner>
  </div>

  <h2>
    <span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'bullseye']"></fa-icon
    ></span>
    YOUR PERSONAL STEP TARGET
  </h2>
  <a
    [routerLink]="['/activity', 'minutes']"
    aria-label="Go to minutes"
    class="subnav-next"
    ><span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
  ></a>
</nav>
<div class="masthead masthead-accent masthead-db" ng-model="target">
  <div *ngIf="isFetching">
    <app-loading-spinner [inline]="1"></app-loading-spinner>
  </div>
  <!--div *ngIf="!perosnalisedTarget && !message" class="pt-4">
    <span class="icon-wrap masthead-icon text-dark"
      ><fa-icon [icon]="['far', 'hourglass-half']"></fa-icon
    ></span>
    <div class="masthead-figure">Collecting data</div>
    <div class="masthead-strap">
      You can't set a step target at this time. Please check back later.
    </div>
  </div-->

  <div *ngIf="!perosnalisedTarget && currentTargetObj">
    <p class="masthead-strap mt-3 mb-3">
      Your current steps per day walking goal is 
    </p>
    <div class="target-controls mt-2">
      <span class="num">{{ getTargetFormatted(currentTargetObj.target)[0] }}</span>
      <span class="num">{{ getTargetFormatted(currentTargetObj.target)[1] }}</span>
      <span class="num">{{ getTargetFormatted(currentTargetObj.target)[2] }}</span>
      <span class="num">{{ getTargetFormatted(currentTargetObj.target)[3] }}</span>
      <span class="num">{{ getTargetFormatted(currentTargetObj.target)[4] }}</span>
      <!--span class="target-control-btns">
        <button (click)="targetUp()" aria-label="Steps Up">
            <span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-up']"></fa-icon></span>
        </button>
        <button (click)="targetDown()" aria-label="Steps Down">
            <span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-down']"></fa-icon></span>
        </button>
      </span-->
    </div>
    <div *ngIf="currentUser.cadence" class="col-md-6 offset-md-3">
      <div *ngIf="+currentUser.cadence < 100">
        <p *ngIf="currentTargetObj.target < 5000" class="masthead-subtext masthead-subtext-white">
          Make sure some steps are undertaken at a brisk pace. Work towards
          achieving at least 500 of your daily steps over a 5-minute brisk walk
        </p>
        <p *ngIf="currentTargetObj.target >= 5000 && currentTargetObj.target < 7500" class="masthead-subtext masthead-subtext-white">
          Make sure some steps are undertaken at a brisk pace. Undertake at
          least 500 of your daily steps over a 5- minute brisk walk, working
          towards achieving at least 1000 steps over at least a 10-minute brisk
          walk
        </p>
        <p *ngIf="currentTargetObj.target >= 7500" class="masthead-subtext masthead-subtext-white">
          Make sure some steps are undertaken at a brisk pace. Work towards
          undertaking at least 1000 steps over at least a 10-minute brisk walk
        </p>
      </div>

      <div *ngIf="+currentUser.cadence >= 100">
        <p *ngIf="currentTargetObj.target < 5000" class="masthead-subtext masthead-subtext-white">
          Make sure some steps are undertaken at a brisk pace. Undertake at
          least 1000 of your daily steps over at least a 10-minute brisk walk
        </p>
        <p *ngIf="currentTargetObj.target >= 5000 && currentTargetObj.target < 7500" class="masthead-subtext masthead-subtext-white">
          Make sure some steps are undertaken at a brisk pace. Undertake at
          least 1000 of your daily steps over at least a 10-minute brisk walk,
          working towards achieving at least 2000 steps over at least a
          20-minute brisk walk, or two 10-minute brisk walks
        </p>
        <p *ngIf="currentTargetObj.target >= 7500" class="masthead-subtext masthead-subtext-white">
          Make sure some steps are undertaken at a brisk pace. Undertake at
          least 2000 steps over at least a 20- minute brisk walk, or two
          10-minute brisk walks
        </p>
      </div>
    </div>
  </div>

  <div class="masthead-figure" *ngIf="perosnalisedTarget">
    Review your target
  </div>

  <div *ngIf="perosnalisedTarget">
    <div class="target-controls mt-2">
      <span class="num">{{ getTargetFormatted(perosnalisedTarget)[0] }}</span>
      <span class="num">{{ getTargetFormatted(perosnalisedTarget)[1] }}</span>
      <span class="num">{{ getTargetFormatted(perosnalisedTarget)[2] }}</span>
      <span class="num">{{ getTargetFormatted(perosnalisedTarget)[3] }}</span>
      <span class="num">{{ getTargetFormatted(perosnalisedTarget)[4] }}</span>
      <!--span class="target-control-btns">
        <button (click)="targetUp()" aria-label="Steps Up">
            <span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-up']"></fa-icon></span>
        </button>
        <button (click)="targetDown()" aria-label="Steps Down">
            <span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-down']"></fa-icon></span>
        </button>
      </span-->
    </div>
  </div>

  <button
    *ngIf="perosnalisedTarget"
    class="btn btn-primary btn-white target-confirm"
    (click)="submitTarget(perosnalisedTarget)"
  >
    <span class="icon-wrap"><fa-icon [icon]="['fas', 'check']"></fa-icon></span>
    CONFIRM
  </button>
  <div class="p-2" *ngIf="currentTargetObj && perosnalisedTarget"><a (click)="submitTarget(currentTargetObj.target)" class="cursor text-white">Or maintain current target of {{currentTargetObj.target | number}}</a></div>
  
  <div *ngIf="originalTarget == target">
    <p class="masthead-subtext masthead-subtext-white">
      Use the buttons to adjust your target
    </p>
    <p *ngIf="currentTargetObj" class="masthead-subtext">
      Last set on
      {{ currentTargetObj.created | dateToISO | dateFormatter: "D" | ordinal }}
      {{ currentTargetObj.created | dateToISO | dateFormatter: "MMM yyyy" }}
    </p>
  </div>
</div>
<div class="container">
  <div class="well" *ngIf="perosnalisedTarget">
    <!--- <h4 class="h-left">AVERAGE DURING DATA COLLECTION</h4>
    <p>
      {{ myActivity.step_avg | number: '1.0-0' }} from
      {{ startDate | dateFormatter: "DD/MM/yyyy" }} - {{ endDate | dateFormatter: "DD/MM/yyyy" }}
    </p>
-->
    <h4 class="h-left">YOUR PERSONALISED WALKING GOAL</h4>
    <p>{{ message }}</p>
    <p>
      Your new personalised walking goal is {{ perosnalisedTarget }} steps/day
    </p>
    <div *ngIf="currentUser.cadence">
      <div *ngIf="+currentUser.cadence < 100">
        <p *ngIf="perosnalisedTarget < 5000">
          Make sure some steps are undertaken at a brisk pace. Work towards
          achieving at least 500 of your daily steps over a 5-minute brisk walk
        </p>
        <p *ngIf="perosnalisedTarget >= 5000 && perosnalisedTarget < 7500">
          Make sure some steps are undertaken at a brisk pace. Undertake at
          least 500 of your daily steps over a 5- minute brisk walk, working
          towards achieving at least 1000 steps over at least a 10-minute brisk
          walk
        </p>
        <p *ngIf="perosnalisedTarget >= 7500">
          Make sure some steps are undertaken at a brisk pace. Work towards
          undertaking at least 1000 steps over at least a 10-minute brisk walk
        </p>
      </div>

      <div *ngIf="+currentUser.cadence >= 100">
        <p *ngIf="perosnalisedTarget < 5000">
          Make sure some steps are undertaken at a brisk pace. Undertake at
          least 1000 of your daily steps over at least a 10-minute brisk walk
        </p>
        <p *ngIf="perosnalisedTarget >= 5000 && perosnalisedTarget < 7500">
          Make sure some steps are undertaken at a brisk pace. Undertake at
          least 1000 of your daily steps over at least a 10-minute brisk walk,
          working towards achieving at least 2000 steps over at least a
          20-minute brisk walk, or two 10-minute brisk walks
        </p>
        <p *ngIf="perosnalisedTarget >= 7500">
          Make sure some steps are undertaken at a brisk pace. Undertake at
          least 2000 steps over at least a 20- minute brisk walk, or two
          10-minute brisk walks
        </p>
      </div>
    </div>
  </div>
  <app-section-nav [section]="'activity'" [active]="'target'"></app-section-nav>
</div>
