import { AvatarService } from './../../services/avatar.service';
import { UserService } from './../../services/user.service';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upload-avatar',
  templateUrl: './upload-avatar.component.html',
  styleUrls: ['./upload-avatar.component.scss'],
})
export class UploadAvatarComponent implements OnInit {
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;

  constructor(
    private http: HttpClient,
    private avatarService: AvatarService,
    private userService: UserService
  ) {}

  ngOnInit(): void {}

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }

  preview() {
    // Show preview
    const mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    };
  }

  onSubmit() {
    const formData = new FormData();
    formData.append('avatar', this.fileData);

    this.fileUploadProgress = '0%';
    this.avatarService.upload(formData).subscribe(
      (events) => {
        if (events.type === HttpEventType.UploadProgress) {
          this.fileUploadProgress =
            Math.round((events.loaded / events.total) * 100) + '%';
        } else if (events.type === HttpEventType.Response) {
          this.fileUploadProgress = '';

          // update use data
          this.userService.reset();
          this.userService.get().subscribe((userData) => {});

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Avatar updated',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            toast: true,
          });
        }
      },
      (error) => {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'There was an error uploading your file',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          toast: true,
        });
      }
    );
  }

  resetAvatar() {
    this.fileData = null;
    this.previewUrl = null;
    this.fileUploadProgress = null;
    this.uploadedFilePath = null;
    this.avatarService.resetAvatar().subscribe((responseData) => {
      // update use data
      this.userService.reset();
      this.userService.get().subscribe((userData) => {});
    });
  }
}
