import { AlertService } from './../../services/alert.service';
import { NgForm } from '@angular/forms';
import { CholesterolService } from './../../services/cholesterol.service';
import { BloodPressureService } from './../../services/blood-pressure.service';
import { BloodGlucoseService } from './../../services/blood-glucose.service';
import { HealthyEatingService } from './../../services/healthy-eating.service';
import { environment } from './../../../environments/environment';
import { UserShapeService } from './../../services/user-shape.service';
import { BoosterSessionsService } from './../../booster-sessions/booster-sessions.service';
import { TargetService } from './../../services/target.service';
import { ActivityService } from './../../services/activity.service';
import { UserSettingsService } from './../../services/user-settings.service';
import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import Swal from 'sweetalert2/src/sweetalert2.js';

@Component({
  selector: 'app-dashboard-learning',
  templateUrl: './dashboard-learning.component.html',
  styleUrls: ['./dashboard-learning.component.scss']
})
export class DashboardLearningComponent implements OnInit {
  @ViewChild('stepsForm', { static: false }) stepsForm: NgForm;

  currentUser;
  currentProgramme;
  deviceSynced = null;
  hideTrackerDB = false;
  isFetching = false;
  errors = [];
  userSettings;

  currentActivity;
  currentTarget;
  currentTargetMins;
  environment = environment;
  currentSteps = 0;

  // ported vars
  learningSessionTotals;
  learningSessionProgressTotal;
  boosterSessionTotals;
  boosterSessionProgressTotal;
  programmeContent;
  startLSession = 0;
  endLSession = 2;
  startSession = 0;
  endSession = 2;
  lastViewed;
  lastViewedPage;

  newAlerts;

  // health trackers
  userShapes = [];
  userHealthyEatings = [];
  userSmokingType;
  smokingLabels;
  cost;
  userSmokings = [];
  userHbA1cs = [];
  userBloodPressures = [];
  userCholesterols = [];
  bmi;

  // challenges
  currentChallenge;
  challengePos;
  iLead = false;
  currentLeader;
  myActivity;
  format;

  date = new Date();

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private userSettingsService: UserSettingsService,
    private activityService: ActivityService,
    private targetService: TargetService,
    private boosterSessionService: BoosterSessionsService,
    private userShapeService: UserShapeService,
    private healthyEatingService: HealthyEatingService,
    private bloodGlucoseService: BloodGlucoseService,
    private bloodPressureService: BloodPressureService,
    private cholesterolService: CholesterolService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.isFetching = true;
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });

      forkJoin({
        userSettings: this.userSettingsService.fetchAll(),
        activity: this.activityService.getActivity(),
        target: this.targetService.getTarget('step'),
        targetMins: this.targetService.getTarget('mins'),
        sessionProgress: this.boosterSessionService.getSessionsProgressData(
          this.currentProgramme.programme_id
        ),
        userShapes: this.userShapeService.fetchAll(),
        userHealthyEatings: this.healthyEatingService.fetchAll(),
        userHbA1cs: this.bloodGlucoseService.fetchAll(),
        userBloodPressures: this.bloodPressureService.fetchAll(),
        userCholesterols: this.cholesterolService.fetchAll(),
        alerts: this.alertService.fetchAll(),
      }).subscribe(
        (responseData) => {
          this.isFetching = false;
          this.userSettings = responseData.userSettings;
          this.hideTrackerDB = this.userSettings['hideDbTracker'];
          this.currentActivity = responseData.activity;
          if (this.currentActivity) {
            this.currentSteps = this.currentActivity.steps;
            this.deviceSynced = true;
          } else {
            this.deviceSynced = false;
          }

          if (responseData.target) {
            this.currentTarget = responseData.target.target;
          }
          if (responseData.targetMins) {
            this.currentTargetMins = responseData.targetMins.target;
          }

          const sessionProgress = this.boosterSessionService.doSessions(
            responseData.sessionProgress,
            user.created
          );
          // console.log(sessionProgress);
          this.learningSessionTotals = sessionProgress.learningSessionTotals;
          this.learningSessionProgressTotal =
            sessionProgress.learningSessionProgressTotal;
          this.boosterSessionTotals = sessionProgress.boosterSessionTotals;
          this.boosterSessionProgressTotal =
            sessionProgress.boosterSessionProgressTotal;
          this.programmeContent = sessionProgress.programmeContent;

          this.userShapes = responseData.userShapes;
          this.userHealthyEatings = responseData.userHealthyEatings;
          this.userHbA1cs = responseData.userHbA1cs;
          this.userBloodPressures = responseData.userBloodPressures;
          this.userCholesterols = responseData.userCholesterols;
          this.newAlerts = this.alertService.filterByProgramme(responseData.alerts, this.currentProgramme.programme_id);
        },
        (error) => {
          console.log(error);

          this.isFetching = false;
          this.errors.push(error.message);
        }
      );
    }
  }

  onSetLSession(index) {
    this.startLSession = index;
    this.endLSession = +this.startLSession + 2;
  }

  onSetSession(index) {
    this.startSession = index;
    this.endSession = +this.startSession + 2;
  }

  onSubmitStepsForm() {
    // save the steps

    this.activityService
      .saveSteps(this.stepsForm.value.steps, this.stepsForm.value.date)
      .subscribe((responseData) => {
        this.activityService.getActivity().subscribe((activityResponseData) => {
          this.currentActivity = activityResponseData;
        });

        Swal.fire({
          icon: 'success',
          title: 'Steps Updated',
          text: 'Your steps were successfully updated',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        /*
        swal({
        title: 'Steps Updated',
        text: 'Your steps were successfully updated',
        type: 'success',
        timer: 1000,
        showConfirmButton: false,
      });*/
      });
  }

  onCloseAlert(alert, followLink = true) {
    this.alertService.updateStatus(alert.alert_id).subscribe((responseData) => {
      this.alertService.fetchAll().subscribe((alertsResponse) => {
        this.newAlerts =  this.alertService.filterByProgramme(alertsResponse, this.currentProgramme.programme_id);
      });
      if (alert.button_link) {
        if(followLink){
        if (alert.button_link === 'refresh') {
          location.reload();
        } else {
          document.location = alert.button_link;
        }
      }
      }
    });
  }

  hideDbTracker() {
    this.hideTrackerDB = true;
    this.userSettingsService
      .saveSetting('hideDbTracker', '1')
      .subscribe((responseData) => {});
  }
}
