<div class="container m3" *ngIf="currentUser">
    <app-m3-header class="d-none d-sm-block"></app-m3-header>
    <app-m3-mobile-navbar
      [pageTitle]="'Health Data'"
      class="d-block d-sm-none"
    ></app-m3-mobile-navbar>
    <nav class="subnav d-block d-sm-none">
      <h2>Liver Health</h2>
    </nav>
    <h1 class="m3-title mt-3 d-none d-sm-block">
      <button class="btn btn-white btn-sm float-right mt-1" [routerLink]="['/m3','health-data']"> &lt; Back</button>
      <app-m3-icon [icon]="'liver'"></app-m3-icon> Liver Health
    </h1>
  
    <!--button (click)="addModal.show()" class="btn btn-mobile-add d-block d-sm-none">
        <app-m3-icon [icon]="'more'" [style]="'color'"></app-m3-icon
        > Add a Sitting Entry</button-->
  
    <h2 class="mt-2" id="alt">
      ALT
      <a class="add-item-btn" (click)="addAltModal.show()"
        ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
      ></a>
    </h2>
    <div class="card" *ngIf="alts">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            
            <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">ALT</span>
                  <span
                    *ngIf="alts[0]"
                    class="info-stat-stat text-rag-{{
                      userAltService.getRagForSex(
                        alts[0].alt,
                        currentUser.gender
                      )
                    }}"
                  >
                    <span *ngIf="alts[0]">{{ alts[0].alt }}</span
                    ><span *ngIf="!alts[0]">?</span></span
                  >
                  <span *ngIf="!alts[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label">U/L</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-blue">
                      {{ userAltService.getGoodAlt(currentUser.gender) }} U/L
                    </span>
                  </div>
                  <div class="rag-details-history" *ngIf="alts.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateAlt>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{
                              userAltService.getAltforColorAndSex(
                                "green",
                                currentUser.gender
                              )
                            }}
                            U/L
                          </div>
                          
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{
                              userAltService.getAltforColorAndSex(
                                "red",
                                currentUser.gender
                              )
                            }}
                            U/L
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let alt of alts.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > alts.length - 6"
                          [tooltip]="
                            alt.alt +
                            ' U/L ' +
                            ' on ' +
                            (alt.date_recorded | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            userAltService.getRagForSex(
                              alt.alt,
                              currentUser.gender
                            )
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateAlt"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

    
            
    
            <h3 class="chart-title text-center mt-4" *ngIf="alts">Your Entries</h3>
            <div class="table-responsive" *ngIf="alts">
              <table *ngIf="alts" class="table">
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>ALT</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let alt of alts; let index = index">
                    <tr *ngIf="index < 10 || showAllEntries">
                      <td class="text-center">
                        {{ alt.date_recorded | dateFormatter : "MMM Do YYYY" }}
                      </td>
                      <td class="text-center">
                        {{ alt.alt }} U/L
                      </td>
    
                      <td>
                        <a
                          [swal]="{
                            title: 'Are you sure you want to delete this?',
                            icon: 'warning',
                            showCancelButton: true
                          }"
                          (confirm)="onDeleteAlt(alt)"
                          class="pointer"
                          ><span class="icon-wrap"
                            ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                        ></a>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <div class="text-center">
                <button
                  *ngIf="!showAllEntries && alts.length > 10"
                  class="btn btn-primary btn-sm btn-xs"
                  (click)="showAllEntries = true"
                >
                  Show all
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <app-m3-alt-chart
            *ngIf="alts && alts.length > 1"
            [alts]="alts.slice().reverse()"
            [sex]="currentUser.gender"
          ></app-m3-alt-chart>
          </div>
        </div>
      </div>
    </div>
  
  <hr />
  
  <h2 class="mt-2">
   FIB-4
    <a class="add-item-btn" (click)="addFib4Modal.show()"
      ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
    ></a>
  </h2>
  <div class="card" *ngIf="fib4s">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          
            <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">FIB-4 </span>
                  <span *ngIf="fib4s[0]" class="info-stat-stat text-rag-{{userFib4Service.getRag(fib4s[0].fib4)}}">
                    <span *ngIf="fib4s[0]">{{ fib4s[0].fib4 }}</span
                    ><span *ngIf="!fib4s[0]">?</span></span
                  >
                  <span *ngIf="!fib4s[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label"></span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-blue">&lt;1.3</span>
                  </div>
                  <div class="rag-details-history" *ngIf="fib4s.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateFib4>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{userFib4Service.getRangeforColor("green")}}
                           
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            {{userFib4Service.getRangeforColor("amber")}}
                           
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{userFib4Service.getRangeforColor("red")}}
                           
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let fib4 of fib4s.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > fib4s.length - 6"
                          [tooltip]="
                          fib4.fib4 +
                            ' ' +
                            ' on ' +
                            (fib4.date_recorded
                              | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{userFib4Service.getRag(fib4.fib4)}}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateFib4"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
          
            
  
          <h3 class="chart-title text-center mt-4" *ngIf="fib4s">Your Entries</h3>
          <div class="table-responsive" *ngIf="fib4s">
            <table *ngIf="fib4s" class="table">
              <thead>
                <tr>
                  <th>DATE</th>
                  <th>FIB-4</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let fib4 of fib4s; let index = index">
                  <tr *ngIf="index < 10 || showAllEntries">
                    <td class="text-center">
                      {{ fib4.date_recorded | dateFormatter : "MMM Do YYYY" }}
                    </td>
                    <td class="text-center">
                      {{ fib4.fib4 }}
                    </td>
  
                    <td>
                      <a
                        [swal]="{
                          title: 'Are you sure you want to delete this?',
                          icon: 'warning',
                          showCancelButton: true
                        }"
                        (confirm)="onDeleteFib4(fib4)"
                        class="pointer"
                        ><span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                      ></a>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <div class="text-center">
              <button
                *ngIf="!showAllEntries && fib4s.length > 10"
                class="btn btn-primary btn-sm btn-xs"
                (click)="showAllEntries = true"
              >
                Show all
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-6">
            <app-m3-fib4-chart
            *ngIf="fib4s && fib4s.length > 1"
            [fib4s]="fib4s.slice().reverse()"
          ></app-m3-fib4-chart>
        </div>
      </div>
    </div>
  </div>
  
  <div
    class="modal fade"
    bsModal
    #addAltModal="bs-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-static-name"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left">Update ALT</h4>
          <button
            type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="addAltModal.hide()"
          >
            <span aria-hidden="true" class="visually-hidden">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form
            name="entryFormAlt"
            class="bs-form tracker-form"
            (ngSubmit)="onSubmitAltForm()"
            #entryFormAlt="ngForm"
          >

        <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="reps">ALT (U/L)</label>
                <input
                  type="number"
                  name="alt"
                  class="form-control input-tracker input-tracker-xsm"
                  placeholder=""
                  id="alt"
                  min="1"
                  max="300"
                  ngModel
                  autocomplete="off"
                  ng-required=""
                  #alt="ngModel"
                />
                <div
                  class="alert alert-danger"
                  *ngIf="alt.touched && alt.invalid"
                >
                  This must be a number below 300.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="entryDateAlt">DATE</label>
                <input
                  name="entryDateAlt"
                  type="text"
                  placeholder="DD/MM/YYYY"
                  class="form-control input-tracker input-tracker-sm"
                  bsDatepicker
                  autocomplete="off"
                  [bsConfig]="{
                    dateInputFormat: 'DD/MM/YYYY',
                    containerClass: 'theme-cv',
                    showWeekNumbers: false
                  }"
                  [(ngModel)]="entryDateAlt"
                  required
                  [bsValue]="entryDateAlt"
                  [maxDate]="entryDateAlt"
                  #dateInput="ngModel"
                />
              </div>
            </div>
          </div>
          <div class="tracker-submit-container">
            <button
              type="submit"
              role="button"
              class="btn btn-primary btn-lg btn-white"
            >
              UPDATE
            </button>
          </div>
          
          </form>
        </div>
      </div>
    </div>
  </div>


  <div
    class="modal fade"
    bsModal
    #addFib4Modal="bs-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-static-name"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left">Update FIB-4</h4>
          <button
            type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="addFib4Modal.hide()"
          >
            <span aria-hidden="true" class="visually-hidden">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form
            name="entryFormFib4"
            class="bs-form tracker-form"
            (ngSubmit)="onSubmitFib4Form()"
            #entryFormFib4="ngForm"
          >

        <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="reps">FIB-4</label>
                <input
                  type="number"
                  name="fib4"
                  class="form-control input-tracker input-tracker-xsm"
                  placeholder=""
                  id="fib4"
                  min="0"
                  max="20"
                  ngModel
                  autocomplete="off"
                  ng-required=""
                  #fib4="ngModel"
                />
                <div
                  class="alert alert-danger"
                  *ngIf="fib4.touched && fib4.invalid"
                >
                  This must be a number below 20.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="entryDateFib4">DATE</label>
                <input
                  name="entryDateFib4"
                  type="text"
                  placeholder="DD/MM/YYYY"
                  class="form-control input-tracker input-tracker-sm"
                  bsDatepicker
                  autocomplete="off"
                  [bsConfig]="{
                    dateInputFormat: 'DD/MM/YYYY',
                    containerClass: 'theme-cv',
                    showWeekNumbers: false
                  }"
                  [(ngModel)]="entryDateFib4"
                  required
                  [bsValue]="entryDateFib4"
                  [maxDate]="entryDateFib4"
                  #dateInput="ngModel"
                />
              </div>
            </div>
          </div>
          <div class="tracker-submit-container">
            <button
              type="submit"
              role="button"
              class="btn btn-primary btn-lg btn-white"
            >
              UPDATE
            </button>
          </div>
          
          </form>
        </div>
      </div>
    </div>
  </div>
  
  
  
  <div class="clear"></div>
  </div>