import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { BloodPressureService } from 'src/app/services/blood-pressure.service';
import { CholesterolService } from 'src/app/services/cholesterol.service';
import { ProgrammeService } from 'src/app/services/programme.service';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-m3-heart',
  templateUrl: './m3-heart.component.html',
  styleUrls: ['./m3-heart.component.scss']
})
export class M3HeartComponent implements OnInit {
  currentUser;
  showAllEntries = false;

  entryDate = new Date();
  maxDate = new Date();

  bloodPressures:any = [];
  cholesterols:any = [];
  hdlRatios:any = [];

  @ViewChild('trackerForm', { static: false }) trackerForm: NgForm;
  @ViewChild('cholesterolForm', { static: false }) cholesterolForm: NgForm;

  constructor(
    private authService: AuthService,
    private programmeService: ProgrammeService,
    private router: Router,
    private userService: UserService,
    private userSettingsService: UserSettingsService,
    private modalService: BsModalService,
    public bloodPressureService:BloodPressureService,
    public cholesterolService:CholesterolService
  ) // private bloodGlucoseService:BloodGlucoseService
  {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {});

      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });

      forkJoin({
        userSettings: this.userSettingsService.fetchAll(),
        bloodPressures: this.bloodPressureService.fetchAll(),
        cholesterols: this.cholesterolService.fetchAll()
      }).subscribe((responseData) => {

        this.bloodPressures = responseData.bloodPressures;
        this.cholesterols = responseData.cholesterols;
        this.cholesterols.forEach((cholesterol)=>{
          if(cholesterol.hdl_ratio){
            this.hdlRatios.push(cholesterol);
          }
        });
      });
    }
  }

  getSystolicRag(systolic: any) {}

  getSystolicRagRange(color: string, sex: string) {
    let ragRange = '';
  }

  getDiastolicRag(diastolic: any) {}

  onDelete(bloodPressure) {
    
    this.bloodPressureService
    .delete(bloodPressure.user_blood_pressure_id)
      .subscribe((responseData) => {
        //this.activityService.clearCache();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/heart']));
      });
  }
  onDeleteCholesterol(cholesterol){
    this.cholesterolService
    .delete(cholesterol.user_cholesterol_id)
      .subscribe((responseData) => {
        //this.activityService.clearCache();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/heart']));
      });
  }

  
  

  onSubmit() {
    // this.trackerForm.value.date

    if (!this.trackerForm.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.bloodPressureService
        .create(
          this.trackerForm.value.diastolic,
          this.trackerForm.value.systolic,
          moment(this.entryDate).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
           
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/heart']));


          },
          (error) => {
            console.log(error);
          }
        );
    }
  }


  onSubmitCholesterol() {
    // this.trackerForm.value.date
    if (!this.cholesterolForm.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.cholesterolService
        .create(
          null,
          this.cholesterolForm.value.ldl,
          null,
          moment(this.entryDate).format('YYYY-MM-DD'),
          this.cholesterolForm.value.hdl_ratio
        )
        .subscribe(
          (responseData) => {
           
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/heart']));
            
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }
}
