<nav class="subnav">
  <a
    [routerLink]="['/health-trackers', 'cholesterol']"
    class="subnav-prev"
    aria-label="Go to Cholesterol"
  >
    <span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon
    ></span>
  </a>
  <h2>
    <span class="icon-wrap">
      <fa-icon [icon]="['fas', 'smoking-ban']"></fa-icon>
    </span>
    SMOKING
  </h2>
</nav>
<div>
  <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>

  <div class="masthead masthead-accent">
    <div *ngIf="userSmokings">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-6">
              <h3
                class="tracker-title tracker-title-sm"
                *ngIf="userSmokingType"
              >
                {{ smokingLabels[userSmokingType] }} <br /><span
                  style="font-size: 18px"
                  >PER DAY</span
                >
              </h3>
              <h3
                class="tracker-title tracker-title-sm"
                *ngIf="!userSmokingType"
              >
                YOU DON'T SMOKE
              </h3>

              <img
                src="./assets/img/icon-no-smoking.png"
                width="56"
                class="smoking-icon"
              /><span class="tracker-value tracker-value-lg">{{
                userSmokings[0][userSmokingType] | number
              }}</span>
            </div>
            <div class="col-6">
              <div class="text-center">
                <img
                  class="inline-block icon-piggy"
                  src="./assets/img/icon-piggy.png"
                  height="75"
                />
                <h3 class="inline-block tracker-title">
                  COST<br /><span style="font-size: 18px">PER YEAR</span>
                </h3>
              </div>

              <span class="tracker-value tracker-value-lg"
                >{{ environment.currencySymbol }}{{ cost | number:'1.0-0' }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="masthead-subtext tracker-subtext">
            Last updated on
            {{ userSmokings[0].created | dateToISO | dateFormatter: "D" | ordinal }}
            {{ userSmokings[0].created | dateToISO | dateFormatter: "MMM yyyy" }}
          </p>
          <br />
        </div>
      </div>
    </div>

    <div *ngIf="!userSmokings">
      <h4>You haven't added any readings yet!</h4>
    </div>
    <a class="text-white" [routerLink]="['/health-trackers', 'smoking-settings']"
      >UPDATE SMOKING SETTINGS</a
    >
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.update = !toggleRow.update"
    >
    <a href="" class="toggle-nav toggle-nav-expand" (click)="$event.preventDefault()"
    ><span class="icon-wrap" aria-label="Expand"
    ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span
></a>
      <div class="toggle-header-title">UPDATE TRACKER</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.update">
        <form
        class="bs-form tracker-form"
        name="trackerForm"
        method="post"
        (ngSubmit)="onSubmit()"
        #trackerForm="ngForm"
      >
        <div>
          <div class="form-group" [hidden]="userSmokingType !== 'cigarettes'">
            <label for="weight">CIGARETTES</label>
            <p class="instruction">
              Please enter the number of cigarettes you smoke per day.
            </p>
            <input
              type="number"
              name="cigarettes"
              class="form-control input-tracker"
              placeholder=""
              id="cigarettes"
             ngModel
             #cigarettes="ngModel"
              [min]="0"
              [max]="100"
              autocomplete="off"
              [required]="userSmokingType=='cigarettes'"
            />
            <div
              class="alert alert-danger"
              *ngIf="
                cigarettes.touched &&
                !cigarettes.valid
              "
            >
              This must be a number.
            </div>
          </div>

          <div class="form-group" [hidden]="userSmokingType !== 'cigars'">
            <label for="weight">CIGARS</label>

            <p class="instruction">
              Please enter the number of cigars you smoke per day.
            </p>
            <input
              type="number"
              name="cigars"
              class="form-control input-tracker"
              placeholder=""
              id="cigars"
              ngModel
              #cigars="ngModel"
              [min]="0"
              [max]="100"
              autocomplete="off"
              [required]="userSmokingType=='cigars'"
            />
            <div
              class="alert alert-danger"
              *ngIf="
                cigars.touched && !cigars.valid
              "
            >
              This must be a number.
            </div>
          </div>

          <div class="form-group" [hidden]="userSmokingType !== 'tobacco'">
            <label for="weight">TOBACCO</label>
            <p class="instruction">
              Please enter the grams of tobacco you smoke per day.
            </p>
            <input
              type="number"
              name="tobacco"
              class="form-control input-tracker"
              placeholder=""
              id="tobacco"
              ngModel
              #tobacco="ngModel"
              [min]="0"
              [max]="100"
              autocomplete="off"
              [required]="userSmokingType=='tobacco'"
            />
            <div
              class="alert alert-danger"
              *ngIf="
                tobacco.touched && !tobacco.valid
              "
            >
              This must be a number.
            </div>
          </div>

          <div class="form-group" [hidden]="userSmokingType !== 'pipe_tobacco'">
            <label for="weight">PIPE TOBACCO</label>
            <p class="instruction">
              Please enter the grams of pipe tobacco you smoke per day.
            </p>
            <input
              type="number"
              name="pipeTobacco"
              class="form-control input-tracker"
              placeholder=""
              id="pipeTobacco"
              ngModel
              #pipeTobacco="ngModel"
              [min]="0"
              [max]="100"
              autocomplete="off"
              [required]="userSmokingType=='pipe_tobacco'"
            />
            <div
              class="alert alert-danger"
              *ngIf="
              pipeTobacco.touched &&
                !pipeTobacco.valid
              "
            >
              This must be a number.
            </div>
          </div>
          <div class="form-group">
            <label for="entryDate">DATE</label>
            <p class="instructions">
              When did you do take this reading?
            </p>
            <input
              name="date"
              type="text"
              placeholder="DD/MM/YYYY"
              class="form-control input-tracker"
              bsDatepicker
              autocomplete="off"
              [bsConfig]="{
                dateInputFormat: 'DD/MM/YYYY',
                containerClass: 'theme-cv',
                showWeekNumbers: false
              }"
              [(ngModel)]="entryDate"
              required
              [bsValue]="entryDate"
              [maxDate]="maxDate"
              #date="ngModel"
            />
          </div>
          <div class="tracker-submit-container">
            <button
              type="submit"
              class="btn btn-primary btn-white"
              [disabled]="!trackerForm.valid"
            >
            <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'check']"></fa-icon
          ></span> CONFIRM
            </button>
          </div>
        </div>
      </form>

      <!--span *ngIf="updatedToday()">You have already updated today</span-->
    </div>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.graph = !toggleRow.graph"
    >
    <a href="" class="toggle-nav toggle-nav-expand" (click)="$event.preventDefault()"
    ><span class="icon-wrap" aria-label="Expand"
    ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span
></a>
      <div class="toggle-header-title">TRACKER GRAPH</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.graph">
      <div *ngIf="userSmokings">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs nav-tabs-duo justify-content-center" role="tablist">
          <li role="presentation" class="active">
            <a aria-controls="weight" role="tab" data-toggle="tab">{{
              userSmokingType | uppercase
            }}</a>
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <div role="tabpanel">
            <div *ngIf="smokingChartData[0].data.length > 1">
              <canvas
                class="mt-3 mb-3 tracker-chart"
                baseChart
                [datasets]="smokingChartData"
                [labels]="chartLabels"
                [options]="chartOptions"
                [legend]="chartLegend"
                [chartType]="chartType"
                [colors]="chartColors"
              ></canvas>
            </div>
            <p class="text-center" *ngIf="smokingChartData[0].data.length < 2">
              Please add more entries to see the graph
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="!userSmokings">
        <p>There are no readings to display in a graph</p>
      </div>
    </div>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.entry = !toggleRow.entry"
    >
    <a href="" class="toggle-nav toggle-nav-expand" (click)="$event.preventDefault()"
    ><span class="icon-wrap" aria-label="Expand"
    ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span
></a>
      <div class="toggle-header-title">ENTRY ARCHIVE</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.entry">
      <table *ngIf="userSmokings" class="table">
        <thead>
          <tr>
            <th>DATE</th>
            <th *ngIf="userSmokingType == 'cigarettes'">CIGARETTES</th>
            <th *ngIf="userSmokingType == 'cigars'">CIGARS</th>
            <th *ngIf="userSmokingType == 'tobacco'">TOBACCO</th>
            <th *ngIf="userSmokingType == 'pipe_tobacco'">PIPE TOBACCO</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let userSmoking of userSmokings">
            <tr *ngIf="userSmoking[userSmokingType] !== null">
              <td class="text-center">
                {{ userSmoking.created | dateToISO | dateFormatter: "D" | ordinal }}
                {{ userSmoking.created | dateToISO | dateFormatter: "MMM yyyy" }}
              </td>
              <td class="text-center" *ngIf="userSmokingType == 'cigarettes'">
                {{ userSmoking.cigarettes }}
              </td>
              <td class="text-center" *ngIf="userSmokingType == 'cigars'">
                {{ userSmoking.cigars }}
              </td>
              <td class="text-center" *ngIf="userSmokingType == 'tobacco'">
                {{ userSmoking.tobacco }}
              </td>
              <td class="text-center" *ngIf="userSmokingType == 'pipe_tobacco'">
                {{ userSmoking.pipe_tobacco }}
              </td>
              <td>
                <a
                  [swal]="{
                    title: 'Are you sure you want to delete this?',
                    icon: 'warning',
                    showCancelButton: true
                  }"
                  (confirm)="onRemove(userSmoking)"
                  class="pointer"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                ></a>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <div *ngIf="!userSmokings">There are no records</div>
    </div>
  </div>
  <!-- decision maker -->
  <hr class="divider-tracker" />
  <div class="text-center decision-maker-block">
    <p>
      Want to set goals to quit smoking?<br />
      Try our Decision Maker tool
    </p>
    <a [routerLink]="['/decision-maker']" class="btn btn-primary"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'map-signs']"></fa-icon
      ></span>
      DECISION MAKER</a
    >
  </div>

  <app-section-nav
    [section]="'healthTrackers'"
    [active]="'smoking'"
  ></app-section-nav>
</div>
