<div class="container m3" *ngIf="currentUser">

  <app-m3-header class="d-none  d-sm-block"></app-m3-header>
  <app-m3-mobile-navbar [pageTitle]="'Sitting'" class="d-block d-sm-none"></app-m3-mobile-navbar>
  <nav class="subnav d-block d-sm-none">
    <h2>
      Overview
    </h2>
  </nav>
  <h1 class="m3-title mt-3 d-none  d-sm-block">
    <a class="add-item-btn" (click)="addModal.show()"
      ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
    ></a>
    <app-m3-icon [icon]="'sitting'"></app-m3-icon> Sitting
  </h1>

  <button (click)="addModal.show()" class="btn btn-mobile-add d-block d-sm-none">
    <app-m3-icon [icon]="'more'" [style]="'color'"></app-m3-icon
    > Add a Sitting Entry</button>

  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">

          <div class="info-stat info-stat-rag mb-2" *ngIf="allSittings && sittingAverage">

            <div class="info-stat-rag-current">
            <app-m3-icon
              [icon]="'sitting-alt'"
            ></app-m3-icon>
            <span class="info-stat-label" *ngIf="allSittings && sittingWeekStart"
                    >My average daily sitting time over the w/c {{sittingWeekStart}}</span
                  >
            <span class="info-stat-stat text-rag-{{
              getSittingRag(sittingAverage, currentUser.device)
            }}">{{
              sittingAverage | minsToHours
            }}</span>

            </div>

            <div class="rag-details">
              <div class="rag-details-target" *ngIf="sittingTarget">
                Target:
                <span class="text-blue">&lt;{{
                  sittingTarget | number
                }}</span> hours
              </div>
              <div class="rag-details-history" *ngIf="latestSittings">
                Daily History:
                <div class="rag-history">
                  <ng-template #popTemplateSitting>
                    <div>
                      <div>Range:</div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-green mr-1"></span>
                        <span *ngIf="currentUser.device=='fitbit'">&lt;9 hours/day</span>
                        <span *ngIf="currentUser.device!='fitbit'">&lt;6 hours/day</span>
                      </div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-amber mr-1"></span>

                        <span *ngIf="currentUser.device=='fitbit'">9-10 hours/day</span>
                        <span *ngIf="currentUser.device!='fitbit'">6-8 hours/day</span>
                        
                      </div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-red mr-1"></span>

                        <span *ngIf="currentUser.device=='fitbit'">≥10 hours/day</span>
                        <span *ngIf="currentUser.device!='fitbit'">≥8 hours/day</span>
                        
                      </div>
                    </div></ng-template
                  >
                  <ng-container
                    *ngFor="let activity of latestSittings.slice().reverse(); let index = index"
                  >
                    <div
                      *ngIf="index > latestSittings.length - 6"
                      [tooltip]="(activity.sedentary_minutes | minsToHours) + ' on ' + (activity.activity_date | dateFormatter :'Do MMM YYYY')"
                      class="rag-circle bg-rag-{{
                        getSittingRag(activity.sedentary_minutes, currentUser.device)
                      }}"
                    ></div>
                </ng-container>
                  <div
                    class="rag-circle pointer"
                    [popover]="popTemplateSitting"
                  >
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['fas', 'info']"></fa-icon
                    ></span>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <!--div class="info-stat mb-2">
            <app-m3-icon [icon]="'sitting-alt'"></app-m3-icon>
            <span class="info-stat-label">My sitting time accounts for</span>
            <span class="info-stat-stat text-rag-{{getSittingRag(allSittings[0].sedentary_minutes, currentUser.device)}}" *ngIf="allSittings"
              >{{ +allSittings[0].sedentary_minutes | minsToHours }}
            </span>
          </div-->




          <div class="info-stat  mb-2">
            <app-m3-icon [icon]="'target'" class=""></app-m3-icon>
            <span class="info-stat-label"
              >Your sitting target should be less than
            </span>
            <span class="info-stat-stat text-rag-green">{{sittingTarget}}</span>
            <span class="info-stat-label">Hours/day</span>
          </div>

          <div class="info-stat mb-2" *ngIf="sittingAverage && (sittingAverage > sittingTarget*60)">
            <span class=""
              ><app-m3-icon [icon]="'target-alt'"></app-m3-icon
            ></span>
            <span class="info-stat-label"
              >Your updated target is to reduce your sitting time by</span
            >
            <span class="info-stat-stat text-rag-green">{{getUpdatedTarget()}}</span
            ><span class="info-stat-label">mins/day</span>
          </div>


          <h3 class="chart-title text-center mt-4" *ngIf="allSittings">Your Entries</h3>
          <div class="table-responsive"  *ngIf="allSittings">
            <table *ngIf="allSittings" class="table">
              <thead>
                <tr>
                  <th>DATE</th>
                  <th>SITTING TIME</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let sitting of allSittings.slice().reverse() ; let index = index;">
                  <tr *ngIf="sitting.sedentary_minutes > 0 && (index<10 || showAllEntries)">
                  <td class="text-center">
                    {{ sitting.activity_date | dateFormatter : "MMM Do YYYY" }}
                  </td>
                  <td class="text-center">
                    {{ sitting.sedentary_minutes | minsToHours}}
                  </td>

                  <td>
                    <a
                      [swal]="{
                        title: 'Are you sure you want to delete this?',
                        icon: 'warning',
                        showCancelButton: true
                      }"
                      (confirm)="onDelete(sitting)"
                      class="pointer"
                      ><span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                    ></a>
                  </td>
                </tr>
                </ng-container>
              </tbody>
            </table>
            <div class="text-center">
              <button
              *ngIf="!showAllEntries && allSittings.length>10"
               
                class="btn btn-primary btn-sm btn-xs"
                (click)="showAllEntries = true"
              >
                Show all
              </button>
            </div>
          </div>
          
        </div>
        <div class="col-md-6">
          <app-m3-sitting-chart *ngIf="allSittings" [allSittings]="allSittings" [device]="currentUser.device"></app-m3-sitting-chart>
          <div class="text-center" *ngIf="currentUser.device != 'fitbit'">
            <button
              class="btn btn-white"
              (click)="syncSteps(1)"
              style="margin: 10px 0 0 0; font-size: 12px"
            >
              <span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'sync']"></fa-icon
              ></span>
              SYNC DATA
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    bsModal
    #addModal="bs-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-static-name"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left">Update Sitting Time</h4>
          <button
            type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="addModal.hide()"
          >
            <span aria-hidden="true" class="visually-hidden">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form
            name="entryForm"
            class="bs-form tracker-form"
            (ngSubmit)="onSubmitEntryForm()"
            #entryForm="ngForm"
          >
            <p class="instruction">
              You can update your hours and minutes for today or any previous
              days using the form below.
            </p>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="weight">HOURS</label>
                  <input
                    type="number"
                    style="font-size: 28px"
                    name="hours"
                    class="form-control input-tracker input-tracker-xsm"
                    placeholder=""
                    id="hours"
                    [ngModel]="currentHours"
                    min="0"
                    max="1000000000"
                    autocomplete="off"
                    ng-required=""
                    #hours="ngModel"
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="hours.touched && hours.invalid"
                  >
                    This must be a number below 1000000000.
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="weight">MINUTES</label>
                  <input
                    type="number"
                    style="font-size: 28px"
                    name="mins"
                    class="form-control input-tracker input-tracker-xsm"
                    placeholder=""
                    id="mins"
                    [ngModel]="currentMins"
                    min="0"
                    max="1000000000"
                    autocomplete="off"
                    ng-required=""
                    #mins="ngModel"
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="mins.touched && mins.invalid"
                  >
                    This must be a number below 1000000000.
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="number">DATE</label>
                  <input
                    name="date"
                    type="text"
                    placeholder="DD/MM/YYYY"
                    class="form-control input-tracker input-tracker-sm"
                    bsDatepicker
                    autocomplete="off"
                    [bsConfig]="{
                      dateInputFormat: 'DD/MM/YYYY',
                      containerClass: 'theme-cv',
                      showWeekNumbers: false
                    }"
                    [(ngModel)]="date"
                    required
                    [bsValue]="date"
                    [maxDate]="maxDate"
                    #dateInput="ngModel"
                  />
                </div>
              </div>
            </div>
            <div class="tracker-submit-container">
              <button
                type="submit"
                role="button"
                class="btn btn-primary btn-lg btn-white"
              >
                UPDATE
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="clear"></div>
</div>
