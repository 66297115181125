<div>
  <canvas
    *ngIf="sts60s[0]"
    class="mt-3 mb-3 tracker-chart"
    baseChart
    [datasets]="lineChartData"
    [labels]="lineChartLabels"
    [options]="lineChartOptions"
    [legend]="lineChartLegend"
    [chartType]="lineChartType"
    [colors]="lineChartColors"
  >
  </canvas>
</div>
