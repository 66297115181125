import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as moment from 'moment';
import { Color, Label } from 'ng2-charts';
import { ActivityService } from 'src/app/services/activity.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-m3-sitting-chart',
  templateUrl: './m3-sitting-chart.component.html',
  styleUrls: ['./m3-sitting-chart.component.scss'],
})
export class M3SittingChartComponent implements OnInit {
  @Input() allSittings;
  @Input() nextAppointment;
  @Input() device;
  // Line chart
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            beginAtZero: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          gridLines: {
            display: true,
            lineWidth: 1,
            color: '#dddddd',
          },
          scaleLabel:{
            labelString:'Average daily sitting (hrs/day)',
            display:true, 
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          }
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            lineWidth: 2,
            color: '#dddddd',
          },
        
          ticks: {
            autoSkip: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
        
          scaleLabel: {
            display: true,
            labelString: 'Week commencing',
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public pointBackgroundColors = [];
  public pointBorderColors = [];
  public backgroundColors = [];
  public barChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'Sitting hours',
      pointBackgroundColor: this.pointBackgroundColors,
      pointBorderColor: this.pointBorderColors,
      fill: false,
      pointRadius: 4,
      backgroundColor: this.backgroundColors
    },
  ];
  public barChartColors: Color[] = [
    { backgroundColor: environment.primaryColor },
  ];

  constructor(private activityService: ActivityService) {}

  ngOnInit(): void {
    if (this.nextAppointment) {
      this.barChartOptions.scales.xAxes[0].ticks.max = moment(
        this.nextAppointment.appointment_date
      ).format('YYYY-MM-DD');
    }
    this.setChartData();
  }

  setChartData() {


    this.barChartData[0].data = [];

    //do weekly sleeps!

    let weeklySleeps = this.activityService.getWeeklySittingAverages(this.allSittings.reverse());
    // setup chart data

    for (var key in weeklySleeps) {
      if (weeklySleeps.hasOwnProperty(key)) {

      this.barChartLabels.push(
        moment(key).format('DD-MM-YY')
      );
      this.barChartData[0].data.push(Math.round(weeklySleeps[key]/60 * 100)/100);
      this.backgroundColors.push(environment.ragColors[this.getSittingRag(weeklySleeps[key], this.device)]);
      }
    };

    
    /*
    this.barChartData[0].data = [];
    // setup chart data
    this.allSittings.forEach((value, key) => {
      if (value.sedentary_minutes) {
        this.barChartLabels.push(
          moment(value.activity_date).format('YYYY-MM-DD')
        );
        this.barChartData[0].data.push(value.sedentary_minutes/60);
        this.pointBackgroundColors.push(environment.ragColors[this.getSittingRag(value.sedentary_minutes, this.device)]);
        this.pointBorderColors.push(environment.ragColors[this.getSittingRag(value.sedentary_minutes, this.device)]);
      }
    });*/
  }

  getSittingRag(minutes, device) {
    return this.activityService.getSittingRag(minutes, device);
  }
}
