import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minsToHours',
})
export class MinsToHoursPipe implements PipeTransform {
  transform(value: number, format?:string): string {
    if(!value) return '0';
    let hours = Math.floor(value / 60);
    let minutes = Math.floor(value % 60);

    if(format == 'hoursOnly'){
      return ''+hours;
    }
    if(format == 'minutesOnly'){
      return ''+minutes;
    }
    if(format == 'full'){
      return hours + ' hours ' + minutes + ' minutes';
    }
    return hours + 'h ' + minutes + 'm';
  }
}
