import { environment } from './../../../environments/environment';
import { ConfigService } from './../../services/config.service';
import { forkJoin } from 'rxjs';
import { SmokingService } from './../../services/smoking.service';
import { CholesterolService } from './../../services/cholesterol.service';
import { BloodPressureService } from './../../services/blood-pressure.service';
import { BloodGlucoseService } from './../../services/blood-glucose.service';
import { HealthyEatingService } from './../../services/healthy-eating.service';
import { UserShapeService } from './../../services/user-shape.service';
import { UserSettingsService } from './../../services/user-settings.service';
import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-health-tracker-index',
  templateUrl: './health-tracker-index.component.html',
  styleUrls: ['./health-tracker-index.component.scss'],
})
export class HealthTrackerIndexComponent implements OnInit {
  isFetching;
  currentProgramme;
  currentUser;
  userSettings;
  userShapes = [];
  userHealthyEatings = [];
  userHbA1cs = [];
  userBloodPressures = [];
  userCholesterols = [];
  newAlerts;
  userSmokings = [];
  userSmokingType;
  cost;
  errors = [];
  environment = environment;
  smokingLabels = {
    cigarettes: 'Cigarettes',
    cigars: 'cigars',
    tobacco: 'Tobacco',
    pipe_tobacco: 'Pipe Tobacco',
  };

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private userSettingsService: UserSettingsService,
    private userShapeService: UserShapeService,
    private healthyEatingService: HealthyEatingService,
    private bloodGlucoseService: BloodGlucoseService,
    private bloodPressureService: BloodPressureService,
    private cholesterolService: CholesterolService,
    private smokingService: SmokingService,
    private configService: ConfigService,
  ) {}

  ngOnInit(): void {
    this.isFetching = true;
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
        if (this.currentUser) {
        }
      });

      forkJoin({
        userSettings: this.userSettingsService.fetchAll(),
        userShapes: this.userShapeService.fetchAll(),
        userHealthyEatings: this.healthyEatingService.fetchAll(),
        userHbA1cs: this.bloodGlucoseService.fetchAll(),
        userBloodPressures: this.bloodPressureService.fetchAll(),
        userCholesterols: this.cholesterolService.fetchAll(),
        userSmokings: this.smokingService.fetchAll(),
        configs: this.configService.fetchAll(),
      }).subscribe(
        (responseData) => {
          this.isFetching = false;
          this.userSettings = responseData.userSettings;
          this.userShapes = responseData.userShapes;
          this.userHealthyEatings = responseData.userHealthyEatings;
          this.userHbA1cs = responseData.userHbA1cs;
          this.userBloodPressures = responseData.userBloodPressures;
          this.userCholesterols = responseData.userCholesterols;
          this.userSmokings = responseData.userSmokings;

          // cost
          if (this.userSmokings[0]) {
            this.userSmokingType = responseData.userSettings['smoking_type'];
            const configs = responseData.configs;
            switch (this.userSmokingType) {
              case 'cigarettes':
                this.cost =
                  this.userSmokings[0].cigarettes *
                  configs['cigarette_cost'] *
                  365;
                break;
              case 'tobacco':
                this.cost =
                  this.userSmokings[0].tobacco * configs['tobacco_cost'] * 365;

                break;
              case 'cigars':
                this.cost =
                  this.userSmokings[0].cigars * configs['cigar_cost'] * 365;

                break;
              case 'pipe_tobacco':
                this.cost =
                  this.userSmokings[0].pipe_tobacco *
                  configs['pipe_tobacco_cost'] *
                  365;

                break;
            }
          }
        },
        (error) => {
          console.log(error);

          this.isFetching = false;
          this.errors.push(error.message);
        }
      );
    }
  }
  calcBmi(weight) {
    if (weight) {
      const BMI =
        Math.round(
          (+weight /
            ((this.currentUser.height / 100) *
              (this.currentUser.height / 100))) *
            10
        ) / 10;
      return BMI;
    } else {
      return '-';
    }
  }
}
