<div
  class="assessment-container"
>
<div *ngIf="isFetching">
  <app-loading-spinner [inline]="1"></app-loading-spinner>
</div>
<div class="container-lg">
  <div *ngIf="quiz">
    <nav class="subnav">
      <h2><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'graduation-cap']"></fa-icon
      ></span> {{ quiz.title }}</h2>
    </nav>
    <div class="booster-session-content">
      <div class="container bg-white booster-session-step-content">
        <div [ngSwitch]="quiz.quiz_type">
          <div *ngSwitchCase="'prepost:self-efficacy'">
            <div *ngIf="prepost == 'post'">
              <p>
                It's time to take the follow-up to the assessment that you took
                before the module
              </p>
            </div>
            <div [hidden]="complete">
              <div [innerHtml]="quiz.instructions | safeHtml"></div>
              <br /><br />
              <div class="text-center" [hidden]="started">
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="onStartQuiz()"
                >
                  START
                </button>
              </div>

              <div *ngIf="started" class="prepost-qs">
                <div *ngFor="let question of questions">
                  <div class="assessment-q-se">
                    <h4>{{ question.question }}</h4>

                    <ngx-slider
                      [(value)]="responses[question.assessment_question_id]"
                      [options]="options"
                      *ngIf="question.options !== 'freetext'"
                    ></ngx-slider>
                    <textarea 
                    *ngIf="question.options == 'freetext'" name="question{{question.assessment_question_id}}" [(ngModel)]="responses[question.assessment_question_id]" class="form-control" rows="5" placeholder="Please enter a response"></textarea>
                  </div>
                </div>
                <div class="text-center" style="margin-bottom: 100px">
                  <button
                    type="button"
                    class="btn btn-primary"
                    (click)="submit()"
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
            <div [hidden]="!complete">
              <div class="text-center" style="margin-bottom: 100px">
                <h4>Thank you for your responses</h4>
                <a [routerLink]="['/dashboard']" class="btn btn-primary"
                  ><span *ngIf="prepost == 'pre'">GET STARTED</span>
                  <span *ngIf="prepost != 'pre'">DASHBOARD</span
                  >
                  <span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span>
                  </a>
              </div>
            </div>
          </div>
          <div *ngSwitchCase="'prepost:knowledge'">
            <div ng-cloak>
              <div [hidden]="complete">
                <div
                  [innerHtml]="quiz.instructions | safeHtml"
                  [hidden]="started"
                ></div>
                <div class="text-center" [hidden]="started">
                  <button
                    type="button"
                    class="btn btn-primary"
                    (click)="onStartQuiz()"
                  >
                    START
                  </button>
                </div>

                <div *ngIf="started" class="prepost-qs">
                  <div class="quiz-pager">
                    <div class="quiz-pager-content">
                      <div
                        *ngFor="let question of questions; let index = index"
                        [ngClass]="{ current: qNum === index + 1 }"
                      >
                        <div [ngClass]="{ correct: question.correct }">
                            <span class="icon-wrap fa-circle"><fa-icon [icon]="['fas', 'circle']"></fa-icon></span>
                            <span class="icon-wrap fa-check-circle"><fa-icon [icon]="['fas', 'check-circle']"></fa-icon></span>
                            <span class="icon-wrap fa-times-circle"><fa-icon [icon]="['fas', 'times-circle']"></fa-icon></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ng-container
                    *ngFor="let question of questions; let index = index"
                  >
                    <div *ngIf="qNum == index + 1">
                      Question {{ question.sort_order }}
                      <hr class="quiz-divider" />
                      <div class="text-center" *ngIf="question.question_image">
                        <img
                          src="'/assets/' + question.imageData.filename"
                          class="img-responsive"
                        /><br />
                      </div>
                      {{ question.question }}
                      <ul class="quiz-answers assessment-answers">
                        <li
                         
                          (click)="answerQuiz(question, 'yes', true)"
                        >
                          Yes
                        </li>
                        <li
                         
                          (click)="answerQuiz(question, 'no', true)"
                        >
                          No
                        </li>
                        <li
                         
                          (click)="answerQuiz(question, 'don\'t know', true)"
                        >
                          Don't Know
                        </li>
                      </ul>
                      <div
                        *ngIf="question.state == 'answered'"
                        style="margin-bottom: 100px"
                      >
                        <div>
                          <span *ngIf="question.correct">Correct.</span
                          ><span *ngIf="!question.correct">Incorrect.</span>
                        </div>
                        <div>
                          <div [innerHtml]="question.feedback | safeHtml"></div>
                        </div>
                        <button
                          class="btn btn-primary"
                          (click)="nextQuestion()"
                        >
                          <span *ngIf="qNum < +questions.length"
                            >Next Question</span
                          ><span *ngIf="qNum == +questions.length"
                            >View Results</span
                          >
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>

              <div
                ng-cloak
                ng-if="questions.length"
                *ngIf="qNum == +questions.length + 1"
                style="margin-bottom: 100px"
              >
                <div class="quiz-score">
                  You Scored...
                  <div class="quiz-score-total">
                    {{ score }} out of {{ questions.length }}
                  </div>
                  <div
                    [innerHtml]="quiz.feedback | safeHtml"
                    *ngIf="quiz.feedback"
                    class="quiz-feedback text-left"
                  ></div>
                  <a [routerLink]="['/dashboard']" class="btn btn-primary"
                    ><span *ngIf="prepost == 'pre'">GET STARTED</span>
                    <span *ngIf="prepost != 'pre'">DASHBOARD</span
                    ><span class="icon-wrap"
                    ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
                  ></span></a>
                </div>
              </div>
            </div>
          </div>

          <div *ngSwitchCase="'prepost:custom'">
            <div *ngIf="prepost == 'post'">
              <p>
                It's time to take the follow-up to the assessment that you took
                before the module
              </p>
            </div>
            <div [hidden]="complete">
              <div [innerHtml]="quiz.instructions | safeHtml"></div>
              <br /><br />
              <div class="text-center" [hidden]="started">
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="onStartQuiz()"
                >
                  START
                </button>
              </div>
              <div *ngIf="started" class="prepost-qs">
                <div *ngFor="let question of questions">
                  <div class="assessment-q-se">
                    <h4>{{ question.question }}</h4>
                    <ng-container *ngIf="question.options == 'freetext'">
                      <textarea name="q{{responses[question.assessment_question_id]}}" class="form-control" [(ngModel)]="responses[question.assessment_question_id]" rows="5" placeholder="Please enter a response"></textarea>
                    </ng-container>
                    <ng-container *ngIf="!question.options">
                      <ngx-slider
                      [(value)]="responses[question.assessment_question_id]"
                      [options]="options"
                    ></ngx-slider>
                    </ng-container>
                    

                  </div>
                </div>
                <div
                  class="text-center"
                  style="margin-bottom: 100px; padding-top: 20px"
                >
                  <p>
                    Please check your answers above carefully before submitting.
                  </p>
                  <button
                    type="button"
                    class="btn btn-primary"
                    (click)="submit()"
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
            <div [hidden]="!complete">
              <div class="text-center" style="margin-bottom: 100px">
                <h4>Thank you for your responses</h4>
                <a [routerLink]="['/dashboard']" class="btn btn-primary"
                  ><span *ngIf="prepost == 'pre'">CONTINUE</span>
                  <span *ngIf="prepost != 'pre'">DASHBOARD</span
                  ><span class="icon-wrap"
                  ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
                ></span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
