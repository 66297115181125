import { NgForm } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { BloodGlucoseService } from './../../services/blood-glucose.service';
import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { environment } from './../../../environments/environment';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-hba1c',
  templateUrl: './hba1c.component.html',
  styleUrls: ['./hba1c.component.scss'],
})
export class Hba1cComponent implements OnInit {
  currentUser;
  currentProgramme;
  userHbA1cs = [];
  userHbA1c;
  isFetching = false;
  errors = [];
  showAllRows = false;
  environment = environment;

  toggleRow = {
    update: false,
    graph: false,
    entry: false,
  };

  toggleChart = {
    bloodGlucose: true,
    veg: false,
    fruit: false,
  };

  unitMode = 'mmol/mol';

  entryDate = new Date();
  maxDate = new Date();

  @ViewChild('trackerForm', { static: false }) trackerForm: NgForm;

  // Line chart
  public chartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            beginAtZero: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          gridLines: {
            display: true,
            lineWidth: 1,
            color: '#95989A',
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            lineWidth: 2,
            color: '#95989A',
          },
          type: 'time',
          ticks: {
            autoSkip: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          time: {
            parser: 'YYYY-MM-DD HH:mm:ss',
            unit: 'day',
            displayFormats: {
              day: 'MMM-DD',
            },
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public chartLabels: Label[] = [];
  public chartType: ChartType = 'line';
  public chartLegend = true;

  public chartColors: Color[] = [{ backgroundColor: environment.primaryColor }];

  public bloodGlucoseChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'Blood Glucose',
      borderWidth: 1,
      borderColor: environment.chartDefaults.colors[0],
      fill: false,
      pointRadius: 2,
    },
  ];
  //
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private bloodGlucoseService: BloodGlucoseService
  ) {}

  ngOnInit(): void {
    this.isFetching = true;
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (this.currentProgramme) {
          // this.barChartColors[0].backgroundColor = this.currentProgramme.config.primaryColor;

          forkJoin({
            bloodGlucoses: this.bloodGlucoseService.fetchAll(),
          }).subscribe(
            (responseData) => {
              this.isFetching = false;
              this.userHbA1cs = responseData.bloodGlucoses;
              if (this.userHbA1cs.length > 0) {
                this.userHbA1c = this.userHbA1cs[0];
              }
              this.setChartData(this.userHbA1cs);
              // end
            },
            (error) => {
              this.isFetching = false;
              this.errors.push(error.message);
            }
          );
        }
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });
    }
  }

  onRemove(bloodGlucose) {
    this.bloodGlucoseService
      .delete(bloodGlucose.user_blood_glucose_id)
      .subscribe(
        (responseData) => {
          const index = this.userHbA1cs.indexOf(bloodGlucose);
          this.userHbA1cs.splice(index, 1);
          this.setChartData(this.userHbA1cs);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  onSubmit() {
    // this.trackerForm.value.date

    if (!this.trackerForm.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.bloodGlucoseService
        .create(
          this.trackerForm.value.glucose,
          this.trackerForm.value.glucosePercent,
          moment(this.entryDate).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
            this.userHbA1cs.unshift(responseData);
            this.userHbA1cs.sort(this.sortByCreated);
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.trackerForm.resetForm();
            this.entryDate = new Date();
            this.setChartData(this.userHbA1cs);
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  // end chart
  updatedToday() {
    let hasUpdated = false;
    this.userHbA1cs.forEach((bloodGlucose) => {
      if (
        moment(bloodGlucose.created).format('MM DD YY') ===
        moment().format('MM DD YY')
      ) {
        hasUpdated = true;
      }
    });
    return hasUpdated;
  }

  setChartData(trackerData) {
    this.bloodGlucoseChartData[0].data = [];
    this.chartLabels = [];
    // setup chart data
    trackerData.forEach((value, key) => {
      this.chartLabels.push(moment(value.created).format('YYYY-MM-DD'));
      this.bloodGlucoseChartData[0].data.push(value.glucose);
    });
  }

  updateVal() {
    // convert from percent..
    this.trackerForm.controls['glucose'].setValue(
      Math.round(
        (+this.trackerForm.controls['glucosePercent'].value - 2.15) *
          10.929 *
          10
      ) / 10
    );
    if (+this.trackerForm.controls['glucose'].value > 140) {
      this.trackerForm.controls['glucose'].setValue(140);
    }
  }

  updateValP() {
    // convert from percent..
    this.trackerForm.controls['glucosePercent'].setValue(
      Math.round(
        (0.0915 * this.trackerForm.controls['glucose'].value + 2.15) * 100
      ) / 100
    );
  }
  sortByCreated(a, b) {
    if (a.created > b.created) {
      return -1;
    }
    if (a.created < b.created) {
      return 1;
    }
    return 0;
  }
}
