import { environment } from 'src/environments/environment';
import { Component, Input, OnInit } from '@angular/core';
import { DndDropEvent } from 'ngx-drag-drop';

@Component({
  selector: 'app-step-drag-and-drop-default',
  templateUrl: './step-drag-and-drop-default.component.html',
  styleUrls: ['./step-drag-and-drop-default.component.scss'],
})
export class StepDragAndDropDefaultComponent implements OnInit {
  @Input() dragAndDrop;
  @Input() dragItems;
  @Input() dropItems;
  correct = 0;
  incorrect = 0;
  environment = environment;

  draggedTitle;
  draggedItem;
  qNum = 1;

  constructor() {}

  ngOnInit(): void {
    this.dropItems.forEach((dropItem, index) => {
      this.dropItems[index].dropped = [];
    });
    this.dragItems.forEach((dragItem, index) => {
      this.dragItems[index].dropped = false;
    });
  }

  startCallback(event) {
    console.log(event);
  }
  stopCallback(event) {}
  dragCallback(event) {}
  /*
  startCallback(event, ui, dragItem) {
    //console.log('You started draggin: ' + dragItem.text);
    this.draggedTitle = dragItem.text;
    this.draggedItem = dragItem;
  }
*/
  // add some classes here..
  onOver(e) {
    // angular.element(e.target).addClass('drag-item-over');
  }
  onOut(e) {
    // angular.element(e.target).removeClass('drag-item-dragging');
  }
  beforeDrop(event, dropItem) {
    console.log(event);

    const draggedItem = event.data;
    dropItem.dropped.push(draggedItem);
    draggedItem.dropped = true;
    draggedItem.state = 'dropped';
    this.dragItems.forEach((dItem, dIndex) => {
      if (dItem.drag_item_id === draggedItem.drag_item_id) {
        this.dragItems[dIndex].state = 'dropped';
      }
    });

    this.qNum++;
  }
  /*
  beforeDrop(evt, ui, dropItem) {
    let deferred = $q.defer();
    let _draggedItem = {};
    if (this.draggedItem != null) {
      _draggedItem = angular.extend(this.draggedItem);
      dropItem.dropped.push(_draggedItem);
      this.draggedItem.dropped = true;
      this.draggedItem.state = 'dropped';
      this.dragItems.forEach( (dItem, dIndex) => {
        if (dItem.drag_item_id == this.draggedItem.drag_item_id) {
          this.dragItems[dIndex].state = 'dropped';
        }
      });
    }
    deferred.resolve();

    this.qNum++;
    return deferred.promise;
  }
*/
  removeDropItem(drop_item_id, drag_item_id) {
    this.dragItems.forEach((dItem, dIndex) => {
      if (dItem.drag_item_id == drag_item_id) {
        this.dragItems[dIndex].state = 'default';
      }
    });

    this.dropItems.forEach((dropItem, index) => {
      if (drop_item_id == dropItem.drop_item_id) {
        dropItem.dropped.forEach( (dragItem, _index)=> {
          this.qNum = index * 1 + 1;
          //console.log(dragItem.drop_target_id + ' = ' + dropItem.drop_item_id);
          if (dragItem.drag_item_id == drag_item_id) {
            this.dropItems[index].dropped.splice(_index, 1);
          }
        });
      }
    });
  }
  submitDnD() {
    var correct = true;
    var thisCorrect = true;
    this.dropItems.forEach((dropItem, index) => {
      thisCorrect = true;
      dropItem.dropped.forEach((dragItem, _index) => {
        //console.log(dragItem.drop_target_id + ' = ' + dropItem.drop_item_id);
        if (dragItem.drop_target_id != dropItem.drop_item_id) {
          thisCorrect = false;
          correct = false;
        }
      });
      if (dropItem.dropped.length == 0) {
        thisCorrect = false;
      }

      if (thisCorrect == true) {
        this.dropItems[index].state = 'correct';
      } else {
        this.dropItems[index].state = 'incorrect';
      }
    });

    // have all the drag items been placed?
    this.dragItems.forEach((dragItem, index) => {
      if (
        dragItem.state != 'dropped' &&
        dragItem.drop_target_id != null &&
        dragItem.drop_target_id != 0
      ) {
        correct = false;
        // set the drop item to incorrect
        this.dropItems.forEach((dropItem, index) => {
          if (dropItem.drop_item_id == dragItem.drop_target_id) {
            this.dropItems[index].state = 'incorrect';
          }
        });
      }
    });

    this.dragAndDrop.state = 'answered';
    this.dragAndDrop.correct = correct;
  }

  setDDqNum(i) {
    this.qNum = i;
  }
}
