<div
class="mt-3 mb-3 tracker-chart">
  <canvas
    baseChart
    [datasets]="barChartData"
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [legend]="barChartLegend"
    [chartType]="barChartType"
    [colors]="barChartColors"
  >
  </canvas>
</div>
