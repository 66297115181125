import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class XsrfInterceptor implements HttpInterceptor {
  constructor(private tokenExtractor: HttpXsrfTokenExtractor) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let requestToForward = req;
    let token = this.tokenExtractor.getToken() as string;
    if (
      token !== null &&
      (req.method == 'POST' ||
        req.method == 'PUT' ||
        req.method == 'DELETE' ||
        req.method == 'PATCH')
    ) {
      requestToForward = req.clone({ setHeaders: { 'X-XSRF-TOKEN': token } });
    }
    return next.handle(requestToForward);
  }
}
