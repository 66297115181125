<app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
<div  *ngIf="section">
<nav class="subnav">
    <a *ngIf="prevSection" [routerLink]="['/educational-reading', 'section', prevSection.content_section_id]"  class="subnav-prev"><span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span></a>
    <h2><span class="num">{{programmeContentSection.sort_order}}</span> {{programmeContentSection.title | uppercase}}</h2>
    <a *ngIf="nextSection"  [routerLink]="['/educational-reading', 'section', nextSection.content_section_id]" class="subnav-next"><span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span></a>
</nav>
<!--img *ngIf="media" ng-src="{{'/assets/' + media.filename}}" class="img-responsive img-erh"-->
<div class="reading-material-overview">
    <h3>THIS SECTION COVERS</h3>
    <div [innerHTML]="section.overview | safeHtml"></div>
</div>
<nav class="section-nav">
    <ul>
        <li *ngFor="let page of pages" ><a   [routerLink]="['/educational-reading', 'page', page.content_page_id]"> {{page.title}}</a></li>
    </ul>
</nav>
    
</div>