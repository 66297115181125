<div *ngIf="quiz">
  <div [innerHTML]="quiz.instructions | safeHtml" *ngIf="qNum != +questions.length + 1"></div>
  <div>
    <div class="quiz-pager">
      <div class="quiz-pager-content">
        <div
          *ngFor="let question of questions; let index = index;"
          [ngClass]="{ 'current' : qNum === (index + 1)}"
        >
          <div
            [ngClass]="{'correct' :  question.correct, 'incorrect' : question.correct === false}"
          >
            <span class="icon-wrap fa fa-circle"><fa-icon [icon]="['fas', 'circle']"></fa-icon></span>
            <span class="icon-wrap fa fa-check-circle"><fa-icon [icon]="['fas', 'check-circle']"></fa-icon></span>
            <span class="icon-wrap fa fa-times-circle"><fa-icon [icon]="['fas', 'times-circle']"></fa-icon></span>
          </div>
        </div>
      </div>
    </div>
    <div *ngFor="let question of questions; let index = index">
      <div *ngIf="qNum == index + 1">
        Question {{ question.sort_order }}
        <hr class="quiz-divider" />
        <div class="text-center" *ngIf="question.image_url">
          <img
            [src]="environment.assetUrl + '/assets/' + question.image_url"
            class="img-responsive q-img"
          /><br />
        </div>
        {{ question.question }}
        <ul class="quiz-answers">
          <li
            [ngClass]="
              {
                'selected-correct' : answer.selectedState ==='selectedCorrect',
                'selected-incorrect' : answer.selectedState ==='selectedIncorrect',
               'unselected-correct' : answer.selectedState ==='unselectedCorrect',
                'unselected-incorrect' : answer.selectedState ==='unselectedIncorrect',
               'selected' : answer.selectedState ==='selected'
              }
            "
            *ngFor="let answer of question.answers; let index = index"
            (click)="answerQuiz(question, [answer], false)"
          >
            <span class="quiz-answer-alph"
              >{{ selectedCorrect }} {{ getLetter(index) | lowercase }}</span
            >
            <img
              *ngIf="answer.image_url"
              [src]="environment.assetUrl + '/assets/' + answer.image_url"
              class="a-img"
            />
            {{ answer.answer }}


            <span class="icon-wrap" >
              <fa-icon *ngIf="!answer.selectedState" [icon]="['fas', 'arrow-circle-right']"></fa-icon>
              <fa-icon *ngIf="answer.selectedState == 'selectedCorrect'" [icon]="['fas', 'check-circle']"></fa-icon>
              <fa-icon *ngIf="answer.selectedState == 'unselectedCorrect'" [icon]="['fas', 'check-circle']"></fa-icon>
              <fa-icon *ngIf="answer.selectedState == 'unselectedIncorrect'" [icon]="['fas', 'times-circle']"></fa-icon>
              <fa-icon *ngIf="answer.selectedState == 'selectedIncorrect'" [icon]="['fas', 'times-circle']"></fa-icon>
            </span>

          </li>
        </ul>
        <button
          class="btn btn-primary"
          (click)="answerQuiz(currQuestion, answersSelected, true)"
          *ngIf="showAnswerBtn"
        >
          Select Answers
        </button>
        <div *ngIf="question.state == 'answered'">
          <div>
            <span *ngIf="question.correct">Correct.</span
            ><span *ngIf="!question.correct">Incorrect.</span>
          </div>
          <div>
            <div [innerHTML]="question.feedback | safeHtml"></div>
          </div>
          <button class="btn btn-primary" (click)="nextQuestion()">
            <span *ngIf="qNum < +questions.length">Next Question</span
            ><span *ngIf="qNum == +questions.length">View Results</span>
          </button>
        </div>
      </div>
    </div>
    <div ng-cloak *ngIf="questions.length && qNum == +questions.length + 1">
      <div class="quiz-score">
        You Scored...
        <div class="quiz-score-total">
          {{ score }} out of {{ questions.length }}
        </div>
        <div
        [innerHTML]="quiz.feedback | safeHtml"
          *ngIf="quiz.feedback"
          class="quiz-feedback text-left"
        ></div>
      </div>
    </div>
  </div>
</div>
