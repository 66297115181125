<div
  *ngIf="currentProgramme"
  class="bg-full d-block d-sm-none"
  [ngStyle]="{
    'background-image':
      'url(' +
      environment.assetUrl +
      '/assets/' +
      currentProgramme.dashboard_bg +
      ')'
  }"
></div>
<div *ngIf="isFetching">
  <app-loading-spinner [inline]="1"></app-loading-spinner>
</div>
<div *ngIf="currentUser">
  <div class="container container-app dashboard-ep">
    <div>
      <div
        class="well well-default text-center d-block d-sm-none"
        *ngIf="
          !deviceSynced &&
          (currentUser.device == 'fitbit' ||
            currentUser.device == 'garmin' ||
            currentUser.device == 'google')
        "
      >
        <div class="masthead-strap">DEVICE CONNECTION ERROR</div>
        <p>
          We are having trouble syncing with your device. Please reload the page
          to try again
        </p>
        <p>
          If this problem continues to occur contact us via the technical
          support chat
        </p>
        <br />
        <div class="text-center">
          <a
            role="button"
            (click)="onReload()"
            class="btn btn-primary btn-lg mr-1"
          >
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'watch-fitness']"></fa-icon
            ></span>
            RELOAD</a
          >
        </div>
        <br /><br /><br />
      </div>

      <div
        class="well well-default text-center d-block d-sm-none"
        *ngIf="
          (!hideTrackerDB || currentUser.buddy || deviceSynced) &&
          currentUser.mobility3 != '0' &&
          currentUser.mobility1 != 'e' &&
          currentUser.mobility1 != 'f'
        "
      >
        <div>
          <div class="masthead home-masthead">
            <div
              *ngIf="
                !deviceSynced &&
                currentUser.device !== 'fitbit' &&
                currentUser.device !== 'garmin' &&
                currentUser.device !== 'google'
              "
            >
              <div *ngIf="!hideTrackerDB">
                <div class="masthead-strap">CONNECT YOUR DEVICE</div>
                <br />
                <div class="masthead-subtext">
                  <p class="text-left">
                    Setting up a device will ensure that the programme tailors'
                    physical activity specifically suited to you and your
                    ability.
                  </p>
                  <p class="text-left">
                    We are aiming to progress you to achieve the minimum
                    recommended steps for health. As little as 10 minutes of
                    brisk walking every day can increase your life expectancy by
                    ~4 years and as little as 500 steps per day can decreased
                    your risk of cardiovascular disease and even death
                  </p>
                  <p class="text-left">
                    If you do not have a device, there is also an option to
                    enter your steps manually
                  </p>
                </div>
                <br />
                <div class="text-center">
                  <a
                    role="button"
                    [routerLink]="['/setup-device']"
                    class="btn btn-primary btn-lg mr-1"
                  >
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['fas', 'watch-fitness']"></fa-icon
                    ></span>
                    SETUP DEVICE</a
                  >
                </div>
                <br /><br /><br />
              </div>
              <div
                *ngIf="
                  !deviceSynced &&
                  (currentUser.device == 'fitbit' ||
                    currentUser.device == 'garmin' ||
                    currentUser.device == 'google')
                "
              >
                <div class="masthead-strap">DEVICE CONNECTION ERROR</div>
                <p>
                  We are having trouble syncing with your device. Please reload
                  the page to try again
                </p>
                <br />
                <div class="text-center">
                  <a
                    role="button"
                    (click)="onReload()"
                    class="btn btn-primary btn-lg mr-1"
                  >
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['fas', 'watch-fitness']"></fa-icon
                    ></span>
                    RELOAD</a
                  >
                </div>
                <br /><br /><br />
              </div>
            </div>

            <!-------->

            <div
              *ngIf="
                !algorithmComplete &&
                currentUser.mobility3 != '0' &&
                currentUser.mobility1 != 'e' &&
                currentUser.mobility1 != 'f' &&
                deviceSynced
              "
            >
              <!---->
              <div class="container container-pad">
                <div class="row dashboard-row">
                  <div class="col-md-12">
                    <div
                      class="masthead masthead-home masthead-flex masthead-setup"
                    >
                      <div class="masthead-strap">ACTIVITY SETUP</div>

                      <div class="p-3" *ngIf="activitySetupStep == 1">
                        <div *ngIf="currentUser.manual_steps != 1">
                          <p class="text-left">Get Ready, Get Set, GO!</p>
                          <p class="text-left">
                            It is important that we know your current activity
                            levels, so carry on as normal and once you have
                            recorded your first 3 days of activity, Steps4Health
                            will generate your personalised stepping target and
                            all of the Steps4Health features will unlock.
                          </p>

                          <p class="text-left">
                            You will receive a notification via email (and phone
                            if android) once your personalised stepping target
                            is ready. You will then have access to all features
                            on the Steps4Health dashboard.
                          </p>
                        </div>

                        <div *ngIf="currentUser.manual_steps == 1">
                          <p class="text-left">
                            Now enter the total number of steps you perform
                            every day for the next 3 days. Three days will
                            ensure your starting step count is accurate, this
                            will be what we base your next walking goal on. You
                            will receive a notification via phone/email once
                            your personalised stepping goal is ready
                          </p>
                          <p class="text-left">Get Ready, Get Set, GO!</p>
                          <p class="text-left">
                            It is important that we know your current activity
                            levels, so carry on as normal and once you have
                            recorded your first 3 days of activity, Steps4Health
                            will generate your personalised stepping target and
                            all of the Steps4Health features will unlock.
                          </p>
                          <p class="text-left">
                            You will receive a notification via email (and phone
                            if android) once your personalised stepping target
                            is ready. You will then have access to all features
                            on the Steps4Health dashboard.
                          </p>
                        </div>

                        <button
                          class="btn btn-primary"
                          (click)="activitySetupStep = 2"
                        >
                          Next
                        </button>
                      </div>

                      <div class="p-3" *ngIf="activitySetupStep == 2">
                        <p class="text-left">
                          We can personalise your walking information even more
                          and give advice based your walking speed. Research
                          shows that walking speed can directly contribute to
                          living a longer healthier life, click below if you
                          would like your walking speed to be included
                        </p>
                        <div class="row">
                          <div class="col">
                            <button
                              class="btn btn-primary btn-sm"
                              (click)="onChooseWalkingSpeed(1)"
                            >
                              Yes, I would like advice on my walking speed
                            </button>
                          </div>
                          <div class="col">
                            <button
                              class="btn btn-primary btn-sm"
                              (click)="onChooseWalkingSpeed(0)"
                            >
                              No, steps only please
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="p-3" *ngIf="activitySetupStep == 3">
                        <div class="row">
                          <div class="col-md-4 offset-md-4">
                            <p class="text-center">
                              Please enter how many steps you walked in 1 minute
                            </p>
                            <form
                              name="speedForm"
                              class="bs-form tracker-form"
                              (ngSubmit)="onSubmitCadenceForm()"
                              #speedForm="ngForm"
                            >
                              <div class="input-group input-group-fa mb-2">
                                <span class="icon-wrap fa"
                                  ><fa-icon
                                    [icon]="['fas', 'shoe-prints']"
                                  ></fa-icon
                                ></span>
                                <input
                                  type="number"
                                  name="cadence"
                                  class="form-control input-tracker input-tracker-sm input-tracker-dashboard"
                                  placeholder="0"
                                  id="cadence"
                                  [(ngModel)]="cadence"
                                  #cadenceInputMob="ngModel"
                                  min="0"
                                  max="1000000000"
                                  autocomplete="off"
                                  ng-required=""
                                  style="text-align: left"
                                />
                              </div>

                              <button
                                role="button"
                                href="#"
                                class="btn btn-primary btn-lg btn-white"
                              >
                                SET WALKING SPEED
                              </button>
                            </form>
                            <p class="mt-3 mb-0">
                              How do I set my walking speed? Watch the animation
                              <a
                                [routerLink]="[
                                  '/learning-session-steps',
                                  'view',
                                  environment.walkingSpeedStepId
                                ]"
                                >here</a
                              >
                            </p>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="activitySetupStep == 4">
                        <div class="headline-flex">
                          <div class="headline-icon">
                            <span class="icon-wrap"
                              ><fa-icon
                                [icon]="['far', 'hourglass-half']"
                              ></fa-icon
                            ></span>
                          </div>
                          <div class="headline-text">
                            <strong>{{ algorithmDaysRemaining }}</strong>
                            <span class="headline-subtext"
                              >day<span *ngIf="algorithmDaysRemaining != 1"
                                >s</span
                              ><br />
                              Remaining</span
                            >
                          </div>
                        </div>

                        <div class="days days-activity">
                          <div
                            *ngFor="let day of algorithmDays"
                            class="day text-dark"
                          >
                            <span
                              class="icon-wrap fa fa-check-circle text-dark"
                              *ngIf="day.status"
                              ><fa-icon
                                [icon]="['fas', 'check-circle']"
                              ></fa-icon
                            ></span>
                            <span
                              class="icon-wrap fa fa-circle text-dark"
                              *ngIf="!day.status"
                              [ngClass]="{ 'not-hit': day.status === 0 }"
                              ><fa-icon
                                [icon]="['fas', 'circle']"
                              ></fa-icon></span
                            ><br />
                            {{ day.day | dateFormatter : "ddd" | uppercase }}
                          </div>
                        </div>

                        <div *ngIf="algorithmDaysRemaining > 0">
                          <p>
                            3 days of activity is required before the dashboard
                            is optimised
                          </p>
                          <a
                            role="button"
                            [routerLink]="['/steps']"
                            class="btn btn-primary"
                          >
                            <span class="icon-wrap"
                              ><fa-icon
                                [icon]="['fas', 'shoe-prints']"
                              ></fa-icon
                            ></span>
                            Steps</a
                          >
                          <a
                            *ngIf="!currentUser.cadence"
                            role="button"
                            (click)="activitySetupStep = 3"
                            class="btn btn-primary ml-1"
                          >
                            <span class="icon-wrap"
                              ><fa-icon [icon]="['fas', 'bullseye']"></fa-icon
                            ></span>
                            SET WALKING SPEED</a
                          >
                          <p *ngIf="!currentUser.cadence">
                            How do I set my walking speed? Watch the animation
                            <a
                              [routerLink]="[
                                '/learning-session-steps',
                                'view',
                                environment.walkingSpeedStepId
                              ]"
                              >here</a
                            >
                          </p>
                        </div>

                        <div *ngIf="algorithmDaysRemaining < 1">
                          <p>Your personalised walking goal is now ready</p>
                          <a
                            role="button"
                            [routerLink]="['/target-ep']"
                            class="btn btn-primary"
                          >
                            <span class="icon-wrap"
                              ><fa-icon [icon]="['fas', 'bullseye']"></fa-icon
                            ></span>
                            GENERATE TARGET</a
                          >
                          <!-- can they also set cadence? -->
                          <a
                            *ngIf="!currentUser.cadence"
                            role="button"
                            (click)="activitySetupStep = 3"
                            class="btn btn-primary ml-1"
                          >
                            <span class="icon-wrap"
                              ><fa-icon [icon]="['fas', 'bullseye']"></fa-icon
                            ></span>
                            SET WALKING SPEED</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- --->
            </div>

            <div
              *ngIf="
                deviceSynced &&
                algorithmComplete &&
                currentTarget &&
                +currentTarget > +currentActivity.steps &&
                currentUser.mobility3 != '0' &&
                currentUser.mobility1 != 'e' &&
                currentUser.mobility1 != 'f'
              "
            >
              <a
                *ngIf="newStepTargetAdvice"
                [routerLink]="['/target-ep']"
                class="notification notification-target"
                ><fa-icon [icon]="['fas', 'info']"></fa-icon
              ></a>
              <div class="masthead-figure masthead-figure-xb">
                <div class="masthead-icon">
                  <img
                    src="assets/img/icon-steps-2.svg"
                    width="81"
                    height="55"
                    class="icon-shoe"
                  />
                </div>
                {{ currentTarget - currentActivity.steps | number }}
              </div>
              <div class="masthead-strap">STEPS REMAINING TODAY</div>

              <div class="progress">
                <div
                  class="progress-bar progress-bar-accent"
                  role="progressbar"
                  [attr.aria-valuenow]="
                    (currentActivity.steps / currentTarget) * 100 | number
                  "
                  [attr.aria-valuemin]="0"
                  [attr.aria-valuemax]="100"
                  [ngStyle]="{
                    width: (currentActivity.steps / currentTarget) * 100 + '%'
                  }"
                >
                  <span class="sr-only"
                    >{{
                      (currentActivity.steps / currentTarget) * 100 | number
                    }}% Complete</span
                  >
                </div>
              </div>
              <p class="masthead-subtext">
                <strong>{{ currentActivity.steps | number }}</strong> of
                <strong>{{ currentTarget | number }}</strong> daily steps target
                completed
              </p>
            </div>

            <div
              *ngIf="
                deviceSynced &&
                currentTarget &&
                +currentTarget <= +currentActivity.steps
              "
            >
              <div class="masthead-figure masthead-figure-xb">
                <span class="icon-wrap hit-goal"
                  ><fa-icon [icon]="['fas', 'star']"></fa-icon
                ></span>
                {{ currentActivity.steps | number
                }}<span class="masthead-strap"> STEPS</span>
              </div>
              <div class="masthead-strap">CONGRATULATIONS!</div>
              <p class="masthead-subtext">
                You have hit your
                <strong>{{ currentTarget | number }}</strong> daily steps target
              </p>
            </div>
          </div>

          <div
            class="row row-btns-duo"
            *ngIf="
              deviceSynced &&
              currentTarget &&
              currentUser.mobility3 != '0' &&
              currentUser.mobility1 != 'e'&&
              currentUser.mobility1 != 'f'
            "
          >
            <ng-container *ngIf="currentProgramme.config.stepBands">
              <div class="col-6">
                <a
                  role="button"
                  [routerLink]="['/steps']"
                  class="btn btn-primary btn-lg btn-block"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'shoe-prints']"></fa-icon
                  ></span>
                  MY STEPS</a
                >
              </div>
              <div class="col-6">
                <a
                  *ngIf="!currentUser.buddy && currentUser.manual_steps != 1"
                  role="button"
                  [routerLink]="['/challenges-individual']"
                  class="btn btn-primary btn-lg btn-block"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'trophy']"></fa-icon
                  ></span>
                  CHALLENGES</a
                >
                <a
                  *ngIf="currentUser.buddy && currentUser.manual_steps != 1"
                  role="button"
                  [routerLink]="['/friends-and-family']"
                  class="btn btn-primary btn-lg btn-block"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'trophy']"></fa-icon
                  ></span>
                  CHALLENGES</a
                >
                <a
                  *ngIf="currentUser.manual_steps == 1"
                  role="button"
                  [routerLink]="['/steps']"
                  ng-click="rowUDS.booleanVal=true"
                  class="btn btn-primary btn-lg"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'edit']"></fa-icon
                  ></span>
                  UPDATE STEPS</a
                >
              </div>
            </ng-container>

            <ng-container
              *ngIf="
                !currentProgramme.config.stepBands &&
                currentUser.mobility3 != '0' &&
                currentUser.mobility1 != 'e'&&
                currentUser.mobility1 != 'f'
              "
            >
              <div class="col-8 col-offset-2">
                <a
                  role="button"
                  [routerLink]="['/steps']"
                  class="btn btn-primary btn-lg btn-block"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'shoe-prints']"></fa-icon
                  ></span>
                  MY STEPS</a
                >
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <!-- aside widgets -->
      <div
        class="container container-app container-rm container-rm-ep"
        *ngIf="
          algorithmComplete ||
          currentUser.mobility3 == '0' ||
          currentUser.mobility1 == 'f'
        "
      >
        <div class="row">
          <div class="col-6">
            <div
              class="well well-default text-center d-block d-sm-none"
              *ngIf="!hideTrackerDB || currentUser.buddy || deviceSynced"
            >
              <div class="masthead home-masthead home-masthead-sub">
                <h3>ACTIVE MINUTES REMAINING</h3>
                <div *ngIf="currentActivity && currentTargetMins">
                  <div
                    *ngIf="
                      (+currentTargetMins > +currentActivity.minutes &&
                        currentTargetMinsUnit != 'week') ||
                      (+currentTargetMins > +minsWeekTotal &&
                        currentTargetMinsUnit == 'week')
                    "
                  >
                    <div class="masthead-figure masthead-figure-xb">
                      <div class="masthead-icon">
                        <img
                          src="assets/img/icon-minutes-2.svg"
                          width="60"
                          class="icon-shoe"
                        />
                      </div>

                      <ng-container *ngIf="currentTargetMinsUnit != 'week'">{{
                        currentTargetMins - currentActivity.minutes | number
                      }}</ng-container>
                      <ng-container *ngIf="currentTargetMinsUnit == 'week'">{{
                        currentTargetMins - minsWeekTotal | number
                      }}</ng-container>
                    </div>
                    <ng-container *ngIf="currentTargetMinsUnit != 'week'">
                      <div class="progress">
                        <div
                          class="progress-bar progress-bar-accent"
                          role="progressbar"
                          aria-valuenow="
                    (currentActivity.minutes / currentTargetMins) * 100 | number
                  "
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style="width: {{
                            (currentActivity.minutes / currentTargetMins) * 100
                              | number
                          }}%;"
                        >
                          <span class="sr-only"
                            >{{
                              (currentActivity.minutes / currentTargetMins) *
                                100 | number
                            }}% Complete</span
                          >
                        </div>
                      </div>
                      <p class="masthead-subtext">
                        <strong>{{ currentActivity.minutes | number }}</strong>
                        of
                        <strong>{{ currentTargetMins | number }}</strong> daily
                        minutes completed
                      </p>
                    </ng-container>
                    <ng-container *ngIf="currentTargetMinsUnit == 'week'">
                      <div class="progress">
                        <div
                          class="progress-bar progress-bar-accent"
                          role="progressbar"
                          aria-valuenow="
                    (minsWeekTotal / currentTargetMins) * 100 | number
                  "
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style="width: {{
                            (minsWeekTotal / currentTargetMins) * 100 | number
                          }}%;"
                        >
                          <span class="sr-only"
                            >{{
                              (minsWeekTotal / currentTargetMins) * 100
                                | number
                            }}% Complete</span
                          >
                        </div>
                      </div>
                      <p class="masthead-subtext">
                        <strong>{{ minsWeekTotal | number }}</strong> of
                        <strong>{{ currentTargetMins | number }}</strong> weekly
                        minutes completed
                      </p>
                    </ng-container>
                  </div>
                  <div
                    *ngIf="
                      ((+currentTargetMins <= +currentActivity.minutes &&
                        currentTargetMinsUnit != 'week') ||
                        (+currentTargetMins <= +minsWeekTotal &&
                          currentTargetMinsUnit == 'week')) &&
                      +currentTargetMins
                    "
                  >
                    <div class="masthead-figure masthead-figure-xb">
                      <span class="icon-wrap hit-goal"
                        ><fa-icon [icon]="['fas', 'star']"></fa-icon></span
                      ><span *ngIf="currentTargetMinsUnit != 'week'">{{
                        currentActivity.minutes | number
                      }}</span>
                      <span *ngIf="currentTargetMinsUnit == 'week'">{{
                        minsWeekTotal | number
                      }}</span
                      ><span class="masthead-strap masthead-strap-sm"> </span>
                    </div>
                    <div class="masthead-strap">CONGRATULATIONS!</div>
                    <p class="masthead-subtext">
                      You have hit your
                      <strong>{{ currentTargetMins | number }}</strong>
                      <span *ngIf="currentTargetMinsUnit != 'week'"> daily</span
                      ><span *ngIf="currentTargetMinsUnit == 'week'">
                        weekly</span
                      >
                      minutes target
                    </p>
                  </div>
                </div>
                <div *ngIf="!currentActivity && currentTargetMins">
                  <p class="masthead-subtext">No minute data yet</p>
                </div>
                <div *ngIf="!currentTargetMins">
                  <p class="masthead-subtext">No minute target set yet</p>
                  <a
                    role="button"
                    [routerLink]="['/activity', 'minutes']"
                    class="btn btn-primary mb-1"
                  >
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['far', 'stopwatch']"></fa-icon
                    ></span>
                    SET TARGET</a
                  >
                </div>
                <a
                  *ngIf="currentTargetMins"
                  role="button"
                  [routerLink]="['/activity', 'minutes']"
                  class="btn btn-primary"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['far', 'stopwatch']"></fa-icon
                  ></span>
                  MY MINUTES</a
                >
              </div>
            </div>
          </div>
          <div class="col-6">
            <div
              class="well well-default text-center d-block d-sm-none"
              *ngIf="!hideTrackerDB || currentUser.buddy || deviceSynced"
            >
              <div class="masthead-home masthead-steps">
                
          <div *ngIf="currentUser.device == 'fitbit'">
            <h3>TODAY'S INACTIVITY</h3>
            <div *ngIf="currentActivity && currentTargetSitting">
              <div>
                <div
                  class="masthead-figure masthead-figure-xb masthead-sitting-stats mt-1"
                >
                  <div class="masthead-icon">
                    <img
                      src="assets/img/icon-sitting-2.svg"
                      height="64"
                      class="icon-shoe"
                    />
                  </div>
                  <div class="masthead-sitting-figure">
                    <div>
                      {{
                        +currentActivity.sedentary_minutes
                          | minsToHours : "hoursOnly"
                      }}
                      <div>HOURS</div>
                    </div>
                    <div>
                      {{
                        +currentActivity.sedentary_minutes
                          | minsToHours : "minutesOnly"
                      }}
                      <div>MINS</div>
                    </div>
                  </div>
                </div>

                <p
                  class="masthead-subtext masthead-subtext-sitting"
                  *ngIf="
                    +currentActivity.sedentary_minutes <= currentTargetSitting
                  "
                >
                  {{
                    +currentTargetSitting - +currentActivity.sedentary_minutes
                      | minsToHours
                  }}
                  sitting time before you exceed your target
                </p>
                <p
                  class="masthead-subtext masthead-subtext-sitting"
                  *ngIf="
                    +currentActivity.sedentary_minutes > currentTargetSitting
                  "
                >
                  You have exceeded your target sitting time by
                  {{
                    (+currentTargetSitting -
                      +currentActivity.sedentary_minutes) *
                      -1 | minsToHours
                  }}
                </p>
                <br />
              </div>
            </div>
          </div>

          <!-- non fitbit -->
          <div *ngIf="currentUser.device != 'fitbit'">
            <h3>
              <span
                *ngIf="
                  !(
                    currentUser.mobility1 == 'e' || currentUser.mobility1 == 'f'
                  )
                "
                >SITTING TIME TODAY</span
              ><span
                *ngIf="
                  currentUser.mobility1 == 'e' || currentUser.mobility1 == 'f'
                "
                >SEDENTARY BEHAVIOUR</span
              >
            </h3>
            <div *ngIf="currentActivity && currentTargetSitting">
              <div>
                <div
                  class="masthead-figure masthead-figure-xb masthead-sitting-stats mt-1"
                >
                  <div class="masthead-icon">
                    <img
                      src="assets/img/icon-sitting-2.svg"
                      height="71"
                      class="icon-shoe"
                    />
                  </div>
                  <div class="masthead-sitting-figure">
                    <div>
                      {{
                        +currentActivity.sedentary_minutes
                          | minsToHours : "hoursOnly"
                      }}
                      <div>HOURS</div>
                    </div>
                    <div>
                      {{
                        +currentActivity.sedentary_minutes
                          | minsToHours : "minutesOnly"
                      }}
                      <div>MINS</div>
                    </div>
                  </div>
                </div>

                <p
                  class="masthead-subtext masthead-subtext-sitting"
                  *ngIf="
                    +currentActivity.sedentary_minutes <= currentTargetSitting
                  "
                  style="font-size: 13px"
                >
                  {{
                    +currentTargetSitting - +currentActivity.sedentary_minutes
                      | minsToHours : "hoursOnly"
                  }}h
                  {{
                    +currentTargetSitting - +currentActivity.sedentary_minutes
                      | minsToHours : "minutesOnly"
                  }}m left of your max
                  {{ +currentTargetSitting | minsToHours : "hoursOnly" }}h
                  {{ +currentTargetSitting | minsToHours : "minutesOnly" }}m
                  daily sitting time recommended
                </p>
                <p
                  class="masthead-subtext masthead-subtext-sitting"
                  *ngIf="
                    +currentActivity.sedentary_minutes > currentTargetSitting
                  "
                >
                  You have exceeded your daily target of less than
                  {{ +currentTargetSitting | minsToHours : "hoursOnly" }}h
                  {{ +currentTargetSitting | minsToHours : "minutesOnly" }}m
                </p>
              </div>
            </div>
          </div>

          <!-- end non fitbit -->
          <div *ngIf="!currentActivity && currentTargetSitting">
            <p class="masthead-subtext">No data yet</p>
          </div>
          <div *ngIf="!currentTargetSitting">
            <p class="masthead-subtext">No target set yet</p>
            <a
              role="button"
              [routerLink]="['/activity', 'sitting']"
              class="btn btn-primary"
            >
              <span class="icon-wrap"
                ><fa-icon [icon]="['far', 'stopwatch']"></fa-icon
              ></span>
              SET TARGET</a
            >
          </div>
          <a
            *ngIf="currentTargetSitting"
            role="button"
            [routerLink]="['/activity', 'sitting']"
            class="btn btn-primary"
          >
            <span class="icon-wrap"
              ><fa-icon [icon]="['far', 'couch']"></fa-icon
            ></span>
            MY SITTING</a
          >

              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <!-- Workouts -->
            <div
              class="well well-default text-center d-block d-sm-none"
              style="width: 100%; position: relative"
              *ngIf="!hideTrackerDB || currentUser.buddy || deviceSynced"
            >
              <div
                class="masthead masthead-home masthead-steps masthead-strength masthead-xs widget-s4h"
              >
                <h3>WORKOUTS COMPLETED</h3>
                <div *ngIf="currentWeek && currentWorkoutTarget">
                  <div class="widget-s4h-details">
                    <div class="widget-s4h-icon">
                      <img src="./assets/img/icon-workouts-2.svg" height="68" />
                    </div>

                    <div class="widget-s4h-figure">
                      {{ workoutDaysHit }}
                      <div class="widget-s4h-subfigure">&nbsp;</div>
                    </div>
                  </div>

                  <div class="clear-headline"></div>
                  <p
                    class="masthead-subtext mt-4"
                    *ngIf="+workoutDaysHit < +currentWorkoutTarget.target"
                  >
                    {{
                      +currentWorkoutTarget.target - +workoutDaysHit
                    }}
                    workouts left to hit your weekly target of
                    {{ currentWorkoutTarget.target }}
                  </p>
                  <p
                    class="masthead-subtext mt-4"
                    *ngIf="+workoutDaysHit >= +currentWorkoutTarget.target"
                  >
                    You have hit your workout target this week
                  </p>
                  <a
                    role="button"
                    [routerLink]="['/activity', 'workouts']"
                    class="btn btn-primary"
                  >
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['fas', 'running']"></fa-icon
                    ></span>
                    MY WORKOUTS</a
                  >
                </div>
                <div *ngIf="!currentWorkoutTarget">
                  <p class="masthead-subtext">No target set yet</p>
                  <a
                    role="button"
                    [routerLink]="['/activity', 'workouts']"
                    class="btn btn-primary"
                  >
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['fas', 'running']"></fa-icon
                    ></span>
                    SET TARGET</a
                  >
                </div>
              </div>
            </div>
            <!-- end workouts -->
          </div>
          <div class="col-6">
            <div
              class="well well-default text-center d-block d-sm-none"
              style="width: 100%; position: relative"
              *ngIf="!hideTrackerDB || currentUser.buddy || deviceSynced"
            >
              <div class="masthead masthead-strength masthead-sm widget-s4h">
                <h3><span>SLEEP TARGET ACHIEVED</span></h3>

                <div *ngIf="currentWeek">
                  <div class="widget-s4h-details">
                    <div class="widget-s4h-icon">
                      <img src="./assets/img/icon-sleep-2.svg" width="60" />
                    </div>

                    <div class="widget-s4h-figure">
                      {{ sleepDaysHit }}
                      <div class="widget-s4h-subfigure">Nights</div>
                    </div>
                  </div>

                  <div class="clear-headline"></div>

                  <p class="masthead-subtext mt-4">
                    {{ nightsLeftThisWeek }} nights left this week to hit your
                    sleep target of {{ getAgeSleep(age) }} hours a night
                  </p>
                </div>
                <div *ngIf="!currentActivity">
                  <p class="masthead-subtext">No data yet</p>
                </div>
                <a
                  role="button"
                  [routerLink]="['/activity', 'sleep']"
                  class="btn btn-primary"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['far', 'alarm-clock']"></fa-icon
                  ></span>
                  MY SLEEP</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end -->

      <!--  learning content tile -->
      <div
        class="well well-default text-center d-block d-sm-none"
        *ngIf="
          !currentUser.buddy &&
          hideTrackerDB &&
          (!deviceSynced || deviceSynced === null)
        "
      >
        <div class="masthead masthead-dashboard">
          <div class="masthead-figure masthead-figure-xb">
            <div class="masthead-icon">
              <span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'graduation-cap']"></fa-icon
              ></span>
            </div>
            {{ learningSessionProgressTotal | number : "1.0-0" }}%
          </div>
          <div class="masthead-strap">PAGES VIEWED</div>

          <div class="progress">
            <div
              class="progress-bar progress-bar-accent"
              role="progressbar"
              [attr.aria-valuenow]="learningSessionProgressTotal | number"
              aria-valuemin="0"
              aria-valuemax="100"
              style="width: {{ learningSessionProgressTotal | number }}%;"
            >
              <span class="sr-only"
                >{{ learningSessionProgressTotal | number }}% Complete</span
              >
            </div>
          </div>
          <div *ngIf="lastViewed && lastViewedPage">
            <p
              class="masthead-subtext"
              *ngIf="
                lastViewed && lastViewedPage.parent.type == 'learningSession'
              "
            >
              <strong>Last Read:</strong>
              {{ lastViewedPage.parent.booster_session_title }}
            </p>
          </div>
        </div>

        <div class="row" *ngIf="lastViewed && lastViewedPage">
          <div class="col-8 offset-2">
            <a
              class="btn btn-primary btn-lg btn-block"
              [routerLink]="[
                '/learning-session-steps',
                'view',
                lastViewedPage.booster_session_step_id
              ]"
              *ngIf="
                lastViewed.booster_session_id &&
                lastViewedPage.parent.type == 'learningSession'
              "
              >CONTINUE</a
            >
          </div>
        </div>
      </div>
      <!-- end lct -->

      <!-- end DB-->
    </div>

    <!-- last viewed test -->
    <!--div
      *ngIf="lastViewed && lastViewedPage && newAlerts.length === 0"
      class="d-block d-sm-none"
    >
     
      <div class="mt-2">
        <div
          *ngIf="
            currentProgramme.config.certificate &&
            learningSessionTotals.completed > 0 &&
            learningSessionTotals.completed ==
              learningSessionTotals.available +
                learningSessionTotals.upcoming &&
            boosterSessionTotals.completed ==
              boosterSessionTotals.available + boosterSessionTotals.upcoming
          "
        >
          <div class="well well-notification">
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'trophy']"></fa-icon
            ></span>
            <div class="notification-content">
              <div class="notification-title">CERTIFICATE OF COMPLETION</div>
              <a
                class="btn btn-primary"
                style="margin-left: 10px"
                href="/download-certificate/{{ currentProgramme.programme_id }}"
                >DOWNLOAD</a
              >
              <div class="notification-text" style="min-height: 38px">
                You have completed all the Learning Sessions and Booster
                Sessions
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="
            !(
              currentProgramme.config.certificate &&
              learningSessionTotals.completed > 0 &&
              learningSessionTotals.completed ==
                learningSessionTotals.available +
                  learningSessionTotals.upcoming &&
              boosterSessionTotals.completed ==
                boosterSessionTotals.available + boosterSessionTotals.upcoming
            ) &&
            lastViewed &&
            lastViewedPage
          "
        >
          <div class="well well-notification" *ngIf="lastViewedPage">
            <span
              class="icon-wrap"
              *ngIf="
                lastViewed && lastViewedPage.parent.type == 'boosterSession'
              "
              ><fa-icon [icon]="['fas', 'graduation-cap']"></fa-icon
            ></span>
            <span
              class="icon-wrap"
              *ngIf="lastViewedPage.parent.type == 'learningSession'"
              ><fa-icon [icon]="['fas', 'bolt']"></fa-icon
            ></span>
            <span
              class="icon-wrap"
              *ngIf="
                lastViewedPage.parent.type != 'learningSession' &&
                lastViewedPage.parent.type != 'boosterSession'
              "
              ><fa-icon [icon]="['fas', 'book']"></fa-icon
            ></span>

            <div class="notification-content">
              <div class="notification-title">LAST VIEWED</div>
              <a
                class="btn btn-primary"
                style="margin-left: 10px"
                [routerLink]="[
                  '/booster-session-steps',
                  'view',
                  lastViewedPage.booster_session_step_id
                ]"
                *ngIf="
                  lastViewed.booster_session_id &&
                  lastViewedPage.parent.type == 'boosterSession'
                "
                >VIEW</a
              >
              <a
                class="btn btn-primary"
                style="margin-left: 10px"
                [routerLink]="[
                  '/learning-session-steps',
                  'view',
                  lastViewedPage.booster_session_step_id
                ]"
                *ngIf="
                  lastViewed.booster_session_id &&
                  lastViewedPage.parent.type == 'learningSession'
                "
                >VIEW</a
              >
              <a
                class="btn btn-primary"
                style="margin-left: 10px"
                [routerLink]="[
                  '/educational-reading',
                  'page',
                  lastViewedPage.content_page_id
                ]"
                *ngIf="lastViewed.content_section_id"
                >VIEW</a
              >

              <a
                class="btn btn-primary"
                style="margin-left: 10px"
                [routerLink]="[
                  '/learning-session-steps',
                  'view',
                  lastViewedPage.booster_session_step_id
                ]"
                *ngIf="
                  !lastViewedPage.content_page_id &&
                  !lastViewedPage.parent.type 
                "
                >VIEW</a
              >
              <div
                class="notification-text"
                style="min-height: 38px"
                *ngIf="
                  lastViewed.booster_session_id &&
                  lastViewedPage.parent.type == 'boosterSession'
                "
              >
                {{ lastViewedPage.parent.booster_session_title }} &gt;
                {{ lastViewedPage.title }}
              </div>
              <div
                class="notification-text"
                style="min-height: 38px"
                *ngIf="
                  lastViewed.booster_session_id &&
                  lastViewedPage.parent.type == 'learningSession'
                "
              >
                {{ lastViewedPage.parent.booster_session_title }} &gt;
                {{ lastViewedPage.title }}
              </div>
              <div
                class="notification-text"
                style="min-height: 38px"
                *ngIf="lastViewed.content_section_id"
              >
                {{ lastViewedPage.parent.content_section_title }} &gt;
                {{ lastViewedPage.title }}
              </div>
              <div
                class="notification-text"
                style="min-height: 38px"
                *ngIf="!lastViewedPage.parent.type && !lastViewed.content_section_id"
              >
                {{ lastViewedPage.title }}
              </div>
            </div>
          </div>
        </div-->
  </div>
</div>
<!-- Desktop -->
<div class="d-none d-sm-block" *ngIf="currentUser">
  <nav
    class="subnav ng-scope subnav-db"
    [hidden]="!deviceSynced && hideTrackerDB"
  >
    <h2>
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'walking']"></fa-icon
      ></span>
      ACTIVITY
    </h2>
  </nav>

  <div
    class="well well-default text-center"
    *ngIf="
      !deviceSynced &&
      (currentUser.device == 'fitbit' ||
        currentUser.device == 'garmin' ||
        currentUser.device == 'google')
    "
  >
    <div class="masthead-strap">DEVICE CONNECTION ERROR</div>
    <p>
      We are having trouble syncing with your device. Please reload the page to
      try again
    </p>
    <p>
      If this problem continues to occur contact us via the technical support
      chat
    </p>
    <br />
    <div class="text-center">
      <a role="button" (click)="onReload()" class="btn btn-primary btn-lg mr-1">
        <span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'watch-fitness']"></fa-icon
        ></span>
        RELOAD</a
      >
    </div>
    <br /><br /><br />
  </div>

  <div *ngIf="!hideTrackerDB">
    <div
      class="row dashboard-row"
      *ngIf="
        !deviceSynced &&
        currentUser.manual_steps != 1 &&
        currentUser.device !== 'fitbit' &&
        currentUser.device !== 'garmin' &&
        currentUser.device !== 'google'
      "
    >
      <div class="col-md-12">
        <div class="no-tracker bg-accent masthead-db">
          <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'exclamation-circle']"></fa-icon
          ></span>
          <h3>PLEASE SETUP YOUR FITNESS TRACKER</h3>
          <div class="p-3">
            <p class="text-left">
              Setting up a device will ensure that the programme tailors'
              physical activity specifically suited to you and your ability.
            </p>
            <p class="text-left">
              We are aiming to progress you to achieve the minimum recommended
              steps for health. As little as 10 minutes of brisk walking every
              day can increase your life expectancy by ~4 years and as little as
              500 steps per day can decreased your risk of cardiovascular
              disease and even death
            </p>
            <p class="text-left">
              If you do not have a device, there is also an option to enter your
              steps manually
            </p>
          </div>

          <a
            role="button"
            [routerLink]="['/setup-device']"
            class="btn btn-primary mr-1"
          >
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'watch-fitness']"></fa-icon
            ></span>
            SETUP DEVICE</a
          >
          <a
            role="button"
            [routerLink]="['/setup-device']"
            class="btn btn-primary ml-1"
          >
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'hand-pointer']"></fa-icon
            ></span>
            MANUAL</a
          >
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!algorithmComplete && deviceSynced">
    <!---->
    <div class="container container-pad">
      <div class="row dashboard-row">
        <div class="col-md-12">
          <div
            class="masthead masthead-accent masthead-db masthead-flex masthead-setup"
          >
            <h3>ACTIVITY SETUP</h3>

            <div class="p-3" *ngIf="activitySetupStep == 1">
              <div *ngIf="currentUser.manual_steps != 1">
                <p class="text-left">Get Ready, Get Set, GO!</p>
                <p class="text-left">
                  It is important that we know your current activity levels, so
                  carry on as normal and once you have recorded your first 3
                  days of activity, Steps4Health will generate your personalised
                  stepping target and all of the Steps4Health features will
                  unlock.
                </p>

                <p class="text-left">
                  You will receive a notification via email (and phone if
                  android) once your personalised stepping target is ready. You
                  will then have access to all features on the Steps4Health
                  dashboard.
                </p>
              </div>

              <div *ngIf="currentUser.manual_steps == 1">
                <p class="text-left">
                  Now enter the total number of steps you perform every day for
                  the next 3 days. Three days will ensure your starting step
                  count is accurate, this will be what we base your next walking
                  goal on. You will receive a notification via phone/email once
                  your personalised stepping goal is ready
                </p>
                <p class="text-left">Get Ready, Get Set, GO!</p>
                <p class="text-left">
                  It is important that we know your current activity levels, so
                  carry on as normal and once you have recorded your first 3
                  days of activity, Steps4Health will generate your personalised
                  stepping target and all of the Steps4Health features will
                  unlock.
                </p>

                <p class="text-left">
                  You will receive a notification via email (and phone if
                  android) once your personalised stepping target is ready. You
                  will then have access to all features on the Steps4Health
                  dashboard.
                </p>
              </div>

              <button class="btn btn-primary" (click)="activitySetupStep = 2">
                Next
              </button>
            </div>

            <div class="p-3" *ngIf="activitySetupStep == 2">
              <p class="text-left">
                We can personalise your walking information even more and give
                advice based your walking speed. Research shows that walking
                speed can directly contribute to living a longer healthier life,
                click below if you would like your walking speed to be included
              </p>
              <button class="btn btn-primary" (click)="onChooseWalkingSpeed(1)">
                Yes, I would like advice on my walking speed
              </button>
              &nbsp; &nbsp;
              <button class="btn btn-primary" (click)="onChooseWalkingSpeed(0)">
                No, steps only please
              </button>
            </div>

            <div class="p-3" *ngIf="activitySetupStep == 3">
              <div class="row">
                <div class="col-md-4 offset-md-4">
                  <p class="text-center">
                    Please enter how many steps you walked in 1 minute
                  </p>
                  <form
                    name="speedForm"
                    class="bs-form tracker-form"
                    (ngSubmit)="onSubmitCadenceForm()"
                    #speedForm="ngForm"
                  >
                    <div class="input-group input-group-fa">
                      <span class="icon-wrap fa"
                        ><fa-icon [icon]="['fas', 'shoe-prints']"></fa-icon
                      ></span>
                      <input
                        type="number"
                        name="cadence"
                        class="form-control input-tracker input-tracker-sm input-tracker-dashboard"
                        placeholder="0"
                        id="cadence"
                        [(ngModel)]="cadence"
                        #cadenceInput="ngModel"
                        min="0"
                        max="1000000000"
                        autocomplete="off"
                        ng-required=""
                        style="text-align: left"
                      />
                    </div>

                    <button
                      role="button"
                      href="#"
                      class="btn btn-primary btn-lg btn-white"
                    >
                      SET WALKING SPEED
                    </button>
                  </form>
                  <p class="mt-3 mb-0">
                    How do I set my walking speed? Watch the animation
                    <a
                      [routerLink]="[
                        '/learning-session-steps',
                        'view',
                        environment.walkingSpeedStepId
                      ]"
                      >here</a
                    >
                  </p>
                </div>
              </div>
            </div>

            <div *ngIf="activitySetupStep == 4">
              <div class="headline-flex">
                <div class="headline-icon">
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['far', 'hourglass-half']"></fa-icon
                  ></span>
                </div>
                <div class="headline-text">
                  <strong>{{ algorithmDaysRemaining }}</strong>
                  <span class="headline-subtext"
                    >day<span *ngIf="algorithmDaysRemaining != 1">s</span><br />
                    Remaining</span
                  >
                </div>
              </div>

              <div class="days days-activity">
                <div *ngFor="let day of algorithmDays" class="day">
                  <span class="icon-wrap fa fa-check-circle" *ngIf="day.status"
                    ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
                  ></span>
                  <span
                    class="icon-wrap fa fa-circle"
                    *ngIf="!day.status"
                    [ngClass]="{ 'not-hit': day.status === 0 }"
                    ><fa-icon [icon]="['fas', 'circle']"></fa-icon></span
                  ><br />
                  {{ day.day | dateFormatter : "ddd" | uppercase }}
                </div>
              </div>

              <div *ngIf="algorithmDaysRemaining > 0">
                <p>
                  3 days of activity is required before the dashboard is
                  optimised
                </p>
                <a
                  role="button"
                  [routerLink]="['/steps']"
                  class="btn btn-primary"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'shoe-prints']"></fa-icon
                  ></span>
                  Steps</a
                ><a
                  *ngIf="!currentUser.cadence"
                  role="button"
                  (click)="activitySetupStep = 3"
                  class="btn btn-primary ml-1"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'bullseye']"></fa-icon
                  ></span>
                  SET WALKING SPEED</a
                >
              </div>

              <div *ngIf="algorithmDaysRemaining < 1">
                <p>Your personalised walking goal is now ready</p>
                <a
                  role="button"
                  [routerLink]="['/target-ep']"
                  class="btn btn-primary"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'bullseye']"></fa-icon
                  ></span>
                  GENERATE TARGET</a
                >
                <a
                  *ngIf="!currentUser.cadence"
                  role="button"
                  (click)="activitySetupStep = 3"
                  class="btn btn-primary ml-1"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'bullseye']"></fa-icon
                  ></span>
                  SET WALKING SPEED</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- --->
  </div>

  <div *ngIf="algorithmComplete">
    <div
      class="row dashboard-row"
      *ngIf="deviceSynced || currentUser.manual_steps == 1"
    >
      <div
        class="col-lg-6 col-6 mb-2"
        *ngIf="currentUser.mobility3 != '0' && currentUser.mobility1 != 'e' && currentUser.mobility1 != 'f'"
      >
        <div class="masthead masthead-accent masthead-db masthead-steps">
          <a
            *ngIf="newStepTargetAdvice"
            [routerLink]="['/target-ep']"
            class="notification notification-target"
            ><fa-icon [icon]="['fas', 'info']"></fa-icon
          ></a>
          <h3 *ngIf="+currentTarget > +currentActivity.steps">
            STEPS REMAINING
          </h3>
          <h3 *ngIf="+currentTarget <= +currentActivity.steps">STEPS TODAY</h3>
          <div *ngIf="currentActivity && currentTarget">
            <div *ngIf="+currentTarget > +currentActivity.steps">
              <div class="masthead-figure masthead-figure-xb">
                <div class="masthead-icon">
                  <img
                    src="assets/img/icon-steps-2.svg"
                    width="50"
                    class="icon-shoe"
                  />
                </div>
                {{ currentTarget - currentActivity.steps | number }}
              </div>

              <div class="progress">
                <div
                  class="progress-bar progress-bar-accent"
                  role="progressbar"
                  aria-valuenow="
                    (currentActivity.steps / currentTarget) * 100 | number
                  "
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: {{
                    (currentActivity.steps / currentTarget) * 100 | number
                  }}%;"
                >
                  <span class="sr-only"
                    >{{
                      (currentActivity.steps / currentTarget) * 100 | number
                    }}% Complete</span
                  >
                </div>
              </div>
              <p class="masthead-subtext">
                <strong>{{ currentActivity.steps | number }}</strong> of
                <strong>{{ currentTarget | number }}</strong> daily steps target
              </p>
            </div>
            <div
              *ngIf="+currentTarget <= +currentActivity.steps && currentTarget"
            >
              <div class="masthead-figure masthead-figure-xb">
                <span class="icon-wrap hit-goal"
                  ><fa-icon [icon]="['fas', 'star']"></fa-icon
                ></span>
                {{ currentActivity.steps | number
                }}<span class="masthead-strap masthead-strap-sm"> </span>
              </div>
              <div class="masthead-strap">CONGRATULATIONS!</div>
              <p class="masthead-subtext">
                You have hit your
                <strong>{{ currentTarget | number }}</strong> daily steps target
              </p>
            </div>
          </div>

          <div *ngIf="!currentActivity && +currentTarget">
            <p class="masthead-subtext">No step data yet</p>
          </div>

          <div *ngIf="!currentTarget">
            <p class="masthead-subtext">No step target set yet</p>
            <a role="button" [routerLink]="['/target']" class="btn btn-primary">
              <span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'bullseye']"></fa-icon
              ></span>
              SET TARGET</a
            >
          </div>
          <a role="button" [routerLink]="['/steps']" class="btn btn-primary">
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'shoe-prints']"></fa-icon
            ></span>
            MY STEPS</a
          >
        </div>
      </div>
      <div class="col-6 mb-2">
        <div class="masthead masthead-accent masthead-db masthead-steps">
          <h3>ACTIVE MINUTES REMAINING</h3>
          <div *ngIf="currentActivity && currentTargetMins">
            <div
              *ngIf="
                (+currentTargetMins > +currentActivity.minutes &&
                  currentTargetMinsUnit != 'week') ||
                (+currentTargetMins > +minsWeekTotal &&
                  currentTargetMinsUnit == 'week')
              "
            >
              <div class="masthead-figure masthead-figure-xb">
                <div class="masthead-icon">
                  <img
                    src="assets/img/icon-minutes-2.svg"
                    width="90"
                    class="icon-shoe"
                  />
                </div>

                <ng-container *ngIf="currentTargetMinsUnit != 'week'">{{
                  currentTargetMins - currentActivity.minutes | number
                }}</ng-container>
                <ng-container *ngIf="currentTargetMinsUnit == 'week'">{{
                  currentTargetMins - minsWeekTotal | number
                }}</ng-container>
              </div>

              <ng-container *ngIf="currentTargetMinsUnit != 'week'">
                <div class="progress">
                  <div
                    class="progress-bar progress-bar-accent"
                    role="progressbar"
                    aria-valuenow="
                    (currentActivity.minutes / currentTargetMins) * 100 | number
                  "
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style="width: {{
                      (currentActivity.minutes / currentTargetMins) * 100
                        | number
                    }}%;"
                  >
                    <span class="sr-only"
                      >{{
                        (currentActivity.minutes / currentTargetMins) * 100
                          | number
                      }}% Complete</span
                    >
                  </div>
                </div>
                <p class="masthead-subtext">
                  <strong>{{ currentActivity.minutes | number }}</strong> of
                  <strong>{{ currentTargetMins | number }}</strong> daily
                  minutes completed
                </p>
              </ng-container>
              <ng-container *ngIf="currentTargetMinsUnit == 'week'">
                <div class="progress">
                  <div
                    class="progress-bar progress-bar-accent"
                    role="progressbar"
                    aria-valuenow="
                    (minsWeekTotal / currentTargetMins) * 100 | number
                  "
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style="width: {{
                      (minsWeekTotal / currentTargetMins) * 100 | number
                    }}%;"
                  >
                    <span class="sr-only"
                      >{{ (minsWeekTotal / currentTargetMins) * 100 | number }}%
                      Complete</span
                    >
                  </div>
                </div>
                <p class="masthead-subtext">
                  <strong>{{ minsWeekTotal | number }}</strong> of
                  <strong>{{ currentTargetMins | number }}</strong> weekly
                  minutes completed
                </p>
              </ng-container>
            </div>
            <div
              *ngIf="
                ((+currentTargetMins <= +currentActivity.minutes &&
                  currentTargetMinsUnit != 'week') ||
                  (+currentTargetMins <= +minsWeekTotal &&
                    currentTargetMinsUnit == 'week')) &&
                +currentTargetMins
              "
            >
              <div class="masthead-figure masthead-figure-xb">
                <span class="icon-wrap hit-goal"
                  ><fa-icon [icon]="['fas', 'star']"></fa-icon></span
                ><span *ngIf="currentTargetMinsUnit != 'week'">{{
                  currentActivity.minutes | number
                }}</span>
                <span *ngIf="currentTargetMinsUnit == 'week'">{{
                  minsWeekTotal | number
                }}</span
                ><span class="masthead-strap masthead-strap-sm"> MINUTES</span>
              </div>
              <div class="masthead-strap">CONGRATULATIONS!</div>
              <p class="masthead-subtext">
                You have hit your
                <strong>{{ currentTargetMins | number }}</strong>
                <span *ngIf="currentTargetMinsUnit != 'week'"> daily</span
                ><span *ngIf="currentTargetMinsUnit == 'week'"> weekly</span>
                minutes target
              </p>
            </div>
          </div>
          <div *ngIf="!currentActivity && currentTargetMins">
            <p class="masthead-subtext">No minute data yet</p>
          </div>
          <div *ngIf="!currentTargetMins">
            <p class="masthead-subtext">No minute target set yet</p>
            <a
              role="button"
              [routerLink]="['/activity', 'minutes']"
              class="btn btn-primary"
            >
              <span class="icon-wrap"
                ><fa-icon [icon]="['far', 'stopwatch']"></fa-icon
              ></span>
              SET TARGET</a
            >
          </div>
          <a
            *ngIf="currentTargetMins"
            role="button"
            [routerLink]="['/activity', 'minutes']"
            class="btn btn-primary"
          >
            <span class="icon-wrap"
              ><fa-icon [icon]="['far', 'stopwatch']"></fa-icon
            ></span>
            MY MINUTES</a
          >
        </div>
      </div>
      <div
        class="col-sm-4"
        [ngClass]="{
          'col-lg-6':
            currentUser.mobility3 == '0' || currentUser.mobility1 == 'f' || currentUser.mobility1 == 'e',
          'col-lg-4':
            currentUser.mobility3 != '0' && currentUser.mobility1 != 'e' && currentUser.mobility1 != 'f'
        }"
      >
        <div class="masthead masthead-accent masthead-db masthead-steps"
        style="min-height:275px !important;">
          <div *ngIf="currentUser.device == 'fitbit'">
            <h3>TODAY'S INACTIVITY</h3>
            <div *ngIf="currentActivity && currentTargetSitting">
              <div>
                <div
                  class="masthead-figure masthead-figure-xb masthead-sitting-stats mt-1"
                >
                  <div class="masthead-icon">
                    <img
                      src="assets/img/icon-sitting-2.svg"
                      height="64"
                      class="icon-shoe"
                    />
                  </div>
                  <div class="masthead-sitting-figure">
                    <div>
                      {{
                        +currentActivity.sedentary_minutes
                          | minsToHours : "hoursOnly"
                      }}
                      <div>HOURS</div>
                    </div>
                    <div>
                      {{
                        +currentActivity.sedentary_minutes
                          | minsToHours : "minutesOnly"
                      }}
                      <div>MINS</div>
                    </div>
                  </div>
                </div>

                <p
                  class="masthead-subtext masthead-subtext-sitting"
                  *ngIf="
                    +currentActivity.sedentary_minutes <= currentTargetSitting
                  "
                >
                  {{
                    +currentTargetSitting - +currentActivity.sedentary_minutes
                      | minsToHours
                  }}
                  sitting time before you exceed your target
                </p>
                <p
                  class="masthead-subtext masthead-subtext-sitting"
                  *ngIf="
                    +currentActivity.sedentary_minutes > currentTargetSitting
                  "
                >
                  You have exceeded your target sitting time by
                  {{
                    (+currentTargetSitting -
                      +currentActivity.sedentary_minutes) *
                      -1 | minsToHours
                  }}
                </p>
                <br />
              </div>
            </div>
          </div>

          <!-- non fitbit -->
          <div *ngIf="currentUser.device != 'fitbit'">
            <h3>
              <span
                *ngIf="
                  !(
                    currentUser.mobility1 == 'e' || currentUser.mobility1 == 'f'
                  )
                "
                >SITTING TIME TODAY</span
              ><span
                *ngIf="
                  currentUser.mobility1 == 'e' || currentUser.mobility1 == 'f'
                "
                >SEDENTARY BEHAVIOUR</span
              >
            </h3>
            <div *ngIf="currentActivity && currentTargetSitting">
              <div>
                <div
                  class="masthead-figure masthead-figure-xb masthead-sitting-stats mt-1"
                >
                  <div class="masthead-icon">
                    <img
                      src="assets/img/icon-sitting-2.svg"
                      height="71"
                      class="icon-shoe"
                    />
                  </div>
                  <div class="masthead-sitting-figure">
                    <div>
                      {{
                        +currentActivity.sedentary_minutes
                          | minsToHours : "hoursOnly"
                      }}
                      <div>HOURS</div>
                    </div>
                    <div>
                      {{
                        +currentActivity.sedentary_minutes
                          | minsToHours : "minutesOnly"
                      }}
                      <div>MINS</div>
                    </div>
                  </div>
                </div>

                <p
                  class="masthead-subtext masthead-subtext-sitting"
                  *ngIf="
                    +currentActivity.sedentary_minutes <= currentTargetSitting
                  "
                  style="font-size: 13px"
                >
                  {{
                    +currentTargetSitting - +currentActivity.sedentary_minutes
                      | minsToHours : "hoursOnly"
                  }}h
                  {{
                    +currentTargetSitting - +currentActivity.sedentary_minutes
                      | minsToHours : "minutesOnly"
                  }}m left of your max
                  {{ +currentTargetSitting | minsToHours : "hoursOnly" }}h
                  {{ +currentTargetSitting | minsToHours : "minutesOnly" }}m
                  daily sitting time recommended
                </p>
                <p
                  class="masthead-subtext masthead-subtext-sitting"
                  *ngIf="
                    +currentActivity.sedentary_minutes > currentTargetSitting
                  "
                >
                  You have exceeded your daily target of less than
                  {{ +currentTargetSitting | minsToHours : "hoursOnly" }}h
                  {{ +currentTargetSitting | minsToHours : "minutesOnly" }}m
                </p>
              </div>
            </div>
          </div>

          <!-- end non fitbit -->
          <div *ngIf="!currentActivity && currentTargetSitting">
            <p class="masthead-subtext">No data yet</p>
          </div>
          <div *ngIf="!currentTargetSitting">
            <p class="masthead-subtext">No target set yet</p>
            <a
              role="button"
              [routerLink]="['/activity', 'sitting']"
              class="btn btn-primary"
            >
              <span class="icon-wrap"
                ><fa-icon [icon]="['far', 'stopwatch']"></fa-icon
              ></span>
              SET TARGET</a
            >
          </div>
          <a
            *ngIf="currentTargetSitting"
            role="button"
            [routerLink]="['/activity', 'sitting']"
            class="btn btn-primary"
          >
            <span class="icon-wrap"
              ><fa-icon [icon]="['far', 'couch']"></fa-icon
            ></span>
            MY SITTING</a
          >
        </div>
      </div>
      <div
        class="col-sm-6"
        [ngClass]="{
          'col-lg-6':
            currentUser.mobility3 == '0' || currentUser.mobility1 == 'e' || currentUser.mobility1 == 'f',
          'col-lg-4':
            currentUser.mobility3 != '0' && currentUser.mobility1 != 'e' && currentUser.mobility1 != 'f'
        }"
      >
        <div
        style="min-height:275px !important;"
          class="masthead masthead-accent masthead-db masthead-steps widget-s4h"
        >
          <h3>WORKOUTS COMPLETED</h3>
          <div *ngIf="currentWeek && currentWorkoutTarget">
            <div class="widget-s4h-details">
              <div class="widget-s4h-icon">
                <img src="./assets/img/icon-workouts-2.svg" height="68" />
              </div>

              <div class="widget-s4h-figure">
                {{ workoutDaysHit }}
                <div class="widget-s4h-subfigure">&nbsp;</div>
              </div>
            </div>

            <div class="clear-headline"></div>
            <p
              class="masthead-subtext mt-4"
              *ngIf="+workoutDaysHit < +currentWorkoutTarget.target"
            >
              {{ +currentWorkoutTarget.target - +workoutDaysHit }} workouts left
              to hit your weekly target of {{ currentWorkoutTarget.target }}
            </p>
            <p
              class="masthead-subtext mt-4"
              *ngIf="+workoutDaysHit >= +currentWorkoutTarget.target"
            >
              You have hit your workout target this week
            </p>
            <a
              role="button"
              [routerLink]="['/activity', 'workouts']"
              class="btn btn-primary"
            >
              <span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'running']"></fa-icon
              ></span>
              MY WORKOUTS</a
            >
          </div>
          <div *ngIf="!currentWorkoutTarget">
            <p class="masthead-subtext">No target set yet</p>
            <a
              role="button"
              [routerLink]="['/activity', 'workouts']"
              class="btn btn-primary"
            >
              <span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'running']"></fa-icon
              ></span>
              SET TARGET</a
            >
          </div>
        </div>
      </div>

      <!-- add sleep widget -->
      <div
        class="col-sm-4"
        [ngClass]="{
          'col-lg-6':
            currentUser.mobility3 == '0' || currentUser.mobility1 == 'e' || currentUser.mobility1 == 'f',
          'col-lg-4':
            currentUser.mobility3 != '0' && currentUser.mobility1 != 'e' && currentUser.mobility1 != 'f'
        }"
      >
        <div
        style="min-height:275px !important;"
          class="masthead masthead-accent masthead-db masthead-strength masthead-sm widget-s4h"
        >
          <h3><span>SLEEP TARGET ACHIEVED</span></h3>

          <div *ngIf="currentWeek">
            <div class="widget-s4h-details">
              <div class="widget-s4h-icon">
                <img src="./assets/img/icon-sleep-2.svg" width="60" />
              </div>

              <div class="widget-s4h-figure">
                {{ sleepDaysHit }}
                <div class="widget-s4h-subfigure">Night<span *ngIf="sleepDaysHit!=1">s</span></div>
              </div>
            </div>

            <div class="clear-headline"></div>

            <p class="masthead-subtext mt-4">
              {{ nightsLeftThisWeek }} nights left this week to hit your sleep target of
              {{ getAgeSleep(age) }} hours a night
            </p>
          </div>
          <div *ngIf="!currentActivity">
            <p class="masthead-subtext">No data yet</p>
          </div>
          <a
            role="button"
            [routerLink]="['/activity', 'sleep']"
            class="btn btn-primary"
          >
            <span class="icon-wrap"
              ><fa-icon [icon]="['far', 'alarm-clock']"></fa-icon
            ></span>
            MY SLEEP</a
          >
        </div>
      </div>
      <!-- end add sleep widget -->
    </div>

    <div *ngIf="algorithmComplete">
      <div *ngIf="!currentUser.buddy">
        <nav class="subnav ng-scope subnav-db">
          <h2>
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'graduation-cap']"></fa-icon
            ></span>
            LEARNING SESSIONS
          </h2>
        </nav>
        <div class="row dashboard-row" *ngIf="programmeContent">
          <div class="col col-lg-12 col-6">
            <div class="row">
              <div
                class="col col-md-12 col-lg-4"
                *ngFor="
                  let booster of programmeContent.booster_sessions
                    | filterObjectArray : { release_date: 'O' };
                  index as index
                "
              >
                <div
                  class="reading-widget booster-widget"
                  style="min-height: 215px"
                  [ngClass]="{ 'd-none d-lg-block': index > startLSession }"
                  *ngIf="index >= startLSession && index < endLSession"
                >
                  <div
                    class="reading-widget-img"
                    [ngStyle]="{
                      'background-image':
                        'url(' +
                        environment.assetUrl +
                        '/assets/' +
                        booster.image_filename +
                        ')'
                    }"
                  ></div>

                  <h3>{{ booster.booster_session_title }}</h3>
                  <button
                    class="btn btn-primary"
                    *ngIf="booster.status === 'started'"
                    [attr.aria-label]="
                      'Continue' + booster.booster_session_title
                    "
                    [routerLink]="[
                      '/learning-sessions',
                      'index',
                      booster.booster_session_id
                    ]"
                  >
                    CONTINUE
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
                    ></span>
                  </button>
                  <button
                    class="btn btn-primary"
                    *ngIf="booster.status == 'completed'"
                    [attr.aria-label]="
                      'Completed' + booster.booster_session_title
                    "
                    [routerLink]="[
                      '/learning-sessions',
                      'index',
                      booster.booster_session_id
                    ]"
                  >
                    COMPLETED
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
                    ></span>
                  </button>
                  <button
                    class="btn btn-primary"
                    *ngIf="
                      booster.status === 'available' ||
                      booster.status === 'unavailable'
                    "
                    [attr.aria-label]="'Begin' + booster.booster_session_title"
                    [routerLink]="[
                      '/learning-sessions',
                      'index',
                      booster.booster_session_id
                    ]"
                  >
                    BEGIN
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
                    ></span>
                  </button>

                  <!--button
                  *ngIf="booster.status === 'unavailable'"
                    
                      class="btn btn-primary disabled"
                    >Not released yet
                    </button-->
                </div>
              </div>
            </div>

            <div class="d-none d-lg-block text-center session-toggles">
              <ng-container
                *ngFor="
                  let booster of programmeContent.booster_sessions
                    | filterObjectArray : { release_date: 'O' };
                  index as index
                "
              >
                <a
                  *ngIf="index % 3 == 0"
                  (click)="onSetLSession(index)"
                  [ngClass]="{ 'circle-active': index == startLSession }"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'circle']"></fa-icon
                  ></span>
                </a>
              </ng-container>
            </div>
            <div
              class="d-none d-sm-block d-md-block d-lg-none text-center session-toggles"
            >
              <ng-container
                *ngFor="
                  let booster of programmeContent.booster_sessions
                    | filterObjectArray : { release_date: 'O' };
                  index as index
                "
              >
                <a
                  (click)="onSetLSession(index)"
                  [ngClass]="{ 'circle-active': index == startLSession }"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'circle']"></fa-icon></span
                ></a>
              </ng-container>
            </div>
          </div>
          <!--div class="col-6 col-lg-4">
              <div
                class="masthead masthead-accent db-tracker"
                style="margin-top: 0; min-height: 215px"
              >
                <h2>PROGRESS</h2>
                <div class="masthead-content" *ngIf="learningSessionTotals">
                  <div class="progress-stat">
                    <span class="icon-wrap text-primary">
                      <fa-icon [icon]="['fas', 'check-circle']"></fa-icon>
                    </span>
                    <span class="progress-label">
                      <span>{{ learningSessionTotals.completed }}</span>
                      completed learning sessions
                    </span>
                  </div>
                  <div class="progress-stat">
                    <span class="icon-wrap text-accent">
                      <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                    </span>
                    <span class="progress-label">
                      <span>{{ learningSessionTotals.available }}</span>
                      available learning sessions
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="text-center"
                *ngIf="
                  currentProgramme.config.certificate &&
                  learningSessionTotals?.completed > 0 &&
                  learningSessionTotals?.completed ==
                    learningSessionTotals?.available +
                      learningSessionTotals?.upcoming &&
                  boosterSessionTotals?.completed ==
                    boosterSessionTotals?.available +
                      boosterSessionTotals?.upcoming
                "
              >
                <a
                  class="btn btn-primary"
                  href="/download-certificate/{{
                    currentProgramme.programme_id
                  }}"
                  >DOWNLOAD CERTIFICATE
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'download']"></fa-icon></span
                ></a>
              </div>
            </div-->
        </div>

        <nav class="subnav ng-scope subnav-db">
          <h2>
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'bolt']"></fa-icon
            ></span>
            BOOSTER SESSIONS
          </h2>
        </nav>

        <div class="row dashboard-row" *ngIf="programmeContent">
          <div class="col-lg-12 col-6">
            <div class="row">
              <div
                class="col-md-12 col-lg-4"
                *ngFor="
                  let booster of programmeContent.booster_sessions
                    | filterObjectArray : { release_date: 'O' } : false : true;
                  index as index
                "
              >
                <div
                  *ngIf="index >= startSession && index < endSession"
                  class="reading-widget booster-widget"
                  style="min-height: 215px"
                  [ngClass]="{ 'd-none d-lg-block': index > startSession }"
                >
                  <div
                    class="reading-widget-img"
                    [ngStyle]="{
                      'background-image':
                        'url(' +
                        environment.assetUrl +
                        '/assets/' +
                        booster.image_filename +
                        ')'
                    }"
                  ></div>
                  <h3>{{ booster.booster_session_title }}</h3>
                  <button
                    class="btn btn-primary"
                    *ngIf="booster.status == 'started'"
                    [attr.aria-label]="
                      'Continue' + booster.booster_session_title
                    "
                    [routerLink]="[
                      '/booster-sessions',
                      'index',
                      booster.booster_session_id
                    ]"
                  >
                    CONTINUE
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
                    ></span>
                  </button>
                  <button
                    class="btn btn-primary"
                    *ngIf="booster.status == 'completed'"
                    [attr.aria-label]="
                      'Completed' + booster.booster_session_title
                    "
                    [routerLink]="[
                      '/booster-sessions',
                      'index',
                      booster.booster_session_id
                    ]"
                  >
                    COMPLETED
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
                    ></span>
                  </button>
                  <button
                    class="btn btn-primary"
                    *ngIf="booster.status == 'available'"
                    [attr.aria-label]="'Begin' + booster.booster_session_title"
                    [routerLink]="[
                      '/booster-sessions',
                      'index',
                      booster.booster_session_id
                    ]"
                  >
                    BEGIN
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
                    ></span>
                  </button>
                  <button
                    *ngIf="booster.status === 'unavailable'"
                    class="btn btn-primary disabled"
                  >
                    Not released yet
                  </button>
                </div>
              </div>
            </div>

            <div class="d-none d-lg-block text-center session-toggles">
              <ng-container
                *ngFor="
                  let booster of programmeContent.booster_sessions
                    | filterObjectArray : { release_date: 'O' } : false : true;
                  index as index
                "
              >
                <a
                  (click)="onSetSession(index)"
                  *ngIf="index % 3 == 0"
                  [ngClass]="{ 'circle-active': index == startSession }"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'circle']"></fa-icon></span
                ></a>
              </ng-container>
            </div>
            <div
              class="d-none d-sm-block d-md-block d-lg-none text-center session-toggles"
            >
              <ng-container
                *ngFor="
                  let booster of programmeContent.booster_sessions
                    | filterObjectArray : { release_date: 'O' } : false : true;
                  index as index
                "
              >
                <a
                  (click)="onSetSession(index)"
                  [ngClass]="{ 'circle-active': index == startSession }"
                  ><span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'circle']"></fa-icon></span
                ></a>
              </ng-container>
            </div>
          </div>
          <!--div class="col-6 col-lg-4">
              <div
                class="masthead masthead-accent db-tracker"
                style="margin-top: 0; min-height: 215px"
              >
                <h2>PROGRESS</h2>
                <div class="masthead-content" *ngIf="boosterSessionTotals">
                  <div class="progress-stat">
                    <span class="icon-wrap text-primary"
                      ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon>
                    </span>
                    <span class="progress-label">
                      <span>{{ boosterSessionTotals.completed }}</span>
                      completed booster sessions
                    </span>
                  </div>
                  <div class="progress-stat">
                    <span class="icon-wrap text-accent">
                      <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                    </span>
                    <span class="progress-label">
                      <span>{{ boosterSessionTotals.available }}</span>
                      available booster sessions
                    </span>
                  </div>
                  <div class="progress-stat">
                    <span class="icon-wrap">
                      <fa-icon [icon]="['far', 'circle']"></fa-icon>
                    </span>
                    <span class="progress-label">
                      <span>{{ boosterSessionTotals.upcoming }}</span> upcoming
                      booster sessions
                    </span>
                  </div>
                </div>
              </div>

              <div
                class="text-center"
                *ngIf="
                  currentProgramme.config.certificate &&
                  learningSessionTotals?.completed > 0 &&
                  learningSessionTotals?.completed ==
                    learningSessionTotals?.available +
                      learningSessionTotals?.upcoming &&
                  boosterSessionTotals?.completed ==
                    boosterSessionTotals?.available +
                      boosterSessionTotals?.upcoming
                "
              >
                <a
                  class="btn btn-primary"
                  href="/download-certificate/{{
                    currentProgramme.programme_id
                  }}"
                  >DOWNLOAD CERTIFICATE
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'download']"></fa-icon></span
                ></a>
              </div>
            </div-->
        </div>

        <nav class="subnav ng-scope subnav-db">
          <h2>
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'chart-line']"></fa-icon
            ></span>
            HEALTH TRACKERS
          </h2>
        </nav>
        <div class="row dashboard-row">
          <div
            class="col-lg-4 col-6"
            *ngIf="currentProgramme.config.trackers.indexOf('shape') > -1"
          >
            <div class="masthead masthead-accent db-tracker">
              <a
                class="tracker-link"
                [routerLink]="['/health-trackers', 'shape']"
              >
                <h2>
                  <span
                    class="icon-wrap"
                    *ngIf="currentUser.gender == 'f' || !currentUser.gender"
                  >
                    <fa-icon [icon]="['fas', 'female']"></fa-icon>
                  </span>
                  <span
                    class="icon-wrap"
                    *ngIf="
                      currentUser.gender == 'x' || currentUser.gender == 'm'
                    "
                  >
                    <fa-icon [icon]="['fas', 'male']"></fa-icon>
                  </span>
                  <span class="tracker-label">SHAPE</span>
                </h2>
              </a>
              <div class="masthead-content">
                <div *ngIf="userShapes[0]">
                  <div class="row">
                    <div class="col-6">
                      <h3 class="tracker-title">BMI &amp; WEIGHT</h3>
                      <div class="row">
                        <div class="col-4">
                          <img src="./assets/img/icon-scales.png" width="40" />
                        </div>
                        <div class="col-8">
                          <div>
                            <span class="tracker-value tracker-value">{{
                              calcBmi(userShapes[0].weight) | number : "1.1-1"
                            }}</span
                            ><br /><span class="tracker-value tracker-value-sm"
                              >{{ userShapes[0].weight | number }}kg</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <h3 class="tracker-title">WAIST MEASUREMENT</h3>
                      <div class="row">
                        <div class="col-6">
                          <img
                            src="./assets/img/icon-tape-measure.png"
                            height="30"
                          />
                        </div>
                        <div class="col-6">
                          <div *ngIf="userShapes[0].waist">
                            <span
                              class="tracker-value tracker-value"
                              *ngIf="environment.country != 'AUS'"
                              style="display: inline-block"
                              >{{
                                userShapes[0].waist | number : "1.0-0"
                              }}”</span
                            >
                            <span
                              class="tracker-value tracker-value"
                              *ngIf="environment.country == 'AUS'"
                              style="display: inline-block"
                              >{{ userShapes[0].waist * 2.54 | number : "1.0-0"
                              }}<span style="font-size: 16px">cm</span></span
                            >
                          </div>

                          <div *ngIf="!userShapes[0].waist">-</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <p class="masthead-subtext tracker-subtext">
                        Last updated on
                        {{ userShapes[0].created | dateFormatter : "D" }}
                        {{ userShapes[0].created | dateFormatter : "MMM yyyy" }}
                      </p>
                    </div>
                  </div>
                </div>

                <div *ngIf="!userShapes[0]">
                  <p class="masthead-subtext tracker-subtext">
                    You haven't added any readings yet
                  </p>
                  <br />
                  <a
                    class="btn btn-primary btn-sm"
                    [routerLink]="['/health-trackers', 'shape']"
                    >ADD ENTRY</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-4 col-6"
            *ngIf="
              currentProgramme.config.trackers.indexOf('healthyEating') > -1
            "
          >
            <div class="masthead masthead-accent db-tracker">
              <a
                class="tracker-link"
                [routerLink]="['/health-trackers', 'healthy-eating']"
              >
                <h2>
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'utensils']"></fa-icon
                  ></span>
                  <span class="tracker-label">HEALTHY EATING</span>
                </h2>
              </a>
              <div class="masthead-content">
                <div *ngIf="userHealthyEatings[0]">
                  <div class="row">
                    <div class="col-6">
                      <h3 class="tracker-title">HEALTHY EATING RATING</h3>
                      <div class="row">
                        <div class="col-4">
                          <img src="./assets/img/icon-food.png" width="35" />
                        </div>
                        <div class="col-8">
                          <div>
                            <span class="tracker-value tracker-value-lg">{{
                              userHealthyEatings[0].healthy_eating | number
                            }}</span
                            ><span
                              class="tracker-value tracker-value-md"
                              style="font-size: 12px"
                              >/10</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <h3 class="tracker-title">DAILY <br />FRUIT &amp; VEG</h3>
                      <div class="row">
                        <div class="col-4">
                          <img src="./assets/img/icon-veg.png" height="40" />
                        </div>
                        <div class="col-8">
                          <div>
                            <span class="tracker-value tracker-value-lg"
                              >{{
                                +userHealthyEatings[0].five_a_day +
                                  +userHealthyEatings[0].five_a_day_fruit
                                  | number
                              }}<span class="tracker-value tracker-value-xs"
                                >OF YOUR<br />5-A-DAY</span
                              ></span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <p class="masthead-subtext tracker-subtext">
                        Last updated on
                        {{
                          userHealthyEatings[0].created | dateFormatter : "D"
                        }}
                        {{
                          userHealthyEatings[0].created
                            | dateFormatter : "MMM yyyy"
                        }}
                      </p>
                    </div>
                  </div>
                </div>

                <div *ngIf="!userHealthyEatings[0]">
                  <p class="masthead-subtext tracker-subtext">
                    You haven't added any readings yet
                  </p>
                  <br />
                  <a
                    class="btn btn-primary btn-sm"
                    [routerLink]="['/health-trackers', 'healthy-eating']"
                    >ADD ENTRY</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-4 col-6"
            *ngIf="currentProgramme.config.trackers.indexOf('hba1c') > -1"
          >
            <div class="masthead masthead-accent db-tracker">
              <a
                class="tracker-link"
                [routerLink]="['/health-trackers', 'hba1c']"
              >
                <h2>
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'tint']"></fa-icon
                  ></span>
                  <span class="tracker-label">HbA1c</span>
                </h2>
              </a>
              <div class="masthead-content">
                <div *ngIf="userHbA1cs[0]">
                  <div class="row">
                    <div class="col-12">
                      <h3 class="tracker-title">
                        LONG TERM <br />BLOOD GLUCOSE MEASURE
                      </h3>
                      <div class="row">
                        <div class="col-12">
                          <img src="./assets/img/icon-drop.png" width="50" />
                          <span
                            class="tracker-value tracker-value-lg tracker-value-hba1c"
                            >{{ userHbA1cs[0].glucose | number }}
                            <span style="font-size: 14px"> mmol/mol</span></span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <p class="masthead-subtext tracker-subtext">
                        Last updated on
                        {{ userHbA1cs[0].created | dateFormatter : "D" }}
                        {{ userHbA1cs[0].created | dateFormatter : "MMM yyyy" }}
                      </p>
                    </div>
                  </div>
                </div>

                <div *ngIf="!userHbA1cs[0]">
                  <p class="masthead-subtext tracker-subtext">
                    You haven't added any readings yet
                  </p>
                  <br />
                  <a
                    class="btn btn-primary btn-sm"
                    [routerLink]="['/health-trackers', 'hba1c']"
                    >ADD ENTRY</a
                  >
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-lg-4 col-6"
            *ngIf="currentProgramme.config.trackers.indexOf('bp') > -1"
          >
            <div class="masthead masthead-accent db-tracker">
              <a
                class="tracker-link"
                [routerLink]="['/health-trackers', 'blood-pressure']"
              >
                <h2>
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'tachometer-alt']"></fa-icon
                  ></span>
                  <span class="tracker-label">BLOOD PRESSURE</span>
                </h2>
              </a>
              <div class="masthead-content">
                <div *ngIf="userBloodPressures[0]">
                  <div class="row">
                    <div class="col-4 nudge-right-10">
                      <h3 class="tracker-title-sm tracker-title-sm-nm">
                        SYSTOLIC
                      </h3>
                      <div class="row">
                        <div class="col-12 text-center">
                          <div>
                            <span class="tracker-value tracker-value-lg">{{
                              userBloodPressures[0].systolic | number
                            }}</span
                            ><br /><span class="tracker-value-sm-nf">mmHg</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <br />
                      <br />
                      <img src="./assets/img/icon-bp.png" width="70" />
                    </div>
                    <div class="col-4 nudge-left-10">
                      <h3 class="tracker-title-sm tracker-title-sm-nm">
                        DIASTOLIC
                      </h3>
                      <div class="row">
                        <div class="col-12 text-center">
                          <div>
                            <span class="tracker-value tracker-value-lg">{{
                              userBloodPressures[0].diastolic | number
                            }}</span
                            ><br /><span class="tracker-value-sm-nf">mmHg</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <p class="masthead-subtext tracker-subtext">
                        Last updated on
                        {{
                          userBloodPressures[0].created | dateFormatter : "D"
                        }}
                        {{
                          userBloodPressures[0].created
                            | dateFormatter : "MMM yyyy"
                        }}
                      </p>
                    </div>
                  </div>
                </div>

                <div *ngIf="!userBloodPressures[0]">
                  <p class="masthead-subtext tracker-subtext">
                    You haven't added any readings yet
                  </p>
                  <br />
                  <a
                    class="btn btn-primary btn-sm"
                    [routerLink]="['/health-trackers', 'blood-pressure']"
                    >ADD ENTRY</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-4 col-6"
            *ngIf="currentProgramme.config.trackers.indexOf('cholesterol') > -1"
          >
            <div
              class="masthead masthead-accent masthead-cholesterol db-tracker"
            >
              <a
                class="tracker-link"
                [routerLink]="['/health-trackers', 'cholesterol']"
              >
                <h2>
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'heartbeat']"></fa-icon
                  ></span>
                  <span class="tracker-label">CHOLESTEROL</span>
                </h2>
              </a>
              <div class="masthead-content">
                <br />
                <div *ngIf="userCholesterols[0]">
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-3">
                          <span
                            class="tracker-value tracker-value-md tracker-value-title"
                            >TOTAL</span
                          ><br />
                          <span class="tracker-value tracker-value-lg">{{
                            userCholesterols[0].cholesterol | number : "1.2-2"
                          }}</span
                          ><br />
                          <span class="tracker-value tracker-value-sm"
                            >mmol/L</span
                          >
                        </div>
                        <div class="col-3 nudge-left-10">
                          <img src="./assets/img/icon-artery.png" width="50" />
                        </div>
                        <div class="col-3 nudge-left-20">
                          <span
                            class="tracker-value tracker-value-md tracker-value-title"
                            >LDL</span
                          ><br />
                          <span class="tracker-value tracker-value-lg">{{
                            userCholesterols[0].ldl | number : "1.2-2"
                          }}</span
                          ><br />
                          <span class="tracker-value tracker-value-sm"
                            >mmol/L</span
                          >
                        </div>
                        <div class="col-3 nudge-left-20">
                          <span
                            class="tracker-value tracker-value-md tracker-value-title"
                            >HDL</span
                          ><br />
                          <span class="tracker-value tracker-value-lg">{{
                            userCholesterols[0].hdl | number : "1.2-2"
                          }}</span
                          ><br />
                          <span class="tracker-value tracker-value-sm"
                            >mmol/L</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <p class="masthead-subtext tracker-subtext">
                        Last updated on
                        {{ userCholesterols[0].created | dateFormatter : "D" }}
                        {{
                          userCholesterols[0].created
                            | dateFormatter : "MMM yyyy"
                        }}
                      </p>
                    </div>
                  </div>
                </div>

                <div *ngIf="!userCholesterols[0]">
                  <p class="masthead-subtext tracker-subtext">
                    You haven't added any readings yet
                  </p>
                  <br />
                  <a
                    class="btn btn-primary btn-sm"
                    [routerLink]="['/health-trackers', 'cholesterol']"
                    >ADD ENTRY</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-4 col-6"
            *ngIf="currentProgramme.config.trackers.indexOf('smoking') > -1"
          >
            <div class="masthead masthead-accent db-tracker">
              <a
                class="tracker-link"
                [routerLink]="['/health-trackers', 'smoking']"
              >
                <h2>
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'smoking-ban']"></fa-icon
                  ></span>
                  <span class="tracker-label">SMOKING</span>
                </h2>
              </a>
              <div class="masthead-content">
                <div *ngIf="userSmokings[0]">
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-6">
                          <h3
                            class="tracker-title tracker-title-sm uppercase-title"
                            *ngIf="userSmokingType"
                            style="margin-bottom: 0; padding-bottom: 0"
                          >
                            {{ smokingLabels[userSmokingType] }} <br /><span
                              style="font-size: 16px"
                              >PER DAY</span
                            >
                          </h3>
                          <h3
                            class="tracker-title tracker-title-sm"
                            *ngIf="!userSmokingType"
                          >
                            YOU DON'T SMOKE
                          </h3>

                          <img
                            src="./assets/img/icon-no-smoking.png"
                            width="36"
                            class="smoking-icon"
                          /><span class="tracker-value tracker-value-lg">{{
                            userSmokings[0][userSmokingType] | number
                          }}</span>
                        </div>
                        <div class="col-6" *ngIf="userSmokingType">
                          <div class="text-center">
                            <img
                              class="inline-block icon-piggy"
                              src="./assets/img/icon-piggy.png"
                              height="50"
                            />
                            <h3 class="inline-block tracker-title">
                              COST<br /><span style="font-size: 14px"
                                >PER YEAR</span
                              >
                            </h3>
                          </div>

                          <span class="tracker-value tracker-value-lg"
                            >{{ environment.currencySymbol
                            }}{{ cost | number : "1.0-0" }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <p
                    class="masthead-subtext tracker-subtext"
                    style="margin-top: 10px"
                  >
                    Last updated on
                    {{ userSmokings[0].created | dateFormatter : "D" }}
                    {{ userSmokings[0].created | dateFormatter : "MMM yyyy" }}
                  </p>
                  <br />
                </div>

                <div *ngIf="!userSmokings[0]">
                  <p class="masthead-subtext tracker-subtext">
                    You haven't added any readings yet
                  </p>
                  <br />
                  <a
                    class="btn btn-primary btn-sm"
                    [routerLink]="['/health-trackers', 'smoking']"
                    >ADD ENTRY</a
                  >
                </div>
                <div *ngIf="!userSmokings">
                  <p class="masthead-subtext tracker-subtext">
                    You need to update your smoking settings.
                  </p>
                  <br /><br />
                  <a
                    class="btn btn-primary btn-sm"
                    [routerLink]="['/health-trackers', 'smoking']"
                    >UPDATE SMOKING SETTINGS</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="fixed-bottom fb-sidenav fixed-bottom-alerts fixed-bottom-alerts-ep">
  <div class="container-lg">
    <ng-container *ngFor="let alert of newAlerts; let index = index">
      <div class="well well-notification" *ngIf="index === 0">
        <a
          class="close-alert-btn"
          tabindex="0"
          (click)="onCloseAlert(alert, false)"
        >
          <span><fa-icon [icon]="['fas', 'times-circle']"></fa-icon></span
        ></a>
        <span class="icon-wrap" *ngIf="alert.alert_type == 'update'"
          ><fa-icon [icon]="['fas', 'sync']"></fa-icon
        ></span>
        <span class="icon-wrap" *ngIf="alert.alert_type == 'info'"
          ><fa-icon [icon]="['fas', 'info']"></fa-icon
        ></span>
        <span class="icon-wrap" *ngIf="alert.alert_type == 'booster'"
          ><fa-icon [icon]="['fas', 'graduation-cap']"></fa-icon
        ></span>
        <div class="notification-content">
          <div class="notification-title">{{ alert.title | uppercase }}</div>
          <div class="notification-text">{{ alert.content }}</div>
          <a
            role="button"
            (click)="onCloseAlert(alert)"
            class="btn btn-primary btn-sm alert-btn"
            *ngIf="alert.button_title"
            ><span *ngIf="alert.button_title">{{
              alert.button_title | uppercase
            }}</span>
            <span *ngIf="!alert.button_title">CLOSE</span>
            <span class="icon-wrap" *ngIf="alert.alert_type == 'update'"
              ><fa-icon [icon]="['fas', 'sync']"></fa-icon
            ></span>
          </a>
        </div>
      </div>
    </ng-container>
  </div>
</div>
