import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2/src/sweetalert2.js';
import * as moment from 'moment';
import { AuthService } from 'src/app/auth/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-programme-setup-ep',
  templateUrl: './programme-setup-ep.component.html',
  styleUrls: ['./programme-setup-ep.component.scss'],
})
export class ProgrammeSetupEpComponent implements OnInit {
  loading = false;
  regDetails = {
    email: null,
    birth_country: null,
    language: null,
    smoker: null,
    exercise: null,
    attendedBefore: 0,
    gender: null,
    weight: null,
    weightStone: null,
    weightLbs: null,
    height: null,
    heightFeet: null,
    heightInches: null,
    glucosePercent: null,
    glucose: null,
    password: '',
    terms: 0,
    agree_marketing: 0,
    origin: null,
    first_name: null,
    last_name: null,
    mobility1: null,
    mobility2: null,
    mobility3: null,
    employed: null,
    employment_status: null,
    activity_time1: null,
    activity_time2: null,
    activity_time3: null,
    activity_time4: null,
    activity_time5: null,
    walking_pace: null,
    sitting_weekday: null,
    sitting_weekend: null,
    notifications1: { setting: 'a', days: [], time: '' },
    notifications2: { setting: 'a', days: [], time: '' },
    notifications3: { setting: 'a', days: [], time: '' },
  };
  stepSubmitted = {};
  subStepSubmitted = {};
  step = 1;
  subStep = 1;
  environment = environment;
  codeError;
  isFetching;
  unitMode = 'metric';
  regProgrammeConfig;
  regProgramme;
  hba1cUnitMode = 'percent';
  code;
  teamNames;
  team_id;
  //bsValue = new Date('1960/01/01');
  bsValue;
  minDob = moment().subtract('year', 110).toDate();
  maxDob = moment().subtract('year', 16).toDate();
  noSpacesPattern = /.*[^ ].*/;
  disclaimer = 'na';

  ethnicityOtherEnabled = false;

  passwordPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#])[A-Za-z\d$=@$!%*?&#]{10,}$/;

  currentUser = null;

  @ViewChild('regForm', { static: false }) regForm: NgForm;

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.isFetching = true;
    this.step = 4;
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userService.userData.subscribe((userDataResponse) => {
        if(userDataResponse){
          this.currentUser = userDataResponse;
          if (this.currentUser.mobility1 && this.currentUser.sitting_weekday) {
            this.router.navigate(['/dashboard-ep']);
          }
          this.isFetching = false;
        }
        else{

          this.isFetching = false;
        }
        });
      } else {
        this.isFetching = false;
      }
    });
  }

  onSubmit() {
    // console.log(this.regForm.value);
  }

  checkReg(regStep) {
    this.isFetching = true;
    this.stepSubmitted[regStep] = true;
    switch (regStep) {
      case 4:
        // is it valid?

        if (this.subStep == 1) {
          this.subStepSubmitted[1] = true;
          if (!this.regForm.controls.mobility1.invalid) {
            this.isFetching = false;
            this.subStep = 2;

            window.scrollTo(0, 0);
          } else {
            this.isFetching = false;
            Swal.fire({
              title: "We notice you've missed something",
              text: 'Please check the form for information that has been missed',
              icon: 'error',
            });
          }
        } else if (this.subStep == 2) {
          this.subStepSubmitted[2] = true;

          if (!this.regForm.controls.mobility2.invalid) {
            this.subStep = 1;
            this.subStepSubmitted[1] = false;
            this.subStepSubmitted[2] = false;
            // check if we need step 5
            if (
              this.regDetails.mobility1 == 'e' ||
              this.regDetails.mobility1 == 'f'
            ) {
              this.step = 6;
            } else {
              this.step = 5;
            }

            this.isFetching = false;

            window.scrollTo(0, 0);
          } else {
            this.isFetching = false;
            Swal.fire({
              title: "We notice you've missed something",
              text: 'Please check the form for information that has been missed',
              icon: 'error',
            });
          }
        }

        break;
      case 5:
        if (this.subStep == 1) {
          this.subStepSubmitted[1] = true;
          if (
            !this.regForm.controls.employed.invalid &&
            !this.regForm.controls.employment_status.invalid
          ) {
            this.subStep = 2;
            this.isFetching = false;
          } else {
            this.isFetching = false;
            Swal.fire({
              title: "We notice you've missed something",
              text: 'Please check the form for information that has been missed',
              icon: 'error',
            });
          }
        } else if (this.subStep == 2) {
          this.subStepSubmitted[2] = true;
          if (
            !this.regForm.controls.activity_time1.invalid &&
            !this.regForm.controls.activity_time2.invalid &&
            !this.regForm.controls.activity_time3.invalid &&
            !this.regForm.controls.activity_time4.invalid &&
            !this.regForm.controls.activity_time5.invalid
          ) {
            this.subStep = 3;
            this.isFetching = false;
          } else {
            this.isFetching = false;
            Swal.fire({
              title: "We notice you've missed something",
              text: 'Please check the form for information that has been missed',
              icon: 'error',
            });
          }
        } else if (this.subStep == 3) {
          this.subStepSubmitted[3] = true;
          if (
            !this.regForm.controls.walking_pace.invalid &&
            !this.regForm.controls.sitting_weekday.invalid &&
            !this.regForm.controls.sitting_weekend.invalid
          ) {
            this.step = 6;
            // do we need the alert about notifications for sitting?

            if (
              this.regForm.controls.employment_status.value == 'a' &&
              +this.regForm.controls.sitting_weekday.value > 7
            ) {
              this.isFetching = false;
              Swal.fire({
                title: 'Helpful Notifications',
                text: 'From the answers given at registration notifications around sitting and inactivity could be beneficial for you',
                icon: 'info',
              });
            }

            this.isFetching = false;

            window.scrollTo(0, 0);
          } else {
            this.isFetching = false;
            Swal.fire({
              title: "We notice you've missed something",
              text: 'Please check the form for information that has been missed',
              icon: 'error',
            });
          }
        }

        break;
      case 6:
        this.isFetching = false;

        // submit it
        this.authService.setupProgramme(this.regForm.value).subscribe(
          (responseData) => {
            // console.log(responseData);
            this.userService.reset();
                this.userService.get().subscribe((userData) => {
                  this.currentUser = userData;
                });
            this.step = 7;

            this.isFetching = false;

          },
          (error) => {
            console.log(error);
            Swal.fire({
              title: 'Error creating account',
              text: 'There was an error setting up your account. Please try again.',
              icon: 'error',
            });
            this.isFetching = false;
          }
        );
        break;
    }
  }

  addToPhone() {}
  complete() {
    this.router.navigate(['/dashboard-ep']);
    /*
    if (+this.regProgrammeConfig.welcomePageOn === 1) {
      this.router.navigate(['/welcome']);
    } else {
      if (this.regProgrammeConfig.prepost) {
        if (this.regProgrammeConfig.prepostOrg) {
          const prepostOrg = JSON.parse(this.regProgrammeConfig.prepostOrg);
          this.userService.userData.subscribe((userDataResponse) => {
            this.currentUser = userDataResponse;
            // get the current user...
            const preposts = [];
            // check for org specific..
            if (prepostOrg[this.currentUser.client_id]) {
              for (const assessmentId of Object.keys(
                prepostOrg[this.currentUser.client_id]
              )) {
                for (const prepost of Object.keys(
                  prepostOrg[this.currentUser.client_id][assessmentId]
                )) {
                  if (
                    prepostOrg[this.currentUser.client_id][assessmentId].pre ==
                    true
                  ) {
                    preposts.push(assessmentId);
                  }
                }
              }
            }

            // go to the pre
            if (preposts.length > 0) {
              this.router.navigate([
                '/assessments',
                'take',
                preposts[0],
                'pre',
              ]);
            } else {
              this.router.navigate(['/dashboard']);
            }
          });
        } else {
          this.router.navigate([
            '/assessments',
            'take',
            this.regProgrammeConfig.prepost[0],
            'pre',
          ]);
        }
      } else {
        this.router.navigate(['/dashboard']);
      }
    }*/
  }

  getPostcodePattern() {
    if (environment.country === 'UK') {
      return '^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$';
    } else if (environment.country === 'AUS') {
      return '^[0-9]{4}$';
    }
  }

  getMinLength() {
    if (environment.country === 'UK') {
      return 5;
    } else if (environment.country === 'AUS') {
      return 4;
    }
  }
  getMaxLength() {
    if (environment.country === 'UK') {
      return 8;
    } else if (environment.country === 'AUS') {
      return 4;
    }
  }

  updateWeight(type?) {
    if (type != 'metric') {
      this.regDetails.weight =
        +parseInt(this.regDetails.weightStone) * 6.35029 +
        this.regDetails.weightLbs * 0.453592;
    } else {
      //convert metric to imperial
      var stones = Math.floor(this.regDetails.weight * 0.1574);
      var remainder = 0.1574 % this.regDetails.weight;
      var totalLbs = 2.20462 * this.regDetails.weight;
      var lbs = totalLbs - stones * 14;
      this.regDetails.weightStone = stones;
      this.regDetails.weightLbs = Math.round(lbs * 10) / 10;
    }
  }

  updateHeight(type?) {
    if (type != 'metric') {
      this.regDetails.height =
        +parseInt(this.regDetails.heightFeet) * 12 * 2.54 +
        +parseInt(this.regDetails.heightInches) * 2.54;
    } else {
      //convert metric to imperial
      var realFeet = (this.regDetails.height * 0.3937) / 12;
      var feet = Math.floor(this.regDetails.height / 30.48);
      var inches = Math.round((realFeet - feet) * 12);
      this.regDetails.heightFeet = feet;
      this.regDetails.heightInches = inches;
    }
  }

  updateVal() {
    // convert from percent..
    this.regDetails.glucose =
      Math.round((this.regDetails.glucosePercent - 2.15) * 10.929 * 10) / 10;
  }

  checkPassword(check, pswd) {
    var pass = false;

    if (pswd) {
      switch (check) {
        case 'uppercase':
          if (/^.*[A-Z].*$/.test(pswd)) {
            pass = true;
          }
          break;
        case 'lowercase':
          if (/^.*[a-z].*$/.test(pswd)) {
            pass = true;
          }
          break;
        case 'number':
          if (/^.*[0-9].*$/.test(pswd)) {
            pass = true;
          }
          break;
        case 'special':
          if (/^.*[$@!%*?&#].*$/.test(pswd)) {
            pass = true;
          }
          break;
      }
    }
    return pass;
  }

  onSelectEthnicity(ethnicity_id) {
    switch (+ethnicity_id) {
      case 3:
      case 7:
      case 11:
      case 14:
      case 16:
      case 21:
        this.ethnicityOtherEnabled = true;
        break;
      default:
        this.ethnicityOtherEnabled = false;
        break;
    }
  }

  getPasswordStrength(password) {
    let score = 0;

    if (/^.*[A-Z].*$/.test(password)) {
      score++;
    }
    if (/^.*[a-z].*$/.test(password)) {
      score++;
    }
    if (/^.*[0-9].*$/.test(password)) {
      score++;
    }
    if (/^.*[$@!%*?&#].*$/.test(password)) {
      score++;
    }

    return score;
  }

  calcBmi(weight, height) {
    return Math.round((weight / Math.pow(height, 2)) * 100) / 100;
  }
}
