import { Options } from '@angular-slider/ngx-slider';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { Eq5dService } from 'src/app/services/eq5d.service';

@Component({
  selector: 'app-eq5d',
  templateUrl: './eq5d.component.html',
  styleUrls: ['./eq5d.component.scss'],
})
export class Eq5dComponent implements OnInit {
  @ViewChild('form', { static: false }) eq5dForm?: NgForm;
  completed = false;
  responses = {
    mobility: 0,
    selfcare: 0,
    activities: 0,
    pain: 0,
    anxiety: 0,
  };
  submittedResponses: any;
  step = 1;
  value = 50;
  options: Options = {
    floor: 0,
    ceil: 100,
    vertical: true,
    showSelectionBar: true,
    tickStep: 1,
    showTicks: true,
    showTicksValues: false,
    stepsArray: [],
  };

  isLoading = false;
  userData: any;
  taskStatus = false;
  stage = 0;

  constructor(
    private eq5dService: Eq5dService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params.stage) {
        this.stage = params.stage;
      }

      const user = this.authService.user.getValue();
      if (user) {
        const add1Week = moment(user.created).add(1, 'week');
        const add1Month = moment(user.created).add(1, 'month');
        const add2Month = moment(user.created).add(2, 'month');
        const add3Month = moment(user.created).add(3, 'month');
        const add4Month = moment(user.created).add(4, 'month');
        const add5Month = moment(user.created).add(5, 'month');
        const add6Month = moment(user.created).add(6, 'month');
        const now = moment();

        if (
          this.stage != 0 &&
          this.stage != 1 &&
          this.stage != 3 &&
          this.stage != 6
        ) {
          this.router.navigate(['/dashboard-ep']);
        }
        if (this.stage == 0 && now > add1Week) {
          this.router.navigate(['/dashboard-ep']);
        }
        // 1 & 3  cut off +1 month
        if (this.stage == 1 && !(now > add1Month && now < add2Month)) {
          this.router.navigate(['/dashboard-ep']);
        }
        if (this.stage == 3 && !(now > add3Month && now < add4Month)) {
          this.router.navigate(['/dashboard-ep']);
        }
        // 6 month no cut off*/
        if (this.stage == 6 && now < add6Month) {
          this.router.navigate(['/dashboard-ep']);
        }
      }
    });

    for (let x = 0; x < 101; x++) {
      if (x % 10 === 0) {
        this.options.stepsArray?.push({ value: x, legend: '' + x });
      } else {
        this.options.stepsArray?.push({ value: x });
      }
    }

    this.isLoading = true;
    forkJoin({
      responses: this.eq5dService.fetchResponse(this.stage),
    }).subscribe(
      (responseData) => {
        this.isLoading = false;
        this.submittedResponses = responseData.responses;

        // have they answered it?
        if (this.submittedResponses) {
          this.completed = true;
        }
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  onPrevious() {
    this.step--;
  }

  onNext() {
    this.step++;
  }

  onSubmit() {
    // console.log(this.eq5dForm.value);

    this.eq5dService
      .saveResponse(
        this.stage,
        this.eq5dForm?.value.mobility,
        this.eq5dForm?.value.selfcare,
        this.eq5dForm?.value.activities,
        this.eq5dForm?.value.pain,
        this.eq5dForm?.value.anxiety,
        this.value
      )
      .subscribe((responseData) => {
        this.completed = true;
        window.scroll(0, 0);
        this.completed = true;
        // complete the task?
      });
  }
}
