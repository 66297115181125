import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FaqsService {
  cacheTime = new Date();
  cacheLifetime = 3 * 60 * 1000;
  allFaqs: any;

  environment = environment;
  constructor(private http: HttpClient) {}

  fetchAll(): Observable<any> {
    const now = new Date();

    if (
      !this.allFaqs ||
      Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime
    ) {
      this.cacheTime = new Date();
      this.allFaqs = this.http
        .get<any>(environment.apiUrl + '/faqs', {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            return responseData._embedded.faqs;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.allFaqs;
  }


  clearCache() {
    this.allFaqs = null;
  }
}
