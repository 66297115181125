<div class="container m3" *ngIf="currentUser">
  <app-m3-header class="d-none d-sm-block"></app-m3-header>
  <app-m3-mobile-navbar
    [pageTitle]="'Non Stepping Activity'"
    class="d-block d-sm-none"
  ></app-m3-mobile-navbar>
  <nav class="subnav d-block d-sm-none">
    <h2>Overview</h2>
  </nav>
  <h1 class="m3-title mt-3 d-none d-sm-block">
    <a class="add-item-btn" (click)="addModal.show()"
      ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
    ></a>
    <app-m3-icon [icon]="'non-stepping'"></app-m3-icon> Non Stepping Activity
  </h1>

  <button
    (click)="addModal.show()"
    class="btn btn-mobile-add d-block d-sm-none"
  >
    <app-m3-icon [icon]="'more'" [style]="'color'"></app-m3-icon> Add an
    Activity Entry
  </button>

  <div class="card" *ngIf="nonSteppings">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div
            class="info-stat info-stat-rag mb-2"
          >
            <div class="info-stat-rag-current">
              <app-m3-icon [icon]="'non-stepping'"></app-m3-icon>
              <span class="info-stat-label"
                >My total moderate to vigorous physical activity (MVPA), including brisk steps </span
              >
              <span
                class="info-stat-stat"
                [ngClass]="{
                  'text-rag-green': +nonSteppingWeeklyTotal+briskMinsThisWeek >= 150,
                  'text-rag-amber': +nonSteppingWeeklyTotal+briskMinsThisWeek >= 100 &&  +nonSteppingWeeklyTotal+briskMinsThisWeek<150,
                  'text-rag-red': +nonSteppingWeeklyTotal+briskMinsThisWeek < 100
                }"
                >{{ nonSteppingWeeklyTotal+briskMinsThisWeek | number : "1.0-0" }}</span
              >
              <span class="info-stat-label">min/week</span>
            </div>

            <div class="rag-details">
              <div class="rag-details-target">
                Target:
                <span class="text-blue">150 min/week</span>
              </div>
              <div class="rag-details-history">
                History:
                <div class="rag-history">
                  <ng-template #popTemplateNonStepping>
                    <div>
                      <div>Range:</div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-green mr-1"></span>
                        >= 150 mins/week
                      </div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-red mr-1"></span>
                        &lt; 150 mins/week
                      </div>
                    </div></ng-template
                  >
                  <ng-container
                    *ngFor="
                      let week of activitiesByWeekTotals.slice();
                      let index = index
                    "
                  >
                    <div
                      *ngIf="index > activitiesByWeekTotals.length - 6"
                      [tooltip]="
                        week.minutes + ' mins/week, ' + week.date_recorded
                      "
                      class="rag-circle"
                      [ngClass]="{ 'bg-rag-green': +week.minutes >= 150, 'bg-rag-red': +week.minutes<150 }"
                    ></div>
                  </ng-container>
                  <div
                    class="rag-circle pointer"
                    [popover]="popTemplateNonStepping"
                  >
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['fas', 'info']"></fa-icon
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info-stat info-stat-target" *ngIf="weeklyTotal">
            <app-m3-icon [icon]="'target'" class="text-blue"></app-m3-icon>
            <span class="info-stat-label"
              >Your updated personalised target is
            </span>
            <span class="info-stat-stat text-blue">{{
              +weeklyTotal + 5 | number : "1.0-0"
            }}</span>
            <span class="info-stat-label">min/week</span>
          </div>
          <div class="info-stat mb-2">
            <app-m3-icon [icon]="'target'" class=""></app-m3-icon>
            <span class="info-stat-label">MVPA should account for</span>
            <span class="info-stat-stat text-rag-green">150</span>
            <span class="info-stat-label"> min/week</span>
          </div>
          <!--app-m3-non-stepping-week-chart *ngIf="nonSteppingsWeek" [nonSteppings]="nonSteppingsWeek"></app-m3-non-stepping-week-chart-->
          <app-m3-non-stepping-chart
            *ngIf="activitiesByWeek && activitiesDone"
            [nonSteppingsByDate]="activitiesByWeek"
            [activtitiesDone]="activitiesDone"
          ></app-m3-non-stepping-chart>
          <!-- entries -->
          <h3
            class="chart-title text-center mt-4"
            *ngIf="nonSteppings.length > 0"
          >
            Your Entries
          </h3>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>DATE</th>
                  <th>ACTIVITY</th>
                  <th>MINUTES</th>
                  <th>INTENSITY</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="let activity of nonSteppings; let index = index"
                >
                  <tr *ngIf="index < 10 || showAllEntries">
                    <td class="text-center">
                      {{
                        activity.date_recorded | dateFormatter : "MMM Do YYYY"
                      }}
                    </td>
                    <td class="text-center">
                      {{ getActivityLabel(activity.activity, true) }}
                    </td>

                    <td class="text-center">
                      {{ activity.minutes }}
                    </td>
                    <td class="text-center">
                      {{ getIntensityLabel(activity.intensity) }}
                    </td>
                    <td>
                      <a
                        [swal]="{
                          title: 'Are you sure you want to delete this?',
                          icon: 'warning',
                          showCancelButton: true
                        }"
                        (confirm)="onDelete(activity)"
                        class="pointer"
                        ><span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                      ></a>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>

            <div class="text-center">
              <button
                *ngIf="!showAllEntries && nonSteppings.length > 10"
                class="btn btn-primary btn-sm btn-xs"
                (click)="showAllEntries = true"
              >
                Show all
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="info-stat mb-2">
            <app-m3-icon [icon]="'walking'"></app-m3-icon>
            <span class="info-stat-label"
              >Your average steps in the past seven days</span
            >
            <span class="info-stat-stat text-rag-{{nonSteppingStepsRag(averageSteps)}}">{{ averageSteps | number }}</span>
            <span class="info-stat-label"> Steps/day</span>
          </div>

          <div class="info-stat mb-2">
            <app-m3-icon [icon]="'non-stepping'"></app-m3-icon>
            <span class="info-stat-label"
              >Your estimated average non-stepping activity in the past seven
              days
            </span>
            <span class="info-stat-stat text-rag-{{nonSteppingStepsRag(averageNonSteppingSteps)}}">{{
              averageNonSteppingSteps | number
            }}</span>
            <span class="info-stat-label"> Steps/day</span>
          </div>

          <div class="info-stat mb-2">
            <app-m3-icon [icon]="'walking-alt'"></app-m3-icon>
            <span class="info-stat-label"
              >Your combined average activity in the past seven days
            </span>
            <span class="info-stat-stat text-rag-{{nonSteppingStepsRag(+averageSteps + +averageNonSteppingSteps)}}">{{
              +averageSteps + +averageNonSteppingSteps | number
            }}</span>
            <span class="info-stat-label"> Steps/day</span>
          </div>

          <app-m3-walking-non-stepping-chart
            [allTimeSteps]="allTimeSteps"
            [allNonSteppings]="nonSteppings"
            *ngIf="allTimeSteps && nonSteppings"
          ></app-m3-walking-non-stepping-chart>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    bsModal
    #addModal="bs-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-static-name"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left">Update Non Stepping Activity</h4>
          <button
            type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="addModal.hide()"
          >
            <span aria-hidden="true" class="visually-hidden">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <form
              name="entryForm"
              class="bs-form tracker-form"
              (ngSubmit)="onSubmitEntryForm()"
              #entryForm="ngForm"
            >
              <div class="form-group">
                <label for="number">DATE</label>
                <input
                  name="date"
                  type="text"
                  placeholder="DD/MM/YYYY"
                  class="form-control input-tracker input-tracker-sm"
                  bsDatepicker
                  autocomplete="off"
                  [bsConfig]="{
                    dateInputFormat: 'DD/MM/YYYY',
                    containerClass: 'theme-cv',
                    showWeekNumbers: false
                  }"
                  [(ngModel)]="dateRecorded"
                  required
                  [bsValue]="dateRecorded"
                  [maxDate]="today"
                  #dateInput="ngModel"
                />
              </div>
              <p>
                On this date, what activities have you performed that do not
                involve stepping?
              </p>

              <table class="table">
                <thead>
                  <tr>
                    <th>Activity</th>
                    <th>Minutes</th>
                    <th>Intensity</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let entry of activitiesToSave">
                    <td>{{ getActivityLabel(entry.activity) }}</td>
                    <td>{{ entry.minutes }}</td>
                    <td>{{ getIntensityLabel(entry.intensity) }}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <div class="form-group">
                        <label for="activity" class="sr-only">Activity</label>
                        <select
                          name="activity"
                          class="custom-select custom-select-lg"
                          id="activity"
                          autocomplete="off"
                          ngModel
                          #activity="ngModel"
                        >
                          <option
                            value="{{ activity.id }}"
                            *ngFor="let activity of activities"
                          >
                            {{ activity.label }}
                          </option>
                        </select>
                        <div
                          class="alert alert-danger"
                          *ngIf="activity.touched && activity.invalid"
                        >
                          This must be number.
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <label for="minutes" class="sr-only">Minutes</label>
                        <input
                          type="number"
                          name="minutes"
                          class="form-control input-tracker"
                          style="width: 70px"
                          placeholder=""
                          id="minutes"
                          min="1"
                          max="10000000"
                          autocomplete="off"
                          ng-required=""
                          #minutes="ngModel"
                          ngModel
                        />
                        <div
                          class="alert alert-danger"
                          *ngIf="minutes.touched && minutes.invalid"
                        >
                          This must be number.
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <label for="intensity" class="sr-only">Intensity</label>
                        <select
                          type="number"
                          name="intensity"
                          class="custom-select custom-select-lg"
                          placeholder=""
                          id="intensity"
                          autocomplete="off"
                          ng-required=""
                          #intensity="ngModel"
                          ngModel
                        >
                          <option value="l">Light</option>
                          <option value="m">Moderate</option>
                          <option value="v">Vigorous</option>
                        </select>
                        <div
                          class="alert alert-danger"
                          *ngIf="intensity.touched && intensity.invalid"
                        >
                          This must be number.
                        </div>
                      </div>
                    </td>
                    <td>
                      <button class="btn btn-white">Add</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
            <div class="text-center">
              <button class="btn btn-white" (click)="onSaveEntries()">
                Save Entries
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="clear"></div>
</div>
