import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ProgrammeService } from 'src/app/services/programme.service';
import { User180TurnService } from 'src/app/services/user-180-turn.service';
import { UserDistressScoreService } from 'src/app/services/user-distress-score.service';
import { UserFallService } from 'src/app/services/user-fall.service';
import { UserMbtqService } from 'src/app/services/user-mbtq.service';
import { UserPaidService } from 'src/app/services/user-paid.service';
import { UserPhq9Service } from 'src/app/services/user-phq9.service';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { UserShortFesService } from 'src/app/services/user-short-fes.service';
import { UserSss8Service } from 'src/app/services/user-sss8.service';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-m3-frailty',
  templateUrl: './m3-frailty.component.html',
  styleUrls: ['./m3-frailty.component.scss'],
})
export class M3FrailtyComponent implements OnInit {
  currentUser;
  falls: any = [];
  user180Turns: any = [];
  shortFess: any = [];
  sss8s: any = [];
  distressScores: any = [];
  showAllEntries = false;

  entryDateFall = new Date();
  entryDate180Turn = new Date();
  entryDateShortFes = new Date();
  entryDateSss8 = new Date();
  entryDateDistressScore = new Date();

  @ViewChild('entryFormFall', { static: false }) entryFormFall: NgForm;
  @ViewChild('entryForm180Turn', { static: false }) entryForm180Turn: NgForm;
  @ViewChild('entryFormShortFes', { static: false }) entryFormShortFes: NgForm;
  @ViewChild('entryFormSss8', { static: false }) entryFormSss8: NgForm;
  @ViewChild('entryFormDistressScore', { static: false })
  entryFormDistressScore: NgForm;

  constructor(
    private authService: AuthService,
    private programmeService: ProgrammeService,
    private router: Router,
    private userService: UserService,
    private userSettingsService: UserSettingsService,
    private modalService: BsModalService,
    public userFallService: UserFallService,
    public user180TurnService: User180TurnService,
    public userShortFesService: UserShortFesService,
    public userSss8Service: UserSss8Service,
    public userDistressScoreService: UserDistressScoreService
  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {});

      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });

      forkJoin({
        userSettings: this.userSettingsService.fetchAll(),
        falls: this.userFallService.fetchAll(),
        user180Turns: this.user180TurnService.fetchAll(),
        shortFess: this.userShortFesService.fetchAll(),
        sss8s: this.userSss8Service.fetchAll(),
        distressScores: this.userDistressScoreService.fetchAll(),
      }).subscribe((responseData) => {
        this.falls = responseData.falls;
        this.user180Turns = responseData.user180Turns;
        this.shortFess = responseData.shortFess;
        this.sss8s = responseData.sss8s;
        this.distressScores = responseData.distressScores;
      });
    }
  }

  onDeleteFall(fall) {
    this.userFallService.delete(fall.user_fall_id).subscribe((responseData) => {
      //this.activityService.clearCache();
      this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigate(['/m3/health-data/independence']));
    });
  }

  onSubmitFallForm() {
    // this.entryForm.value.date

    if (!this.entryFormFall.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something below",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.userFallService
        .create(
          this.entryFormFall.value.fallsInput,
          moment(this.entryDateFall).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => this.router.navigate(['/m3/health-data/independence']));
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }



  onDeleteuser180Turn(user180Turn) {
    this.user180TurnService.delete(user180Turn.user_180_turn_id).subscribe((responseData) => {
      //this.activityService.clearCache();
      this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigate(['/m3/health-data/independence']));
    });
  }

  onSubmituser180TurnForm() {
    // this.entryForm.value.date

    if (!this.entryForm180Turn.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something below",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.user180TurnService
        .create(
          this.entryForm180Turn.value.user180TurnsInput,
          moment(this.entryDate180Turn).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => this.router.navigate(['/m3/health-data/independence']));
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  // Short FES
  onDeleteShortFes(shortFes) {
    this.userShortFesService.delete(shortFes.user_shortFes_id).subscribe((responseData) => {
      //this.activityService.clearCache();
      this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigate(['/m3/health-data/independence']));
    });
  }

  onSubmitShortFesForm() {
    // this.entryForm.value.date

    if (!this.entryFormShortFes.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something below",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.userShortFesService
        .create(
          this.entryFormShortFes.value.shortFesInput,
          moment(this.entryDateShortFes).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => this.router.navigate(['/m3/health-data/independence']));
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }
  // End Short FES


  // SSS8
  onDeleteSss8(sss8) {
    this.userSss8Service.delete(sss8.user_sss8_id).subscribe((responseData) => {
      //this.activityService.clearCache();
      this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigate(['/m3/health-data/independence']));
    });
  }

  onSubmitSss8Form() {
    // this.entryForm.value.date

    if (!this.entryFormSss8.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something below",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.userSss8Service
        .create(
          this.entryFormSss8.value.sss8Input,
          moment(this.entryDateSss8).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => this.router.navigate(['/m3/health-data/independence']));
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }
  // End SSS8



  // Distress
  onDeleteDistress(Distress) {
    this.userDistressScoreService.delete(Distress.user_distress_score_id).subscribe((responseData) => {
      //this.activityService.clearCache();
      this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigate(['/m3/health-data/independence']));
    });
  }

  onSubmitDistressForm() {
    // this.entryForm.value.date

    if (!this.entryFormDistressScore.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something below",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.userDistressScoreService
        .create(
          this.entryFormDistressScore.value.distressScoreInput,
          moment(this.entryDateDistressScore).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => this.router.navigate(['/m3/health-data/independence']));
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }
  // End Distress
}
