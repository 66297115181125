import { AuthService } from './../auth/auth.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChallengeService {
  userId;

  mapCacheTime = new Date();
  mapCacheLifetime = 5 * 60 * 1000;
  allMaps: any = null;

  mapProgressCacheTime = new Date();
  mapProgressCacheLifetime = 10 * 60 * 1000;
  allMapProgress: any = null;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userId = user.id;
      }
    });
  }

  fetchMaps(): Observable<any> {
    const now = new Date();

    if (
      !this.allMaps ||
      Math.abs(now.getTime() - this.mapCacheTime.getTime()) >
        this.mapCacheLifetime
    ) {
      this.mapCacheTime = new Date();
      let httpParams = new HttpParams();
      httpParams = httpParams.append('challenge_type', 'map');
      httpParams = httpParams.append('status', '1');
      httpParams = httpParams.append('sort', 'sort_order');
      httpParams = httpParams.append('order', 'ASC');
      (this.allMaps = this.http
        .get<any>(environment.apiUrl + '/challenges', {
          params: httpParams,
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            return responseData._embedded.challenges;
          })
        )),
        publishReplay(1),
        refCount();
    }
    return this.allMaps;
  }

  fetchMapChallengeProgress(): Observable<any> {
    const now = new Date();

    if (
      !this.allMapProgress ||
      Math.abs(now.getTime() - this.mapProgressCacheTime.getTime()) >
        this.mapProgressCacheLifetime
    ) {
      this.mapProgressCacheTime = new Date();
      let httpParams = new HttpParams();
      httpParams = httpParams.append('user_id', '' + this.userId);

      this.allMapProgress = this.http
        .get<any>(environment.apiUrl + '/map_challenge_progress', {
          params: httpParams,
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            return responseData._embedded.map_challenge_progress;
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allMapProgress;
  }

  updateMapProgress(id, completed) {
    this.clearCache();
    const postData = {
      completed,
    };
    return this.http.patch<{ name: string }>(
      environment.apiUrl + '/map_challenge_progress/' + id,
      postData,
      {
        observe: 'response',
      }
    );
  }

  createMapProgress(challenge_id, completed, date_started) {
    this.clearCache();
    const postData = {
      user_id: this.userId,
      challenge_id,
      completed,
      date_started,
    };
    return this.http
      .post<{ name: string }>(
        environment.apiUrl + '/map_challenge_progress',
        postData,
        {
          observe: 'response',
        }
      )
      .pipe(
        map((responseData) => {
          return responseData.body;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetch(challengeId) {
    return this.http
      .get<any>(environment.apiUrl + '/challenges/' + challengeId, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        })
      );
  }

  clearCache() {
    this.allMaps = null;
  }
}
