import { ProgrammeService } from './../../../services/programme.service';
import { UserService } from './../../../services/user.service';
import { AuthService } from './../../../auth/auth.service';
import { CommentService } from './../../../services/comment.service';
import { environment } from './../../../../environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forum-create-topic',
  templateUrl: './forum-create-topic.component.html',
  styleUrls: ['./forum-create-topic.component.scss'],
})
export class ForumCreateTopicComponent implements OnInit {
  environment = environment;

  currentUser;
  currentProgramme;
  isFetching;
  page = 1;
  perPage = 7;
  category;
  unread;
  commentViews = {};
  commentViewsClicked = {};
  commentLikes = {};
  topPage = 1;
  topic;
  comments;

  topicId;
  isDisabled;

  @ViewChild('commentForm', { static: false }) commentForm: NgForm;

  constructor(
    private commentService: CommentService,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService
  ) {}

  ngOnInit(): void {
    // are we logged in?
    this.isFetching = true;
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        if (programmeData) {
          this.currentProgramme = programmeData;
        }
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });
    }
  }

  onSubmit() {
    this.isDisabled = true;

    if (!this.commentForm.valid) {
      Swal.fire({
        title: "We notice you've missed something",
        text: 'Please check the form for information that has been missed',
        icon: 'error',
      });
      this.isDisabled = false;
    } else {
      this.commentService
        .createTopic(
          this.commentForm.value.category,
          this.commentForm.value.subject,
          this.commentForm.value.comment,
          this.currentProgramme.programme_id
        )
        .subscribe(
          (responseData) => {
            this.router.navigate(['/chat']);
          },

          (error) => {
            console.log(error);
          }
        );
    }
  }

  checkEmpty(comment) {
    if (comment) {
      return comment.trim() === '';
    } else {
      return true;
    }
  }
}
