<nav class="subnav">  
  <div *ngIf="currentProgramme && currentUser">
    <a
      *ngIf="currentProgramme.config.buddies == 1 && currentUser.buddy"
      [routerLink]="['/steps']"
      aria-label="Go to steps"
      class="subnav-prev"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
    ></a>

    <a
      *ngIf="currentProgramme.config.buddies != 1 || !currentUser.buddy"
      [routerLink]="['/challenges']"
      aria-label="Challenges"
      class="subnav-prev"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
    ></a>
  </div>
      <h2 *ngIf="currentUser">
        <span class="icon-wrap">
          <fa-icon
            [icon]="['far', 'dumbbell']"
          ></fa-icon>
          
        </span>
       STRENGTH
      </h2>
      <div *ngIf="currentProgramme && currentUser">
        <a
          *ngIf="currentProgramme.config.buddies == 1"
          [routerLink]="['/friends-and-family']"
          aria-label="Go to buddies"
          class="subnav-next"
          ><span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
        ></a>
    
        <a
          *ngIf="currentProgramme.config.buddies != 1"
          [routerLink]="['/target']"
          aria-label="Go to target"
          class="subnav-next"
          ><span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
        ></a>
      </div>
    </nav>
    <div>
        <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
      <div class="masthead masthead-accent masthead-strength" *ngIf="userWorkouts.length > 0">
        <div *ngIf="userWorkouts.length > 0">

          <div class="headline">
            <div class="headline-icon">
              <img src="./assets/img/icon-stength-bands.svg" alt="Icon showing character using strength bands">
            </div>
            <div class="headline-text">
              <strong>{{daysActive}}</strong> <span class="headline-subtext">day<span *ngIf="daysActive!=1">s</span> completed<br> this week</span>
            </div>
          </div>
          <div class="clear-headline"></div>
            
            
            <div class="days">
              <div *ngFor="let day of days" class="day">
               
                <span class="icon-wrap fa fa-check-circle" *ngIf="(userWorkouts | filterObjectArray: { workout_date: day }).length>0"
                  ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
                ></span>
                <span class="icon-wrap fa fa-circle" [ngClass]="{'not-hit': (userWorkouts | filterObjectArray: { workout_date: day }).length === 0 && day<todayFormatted || day>todayFormatted }" *ngIf="(userWorkouts | filterObjectArray: { workout_date: day }).length === 0"
                  ><fa-icon [icon]="['fas', 'circle']"></fa-icon
                ></span><br>
                <span>{{day | dateFormatter:"dd" | slice :0:1}}</span>
              </div>
            </div>

        </div>
        <div class="row">
          <div class="col-12">
            <p class="masthead-subtext tracker-subtext">
              Last updated on
              {{ userWorkouts[0].created | dateToISO | dateFormatter: "D" | ordinal }}
              {{ userWorkouts[0].created | dateToISO | dateFormatter: "MMM yyyy" }}
            </p>
          </div>
        </div>
      </div>
    
      <div *ngIf="userWorkouts.length === 0">
        <p class="lead text-center m-4">You haven't added any workouts yet.</p>
      </div>
    </div>
    
    <div class="alert alert-success" *ngIf="daysActive===2"><span class="icon-wrap" aria-label="Add workout"
      ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon></span
  > Well done for completing your second strength session of the week</div>
    <div>

      <div class="alert alert-success" *ngIf="userWorkouts.length===1"><span class="icon-wrap" aria-label="Add workout"
        ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon></span
    > Well done for logging your first strength session. Try and complete at least two strength sessions a week</div>
      <div>
      
      <div class="toggle-container">
        <div
          class="toggle-header toggle-header-left"
          [routerLink]="['/activity','strength', 'add']"
        >
          <a [routerLink]="['/activity','strength', 'add']" class="toggle-nav toggle-nav-expand"
            ><span class="icon-wrap" aria-label="Add workout"
              ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
          ></a>
          <div class="toggle-header-title">ADD STRENGTH WORKOUT</div>
        </div>
      </div>
    
      
      
      <div class="toggle-container">
        <div
          class="toggle-header toggle-header-left"
          (click)="toggleRow.entry = !toggleRow.entry"
        >
          <a href="" (click)="$event.preventDefault()" class="toggle-nav toggle-nav-expand"
            ><span class="icon-wrap" aria-label="Entry Archive Expand"
              ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span
          ></a>
          <div class="toggle-header-title">WOKROUT ENTRIES</div>
        </div>
        <div
          class="toggle-content"
          *ngIf="toggleRow.entry || showAllRows"
          [attr.aria-expanded]="toggleRow.entry || showAllRows"
        >
          <table *ngIf="userWorkouts.length > 0" class="table">
            <thead>
              <tr>
                <th class="text-left">DATE</th>
                <th class="text-left">MINUTES</th>
                <th class="text-left">EFFORT</th>
                <th class="text-left">TOTAL REPS</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let userWorkout of userWorkouts">
                <td class="text-left">
                  {{ userWorkout.workout_date | dateToISO | dateFormatter: "D" | ordinal }}
                  {{ userWorkout.workout_date | dateToISO | dateFormatter: "MMM yyyy" }}
                </td>
                <td class="text-left">
                    {{ userWorkout.minutes }}
                </td>
                <td class="text-left">
                  <div class="strength-entry-circle bg-effort-{{ userWorkout.effort }}">{{ userWorkout.effort }}</div>
                  
                </td>
                <td class="text-left">
                    <span *ngIf="userWorkout.reps">{{ userWorkout.reps }}</span>
                    <span *ngIf="!userWorkout.reps">-</span>
                </td>
                <td>
                  <a
                   [routerLink]="['/activity', 'strength', 'view', userWorkout.user_strength_workout_id]"
                    class="pointer"
                    ><span class="icon-wrap"
                      ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="userWorkouts.length ===0">There are no records</div>
        </div>
      </div>
    
    </div>
    