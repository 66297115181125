<nav class="subnav">
    <a [routerLink]="['/activity', 'minutes']" aria-label="Go to target" class="subnav-prev"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
    ></a>
    <h2>
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'running']"></fa-icon
      ></span>
      MY WORKOUTS
    </h2>
  
    <a
      [routerLink]="['/achievements']"
      aria-label="Go to Achievements"
      class="subnav-next"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
    ></a>
  </nav>
  <div *ngIf="isFetching">
    <app-loading-spinner [inline]="1"></app-loading-spinner>
  </div>
  
  <div *ngIf="currentProgramme && currentUser">
    <div class="masthead masthead-accent masthead-strength">
      <div class="masthead-pad-top">
        <div *ngIf="currentWeek && currentTarget">
          <div class="headline">
            <div class="headline-icon">
              <span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'running']"></fa-icon
              ></span>
            </div>
  
            <div class="headline-text">
              <div class="headline-target">
                <span class="icon-wrap"
                  ><fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon
                ></span>
                {{ +currentTarget.target }} workouts per week
              </div>
              <strong>{{ daysHit }}</strong>
              <span class="headline-subtext"
                >workout<span *ngIf="daysHit != 1">s</span><br />
                this week</span
              >
            </div>
          </div>
          <div class="clear-headline"></div>
  
          <div class="days">
            <div *ngFor="let day of currentWeek; let index = index" class="day">
              <div *ngIf="weekData[index]">
                <span
                  class="icon-wrap fa fa-check-circle"
                  *ngIf="
                    weekData[index].hit
                  "
                  ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
                ></span>
                <span
                  class="icon-wrap fa fa-circle"
                  [ngClass]="{ 'not-hit': 1 == 1 }"
                  *ngIf="
                    !weekData[index].hit
                  "
                  ><fa-icon [icon]="['fas', 'circle']"></fa-icon
                ></span>
              </div>
  
              <br />
              <span>{{ day | dateFormatter: "dd" | slice: 0:1 }}</span>
            </div>
          </div>
        </div>
        <div *ngIf="!currentTarget">
          <div class="masthead-strap">PLEASE SET A TARGET</div>
  <p class="masthead-subtext col-md-6 offset-md-3">In addition to your stepping activity how many times a week would you like to do a Workout? i.e. a swimming session, a home based exercise workout, etc..</p>
          <form name="targetForm" class="bs-form tracker-form">
            <div class="row">
              <div class="col-md-4 offset-md-4">
                <div class="form-group">
                  <label for="minsTopTarget">WORKOUTS PER WEEK</label>
                  <input
                    type="number"
                    name="mins"
                    class="form-control input-tracker input-tracker-sm"
                    placeholder=""
                    id="minsTopTarget"
                    [(ngModel)]="targetMins"
                    #targetMinsInput="ngModel"
                    min="1"
                    max="21"
                    autocomplete="off"
                    ng-required=""
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="targetMinsInput.touched && targetMinsInput.invalid"
                  >
                    This must be a number between 1 and 21.
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                (click)="submitTarget(targetMins)"
                class="btn btn-primary btn-lg btn-white"
                [disabled]="targetMinsInput.invalid"
              >
                SET TARGET
              </button>
            </div>
          </form>
        </div>
      </div>
  
      <!--div class="row">
                  <div class="col-6" [ngClass]="{'col-offset-3':currentUser.manual_steps}">
                      <h3 class="tracker-title-sm" style="font-size:23px;">EXERCISE TODAY</h3>
                      <div class="row">
                          <div class="col-12 text-center"><img src="./assets/img/icon-stopwatch.png" width="77" alt="Stopwatch" style="display:inline-block;top:-20px;position:relative;">
                             <div  style="display:inline-block;"><span class="tracker-value tracker-value-lg">{{(dailyMinutes.minutes)}}</span><br>
                              <span class="tracker-value tracker-value-sm-nf">MINUTES</span>
                             </div>
                          </div>
                      
                      </div>
                      
                      
                  </div>
                  <div class="col-6"  *ngIf="!currentUser.manual_steps">
                      <h3 class="tracker-title-sm" style="font-size:23px;">INTENSITY</h3>
                      <table class="widget-table" role="presentation">
                          <tr>
                              <td class="text-right"> LIGHT :</td><td>&nbsp; {{dailyMinutes.lightly_active_minutes}} min</td>
                          </tr>
                          <tr>
                              <td class="text-right">MODERATE :</td><td>&nbsp; {{dailyMinutes.fairly_active_minutes}} min</td>
                          </tr>
                          <tr>
                              <td class="text-right">VIGOROUS :</td><td>&nbsp; {{dailyMinutes.very_active_minutes}} min</td>
                          </tr>
                      </table>
                      
                  </div>
              </div>
               <div class="row">
                   <div class="col-12">
                      <p class="masthead-subtext tracker-subtext">Aim for 120 minutes of exercise per day</p>
                   </div>
               </div-->
    </div>
  
    <div class="toggle-container">
      <div
        class="toggle-header toggle-header-left"
        (click)="toggleRow.update = !toggleRow.update"
      >
        <a
          href=""
          class="toggle-nav toggle-nav-expand"
          (click)="$event.preventDefault()"
        >
          <span class="icon-wrap" aria-label="Down" *ngIf="!toggleRow.update"
            ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
          ></span>
          <span class="icon-wrap" aria-label="Up" *ngIf="toggleRow.update"
            ><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon
          ></span>
        </a>
  
        <div class="toggle-header-title">ADD WORKOUT</div>
      </div>
      <div class="toggle-content" *ngIf="toggleRow.update">
        <form
          name="minsForm"
          class="bs-form tracker-form"
          (ngSubmit)="onSubmitForm()"
          #minsForm="ngForm"
        >
          <p class="instruction">
            You can add a workout for today or any previous days using the form
            below.
          </p>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="weight">MINUTES</label>
                <input
                  type="number"
                  style="font-size: 28px"
                  name="mins"
                  class="form-control input-tracker input-tracker-xsm"
                  placeholder="e.g. 15"
                  id="mins"
                  ngModel
                  min="0"
                  max="1000000000"
                  autocomplete="off"
                  ng-required=""
                  #mins="ngModel"
                />
                <div
                  class="alert alert-danger"
                  *ngIf="mins.touched && mins.invalid"
                >
                  This must be a number below 1000000000.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="number">DATE</label>
                <input
                  name="date"
                  type="text"
                  placeholder="DD/MM/YYYY"
                  class="form-control input-tracker input-tracker-sm"
                  bsDatepicker
                  autocomplete="off"
                  [bsConfig]="{
                    dateInputFormat: 'DD/MM/YYYY',
                    containerClass: 'theme-cv',
                    showWeekNumbers: false
                  }"
                  [(ngModel)]="date"
                  required
                  [bsValue]="date"
                  [maxDate]="date"
                  #dateInput="ngModel"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="weight">EFFORT</label>
                <input
                  type="number"
                  style="font-size: 28px"
                  name="effort"
                  class="form-control input-tracker input-tracker-xsm"
                  placeholder="e.g. 5"
                  id="effort"
                  ngModel
                  min="0"
                  max="10"
                  autocomplete="off"
                  ng-required=""
                  #effort="ngModel"
                />
                <p class="instructions">On a scale of 0 - 10 where 0 is no effort and 10 is maximal effort</p>
                <div
                  class="alert alert-danger"
                  *ngIf="effort.touched && effort.invalid"
                >
                  This must be a number below 1000000000.
                </div>
              </div>
            </div>
  
            <div class="col-md-6">
              <div class="form-group">
                <label for="exercise">EXERCISE</label>
                <select
                  type="number"
                  name="exercise"
                  class="custom-select custom-select-lg"
                  id="exercise"
                  [ngModel]
                  ng-required=""
                  #exercise="ngModel"
                >
  
                <option [ngValue]="null">Please choose an exercise</option>
                  <option
                    *ngFor="let exercise of exercises"
                    [ngValue]="exercise.exercise_id"
                  >
                    {{ exercise.name }}
                  </option>
                </select>
                <div
                  class="alert alert-danger"
                  *ngIf="exercise.touched && exercise.invalid"
                >
                  This must be a number below 1000000000.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="comments">Comments</label>
                <textarea
                  name="comments"
                  class="form-control"
                  id="comments"
                  ngModel
                  #comments="ngModel"
                  placeholder="You can enter a comment here"
                >
                </textarea>
                <div
                  class="alert alert-danger"
                  *ngIf="comments.touched && comments.invalid"
                >
                  Please enter a comment.
                </div>
              </div>
            </div>
          </div>
          <div class="tracker-submit-container">
            <button
              type="submit"
              role="button"
              class="btn btn-primary btn-lg btn-white"
            >
              ADD WORKOUT
            </button>
          </div>
        </form>
      </div>
    </div>
  
    <div class="toggle-container" *ngIf="currentTarget">
      <div
        class="toggle-header toggle-header-left"
        (click)="toggleRow.target = !toggleRow.target"
      >
        <a
          href=""
          class="toggle-nav toggle-nav-expand"
          (click)="$event.preventDefault()"
          ><span class="icon-wrap" aria-label="Down" *ngIf="!toggleRow.target"
            ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
          ></span>
          <span class="icon-wrap" aria-label="Up" *ngIf="toggleRow.target"
            ><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon></span
        ></a>
  
        <div class="toggle-header-title">SET WORKOUTS TARGET</div>
      </div>
      <div class="toggle-content" *ngIf="toggleRow.target">

  <p>In addition to your stepping activity how many times a week would you like to do a Workout? i.e. a swimming session, a home based exercise workout, etc..</p>
        <form name="targetForm" class="bs-form tracker-form">
          <p class="instruction"></p>
          <div class="row">
            <div class="col-md-4 offset-md-4">
              <div class="form-group">
                <label for="weight">WORKOUTS PER WEEK</label>
                <input
                  type="number"
                  name="mins"
                  class="form-control input-tracker input-tracker-sm"
                  placeholder=""
                  id="mins"
                  [(ngModel)]="targetMins"
                  #targetMinsInput="ngModel"
                  min="1"
                  max="21"
                  autocomplete="off"
                  ng-required=""
                />
                <div
                  class="alert alert-danger"
                  *ngIf="targetMinsInput.touched && targetMinsInput.invalid"
                >
                  This must be a number between 1 and 21.
                </div>
              </div>
            </div>
          </div>
          <div class="tracker-submit-container">
            <button
              (click)="submitTarget(targetMins)"
              class="btn btn-primary btn-lg btn-white"
              [disabled]="targetMinsInput.invalid"
            >
              UPDATE TARGET
            </button>
          </div>
        </form>
      </div>
    </div>
  
    <div class="toggle-container">
      <div class="toggle-header toggle-header-sub">
        <a
          href=""
          *ngIf="previousWeek"
          (click)="$event.preventDefault(); previous()"
          class="toggle-nav toggle-nav-prev"
          ><span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
        ></a>
        <a
          href=""
          *ngIf="nextWeek.startDate < now"
          (click)="$event.preventDefault(); next()"
          class="toggle-nav toggle-nav-next"
          ><span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
        ></a>
        <div class="toggle-header-title">WEEKLY WORKOUTS</div>
        <div class="toggle-header-subtitle">
          {{ currentWeek[0] | dateFormatter: "DD/MM/yyyy" }} -
          {{ currentWeek[6] | dateFormatter: "DD/MM/yyyy" }}
        </div>
      </div>
      <div class="toggle-content">
        <div class="container">
        
          
  
          <table class="table table-workouts">
            <thead>
              <tr>
                <th width="20"></th>
                <th style="text-align: left">DAY</th>
                <th style="text-align: right">MINUTES</th>
                <th style="text-align: right">EFFORT</th>
                <th style="text-align: right"></th>
                <th></th>
              </tr>
            </thead>
            <tbody >
              <ng-container
                *ngFor="let dayData of currentWeek; let index = index"
              >
                <tr [ngClass]="{ current: ( dayData | dateFormatter: 'yyyy-MM-dd' ) == now }">
                  <td>
                    <!--span
                      class="icon-wrap"
                      *ngIf="weekData[index].minutes * 1 < currentTarget.target * 1"
                      ><fa-icon [icon]="['far', 'star']"></fa-icon
                    ></span>
  
                    <span
                      class="icon-wrap hit-goal"
                      *ngIf="
                        weekData[index].minutes * 1 >= currentTarget.target * 1 &&
                        weekData[index].minutes > 0
                      "
                      ><fa-icon [icon]="['fas', 'star']"></fa-icon
                    ></span-->
                  </td>
                  <td>{{ dayData | dateFormatter: "dddd" | uppercase }}</td>
                  <td class="text-right">
                    {{workoutMinutesForDate(dayData)}}
                  </td>
                  <td></td>
                  <td></td>
                  
                  <td width="20"></td>
                </tr>
                <!-- workouts? -->
                <ng-container
                  *ngFor="
                    let workout of workoutsByDate(dayData)
                  "
                >
                  <tr class="tr-workout">
                    <td class="text-right"></td>
                    <td>{{ workout.exercise }}</td>
                    <td class="text-right">{{ workout.minutes }}</td>
                    <td class="text-right">
                      <span
                        class="pull-right effort-circle bg-effort-{{
                          workout.effort
                        }}"
                        >{{ workout.effort }}</span
                      >
                    </td>
                    <td class="text-right">
                      <a *ngIf="workout.comments" class="pointer"
                      [popover]="workout.comments"
                        >
                        <span class="icon-wrap"
                        ><fa-icon
                          [icon]="['fas', 'comment']"
                        ></fa-icon
                      ></span>
                        </a
                      >
                    </td>
                   
                    <td class="text-right">
                      <a [routerLink]="['/activity','workouts','edit-workout', workout.user_workout_id]">
                      <span class="icon-wrap"
                        ><fa-icon
                          [icon]="['fas', 'chevron-circle-right']"
                        ></fa-icon
                      ></span></a>
                    </td>
                  </tr>
                </ng-container>
                <tr>
                  <td colspan="6" class="td-divider"></td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  
    
    
    <app-section-nav
      [section]="'activity'"
      [active]="'workouts'"
    ></app-section-nav>
  </div>
  