import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as moment from 'moment';
import { Color, Label } from 'ng2-charts';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivityService } from 'src/app/services/activity.service';
import { DateHelperService } from 'src/app/services/date-helper.service';
import { HeartRateService } from 'src/app/services/heart-rate.service';
import { ProgrammeService } from 'src/app/services/programme.service';
import { TargetService } from 'src/app/services/target.service';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-m3-walking',
  templateUrl: './m3-walking.component.html',
  styleUrls: ['./m3-walking.component.scss'],
})
export class M3WalkingComponent implements OnInit {
  currentUser;
  currentProgramme;
  date = new Date();
  currentTarget;
  allTimeSteps;
  weekSummary;
  stepAverage;
  personalisedTarget;
  modalRef?: BsModalRef;
  weeklyAverages: any = [];
  changeInSteps;
  showAllEntries = false;
  @ViewChild('entryForm', { static: false }) entryForm: NgForm;
  @ViewChild('cadenceForm', { static: false }) cadenceForm: NgForm;

  startDate;
  lastWeek;
  endDate;
  isFetching = false;
  currentTargetObj: any;
  myActivity: any;
  perosnalisedTarget: any;
  stepTarget: any;

  // chart

  constructor(
    private authService: AuthService,
    private programmeService: ProgrammeService,
    private router: Router,
    private userService: UserService,
    private userSettingsService: UserSettingsService,
    private activityService: ActivityService,
    private targetService: TargetService,
    private modalService: BsModalService,
    private changeDetector: ChangeDetectorRef,
    private dateHelperService: DateHelperService
  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {});

      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });

      forkJoin({
        userSettings: this.userSettingsService.fetchAll(),
        target: this.targetService.getTarget('step'),
        allTimeSteps: this.activityService.getActivityBetween(
          moment().subtract(1, 'years').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ),
        weekSummary: this.activityService.getActivitySummary(
          moment().subtract(1, 'week').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ),
      }).subscribe((responseData) => {
        this.currentTarget = responseData.target;
        this.allTimeSteps = responseData.allTimeSteps;
        this.weekSummary = responseData.weekSummary[0];
        this.stepAverage = this.activityService.getStepAverage(
          this.allTimeSteps
        );

        let allWeeklyAverages = this.activityService.getWeeklyStepAverages(
          this.allTimeSteps
        );

        let index = 0;
        for (var key in allWeeklyAverages) {
          if (allWeeklyAverages.hasOwnProperty(key)) {
            index++;
            if (index <= 5) {
              this.weeklyAverages.push({
                activity_date: key,
                steps: allWeeklyAverages[key],
              });
            }
          }
        }
        if (this.weeklyAverages.length > 1) {
          // this.changeInSteps = +this.weeklyAverages[1].steps - +this.weeklyAverages[2].steps;
        }

        if (!this.stepAverage || this.stepAverage == 0) {
          if (this.weeklyAverages) {
            let weekStep = false;
            this.weeklyAverages
              .slice()
              .reverse()
              .forEach((week) => {
                if (!weekStep && +week.steps > 0) {
                  this.stepAverage = week.steps;
                  weekStep = true;
                }
              });
          }
        }

        this.changeInSteps = this.activityService.getChangeInSteps(
          this.allTimeSteps
        );
      });
    }
  }

  onSubmitEntryForm() {
    // save the steps
    this.activityService
      .saveSteps(this.entryForm.value.steps, this.entryForm.value.date)
      .subscribe((responseData) => {
        // refresh the route..
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/walking']));

        Swal.fire({
          icon: 'success',
          title: 'Entry Added',
          text: 'Your steps have been added',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      });
  }

  onSubmitCadenceForm() {
    // save the steps

    this.activityService
      .saveWalkingSpeed(this.cadenceForm.value.cadence)
      .subscribe((responseData) => {
        this.userService.reset();
        this.userService.get().subscribe((userData) => {
          this.currentUser = userData;
        });

        Swal.fire({
          icon: 'success',
          title: 'Walking Speed set',
          text: 'Your walking speed has been saved.',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });

        // refresh the route..
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/walking']));
      });
  }

  /*
  onDelete(heartRate) {
    this.heartRateService.delete(heartRate.user_heart_rate_id).subscribe(
      (responseData) => {
        const index = this.heartRates.indexOf(heartRate);
        this.heartRates.splice(index, 1);
        this.setChartData();
      },
      (error) => {
        console.log(error);
      }
    );
  }*/

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.changeDetector.detectChanges();
  }

  getStepsRag(averageSteps: number) {
    return this.activityService.getStepsRag(averageSteps);
  }

  syncSteps(force) {
    force = typeof force === 'undefined' ? '0' : force;
    this.activityService.getActivity(force).subscribe((responseData) => {
      this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigate(['/m3', 'walking']));
    });
  }

  onDelete(sitting) {
    this.activityService
      .deleteSteps(sitting.user_activity_id)
      .subscribe((responseData) => {
        //this.activityService.clearCache();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/walking']));
      });

    /*
    this.activityService.delete(sleep.user_sleep_id).subscribe(
      (responseData) => {
        this.sleepService.clearCache();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/sleep']));
      },
      (error) => {
        console.log(error);
      }
    );*/
  }

  checkStepTarget() {
    const now = moment();
    // what is the day today
    this.startDate = moment(now).subtract(1, 'weeks');
    this.lastWeek = this.dateHelperService.getWeekDayFor(now);
    this.startDate = moment(this.startDate).format('YYYY-MM-DD');
    this.endDate = moment(now).subtract(1, 'days').format('YYYY-MM-DD');

    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;

        // do it
        const threeDaysAgo = moment().subtract('4', 'days');
        const weekAgo = moment().subtract('7', 'days');
        const created = moment(user.created);
        let startDay = created;
        if (threeDaysAgo > created) {
          startDay = threeDaysAgo;
        }

        this.startDate = moment(startDay).format('YYYY-MM-DD');
        this.endDate = moment(now).subtract(1, 'days').format('YYYY-MM-DD');

        // get monday last week
        const lastMonday = moment().isoWeekday(-6);
        const lastSunday = moment(lastMonday).add(6, 'days');
        const lastLastMonday = moment().isoWeekday(-13);

        this.isFetching = true;
        forkJoin({
          userSettings: this.userSettingsService.fetchAll(),
          activity: this.activityService.getActivity(0),
          target: this.targetService.getTarget('step'),
          activitySummary: this.activityService.getActivitySummary(
            this.startDate,
            this.endDate
          ),
          activityBetween: this.activityService.getActivityBetween(
            this.startDate,
            this.endDate
          ),
          activityLastWeek: this.activityService.getActivitySummary(
            lastMonday.format('YYYY-MM-DD'),
            lastSunday.format('YYYY-MM-DD')
          ),
          activityLastLastWeek: this.activityService.getActivitySummary(
            lastLastMonday.format('YYYY-MM-DD'),
            lastSunday.format('YYYY-MM-DD')
          ),
        }).subscribe(
          (responseData) => {
            this.isFetching = false;
            this.currentTargetObj = responseData.target;
            this.myActivity = responseData.activitySummary[0];

            if (this.currentTargetObj) {
              // can they update based on last week?

              if (
                +responseData.activityLastLastWeek[0].step_avg >=
                  +this.currentTargetObj.target &&
                +responseData.activityLastWeek[0].step_avg >=
                  +this.currentTargetObj.target
              ) {
                if (+responseData.activityLastWeek[0].step_avg < 2000) {
                  this.perosnalisedTarget = Math.round(
                    +responseData.activityLastWeek[0].step_avg + 100
                  );
                } else {
                  this.perosnalisedTarget = Math.round(
                    +responseData.activityLastWeek[0].step_avg + 500
                  );
                }
                if (this.perosnalisedTarget >= 10000) {
                  this.perosnalisedTarget = 10000;
                }
              } else if (
                +responseData.activityLastWeek[0].step_avg >=
                +this.currentTargetObj.target
              ) {
                this.perosnalisedTarget = this.currentTargetObj.target;
              } else if (
                responseData.activityLastWeek[0].step_avg <
                  +this.currentTargetObj.target &&
                this.currentUser.created < lastMonday.format('ddd Do MMM')
              ) {
                if (responseData.activityLastWeek[0].step_avg < 500) {
                  this.perosnalisedTarget = Math.round(
                    +this.currentTargetObj.target - 500
                  );
                } else {
                  if (+responseData.activityLastWeek[0].step_avg < 2000) {
                    this.perosnalisedTarget = Math.round(
                      +responseData.activityLastWeek[0].step_avg + 100
                    );
                  } else {
                    this.perosnalisedTarget = Math.round(
                      +responseData.activityLastWeek[0].step_avg + 500
                    );
                  }
                }
              }

              if (this.perosnalisedTarget >= 10000) {
                this.perosnalisedTarget = 10000;
              }
              if (this.perosnalisedTarget > 0) {
                this.targetService
                  .create(this.perosnalisedTarget, 'step', 'steps')
                  .subscribe((responseData) => {
                    this.stepTarget = responseData;
                    Swal.fire({
                      position: 'top-end',
                      text: 'Your step target was updated',
                      icon: 'success',
                      toast: true,
                      showConfirmButton: false,
                      timer: 1000,
                    });
                    this.router
                      .navigateByUrl('/', { skipLocationChange: true })
                      .then(() => this.router.navigate(['/m3', 'walking']));
                  });
              }
            }
          },
          (error) => {
            this.isFetching = false;
          }
        );
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });
    }
  }
}
