import { map, catchError } from 'rxjs/operators';
import { AuthService } from './../auth/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { throwError, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  userId;
  endPoint = '/notifications';
  updateNotifications = new BehaviorSubject(null);

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userId = user.id;
      }
    });
  }
  getNotifications(status) {
    if (!this.userId) {
      return;
    }
    let searchParams = new HttpParams();

    searchParams = searchParams.append('user_id', this.userId);
    searchParams = searchParams.append('status', status);
    searchParams = searchParams.append('sort', 'created');
    searchParams = searchParams.append('order', 'DESC');
    return this.http
      .get<any>(environment.apiUrl + this.endPoint, {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.notifications;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  addNotification(user_id, notification_type, content, link, status) {
    const postData = {
      user_id: user_id,
      notification_type,
      content,
      link,
      status,
    };
    return this.http
      .post<{ name: string }>(environment.apiUrl + this.endPoint, postData, {
        observe: 'response',
      })
      .pipe(
        map((responseData) => {
          return responseData.body;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  update(id, notification_type, content, link, status) {
    const postData = {
      notification_type,
      content,
      link,
      status,
    };
    return this.http.patch<{ name: string }>(
      environment.apiUrl + this.endPoint + '/' + id,
      postData,
      {
        observe: 'response',
      }
    );
  }

  refreshNotifications(){
    this.updateNotifications.next(true);
  }
}
