import { environment } from 'src/environments/environment';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-step-drag-and-drop-selection',
  templateUrl: './step-drag-and-drop-selection.component.html',
  styleUrls: ['./step-drag-and-drop-selection.component.scss'],
})
export class StepDragAndDropSelectionComponent implements OnInit {
  @Input() dragAndDrop;
  @Input() dragItems;
  correct = 0;
  incorrect = 0;
  environment = environment;

  constructor() {}

  ngOnInit(): void {
    this.dragAndDrop.state = 'default';
  }

  selectItem(item) {
    if (this.dragAndDrop.state != 'answered') {
      this.dragItems.forEach( (dItem, dIndex) => {
        if (dItem.drag_item_id == item.drag_item_id) {
          if (this.dragItems[dIndex].state == 'selected') {
            this.dragItems[dIndex].state = 'default';
          } else {
            this.dragItems[dIndex].state = 'selected';
          }
        }
      });
    }
  }
  submitSelect() {
    this.correct = 0;
    this.incorrect = 0;
    this.dragItems.forEach( (dItem, dIndex) => {
      if (dItem.state == 'selected' && dItem.correct == 1) {
        this.dragItems[dIndex].state = 'selectedCorrect';
        this.correct++;
      }
      if (dItem.state == 'default' && dItem.correct == 1) {
        this.dragItems[dIndex].state = 'unselectedCorrect';
      }
      if (dItem.state == 'selected' && dItem.correct != 1) {
        this.dragItems[dIndex].state = 'selectedIncorrect';
        this.incorrect++;
      }
      if (dItem.state == 'default' && dItem.correct != 1) {
        this.dragItems[dIndex].state = 'unselectedIncorrect';
      }
    });
    this.dragAndDrop.state = 'answered';
  }
}
