<nav class="subnav">
  <a [routerLink]="['/health-trackers','blood-pressure']" class="subnav-prev" aria-label="Go to Blood Pressure">
    <span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span>
    </a>
  <h2> <span class="icon-wrap"><fa-icon [icon]="['fas', 'heartbeat']"></fa-icon></span> CHOLESTEROL</h2>
  <a [routerLink]="['/health-trackers','smoking']" class="subnav-next" aria-label="Go to Smoking"
    ><span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span></a>
</nav>
<div>
  <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
  <div class="masthead masthead-accent masthead-cholesterol">
    <div *ngIf="userCholesterols.length > 0">
      <div class="row mt-3">
        <div class="col-12">
          <div class="row">
            <div class="col-3">
              <span class="tracker-value tracker-value-md tracker-value-title"
                >TOTAL</span
              ><br />
              <span class="tracker-value tracker-value-lg">{{
                userCholesterols[0].cholesterol | number :'1.2-2'
              }}</span
              ><br />
              <span class="tracker-value tracker-value-sm" style="float: none"
                >mmol/L</span
              >
            </div>
            <div class="col-3">
              <img src="./assets/img/icon-artery.png" width="75" alt="Artery" />
            </div>
            <div class="col-3">
              <span class="tracker-value tracker-value-md tracker-value-title"
                >LDL</span
              ><br />
              <span class="tracker-value tracker-value-lg">{{
                userCholesterols[0].ldl | number :'1.2-2'
              }}</span
              ><br />
              <span class="tracker-value tracker-value-sm" style="float: none"
                >mmol/L</span
              >
            </div>
            <div class="col-3">
              <span class="tracker-value tracker-value-md tracker-value-title"
                >HDL</span
              ><br />
              <span class="tracker-value tracker-value-lg">{{
                userCholesterols[0].hdl | number :'1.2-2'
              }}</span
              ><br />
              <span class="tracker-value tracker-value-sm" style="float: none"
                >mmol/L</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="masthead-subtext tracker-subtext">
            Last updated on
            {{ userCholesterols[0].created | dateToISO | dateFormatter: "D" | ordinal }}
            {{ userCholesterols[0].created | dateToISO | dateFormatter: "MMM yyyy" }}
          </p>
        </div>
      </div>
    </div>

    <div *ngIf="userCholesterols.length === 0">
      <h4>You haven't added any readings yet!</h4>
    </div>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.update = !toggleRow.update"
    >
      <a href="" class="toggle-nav toggle-nav-expand" (click)="$event.preventDefault()"><span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span></a>
      <div class="toggle-header-title">UPDATE TRACKER</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.update">
      <form
        class="bs-form tracker-form"
        name="trackerForm"
        method="post"
        (ngSubmit)="onSubmit()"
        #trackerForm="ngForm"
      >
        <div>
          <div class="form-group">
            <label for="cholesterol">TOTAL CHOLESTEROL MEASURE (mmol/L)</label>
            <p class="instruction">
              Please enter your latest Cholesterol measure below in mmol/L.
            </p>
            <input
              type="number"
              name="cholesterol"
              class="form-control input-tracker"
              placeholder=""
              id="cholesterol"
              [ngModel]
              #cholesterol="ngModel"
              [min]="0"
              [max]="10"
              autocomplete="off"
              [ngClass]="{ 'is-invalid': cholesterol.touched && !cholesterol.valid }"
            />
            <div
              class="alert alert-danger"
              *ngIf="cholesterol.errors?.min || cholesterol.errors?.max"
            >
              This must be a number between 0 and 10.
            </div>
          </div>

          <div class="form-group">
            <label for="ldl">LDL (mmol/L)</label>

            <p class="instruction">
              Please enter your latest LDL Cholesterol measure below in mmol/L.
            </p>
            <input
              type="number"
              name="ldl"
              class="form-control input-tracker"
              placeholder=""
              [ngModel]
              id="ldl"
              [min]="0"
              [max]="10"
              autocomplete="off"
              #ldl="ngModel"
            />
            <div
              class="alert alert-danger"
              *ngIf="ldl.errors?.min || ldl.errors?.max"
            >
              This must be a number between 0 and 10.
            </div>
          </div>

          <div class="form-group">
            <label for="hdl">HDL (mmol/L)</label>
            <p class="instruction">
              Please enter your latest HDL Cholesterol measure below in mmol/L.
            </p>
            <input
              type="number"
              name="hdl"
              class="form-control input-tracker"
              placeholder=""
              id="hdl"
              [ngModel]
              [min]="0"
              [max]="10"
              autocomplete="off"
              #hdl="ngModel"
            />
            
            <div
              class="alert alert-danger"
              *ngIf="hdl.errors?.min || hdl.errors?.max"
            >
              This must be a number between 0 and 10.
            </div>
          </div>

          <div class="form-group">
            <label for="entryDate">DATE</label>
            <p class="instructions">
              When did you do take this reading?
            </p>
            <input
              name="date"
              type="text"
              placeholder="DD/MM/YYYY"
              class="form-control input-tracker"
              bsDatepicker
              autocomplete="off"
              [bsConfig]="{
                dateInputFormat: 'DD/MM/YYYY',
                containerClass: 'theme-cv',
                showWeekNumbers: false
              }"
              [(ngModel)]="entryDate"
              required
              [bsValue]="entryDate"
              [maxDate]="maxDate"
              #date="ngModel"
            />
          </div>
          <div class="tracker-submit-container">
            <button

            [disabled]="!trackerForm.valid || trackerForm.pristine"
              type="submit"
              class="btn btn-primary btn-white"
            >
            <span class="icon-wrap"><fa-icon [icon]="['fas', 'check']"></fa-icon></span> CONFIRM
            </button>
          </div>
        </div>
      </form>

      <!--span *ngIf="updatedToday()">You have already updated today</span-->
    </div>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.graph = !toggleRow.graph"
    >
      <a href="" class="toggle-nav toggle-nav-expand" (click)="$event.preventDefault()"
        ><span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span></a>
      <div class="toggle-header-title">TRACKER GRAPH</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.graph">
      <div *ngIf="userCholesterols.length > 0">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs nav-tabs-duo justify-content-center" role="tablist">
          <li role="presentation" [ngClass]="{ active: toggleChart.total }">
            <a
              aria-controls="total"
              role="tab"
              data-toggle="tab"
              (click)="toggleChart.total = true;toggleChart.ldl = false;toggleChart.hdl = false;"
              >TOTAL</a
            >
          </li>
          <li role="presentation" [ngClass]="{ active: toggleChart.ldl }">
            <a
              aria-controls="ldl"
              role="tab"
              data-toggle="tab"
              (click)="toggleChart.total = false;toggleChart.ldl = true;toggleChart.hdl = false;"
              >LDL</a
            >
          </li>
          <li role="presentation" [ngClass]="{ active: toggleChart.hdl }">
            <a
              aria-controls="hdl"
              role="tab"
              data-toggle="tab"
              (click)="toggleChart.total = false;toggleChart.ldl = false;toggleChart.hdl = true;"
              >HDL</a
            >
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <div
            role="tabpanel"
            id="toggleChart.total"
            *ngIf="toggleChart.total"
          >
            <div *ngIf="totalChartData[0].data.length > 1">
              <canvas
                class="mt-3 mb-3 tracker-chart"
                    baseChart
                    [datasets]="totalChartData"
                    [labels]="totalChartLabels"
                    [options]="totalChartOptions"
                    [legend]="totalChartLegend"
                    [chartType]="totalChartType"
                    [colors]="totalChartColors"
                >
                </canvas>
              <!--canvas id="line" class="chart chart-line tracker-chart" chart-data="data" chart-labels="labels" chart-series="series" chart-options="options"
                                chart-dataset-override="datasetOverride" chart-click="onClick" height="200"  aria-label="Bar Chart Values {{data}}">
                                </canvas-->
            </div>
            <p class="text-center" *ngIf="totalChartData[0].data.length < 2">
              Please add more entries to see the graph
            </p>
          </div>
          <div
            role="tabpanel"
            id="toggleChart.ldl"
            *ngIf="toggleChart.ldl"
          >
            <div *ngIf="ldlChartData[0].data.length > 1">
              <canvas
                class="mt-3 mb-3 tracker-chart"
                    baseChart
                    [datasets]="ldlChartData"
                    [labels]="totalChartLabels"
                    [options]="totalChartOptions"
                    [legend]="totalChartLegend"
                    [chartType]="totalChartType"
                    [colors]="totalChartColors"
                >
                </canvas>
            </div>
            <p class="text-center" *ngIf="ldlChartData[0].data.length < 2">
              Please add more entries to see the graph
            </p>
          </div>
          <div
            role="tabpanel"
            id="toggleChart.hdl"
            *ngIf="toggleChart.hdl"
          >
            <div *ngIf="hdlChartData[0].data.length > 1">
              <canvas
                class="mt-3 mb-3 tracker-chart"
                    baseChart
                    [datasets]="hdlChartData"
                    [labels]="totalChartLabels"
                    [options]="totalChartOptions"
                    [legend]="totalChartLegend"
                    [chartType]="totalChartType"
                    [colors]="totalChartColors"
                >
                </canvas>
            </div>
            <p class="text-center" *ngIf="hdlChartData[0].data.length < 2">
              Please add more entries to see the graph
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="userCholesterols.length === 0">
        <p>There are no readings to display in a graph</p>
      </div>
    </div>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.entry = !toggleRow.entry"
    >
      <a href="" class="toggle-nav toggle-nav-expand" (click)="$event.preventDefault()"
        ><span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span></a>
      <div class="toggle-header-title">ENTRY ARCHIVE</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.entry">
      <table *ngIf="userCholesterols.length > 0" class="table">
        <thead>
          <tr>
            <th>DATE</th>
            <th>TOTAL</th>
            <th>LDL</th>
            <th>HDL</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let userCholesterol of userCholesterols">
            <td class="text-center">
              {{ userCholesterol.created | dateToISO | dateFormatter: "D" | ordinal }}
              {{ userCholesterol.created | dateToISO | dateFormatter: "MMM yyyy" }}
            </td>
            <td class="text-center">{{ userCholesterol.cholesterol }}</td>
            <td class="text-center">{{ userCholesterol.ldl }}</td>
            <td class="text-center">{{ userCholesterol.hdl }}</td>
            <td>
              <a
              [swal]="{ title: 'Are you sure you want to delete this?', icon:'warning', showCancelButton: true }"
              (confirm)="onRemove(userCholesterol)"
              class="pointer"
                ><span class="icon-wrap"><fa-icon [icon]="['fas', 'trash']"></fa-icon></span></a>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="userCholesterols.length === 0">There are no records</div>
    </div>
  </div>
  <!-- decision maker -->
  <hr class="divider-tracker" />
  <div class="text-center decision-maker-block">
    <p>
      Want to set goals to reduce your cholesterol levels?<br />
      Try our Decision Maker tool
    </p>
    <a [routerLink]="['/decision-maker']" class="btn btn-primary"
      ><span class="icon-wrap"><fa-icon [icon]="['fas', 'map-signs']"></fa-icon></span> DECISION MAKER</a
    >
  </div>



<app-section-nav [section]="'healthTrackers'" [active]="'cholesterol'"></app-section-nav>

</div>
