import { forkJoin } from 'rxjs';
import { UserService } from './../services/user.service';
import { ProgrammeService } from './../services/programme.service';
import { AuthService } from './../auth/auth.service';
import { AwardService } from './../services/award.service';
import { ActivityService } from './../services/activity.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-achievements',
  templateUrl: './achievements.component.html',
  styleUrls: ['./achievements.component.scss'],
})
export class AchievementsComponent implements OnInit {
  MfGold = 0;
  MfSilver = 0;
  MfBronze = 0;

  wGold = 0;
  wSilver = 0;
  wBronze = 0;

  lbGold = 0;
  lbSilver = 0;
  lbBronze = 0;

  latestAward;
  currentProgramme;
  currentUser;
  isFetching;
  awards;

  errors = [];

  constructor(
    private activityService: ActivityService,
    private awardService: AwardService,
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService
  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });

      forkJoin({
      //  activity: this.activityService.getSteps(),
        awards: this.awardService.fetchAll(),
      }).subscribe(
        (responseData) => {
          this.isFetching = false;

          this.awards = responseData.awards;
          this.awards.forEach((award) => {
            if (!this.latestAward && award.award_type === 'challenge') {
              this.latestAward = award;
            }
            if (award.award_rank == '1') {
              if (award.award_sub_type === 'mon-fri') {
                this.MfGold++;
              }
              if (award.award_sub_type === 'weekend') {
                this.wGold++;
              }
              if (award.award_sub_type === 'global') {
                this.lbGold++;
              }
            }
            if (award.award_rank == '2') {
              if (award.award_sub_type === 'mon-fri') {
                this.MfSilver++;
              }
              if (award.award_sub_type === 'weekend') {
                this.wSilver++;
              }
              if (award.award_sub_type === 'global') {
                this.lbSilver++;
              }
            }
            if (award.award_rank == '3') {
              if (award.award_sub_type === 'mon-fri') {
                this.MfBronze++;
              }
              if (award.award_sub_type === 'weekend') {
                this.wBronze++;
              }
              if (award.award_sub_type === 'global') {
                this.lbBronze++;
              }
            }
          });
        },
        (error) => {
          this.isFetching = false;
          this.errors.push(error.message);
        }
      );
    }
  }
}
