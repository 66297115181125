<div
  *ngIf="currentProgramme"
  class="bg-full d-block d-sm-none"
  [ngStyle]="{
    'background-image':
      'url(' +
      environment.assetUrl +
      '/assets/' +
      currentProgramme.dashboard_bg +
      ')'
  }"
></div>
<div *ngIf="isFetching">
  <app-loading-spinner [inline]="1"></app-loading-spinner>
</div>
<div *ngIf="currentUser && programmeContent">
  <div class="container container-app container-rm">
    <!--div class="masthead masthead-accent masthead-pad-top">
                      <div class="masthead-strap">NEW BOOSTER SESSION</div>
                      
                      
                    
                  </div-->
    <!-- reading material -->

    <h3 class="dashboard-subtitle mt-4">EDUCATIONAL READING</h3>
    <div class="row">
      <div
        class="col-6"
        *ngFor="let contentSection of programmeContent.content_sections; let index = index;"
      >
        <div class="reading-widget">
          <div
            class="reading-widget-img"
            [ngStyle]="{
              'background-image':
                'url(' + environment.assetUrl + '/assets/' +
                contentSection.image_filename +
                ')'
            }"
          >
            <span class="reading-widget-num">{{ index + 1 }}</span>
          </div>
          <h3>{{ contentSection.content_section_title }}</h3>

          <button
            class="btn btn-primary"
            [routerLink]="['/educational-reading', 'section', contentSection.content_section_id]"
            
          >
            OPEN <span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span>
          </button>
        </div>
      </div>
    </div>

    <!-- Trackers -->
    <div class="dashboard-subnav">
      <h3 class="dashboard-subtitle">HEALTH TRACKERS</h3>
      <div
        *ngIf="currentProgramme.config.trackers.indexOf('minutes') > -1"
        class="toggle-header toggle-header-left"
        [routerLink]="['/activity', 'minutes']"
      >
        <a class="toggle-nav toggle-nav-expand"
          ><span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span></a>
        <div class="toggle-header-title">
            <span class="icon-wrap section-nav-icon"><fa-icon [icon]="['far', 'stopwatch']"></fa-icon></span> Minutes
        </div>
      </div>

      <div
        *ngIf="currentProgramme.config.trackers.indexOf('shape') > -1"
        class="toggle-header toggle-header-left"
        [routerLink]="['/health-trackers', 'shape']"
      >
        <a class="toggle-nav toggle-nav-expand"
          ><span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span></a>
        <div class="toggle-header-title">

            <span class="icon-wrap section-nav-icon" *ngIf="currentUser.gender == 'f' || !currentUser.gender"><fa-icon [icon]="['fas', 'female']"></fa-icon></span>
            <span class="icon-wrap section-nav-icon" *ngIf="currentUser.gender == 'm'"><fa-icon [icon]="['fas', 'male']"></fa-icon></span>
          
          Shape
        </div>
      </div>

      <div
        *ngIf="currentProgramme.config.trackers.indexOf('healthEating') > -1"
        class="toggle-header toggle-header-left"
        [routerLink]="['/health-trackers', 'healthy-eating']"
      >
        <a class="toggle-nav toggle-nav-expand"
          ><span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span></a>
        <div class="toggle-header-title">
            <span class="icon-wrap section-nav-icon"><fa-icon [icon]="['fas', 'utensils']"></fa-icon></span> Health Eating
        </div>
      </div>

      <div
        *ngIf="currentProgramme.config.trackers.indexOf('hba1c') > -1"
        class="toggle-header toggle-header-left"
        [routerLink]="['/health-trackers', 'hba1c']"
      >
        <a class="toggle-nav toggle-nav-expand"
          ><span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span></a>
        <div class="toggle-header-title">
            <span class="icon-wrap section-nav-icon"><fa-icon [icon]="['fas', 'tint']"></fa-icon></span> HbA1c
        </div>
      </div>

      <div
        *ngIf="currentProgramme.config.trackers.indexOf('cholesterol') > -1"
        class="toggle-header toggle-header-left"
        [routerLink]="['/health-trackers', 'cholesterol']"
      >
        <a class="toggle-nav toggle-nav-expand"
          ><span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span></a>
        <div class="toggle-header-title">
            <span class="icon-wrap section-nav-icon"><fa-icon [icon]="['fas', 'heartbeat']"></fa-icon></span> Cholesterol
        </div>
      </div>

      <div
        *ngIf="currentProgramme.config.trackers.indexOf('bp') > -1"
        class="toggle-header toggle-header-left"
        [routerLink]="['/health-trackers', 'blood-pressure']"
      >
        <a class="toggle-nav toggle-nav-expand"
          ><span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span></a>
        <div class="toggle-header-title">
            <span class="icon-wrap section-nav-icon"><fa-icon [icon]="['fas', 'tachometer-alt']"></fa-icon></span> Blood Pressure
        </div>
      </div>
      <div
        *ngIf="currentProgramme.config.trackers.indexOf('smoking') > -1"
        class="toggle-header toggle-header-left"
        [routerLink]="['/health-trackers', 'smoking']"
      >
        <a class="toggle-nav toggle-nav-expand"
          ><span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span></a>
        <div class="toggle-header-title">
            <span class="icon-wrap section-nav-icon"><fa-icon [icon]="['fas', 'smoking-ban']"></fa-icon></span> Smoking
        </div>
      </div>
    </div>

    <hr class="divider-tracker" />
    <div class="text-center decision-maker-block">
      <p>
        Need help to make healthier eating choices?<br />Try our Decision Maker
        tool
      </p>
      <a [routerLink]="['/decision-maker']" class="btn btn-primary"
        > <span class="icon-wrap"><fa-icon [icon]="['fas', 'map-signs']"></fa-icon></span> DECISION MAKER</a
      >
    </div>

    <div *ngIf="lastViewed && !newAlerts" class="d-block d-sm-none">
        <!-- Have they completed?-->
        <div class="fixed-bottom fb-sidenav">
          <div
            *ngIf="
              currentProgramme.config.certificate &&
              learningSessionTotals.completed > 0 &&
              learningSessionTotals.completed ==
                learningSessionTotals.available +
                  learningSessionTotals.upcoming &&
              boosterSessionTotals.completed ==
                boosterSessionTotals.available + boosterSessionTotals.upcoming
            "
          >
            <div class="well well-notification">
              <span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'trophy']"></fa-icon
              ></span>
              <div class="notification-content">
                <div class="notification-title">CERTIFICATE OF COMPLETION</div>
                <a
                  class="btn btn-primary"
                  style="margin-left: 10px"
                  href="/download-certificate/{{ currentProgramme.programme_id }}"
                  >DOWNLOAD</a
                >
                <div class="notification-text" style="min-height: 38px">
                  You have completed all the Learning Sessions and Booster
                  Sessions
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="
              !(
                currentProgramme.config.certificate &&
                learningSessionTotals.completed > 0 &&
                learningSessionTotals.completed ==
                  learningSessionTotals.available +
                    learningSessionTotals.upcoming &&
                boosterSessionTotals.completed ==
                  boosterSessionTotals.available + boosterSessionTotals.upcoming
              )
            "
          >
            <div class="well well-notification">
              <span
                class="icon-wrap"
                *ngIf="lastViewedPage.parent.type == 'boosterSession'"
                ><fa-icon [icon]="['fas', 'graduation-cap']"></fa-icon
              ></span>
              <span
                class="icon-wrap"
                *ngIf="lastViewedPage.parent.type == 'learningSession'"
                ><fa-icon [icon]="['fas', 'bolt']"></fa-icon
              ></span>
              <span
                class="icon-wrap"
                *ngIf="
                  lastViewedPage.parent.type != 'learningSession' &&
                  lastViewedPage.parent.type != 'boosterSession'
                "
                ><fa-icon [icon]="['fas', 'book']"></fa-icon
              ></span>
  
              <div class="notification-content">
                <div class="notification-title">LAST VIEWED</div>
                <a
                  class="btn btn-primary"
                  style="margin-left: 10px"
                  [routerLink]="['/booster-session-steps', 'view', lastViewedPage.booster_session_step_id]"
                  *ngIf="
                    lastViewed.booster_session_id &&
                    lastViewedPage.parent.type == 'boosterSession'
                  "
                  >VIEW</a
                >
                <a
                  class="btn btn-primary"
                  style="margin-left: 10px"
                  [routerLink]="['/learning-session-steps', 'view', lastViewedPage.booster_session_step_id]"
                  *ngIf="
                    lastViewed.booster_session_id &&
                    lastViewedPage.parent.type == 'learningSession'
                  "
                  >VIEW</a
                >
                <a
                  class="btn btn-primary"
                  style="margin-left: 10px"
                  [routerLink]="['/educational-reading', 'page', lastViewedPage.content_page_id]"
                  *ngIf="lastViewed.content_section_id"
                  >VIEW</a
                >
  
                <div
                  class="notification-text"
                  style="min-height: 38px"
                  *ngIf="
                    lastViewed.booster_session_id &&
                    lastViewedPage.parent.type == 'boosterSession'
                  "
                >
                  {{ lastViewedPage.parent.booster_session_title }} &gt;
                  {{ lastViewedPage.title }}
                </div>
                <div
                  class="notification-text"
                  style="min-height: 38px"
                  *ngIf="
                    lastViewed.booster_session_id &&
                    lastViewedPage.parent.type == 'learningSession'
                  "
                >
                  {{ lastViewedPage.parent.booster_session_title }} &gt;
                  {{ lastViewedPage.title }}
                </div>
                <div
                  class="notification-text"
                  style="min-height: 38px"
                  *ngIf="lastViewed.content_section_id"
                >
                  {{ lastViewedPage.parent.content_section_title }} &gt;
                  {{ lastViewedPage.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
