import { ConfigService } from './../services/config.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-offline',
  templateUrl: './offline.component.html',
  styleUrls: ['./offline.component.scss']
})
export class OfflineComponent implements OnInit {
  message;
  constructor(private configService: ConfigService, private router: Router) { }

  ngOnInit(): void {

    this.configService.fetchAll().subscribe((responseData) => {
      if(responseData['online'] !== '1'){
        this.message = responseData['maintenance_message'];
      }
      else{
        this.router.navigate(['/dashboard']);
      }
    });
  }

}
