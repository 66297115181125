<div>
  <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
  <div *ngIf="learningSession">
    <nav class="subnav">
      <a class="subnav-prev" *ngIf="prevSession" [routerLink]="['/learning-sessions', 'index', prevSession.booster_session_id]"><span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span></a>
      <h2 class="uppercase-title">
        <span class="icon-wrap"><fa-icon [icon]="['fas', 'graduation-cap']"></fa-icon></span>
        {{ learningSessionProgramme?.booster_session_title }}
      </h2>
      <a *ngIf="nextSession" [routerLink]="['/learning-sessions', 'index', nextSession.booster_session_id]" class="subnav-next"
        ><span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span></a>
    </nav>
    <div *ngIf="learningSession.status">
      <div
        *ngIf="learningSession.status != 1 && currentUser.is_admin != 1"
      >
        <br />
        <p class="text-center">
          This booster session is currently unavailable.
        </p>
      </div>
      <div ng-show="learningSession.status == 1 || currentUser.is_admin == 1">
        <div
          class="section-img-masthead"
          *ngIf="learningSession.image_url"
          [ngStyle]="{'background-image' :  'url(' + environment.assetUrl + '/assets/' + learningSession.image_url + ')'}"
        ></div>
        <nav class="section-nav section-nav-booster-session">
          <ul>
            <li
              *ngFor="
                let learningSessionStep of learningSessionSteps;
                let index = index
              "
              
            >
              <a [routerLink]="['/learning-session-steps', 'view', learningSessionStep.booster_session_step_id]">
                <span class="num">{{ index + 1 }}</span>
                <span class="section-nav-label">{{ learningSessionStep.title }}</span>
                <fa-icon *ngIf="learningSessionStep.progress!='viewed'" [icon]="['far', 'arrow-circle-right']" [fixedWidth]="true"></fa-icon>
                <fa-icon *ngIf="learningSessionStep.progress==='viewed'" class="text-primary"  [icon]="['fas', 'check-circle']" [fixedWidth]="true"></fa-icon>
                </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
