import { NgForm } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { BloodPressureService } from './../../services/blood-pressure.service';
import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { environment } from './../../../environments/environment';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-blood-pressure',
  templateUrl: './blood-pressure.component.html',
  styleUrls: ['./blood-pressure.component.scss'],
})
export class BloodPressureComponent implements OnInit {
  currentUser;
  currentProgramme;
  userBloodPressures = [];
  userBloodPressure;
  isFetching = false;
  errors = [];
  showAllRows = false;
  environment = environment;

  toggleRow = {
    update: false,
    graph: false,
    entry: false,
  };

  toggleChart = {
    systolic: true,
  };

  unitMode = 'mmol/mol';

  entryDate = new Date();
  maxDate = new Date();

  @ViewChild('trackerForm', { static: false }) trackerForm: NgForm;

  // Line chart
  public chartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            beginAtZero: false,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          gridLines: {
            display: true,
            lineWidth: 1,
            color: '#95989A',
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            lineWidth: 2,
            color: '#95989A',
          },
          type: 'time',
          ticks: {
            autoSkip: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          time: {
            parser: 'YYYY-MM-DD HH:mm:ss',
            unit: 'day',
            displayFormats: {
              day: 'MMM-DD',
            },
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public chartLabels: Label[] = [];
  public chartType: ChartType = 'line';
  public chartLegend = true;

  public chartColors: Color[] = [{ backgroundColor: environment.primaryColor }];

  public systolicChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'Systolic',
      borderWidth: 1,
      borderColor: environment.chartDefaults.colors[0],
      fill: false,
      pointRadius: 2,
    },
  ];
  public diastolicChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'Diastolic',
      borderWidth: 1,
      borderColor: environment.chartDefaults.colors[0],
      fill: false,
      pointRadius: 2,
    },
  ];
  //
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private bloodPressureService: BloodPressureService
  ) {}

  ngOnInit(): void {
    this.isFetching = true;
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (this.currentProgramme) {
          // this.barChartColors[0].backgroundColor = this.currentProgramme.config.primaryColor;

          forkJoin({
            bloodPressures: this.bloodPressureService.fetchAll(),
          }).subscribe(
            (responseData) => {
              this.isFetching = false;
              this.userBloodPressures = responseData.bloodPressures;
              if (this.userBloodPressures.length > 0) {
                this.userBloodPressure = this.userBloodPressures[0];
              }
              this.setChartData(this.userBloodPressures);
              // end
            },
            (error) => {
              this.isFetching = false;
              this.errors.push(error.message);
            }
          );
        }
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });
    }
  }

  onRemove(bloodPressure) {
    this.bloodPressureService
      .delete(bloodPressure.user_blood_pressure_id)
      .subscribe(
        (responseData) => {
          const index = this.userBloodPressures.indexOf(bloodPressure);
          this.userBloodPressures.splice(index, 1);
          this.setChartData(this.userBloodPressures);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  onSubmit() {
    // this.trackerForm.value.date

    if (!this.trackerForm.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.bloodPressureService
        .create(
          this.trackerForm.value.diastolic,
          this.trackerForm.value.systolic,
          moment(this.entryDate).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
            this.userBloodPressures.unshift(responseData);
            this.userBloodPressures.sort(this.sortByCreated);
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.trackerForm.resetForm();
            this.entryDate = new Date();
            this.setChartData(this.userBloodPressures);
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  // end chart
  updatedToday() {
    let hasUpdated = false;
    this.userBloodPressures.forEach((bloodPressure) => {
      if (
        moment(bloodPressure.created).format('MM DD YY') ===
        moment().format('MM DD YY')
      ) {
        hasUpdated = true;
      }
    });
    return hasUpdated;
  }

  setChartData(trackerData) {
    let max = 0;
    let min = 100;
    this.systolicChartData[0].data = [];
    this.diastolicChartData[0].data = [];
    this.chartLabels = [];
    // setup chart data
    trackerData.forEach((value, key) => {
      this.chartLabels.push(moment(value.created).format('YYYY-MM-DD'));
      this.systolicChartData[0].data.push(value.systolic);
      this.diastolicChartData[0].data.push(value.diastolic);

      if (+value.systolic > max) {
        max = +value.systolic;
      }
      if (+value.diastolic > max) {
        max = +value.diastolic;
      }
      if (+value.systolic < min) {
        min = +value.systolic;
      }
      if (+value.diastolic < min) {
        min = +value.diastolic;
      }
    });

    if (max > 0) {
      this.chartOptions.scales.yAxes[0].ticks.suggestedMax = max + 10;
    }
    if (min < 100) {
      this.chartOptions.scales.yAxes[0].ticks.suggestedMin = min - 10;
    }
  }

  sortByCreated(a, b) {
    if (a.created > b.created) {
      return -1;
    }
    if (a.created < b.created) {
      return 1;
    }
    return 0;
  }
}
