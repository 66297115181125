<nav class="subnav">


    <div *ngIf="currentProgramme && currentUser">
      <a
        *ngIf="currentProgramme.config.buddies == 1"
        [routerLink]="['/friends-and-family']"
        aria-label="Go to buddies"
        class="subnav-prev"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
      ></a>

      <a
        *ngIf="currentProgramme.config.buddies != 1"
        [routerLink]="['/challenges']"
        aria-label="Challenges"
        class="subnav-prev"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
      ></a>

      
    </div>

    <div *ngIf="isFetching">
      <app-loading-spinner [inline]="1"></app-loading-spinner>
    </div>

  <h2><span class="icon-wrap"><fa-icon [icon]="['fas', 'bullseye']"></fa-icon></span> SET DAILY STEPS TARGET</h2>
  <a  [routerLink]="['/activity', 'minutes']" aria-label="Go to minutes" class="subnav-next"
    ><span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span></a>
</nav>
<div class="masthead masthead-accent" ng-model="target">
    <div *ngIf="isFetching">
        <app-loading-spinner [inline]="1"></app-loading-spinner>
      </div>
  <div *ngIf="originalTarget == 0">
    <h2>It looks like you haven't set a target yet!</h2>

    <button class="btn btn-primary target-confirm" (click)="originalTarget=1">
      Get Started!
    </button>
  </div>

  <div *ngIf="originalTarget > 0">
    <div class="target-controls">
      <span class="num">{{ getTargetFormatted(target)[0] }}</span>
      <span class="num">{{ getTargetFormatted(target)[1] }}</span>
      <span class="num">{{ getTargetFormatted(target)[2] }}</span>
      <span class="num">{{ getTargetFormatted(target)[3] }}</span>
      <span class="num">{{ getTargetFormatted(target)[4] }}</span>
      <span class="target-control-btns">
        <button (click)="targetUp()" aria-label="Steps Up">
            <span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-up']"></fa-icon></span>
        </button>
        <button (click)="targetDown()" aria-label="Steps Down">
            <span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-down']"></fa-icon></span>
        </button>
      </span>
    </div>
  </div>

  <button
    class="btn btn-primary btn-white target-confirm"
    (click)="submitTarget(target)"
    *ngIf="originalTarget != target && originalTarget > 0"
  >
  <span class="icon-wrap"><fa-icon [icon]="['fas', 'check']"></fa-icon></span> CONFIRM
  </button>
  <div *ngIf="originalTarget == target">
    <p class="masthead-subtext masthead-subtext-white">
      Use the buttons to adjust your target
    </p>
    <p *ngIf="currentTargetObj" class="masthead-subtext">
      Last set on
      {{ currentTargetObj.created | dateToISO | dateFormatter: "D" | ordinal }}
      {{ currentTargetObj.created | dateToISO | dateFormatter: "MMM yyyy" }}
    </p>
  </div>
</div>
<div class="container" *ngIf="myActivity">
  <div class="well">
    <h4 class="h-left">AVERAGE STEPS LAST WEEK</h4>
    <p>
      {{ myActivity.step_avg | number: '1.0-0' }} from
      {{ startDate | dateFormatter: "DD/MM/yyyy" }} - {{ endDate | dateFormatter: "DD/MM/yyyy" }}
    </p>
    <h4 class="h-left">HIT YOUR STEPS TARGET</h4>
    <p>{{ myActivity.goal_count | number }} days in the last week</p>
    <h4 class="h-left">WE RECOMMEND</h4>
    <p>
      <span *ngIf="myActivity.step_avg < 5000"
        >As a guide your long term goal should be to reach 7000 steps. Try to
        build up your steps gradually; setting too big a goal can be
        de-motivating. Increasing by just 500 steps or 5 -10 minutes activity
        per day is a more achievable short-term goal</span
      >
      <span *ngIf="myActivity.step_avg >= 5000 && myActivity.step_avg < 10000"
        >As a guide your long term goal should be to reach 10,000 steps.. Try to
        build up your steps gradually; setting too big a goal can be
        de-motivating. Increasing by just 500 steps or 5 or 10 minutes activity
        per day is a more achievable short-term goal</span
      >
      <span *ngIf="myActivity.step_avg > 10000"
        >Great work! Keep up the good work. Now start to think about the
        intensity of your activities</span
      >
    </p>
  </div>
  <app-section-nav [section]="'activity'" [active]="'target'"></app-section-nav>
</div>

