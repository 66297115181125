import { UserInviteService } from './../../services/user-invite.service';
import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-friends-and-family-invites',
  templateUrl: './friends-and-family-invites.component.html',
  styleUrls: ['./friends-and-family-invites.component.scss'],
})
export class FriendsAndFamilyInvitesComponent implements OnInit {
  invites = [];
  currentProgramme;
  currentUser;
  isFetching;
  remainingInvites = 0;
  maxInvites = 5;
  toggleRow = {
    newInvite: false,
    invites: false,
  };

  @ViewChild('inviteForm', { static: false }) inviteForm: NgForm;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private userInviteService: UserInviteService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const user = this.authService.user;
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (this.currentProgramme) {
          this.userService.userData
            .pipe(take(1))
            .subscribe((userDataResponse) => {
              if (userDataResponse) {
                this.currentUser = userDataResponse;

                forkJoin({
                  invites: this.userInviteService.fetchAll(),
                }).subscribe(
                  (responseData) => {
                    this.invites = responseData.invites;

                    this.remainingInvites =
                      this.maxInvites - this.invites.length;
                  },
                  (error) => {}
                );
              }
            });
        }
      });
    }
  }

  onSubmit() {
    Swal.fire({
      title: 'Are you sure?',
      text:
        "Would you like to send an invite to '" +
        this.inviteForm.value.email +
        "'",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Send the invite',
    }).then((result) => {
      if (result.value) {
        this.userInviteService
          .create(
            this.currentProgramme.programme_id,
            this.inviteForm.value.email
          )
          .subscribe(
            (responseData) => {
              if (responseData['status'] === 'SUCCESS') {
                Swal.fire({
                  title: 'Invite Sent',
                  text: 'Your invite was successfully sent',
                  icon: 'success',
                  timer: 1000,
                  showConfirmButton: false,
                });
              } else {
                Swal.fire({
                  title: 'Invite Error',
                  text:
                    'There was an error sending the invite: ' +
                    responseData['error'],
                  icon: 'error',
                  timer: 2000,
                  showConfirmButton: false,
                });
              }
              this.router
                .navigateByUrl('/', { skipLocationChange: true })
                .then(() => this.router.navigate(['/friends-and-family', 'invites']));
            },
            (error) => {
              Swal.fire({
                title: 'Invite Error',
                text: 'There was an error sending the invite: ' + error,
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
              });
            }
          );
      }
    });
  }
}
