<nav class="subnav">
  <h2>REGISTER</h2>
</nav>

<app-loading-spinner *ngIf="isFetching"></app-loading-spinner>
<form
  class="bs-form reg-form"
  name="regForm"
  method="post"
  (ngSubmit)="onSubmit()"
  #regForm="ngForm"
>
  <!-- step 1 -->
  <div [hidden]="step !== 1">
    <div
      class="reading-material-page-header booster-session-step-header register-header text-left"
    >
      1 | Code
    </div>
    <div class="container">
      <div class="form-group">
        <label for="location"
          >ENTER YOUR VERIFICATION CODE <span class="required">*</span></label
        >
        <input
          type="text"
          name="code"
          class="form-control"
          placeholder=""
          id="code"
          [(ngModel)]="code"
          autocomplete="off"
          required
        />
        <div class="alert alert-danger" *ngIf="codeError">
          {{codeError}}
        </div>
      </div>

      <div *ngIf="environment.country != 'AUS'">
        <div>
          <div>
              <div>
                  <p><strong><u>What to do if you have not been sent a verification code:</u></strong></p>
                  <p>If you want access to&nbsp;<strong>MyDESMOND Type 2 diabetes or Let&apos;s Prevent Diabetes or Babysteps&nbsp;</strong> please find your nearest DESMOND Centre here&nbsp;<a href="https://www.desmond.nhs.uk/find-your-nearest-centre">https://www.desmond.nhs.uk/find-your-nearest-centre</a> with details of how to sign up. If there is not a centre near you, we advise you to speak with your GP/Diabetes Nurse to find out what is available in your area.</p>
                  <p>If you want to access&nbsp;<strong>Steps4Health</strong> - this is currently only available across Leicestershire &amp; Rutland - if you are from this area, please visit <a href="https://forms.office.com/e/jqDwdWTtLh" target="_blank" rel="noopener noreferrer">https://forms.office.com/e/jqDwdWTtLh</a> to register for access.&nbsp;</p>
                  <p>If you are trying to access the&nbsp;<strong>LENA</strong> (LowENergyApproach) programme - please refer back to your local weight management service for access.&nbsp;</p>
                  <p><strong><u>What to do if you have a verification code but are struggling to complete your registration:</u></strong></p>
                  <p>Please see the video linked below for guidance. If you continue to have problems, please email&nbsp;<a href="mailto:mydesmond@uhl-tr.nhs.uk">mydesmond@uhl-tr.nhs.uk</a> (and please state your email address and the programme you are trying to access, as well as the problem you are having). If you are able to send screenshots/photographs of any errors or what you see on screen this will help us to resolve your issue more timely.</p>
                  <p><u><strong>What to do if you have already registered:</strong></u></p>
                  <p>If you are already registered on one of our programmes, please&nbsp;<a [routerLink]="['/login']">log into your account</a> via this link and enter your email address and the password you set up on registration.&nbsp;</p>
                 
              </div>
          </div></div>
          <p class="required-msg mb-2">
            <span class="required">*</span> indicates required field
          </p>
  <div class="lead"><strong>All our digital programmes are part of the MyDESMOND Digital Health Platform</strong></div>
      </div>
      <!--p *ngIf="environment.country != 'AUS'">
        If you have not been sent a verification code; please find your nearest
        DESMOND Centre here
        <a
          href="https://www.desmond.nhs.uk/find-your-nearest-centre"
          target="_blank"
          >https://www.desmond.nhs.uk/find-your-nearest-centre</a
        >
        with details of how to sign up. If there is not a centre near you we
        advise you to speak with your GP/Diabetes Nurse to find out what is
        available in your area.
      </p>
      <p *ngIf="environment.country != 'AUS'">For anyone struggling to complete their registration, please see the video linked below for guidance.</p>
      <p *ngIf="environment.country != 'AUS'">If you are already registered on MyDESMOND please <a [routerLink]="['/login']">log into your account</a>.</p-->

     
      <hr class="divider" />
      <div class="container">
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6 text-right">
            <button
              (click)="checkCode(code)"
              class="btn btn-primary btn-white"
              [disabled]="!code"
              type="button"
            >
              NEXT <span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span>
            </button>
          </div>
        </div>
      </div>

      <!-- new video -->
      <div *ngIf="environment.country != 'AUS'" style="max-width:600px; margin:3rem auto;">
        <video  poster="./assets/img/reg-video-poser.jpg" controls preload width="100%"  webkit-playsinline playsinline>
          <source  src="./assets/video/MyDesmond_Register.mp4" type="video/mp4">
         </video>
        <!--video  poster="./assets/img/reg-poster-steps4health.png" controls preload width="100%"  webkit-playsinline playsinline>
          <source  src="./assets/video/Steps4Health_Registration.mp4" type="video/mp4">
         </video-->
      </div>

    </div>
  </div>
<div *ngIf="regProgrammeConfig">

  <!-- step 2 -->
  <div [hidden]="step !== 2">
    <div
      class="reading-material-page-header booster-session-step-header register-header text-left"
    >
      2 | Personal Details
    </div>
    <div class="container">
      <div class="form-group">
        <label for="location">FIRST NAME <span class="required">*</span></label>
        <input
          type="text"
          name="first_name"
          class="form-control"
          placeholder="First Name"
          id="first_name"
          [ngModel]="regDetails.first_name"
          #first_name="ngModel"
          autocomplete="off"
          required
          minlength="1"
          maxlength="100"
        />
        <div
          class="alert alert-danger"
          *ngIf="
            (first_name.touched && first_name.invalid) ||
            (stepSubmitted[2] && first_name.invalid)
          "
        >
          There is an error with your first name.
        </div>
      </div>
      <div class="form-group">
        <label for="location">LAST NAME <span class="required">*</span></label>
        <input
          type="text"
          name="last_name"
          class="form-control"
          placeholder="Last Name"
          id="last_name"
          [ngModel]="regDetails.last_name"
          #last_name="ngModel"
          autocomplete="off"
          required
          minlength="1"
          maxlength="100"
        />
        <div
          class="alert alert-danger"
          *ngIf="
            (last_name.touched && last_name.invalid) ||
            (stepSubmitted[2] && last_name.invalid)
          "
        >
          There is an error with your last name.
        </div>
      </div>

      <div class="form-group">
        <label for="location"
          >SCREEN NAME <span class="required">*</span></label
        >
        <p>This is will be shown on challenge boards and comments. Please use only letters and numbers and remove any spaces or special characters.</p>
        <input
          type="text"
          name="screen_name"
          class="form-control"
          placeholder="Screen Name"
          id="screen_name"
          ngModel
          #screen_name="ngModel"
          autocomplete="off"
          required
          minlength="1"
          maxlength="100"
          pattern="^[a-zA-Z0-9]*$"
        />
        <div
          class="alert alert-danger"
          *ngIf="
            (screen_name.touched && screen_name.invalid) ||
            (stepSubmitted[2] && screen_name.invalid)
          "
        >
          There is an error with your screen name. Please use only letters and numbers and remove any spaces or special characters.
          <span *ngIf="screen_name.errors?.nametaken"
            >Sorry, that screen name is already taken</span
          >
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group form-group-dob">
            <label for="location"
              >DATE OF BIRTH <span class="required">*</span></label
            >

            <input
              class="form-control"
              name="dob"
              #dob="ngModel"
              bsDatepicker
              placeholder="DD/MM/YYYY"
              autocomplete="off"
              placement="top"
              [maxDate]="maxDob"
              [bsConfig]="{
                dateInputFormat: 'DD/MM/YYYY',
                containerClass: 'theme-cv',
                showWeekNumbers: false
              }"
              [(ngModel)]="bsValue"
              required
              [minDate]="minDob"
              [maxDate]="maxDob"
            />

            <div
              class="alert alert-danger"
              *ngIf="
                (dob.touched && dob.invalid) ||
                (stepSubmitted[2] && dob.invalid)
              "
            >
              There is an error with your Date of Birth.
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="weight">SEX <span class="required">*</span></label>
            <input
              type="hidden"
              name="gender"
              id="gender"
              [(ngModel)]="regDetails.gender"
              #gender="ngModel"
              required
            />
            <div class="row">
              <div class="col-3">
                <button
                  type="button"
                  class="btn btn-block btn-default"
                  [ngClass]="{
                    'btn-default': regDetails.gender !== 'female',
                    'btn-primary': regDetails.gender === 'female'
                  }"
                  (click)="regDetails.gender = 'female'"
                >
                  FEMALE
                </button>
              </div>
              <div class="col-3">
                <button
                  type="button"
                  class="btn btn-block btn-default"
                  [ngClass]="{
                    'btn-default': regDetails.gender !== 'male',
                    'btn-primary': regDetails.gender === 'male'
                  }"
                  (click)="regDetails.gender = 'male'"
                >
                  MALE
                </button>
              </div>
              <div class="col-3">
                <button
                  type="button"
                  class="btn btn-block btn-default"
                  [ngClass]="{
                    'btn-default': regDetails.gender !== 'x',
                    'btn-primary': regDetails.gender === 'x'
                  }"
                  (click)="regDetails.gender = 'x'"
                >
                  X
                </button>
                <em>(Indeterminate / Intersex / Unspecified)</em>
              </div>

              <div class="col-3">
                <button
                  type="button"
                  class="btn btn-block btn-default"
                  [ngClass]="{
                    'btn-default': regDetails.gender !== 'n',
                    'btn-primary': regDetails.gender === 'n'
                  }"
                  (click)="regDetails.gender = 'n'"
                >
                  Prefer not to say
                </button>
              </div>
            </div>
            <div
              class="alert alert-danger"
              *ngIf="stepSubmitted[2] && !regDetails.gender"
            >
              Please select your gender.
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6" *ngIf="environment.country == 'UK'">
          <div class="form-group">
            <label for="location"
              >ETHNICITY <span class="required">*</span></label
            >

            <select
              name="ethnicity_id"
              class="form-control"
              #ethnicity_id="ngModel"
              id="ethnicity_id"
              ngModel
              required
            >
              <option value="">Please Select</option>
              <optgroup label="White">
                <option value="1">British</option>
                <option value="2">Irish</option>
                <option value="3">
                  Any other White background (please specify)
                </option>
              </optgroup>
              <optgroup label="Mixed">
                <option value="4">White and Black Caribbean</option>
                <option value="5">White and Black African</option>
                <option value="6">White and Asian</option>
                <option value="7">
                  Any other Mixed background (please specify)
                </option>
              </optgroup>
              <optgroup label="Asian&#x20;or&#x20;Asian&#x20;British">
                <option value="8">Indian</option>
                <option value="9">Pakistani</option>
                <option value="10">Bangladeshi</option>
                <option value="11">
                  Any other Asian background (please specify)
                </option>
              </optgroup>
              <optgroup label="Black&#x20;or&#x20;Black&#x20;British">
                <option value="12">Caribbean</option>
                <option value="13">African</option>
                <option value="14">
                  Any other Black background (please specify)
                </option>
              </optgroup>
              <optgroup
                label="Chinese&#x20;or&#x20;other&#x20;ethnic&#x20;group"
              >
                <option value="15">Chinese</option>
                <option value="16">Any other (please specify)</option>
              </optgroup>
              <optgroup
                label="Arab&#x20;or&#x20;Middle&#x20;Eastern&#x20;descent"
              >
                <option value="17">Arab</option>
                <option value="18">North African</option>
                <option value="19">Iraqi</option>
                <option value="20">Kurdish</option>
                <option value="21">
                  Any other Middle Eastern background (please specify)
                </option>
              </optgroup>
              <optgroup label="Other">
                <option value="23">Prefer not to say</option>
                <option value="24">Other</option>
              </optgroup>
            </select>

            <div
              class="alert alert-danger"
              *ngIf="
                (ethnicity_id.touched && ethnicity_id.invalid) ||
                (stepSubmitted[2] && ethnicity_id.invalid)
              "
            >
              Ethnicity is required.
            </div>
          </div>
        </div>
        <div class="col-sm-6" *ngIf="environment.country == 'AUS'">
          <div class="form-group">
            <label for="origin"
              >ARE YOU OF ABORIGINAL OR TORRES STRAIT ISLANDER ORIGIN?
              <span class="required">*</span></label
            >
            <br />
            <label
              ><input
                type="radio"
                name="origin"
                [(ngModel)]="regDetails.origin"
                value="no"
                ng-required="!regDetails.origin"
              />
              No</label
            ><br />
            <label
              ><input
                type="radio"
                name="origin"
                [(ngModel)]="regDetails.origin"
                value="Aboriginal"
                ng-required="!regDetails.origin"
              />
              Yes, Aboriginal</label
            ><br />
            <label
              ><input
                type="radio"
                name="origin"
                [(ngModel)]="regDetails.origin"
                value="Torres Strait Islander"
                ng-required="!regDetails.origin"
              />
              Yes, Torres Strait Islander</label
            ><br />
            <label
              ><input
                type="radio"
                name="origin"
                [(ngModel)]="regDetails.origin"
                value="Aboriginal and Torres Strait Islander"
                ng-required="!regDetails.origin"
              />
              Yes, Aboriginal and Torres Strait Islander</label
            ><br />
            <label
              ><input
                type="radio"
                name="origin"
                [(ngModel)]="regDetails.origin"
                value="not say"
                ng-required="!regDetails.origin"
              />
              Do not wish to disclose</label
            >
            <!--div class="alert alert-danger" *ngIf="(origin.touched && origin.invalid) ||  (stepSubmitted[2] && origin.invalid)">Please select an option above.</div-->

            <br /><br />

            <label for="location"
              >IN WHICH COUNTRY WERE YOU BORN?
              <span class="required">*</span></label
            >
            <select
              name="birth_country"
              class="form-control"
              id="birth_country"
              ngModel
              #birth_country="ngModel"
              required
            >
              <option value="">Please Select</option>
              <option value="Australia">Australia</option>
              <option value="Afghanistan">Afghanistan</option>
              <option value="Åland Islands">Åland Islands</option>
              <option value="Albania">Albania</option>
              <option value="Algeria">Algeria</option>
              <option value="American Samoa">American Samoa</option>
              <option value="Andorra">Andorra</option>
              <option value="Angola">Angola</option>
              <option value="Anguilla">Anguilla</option>
              <option value="Antarctica">Antarctica</option>
              <option value="Antigua and Barbuda">Antigua and Barbuda</option>
              <option value="Argentina">Argentina</option>
              <option value="Armenia">Armenia</option>
              <option value="Aruba">Aruba</option>
              <option value="Austria">Austria</option>
              <option value="Azerbaijan">Azerbaijan</option>
              <option value="Bahamas">Bahamas</option>
              <option value="Bahrain">Bahrain</option>
              <option value="Bangladesh">Bangladesh</option>
              <option value="Barbados">Barbados</option>
              <option value="Belarus">Belarus</option>
              <option value="Belgium">Belgium</option>
              <option value="Belize">Belize</option>
              <option value="Benin">Benin</option>
              <option value="Bermuda">Bermuda</option>
              <option value="Bhutan">Bhutan</option>
              <option value="Bolivia">Bolivia</option>
              <option value="Bosnia and Herzegovina">
                Bosnia and Herzegovina
              </option>
              <option value="Botswana">Botswana</option>
              <option value="Bouvet Island">Bouvet Island</option>
              <option value="Brazil">Brazil</option>
              <option value="British Indian Ocean Territory">
                British Indian Ocean Territory
              </option>
              <option value="Brunei Darussalam">Brunei Darussalam</option>
              <option value="Bulgaria">Bulgaria</option>
              <option value="Burkina Faso">Burkina Faso</option>
              <option value="Burundi">Burundi</option>
              <option value="Cambodia">Cambodia</option>
              <option value="Cameroon">Cameroon</option>
              <option value="Canada">Canada</option>
              <option value="Cape Verde">Cape Verde</option>
              <option value="Cayman Islands">Cayman Islands</option>
              <option value="Central African Republic">
                Central African Republic
              </option>
              <option value="Chad">Chad</option>
              <option value="Chile">Chile</option>
              <option value="China">China</option>
              <option value="Christmas Island">Christmas Island</option>
              <option value="Cocos (Keeling) Islands">
                Cocos (Keeling) Islands
              </option>
              <option value="Colombia">Colombia</option>
              <option value="Comoros">Comoros</option>
              <option value="Congo">Congo</option>
              <option value="Congo, The Democratic Republic of The">
                Congo, The Democratic Republic of The
              </option>
              <option value="Cook Islands">Cook Islands</option>
              <option value="Costa Rica">Costa Rica</option>
              <option value="Cote D'ivoire">Cote D'ivoire</option>
              <option value="Croatia">Croatia</option>
              <option value="Cuba">Cuba</option>
              <option value="Cyprus">Cyprus</option>
              <option value="Czech Republic">Czech Republic</option>
              <option value="Denmark">Denmark</option>
              <option value="Djibouti">Djibouti</option>
              <option value="Dominica">Dominica</option>
              <option value="Dominican Republic">Dominican Republic</option>
              <option value="Ecuador">Ecuador</option>
              <option value="Egypt">Egypt</option>
              <option value="El Salvador">El Salvador</option>
              <option value="Equatorial Guinea">Equatorial Guinea</option>
              <option value="Eritrea">Eritrea</option>
              <option value="Estonia">Estonia</option>
              <option value="Ethiopia">Ethiopia</option>
              <option value="Falkland Islands (Malvinas)">
                Falkland Islands (Malvinas)
              </option>
              <option value="Faroe Islands">Faroe Islands</option>
              <option value="Fiji">Fiji</option>
              <option value="Finland">Finland</option>
              <option value="France">France</option>
              <option value="French Guiana">French Guiana</option>
              <option value="French Polynesia">French Polynesia</option>
              <option value="French Southern Territories">
                French Southern Territories
              </option>
              <option value="Gabon">Gabon</option>
              <option value="Gambia">Gambia</option>
              <option value="Georgia">Georgia</option>
              <option value="Germany">Germany</option>
              <option value="Ghana">Ghana</option>
              <option value="Gibraltar">Gibraltar</option>
              <option value="Greece">Greece</option>
              <option value="Greenland">Greenland</option>
              <option value="Grenada">Grenada</option>
              <option value="Guadeloupe">Guadeloupe</option>
              <option value="Guam">Guam</option>
              <option value="Guatemala">Guatemala</option>
              <option value="Guernsey">Guernsey</option>
              <option value="Guinea">Guinea</option>
              <option value="Guinea-bissau">Guinea-bissau</option>
              <option value="Guyana">Guyana</option>
              <option value="Haiti">Haiti</option>
              <option value="Heard Island and Mcdonald Islands">
                Heard Island and Mcdonald Islands
              </option>
              <option value="Holy See (Vatican City State)">
                Holy See (Vatican City State)
              </option>
              <option value="Honduras">Honduras</option>
              <option value="Hong Kong">Hong Kong</option>
              <option value="Hungary">Hungary</option>
              <option value="Iceland">Iceland</option>
              <option value="India">India</option>
              <option value="Indonesia">Indonesia</option>
              <option value="Iran, Islamic Republic of">
                Iran, Islamic Republic of
              </option>
              <option value="Iraq">Iraq</option>
              <option value="Ireland">Ireland</option>
              <option value="Isle of Man">Isle of Man</option>
              <option value="Israel">Israel</option>
              <option value="Italy">Italy</option>
              <option value="Jamaica">Jamaica</option>
              <option value="Japan">Japan</option>
              <option value="Jersey">Jersey</option>
              <option value="Jordan">Jordan</option>
              <option value="Kazakhstan">Kazakhstan</option>
              <option value="Kenya">Kenya</option>
              <option value="Kiribati">Kiribati</option>
              <option value="Korea, Democratic People's Republic of">
                Korea, Democratic People's Republic of
              </option>
              <option value="Korea, Republic of">Korea, Republic of</option>
              <option value="Kuwait">Kuwait</option>
              <option value="Kyrgyzstan">Kyrgyzstan</option>
              <option value="Lao People's Democratic Republic">
                Lao People's Democratic Republic
              </option>
              <option value="Latvia">Latvia</option>
              <option value="Lebanon">Lebanon</option>
              <option value="Lesotho">Lesotho</option>
              <option value="Liberia">Liberia</option>
              <option value="Libyan Arab Jamahiriya">
                Libyan Arab Jamahiriya
              </option>
              <option value="Liechtenstein">Liechtenstein</option>
              <option value="Lithuania">Lithuania</option>
              <option value="Luxembourg">Luxembourg</option>
              <option value="Macao">Macao</option>
              <option value="Macedonia, The Former Yugoslav Republic of">
                Macedonia, The Former Yugoslav Republic of
              </option>
              <option value="Madagascar">Madagascar</option>
              <option value="Malawi">Malawi</option>
              <option value="Malaysia">Malaysia</option>
              <option value="Maldives">Maldives</option>
              <option value="Mali">Mali</option>
              <option value="Malta">Malta</option>
              <option value="Marshall Islands">Marshall Islands</option>
              <option value="Martinique">Martinique</option>
              <option value="Mauritania">Mauritania</option>
              <option value="Mauritius">Mauritius</option>
              <option value="Mayotte">Mayotte</option>
              <option value="Mexico">Mexico</option>
              <option value="Micronesia, Federated States of">
                Micronesia, Federated States of
              </option>
              <option value="Moldova, Republic of">Moldova, Republic of</option>
              <option value="Monaco">Monaco</option>
              <option value="Mongolia">Mongolia</option>
              <option value="Montenegro">Montenegro</option>
              <option value="Montserrat">Montserrat</option>
              <option value="Morocco">Morocco</option>
              <option value="Mozambique">Mozambique</option>
              <option value="Myanmar">Myanmar</option>
              <option value="Namibia">Namibia</option>
              <option value="Nauru">Nauru</option>
              <option value="Nepal">Nepal</option>
              <option value="Netherlands">Netherlands</option>
              <option value="Netherlands Antilles">Netherlands Antilles</option>
              <option value="New Caledonia">New Caledonia</option>
              <option value="New Zealand">New Zealand</option>
              <option value="Nicaragua">Nicaragua</option>
              <option value="Niger">Niger</option>
              <option value="Nigeria">Nigeria</option>
              <option value="Niue">Niue</option>
              <option value="Norfolk Island">Norfolk Island</option>
              <option value="Northern Mariana Islands">
                Northern Mariana Islands
              </option>
              <option value="Norway">Norway</option>
              <option value="Oman">Oman</option>
              <option value="Pakistan">Pakistan</option>
              <option value="Palau">Palau</option>
              <option value="Palestinian Territory, Occupied">
                Palestinian Territory, Occupied
              </option>
              <option value="Panama">Panama</option>
              <option value="Papua New Guinea">Papua New Guinea</option>
              <option value="Paraguay">Paraguay</option>
              <option value="Peru">Peru</option>
              <option value="Philippines">Philippines</option>
              <option value="Pitcairn">Pitcairn</option>
              <option value="Poland">Poland</option>
              <option value="Portugal">Portugal</option>
              <option value="Puerto Rico">Puerto Rico</option>
              <option value="Qatar">Qatar</option>
              <option value="Reunion">Reunion</option>
              <option value="Romania">Romania</option>
              <option value="Russian Federation">Russian Federation</option>
              <option value="Rwanda">Rwanda</option>
              <option value="Saint Helena">Saint Helena</option>
              <option value="Saint Kitts and Nevis">
                Saint Kitts and Nevis
              </option>
              <option value="Saint Lucia">Saint Lucia</option>
              <option value="Saint Pierre and Miquelon">
                Saint Pierre and Miquelon
              </option>
              <option value="Saint Vincent and The Grenadines">
                Saint Vincent and The Grenadines
              </option>
              <option value="Samoa">Samoa</option>
              <option value="San Marino">San Marino</option>
              <option value="Sao Tome and Principe">
                Sao Tome and Principe
              </option>
              <option value="Saudi Arabia">Saudi Arabia</option>
              <option value="Senegal">Senegal</option>
              <option value="Serbia">Serbia</option>
              <option value="Seychelles">Seychelles</option>
              <option value="Sierra Leone">Sierra Leone</option>
              <option value="Singapore">Singapore</option>
              <option value="Slovakia">Slovakia</option>
              <option value="Slovenia">Slovenia</option>
              <option value="Solomon Islands">Solomon Islands</option>
              <option value="Somalia">Somalia</option>
              <option value="South Africa">South Africa</option>
              <option value="South Georgia and The South Sandwich Islands">
                South Georgia and The South Sandwich Islands
              </option>
              <option value="Spain">Spain</option>
              <option value="Sri Lanka">Sri Lanka</option>
              <option value="Sudan">Sudan</option>
              <option value="Suriname">Suriname</option>
              <option value="Svalbard and Jan Mayen">
                Svalbard and Jan Mayen
              </option>
              <option value="Swaziland">Swaziland</option>
              <option value="Sweden">Sweden</option>
              <option value="Switzerland">Switzerland</option>
              <option value="Syrian Arab Republic">Syrian Arab Republic</option>
              <option value="Taiwan, Province of China">
                Taiwan, Province of China
              </option>
              <option value="Tajikistan">Tajikistan</option>
              <option value="Tanzania, United Republic of">
                Tanzania, United Republic of
              </option>
              <option value="Thailand">Thailand</option>
              <option value="Timor-leste">Timor-leste</option>
              <option value="Togo">Togo</option>
              <option value="Tokelau">Tokelau</option>
              <option value="Tonga">Tonga</option>
              <option value="Trinidad and Tobago">Trinidad and Tobago</option>
              <option value="Tunisia">Tunisia</option>
              <option value="Turkey">Turkey</option>
              <option value="Turkmenistan">Turkmenistan</option>
              <option value="Turks and Caicos Islands">
                Turks and Caicos Islands
              </option>
              <option value="Tuvalu">Tuvalu</option>
              <option value="Uganda">Uganda</option>
              <option value="Ukraine">Ukraine</option>
              <option value="United Arab Emirates">United Arab Emirates</option>
              <option value="United Kingdom">United Kingdom</option>
              <option value="United States">United States</option>
              <option value="United States Minor Outlying Islands">
                United States Minor Outlying Islands
              </option>
              <option value="Uruguay">Uruguay</option>
              <option value="Uzbekistan">Uzbekistan</option>
              <option value="Vanuatu">Vanuatu</option>
              <option value="Venezuela">Venezuela</option>
              <option value="Viet Nam">Viet Nam</option>
              <option value="Virgin Islands, British">
                Virgin Islands, British
              </option>
              <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
              <option value="Wallis and Futuna">Wallis and Futuna</option>
              <option value="Western Sahara">Western Sahara</option>
              <option value="Yemen">Yemen</option>
              <option value="Zambia">Zambia</option>
              <option value="Zimbabwe">Zimbabwe</option>
            </select>
            <input
              type="text"
              class="form-control"
              placeholder="Please specify"
              name="birth_country_other"
              ng-model="regDetails.birth_country_other"
              *ngIf="regDetails.birth_country == 'other'"
            />
            <div
              class="alert alert-danger"
              *ngIf="
                (birth_country.touched && birth_country.invalid) ||
                (stepSubmitted[2] && birth_country.invalid)
              "
            >
              Please select your birth country.
            </div>
            <br /><br />
          </div>
        </div>
        <div class="col-sm-6">
          <div *ngIf="environment.country == 'AUS'">
            <label for="location">
              WHAT LANGUAGE DO YOU SPEAK MOST OFTEN AT HOME?<span
                class="required"
                >*</span
              ></label
            >
            <select
              #language="ngModel"
              name="language"
              class="form-control"
              id="language"
              ngModel
              required
            >
              <option value="">Please Select</option>
              <option value="English">English</option>
              <option value="other">Other (please specify)</option>
            </select>
            <input
              type="text"
              class="form-control"
              placeholder="Please specify"
              name="language_other"
              #language_other="ngModel"
              ngModel
              [hidden]="language.value !== 'other'"
              [required]="language.value === 'other'"
              [pattern]="noSpacesPattern"
            />

            <div
              class="alert alert-danger"
              *ngIf="
                (language.touched && language.invalid) ||
                (stepSubmitted[2] && language.invalid) ||
                (language_other.touched && language_other.invalid) ||
                (stepSubmitted[2] && language_other.invalid)
              "
            >
              Please select a preferred language.
            </div>
            <br /><br />
          </div>

          <div [hidden]="regProgrammeConfig.m3==1" >
          <label for="post_code">POST CODE</label>
          <p class="instructions" *ngIf="environment.country != 'AUS'">
            For those outside of the UK, please leave blank.
          </p>
          <input
            type="text"
            name="post_code"
            #postcode="ngModel"
            placeholder="Postcode"
            [pattern]="getPostcodePattern()"
            [required]="environment.country === 'AUS'"
            [attr.minlength]="getMinLength()"
            [attr.maxlength]="getMaxLength()"
            ngModel
            class="form-control"
          />
          <div
            class="alert alert-danger"
            *ngIf="
              (postcode.touched && postcode.invalid) ||
              (stepSubmitted[2] && postcode.invalid)
            "
          >
            There is an error with your Postcode.
          </div>

        </div>
        </div>
      </div>

      <p class="required-msg">
        <span class="required">*</span> indicates required field
      </p>
      <hr class="divider" />
      <div class="container">
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6 text-right">
            <button (click)="checkReg(2)" class="btn btn-primary btn-white" 
            type="button">
              NEXT <span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- step 3 -->
  <div [hidden]="step !== 3">

    <!-- GU1DE REG -->

  <ng-container *ngIf="regProgrammeConfig.gu1de==1">
    <div
    class="reading-material-page-header booster-session-step-header register-header text-left"
  >
    3 | Medical Details
  </div>
  <div class="container">
    <div class="form-group">
      <label for="gu1de_reason">WHAT IS YOUR REASON FOR ENROLLING ON THE GU1DE PROGRAMME? <span class="required">*</span></label>
      <select
      name="gu1de_reason"
      class="form-control"
      #gu1de_reason="ngModel"
      id="gu1de_reason"
      ngModel
      required
    >
    <option value="">Please Select</option>
      <option value="live">I live with type 1 diabetes</option>
      <option value="learn">I don't have type 1 but I want to learn more</option>
      <option value="hcp">I am a health professional</option>
      <option value="parent">I am a parent of someone with type 1 diabetes</option>
      <option value="carer">I care for someone with type 1 diabetes</option>

    </select>
      <div
        class="alert alert-danger"
        *ngIf="
          (gu1de_reason.touched && gu1de_reason.invalid) ||
          (stepSubmitted[3] && gu1de_reason.invalid)
        "
      >
        There is an error with this.
      </div>
    </div>


    <div
        class="form-group"
      >
        <label for="attendedBefore"
          >
          <span >Have you ever participated in a structured education programme e.g DAFNE, BERTI?</span> 
          <span class="required">*</span></label
        >
        <input
          type="hidden"
          name="attendedBefore"
          id="attendedBefore"
          [(ngModel)]="regDetails.attendedBefore"
          #attendedBefore="ngModel"
          required
        />
        <div class="row">
          <div class="col-6">
            <button
            type="button"
             class="btn-block btn btn-default"
              [ngClass]="{
                'btn-primary': regDetails.attendedBefore === 1,
                'btn-default': regDetails.attendedBefore === 0
              }"
              (click)="regDetails.attendedBefore = 1"
            >
              YES
            </button>
          </div>
          <div class="col-6">
            <button
            type="button"
             class="btn-block btn btn-default"
            [ngClass]="{
              'btn-primary': regDetails.attendedBefore === 0,
              'btn-default': regDetails.attendedBefore === 1
            }"
              (click)="regDetails.attendedBefore = 0"
            >
              NO
            </button>
          </div>
        </div>

        <div class="alert alert-danger" *ngIf="(stepSubmitted[3] && regDetails.attendedBefore==null) ">Please select either Yes or No.</div>
      </div>    


      <div class="form-group">
        <label for="since_participated">If Yes: How long ago did you participate?</label>
        <select
        name="since_participated"
        class="form-control"
        #since_participated="ngModel"
        id="since_participated"
        ngModel

      >
      <option value="">Please Select</option>
        <option value="<1">Less than 1 year</option>
        <option value="<5">less than 5 years</option>
        <option value="<10">less than 10 Years</option>
        <option value=">10">More than 10 years</option>
  
      </select>
        <div
          class="alert alert-danger"
          *ngIf="
            (since_participated.touched && since_participated.invalid) ||
            (stepSubmitted[3] && since_participated.invalid)
          "
        >
          There is an error with this.
        </div>
      </div>



      <div class="form-group">
        <label for="since_diagnosed">How long has it been since your diagnosis of diabetes?</label>
        <select
        name="since_diagnosed"
        class="form-control"
        #since_diagnosed="ngModel"
        id="since_diagnosed"
        ngModel
        required
      >

      <option value="">Please Select</option>
        <option value="N/A">N/A</option>
        <option value="<1">Less than 12 months</option>
        <option value="1">1 year</option>
        <option value="2">2 years</option>
        <option value="3">3 years</option>
        <option value="4">4 years</option>
        <option value="5">5 years</option>
        <option value="6">6 years</option>
        <option value="7">7 years</option>
        <option value="8">8 years</option>
        <option value="9">9 years</option>
        <option value="10">10 years</option>
        <option value="11">11 years</option>
        <option value="12">12 years</option>
        <option value="13">13 years</option>
        <option value="14">14 years</option>
        <option value="15">15 years</option>
        <option value="16">16 years</option>
        <option value="17">17 years</option>
        <option value="18">18 years</option>
        <option value="19">19 years</option>
        <option value="20">20 years</option>
        <option value="21+">21 years or more</option>
  
      </select>
        <div
          class="alert alert-danger"
          *ngIf="
            (since_diagnosed.touched && since_diagnosed.invalid) ||
            (stepSubmitted[3] && since_diagnosed.invalid)
          "
        >
          There is an error with this.
        </div>
      </div>


      <div class="form-group">
        <label for="hospital_care"
          >Do you currently go to your local hospital for your diabetes care?</label
        >
        <input
          type="hidden"
          name="hospital_care"
          id="hospital_care"
          [(ngModel)]="regDetails.hospital_care"
          #hospital_care="ngModel"
        />
        <div class="row">
          <div class="col-6">
            <button

            type="button"
              class="btn-block btn btn-default"
              [ngClass]="{
                'btn-primary': regDetails.hospital_care === 1,
                'btn-default': regDetails.hospital_care === 0 || regDetails.hospital_care===null
              }"
              (click)="regDetails.hospital_care = 1"
            >
              YES
            </button>
          </div>
          <div class="col-6">
            <button

            type="button"
              class="btn-block btn btn-default"
              [ngClass]="{
                'btn-primary': regDetails.hospital_care === 0,
                'btn-default': regDetails.hospital_care === 1 || regDetails.hospital_care===null
              }"
              (click)="regDetails.hospital_care = 0"
            >
              NO
            </button>
          </div>
        </div>

        <div
          class="alert alert-danger"
          *ngIf="stepSubmitted[3] && regDetails.hospital_care == null"
        >
          Please select your smoking status.
        </div>
      </div>
  
      <p class="required-msg">
        <span class="required">*</span> indicates required field
      </p>
      <hr class="divider" />
      <div class="container">
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6 text-right">
            <a (click)="checkReg(3)" class="btn btn-primary btn-white"
              >NEXT <span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span></a>
          </div>
        </div>
      </div>


  </div>

  </ng-container>

<!-- non GU1DE REG-->
<ng-container *ngIf="regProgrammeConfig.gu1de!=1">
      <div
      class="reading-material-page-header booster-session-step-header register-header text-left"
    >
      3 | Medical Details
    </div>
    <div class="container">
      <div [hidden]="unitMode !== 'metric'">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="weight"
                >WEIGHT (KG) <span class="required">*</span></label
              >
              <input
                type="number"
                name="weight"
                #weight="ngModel"
                class="form-control input-tracker"
                placeholder=""
                id="weight"
                [(ngModel)]="regDetails.weight"
                [min]="40"
                [max]="350"
                autocomplete="off"
                required
                (change)="updateWeight('metric')"
              />
              <div
                class="alert alert-danger"
                *ngIf="
                  (weight.touched && weight.invalid) ||
                  (stepSubmitted[3] && weight.invalid)
                "
              >
                Please enter a valid weight.
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="height"
                >HEIGHT (CM) <span class="required">*</span></label
              >
              <input
                type="number"
                name="height"
                class="form-control input-tracker"
                placeholder=""
                id="height"
                [(ngModel)]="regDetails.height"
                [min]="40"
                [max]="250"
                autocomplete="off"
                required
                #height="ngModel"
                (change)="updateHeight('metric')"
              />
              <div
                class="alert alert-danger"
                *ngIf="
                  (height.touched && height.invalid) ||
                  (stepSubmitted[3] && height.invalid)
                "
              >
                Please enter a valid height.
              </div>
            </div>
          </div>
        </div>
        <a (click)="unitMode = 'imperial'" class="btn btn-sm btn-default">Switch to Imperial</a>
      </div>
      <div [hidden]="unitMode !== 'imperial'">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="weightStone"
                >WEIGHT (Stone) <span class="required">*</span></label
              >
              <input
                type="number"
                name="weightStone"
                class="form-control input-tracker"
                placeholder=""
                id="weightStone"
                [(ngModel)]="regDetails.weightStone"
                #weightStone="ngModel"
                [min]="3"
                [max]="40"
                autocomplete="off"
                (change)="updateWeight()"
              />
              <div
                class="alert alert-danger"
                *ngIf="
                  (weightStone.touched && weightStone.invalid) ||
                  (stepSubmitted[3] && weightStone.invalid)
                "
              >
                Please enter a valid weight.
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="weightLbs"
                >WEIGHT (lbs) <span class="required">*</span></label
              >
              <input
                type="number"
                name="weightLbs"
                class="form-control input-tracker"
                placeholder=""
                id="weightLbs"
                [(ngModel)]="regDetails.weightLbs"
                #weightLbs="ngModel"
                [min]="0"
                [max]="15"
                autocomplete="off"
                (change)="updateWeight()"
              />
              <div
                class="alert alert-danger"
                *ngIf="
                  (weightLbs.touched && weightLbs.invalid) ||
                  (stepSubmitted[3] && weightLbs.invalid)
                "
              >
                Please enter a valid weight.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="heightFeet"
                >HEIGHT (Feet) <span class="required">*</span></label
              >
              <input
                type="number"
                name="heightFeet"
                class="form-control input-tracker"
                placeholder=""
                id="heightFeet"
                [(ngModel)]="regDetails.heightFeet"
                #heightFeet="ngModel"
                [min]="3"
                [max]="9"
                autocomplete="off"
                (change)="updateHeight()"
              />
              <div
                class="alert alert-danger"
                *ngIf="
                  (heightFeet.touched && heightFeet.invalid) ||
                  (stepSubmitted[3] && heightFeet.invalid)
                "
              >
                Please enter a valid height.
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="heightInches"
                >HEIGHT (Inches) <span class="required">*</span></label
              >
              <input
                type="number"
                name="heightInches"
                class="form-control input-tracker"
                placeholder=""
                id="heightInches"
                [(ngModel)]="regDetails.heightInches"
                #heightInches="ngModel"
                [min]="0"
                [max]="11"
                autocomplete="off"
                (change)="updateHeight()"
              />
              <div
                class="alert alert-danger"
                *ngIf="
                  (heightInches.touched && heightInches.invalid) ||
                  (stepSubmitted[3] && heightInches.invalid)
                "
              >
                Please enter a valid height.
              </div>
            </div>
          </div>
        </div>

        <a (click)="unitMode = 'metric'" class="btn btn-sm btn-default">Switch to Metric</a>
      </div>
      <br />

      <div class="form-group">
        <label for="smoker"
          >DO YOU SMOKE? <span class="required">*</span></label
        >
        <input
          type="hidden"
          name="smoker"
          id="smoker"
          [(ngModel)]="regDetails.smoker"
          #smoker="ngModel"
          required
        />
        <div class="row">
          <div class="col-6">
            <button

            type="button"
              class="btn-block btn btn-default"
              [ngClass]="{
                'btn-primary': regDetails.smoker === 1,
                'btn-default': regDetails.smoker === 0 || regDetails.smoker===null
              }"
              (click)="regDetails.smoker = 1"
            >
              YES
            </button>
          </div>
          <div class="col-6">
            <button

            type="button"
              class="btn-block btn btn-default"
              [ngClass]="{
                'btn-primary': regDetails.smoker === 0,
                'btn-default': regDetails.smoker === 1 || regDetails.smoker===null
              }"
              (click)="regDetails.smoker = 0"
            >
              NO
            </button>
          </div>
        </div>

        <div
          class="alert alert-danger"
          *ngIf="stepSubmitted[3] && regDetails.smoker == null"
        >
          Please select your smoking status.
        </div>
      </div>


      <!-- mobility status -->
      <div class="form-group"  [hidden]="regProgrammeConfig.m3!=1">
        <label for="mobility1" class="uppercase">
          Which one of the options below best describes your activity in
          the last week (Single answer)
          <span class="required">*</span></label
        >
        <div class="row">
          <div class="col-12">
            <div class="radio-group-block">
              <div class="custom-control custom-radio">
                <input
                  #mobility1="ngModel"
                  [required]="regProgrammeConfig.m3==1"
                  [(ngModel)]="regDetails.mobility1"
                  type="radio"
                  id="mobility1a"
                  name="mobility1"
                  class="custom-control-input"
                  value="a"
                />
                <label class="custom-control-label" for="mobility1a"
                  >I have no problems in walking about or doing my usual
                  activities</label
                >
              </div>

              <div class="custom-control custom-radio">
                <input
                  #mobility1="ngModel"
                  
                  [required]="regProgrammeConfig.m3==1"
                  [(ngModel)]="regDetails.mobility1"
                  type="radio"
                  id="mobility1b"
                  name="mobility1"
                  class="custom-control-input"
                  value="b"
                />
                <label class="custom-control-label" for="mobility1b"
                  >I have slight problems in walking about or doing my
                  usual activities</label
                >
              </div>

              <div class="custom-control custom-radio">
                <input
                  #mobility1="ngModel"
                  
                  [required]="regProgrammeConfig.m3==1"
                  [(ngModel)]="regDetails.mobility1"
                  type="radio"
                  id="mobility1c"
                  name="mobility1"
                  class="custom-control-input"
                  value="c"
                />
                <label class="custom-control-label" for="mobility1c">
                  I have moderate problems in walking about or doing my
                  usual activities e.g., The distance and duration I can
                  walk for is limited</label
                >
              </div>

              <div class="custom-control custom-radio">
                <input
                  #mobility1="ngModel"
                  
                  [required]="regProgrammeConfig.m3==1"
                  [(ngModel)]="regDetails.mobility1"
                  type="radio"
                  id="mobility1d"
                  name="mobility1"
                  class="custom-control-input"
                  value="d"
                />
                <label class="custom-control-label" for="mobility1d">
                  I have severe problems in walking about or doing my
                  usual activities e.g., I have physical
                  limitations</label
                >
              </div>

              <div class="custom-control custom-radio">
                <input
                  #mobility1="ngModel"
                  
                  [required]="regProgrammeConfig.m3==1"
                  [(ngModel)]="regDetails.mobility1"
                  type="radio"
                  id="mobility1e"
                  name="mobility1"
                  class="custom-control-input"
                  value="e"
                />
                <label class="custom-control-label" for="mobility1e">
                  I am currently unable walk (temporarily) e.g., I am
                  unable to do most of my usual activities</label
                >
              </div>

              <div class="custom-control custom-radio">
                <input
                  #mobility1="ngModel"
                  
                  [required]="regProgrammeConfig.m3==1"
                  [(ngModel)]="regDetails.mobility1"
                  type="radio"
                  id="mobility1f"
                  name="mobility1"
                  class="custom-control-input"
                  value="f"
                />
                <label class="custom-control-label" for="mobility1f">
                  I am unable walk (permanently) e.g., I am a wheelchair
                  user</label
                >
              </div>
            </div>
          </div>
        </div>

        <div
          class="alert alert-danger"
          *ngIf="stepSubmitted[3] && regDetails.mobility1 == null"
        >
          Please select an option.
        </div>
        <em
        >(Usual activities include getting washed and dressed, going to
        work, hobbies, and doing things with family and friends)
      </em>
      </div>

      <!-- end mobility status -->


      <div class="form-group" [hidden]="regProgrammeConfig.m3==1">
        <label for="glucose"
          >HbA1c
          <span ng-hide="regProgrammeConfig.hba1cMandatory == 1">
            (if known)</span
          >
          <span *ngIf="regProgrammeConfig.hba1cMandatory == 1" class="required"
            >*</span
          ></label
        >
        <p class="instruction">
          Please enter your latest HbA1c measure below. Please note your HbA1c
          is normally only measured every 3 months.
        </p>
        <div class="row">
          <div class="col-4">
            <div [hidden]="hba1cUnitMode !== 'percent'">
              <p>
                <strong>Enter a value as a %</strong> or
                <a (click)="hba1cUnitMode = 'mmol/mol'" class="btn btn-sm btn-default">Switch to mmol/mol</a>
              </p>
              <input
                type="number"
                name="glucosePercent"
                placeholder="%"
                class="form-control input-tracker"
                id="glucosePercent"
                [(ngModel)]="regDetails.glucosePercent"
                #glucosePercent="ngModel"
                [min]="4"
                [max]="15"
                autocomplete="off"
                (change)="updateVal()"
              />
              <div
                class="alert alert-danger"
                *ngIf="
                  (glucosePercent.touched && glucosePercent.invalid) ||
                  (stepSubmitted[3] && glucosePercent.invalid)
                "
              >
                This must be a number 4-15.
              </div>
            </div>

            <div [hidden]="hba1cUnitMode !== 'mmol/mol'">
              <p>
                <strong>Enter a value in mmol/mol</strong> or
                <a (click)="hba1cUnitMode = 'percent'" class="btn btn-sm btn-default">Switch to %</a>
              </p>
              <input
                type="number"
                name="glucose"
                placeholder="mmol/mol"
                class="form-control input-tracker"
                id="glucose"
                [(ngModel)]="regDetails.glucose"
                #glucose="ngModel"
                [min]="20"
                [max]="140"
                autocomplete="off"
                [required]="regProgrammeConfig.hba1cMandatory == 1"
              />
              <div
                class="alert alert-danger"
                *ngIf="
                  (glucose.touched && glucose.invalid) ||
                  (stepSubmitted[3] && glucose.invalid)
                "
              >
              Please enter a valid HbA1c.
                This must be a number 20-140.
              </div>
            </div>
            
          </div>
        </div>
      </div>

      <div  [hidden]="regProgrammeConfig.m3==1">

      <label>CHOLESTEROL (if known)</label>
      <div class="row">
        <div class="col-4">
          <div class="form-group">
            <label for="cholesterol">TOTAL</label>
            <input
              type="number"
              name="cholesterol"
              class="form-control input-tracker"
              placeholder="mmol/L"
              id="cholesterol"
              #cholesterol="ngModel"
              ngModel
              [min]="0"
              [max]="10"
              autocomplete="off"
            />
            <div
              class="alert alert-danger"
              *ngIf="
                (cholesterol.touched &&
                  cholesterol.invalid) ||
                (stepSubmitted[3] && cholesterol.invalid)
              "
            >
              Please enter a valid cholesterol level.
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label for="ldl">LDL</label>

            <input
              type="number"
              name="ldl"
              class="form-control input-tracker"
              placeholder="mmol/L"
              id="ldl"
              ngModel
              #ldl="ngModel"
              [min]="0"
              [max]="10"
              autocomplete="off"
            />
            <div
              class="alert alert-danger"
              *ngIf="
                (ldl.touched && ldl.invalid) ||
                (stepSubmitted[3] && ldl.invalid)
              "
            >
              This must be a number between 0 and 10.
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label for="hdl">HDL</label>
            <input
              type="number"
              name="hdl"
              class="form-control input-tracker"
              placeholder="mmol/L"
              id="hdl"
              ngModel
              #hdl="ngModel"
              [min]="0"
              [max]="10"
              autocomplete="off"
            />
            <div
              class="alert alert-danger"
              *ngIf="
                (hdl.touched && hdl.invalid) ||
                (stepSubmitted[3] && hdl.invalid)
              "
            >
              This must be a number between 0 and 10.
            </div>
          </div>
        </div>
      </div>
    </div>
      <div class="form-group"  [hidden]="regProgrammeConfig.m3==1">
        <label for="exercise"
          >30 MINS EXERCISE A DAY FOR 5 DAYS A WEEK?
          <span class="required">*</span></label
        >
        <input
          type="hidden"
          name="exercise"
          id="exercise"
          #exercise="ngModel"
          [(ngModel)]="regDetails.exercise"
          [required]="!regProgrammeConfig.m3"
        />
        <div class="row">
          <div class="col-6">
            <button
            type="button"
               class="btn-block btn btn-default"
              [ngClass]="{
                'btn-primary': regDetails.exercise === 1,
                'btn-default': regDetails.exercise === 0 || regDetails.exercise === null
              }"
              (click)="regDetails.exercise = 1"
            >
              YES
            </button>
          </div>
          <div class="col-6">
            <button
            type="button"
               class="btn-block btn btn-default"
              [ngClass]="{
                'btn-primary': regDetails.exercise === 0,
                'btn-default': regDetails.exercise === 1 || regDetails.exercise === null
              }"
              (click)="regDetails.exercise = 0"
            >
              NO
            </button>
          </div>
        </div>

        <div
          class="alert alert-danger"
          *ngIf="stepSubmitted[3] && regDetails.exercise == null"
        >
          Please select your exercise.
        </div>
      </div>

      <p class="required-msg">
        <span class="required">*</span> indicates required field
      </p>
      <hr class="divider" />
      <div class="container">
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6 text-right">
            <a (click)="checkReg(3)" class="btn btn-primary btn-white"
              >NEXT <span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span></a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  </div>

  <!-- step 4 -->
  <div [hidden]="step !== 4">
    <div
      class="reading-material-page-header booster-session-step-header register-header text-left"
    >
      4 | Account Details
    </div>
    <div class="container">
      <div class="form-group">
        <label for="email">EMAIL ADDRESS <span class="required">*</span></label>
        <input
          type="email"
          name="email"
          class="form-control input-tracker"
          placeholder=""
          id="email"
          #email="ngModel"
          [ngModel]="regDetails.email"
          autocomplete="off"
          required
          readonly
        />
        <div
          class="alert alert-danger"
          *ngIf="
            (email.touched && email.invalid) ||
            (stepSubmitted[4] && email.invalid)
          "
        >
          Please enter a valid email address.
        </div>
      </div>

      <div class="form-group">
        <label for="password">PASSWORD <span class="required">*</span></label>
        <p>
          Minimum ten characters, at least one uppercase letter, one lowercase
          letter, one number and one special character ($@!%*?&amp;#)
        </p>
        <input
          type="password"
          name="password"
          class="form-control input-tracker ok-password"
          [ngClass]="
            password.dirty && password.invalid ? 'error' : ''
          "
          placeholder="PASSWORD"
          id="password"
          ng-required="true"
          [(ngModel)]="regDetails.password"
          #password="ngModel"
          autocomplete="off"
          required
          [pattern]="passwordPattern"
        />
        <div
          class="alert alert-danger"
          *ngIf="
            stepSubmitted[4] &&
            (password.invalid ) &&
           password.dirty
          "
        >
          Please enter a valid password.
        </div>
        <div
          class="label password-count"
          [ngClass]="
            regDetails.password.length > 9 ? 'label-success' : 'label-danger'
          "
        >
          {{ password.value | passwordCount: 10 }}
        </div>

        <div class="strength-meter">
          <div
            class="strength-meter-fill"
            [attr.data-strength]="getPasswordStrength(password.value)"
           
          ></div>
        </div>
        <div>
          <ul class="password-checklist">
            <li
              [ngClass]="{
                'text-danger':
                  regDetails.password.length < 10 ||
                  !password.value,
                'text-success': regDetails.password.length > 7
              }"
            >
            <span
            class="icon-wrap"
            *ngIf="regDetails.password.length > 9"
            ><fa-icon [icon]="['fas', 'check']"></fa-icon
          ></span>
            <span
            class="icon-wrap"
            *ngIf="regDetails.password.length < 10 || !password.value"
            ><fa-icon [icon]="['fas', 'times']"></fa-icon
          ></span>
              Minimum ten characters
            </li>
            <li
              [ngClass]="{
                'text-danger': !checkPassword(
                  'uppercase',
                  password.value
                ),
                'text-success': checkPassword(
                  'uppercase',
                  password.value
                )
              }"
            >

            <span class="icon-wrap" *ngIf="checkPassword('uppercase', password.value)"><fa-icon [icon]="['fas', 'check']"></fa-icon></span>
            <span class="icon-wrap" *ngIf="!checkPassword('uppercase', password.value)"><fa-icon [icon]="['fas', 'times']"></fa-icon></span>

              At least one uppercase letter
            </li>
            <li
              [ngClass]="{
                'text-danger': !checkPassword(
                  'lowercase',
                  password.value
                ),
                'text-success': checkPassword(
                  'lowercase',
                  password.value
                )
              }"
            >
            <span class="icon-wrap" *ngIf="checkPassword('lowercase', password.value)"><fa-icon [icon]="['fas', 'check']"></fa-icon></span>
            <span class="icon-wrap" *ngIf="!checkPassword('lowercase', password.value)"><fa-icon [icon]="['fas', 'times']"></fa-icon></span>


              One lowercase letter
            </li>
            <li
              [ngClass]="{
                'text-danger': !checkPassword(
                  'number',
                  password.value
                ),
                'text-success': checkPassword(
                  'number',
                  password.value
                )
              }"
            >

            <span class="icon-wrap" *ngIf="checkPassword('number', password.value)"><fa-icon [icon]="['fas', 'check']"></fa-icon></span>
            <span class="icon-wrap" *ngIf="!checkPassword('number', password.value)"><fa-icon [icon]="['fas', 'times']"></fa-icon></span>

              One number
            </li>
            <li
              [ngClass]="{
                'text-danger': !checkPassword(
                  'special',
                  password.value
                ),
                'text-success': checkPassword(
                  'special',
                  password.value
                )
              }"
            >

            <span class="icon-wrap" *ngIf="checkPassword('special', password.value)"><fa-icon [icon]="['fas', 'check']"></fa-icon></span>
            <span class="icon-wrap" *ngIf="!checkPassword('special', password.value)"><fa-icon [icon]="['fas', 'times']"></fa-icon></span>

              One special character ($@!%*?&amp;#)
            </li>
          </ul>
        </div>
      </div>

      <div class="form-group">
        <label for="confirmPassword"
          >CONFIRM PASSWORD <span class="required">*</span></label
        >
        <input
          type="password"
          name="confirmPassword"
          class="form-control input-tracker"
          placeholder=""
          id="confirmPassword"
          ngModel
          #confirmPassword="ngModel"
          autocomplete="off"
          required="true"
          [equalTo]="password"
        />
        <div class="alert alert-danger" *ngIf="(confirmPassword.touched && confirmPassword.invalid) || (confirmPassword.touched && confirmPassword.errors?.equalTo)">Your passwords do not match or there is an error with your password.</div>
      </div>

      <div *ngIf="regProgramme">

      <div
      [hidden]="regProgrammeConfig.m3==1 || regProgrammeConfig.gu1de==1"
        class="form-group"
        *ngIf="(environment.defaultProgramme != 'BabySteps' && environment.country !== 'AUS') || (environment.country == 'AUS' && regProgramme.programme_id !=3)"

      >
        <label for="attendedBefore"
          >
          
          <span *ngIf="environment.country == 'AUS'">
            <span *ngIf="regProgramme.programme_id == 2">HAVE YOU ATTENDED A FACE TO FACE DESMOND PROGRAM?</span>            
            <span *ngIf="regProgramme.programme_id == 8">HAVE YOU ATTENDED A FACE TO FACE LET'S PREVENT PROGRAM?</span>            
            <span *ngIf="regProgramme.programme_id != 8 && regProgramme.programme_id != 2">HAVE YOU ATTENDED A FACE TO FACE PROGRAM?</span>
          </span>  
          <span *ngIf="environment.country !== 'AUS'">HAVE YOU ATTENDED A FACE TO FACE DESMOND PROGRAMME?</span> 

          
          <span class="required">*</span></label
        >
        <input
          type="hidden"
          name="attendedBefore"
          id="attendedBefore"
          [(ngModel)]="regDetails.attendedBefore"
          #attendedBefore="ngModel"
          [required]="regProgrammeConfig.m3!=1"
        />
        <div class="row">
          <div class="col-6">
            <button
            type="button"
             class="btn-block btn btn-default"
              [ngClass]="{
                'btn-primary': regDetails.attendedBefore === 1,
                'btn-default': regDetails.attendedBefore === 0
              }"
              (click)="regDetails.attendedBefore = 1"
            >
              YES
            </button>
          </div>
          <div class="col-6">
            <button
            type="button"
             class="btn-block btn btn-default"
            [ngClass]="{
              'btn-primary': regDetails.attendedBefore === 0,
              'btn-default': regDetails.attendedBefore === 1
            }"
              (click)="regDetails.attendedBefore = 0"
            >
              NO
            </button>
          </div>
        </div>

        <div class="alert alert-danger" *ngIf="(stepSubmitted[4] && regDetails.attendedBefore==null) ">Please select either Yes or No.</div>
      </div>    
    </div>


    <!--div class="form-group" [hidden]="regProgrammeConfig.m3!=1">
      <input
          type="checkbox"
          name="research_consent"
          id="research_consent"
          #research_consent="ngModel"
          [(ngModel)]="regDetails.research_consent"
        />
        I consent for my anonymised data collected on this platform to be used for research purposes.
    </div-->

      <div class="form-group">
        <input
          type="checkbox"
          name="terms"
          id="terms"
          #terms="ngModel"
          [(ngModel)]="regDetails.terms"
          required
        />
        I agree to the <a href="/terms-of-use" target="_blank">Terms of Use</a>,
        <a href="/privacy-policy" target="_blank">Privacy/Cookie Policy</a> and
        <a href="/acceptable-use" target="_blank">Acceptable Use Policy</a>
        <span class="required">*</span>.
        <div class="alert alert-danger"  *ngIf="(terms.touched && terms.invalid) ||  (stepSubmitted[4] && terms.invalid)">Please agree to the terms.</div>
      </div>

      <div class="form-group" *ngIf="environment.country == 'AUS'">
        <input
          type="checkbox"
          name="agree_marketing"
          id="agree_marketing"
          #agree_marketing="ngModel"
          required
          [(ngModel)]="regDetails.agree_marketing"
        />
        I would like to receive more information from 
        <span *ngIf="environment.cookieDomain == 'https://www.mydesmondaustralia.com.au' || environment.cookieDomain == 'https://test.mydesmondaustralia.com.au' || environment.cookieDomain == 'https://play.mydesmondaustralia.com.au'">Diabetes WA</span>
        <span *ngIf="environment.cookieDomain == 'https://trn-mydesmond.ndss.com.au' || environment.cookieDomain == 'https://stg-mydesmond.ndss.com.au' || environment.cookieDomain == 'https://mydesmond.ndss.com.au'">NDSS Agents</span>
          about other
        ways to manage diabetes or stay healthy.
      </div>

      <p class="required-msg">
        <span class="required">*</span> indicates required field
      </p>
      <hr class="divider" />
      <div class="container">
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6 text-right">
            <button (click)="checkReg(4)" class="btn btn-primary btn-white" [disabled]="isFetching" type="button"
              >NEXT <span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span></button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- step 5 -->
  <!--div *ngIf="step==5">
        <div class="reading-material-page-header booster-session-step-header register-header text-left">5 | Team Name</div>
        <div class="container">
            <p>Please vote for your team name from the selsection of choices below...</p>
        </div>
            <div class="toggle-header toggle-header-left" ng-repeat="teamName in teamNames" (click)="teamVote(teamName)">
                <a class="toggle-nav toggle-nav-expand"><i class="fa fa-arrow-circle-right"></i></a>
                <div class="toggle-header-title">{{teamName | uppercase}}</div>
            </div>
            
            <hr class="divider" />
            <div class="container">
                <div class=" row">
                    <div class="col-6">
                    </div>
                    <div class="col-6 text-right">
                        <a (click)="" class="btn btn-primary btn-white">NEXT <span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span></a>
                    </div>
                </div>
            </div>
    </div-->

  <!-- step 5 -->
  <div [hidden]="step !== 5">
    <div
      class="reading-material-page-header booster-session-step-header register-header text-left"
    >
      Completed
    </div>
    <div class="container">
      <p>Congratulations!</p>
      <div *ngIf="environment.defaultProgramme != 'BabySteps'">
        <div *ngIf="environment.country == 'AUS'">
          <span *ngIf="regProgramme.programme_id == 2">You are now registered to use MyDESMOND.</span>
          <span *ngIf="regProgramme.programme_id == 3">You are now registered to use Baby Steps.</span>
            <span *ngIf="regProgramme.programme_id == 8">You are now registered to use Let's Prevent.</span>
        </div>
        <div *ngIf="environment.country !== 'AUS'">
          <p>You are now registered to use {{regProgramme.title}}.</p>
        </div>

        <!--p class="visible-xs">
          We recommend you add the MyDesmond shortcut icon to your home screen
          on your mobile phone using the button below.
        </p-->
      </div>
      <div *ngIf="environment.defaultProgramme == 'BabySteps'">
        <p>
          You are now registered to use Baby Steps - part of the MyDesmond
          family.
        </p>
        <!--p class="visible-xs">
          We recommend you add the MyDesmond shortcut icon to your home screen
          on your mobile phone using the button below. Please note this feature
          is not available for Chrome users on iOS
        </p-->
      </div>
    </div>
    <!--div class="text-center visible-xs">
      <img
        src="/app/assets/icons/apple-icon-180x180.png"
        width="115"
        height="115"
      /><br /><br />
      <button class="btn btn-primary" (click)="addToPhone()">
        <i class="fa fa-plus-circle"></i> ADD TO PHONE
      </button>
    </div-->
    <hr class="divider" />
    <div class="container">
      <div class="row">
        <div class="col-6"></div>
        <div class="col-6 text-right">
          <a (click)="complete()" class="btn btn-primary btn-white"
            >NEXT <span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span></a>
        </div>
      </div>
    </div>
  </div>
</div>
</form>
