import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { AuthService } from './../auth/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class BloodGlucoseService {
  userId;
  endPoint = '/user_blood_glucoses';
  glucoseAverages?: any;
  glucoseTimeInRanges?: any;
  glucoseTimeBelowRanges?: any;

  allEntries?: any;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userId = user.id;
      }
    });
  }

  fetchAll() : Observable<any> {
    if (!this.userId) {
      return;
    }

    if (!this.allEntries) {
      let searchParams = new HttpParams();

      searchParams = searchParams.append('user_id', this.userId);
      searchParams = searchParams.append('sort', 'created');
      searchParams = searchParams.append('order', 'DESC');

      this.allEntries = this.http
        .get<any>(environment.apiUrl + this.endPoint, {
          params: searchParams,
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            return responseData._embedded.user_blood_glucoses;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.allEntries;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.endPoint + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(glucose, glucosePercent, entryDate) {
    this.clearCache();
    const postData = {
      user_id: this.userId,
      glucose,
      glucosePercent,
      created: entryDate,
    };
    return this.http
      .post<{ name: string }>(environment.apiUrl + this.endPoint, postData, {
        observe: 'response',
      })
      .pipe(
        map((responseData) => {
          return responseData.body;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  update(id, glucose, glucosePercent) {
    this.clearCache();
    const postData = {
      glucose,
      glucosePercent,
    };
    return this.http.patch<{ name: string }>(
      environment.apiUrl + this.endPoint + '/' + id,
      postData,
      {
        observe: 'response',
      }
    );
  }

  delete(id) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.endPoint + '/' + id
    );
  }

  fetchGlucoseAverages() {
    if (!this.glucoseAverages) {
      this.glucoseAverages = this.http
        .get<any>(environment.apiUrl + '/user_glucose_averages', {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: any[] = [];
            responseData['_embedded']['user_glucose_averages'].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.glucoseAverages;
  }

  createAverage(glucose_average: string, date_recorded: string) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      glucose_average,
    };
    return this.http.post<any>(
      environment.apiUrl + '/user_glucose_averages',
      payload,
      {
        observe: 'response',
      }
    );
  }

  updateAverage(id: number, glucose_average: string, date_recorded: string) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      glucose_average,
    };
    return this.http.patch<any>(
      environment.apiUrl + '/user_glucose_averages/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  deleteAverage(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + '/user_glucose_averages/' + id
    );
  }

  fetchGlucoseTimeInRanges() {
    if (!this.glucoseTimeInRanges) {
      this.glucoseTimeInRanges = this.http
        .get<any>(environment.apiUrl + '/user_glucose_time_in_ranges', {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: any[] = [];
            responseData['_embedded']['user_glucose_time_in_ranges'].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.glucoseTimeInRanges;
  }

  createTimeInRange(time_in_range: string, date_recorded: string) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      time_in_range,
    };
    return this.http.post<any>(
      environment.apiUrl + '/user_glucose_time_in_ranges',
      payload,
      {
        observe: 'response',
      }
    );
  }

  updateTimeInRange(id: number, time_in_range: string, date_recorded: string) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      time_in_range,
    };
    return this.http.patch<any>(
      environment.apiUrl + '/user_glucose_time_in_ranges/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  deleteTimeInRange(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + '/user_glucose_time_in_ranges/' + id
    );
  }

  fetchGlucoseTimeBelowRanges() {
    if (!this.glucoseTimeBelowRanges) {
      this.glucoseTimeBelowRanges = this.http
        .get<any>(environment.apiUrl + '/user_glucose_time_below_ranges', {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: any[] = [];
            responseData['_embedded']['user_glucose_time_below_ranges'].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.glucoseTimeBelowRanges;
  }

  createTimeBelowRange(time_below_range: string, date_recorded: string) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      time_below_range,
    };
    return this.http.post<any>(
      environment.apiUrl + '/user_glucose_time_below_ranges',
      payload,
      {
        observe: 'response',
      }
    );
  }

  updateTimeBelowRange(
    id: number,
    time_below_range: string,
    date_recorded: string
  ) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      time_below_range,
    };
    return this.http.patch<any>(
      environment.apiUrl + '/user_glucose_time_below_ranges/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  deleteTimeBelowRange(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + '/user_glucose_time_below_ranges/' + id
    );
  }

  getRag(hba1c) {
    let ragColour = '';

    if (+hba1c <= 7) {
      ragColour = 'green';
    } else if (+hba1c > 7 && +hba1c <= 8) {
      ragColour = 'amber';
    } else {
      ragColour = 'red';
    }
    return ragColour;
  }

  getHba1cforColor(colour: string) {
    let ragRange = '';
    if (colour == 'green') {
      ragRange = '≤7.0';
    } else if (colour == 'amber') {
      ragRange = '7.1-8.0';
    } else {
      ragRange = '>8.0';
    }

    return ragRange;
  }

  getHba1cTarget() {
    return '6.5-7.0';
  }

  getChangeRag(change) {
    let ragColour = '';

    if (+change >= 0.3) {
      ragColour = 'red';
    } else if (+change < 0.3 && +change > -0.3) {
      ragColour = 'amber';
    } else {
      ragColour = 'green';
    }
    return ragColour;
  }

  getChangeByColour(colour) {
    let range = '';
    if (colour == 'green') {
      range = '- ≥0.3%';
    } else if (colour == 'amber') {
      range = '+/- <0.3';
    } else if (colour == 'red') {
      range = '≥0.3%';
    }
    return range;
  }

  getAverageChangeRag(change) {
    let ragColour = '';

    if (+change >= 0.5) {
      ragColour = 'red';
    } else if (+change < 0.5 && +change > -0.5) {
      ragColour = 'amber';
    } else {
      ragColour = 'green';
    }
    return ragColour;
  }

  getAverageChangeByColour(colour) {
    let range = '';
    if (colour == 'green') {
      range = '- ≥0.5';
    } else if (colour == 'amber') {
      range = '+/- <0.5';
    } else if (colour == 'red') {
      range = '≥0.5';
    }
    return range;
  }

  getInRangeRag(change) {
    let ragColour = '';

    if (+change >= 70) {
      ragColour = 'green';
    } else if (+change < 50) {
      ragColour = 'red';
    } else {
      ragColour = 'amber';
    }
    return ragColour;
  }

  getInRangeByColour(colour) {
    let range = '';
    if (colour == 'green') {
      range = '≥70';
    } else if (colour == 'amber') {
      range = '50-69';
    } else if (colour == 'red') {
      range = '<50';
    }
    return range;
  }

  getBelowRangeRag(change) {
    let ragColour = '';

    if (+change <= 4) {
      ragColour = 'green';
    } else if (+change > 6.0) {
      ragColour = 'red';
    } else {
      ragColour = 'amber';
    }
    return ragColour;
  }

  getBelowRangeByColour(colour) {
    let range = '';
    if (colour == 'green') {
      range = '≤4';
    } else if (colour == 'amber') {
      range = '4.1-6';
    } else if (colour == 'red') {
      range = '>6.0';
    }
    return range;
  }

  getGlucoseAverageRag(value) {
    let ragColour = '';

    if (+value < 8.6) {
      ragColour = 'green';
    } else if (+value <= 10) {
      ragColour = 'amber';
    } else {
      ragColour = 'red';
    }
    return ragColour;
  }

  clearCache() {
    this.glucoseAverages = null;
    this.glucoseTimeBelowRanges = null;
    this.glucoseTimeInRanges = null;
    this.allEntries = null;
  }
}
