import { UserService } from './user.service';
import { map, catchError } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { AuthService } from './../auth/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommentService {
  userId;
  currentUser;
  endPoint = '/comments';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService
  ) {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userId = user.id;
        this.userService.userData.subscribe((userDataResponse) => {
          this.currentUser = userDataResponse;
        });
      }
    });
  }

  fetchAskTheExpert() {
    if (!this.userId) {
      return;
    }
    let searchParams = new HttpParams();

    searchParams = searchParams.append('comment_type_ref', this.userId);
    searchParams = searchParams.append('comment_type', 'askTheExpert');
    searchParams = searchParams.append('sort', 'created');
    searchParams = searchParams.append('order', 'DESC');
    return this.http
      .get<any>(environment.apiUrl + this.endPoint, {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.comments;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchTechnicalSupport() {
    if (!this.userId) {
      return;
    }
    let searchParams = new HttpParams();

    searchParams = searchParams.append('comment_type_ref', this.userId);
    searchParams = searchParams.append('comment_type', 'technicalSupport');
    searchParams = searchParams.append('sort', 'created');
    searchParams = searchParams.append('order', 'DESC');
    return this.http
      .get<any>(environment.apiUrl + this.endPoint, {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.comments;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchAttachments() {
    if (!this.userId) {
      return;
    }
    let searchParams = new HttpParams();

    searchParams = searchParams.append('user_id', this.userId);
    return this.http
      .get<any>(environment.apiUrl + '/comment_attachments', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.comment_attachments;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.endPoint + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  createAskTheExpert(comment) {
    const postData = {
      user_id: this.userId,
      client_id: this.currentUser.client_id,
      likes: 0,
      comment_type: 'askTheExpert',
      comment_type_ref: ''+this.userId,
      comment,
    };
    return this.http
      .post<{ name: string }>(environment.apiUrl + this.endPoint, postData, {
        observe: 'response',
      })
      .pipe(
        map((responseData) => {
          return responseData.body;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  createTechnicalSupport(comment) {
    const postData = {
      user_id: this.userId,
      client_id: this.currentUser.client_id,
      likes: 0,
      comment_type: 'technicalSupport',
      comment_type_ref: ''+this.userId,
      comment,
    };
    return this.http
      .post<{ name: string }>(environment.apiUrl + this.endPoint, postData, {
        observe: 'response',
      })
      .pipe(
        map((responseData) => {
          return responseData.body;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchBuddyChallenge(comment_type_ref) {
    let searchParams = new HttpParams();

    searchParams = searchParams.append('comment_type_ref', comment_type_ref);
    searchParams = searchParams.append('comment_type', 'buddyChallenge');
    searchParams = searchParams.append('sort', 'created');
    searchParams = searchParams.append('order', 'ASC');
    return this.http
      .get<any>(environment.apiUrl + this.endPoint, {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.comments;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchBuddyChallengeLikes(comment_type_ref) {
    if (!this.userId) {
      return;
    }
    let searchParams = new HttpParams();

    searchParams = searchParams.append('user_id', this.userId);
    searchParams = searchParams.append('comment_type_ref', comment_type_ref);
    searchParams = searchParams.append('comment_type', 'buddyChallenge');
    searchParams = searchParams.append('sort', 'created');
    searchParams = searchParams.append('order', 'ASC');
    return this.http
      .get<any>(environment.apiUrl + '/user_comment_likes', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.user_comment_likes;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  createBuddyComment(comment, comment_type_ref) {
    const postData = {
      user_id: this.userId,
      client_id: this.currentUser.client_id,
      likes: 0,
      comment_type: 'buddyChallenge',
      comment_type_ref: comment_type_ref,
      comment,
    };
    return this.http
      .post<{ name: string }>(environment.apiUrl + this.endPoint, postData, {
        observe: 'response',
      })
      .pipe(
        map((responseData) => {
          return responseData.body;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchForumTopics(programmeId, category, page, perPage, count?) {
    let searchParams = new HttpParams();

    if (category === 'all') {
      category = null;
    }
    if (category === 'general') {
      category = '';
    }
    searchParams = searchParams.append('comment_type', 'global_chat');
    if (category) {
      searchParams = searchParams.append(
        'comment_type_ref',
        'topic' + category
      );
    } else if (category === '') {
      searchParams = searchParams.append('comment_type_ref', 'topic');
    } else {
      searchParams = searchParams.append('comment_type_ref[]', 'topic');
      searchParams = searchParams.append('comment_type_ref[]', 'topicExercise');
      searchParams = searchParams.append('comment_type_ref[]', 'topicDiet');
      searchParams = searchParams.append('comment_type_ref[]', 'topicHealth');
    }
    if (page) {
      searchParams = searchParams.append('page', '' + page);
    }
    if (perPage) {
      searchParams = searchParams.append('page_limit', '' + perPage);
    }
    searchParams = searchParams.append('sort', 'modified');
    searchParams = searchParams.append('order', 'DESC');
    searchParams = searchParams.append('programme_id', programmeId);

    if (count) {
      searchParams = searchParams.append('get_count', 'true');
    }
    return this.http
      .get<any>(environment.apiUrl + '/comments', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.comments;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchUserTopicsForum() {
    let searchParams = new HttpParams();

    searchParams = searchParams.append('comment_type', 'global_chat');
    searchParams = searchParams.append('user_id', this.userId);
    searchParams = searchParams.append('sort', 'modified');
    searchParams = searchParams.append('order', 'DESC');
    return this.http
      .get<any>(environment.apiUrl + '/comments', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.comments;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchGlobalReplies() {
    let searchParams = new HttpParams();

    searchParams = searchParams.append('user_id', this.userId);
    searchParams = searchParams.append('comment_type', 'global_chat_reply');
    searchParams = searchParams.append('sort', 'modified');
    searchParams = searchParams.append('order', 'DESC');
    return this.http
      .get<any>(environment.apiUrl + '/comments', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.comments;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchCommentViews() {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('user_id', this.userId);

    return this.http
      .get<any>(environment.apiUrl + '/user_comment_views', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.user_comment_views;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchCommentLikes() {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('user_id', this.userId);
    searchParams = searchParams.append('sort', 'created');
    searchParams = searchParams.append('order', 'ASC');

    return this.http
      .get<any>(environment.apiUrl + '/user_comment_likes', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.user_comment_likes;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  getTopic(topicId) {
    return this.http
      .get<any>(environment.apiUrl + this.endPoint + '/' + topicId, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  getTopicReplies(topicId) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('comment_type', 'global_chat_reply');
    searchParams = searchParams.append('comment_type_ref', topicId);
    searchParams = searchParams.append('sort', 'created');
    searchParams = searchParams.append('order', 'ASC');

    return this.http
      .get<any>(environment.apiUrl + '/comments', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.comments;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  createTopicReply(topicId, comment) {
    const postData = {
      user_id: this.userId,
      client_id: this.currentUser.client_id,
      likes: 0,
      comment_type: 'global_chat_reply',
      comment_type_ref: topicId,
      comment,
    };
    return this.http
      .post<{ name: string }>(environment.apiUrl + this.endPoint, postData, {
        observe: 'response',
      })
      .pipe(
        map((responseData) => {
          return responseData.body;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  createTopic(category, subject, comment, programmeId) {
    category = category.charAt(0).toUpperCase() + category.slice(1);
    if (category === 'General') {
      category = '';
    }
    const postData = {
      user_id: this.userId,
      client_id: this.currentUser.client_id,
      likes: 0,
      comment_type: 'global_chat',
      comment_type_ref: 'topic' + category,
      subject,
      comment,
      programme_id: programmeId,
    };
    return this.http
      .post<{ name: string }>(environment.apiUrl + this.endPoint, postData, {
        observe: 'response',
      })
      .pipe(
        map((responseData) => {
          return responseData.body;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  createCommentView(commentId) {
    const postData = {
      user_id: this.userId,
      comment_id: commentId,
    };
    return this.http
      .post<{ name: string }>(
        environment.apiUrl + '/user_comment_views',
        postData,
        {
          observe: 'response',
        }
      )
      .pipe(
        map((responseData) => {
          return responseData.body;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  likeComment(commentId, commentType, commentTypeRef) {
    const postData = {
      user_id: this.userId,
      comment_id: commentId,
      comment_type: commentType,
      comment_type_ref: commentTypeRef,
    };
    return this.http
      .post<{ name: string }>(
        environment.apiUrl + '/user_comment_likes',
        postData,
        {
          observe: 'response',
        }
      )
      .pipe(
        map((responseData) => {
          return responseData.body;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  unlikeComment(commentLikeId) {
    return this.http.delete<{ name: string }>(
      environment.apiUrl + '/user_comment_likes/' + commentLikeId
    );
  }

  updateComment(commentId, commentData) {
    return this.http.patch<{ name: string }>(
      environment.apiUrl + this.endPoint + '/' + commentId,
      commentData,
      {
        observe: 'response',
      }
    );
  }

  searchForum(query, programmeId) {
    let searchParams = new HttpParams();

    searchParams = searchParams.append('comment_type[]', 'global_chat');
    searchParams = searchParams.append('comment_type[]', 'global_chat_reply');
    searchParams = searchParams.append('query', query);
    searchParams = searchParams.append('sort', 'modified');
    searchParams = searchParams.append('order', 'DESC');
    searchParams = searchParams.append('programme_id', programmeId);

    return this.http
      .get<any>(environment.apiUrl + '/comments', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.comments;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
}
