import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { AuthService } from './../auth/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BloodPressureService {
  userId;
  endPoint = '/user_blood_pressures';
  allEntries?: any;


  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userId = user.id;
      }
    });
  }

  fetchAll(): Observable<any> {
    if (!this.userId) {
      return;
    }
    if (!this.allEntries) {
    let searchParams = new HttpParams();

    searchParams = searchParams.append('user_id', this.userId);
    searchParams = searchParams.append('sort', 'created');
    searchParams = searchParams.append('order', 'DESC');
    this.allEntries =  this.http
      .get<any>(environment.apiUrl + this.endPoint, {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.user_blood_pressures;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        }),
        publishReplay(1),
        refCount()
      );
    }
    return this.allEntries;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.endPoint + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(diastolic, systolic, entryDate) {
    this.clearCache();
    const postData = {
      user_id: this.userId,
      diastolic,
      systolic,
      created: entryDate,
    };
    return this.http
      .post<{ name: string }>(environment.apiUrl + this.endPoint, postData, {
        observe: 'response',
      })
      .pipe(
        map((responseData) => {
          return responseData.body;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  update(id, diastolic, systolic) {
    this.clearCache();
    const postData = {
      diastolic,
      systolic,
    };
    return this.http.patch<{ name: string }>(
      environment.apiUrl + this.endPoint + '/' + id,
      postData,
      {
        observe: 'response',
      }
    );
  }

  delete(id) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.endPoint + '/' + id
    );
  }



  getRag(systolic, diastolic) {
    let ragColour = '';
  
    if(+systolic <= 130 && diastolic<=80){
      ragColour = 'green';
    }
    else if(+systolic > 130 && systolic< 141 && diastolic>=80 && diastolic<91){
      ragColour = 'amber';

    }
    else if(+systolic > 140 && diastolic>90){
      ragColour = 'red';
    }
    return ragColour;
  }



  getSystolicRag(systolic) {
    let ragColour = '';
  
    if(+systolic <= 139 ){
      ragColour = 'green';
    }
    else if(+systolic > 140 && systolic< 149){
      ragColour = 'amber';

    }
    else if(+systolic > 149){
      ragColour = 'red';
    }
    return ragColour;
  }



  getDiastolicRag(diastolic) {
    let ragColour = '';
  
    if(+diastolic<=89){
      ragColour = 'green';
    }
    else if(+diastolic>=99 && +diastolic<99){
      ragColour = 'amber';

    }
    else if(+diastolic>99){
      ragColour = 'red';
    }
    return ragColour;
  }


  clearCache() {
    this.allEntries = null;
  }
}
