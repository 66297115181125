import { CommentService } from './../services/comment.service';
import { environment } from './../../environments/environment';
import { UserInviteService } from './../services/user-invite.service';
import { DateHelperService } from './../services/date-helper.service';
import { ActivityService } from './../services/activity.service';
import { ProgrammeService } from './../services/programme.service';
import { UserService } from './../services/user.service';
import { AuthService } from './../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import * as moment from 'moment';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-friends-and-family',
  templateUrl: './friends-and-family.component.html',
  styleUrls: ['./friends-and-family.component.scss'],
})
export class FriendsAndFamilyComponent implements OnInit {
  currentUser;
  currentProgramme;
  isFetching;
  monfriComments = [];
  weekendComments = [];
  currentChallenge = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };

  monfriChallenge = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  weekendChallenge = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  monfriChallengeNext = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  weekendChallengeNext = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  monfriChallengePrev = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  weekendChallengePrev = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };

  weekendActivity;
  monFriActivity;

  currentLeader = [];
  iLead;

  today;
  currentWeek;
  nowTime;
  now;

  remainingInvites = 0;

  myActivity;

  toggleRow = {
    monFri: false,
    weekend: false,
    custom: false,
  };

  showPercent;

  maxIvites = 5;
  buddyRef;
  invites;

  tableModeWe;
  tableMode;

  environment = environment;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private activityService: ActivityService,
    private dateHelperService: DateHelperService,
    private userInviteService: UserInviteService,
    private commentService: CommentService
  ) {}

  ngOnInit(): void {
    // get week
    const now = moment();

    // what is the day today
    this.today = moment(now).format('dddd');
    this.currentWeek = this.dateHelperService.getWeekFor(now);
    this.now = now.format('YYYY-MM-DD');

    this.nowTime = now.format('YYYY-MM-DD  HH:mm:ss');

    if (this.today === 'Saturday' || this.today === 'Sunday') {
      this.weekendChallenge.title = 'Weekend Challenge';
      this.currentChallenge.title = 'Weekend Challenge';
      this.weekendChallenge.startDate = moment(this.currentWeek[5]).format(
        'YYYY-MM-DD'
      );
      this.weekendChallenge.nextStartDate = moment(this.currentWeek[5])
        .add(1, 'week')
        .format('YYYY-MM-DD');
      this.weekendChallenge.endDate = moment(this.currentWeek[6]).format(
        'YYYY-MM-DD'
      );
      this.weekendChallenge.type = 'WEEKEND';

      this.currentChallenge = this.weekendChallenge;

      this.monfriChallenge.title = 'Monday - Friday Challenge';
      this.monfriChallenge.startDate = moment(this.currentWeek[0]).format(
        'YYYY-MM-DD'
      );
      this.monfriChallenge.endDate = moment(this.currentWeek[4]).format(
        'YYYY-MM-DD'
      );
      this.monfriChallenge.type = 'MON-FRI';
      this.monfriChallenge.id =
        this.monfriChallenge.type +
        ';' +
        this.monfriChallenge.startDate +
        ';' +
        this.monfriChallenge.endDate;
      this.monfriChallenge = this.monfriChallenge;
    } else {
      this.monfriChallenge.title = 'Monday - Friday Challenge';
      this.currentChallenge.title = 'Monday - Friday Challenge';
      this.monfriChallenge.startDate = moment(this.currentWeek[0]).format(
        'YYYY-MM-DD'
      );
      this.monfriChallenge.endDate = moment(this.currentWeek[4]).format(
        'YYYY-MM-DD'
      );
      this.monfriChallenge.type = 'MON-FRI';
      this.monfriChallenge.id =
        this.monfriChallenge.type +
        ';' +
        this.monfriChallenge.startDate +
        ';' +
        this.monfriChallenge.endDate;

      this.currentChallenge = this.monfriChallenge;

      this.weekendChallenge.title = 'Weekend Challenge';
      this.weekendChallenge.startDate = moment(this.currentWeek[5])
        .subtract(1, 'week')
        .format('YYYY-MM-DD');
      this.weekendChallenge.nextStartDate = moment(this.currentWeek[5]).format(
        'YYYY-MM-DD'
      );

      this.weekendChallenge.endDate = moment(this.currentWeek[6])
        .subtract(1, 'week')
        .format('YYYY-MM-DD');
      this.weekendChallenge.type = 'WEEKEND';
      this.weekendChallenge.id =
        this.weekendChallenge.type +
        ';' +
        this.weekendChallenge.startDate +
        ';' +
        this.weekendChallenge.endDate;
    }

    const user = this.authService.user;
    this.isFetching = true;
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (this.currentProgramme) {
          this.userService.userData
            .pipe(take(1))
            .subscribe((userDataResponse) => {
              if (userDataResponse) {
                this.currentUser = userDataResponse;

                forkJoin({
                  invites: this.userInviteService.fetchAll(),
                  asBuddy: this.userInviteService.fetchAsFriend(),
                  monFriActivity: this.activityService.getBuddyActivity(
                    this.monfriChallenge.startDate,
                    this.monfriChallenge.endDate
                  ),
                  weekendActivity: this.activityService.getBuddyActivity(
                    this.weekendChallenge.startDate,
                    this.weekendChallenge.endDate
                  ),
                }).subscribe(
                  (responseData) => {
                    this.isFetching = false;
                    this.invites = responseData.invites;
                    this.remainingInvites =
                      this.maxIvites - this.invites.length;
                    if (responseData.asBuddy[0]) {
                      this.buddyRef = responseData.asBuddy[0].user_id;
                    }
                    this.weekendActivity = responseData.weekendActivity;
                    this.monFriActivity = responseData.monFriActivity;

                    if (this.currentChallenge.title === 'MON-FRI') {
                      this.setCurrentLeader(this.monFriActivity);
                    } else {
                      this.setCurrentLeader(this.weekendActivity);
                    }

                    // get the comments
                    if (this.currentUser.buddy == 1) {
                      this.buddyRef = responseData.asBuddy[0].user_id;
                    } else {
                      this.buddyRef = this.currentUser.user_id;
                    }

                    forkJoin({
                      monFriComments: this.commentService.fetchBuddyChallenge(
                        this.buddyRef + ';' + this.monfriChallenge.id
                      ),
                      weComments: this.commentService.fetchBuddyChallenge(
                        this.buddyRef + ';' + this.weekendChallenge.id
                      ),
                    }).subscribe((commentsResponse) => {
                      this.monfriComments = commentsResponse.monFriComments;
                      this.weekendComments = commentsResponse.weComments;
                    });
                  },
                  (error) => {}
                );
              }
            });
        }
      });
    }
  }

  setFilterBand(band, type) {}

  prevData(challenge) {
    this.tableMode = 'activity';
    if (challenge === 'mon-fri') {
      // work out prev
      this.monfriChallengePrev.startDate = moment(
        this.monfriChallenge.startDate
      )
        .subtract(1, 'week')
        .format('YYYY-MM-DD');
      this.monfriChallengePrev.endDate = moment(this.monfriChallenge.endDate)
        .subtract(1, 'week')
        .format('YYYY-MM-DD');

      this.monfriChallenge.title = 'Monday - Friday Challenge';

      this.monfriChallenge.startDate = this.monfriChallengePrev.startDate;
      this.monfriChallenge.endDate = this.monfriChallengePrev.endDate;
      this.monfriChallenge.type = 'MON-FRI';
      this.monfriChallenge.id =
        'band-' +
        this.currentUser.step_band +
        ';' +
        this.monfriChallenge.type +
        ';' +
        this.monfriChallengePrev.startDate +
        ';' +
        this.monfriChallengePrev.endDate;

      // get data OR awards

      this.activityService
        .getBuddyActivity(
          this.monfriChallenge.startDate,
          this.monfriChallenge.endDate
        )
        .subscribe(
          (responseData) => {
            this.monFriActivity = responseData;
          },
          (error) => {}
        );
    } else {
      // work out prev
      this.weekendChallengePrev.startDate = moment(
        this.weekendChallenge.startDate
      )
        .subtract(1, 'week')
        .format('YYYY-MM-DD');
      this.weekendChallengePrev.endDate = moment(this.weekendChallenge.endDate)
        .subtract(1, 'week')
        .format('YYYY-MM-DD');

      this.weekendChallenge.title = 'Weekend Challenge';

      this.weekendChallenge.startDate = this.weekendChallengePrev.startDate;
      this.weekendChallenge.nextStartDate = moment(
        this.weekendChallenge.startDate
      )
        .add(1, 'week')
        .format('YYYY-MM-DD');
      this.weekendChallenge.endDate = this.weekendChallengePrev.endDate;
      this.weekendChallenge.type = 'WEEKEND';
      this.weekendChallenge.id =
        'band-' +
        this.currentUser.step_band +
        ';' +
        this.weekendChallenge.type +
        ';' +
        this.weekendChallengePrev.startDate +
        ';' +
        this.weekendChallengePrev.endDate;

      this.activityService
        .getBuddyActivity(
          this.weekendChallenge.startDate,
          this.weekendChallenge.endDate
        )
        .subscribe(
          (responseData) => {
            this.weekendActivity = responseData;
          },
          (error) => {}
        );
    }
  }
  nextData(challenge) {
    this.tableMode = 'activity';
    if (challenge === 'mon-fri') {
      // work out next
      this.monfriChallengeNext.startDate = moment(
        this.monfriChallenge.startDate
      )
        .add(1, 'week')
        .format('YYYY-MM-DD');
      this.monfriChallengeNext.endDate = moment(this.monfriChallenge.endDate)
        .add(1, 'week')
        .format('YYYY-MM-DD');

      this.monfriChallenge.title = 'Monday - Friday Challenge';

      this.monfriChallenge.startDate = this.monfriChallengeNext.startDate;
      this.monfriChallenge.endDate = this.monfriChallengeNext.endDate;
      this.monfriChallenge.type = 'MON-FRI';
      this.monfriChallenge.id =
        'band-' +
        this.currentUser.step_band +
        ';' +
        this.monfriChallenge.type +
        ';' +
        this.monfriChallengeNext.startDate +
        ';' +
        this.monfriChallengeNext.endDate;

      // get data OR awards
      this.activityService
        .getBuddyActivity(
          this.monfriChallenge.startDate,
          this.monfriChallenge.endDate
        )
        .subscribe(
          (responseData) => {
            this.monFriActivity = responseData;
          },
          (error) => {}
        );
    } else {
      // work out next
      this.weekendChallengeNext.startDate = moment(
        this.weekendChallenge.startDate
      )
        .add(1, 'week')
        .format('YYYY-MM-DD');
      this.weekendChallengeNext.endDate = moment(this.weekendChallenge.endDate)
        .add(1, 'week')
        .format('YYYY-MM-DD');

      this.weekendChallenge.title = 'Weekend Challenge';

      this.weekendChallenge.startDate = this.weekendChallengeNext.startDate;
      this.weekendChallenge.nextStartDate = moment(
        this.weekendChallenge.startDate
      )
        .add(1, 'week')
        .format('YYYY-MM-DD');
      this.weekendChallenge.endDate = this.weekendChallengeNext.endDate;
      this.weekendChallenge.type = 'WEEKEND';
      this.weekendChallenge.id =
        'band-' +
        this.currentUser.step_band +
        ';' +
        this.weekendChallenge.type +
        ';' +
        this.weekendChallengeNext.startDate +
        ';' +
        this.weekendChallengeNext.endDate;

      // get data

      this.activityService
        .getBuddyActivity(
          this.weekendChallenge.startDate,
          this.weekendChallenge.endDate
        )
        .subscribe(
          (responseData) => {
            this.weekendActivity = responseData;
          },
          (error) => {}
        );
    }
  }

  setCurrentLeader(activity) {
    this.currentLeader = [];

    activity.forEach((item, index) => {
      if (this.currentLeader.length == 0) {
        this.currentLeader.push(activity[index]);
        if (activity[index].user_id == this.currentUser.user_id) {
          this.iLead = true;
        }
      } else {
        if (index > 0) {
          if (activity[index].step_count == this.currentLeader[0].step_count) {
            this.currentLeader.push(activity[index]);
            if (activity[index].user_id == this.currentUser.user_id) {
              this.iLead = true;
            }
          }
        }
      }
    });

    //console.log(this.currentLeader);

    this.monFriActivity.forEach((user) => {
      if (user.user_id == this.currentUser.user_id) {
        this.myActivity = user;
      }
    });
  }

  sortActivity(items, sort, reverse) {
    let sorted = true;
    items = items.sort((a, b) => {
      if (!a[sort]) {
        // Change this values if you want to put `null` values at the end of the array
       // return -1;
      }
      if (!b[sort]) {
        // Change this values if you want to put `null` values at the end of the array
        // return +1;
      }

      let result = a[sort] - b[sort];

      if (isNaN(result)) {
        return !reverse
          ? a[sort].toString().localeCompare(b[sort])
          : b[sort].toString().localeCompare(a[sort]);
      } else {
        return !reverse ? result : -result;
      }

      /*
      if (sorted) return a[sort].localeCompare(b[sort]);
      else return b[sort].localeCompare(a[sort]);*/

      // a[sort].localeCompare(b[sort])
    });

    return items;
  }

  getPos(activities, thisActivity, metric) {
    let pos = 0;
    activities.forEach((ac, index) => {
      if (ac === thisActivity) {
        pos = index + 1;
        // is the stepcount the same?
        for (let i = index; i >= 0; i--) {
          if (activities[i][metric] === thisActivity[metric]) {
            pos = i;
          }
        }
      }
    });
    return pos + 1;
  }
}
