import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { AuthService } from './../auth/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DecisionMakerService {
  userId;
  endPoint = '/decision_makers';
  allDms: any;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userId = user.id;
      }
    });
  }

  fetchAll() {
    if (!this.userId) {
      return;
    }

    if (!this.allDms) {
      let searchParams = new HttpParams();

      searchParams = searchParams.append('user_id', this.userId);
      searchParams = searchParams.append('sort', 'created');
      searchParams = searchParams.append('order', 'DESC');
      this.allDms = this.http
        .get<any>(environment.apiUrl + this.endPoint, {
          params: searchParams,
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            return responseData._embedded.decision_makers;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.allDms;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.endPoint + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(
    area,
    importance,
    action,
    barriers,
    overcome,
    weeks,
    review_date,
    confidence
  ) {
    this.clearCache();
    const postData = {
      user_id: this.userId,
      area,
      importance,
      action,
      barriers,
      overcome,
      weeks,
      review_date: moment(review_date).format('YYYY-MM-DD'),
      confidence,
      status: 1,
    };
    return this.http
      .post<{ name: string }>(environment.apiUrl + this.endPoint, postData, {
        observe: 'response',
      })
      .pipe(
        map((responseData) => {
          return responseData.body;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  update(
    id,
    area,
    importance,
    action,
    barriers,
    overcome,
    weeks,
    review_date,
    confidence,
    status
  ) {
    this.clearCache();
    const postData = {
      area,
      importance,
      action,
      barriers,
      overcome,
      weeks,
      review_date: moment(review_date).format('YYYY-MM-DD'),
      confidence,
      status,
    };
    return this.http.patch<{ name: string }>(
      environment.apiUrl + this.endPoint + '/' + id,
      postData,
      {
        observe: 'response',
      }
    );
  }

  delete(id) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.endPoint + '/' + id
    );
  }

  clearCache() {
    this.allDms = null;
  }
}
