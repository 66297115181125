<nav class="subnav">
  <a [routerLink]="['/target']" aria-label="Go to target" class="subnav-prev"
    ><span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
  ></a>
  <h2>
    <span class="icon-wrap"><fa-icon [icon]="['far', 'stopwatch']"></fa-icon></span>
    ACTIVE MINUTES
  </h2>

  <a
    [routerLink]="['/achievements']"
    aria-label="Go to Achievements"
    class="subnav-next"
    ><span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
  ></a>
</nav>
<div *ngIf="isFetching">
  <app-loading-spinner [inline]="1"></app-loading-spinner>
</div>

<div *ngIf="currentProgramme && currentUser">
  <div class="masthead masthead-accent">
    <div class="masthead-pad-top">
      <div
        *ngIf="currentTargetMins && ((+currentTargetMins > +currentActivity.minutes && currentTargetUnit!='week') || (+currentTargetMins > +weekTotal && currentTargetUnit=='week'))"
      >
        <div class="masthead-figure masthead-figure-xb">
          <div class="masthead-icon">
            <img
              src="./assets/img/icon-stopwatch.png"
              width="77"
              class="icon-stopwatch"
            />
          </div>

          <ng-container *ngIf="currentTargetUnit !='week'">{{ currentTargetMins - currentActivity.minutes | number }}</ng-container>
          <ng-container *ngIf="currentTargetUnit =='week'">{{ currentTargetMins - weekTotal| number }}</ng-container>


        </div>
        <div class="masthead-strap">ACTIVE MINUTES REMAINING <span *ngIf="currentTargetUnit!='week'">TODAY</span> <span *ngIf="currentTargetUnit=='week'">THIS WEEK</span></div>
        
        <ng-container *ngIf="currentTargetUnit != 'week'" >
        <div class="progress">
          <div
            class="progress-bar progress-bar-accent"
            role="progressbar"
            [attr.aria-valuenow]="
              (currentActivity.minutes / currentTargetMins) * 100 | number
            "
            aria-valuemin="0"
            aria-valuemax="100"
            style="width: {{
              (currentActivity.minutes / currentTargetMins) * 100 | number
            }}%;"
          >
            <span class="sr-only"
              >{{
                (currentActivity.minutes / currentTargetMins) * 100 | number
              }}% Complete</span
            >
          </div>
        </div>
        <p class="masthead-subtext mb-0">
          <strong>{{ currentActivity.minutes | number }}</strong> of
          <strong>{{ currentTargetMins | number }}</strong> active minutes per day
           target completed
        </p>
      </ng-container>
      <ng-container *ngIf="currentTargetUnit == 'week'" >
        <div class="progress">
          <div
            class="progress-bar progress-bar-accent"
            role="progressbar"
            [attr.aria-valuenow]="
              (weekTotal / currentTargetMins) * 100 | number
            "
            aria-valuemin="0"
            aria-valuemax="100"
            style="width: {{
              (weekTotal / currentTargetMins) * 100 | number
            }}%;"
          >
            <span class="sr-only"
              >{{
                (weekTotal / currentTargetMins) * 100 | number
              }}% Complete</span
            >
          </div>
        </div>
        <p class="masthead-subtext mb-0">
          <strong>{{ weekTotal | number }}</strong> of
          <strong>{{ currentTargetMins | number }}</strong> active minutes per week
           target completed
        </p>
      </ng-container>

      </div>
      <div
        *ngIf="
          currentTargetMins && ((+currentTargetMins <= +currentActivity.minutes && currentTargetUnit!='week') || (+currentTargetMins <= +weekTotal && currentTargetUnit=='week'))
        "
      >
        <div class="masthead-figure masthead-figure-xb">
          <span class="icon-wrap hit-goal"
      ><fa-icon [icon]="['fas', 'star']"></fa-icon></span
  > 
  
  <span *ngIf="currentTargetUnit!='week'">{{ currentActivity.minutes | number
          }}</span>
          <span *ngIf="currentTargetUnit=='week'">{{ weekTotal | number
          }}</span>
          
          <span class="masthead-strap"> ACTIVE MINUTES</span>
        </div>
        <div class="masthead-strap">CONGRATULATIONS!</div>
        <p class="masthead-subtext mb-0">
          You have hit your
          <strong>{{ currentTargetMins | number }}</strong> active minutes
           target
        </p>
      </div>

      <div *ngIf="!currentTargetMins">
        <div class="masthead-strap">PLEASE SET A TARGET</div>
      </div>
    </div>

    <!--div class="row">
                <div class="col-6" [ngClass]="{'col-offset-3':currentUser.manual_steps}">
                    <h3 class="tracker-title-sm" style="font-size:23px;">EXERCISE TODAY</h3>
                    <div class="row">
                        <div class="col-12 text-center"><img src="./assets/img/icon-stopwatch.png" width="77" alt="Stopwatch" style="display:inline-block;top:-20px;position:relative;">
                           <div  style="display:inline-block;"><span class="tracker-value tracker-value-lg">{{(dailyMinutes.minutes)}}</span><br>
                            <span class="tracker-value tracker-value-sm-nf">MINUTES</span>
                           </div>
                        </div>
                    
                    </div>
                    
                    
                </div>
                <div class="col-6"  *ngIf="!currentUser.manual_steps">
                    <h3 class="tracker-title-sm" style="font-size:23px;">INTENSITY</h3>
                    <table class="widget-table" role="presentation">
                        <tr>
                            <td class="text-right"> LIGHT :</td><td>&nbsp; {{dailyMinutes.lightly_active_minutes}} min</td>
                        </tr>
                        <tr>
                            <td class="text-right">MODERATE :</td><td>&nbsp; {{dailyMinutes.fairly_active_minutes}} min</td>
                        </tr>
                        <tr>
                            <td class="text-right">VIGOROUS :</td><td>&nbsp; {{dailyMinutes.very_active_minutes}} min</td>
                        </tr>
                    </table>
                    
                </div>
            </div>
             <div class="row">
                 <div class="col-12">
                    <p class="masthead-subtext tracker-subtext">Aim for 120 minutes of exercise per day</p>
                 </div>
             </div-->
  </div>

  <div class="toggle-container" *ngIf="currentUser.manual_steps">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.update = !toggleRow.update"
    >
      <a href="" class="toggle-nav toggle-nav-expand" (click)="$event.preventDefault()"
        >
        
        <span class="icon-wrap" aria-label="Down" *ngIf="!toggleRow.update"
      ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span
  >
  <span class="icon-wrap" aria-label="Up" *ngIf="toggleRow.update"
      ><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon></span
  >
        
        </a>

      <div class="toggle-header-title">UPDATE MY ACTIVE MINUTES</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.update">
      <form
        name="minsForm"
        class="bs-form tracker-form"
        (ngSubmit)="onSubmitMinsForm()"
        #minsForm="ngForm"
      >
        <p class="instruction">
          You can update your minutes for today or any previous days using the
          form below.
        </p>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="weight">ACTIVE MINUTES</label>
              <input
                type="number"
                style="font-size: 28px"
                name="mins"
                class="form-control input-tracker input-tracker-xsm"
                placeholder=""
                id="mins"
                [ngModel]="currentMins"
                min="0"
                max="1000000000"
                autocomplete="off"
                ng-required=""
                #mins="ngModel"
              />
              <div
                class="alert alert-danger"
                *ngIf="mins.touched && mins.invalid"
              >
                This must be a number below 1000000000.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="number">DATE</label>
              <input
                    name="date"
                      type="text"
                      placeholder="DD/MM/YYYY"
                      class="form-control input-tracker input-tracker-sm"
                      bsDatepicker
                      autocomplete="off"
                      [bsConfig]="{
                        dateInputFormat: 'DD/MM/YYYY',
                        containerClass: 'theme-cv',
                        showWeekNumbers: false
                      }"
                      [(ngModel)]="date"
                      required
                      [bsValue]="date"
                      [maxDate]="date"
                      #dateInput="ngModel"
                    />
            </div>
          </div>
        </div>
        <div class="tracker-submit-container">
          <button
            type="submit"
            role="button"
            class="btn btn-primary btn-lg btn-white"
          >
            UPDATE ACTIVE MINUTES
          </button>
        </div>
      </form>
    </div>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.target = !toggleRow.target"
    >
      <a href="" class="toggle-nav toggle-nav-expand" (click)="$event.preventDefault()"
        ><span class="icon-wrap" aria-label="Down" *ngIf="!toggleRow.target"
        ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span
    >
    <span class="icon-wrap" aria-label="Up" *ngIf="toggleRow.target"
        ><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon></span
    ></a>

      <div class="toggle-header-title">SET ACTIVE MINUTES TARGET</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.target">
      <form name="targetForm" class="bs-form tracker-form">
        <p class="instruction"></p>
        <div class="row">
          <div class="col-md-4 offset-md-1">
            <div class="form-group">
              <label for="mins">MINUTES OF ACTIVITY</label>
              <input
                type="number"
                name="mins"
                class="form-control input-tracker input-tracker-sm"
                placeholder=""
                id="mins"
                [(ngModel)]="targetMins"
                #targetMinsInput="ngModel"
                min="1"
                max="1000"
                autocomplete="off"
                ng-required=""
              />
              <div
                class="alert alert-danger"
                *ngIf="targetMinsInput.touched && targetMinsInput.invalid"
              >
                This must be a number between 1 and 1000.
              </div>
            </div>
          </div>
          <div class="col-md-4 offset-md-2">
            <div class="form-group">
              <label for="unit">PERIOD</label>
              <select
                name="unit"
                class="custom-select custom-select-lg"
                placeholder=""
                id="unit"
                [(ngModel)]="targetUnit"
                #targetUnitInput="ngModel"
                min="1"
                max="1000"
                autocomplete="off"
                ng-required=""
              >
              <option [value]="'day'">Per Day</option>
              <option [value]="'week'">Per Week</option>
              </select>
              <div
                class="alert alert-danger"
                *ngIf="targetMinsInput.touched && targetMinsInput.invalid"
              >
                This must be a number between 1 and 1000.
              </div>
            </div>
          </div>
        </div>
        <div class="tracker-submit-container">
          <button
            (click)="submitTarget(targetMins)"
            class="btn btn-primary btn-lg btn-white"
            [disabled]="targetMinsInput.invalid"
          >
            UPDATE TARGET</button
          >
        </div>
      </form>
    </div>
  </div>

  <div class="toggle-container">
    <div class="toggle-header toggle-header-sub">
      <a
        href=""
        *ngIf="previousWeek"
        (click)="$event.preventDefault();previous()"
        class="toggle-nav toggle-nav-prev"
        ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
    ></a>
      <a
        href=""
        *ngIf="nextWeek.startDate < now"
        (click)="$event.preventDefault();next()"
        class="toggle-nav toggle-nav-next"
        ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
    ></a>
      <div class="toggle-header-title">WEEKLY MINUTES</div>
      <div class="toggle-header-subtitle">
        {{ currentWeek[0] | dateFormatter: "DD/MM/yyyy" }} -
        {{ currentWeek[6] | dateFormatter: "DD/MM/yyyy" }}
      </div>
    </div>
    <div class="toggle-content">
      <div class="container">
        <canvas
          class="mt-3 mb-3"
          baseChart
          [datasets]="barChartData"
          [labels]="barChartLabels"
          [options]="barChartOptions"
          [legend]="barChartLegend"
          [chartType]="barChartType"
          [colors]="barChartColors"
          height="200"
        >

        </canvas>

        <div class="step-total">
          <span class="total">{{ weekTotal | number }}</span> minutes of
          activity so far
          <div class="step-total-week">


            <ng-container *ngIf="currentTargetUnit!='week'">
              <div *ngIf="+currentTargetMins * 7 - +weekTotal > 0">
                <span
                  ><strong>{{
                    currentTargetMins * 7 - weekTotal | number
                  }}</strong></span
                >
                of <strong>{{ currentTargetMins * 7 | number }}</strong> weekly
                minutes remaining
              </div>
              <div *ngIf="+currentTargetMins * 7 - +weekTotal < 1">
                You hit your
                <strong>{{ currentTargetMins * 7 | number }}</strong> weekly
                minutes activity target
              </div>
            </ng-container>
           
            <ng-container *ngIf="currentTargetUnit=='week'">
              <div *ngIf="+currentTargetMins - +weekTotal > 0">
                <span
                  ><strong>{{
                    currentTargetMins  - weekTotal | number
                  }}</strong></span
                >
                of <strong>{{ currentTargetMins  | number }}</strong> weekly
                minutes remaining
              </div>
              <div *ngIf="+currentTargetMins  - +weekTotal < 1">
                You hit your
                <strong>{{ currentTargetMins  | number }}</strong> weekly
                minutes activity target
              </div>
            </ng-container>




            <button
              class="btn btn-sm btn-primary"
              (click)="syncSteps(1)"
              style="margin: 10px 0 0 0; font-size: 12px"
            >
            <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'sync']"></fa-icon></span
        > SYNC MINUTES
            </button>
          </div>
        </div>

        <table class="table">
          <thead>
            <tr>
              <th></th>
              <th style="text-align: left">DAY</th>
              <th style="text-align: right">MINUTES</th>
              <th style="text-align: right">% OF TARGET</th>
            </tr>
          </thead>
          <tbody *ngIf="weekData.length > 0">
            <tr
              *ngFor="let dayData of currentWeek; let index = index"
              [ngClass]="{ current: weekData[index].activity_date == now }"
            >
              <td>

                <span class="icon-wrap" *ngIf="weekData[index].minutes * 1 < currentTargetMins * 1"
      ><fa-icon [icon]="['far', 'star']"></fa-icon></span
  >

  <span class="icon-wrap hit-goal" *ngIf="
  weekData[index].minutes * 1 >= currentTargetMins * 1 &&
  weekData[index].minutes > 0
"
      ><fa-icon [icon]="['fas', 'star']"></fa-icon></span
  >

                
              </td>
              <td>{{ dayData | dateFormatter: "dddd" }}</td>
              <td class="text-right">
                {{ weekData[index].minutes | number }}
              </td>
              <td class="text-right">
                <span *ngIf="currentTargetMins > 0"
                  >{{
                    (weekData[index].minutes / currentTargetMins) * 100
                      | number:'1.0-0'
                  }}<span
                    *ngIf="(weekData[index].minutes / currentTargetMins) * 100"
                    >%</span
                  ></span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.graph = !toggleRow.graph"
    >
      <a href="" class="toggle-nav toggle-nav-expand" (click)="$event.preventDefault()"
        ><span class="icon-wrap" aria-label="Down" *ngIf="!toggleRow.graph"
        ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span
    >
    <span class="icon-wrap" aria-label="Up" *ngIf="toggleRow.graph"
        ><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon></span
    ></a>

      <div class="toggle-header-title">MINUTES GRAPH</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.graph">
      <!-- Nav tabs -->
      <ul class="nav nav-tabs nav-tabs-duo justify-content-center" role="tablist">
        <li role="presentation" [ngClass]="{ active: showMonth }">
          <a
            href=""
            role="tab"
            aria-controls="showMonth"
            data-toggle="tab"
            (click)="$event.preventDefault(); showMonth = true"
            >PAST 30 DAYS</a
          >
        </li>
        <li role="presentation" [ngClass]="{ active: !showMonth }">
          <a
            href=""
            role="tab"
            aria-controls="allTime"
            data-toggle="tab"
            (click)="$event.preventDefault(); showMonth = false"
            >ALL TIME</a
          >
        </li>
      </ul>

      <!-- Tab panes -->
      <div class="tab-content">
        <div
          role="tabpanel"
          
          id="showMonth"
          *ngIf="showMonth"
        >
          <!--canvas
            id="line2"
            class="chart chart-line tracker-chart"
            chart-data="monthData"
            chart-labels="monthLabels"
            chart-series="series"
            chart-options="monthOptions"
            chart-dataset-override="monthDatasetOverride"
            height="200"
            aria-label="Bar Chart Values {{ monthData }}"
          ></canvas-->
          <canvas
            class="mt-3 mb-3 tracker-chart"
            baseChart
            [datasets]="lineChartData"
            [labels]="lineChartLabels"
            [options]="lineChartOptions"
            [legend]="lineChartLegend"
            [chartType]="lineChartType"
            [colors]="lineChartColors"
          >
          </canvas>
        </div>
        <div
          role="tabpanel"
          
          id="allTime"
          *ngIf="!showMonth"
        >
          <!--canvas
            id="line3"
            class="chart chart-line tracker-chart"
            chart-data="allTimeData"
            chart-labels="allTimeLabels"
            chart-series="series"
            chart-options="allTimeOptions"
            chart-dataset-override="allTimeDatasetOverride"
            height="200"
            aria-label="Bar Chart Values {{ allTimeData }}"
          ></canvas-->
          <canvas
            class="mt-3 mb-3 tracker-chart"
            baseChart
            [datasets]="allTimeChartData"
            [labels]="allTimeChartLabels"
            [options]="allTimeChartOptions"
            [legend]="allTimeChartLegend"
            [chartType]="allTimeChartType"
            [colors]="allTimeChartColors"
          >
          </canvas>
        </div>
      </div>
    </div>
  </div>
<app-section-nav [section]="'activity'" [active]="'minutes'"></app-section-nav>
</div>
