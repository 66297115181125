<nav class="subnav" *ngIf="currentUser">
  <a [routerLink]="['/target']" aria-label="Go to target" class="subnav-prev"
    ><span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
  ></a>
  <h2>
    <span class="icon-wrap"><fa-icon [icon]="['far', 'couch']"></fa-icon></span>

    <span *ngIf="!(currentUser.mobility1 == 'e' || currentUser.mobility1 == 'f')">MY SITTING</span><span *ngIf="currentUser.mobility1 == 'e' || currentUser.mobility1 == 'f'">MY SEDENTARY BEHAVIOUR</span>
  </h2>

  <a
    [routerLink]="['/achievements']"
    aria-label="Go to Achievements"
    class="subnav-next"
    ><span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
  ></a>
</nav>
<div *ngIf="isFetching">
  <app-loading-spinner [inline]="1"></app-loading-spinner>
</div>
<div *ngIf="currentProgramme && currentUser">
  <div
    class="masthead masthead-accent masthead-strength masthead-pad-top"
    *ngIf="currentWeek && weekData"
  >
    <div *ngIf="currentWeek && currentTarget && !reviewTarget">
      <div class="headline">
        <div class="headline-icon">
          <img
            src="./assets/img/icon-sitting.svg"
            alt="Icon showing character sitting in a chair"
          />
        </div>

        <div class="headline-text">
          <div class="headline-target">
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon
            ></span>
            Under {{ +currentTarget | minsToHours }} a day
          </div>
          <strong>{{ daysHit }}</strong>
          <span class="headline-subtext"
            >day<span *ngIf="daysHit != 1">s</span> achived<br />
            this week</span
          >
        </div>
      </div>
      <div class="clear-headline"></div>

      <div class="days">
        <div *ngFor="let day of currentWeek; let index = index" class="day">
          <div *ngIf="weekData[index]">
            <span
              class="icon-wrap fa fa-check-circle"
              *ngIf="
                +weekData[index].sedentary_minutes < +currentTarget &&
                weekData[index].sedentary_minutes > 0
              "
              ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
            ></span>
            <span
              class="icon-wrap fa fa-circle"
              [ngClass]="{ 'not-hit': 1 == 1 }"
              *ngIf="
                +weekData[index].sedentary_minutes >= +currentTarget ||
                !weekData[index].sedentary_minutes
              "
              ><fa-icon [icon]="['fas', 'circle']"></fa-icon
            ></span>
          </div>

          <br />
          <span>{{ day | dateFormatter: "dd" | slice: 0:1 }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="(!suggestedTarget && !currentTarget)">
      <div class="masthead-strap">TARGET SETUP</div>

      <div class="headline-flex">
        <div class="headline-icon">
          <span class="icon-wrap"
            ><fa-icon
              [icon]="['far', 'hourglass-half']"
            ></fa-icon
          ></span>
        </div>
        <div class="headline-text">
          <strong>{{ algorithmDaysRemaining }}</strong>
          <span class="headline-subtext"
            >day<span *ngIf="algorithmDaysRemaining != 1"
              >s</span
            ><br />
            Remaining</span
          >
        </div>
      </div>

      <div class="days days-activity">
        <div
          *ngFor="let day of algorithmDays"
          class="day"
        >
          <span
            class="icon-wrap fa fa-check-circle"
            *ngIf="day.status"
            ><fa-icon
              [icon]="['fas', 'check-circle']"
            ></fa-icon
          ></span>
          <span
            class="icon-wrap fa fa-circle"
            *ngIf="!day.status"
            [ngClass]="{ 'not-hit': day.status === 0 }"
            ><fa-icon
              [icon]="['fas', 'circle']"
            ></fa-icon></span
          ><br />
          {{ day.day | dateFormatter: "ddd" | uppercase }}
        </div>

        <p *ngIf="currentUser && currentUser.device !='fitbit'">Please enter your sitting time in the form below.</p>
        <p *ngIf="stepTarget && algorithmDaysRemaining>0"> <a
          role="button"
          (click)="generateTarget()"
          class="btn btn-primary"
        >
          <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'bullseye']"></fa-icon
          ></span>
          GENERATE TARGET</a
        ></p>
      </div>
     

      <div *ngIf="algorithmDaysRemaining < 1" class="mb-4">
        <p>Your personalised sitting goal is now ready</p>
        <a
          role="button"
          (click)="generateTarget()"
          class="btn btn-primary"
        >
          <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'bullseye']"></fa-icon
          ></span>
          GENERATE TARGET</a
        >
      </div>


    </div>

    <div *ngIf="suggestedTarget  || reviewTarget ">
      <div class="masthead-strap mb-4">YOUR PERSONALISED <span *ngIf="!(currentUser.mobility1 == 'e' || currentUser.mobility1 == 'f')">SITTING</span><span *ngIf="currentUser.mobility1 == 'e' || currentUser.mobility1 == 'f'">SEDENTARY BEHAVIOUR</span> GOAL</div>

      <div>
        <p class="masthead-subtext">
          <span *ngIf="reviewTarget"> Congratulations you have consistently reduced your sitting time over the past two weeks your new
          target is </span>
          <span *ngIf="!reviewTarget">
            Your 
          target is
          </span>
          
         
            {{
              +suggestedTarget
                | minsToHours: "hoursOnly"
            }}
             hours 
          <span *ngIf="+(+suggestedTarget | minsToHours: 'minutesOnly') > 0">
            {{
              +suggestedTarget
                | minsToHours: "minutesOnly"
            }}
            minutes </span></p>
      <form name="targetForm mt-2" class="bs-form tracker-form">
        
        <div
                      class="masthead-figure masthead-figure-xb masthead-sitting-stats"
                    >
                      <div class="masthead-icon">
                        <img
                          src="assets/img/icon-sitting.svg"
                          height="64"
                          class="icon-shoe"
                        />
                      </div>
                      <div class="masthead-sitting-figure">
                        <div>
                          {{
                            +suggestedTarget
                              | minsToHours: "hoursOnly"
                          }}
                          <div>HOURS</div>
                        </div>
                        <div>
                          {{
                            +suggestedTarget
                              | minsToHours: "minutesOnly"
                          }}
                          <div>MINS</div>
                        </div>
                      </div>
                    </div>
              <input
                type="hidden"
                name="targetHours"
                class="form-control input-tracker input-tracker-sm"
                placeholder=""
                id="targetHoursMasthead"
                [(ngModel)]="suggestedTarget"
                #targetHoursInput="ngModel"
                min="1"
                max="1000"
                autocomplete="off"
                ng-required=""
              />
        <div>
          <button
            (click)="submitTarget(+suggestedTarget)"
            class="btn btn-primary btn-lg btn-white"
            [disabled]="targetHoursInput.invalid"
          >
            CONFIRM TARGET
          </button>
        </div>
      </form>
    </div>
    </div>
    <div class="row">
      <div class="col-12">
        <!--p class="masthead-subtext tracker-subtext">
              Last updated on
             
            </p-->
      </div>
    </div>
  </div>

  <div class="toggle-container" *ngIf="currentUser.device !='fitbit'">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.update = !toggleRow.update"
    >
      <a
        href=""
        class="toggle-nav toggle-nav-expand"
        (click)="$event.preventDefault()"
      >
        <span class="icon-wrap" aria-label="Down" *ngIf="!toggleRow.update"
          ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
        ></span>
        <span class="icon-wrap" aria-label="Up" *ngIf="toggleRow.update"
          ><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon
        ></span>
      </a>

      <div class="toggle-header-title">UPDATE MY <span *ngIf="!(currentUser.mobility1 == 'e' || currentUser.mobility1 == 'f')">SITTING</span><span *ngIf="currentUser.mobility1 == 'e' || currentUser.mobility1 == 'f'">SEDENTARY BEHAVIOUR</span></div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.update">
      <form
        name="minsForm"
        class="bs-form tracker-form"
        (ngSubmit)="onSubmitForm()"
        #minsForm="ngForm"
      >
        <p class="instruction">
          You can update your hours and minutes for today or any previous days
          using the form below.
        </p>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="weight">HOURS</label>
              <input
                type="number"
                style="font-size: 28px"
                name="hours"
                class="form-control input-tracker input-tracker-xsm"
                placeholder=""
                id="hours"
                [ngModel]="currentHours"
                min="0"
                max="1000000000"
                autocomplete="off"
                ng-required=""
                #hours="ngModel"
              />
              <div
                class="alert alert-danger"
                *ngIf="hours.touched && hours.invalid"
              >
                This must be a number below 1000000000.
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="weight">MINUTES</label>
              <input
                type="number"
                style="font-size: 28px"
                name="mins"
                class="form-control input-tracker input-tracker-xsm"
                placeholder=""
                id="mins"
                [ngModel]="currentMins"
                min="0"
                max="1000000000"
                autocomplete="off"
                ng-required=""
                #mins="ngModel"
              />
              <div
                class="alert alert-danger"
                *ngIf="mins.touched && mins.invalid"
              >
                This must be a number below 1000000000.
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="number">DATE</label>
              <input
                name="date"
                type="text"
                placeholder="DD/MM/YYYY"
                class="form-control input-tracker input-tracker-sm"
                bsDatepicker
                autocomplete="off"
                [bsConfig]="{
                  dateInputFormat: 'DD/MM/YYYY',
                  containerClass: 'theme-cv',
                  showWeekNumbers: false
                }"
                [(ngModel)]="date"
                required
                [bsValue]="date"
                [maxDate]="date"
                #dateInput="ngModel"
              />
            </div>
          </div>
        </div>
        <div class="tracker-submit-container">
          <button
            type="submit"
            role="button"
            class="btn btn-primary btn-lg btn-white"
          >
            UPDATE
          </button>
        </div>
      </form>
    </div>
  </div>

  <!--div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.target = !toggleRow.target"
    >
      <a
        href=""
        class="toggle-nav toggle-nav-expand"
        (click)="$event.preventDefault()"
        ><span class="icon-wrap" aria-label="Down" *ngIf="!toggleRow.target"
          ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
        ></span>
        <span class="icon-wrap" aria-label="Up" *ngIf="toggleRow.target"
          ><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon></span
      ></a>

      <div class="toggle-header-title">SET DAILY SITTING TARGET</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.target">
      <form name="targetForm" class="bs-form tracker-form">
        <p class="instruction">
          Set a target for the number of hours of daily sitting you don't want
          to exceed.
        </p>
        <div class="row">
          <div class="col-md-4 offset-md-4">
            <div class="form-group">
              <label for="weight">HOURS</label>
              <input
                type="number"
                name="targetHours"
                class="form-control input-tracker input-tracker-sm"
                placeholder=""
                id="targetHours"
                [(ngModel)]="targetHours"
                #targetHoursInput="ngModel"
                min="1"
                max="1000"
                autocomplete="off"
                ng-required=""
              />
              <div
                class="alert alert-danger"
                *ngIf="targetHoursInput.touched && targetHoursInput.invalid"
              >
                This must be a number between 1 and 1000.
              </div>
            </div>
          </div>
        </div>
        <div class="tracker-submit-container">
          <button
            (click)="submitTarget(targetHours)"
            class="btn btn-primary btn-lg btn-white"
            [disabled]="targetHoursInput.invalid"
          >
            UPDATE TARGET
          </button>
        </div>
      </form>
    </div>
  </div-->

  <div class="toggle-container">
    <div class="toggle-header toggle-header-sub">
      <a
        href=""
        *ngIf="previousWeek"
        (click)="$event.preventDefault(); previous()"
        class="toggle-nav toggle-nav-prev"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
      ></a>
      <a
        href=""
        *ngIf="nextWeek.startDate < now"
        (click)="$event.preventDefault(); next()"
        class="toggle-nav toggle-nav-next"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
      ></a>
      <div class="toggle-header-title">WEEKLY <span *ngIf="!(currentUser.mobility1 == 'e' || currentUser.mobility1 == 'f')">SITTING</span><span *ngIf="currentUser.mobility1 == 'e' || currentUser.mobility1 == 'f'">SEDENTARY BEHAVIOUR</span> TIME</div>
      <div class="toggle-header-subtitle">
        {{ currentWeek[0] | dateFormatter: "DD/MM/yyyy" }} -
        {{ currentWeek[6] | dateFormatter: "DD/MM/yyyy" }}
      </div>
    </div>
    <div class="toggle-content">
      <div class="container">
        <table class="table">
          <thead>
            <tr>
              <th width="20"></th>
              <th style="text-align: left">DAY</th>
              <th style="text-align: right">DURATION</th>
            </tr>
          </thead>
          <tbody *ngIf="weekData.length > 0">
            <tr
              *ngFor="let dayData of currentWeek; let index = index"
              [ngClass]="{ current: weekData[index].activity_date == now }"
            >
              <td>
                <span
                  class="icon-wrap"
                  *ngIf="
                    +weekData[index].sedentary_minutes >= +currentTarget
                  "
                  ><fa-icon [icon]="['far', 'star']"></fa-icon
                ></span>

                <span
                  class="icon-wrap hit-goal"
                  *ngIf="
                    +weekData[index].sedentary_minutes  < +currentTarget &&
                    +weekData[index].sedentary_minutes > 0
                  "
                  ><fa-icon [icon]="['fas', 'star']"></fa-icon
                ></span>
              </td>
              <td>{{ dayData | dateFormatter: "dddd" }}</td>
              <td class="text-right">
                <span *ngIf="weekData[index].sedentary_minutes">{{ weekData[index].sedentary_minutes | minsToHours }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.graph = !toggleRow.graph"
    >
      <a
        href=""
        class="toggle-nav toggle-nav-expand"
        (click)="$event.preventDefault()"
        ><span class="icon-wrap" aria-label="Down" *ngIf="!toggleRow.graph"
          ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
        ></span>
        <span class="icon-wrap" aria-label="Up" *ngIf="toggleRow.graph"
          ><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon></span
      ></a>

      <div class="toggle-header-title"><span *ngIf="!(currentUser.mobility1 == 'e' || currentUser.mobility1 == 'f')">SITTING</span><span *ngIf="currentUser.mobility1 == 'e' || currentUser.mobility1 == 'f'">SEDENTARY BEHAVIOUR</span> GRAPH</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.graph">
      <!-- Nav tabs -->
      <ul
        class="nav nav-tabs nav-tabs-duo justify-content-center"
        role="tablist"
      >
        <li role="presentation" [ngClass]="{ active: showMonth }">
          <a
            href=""
            role="tab"
            aria-controls="showMonth"
            data-toggle="tab"
            (click)="$event.preventDefault(); showMonth = true"
            >PAST 30 DAYS</a
          >
        </li>
        <li role="presentation" [ngClass]="{ active: !showMonth }">
          <a
            href=""
            role="tab"
            aria-controls="allTime"
            data-toggle="tab"
            (click)="$event.preventDefault(); showMonth = false"
            >ALL TIME</a
          >
        </li>
      </ul>

      <!-- Tab panes -->
      <div class="tab-content">
        <div role="tabpanel" id="showMonth" *ngIf="showMonth">
          <!--canvas
              id="line2"
              class="chart chart-line tracker-chart"
              chart-data="monthData"
              chart-labels="monthLabels"
              chart-series="series"
              chart-options="monthOptions"
              chart-dataset-override="monthDatasetOverride"
              height="200"
              aria-label="Bar Chart Values {{ monthData }}"
            ></canvas-->
          <canvas
            class="mt-3 mb-3 tracker-chart"
            baseChart
            [datasets]="lineChartData"
            [labels]="lineChartLabels"
            [options]="lineChartOptions"
            [legend]="lineChartLegend"
            [chartType]="lineChartType"
            [colors]="lineChartColors"
          >
          </canvas>
        </div>
        <div role="tabpanel" id="allTime" *ngIf="!showMonth">
          <!--canvas
              id="line3"
              class="chart chart-line tracker-chart"
              chart-data="allTimeData"
              chart-labels="allTimeLabels"
              chart-series="series"
              chart-options="allTimeOptions"
              chart-dataset-override="allTimeDatasetOverride"
              height="200"
              aria-label="Bar Chart Values {{ allTimeData }}"
            ></canvas-->
          <canvas
            class="mt-3 mb-3 tracker-chart"
            baseChart
            [datasets]="allTimeChartData"
            [labels]="allTimeChartLabels"
            [options]="allTimeChartOptions"
            [legend]="allTimeChartLegend"
            [chartType]="allTimeChartType"
            [colors]="allTimeChartColors"
          >
          </canvas>
        </div>
      </div>
    </div>
  </div>
  <app-section-nav
    [section]="'activity'"
    [active]="'minutes'"
  ></app-section-nav>
</div>
