import { NgForm } from '@angular/forms';
import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { WorkoutsService } from 'src/app/services/workouts.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2/src/sweetalert2.js';

@Component({
  selector: 'app-strength-add',
  templateUrl: './strength-add.component.html',
  styleUrls: ['./strength-add.component.scss'],
})
export class StrengthAddComponent implements OnInit {
  currentUser;
  currentProgramme;
  workoutDate = new Date();
  isFetching = false;

  @ViewChild('trackerForm', { static: false }) trackerForm: NgForm;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private workoutsService: WorkoutsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isFetching = true;

    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (this.currentProgramme) {
          this.isFetching = false;
        }
      });

      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });
    }
  }

  onSubmit() {
    // this.trackerForm.value.date

    if (!this.trackerForm.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.workoutsService
        .create(
          this.trackerForm.value.minutes,
          this.trackerForm.value.effort,
          moment(this.workoutDate).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
            // set chart data
            let msg = '';
            if (this.trackerForm.value.effort < 4) {
              msg =
                'You have entered a low effort rating for this workout. To get the most benefits, your effort level should be between 6-8. Try increasing the resistance/weight by changing the band colour, try to do some more repetitions, or reduce the rest time to make this workout harder. Remember to try different exercises if you find this one easy';
            }
            if (this.trackerForm.value.effort > 8) {
              msg =
                'You have entered a high effort rating for this workout. To get the most benefits, your effort level should be between 6-8. Try reducing the resistance/weight by changing the band colour, try to do some less repetitions, or increase the rest time to make this workout easier. Remember to try different exercises if you find this one hard';
            }
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Exercise added!',
              text: 'Your entry was successfully added. ' + msg,
              showConfirmButton: true,
            });
            this.trackerForm.resetForm();
            this.router.navigateByUrl(
              '/activity/strength/view/' +
                responseData['user_strength_workout_id']
            );
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }
}
