import { PageViewService } from './../../services/page-view.service';
import { forkJoin } from 'rxjs';
import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { LearningMaterialService } from './../../services/learning-material.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-help-page',
  templateUrl: './help-page.component.html',
  styleUrls: ['./help-page.component.scss']
})
export class HelpPageComponent implements OnInit, OnDestroy {
  currentUser;
  currentProgramme;
  prevSection;
  nextSection;
  sectionId;
  pageId;
  isFetching;
  section;
  programmeContentSection;
  pages;
  page;
  prevPage;
  nextPage;
  media;
  subnav;
  start;
  firstView = true;

  constructor(
    private learningMaterialService: LearningMaterialService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private router: Router,
    private pageViewService: PageViewService,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });
    }

    //
    this.route.params.subscribe((params) => {
      if (this.firstView) {
        this.start = moment();
        this.firstView = false;
      } else {
        this.savePageView();
        this.start = moment();
      }
      this.prevSection = null;
      this.nextSection = null;
      this.nextPage = null;
      this.prevPage = null;
      this.page = null;
      this.pageId = this.route.snapshot.params.pageId;
      this.isFetching = true;
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (this.currentProgramme) {
          // get the page

          this.learningMaterialService
            .fetchPage(this.pageId)
            .subscribe((response) => {
              this.page = response;
              this.sectionId = this.page.content_section_id;

              forkJoin({
                section: this.learningMaterialService.fetch(this.sectionId),
                pages: this.learningMaterialService.fetchPages(this.sectionId),
                programmeContent: this.programmeService.getProgrammeContent(
                  this.currentProgramme.programme_id
                ),
              }).subscribe((responseData) => {
                this.isFetching = false;
                this.section = responseData.section;

                // can they access?
                if (
                  this.section.status === 'unavailable' &&
                  this.currentUser.is_admin == 0
                ) {
                  this.router.navigate(['/dashboard']);
                }

                this.pages = responseData.pages;

                this.pages.forEach((_page, index)=>{
                  if(_page.content_page_id === this.page.content_page_id){
                    if (this.pages[index - 1]) {
                      this.prevPage = this.pages[index - 1];
                    }
                    if (this.pages[index + 1]) {
                      this.nextPage = this.pages[index + 1];
                    }
                  }
                });

                // do prev and next
                const sections = responseData.programmeContent.help_sections;
                sections.forEach((section, index) => {
                  if (section.content_section_id === this.sectionId) {
                    this.programmeContentSection = section;
                    // setHtmlTitle($scope.programmeBoosterSession.booster_session_title );

                    if (sections[index - 1]) {
                      this.prevSection = sections[index - 1];
                    }
                    if (sections[index + 1]) {
                      this.nextSection = sections[index + 1];
                    }
                  }
                });

                this.titleService.setTitle(
                  environment.title +
                    ' | Help | ' +
                    this.section.title + ' | ' + this.page.title
                );

              });
            });
        }
      });
    });
  }

  showSubnav(){
    this.subnav = !this.subnav;
  }

  ngOnDestroy() {
    this.savePageView();
  }

  savePageView() {
    const end = moment();
    const timeSpent = Math.round((+end - this.start) / 1000);
    // console.log(timeSpent);

    // post
    this.pageViewService
      .createContentSectionView(
        timeSpent,
        this.page.content_section_id,
        this.page.content_page_id,
        1,
        1
      )
      .subscribe(
        (responseData) => {
          // console.log(responseData);
        },
        (error) => {
          // console.log(error);
        }
      );
  }
}
