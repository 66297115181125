import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { BloodGlucoseService } from 'src/app/services/blood-glucose.service';
import { ProgrammeService } from 'src/app/services/programme.service';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-m3-glucose',
  templateUrl: './m3-glucose.component.html',
  styleUrls: ['./m3-glucose.component.scss'],
})
export class M3GlucoseComponent implements OnInit {
  currentUser;
  currentProgramme;
  date = new Date();
  allSittings;
  currentHours = 0;
  currentMins = 0;
  nextAppointment;
  sittingTarget = 6;
  latestSittings: any = [];
  sittingAverage: any;
  showAllEntries = false;

  hba1cUnit = '%';
  unitMode = 'percent';
  hba1cChanges:any;
  hba1cs;

  glucoseAverages:any = [];
  glucoseAverageChanges:any = [];
  glucoseInRanges:any = [];
  glucoseBelowRanges:any = [];


  entryDate = new Date();
  maxDate = new Date();
  entryDateLevel = new Date();
  entryDateInRange = new Date();
  entryDateBelowRange = new Date();

  @ViewChild('entryForm', { static: false }) entryForm: NgForm;
  @ViewChild('entryFormAverage', { static: false }) entryFormAverage: NgForm;
  @ViewChild('entryFormInRange', { static: false }) entryFormInRange: NgForm;
  @ViewChild('entryFormBelowRange', { static: false }) entryFormBelowRange: NgForm;

  // end chart
  @ViewChild('addModal') public addModal: ModalDirective;

  constructor(
    private authService: AuthService,
    private programmeService: ProgrammeService,
    private router: Router,
    private userService: UserService,
    private userSettingsService: UserSettingsService,
    private modalService: BsModalService,
    public bloodGlucoseService:BloodGlucoseService
  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {});

      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
        if (this.currentUser.device) {
          if (this.currentUser.device != 'fitbit') {
            this.sittingTarget = 9;
          }
        }
      });

      forkJoin({
        userSettings: this.userSettingsService.fetchAll(),
        hba1cs: this.bloodGlucoseService.fetchAll(),
        glucoseAverages: this.bloodGlucoseService.fetchGlucoseAverages(),
        glucoseBelowRanges: this.bloodGlucoseService.fetchGlucoseTimeBelowRanges(),
        glucoseInRanges: this.bloodGlucoseService.fetchGlucoseTimeInRanges(),
      }).subscribe((responseData) => {

        this.hba1cs = responseData.hba1cs;
        this.hba1cChanges = [];
        if(this.hba1cUnit == '%'){
          this.hba1cs.forEach((hba1c, index)=>{

            if(this.hba1cs[index+1]){
              this.hba1cChanges.push(
                {change: Math.round((this.convertHba1cToPercent(hba1c.glucose) - this.convertHba1cToPercent(this.hba1cs[index+1].glucose))*100)/100, created: hba1c.created}
              );
            }
            hba1c.glucose = this.convertHba1cToPercent(hba1c.glucose);
          });
        }
        this.glucoseAverages = responseData.glucoseAverages;
        this.glucoseAverageChanges = [];
        this.glucoseAverages.forEach((glucoseAverage, index)=>{
          if(this.glucoseAverages[index+1]){
            this.glucoseAverageChanges.push(
              {change: (Math.round(glucoseAverage.user_glucose_average - this.glucoseAverages[index+1].user_glucose_average)*100)/100, date_recorded: glucoseAverage.date_recorded}
            );
          }
        });


        this.glucoseBelowRanges = responseData.glucoseBelowRanges;
        this.glucoseInRanges = responseData.glucoseInRanges;



      });
    }
  }

  onSubmitEntryForm() {
    // this.entryForm.value.date

    if (!this.entryForm.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.bloodGlucoseService
        .create(
          this.entryForm.value.glucose,
          this.entryForm.value.glucosePercent,
          moment(this.entryDate).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
           
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/glucose']));
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }


  onSubmitEntryFormAverage() {
    // this.entryForm.value.date

    if (!this.entryFormAverage.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.bloodGlucoseService
        .createAverage(
          this.entryFormAverage.value.glucoseAverage,
          moment(this.entryDateLevel).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
           
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/glucose']));
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }



  onSubmitEntryFormInRange() {
    // this.entryForm.value.date

    if (!this.entryFormInRange.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.bloodGlucoseService
        .createTimeInRange(
          this.entryFormInRange.value.glucoseInRange,
          moment(this.entryDateInRange).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
           
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/glucose']));
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }


  onSubmitEntryFormBelowRange() {
    // this.entryForm.value.date

    if (!this.entryFormBelowRange.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something",
        text: 'Please check the form for belowformation that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.bloodGlucoseService
        .createTimeBelowRange(
          this.entryFormBelowRange.value.glucoseBelowRange,
          moment(this.entryDateBelowRange).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
           
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/glucose']));
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  onDeleteHba1c(hba1c) {
    
    this.bloodGlucoseService
    .delete(hba1c.user_blood_glucose_id)
      .subscribe((responseData) => {
        //this.activityService.clearCache();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/glucose']));
      });
  }

  onDeleteAverage(average) {
    
    this.bloodGlucoseService
    .deleteAverage(average.user_glucose_average_id)
      .subscribe((responseData) => {
        //this.activityService.clearCache();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/glucose']));
      });
  }

  onDeleteInRange(inRange) {
    
    this.bloodGlucoseService
    .deleteTimeInRange(inRange.user_glucose_time_in_range_id)
      .subscribe((responseData) => {
        //this.activityService.clearCache();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/glucose']));
      });
  }
  onDeleteBelowRange(belowRange) {
    
    this.bloodGlucoseService
    .deleteTimeBelowRange(belowRange.user_glucose_time_below_range_id)
      .subscribe((responseData) => {
        //this.activityService.clearCache();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/glucose']));
      });
  }

  getHba1cRag(hba1c:any){
    return this.bloodGlucoseService.getRag(hba1c);
  }

  getHba1cChangeRag(change:any){
    return this.bloodGlucoseService.getChangeRag(change);
  }
  getGlucoseAverageRag(value:any){


  }


  convertHba1cToPercent(value:number) {
    // convert from percent..
    return(
      Math.round(
        (0.0915 * value + 2.15) * 100
      ) / 100
    );
  }


  updateVal() {
    // convert from percent..
    this.entryForm.controls['glucose'].setValue(
      Math.round(
        (+this.entryForm.controls['glucosePercent'].value - 2.15) *
          10.929 *
          10
      ) / 10
    );
    if (+this.entryForm.controls['glucose'].value > 140) {
      this.entryForm.controls['glucose'].setValue(140);
    }
  }

  updateValP() {
    // convert from percent..
    this.entryForm.controls['glucosePercent'].setValue(
      Math.round(
        (0.0915 * this.entryForm.controls['glucose'].value + 2.15) * 100
      ) / 100
    );
  }
}
