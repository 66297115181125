import { NgForm } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { UserShapeService } from './../../services/user-shape.service';
import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { environment } from './../../../environments/environment';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-shape',
  templateUrl: './shape.component.html',
  styleUrls: ['./shape.component.scss'],
})
export class ShapeComponent implements OnInit {
  currentUser;
  currentProgramme;
  userShapes = [];
  userShape;
  isFetching = false;
  errors = [];
  showAllRows = false;
  environment = environment;
  currentWaist = null;
  currentWaistCm = null;
  currentWeight;

  toggleRow = {
    update: false,
    graph: false,
    entry: false,
  };

  toggleChart = {
    weight: true,
    waist: false,
  };
  data = [];
  data2 = [];
  data3 = [];
  entryDate = new Date();
  maxDate = new Date();

  @ViewChild('trackerForm', { static: false }) trackerForm: NgForm;

  unitMode = 'metric';
  unitModeWaist = 'imperial';
  unitModeWeight = 'metric';
  bmi;
  // chart1

  // Line chart
  public chartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            beginAtZero: false,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          gridLines: {
            display: true,
            lineWidth: 1,
            color: '#95989A',
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            lineWidth: 2,
            color: '#95989A',
          },
          type: 'time',
          ticks: {
            autoSkip: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
          time: {
            parser: 'YYYY-MM-DD HH:mm:ss',
            unit: 'day',
            displayFormats: {
              day: 'MMM-DD',
            },
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public chartLabels: Label[] = [];
  public chartType: ChartType = 'line';
  public chartLegend = true;

  public chartColors: Color[] = [{ backgroundColor: environment.primaryColor }];

  // LDL
  public weightChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'Weight (Kg)',
      borderWidth: 1,
      borderColor: environment.chartDefaults.colors[0],
      fill: false,
      pointRadius: 2,
    },
  ];
  // HDL
  public waistChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'Waist (inches)',
      borderWidth: 1,
      borderColor: environment.chartDefaults.colors[0],
      fill: false,
      pointRadius: 2,
    },
  ];
  //
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private userShapeService: UserShapeService
  ) {}

  ngOnInit(): void {
    if (this.environment.country === 'AUS') {
      this.unitModeWaist = 'metric';
    }

    this.isFetching = true;
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (this.currentProgramme) {
          // this.barChartColors[0].backgroundColor = this.currentProgramme.config.primaryColor;

          forkJoin({
            userShapes: this.userShapeService.fetchAll(),
          }).subscribe(
            (responseData) => {
              this.isFetching = false;
              this.userShapes = responseData.userShapes;
              if (this.userShapes.length > 0) {
                this.userShape = this.userShapes[0];
                this.currentWeight = this.userShape.weight;
                this.currentWaist = this.userShape.waist;
                this.currentWaistCm = this.userShape.waist * 2.54;
              }
              this.bmi = this.calcBmi(this.userShape.weight);
              this.setChartData(this.userShapes);
              // end
            },
            (error) => {
              this.isFetching = false;
              this.errors.push(error.message);
            }
          );
        }
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });
    }
  }

  onRemove(userShape) {
    this.userShapeService.delete(userShape.user_shape_id).subscribe(
      (responseData) => {
        const index = this.userShapes.indexOf(userShape);
        this.userShapes.splice(index, 1);
        this.setChartData(this.userShapes);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onSubmit() {
    // this.trackerForm.value.date

    if (!(this.trackerForm.value.weight || this.trackerForm.value.waist)) {
     
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      let weightInput = null;
      let waistInput = null;
      if (this.trackerForm.value.weight) {
        weightInput = this.trackerForm.value.weight;
      }
      if (this.trackerForm.value.waist) {
        waistInput = this.trackerForm.value.waist;
      }

      this.userShapeService
        .create(
          weightInput,
          waistInput,
          moment(this.entryDate).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
            this.userShapes.unshift(responseData);
            this.userShapes.sort(this.sortByCreated);
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.trackerForm.resetForm();
            this.entryDate = new Date();
            this.setChartData(this.userShapes);

            this.bmi = this.calcBmi(this.userShapes[0].weight);
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  // end chart
  updatedToday() {
    let hasUpdated = false;
    this.userShapes.forEach((userShape) => {
      if (
        moment(userShape.created).format('MM DD YY') ===
        moment().format('MM DD YY')
      ) {
        hasUpdated = true;
      }
    });
    return hasUpdated;
  }

  setChartData(trackerData) {
    this.weightChartData[0].data = [];
    this.waistChartData[0].data = [];
    this.chartLabels = [];
    // setup chart data
    trackerData.forEach((value, key) => {
      this.chartLabels.push(moment(value.created).format('YYYY-MM-DD'));
      this.weightChartData[0].data.push(value.weight);
      this.waistChartData[0].data.push(value.waist);
    });
  }

  calcBmi(weight) {
    if (weight) {
      const BMI =
        Math.round(
          (+weight /
            ((this.currentUser.height / 100) *
              (this.currentUser.height / 100))) *
            10
        ) / 10;
      return BMI;
    } else {
      return '-';
    }
  }

  updateWeight(type?) {
    if (type !== 'metric') {
      let ilbs = this.trackerForm.controls['weightLbs'].value;
      if (!ilbs) {
        ilbs = 0;
      }
      this.trackerForm.controls['weight'].setValue(
        +this.trackerForm.controls['weightStone'].value * 6.35029 +
          ilbs * 0.453592
      );
      this.trackerForm.controls['weight'].setValue(
        (Math.round(+this.trackerForm.controls['weight'].value) * 10) / 10
      );
    } else {
      // convert metric to imperial
      const stones = Math.floor(
        +this.trackerForm.controls['weight'].value * 0.1574
      );
      const remainder = 0.1574 % +this.trackerForm.controls['weight'].value;
      const totalLbs = 2.20462 * +this.trackerForm.controls['weight'].value;
      const lbs = totalLbs - stones * 14;
      this.trackerForm.controls['weightStone'].setValue(stones);
      this.trackerForm.controls['weightLbs'].setValue(
        Math.round(lbs * 10) / 10
      );
    }
  }

  updateWaist(type?) {
    if (type !== 'metric') {
      if (+this.trackerForm.controls['waist'].value) {
        this.trackerForm.controls['waistCm'].setValue(
          Math.round(+this.trackerForm.controls['waist'].value * 2.54 * 10) / 10
        );
      } else {
        this.trackerForm.controls['waistCm'].setValue('');
      }
    } else {
      if (+this.trackerForm.controls['waistCm'].value) {
        this.trackerForm.controls['waist'].setValue(
          Math.round(
            (+this.trackerForm.controls['waistCm'].value / 2.54) * 10
          ) / 10
        );
      } else {
        this.trackerForm.controls['waist'].setValue('');
      }
    }
  }

  onSwitchMode(event, area, mode) {
    event.preventDefault();
    if (area === 'weight') {
      this.unitMode = mode;
      if (mode === 'imperial') {
        this.updateWeight('metric');
      }
      if (mode === 'metric') {
        this.updateWeight('imperial');
      }
    }
    if (area === 'waist') {
      this.unitModeWaist = mode;
      if (mode === 'imperial') {
        this.updateWaist('metric');
      }
      if (mode === 'metric') {
        this.updateWaist('imperial');
      }
    }
  }

  sortByCreated(a, b) {
    if (a.created > b.created) {
      return -1;
    }
    if (a.created < b.created) {
      return 1;
    }
    return 0;
  }
}
