import { Injectable } from '@angular/core';
import { ActivityService } from './activity.service';
import { BloodGlucoseService } from './blood-glucose.service';
import { ExerciseWorkoutsService } from './exercise-workouts.service';
import { HeartRateService } from './heart-rate.service';
import { NonSteppingService } from './non-stepping.service';
import { SleepService } from './sleep.service';
import { Sts60Service } from './sts60.service';
import { UserAlbuminService } from './user-albumin.service';
import { UserAltService } from './user-alt.service';
import { UserEgfrService } from './user-egfr.service';
import { UserFib4Service } from './user-fib4.service';
import { UserMbtqService } from './user-mbtq.service';
import { UserPaidService } from './user-paid.service';
import { UserPhq9Service } from './user-phq9.service';
import { Vo2maxService } from './vo2max.service';
import { Eq5dService } from './eq5d.service';
import { UserFallService } from './user-fall.service';
import { User180TurnService } from './user-180-turn.service';
import { UserShortFesService } from './user-short-fes.service';
import { UserSss8Service } from './user-sss8.service';
import { UserDistressScoreService } from './user-distress-score.service';
import { UserCfsScoreService } from './user-cfs-score.service';
import { TargetService } from './target.service';
import { ProgrammeService } from './programme.service';
import { HealthyEatingService } from './healthy-eating.service';
import { AlertService } from './alert.service';
import { AssessmentsService } from './assessments.service';
import { AwardService } from './award.service';
import { BloodPressureService } from './blood-pressure.service';
import { ChallengeService } from './challenge.service';
import { CholesterolService } from './cholesterol.service';
import { CmsService } from './cms.service';
import { DecisionMakerService } from './decision-maker.service';
import { FaqsService } from './faqs.service';
import { LearningMaterialService } from './learning-material.service';
import { PageViewService } from './page-view.service';
import { SmokingService } from './smoking.service';
import { WorkoutsService } from './workouts.service';

@Injectable({
  providedIn: 'root',
})
export class CacheManagerService {
  constructor(
    private vo2maxService: Vo2maxService,
    private heartRateService: HeartRateService,
    private sleepService: SleepService,
    private bloodGlucoseService: BloodGlucoseService,
    private exericseWorkoutsService: ExerciseWorkoutsService,
    private nonSteppingService: NonSteppingService,
    private sts60Service: Sts60Service,
    private userAlbuminService: UserAlbuminService,
    private userAltService: UserAltService,
    private userEgfrServuce: UserEgfrService,
    private userFib4Service: UserFib4Service,
    private userMbtqService: UserMbtqService,
    private userPaidService: UserPaidService,
    private userPhq9Service: UserPhq9Service,
    private activityService: ActivityService,
    private userFallService: UserFallService,
    private user180TurnService: User180TurnService,
    private userShortFesService: UserShortFesService,
    private userSss8Service: UserSss8Service,
    private userDistressScoreService: UserDistressScoreService,
    private userCfsScoreService: UserCfsScoreService,
    private targetService: TargetService,
    private programmeService: ProgrammeService,
    private healthyEatingService: HealthyEatingService,
    private alertService: AlertService,
    private assessmentservice: AssessmentsService,
    private awardService: AwardService,
    private bloodPressureService: BloodPressureService,
    private challengeService: ChallengeService,
    private cholesterService: CholesterolService,
    private cmsService: CmsService,
    private decisionMakerService:DecisionMakerService,
    private eq5dService:Eq5dService,
    private faqService:FaqsService,
    private learningMaterialService:LearningMaterialService,
    private pageViewService:PageViewService,
    private smokingService:SmokingService,
    private workoutService:WorkoutsService
  ) {}

  clearAllCache() {
    this.vo2maxService.clearCache();
    this.heartRateService.clearCache();
    this.sleepService.clearCache();
    this.bloodGlucoseService.clearCache();
    this.exericseWorkoutsService.clearCache();
    this.nonSteppingService.clearCache();
    this.sts60Service.clearCache();
    this.userAlbuminService.clearCache();
    this.userAltService.clearCache();
    this.userEgfrServuce.clearCache();
    this.userFib4Service.clearCache();
    this.userMbtqService.clearCache();
    this.userPaidService.clearCache();
    this.userPhq9Service.clearCache();
    this.activityService.clearCache();
    this.userFallService.clearCache();
    this.user180TurnService.clearCache();
    this.userShortFesService.clearCache();
    this.userSss8Service.clearCache();
    this.userDistressScoreService.clearCache();
    this.userCfsScoreService.clearCache();
    this.targetService.clearCache();
    this.programmeService.clearCache();
    this.healthyEatingService.clearCache();
    this.alertService.clearCache();
    this.assessmentservice.clearCache();
    this.awardService.clearCache();
    this.bloodPressureService.clearCache();
    this.challengeService.clearCache();
    this.cholesterService.clearCache();
    this.cmsService.clearCache();
    this.decisionMakerService.clearCache();
    this.eq5dService.clearCache();
    this.faqService.clearCache();
    this.learningMaterialService.clearCache();
    this.pageViewService.clearCache();
    this.smokingService.clearCache();
    this.workoutService.clearCache();

  }
}
