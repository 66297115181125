import { environment } from 'src/environments/environment';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'dateFormatter',
})
export class DateFormatterPipe implements PipeTransform {
  transform(date: any, format: string): any {
    if (date) {
      /*if (environment.country === 'AUS') {
        // console.log('date in = ' + date);
        const localtz = moment.tz.guess();
        date = moment(date).tz(environment.timezone);
        const formattedDate = date.clone().tz(localtz).utc().format(format);
        // console.log('date out = ' + formattedDate);
        return formattedDate;
      } else {*/
        return moment(date).format(format);
      /*}*/
    }
  }
}
