import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as moment from 'moment';
import { Color, Label } from 'ng2-charts';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { HeartRateService } from 'src/app/services/heart-rate.service';
import { ProgrammeService } from 'src/app/services/programme.service';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { UserService } from 'src/app/services/user.service';
import { Vo2maxService } from 'src/app/services/vo2max.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2/src/sweetalert2.js';

@Component({
  selector: 'app-m3-fitness',
  templateUrl: './m3-fitness.component.html',
  styleUrls: ['./m3-fitness.component.scss'],
})
export class M3FitnessComponent implements OnInit {
  currentUser;
  currentProgramme;
  currentHeartRate;
  date = new Date();
  heartRates;
  vo2maxs;
  currentVo2max;
  ageEquivalent;
  @ViewChild('entryForm', { static: false }) entryForm: NgForm;

  age;
  sex;
  modalRef?: BsModalRef;
  showAllEntries = false;
  @ViewChild('addModal') public addModal: ModalDirective;

  constructor(
    private authService: AuthService,
    private programmeService: ProgrammeService,
    private router: Router,
    private userService: UserService,
    private heartRateService: HeartRateService,
    private userSettingsService: UserSettingsService,
    private vo2maxService: Vo2maxService,
    private modalService: BsModalService


  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {});

      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
        this.sex = this.currentUser.gender;
        if(this.currentUser.dob){
          this.age = moment().diff(this.currentUser.dob, 'years',false);
        }
      });

      forkJoin({
        userSettings: this.userSettingsService.fetchAll(),
        heartRates: this.heartRateService.fetchAll(),
        vo2maxs: this.vo2maxService.fetchAll(),
        
      }).subscribe(
        (responseData) => {
          this.heartRates = responseData.heartRates;
          this.vo2maxs = responseData.vo2maxs;
          if(this.heartRates[0]){
            this.currentHeartRate = this.heartRates[0].heart_rate;
          }
          else{
           //this.modalRef = this.modalService.show(this.addModal);
           //console.log(this.addModal);

           this.addModal.show();
           // this.addModal.;
          }
          if(this.vo2maxs[0]){
            this.currentVo2max = this.vo2maxs[0].vo2max;

            this.ageEquivalent = this.vo2maxService.getVo2maxAgeEquivalent(this.vo2maxs[0].vo2max, this.age, this.sex);
          }

        }
      );
    }
  }

  onSubmitEntryForm() {
    // save the steps
    this.heartRateService
      .create(this.entryForm.value.hr, this.entryForm.value.date)
      .subscribe((responseData) => {
        // refresh the route..
        this.vo2maxService.clearCache();
        this.heartRateService.clearCache();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/fitness']));

        Swal.fire({
          icon: 'success',
          title: 'Entry Added',
          text: 'Your Heart Rate entry was added',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      });
  }

  


  onDelete(heartRate) {
    this.heartRateService.delete(heartRate.user_heart_rate_id).subscribe(
      (responseData) => {
        const index = this.heartRates.indexOf(heartRate);
        this.heartRates.splice(index, 1);
        this.vo2maxService.clearCache();
        this.heartRateService.clearCache();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/fitness']));
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onDeleteVo2max(vo2max){
    this.vo2maxService.delete(vo2max.user_vo2max_id).subscribe(
      (responseData) => {
        this.vo2maxService.clearCache();
        this.heartRateService.clearCache();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/fitness']));
      },
      (error) => {
        console.log(error);
      }
    );

  }
  getGoodforAgeAndSex(age:number, sex:string){
    return this.vo2maxService.getGoodforAgeAndSex(age, sex);
  }

  getGoodAgeforVo2maxAndSex(vo2max, sex){
    return this.vo2maxService.getGoodAgeforVo2maxAndSex(vo2max, sex);
  }

  getRagForAgeAndSex( vo2max:any, age:number, sex:string,){
    return this.vo2maxService.getRagForAgeAndSex(vo2max, age, sex);
  }


  getVo2maxforColorAgeAndSex(color:string, age:number, sex:string){
    return this.vo2maxService.getVo2maxforColorAgeAndSex(color, age, sex);
  }

  
}
