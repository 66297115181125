<nav class="subnav">
    <a [routerLink]="['/health-trackers','shape']" class="subnav-prev" aria-label="Go to shape">
      <span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span>
      </a>
    <h2> <span class="icon-wrap"><fa-icon [icon]="['fas', 'utensils']"></fa-icon></span> HEALTHY EATING</h2>
    <a [routerLink]="['/health-trackers','hba1c']" class="subnav-next" aria-label="Go to HbA1c"
      ><span class="icon-wrap"><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span></a>
  </nav>
<div>
    <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
  <div class="masthead masthead-accent">
    <div *ngIf="healthyEatings.length>0">
      <div class="row">
        <div class="col-6">
          <h3 class="tracker-title">HEALTHY EATING RATING</h3>
          <div class="row">
            <div class="col-6">
              <img src="./assets/img/icon-food.png" width="56" />
            </div>
            <div class="col-6">
              <div>
                <span class="tracker-value tracker-value-lg">{{
                  healthyEatings[0].healthy_eating | number
                }}</span
                ><span class="tracker-value tracker-value-md">/10</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <h3 class="tracker-title">DAILY <br />FRUIT &amp; VEG</h3>
          <div class="row">
            <div class="col-6">
              <img src="./assets/img/icon-veg.png" height="69" />
            </div>
            <div class="col-6">
              <div style="text-align: left">
                <span class="tracker-value tracker-value-lg"
                  >{{
                    healthyEatings[0].five_a_day * 1 +
                      healthyEatings[0].five_a_day_fruit * 1 | number
                  }}<br /><span class="tracker-value tracker-value-sm"
                    >OF YOUR<br /><span *ngIf="environment.country != 'AUS'"
                      >5</span
                    ><span *ngIf="environment.country == 'AUS'">7</span
                    >-A-DAY</span
                  ></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="masthead-subtext tracker-subtext">
            Last updated on
            {{
              healthyEatings[0].created
                | dateToISO
                | dateFormatter: "D"
                | ordinal
            }}
            {{ healthyEatings[0].created | dateToISO | dateFormatter: "MMM yyyy" }}
          </p>
        </div>
      </div>
    </div>

    <div *ngIf="healthyEatings.length < 1">
      <h4>You haven't added any readings yet!</h4>
    </div>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.update = !toggleRow.update"
    >
      <a href="" class="toggle-nav toggle-nav-expand" (click)="$event.preventDefault()"
        ><span class="icon-wrap" aria-label="Expand"
        ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span
    ></a>
      <div class="toggle-header-title">UPDATE TRACKER</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.update">
      
      <form
        class="bs-form tracker-form"
        name="trackerForm"
        (ngSubmit)="onSubmit()"
        #trackerForm="ngForm"
      >
        <div>
          <p class="instruction">
            You may update all of these figures or if you wish to update only
            one then leave the other fields blank.
          </p>
          <div class="form-group">
            <label for="weight">HEALTHY EATING RATING</label>
            <p class="instruction">
              How do you feel about your current eating habits?
            </p>
            <p class="instruction-sm">
              1 = I am not satisfied,     5 = I still have some things to work
              on,    10 =  I am completely satisfied
            </p>
            <input
              type="number"
              name="healthyEating"
              class="form-control input-tracker"
              placeholder=""
              id="healthyEating"
              ngModel
              #healthyEating="ngModel"
              [min]="0"
              [max]="10"
              autocomplete="off"
              [required]="!(veg.value!='' || fruit.value!='' )"
            />
            <div
              class="alert alert-danger"
              *ngIf="
              healthyEating.touched &&
              !healthyEating.valid
              "
            >
              This must be a number.
            </div>
          </div>
          <div class="form-group">
            <label for="number">DAILY SERVES OF FRUIT</label>
            <p class="instruction">
              How many serves of fruit do you usually eat each day?
            </p>
            <p class="instruction">
               (A 'serve' of fruit = 1 med or 2 sml pieces of fruit.) 
            </p>
            <input
              type="number"
              name="fruit"
              class="form-control input-tracker"
              placeholder=""
              id="fruit"
              ngModel
              #fruit="ngModel"
              [min]="0"
              [max]="30"
              autocomplete="off"
              [required]="!(healthyEating.value!='' || fruit.value!='' )"
            />
            <div
              class="alert alert-danger"
              *ngIf="
                fruit.touched &&
                !fruit.valid
              "
            >
              This must be a number.
            </div>
          </div>
          <div class="form-group">
            <label for="number">DAILY SERVES OF VEG</label>
            <p class="instruction">
              How many serves of veg do you usually eat each day?
            </p>
            <p class="instruction">
               (A 'serve' of veg =  1/2 cup cooked vegetables or 1 cup of salad
              vegetables) 
            </p>
            <input
              type="number"
              name="veg"
              class="form-control input-tracker"
              placeholder=""
              id="veg"
              #veg="ngModel"
              ngModel
              [min]="0"
              [max]="30"
              autocomplete="off"
              [required]="!(healthyEating.value!='' || veg.value!='' )"
            />
            <div
              class="alert alert-danger"
              *ngIf="
                veg.touched &&
                !veg.valid
              "
            >
              This must be a number.
            </div>
          </div>
          <div class="form-group">
            <label for="entryDate">DATE</label>
            <p class="instructions">
              When did you do take this reading?
            </p>
            <input
              name="date"
              type="text"
              placeholder="DD/MM/YYYY"
              class="form-control input-tracker"
              bsDatepicker
              autocomplete="off"
              [bsConfig]="{
                dateInputFormat: 'DD/MM/YYYY',
                containerClass: 'theme-cv',
                showWeekNumbers: false
              }"
              [(ngModel)]="entryDate"
            
              [bsValue]="entryDate"
              [maxDate]="maxDate"
              #date="ngModel"
            />
          </div>
          <div class="tracker-submit-container">
            <button
              type="submit"
              class="btn btn-primary btn-white"
              [disabled]="!trackerForm.valid"
            >
            <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'check']"></fa-icon
          ></span> CONFIRM
            </button>
          </div>
        </div>
      </form>

      <!--span *ngIf="updatedToday()">You have already updated today</span-->
    </div>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.graph = !toggleRow.graph"
    >
      <a href="" class="toggle-nav toggle-nav-expand" (click)="$event.preventDefault()"
        >
        <span class="icon-wrap" aria-label="Expand"
          ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span
      >
        </a>
      <div class="toggle-header-title">TRACKER GRAPH</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.graph">
      <div *ngIf="healthyEatings.length > 0">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs nav-tabs-duo justify-content-center" role="tablist">
          <li role="presentation" [ngClass]="{ active: toggleChart.healthyEating }">
            <a
              aria-controls="weightt"
              role="tab"
              data-toggle="tab"
              (click)="toggleChart.healthyEating = true;toggleChart.veg=false;toggleChart.fruit=false;"
              >HEALTHY EATING</a
            >
          </li>
          <li role="presentation" [ngClass]="{ active: toggleChart.veg }">
            <a
              aria-controls="waistt"
              role="tab"
              data-toggle="tab"
              (click)="toggleChart.healthyEating = false;toggleChart.veg=true;toggleChart.fruit=false;"
              >VEG</a
            >
          </li>
          <li role="presentation" [ngClass]="{ active: toggleChart.fruit }">
            <a
              aria-controls="waistt"
              role="tab"
              data-toggle="tab"
              (click)="toggleChart.healthyEating = false;toggleChart.veg=false;toggleChart.fruit=true;"
              >FRUIT</a
            >
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <div
            role="tabpanel"
            
            id="weightt"
            *ngIf="toggleChart.healthyEating"
          >
            <div class="chart tracker-chart">
              <div *ngIf="healthyEatingChartData[0].data.length > 1">
                <canvas
                class="mt-3 mb-3 tracker-chart"
                baseChart
                [datasets]="healthyEatingChartData"
                [labels]="chartLabels"
                [options]="chartOptions"
                [legend]="chartLegend"
                [chartType]="chartType"
                [colors]="chartColors"
              >
              </canvas>
                <!--canvas
                  id="line"
                  class="chart chart-line tracker-chart"
                  chart-data="data"
                  chart-labels="labels"
                  chart-series="series"
                  chart-options="options"
                  chart-dataset-override="datasetOverride"
                  chart-click="onClick"
                  height="200"
                  aria-label="Bar Chart Values {{ healthyEatingChartData[0].data }}"
                >
                </canvas-->
              </div>
              <p class="text-center" *ngIf="healthyEatingChartData[0].data.length < 2">
                Please add more entries to see the graph
              </p>
            </div>
          </div>
          <div
            role="tabpanel"
            
            id="waistt"
            *ngIf="toggleChart.veg"
          >
            <div class="chart tracker-chart">
              <div *ngIf="vegChartData[0].data.length > 1">
                <canvas
                class="mt-3 mb-3 tracker-chart"
                baseChart
                [datasets]="vegChartData"
                [labels]="chartLabels"
                [options]="chartOptions"
                [legend]="chartLegend"
                [chartType]="chartType"
                [colors]="chartColors"
              >
              </canvas>
              <!--canvas
                  id="line2"
                  class="chart chart-line tracker-chart"
                  chart-data="data2"
                  chart-labels="labels2"
                  chart-series="series2"
                  chart-options="options2"
                  chart-dataset-override="datasetOverride2"
                  height="200"
                  aria-label="Bar Chart Values {{ vegChartData[0].data }}"
                >
                </canvas-->
              </div>
              <p class="text-center" *ngIf="vegChartData[0].data.length < 2">
                Please add more entries to see the graph
              </p>
            </div>
          </div>
          <div
            role="tabpanel"
            
            id="waistt"
            *ngIf="toggleChart.fruit"
          >
            <div class="chart tracker-chart">
              <div *ngIf="fruitChartData[0].data.length > 1">
                <canvas
                class="mt-3 mb-3 tracker-chart"
                baseChart
                [datasets]="fruitChartData"
                [labels]="chartLabels"
                [options]="chartOptions"
                [legend]="chartLegend"
                [chartType]="chartType"
                [colors]="chartColors"
              >
              </canvas>
                <!--canvas
                  id="line3"
                  class="chart chart-line tracker-chart"
                  chart-data="data3"
                  chart-labels="labels3"
                  chart-series="series3"
                  chart-options="options3"
                  chart-dataset-override="datasetOverride3"
                  height="300"
                  aria-label="Bar Chart Values {{ fruitChartData[0].data }}"
                >
                </canvas-->
              </div>
              <p class="text-center" *ngIf="fruitChartData[0].data.length < 2">
                Please add more entries to see the graph
              </p>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="healthyEatings.length === 0">
        <p>There are no readings to display in a graph</p>
      </div>
    </div>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.entry = !toggleRow.entry"
    >
      <a href="" class="toggle-nav toggle-nav-expand" (click)="$event.preventDefault()"
        ><span class="icon-wrap" aria-label="Expand"
        ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span
    ></a>
      <div class="toggle-header-title">ENTRY ARCHIVE</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.entry">
      <table *ngIf="healthyEatings.length > 0" class="table">
        <thead>
          <tr>
            <th>DATE</th>
            <th>HEALTHY EATING</th>
            <th>VEG</th>
            <th>FRUIT</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let healthyEating of healthyEatings">
            <td class="text-center">
              {{ healthyEating.created | dateToISO | dateFormatter: "D" | ordinal }}
              {{ healthyEating.created | dateToISO | dateFormatter: "MMM yyyy" }}
            </td>
            <td class="text-center">{{ healthyEating.healthy_eating }}</td>
            <td class="text-center">{{ healthyEating.five_a_day }}</td>
            <td class="text-center">
              {{ healthyEating.five_a_day_fruit }}
            </td>
            <td>
              <a
              [swal]="{
                title: 'Are you sure you want to delete this?',
                icon: 'warning',
                showCancelButton: true
              }"
              (confirm)="onRemove(healthyEating)"
              class="pointer"
              ><span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
            ></a>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="healthyEatings.length === 0">There are no records</div>
    </div>
  </div>
  <!-- decision maker -->
  <hr class="divider-tracker" />
  <div class="text-center decision-maker-block">
    <p>
      Want to set goals to make healthier food choices?<br />
      Try our Decision Maker tool
    </p>
    <a [routerLink]="['/decision-maker']" class="btn btn-primary"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'map-signs']"></fa-icon
      ></span>
      DECISION MAKER</a
    >
  </div>

  <app-section-nav
    [section]="'healthTrackers'"
    [active]="'healthyEating'"
  ></app-section-nav>
</div>
