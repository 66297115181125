<div class="container m3" *ngIf="currentUser">

    <app-m3-header class="d-none  d-sm-block"></app-m3-header>
    <app-m3-mobile-navbar [pageTitle]="'Health Data'" class="d-block d-sm-none"></app-m3-mobile-navbar>
    <nav class="subnav d-block d-sm-none">
      <h2>
        Glucose Levels
      </h2>
    </nav>
    
    <h1 class="m3-title mt-3 d-none  d-sm-block">
      
      <button class="btn btn-white btn-sm float-right mt-1" [routerLink]="['/m3','health-data']"> &lt; Back</button>
      <app-m3-icon [icon]="'glucose'"></app-m3-icon> Glucose Levels
    </h1>
    
    <!--button (click)="addModal.show()" class="btn btn-mobile-add d-block d-sm-none">
      <app-m3-icon [icon]="'more'" [style]="'color'"></app-m3-icon
      > Add a Sitting Entry</button-->
  
      <h2 class="mt-2" id="hba1c">
      HbA1c <a class="add-item-btn" (click)="addModal.show()"
        ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
      ></a></h2>
    <div class="card" *ngIf="hba1cs">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
  
            
            <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
              <div class="info-stat-rag-current">
                <span class="info-stat-label">HbA1c</span>
                <span
                  *ngIf="hba1cs[0]"
                  class="info-stat-stat text-rag-{{
                    bloodGlucoseService.getRag(hba1cs[0].glucose)
                  }}"
                >
                  <span *ngIf="hba1cs[0]">{{ hba1cs[0].glucose }}</span
                  ><span *ngIf="!hba1cs[0]">?</span></span
                >
                <span *ngIf="!hba1cs[0]" class="info-stat-stat">-</span>
                <span class="info-stat-label">%</span>
              </div>

              <div class="rag-details">
                <div class="rag-details-target">
                  Target:
                  <span class="text-blue">{{bloodGlucoseService.getHba1cTarget()}}%</span>
                </div>
                <div class="rag-details-history" *ngIf="hba1cs.length > 0">
                  History:
                  <div class="rag-history">
                    <ng-template #popTemplateglucose>
                      <div>
                        <div>Range:</div>
                        <div class="d-flex">
                          <span class="rag-circle bg-rag-green mr-1"></span>
                          {{bloodGlucoseService.getHba1cforColor('green')}}%
                        </div>
                        <div class="d-flex">
                          <span class="rag-circle bg-rag-amber mr-1"></span>
                          {{bloodGlucoseService.getHba1cforColor('amber')}}%
                        </div>
                        <div class="d-flex">
                          <span class="rag-circle bg-rag-red mr-1"></span>
                          {{bloodGlucoseService.getHba1cforColor('red')}}%
                        </div>
                      </div></ng-template
                    >
                    <ng-container
                      *ngFor="
                        let glucose of hba1cs.slice().reverse();
                        let index = index
                      "
                    >
                      <div
                        *ngIf="index > hba1cs.length - 6"
                        [tooltip]="
                          glucose.glucose +
                          '%' +
                          ' on ' +
                          (glucose.created | dateFormatter : 'Do MMM YYYY')
                        "
                        class="rag-circle bg-rag-{{
                          getHba1cRag(glucose.glucose)
                        }}"
                      ></div>
                    </ng-container>
                    <div
                      class="rag-circle pointer"
                      [popover]="popTemplateglucose"
                    >
                      <span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'info']"></fa-icon
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
              <div class="info-stat-rag-current">
                <span class="info-stat-label">Change in HbA1c</span>
                <span
                  *ngIf="hba1cChanges[0]"
                  class="info-stat-stat text-rag-{{
                    bloodGlucoseService.getChangeRag(hba1cChanges[0].change)
                  }}"
                >
                  <span *ngIf="hba1cs[0]">
                    <fa-icon
                      *ngIf="+hba1cChanges[0].change > 0"
                      [icon]="['fas', 'arrow-up']"
                    ></fa-icon>
                    <fa-icon
                      *ngIf="+hba1cChanges[0].change < 0"
                      [icon]="['fas', 'arrow-down']"
                    ></fa-icon>

                    {{ hba1cChanges[0].change }}</span
                  ><span *ngIf="!hba1cs[0]">?</span></span
                >
                <span *ngIf="!hba1cs[0]" class="info-stat-stat">-</span>
                <span class="info-stat-label">%</span>
              </div>

              <div class="rag-details">
                <div class="rag-details-target"></div>
                <div
                  class="rag-details-history"
                  *ngIf="hba1cChanges.length > 0"
                >
                  History:
                  <div class="rag-history">
                    <ng-template #popTemplateglucose>
                      <div>
                        <div>Range:</div>
                        <div class="d-flex">
                          <span class="rag-circle bg-rag-green mr-1"></span>
                          {{bloodGlucoseService.getChangeByColour('green')}}%
                        </div>
                        <div class="d-flex">
                          <span class="rag-circle bg-rag-amber mr-1"></span>
                          {{bloodGlucoseService.getChangeByColour('amber')}}%
                        </div>
                        <div class="d-flex">
                          <span class="rag-circle bg-rag-red mr-1"></span>
                          {{bloodGlucoseService.getChangeByColour('red')}}%
                        </div>
                      </div></ng-template
                    >
                    <ng-container
                      *ngFor="
                        let glucose of hba1cChanges.slice().reverse();
                        let index = index
                      "
                    >
                      <div
                        *ngIf="index > hba1cChanges.length - 6"
                        [tooltip]="
                          glucose.change +
                          '%' +
                          ' on ' +
                          (glucose.created | dateFormatter : 'Do MMM YYYY')
                        "
                        class="rag-circle bg-rag-{{
                          bloodGlucoseService.getChangeRag(glucose.change)
                        }}"
                      ></div>
                    </ng-container>
                    <div
                      class="rag-circle pointer"
                      [popover]="popTemplateglucose"
                    >
                      <span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'info']"></fa-icon
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h3 class="chart-title text-center mt-4" *ngIf="hba1cs">Your Entries</h3>
            <div class="table-responsive"  *ngIf="hba1cs">
              <table *ngIf="hba1cs" class="table">
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>HbA1c</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let hba1c of hba1cs ; let index = index;">
                    <tr *ngIf="hba1c.glucose > 0 && (index<10 || showAllEntries)">
                    <td class="text-center">
                      {{ hba1c.created | dateFormatter : "MMM Do YYYY" }}
                    </td>
                    <td class="text-center">
                      {{ hba1c.glucose}}
                    </td>
  
                    <td>
                      <a
                        [swal]="{
                          title: 'Are you sure you want to delete this?',
                          icon: 'warning',
                          showCancelButton: true
                        }"
                        (confirm)="onDeleteHba1c(hba1c)"
                        class="pointer"
                        ><span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                      ></a>
                    </td>
                  </tr>
                  </ng-container>
                </tbody>
              </table>
              <div class="text-center">
                <button
                *ngIf="!showAllEntries && hba1cs.length>10"
                 
                  class="btn btn-primary btn-sm btn-xs"
                  (click)="showAllEntries = true"
                >
                  Show all
                </button>
              </div>
            </div>
            
          </div>
          <div class="col-md-6">
            <app-m3-hba1c-chart
            *ngIf="hba1cs && hba1cs.length > 1"
            [hba1cs]="hba1cs.slice().reverse()"
          ></app-m3-hba1c-chart>
          </div>
        </div>
      </div>
    </div>


    <hr>


    <h2 class="mt-2" id="hba1c">
        Average Glucose Level <a class="add-item-btn" (click)="addLevelModal.show()"
          ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
        ></a></h2>
      <div class="card" *ngIf="glucoseAverages">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
    
                <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                    <div class="info-stat-rag-current">
                     
                      <span class="info-stat-label">Average glucose level</span>
                      <span
                        *ngIf="glucoseAverages[0]"
                        class="info-stat-stat text-rag-{{
                          getGlucoseAverageRag(glucoseAverages[0].user_glucose_average)
                        }}"
                      >
                        <span *ngIf="glucoseAverages[0]">{{ glucoseAverages[0].user_glucose_average }}</span
                        ><span *ngIf="!glucoseAverages[0]">?</span></span
                      >
                      <span *ngIf="!glucoseAverages[0]" class="info-stat-stat">-</span>
                      <span class="info-stat-label">mmol/l</span>
                    </div>
    
                    <div class="rag-details">
                      <div class="rag-details-target">
                       
                        
                      </div>
                      <div class="rag-details-history" *ngIf="glucoseAverages.length > 0">
                        History:
                        <div class="rag-history">
                          
                          <ng-container
                            *ngFor="let glucoseAverage of glucoseAverages.slice().reverse(); let index = index">
                            <div
                              *ngIf="index > glucoseAverages.length - 6"
                              [tooltip]="
                              glucoseAverage.user_glucose_average +
                                '' +
                                ' on ' +
                                (glucoseAverage.date_recorded| dateFormatter : 'Do MMM YYYY')
                              "
                              class="rag-circle bg-rag-{{
                                getGlucoseAverageRag(glucoseAverage.user_glucose_average)
                              }}"
                            ></div>
                          </ng-container>
                         
                        </div>
                      </div>
                    </div>
                  </div>
    
                  <div
                class="info-stat info-stat-rag info-stat-no-icon mb-3"
                *ngIf="glucoseAverageChanges[0]"
              >
                <div class="info-stat-rag-current">
                  <span class="info-stat-label"
                    >Change in average glucose level</span
                  >
                  <span
                    *ngIf="glucoseAverageChanges[0]"
                    class="info-stat-stat text-rag-{{
                      bloodGlucoseService.getAverageChangeRag(
                        glucoseAverageChanges[0].change
                      )
                    }}"
                  >
                    <span *ngIf="glucoseAverageChanges[0]">{{
                      glucoseAverageChanges[0].change
                    }}</span
                    ><span *ngIf="!glucoseAverageChanges[0]">?</span></span
                  >
                  <span *ngIf="!glucoseAverageChanges[0]" class="info-stat-stat"
                    >-</span
                  >
                  <span class="info-stat-label">mmol/l</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target"></div>
                  <div
                    class="rag-details-history"
                    *ngIf="glucoseAverageChanges.length > 0"
                  >
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateAverageChange>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{
                              bloodGlucoseService.getAverageChangeByColour(
                                "green"
                              )
                            }}mmol/l
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            {{
                              bloodGlucoseService.getAverageChangeByColour(
                                "amber"
                              )
                            }}mmol/l
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{
                              bloodGlucoseService.getAverageChangeByColour(
                                "red"
                              )
                            }}mmol/l
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let glucoseAverage of glucoseAverageChanges
                            .slice()
                            .reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > glucoseAverageChanges.length - 6"
                          [tooltip]="
                            glucoseAverage.change +
                            ' mmol/l ' +
                            ' on ' +
                            (glucoseAverage.date_recorded
                              | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            bloodGlucoseService.getAverageChangeRag(
                              glucoseAverage.change
                            )
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateAverageChange"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              
                
  
                
                
  
              <h3 class="chart-title text-center mt-4" *ngIf="glucoseAverages">Your Entries</h3>
              <div class="table-responsive"  *ngIf="glucoseAverages">
                <table *ngIf="glucoseAverages" class="table">
                  <thead>
                    <tr>
                      <th>DATE</th>
                      <th>GLUCOSE AVERAGE</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let glucoseAverage of glucoseAverages ; let index = index;">
                      <tr *ngIf="(index<10 || showAllEntries)">
                      <td class="text-center">
                        {{ glucoseAverage.date_recorded | dateFormatter : "MMM Do YYYY" }}
                      </td>
                      <td class="text-center">
                        {{ glucoseAverage.user_glucose_average}}
                      </td>
    
                      <td>
                        <a
                          [swal]="{
                            title: 'Are you sure you want to delete this?',
                            icon: 'warning',
                            showCancelButton: true
                          }"
                          (confirm)="onDeleteAverage(glucoseAverage)"
                          class="pointer"
                          ><span class="icon-wrap"
                            ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                        ></a>
                      </td>
                    </tr>
                    </ng-container>
                  </tbody>
                </table>
                <div class="text-center">
                  <button
                  *ngIf="!showAllEntries && glucoseAverages.length>10"
                   
                    class="btn btn-primary btn-sm btn-xs"
                    (click)="showAllEntries = true"
                  >
                    Show all
                  </button>
                </div>
              </div>
              
            </div>
            <div class="col-md-6">
              
            <app-m3-glucose-average-chart
            *ngIf="glucoseAverages && glucoseAverages.length > 1"
            [averages]="glucoseAverages.slice().reverse()"
          ></app-m3-glucose-average-chart>
            </div>
          </div>
        </div>
      </div>



<hr>
    <h2 class="mt-2" id="hba1c">
       Time in Range <a class="add-item-btn" (click)="addInRangeModal.show()"
        ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
      ></a></h2>
    <div class="card" *ngIf="glucoseInRanges">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
  
              
            <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
              <div class="info-stat-rag-current">
                <span class="info-stat-label">Time in range</span>
                <span
                  *ngIf="glucoseInRanges[0]"
                  class="info-stat-stat text-rag-{{bloodGlucoseService.getInRangeRag(glucoseInRanges[0].time_in_range)}}"
                >
                  <span *ngIf="glucoseInRanges[0]">{{
                    glucoseInRanges[0].time_in_range
                  }}</span
                  ><span *ngIf="!glucoseInRanges[0]">?</span></span
                >
                <span *ngIf="!glucoseInRanges[0]" class="info-stat-stat"
                  >-</span
                >
                <span class="info-stat-label">%</span>
              </div>

              <div class="rag-details">
                <div class="rag-details-target">
                  Target:
                  <span class="text-blue">
                    {{bloodGlucoseService.getInRangeByColour('green')}}%</span>
                </div>
                <div
                  class="rag-details-history"
                  *ngIf="glucoseInRanges.length > 0"
                >
                  History:
                  <div class="rag-history">
                    <ng-template #popTemplateglucose>
                      <div>
                        <div>Range:</div>
                        <div class="d-flex">
                          <span class="rag-circle bg-rag-green mr-1"></span>
                          {{bloodGlucoseService.getInRangeByColour('green')}}%
                        </div>
                        <div class="d-flex">
                          <span class="rag-circle bg-rag-amber mr-1"></span>

                          {{bloodGlucoseService.getInRangeByColour('amber')}}%
                        </div>
                        <div class="d-flex">
                          <span class="rag-circle bg-rag-red mr-1"></span>
                          {{bloodGlucoseService.getInRangeByColour('red')}}%
                        </div>
                      </div></ng-template
                    >
                    <ng-container
                      *ngFor="
                        let glucose of glucoseInRanges.slice().reverse();
                        let index = index
                      "
                    >
                      <div
                        *ngIf="index > glucoseInRanges.length - 6"
                        [tooltip]="
                          glucose.time_in_range +
                          '% ' +
                          ' on ' +
                          (glucose.date_recorded
                            | dateFormatter : 'Do MMM YYYY')
                        "
                        class="rag-circle bg-rag-{{bloodGlucoseService.getInRangeRag(glucose.time_in_range)}}"
                      ></div>
                    </ng-container>
                    <div
                      class="rag-circle pointer"
                      [popover]="popTemplateglucose"
                    >
                      <span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'info']"></fa-icon
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            

            
              

              
              

            <h3 class="chart-title text-center mt-4" *ngIf="glucoseInRanges">Your Entries</h3>
            <div class="table-responsive"  *ngIf="glucoseInRanges">
              <table *ngIf="glucoseInRanges" class="table">
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>IN RANGE</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let glucoseInRange of glucoseInRanges ; let index = index;">
                    <tr *ngIf="(index<10 || showAllEntries)">
                    <td class="text-center">
                      {{ glucoseInRange.date_recorded | dateFormatter : "MMM Do YYYY" }}
                    </td>
                    <td class="text-center">
                      {{ glucoseInRange.time_in_range}}
                    </td>
  
                    <td>
                      <a
                        [swal]="{
                          title: 'Are you sure you want to delete this?',
                          icon: 'warning',
                          showCancelButton: true
                        }"
                        (confirm)="onDeleteInRange(glucoseInRange)"
                        class="pointer"
                        ><span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                      ></a>
                    </td>
                  </tr>
                  </ng-container>
                </tbody>
              </table>
              <div class="text-center">
                <button
                *ngIf="!showAllEntries && glucoseInRanges.length>10"
                 
                  class="btn btn-primary btn-sm btn-xs"
                  (click)="showAllEntries = true"
                >
                  Show all
                </button>
              </div>
            </div>
            
          </div>
          <div class="col-md-6">
            
            <app-m3-glucose-in-range-chart
            *ngIf="glucoseInRanges && glucoseInRanges.length > 1"
            [timeinranges]="glucoseInRanges.slice().reverse()"
          ></app-m3-glucose-in-range-chart>
          </div>
        </div>
      </div>
    </div>





<hr>
<h2 class="mt-2" id="hba1c">
   Time Below Range <a class="add-item-btn" (click)="addBelowRangeModal.show()"
    ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
  ></a></h2>
<div class="card" *ngIf="glucoseBelowRanges">
  <div class="card-body">
    <div class="row">
      <div class="col-md-6">

          
        <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
          <div class="info-stat-rag-current">
            <span class="info-stat-label">Time below range</span>
            <span
              *ngIf="glucoseBelowRanges[0]"
              class="info-stat-stat text-rag-{{bloodGlucoseService.getBelowRangeRag(glucoseBelowRanges[0].time_below_range)}}"
            >
              <span *ngIf="glucoseBelowRanges[0]">{{
                glucoseBelowRanges[0].time_below_range
              }}</span
              ><span *ngIf="!glucoseBelowRanges[0]">?</span></span
            >
            <span *ngIf="!glucoseBelowRanges[0]" class="info-stat-stat"
              >-</span
            >
            <span class="info-stat-label">%</span>
          </div>

          <div class="rag-details">
            <div class="rag-details-target">
              Target:
              <span class="text-blue">
                {{bloodGlucoseService.getBelowRangeByColour('green')}}%</span>
            </div>
            <div
              class="rag-details-history"
              *ngIf="glucoseBelowRanges.length > 0"
            >
              History:
              <div class="rag-history">
                <ng-template #popTemplateglucose>
                  <div>
                    <div>Range:</div>
                    <div class="d-flex">
                      <span class="rag-circle bg-rag-green mr-1"></span>
                      {{bloodGlucoseService.getBelowRangeByColour('green')}}%
                    </div>
                    <div class="d-flex">
                      <span class="rag-circle bg-rag-amber mr-1"></span>

                      {{bloodGlucoseService.getBelowRangeByColour('amber')}}%
                    </div>
                    <div class="d-flex">
                      <span class="rag-circle bg-rag-red mr-1"></span>
                      {{bloodGlucoseService.getBelowRangeByColour('red')}}%
                    </div>
                  </div></ng-template
                >
                <ng-container
                  *ngFor="
                    let glucose of glucoseBelowRanges.slice().reverse();
                    let index = index
                  "
                >
                  <div
                    *ngIf="index > glucoseBelowRanges.length - 6"
                    [tooltip]="
                      glucose.time_below_range +
                      '% ' +
                      ' on ' +
                      (glucose.date_recorded
                        | dateFormatter : 'Do MMM YYYY')
                    "
                    class="rag-circle bg-rag-{{bloodGlucoseService.getBelowRangeRag(glucose.time_below_range)}}"
                  ></div>
                </ng-container>
                <div
                  class="rag-circle pointer"
                  [popover]="popTemplateglucose"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'info']"></fa-icon
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        

        
          

          
          

        <h3 class="chart-title text-center mt-4" *ngIf="glucoseBelowRanges">Your Entries</h3>
        <div class="table-responsive"  *ngIf="glucoseBelowRanges">
          <table *ngIf="glucoseBelowRanges" class="table">
            <thead>
              <tr>
                <th>DATE</th>
                <th>IN RANGE</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let glucoseBelowRange of glucoseBelowRanges ; let index = index;">
                <tr *ngIf="(index<10 || showAllEntries)">
                <td class="text-center">
                  {{ glucoseBelowRange.date_recorded | dateFormatter : "MMM Do YYYY" }}
                </td>
                <td class="text-center">
                  {{ glucoseBelowRange.time_below_range}}
                </td>

                <td>
                  <a
                    [swal]="{
                      title: 'Are you sure you want to delete this?',
                      icon: 'warning',
                      showCancelButton: true
                    }"
                    (confirm)="onDeleteBelowRange(glucoseBelowRange)"
                    class="pointer"
                    ><span class="icon-wrap"
                      ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                  ></a>
                </td>
              </tr>
              </ng-container>
            </tbody>
          </table>
          <div class="text-center">
            <button
            *ngIf="!showAllEntries && glucoseBelowRanges.length>10"
             
              class="btn btn-primary btn-sm btn-xs"
              (click)="showAllEntries = true"
            >
              Show all
            </button>
          </div>
        </div>
        
      </div>
      <div class="col-md-6">
        
        <app-m3-glucose-below-range-chart
        *ngIf="glucoseBelowRanges && glucoseBelowRanges.length > 1"
        [timebelowranges]="glucoseBelowRanges.slice().reverse()"
      ></app-m3-glucose-below-range-chart>
      </div>
    </div>
  </div>
</div>
  
    <div
      class="modal fade"
      bsModal
      #addModal="bs-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="dialog-static-name"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title pull-left">Update HbA1c</h4>
            <button
              type="button"
              class="btn-close close pull-right"
              aria-label="Close"
              (click)="addModal.hide()"
            >
              <span aria-hidden="true" class="visually-hidden">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form
              name="entryForm"
              class="bs-form tracker-form"
              (ngSubmit)="onSubmitEntryForm()"
              #entryForm="ngForm"
            ><div>
                <div class="form-group">
                  <label for="glucosePercent">GLUCOSE MEASURE (mmol/mol)</label>
                  <p class="instruction">
                    Please enter your latest HbA1c measure below. Please note your
                    HbA1c is normally only measured every 3 months.
                  </p>
      
                  <div [hidden]="unitMode !== 'percent'">
                    <p><strong>Enter a value as a %</strong></p>
                    <input
                      type="number"
                      name="glucosePercent"
                      class="form-control input-tracker"
                      placeholder=""
                      aria-label="glucose Percent"
                      id="glucosePercent"
                      #glucosePercent="ngModel"
                      ngModel
                      [min]="4"
                      [max]="15"
                      autocomplete="off"
                      (change)="updateVal()"
                    />
                    <div
                      class="alert alert-danger"
                      *ngIf="
                       glucosePercent.touched &&
                       !glucosePercent.valid
                      "
                    >
                      This must be a number 4-15.
                    </div>
                    <div class="text-center">
                      <br />
                      <a href="#" (click)="$event.preventDefault();unitMode='mmol/mol'">Switch to mmol/mol</a>
                    </div>
                  </div>
      
                  <div [hidden]="unitMode !== 'mmol/mol'">
                    <p><strong>Enter a value in mmol/mol</strong></p>
                    <input
                      type="number"
                      name="glucose"
                      class="form-control input-tracker"
                      placeholder=""
                      id="glucose"
                      #glucose="ngModel"
                      ngModel
                      [min]="20"
                      [max]="140"
                      autocomplete="off"
                      required
                      aria-label="glucose"
                      (change)="updateValP()"
                    />
                    <div
                      class="alert alert-danger"
                      *ngIf="
                        glucose.touched && !glucose.valid
                      "
                    >
                      This must be a number 20-140.
                    </div>
                    <div class="text-center">
                      <br />
                      <a href="#" (click)="$event.preventDefault();unitMode='percent'">Switch to %</a>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="entryDate">DATE</label>
                  <p class="instructions">
                    When did you do take this reading?
                  </p>
                  <input
                    name="date"
                    type="text"
                    placeholder="DD/MM/YYYY"
                    class="form-control input-tracker"
                    bsDatepicker
                    autocomplete="off"
                    [bsConfig]="{
                      dateInputFormat: 'DD/MM/YYYY',
                      containerClass: 'theme-cv',
                      showWeekNumbers: false
                    }"
                    [(ngModel)]="entryDate"
                    required
                    [bsValue]="entryDate"
                    [maxDate]="maxDate"
                    #date="ngModel"
                  />
                </div>
                <div class="tracker-submit-container">
                  <button
                    type="submit"
                    class="btn btn-primary btn-white"
                    ng-disabled="!trackerForm.$valid"
                  >
                  <span class="icon-wrap"
                  ><fa-icon [icon]="['fas', 'check']"></fa-icon
                ></span> CONFIRM
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- add level modal -->

    <div
      class="modal fade"
      bsModal
      #addLevelModal="bs-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="dialog-static-name"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title pull-left">Update Glucose Level</h4>
            <button
              type="button"
              class="btn-close close pull-right"
              aria-label="Close"
              (click)="addModal.hide()"
            >
              <span aria-hidden="true" class="visually-hidden">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form
              name="entryFormAverage"
              class="bs-form tracker-form"
              (ngSubmit)="onSubmitEntryFormAverage()"
              #entryFormAverage="ngForm"
            ><div>
                <div class="form-group">
                  <label for="glucosePercent">GLUCOSE AVERAGE (mmol/mol)</label>
                  
      
                    <p><strong>Enter a value in mmol/mol</strong></p>
                    <input
                      type="number"
                      name="glucoseAverage"
                      class="form-control input-tracker"
                      placeholder=""
                      id="glucoseAverage"
                      #glucoseAverage="ngModel"
                      ngModel
                      [min]="0"
                      [max]="100"
                      autocomplete="off"
                      required
                      aria-label="glucoseAverage"
                    />
                    <div
                      class="alert alert-danger"
                      *ngIf="
                      glucoseAverage.touched && !glucoseAverage.valid
                      "
                    >
                      This must be a number 20-140.
                    </div>
                </div>
                <div class="form-group">
                  <label for="dateLevel">DATE</label>
                  <p class="instructions">
                    When did you do take this reading?
                  </p>
                  <input
                    name="dateLevel"
                    type="text"
                    placeholder="DD/MM/YYYY"
                    class="form-control input-tracker"
                    bsDatepicker
                    autocomplete="off"
                    [bsConfig]="{
                      dateInputFormat: 'DD/MM/YYYY',
                      containerClass: 'theme-cv',
                      showWeekNumbers: false
                    }"
                    [(ngModel)]="entryDateLevel"
                    required
                    [bsValue]="entryDateLevel"
                    [maxDate]="maxDate"
                    #dateLevel="ngModel"
                  />
                </div>
                <div class="tracker-submit-container">
                  <button
                    type="submit"
                    class="btn btn-primary btn-white"
                    ng-disabled="!trackerForm.$valid"
                  >
                  <span class="icon-wrap"
                  ><fa-icon [icon]="['fas', 'check']"></fa-icon
                ></span> CONFIRM
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>



    <!-- add In Range modal -->

    <div
      class="modal fade"
      bsModal
      #addInRangeModal="bs-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="dialog-static-name"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title pull-left">Update Glucose In Range</h4>
            <button
              type="button"
              class="btn-close close pull-right"
              aria-label="Close"
              (click)="addModal.hide()"
            >
              <span aria-hidden="true" class="visually-hidden">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form
              name="entryFormInRange"
              class="bs-form tracker-form"
              (ngSubmit)="onSubmitEntryFormInRange()"
              #entryFormInRange="ngForm"
            ><div>
                <div class="form-group">
                  <label for="glucosePercent">TIME IN RANGE (%)</label>
                  
      
                    <p><strong>Enter a value in %</strong></p>
                    <input
                      type="number"
                      name="glucoseInRange"
                      class="form-control input-tracker"
                      placeholder=""
                      id="glucoseInRange"
                      #glucoseInRange="ngModel"
                      ngModel
                      [min]="0"
                      [max]="100"
                      autocomplete="off"
                      required
                      aria-label="glucoseInRange"
                    />
                    <div
                      class="alert alert-danger"
                      *ngIf="
                      glucoseInRange.touched && !glucoseInRange.valid
                      "
                    >
                      This must be a number 0-100.
                    </div>
                </div>
                <div class="form-group">
                  <label for="dateInRange">DATE</label>
                  <p class="instructions">
                    When did you do take this reading?
                  </p>
                  <input
                    name="dateInRange"
                    type="text"
                    placeholder="DD/MM/YYYY"
                    class="form-control input-tracker"
                    bsDatepicker
                    autocomplete="off"
                    [bsConfig]="{
                      dateInputFormat: 'DD/MM/YYYY',
                      containerClass: 'theme-cv',
                      showWeekNumbers: false
                    }"
                    [(ngModel)]="entryDateInRange"
                    required
                    [bsValue]="entryDateInRange"
                    [maxDate]="maxDate"
                    #dateInRange="ngModel"
                  />
                </div>
                <div class="tracker-submit-container">
                  <button
                    type="submit"
                    class="btn btn-primary btn-white"
                    ng-disabled="!trackerForm.$valid"
                  >
                  <span class="icon-wrap"
                  ><fa-icon [icon]="['fas', 'check']"></fa-icon
                ></span> CONFIRM
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>



    <div
      class="modal fade"
      bsModal
      #addBelowRangeModal="bs-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="dialog-static-name"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title pull-left">Update Glucose Below Range</h4>
            <button
              type="button"
              class="btn-close close pull-right"
              aria-label="Close"
              (click)="addModal.hide()"
            >
              <span aria-hidden="true" class="visually-hidden">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form
              name="entryFormBelowRange"
              class="bs-form tracker-form"
              (ngSubmit)="onSubmitEntryFormBelowRange()"
              #entryFormBelowRange="ngForm"
            ><div>
                <div class="form-group">
                  <label for="glucosePercent">TIME BELOW RANGE (%)</label>
                  
      
                    <p><strong>Enter a value in %</strong></p>
                    <input
                      type="number"
                      name="glucoseBelowRange"
                      class="form-control input-tracker"
                      placeholder=""
                      id="glucoseBelowRange"
                      #glucoseBelowRange="ngModel"
                      ngModel
                      [min]="0"
                      [max]="100"
                      autocomplete="off"
                      required
                      aria-label="glucoseBelowRange"
                    />
                    <div
                      class="alert alert-danger"
                      *ngIf="
                      glucoseBelowRange.touched && !glucoseBelowRange.valid
                      "
                    >
                      This must be a number 0-100.
                    </div>
                </div>
                <div class="form-group">
                  <label for="dateBelowRange">DATE</label>
                  <p class="instructions">
                    When did you do take this reading?
                  </p>
                  <input
                    name="dateBelowRange"
                    type="text"
                    placeholder="DD/MM/YYYY"
                    class="form-control input-tracker"
                    bsDatepicker
                    autocomplete="off"
                    [bsConfig]="{
                      dateInputFormat: 'DD/MM/YYYY',
                      containerClass: 'theme-cv',
                      showWeekNumbers: false
                    }"
                    [(ngModel)]="entryDateBelowRange"
                    required
                    [bsValue]="entryDateBelowRange"
                    [maxDate]="maxDate"
                    #dateBelowRange="ngModel"
                  />
                </div>
                <div class="tracker-submit-container">
                  <button
                    type="submit"
                    class="btn btn-primary btn-white"
                    ng-disabled="!trackerForm.$valid"
                  >
                  <span class="icon-wrap"
                  ><fa-icon [icon]="['fas', 'check']"></fa-icon
                ></span> CONFIRM
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  
    <div class="clear"></div>
  </div>
  