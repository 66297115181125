<div class="container m3" *ngIf="currentUser">
  <app-m3-header class="d-none d-sm-block"></app-m3-header>
  <app-m3-mobile-navbar
    [pageTitle]="'Fitness'"
    class="d-block d-sm-none"
  ></app-m3-mobile-navbar>
  <nav class="subnav d-block d-sm-none">
    <h2>Overview</h2>
  </nav>
  <h1 class="m3-title mt-3 d-none d-sm-block">
    <a class="add-item-btn" (click)="addModal.show()"
      ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
    ></a>
    <app-m3-icon [icon]="'fitness'"></app-m3-icon>Fitness
  </h1>

  <button
    (click)="addModal.show()"
    class="btn btn-mobile-add d-block d-sm-none"
  >
    <app-m3-icon [icon]="'more'" [style]="'color'"></app-m3-icon> Add a Fitness
    Entry
  </button>

  <div class="card">
    <div class="card-body">
      <div>
        <div class="row">
          <div class="col-md-6">
            <div class="info-stat mb-2">
              <span class="text-dark"
                ><app-m3-icon [icon]="'heart-rate'"></app-m3-icon
              ></span>
              <span class="info-stat-label">My resting heart rate</span>
              <span class="info-stat-stat" [ngClass]="{'text-rag-red':currentHeartRate<60 || currentHeartRate>99, 'text-rag-green':currentHeartRate>=60 && currentHeartRate<=99}">{{
                currentHeartRate
              }}</span>
              <span class="info-stat-label">bpm</span>
            </div>

            <div class="info-stat info-stat-rag mb-2">
              <div class="info-stat-rag-current">
                <app-m3-icon [icon]="'vo2max'" class="text-dark"></app-m3-icon>
                <span class="info-stat-label"
                  >My estimated VO<sub>2</sub>max</span
                >
                <span
                *ngIf="vo2maxs && vo2maxs[0]"
                  class="info-stat-stat text-rag-{{
                    getRagForAgeAndSex(vo2maxs[0].vo2max, age, sex)
                  }}"
                  >{{ currentVo2max
                  }}</span
                >
                <span  class="info-stat-stat"
                *ngIf="!vo2maxs">-</span>
                <span class="info-stat-label">ml/kg/min</span>
              </div>
              <div class="rag-details">
                <div class="rag-details-target">
                  <span *ngIf="vo2maxs && vo2maxs[0] && +vo2maxs[0].vo2max < +getGoodforAgeAndSex(age, sex)">
                  Target:
                  <span class="text-blue">{{
                    getGoodforAgeAndSex(age, sex)
                  }}</span></span>
                </div>
                <div class="rag-details-history" *ngIf="vo2maxs">
                  History:
                  <div class="rag-history">
                    <ng-template #popTemplateVo2Max>
                      <div>
                        <div>Range:</div>
                        <div class="d-flex">
                          <span class="rag-circle bg-rag-green mr-1"></span>
                          {{getVo2maxforColorAgeAndSex('green', age, sex)}} ml/kg/min
                        </div>
                        <div class="d-flex">
                          <span class="rag-circle bg-rag-amber mr-1"></span>
                          {{getVo2maxforColorAgeAndSex('amber', age, sex)}} ml/kg/min
                        </div>
                        <div class="d-flex">
                          <span class="rag-circle bg-rag-red mr-1"></span>
                          {{getVo2maxforColorAgeAndSex('red', age, sex)}} ml/kg/min
                        </div>
                      </div></ng-template
                    >
                    <ng-container
                      *ngFor="let vo2max of vo2maxs.slice().reverse(); let index = index"
                    >
                      <div
                        *ngIf="index >  vo2maxs.length - 6"
                        [tooltip]="
                            vo2max.vo2max +
                            ' ml/kg/min' +
                            ' on ' +
                            (vo2max.date_recorded
                              | dateFormatter : 'Do MMM YYYY')
                          "
                        class="rag-circle bg-rag-{{
                          getRagForAgeAndSex(vo2max.vo2max, age, sex)
                        }}"
                      ></div>
                    </ng-container>
                    <div
                      class="rag-circle pointer"
                      [popover]="popTemplateVo2Max"
                    >
                      <span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'info']"></fa-icon
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <app-m3-fitness-chart
              *ngIf="vo2maxs && vo2maxs.length > 1"
              [heartRates]="heartRates"
              [vo2maxs]="vo2maxs"
              [age]="age"
              [sex]="sex"
            ></app-m3-fitness-chart>


             
             <h3 class="chart-title text-center mt-4" *ngIf="currentHeartRate">Your Heart Rate Entries</h3>
            <div class="table-responsive" *ngIf="currentHeartRate">
              <table class="table">
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>HEART RATE</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container  *ngFor="let hr of heartRates;let index = index;">
                  <tr *ngIf="index<10 || showAllEntries">
                    <td class="text-center">
                      {{ hr.date_recorded | dateFormatter : "MMM Do YYYY" }}
                    </td>
                    <td class="text-center">
                      {{ hr.heart_rate }}bpm
                    </td>

                    <td>
                      <a
                        [swal]="{
                          title: 'Are you sure you want to delete this?',
                          icon: 'warning',
                          showCancelButton: true
                        }"
                        (confirm)="onDelete(hr)"
                        class="pointer"
                        ><span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                      ></a>
                    </td>
                  </tr>
                    
                </ng-container>
                </tbody>
              </table>
              <div class="text-center">
                <button
                *ngIf="!showAllEntries && heartRates.length>10"
                 
                  class="btn btn-primary btn-sm btn-xs"
                  (click)="showAllEntries = true"
                >
                  Show all
                </button>
              </div>

            </div>
          </div>
          <div class="col-md-6">
            <div class="info-stat mb-2">
              <span class="text-pink"
                ><app-m3-icon [icon]="'heart-rate'"></app-m3-icon
              ></span>
              <span class="info-stat-label"
                >Your age</span
              >
              <span
                class="info-stat-stat text-pink"
                style="min-width: 100px"
                *ngIf="age"
                >{{ age }}</span
              >
            </div>

            <div class="info-stat mb-2">
              <span class=""
                ><app-m3-icon [icon]="'heart-rate'"></app-m3-icon
              ></span>
              <span class="info-stat-label"
                >Your estimated VO<sub>2</sub>max is what we would expected for a <span *ngIf="sex == 'f'">female</span>
                <span *ngIf="sex == 'm'">male</span> aged…</span
              >
              <span
                class="info-stat-stat"
                [ngClass]="{'text-rag-green':+getGoodAgeforVo2maxAndSex(currentVo2max, sex).slice(-2) <= +age , 'text-rag-amber':+getGoodAgeforVo2maxAndSex(currentVo2max, sex).slice(-2) > +age && +getGoodAgeforVo2maxAndSex(currentVo2max, sex).slice(-2) < +age+10, 'text-rag-red':+getGoodAgeforVo2maxAndSex(currentVo2max, sex).slice(-2) >= +age + 10 ||  getGoodAgeforVo2maxAndSex(currentVo2max, sex) == '80+' && +age<80 }"
                style="min-width: 100px"
                *ngIf="age && sex && currentVo2max"
                >{{ getGoodAgeforVo2maxAndSex(currentVo2max, sex) }}</span
              >
            </div>

            <!--div class="info-stat mb-2" *ngIf="ageEquivalent && (+ageEquivalent.min != +ageEquivalent.max)">
              <span class="text-pink"
                ><app-m3-icon [icon]="'heart-rate'"></app-m3-icon
              ></span>
              <span class="info-stat-label"
                >Your estimated VO<sub>2</sub>max is equivalent to someone between
                

                <span *ngIf="ageEquivalent.max>=0 && ageEquivalent.min <=0"> {{ageEquivalent.min*-1 +' younger and '+ ageEquivalent.max}} years older than you</span>
                <span *ngIf="ageEquivalent.max>=0 && ageEquivalent.min >0"> {{ageEquivalent.min +' and '+ ageEquivalent.max}} years older than you</span>
                <span *ngIf="ageEquivalent.max<=0 && ageEquivalent.min <=0">{{ageEquivalent.max*-1 +' and '+ ageEquivalent.min*-1}} years younger than you</span>
                
              </span>

             
            </div-->

            <div class="info-stat">
              <app-m3-icon [icon]="'target'" class="text-dark"></app-m3-icon>
              <span class="info-stat-label"
                >Your VO<sub>2</sub>max should be greater than...
              </span>
              <span
                class="info-stat-stat text-rag-green" 
                style="min-width: 150px"
                *ngIf="age && sex"
                >{{ getGoodforAgeAndSex(age, sex) }} </span
              ><span class="info-stat-label">ml/kg/min</span>
            </div>

            <div class="mt-4">
              <h3 class="chart-title text-center" *ngIf="currentHeartRate">VO<sub>2</sub>max reference values</h3>
              <div class="table-responsive">
                <app-vo2max-table
                  *ngIf="age && sex && vo2maxs && vo2maxs[0]"
                  [gender]="sex"
                  [vo2max]="vo2maxs[0].vo2max"
                  [age]="age"
                ></app-vo2max-table>
              </div>

              <h3 class="chart-title text-center mt-4" *ngIf="vo2maxs && vo2maxs[0]">Your VO<sub>2</sub>max Entries</h3>
              <div class="table-responsive" *ngIf="vo2maxs && vo2maxs[0]">
                <table class="table">
                  <thead>
                    <tr>
                      <th>DATE</th>
                      <th>VO<sub>2</sub>max</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container  *ngFor="let vo2max of vo2maxs;let index = index;">
                    <tr *ngIf="index<10 || showAllEntries">
                      <td class="text-center">
                        {{ vo2max.date_recorded | dateFormatter : "MMM Do YYYY" }}
                      </td>
                      <td class="text-center">
                        {{ vo2max.vo2max }}ml/kg/min
                      </td>
  
                      <td>
                        <a
                          [swal]="{
                            title: 'Are you sure you want to delete this?',
                            icon: 'warning',
                            showCancelButton: true
                          }"
                          (confirm)="onDeleteVo2max(vo2max)"
                          class="pointer"
                          ><span class="icon-wrap"
                            ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                        ></a>
                      </td>
                    </tr>
                      
                  </ng-container>
                  </tbody>
                </table>
                <div class="text-center">
                  <button
                  *ngIf="!showAllEntries && heartRates.length>10"
                   
                    class="btn btn-primary btn-sm btn-xs"
                    (click)="showAllEntries = true"
                  >
                    Show all
                  </button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      bsModal
      #addModal="bs-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="dialog-static-name"
      show="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title pull-left">Update Heart Rate</h4>
            <button
              type="button"
              class="btn-close close pull-right"
              aria-label="Close"
              (click)="addModal.hide()"
            >
              <span aria-hidden="true" class="visually-hidden">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form
              name="entryForm"
              class="bs-form tracker-form"
              (ngSubmit)="onSubmitEntryForm()"
              #entryForm="ngForm"
            >
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="hr">Beats Per Minute</label>
                    <input
                      type="number"
                      name="hr"
                      class="form-control input-tracker input-tracker-xsm"
                      placeholder=""
                      id="hr"
                      [ngModel]="currentHeartRate"
                      min="10"
                      max="300"
                      autocomplete="off"
                      ng-required=""
                      #hr="ngModel"
                    />
                    <div
                      class="alert alert-danger"
                      *ngIf="hr.touched && hr.invalid"
                    >
                      This must be a number below 300.
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="number">DATE</label>
                    <input
                      name="date"
                      type="text"
                      placeholder="DD/MM/YYYY"
                      class="form-control input-tracker input-tracker-sm"
                      bsDatepicker
                      autocomplete="off"
                      [bsConfig]="{
                        dateInputFormat: 'DD/MM/YYYY',
                        containerClass: 'theme-cv',
                        showWeekNumbers: false
                      }"
                      [(ngModel)]="date"
                      required
                      [bsValue]="date"
                      [maxDate]="date"
                      #dateInput="ngModel"
                    />
                  </div>
                </div>
              </div>
              <div class="tracker-submit-container">
                <button
                  type="submit"
                  role="button"
                  class="btn btn-primary btn-lg btn-white"
                >
                  UPDATE HEART RATE
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
