import { environment } from 'src/environments/environment';
import { CmsService } from './../services/cms.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss']
})
export class LegalComponent implements OnInit {
  cms;
  environment = environment;
  col1 = true;
  col2 = false;
  col3 = false;

  constructor(private cmsService: CmsService) {}

  ngOnInit(): void {
    this.cmsService.fetchByKey(['terms_of_use', 'privacy_policy', 'acceptable_use_policy']).subscribe((responseData) => {
      this.cms = responseData;
    });
  }

}
