import { UserService } from './../services/user.service';
import { AuthService } from './../auth/auth.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-award',
  templateUrl: './award.component.html',
  styleUrls: ['./award.component.scss'],
})
export class AwardComponent implements OnInit {
  currentUser;
  @Input() activeAward;
  @Output() closeAward: EventEmitter<any> = new EventEmitter();
  constructor(
    private authService: AuthService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    const user = this.authService.user.getValue();
    if (user) {
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });
    }
  }

  onCloseAward(event) {
    this.closeAward.emit(event);
  }
}
