<nav class="subnav">
  <h2>
    <span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'map-signs']"></fa-icon
    ></span>
    DECISION MAKER
  </h2>
</nav>
<br />
<div *ngIf="decisionMakers && row">
  <div class="text-center decision-maker-block">
    <p>
      Need help setting your goals?<br />
      Try our Decision Maker tool
    </p>
    <a [routerLink]="['/decision-maker']" class="btn btn-primary"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'map-signs']"></fa-icon
      ></span>
      DECISION MAKER</a
    >
  </div>
  <div class="toggle-header toggle-header-sub" *ngIf="decisionMakers">
    <div class="toggle-header-title">MY DECISIONS</div>
  </div>
  <br />
  <nav class="subnav subnav-tabbed subnav-tabbed-chat">

    <a
      href="#"
      (click)="$event.preventDefault(); setStatus(1)"
      [ngClass]="{ active: status == '1' }"
    >
      ACTIVE
      <span class="notification">{{
        (decisionMakers | filterObjectArray: { status: 1 }).length
      }}</span></a
    >
    <a
      href="#"
      (click)="$event.preventDefault(); setStatus(2)"
      [ngClass]="{ active: status == '2' }"
    >
      PARKED
      <span class="notification">{{
        (decisionMakers | filterObjectArray: { status: 2 }).length
      }}</span></a
    >
    <a
      href="#"
      (click)="$event.preventDefault(); setStatus(3)"
      [ngClass]="{ active: status == '3' }"
    >
      ACHIEVED
      <span class="notification">{{
        (decisionMakers | filterObjectArray: { status: 3 }).length
      }}</span></a
    >
    <a
      href="#"
      (click)="$event.preventDefault(); setStatus(4)"
      [ngClass]="{ active: status == '4' }"
      class="hidden-xs"
    >
      DELETED
      <span class="notification">{{
        (decisionMakers | filterObjectArray: { status: 4 }).length
      }}</span></a
    >
  </nav>
  <div
    *ngIf="(decisionMakers | filterObjectArray: { status: status }).length == 0"
    class="mt-30 text-center"
  >
    No decisions in this category
  </div>
  <div
    class="toggle-container"
    *ngFor="
      let decisionMaker of decisionMakers
        | filterObjectArray: { status: +status }
    "
  >
    <div
      class="toggle-header toggle-header-left"
      (click)="
        row[decisionMaker.decision_maker_id].booleanVal = !row[
          decisionMaker.decision_maker_id
        ].booleanVal
      "
    >
      <a
        href=""
        class="toggle-nav toggle-nav-expand"
        (click)="$event.preventDefault()"
      >
        <span
          class="icon-wrap"
          *ngIf="!row[decisionMaker.decision_maker_id].booleanVal"
          ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
        ></span>
        <span
          class="icon-wrap"
          *ngIf="row[decisionMaker.decision_maker_id].booleanVal"
          ><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon
        ></span>
      </a>
      <div class="toggle-header-title">
        {{ decisionMaker.area | uppercase }}
        <span class="pull-right" style="font-size:12px;">{{
          decisionMaker.review_date | dateFormatter: "DD/MM/yyyy"
        }}</span>
      </div>
    </div>
    <div
      class="toggle-content"
      *ngIf="row[decisionMaker.decision_maker_id].booleanVal"
    >
      <div class="well">
        <h5>FOCUS ON</h5>
        <div>{{ decisionMaker.area }}</div>

        <h5>IMPORTANCE</h5>
        <div>{{ decisionMaker.importance }}/10</div>

        <h5>ACHIEVE THIS BY</h5>
        <div>{{ decisionMaker.action }}</div>

        <h5>POTENTIAL BARRIERS</h5>
        <div>{{ decisionMaker.barriers }}</div>

        <h5>OVERCOME BY</h5>
        <div>{{ decisionMaker.overcome }}</div>

        <div *ngIf="decisionMaker.confidence">
          <h5>CONFIDENCE</h5>
          <div>{{ decisionMaker.confidence }}/10</div>
        </div>

        <h5>REVIEW DATE</h5>
        <div>{{ decisionMaker.review_date | dateFormatter: "DD/MM/yyyy" }}</div>
        <hr />
        <div class="btn-group">
          <button
            *ngIf="decisionMaker.status != 4 && decisionMaker.status != 3"
            [routerLink]="[
              '/decision-maker-edit',
              decisionMaker.decision_maker_id
            ]"
            class="btn btn-primary"
          >
            EDIT
          </button>
          <button
            *ngIf="decisionMaker.status != 1"
            (click)="updateStatus(decisionMaker, 1)"
            class="btn btn-info"
          >
            REACTIVATE
          </button>
          <button
            *ngIf="decisionMaker.status != 3"
            (click)="updateStatus(decisionMaker, 3)"
            class="btn btn-success"
          >
            COMPLETE
          </button>
          <button
            *ngIf="decisionMaker.status != 2"
            (click)="updateStatus(decisionMaker, 2)"
            class="btn btn-warning"
          >
            PARK
          </button>
          <button
            *ngIf="decisionMaker.status != 4"
            (click)="updateStatus(decisionMaker, 4)"
            class="btn btn-danger"
          >
            DELETE
          </button>
          <a
            href="/print-decision/{{ decisionMaker.decision_maker_id }}"
            *ngIf="decisionMaker.status != 4 && decisionMaker.status != 3"
            target="_blank"
            class="btn btn-default"
            >PRINT</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
