<div *ngIf="questionnaire && questions">
  <div
    [innerHTML]="questionnaire.instructions | safeHtml"
    *ngIf="qNum != +questions.length + 1"
  ></div>
  <div>
    <div class="quiz-pager" [hidden]="qNum == +questions.length + 1">
      <div class="quiz-pager-content">
        <div
          *ngFor="let question of questions; let index = index"
          [ngClass]="{ current: qNum == index + 1 }"
        >
          <div [ngClass]="{ correct: question.state === 'answered' }">
            <span class="icon-wrap fa fa-circle"
              ><fa-icon [icon]="['fas', 'circle']"></fa-icon
            ></span>
            <span class="icon-wrap fa fa-check-circle"
              ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
            ></span>
            <span class="icon-wrap fa fa-times-circle"
              ><fa-icon [icon]="['fas', 'times-circle']"></fa-icon
            ></span>
          </div>
        </div>
      </div>
    </div>

    <div *ngFor="let question of questions; let index = index">
      <div *ngIf="qNum == index + 1">
        Question {{ question.sort_order }}
        <hr class="quiz-divider" />
        <div>{{ question.question }}</div>
        <ul class="questionnaire-answers">
          <li
            *ngFor="let answer of question.answers"
            (click)="pickQuestionnaireAnswer(question, answer)"
          >
            {{ answer.answer }}
          </li>
        </ul>
      </div>
    </div>

    <div *ngIf="qNum == +questions.length + 1">
      <div class="continuum-container">
        <div
          class="continuum-arrow-top"
          [ngStyle]="{ left: questionnaireOffset() }"
        ></div>

        <div
          class="continuum-arrow-bottom"
          [ngStyle]="{ left: questionnaireOffset() }"
        ></div>

        <div class="continuum">
          <div class="continuum-divider"></div>
          <div class="continuum-divider"></div>
          <div class="continuum-divider"></div>
          <div class="continuum-divider"></div>
          <div class="continuum-divider"></div>
          <div class="continuum-divider"></div>
          <div class="continuum-divider"></div>
          <div class="continuum-divider"></div>
          <div class="continuum-divider"></div>
          <div class="continuum-divider"></div>
          <div class="continuum-divider"></div>
        </div>
      </div>

      <div class="questionnaire-score">
        <div
          *ngIf="
            checkQuestionnaireFeedback(
              questionnaire.score,
              questionnaire.feedback.feedback1Min,
              questionnaire.feedback.feedback1Max
            )
          "
          [innerHTML]="questionnaire.feedback.feedback1Feedback | safeHtml"
        ></div>
        <div
          *ngIf="
            checkQuestionnaireFeedback(
              questionnaire.score,
              questionnaire.feedback.feedback2Min,
              questionnaire.feedback.feedback2Max
            )
          "
          [innerHTML]="questionnaire.feedback.feedback2Feedback | safeHtml"
        ></div>
        <div
          *ngIf="
            checkQuestionnaireFeedback(
              questionnaire.score,
              questionnaire.feedback.feedback3Min,
              questionnaire.feedback.feedback3Max
            )
          "
          [innerHTML]="questionnaire.feedback.feedback3Feedback | safeHtml"
        ></div>
      </div>
    </div>
  </div>
</div>
