<div [innerHTML]="dragAndDrop.instructions | safeHtml" *ngIf="dragAndDrop.instructions"></div>

<div id="board">
<ul 
dndDropzone
          dndEffectAllowed="move"
          (dndDrop)="onDrop($event, dragItems)"
style="margin:0;padding:0;">
<li dndPlaceholderRef
class="dndPlaceholder"></li>
   <li *ngFor="let item of dragItems;let index = index;"
   [dndDraggable]="item"
            dndEffectAllowed="move"
            (dndStart)="onDragStart($event)"
            (dndCopied)="onDragged(item, dragItems, 'copy')"
            (dndLinked)="onDragged(item, dragItems, 'link')"
            (dndMoved)="onDragged(item, dragItems, 'move')"
            (dndCanceled)="onDragged(item, dragItems, 'none')"
            (dndEnd)="onDragEnd($event)"
            class="as-sortable-item"
   >
      <div data-as-sortable-item-handle>
      <div class="rank-item-no">{{index+1}}</div>
            <div class="rank-item-img" *ngIf="item.image">
                <img [src]="environment.assetUrl + '/asset-thumb/' + item.image + '/100x100'" width="50" height="50">
            </div>
            <div class="rank-item-text">{{item.text}}</div>
            <div class="clear"></div>
      </div>
   </li>
</ul>


</div>

<!--
<ul class="thumbnails">
              <li class="thumbnail vthumbnail" ng-repeat="item in list1" data-drop="true" data-drag="true" ng-model="list1" jqyoui-droppable="{index: {{$index}}}" jqyoui-draggable="{index: {{$index}}, insertInline: true, direction:'jqyouiDirection'}" data-jqyoui-options="{revert: 'invalid'}" data-direction="{{item.jqyouiDirection}}">
                <h1>{{item.title}}</h1>
              </li>
</ul>

    <div class="rank-item-container" ng-repeat=" dragItem in dragItems" data-drop="true" data-drag="true" ng-model="dragItems" jqyoui-droppable="{index: {{$index}}}" jqyoui-draggable="{index: {{$index}}, insertInline: true, direction:'jqyouiDirection'}" data-jqyoui-options="{revert: 'invalid',  hoverClass: 'under-drop-rank', tolerance:'intersect'}" data-direction="{{item.jqyouiDirection}}">
        <div ng-class="{default: 'rank-item', correct:'rank-item rank-item-correct', incorrect:'rank-item rank-item-incorrect'}[dragItem.state]" ng-style="!dragItem.image && {'padding-left':'10px'}">
            <div class="rank-item-no">{{$index+1}}</div>
            <div class="rank-item-img" *ngIf="dragItem.image">
                <img src="/asset-thumb/{{dragItem.image}}/100x100" width="50" height="50">
            </div>
            <div class="rank-item-text">{{dragItem.text}}</div>
            <div class="upDown"><a href="" *ngIf="$index>0" (click)="moveUp(dragItem)"><i class="fa fa-arrow-up"></i></a> <a href="" *ngIf="$index+1 < dragItems.length" (click)="moveDown(dragItem)"><i class="fa fa-arrow-down"></i></a></div>
        </div>
    </div>-->
<div class="clearfix"></div>
<div class="text-center">
    <button *ngIf="dragAndDrop.state=='default'" class="btn btn-primary btn-primary" (click)="submitRanking()"><i class="fa fa-check"></i> CONFIRM</button>
</div>
<div *ngIf="dragAndDrop.state=='answered'">
    <div><span *ngIf="dragAndDrop.correct">You were correct.</span><span *ngIf="!dragAndDrop.correct">You were incorrect.</span></div>
    <div [innerHTML]="dragAndDrop.completed_text | safeHtml" *ngIf="dragAndDrop.completed_text"></div>
</div>
          
                            
                            