<div *ngIf="currentProgramme">
  <div [ngSwitch]="currentProgramme.config.dashboardView">
    <div *ngSwitchCase="1">
      <app-dashboard-team></app-dashboard-team>
    </div>
    <div *ngSwitchCase="2">
      <app-dashboard-individual></app-dashboard-individual>
    </div>
    <div *ngSwitchCase="3">
      <app-dashboard-learning></app-dashboard-learning>
    </div>
  </div>
</div>
