import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as moment from 'moment';
import { Color, Label } from 'ng2-charts';
import { BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivityService } from 'src/app/services/activity.service';
import { AppointmentService } from 'src/app/services/appointment.service';
import { ProgrammeService } from 'src/app/services/programme.service';
import { SleepService } from 'src/app/services/sleep.service';
import { TargetService } from 'src/app/services/target.service';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-m3-sleep',
  templateUrl: './m3-sleep.component.html',
  styleUrls: ['./m3-sleep.component.scss'],
})
export class M3SleepComponent implements OnInit {
  currentUser;
  currentProgramme;
  date = new Date();
  maxDate = new Date();
  allSleeps;
  currentHours = 0;
  currentMins = 0;
  age;
  nextAppointment;

  sleepAverage;
  sleepScore;
  sleepWeekStart;
  showAllEntries = false;

  @ViewChild('entryForm', { static: false }) entryForm: NgForm;
  @ViewChild('sleepScoreForm', { static: false }) sleepScoreForm: NgForm;

  constructor(
    private authService: AuthService,
    private programmeService: ProgrammeService,
    private router: Router,
    private userService: UserService,
    private userSettingsService: UserSettingsService,
    private sleepService: SleepService,
    private targetService: TargetService,
    private modalService: BsModalService,
    private appointmentService: AppointmentService,
    private activityService:ActivityService
  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {});

      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;

        if (this.currentUser.dob) {
          this.age = moment().diff(this.currentUser.dob, 'years', false);
        }
      });

      forkJoin({
        userSettings: this.userSettingsService.fetchAll(),
        allTimeSleep: this.sleepService.fetchAll(),
        nextAppointment: this.appointmentService.getNextAppointment(),
        sleepScore: this.sleepService.getSleepScore(),
      }).subscribe((responseData) => {
        this.allSleeps = responseData.allTimeSleep;
        if (this.allSleeps[0]) {
          let hours = Math.floor(this.allSleeps[0].sleep / 60);
          let minutes = Math.floor(this.allSleeps[0].sleep % 60);

          if (
            moment(this.allSleeps[0].date_recorded).dayOfYear() ==
            moment().dayOfYear()
          ) {
            this.currentMins = minutes;
            this.currentHours = hours;
          }
        }

        if (this.allSleeps.length > 0) {
          if (
            moment(this.allSleeps[0].date_recorded) >
            moment().subtract(1, 'week')
          ) {
            this.sleepWeekStart = moment()
              .subtract(6, 'day')
              .format('Do MMM');
          } else {
            this.sleepWeekStart = moment(this.allSleeps[0].date_recorded)
              .subtract(6, 'day')
              .format('Do MMM');
          }
        }

        this.sleepAverage = this.sleepService.getSleepAverage(this.allSleeps);
        this.nextAppointment = responseData.nextAppointment;

        this.sleepScore = responseData.sleepScore;
      });
    }
  }

  onDelete(sleep) {
    this.sleepService.delete(sleep.user_sleep_id).subscribe(
      (responseData) => {
        this.sleepService.clearCache();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/sleep']));
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onSubmitEntryForm() {
    // save the steps
    const totalMins =
      +this.entryForm.value.hours * 60 + +this.entryForm.value.mins;
    this.sleepService
      .create('' + totalMins, this.entryForm.value.date)
      .subscribe((responseData) => {
        // refresh the route..
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/sleep']));

        Swal.fire({
          icon: 'success',
          title: 'Entry Updated',
          text: 'Your sleep time has been added',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      });
  }

  getAgeSleep(age) {
    if (age < 65) {
      return '7-9';
    } else {
      return '7-8';
    }
  }

  getSleepRagForAge(sleep: any, age: number) {
    return this.sleepService.getRagForAge(sleep, age);
  }

  onSubmitSleepScoreForm() {
    this.sleepService
      .setSleepScore(
        this.sleepScoreForm.value.auto_meq,
        this.sleepScoreForm.value.chronotype
      )
      .subscribe((responseData) => {
        // refresh the route..
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/sleep']));

        Swal.fire({
          icon: 'success',
          title: 'Score Updated',
          text: 'Your sleep score has been updated',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      });
  }

  isHittingTarget() {
    let isHitting = false;
    if (this.sleepAverage >= 7 * 60 && this.sleepAverage <= 9 * 60) {
      isHitting = true;
    }
    return isHitting;
  }

  getTargetOffset(){
    if(this.sleepAverage<7*60){

      return Math.round(7*60 - this.sleepAverage) + ' minutes under'; 
    }
    else{
      return  Math.round(9*60 - this.sleepAverage)*-1 + ' minutes over'; 
    }

  }

  getTargetOffsetMins(){
    if(this.sleepAverage<7*60){

      return Math.round(7*60 - this.sleepAverage); 
    }
    else{
      return  Math.round(9*60 - this.sleepAverage)*-1; 
    }
  }



  syncSteps(force) {
    force = typeof force === 'undefined' ? '0' : force;
    this.activityService.getActivity(force).subscribe((responseData) => {
      this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigate(['/m3', 'sleep']));
    });
  }
   
}
