import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as moment from 'moment';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { UserShortFesService } from 'src/app/services/user-short-fes.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-m3-user-short-fes-chart',
  templateUrl: './m3-user-short-fes-chart.component.html',
  styleUrls: ['./m3-user-short-fes-chart.component.scss']
})
export class M3UserShortFesChartComponent implements OnInit {

  @Input() shortFess:any;
  // Line chart
  public lineChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            beginAtZero: true,
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
            suggestedMax: 5,
          },
          gridLines: {
            display: true,
            lineWidth: 1,
            color: '#dddddd',
          },
          scaleLabel:{
            display:true,
            labelString: 'Short Fess',
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          }
          
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            lineWidth: 2,
            color: '#dddddd',
          },
          ticks: {
            fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
            source: 'data',
            maxRotation: 45,
            minRotation: 45,
          },
          scaleLabel: {
            display: true, fontFamily: environment.chartDefaults.fontFamily,
            fontSize: environment.chartDefaults.fontSize,
            fontColor: environment.chartDefaults.fontColor,
            fontStyle: environment.chartDefaults.fontStyle,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
      shaderArea:{

      }
    },
  };
  public lineChartLabels: Label[] = [];
  public lineChartType: ChartType = 'line';
  public lineChartLegend = true;

  public pointBackgroundColors = [];
  public pointBorderColors = [];
  public lineChartData: ChartDataSets[] = [
     {
      data: [],
      pointBackgroundColor: this.pointBackgroundColors,
      pointBorderColor: this.pointBorderColors,
      label: 'Short FES',
      borderColor: '#22B4FC',
      borderWidth: 3,
      fill: false,
      pointRadius: 4,
    },
  ];
  public lineChartColors: Color[] = [
    { backgroundColor: '#999999' },
    { backgroundColor: '#22B4FC' },
  ];
  
  constructor(private userShortFesService: UserShortFesService) {}

  ngOnInit(): void {

    let range = +this.userShortFesService.getRangeforColor('green').replace(/^\D+/g, '');
   
    this.lineChartOptions.plugins.shaderArea.rangeStart = range;

    BaseChartDirective.registerPlugin({
      id: 'shaderArea',
      beforeDraw(chart, easing, options) {
        const ctx = chart.ctx;
        const chartArea = chart.chartArea;
        var scale = this.scale;

        ctx.save();
        ctx.fillStyle = 'rgba(147, 208, 147 ,0.4)';
        let yAxe = chart['scales'][chart.config.options.scales.yAxes[0].id];
        let rangesStart = yAxe.getPixelForValue(options.rangeStart);
        let rangesEnd = yAxe.getPixelForValue(yAxe.min);
        ctx.fillRect(
          chartArea.left,
          rangesStart,
          chartArea.right - chartArea.left,
          rangesEnd - rangesStart
        );
        ctx.restore();
      },
    });
    // end

    this.setChartData();
  }

  setChartData() {
    this.lineChartData[0].data = [];
    //this.lineChartData[1].data = [];
    // setup chart data
    this.shortFess.forEach((vo2value, vo2key) => {
      this.lineChartLabels.push(
        moment(vo2value.date_recorded).format('DD-MM-YY')
      );

      this.lineChartData[0].data.push(vo2value.short_fes);
      this.pointBackgroundColors.push(
        environment.ragColors[
          this.userShortFesService.getRag(vo2value.short_fes)
        ]
      );
      this.pointBorderColors.push(
        environment.ragColors[
          this.userShortFesService.getRag(vo2value.short_fes)
        ]
      );
    });
  }

}
