import { environment } from './../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService, AuthResponseData } from './auth.service';
import { ConfigService } from '../services/config.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
})
export class AuthComponent implements OnInit {
  isLoading = false;
  error: string = null;
  testSite = false;
  environment = environment;
  msgCode;
  status;
  attempts = 0;

  captchaPass = true;
  captcha_status = '';
  credentials = {
    'g-recaptcha-response': null,
  };
  bg:any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.configService.fetchAll().subscribe((configs:any)=>{
      if(configs.login_bg){
        this.bg = environment.assetUrl + '/assets/' + configs.login_bg;
      }else{
        this.bg = './assets/img/bg-login-2023.jpg';
      }
    });
    // get any status if they exist
    this.route.params.subscribe((params) => {
      this.status = this.route.snapshot.params.status;
    });


    // are we logged in?
    this.testSite = environment.testSite;
    const user = this.authService.user.getValue();
    this.isLoading = this.authService.fetching.getValue();
    // try this..
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
    if (user) {
      if (this.authService.redirect) {
        this.router.navigateByUrl(this.authService.redirect);
      } else {
        this.router.navigate(['/dashboard']);
      }
    }
  }
  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }

    if (this.attempts > 4) {
      if (this.credentials['g-recaptcha-response'] == null) {
        this.captcha_status = 'Please verify captcha';
        this.captchaPass = false;
      } else {
        this.captcha_status = 'Captcha verified';
      }
    }
    if (this.captchaPass) {
      this.captcha_status = '';
      const email = form.value.email;
      const password = form.value.password;
      const rememberMe = form.value.rememberMe;
      let authObs: Observable<AuthResponseData>;

      this.isLoading = true;

      authObs = this.authService.login(email, password, rememberMe);

      authObs.subscribe(
        (resData) => {
          this.isLoading = false;
          // do they need to evaluate?
          this.router.navigate(['/dashboard']);
        },
        (errorMessage) => {
          this.error = errorMessage;
          this.isLoading = false;
          this.attempts++;
        }
      );

      form.reset();
    }
  }

  captchaResolved(event){
    // console.log(event);
  }

  captchaError(event){
   // console.log(event);
  }
}
