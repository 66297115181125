import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {
  Chart,
  ChartConfiguration,
  ChartDataSets,
  ChartOptions,
  ChartType,
} from 'chart.js';
import * as moment from 'moment';
import { Color, Label } from 'ng2-charts';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivityService } from 'src/app/services/activity.service';
import { HeartRateService } from 'src/app/services/heart-rate.service';
import { NonSteppingService } from 'src/app/services/non-stepping.service';
import { ProgrammeService } from 'src/app/services/programme.service';
import { TargetService } from 'src/app/services/target.service';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-m3-non-stepping',
  templateUrl: './m3-non-stepping.component.html',
  styleUrls: ['./m3-non-stepping.component.scss'],
})
export class M3NonSteppingComponent implements OnInit {
  currentUser;
  currentProgramme;
  date = new Date();
  today = new Date();
  activitiesToSave = [];
  @ViewChild('entryForm', { static: false }) entryForm: NgForm;
  dateRecorded = new Date();
  nonSteppings;
  weeklyTotal = 0;
  nonSteppingsWeek: any = [];
  activitiesDone;
  activitiesByWeek: any;
  nonSteppingWeeklyTotal: any = 0;
  activitiesByWeekTotals: any = [];

  averageSteps = 0;
  averageNonSteppingSteps = 0;

  allTimeSteps;

  activities = [
    { id: 'swimming', label: 'Swimming', labelShort:'Swimming' },
    { id: 'cycling', label: 'Cycling', labelShort: 'Cycling' },
    { id: 'stationaryBike', label: 'Stationary bike', labelShort: 'Stationary bike' },
    { id: 'yoga', label: 'Yoga' , labelShort: 'Yoga'},
    { id: 'pilates', label: 'Pilates' , labelShort: 'Pilates'},
    {
      id: 'waterSports',
      label:
        'Water sports (e.g. Sailing, wind-surfing, stand up paddleboarding)'
        ,
        labelShort:
        'Water sports'
    },
    { id: 'rowing', label: 'Rowing / canoeing', labelShort: 'Rowing / canoeing'  },
    { id: 'armErgometry', label: 'Arm ergometry', labelShort: 'Arm ergometry'  },
    { id: 'seatedExercise', label: 'Seated aerobic exercise', labelShort: 'Seated exercise' },
    { id: 'aquaticExercise', label: 'Aquatic exercise (aerobics / Zumba)', labelShort: 'Aquatic exercise' },
    { id: 'horseRiding', label: 'Horse riding', labelShort: 'Horse riding'  },
    { id: 'other', label: 'Other aerobic exercise', labelShort: 'Other aerobic exercise'  },
    { id: 'activeMinutes', label: 'Active Minutes', labelShort: 'Active Minutes'  },

    { id: 'seatedStrengthening', label: 'Seated strengthening exercise', labelShort: 'Seated strengthening exercise'  },
    { id: 'seatedCircuit', label: 'Seated circuit (both aerobic and strengthening exercises) ', labelShort: 'Seated circuit'  },
  ];

  showAllEntries = false;
  briskMinsThisWeek = 0;

  @ViewChild('addModal') public addModal: ModalDirective;

  constructor(
    private authService: AuthService,
    private programmeService: ProgrammeService,
    private router: Router,
    private userService: UserService,
    private userSettingsService: UserSettingsService,
    private activityService: ActivityService,
    private targetService: TargetService,
    private nonSteppingService: NonSteppingService
  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {});

      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });

      forkJoin({
        userSettings: this.userSettingsService.fetchAll(),
        nonSteppings: this.nonSteppingService.fetchAll(),
        allTimeSteps: this.activityService.getActivityBetween(
          moment().subtract(10, 'years').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ),
      }).subscribe((responseData) => {
        this.nonSteppings = responseData.nonSteppings;
        this.allTimeSteps = responseData.allTimeSteps;
        this.activitiesByWeek = this.nonSteppingService.getActivitiesByWeek(
          this.nonSteppings,
          this.allTimeSteps
        );
        this.activitiesByWeekTotals =
          this.nonSteppingService.getActivitiesByWeekTotals(
            this.activitiesByWeek
          );

        this.allTimeSteps.forEach((activity:any)=>{
          if (
            moment(activity.activity_date).format('YYYY-MM-DD') >=
            moment().startOf('isoWeek').format('YYYY-MM-DD') && activity.brisk_step_minutes
          ) {
            this.briskMinsThisWeek += +activity.brisk_step_minutes;
          }
        });

        if (!this.nonSteppings[0]) {
          this.addModal.show();
        }
          //console.log(moment().subtract('days', 6).format('YYYY-MM-DD'));
          let activtitiesDone = [];
          this.nonSteppings.forEach((nonStepping) => {
            activtitiesDone.push(nonStepping.activity);

            // is it in the last 7 days?
            if (
              moment(nonStepping.date_recorded).format('YYYY-MM-DD') >=
              moment().startOf('isoWeek').format('YYYY-MM-DD')
            ) {
              this.nonSteppingWeeklyTotal += +nonStepping.minutes;
              this.nonSteppingsWeek.push(nonStepping);
            }
          });
          
          this.activitiesDone = activtitiesDone;
          this.activitiesDone.push('activeMinutes');
          
          this.averageSteps = this.activityService.getStepAverage(
            this.allTimeSteps
          );
          this.averageNonSteppingSteps = this.nonSteppingService.getStepAverage(
            this.nonSteppings
          );
        
      });
    }
  }

  onSubmitEntryForm() {
    this.activitiesToSave.push({
      activity: this.entryForm.value.activity,
      minutes: this.entryForm.value.minutes,
      intensity: this.entryForm.value.intensity,
    });

    // loop and save

    this.entryForm.control.patchValue({
      activity: '',
      minutes: '',
      intensity: '',
    });

    /*
    // save the steps
    this.activityService
      .saveSteps(this.entryForm.value.steps, this.entryForm.value.date)
      .subscribe((responseData) => {
        // refresh the route..
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/walking']));

        Swal.fire({
          icon: 'success',
          title: 'Entry Added',
          text: 'Your steps have been added',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      });*/
  }

  onSaveEntries() {
    let saved = 0;
    this.activitiesToSave.forEach((entry) => {
      this.nonSteppingService
        .create(
          entry.activity,
          entry.minutes,
          entry.intensity,
          moment(this.dateRecorded).format('YYYY-MM-DD')
        )
        .subscribe((responseData) => {
          saved++;
          if (saved == this.activitiesToSave.length) {
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => this.router.navigate(['/m3/non-stepping']));
          }
        });
    });
  }

  getActivityLabel(activityId, short = false) {
    let label = '';
    this.activities.forEach((activity) => {
      if (activity.id == activityId) {
        if(short==true){
        label = activity.labelShort;
        }
        else{
          label = activity.label;
        }
      }
    });
    return label;
  }

  getIntensityLabel(intensity) {
    let label = '';
    if (intensity == 'l') {
      label = 'Light';
    }
    if (intensity == 'm') {
      label = 'Moderate';
    }
    if (intensity == 'v') {
      label = 'Vigorous';
    }
    return label;
  }

  onDelete(activity) {
    this.nonSteppingService.delete(activity.user_non_stepping_id).subscribe(
      (responseData) => {
        const index = this.nonSteppings.indexOf(activity);
        this.nonSteppings.splice(index, 1);
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/non-stepping']));
      },
      (error) => {
        console.log(error);
      }
    );
  }

  nonSteppingStepsRag(total:number){
    let rag = '';
    if(total>= 7500){
      rag = 'green';
    }
    else if(total>=5000){
      rag = 'amber';
    }
    else{
      rag = 'red';
    }
    return rag;
  }
}
