import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { environment } from 'src/environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2/src/sweetalert2.js';
import * as moment from 'moment';
import { NgForm, Validators } from '@angular/forms';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss'],
})
export class PersonalDetailsComponent implements OnInit {
  isFetching;
  currentUser;
  currentProgramme;
  environment = environment;
  stepSubmitted;
  regForm;
  regDetails;
  unitMode = 'metric';
  heightFeet;
  heightInches;
  languageInput;

  noSpacesPattern = /.*[^ ].*/;
  minDate = moment().subtract(100, 'years').toDate();
  maxDate = moment().subtract(13, 'years').toDate();

  @ViewChild('profileForm', { static: false }) profileForm: NgForm;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService
  ) {
  }

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
        if (this.currentUser) {
          if(!this.currentUser.mobile){
            this.currentUser.mobile = '';
          }
          this.languageInput = this.currentUser.language;
          if (this.currentUser.language !== 'English')  {
            this.currentUser.language_other = this.currentUser.language;
            // this.currentUser.language = 'other';
            this.languageInput = 'other';
          }

          this.currentUser.dob = moment(this.currentUser.dob).toDate();
        }
      });
    }

    this.isFetching = true;

    this.programmeService.currentProgramme.subscribe((programmeData) => {
      this.currentProgramme = programmeData;
      if (this.currentProgramme) {
      }
    });
  }

  onSubmit() {
    if (this.environment.country == 'AUS' && !this.currentUser.buddy) {
      if (
        this.currentUser.birth_country === 'other' &&
        !this.currentUser.birth_country_other
      ) {
        this.profileForm.controls['birth_country'].clearValidators();
      }
      if (
        this.currentUser.birth_country === 'other' &&
        this.currentUser.birth_country_other
      ) {
        this.profileForm.controls['birth_country'].setValidators([
          Validators.required,
        ]);
      }

      if (
        this.currentUser.language === 'other' &&
        !this.currentUser.language_other
      ) {
        this.profileForm.controls['language'].clearValidators();
      }
      if (
        this.currentUser.language === 'other' &&
        this.currentUser.language_other
      ) {
        this.profileForm.controls['language'].setValidators([
          Validators.required,
        ]);
      }
    }

    this.authService
      .checkScreenName(this.profileForm.value.screen_name)
      .subscribe(
        (responseData) => {
          if (responseData.status === 'SUCCESS') {
            //$scope.regForm.screen_name.$setValidity('nametaken', true);

            if (!this.profileForm.valid) {
              // const invalid = this.findInvalidControls(this.profileForm);

              Swal.fire({
                title: "We notice you've missed something",
                text:
                  'Please check the form for information that has been missed',
                icon: 'error',
              });
            } else {
              let language = this.profileForm.value.language;
              let birth_country = this.profileForm.value.birth_country;
              if (this.profileForm.value.language_other) {
                language = this.profileForm.value.language_other;
              }
              if (this.profileForm.value.birth_country_other) {
                birth_country = this.profileForm.value.birth_country_other;
              }

              this.authService
                .updatePersonalDetails(
                  this.profileForm.value.first_name,
                  this.profileForm.value.last_name,
                  this.profileForm.value.mobile,
                  moment(this.profileForm.value.dob).format(
                    'YYYY-MM-DD'
                  ),
                  this.profileForm.value.ethnicity_id,
                  this.profileForm.value.post_code,
                  this.profileForm.value.gender,
                  this.profileForm.value.screen_name,
                  this.profileForm.value.height,
                  this.profileForm.value.origin,
                  birth_country,
                  language
                )
                .subscribe(
                  (userData) => {
                    this.userService.reset();
                    this.userService.get().subscribe((newUserData) => {
                      this.currentUser = newUserData;
                    });
                    Swal.fire({
                      title: 'Profile updated',
                      text: 'Your data was successfully updated',
                      icon: 'success',
                      timer: 1000,
                      showConfirmButton: false,
                    });
                  },
                  (error) => {}
                );
            }
          } else {
            Swal.fire({
              icon: 'warning',
              title: "We notice you've missed something",
              text: 'There was an error with your screen name.',
            });
            this.profileForm.controls['screen_name'].setErrors({
              nametaken: true,
            });
          }
        },
        (error) => {
          // screen_name.errors.nametaken
          Swal.fire({
            icon: 'warning',
            title: "We notice you've missed something",
            text: 'There was an error with your screen name.',
          });
        }
      );
  }

  updateHeight() {
    this.currentUser.height =
      +this.heightFeet * 12 * 2.54 + +this.heightInches * 2.54;
  }

  public findInvalidControls(form) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  getPostcodePattern() {
    if (environment.country === 'UK') {
      return '^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$';
    } else if (environment.country === 'AUS') {
      return '^[0-9]{4}$';
    }
  }

  getMinLength() {
    if (environment.country === 'UK') {
      return 5;
    } else if (environment.country === 'AUS') {
      return 4;
    }
  }
  getMaxLength() {
    if (environment.country === 'UK') {
      return 8;
    } else if (environment.country === 'AUS') {
      return 4;
    }
  }
}
