<!-- Video -->
<div class="videogular-container">
  <!--videogular
    vg-theme="config.theme"
    vg-native-fullscreen="true"
    vg-native-controls="true"
  >
    <vg-media vg-src="config.sources" vg-tracks="config.tracks"> </vg-media>
    <vg-controls vg-autohide="true" vg-autohide-time="1000">
                    <vg-play-pause-button></vg-play-pause-button>
                   
                    <vg-scrub-bar>
                            <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                    </vg-scrub-bar>
                    <vg-time-display>{{ timeLeft | dateFormatter:'mm:ss' }}</vg-time-display>
                    <vg-volume>
                            <vg-mute-button></vg-mute-button>
                            <vg-volume-bar></vg-volume-bar>
                    </vg-volume>
                    <vg-fullscreen-button></vg-fullscreen-button>
            </vg-controls>

    <vg-overlay-play></vg-overlay-play>
    <vg-poster vg-url="config.plugins.poster"></vg-poster>
  </videogular-->


  <video  [attr.poster]="poster" controls preload width="100%"  webkit-playsinline playsinline>
    <source *ngIf="video" [src]="video" type="video/mp4">
    <track *ngIf="captions" [src]="captions" kind="subtitles" srclang="en" 
           label="English" default>
   </video>

  <br />

  <div *ngIf="transcript">
 <button class="btn btn-xs btn-primary" (click)="onToggleShowTranscript()" *ngIf="environment.country === 'AUS'">SHOW TRANSCRIPT</button>
  <div 
  class="transcript" [innerHTML]="transcript | safeHtml" *ngIf="showTranscript"></div>
  </div>
 

</div>
