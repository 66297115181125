import { NgForm } from '@angular/forms';
import { DateHelperService } from './../services/date-helper.service';
import { TargetService } from './../services/target.service';
import { ActivityService } from './../services/activity.service';
import { UserSettingsService } from './../services/user-settings.service';
import { ProgrammeService } from './../services/programme.service';
import { UserService } from './../services/user.service';
import { AuthService } from './../auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from './../../environments/environment';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-target',
  templateUrl: './target.component.html',
  styleUrls: ['./target.component.scss'],
})
export class TargetComponent implements OnInit {
  target = 10000;
  originalTarget;
  currentTargetObj;
  myActivity;
  startDate;
  endDate;
  lastWeek;
  currentProgramme;
  currentUser;
  isFetching;
  errors;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private userSettingsService: UserSettingsService,
    private activityService: ActivityService,
    private targetService: TargetService,
    private dateHelperService: DateHelperService,
    private router:Router
  ) {}

  ngOnInit(): void {
    const now = moment();

    // what is the day today
    this.startDate = moment(now).subtract(1, 'weeks');
    this.lastWeek = this.dateHelperService.getWeekDayFor(now);
    this.startDate = moment(this.startDate).format('YYYY-MM-DD');
    this.endDate = moment(now).subtract(1, 'days').format('YYYY-MM-DD');

    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if(this.currentProgramme.config.exerciseProgramme == 1){
          this.router.navigate(['/target-ep']);
        }
        else{



        this.isFetching = true;
        forkJoin({
          userSettings: this.userSettingsService.fetchAll(),
          activity: this.activityService.getActivity(0),
          target: this.targetService.getTarget('step'),
          activitySummary: this.activityService.getActivitySummary(
            this.startDate,
            this.endDate
          ),
        }).subscribe(
          (responseData) => {
            this.isFetching = false;
  
            if (responseData.target) {
              this.currentTargetObj = responseData.target.target;
              this.originalTarget = responseData.target.target;
              this.target = responseData.target.target;
            } else {
              this.target = 10000;
              this.originalTarget = 0;
            }
            // this.userSettings = responseData.userSettings;
            this.myActivity = responseData.activitySummary[0];
  
            // activity
            // get team
            if (this.myActivity) {
              if (this.myActivity.goal_count == null) {
                this.myActivity.goal_count = 0;
              }
  
              if (
                this.target * 1.5 < +this.myActivity.step_avg ||
                this.target * 0.5 > +this.myActivity.step_avg
              ) {
                // check the target
                Swal.fire({
                  title: 'Your Step Target',
                  text:
                    ' Is ' +
                    this.target +
                    ' steps a realistic target for you?  Your average steps are ' +
                    Math.round(this.myActivity.step_avg) +
                    '.',
                  icon: 'info',
                  timer: 2000,
                  showConfirmButton: false,
                });
              }
            }
  
            // end
  
            // end
          },
          (error) => {
            this.isFetching = false;
            this.errors.push(error.message);
          }
        );


        }
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });

    }
  }

  getTargetFormatted(num) {
    const size = 5;
    return ('00000' + num).substr(-size);
  }

  targetUp() {
    this.target = this.target * 1 + 100;
  }

  targetDown() {
    if (this.target > 100) {
      this.target = this.target - 100;
    }
  }

  submitTarget(target) {
    this.targetService.create(target, 'step', 'steps').subscribe(
      (responseData) => {
        if (!this.myActivity) {
          Swal.fire({
            title: 'Target Set',
            text: 'Your new target has been set, now go and get moving!',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
          });
        } else {
          if (
            this.target * 1.5 < +this.myActivity.step_avg ||
            this.target * 0.5 > +this.myActivity.step_avg
          ) {
            // check the target
            Swal.fire({
              title: 'Your Target',
              text:
                ' Is ' +
                this.target +
                ' a realistic target for you?  Your average steps are ' +
                Math.round(this.myActivity.step_avg) +
                '.',
              icon: 'info',
              showConfirmButton: true,
            });
          } else {
            Swal.fire({
              title: 'Target Set',
              text: 'Your new target has been set, now go and get moving!',
              icon: 'success',
              timer: 2000,
              showConfirmButton: false,
            });
          }
        }

        // new target set, do we need to reset hit goal?

        this.activityService
          .getActivityBetween(
            moment().format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD')
          )
          .subscribe((activityResponse) => {
            const activity = activityResponse[0];
            if (activity) {
              if (activity.hit_goal == 1 && +this.target > activity.steps) {
                this.activityService
                  .update(activity.user_activity_id, activity.steps, 0)
                  .subscribe((hitGoalResponse) => {});
              }
            }
          });
      },
      (error) => {}
    );
  }
}
