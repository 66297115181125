import { environment } from './../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private http: HttpClient) {}

  fetchResults(query, programme_id) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('programme_id', '' + programme_id);
    searchParams = searchParams.append('query', query);

    return this.http
      .get<any>(environment.apiUrl + '/search', {
        responseType: 'json',
        params: searchParams,
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.search;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
}
