<div class="assessment-container">
    <div class="container-lg">
      <nav class="subnav" *ngIf="cms">
        <h2>
          <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'info']"></fa-icon
          ></span>
          Welcome
        </h2>
      </nav>
      <div *ngIf="isFetching">
        <app-loading-spinner [inline]="1"></app-loading-spinner>
      </div>
  
  
  

  
      <div class="container container-pad" *ngIf="videoComplete">
        <div class="row dashboard-row">
          <div class="col-lg-6 col-md-6">
            <div class="no-tracker bg-accent masthead-db" style="min-height: 360px">
              <span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'exclamation-circle']"></fa-icon
              ></span>
              <h3>PLEASE SETUP YOUR<br />FITNESS TRACKER</h3>
              <p class="text-left">
                Setting up a device will ensure that the programme tailors'
                physical activity specifically suited to you and your ability.
              </p>
              <p class="text-left">
                We are aiming to progress you to achieve the minimum recommended
                steps for health. As little as 10 more minutes of walking per day
                can increase your life expectancy by ~4 years and as little as 500
                steps per day can decreased your risk of cardiovascular disease
                and even death
              </p>
              <p class="text-left">
                If you do not have a device, there is also an option to enter your
                steps manually
              </p>
              <a
                role="button"
                [routerLink]="['/setup-device']"
                class="btn btn-primary mr-1"
              >
                <span class="icon-wrap"
                  ><fa-icon [icon]="['fas', 'watch-fitness']"></fa-icon
                ></span>
                SETUP DEVICE</a
              >
              <a
                role="button"
                [routerLink]="['/setup-device']"
                class="btn btn-primary ml-1"
              >
                <span class="icon-wrap"
                  ><fa-icon [icon]="['fas', 'hand-pointer']"></fa-icon
                ></span>
                MANUAL</a
              >
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div
              style="
                height: 360px;
                background-image: url('./assets/img/bg-tracker.jpg');
                background-size: cover;
                position: relative;
              "
            ></div>
          </div>
        </div>
        <!--
        <hr class="divider" />
        <div class="text-center">
          <a (click)="goNext()" class="btn btn-primary"
            >CONTINUE
            <span class="icon-wrap"
              ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span
          ></a>
        </div> -->
      </div>
    </div>
  </div>
  