import { DeviceService } from './../services/device.service';
import { ProgrammeService } from './../services/programme.service';
import { UserService } from './../services/user.service';
import { AuthService } from './../auth/auth.service';
import { ActivityService } from './../services/activity.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ActivityData, CapacitorHealthkit, QueryOutput, SampleNames } from '@perfood/capacitor-healthkit';
const READ_PERMISSIONS = [
  'calories',
  'stairs',
  'activity',
  'steps',
  'distance',
  'duration',
  'weight',
  'heartRate'
];

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss'],
})
export class DeviceComponent implements OnInit {
  currentUser;
  currentProgramme;
  isFetching;
  errorCode;
  responseData;
  environment = environment;
  endDate = new Date();
  startDate = new Date(this.endDate.getTime() 
      - 7 * 24 * 60 * 60 * 1000);
  appleData:any;
  appleError:any;

  constructor(
    private activityService: ActivityService,
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private deviceService: DeviceService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });

      this.deviceService.checkConnection().subscribe(
        (responseData) => {
          this.responseData = responseData;
        },
        (error) => {
          console.log('an error occured');
          console.log(error);
          this.errorCode = error;
        }
      );
    }

    // any error code returned?
    this.route.params.subscribe((params) => {
      this.errorCode = this.route.snapshot.params.errorCode;
    });
  }

  disconnect() {
    this.isFetching = true;
    this.deviceService.disconnect().subscribe(
      (responseData) => {
        this.userService.reset();
        this.userService.get().subscribe((userData) => {
          this.currentUser = userData;
          this.deviceService.checkConnection().subscribe(
            (deviceResponseData) => {
              this.responseData = deviceResponseData;
              this.isFetching = false;
            },
            (error) => {
              console.log(error);
            }
          );
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  setManual() {
    this.deviceService.setManual().subscribe(
      (responseData) => {
        this.userService.reset();
        this.userService.get().subscribe((userData) => {
          this.currentUser = userData;
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public async onApple(): Promise<void> {
    try {
      await CapacitorHealthkit.requestAuthorization({
        all: [''],
        read: READ_PERMISSIONS,
        write: [''],
      });
    } catch (error) {
      console.error('[HealthKitService] Error getting Authorization:', error);
    }
  }

   async getActivityData(
    startDate: Date,
    endDate: Date = new Date(),
  ): Promise<QueryOutput<ActivityData>> | undefined {
    try {
      const queryOptions = {
        sampleName: SampleNames.STEP_COUNT,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        limit: 0,
      };

      this.appleData =  await CapacitorHealthkit.queryHKitSampleType<ActivityData>(queryOptions);
    } catch (error) {
      this.appleError = error;
      console.error(error);

      return undefined;
    }
  }

  async getHrData(
    startDate: Date,
    endDate: Date = new Date(),
  ): Promise<QueryOutput<ActivityData>> | undefined {
    try {
      const queryOptions = {
        sampleName: SampleNames.HEART_RATE,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        limit: 0,
      };

      this.appleData =  await CapacitorHealthkit.queryHKitSampleType<ActivityData>(queryOptions);
    } catch (error) {
      this.appleError = error;
      console.error(error);

      return undefined;
    }
  }
}
