import { DecisionMakerService } from './../../services/decision-maker.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/src/sweetalert2.js';

@Component({
  selector: 'app-decision-maker-saved',
  templateUrl: './decision-maker-saved.component.html',
  styleUrls: ['./decision-maker-saved.component.scss'],
})
export class DecisionMakerSavedComponent implements OnInit {
  decisionMakers = null;
  status = '1';
  row = {};

  constructor(private decisionMakerService: DecisionMakerService) {}

  ngOnInit(): void {
    this.decisionMakerService.fetchAll().subscribe((responseData) => {
      this.decisionMakers = responseData;
      this.decisionMakers.forEach((dm, index) => {
        this.row[dm.decision_maker_id] = {};
        this.row[dm.decision_maker_id] = { booleanVal: false };
      });
    });
  }

  setStatus(status) {
    this.status = '' + status;
  }

  orderByDate(item) {
    const date = new Date(item.review_date);
    return date;
  }

  updateStatus(decisionMaker, newStatus) {
    let msg = '';
    let msgAfter = '';
    switch (newStatus) {
      case 1:
        msg = 'Would you like to reactivate this decision';
        msgAfter = 'Your saved decision was reactivated';
        break;
      case 2:
      default:
        msg = 'Would you like to park this decision';
        msgAfter = 'Your saved decision was parked';
        break;
      case 3:
        msg = 'Would you like to complete this decision';
        msgAfter = 'Your saved decision was completed';
        break;
      case 4:
        msg = 'Would you like to delete this decision';
        msgAfter = 'Your saved decision was deleted';
        break;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if (result.isConfirmed) {
        this.decisionMakerService
          .update(
            decisionMaker.decision_maker_id,
            decisionMaker.focus,
            decisionMaker.importance,
            decisionMaker.action,
            decisionMaker.barriers,
            decisionMaker.overcome,
            decisionMaker.weeks,
            decisionMaker.review_date,
            decisionMaker.confidence,
            newStatus
          )
          .subscribe((response) => {
            Swal.fire({
              title: 'Entry Updated',
              text: msgAfter,
              icon: 'success',
              timer: 1000,
              showConfirmButton: false,
            });
            this.decisionMakerService.fetchAll().subscribe((responseData) => {
              this.decisionMakers = responseData;
              this.decisionMakers.forEach((dm, index) => {
                this.row[dm.decision_maker_id] = {};
                this.row[dm.decision_maker_id] = { booleanVal: false };
              });
            });

          });
      }
    });
  }
}
