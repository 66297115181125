
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { forkJoin, of } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { MediaService } from 'src/app/services/media.service';

@Component({
  selector: 'app-step-video',
  templateUrl: './step-video.component.html',
  styleUrls: ['./step-video.component.scss'],
})
export class StepVideoComponent implements OnInit {
  showTranscript;
  @Input() content;
  poster;
  captions;
  transcript;
  video;
  environment = environment;

  constructor(private mediaService: MediaService, private router: Router) {}

  ngOnInit(): void {

    if(environment.country === 'AUS'){
      this.showTranscript = false;
    }
    else{
      this.showTranscript = true;
    }

    this.video = null;
    this.poster = null;
    this.captions = null;
    this.transcript = null;
    const parsedContent = JSON.parse(this.content);
    if (parsedContent.transcript) {
      this.transcript = parsedContent.transcript;
    }

    const observables = {
      video: null as any,
      poster: null as any,
      captions: null as any,
    };

    if (parsedContent.video) {
      observables.video = this.mediaService.fetch(parsedContent.video);
    } else {
      delete observables.video;
    }
    if (parsedContent.placeholder) {
      observables.poster = this.mediaService.fetch(parsedContent.placeholder);
    } else {
      delete observables.poster;
    }
    if (parsedContent.captions) {
      observables.captions = this.mediaService.fetch(parsedContent.captions);
    } else {
      delete observables.captions;
    }
    // console.log(parsedContent);
    forkJoin(observables as { video; poster; captions }).subscribe(
      (responseData) => {
        if (responseData['video']) {
          this.video =
            environment.assetUrl +
            '/assets/' +
            responseData['video']['filename'];
        }
        if (responseData['poster']) {
          this.poster =
            environment.assetUrl +
            '/assets/' +
            responseData['poster']['filename'];
        }
        if (responseData['captions']) {
          this.captions =
            environment.assetUrl +
            '/assets/' +
            responseData['captions']['filename'];
        }
      }
    );
  }

  onToggleShowTranscript(){
    this.showTranscript = !this.showTranscript;
  }
}
