<nav class="subnav">
  <a [routerLink]="['/friends-and-family']" aria-label="Go to p" class="subnav-prev"
    ><span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
  ></a>
  <h2>
    <span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'user-friends']"></fa-icon
    ></span>
    BUDDIES
  </h2>
  
</nav>
<div *ngIf="isFetching">
  <app-loading-spinner [inline]="1"></app-loading-spinner>
</div>

<div *ngIf="currentProgramme && currentUser">
  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.newInvite = !toggleRow.newInvite"
    >
      <a href="" class="toggle-nav toggle-nav-expand" (click)="$event.preventDefault()"
        ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
      ></span></a>
      <div class="toggle-header-title">NEW INVITE</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.newInvite">
      <p>
        You have {{ remainingInvites }} invite<span
          *ngIf="remainingInvites != 1"
          >s</span
        >
        remaining.
      </p>

      <form
      *ngIf="remainingInvites > 0"
      class="text-center comment-form"
      name="inviteForm"
      method="post"
      (ngSubmit)="onSubmit()"
      #inviteForm="ngForm"
    >
        <div class="form-group">
          <label for="email">ENTER YOUR FRIEND'S EMAIL ADDRESS</label>
          <input
            type="email"
            name="email"
            class="form-control input-tracker"
            placeholder="Enter an email address"
            id="email"
            ngModel
            autocomplete="off"
            required
            disbaled
            #email="ngModel"
          />
          <div
            class="alert alert-danger"
            *ngIf="email.touched && email.invalid"
          >
            Please enter a valid email address.
          </div>
        </div>
        <hr class="divider" />
        <p>
          When you press the SEND INVITE button your friend will be sent an
          email with instructions for them to sign up as a friend.
        </p>
        <div class="container">
          <div class="row">
            <div class="col-12 text-center">
              <button
                
                class="btn btn-primary btn-white"
                > <span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'check']"></fa-icon
              ></span> SEND INVITE</button
              >
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.invites = !toggleRow.invites"
    >
      <a href="" class="toggle-nav toggle-nav-expand" (click)="$event.preventDefault()"
        ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
      ></span></a>
      <div class="toggle-header-title">ALL INVITES</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.invites">
      <!-- existing invites -->

      <table *ngIf="invites.length > 0" class="table">
        <thead>
          <tr>
            <th>Email</th>
            <th>Code</th>
            <th>Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let invite of invites">
            <td>{{ invite.email }}</td>
            <td>{{ invite.invite_code }}</td>
            <td>
              {{ invite.created | dateToISO | dateFormatter: "D" | ordinal }}
              {{ invite.created | dateToISO | dateFormatter: "MMM yyyy" }}
            </td>
            <td class="text-center">

              <span class="icon-wrap" *ngIf="invite.status == 2"
                      ><fa-icon [icon]="['fas', 'check-square']"></fa-icon
                    ></span>

                    <span class="icon-wrap" *ngIf="invite.status == 1"
                      ><fa-icon [icon]="['far', 'square']"></fa-icon
                    ></span>
              
            </td>
          </tr>
        </tbody>
      </table>
      <p *ngIf="invites.length == 0">No invites found</p>
    </div>
  </div>

  <app-section-nav
    [section]="'activity'"
    [active]="'buddies'"
  ></app-section-nav>
</div>
