import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserMbtqService {
  error = new Subject<string>();
  slug = '/user_mbtqs';
  resourceName = 'user_mbtqs';
  allMbtqs?: any;
  mbtqsBetween?: any;
  
  constructor(private http: HttpClient) {}

  fetchAll(): any {
    if (!this.allMbtqs) {
      this.allMbtqs = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: any[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allMbtqs;
  }

  clearCache() {
    this.allMbtqs = null;
    this.mbtqsBetween = null;
  }

  
  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
        
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(
    mbtq: string,
    date_recorded: string,
  ) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      mbtq,
    };
    return this.http.post<any>(environment.apiUrl + this.slug, payload, {
      observe: 'response',
    });
  }

  update(
    id: number,
    mbtq: string,
    date_recorded: string,
  ) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      mbtq,
    };
    return this.http.patch<any>(
      environment.apiUrl + this.slug + '/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + '/' + id
    );
  }
  getRag(mbtq) {
    let ragColour = '';
    if (+mbtq <10) {
      ragColour = 'green';
    } else if (+mbtq>22) {
      ragColour = 'red';
    } else {
      ragColour = 'amber';
    }

    return ragColour;
  }

  getMbtqforColor(colour: string) {
    let ragRange = '';
    if (colour == 'green') {
      ragRange = '<10';
    } else if (colour == 'amber') {
      ragRange = '10-22';
    } else {
      ragRange = '>22';
    }

    return ragRange;
  }

}
