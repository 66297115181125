<div class="award-content animated fadeIn" *ngIf="activeAward && currentUser">
  <a
    href=""
    (click)="$event.preventDefault(); onCloseAward($event)"
    class="award-close"
    ><span class="icon-wrap"><fa-icon [icon]="['fas', 'times']"></fa-icon></span
  ></a>
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3 col-sm-12 text-center">
        <h1>CONGRATULATIONS!</h1>
        <div [ngSwitch]="activeAward.type">
          <div class="rosette" *ngSwitchCase="'c'">
            <div [ngSwitch]="activeAward.position">
              <div *ngSwitchCase="'1'" class="award-pos">
                1<span class="ordinal">ST</span>
              </div>
              <div *ngSwitchCase="'2'" class="award-pos">
                2<span class="ordinal">ND</span>
              </div>
              <div *ngSwitchCase="'3'" class="award-pos">
                3<span class="ordinal">RD</span>
              </div>
            </div>

            <img src="./assets/img/rosette-large.png" width="210" />
          </div>
          <div class="rosette" *ngSwitchCase="'m'">
            <span class="icon-wrap" style="font-size: 150px"
              ><fa-icon [icon]="['fas', 'star']"></fa-icon
            ></span>
          </div>
          <div class="rosette" *ngSwitchCase="'r'">
            <span class="icon-wrap" style="font-size: 150px"
              ><fa-icon [icon]="['fas', 'star']"></fa-icon
            ></span>
          </div>
        </div>

        <div class="award-details">
          <div *ngIf="activeAward.type == 'c'">
            <div [ngSwitch]="activeAward.position">
              <h2 *ngSwitchCase="'1'">WINNER</h2>
              <h2 *ngSwitchCase="'2'">SECOND</h2>
              <h2 *ngSwitchCase="'3'">THIRD</h2>
            </div>
            <span [ngSwitch]="activeAward.typeRef">
              <h4 *ngSwitchCase="'mf'">MONDAY - FRIDAY CHALLENGE</h4>
              <h4 *ngSwitchCase="'w'">WEEKEND CHALLENGE</h4>
              <h4 *ngSwitchCase="'c'">{{ activeAward.title }}</h4>
              <h4 *ngSwitchCase="'b'">{{ activeAward.title }}</h4>
              <h4 *ngSwitchCase="'g'">{{ activeAward.title }}</h4>
            </span>

            <div>{{ activeAward.startDate }} - {{ activeAward.endDate }}</div>
          </div>

          <div *ngIf="activeAward.type == 'm'">
            <h4>You've walked</h4>
            <h2>{{ activeAward.position | number }} STEPS!</h2>
          </div>

          <div *ngIf="activeAward.type == 'r'">
            <div [ngSwitch]="activeAward.award_sub_type">
              <div *ngSwitchCase="'daySteps'">
                <h4>New Daily Step Record!</h4>
                <h2>{{ activeAward.position | number }} STEPS!</h2>
              </div>
              <div *ngSwitchCase="'dayMinutes'">
                <h4>New Daily Activity Record!</h4>
                <h2>{{ activeAward.position | number }} MINUTES!</h2>
              </div>
              <div *ngSwitchCase="'weekSteps'">
                <h4>New Weekly Step Record!</h4>
                <h2>{{ activeAward.position | number }} STEPS!</h2>
              </div>
              <div *ngSwitchCase="'weekMinutes'">
                <h4>New Weekly Activity Record!</h4>
                <h2>{{ activeAward.position | number }} MINUTES!</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="award-share">
          <div class="container-fluid">
            <div class="row row-small-pad">
              <div class="col-4"><span>SHARE ON...</span></div>
              <div class="col-4"><span><a [href]="'https://www.facebook.com/sharer/sharer.php?u=https%3A//www.mydesmond.com/shared?u=' + currentUser.user_id + '%26p=' + activeAward.position + '%26t=' + activeAward.type + '%26tr=' + activeAward.typeRef + '%26d1=' + activeAward.startDate + '%26d2=' + activeAward.endDate" target="_blank"><span class="icon-wrap"><fa-icon [icon]="['fab', 'facebook-square']"></fa-icon></span
                > FACEBOOK</a></span></div>
              <div class="col-4"><span><a [href]="'https://twitter.com/share?text=I won an award on MyDesmond&url=https%3A//www.mydesmond.com/shared?u=' + currentUser.user_id + '%26p=' + activeAward.position + '%26t=' + activeAward.type + '%26tr=' + activeAward.typeRef + '%26d1=' + activeAward.startDate + '%26d2=' + activeAward.endDate" target="_blank"><span class="icon-wrap"><fa-icon [icon]="['fab', 'twitter-square']"></fa-icon></span
                > TWITTER</a></span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
