import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-programme-offline',
  templateUrl: './programme-offline.component.html',
  styleUrls: ['./programme-offline.component.scss']
})
export class ProgrammeOfflineComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
