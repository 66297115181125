<div class="container m3" *ngIf="currentUser">
  <app-m3-header class="d-none d-sm-block"></app-m3-header>
  <app-m3-mobile-navbar
    [pageTitle]="'Health Data'"
    class="d-block d-sm-none"
  ></app-m3-mobile-navbar>
  <nav class="subnav d-block d-sm-none">
    <h2>Kidney Health</h2>
  </nav>
  <h1 class="m3-title mt-3 d-none d-sm-block">
    <app-m3-icon [icon]="'kidney'"></app-m3-icon> Kidney Health
  </h1>

  <!--button (click)="addModal.show()" class="btn btn-mobile-add d-block d-sm-none">
      <app-m3-icon [icon]="'more'" [style]="'color'"></app-m3-icon
      > Add a Sitting Entry</button-->

  <h2 class="mt-2" id="hba1c">
    <button class="btn btn-white btn-sm float-right mt-1" [routerLink]="['/m3','health-data']"> &lt; Back</button>
    Urine albumin/creatinine ratio
    <a class="add-item-btn" (click)="addModal.show()"
      ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
    ></a>
  </h2>
  <div class="card" *ngIf="albumins">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
            <div class="info-stat-rag-current">
              <span class="info-stat-label"
                >Urine albumin/creatinine ratio</span
              >
              <span
                *ngIf="albumins[0]"
                class="info-stat-stat text-rag-{{
                  userAlbuminService.getRagForSex(
                    albumins[0].albumin,
                    currentUser.gender
                  )
                }}"
              >
                <span *ngIf="albumins[0]">{{ albumins[0].albumin }}</span
                ><span *ngIf="!albumins[0]">?</span></span
              >
              <span *ngIf="!albumins[0]" class="info-stat-stat">-</span>
              <span class="info-stat-label">mg/mol</span>
            </div>

            <div class="rag-details">
              <div class="rag-details-target">
                <span class="text-blue"
                  >{{
                    userAlbuminService.getGoodAlbumin(currentUser.gender)
                  }}mg/mol</span
                >
              </div>
              <div class="rag-details-history" *ngIf="albumins.length > 0">
                History:
                <div class="rag-history">
                  <ng-template #popTemplateAlbumin>
                    <div>
                      <div>Range:</div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-green mr-1"></span>
                        {{
                          userAlbuminService.getRangeforColorAndSex(
                            "green",
                            currentUser.gender
                          )
                        }}
                        U/L
                      </div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-amber mr-1"></span>

                        {{
                          userAlbuminService.getRangeforColorAndSex(
                            "amber",
                            currentUser.gender
                          )
                        }}
                        U/L
                      </div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-red mr-1"></span>
                        {{
                          userAlbuminService.getRangeforColorAndSex(
                            "red",
                            currentUser.gender
                          )
                        }}
                        U/L
                      </div>
                    </div></ng-template
                  >
                  <ng-container
                    *ngFor="
                      let albumin of albumins.slice().reverse();
                      let index = index
                    "
                  >
                    <div
                      *ngIf="index > albumins.length - 6"
                      [tooltip]="
                        albumin.albumin +
                        '' +
                        ' on ' +
                        (albumin.date_recorded | dateFormatter : 'Do MMM YYYY')
                      "
                      class="rag-circle bg-rag-{{
                        userAlbuminService.getRagForSex(
                          albumin.albumin,
                          currentUser.gender
                        )
                      }}"
                    ></div>
                  </ng-container>
                  <div
                    class="rag-circle pointer"
                    [popover]="popTemplateAlbumin"
                  >
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['fas', 'info']"></fa-icon
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h3 class="chart-title text-center mt-4" *ngIf="albumins">
            Your Entries
          </h3>
          <div class="table-responsive" *ngIf="albumins">
            <table *ngIf="albumins" class="table">
              <thead>
                <tr>
                  <th>DATE</th>
                  <th>Urine albumin/creatinine ratio</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="let albumin of albumins; let index = index"
                >
                  <tr *ngIf="index < 10 || showAllEntries">
                    <td class="text-center">
                      {{
                        albumin.date_recorded | dateFormatter : "MMM Do YYYY"
                      }}
                    </td>
                    <td class="text-center">{{ albumin.albumin }}mg/mol</td>

                    <td>
                      <a
                        [swal]="{
                          title: 'Are you sure you want to delete this?',
                          icon: 'warning',
                          showCancelButton: true
                        }"
                        (confirm)="onDeleteAlbumin(albumin)"
                        class="pointer"
                        ><span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                      ></a>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <div class="text-center">
              <button
                *ngIf="!showAllEntries && albumins.length > 10"
                class="btn btn-primary btn-sm btn-xs"
                (click)="showAllEntries = true"
              >
                Show all
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-6">
            <app-m3-albumin-chart
              *ngIf="albumins && albumins.length > 1"
              [albumins]="albumins.slice().reverse()"
              [sex]="currentUser.gender"
            ></app-m3-albumin-chart>
        </div>
      </div>
    </div>
  </div>

  <hr />

  <h2 class="mt-2">
    eGFR
    <a class="add-item-btn" (click)="addEgfrModal.show()"
      ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
    ></a>
  </h2>
  <div class="card" *ngIf="egfrs">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
            <div class="info-stat-rag-current">
              <span class="info-stat-label">eGFR</span>
              <span
                *ngIf="egfrs[0]"
                class="info-stat-stat text-rag-{{
                  userEgfrService.getRag(egfrs[0].egfr)
                }}"
              >
                <span *ngIf="egfrs[0]">{{ egfrs[0].egfr }}</span
                ><span *ngIf="!egfrs[0]">?</span></span
              >
              <span *ngIf="!egfrs[0]" class="info-stat-stat">-</span>

              <span class="info-stat-label">ml/min/1.73m2</span>
            </div>

            <div class="rag-details">
              <div class="rag-details-target">
                <span class="text-blue"
                  >{{
                    userEgfrService.getEgfrforColor("green")
                  }}
                  ml/min/1.73m2</span
                >
              </div>
              <div class="rag-details-history" *ngIf="egfrs.length > 0">
                History:
                <div class="rag-history">
                  <ng-template #popTemplateEgfr>
                    <div>
                      <div>Range:</div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-green mr-1"></span>
                        {{ userEgfrService.getEgfrforColor("green") }}
                        ml/min/1.73m2
                      </div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-amber mr-1"></span>

                        {{ userEgfrService.getEgfrforColor("amber") }}
                        ml/min/1.73m2
                      </div>
                      <div class="d-flex">
                        <span class="rag-circle bg-rag-red mr-1"></span>
                        {{ userEgfrService.getEgfrforColor("red") }}
                        ml/min/1.73m2
                      </div>
                    </div></ng-template
                  >
                  <ng-container
                    *ngFor="
                      let egfr of egfrs.slice().reverse();
                      let index = index
                    "
                  >
                    <div
                      *ngIf="index > egfrs.length - 6"
                      [tooltip]="
                        egfr.egfr +
                        ' ml/min/1.73m2 ' +
                        ' on ' +
                        (egfr.date_recorded | dateFormatter : 'Do MMM YYYY')
                      "
                      class="rag-circle bg-rag-{{
                        userEgfrService.getRag(egfr.egfr)
                      }}"
                    ></div>
                  </ng-container>
                  <div class="rag-circle pointer" [popover]="popTemplateEgfr">
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['fas', 'info']"></fa-icon
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h3 class="chart-title text-center mt-4" *ngIf="egfrs">
            Your Entries
          </h3>
          <div class="table-responsive" *ngIf="egfrs">
            <table *ngIf="egfrs" class="table">
              <thead>
                <tr>
                  <th>DATE</th>
                  <th>eGFR</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let egfr of egfrs; let index = index">
                  <tr *ngIf="index < 10 || showAllEntries">
                    <td class="text-center">
                      {{ egfr.date_recorded | dateFormatter : "MMM Do YYYY" }}
                    </td>
                    <td class="text-center">{{ egfr.egfr }} ml/min/1.73m2</td>

                    <td>
                      <a
                        [swal]="{
                          title: 'Are you sure you want to delete this?',
                          icon: 'warning',
                          showCancelButton: true
                        }"
                        (confirm)="onDeleteEgfr(egfr)"
                        class="pointer"
                        ><span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
                      ></a>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <div class="text-center">
              <button
                *ngIf="!showAllEntries && egfrs.length > 10"
                class="btn btn-primary btn-sm btn-xs"
                (click)="showAllEntries = true"
              >
                Show all
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-6">
            <app-m3-egfr-chart
            *ngIf="egfrs && egfrs.length > 1"
            [egfrs]="egfrs.slice().reverse()"
          ></app-m3-egfr-chart>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    bsModal
    #addModal="bs-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-static-name"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left">
            Update Urine albumin/creatinine ratio
          </h4>
          <button
            type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="addModal.hide()"
          >
            <span aria-hidden="true" class="visually-hidden">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form
            name="entryFormAlbumin"
            class="bs-form tracker-form"
            (ngSubmit)="onSubmitAlbuminForm()"
            #entryFormAlbumin="ngForm"
          >
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="reps"
                    >Urine albumin/creatinine ratio (mg/mol)</label
                  >
                  <input
                    type="number"
                    name="albumin"
                    class="form-control input-tracker input-tracker-xsm"
                    placeholder=""
                    id="albumin"
                    min="1"
                    max="300"
                    ngModel
                    autocomplete="off"
                    ng-required=""
                    #albumin="ngModel"
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="albumin.touched && albumin.invalid"
                  >
                    This must be a number below 300.
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="entryDateAlbumin">DATE</label>
                  <input
                    name="entryDateAlbumin"
                    type="text"
                    placeholder="DD/MM/YYYY"
                    class="form-control input-tracker input-tracker-sm"
                    bsDatepicker
                    autocomplete="off"
                    [bsConfig]="{
                      dateInputFormat: 'DD/MM/YYYY',
                      containerClass: 'theme-cv',
                      showWeekNumbers: false
                    }"
                    [(ngModel)]="entryDateAlbumin"
                    required
                    [bsValue]="entryDateAlbumin"
                    [maxDate]="entryDateAlbumin"
                    #dateInput="ngModel"
                  />
                </div>
              </div>
            </div>
            <div class="tracker-submit-container">
              <button
                type="submit"
                role="button"
                class="btn btn-primary btn-lg btn-white"
              >
                UPDATE
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- add level modal -->

  <div
    class="modal fade"
    bsModal
    #addEgfrModal="bs-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-static-name"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left">Update eGFR</h4>
          <button
            type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="addEgfrModal.hide()"
          >
            <span aria-hidden="true" class="visually-hidden">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form
            name="entryFormEgfr"
            class="bs-form tracker-form"
            (ngSubmit)="onSubmitEgfrForm()"
            #entryFormEgfr="ngForm"
          >
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="reps">eGFR (ml/min/1.73m2)</label>
                  <input
                    type="number"
                    name="egfr"
                    class="form-control input-tracker input-tracker-xsm"
                    placeholder=""
                    id="number"
                    min="1"
                    max="300"
                    ngModel
                    autocomplete="off"
                    ng-required=""
                    #number="ngModel"
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="number.touched && number.invalid"
                  >
                    This must be a number below 300.
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="number">DATE</label>
                  <input
                    name="date"
                    type="text"
                    placeholder="DD/MM/YYYY"
                    class="form-control input-tracker input-tracker-sm"
                    bsDatepicker
                    autocomplete="off"
                    [bsConfig]="{
                      dateInputFormat: 'DD/MM/YYYY',
                      containerClass: 'theme-cv',
                      showWeekNumbers: false
                    }"
                    [(ngModel)]="entryDateEgfr"
                    required
                    [bsValue]="entryDateEgfr"
                    [maxDate]="entryDateEgfr"
                    #dateInput="ngModel"
                  />
                </div>
              </div>
            </div>
            <div class="tracker-submit-container">
              <button
                type="submit"
                role="button"
                class="btn btn-primary btn-lg btn-white"
              >
                UPDATE
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="clear"></div>
</div>
