<nav class="subnav">
  <h2><i class="fa fa-gavel"></i> TERMS AND CONDITIONS</h2>
</nav>
<p class="lead text-center">
  <br />
  Our Terms and conditions have changed...
</p>

<div class="scrollbox" *ngIf="cms">
  <div class="container container-pad">
    <!-- Nav tabs -->
    <ul
      class="nav nav-tabs nav-tabs-duo nav-tabs-skinny justify-content-center"
      role="tablist"
    >
      <li role="presentation" [ngClass]="{ active: col1 }">
        <a
          aria-controls="percent"
          role="tab"
          data-toggle="tab"
          (click)="col1 = true;col2=false;col3=false;"
          >TERMS OF USE</a
        >
      </li>
      <li role="presentation" [ngClass]="{ active: col2 }">
        <a
          aria-controls="step-count"
          role="tab"
          data-toggle="tab"
          (click)="col2 = true;col1=false;col3=false;"
          >PRIVACY/COOKIE POLICY</a
        >
      </li>
      <li role="presentation" [ngClass]="{ active: col3 }">
        <a
          aria-controls="step-count"
          role="tab"
          data-toggle="tab"
          (click)="col3 = true;col2=false;col1=false;"
          >ACCEPTABLE USE POLICY</a
        >
      </li>
    </ul>

    <!-- Tab panes -->
    <div class="tab-content" style="height: 400px; overflow: scroll">
      <div role="tabpanel" id="terms" ng-show="col1">
        <div [innerHtml]="cms.terms_of_use.content | safeHtml"></div>
      </div>
      <div role="tabpanel"  id="privacy" ng-show="col2">
        <div [innerHtml]="cms.privacy_policy.content | safeHtml"></div>
      </div>
      <div role="tabpanel" id="use" ng-show="col3">
        <div [innerHtml]="cms.acceptable_use_policy.content | safeHtml"></div>
      </div>
    </div>
    <div class="text-center" style="margin-top: 10px">
      <button class="btn btn-primary" (click)="onAgree()">
        I AGREE TO THE TERMS
      </button>
    </div>
  </div>
</div>
