<div *ngIf="isFetching">
  <app-loading-spinner [inline]="1"></app-loading-spinner>
</div>
<div class="container m3 m3-dashboard" *ngIf="currentUser">
  <app-m3-mobile-navbar
    [pageTitle]="'home'"
    class="d-block d-sm-none"
  ></app-m3-mobile-navbar>

  <app-m3-header></app-m3-header>

  <div *ngIf="currentUser" class="m3-inner-container">
    <!--nav
      class="subnav ng-scope subnav-db"
      [hidden]="!deviceSynced || algorithmComplete"
    >
      <h2>
        <span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'walking']"></fa-icon
        ></span>
        ACTIVITY SETUP
      </h2>
    </nav>

    <div>
      <div class="row dashboard-row" *ngIf="!deviceSynced">
        <div class="col-md-12">
          <div class="mt-2 card pt-3">
            <h3 class="text-center">
              <span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'exclamation-circle']"></fa-icon
              ></span>
              PLEASE SETUP YOUR FITNESS TRACKER
            </h3>
            <div class="p-3">
              <p class="text-left">
                Setting up a device will ensure that the programme tailors'
                physical activity specifically suited to you and your ability.
              </p>
              <p class="text-left">
                We are aiming to progress you to achieve the minimum recommended
                steps for health. As little as 5 more minutes of walking per day
                can increase your life expectancy by ~4 years and as little as
                500 steps per day can decreased your risk of cardiovascular
                disease and even death
              </p>
              <p class="text-left">
                If you do not have a device, there is also an option to enter
                your steps manually
              </p>
            </div>

            <div class="row mb-3">
              <div class="col text-right">
                <a
                  role="button"
                  [routerLink]="['/setup-device']"
                  class="btn btn-white mr-1"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'watch-fitness']"></fa-icon
                  ></span>
                  SETUP DEVICE</a
                >
              </div>
              <div class="col text-left">
                <a
                  role="button"
                  [routerLink]="['/setup-device']"
                  class="btn btn-white ml-1"
                >
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'hand-pointer']"></fa-icon
                  ></span>
                  MANUAL</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!algorithmComplete && deviceSynced">
 
      <div class="container container-pad">
        <div class="row dashboard-row">
          <div class="col-md-12">
            <div class="card">
              <div class="p-3" *ngIf="activitySetupStep == 1">
                <div *ngIf="currentUser.manual_steps != 1">
                  <p class="text-left">Get Ready, Get Set, GO!</p>
                  <p class="text-left">
                    Once you have worn your device for at least 3 full days and
                    provided us with the data from the questions below we will
                    be able to generate your report.
                  </p>
                  <p class="text-left">
                    Three days will ensure your baseline activity level is
                    accurate
                  </p>
                </div>

                <div *ngIf="currentUser.manual_steps == 1">
                  <p class="text-left">
                    Once you have entered the total number of steps you perform
                    every day for the next 3 days and provided us with the data
                    from the questions below we will be able to generate your
                    report.
                  </p>
                  <p class="text-left">
                    Three days will ensure your starting activity data is
                    accurate
                  </p>
                </div>

                <div class="text-center">
                  <button
                    class="btn btn-white"
                    (click)="onConfirmReadActivitySetup()"
                  >
                    Continue
                  </button>
                </div>
              </div>

              <div *ngIf="activitySetupStep == 2" class="text-center">
                <div class="headline-flex">
                  <div class="headline-icon">
                    <span class="icon-wrap"
                      ><fa-icon [icon]="['far', 'hourglass-half']"></fa-icon
                    ></span>
                  </div>
                  <div class="headline-text">
                    <strong>{{ algorithmDaysRemaining }}</strong>
                    <span class="headline-subtext"
                      >day<span *ngIf="algorithmDaysRemaining != 1">s</span
                      ><br />
                      Remaining</span
                    >
                  </div>
                </div>

                <div class="days days-activity">
                  <div *ngFor="let day of algorithmDays" class="day">
                    <span
                      class="icon-wrap fa fa-check-circle"
                      *ngIf="day.status"
                      ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
                    ></span>
                    <span
                      class="icon-wrap fa fa-circle"
                      *ngIf="!day.status"
                      [ngClass]="{ 'not-hit': day.status === 0 }"
                      ><fa-icon
                        [icon]="['fas', 'circle']"
                        style="color: #333"
                      ></fa-icon></span
                    ><br />
                    {{ day.day | dateFormatter : "ddd" | uppercase }}
                  </div>
                </div>

                <div *ngIf="algorithmDaysRemaining > 0">
                  <p>
                    3 days of activity is required before the dashboard is
                    optimised
                  </p>

                  <div class="m-3">
                    <a [routerLink]="['/m3', 'walking']" class="btn btn-white">
                      <app-m3-icon [icon]="'walking'"></app-m3-icon> Daily
                      Walking
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div-->

    <div>
      <div class="row mt-3 mb-3">
        <div class="col-md-6">
          <div class="head-block">
            <h3>
              <a
                class="more-link"
                [routerLink]="['/', 'm3', 'health-data', 'glucose']"
                ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
              ></a>
              <app-m3-icon [icon]="'glucose'"></app-m3-icon>
              Glucose Levels
            </h3>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">HbA1c</span>
                  <span
                    *ngIf="hba1cs[0]"
                    class="info-stat-stat text-rag-{{
                      bloodGlucoseService.getRag(hba1cs[0].glucose)
                    }}"
                  >
                    <span *ngIf="hba1cs[0]">{{ hba1cs[0].glucose }}</span
                    ><span *ngIf="!hba1cs[0]">?</span></span
                  >
                  <span *ngIf="!hba1cs[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label">%</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green"
                      >{{ bloodGlucoseService.getHba1cTarget() }}%</span
                    >
                  </div>
                  <div class="rag-details-history" *ngIf="hba1cs.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateglucose>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{ bloodGlucoseService.getHba1cforColor("green") }}%
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            {{ bloodGlucoseService.getHba1cforColor("amber") }}%
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{ bloodGlucoseService.getHba1cforColor("red") }}%
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let glucose of hba1cs.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > hba1cs.length - 6"
                          [tooltip]="
                            glucose.glucose +
                            '%' +
                            ' on ' +
                            (glucose.created | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            bloodGlucoseService.getRag(glucose.glucose)
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateglucose"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">Change in HbA1c</span>
                  <span
                    *ngIf="hba1cChanges[0]"
                    class="info-stat-stat text-rag-{{
                      bloodGlucoseService.getChangeRag(hba1cChanges[0].change)
                    }}"
                  >
                    <span *ngIf="hba1cs[0]">
                      <fa-icon
                        *ngIf="+hba1cChanges[0].change > 0"
                        [icon]="['fas', 'arrow-up']"
                      ></fa-icon>
                      <fa-icon
                        *ngIf="+hba1cChanges[0].change < 0"
                        [icon]="['fas', 'arrow-down']"
                      ></fa-icon>

                      {{ hba1cChanges[0].change }}</span
                    ><span *ngIf="!hba1cs[0]">?</span></span
                  >
                  <span *ngIf="!hba1cs[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label">%</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target"></div>
                  <div
                    class="rag-details-history"
                    *ngIf="hba1cChanges.length > 0"
                  >
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateglucose>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{
                              bloodGlucoseService.getChangeByColour("green")
                            }}%
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            {{
                              bloodGlucoseService.getChangeByColour("amber")
                            }}%
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{ bloodGlucoseService.getChangeByColour("red") }}%
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let glucose of hba1cChanges.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > hba1cChanges.length - 6"
                          [tooltip]="
                            glucose.change +
                            '%' +
                            ' on ' +
                            (glucose.created | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            bloodGlucoseService.getChangeRag(glucose.change)
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateglucose"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">Average glucose level</span>
                  <span
                    *ngIf="glucoseAverages[0]"
                    class="info-stat-stat text-rag-{{
                      getGlucoseAverageRag(
                        glucoseAverages[0].user_glucose_average
                      )
                    }}"
                  >
                    <span *ngIf="glucoseAverages[0]">{{
                      glucoseAverages[0].user_glucose_average
                    }}</span
                    ><span *ngIf="!glucoseAverages[0]">?</span></span
                  >
                  <span *ngIf="!glucoseAverages[0]" class="info-stat-stat"
                    >-</span
                  >
                  <span class="info-stat-label">mmol/l</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target"></div>
                  <div class="rag-details-history" *ngIf="hba1cs.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-container
                        *ngFor="
                          let glucoseAverage of glucoseAverages
                            .slice()
                            .reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > glucoseAverages.length - 6"
                          [tooltip]="
                            glucoseAverage.user_glucose_average +
                            '' +
                            ' on ' +
                            (glucoseAverage.date_recorded
                              | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            getGlucoseAverageRag(
                              glucoseAverage.user_glucose_average
                            )
                          }}"
                        ></div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="info-stat info-stat-rag info-stat-no-icon mb-3"
                *ngIf="glucoseAverageChanges[0]"
              >
                <div class="info-stat-rag-current">
                  <span class="info-stat-label"
                    >Change in average glucose level</span
                  >
                  <span
                    *ngIf="glucoseAverageChanges[0]"
                    class="info-stat-stat text-rag-{{
                      bloodGlucoseService.getAverageChangeRag(
                        glucoseAverageChanges[0].change
                      )
                    }}"
                  >
                    <span *ngIf="glucoseAverageChanges[0]">
                      <fa-icon
                        *ngIf="glucoseAverageChanges[0].change > 0"
                        [icon]="['fas', 'arrow-up']"
                      ></fa-icon>
                      <fa-icon
                        *ngIf="glucoseAverageChanges[0].change < 0"
                        [icon]="['fas', 'arrow-down']"
                      ></fa-icon>
                      {{ glucoseAverageChanges[0].change }} </span
                    ><span *ngIf="!glucoseAverageChanges[0]">?</span></span
                  >
                  <span *ngIf="!glucoseAverageChanges[0]" class="info-stat-stat"
                    >-</span
                  >
                  <span class="info-stat-label">mmol/l</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target"></div>
                  <div
                    class="rag-details-history"
                    *ngIf="glucoseAverageChanges.length > 0"
                  >
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateAverageChange>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{
                              bloodGlucoseService.getAverageChangeByColour(
                                "green"
                              )
                            }}mmol/l
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            {{
                              bloodGlucoseService.getAverageChangeByColour(
                                "amber"
                              )
                            }}mmol/l
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{
                              bloodGlucoseService.getAverageChangeByColour(
                                "red"
                              )
                            }}mmol/l
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let glucoseAverage of glucoseAverageChanges
                            .slice()
                            .reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > glucoseAverageChanges.length - 6"
                          [tooltip]="
                            glucoseAverage.change +
                            ' mmol/l ' +
                            ' on ' +
                            (glucoseAverage.date_recorded
                              | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            bloodGlucoseService.getAverageChangeRag(
                              glucoseAverage.change
                            )
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateAverageChange"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">Time in range</span>
                  <span
                    *ngIf="glucoseInRanges[0]"
                    class="info-stat-stat text-rag-{{
                      bloodGlucoseService.getInRangeRag(
                        glucoseInRanges[0].time_in_range | number : '1.0-0'
                      )
                    }}"
                  >
                    <span *ngIf="glucoseInRanges[0]">{{
                      glucoseInRanges[0].time_in_range | number : "1.0-0"
                    }}</span
                    ><span *ngIf="!glucoseInRanges[0]">?</span></span
                  >
                  <span *ngIf="!glucoseInRanges[0]" class="info-stat-stat"
                    >-</span
                  >
                  <span class="info-stat-label">%</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green">
                      {{
                        bloodGlucoseService.getInRangeByColour("green")
                      }}%</span
                    >
                  </div>
                  <div
                    class="rag-details-history"
                    *ngIf="glucoseInRanges.length > 0"
                  >
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateglucose>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{
                              bloodGlucoseService.getInRangeByColour("green")
                            }}%
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>

                            {{
                              bloodGlucoseService.getInRangeByColour("amber")
                            }}%
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{ bloodGlucoseService.getInRangeByColour("red") }}%
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let glucose of glucoseInRanges.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > glucoseInRanges.length - 6"
                          [tooltip]="
                            (glucose.time_in_range | number : '1.0-0') +
                            '% ' +
                            ' on ' +
                            (glucose.date_recorded
                              | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            bloodGlucoseService.getInRangeRag(
                              glucose.time_in_range | number : '1.0-0'
                            )
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateglucose"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">Time below range</span>
                  <span
                    *ngIf="glucoseBelowRanges[0]"
                    class="info-stat-stat text-rag-{{
                      bloodGlucoseService.getBelowRangeRag(
                        glucoseBelowRanges[0].time_below_range
                          | number : '1.0-0'
                      )
                    }}"
                  >
                    <span *ngIf="glucoseBelowRanges[0]">{{
                      glucoseBelowRanges[0].time_below_range | number : "1.0-0"
                    }}</span
                    ><span *ngIf="!glucoseBelowRanges[0]">?</span></span
                  >
                  <span *ngIf="!glucoseBelowRanges[0]" class="info-stat-stat"
                    >-</span
                  >
                  <span class="info-stat-label">%</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green">
                      {{
                        bloodGlucoseService.getBelowRangeByColour("green")
                      }}%</span
                    >
                  </div>
                  <div
                    class="rag-details-history"
                    *ngIf="glucoseBelowRanges.length > 0"
                  >
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateglucose>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{
                              bloodGlucoseService.getBelowRangeByColour(
                                "green"
                              )
                            }}%
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>

                            {{
                              bloodGlucoseService.getBelowRangeByColour(
                                "amber"
                              )
                            }}%
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{
                              bloodGlucoseService.getBelowRangeByColour("red")
                            }}%
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let glucose of glucoseBelowRanges.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > glucoseBelowRanges.length - 6"
                          [tooltip]="
                            (glucose.time_below_range | number : '1.0-0') +
                            '% ' +
                            ' on ' +
                            (glucose.date_recorded
                              | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            bloodGlucoseService.getBelowRangeRag(
                              glucose.time_below_range
                            )
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateglucose"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="head-block">
            <h3>
              <a
                class="more-link"
                [routerLink]="['/', 'm3', 'health-data', 'kidney']"
                ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
              ></a>
              <app-m3-icon [icon]="'kidney'"></app-m3-icon>
              Kidney Health
              <div
                class="rag-circle pointer"
                [popover]="
                  'People with diabetes can develop problems with their kidneys.  It is important to monitor these tests as well as controlling glucose levels (HbA1c) and blood pressure to prevent damage occurring.'
                "
              >
                <span class="icon-wrap"
                  ><fa-icon [icon]="['fas', 'info']"></fa-icon
                ></span>
              </div>
            </h3>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label"
                    >Urine albumin/creatinine ratio
                    <div
                      class="rag-circle pointer"
                      style="display: inline-flex"
                      [popover]="
                        'Amount of normal protein leaking from the kidney'
                      "
                    >
                      <span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'info']"></fa-icon
                      ></span></div
                  ></span>
                  <span
                    *ngIf="albumins[0]"
                    class="info-stat-stat text-rag-{{
                      userAlbuminService.getRagForSex(
                        albumins[0].albumin,
                        currentUser.gender
                      )
                    }}"
                  >
                    <span *ngIf="albumins[0]">{{ albumins[0].albumin }}</span
                    ><span *ngIf="!albumins[0]">?</span></span
                  >
                  <span *ngIf="!albumins[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label">mg/mmol</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    <span class="text-rag-green"
                      >{{
                        userAlbuminService.getGoodAlbumin(currentUser.gender)
                      }}mg/mmol</span
                    >
                  </div>
                  <div class="rag-details-history" *ngIf="albumins.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateAlbumin>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{
                              userAlbuminService.getRangeforColorAndSex(
                                "green",
                                currentUser.gender
                              )
                            }}
                            mg/mmol
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>

                            {{
                              userAlbuminService.getRangeforColorAndSex(
                                "amber",
                                currentUser.gender
                              )
                            }}
                            mg/mmol
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{
                              userAlbuminService.getRangeforColorAndSex(
                                "red",
                                currentUser.gender
                              )
                            }}
                            mg/mmol
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let albumin of albumins.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > albumins.length - 6"
                          [tooltip]="
                            albumin.albumin +
                            '' +
                            ' on ' +
                            (albumin.date_recorded
                              | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            userAlbuminService.getRagForSex(
                              albumin.albumin,
                              currentUser.gender
                            )
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateAlbumin"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label"
                    >eGFR
                    <div
                      class="rag-circle pointer"
                      style="display: inline-flex"
                      [popover]="
                        'Roughly how effective the kidneys are at filtering blood'
                      "
                    >
                      <span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'info']"></fa-icon
                      ></span></div
                  ></span>
                  <span
                    *ngIf="egfrs[0]"
                    class="info-stat-stat text-rag-{{
                      userEgfrService.getRag(egfrs[0].egfr)
                    }}"
                  >
                    <span *ngIf="egfrs[0]">{{ egfrs[0].egfr }}</span
                    ><span *ngIf="!egfrs[0]">?</span></span
                  >
                  <span *ngIf="!egfrs[0]" class="info-stat-stat">-</span>

                  <span class="info-stat-label">ml/min/1.73m2</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    <span class="text-rag-green"
                      >{{
                        userEgfrService.getEgfrforColor("green")
                      }}
                      ml/min/1.73m2</span
                    >
                  </div>
                  <div class="rag-details-history" *ngIf="egfrs.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateEgfr>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{ userEgfrService.getEgfrforColor("green") }}
                            ml/min/1.73m2
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>

                            {{ userEgfrService.getEgfrforColor("amber") }}
                            ml/min/1.73m2
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{ userEgfrService.getEgfrforColor("red") }}
                            ml/min/1.73m2
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let egfr of egfrs.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > egfrs.length - 6"
                          [tooltip]="
                            egfr.egfr +
                            ' ml/min/1.73m2 ' +
                            ' on ' +
                            (egfr.date_recorded | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            userEgfrService.getRag(egfr.egfr)
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateEgfr"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="head-block">
            <h3>
              <a
                class="more-link"
                [routerLink]="['/', 'm3', 'health-data', 'weight']"
                ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
              ></a>
              <app-m3-icon [icon]="'scales'"></app-m3-icon>
              Body Weight
            </h3>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">Body Mass Index</span>
                  <span
                    *ngIf="bmis[0]"
                    class="info-stat-stat text-rag-{{
                      getBmiRag(bmis[0].bmi, currentUser.ethnicity_id)
                    }}"
                  >
                    <span *ngIf="bmis[0]">{{
                      bmis[0].bmi | number : "1.1-1"
                    }}</span
                    ><span *ngIf="!bmis[0]">?</span></span
                  >
                  <span *ngIf="!bmis[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label"></span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green">
                      {{
                        getBmiRagRange("green", currentUser.ethnicity_id)
                      }}</span
                    >
                  </div>
                  <div class="rag-details-history" *ngIf="bmis.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplatebmi>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>

                            {{
                              getBmiRagRange("green", currentUser.ethnicity_id)
                            }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            {{
                              getBmiRagRange("amber", currentUser.ethnicity_id)
                            }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{
                              getBmiRagRange("red", currentUser.ethnicity_id)
                            }}
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let bmi of bmis.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > bmis.length - 6"
                          [tooltip]="
                            (bmi.bmi | number : '1.1-1') +
                            '' +
                            ' on ' +
                            (bmi.date_recorded | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            getBmiRag(bmi.bmi, currentUser.ethnicity_id)
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplatebmi"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">Waist Circumference</span>
                  <span
                    *ngIf="waists[0]"
                    class="info-stat-stat text-rag-{{
                      getWaistRag(
                        waists[0].waist,
                        currentUser.gender,
                        currentUser.ethnicity_id
                      )
                    }}"
                  >
                    <span *ngIf="waists[0]">{{
                      +waists[0].waist | number : "1.1-1"
                    }}</span
                    ><span *ngIf="!waists[0]">?</span></span
                  >
                  <span *ngIf="!waists[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label">cm</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green">
                      {{
                        getWaistRagRange(
                          "green",
                          currentUser.gender,
                          currentUser.ethnicity_id
                        )
                      }}cm</span
                    >
                  </div>
                  <div class="rag-details-history" *ngIf="waists.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplatewaist>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>

                            {{
                              getWaistRagRange(
                                "green",
                                currentUser.gender,
                                currentUser.ethnicity_id
                              )
                            }}cm
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>

                            {{
                              getWaistRagRange(
                                "amber",
                                currentUser.gender,
                                currentUser.ethnicity_id
                              )
                            }}cm
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>

                            {{
                              getWaistRagRange(
                                "red",
                                currentUser.gender,
                                currentUser.ethnicity_id
                              )
                            }}cm
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let waist of waists.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > waists.length - 6"
                          [tooltip]="
                            (waist.waist | number : '1.1-1') +
                            ' cm' +
                            ' on ' +
                            (waist.date_recorded
                              | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            getWaistRag(
                              waist.waist,
                              currentUser.gender,
                              currentUser.ethnicity_id
                            )
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplatewaist"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">Weight</span>
                  <span
                    *ngIf="weights[0] && bmis[0]"
                    class="info-stat-stat text-rag-{{
                      getBmiRag(bmis[0].bmi, currentUser.ethnicity_id)
                    }}"
                  >
                    <span *ngIf="weights[0]">{{
                      weights[0].weight | number : "1.1-1"
                    }}</span
                    ><span *ngIf="!weights[0]">?</span></span
                  >
                  <span *ngIf="weights[0] && !bmis[0]" class="info-stat-stat">
                    <span *ngIf="weights[0]">{{
                      weights[0].weight | number : "1.1-1"
                    }}</span
                    ><span *ngIf="!weights[0]">?</span></span
                  >

                  <span *ngIf="!weights[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label">Kg</span>
                  <!--span *ngIf="userShapes[1]" style="margin-left:5px">
                    <fa-icon *ngIf="+userShapes[1].weight<+userShapes[0].weight" [icon]="['fas', 'arrow-up']"></fa-icon>
                    <fa-icon *ngIf="+userShapes[1].weight>+userShapes[0].weight" [icon]="['fas', 'arrow-down']"></fa-icon>
                    <span *ngIf="+userShapes[1].weight == +userShapes[0].weight">-</span>
                  </span-->
                </div>

                <div class="rag-details">
                  <div class="rag-details-target"></div>
                  <div class="rag-details-history" *ngIf="weights.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-container
                        *ngFor="
                          let weight of weights.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > weights.length - 6"
                          [tooltip]="
                            (weight.weight | number : '1.1-1') +
                            '' +
                            ' on ' +
                            (weight.created | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle"
                        >
                          <fa-icon
                            *ngIf="
                              weights.slice().reverse()[index - 1] &&
                              +weight.weight >
                                +weights.slice().reverse()[index - 1].weight
                            "
                            [icon]="['fas', 'arrow-up']"
                          ></fa-icon>
                          <fa-icon
                            *ngIf="
                              weights.slice().reverse()[index - 1] &&
                              +weight.weight <
                                +weights.slice().reverse()[index - 1].weight
                            "
                            [icon]="['fas', 'arrow-down']"
                          ></fa-icon>
                          <span
                            *ngIf="
                              weights.slice().reverse()[index - 1] &&
                              +weights.slice().reverse()[index - 1].weight ==
                                +weight.weight
                            "
                            >-</span
                          >
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>

              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">Change in weight</span>
                  <span
                    *ngIf="weightChanges[0]"
                    class="info-stat-stat"
                    [ngClass]="{
                      'text-rag-green': +weightChanges[0].change <= -0.5,
                      'text-rag-red': +weightChanges[0].change >= 0.5,
                      'text-rag-amber':
                        +weightChanges[0].change < 0.5 &&
                        +weightChanges[0].change >= -0.5
                    }"
                  >
                    <fa-icon
                      *ngIf="+weightChanges[0].change > 0"
                      [icon]="['fas', 'arrow-up']"
                    ></fa-icon>
                    <fa-icon
                      *ngIf="+weightChanges[0].change < 0"
                      [icon]="['fas', 'arrow-down']"
                    ></fa-icon>
                    <span *ngIf="weightChanges[0]">{{
                      weightChanges[0].change | number : "1.1-1"
                    }}</span
                    ><span *ngIf="!weightChanges[0]">?</span></span
                  >
                  <span *ngIf="!weightChanges[0]" class="info-stat-stat"
                    >-</span
                  >
                  <span class="info-stat-label">Kg</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target"></div>
                  <div
                    class="rag-details-history"
                    *ngIf="weightChanges.length > 0"
                  >
                    History:
                    <div class="rag-history">
                      <ng-container
                        *ngFor="
                          let weight of weightChanges.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > weightChanges.length - 6"
                          [tooltip]="
                            (weight.change | number : '1.1-1') +
                            '' +
                            ' on ' +
                            (weight.date_recorded
                              | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle"
                        >
                          <fa-icon
                            *ngIf="+weight.change > 0"
                            [icon]="['fas', 'arrow-up']"
                          ></fa-icon>
                          <fa-icon
                            *ngIf="+weight.change < 0"
                            [icon]="['fas', 'arrow-down']"
                          ></fa-icon>
                          <span *ngIf="weight.change == 0">-</span>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Liver -->

          <div class="head-block">
            <h3>
              <a
                class="more-link"
                [routerLink]="['/', 'm3', 'health-data', 'liver']"
                ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
              ></a>
              <app-m3-icon [icon]="'liver'"></app-m3-icon>
              Liver Health
              <div
                class="rag-circle pointer"
                [popover]="
                  'People with diabetes can commonly have a problem with their liver called \'Non-alcoholic fatty liver disease\'. Although there are other reasons these tests can be abnormal, it is important to monitor for early signs of liver damage in diabetes.  Controlling body weight is key to preventing and managing fatty liver disease.'
                "
              >
                <span class="icon-wrap"
                  ><fa-icon [icon]="['fas', 'info']"></fa-icon
                ></span>
              </div>
            </h3>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label"
                    >ALT
                    <div
                      class="rag-circle pointer"
                      style="display: inline-flex"
                      [popover]="
                        'A blood test suggesting damange to liver cells'
                      "
                    >
                      <span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'info']"></fa-icon
                      ></span></div
                  ></span>
                  <span
                    *ngIf="alts[0]"
                    class="info-stat-stat text-rag-{{
                      userAltService.getRagForSex(
                        alts[0].alt,
                        currentUser.gender
                      )
                    }}"
                  >
                    <span *ngIf="alts[0]">{{ alts[0].alt }}</span
                    ><span *ngIf="!alts[0]">?</span></span
                  >
                  <span *ngIf="!alts[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label">U/L</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green">
                      {{ userAltService.getGoodAlt(currentUser.gender) }} U/L
                    </span>
                  </div>
                  <div class="rag-details-history" *ngIf="alts.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateAlt>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{
                              userAltService.getAltforColorAndSex(
                                "green",
                                currentUser.gender
                              )
                            }}
                            U/L
                          </div>

                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{
                              userAltService.getAltforColorAndSex(
                                "red",
                                currentUser.gender
                              )
                            }}
                            U/L
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let alt of alts.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > alts.length - 6"
                          [tooltip]="
                            alt.alt +
                            ' U/L ' +
                            ' on ' +
                            (alt.date_recorded | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            userAltService.getRagForSex(
                              alt.alt,
                              currentUser.gender
                            )
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateAlt"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label"
                    >FIB-4
                    <div
                      class="rag-circle pointer"
                      style="display: inline-flex"
                      [popover]="
                        'If this is raised it means there may be a risk of liver scarring (known as fibrosis)'
                      "
                    >
                      <span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'info']"></fa-icon
                      ></span></div
                  ></span>
                  <span
                    *ngIf="fib4s[0]"
                    class="info-stat-stat text-rag-{{
                      userFib4Service.getRag(fib4s[0].fib4)
                    }}"
                  >
                    <span *ngIf="fib4s[0]">{{ fib4s[0].fib4 }}</span
                    ><span *ngIf="!fib4s[0]">?</span></span
                  >
                  <span *ngIf="!fib4s[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label"></span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green">&lt;1.3</span>
                  </div>
                  <div class="rag-details-history" *ngIf="fib4s.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateFib4>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{ userFib4Service.getRangeforColor("green") }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            {{ userFib4Service.getRangeforColor("amber") }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{ userFib4Service.getRangeforColor("red") }}
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let fib4 of fib4s.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > fib4s.length - 6"
                          [tooltip]="
                            fib4.fib4 +
                            ' ' +
                            ' on ' +
                            (fib4.date_recorded | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            userFib4Service.getRag(fib4.fib4)
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateFib4"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="head-block">
            <h3>
              <a
                class="more-link"
                [routerLink]="['/', 'm3', 'health-data', 'heart']"
                ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
              ></a>
              <app-m3-icon [icon]="'heart'"></app-m3-icon>
              Heart Health
            </h3>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">BP Systolic</span>
                  <span
                    *ngIf="bloodPressures[0]"
                    class="info-stat-stat text-rag-{{
                      getBloodPressureSystolicRag(bloodPressures[0].systolic)
                    }}"
                  >
                    <span *ngIf="bloodPressures[0]">{{
                      bloodPressures[0].systolic
                    }}</span
                    ><span *ngIf="!bloodPressures[0]">?</span></span
                  >
                  <span *ngIf="!bloodPressures[0]" class="info-stat-stat"
                    >-</span
                  >
                  <span class="info-stat-label">mmHg</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green">≤139 mmHg</span>
                  </div>
                  <div
                    class="rag-details-history"
                    *ngIf="bloodPressures.length > 0"
                  >
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateglucose>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>

                            ≤139 mmHg
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>

                            140-149 mmHg
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>

                            >149 mmHg
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let bloodPressure of bloodPressures.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > bloodPressures.length - 6"
                          [tooltip]="
                            bloodPressure.systolic +
                            ' mmHg' +
                            ' on ' +
                            (bloodPressure.created
                              | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            getBloodPressureSystolicRag(bloodPressure.systolic)
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateglucose"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">BP Diastolic</span>
                  <span
                    *ngIf="bloodPressures[0]"
                    class="info-stat-stat text-rag-{{
                      getBloodPressureDiastolicRag(bloodPressures[0].diastolic)
                    }}"
                  >
                    <span *ngIf="bloodPressures[0]">{{
                      bloodPressures[0].diastolic
                    }}</span
                    ><span *ngIf="!bloodPressures[0]">?</span></span
                  >
                  <span *ngIf="!bloodPressures[0]" class="info-stat-stat"
                    >-</span
                  >
                  <span class="info-stat-label">mmHg</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green">≤89 mmHg</span>
                  </div>
                  <div
                    class="rag-details-history"
                    *ngIf="bloodPressures.length > 0"
                  >
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateglucose>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>

                            ≤89 mmHg
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>

                            90-99 mmHg
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>

                            >99 mmHg
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let bloodPressure of bloodPressures.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > bloodPressures.length - 6"
                          [tooltip]="
                            bloodPressure.diastolic +
                            ' mmHg' +
                            ' on ' +
                            (bloodPressure.created
                              | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            getBloodPressureDiastolicRag(
                              bloodPressure.diastolic
                            )
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateglucose"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  
                  <span class="info-stat-label">Total cholesterol </span>
                  <span *ngIf="cholesterols[0]" class="info-stat-stat text-rag-{{
                    getCholesterolRag(cholesterols[0].cholesterol, 'total')
                  }}">
                    <span *ngIf="cholesterols[0]">{{ cholesterols[0].cholesterol }}</span
                    ><span *ngIf="!cholesterols[0]">?</span></span
                  >
                  <span *ngIf="!cholesterols[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label">mmol/L</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green">&lt;4 mmol/L</span>
                  </div>
                  <div class="rag-details-history" *ngIf="cholesterols.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateglucose>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            &lt;4
                            mmol/L
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            4.1-7.4
                            mmol/L
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            >7.5
                            mmol/L
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="let cholesterol of cholesterols; let index = index"
                      >
                        <div
                          *ngIf="index < 5"
                          [tooltip]="
                          cholesterol.cholesterol +
                            ' mmol/L' +
                            ' on ' +
                            (cholesterol.created
                              | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            getCholesterolRag(cholesterol.cholesterol, 'total')
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateglucose"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div-->

              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">LDL cholesterol </span>
                  <span
                    *ngIf="cholesterols[0]"
                    class="info-stat-stat text-rag-{{
                      getCholesterolRag(cholesterols[0].ldl, 'ldl')
                    }}"
                  >
                    <span *ngIf="cholesterols[0]">{{
                      cholesterols[0].ldl
                    }}</span
                    ><span *ngIf="!cholesterols[0]">?</span></span
                  >
                  <span *ngIf="!cholesterols[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label">mmol/L</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green">&lt;1.4 mmol/L</span>
                  </div>
                  <div
                    class="rag-details-history"
                    *ngIf="cholesterols.length > 0"
                  >
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateglucose>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            &lt;1.4 mmol/L
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            1.4-2.5 mmol/L
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            >2.5 mmol/L
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let cholesterol of cholesterols.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > cholesterols.length - 6"
                          [tooltip]="
                            cholesterol.ldl +
                            ' mmol/L' +
                            ' on ' +
                            (cholesterol.created
                              | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            getCholesterolRag(cholesterol.ldl, 'ldl')
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateglucose"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">Cholesterol / HDL ratio </span>
                  <span
                    *ngIf="hdlRatios[0]"
                    class="info-stat-stat text-rag-{{
                      getCholesterolRag(hdlRatios[0].hdl_ratio, 'hdl_ratio')
                    }}"
                  >
                    <span *ngIf="hdlRatios[0]">{{
                      hdlRatios[0].hdl_ratio | number : "1.1-1"
                    }}</span
                    ><span *ngIf="!hdlRatios[0]">?</span></span
                  >
                  <span *ngIf="!hdlRatios[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label"></span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green">&lt;6.0</span>
                  </div>
                  <div class="rag-details-history" *ngIf="hdlRatios.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateglucose>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            &lt;6.0
                          </div>

                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            ≥6.0
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let hdlRatio of hdlRatios.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > hdlRatios.length - 6"
                          [tooltip]="
                            (hdlRatio.hdl_ratio | number : '1.1-1') +
                            ' on ' +
                            (hdlRatio.created | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            getCholesterolRag(hdlRatio.hdl_ratio, 'hdl_ratio')
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateglucose"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">Smoking</span>
                  <span
                    *ngIf="smokings[0]"
                    class="info-stat-stat text-rag-green"
                  >
                    <span *ngIf="smokings[0]">{{ currentUser.smoker }}</span
                    ><span *ngIf="!smokings[0]">?</span></span
                  >
                  <span *ngIf="!smokings[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label">&nbsp;</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">&nbsp;</div>
                  <div class="rag-details-history" *ngIf="smokings.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateglucose>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>

                            Never
                          </div>

                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            Smoker
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let smoking of smokings.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > smokings.length - 6"
                          [tooltip]="
                            smoking.cigarettes +
                            ' cigarettes ' +
                            ' on ' +
                            (smoking.date_recorded
                              | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-red"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateglucose"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- activity -->

          <div class="head-block">
            <h3>
              <a class="more-link" [routerLink]="['/', 'm3', 'dashboard']"
                ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
              ></a>
              <app-m3-icon [icon]="'fitness'"></app-m3-icon>
              Activity
            </h3>
          </div>
          <div class="card">
            <div class="card-body">
              <div
                class="info-stat info-stat-rag mb-2"
                *ngIf="stepAverage != null"
              >
                <div class="info-stat-rag-current">
                  <app-m3-icon [icon]="'walking-alt'"></app-m3-icon>
                  <span class="info-stat-label"
                    >My average daily steps in the last 7 days</span
                  >
                  <span
                    class="info-stat-stat text-rag-{{
                      getStepsRag(stepAverage)
                    }}"
                    >{{ stepAverage | number }}</span
                  >
                </div>
                <div class="rag-details">
                  <div
                    class="rag-details-target d-flex"
                    *ngIf="stepTarget && +stepAverage < 7500"
                  >
                    Optimal population level:
                    <ng-template #popTemplateStepsTarget>
                      <div>
                        The optimal level of &gt;7,500 steps may differ to the
                        current weekly target. Feedback should be based on
                        meeting and progressing the current target where this is
                        available.
                      </div></ng-template
                    >
                    &nbsp;<span class="text-rag-green">&gt;7,500-10,000</span>
                    &nbsp;<span
                      class="rag-circle pointer"
                      [popover]="popTemplateStepsTarget"
                    >
                      <span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'info']"></fa-icon
                      ></span>
                    </span>
                  </div>
                  <div class="rag-details-target" *ngIf="+stepAverage >= 7500">
                    &nbsp;
                  </div>
                  <div class="rag-details-history">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateSteps>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            >7,500
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            5,000 - 7,499
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            &lt; 5000
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let step of weeklyStepAverages.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > weeklyStepAverages.length - 6"
                          [tooltip]="
                            (step.steps | number) +
                            ' steps, W/C ' +
                            step.activity_date
                          "
                          class="rag-circle bg-rag-{{
                            getStepsRag(step.steps)
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateSteps"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="rag-details" *ngIf="stepTarget">
                  <div class="rag-details-target d-flex">
                    My weekly target: &nbsp;<span class="text-rag-green">{{
                      stepTarget.target | number
                    }}</span>
                  </div>
                  <div class="rag-details-history">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateStepTarget>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            Target achieved
                          </div>

                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            Target not achieved
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let step of weeklyStepAverages.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > weeklyStepAverages.length - 6"
                          [tooltip]="
                            (step.steps | number) +
                            ' steps, W/C ' +
                            step.activity_date
                          "
                          class="rag-circle"
                          [ngClass]="{
                            'bg-rag-green': +step.steps >= +stepTarget.target,
                            'bg-rag-red': +step.steps < +stepTarget.target
                          }"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateStepTarget"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="info-stat mb-3" *ngIf="changeInSteps">
                <app-m3-icon
                  [icon]="'target'"
                  class="text-rag-green"
                ></app-m3-icon>
                <span class="info-stat-label">average daily step change </span>
                <span
                  class="info-stat-stat text-rag-{{
                    getChangeInStepsRag(changeInSteps)
                  }}"
                  ><span class="info-stat-stat" *ngIf="changeInSteps >= 0"
                    ><fa-icon [icon]="['fas', 'arrow-up']"></fa-icon
                    >{{ changeInSteps | number }}</span
                  >
                  <span class="info-stat-stat" *ngIf="changeInSteps < 0"
                    ><fa-icon [icon]="['fas', 'arrow-down']"></fa-icon
                    >{{ changeInSteps * -1 | number }}</span
                  ></span
                >
              </div>

              <div class="info-stat info-stat-rag mb-3">
                <div class="info-stat-rag-current">
                  <app-m3-icon
                    [icon]="'vo2max'"
                    class="text-dark"
                  ></app-m3-icon>
                  <span class="info-stat-label"
                    >My estimated VO<sub>2</sub>max</span
                  >
                  <span
                    *ngIf="vo2maxs && vo2maxs[0]"
                    class="info-stat-stat text-rag-{{
                      getRagForAgeAndSex(vo2maxs[0].vo2max, age, sex)
                    }}"
                  >
                    <span *ngIf="vo2maxs && vo2maxs[0]">{{
                      vo2maxs[0].vo2max
                    }}</span
                    ><span *ngIf="!vo2maxs">?</span></span
                  >
                  <span *ngIf="!vo2maxs" class="info-stat-stat">-</span>
                  <span class="info-stat-label">ml/kg/min</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target" *ngIf="vo2maxs[0]">
                    <span
                      *ngIf="
                        vo2maxs &&
                        getGoodforAgeAndSex(age, sex) > vo2maxs[0].vo2max
                      "
                    >
                      Target:
                      <span class="text-rag-green">{{
                        getGoodforAgeAndSex(age, sex)
                      }}</span>
                      <span class="ml-1">ml/kg/min</span></span
                    >
                  </div>
                  <div class="rag-details-history" *ngIf="vo2maxs.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateVo2Max>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{ getVo2maxforColorAgeAndSex("green", age, sex) }}
                            ml/kg/min
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            {{ getVo2maxforColorAgeAndSex("amber", age, sex) }}
                            ml/kg/min
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{ getVo2maxforColorAgeAndSex("red", age, sex) }}
                            ml/kg/min
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let vo2max of vo2maxs.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > vo2maxs.length - 6"
                          [tooltip]="
                            vo2max.vo2max +
                            ' ml/kg/min' +
                            ' on ' +
                            (vo2max.date_recorded
                              | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            getRagForAgeAndSex(vo2max.vo2max, age, sex)
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateVo2Max"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="info-stat info-stat-rag mb-3">
                <div class="info-stat-rag-current">
                  <app-m3-icon [icon]="'sts'" class="text-dark"></app-m3-icon>
                  <span class="info-stat-label"
                    >Physical function / strength</span
                  >
                  <span
                    *ngIf="sts60s[0]"
                    class="info-stat-stat text-rag-{{
                      getSts60RagForAgeAndSex(sts60s[0].sts, age, sex)
                    }}"
                    ><span *ngIf="sts60s"
                      ><span *ngIf="!sts60s[0]">-</span
                      ><span *ngIf="sts60s[0]">{{ sts60s[0].sts }}</span></span
                    ></span
                  >
                  <span class="info-stat-stat" *ngIf="!sts60s[0]">-</span>

                  <span class="info-stat-label">reps</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green">{{
                      sts60Service.getGoodSts(this.age, this.currentUser.gender)
                    }}</span>
                  </div>
                  <div class="rag-details-history" *ngIf="sts60s.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateSts60>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{ getSts60forColorAgeAndSex("green", age, sex) }}
                            reps
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            {{ getSts60forColorAgeAndSex("amber", age, sex) }}
                            reps
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{ getSts60forColorAgeAndSex("red", age, sex) }}
                            reps
                          </div>
                        </div>
                      </ng-template>
                      <ng-container
                        *ngFor="
                          let sts60 of sts60s.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > sts60s.length - 6"
                          [tooltip]="
                            sts60.sts +
                            ' on ' +
                            (sts60.date_recorded
                              | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            getSts60RagForAgeAndSex(sts60.sts, age, sex)
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateSts60"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="info-stat info-stat-rag mb-3">
                <div class="info-stat-rag-current">
                  <app-m3-icon [icon]="'zzz'"></app-m3-icon>
                  <span class="info-stat-label"
                    >My average daily sleep time</span
                  >
                  <span
                    *ngIf="sleepAverage"
                    class="info-stat-stat text-rag-{{
                      getSleepRagForAge(sleepAverage, age)
                    }}"
                    >{{ sleepAverage | minsToHours }}</span
                  >
                  <span class="info-stat-stat" *ngIf="!sleepAverage">-</span>
                  <span class="info-stat-label">hours</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green">{{ getAgeSleep(age) }}</span>
                    hours/day
                  </div>
                  <div class="rag-details-history" *ngIf="allSleeps.length > 0">
                    Daily History:
                    <div class="rag-history">
                      <ng-template #popTemplateSleep>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            <span *ngIf="age < 26"> 7-9 hours/day </span>
                            <span *ngIf="age > 25 && age < 65">
                              7-9 hours/day
                            </span>
                            <span *ngIf="age > 64"> 7-8 hours/day </span>
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>

                            <span *ngIf="age < 26"> 10-11 or 6 hours/day </span>
                            <span *ngIf="age > 25 && age < 65">
                              10 or 6 hours/day
                            </span>
                            <span *ngIf="age > 64"> 9 or 5-6 hours/day </span>
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>

                            <span *ngIf="age < 26">
                              &lt;6 or ≥11 hours/day
                            </span>
                            <span *ngIf="age > 25 && age < 65">
                              &lt;6 or ≥10 hours/day
                            </span>
                            <span *ngIf="age > 64">
                              &lt;5 or ≥9 hours/day
                            </span>
                          </div>
                        </div>
                      </ng-template>
                      <ng-container
                        *ngFor="
                          let sleep of allSleeps.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > allSleeps.length - 6"
                          [tooltip]="
                            (sleep.sleep | minsToHours) +
                            ' on ' +
                            (sleep.date_recorded
                              | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            getSleepRagForAge(sleep.sleep, age)
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateSleep"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="info-stat info-stat-rag mb-3">
                <div class="info-stat-rag-current">
                  <app-m3-icon [icon]="'sitting-alt'"></app-m3-icon>
                  <span class="info-stat-label"
                    >My daily sitting time average</span
                  >
                  <span
                    *ngIf="sittingAverage"
                    class="info-stat-stat text-rag-{{
                      getSittingRag(sittingAverage, currentUser.device)
                    }}"
                    >{{ sittingAverage | minsToHours }}</span
                  >
                  <span *ngIf="!sittingAverage" class="info-stat-stat">-</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target" *ngIf="sittingTarget">
                    Target:
                    <span class="text-rag-green">{{
                      sittingTarget | number
                    }}</span>
                    hours
                  </div>
                  <div
                    class="rag-details-history"
                    *ngIf="latestSittings.length > 0"
                  >
                    Daily History:
                    <div class="rag-history">
                      <ng-template #popTemplateSitting>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            <span *ngIf="currentUser.device != 'manual'"
                              >&lt;9 hours/day</span
                            >
                            <span *ngIf="currentUser.device == 'manual'"
                              >&lt;6 hours/day</span
                            >
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>

                            <span *ngIf="currentUser.device != 'manual'"
                              >9-10 hours/day</span
                            >
                            <span *ngIf="currentUser.device == 'manual'"
                              >6-8 hours/day</span
                            >
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>

                            <span *ngIf="currentUser.device != 'manual'"
                              >≥10 hours/day</span
                            >
                            <span *ngIf="currentUser.device == 'manual'"
                              >≥8 hours/day</span
                            >
                          </div>
                        </div>
                      </ng-template>
                      <ng-container
                        *ngFor="
                          let activity of latestSittings.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > latestSittings.length - 6"
                          [tooltip]="
                            (activity.sedentary_minutes | minsToHours) +
                            ' on ' +
                            (activity.activity_date
                              | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            getSittingRag(
                              activity.sedentary_minutes,
                              currentUser.device
                            )
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateSitting"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="info-stat info-stat-rag mb-2">
                <div class="info-stat-rag-current">
                  <app-m3-icon [icon]="'non-stepping'"></app-m3-icon>
                  <span class="info-stat-label"
                    >My total moderate to vigorous physical activity
                    (MVPA)</span
                  >
                  <span
                    class="info-stat-stat"
                    [ngClass]="{
                      'text-rag-green':
                        +nonSteppingWeeklyTotal + activeMinsThisWeek >= 150
                    }"
                    >{{
                      nonSteppingWeeklyTotal + activeMinsThisWeek
                        | number : "1.0-0"
                    }}</span
                  >
                  <span class="info-stat-label">min/week</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green">150 min/week</span>
                  </div>
                  <div
                    class="rag-details-history"
                    *ngIf="activitiesByWeekTotals.length > 0"
                  >
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateNonStepping>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            >= 150 mins/week
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            &lt; 150 mins/week
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let week of activitiesByWeekTotals.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > activitiesByWeekTotals.length - 6"
                          [tooltip]="
                            week.minutes + ' mins/week, ' + week.date_recorded
                          "
                          class="rag-circle"
                          [ngClass]="{
                            'bg-rag-green': +week.minutes >= 150,
                            'bg-rag-red': +week.minutes < 150
                          }"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateNonStepping"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- end activity -->

          <!-- Wellbeing -->

          <div class="head-block">
            <h3>
              <a
                class="more-link"
                [routerLink]="['/', 'm3', 'health-data', 'wellbeing']"
                ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
              ></a>
              <app-m3-icon [icon]="'wellbeing'"></app-m3-icon>
              Wellbeing
            </h3>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">PHQ-9 Score (depression)</span>
                  <span
                    *ngIf="phq9s[0]"
                    class="info-stat-stat text-rag-{{
                      userPhq9Service.getRag(phq9s[0].phq9)
                    }}"
                  >
                    <span *ngIf="phq9s[0]">{{ phq9s[0].phq9 }}</span
                    ><span *ngIf="!phq9s[0]">?</span></span
                  >
                  <span *ngIf="!phq9s[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label">&nbsp;</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green">
                      {{ userPhq9Service.getPhq9forColor("green") }}
                    </span>
                  </div>
                  <div class="rag-details-history" *ngIf="phq9s.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplatePhq9>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{ userPhq9Service.getPhq9forColor("green") }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            {{ userPhq9Service.getPhq9forColor("amber") }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{ userPhq9Service.getPhq9forColor("red") }}
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let phq9 of phq9s.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > phq9s.length - 6"
                          [tooltip]="
                            phq9.phq9 +
                            ' ' +
                            ' on ' +
                            (phq9.date_recorded | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            userPhq9Service.getRag(phq9.phq9)
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplatePhq9"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label"
                    >PAID-20 Score (emotional disresss)</span
                  >
                  <span
                    *ngIf="paids[0]"
                    class="info-stat-stat text-rag-{{
                      userPaidService.getRag(paids[0].paid)
                    }}"
                  >
                    <span *ngIf="paids[0]">{{ paids[0].paid }}</span
                    ><span *ngIf="!paids[0]">?</span></span
                  >
                  <span *ngIf="!paids[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label">&nbsp;</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green">
                      {{ userPaidService.getPaidforColor("green") }}
                    </span>
                  </div>
                  <div class="rag-details-history" *ngIf="paids.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplatePaid>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{ userPaidService.getPaidforColor("green") }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            {{ userPaidService.getPaidforColor("amber") }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{ userPaidService.getPaidforColor("red") }}
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let paid of paids.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > paids.length - 6"
                          [tooltip]="
                            paid.paid +
                            ' ' +
                            ' on ' +
                            (paid.date_recorded | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            userPaidService.getRag(paid.paid)
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplatePaid"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label"
                    >MBTQ Score (treatment burden)</span
                  >

                  <span
                    *ngIf="mbtqs[0]"
                    class="info-stat-stat text-rag-{{
                      userMbtqService.getRag(mbtqs[0].mbtq)
                    }}"
                  >
                    <span *ngIf="mbtqs[0]">{{ mbtqs[0].mbtq }}</span
                    ><span *ngIf="!mbtqs[0]">?</span></span
                  >
                  <span *ngIf="!mbtqs[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label">&nbsp;</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green">
                      {{ userMbtqService.getMbtqforColor("green") }}
                    </span>
                  </div>
                  <div class="rag-details-history" *ngIf="mbtqs.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateMbtq>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{ userMbtqService.getMbtqforColor("green") }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            {{ userMbtqService.getMbtqforColor("amber") }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{ userMbtqService.getMbtqforColor("red") }}
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let mbtq of mbtqs.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > mbtqs.length - 6"
                          [tooltip]="
                            mbtq.mbtq +
                            ' ' +
                            ' on ' +
                            (mbtq.date_recorded | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            userMbtqService.getRag(mbtq.mbtq)
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateMbtq"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- end Wellbeing -->

          <!-- Frailty -->

          <ng-container *ngIf="showFrailty">
          <div class="head-block">
            <h3>
              <a
                class="more-link"
                [routerLink]="['/', 'm3', 'health-data', 'independence']"
                ><app-m3-icon [icon]="'more'" [style]="'thin'"></app-m3-icon
              ></a>
              <app-m3-icon [icon]="'independence'"></app-m3-icon>
              Independence
            </h3>
          </div>


          <div class="card">
            <div class="card-body">
              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">Falls in the last year</span>
                  <span
                    *ngIf="falls[0]"
                    class="info-stat-stat text-rag-{{
                      userFallService.getRag(falls[0].falls)
                    }}"
                  >
                    <span *ngIf="falls[0]">{{ falls[0].falls }}</span
                    ><span *ngIf="!falls[0]">?</span></span
                  >
                  <span *ngIf="!falls[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label">&nbsp;</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green">
                      {{userFallService.getRangeforColor("green")}}
                    </span>
                  </div>
                  <div class="rag-details-history" *ngIf="falls.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateFall>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{ userFallService.getRangeforColor("green") }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            {{ userFallService.getRangeforColor("amber") }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{ userFallService.getRangeforColor("red") }}
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let fall of falls.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > falls.length - 6"
                          [tooltip]="
                            fall.falls +
                            ' ' +
                            ' on ' +
                            (fall.date_recorded | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            userFallService.getRag(fall.falls)
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateFall"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
           
              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">180&deg; Turn (balance)</span>
                  <span
                    *ngIf="user180Turns[0]"
                    class="info-stat-stat text-rag-{{
                      user180TurnService.getRag(user180Turns[0].steps)
                    }}"
                  >
                    <span *ngIf="user180Turns[0]">{{ user180Turns[0].steps}}</span
                    ><span *ngIf="!user180Turns[0]">?</span></span
                  >
                  <span *ngIf="!user180Turns[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label">steps</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green">
                      {{ user180TurnService.getRangeforColor("green") }} steps
                    </span>
                  </div>
                  <div class="rag-details-history" *ngIf="user180Turns.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateuser180Turn>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{ user180TurnService.getRangeforColor("green") }} steps
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{ user180TurnService.getRangeforColor("red") }} steps
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let user180_turn of user180Turns.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > user180Turns.length - 6"
                          [tooltip]="
                            user180_turn.steps+
                            '  steps ' +
                            ' on ' +
                            (user180_turn.date_recorded | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            user180TurnService.getRag(user180_turn.steps)
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateuser180Turn"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">Short FES-I (confidence with balance)</span>
                  <span
                    *ngIf="shortFess[0]"
                    class="info-stat-stat text-rag-{{
                      userShortFesService.getRag(shortFess[0].short_fes)
                    }}"
                  >
                    <span *ngIf="shortFess[0]">{{ shortFess[0].short_fes }}</span
                    ><span *ngIf="!shortFess[0]">?</span></span
                  >
                  <span *ngIf="!shortFess[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label">&nbsp;</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green">
                      {{ userShortFesService.getRangeforColor("green") }}
                    </span>
                  </div>
                  <div class="rag-details-history" *ngIf="shortFess.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateShortFes>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{ userShortFesService.getRangeforColor("green") }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            {{ userShortFesService.getRangeforColor("amber") }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{ userShortFesService.getRangeforColor("red") }}
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let shortFes of shortFess.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > shortFess.length - 6"
                          [tooltip]="
                            shortFes.short_fes +
                            ' ' +
                            ' on ' +
                            (shortFes.date_recorded | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            userShortFesService.getRag(shortFes.short_fes)
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateShortFes"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">SSS8 (symptoms)</span>
                  <span
                    *ngIf="sss8s[0]"
                    class="info-stat-stat text-rag-{{
                      userSss8Service.getRag(sss8s[0].sss8)
                    }}"
                  >
                    <span *ngIf="sss8s[0]">{{ sss8s[0].sss8 }}</span
                    ><span *ngIf="!sss8s[0]">?</span></span
                  >
                  <span *ngIf="!sss8s[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label">&nbsp;</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green">
                      {{ userSss8Service.getRangeforColor("green") }}
                    </span>
                  </div>
                  <div class="rag-details-history" *ngIf="sss8s.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateSss8>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{ userSss8Service.getRangeforColor("green") }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            {{ userSss8Service.getRangeforColor("amber") }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{ userSss8Service.getRangeforColor("red") }}
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let sss8 of sss8s.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > sss8s.length - 6"
                          [tooltip]="
                            sss8.sss8 +
                            ' ' +
                            ' on ' +
                            (sss8.date_recorded | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            userSss8Service.getRag(sss8.sss8)
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateSss8"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="info-stat info-stat-rag info-stat-no-icon mb-3">
                <div class="info-stat-rag-current">
                  <span class="info-stat-label">Distress Thermometer (emotional distress)</span>
                  <span
                    *ngIf="distressScores[0]"
                    class="info-stat-stat text-rag-{{
                      userDistressScoreService.getRag(distressScores[0].distress_score)
                    }}"
                  >
                    <span *ngIf="distressScores[0]">{{ distressScores[0].distress_score }}</span
                    ><span *ngIf="!distressScores[0]">?</span></span
                  >
                  <span *ngIf="!distressScores[0]" class="info-stat-stat">-</span>
                  <span class="info-stat-label">&nbsp;</span>
                </div>

                <div class="rag-details">
                  <div class="rag-details-target">
                    Target:
                    <span class="text-rag-green">
                      {{ userDistressScoreService.getRangeforColor("green") }}
                    </span>
                  </div>
                  <div class="rag-details-history" *ngIf="distressScores.length > 0">
                    History:
                    <div class="rag-history">
                      <ng-template #popTemplateDistressScore>
                        <div>
                          <div>Range:</div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-green mr-1"></span>
                            {{ userDistressScoreService.getRangeforColor("green") }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-amber mr-1"></span>
                            {{ userDistressScoreService.getRangeforColor("amber") }}
                          </div>
                          <div class="d-flex">
                            <span class="rag-circle bg-rag-red mr-1"></span>
                            {{ userDistressScoreService.getRangeforColor("red") }}
                          </div>
                        </div></ng-template
                      >
                      <ng-container
                        *ngFor="
                          let distressScore of distressScores.slice().reverse();
                          let index = index
                        "
                      >
                        <div
                          *ngIf="index > distressScores.length - 6"
                          [tooltip]="
                            distressScore.distress_score +
                            ' ' +
                            ' on ' +
                            (distressScore.distress_score | dateFormatter : 'Do MMM YYYY')
                          "
                          class="rag-circle bg-rag-{{
                            userDistressScoreService.getRag(distressScore.distress_score)
                          }}"
                        ></div>
                      </ng-container>
                      <div
                        class="rag-circle pointer"
                        [popover]="popTemplateDistressScore"
                      >
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['fas', 'info']"></fa-icon
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>




            </div>
          </div>
        </ng-container>


        </div>
      </div>
    </div>
  </div>
</div>
