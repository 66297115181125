import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class Eq5dService {
  error = new Subject<string>();

  cacheTime = new Date();
  cacheLifetime = 10 * 60 * 1000;
  cachedResponses: any = {};

  constructor(private http: HttpClient, private router: Router) {}

  fetchResponse(stage: number): Observable<any> {
    const now = new Date();

    if (
      !this.cachedResponses ||
      Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime
    ) {
      this.cacheTime = new Date();

      let params = new HttpParams();
      params = params.append('stage', stage.toString());

      this.cachedResponses = this.http
        .get<any>(environment.apiUrl + '/eq5d_responses', {
          responseType: 'json',
          params,
        })
        .pipe(
          map((responseData) => {
            if (responseData['_embedded'].eq5d_responses[0]) {
              return responseData['_embedded'].eq5d_responses[0];
            } else {
              return null;
            }
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.cachedResponses;
  }

  fetchAll() {
    let params = new HttpParams();

    return this.http
      .get<any>(environment.apiUrl + '/eq5d_responses', {
        responseType: 'json',
        params,
      })
      .pipe(
        map((responseData) => {
          return responseData['_embedded'].eq5d_responses;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
  saveResponse(
    stage: any,
    mobility: any,
    selfcare: any,
    activities: any,
    pain: any,
    anxiety: any,
    health_rating: any
  ) {
    this.clearCache();
    const postData = {
      stage,
      mobility,
      selfcare,
      activities,
      pain,
      anxiety,
      health_rating,
    };
    return this.http.post<any>(
      environment.apiUrl + '/eq5d_responses',
      postData,
      {
        observe: 'response',
      }
    );
  }

  checkTake(user) {
    let stagesTake: any = [];
    const add1Week = moment(user.created).add(1, 'week');
    const add1Month = moment(user.created).add(1, 'month');
    const add2Month = moment(user.created).add(2, 'month');
    const add3Month = moment(user.created).add(3, 'month');
    const add4Month = moment(user.created).add(4, 'month');
    const add5Month = moment(user.created).add(5, 'month');
    const add6Month = moment(user.created).add(6, 'month');
    const now = moment();

    this.fetchAll().subscribe((responseData) => {
      responseData.forEach((eq5dResponse) => {
        stagesTake.push(eq5dResponse.stage);
      });

      // stage 0 complete?
      // Baseline - the first day of the 3 day monitoring period or alternatively when they next log on.
      if (stagesTake.indexOf(0) === -1 && now < add1Week) {
        this.router.navigate(['/assessments', 'eq5d', 0]);
      }

      // 1 & 3  cut off +1 month
      if (stagesTake.indexOf(1) === -1 && now > add1Month && now < add2Month) {
        this.router.navigate(['/assessments', 'eq5d', 1]);
      }
      if (stagesTake.indexOf(3) === -1 && now > add3Month && now < add4Month) {
        this.router.navigate(['/assessments', 'eq5d', 3]);
      }
      // 6 month no cut off*/
      if (stagesTake.indexOf(6) === -1 && now > add6Month) {
        this.router.navigate(['/assessments', 'eq5d', 6]);
      }
    });
  }

  clearCache() {
    this.cachedResponses = null;
  }
}
