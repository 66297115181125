<div id="skipnav">
  <p class="skipnav">
    <a (click)="onClick($event, 'mainContent')" href="#mainContent"
      >Skip to Content</a
    >
  </p>
</div>
<div
  class="top-container"
  [ngClass]="{
    'container-fluid':
      routerOutlet.activatedRouteData.fluidContainer || m3 == 1,
    container: !routerOutlet.activatedRouteData.fluidContainer && m3 != 1,
    m3: m3 == 1
  }"
  (window:resize)="onResize($event)"
>
  <div class="row">
    <div
      class="col-lg-3 col-md-4 hidden-sm hidden-xs"
      *ngIf="currentUser && !routerOutlet.activatedRouteData.hideSiebar"
    >
      <app-sidebar></app-sidebar>
    </div>

    <div
      class="col-sm-12 col-md-8 col-lg-9"
      [ngClass]="{
        'col-sm-12 col-md-8 col-lg-9': currentUser,
        'col-sm-12 col-md-12 col-lg-12':
          !currentUser || routerOutlet.activatedRouteData.hideSiebar
      }"
    >
      <div ng-hide="hideNav !== null" class="app-container">
        <!-- Static navbar -->
        <app-navbar
          [pageTitle]="routerOutlet.activatedRouteData.pageTitle"
          *ngIf="currentUser && !routerOutlet.activatedRouteData.hideNavbar"
        ></app-navbar>
      </div>
      <div class="app-container" #mainContainer>
        <div
          [@routeAnimations]="showMobile(routerOutlet)"
          [@routeAnimationsDesktop]="showDesktop(routerOutlet)"
        >
          <router-outlet #routerOutlet="outlet"></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer-nav
  *ngIf="!routerOutlet.activatedRouteData.hideFooterNav"
></app-footer-nav>
<app-add-to-homescreen></app-add-to-homescreen>
<app-award
  *ngIf="showAward"
  [activeAward]="activeAward"
  (closeAward)="onCloseAward($event)"
></app-award>
