import { PageViewService } from './../../services/page-view.service';
import { environment } from './../../../environments/environment';
import { DragAndDropService } from './../../services/drag-and-drop.service';
import { forkJoin } from 'rxjs';
import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { BoosterSessionsService } from './../../booster-sessions/booster-sessions.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { AssessmentsService } from 'src/app/services/assessments.service';

@Component({
  selector: 'app-booster-session-step',
  templateUrl: './booster-session-step.component.html',
  styleUrls: ['./booster-session-step.component.scss'],
})
export class BoosterSessionStepComponent implements OnInit, OnDestroy {
  isFetching;
  boosterSessionStep;
  boosterSession;
  boosterSessionProgramme;
  currentUser;
  currentProgramme;
  boosterSessionStepId;
  prevStep;
  nextStep;
  boosterSessionId;
  boosterSessionSteps;
  sessionProgress;
  dragAndDrop;
  dragItems;
  dropItems;
  prevSession;
  nextSession;
  environment = environment;
  start;
  firstView = true;

  constructor(
    private boosterSessionService: BoosterSessionsService,
    private route: ActivatedRoute,
    private boosterSessionsService: BoosterSessionsService,
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private router: Router,
    private dragAndDropService: DragAndDropService,
    private titleService: Title,
    private pageViewservice: PageViewService,
    private assessmentService: AssessmentsService
  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
        if (this.currentUser) {
          if (this.currentUser.buddy) {
            this.router.navigate(['/dashboard']);
          }
        }
      });
    }

    //
    this.route.params.subscribe((params) => {
      if (this.firstView) {
        this.start = moment();
        this.firstView = false;
      } else {
        this.savePageView();
        this.start = moment();
      }

      this.prevStep = null;
      this.nextStep = null;
      this.boosterSessionStepId = this.route.snapshot.params.boosterSessionStepId;
      this.isFetching = true;
      this.boosterSessionStep = null;

      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (this.currentProgramme) {
          // get the step first
          this.boosterSessionsService
            .fetchStep(this.boosterSessionStepId)
            .subscribe((stepResponse) => {
              //console.log(stepResponse);
              this.boosterSessionId = stepResponse.booster_session_id;
              this.boosterSessionStep = stepResponse;

              // what other data do we need?
              if (+this.boosterSessionStep.booster_session_step_type_id == 3) {
                const dragAndDropId = this.boosterSessionStep
                  .booster_session_step_type_ref_id;
                forkJoin({
                  dragAndDrop: this.dragAndDropService.fetch(dragAndDropId),
                  dragItems: this.dragAndDropService.fetchDragItems(
                    dragAndDropId
                  ),
                  dropItems: this.dragAndDropService.fetchDropItems(
                    dragAndDropId
                  ),
                }).subscribe((dragDropResponse) => {
                  this.dragAndDrop = dragDropResponse.dragAndDrop;
                  this.dragItems = dragDropResponse.dragItems;
                  this.dropItems = dragDropResponse.dropItems;
                });
              }

              forkJoin({
                boosterSession: this.boosterSessionsService.fetch(
                  this.boosterSessionId
                ),
                boosterSessionSteps: this.boosterSessionsService.fetchSteps(
                  this.boosterSessionId
                ),
                sessionProgress: this.boosterSessionsService.getSessionProgress(
                  this.boosterSessionId
                ),
                programmeContent: this.programmeService.getProgrammeContent(
                  this.currentProgramme.programme_id
                ),
              }).subscribe((responseData) => {
                this.isFetching = false;
                this.boosterSession = responseData.boosterSession;

                // can they access?
                if (
                  this.boosterSession.status === 'unavailable' &&
                  +this.currentUser.is_admin == 0
                ) {
                  this.router.navigate(['/dashboard']);
                }

                this.boosterSessionSteps = responseData.boosterSessionSteps;
                this.sessionProgress = responseData.sessionProgress;

                if (this.sessionProgress) {
                  if (this.sessionProgress.pages_viewed) {
                    const pagesViewed = JSON.parse(
                      this.sessionProgress.pages_viewed
                    );

                    if (Array.isArray(pagesViewed)) {
                      // set the status..
                      this.boosterSessionSteps.forEach((item, index) => {
                        if (
                          pagesViewed.indexOf(item.booster_session_step_id) !==
                          -1
                        ) {
                          this.boosterSessionSteps[index].progress = 'viewed';
                        }
                      });
                    }
                  }
                }
                // do prev and next
                const boosterSessions = responseData.programmeContent.booster_sessions.filter(
                  (boosterSession) => boosterSession.release_date != 'O'
                );
                this.boosterSessionSteps.forEach((step, index) => {
                  if (
                    step.booster_session_step_id == this.boosterSessionStepId
                  ) {
                    if (this.boosterSessionSteps[index - 1]) {
                      this.prevStep = this.boosterSessionSteps[index - 1];
                    }
                    if (this.boosterSessionSteps[index + 1]) {
                      this.nextStep = this.boosterSessionSteps[index + 1];
                    }
                  }
                });

                // do prev and next session
                // do prev and next
                boosterSessions.forEach((session, index) => {
                  if (session.booster_session_id == this.boosterSessionId) {
                    this.boosterSessionProgramme = session;
                    // setHtmlTitle($scope.programmeBoosterSession.booster_session_title );

                    if (boosterSessions[index - 1]) {
                      this.prevSession = boosterSessions[index - 1];
                    }
                    if (boosterSessions[index + 1]) {
                      this.nextSession = boosterSessions[index + 1];
                    }
                  }
                });

                this.titleService.setTitle(
                  environment.title +
                    ' | Booster Sessions | ' +
                    this.boosterSession.title +
                    ' | ' +
                    this.boosterSessionStep.title
                );
              });
            });
        }
      });
    });
  }

  checkAssessment(session){
    if (session.assessment_id) {
      // ok, do we have any results?
      const assessmentIds = JSON.parse(session.assessment_id);
      let sentOff = false;
      assessmentIds.forEach((assessmentId) => {
        this.assessmentService
          .fetchResults(assessmentId, 'post', this.boosterSessionId)
          .subscribe((resultsResponse) => {
            if (resultsResponse.length < 1 && sentOff == false) {
              sentOff = true;
              setTimeout(() => {
                // take it
                this.router.navigate([
                  '/assessments',
                  'take',
                  assessmentId,
                  'post',
                  this.boosterSessionId,
                ]);
                return;
              }, 500);
            }
          });
      });
    }
  }

  ngOnDestroy() {
    this.savePageView();
    // are we on the last step?
    if(!this.nextStep){
      this.checkAssessment(this.boosterSession);
    }
  }

  savePageView() {
    const end = moment();
    const timeSpent = Math.round((+end - this.start) / 1000);

    // post
    this.pageViewservice
      .create(
        timeSpent,
        this.boosterSessionStep.booster_session_id,
        this.boosterSessionStep.booster_session_step_id,
        1,
        1
      )
      .subscribe(
        (responseData) => {
          this.pageViewservice.refreshProgress();
        },
        (error) => {}
      );
  }
}
