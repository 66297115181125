<nav class="subnav">
  <h2><span class="icon-wrap"
    ><fa-icon [icon]="['fas', 'map-signs']"></fa-icon
  ></span> DECISION MAKER</h2>
</nav>
<div class="dm-pager">
  <div [ngClass]="{ current: step === 1, complete: step > 1 }">
    <span class="icon-wrap fa fa-circle"
      ><fa-icon [icon]="['fas', 'circle']"></fa-icon
    ></span>
    <span class="icon-wrap fa fa-check-circle"
      ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
    ></span>
  </div>
  <div [ngClass]="{ current: step === 2, complete: step > 2 }">
    <span class="icon-wrap fa fa-circle"
      ><fa-icon [icon]="['fas', 'circle']"></fa-icon
    ></span>
    <span class="icon-wrap fa fa-check-circle"
      ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
    ></span>
  </div>
  <div [ngClass]="{ current: step === 3, complete: step > 3 }">
    <span class="icon-wrap fa fa-circle"
      ><fa-icon [icon]="['fas', 'circle']"></fa-icon
    ></span>
    <span class="icon-wrap fa fa-check-circle"
      ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
    ></span>
  </div>
  <div [ngClass]="{ current: step === 4, complete: step > 4 }">
    <span class="icon-wrap fa fa-circle"
      ><fa-icon [icon]="['fas', 'circle']"></fa-icon
    ></span>
    <span class="icon-wrap fa fa-check-circle"
      ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
    ></span>
  </div>
  <div [ngClass]="{ current: step === 5, complete: step > 5 }">
    <span class="icon-wrap fa fa-circle"
      ><fa-icon [icon]="['fas', 'circle']"></fa-icon
    ></span>
    <span class="icon-wrap fa fa-check-circle"
      ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
    ></span>
  </div>
  <div [ngClass]="{ current: step === 6, complete: step > 6 }">
    <span class="icon-wrap fa fa-circle"
      ><fa-icon [icon]="['fas', 'circle']"></fa-icon
    ></span>
    <span class="icon-wrap fa fa-check-circle"
      ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
    ></span>
  </div>
  <div [ngClass]="{ current: step === 7, complete: step > 7 }">
    <span class="icon-wrap fa fa-circle"
      ><fa-icon [icon]="['fas', 'circle']"></fa-icon
    ></span>
    <span class="icon-wrap fa fa-check-circle"
      ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
    ></span>
  </div>
  <div [ngClass]="{ complete: step === 8 }">
    <span class="icon-wrap fa fa-circle"
      ><fa-icon [icon]="['fas', 'circle']"></fa-icon
    ></span>
    <span class="icon-wrap fa fa-check-circle"
      ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
    ></span>
  </div>
</div>
<div class="decision-maker">
  <div *ngIf="step == 1">
    <p class="instruction text-center">What change do you want to focus on?</p>

    <div
      class="toggle-header toggle-header-left"
      *ngFor="let decision of decisionData; let index = index"
      (click)="focus = decision.title; setStep(2); focusIndex = index"
    >
      <a class="toggle-nav toggle-nav-expand"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
      ></a>
      <div class="toggle-header-title">
        <span class="icon-wrap"
          ><fa-icon [icon]="['fas', decision.icon]"></fa-icon
        ></span>
        {{ decision.title | uppercase }}
      </div>
    </div>

    <div class="toggle-header toggle-header-left">
      <a class="toggle-nav toggle-nav-expand"></a>
      <div class="toggle-header-title">
        <span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'user-headset']"></fa-icon
        ></span>
        PERSONALISED GOAL
        <div class="input-group mt-10">
          <input
            type="text"
            class="form-control"
            placeholder="Enter your own..."
            [(ngModel)]="otherOptions.area"
          />
          <span class="input-group-btn">
            <button
              class="btn btn-primary"
              type="button"
              (click)="focus = otherOptions.area; setStep(2)"
              [disabled]="!otherOptions.area"
            >
              NEXT
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="step == 2">
    <p class="instruction text-center">How important is this change to me?</p>
    <p class="instruction-sm text-center">
      From 1 (low importance) to 10 (high importance)
    </p>

    <div class="target-controls text-center">
      <span class="num">{{ importance }}</span>
      <span class="target-control-btns">
        <button (click)="importanceUp()">
          <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'chevron-up']"></fa-icon
          ></span>
        </button>
        <button (click)="importanceDown()">
          <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'chevron-down']"></fa-icon
          ></span>
        </button>
      </span>
    </div>

    <hr class="divider" />
    <div class="container">
      <div class="row">
        <div class="col-6">
          <a *ngIf="step > 1" (click)="prev()" class="btn btn-primary btn-white"
            ><span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'arrow-circle-left']"></fa-icon
            ></span>
            PREV</a
          >
        </div>
        <div class="col-6 text-right">
          <a
            *ngIf="step < 5"
            (click)="checkImportance()"
            class="btn btn-primary btn-white"
            >NEXT
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
          ></a>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="step == 3">
    <p class="instruction text-center">How do you plan to achieve this?</p>
    <div
      class="toggle-header toggle-header-left"
      *ngFor="let _task of decisionData[focusIndex].how"
      (click)="setStep(4); task = _task.title"
    >
      <a class="toggle-nav toggle-nav-expand"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
      ></a>
      <div class="toggle-header-title">{{ _task.title | uppercase }}</div>
    </div>

    <div class="toggle-header toggle-header-left">
      <a class="toggle-nav toggle-nav-expand"></a>
      <div class="toggle-header-title">
        OTHER
        <div class="input-group mt-10">
          <input
            type="text"
            class="form-control"
            placeholder="Enter your own..."
            [(ngModel)]="otherOptions.how"
          />
          <span class="input-group-btn">
            <button
              class="btn btn-primary"
              type="button"
              (click)="task = otherOptions.how; setStep(4)"
              [disabled]="!otherOptions.how"
            >
              NEXT
            </button>
          </span>
        </div>
      </div>
    </div>

    <hr class="divider" />

    <div class="container">
      <div class="row">
        <div class="col-6">
          <a *ngIf="step > 1" (click)="prev()" class="btn btn-primary btn-white"
            ><span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'arrow-circle-left']"></fa-icon
            ></span>
            PREV</a
          >
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="step == 4">
    <p class="instruction text-center">
      What is the main barrier that may prevent you from achieving this? Choose
      one
    </p>
    <div
      class="toggle-header toggle-header-left"
      *ngFor="
        let _barrier of decisionData[focusIndex].barriers;
        let index = index
      "
      (click)="setStep(5); barrier = _barrier.barrier; barrierIndex = index"
    >
      <a class="toggle-nav toggle-nav-expand"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
      ></a>
      <div class="toggle-header-title">{{ _barrier.barrier | uppercase }}</div>
    </div>
    <div class="toggle-header toggle-header-left">
      <a class="toggle-nav toggle-nav-expand"></a>
      <div class="toggle-header-title">
        OTHER
        <div class="input-group mt-10">
          <input
            type="text"
            class="form-control"
            placeholder="Enter your own..."
            [(ngModel)]="otherOptions.barrier"
          />
          <span class="input-group-btn">
            <button
              class="btn btn-primary"
              type="button"
              (click)="barrier = otherOptions.barrier; setStep(5)"
              [disabled]="!otherOptions.barrier"
            >
              NEXT
            </button>
          </span>
        </div>
      </div>
    </div>

    <hr class="divider" />
    <div class="container">
      <div class="row">
        <div class="col-6">
          <a *ngIf="step > 1" (click)="prev()" class="btn btn-primary btn-white"
            ><span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'arrow-circle-left']"></fa-icon
            ></span>
            PREV</a
          >
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="step == 5">
    <p class="instruction text-center">How will I overcome this?</p>
    <div
      class="toggle-header toggle-header-left"
      *ngFor="
        let _overcome of decisionData[focusIndex].barriers[barrierIndex]
          .overcome
      "
      (click)="setStep(6); overcome = _overcome"
    >
      <a class="toggle-nav toggle-nav-expand"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
      ></a>
      <div class="toggle-header-title">{{ _overcome | uppercase }}</div>
    </div>
    <div class="toggle-header toggle-header-left">
      <a class="toggle-nav toggle-nav-expand"></a>
      <div class="toggle-header-title">
        OTHER
        <div class="input-group mt-10">
          <input
            type="text"
            class="form-control"
            placeholder="Enter your own..."
            [(ngModel)]="otherOptions.overcome"
          />
          <span class="input-group-btn">
            <button
              class="btn btn-primary"
              type="button"
              (click)="overcome = otherOptions.overcome; setStep(6)"
              [disabled]="!otherOptions.overcome"
            >
              NEXT
            </button>
          </span>
        </div>
      </div>
    </div>

    <hr class="divider" />
    <div class="container">
      <div class="row">
        <div class="col-6">
          <a *ngIf="step > 1" (click)="prev()" class="btn btn-primary btn-white"
            ><span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'arrow-circle-left']"></fa-icon
            ></span>
            PREV</a
          >
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="step == 6">
    <p class="instruction text-center">
      How confident are you in making this change?
    </p>
    <p class="instruction-sm text-center">
      From 1 (low confidence) to 10 (high confidence)
    </p>

    <div class="target-controls text-center">
      <span class="num">{{ confidence }}</span>
      <span class="target-control-btns">
        <button (click)="confidenceUp()">
          <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'chevron-up']"></fa-icon
          ></span>
        </button>
        <button (click)="confidenceDown()">
          <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'chevron-down']"></fa-icon
          ></span>
        </button>
      </span>
    </div>

    <p class="instruction text-center">
      Think about who or what could support you to achieve this goal e.g.
      family/friends.
    </p>

    <hr class="divider" />
    <div class="container">
      <div class="row">
        <div class="col-6">
          <a *ngIf="step > 1" (click)="prev()" class="btn btn-primary btn-white"
            ><span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'arrow-circle-left']"></fa-icon
            ></span>
            PREV</a
          >
        </div>
        <div class="col-6 text-right">
          <a
            *ngIf="step < 7"
            (click)="checkConfidence()"
            class="btn btn-primary btn-white"
            >NEXT
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
          ></a>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="step == 7">
    <p class="instruction text-center">When will you review it?</p>
    <div class="target-controls text-center">
      <div class="form-group">
        <div class="input-group-fa input-tracker input-tracker-sm">
          <i class="fa fa-calendar" aria-hidden="true"></i>
          <input
            type="text"
            placeholder="DD/MM/YYYY"
            class="form-control"
            bsDatepicker
            autocomplete="off"
            [bsConfig]="{
              dateInputFormat: 'DD/MM/YYYY',
              containerClass: 'theme-cv',
              showWeekNumbers: false
            }"
            [(ngModel)]="date"
            required
            [minDate]="minDate"
            [bsValue]="date"
            #reviewDate="ngModel"
          />
          <!--input (click)="open1()" style="text-indent:50px;" type="text" class="form-control input-tracker  input-tracker-sm" uib-datepicker-popup="{{format}}" [(ngModel)]="date" is-open="popup1.opened" datepicker-options="dateOptions" ng-required="true" close-text="Close" alt-input-formats="altInputFormats" /-->
        </div>
      </div>
    </div>

    <hr class="divider" />
    <div class="container">
      <div class="row">
        <div class="col-6">
          <a *ngIf="step > 1" (click)="prev()" class="btn btn-primary btn-white"
            ><span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'arrow-circle-left']"></fa-icon
            ></span>
            PREV</a
          >
        </div>
        <div class="col-6 text-right">
          <button type="button" (click)="setStep(8); save()" class="btn btn-primary btn-white" [disabled]="reviewDate.invalid"
            >NEXT
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
          ></button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="step == 8">
    <p class="instruction text-center">Your decision in full…</p>
    <div class="well">
      <h5>FOCUS ON</h5>
      <div>{{ focus }}</div>

      <h5>IMPORTANCE</h5>
      <div>{{ importance }}/10</div>

      <h5>ACHIEVE THIS BY</h5>
      <div>{{ task }}</div>

      <h5>POTENTIAL BARRIER</h5>
      <div>{{ barrier }}</div>

      <h5>OVERCOME BY</h5>
      <div>{{ overcome }}</div>

      <h5>CONFIDENCE</h5>
      <div>{{ confidence }}/10</div>

      <h5>REVIEW DATE</h5>
      <div>{{ date | dateFormatter: "DD/MM/yyyy" }}</div>
    </div>
    <hr />
    <div class="container">
      <div class="row">
        <div class="col-6">
          <a (click)="restart()" class="btn btn-primary btn-white"
            ><span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'sync']"></fa-icon
            ></span>
            RESTART</a
          >
        </div>
        <div class="col-6 text-right">
          <a [routerLink]="['/decision-maker-saved']" class="btn btn-primary btn-white"
            >MY DECISIONS
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></span
          ></a>
        </div>
      </div>
    </div>
  </div>
</div>
<br /><br />
