import { interval } from 'rxjs';
import { NotificationService } from './../services/notification.service';
import { Router } from '@angular/router';
import { environment } from './../../environments/environment';
import { BoosterSessionsService } from './../booster-sessions/booster-sessions.service';
import { UserService } from './../services/user.service';
import { ProgrammeService } from './../services/programme.service';
import { AuthService } from './../auth/auth.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Input() pageTitle;
  isFetching = false;
  currentProgramme;
  currentUser;
  newNotifications;
  environment = environment;
  showUserMenu;
  showMobileMenu;
  notifications = [];
  notificationsInterval;

  constructor(
    private authService: AuthService,
    private programmeService: ProgrammeService,
    private userService: UserService,
    private router: Router,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((val) => {
      this.showUserMenu = false;
      this.showMobileMenu = false;
    });

    this.isFetching = true;
    // are we logged in?
    this.authService.user.subscribe((user) => {
      if (user) {
        this.programmeService.currentProgramme.subscribe((programmeData) => {
          if (programmeData) {
            this.currentProgramme = programmeData;
          }
        });
        this.userService.userData.subscribe((userDataResponse) => {
          this.currentUser = userDataResponse;
        });
      }
    });

    this.notificationService
      .getNotifications(0)
      .subscribe((notificationResponse) => {
        this.notifications = notificationResponse;
      });

    // check for notifications
    this.notificationsInterval = interval(60000).subscribe((count) => {
      this.notificationService.getNotifications(0).subscribe((responseData) => {
        this.notifications = responseData;
      });
    });
    this.notificationService.updateNotifications.subscribe((data) => {
      if (data) {
        this.notificationService
          .getNotifications(0)
          .subscribe((responseData) => {
            this.notifications = responseData;
          });
      }
    });
  }

  onToggleUserMenu() {
    this.showUserMenu = !this.showUserMenu;
  }
  onCloseMenu(event) {
    this.showUserMenu = false;
  }
  onToggleMobileMenu() {
    this.showMobileMenu = !this.showMobileMenu;
  }
  onCloseMobileMenu(event) {
    this.showMobileMenu = false;
  }

  onShowMobileMenu(event) {
    this.showMobileMenu = true;
    this.showUserMenu = false;
  }

  onShowUserMenu(event) {
    this.showMobileMenu = false;
    this.showUserMenu = true;
  }

  ngOnDestroy(): void {
    this.notificationsInterval.unsubscribe();
  }
}
