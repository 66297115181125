import { environment } from './../../../environments/environment';
import { ActivityService } from './../../services/activity.service';
import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { DateHelperService } from './../../services/date-helper.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { ChallengeService } from 'src/app/services/challenge.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-challenge-global-archive',
  templateUrl: './challenge-global-archive.component.html',
  styleUrls: ['./challenge-global-archive.component.scss']
})
export class ChallengeGlobalArchiveComponent implements OnInit, OnDestroy {
  startDate;
  endDate;
  challengeType;
  challenge;
  currentUser;
  currentProgramme;
  isFetching;
  currentWeek;
  previousChallenge;
  nextChallenge;
  challenge_results;
  currentLeader;
  environment = environment;
  toggleRow = { results: true };

  constructor(
    private route: ActivatedRoute,
    private dateHelperService: DateHelperService,
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private router: Router,
    private activityService: ActivityService
  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
        if (this.currentUser.buddy) {
          this.router.navigate(['/dashboard']);
        }
      });
    }

    //
    this.route.params.subscribe((params) => {
      this.startDate = this.route.snapshot.params.challengeStartDate;
      this.endDate = this.route.snapshot.params.challengeEndDate;
      this.challengeType = this.route.snapshot.params.challengeType;
      this.isFetching = true;

      this.startDate = moment(this.startDate).format('YYYY-MM-DD');
      this.endDate = moment(this.endDate).format('YYYY-MM-DD');
      this.challenge = {
        startDate: this.startDate,
        endDate: this.endDate,
        type: this.route.snapshot.params.challengeType,
        id: '',
      };

      this.challenge.id =
        this.challenge.type +
        ';' +
        this.challenge.startDate +
        ';' +
        this.challenge.endDate;
      this.currentWeek = this.dateHelperService.getWeekFor(
        this.challenge.startDate
      );

      if (this.challenge.type == 'WEEKEND') {
        this.challenge.title = 'Weekend Challenge';
        // previous challenge
        this.previousChallenge = {
          title: 'Monday - Friday Challenge',
          startDate: moment(this.currentWeek[0]).format('YYYY-MM-DD'),
          endDate: moment(this.currentWeek[4]).format('YYYY-MM-DD'),
          type: 'MON-FRI',
          id: '',
        };
        this.previousChallenge.id =
          this.previousChallenge.type +
          ';' +
          this.previousChallenge.startDate +
          ';' +
          this.previousChallenge.endDate;
      } else if (this.challenge.type == 'MON-FRI') {
        this.challenge.title = 'Monday to Friday Challenge';
        this.previousChallenge = {
          title: 'Weekend Challenge',
          startDate: moment(this.currentWeek[5])
            .subtract(7, 'd')
            .format('YYYY-MM-DD'),
          endDate: moment(this.currentWeek[6])
            .subtract(7, 'd')
            .format('YYYY-MM-DD'),
          type: 'WEEKEND',
          id: '',
        };
        this.previousChallenge.id =
          this.previousChallenge.type +
          ';' +
          this.previousChallenge.startDate +
          ';' +
          this.previousChallenge.endDate;
      }

      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        if (this.currentProgramme) {
          forkJoin({
            challengeResults: 
            this.activityService
                        .getGlobalActivity(
                          this.currentProgramme.programme_id,
                          this.startDate,
                          this.endDate,
                          '',
                          ''
                        )
            /*this.activityService.getChallengeGlobalResults(
              this.challengeType,
              this.startDate,
              this.endDate
            )*/,
          }).subscribe((responseData) => {
            this.isFetching = false;

            this.challenge_results = responseData.challengeResults;
            if (this.challenge_results[0]) {
              this.currentLeader = {
                screen_name: this.challenge_results[0].screen_name,
                avatar: this.challenge_results[0].avatar,
              };
            }
          });
        }
      });
    });
  }

  orderResult(items, sort, reverse?) {
    let sorted = true;
    items = items.sort((a, b) => {
      if (!a[sort]) {
        // Change this values if you want to put `null` values at the end of the array
        return -1;
      }
      if (!b[sort]) {
        // Change this values if you want to put `null` values at the end of the array
        return +1;
      }

      if (sorted) return a[sort].localeCompare(b[sort]);
      else return b[sort].localeCompare(a[sort]);

      // a[sort].localeCompare(b[sort])
    });

    if (reverse === true) {
      items = items.reverse();
    }
    return items;
  }

  ngOnDestroy(){
   // this.userService.userData.unsubscribe();
  }
}
