import { environment } from 'src/environments/environment';
import { Component, Input, OnInit } from '@angular/core';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { polyfill } from 'mobile-drag-drop';
// optional import of scroll behaviour
import { scrollBehaviourDragImageTranslateOverride } from 'mobile-drag-drop/scroll-behaviour';

@Component({
  selector: 'app-step-drag-and-drop-ranking',
  templateUrl: './step-drag-and-drop-ranking.component.html',
  styleUrls: ['./step-drag-and-drop-ranking.component.scss'],
})
export class StepDragAndDropRankingComponent implements OnInit {
  @Input() dragAndDrop;
  @Input() dragItems;
  correct = 0;
  incorrect = 0;
  environment = environment;

  draggedTitle;
  draggedItem;
  qNum = 1;
  currentDragEffectMsg;
  currentDraggableEvent;
  originalOrder = [];

  constructor() {}

  ngOnInit(): void {
    this.dragAndDrop.state = 'default';
    polyfill({
      // use this to make use of the scroll behaviour
      dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride,
    });

    // workaround to make scroll prevent work in iOS Safari > 10
    try {
      window.addEventListener('touchmove', function () {}, { passive: false });
    } catch (e) {}
    this.dragItems.forEach((di, index) => {
      this.dragItems[index].state = 'default';
      this.originalOrder.push(di);
    });
  }

  onDragStart(event: DragEvent) {
    this.currentDragEffectMsg = '';
    this.currentDraggableEvent = event;
  }

  onDragged(item: any, list: any[], effect: DropEffect) {
    this.currentDragEffectMsg = `Drag ended with effect "${effect}"!`;

    if (effect === 'move') {
      const index = list.indexOf(item);
      list.splice(index, 1);
    }
  }

  onDragEnd(event: DragEvent) {
    this.currentDraggableEvent = event;
  }

  onDrop(event: DndDropEvent, list?: any[]) {
    if (list && (event.dropEffect === 'copy' || event.dropEffect === 'move')) {
      let index = event.index;

      if (typeof index === 'undefined') {
        index = list.length;
      }

      list.splice(index, 0, event.data);
    }
  }

  submitRanking() {
    this.dragAndDrop.correct = true;

    this.dragItems.forEach((dItem, dIndex) => {
      if (dItem.drag_item_id != this.originalOrder[dIndex].drag_item_id) {
        this.dragAndDrop.correct = false;
        this.dragItems[dIndex].state = 'incorrect';
      } else {
        this.dragItems[dIndex].state = 'correct';
      }
    });
    this.dragAndDrop.state = 'answered';
  }
}
