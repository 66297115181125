<nav class="subnav">
  <a
    [routerLink]="['/health-trackers', 'hba1c']"
    class="subnav-prev"
    aria-label="Go to HbA1c"
  >
    <span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon
    ></span>
  </a>
  <h2>
    <span class="icon-wrap">
      <fa-icon [icon]="['fas', 'tachometer-alt']"></fa-icon>
    </span>
    BLOOD PRESSURE
  </h2>
  <a
    [routerLink]="['/health-trackers', 'cholesterol']"
    class="subnav-next"
    aria-label="Go to Cholesterol"
    ><span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
  ></a>
</nav>
<div>
  <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>

  <div class="masthead masthead-accent">
    <div *ngIf="userBloodPressures.length > 0 ">
      <div class="row mt-3">
        <div class="col-4">
          <h3 class="tracker-title-sm tracker-title-sm-nm">SYSTOLIC</h3>
          <div class="row">
            <div class="col-12 text-center">
              <div>
                <span class="tracker-value tracker-value-lg">{{
                  userBloodPressures[0].systolic | number
                }}</span
                ><br /><span class="tracker-value-sm-nf">mmHg</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <br />
          <br />
          <img
            src="./assets/img/icon-bp.png"
            width="92"
            alt="Bloop pressure icon"
          />
        </div>
        <div class="col-4">
          <h3 class="tracker-title-sm tracker-title-sm-nm">DIASTOLIC</h3>
          <div class="row">
            <div class="col-12 text-center">
              <div>
                <span class="tracker-value tracker-value-lg">{{
                  userBloodPressures[0].diastolic | number
                }}</span
                ><br /><span class="tracker-value-sm-nf">mmHg</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="masthead-subtext tracker-subtext">
            Last updated on
            {{
              userBloodPressures[0].created
                | dateToISO
                | dateFormatter: "D"
                | ordinal
            }}
            {{ userBloodPressures[0].created | dateToISO | dateFormatter: "MMM yyyy" }}
          </p>
        </div>
      </div>
    </div>

    <div *ngIf="userBloodPressures.length === 0">
      <h4>You haven't added any readings yet!</h4>
    </div>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.update = !toggleRow.update"
    >
    <a href="" class="toggle-nav toggle-nav-expand" (click)="$event.preventDefault()"
    ><span class="icon-wrap" aria-label="Expand"
    ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span
></a>
      <div class="toggle-header-title">UPDATE TRACKER</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.update">
      <form
        class="bs-form tracker-form"
        name="trackerForm"
        method="post"
        (ngSubmit)="onSubmit()"
        #trackerForm="ngForm"
      >
        <div >
          <div class="form-group">
            <label for="systolic">SYSTOLIC (mmHg)</label>
            <input
              type="number"
              name="systolic"
              class="form-control input-tracker"
              placeholder=""
              id="systolic"
             ngModel
             #systolic="ngModel"
              [min]="diastolic.value  || 70"
              [max]="239"
              autocomplete="off"
              required
            />
            <div
              class="alert alert-danger"
              *ngIf="
                systolic.touched && !systolic.valid
              "
            >
              This must be a number between
              {{ diastolic.value || 70 }} and 240, inclusive.
            </div>
          </div>
          <div class="form-group">
            <label for="diastolic">DIASTOLIC (mmHg)</label>
            <input
              type="number"
              name="diastolic"
              class="form-control input-tracker"
              placeholder=""
              id="diastolic"
              ngModel
              #diastolic="ngModel"
              [min]="30"
              [max]="150"
              autocomplete="off"
              required
            />
            <div
              class="alert alert-danger"
              *ngIf="
                diastolic.touched && !diastolic.valid 
              "
            >
              This must be a number between 30 and 150, inclusive.
            </div>
          </div>
          <div class="form-group">
            <label for="entryDate">DATE</label>
            <p class="instructions">
              When did you do take this reading?
            </p>
            <input
              name="date"
              type="text"
              placeholder="DD/MM/YYYY"
              class="form-control input-tracker"
              bsDatepicker
              autocomplete="off"
              [bsConfig]="{
                dateInputFormat: 'DD/MM/YYYY',
                containerClass: 'theme-cv',
                showWeekNumbers: false
              }"
              [(ngModel)]="entryDate"
              required
              [bsValue]="entryDate"
              [maxDate]="maxDate"
              #date="ngModel"
            />
          </div>
          <div class="tracker-submit-container">
            <button
              type="submit"
              class="btn btn-primary btn-white"
              [disabled]="!trackerForm.valid"
            >
            <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'check']"></fa-icon
          ></span> CONFIRM
            </button>
          </div>
        </div>
      </form>

    </div>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.graph = !toggleRow.graph"
    >
    <a href="" class="toggle-nav toggle-nav-expand" (click)="$event.preventDefault()"
    ><span class="icon-wrap" aria-label="Expand"
    ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span
></a>
      <div class="toggle-header-title">TRACKER GRAPH</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.graph">
      <div *ngIf="userBloodPressures.length > 0 ">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs nav-tabs-duo" role="tablist">
          <li role="presentation" [ngClass]="{ active: toggleChart.systolic }">
            <a
              aria-controls="weight"
              role="tab"
              data-toggle="tab"
              (click)="toggleChart.systolic = true;"
              >SYSTOLIC</a
            >
          </li>
          <li role="presentation" [ngClass]="{ active: !toggleChart.systolic }">
            <a
              aria-controls="waist"
              role="tab"
              data-toggle="tab"
              (click)="toggleChart.systolic = false"
              >DIASTOLIC</a
            >
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <div
            role="tabpanel"
            id="systolicChart"
            *ngIf="toggleChart.systolic"
          >
            <div class="chart tracker-chart">
              <div *ngIf="systolicChartData[0].data.length > 1">
                <canvas
                  class="mt-3 mb-3 tracker-chart"
                  baseChart
                  [datasets]="systolicChartData"
                  [labels]="chartLabels"
                  [options]="chartOptions"
                  [legend]="chartLegend"
                  [chartType]="chartType"
                  [colors]="chartColors"
                  height="200"
                ></canvas>
                <!--canvas id="line" class="chart chart-line tracker-chart" chart-data="data" chart-labels="labels" chart-series="series" chart-options="options"
                                    chart-dataset-override="datasetOverride" chart-click="onClick" height="200"  aria-label="Bar Chart Values {{data}}">
                                    </canvas-->
              </div>
              <p
                class="text-center"
                *ngIf="systolicChartData[0].data.length < 2"
              >
                Please add more entries to see the graph
              </p>
            </div>
          </div>
          <div
            role="tabpanel"
            id="diastolicChart"
            *ngIf="!toggleChart.systolic"
          >
            <div class="chart tracker-chart">
              <div *ngIf="diastolicChartData[0].data.length > 1">
                <canvas
                  class="mt-3 mb-3 tracker-chart"
                  baseChart
                  [datasets]="diastolicChartData"
                  [labels]="chartLabels"
                  [options]="chartOptions"
                  [legend]="chartLegend"
                  [chartType]="chartType"
                  [colors]="chartColors"
                  height="200"
                ></canvas>
              </div>
              <p class="text-center" *ngIf="diastolicChartData[0].data.length < 2">
                Please add more entries to see the graph
              </p>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="userBloodPressures.length === 0">
        <p>There are no readings to display in a graph</p>
      </div>
    </div>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.entry = !toggleRow.entry"
    >
    <a href="" class="toggle-nav toggle-nav-expand" (click)="$event.preventDefault()"
    ><span class="icon-wrap" aria-label="Expand"
    ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span
></a>
      <div class="toggle-header-title">ENTRY ARCHIVE</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.entry">
      <table *ngIf="userBloodPressures.length > 0" class="table">
        <thead>
          <tr>
            <th>DATE</th>
            <th>SYSTOLIC</th>
            <th>DIASTOLIC</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let userBloodPressure of userBloodPressures">
            <td class="text-center">
              {{ userBloodPressure.created | dateToISO | dateFormatter: "D" | ordinal }}
              {{ userBloodPressure.created | dateToISO | dateFormatter: "MMM yyyy" }}
            </td>
            <td class="text-center">{{ userBloodPressure.systolic }}</td>
            <td class="text-center">{{ userBloodPressure.diastolic }}</td>
            <td>
                <a
                [swal]="{
                  title: 'Are you sure you want to delete this?',
                  icon: 'warning',
                  showCancelButton: true
                }"
                (confirm)="onRemove(userBloodPressure)"
                class="pointer"
                ><span class="icon-wrap"
                  ><fa-icon [icon]="['fas', 'trash']"></fa-icon></span
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="userBloodPressures.length === 0">There are no records</div>
    </div>
  </div>
  <!-- decision maker -->
  <hr class="divider-tracker" />
  <div class="text-center decision-maker-block">
    <p>
      Want to set goals to increase/decrease your blood pressure?<br />
      Try our Decision Maker tool
    </p>
    <a [routerLink]="['/decision-maker']" class="btn btn-primary"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'map-signs']"></fa-icon
      ></span>
      DECISION MAKER</a
    >
  </div>

  <app-section-nav
    [section]="'healthTrackers'"
    [active]="'bloodPressure'"
  ></app-section-nav>
</div>
