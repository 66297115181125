<nav class="subnav">
    <a [routerLink]="['/notifications']" class="subnav-prev"
      > <span
      ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon
    ></span></a>
    <h2><span
      ><fa-icon [icon]="['fas', 'user']"></fa-icon
    ></span> MY PROFILE</h2>
    <a [routerLink]="['/settings']" class="subnav-next"
      > <span
      ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon
    ></span></a>
  </nav>
<div class="masthead masthead-accent" *ngIf="currentUser">
    <div class="profile">
        <div class="avatar">
            <img [src]="currentUser.avatar !== null && environment.avatarUrl + '/assets/avatar/' + currentUser.avatar ||  'assets/img/default-avatar.png'" class="img-circle img-avatar" width="100" height="100" /> 
            <br><br>
        </div>
        <div class="profile-details">
            <ul>
                <li class="profile-name" *ngIf="!currentUser.buddy">{{currentUser.first_name + ' ' + currentUser.last_name | uppercase}}</li>
                <li class="profile-name" *ngIf="currentUser.buddy">{{currentUser.screen_name | uppercase}}</li>
                <!--li class="profile-location">Location: {{currentUser.location}}</li-->
                <!--li class="profile-social"><a ng-show="currentUser.facebook" href="{{currentUser.facebook}}"><i class="fa fa-facebook-square"></i></a> <a ng-show="currentUser.twitter" href="{{currentUser.twitter}}"><i class="fa fa-twitter"></i></a> <a ng-show="currentUser.instagram" href="{{currentUser.instagram}}"><i class="fa fa-instagram"></i></a></li-->
            </ul>
            
        </div>
        <div class="profile-achievements">
            <div class="row">
                <div class="col-4 text-left"><span class="medal"><img src="./assets/img/icon-medal-gold.png" width="16" height="18"></span> <strong>1st</strong> - {{gold}}</div>
                <div class="col-4"><span class="medal"><img src="./assets/img/icon-medal-silver.png" width="16" height="18"></span> <strong>2nd</strong> - {{silver}}</div>
                <div class="col-4 text-right"><span class="medal"><img src="./assets/img/icon-medal-bronze.png" width="16" height="18"></span> <strong>3rd</strong> - {{bronze}}</div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <!--p>{{currentUser.bio}}</p-->
    <hr class="divider">
    <div class="text-center">
        <button [routerLink]="['/update-profile']" class="btn btn-primary btn-lg"><span
            ><fa-icon [icon]="['fas', 'pencil']"></fa-icon
          ></span> EDIT MY AVATAR</button>
    </div>
</div>