import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivityService } from 'src/app/services/activity.service';
import { BloodGlucoseService } from 'src/app/services/blood-glucose.service';
import { BloodPressureService } from 'src/app/services/blood-pressure.service';
import { CholesterolService } from 'src/app/services/cholesterol.service';
import { HeartRateService } from 'src/app/services/heart-rate.service';
import { NonSteppingService } from 'src/app/services/non-stepping.service';
import { ProgrammeService } from 'src/app/services/programme.service';
import { SleepService } from 'src/app/services/sleep.service';
import { SmokingService } from 'src/app/services/smoking.service';
import { Sts60Service } from 'src/app/services/sts60.service';
import { TargetService } from 'src/app/services/target.service';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { UserShapeService } from 'src/app/services/user-shape.service';
import { UserService } from 'src/app/services/user.service';
import { Vo2maxService } from 'src/app/services/vo2max.service';
import { UserAlbuminService } from 'src/app/services/user-albumin.service';
import { UserEgfrService } from 'src/app/services/user-egfr.service';
import { UserAltService } from 'src/app/services/user-alt.service';
import { UserFib4Service } from 'src/app/services/user-fib4.service';
import { UserPhq9Service } from 'src/app/services/user-phq9.service';
import { UserPaidService } from 'src/app/services/user-paid.service';
import { UserMbtqService } from 'src/app/services/user-mbtq.service';
import { UserFallService } from 'src/app/services/user-fall.service';
import { User180TurnService } from 'src/app/services/user-180-turn.service';
import { UserShortFesService } from 'src/app/services/user-short-fes.service';
import { UserSss8Service } from 'src/app/services/user-sss8.service';
import { UserDistressScoreService } from 'src/app/services/user-distress-score.service';
import { UserCfsScoreService } from 'src/app/services/user-cfs-score.service';

@Component({
  selector: 'app-m3-health-data',
  templateUrl: './m3-health-data.component.html',
  styleUrls: ['./m3-health-data.component.scss'],
})
export class M3HealthDataComponent implements OnInit {
  currentUser;
  currentProgramme;
  age;
  deviceSynced = false;
  currentActivity;
  currentSteps;
  currentTargetObj;
  currentTarget;
  userSettings;
  isFetching = false;
  perosnalisedTarget;
  myActivity;
  stepTarget;
  stepAverage;
  allTimeSteps: any = [];
  changeInSteps;
  hba1cChanges: any = [];
  activeMinsThisWeek = 0;

  activitiesDone;
  activitiesByWeek: any = {};
  activitiesByWeekTotals = [];
  sex: any;

  algorithmComplete = false;
  algorithmDays = [
    { day: '', status: 0 },
    { day: '', status: 0 },
    { day: '', status: 0 },
  ];
  algorithmDaysRemaining = 3;
  activitySetupStep = 1;

  hba1cs: any = [];
  userShapes: any = [];
  bmis: any = [];
  weights: any = [];
  waists: any = [];
  weightChanges: any = [];

  bloodPressures: any = [];
  cholesterols: any = [];
  hdlRatios: any = [];
  smokings: any = [];
  allSleeps: any = [];
  weeklyStepAverages: any = [];
  sleepHours;
  sleepMins;
  nonSteppingsByDate = [];
  nonSteppings: any = [];
  nonSteppingWeeklyTotal = 0;
  nonSteppingsWeek = [];
  sittingTarget = 6;
  latestSittings: any = [];
  sittingAverage = 0;
  sleepAverage;
  sleepScore;
  sts60s: any = [];
  heartRates: any = [];
  vo2maxs: any = [];

  hba1cUnit = '%';

  glucoseAverages: any = [];
  glucoseAverageChanges: any = [];
  glucoseInRanges: any = [];
  glucoseBelowRanges: any = [];

  // kidney
  albumins: any = [];
  egfrs: any = [];

  // liver
  alts: any = [];
  fib4s: any = [];

  // wellbeing
  phq9s: any = [];
  paids: any = [];
  mbtqs: any = [];

  falls: any = [];
  user180Turns: any = [];
  shortFess: any = [];
  sss8s: any = [];
  distressScores: any = [];

  showFrailty = false;
  userCfsScores:any = [];

  constructor(
    private authService: AuthService,
    private programmeService: ProgrammeService,
    private userService: UserService,
    private userSettingsService: UserSettingsService,
    private activityService: ActivityService,
    private targetService: TargetService,
    public bloodGlucoseService: BloodGlucoseService,
    private userShapeService: UserShapeService,
    private bloodPressureService: BloodPressureService,
    private cholesterolService: CholesterolService,
    private smokingService: SmokingService,
    private vo2maxService: Vo2maxService,
    public sts60Service: Sts60Service,
    private sleepService: SleepService,
    private nonSteppingService: NonSteppingService,
    public userAlbuminService: UserAlbuminService,
    public userEgfrService: UserEgfrService,
    public userAltService: UserAltService,
    public userFib4Service: UserFib4Service,
    public userPhq9Service: UserPhq9Service,
    public userPaidService: UserPaidService,
    public userMbtqService: UserMbtqService,
    public userFallService: UserFallService,
    public user180TurnService: User180TurnService,
    public userShortFesService: UserShortFesService,
    public userSss8Service: UserSss8Service,
    public userDistressScoreService: UserDistressScoreService,
    public userCfsScoreService : UserCfsScoreService
  ) {}

  ngOnInit(): void {
    // are we logged in?
    this.isFetching = true;
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {});

      this.userService.userData.subscribe((userDataResponse) => {
        if (userDataResponse) {
          this.currentUser = userDataResponse;
          this.sex = this.currentUser.gender;
          if (this.currentUser.dob) {
            this.age = moment().diff(this.currentUser.dob, 'years', false);
          }
          if (this.currentUser.device) {
            if (this.currentUser.device == 'fitbit') {
              this.sittingTarget = 9;
            }
          }
        }
      });

      // get start date and three day summary?
      // do it
      const threeDaysAgo = moment().subtract('4', 'days');
      const created = moment(user.created);
      let startDay = created;
      if (threeDaysAgo > created) {
        startDay = threeDaysAgo;
      }

      const now = moment();

      let startDate = moment(startDay).format('YYYY-MM-DD');
      let endDate = moment(now).subtract(1, 'days').format('YYYY-MM-DD');

      forkJoin({
        userSettings: this.userSettingsService.fetchAll(),
        activity: this.activityService.getActivity(),
        target: this.targetService.getTarget('step'),
        activitySummary: this.activityService.getActivitySummary(
          startDate,
          endDate
        ),
        allTimeSteps: this.activityService.getActivityBetween(
          moment().subtract(1, 'years').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ),
        hba1cs: this.bloodGlucoseService.fetchAll(),
        glucoseAverages: this.bloodGlucoseService.fetchGlucoseAverages(),
        glucoseBelowRanges:
          this.bloodGlucoseService.fetchGlucoseTimeBelowRanges(),
        glucoseInRanges: this.bloodGlucoseService.fetchGlucoseTimeInRanges(),
        userShapes: this.userShapeService.fetchAll(),
        bloodPressures: this.bloodPressureService.fetchAll(),
        cholesterols: this.cholesterolService.fetchAll(),
        smokings: this.smokingService.fetchAll(),
        vo2maxs: this.vo2maxService.fetchAll(),
        sts60s: this.sts60Service.fetchAll(),

        allTimeSleep: this.sleepService.fetchAll(),

        nonSteppings: this.nonSteppingService.fetchAll(),
        sleepScore: this.sleepService.getSleepScore(),
        albumins: this.userAlbuminService.fetchAll(),
        egfrs: this.userEgfrService.fetchAll(),
        alts: this.userAltService.fetchAll(),
        fib4s: this.userFib4Service.fetchAll(),
        phq9s: this.userPhq9Service.fetchAll(),
        paids: this.userPaidService.fetchAll(),
        mbtqs: this.userMbtqService.fetchAll(),
        userFalls: this.userFallService.fetchAll(),
        user180Turns: this.user180TurnService.fetchAll(),
        userShortFess: this.userShortFesService.fetchAll(),
        userSss8s: this.userSss8Service.fetchAll(),
        userDistressScores: this.userDistressScoreService.fetchAll(),
        userCfsScores: this.userCfsScoreService.fetchAll()
      }).subscribe(
        (responseData) => {
          this.isFetching = false;

          this.stepTarget = responseData.target;
          this.allTimeSteps = responseData.allTimeSteps;
          this.allTimeSteps.forEach((activity: any) => {
            if (
              moment(activity.activity_date).format('YYYY-MM-DD') >=
              moment().startOf('isoWeek').format('YYYY-MM-DD')
            ) {
              this.activeMinsThisWeek += +activity.minutes;
            }
          });

          this.userSettings = responseData.userSettings;
          if (this.userSettings['readActivitySetup']) {
            this.activitySetupStep = 2;
          }

          this.currentActivity = responseData.activity;
          this.stepAverage = this.activityService.getStepAverage(
            this.allTimeSteps
          );
          // console.log(this.currentActivity);
          if (this.currentActivity) {
            this.currentSteps = this.currentActivity.steps;
            this.deviceSynced = true;
          } else {
            this.deviceSynced = false;
          }

          if (responseData.target) {
            this.currentTargetObj = responseData.target;
            this.currentTarget = responseData.target.target;
            this.algorithmComplete = true;
          }

          // activity data

          // this.heartRates = responseData.heartRates;
          this.vo2maxs = responseData.vo2maxs;
          this.sts60s = responseData.sts60s;
          this.stepTarget = responseData.target;

          this.allTimeSteps = responseData.allTimeSteps;
          this.allTimeSteps.forEach((activity) => {
            if (activity.sedentary_minutes && this.latestSittings.length < 6) {
              this.latestSittings.push(activity);
            }
          });

          let allWeeklyAverages = this.activityService.getWeeklyStepAverages(
            this.allTimeSteps
          );
          let index = 0;
          for (var key in allWeeklyAverages) {
            if (allWeeklyAverages.hasOwnProperty(key)) {
              index++;
              if (index <= 5) {
                this.weeklyStepAverages.push({
                  activity_date: key,
                  steps: allWeeklyAverages[key],
                });
              }
            }
          }

          this.changeInSteps = this.activityService.getChangeInSteps(
            this.allTimeSteps
          );

          this.sittingAverage = this.activityService.getSittingAverage(
            this.allTimeSteps
          );

          this.sleepScore = responseData.sleepScore;

          this.userSettings = responseData.userSettings;
          if (this.userSettings['readActivitySetup']) {
            this.activitySetupStep = 2;
          }

          this.allSleeps = responseData.allTimeSleep;
          this.sleepAverage = this.sleepService.getSleepAverage(this.allSleeps);
          if (this.allSleeps[0]) {
            let hours = Math.floor(this.allSleeps[0].sleep / 60);
            let minutes = Math.floor(this.allSleeps[0].sleep % 60);

            if (
              moment(this.allSleeps[0].date_recorded).dayOfYear() ==
              moment().dayOfYear()
            ) {
              this.sleepMins = minutes;
              this.sleepHours = hours;
            }
          }

          // non stepping..

          this.nonSteppings = responseData.nonSteppings;

          this.activitiesByWeek = this.nonSteppingService.getActivitiesByWeek(
            this.nonSteppings,
            this.allTimeSteps
          );

          this.activitiesByWeekTotals =
            this.nonSteppingService.getActivitiesByWeekTotals(
              this.activitiesByWeek
            );

          let activtitiesDone = [];
          this.nonSteppings.forEach((nonStepping) => {
            activtitiesDone.push(nonStepping.activity);
            // is it in the last 7 days?
            if (
              moment(nonStepping.date_recorded).format('YYYY-MM-DD') >=
              moment().startOf('isoWeek').format('YYYY-MM-DD')
            ) {
              this.nonSteppingWeeklyTotal += +nonStepping.minutes;
              this.nonSteppingsWeek.push(nonStepping);
            }

            if (!this.nonSteppingsByDate[nonStepping.date_recorded]) {
              this.nonSteppingsByDate[nonStepping.date_recorded] = [];
            }
            this.nonSteppingsByDate[nonStepping.date_recorded].push(
              nonStepping
            );
          });
          this.activitiesDone = activtitiesDone;

          // do week total
          /*
        for (var key in this.nonSteppingsByDate) {
          this.weeklyTotal = 0;
          if (this.nonSteppingsByDate.hasOwnProperty(key)) {
            this.nonSteppingsByDate[key].forEach((activity, acIndex) => {
              this.weeklyTotal += +activity.minutes;
            });
          }
        }*/

          this.currentActivity = responseData.activity;
          this.stepAverage = this.activityService.getStepAverage(
            this.allTimeSteps
          );
          if (!this.stepAverage || this.stepAverage == 0) {
            if (this.weeklyStepAverages) {
              let weekStep = false;
              this.weeklyStepAverages
                .slice()
                .reverse()
                .forEach((week) => {
                  if (!weekStep && +week.steps > 0) {
                    this.stepAverage = week.steps;
                    weekStep = true;
                  }
                });
            }
          }
          // console.log(this.currentActivity);
          if (this.currentActivity) {
            this.currentSteps = this.currentActivity.steps;
            this.deviceSynced = true;
          } else {
            this.deviceSynced = false;
          }

          if (responseData.target) {
            this.currentTargetObj = responseData.target;
            this.currentTarget = responseData.target.target;
            this.algorithmComplete = true;
          }
          // end activity data

          this.generateAlgorithmDays();

          // health data
          let bmi = 0;
          this.hba1cs = responseData.hba1cs;
          this.hba1cChanges = [];
          if (this.hba1cUnit == '%') {
            this.hba1cs.forEach((hba1c, index) => {
              if (this.hba1cs[index + 1]) {
                this.hba1cChanges.push({
                  change:
                    Math.round(
                      (this.convertHba1cToPercent(hba1c.glucose) -
                        this.convertHba1cToPercent(
                          this.hba1cs[index + 1].glucose
                        )) *
                        100
                    ) / 100,
                  created: hba1c.created,
                });
              }
              hba1c.glucose = this.convertHba1cToPercent(hba1c.glucose);
            });
          }
          this.glucoseAverages = responseData.glucoseAverages;
          this.glucoseAverageChanges = [];
          this.glucoseAverages.forEach((glucoseAverage, index) => {
            if (this.glucoseAverages[index + 1]) {
              this.glucoseAverageChanges.push({
                change:
                  (Math.round(
                    glucoseAverage.user_glucose_average -
                      this.glucoseAverages[index + 1].user_glucose_average
                  ) *
                    100) /
                  100,
                date_recorded: glucoseAverage.date_recorded,
              });
            }
          });

          this.glucoseBelowRanges = responseData.glucoseBelowRanges;
          this.glucoseInRanges = responseData.glucoseInRanges;

          this.bloodPressures = responseData.bloodPressures;
          this.cholesterols = responseData.cholesterols;
          this.cholesterols.forEach((cholesterol) => {
            if (cholesterol.hdl_ratio) {
              this.hdlRatios.push(cholesterol);
            }
          });
          this.smokings = responseData.smokings;

          this.userShapes = responseData.userShapes;

          this.userShapes.forEach((user_shape, index) => {
            if (user_shape.weight) {
              this.weights.push(user_shape);
              bmi = this.userShapeService.getBmi(
                this.currentUser.height,
                user_shape.weight
              );
              this.bmis.push({
                bmi: bmi,
                date_recorded: user_shape.created,
              });
            }
            if (user_shape.waist) {
              this.waists.push({
                waist: user_shape.waist * 2.54,
                date_recorded: user_shape.created,
              });
            }
          });
          this.weights.forEach((user_shape, index) => {
            if (index > 0 && this.weights[index - 1].weight) {
              this.weightChanges.push({
                change: +this.weights[index - 1].weight - +user_shape.weight,
                date_recorded: user_shape.created,
              });
            }
          });

          this.albumins = responseData.albumins;
          this.egfrs = responseData.egfrs;

          this.alts = responseData.alts;
          this.fib4s = responseData.fib4s;

          this.phq9s = responseData.phq9s;
          this.paids = responseData.paids;
          this.mbtqs = responseData.mbtqs;
          this.falls = responseData.userFalls;
          this.user180Turns = responseData.user180Turns;
          this.shortFess = responseData.userShortFess;
          this.sss8s = responseData.userSss8s;
          this.distressScores = responseData.userDistressScores;
          this.userCfsScores = responseData.userCfsScores;

          if(this.userCfsScores[0]){
            if(+this.userCfsScores[0].cfs_score >=4){
              this.showFrailty = true;
            }
          }
        },
        (error) => {
          this.isFetching = false;
        }
      );
    }
  }

  generateAlgorithmDays() {
    // get 4 days ago
    const threeDaysAgo = moment().subtract('3', 'days');
    const created = moment(this.currentUser.created);
    let startDay = created.add('1', 'day');
    if (threeDaysAgo > created) {
      startDay = threeDaysAgo;
    }

    this.algorithmDaysRemaining = 3;
    this.activityService
      .getActivityBetween(
        startDay.format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      )
      .subscribe((responseData) => {
        const userActivity = responseData;
        // do the loop
        let returnDays = [];
        let totalSteps = 0;
        for (let i = 0; i < 3; i++) {
          let dayStatus = 0;

          userActivity.forEach((activity) => {
            if (
              activity.activity_date ==
                moment(startDay).add(i, 'days').format('YYYY-MM-DD') &&
              activity.steps > 0 &&
              moment(startDay).add(i, 'days').format('YYYY-MM-DD') <
                moment().format('YYYY-MM-DD')
            ) {
              dayStatus = 1;
              this.algorithmDaysRemaining--;
              totalSteps += +activity.steps;
            }
          });
          returnDays.push({
            day: moment(startDay).add(i, 'days'),
            status: dayStatus,
          });
        }

        if (this.algorithmDaysRemaining == 0) {
          // work out the target?
          this.perosnalisedTarget = Math.round(totalSteps / 3 + 500);

          if (this.perosnalisedTarget >= 10000) {
            this.perosnalisedTarget = 10000;
          }
        }
        this.algorithmDays = returnDays;
      });
  }
  onConfirmReadActivitySetup() {
    this.activitySetupStep = 2;
    this.userSettingsService
      .saveSetting('readActivitySetup', '1')
      .subscribe((responseData) => {});
  }

  getHba1cRag(hba1c: any) {
    return this.bloodGlucoseService.getRag(hba1c);
  }

  getHba1cChangeRag(change: any) {
    return this.bloodGlucoseService.getChangeRag(change);
  }
  getGlucoseAverageRag(value: any) {
    return this.bloodGlucoseService.getGlucoseAverageRag(value);
  }

  getBmiRag(bmi, ethnicityId) {
    return this.userShapeService.getBmiRag(bmi, ethnicityId);
  }

  getBmiRagRange(color: string, ethnicityId) {
    return this.userShapeService.getBmiRagRange(color, ethnicityId);
  }

  getWaistRag(waist: number, sex, ethnicityId) {
    return this.userShapeService.getWaistRag(waist, sex, ethnicityId);
  }
  getWaistRagRange(color: string, sex, ethnicityId) {
    return this.userShapeService.getWaistRagRange(color, sex, ethnicityId);
  }

  getBloodPressureRag(systolic: number, diastolic: number) {
    return this.bloodPressureService.getRag(systolic, diastolic);
  }

  getBloodPressureSystolicRag(systolic: number) {
    return this.bloodPressureService.getSystolicRag(systolic);
  }
  getBloodPressureDiastolicRag(diastolic: number) {
    return this.bloodPressureService.getDiastolicRag(diastolic);
  }
  getCholesterolRag(value: number, type: string) {
    return this.cholesterolService.getRag(value, type);
  }

  getAlbuminRagRange(color: string, sex: string) {
    let ragRange = '';
  }

  getGoodforAgeAndSex(age: number, sex: string) {
    return this.vo2maxService.getGoodforAgeAndSex(age, sex);
  }

  getRagForAgeAndSex(vo2max: any, age: number, sex: string) {
    return this.vo2maxService.getRagForAgeAndSex(vo2max, age, sex);
  }

  getVo2maxforColorAgeAndSex(color: string, age: number, sex: string) {
    return this.vo2maxService.getVo2maxforColorAgeAndSex(color, age, sex);
  }
  getSts60RagForAgeAndSex(sts60: any, age: number, sex: string) {
    return this.sts60Service.getRagForAgeAndSex(sts60, age, sex);
  }
  getSts60forColorAgeAndSex(color: string, age: number, sex: string) {
    return this.sts60Service.getSts60forColorAgeAndSex(color, age, sex);
  }

  getStepsRag(averageSteps: number) {
    return this.activityService.getStepsRag(averageSteps);
  }
  getSittingRag(minutes, device) {
    return this.activityService.getSittingRag(minutes, device);
  }
  getSleepRagForAge(sleep: any, age: number) {
    return this.sleepService.getRagForAge(sleep, age);
  }

  getAgeSleep(age) {
    if (age < 65) {
      return '7-9';
    } else {
      return '7-8';
    }
  }

  convertHba1cToPercent(value: number) {
    // convert from percent..
    return Math.round((0.0915 * value + 2.15) * 100) / 100;
  }

  getAltRag(alt: any) {}

  getAlbuminRag(albumin: any) {}

  getPhq9Rag(phq9: any) {}

  getChangeInStepsRag(steps) {
    let ragColour = '';
    if (steps >= 500) {
      ragColour = 'green';
    } else if (steps > -500) {
      ragColour = 'amber';
    } else {
      ragColour = 'red';
    }
    return ragColour;
  }
}
