import { LearningMaterialService } from './../../services/learning-material.service';
import { PageViewService } from './../../services/page-view.service';
import { DateHelperService } from './../../services/date-helper.service';
import { ConfigService } from './../../services/config.service';
import { SmokingService } from './../../services/smoking.service';
import { AlertService } from './../../services/alert.service';
import { NgForm } from '@angular/forms';
import { CholesterolService } from './../../services/cholesterol.service';
import { BloodPressureService } from './../../services/blood-pressure.service';
import { BloodGlucoseService } from './../../services/blood-glucose.service';
import { HealthyEatingService } from './../../services/healthy-eating.service';
import { environment } from './../../../environments/environment';
import { UserShapeService } from './../../services/user-shape.service';
import { BoosterSessionsService } from './../../booster-sessions/booster-sessions.service';
import { TargetService } from './../../services/target.service';
import { ActivityService } from './../../services/activity.service';
import { UserSettingsService } from './../../services/user-settings.service';
import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import Swal from 'sweetalert2/src/sweetalert2.js';
import * as moment from 'moment';
import { ExerciseWorkoutsService } from 'src/app/services/exercise-workouts.service';
import { Router } from '@angular/router';
import { SleepService } from 'src/app/services/sleep.service';
import { Eq5dService } from 'src/app/services/eq5d.service';

@Component({
  selector: 'app-dashboard-ep',
  templateUrl: './dashboard-ep.component.html',
  styleUrls: ['./dashboard-ep.component.scss'],
})
export class DashboardEpComponent implements OnInit {
  @ViewChild('stepsForm', { static: false }) stepsForm: NgForm;
  @ViewChild('speedForm', { static: false }) speedForm: NgForm;

  currentUser;
  currentProgramme;
  deviceSynced = null;
  hideTrackerDB = false;
  isFetching = false;
  errors = [];
  userSettings;

  currentActivity;
  currentTarget;
  currentTargetObj;
  currentTargetMins;
  currentTargetMinsUnit;
  currentTargetSitting;
  environment = environment;
  currentSteps = 0;
  cadence;
  nightsLeftThisWeek = 8 - moment().day();

  // ported vars
  learningSessionTotals;
  learningSessionProgressTotal;
  boosterSessionTotals;
  boosterSessionProgressTotal;
  programmeContent;
  startLSession = 0;
  endLSession = 3;
  startSession = 0;
  endSession = 3;
  lastViewed;
  lastViewedPage;

  currentWeek;
  minsWeekTotal = 0;

  newAlerts = [];

  algorithmComplete = false;
  algorithmDays = [
    { day: '', status: 0 },
    { day: '', status: 0 },
    { day: '', status: 0 },
  ];
  algorithmDaysRemaining = 3;
  activitySetupStep = 1;

  // health trackers
  userShapes = [];
  userHealthyEatings = [];
  userSmokingType;
  smokingLabels = {
    cigarettes: 'Cigarettes',
    cigars: 'cigars',
    tobacco: 'Tobacco',
    pipe_tobacco: 'Pipe Tobacco',
  };
  cost;
  userSmokings = [];
  userHbA1cs = [];
  userBloodPressures = [];
  userCholesterols = [];
  bmi;

  // challenges
  challengePos;
  iLead = false;
  currentLeader = [];
  myActivity;
  format;
  monfriChallenge = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  weekendChallenge = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  currentChallenge = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  teamChallenge = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };
  globalChallenge = {
    title: null,
    startDate: null,
    endDate: null,
    nextStartDate: null,
    type: null,
    id: null,
  };

  date = new Date();

  // get dates for EP step taret progression
  // get monday last week
  lastMonday = moment().isoWeekday(-6);
  lastSunday = moment(this.lastMonday).add(6, 'days');
  lastLastMonday = moment().isoWeekday(-13);
  newStepTargetAdvice = false;

  workoutDaysHit = 0;
  workoutWeekData = [];
  currentWorkoutTarget;
  allWorkouts;

  // sleep
  age: any;
  sleepDaysHit = 0;
  sleepWeekData: any = [];
  allSleeps: any;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private userSettingsService: UserSettingsService,
    private activityService: ActivityService,
    private targetService: TargetService,
    private boosterSessionService: BoosterSessionsService,
    private userShapeService: UserShapeService,
    private healthyEatingService: HealthyEatingService,
    private bloodGlucoseService: BloodGlucoseService,
    private bloodPressureService: BloodPressureService,
    private cholesterolService: CholesterolService,
    private smokingService: SmokingService,
    private alertService: AlertService,
    private configService: ConfigService,
    private dateHelperService: DateHelperService,
    private pageViewService: PageViewService,
    private learningMaterialService: LearningMaterialService,
    private exerciseWorkoutsService: ExerciseWorkoutsService,
    private router: Router,
    private sleepService: SleepService,
    private eq5dService: Eq5dService
  ) {}

  ngOnInit(): void {
    this.isFetching = true;
    const now = moment();
    this.currentWeek = this.dateHelperService.getWeekFor(now);
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.eq5dService.checkTake(user);
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
        const startDate = moment(this.currentWeek[0]).format('YYYY-MM-DD');
        const endDate = moment(this.currentWeek[6]).format('YYYY-MM-DD');
        forkJoin({
          userSettings: this.userSettingsService.fetchAll(),
          activity: this.activityService.getActivity(),
          target: this.targetService.getTarget('step'),
          targetMins: this.targetService.getTarget('mins'),
          targetSitting: this.targetService.getTarget('sitting'),
          targetWorkout: this.targetService.getTarget('workout'),
          sessionProgress: this.boosterSessionService.getSessionsProgressData(
            this.currentProgramme.programme_id
          ),
          userShapes: this.userShapeService.fetchAll(),
          userHealthyEatings: this.healthyEatingService.fetchAll(),
          userHbA1cs: this.bloodGlucoseService.fetchAll(),
          userBloodPressures: this.bloodPressureService.fetchAll(),
          userCholesterols: this.cholesterolService.fetchAll(),
          userSmokings: this.smokingService.fetchAll(),
          alerts: this.alertService.fetchAll(),
          configs: this.configService.fetchAll(),
          pageviews: this.pageViewService.fetchAll(),
          activityThisWeek: this.activityService.getActivityBetween(
            startDate,
            endDate
          ),
          activityLastWeek: this.activityService.getActivitySummary(
            this.lastMonday.format('YYYY-MM-DD'),
            this.lastSunday.format('YYYY-MM-DD')
          ),
          activityLastLastWeek: this.activityService.getActivitySummary(
            this.lastLastMonday.format('YYYY-MM-DD'),
            this.lastSunday.format('YYYY-MM-DD')
          ),
          workouts: this.exerciseWorkoutsService.fetchAll(),
          allTimeSleep: this.sleepService.fetchAll(),
        }).subscribe(
          (responseData) => {
            this.isFetching = false;

            this.userSettings = responseData.userSettings;
            this.hideTrackerDB = this.userSettings['hideDbTracker'];

            // have they chosen the walking speed advice?
            if (this.userSettings['walkingSpeedAdvice']) {
              if (this.userSettings['walkingSpeedAdvice'] == 0) {
                this.activitySetupStep = 4;
              } else if (
                this.userSettings['walkingSpeedAdvice'] == 1 &&
                !this.currentUser.cadence
              ) {
                this.activitySetupStep = 3;
              } else if (
                this.userSettings['walkingSpeedAdvice'] == 1 &&
                this.currentUser.cadence
              ) {
                this.activitySetupStep = 4;
              }
            }
            this.currentActivity = responseData.activity;
            if (this.currentActivity) {
              this.currentSteps = this.currentActivity.steps;
              this.deviceSynced = true;
            } else {
              this.deviceSynced = false;
            }

            if (responseData.target) {
              this.currentTargetObj = responseData.target;
              this.currentTarget = responseData.target.target;
              this.algorithmComplete = true;
            }
            this.currentWorkoutTarget = responseData.targetWorkout;
            // do they not want steps?
            if (
              this.currentUser.mobility3 == '0' ||
              this.currentUser.mobility1 == 'e' ||
              this.currentUser.mobility1 == 'f'
            ) {
              this.algorithmComplete = true;
            }

            if (responseData.targetMins) {
              this.currentTargetMins = responseData.targetMins.target;
              this.currentTargetMinsUnit = responseData.targetMins.target_unit;
            }

            if (responseData.targetSitting) {
              this.currentTargetSitting = responseData.targetSitting.target;
            }
            const sessionProgress = this.boosterSessionService.doSessions(
              responseData.sessionProgress,
              user.created
            );

            this.minsWeekTotal = 0;
            responseData.activityThisWeek.forEach((activity) => {
              this.minsWeekTotal += +activity.minutes;
            });

            // console.log(sessionProgress);
            this.learningSessionTotals = sessionProgress.learningSessionTotals;
            this.learningSessionProgressTotal =
              sessionProgress.learningSessionProgressTotal;
            this.boosterSessionTotals = sessionProgress.boosterSessionTotals;
            this.boosterSessionProgressTotal =
              sessionProgress.boosterSessionProgressTotal;
            this.programmeContent = sessionProgress.programmeContent;

            this.userShapes = responseData.userShapes;
            this.userHealthyEatings = responseData.userHealthyEatings;
            this.userHbA1cs = responseData.userHbA1cs;
            this.userBloodPressures = responseData.userBloodPressures;
            this.userCholesterols = responseData.userCholesterols;
            this.newAlerts = this.alertService.filterByProgramme(
              responseData.alerts,
              this.currentProgramme.programme_id
            );
            this.userSmokings = responseData.userSmokings;

            // cost
            if (this.userSmokings[0]) {
              this.userSmokingType = responseData.userSettings['smoking_type'];
              const configs = responseData.configs;
              switch (this.userSmokingType) {
                case 'cigarettes':
                  this.cost =
                    this.userSmokings[0].cigarettes *
                    configs['cigarette_cost'] *
                    365;
                  break;
                case 'tobacco':
                  this.cost =
                    this.userSmokings[0].tobacco *
                    configs['tobacco_cost'] *
                    365;

                  break;
                case 'cigars':
                  this.cost =
                    this.userSmokings[0].cigars * configs['cigar_cost'] * 365;

                  break;
                case 'pipe_tobacco':
                  this.cost =
                    this.userSmokings[0].pipe_tobacco *
                    configs['pipe_tobacco_cost'] *
                    365;

                  break;
              }
            }

            // workouts

            this.allWorkouts = responseData.workouts;
            // week data
            this.workoutDaysHit = 0;
            this.currentWeek.forEach((day) => {
              if (this.workoutsByDate(day).length > 0) {
                this.workoutDaysHit += this.workoutsByDate(day).length;
                this.workoutWeekData.push({
                  date: moment(day).format('YYYY-MM-DD'),
                  hit: 1,
                });
              } else {
                this.workoutWeekData.push({
                  date: moment(day).format('YYYY-MM-DD'),
                  hit: 0,
                });
              }
            });
            // end workouts

            // sleep

            this.allSleeps = responseData.allTimeSleep;

            // get this week's data
            this.currentWeek.forEach((item, index) => {
              const thisActivity = this.allSleeps.filter(this.searchSleep, {
                date_recorded: moment(item).format('YYYY-MM-DD'),
              })[0];

              if (thisActivity) {
                //this.weekTotal += +thisActivity.sedentary_minutes;
                this.sleepWeekData.push(thisActivity);
                if (
                  +thisActivity.sleep / 60 >= 7 &&
                  +thisActivity.sleep / 60 <= 9
                ) {
                  this.sleepDaysHit++;
                }
              } else {
                const dData = {
                  date_recorded: moment(item).format('YYYY-MM-DD'),
                };
                this.sleepWeekData.push(dData);
              }
            });

            // end sleep

            this.lastViewed = responseData.pageviews[0];
            if (this.lastViewed) {
              if (this.lastViewed.booster_session_id) {
                this.boosterSessionService
                  .fetchStep(this.lastViewed.page_id)
                  .subscribe((lastViewedResponse) => {
                    if (lastViewedResponse) {
                      this.lastViewedPage = lastViewedResponse;
                      this.lastViewedPage.parent = {};
                      // get the parent..
                      this.programmeContent.booster_sessions.forEach((bs) => {
                        if (
                          bs.booster_session_id ==
                          this.lastViewedPage.booster_session_id
                        ) {
                          this.lastViewedPage.parent = bs;
                          if (bs.release_date == 'O') {
                            this.lastViewedPage.parent.type = 'learningSession';
                          } else {
                            this.lastViewedPage.parent.type = 'boosterSession';
                          }
                        }
                      });
                    }
                  });
              }

              if (this.lastViewed.content_section_id) {
                this.learningMaterialService
                  .fetchPage(this.lastViewed.page_id)
                  .subscribe((lastViewedResponse) => {
                    if (lastViewedResponse) {
                      this.lastViewedPage = lastViewedResponse;
                      this.lastViewedPage.parent = {};

                      this.programmeContent.content_sections.forEach((bs) => {
                        if (
                          bs.content_section_id ==
                          this.lastViewedPage.content_section_id
                        ) {
                          this.lastViewedPage.parent = bs;
                        }
                      });
                    }
                  });
              }
            }

            this.generateAlgorithmDays();

            // step target advice available?
            if (this.currentTargetObj) {
              // can they update based on last week?
              if (
                this.currentTargetObj.created <
                this.lastSunday.format('YYYY-MM-DD')
              ) {
                //console.log(responseData.activityLastWeek[0]);

                if (
                  responseData.activityLastWeek[0] &&
                  responseData.activityLastLastWeek[0]
                ) {
                  if (
                    +responseData.activityLastLastWeek[0].step_avg >=
                      +this.currentTargetObj.target &&
                    +responseData.activityLastWeek[0].step_avg >=
                      +this.currentTargetObj.target
                  ) {
                    this.newStepTargetAdvice = true;
                  } else if (
                    +responseData.activityLastWeek[0].step_avg >=
                    +this.currentTargetObj.target
                  ) {
                    this.newStepTargetAdvice = true;
                  } else if (
                    responseData.activityLastWeek[0].step_avg <
                      +this.currentTargetObj.target &&
                    this.currentUser.created <
                      this.lastMonday.format('YYYY-MM-DD')
                  ) {
                    this.newStepTargetAdvice = true;
                  }
                }
              }
            }
            // end step target
          },
          (error) => {
            console.log(error);

            this.isFetching = false;
            this.errors.push(error.message);
          }
        );
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
        if (this.currentUser) {
          this.getChallenge();
        }
      });
    }
  }

  onSetLSession(index) {
    this.startLSession = index;
    this.endLSession = +this.startLSession + 3;
  }

  onSetSession(index) {
    this.startSession = index;
    this.endSession = +this.startSession + 3;
  }

  onSubmitStepsForm() {
    // save the steps

    this.activityService
      .saveSteps(this.stepsForm.value.steps, this.stepsForm.value.date)
      .subscribe((responseData) => {
        this.activityService.getActivity().subscribe((activityResponseData) => {
          this.currentActivity = activityResponseData;
        });

        Swal.fire({
          icon: 'success',
          title: 'Steps Updated',
          text: 'Your steps were successfully updated',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        /*
        swal({
        title: 'Steps Updated',
        text: 'Your steps were successfully updated',
        type: 'success',
        timer: 1000,
        showConfirmButton: false,
      });*/
      });
  }

  onSubmitCadenceForm() {
    // save the steps

    this.activityService
      .saveWalkingSpeed(this.speedForm.value.cadence)
      .subscribe((responseData) => {
        this.userService.reset();
        this.userService.get().subscribe((userData) => {
          this.currentUser = userData;
        });

        Swal.fire({
          icon: 'success',
          title: 'Walking Speed set',
          text: 'Your walking speed has been saved.',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        this.activitySetupStep = 4;
      });
  }

  onCloseAlert(alert, followLink = true) {
    this.alertService.updateStatus(alert.alert_id).subscribe((responseData) => {
      this.alertService.fetchAll().subscribe((alertsResponse) => {
        this.newAlerts = this.newAlerts = this.alertService.filterByProgramme(
          alertsResponse,
          this.currentProgramme.programme_id
        );
      });
      if (alert.button_link) {
        if (followLink) {
          if (alert.button_link === 'refresh') {
            location.reload();
          } else {
            document.location = alert.button_link;
          }
        }
      }
    });
  }

  hideDbTracker() {
    this.hideTrackerDB = true;
    this.userSettingsService
      .saveSetting('hideDbTracker', '1')
      .subscribe((responseData) => {});
  }

  calcBmi(weight) {
    if (weight) {
      const BMI =
        Math.round(
          (+weight /
            ((this.currentUser.height / 100) *
              (this.currentUser.height / 100))) *
            10
        ) / 10;
      return BMI;
    } else {
      return '-';
    }
  }

  getChallenge() {
    // start challenge

    // get week
    const now = moment().format('YYYY-MM-DD');

    // what is the day today
    const today = moment(now).format('dddd');
    const currentWeek = this.dateHelperService.getWeekFor(now);

    this.globalChallenge.startDate = this.teamChallenge.startDate;
    this.globalChallenge.endDate = this.teamChallenge.endDate;
    this.globalChallenge.nextStartDate = moment(currentWeek[0])
      .add(1, 'week')
      .format('YYYY-MM-DD');

    if (today == 'Saturday' || today == 'Sunday') {
      this.weekendChallenge.title = 'Weekend Challenge';
      this.currentChallenge.title = 'Weekend Challenge';
      this.weekendChallenge.startDate = moment(currentWeek[5]).format(
        'YYYY-MM-DD'
      );
      this.weekendChallenge.endDate = moment(currentWeek[6]).format(
        'YYYY-MM-DD'
      );
      this.weekendChallenge.type = 'WEEKEND';
      this.weekendChallenge.id =
        this.weekendChallenge.type +
        ';' +
        this.weekendChallenge.startDate +
        ';' +
        this.weekendChallenge.endDate;

      this.currentChallenge = this.weekendChallenge;

      this.monfriChallenge.title = 'Monday - Friday Challenge';
      this.monfriChallenge.startDate = moment(currentWeek[0]).format(
        'YYYY-MM-DD'
      );
      this.monfriChallenge.endDate = moment(currentWeek[4]).format(
        'YYYY-MM-DD'
      );
      this.monfriChallenge.type = 'MON-FRI';
      this.monfriChallenge.id =
        this.monfriChallenge.type +
        ';' +
        this.monfriChallenge.startDate +
        ';' +
        this.monfriChallenge.endDate;
    } else {
      this.monfriChallenge.title = 'Monday - Friday Challenge';
      this.currentChallenge.title = 'Monday - Friday Challenge';
      this.monfriChallenge.startDate = moment(currentWeek[0]).format(
        'YYYY-MM-DD'
      );
      this.monfriChallenge.endDate = moment(currentWeek[4]).format(
        'YYYY-MM-DD'
      );
      this.monfriChallenge.type = 'MON-FRI';
      this.monfriChallenge.id =
        this.monfriChallenge.type +
        ';' +
        this.monfriChallenge.startDate +
        ';' +
        this.monfriChallenge.endDate;

      this.currentChallenge = this.monfriChallenge;

      this.weekendChallenge.title = 'Weekend Challenge';
      this.weekendChallenge.startDate = moment(currentWeek[5])
        .subtract(1, 'week')
        .format('YYYY-MM-DD');

      this.weekendChallenge.endDate = moment(currentWeek[6])
        .subtract(1, 'week')
        .format('YYYY-MM-DD');
      this.weekendChallenge.type = 'WEEKEND';
      this.weekendChallenge.id =
        this.weekendChallenge.type +
        ';' +
        this.weekendChallenge.startDate +
        ';' +
        this.weekendChallenge.endDate;
    }

    if (!this.currentUser.buddy) {
      this.activityService
        .getBandActivity(
          this.currentUser.step_band,
          this.currentProgramme.programme_id,
          this.currentChallenge.startDate,
          this.currentChallenge.endDate
        )
        .subscribe((responseData) => {
          let pos = 0;
          responseData.forEach((item, index) => {
            pos++;

            if (item.user_id == this.currentUser.user_id) {
              this.challengePos = pos;
            }
            if (item.user_id === this.currentUser.user_id) {
              this.myActivity = item;
            }

            if (this.currentLeader.length === 0) {
              this.currentLeader.push(responseData[index]);
              if (responseData[index].user_id === this.currentUser.user_id) {
                this.iLead = true;
              }
            } else {
              if (index > 0) {
                if (
                  responseData[index].step_count ===
                  this.currentLeader[0].step_count
                ) {
                  this.currentLeader.push(responseData[index]);
                  if (
                    responseData[index].user_id === this.currentUser.user_id
                  ) {
                    this.iLead = true;
                  }
                }
              }
            }
          });
        });
    }

    // end challenge
  }

  onChooseWalkingSpeed(yesNo) {
    this.userSettingsService
      .saveSetting('walkingSpeedAdvice', yesNo)
      .subscribe((responseData) => {
        // redirect them here?
        this.router.navigateByUrl(
          '/learning-session-steps/view/' + environment.walkingSpeedStepId
        );
        /*
        if(yesNo == 1){
        this.activitySetupStep = 3; 
        }
        else{
        this.activitySetupStep = 4;
        }*/
      });
  }

  generateAlgorithmDays() {
    // get 4 days ago
    const threeDaysAgo = moment().subtract('3', 'days');
    const created = moment(this.currentUser.created);
    let startDay = created.add('1', 'day');
    if (threeDaysAgo.format('YYYY-MM-DD') > created.format('YYYY-MM-DD')) {
      startDay = threeDaysAgo;
    }

    this.algorithmDaysRemaining = 3;
    this.activityService
      .getActivityBetween(
        startDay.format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      )
      .subscribe((responseData) => {
        const userActivity = responseData;
        // do the loop
        let returnDays = [];
        for (let i = 0; i < 3; i++) {
          let dayStatus = 0;

          userActivity.forEach((activity) => {
            if (
              activity.activity_date ==
                moment(startDay).add(i, 'days').format('YYYY-MM-DD') &&
              activity.steps > 0 &&
              moment(startDay).add(i, 'days').format('YYYY-MM-DD') <
                moment().format('YYYY-MM-DD')
            ) {
              dayStatus = 1;
              this.algorithmDaysRemaining--;
            }
          });
          returnDays.push({
            day: moment(startDay).add(i, 'days'),
            status: dayStatus,
          });
        }

        this.algorithmDays = returnDays;
      });
  }

  workoutsByDate(date) {
    let workouts = [];
    if (this.allWorkouts) {
      this.allWorkouts.forEach((workout) => {
        if (
          moment(workout.workout_date).format('YYYY-MM-DD') ==
          moment(date).format('YYYY-MM-DD')
        ) {
          workouts.push(workout);
        }
      });
    }
    return workouts;
  }

  onReload() {
    location.reload();
  }

  // sleep

  getAgeSleep(age) {
    if (age < 65) {
      return '7-9';
    } else {
      return '7-8';
    }
  }

  searchSleep(obj) {
    return Object.keys(this).every((key) => obj[key] === this[key]);
  }
}
