import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ProgrammeService } from 'src/app/services/programme.service';
import { UserAltService } from 'src/app/services/user-alt.service';
import { UserFib4Service } from 'src/app/services/user-fib4.service';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-m3-liver',
  templateUrl: './m3-liver.component.html',
  styleUrls: ['./m3-liver.component.scss']
})
export class M3LiverComponent implements OnInit {
  currentUser;
  alts: any = [];
  fib4s: any = [];
  showAllEntries = false;
  entryDateAlt = new Date();
  entryDateFib4 = new Date();


  @ViewChild('entryFormAlt', { static: false }) entryFormAlt: NgForm;
  @ViewChild('entryFormFib4', { static: false }) entryFormFib4: NgForm;

  constructor(
    private authService: AuthService,
    private programmeService: ProgrammeService,
    private router: Router,
    private userService: UserService,
    private userSettingsService: UserSettingsService,
    private modalService: BsModalService,
    public userAltService:UserAltService,
    public userFib4Service:UserFib4Service
  )
  {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {});

      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });

      forkJoin({
        userSettings: this.userSettingsService.fetchAll(),
        alts:this.userAltService.fetchAll(),
        fib4s: this.userFib4Service.fetchAll()
      }).subscribe((responseData) => {
        this.alts = responseData.alts;
        this.fib4s = responseData.fib4s;
      });
    }
  }

  
  onDeleteAlt(alt) {
    
    this.userAltService
    .delete(alt.user_alt_id)
      .subscribe((responseData) => {
        //this.activityService.clearCache();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/liver']));
      });
  }

  onSubmitAltForm() {
    // this.entryForm.value.date
    
    if (!this.entryFormAlt.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something below",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.userAltService
        .create(
          this.entryFormAlt.value.alt,
          moment(this.entryDateAlt).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
           
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/liver']));
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }


  onDeleteFib4(fib4) {
    
    this.userFib4Service
    .delete(fib4.user_fib4_id)
      .subscribe((responseData) => {
        //this.activityService.clearCache();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/liver']));
      });
  }

  onSubmitFib4Form() {
    // this.entryForm.value.date
    
    if (!this.entryFormFib4.valid) {
      Swal.fire({
        icon: 'error',
        title: "We notice you've missed something below",
        text: 'Please check the form for information that has been missed',
        showConfirmButton: true,
      });
    } else {
      this.userFib4Service
        .create(
          this.entryFormFib4.value.fib4,
          moment(this.entryDateFib4).format('YYYY-MM-DD')
        )
        .subscribe(
          (responseData) => {
           
            // set chart data
            Swal.fire({
              icon: 'success',
              title: 'Entry added',
              text: 'Your data was successfully updated',
              showConfirmButton: false,
              timer: 1000,
            });
            this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/m3/health-data/liver']));
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

}
